import cs from "classnames";

import { AttachmentActionsWrapper } from "@js/apps/give-and-get-help/components/post-attachments/attachment-actions-wrapper";
import type { Message } from "@js/types/messenger";

import { AttachmentPreview } from "./attachment-preview";
import type { PreparedAttachment } from "./model";

type AttachmentsPreviewProps = {
  attachments: PreparedAttachment[];
  grid?: boolean;
  isMultipleAttachments: boolean;
  removeAttachment?: (
    id: string | number,
    isEditMode: boolean,
    isFromText?: boolean,
  ) => void;
  onEditLinkClick?: (url: string) => void;
  report?: {
    isOwner: boolean;
    message: Message;
  };
};

export const AttachmentsPreview = ({
  attachments,
  grid,
  isMultipleAttachments,
  removeAttachment,
  onEditLinkClick,
}: AttachmentsPreviewProps) => {
  return (
    <div
      className={cs(`post-attachments`, {
        "post-attachments--grid": grid,
        "post-attachments--single-attachment": !isMultipleAttachments,
      })}
    >
      {attachments.map((attachment) => {
        if (!removeAttachment || !("id" in attachment)) {
          return (
            <AttachmentPreview
              key={attachment.id}
              attachment={attachment}
              showThumbnail
            />
          );
        }

        return (
          <AttachmentActionsWrapper
            key={attachment.id}
            isFromText={
              "isFromText" in attachment ? attachment.isFromText : false
            }
            onRemoveClick={() =>
              !!removeAttachment && "isFromText" in attachment
                ? removeAttachment(
                    attachment?.url || attachment.id,
                    false,
                    attachment.isFromText,
                  )
                : removeAttachment(attachment.id, false)
            }
            onEditLinkClick={
              !!onEditLinkClick && "url" in attachment && attachment.url
                ? () => onEditLinkClick(attachment.url || "")
                : undefined
            }
          >
            <AttachmentPreview attachment={attachment} />
          </AttachmentActionsWrapper>
        );
      })}
    </div>
  );
};
