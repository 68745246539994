import React from "react";
import cs from "classnames";

import type { ButtonTileSelectProps } from "@hexocean/braintrust-ui-components";
import {
  Box,
  ButtonCore,
  useButtonSelect,
} from "@hexocean/braintrust-ui-components";
import type { OptionWithPrimitiveValue } from "@js/types/common";

export const ProjectDurationTileButton = ({
  value,
  onChange,
  error,
  options,
  itemWidth,
  canSingleValueBeEmpty,
}: ButtonTileSelectProps<
  OptionWithPrimitiveValue & {
    text: string;
  }
>) => {
  const {
    isSelected,
    handleChange,
    containerA11yProps,
    getItemKey,
    itemA11yProps,
  } = useButtonSelect({
    value,
    onChange,
    canSingleValueBeEmpty,
  });

  return (
    <Box display="inline-block">
      <Box {...containerA11yProps} display="flex" flexWrap="wrap" m={-1.75}>
        {options.map((option, index) => {
          const _isSelected = isSelected(option);

          return (
            <Box
              key={getItemKey(option, index)}
              width={itemWidth}
              className="button-tile-duration--shadow"
              m={1.75}
            >
              <ButtonCore
                {...itemA11yProps(_isSelected)}
                className={cs("button-tile-duration", {
                  "button-variant--white-violet": true,
                  "button-shape--squared": true,
                  "selected--border": _isSelected,
                })}
                onClick={(ev) => {
                  ev.preventDefault();
                  handleChange(option);
                }}
              >
                <Box
                  pt={3}
                  pb="20px"
                  px="20px"
                  display="flex"
                  flexDirection="column"
                  alignItems="flex-start"
                  width="100%"
                >
                  {option.text && (
                    <Box style={{ fontSize: 20 }}>{option.text || ""}</Box>
                  )}
                  {option.label}
                </Box>
              </ButtonCore>
            </Box>
          );
        })}
      </Box>
      {error && <p className="general-form-error">{error}</p>}
    </Box>
  );
};
