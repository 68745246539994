import type { PlaceDetailsResponse } from "@js/hooks/google-maps";

export const ValueType = {
  custom: "custom",
  prediction: "prediction",
  initial_mapped_from_string: "initial_mapped_from_string",
  detail: "detail",
} as const;

type CommonValueProperties = {
  id: string;
  name: string;
  selected?: boolean;
};

type DetailValue = { type: typeof ValueType.detail } & {
  /** @param id clone of `place_id` just for easier manipulation. TODO consider removing `place_id` or `id` completely */
  id: PlaceDetailsResponse["place_id"];
} & PlaceDetailsResponse & { selected?: boolean };

type CustomValue = { type: typeof ValueType.custom } & CommonValueProperties;

type PredictionValue = {
  type: typeof ValueType.prediction;
} & CommonValueProperties;

type InitialValueFromString = {
  type: typeof ValueType.initial_mapped_from_string;
} & CommonValueProperties;

export type BackendValue = DetailValue | CustomValue | string | null;

export type ComboBoxValue =
  | DetailValue
  | CustomValue
  | PredictionValue
  | InitialValueFromString
  | null;
