import { useEffect } from "react";

import { ModalInstance } from "@js/components/modal";
import type { EmployerBidListBid, Job } from "@js/types/jobs";

import { BidListEmptyState } from "../../components/job-management-empty-state";

import { BidListItem } from "./components/bid-list-item";
import { useSelectBid } from "./hooks/select-bid/select-bid";
import { useJobBidListScroll } from "./hooks/use-job-bid-list-scroll/use-job-bid-list-scroll";

import style from "./style.module.scss";

export type JobBidListProps = {
  bidList: EmployerBidListBid[];
  job: Job;
  areFiltersDefault: boolean;
};

export const JobBidList = ({
  job,
  areFiltersDefault,
  bidList = [],
}: JobBidListProps) => {
  const { isSelected, toggleSelect } = useSelectBid();

  useJobBidListScroll();

  useEffect(() => ModalInstance.close, []);

  if (!bidList.length && areFiltersDefault) {
    return (
      <div className={style.wrapper} data-testid="bid-list-empty-state">
        <BidListEmptyState job={job} />
      </div>
    );
  }

  return (
    <div className={style.wrapper} data-testid="job-bid-list">
      {bidList.map((bid) => (
        <BidListItem
          key={bid.id}
          bid={bid}
          job={job}
          selected={isSelected(bid.id)}
          onCheckboxChange={() => toggleSelect(bid.id)}
        />
      ))}
    </div>
  );
};
