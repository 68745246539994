import { mapKeys } from "@js/utils";

import type { NftAttribute } from "./types";

export const getNftAttributesAsObject = (attributes: NftAttribute[] = []) => {
  return mapKeys(attributes, "trait_type");
};

export const getNftAttribute = (
  attributes: NftAttribute[] = [],
  traitType: EnumType<typeof ENUMS.NFTMetadataTraitTypeLabels>,
) => {
  const attributesObj = getNftAttributesAsObject(attributes);
  const attribute = attributesObj[traitType] || null;

  return attribute;
};

export const getProfileDescriptionMessage = (tokenId: number) => {
  return decodeURIComponent(
    `Just minted Braintrust Membership NFT #${tokenId}! ` +
      `Now my profile data's on-chain for other web3 apps to use. - ${window.location.href}`,
  );
};
