import { forwardRef, type MouseEvent } from "react";
import { useNavigate } from "react-router-dom";
import AvatarBackground from "@static/freelancer/bg-top-talent.svg";

import {
  Avatar,
  Box,
  Button,
  Loader,
  Typography,
} from "@hexocean/braintrust-ui-components";
import { LocationPinSmallIcon } from "@hexocean/braintrust-ui-components/Icons";
import { CardCarouselSection } from "@js/apps/freelancer/components";
import { RouterLink } from "@js/components/link";
import type { Freelancer } from "@js/types/freelancer";

import { useEliteTalentProfiles } from "../../hooks/elite-talent-profiles";

import styles from "./style.module.scss";

export const EliteTalentModule = forwardRef((props, ref) => {
  const { talents, loading } = useEliteTalentProfiles();

  if (loading) {
    return <Loader />;
  }

  if (!talents) {
    return null;
  }

  return (
    <Box className={styles.eliteTalentCarouselContainer}>
      <CardCarouselSection
        title="Explore elite Talent"
        urlLabel="Explore Talent"
        url="/talent"
        items={talents}
        loading={loading}
        ref={ref}
        transitionProps={props}
        renderItem={(item) => <EliteTalentItem freelancer={item} />}
      />
    </Box>
  );
});

type EliteTalentItemProps = {
  freelancer: Freelancer;
};

const EliteTalentItem = ({
  freelancer: { user, location, id },
}: EliteTalentItemProps) => {
  const navigate = useNavigate();
  const talentLink = `/talent/${id}`;

  return (
    <Box
      className={styles.eliteTalentItemContainer}
      onClick={() => navigate(talentLink)}
      role="button"
    >
      <div>
        <div className={styles.eliteTalentItemAvatarContainer}>
          <AvatarBackground
            className={styles.eliteTalentItemAvatarBackground}
          />
          <Avatar className={styles.eliteTalentItemAvatar} src={user} />
        </div>
        <div className={styles.eliteTalentItemIntroduction}>
          <Typography
            component="p"
            size="small"
            color="dark-violet"
            fontWeight={500}
            ellipsis
            mb={1}
          >
            {user.title}
          </Typography>
          <Typography component="h4" fontWeight={500} multipleEllipsis>
            {user.public_name}
          </Typography>
        </div>
        {!!location && (
          <div className={styles.eliteTalentItemLocation}>
            <LocationPinSmallIcon
              className={styles.eliteTalentItemLocationIcon}
            />
            <Typography component="span" size="small" ellipsis>
              {location}
            </Typography>
          </div>
        )}
      </div>

      <Box px={2}>
        <Button
          className={styles.eliteTalentItemViewProfileButton}
          variant="secondary"
          shape="squared"
          to={talentLink}
          fullWidth
          RouterLink={RouterLink}
          onClick={(ev: MouseEvent<HTMLElement>) => ev.stopPropagation()}
        >
          View profile
        </Button>
      </Box>
    </Box>
  );
};
