import React from "react";
import { Field } from "redux-form";

import { Grid } from "@hexocean/braintrust-ui-components";
import { StandardTextField } from "@js/forms/fields";
import { AvatarField } from "@js/forms/fields";
import { RichTextareaField } from "@js/forms/fields/rich-textarea";

import { LanguageField } from "./languages-dropdown";

export const PersonalInfoFields = () => {
  return (
    <Grid container spacing={3}>
      <Grid item sm={3} md={2} className="column-left">
        <Field
          name="avatar"
          updateFieldName="avatar_id"
          component={AvatarField}
          type="employer"
          uploadType={ENUMS.UploadType.USER_AVATAR_IMAGE_UPLOAD}
        />
      </Grid>
      <Grid item sm={7} minWidth={0}>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <Field
              variant="standard"
              id="first_name"
              name="first_name"
              component={StandardTextField}
              maxLength={50}
              label="First Name"
              size="small"
            />
          </Grid>
          <Grid item xs={6}>
            <Field
              variant="standard"
              id="last_name"
              name="last_name"
              component={StandardTextField}
              maxLength={50}
              label="Last Name"
              size="small"
            />
          </Grid>
          <Grid item xs={12}>
            <Field
              variant="standard"
              id="title"
              name="title"
              placeholder="E.g. Project Manager, UI / UX Designer, Programmer"
              component={StandardTextField}
              maxLength={255}
              label="Title"
              size="small"
            />
          </Grid>
          <Grid item xs={12}>
            <Field
              maxTextLength={600}
              id="introduction"
              name="introduction"
              toolbar={[
                ["bold", "italic"],
                ["align-left", "align-center", "align-right"],
                ["list", "indent"],
                ["emoji", "clear-formatting"],
                ["undo", "redo"],
              ]}
              component={RichTextareaField}
              label="Tell Us About Yourself"
            />
          </Grid>
          <Grid item xs={12}>
            <Field
              id="user_languages"
              name="user_languages"
              component={LanguageField}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
