import { useRef, useState } from "react";

import { Box } from "@hexocean/braintrust-ui-components";
import { RouterLink } from "@js/components/link";
import { useOnElementVisible } from "@js/hooks/use-on-element-visible";
import type { EmployerBidListBid } from "@js/types/jobs";

import { BidAvatarWithScoreMatch } from "../../bid-avatar-with-score-match";

type BidListItemAvatarProps = {
  bid: EmployerBidListBid;
};

export const BidListItemAvatar = ({ bid }: BidListItemAvatarProps) => {
  const [showAvatar, setShowAvatar] = useState(false);
  const ref = useRef<HTMLElement>(null);

  // Lazy load avatars
  useOnElementVisible(
    ref,
    (isVisible) => {
      if (!isVisible) {
        return;
      }

      setShowAvatar(true);
    },
    { rootMargin: "150px 0px 150px 0px" },
  );

  return (
    <Box width={73} height={73} minWidth={73} ref={ref}>
      {showAvatar && (
        <RouterLink
          to={bid.freelancer.user.profile_url}
          target="_blank"
          className="job-bids-list-item__user-link"
        >
          <BidAvatarWithScoreMatch bid={bid} />
        </RouterLink>
      )}
    </Box>
  );
};
