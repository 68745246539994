import { InjectedConnector } from "@web3-react/injected-connector";
import { PortisConnector } from "@web3-react/portis-connector";
import { WalletConnectConnector } from "@web3-react/walletconnect-connector";
import { WalletLinkConnector } from "@web3-react/walletlink-connector";

import { FortmaticConnector } from "./fortmatic";
import { NetworkConnector } from "./network-connector";

export enum SupportedChainId {
  MAINNET = 1,
  ROPSTEN = 3,
  GÖRLI = 5,
  LOCALHOST = 1337,
}

const SUPPORTED_CHAIN_IDS: SupportedChainId[] = [
  SupportedChainId.MAINNET,
  SupportedChainId.ROPSTEN,
  SupportedChainId.GÖRLI,
  SupportedChainId.LOCALHOST,
];

const NETWORK_CHAIN_ID: SupportedChainId = parseInt(
  SETTINGS.VOTING_APP_CHAIN_ID ?? "1",
  10,
);

if (typeof SETTINGS.VOTING_INFURA_KEY === "undefined") {
  throw new Error(
    `SETTINGS.VOTING_INFURA_KEY must be a defined environment variable`,
  );
}

const NETWORK_URLS: { [key in SupportedChainId]: string } = {
  [SupportedChainId.MAINNET]: `https://mainnet.infura.io/v3/${SETTINGS.VOTING_INFURA_KEY}`,
  [SupportedChainId.ROPSTEN]: `https://ropsten.infura.io/v3/${SETTINGS.VOTING_INFURA_KEY}`,
  [SupportedChainId.GÖRLI]: `https://goerli.infura.io/v3/${SETTINGS.VOTING_INFURA_KEY}`,
  [SupportedChainId.LOCALHOST]: `http://localhost:8545`,
};

export const isLocalhostNetwork =
  NETWORK_CHAIN_ID === SupportedChainId.LOCALHOST;

export const network = new NetworkConnector({
  urls: NETWORK_URLS,
  defaultChainId: NETWORK_CHAIN_ID,
});

export const injected = new InjectedConnector({
  supportedChainIds: SUPPORTED_CHAIN_IDS,
});

export const walletconnect = new WalletConnectConnector({
  supportedChainIds: SUPPORTED_CHAIN_IDS,
  rpc: NETWORK_URLS,
  qrcode: true,
});

const formaticSupportedChainIdToNetwork = [1, 3, 4, 42];
const formaticChainId = isLocalhostNetwork
  ? SupportedChainId.MAINNET
  : NETWORK_CHAIN_ID;
export const fortmatic = formaticSupportedChainIdToNetwork.includes(
  formaticChainId,
)
  ? new FortmaticConnector({
      apiKey: SETTINGS.VOTING_APP_FORTMATIC_KEY ?? "",
      chainId: formaticChainId,
    })
  : undefined;

export const portis = new PortisConnector({
  dAppId: SETTINGS.VOTING_APP_PORTIS_ID ?? "",
  networks: [isLocalhostNetwork ? SupportedChainId.MAINNET : NETWORK_CHAIN_ID],
});

export const walletlink = new WalletLinkConnector({
  url: NETWORK_URLS[NETWORK_CHAIN_ID],
  appName: "Uniswap",
  // eslint-disable-next-line max-len
  appLogoUrl:
    "https://mpng.pngfly.com/20181202/bex/kisspng-emoji-domain-unicorn-pin-badges-sticker-unicorn-tumblr-emoji-unicorn-iphoneemoji-5c046729264a77.5671679315437924251569.jpg",
});
