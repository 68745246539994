import React from "react";
import { Outlet, useLocation } from "react-router-dom";
import { Field } from "redux-form";
import css from "classnames";

import { Box, UnderlinedTabs } from "@hexocean/braintrust-ui-components";
import { useMediaQuery } from "@hexocean/braintrust-ui-components/hooks";
import { RouterLink } from "@js/components/link";
import { SelectField } from "@js/forms/fields";

import DashboardLayout from "../layout";

import { HelpOffers11FiltersForm, OFFER_STATUS_OPTIONS } from "./filters";

const MY_HELP_OFFERS_NAVIGATION_ITEMS = [
  {
    label: "Getting help",
    path: "/talent/dashboard/my_jobs/help_offers/getting_help",
  },
  {
    label: "Giving help",
    path: "/talent/dashboard/my_jobs/help_offers/giving_help",
  },
];

export const HELP_OFFERS_1_1_FILTER_STATUS_FORM_ID =
  "help-offers-1-1-filter-status";

export const MyHelpOffersListingPage = () => {
  const { pathname } = useLocation();

  const isMobile = useMediaQuery(500);
  const labelSize = isMobile ? "medium" : "large";

  return (
    <DashboardLayout
      pageTitle="1:1 Help Offers"
      contentClassName={css("dashboard-layout-content", {
        "dashboard-layout-content--no-inline-padding": isMobile,
      })}
    >
      <Box className="listings-content-wrapper">
        <Box
          component="nav"
          className="my-offers-nav"
          mb={{ xs: 3, sm: 4 }}
          mt={-1}
          display="flex"
          flexDirection={{ xs: "column", sm: "row" }}
          justifyContent="space-between"
          alignItems="flex-end"
        >
          <UnderlinedTabs
            resetLeftPadding
            spacing={0}
            itemsGap="24px"
            pathname={pathname}
            items={MY_HELP_OFFERS_NAVIGATION_ITEMS}
            labelUnActiveColor="tertiary"
            fontWeight={400}
            labelSize={labelSize}
            itemProps={{
              className: "listings-content-wrapper__tab",
            }}
            padding="0"
            margin="0"
            RouterLink={RouterLink}
          />
          <HelpOffers11FiltersForm form={HELP_OFFERS_1_1_FILTER_STATUS_FORM_ID}>
            <Box mt={{ xs: 1, sm: 0 }} minWidth={186}>
              <Field
                id="offer-status"
                name="status"
                component={SelectField}
                options={OFFER_STATUS_OPTIONS}
              />
            </Box>
          </HelpOffers11FiltersForm>
        </Box>
        <Outlet />
      </Box>
    </DashboardLayout>
  );
};
