import React from "react";

import { Stack } from "@hexocean/braintrust-ui-components";
import type { ATSIntegration } from "@js/types/employer";

import { JobPostSettingsSection } from "./job-post-settings-section";
import { MyIntegrationSection } from "./my-integration-section";

type ATSIntegrationConnectedContentProps = { integration: ATSIntegration };

export const ATSIntegrationConnectedContent = ({
  integration,
}: ATSIntegrationConnectedContentProps) => {
  return (
    <>
      <Stack sx={{ gap: { xs: 2, sm: 3 } }}>
        <MyIntegrationSection integration={integration} />
        <JobPostSettingsSection integration={integration} />
      </Stack>
    </>
  );
};
