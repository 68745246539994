import React from "react";
import { Outlet, useLocation } from "react-router-dom";

import {
  Box,
  Stack,
  Typography,
  UnderlinedTabs,
} from "@hexocean/braintrust-ui-components";
import { useMediaQuery } from "@hexocean/braintrust-ui-components/hooks";
import { RouterLink } from "@js/components/link";
import { AppLayout } from "@js/layouts/app";

import styles from "./styles.module.scss";

const BOOKMARKS_NAVIGATION_ITEMS = [
  {
    label: "Career Help",
    path: "/bookmarks/career_help",
  },
  {
    label: "Jobs",
    path: "/bookmarks/jobs",
  },
];

export const BookmarksPage = () => {
  const { pathname } = useLocation();
  const isMobile = useMediaQuery("sm");
  const labelSize = isMobile ? "medium" : "small";
  const labelVariant = isMobile ? "paragraph" : "title";

  return (
    <AppLayout
      pageTitle="Bookmarks"
      bgcolor="var(--white)"
      flexColumn
      className={styles.bookmarksPage}
    >
      <Stack sx={{ px: { xs: 2, sm: 0 } }}>
        <Typography
          component="h1"
          variant="title"
          display="block"
          fontWeight={400}
          size={isMobile ? "small" : "medium"}
        >
          Your saved items
        </Typography>
        <Box
          component="nav"
          mb={{ xs: 3, sm: 4 }}
          mt="12px"
          display="flex"
          flexDirection={{ xs: "column", sm: "row" }}
          justifyContent="space-between"
          alignItems="flex-end"
        >
          <UnderlinedTabs
            items={BOOKMARKS_NAVIGATION_ITEMS}
            pathname={pathname}
            labelUnActiveColor="tertiary"
            fontWeight={400}
            labelSize={labelSize}
            labelVariant={labelVariant}
            RouterLink={RouterLink}
            resetLeftPadding
            spacing={0}
            itemsGap="24px"
            itemProps={{
              className: styles.bookmarkTab,
            }}
            padding="0"
            margin="0"
          />
        </Box>
      </Stack>

      <Outlet />
    </AppLayout>
  );
};
