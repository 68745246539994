import React from "react";

import { Box, Typography } from "@hexocean/braintrust-ui-components";
import type { Employer } from "@js/types/employer";

type EmployerLogoProps = {
  employer: Pick<
    Employer,
    "id" | "logo" | "name" | "logo_thumbnail" | "has_logo_set"
  >;
  source?: string;
  variant?: "normal" | "listing" | "menuitem";
  size?: "small" | "medium" | "large";
  listingStyle?: React.CSSProperties;
};

export const EmployerLogo = ({
  employer,
  source,
  variant = "normal",
  size = "medium",
  listingStyle,
}: EmployerLogoProps) => {
  if (!employer || !employer.name) {
    return null;
  }

  if (employer.has_logo_set) {
    return (
      <Box
        width="100%"
        height="100%"
        display="flex"
        alignSelf="center"
        justifyContent="center"
        alignItems="center"
      >
        <img
          src={source ?? employer.logo_thumbnail}
          width="100%"
          height="100%"
          style={{ ...listingStyle, alignSelf: "center" }}
          alt=""
        />
      </Box>
    );
  }

  if (variant === "listing") {
    return (
      <Box
        width="63px"
        height="63px"
        borderRadius="100%"
        bgcolor="var(--medium-violet)"
        display="flex"
        alignSelf="center"
        justifyContent="center"
        alignItems="center"
        sx={listingStyle}
      >
        <Typography
          sx={{
            color: "var(--dark-violet)",
            fontSize: "21px !important",
          }}
          mt={0.2}
          ml={0.2}
          component="span"
          variant="label"
        >
          {employer.name[0]}
        </Typography>
      </Box>
    );
  }

  if (variant === "menuitem") {
    return (
      <Box
        width="25px"
        height="25px"
        borderRadius="100%"
        bgcolor="var(--medium-violet)"
        display="flex"
        alignSelf="center"
        justifyContent="center"
        alignItems="center"
        sx={listingStyle}
      >
        <Typography
          sx={{
            color: "var(--dark-violet)",
          }}
          mt={0.2}
          component="span"
          variant="label"
        >
          {employer.name[0]}
        </Typography>
      </Box>
    );
  }

  return (
    <Box
      width="100%"
      height="100%"
      display="flex"
      alignSelf="center"
      justifyContent="center"
      alignItems="center"
    >
      <Typography component="span" variant="title" size={size}>
        {employer.name[0]}
      </Typography>
    </Box>
  );
};

type BoxedEmployerLogoProps = EmployerLogoProps & {
  size: keyof typeof SIZE;
  bgcolor?: string;
  style?: React.CSSProperties;
  borderRadius?: string;
  className?: string;
  logoWrapperSize?: {
    width: React.CSSProperties["width"];
    height: React.CSSProperties["height"];
  };
};

export const SIZE = {
  small: "42px",
  medium: "55px",
  large: "101px",
};

export const BoxedEmployerLogo = ({
  className,
  employer,
  size,
  source,
  bgcolor,
  variant,
  borderRadius = "30%",
  style,
  listingStyle,
  logoWrapperSize = { width: "43%", height: "43%" },
}: BoxedEmployerLogoProps) => {
  const _size = SIZE[size];

  return (
    <Box
      className={className}
      width={_size}
      height={_size}
      minWidth={_size}
      bgcolor={`var(${bgcolor})` || "var(--white)"}
      display="flex"
      justifyContent="center"
      alignItems="center"
      borderRadius={borderRadius}
      boxSizing="border-box"
      style={style}
    >
      <Box {...logoWrapperSize} className="employer-logo-wrapper">
        <EmployerLogo
          size={size}
          employer={employer}
          source={source}
          variant={variant}
          listingStyle={listingStyle}
        />
      </Box>
    </Box>
  );
};
