import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";

import { Snackbar } from "@js/components/snackbar";

import { API_CONFIRM_ERROR_MESSAGE_SEARCH_PARAM } from "../../constants";

type ErrorsType = typeof ENUMS.ConfirmationServiceError;
type ErrorKeys = ErrorsType[keyof ErrorsType];

export const useConfirmPageApiError = () => {
  const [params, setParams] = useSearchParams();
  const confirmationErrorKey = params.get(
    API_CONFIRM_ERROR_MESSAGE_SEARCH_PARAM,
  );

  useEffect(() => {
    if (!!confirmationErrorKey) {
      const errorMessage = getErrorMessage(confirmationErrorKey);

      Snackbar.error(errorMessage);

      setParams(
        (prevState) => {
          prevState.delete(API_CONFIRM_ERROR_MESSAGE_SEARCH_PARAM);
          return prevState;
        },
        { replace: true },
      );
    }
  }, [confirmationErrorKey, setParams]);
};

const getErrorMessage = (confirmationErrorKey: string) => {
  const errorKeysArr = Object.values(ENUMS.ConfirmationServiceError);
  const isDefinedError = errorKeysArr.includes(
    confirmationErrorKey as ErrorKeys,
  );

  if (isDefinedError) {
    return ENUMS.ConfirmationServiceErrorLabels[confirmationErrorKey];
  }

  return "Something went wrong.";
};
