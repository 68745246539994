import * as React from "react";

import type { AvatarGroupProps } from "@hexocean/braintrust-ui-components";
import {
  Avatar,
  AvatarGroup,
  Typography,
  VerticalSeparator,
} from "@hexocean/braintrust-ui-components";
import { useMediaQuery } from "@hexocean/braintrust-ui-components/hooks";
import { VerifiedIcon } from "@hexocean/braintrust-ui-components/Icons";
import { Logo } from "@js/components/logo";
import type { User } from "@js/types/auth";

import "./styles.scss";

type ExampleUser = Pick<
  User,
  | "first_name"
  | "id"
  | "avatar"
  | "has_avatar_set"
  | "avatar_thumbnail"
  | "gravatar"
>;

type UsersThumbnailAvatarsProps = AvatarGroupProps & {
  users: User[] | ExampleUser[];
  usersAmount?: number;
  variantType?: "withLogo" | "verified";
  maxGroupImages?: number;
  avatarsSpacing?: number;
};

export const UsersThumbnailAvatars: React.FC<UsersThumbnailAvatarsProps> = ({
  variantType = "withLogo",
  users,
  usersAmount,
  ...props
}) => {
  const isVariantWithLogo = variantType === "withLogo";
  const isMobile = useMediaQuery("sm");

  const _users = isMobile ? users.slice(0, 3) : users;

  return (
    <div className="user-thumbnail-avatars">
      {isVariantWithLogo && <Logo className="user-thumbnail-avatars__logo" />}
      {isMobile && <VerticalSeparator />}
      <div className="user-group-and-thumbnail-wrapper">
        <AvatarGroup
          className={
            isVariantWithLogo
              ? "users-thumbnails-avatars-group--with-logo"
              : "users-thumbnails-avatars-group--verified"
          }
          {...props}
        >
          {!isVariantWithLogo && (
            <VerifiedIcon sx={{ position: "relative", zIndex: 10 }} />
          )}
          {_users.map((user, idx) => {
            return (
              <Avatar
                key={user.id}
                className="users-thumbnails-avatars-group__avatar"
                sx={
                  isVariantWithLogo
                    ? { zIndex: idx }
                    : { marginLeft: "-8px !important" }
                }
                alt={user.first_name}
                src={user}
              />
            );
          })}
        </AvatarGroup>
        {isVariantWithLogo && !!usersAmount && (
          <UsersThumbnailCountBadge
            amount={usersAmount}
            zIndex={users.length}
          />
        )}
      </div>
    </div>
  );
};

type UsersThumbnailCountBadgeProps = {
  amount: number;
  zIndex: number;
};

const UsersThumbnailCountBadge: React.FC<UsersThumbnailCountBadgeProps> = ({
  amount,
  zIndex,
}) => {
  return (
    <Typography
      component="span"
      variant="label"
      className="users-count-badge"
      sx={{ zIndex }}
    >
      {amount} members
    </Typography>
  );
};
