import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import _ from "underscore";

const parseParams = (params: URLSearchParams) => {
  const obj = {};

  for (const [key] of params) {
    if (params.getAll(key).length > 1) {
      obj[key] = params.getAll(key);
    } else {
      obj[key] = params.get(key);
    }
  }

  return obj;
};

export const useQueryParams = (): Record<string, any> => {
  const [previousParams, setPreviousParams] = useState<Record<string, any>>({});
  const [params] = useSearchParams();

  const parsedParams = parseParams(params);

  if (_.isEqual(parsedParams, previousParams)) {
    return previousParams;
  }
  setPreviousParams(parsedParams);
  return parsedParams;
};
