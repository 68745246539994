import {
  FETCHING_COMPANY_NODES,
  FETCHING_COMPANY_NODES_FAILED,
  FETCHING_COMPANY_NODES_SUCCESS,
  FETCHING_INVOICES_TO_SETTLE_EMPLOYERS_PAYMENT,
  FETCHING_INVOICES_TO_SETTLE_EMPLOYERS_PAYMENT_FAILED,
  FETCHING_INVOICES_TO_SETTLE_EMPLOYERS_PAYMENT_SUCCESS,
  WITHDRAWALS_FAILED,
  WITHDRAWALS_FETCHING,
  WITHDRAWALS_SUCCESS,
} from "@js/apps/admin/action-types";
import type { AdminState } from "@js/types/admin";

const INITIAL_STATE: AdminState = {
  rewardRulesLoading: false,
  rewardRules: {
    count: 0,
    next: null,
    previous: null,
    results: [],
    max_order: 0,
  },
  referralReportLoading: false,
  referralReport: {
    count: 0,
    next: null,
    previous: null,
    results: [],
    users_referring_approved_talents: null,
    users_referring_unapproved_talents: null,
  },
  withdrawalsLoading: false,
  withdrawals: {
    count: 0,
    next: null,
    previous: null,
    owner_wallet_balance: "N/A",
    hot_wallet_token_balance: "N/A",
    results: [],
  },
  fetchingSettleEmployersPaymentData: false,
  settleEmployersPaymentData: {
    invoices: [],
    suggested_invoice_ids: [],
  },
  fetchingSkills: false,
  skills: {
    count: 0,
    next: null,
    previous: null,
    results: [],
  },
  fetchingCompanyNodes: false,
  companyNodes: [],
};

export default (state = INITIAL_STATE, action): AdminState => {
  switch (action.type) {
    case WITHDRAWALS_FETCHING:
      return {
        ...state,
        withdrawalsLoading: true,
        withdrawals: INITIAL_STATE.withdrawals,
      };

    case WITHDRAWALS_SUCCESS:
      return {
        ...state,
        withdrawalsLoading: false,
        withdrawals: action.payload,
      };

    case WITHDRAWALS_FAILED:
      return {
        ...state,
        withdrawalsLoading: false,
        withdrawals: INITIAL_STATE.withdrawals,
      };

    case FETCHING_INVOICES_TO_SETTLE_EMPLOYERS_PAYMENT:
      return {
        ...state,
        fetchingSettleEmployersPaymentData: true,
        settleEmployersPaymentData: INITIAL_STATE.settleEmployersPaymentData,
      };

    case FETCHING_INVOICES_TO_SETTLE_EMPLOYERS_PAYMENT_SUCCESS:
      return {
        ...state,
        fetchingSettleEmployersPaymentData: false,
        settleEmployersPaymentData: action.payload,
      };

    case FETCHING_INVOICES_TO_SETTLE_EMPLOYERS_PAYMENT_FAILED:
      return {
        ...state,
        fetchingSettleEmployersPaymentData: false,
        settleEmployersPaymentData: INITIAL_STATE.settleEmployersPaymentData,
      };

    case FETCHING_COMPANY_NODES:
      return {
        ...state,
        fetchingCompanyNodes: true,
        companyNodes: INITIAL_STATE.companyNodes,
      };

    case FETCHING_COMPANY_NODES_SUCCESS:
      return {
        ...state,
        fetchingCompanyNodes: false,
        companyNodes: action.payload,
      };

    case FETCHING_COMPANY_NODES_FAILED:
      return {
        ...state,
        fetchingCompanyNodes: false,
        companyNodes: INITIAL_STATE.companyNodes,
      };

    default:
      return state;
  }
};
