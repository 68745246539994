import React from "react";
import type { TypedWrappedFieldProps } from "redux-form";

import type { AutocompleteRenderInputParams } from "@hexocean/braintrust-ui-components";

export type ReduxFormAutocompleteFactoryInjectedProps =
  TypedWrappedFieldProps & {
    noErrorOnTouch?: boolean;
    renderInput: (
      params: AutocompleteRenderInputParams,
      state: { isError: boolean; error: string },
    ) => React.ReactNode;
  };

export const ReduxFormAutocompleteFactory = (Component: React.ElementType) => {
  return ({
    input,
    meta: { error, touched, submitFailed },
    noErrorOnTouch,
    multiple,
    renderInput,
    ...props
  }: ReduxFormAutocompleteFactoryInjectedProps & { multiple?: boolean }) => {
    const { onChange, value } = input;

    const _value = multiple ? (Array.isArray(value) ? value : []) : value;
    const isError = Boolean(
      (submitFailed || (!noErrorOnTouch && touched)) && error,
    );

    return (
      <Component
        multiple={multiple}
        onChange={onChange}
        value={_value}
        {...props}
        renderInput={(params) => {
          return renderInput(params, { isError, error });
        }}
      />
    );
  };
};
