import React from "react";
import _ from "underscore";

import { Box } from "@hexocean/braintrust-ui-components";
import { useGetNotificationsSettingsQuery } from "@js/apps/settings/api";
import {
  JobsNotificationsSection,
  NotificationsSection,
} from "@js/apps/settings/components/notifications-section";

const JOBS_GROUP = ENUMS.NotificationGroup.JOBS;

export const ClientNotificationSettings = () => {
  const { data, isLoading } = useGetNotificationsSettingsQuery();

  if (!data || isLoading) {
    return null;
  }

  const notificationsGroupsSettings = _.groupBy(
    data.general_notifications_settings,
    (i) => i.group,
  );

  const jobsNotificationGroup = notificationsGroupsSettings[JOBS_GROUP];

  return (
    <Box mb={5}>
      {jobsNotificationGroup && (
        <JobsNotificationsSection
          key="job"
          title={"Jobs"}
          notifications={jobsNotificationGroup}
        />
      )}
      {Object.keys(_.omit(notificationsGroupsSettings, JOBS_GROUP)).map(
        (group) => {
          return (
            <NotificationsSection
              key={group}
              title={group}
              notifications={notificationsGroupsSettings[group]}
            />
          );
        },
      )}
    </Box>
  );
};
