import React from "react";

import type { EmptyStateProps } from "@js/apps/freelancer/components/my-work-empty-state";
import { MyWorkEmptyState } from "@js/apps/freelancer/components/my-work-empty-state";

type MyOfferEmptyListProps = {
  title?: EmptyStateProps["title"];
  description: EmptyStateProps["description"];
};

export const OfferListEmptyState = ({
  title = "No offers yet",
  description,
}: MyOfferEmptyListProps) => (
  <MyWorkEmptyState
    title={title}
    description={description}
    icon="💬"
    iconBackgroundColor="var(--medium-red)"
  />
);
