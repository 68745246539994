import { SubmissionError } from "redux-form";
import _ from "underscore";

import {
  openAuthBackupCodesModal,
  openOTPDisabledWarningModal,
  OTPAuthBackupCodesModalInstance,
} from "@js/apps/settings/components/otp-auth";
import { Snackbar } from "@js/components/snackbar";
import { useAppSelector } from "@js/hooks";
import { isErrorWithMessage } from "@js/types/errors";
import type { PaymentMethod, StripeACHPayment } from "@js/types/payments";
import { formatErrorForSnackbar } from "@js/utils";

import {
  useCreateStripeACHPaymentMethodMutation,
  useCreateStripeCCPaymentMethodMutation,
  useDeletePaymentMethodMutation,
  useGetPaymentMethodsQuery,
  useSetAsDefaultPaymentMethodMutation,
  useVerifyStripeACHPaymentMethodMutation,
} from "../../api";
import {
  DeletePaymentMethodConfirmModalInstance,
  openDeletePaymentMethodModal,
} from "../../components/delete-payment-method-confirm-modal";
import { openVerifyStripeACHPaymentMethodModal } from "../../components/verify-stripe-ach-payment-method";
import type { VerifyStripeACHFormData } from "../../forms/verify-stripe-ach-payment-method";
import type {
  CreateACHPaymentMethodStripeResult,
  CreateCCPaymentMethodStripeResult,
} from "../../types";

import { AddPaymentModalInstance } from "./add-payment-method-modal";

type UsePaymentContainer = {
  inOfferFlow?: boolean;
};

export const usePaymentContainer = ({ inOfferFlow }: UsePaymentContainer) => {
  const user = useAppSelector((state) => state.auth.user);
  const employerProfile = useAppSelector(
    (state) => state.employer?.employerProfile,
  );

  const {
    can_add_payment_method: canAddPaymentMethod = undefined,
    cannot_add_payment_method_reason: cannotAddPaymentMethodReason = "",
  } = employerProfile || {};

  const { data: paymentMethods, isFetching: fetchingPaymentMethods } =
    useGetPaymentMethodsQuery();

  const [deletePayment] = useDeletePaymentMethodMutation();
  const [setAsDefaultPayment] = useSetAsDefaultPaymentMethodMutation();
  const [verifyStripeACHPayment] = useVerifyStripeACHPaymentMethodMutation();
  const [createStripeCCMethod, { isLoading: creatingCCMethod }] =
    useCreateStripeCCPaymentMethodMutation();
  const [createStripeACHMethod, { isLoading: creatingACHMethod }] =
    useCreateStripeACHPaymentMethodMutation();

  const creatingPaymentMethod = creatingACHMethod || creatingCCMethod;

  const onPaymentMethodDelete = (
    deletePaymentMethod: (paymentMethod: PaymentMethod) => Promise<void>,
    paymentMethod: PaymentMethod,
  ) => {
    openDeletePaymentMethodModal(() =>
      deletePaymentMethod(paymentMethod)
        .catch((error) => {
          if (isErrorWithMessage(error.data)) {
            const errors = error.data._error;
            Snackbar.error(formatErrorForSnackbar(errors));
            return;
          }

          Snackbar.error("Failed to delete payment method.");
        })
        .finally(DeletePaymentMethodConfirmModalInstance.close),
    );
  };

  const deletePaymentMethod = async (paymentMethod: PaymentMethod) => {
    return deletePayment(paymentMethod.id).unwrap();
  };

  const verifyStripeACHPaymentMethod = async (
    paymentMethod: PaymentMethod,
    values: VerifyStripeACHFormData,
  ) => {
    return verifyStripeACHPayment({
      paymentMethodId: paymentMethod.method.id,
      values,
    })
      .unwrap()
      .catch((error) => {
        throw new SubmissionError(error.data);
      });
  };

  const onVerifyStripeACHPaymentMethod = (paymentMethod: StripeACHPayment) => {
    openVerifyStripeACHPaymentMethodModal({
      paymentMethod,
      onSubmit: verifyStripeACHPaymentMethod,
    });
  };

  const createStripePaymentMethod = (
    stripePaymentMethod: CreateCCPaymentMethodStripeResult,
  ) => {
    if (!stripePaymentMethod.card) return;

    return createStripeCCMethod({
      payment_method_id: stripePaymentMethod.id,
      last4: stripePaymentMethod.card.last4,
      brand: stripePaymentMethod.card.brand,
      exp_month: stripePaymentMethod.card.exp_month,
      exp_year: stripePaymentMethod.card.exp_year,
    })
      .unwrap()
      .catch((error) => {
        Snackbar.error(
          error.data._error || "Failed to add payment method, please try again",
        );
      });
  };

  const createStripeACHPaymentMethod = async (
    stripePaymentMethod: CreateACHPaymentMethodStripeResult,
  ) => {
    if (!stripePaymentMethod.bank_account) return;

    return createStripeACHMethod({
      data: {
        ..._.omit(stripePaymentMethod, "id", "bank_account"),
        bank_account_token: stripePaymentMethod.id,
        bank_account_id: stripePaymentMethod.bank_account.id,
        bank_name: stripePaymentMethod.bank_account.bank_name,
      },
      in_offer_flow: inOfferFlow,
    }).unwrap();
  };

  const setAsDefaultPaymentMethod = async (paymentMethod: PaymentMethod) => {
    try {
      return await setAsDefaultPayment(paymentMethod.id).unwrap();
    } catch {
      Snackbar.error("Failed to set payment method as default.");
    }
  };

  const OnSuccessOTPEnable = (backupCodes: string[]) => {
    openAuthBackupCodesModal(backupCodes, {
      onClickContinueAction: () => {
        OTPAuthBackupCodesModalInstance.close();
        AddPaymentModalInstance.open();
      },
    });
  };

  const addPaymentMethod = () =>
    user?.is_otp_enabled
      ? AddPaymentModalInstance.open()
      : openOTPDisabledWarningModal({
          onSuccessAction: OnSuccessOTPEnable,
        });

  return {
    fetchingPaymentMethods,
    paymentMethods: paymentMethods || [],
    creatingPaymentMethod,
    onPaymentMethodDelete,
    onVerifyStripeACHPaymentMethod,
    createStripePaymentMethod,
    createStripeACHPaymentMethod,
    deletePaymentMethod,
    setAsDefaultPaymentMethod,
    addPaymentMethod,
    canAddPaymentMethod,
    cannotAddPaymentMethodReason,
  };
};
