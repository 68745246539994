import React from "react";

import { Box } from "@hexocean/braintrust-ui-components";
import { TestimonialsCarousel } from "@js/apps/onboarding/views/layout/user-comments-carousel";
import { Logo } from "@js/components/logo";
import { CoreLayout } from "@js/layouts/core";

type AuthSignInLayoutProps = {
  children: React.ReactNode;
  pageTitle?: string;
};

export const AuthSignInLayout = ({
  children,
  pageTitle,
}: AuthSignInLayoutProps) => {
  return (
    <CoreLayout pageTitle={pageTitle}>
      <div className="auth-sign-in">
        <div className="auth-sign-in__left">
          <div className="auth-sign-in__left__content">
            <Box textAlign="center" mb={7}>
              <Logo alt="Braintrust" />
            </Box>
            {children}
          </div>
        </div>

        <TestimonialsCarousel flow="sign-in" />
      </div>
    </CoreLayout>
  );
};
