import { useMemo } from "react";

import type { Invoice } from "@js/types/invoices";

type UseMarkAsPaidActionProps = {
  invoices: Pick<Invoice, "id" | "can_be_marked_as_paid">[];
  markAsPaid: (ids: number[]) => void;
};

export const useMarkAsPaidAction = ({
  invoices,
  markAsPaid,
}: UseMarkAsPaidActionProps) => {
  const canBeMarkedAsPaid = useMemo(() => {
    if (!invoices?.length) {
      return false;
    }

    return invoices.every((invoice) => invoice.can_be_marked_as_paid);
  }, [invoices]);

  const onConfirm = () => {
    const invoicesIds = invoices.map(({ id }) => id);
    markAsPaid(invoicesIds);
  };

  return { canBeMarkedAsPaid, onConfirm };
};
