import React from "react";
import { Field } from "redux-form";

import { useVoteOnPollOptionMutation } from "@js/apps/give-and-get-help/api";
import { PollField } from "@js/apps/give-and-get-help/forms/fields/poll-field";
import { createFormInstance } from "@js/forms/components";
import type { PollOption } from "@js/types/give-and-get-help";

const POLL_FORM_BASE_ID = "answer_poll_form";

const PollForm = createFormInstance<{ poll_answer: number }, unknown>(
  POLL_FORM_BASE_ID,
);

type PollFormComponentProps = {
  options: PollOption[];
  votes_count: number | null;
  postId: number;
  disablePoll?: boolean;
  resultsShown?: boolean;
};

export const PollFormComponent = ({
  postId,
  options,
  disablePoll,
  votes_count,
  resultsShown,
}: PollFormComponentProps) => {
  const [voteOnOption] = useVoteOnPollOptionMutation();
  const formId = `${POLL_FORM_BASE_ID}--post-${postId}`;

  return (
    <PollForm
      form={formId}
      onChange={(_values, _dispatch, { submit, submitSucceeded }) => {
        if (submit && !submitSucceeded) {
          submit();
        }
      }}
      onSubmit={(values) => {
        voteOnOption({ optionId: values.poll_answer, postId });
      }}
    >
      <Field
        name="poll_answer"
        options={options}
        votes_count={votes_count}
        resultsShown={resultsShown}
        component={PollField}
        disablePoll={disablePoll}
      />
    </PollForm>
  );
};
