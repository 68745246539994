import React from "react";

import {
  Box,
  Button,
  Stack,
  Typography,
  useMediaQuery,
} from "@hexocean/braintrust-ui-components";
import { ModalInstance } from "@js/components/modal";
import { Snackbar } from "@js/components/snackbar";
import { useAppDispatch } from "@js/hooks";
import type { ATSIntegration } from "@js/types/employer";

import { clickDisconnectATS, fetchEmployerProfile } from "../../actions";
import { useDeleteATSIntegrationMutation } from "../../api";

import styles from "./styles.module.scss";

type DisconnectATSIntegrationModalProps = { integration: ATSIntegration };

export const DisconnectATSIntegrationModal = ({
  integration,
}: DisconnectATSIntegrationModalProps) => {
  const dispatch = useAppDispatch();
  const [deleteATSIntegration, { isLoading }] =
    useDeleteATSIntegrationMutation();

  const handleCancelClick = () => {
    closeDisconnectATSIntegrationModal();
  };

  const handleDisconnectClick = () => {
    deleteATSIntegration({ id: integration.id })
      .unwrap()
      .then(async () => {
        dispatch(clickDisconnectATS());
        dispatch(fetchEmployerProfile());
        closeDisconnectATSIntegrationModal();
      })
      .catch(() => {
        Snackbar.error(
          "Something went wrong, please try again or contact with support team.",
        );
      });
  };

  return (
    <DisconnectATSIntegrationModalContent
      integration={integration}
      onDisconnect={handleDisconnectClick}
      onCancel={handleCancelClick}
      isLoading={isLoading}
    />
  );
};

export type DisconnectATSIntegrationModalContentProps = {
  integration: ATSIntegration;
  onDisconnect: () => void;
  onCancel: () => void;
  isLoading: boolean;
};

export const DisconnectATSIntegrationModalContent = ({
  integration,
  onDisconnect,
  onCancel,
  isLoading,
}: DisconnectATSIntegrationModalContentProps) => {
  const isMobile = useMediaQuery("sm");
  const btnSize = isMobile ? "small" : "medium";

  return (
    <Box>
      <Typography
        component="p"
        variant="title"
        size="small"
        sx={{ mb: 1 }}
        fontWeight={400}
      >
        Are you sure you want to disconnect from {integration.integration}?
      </Typography>
      <Typography
        component="p"
        variant="paragraph"
        size="medium"
        sx={{ mb: { xs: 3, md: 4 } }}
      >
        Jobs from {integration.integration} will no longer be added to
        Braintrust
      </Typography>
      <Stack direction="row" sx={{ justifyContent: "end", gap: 1 }}>
        <Button
          size={btnSize}
          shape="squared"
          variant="black-outlined"
          onClick={onCancel}
        >
          Cancel
        </Button>
        <Button
          size={btnSize}
          shape="squared"
          variant="destructive"
          onClick={onDisconnect}
          disabled={isLoading}
        >
          Yes, disconnect
        </Button>
      </Stack>
    </Box>
  );
};

export const openDisconnectATSIntegrationModal = (
  arg: DisconnectATSIntegrationModalProps,
) => {
  ModalInstance.close();
  ModalInstance.open({
    closeButton: true,
    padding: false,
    closeButtonProps: {
      variant: "tertiary",
      size: "small",
      className: styles.closeButton,
    },
    children: <DisconnectATSIntegrationModal {...arg} />,
    className: styles.modal,
  });
};

export const closeDisconnectATSIntegrationModal = () => {
  ModalInstance.close();
};
