import { useEffect, useMemo, useState } from "react";
import type { WrappedFieldProps } from "redux-form";

import type { ComboBoxProps } from "@hexocean/braintrust-ui-components/";
import { ComboBox } from "@hexocean/braintrust-ui-components/";
import { TextField } from "@hexocean/braintrust-ui-components/components/Input/";

import type {
  EmployerInvoicesPayeeSearchParams,
  EmployerInvoicesPayeeSearchResult,
} from "../../api";
import {
  useGetEmployerInvoicesPayeeQuery,
  useGetTalentInitialItemQuery,
} from "../../api";
import { useSearchPhrase } from "../../hooks/search-phrase";
import { useSynchronizeSingleValueInternalStateWithFormValue } from "../../hooks/synchronize-with-form-value";

type EmployerInvoicePayeeComboboxFieldProps = WrappedFieldProps &
  Partial<
    Omit<ComboBoxProps<EmployerInvoicesPayeeSearchResult, false>, "component">
  > & {
    label?: string;
    endpointParams?: Partial<EmployerInvoicesPayeeSearchParams>;
  };

export const EmployerInvoicePayeeSearchComboboxField = ({
  input,
  meta,
  label,
  endpointParams,
  ...props
}: EmployerInvoicePayeeComboboxFieldProps) => {
  const [value, setValue] = useState<EmployerInvoicesPayeeSearchResult | null>(
    null,
  );
  const { onInputChange, searchPhrase } = useSearchPhrase();

  const { data: fetchedOptions } = useGetEmployerInvoicesPayeeQuery({
    search: searchPhrase,
    ...endpointParams,
  });

  const options = useMemo(() => {
    return [{ id: -1, payee_name: "Braintrust" }, ...(fetchedOptions || [])];
  }, [fetchedOptions]);

  const { data: initialItem, isLoading: initialTaxonomyLoading } =
    useGetTalentInitialItemQuery(Number(meta.initial), {
      skip: meta.dirty || !meta.initial || !!value || meta.initial === -1,
    });

  useSynchronizeSingleValueInternalStateWithFormValue({
    initialTaxonomyLoading,
    formValue: input.value,
    isValueEqualFormValue: input.value === value?.id,
    findOption: (option) => {
      return option.id === input.value;
    },
    setValue,
    options,
  });

  useEffect(() => {
    if (initialItem) {
      setValue({
        id: initialItem.id,
        payee_name: initialItem.name,
      });
    }
  }, [initialItem]);

  return (
    <ComboBox<EmployerInvoicesPayeeSearchResult, false>
      initialTaxonomiesLoading={initialTaxonomyLoading}
      options={options}
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            label={label || "Payee Name"}
            error={!!meta.error}
            helperText={meta.error}
          />
        );
      }}
      onInputChange={onInputChange}
      value={value}
      onChange={(_ev, valueArg) => {
        setValue(valueArg);
        input.onChange(valueArg?.id || null);
      }}
      getOptionLabel={(option) => option.payee_name}
      {...props}
    />
  );
};
