import { useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";

import { API } from "@js/api";
import { useUser } from "@js/apps/common/hooks";
import { useIsEmployerUserVerified } from "@js/apps/common/hooks/is-employer-verified";
import { useGetJobDraftQuery } from "@js/apps/jobs/api";
import type { CreateJobResponse } from "@js/apps/jobs/apps/create-job/actions";
import { createJob } from "@js/apps/jobs/apps/create-job/actions";
import { useHandleFailedSubmission } from "@js/apps/jobs/apps/create-job/hooks/handle-failed-submission";
import {
  getDraftInitialValues,
  prepareCreateJobSubmitFormValues,
} from "@js/apps/jobs/apps/create-job/utils";
import { ModalInstance } from "@js/components/modal";
import { Snackbar } from "@js/components/snackbar";
import { useAppDispatch } from "@js/hooks";
import type { Job, JobFormValues } from "@js/types/jobs";

import { setCurrentPostedATSJobId } from "../../components/ats-integration-modal/ats-jobs-slice";

type UseReviewDraftJobArgs = {
  draftId: number | undefined;
  isLastJobToPublish?: boolean;
};

type UseReviewDraftJobReturn = {
  onSubmit: (values: JobFormValues) => Promise<CreateJobResponse>;
  onSubmitSuccess: (response: Job) => void;
  onSubmitFail: (errors: { [key: string]: string[] }) => void;
  initialValues: Partial<JobFormValues>;
};

export const useReviewDraftJob = ({
  draftId,
  isLastJobToPublish,
}: UseReviewDraftJobArgs): UseReviewDraftJobReturn => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const user = useUser();
  const userId = user?.id;
  const isEmployerVerified = useIsEmployerUserVerified();
  const onSubmitFail = useHandleFailedSubmission(draftId);
  const { data: draft } = useGetJobDraftQuery(
    { id: draftId as number },
    { skip: !draftId },
  );

  const handleJobSubmit = useCallback(
    async (values: JobFormValues) => {
      const data = prepareCreateJobSubmitFormValues(values);
      const shouldCreateDraft = !isEmployerVerified;
      if (!shouldCreateDraft) {
        return dispatch(
          createJob({
            data,
            dry_run: false,
            public_after_verification: false,
          }),
        );
      }

      return dispatch(
        createJob({
          data,
          dry_run: true,
          public_after_verification: true,
        }),
      );
    },
    [dispatch, isEmployerVerified],
  );

  const onSubmit = useCallback(
    async (values: JobFormValues) => {
      return await handleJobSubmit(values);
    },
    [handleJobSubmit],
  );

  const onSubmitSuccess = useCallback(
    (response: Job) => {
      {
        if (!isEmployerVerified) {
          navigate("/employer/dashboard/my_jobs/");
          Snackbar.toast({
            header: "Your account is not verified",
            buttonText: "Got it",
            content: `Your job offer has been saved as a draft, to make it public,
                    verify your email address by clicking the verification link in
                    the email sent to the address you provided during
                    registration.`,
          });
          return;
        }

        dispatch(API.util.invalidateTags([{ type: "JobDrafts", id: "LIST" }]));
        dispatch(API.util.invalidateTags(["OwnJobs"]));

        if (isLastJobToPublish) {
          ModalInstance.close();
          navigate(`/jobs/${response.id}/invite_talent/`);

          return;
        }

        dispatch(setCurrentPostedATSJobId(response.id));
        return;
      }
    },
    [dispatch, isEmployerVerified, isLastJobToPublish, navigate],
  );

  const initialValues = useMemo(() => {
    if (!userId) {
      return {};
    }

    return getDraftInitialValues({ draft: draft || {}, userId });
  }, [draft, userId]);

  return {
    onSubmit,
    onSubmitSuccess,
    onSubmitFail,
    initialValues,
  };
};
