import { Field, reduxForm } from "redux-form";

import { Typography } from "@hexocean/braintrust-ui-components";
import { ModalConfirm, ModalInstance } from "@js/components/modal";
import { Form } from "@js/forms/components/form";
import { ButtonSelectField } from "@js/forms/fields";
import { enumToOptions } from "@js/utils";

import { patchJob } from "../../apps/create-job/actions";

const CHANGE_SUPPORT_TYPE = "change-support-type";

export const openJobFillSupportTypeModal = (job) => {
  ModalInstance.open({
    children: (
      <JobSupportTypeFormModal
        onCancel={ModalInstance.close}
        onSubmit={(values) => {
          const { jobId, job_fill_support_type } = values;

          return patchJob(jobId, { job_fill_support_type });
        }}
        onSubmitSuccess={() => {
          ModalInstance.close();
          window.location.reload();
        }}
        initialValues={{
          jobId: job.id,
          job_fill_support_type: job.job_fill_support_type,
        }}
      />
    ),
    closeButton: true,
    className: "add-support-type-modal",
  });
};

type FormData = {
  jobId: number;
  job_fill_support_type: EnumType<typeof ENUMS.JobFillSupportType>;
};

type JobSupportTypeOwnProps = {
  onCancel: () => void;
};

const JobSupportTypeFormModal = reduxForm<FormData, JobSupportTypeOwnProps>({
  form: CHANGE_SUPPORT_TYPE,
})(({ error, submit, submitting, onCancel }) => {
  return (
    <Form onSubmit={submitting ? null : submit} error={error}>
      <ModalConfirm confirmText="Save" onConfirm={submit} onCancel={onCancel}>
        <Typography mb={3} component="p" variant="title" size="small">
          Change Job Fill Support Type
        </Typography>
        <Field
          name="job_fill_support_type"
          options={enumToOptions(ENUMS.JobFillSupportTypeLabels)}
          variant="white-violet"
          shape="squared"
          multiple={false}
          component={ButtonSelectField}
        />
      </ModalConfirm>
    </Form>
  );
});
