import { TableCell, TableRow } from "@hexocean/braintrust-ui-components";
import type { InvoiceFiltersFetchParams } from "@js/apps/invoices/filters";
import { TableSortLabel } from "@js/components/table";

const SORTABLE_CELLS = [
  { key: 1, align: "left", orderBy: "employer_name", label: "Paying" },
  { key: 2, align: "center", orderBy: "number", label: "Invoice Number" },
  { key: 3, align: "right", orderBy: "issued_at", label: "Date" },
  { key: 4, align: "right", orderBy: "due_date", label: "Due" },
  { key: 5, align: "right", orderBy: "_gross_total", label: "Amount" },
] as const;

export type FreelancerInvoiceListingTableHeadProps = {
  onSubmit: (filters: Partial<InvoiceFiltersFetchParams>) => void;
  filters: Partial<InvoiceFiltersFetchParams>;
  onlyMobileMenu?: boolean;
};

export const FreelancerInvoiceListingTableHead = ({
  filters,
  onlyMobileMenu,
  onSubmit,
}: FreelancerInvoiceListingTableHeadProps) => {
  return (
    <TableRow>
      <TableCell>Status</TableCell>
      {SORTABLE_CELLS.map(({ key, align, ...rest }) => (
        <TableCell key={key} align={align}>
          <TableSortLabel
            key={key}
            onChange={onSubmit}
            filters={filters}
            {...rest}
          />
        </TableCell>
      ))}
      {!onlyMobileMenu && (
        <TableCell
          className="invoices-listing-row-actions"
          width="100"
          align="center"
        >
          Actions
        </TableCell>
      )}
    </TableRow>
  );
};
