import BTRST_PRODUCTION from "./production/BTRST.json";
import TIMELOCK_PRODUCTION from "./production/Timelock.json";
import BTRST_STAGING from "./staging/BTRST.json";
import TIMELOCK_STAGING from "./staging/Timelock.json";

const ABI = {
  production: {
    BTRST: BTRST_PRODUCTION,
    TIMELOCK: TIMELOCK_PRODUCTION,
  },
  staging: {
    BTRST: BTRST_STAGING,
    TIMELOCK: TIMELOCK_STAGING,
  },
};

export const BTRST = ABI[SETTINGS.VOTING_ABI_DEPLOYMENT].BTRST;
export const TIMELOCK = ABI[SETTINGS.VOTING_ABI_DEPLOYMENT].TIMELOCK;
