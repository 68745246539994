import { BidTabLoader } from "@js/apps/jobs/apps/bids/components/bid-application-panel/tabs/bid-tab-loader";
import type { EmployerBidListBid } from "@js/types/jobs";

import { MessagesBox, TextBox } from "../../components";
import { MessagesProvider } from "../../context";
import { useGetOrCreateRoom } from "../../hooks";

export const JobMessenger = ({ bid }: { bid: EmployerBidListBid }) => {
  const receiverUserId = bid.freelancer.user.id;
  const { isLoading, room } = useGetOrCreateRoom({ receiverUserId });

  if (isLoading || !room) {
    return <BidTabLoader />;
  }

  return (
    <div className="generic-messenger generic-messenger--block generic-messenger--job-messenger">
      <div className="generic-messenger__content" style={{ padding: 0 }}>
        <MessagesProvider>
          <MessagesBox room={room} />
          <TextBox room={room} />
        </MessagesProvider>
      </div>
    </div>
  );
};
