import { SubmissionError } from "redux-form";
import type { AxiosError, AxiosResponse } from "axios";
import axios from "axios";

import { Snackbar } from "@js/components/snackbar";
import type { AppThunkAction } from "@js/store";
import type { CompanyNode } from "@js/types/common";

import * as actionTypes from "./action-types";

const checkStatusAndShowPaymentProviderErrorSnackbar = <
  Provider extends "Stripe" | "Wise",
>(
  error: AxiosError,
  provider: Provider,
) => {
  if (error.response && error.response.status >= 500) {
    Snackbar.error(`${provider} is temporarily down, please try again later.`);
  }
};

export const fetchTransferWiseCurrencies =
  (): AppThunkAction<Promise<any>> => (dispatch) =>
    new Promise((resolve) => {
      dispatch({
        type: actionTypes.FETCH_TRANSFERWISE_CURRENCIES,
      });

      return axios
        .get(`/api/transferwise_accounts/currencies/`)
        .then((response) => {
          dispatch({
            type: actionTypes.FETCH_TRANSFERWISE_CURRENCIES_SUCCESS,
            payload: response.data,
          });

          resolve(response.data);
        })
        .catch((error) => {
          checkStatusAndShowPaymentProviderErrorSnackbar(error, "Wise");

          dispatch({
            type: actionTypes.FETCH_TRANSFERWISE_CURRENCIES_FAILED,
          });
        });
    });

export const fetchWithdrawalMethods =
  (): AppThunkAction<Promise<any>> => (dispatch) =>
    new Promise((resolve) => {
      dispatch({
        type: actionTypes.FETCH_WITHDRAWAL_METHODS,
      });

      return axios
        .get(`/api/withdrawal_methods/`)
        .then((response) => {
          dispatch({
            type: actionTypes.FETCH_WITHDRAWAL_METHODS_SUCCESS,
            payload: response.data,
          });

          resolve(response.data);
        })
        .catch(() =>
          dispatch({
            type: actionTypes.FETCH_WITHDRAWAL_METHODS_FAILED,
          }),
        );
    });

export const fetchCurrencyRequirementsClear = () => ({
  type: actionTypes.FETCH_WITHDRAWAL_METHODS_OTHER_REQUIREMENTS_CLEAR,
});

export const fetchCurrencyRequirements =
  (currency: string): AppThunkAction<Promise<any>> =>
  (dispatch) =>
    new Promise((resolve, reject) => {
      dispatch({
        type: actionTypes.FETCH_WITHDRAWAL_METHODS_CURRENCY_REQUIREMENTS,
      });

      return axios
        .get(`/api/transferwise_accounts/requirements/?currency=${currency}`)
        .then((response: AxiosResponse) => {
          dispatch({
            type: actionTypes.FETCH_WITHDRAWAL_METHODS_CURRENCY_REQUIREMENTS_SUCCESS,
            payload: response.data,
          });

          resolve(response.data);
        })
        .catch((error: AxiosError<Record<string, any>>) => {
          dispatch({
            type: actionTypes.FETCH_WITHDRAWAL_METHODS_CURRENCY_REQUIREMENTS_FAILED,
          });

          if (error.response) {
            return reject(new SubmissionError(error.response.data));
          }

          reject(
            new SubmissionError({
              _error: "There was a problem with this request",
            }),
          );
        });
    });

export const fetchOtherRequirements =
  (data: Record<string, any>): AppThunkAction<Promise<Record<string, any>>> =>
  (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: actionTypes.FETCH_WITHDRAWAL_METHODS_OTHER_REQUIREMENTS,
      });

      const source = axios.CancelToken.source();

      return Object.assign(
        axios
          .post(`/api/transferwise_accounts/requirements/`, data, {
            cancelToken: source.token,
          })
          .then((response: AxiosResponse<Record<string, any>>) => {
            dispatch({
              type: actionTypes.FETCH_WITHDRAWAL_METHODS_OTHER_REQUIREMENTS_SUCCESS,
              payload: response.data,
            });

            resolve(response.data);
          })
          .catch((error: AxiosError<Record<string, any>>) => {
            if (error.response) {
              checkStatusAndShowPaymentProviderErrorSnackbar(error, "Wise");
              return reject(error.response.data);
            }

            reject({ _error: "There was a problem with this request" });
          }),
        { source },
      );
    });
  };

export const createTransferWiseWithdrawalMethod =
  (withdrawalMethod): AppThunkAction<Promise<any>> =>
  (dispatch) =>
    new Promise((resolve, reject) => {
      dispatch({
        type: actionTypes.CREATE_WITHDRAWAL_METHOD,
      });

      return axios
        .post(`/api/transferwise_accounts/`, withdrawalMethod)
        .then((response) => {
          dispatch({
            type: actionTypes.CREATE_WITHDRAWAL_METHOD_SUCCESS,
            payload: response.data,
          });

          Snackbar.info(
            "Please check your inbox to confirm new bank account information.",
          );
          resolve(response.data);
        })
        .catch((error) => {
          const { data } = error.response;

          checkStatusAndShowPaymentProviderErrorSnackbar(error, "Wise");

          dispatch({
            type: actionTypes.CREATE_WITHDRAWAL_METHOD_FAILED,
          });

          reject(new SubmissionError(data));
        });
    });

export const createStripeWithdrawalMethod =
  (): AppThunkAction<Promise<any>> => (dispatch) =>
    new Promise((resolve, reject) => {
      dispatch({
        type: actionTypes.CREATE_WITHDRAWAL_METHOD,
      });

      return axios
        .post(`/api/stripe_accounts/`)
        .then((response) => {
          dispatch({
            type: actionTypes.CREATE_WITHDRAWAL_METHOD_SUCCESS,
            payload: response.data,
          });

          Snackbar.info(
            "Please check your inbox to confirm new withdrawal method.",
          );
          resolve(response.data);
        })
        .catch((error) => {
          const { data } = error.response;
          checkStatusAndShowPaymentProviderErrorSnackbar(error, "Stripe");
          dispatch({
            type: actionTypes.CREATE_WITHDRAWAL_METHOD_FAILED,
            payload: data,
          });
          reject(new SubmissionError(data));
        });
    });

export const addStripeMethodRecipients =
  (companyNodes: CompanyNode): AppThunkAction<Promise<any>> =>
  (dispatch) => {
    dispatch({
      type: actionTypes.ADD_STRIPE_RECIPIENTS,
    });

    return axios
      .post(`/api/stripe_recipients/`, companyNodes)
      .then((response) => {
        dispatch({
          type: actionTypes.ADD_STRIPE_RECIPIENTS_SUCCESS,
          payload: response.data,
        });

        Snackbar.success("Company node added successfully.");
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.ADD_STRIPE_RECIPIENTS_FAILED,
        });

        throw error;
      });
  };

export const deleteRecipient = (companyNodeId: number, code: string) =>
  axios
    .delete(`/api/stripe_recipients/${companyNodeId}`, {
      data: { code },
    })
    .then(() => {
      Snackbar.success("Company node has been successfully removed.");
    });

export const fetchFreelancerCompanyNodes =
  (): AppThunkAction<Promise<any>> => (dispatch) =>
    new Promise((resolve) => {
      dispatch({
        type: actionTypes.FETCH_COMPANY_NODES,
      });

      return axios
        .get(`/api/freelancer_company_nodes/`)
        .then((response) => {
          dispatch({
            type: actionTypes.FETCH_COMPANY_NODES_SUCCESS,
            payload: response.data,
          });

          resolve(response.data);
        })
        .catch(() =>
          dispatch({
            type: actionTypes.FETCH_COMPANY_NODES_FAILED,
          }),
        );
    });

export const deleteWithdrawalMethod = (withdrawalMethodId: number, twoFa) => {
  return axios.delete(`/api/withdrawal_methods/${withdrawalMethodId}/`, {
    data: { code: twoFa.code, is_backup_code: twoFa.is_backup_code },
  });
};

export const setAsDefaultWithdrawalMethod = (
  withdrawalMethodId: number,
  twoFa: {
    code: string;
    is_backup_code: boolean;
  },
) =>
  axios
    .post(
      `/api/withdrawal_methods/${withdrawalMethodId}/prepare_to_set_as_default/`,
      { code: twoFa.code, is_backup_code: twoFa.is_backup_code },
    )
    .then(() =>
      Snackbar.success(
        "An email with a confirmation link has been sent to your email address.",
      ),
    );

export const resendConfirmAddWithdrawalMethod = () =>
  new Promise(() =>
    axios
      .post(`/api/stripe_accounts/resend_confirmation_email/`)
      .then(() =>
        Snackbar.success(
          "An email with a confirmation link has been sent to your email address.",
        ),
      )
      .catch((error) =>
        Snackbar.error(
          error.response.data || "Failed to resend confirmation email",
        ),
      ),
  );

export const addRecipientOnboardingLink = ({
  id,
  link,
}: {
  id: string;
  link: string;
}) => ({
  type: actionTypes.ADD_STRIPE_RECIPIENTS_ONBOARDING_LINK,
  payload: {
    id,
    link,
  },
});

export const removeRecipientOnboardingLink = (id: string) => ({
  type: actionTypes.REMOVE_STRIPE_RECIPIENTS_ONBOARDING_LINK,
  payload: id,
});
