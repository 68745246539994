import type { ReactNode } from "react";
import cs from "classnames";

import type {
  BoxProps,
  TypographyProps,
} from "@hexocean/braintrust-ui-components";
import {
  Box,
  Typography,
  useMediaQuery,
} from "@hexocean/braintrust-ui-components";
import { BraintrustIcon } from "@hexocean/braintrust-ui-components/Icons";
import { Emoji } from "@js/components/emoji";
import type {
  IPost as PostProps,
  PostCategory,
} from "@js/types/give-and-get-help";
import { formatCurrency } from "@js/utils";
import { matchFontColorToBackgroundColor } from "@js/utils/match-color";

import type { FormatBudgetConfig } from "../../utils";
import { formatBudget } from "../../utils";
import { getCategoryLabel } from "../category-and-budget-utils";

type PostCategoryBadgeProps = {
  category: Omit<PostCategory, "budget_lower" | "budget_higher"> | null;
  budget?: PostProps["budget"];
  withoutName?: boolean;
  nameProps?: Partial<TypographyProps>;
  padding?: BoxProps["padding"];
};

export const PostCategoryBadge = ({
  category,
  budget,
  withoutName = false,
  nameProps,
  padding,
}: PostCategoryBadgeProps) => {
  if (!category) {
    return null;
  }

  const categoryFontColor = matchFontColorToBackgroundColor(category.color);
  const classNames = cs("post-category-badge", {
    "post-category-badge--budget": budget,
  });
  return (
    <Box
      className={classNames}
      padding={!!padding ? padding : "8px 16px"}
      borderRadius={2}
      bgcolor={`var(${category.color})`}
      color={`var(${categoryFontColor})`}
    >
      <Typography
        component="p"
        size="small"
        className="post-category-badge__label"
      >
        {getCategoryLabel(category, withoutName, nameProps)}
        {budget && (
          <Typography
            component="span"
            color="inherit"
            fontWeight={500}
            className="post-category-badge__budget"
          >
            {budget === "0.00" ? "Free" : getBudgetLabel(budget)}
          </Typography>
        )}
      </Typography>
    </Box>
  );
};

export const getBudgetLabel = (budget: PostProps["budget"]) => {
  return (
    <>
      {formatCurrency(budget, { removeDecimal: true })} in{" "}
      <BraintrustIcon
        style={{
          fontSize: "inherit",
          verticalAlign: "text-bottom",
        }}
      />{" "}
      BTRST
    </>
  );
};

type PostBudgetBadgeProps = {
  budget: PostProps["budget"];
};

export const PostBudgetBadge = ({ budget }: PostBudgetBadgeProps) => {
  return (
    <Box display="flex" alignItems="center" whiteSpace="nowrap">
      <Emoji emoji="🔥" />
      <Typography
        component="span"
        color="inherit"
        fontWeight={400}
        size="small"
        pl={1}
      >
        Asking for 1:1 help
      </Typography>
      {Number(budget) !== 0 && (
        <Box paddingLeft={0.5}>
          <BudgetLabel budget={budget} size="small" />
        </Box>
      )}
    </Box>
  );
};

type CategoryBudgetBadgeContainerProps = {
  categoryColor: EnumType<typeof ENUMS.PostCategoryColor>;
  children: ReactNode;
} & BoxProps;

export const CategoryBudgetBadgeContainer = ({
  categoryColor,
  children,
  ...boxProps
}: CategoryBudgetBadgeContainerProps) => {
  const fontColor = matchFontColorToBackgroundColor(categoryColor);
  return (
    <Box
      className="category-and-budget"
      bgcolor={`var(${categoryColor})`}
      color={`var(${fontColor})`}
      data-testid="category-budget-badge"
      {...boxProps}
    >
      {children}
    </Box>
  );
};

type CategoryNameProps = {
  category: PostCategory;
  nameProps?: Partial<TypographyProps>;
};

export const CategoryName = ({ category, nameProps }: CategoryNameProps) => {
  const isMobile = useMediaQuery(750);

  return (
    <Box className="post-category-name">
      {getCategoryLabel(category, false, {
        size: isMobile ? "small" : "medium",
        ...nameProps,
      })}
    </Box>
  );
};

type BudgetLabelProps = FormatBudgetConfig & Partial<TypographyProps>;

export const BudgetLabel = ({
  budget,
  truncateBudget,
  hideFreeLabel,
  ...rest
}: BudgetLabelProps) => {
  const isFree = Number(budget) === 0;
  const budgetLabel = formatBudget({
    budget,
    truncateBudget,
    hideFreeLabel,
  });

  return (
    <Typography component="span" color="inherit" fontWeight={500} {...rest}>
      {budgetLabel}{" "}
      {!isFree && (
        <>
          in{" "}
          <BraintrustIcon
            style={{
              fontSize: "inherit",
              verticalAlign: "text-bottom",
            }}
          />{" "}
          BTRST
        </>
      )}
    </Typography>
  );
};
