import React from "react";
import { useParams } from "react-router-dom";

import { useAppDispatch, useAppSelector, useNavigate } from "@js/hooks";

import { resetPassword } from "../actions";
import { AuthComponent } from "../components/auth-component";
import { ResetPasswordForm } from "../forms";

import { AuthSignInLayout } from "./layout/sign-in-layout";

export type ResetPasswordProps = {
  values: {
    new_password1: string;
  };
  uid: string;
  token: string;
};

const ResetPasswordPage = (): JSX.Element => {
  const { uid, token } = useParams();
  const resetPasswordMessage = useAppSelector(
    (state) => state.auth.resetPasswordMessage,
  );

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const onSubmit = (props: ResetPasswordProps): Promise<void> => {
    return dispatch(resetPassword(props)).then(() =>
      navigate("/auth/login/?message=password_reset"),
    );
  };

  return (
    <AuthSignInLayout pageTitle="New password">
      <AuthComponent
        title="Set your new password"
        titleSize="medium"
        messageCode={resetPasswordMessage ?? undefined}
      >
        {uid && token && (
          <ResetPasswordForm
            onSubmit={(values) =>
              onSubmit({
                values,
                token,
                uid,
              })
            }
          />
        )}
      </AuthComponent>
    </AuthSignInLayout>
  );
};

export default ResetPasswordPage;
