import type { ReactNode } from "react";

import type { BoxProps } from "@hexocean/braintrust-ui-components";
import { Typography } from "@hexocean/braintrust-ui-components";
import { Loader, Stack } from "@hexocean/braintrust-ui-components";
import { Box } from "@hexocean/braintrust-ui-components";

export type ConnectorContainerProps = BoxProps & {
  title: ReactNode;
  isLoading?: boolean;
};

export const ConnectorContainer = ({
  children,
  isLoading,
  title,
  ...rest
}: ConnectorContainerProps) => {
  return (
    <Box
      {...rest}
      sx={{
        px: { xs: 2, sm: 4, md: 8 },
        py: { xs: 3, sm: 4, md: 6.5 },
        backgroundColor: "var(--off-white)",
        borderRadius: 2,
        ...rest?.sx,
      }}
    >
      <Stack direction="row" gap={2}>
        <Typography
          component="h2"
          variant="title"
          size="small"
          fontWeight={400}
        >
          {title}
        </Typography>
        {isLoading && (
          <Box sx={{ position: "relative", minWidth: "2rem", mt: "2px" }}>
            <Loader centered size={25} />
          </Box>
        )}
      </Stack>
      {children}
    </Box>
  );
};
