import React, { useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import type { TypedWrappedFieldProps } from "redux-form";
import { Fields } from "redux-form";

import {
  Box,
  Checkbox,
  Loader,
  PaperWithVioletOptions,
  StyleAutocompleteMediumVioletChips,
  Typography,
} from "@hexocean/braintrust-ui-components";
import {
  LocationPinIcon,
  MenuArrowDownIcon,
} from "@hexocean/braintrust-ui-components/Icons";
import type { TalentLocation } from "@js/apps/common/components/filters/config";
import { TalentLocationForm } from "@js/apps/common/components/filters/config";
import { CUSTOM_JOB_LOCATIONS } from "@js/apps/common/constants";
import { useIsNodeStaff } from "@js/apps/common/hooks";
import type { UseTalentLocationFilterFieldManagerArg } from "@js/apps/common/hooks/use-talent-location-filter-field-manager";
import { useTalentLocationFilterFieldManager } from "@js/apps/common/hooks/use-talent-location-filter-field-manager";
import { useGetTalentPopularLocationsQuery } from "@js/apps/freelancer/api";
import { RecommendedLocationButtonSelect } from "@js/apps/freelancer/forms/fields/recommended-location-button-select";
import { useHandleFilterApplied } from "@js/apps/freelancer/hooks/use-handle-filter-applied";
import type {
  LocationSelectReason,
  TalentMixedLocation,
} from "@js/apps/freelancer/types";
import { JobPopoverFilterButton } from "@js/apps/jobs/apps/listing/components";
import { GooglePlacesMultipleField } from "@js/forms/fields/autocomplete/";
import type { TypedWrappedFieldInputProps } from "@js/forms/utils";
import { isError } from "@js/forms/utils";
import type { PlaceDetailsResponse } from "@js/hooks";
import { useGoogleMaps } from "@js/hooks";
import { PossibleFilters } from "@js/types/common";
import { pluralize } from "@js/utils";

type CustomLocation = {
  custom: boolean;
  name: string;
  id: string;
};

type SelectedLocations = Array<PlaceDetailsResponse | undefined>;
type SelectedCustomLocations = Array<CustomLocation | undefined>;

export const useTalentLocationFilter = () => {
  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const [selectedLocations, setSelectedLocations] =
    React.useState<SelectedLocations>([]);
  const [params] = useSearchParams();
  const { getPlaceDetails } = useGoogleMaps();
  const selectedLocation = params.get("place_id");
  const selectedCustomLocation = params.get("custom_location");

  const parsedLocationsIds = React.useMemo(
    () => parseParams(selectedLocation),
    [selectedLocation],
  );

  const selectedCustomLocations: SelectedCustomLocations = React.useMemo(() => {
    return parseParams(selectedCustomLocation).map((customLocation) => {
      return CUSTOM_JOB_LOCATIONS.find(
        (location) => location.id === customLocation,
      );
    });
  }, [selectedCustomLocation]);

  const isActive = !!selectedLocation || !!selectedCustomLocation;
  const locationsToInit = [
    ...selectedLocations,
    ...selectedCustomLocations,
  ].filter(Boolean);

  React.useEffect(() => {
    if (parsedLocationsIds.length > 0) {
      Promise.all(
        parsedLocationsIds.map((place) => {
          return getPlaceDetails({
            placeId: place,
            fields: SETTINGS.GOOGLE_PLACES_DETAILS,
          }).then((value) => {
            return value;
          });
        }),
      ).then((value) => {
        setSelectedLocations(value);
      });
    }
    setSelectedLocations([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parsedLocationsIds]);

  const label = useMemo(() => {
    const getLocationLabel = (location) => {
      if (location.formatted_address) return location.formatted_address;
      if (location.name) return location.name;
    };

    if (locationsToInit.length > 0 && isActive) {
      const labelMainPart = getLocationLabel(locationsToInit[0]);
      const labelExtensions =
        locationsToInit.length - 1 > 0
          ? `& ${locationsToInit.length - 1} other${pluralize(
              locationsToInit.length - 1,
            )}`
          : "";
      return `${labelMainPart} ${labelExtensions}`;
    }

    return "Location";
  }, [locationsToInit, isActive]);

  return {
    label,
    isOpen,
    setIsOpen,
    initialLocation:
      selectedLocation || selectedCustomLocation ? locationsToInit : [],
    isActive,
  };
};

const parseParams = (param: string | null) => (param ? param.split(",") : []);

const formId = "talent-filters__location";

export const TalentLocationFilter = () => {
  const { label, isOpen, setIsOpen, initialLocation, isActive } =
    useTalentLocationFilter();
  const { handleFilterApplied } = useHandleFilterApplied();
  const handleSubmitSideAction = (values: Record<string, unknown>) => {
    handleFilterApplied({
      filter_type: PossibleFilters.LOCATION,
      filter_value: values,
    });
  };

  const handleSubmitSuccess = () => {
    setIsOpen(false);
  };

  return (
    <JobPopoverFilterButton
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      startIcon={<LocationPinIcon />}
      isActive={isActive}
      label={label}
      popoverContent={
        <TalentLocationForm
          transformInitialValues={(values) => {
            return {
              ...values,
              location: initialLocation,

              place_id: values.place_id ? values.place_id.split(",") : [],
              custom_location: values.custom_location
                ? values.custom_location.split(",")
                : [],
              session_token: values.session_token
                ? values.session_token.split(",")
                : null,
            } as unknown as TalentLocation;
          }}
          form={formId}
          onSubmitSuccess={handleSubmitSuccess}
          onSubmitSideAction={handleSubmitSideAction}
        >
          {({ submit }) => {
            return (
              <Fields
                submit={submit}
                names={[
                  "custom_location",
                  "location",
                  "place_id",
                  "session_token",
                  "include_verified_locations_only",
                ]}
                component={TalentLocationFilterField}
              />
            );
          }}
        </TalentLocationForm>
      }
    />
  );
};

type TalentLocationFilterFieldProps = {
  submit: () => void;
} & Omit<
  UseTalentLocationFilterFieldManagerArg,
  "include_verified_locations_only"
> & {
    include_verified_locations_only: NonNullable<
      UseTalentLocationFilterFieldManagerArg["include_verified_locations_only"]
    >;
  };

const TalentLocationFilterField = ({
  submit,
  location, // just for input string purpose
  custom_location, // custom option like United states only, Europe etc
  place_id,
  session_token,
  include_verified_locations_only,
}: TalentLocationFilterFieldProps) => {
  const { handleReset, handleLocationSelection } =
    useTalentLocationFilterFieldManager({
      location,
      custom_location,
      place_id,
      session_token,
      include_verified_locations_only,
    });

  const handleChange = (
    data: TalentMixedLocation[],
    reason: LocationSelectReason,
  ) => {
    handleLocationSelection(data, reason, false);
  };

  const error = isError([
    location,
    // no error in others
  ]);

  return (
    <JobPopoverFilterButton.Content
      onReset={handleReset}
      onApply={submit}
      disableApply={error}
    >
      <Box p={1}>
        <Typography
          component="h3"
          mb={1}
          variant="label"
          size="large"
          lineHeight={1.4}
          fontWeight={500}
        >
          Select talent location 📌
        </Typography>
        <GooglePlacesMultipleField<true>
          id="location"
          data-testid="multiple-location-field"
          label={undefined}
          placeholder={
            !!location.input.value?.length
              ? ""
              : "Select a country, state, or city"
          }
          noErrorOnTouch
          disableClearable
          fullWidth
          useCustomLocations
          placesServiceTypes={"regions_without_sublocality"}
          component={StyleAutocompleteMediumVioletChips}
          PaperComponent={PaperWithVioletOptions}
          popupIcon={<MenuArrowDownIcon />}
          locationIcon={false}
          input={{
            ...location.input,
            onChange: (value, reason) => {
              handleChange(
                value as TalentMixedLocation[],
                reason as LocationSelectReason,
              );
            },
          }}
          meta={location.meta}
        />
        <PopularLocationsButtonSelect
          input={{
            ...location.input,
            onChange: handleChange,
          }}
          meta={location.meta}
        />
        <VerifiedLocationsOnlyCheckbox
          input={include_verified_locations_only.input}
        />
      </Box>
    </JobPopoverFilterButton.Content>
  );
};

const PopularLocationsButtonSelect = ({
  input,
  meta,
}: {
  input: Omit<TypedWrappedFieldProps["input"], "onChange"> & {
    onChange: (
      data: TalentMixedLocation[],
      reason?: LocationSelectReason,
    ) => void;
  };
  meta: TypedWrappedFieldProps["meta"];
}) => {
  const { data: options, isLoading } = useGetTalentPopularLocationsQuery();

  const optionsToDisplay = useMemo(
    () =>
      options?.map((option) => {
        return {
          label: option.name || option.full_location?.formatted_address || "",
          value: option.custom_location
            ? option.custom_location
            : option.place_id,
        };
      }),
    [options],
  );

  if (!optionsToDisplay?.length || isLoading) return <Loader />;

  return (
    <RecommendedLocationButtonSelect
      input={input}
      meta={meta}
      options={optionsToDisplay}
      label="Popular Talent locations"
    />
  );
};

const VerifiedLocationsOnlyCheckbox: React.FC<{
  input: TypedWrappedFieldInputProps<boolean | null>;
}> = ({ input }) => {
  const isNodeStaff = useIsNodeStaff();

  if (!isNodeStaff) return null;

  return (
    <Box display="flex" mt={2}>
      <Checkbox
        checked={Boolean(input.value)}
        // set this field's value to null so that we don't send the include_verified_locations_only=false search param
        // instead, we complete remove this param if the checkbox is unchecked
        onChange={() => input.onChange(input.value ? null : true)}
        label={
          <Typography component="p" variant="paragraph">
            Only include Talent with a verified location
          </Typography>
        }
      />
    </Box>
  );
};
