import { ShareLink } from "@js/apps/common/services";
import { useGetNftDetailsQuery } from "@js/apps/nft/api";
import { getProfileDescriptionMessage } from "@js/apps/nft/utils";

export const useNftShareSocialLinks = (tokenId: number) => {
  const { data: nftDetails } = useGetNftDetailsQuery(tokenId);

  const message = getProfileDescriptionMessage(tokenId);

  const linkedInUrl = ShareLink.linkedIn(window.location.href);
  const twitterUrl = ShareLink.twitter("", message);
  const openSeaUrl = nftDetails?.opensea_link;

  return { linkedInUrl, twitterUrl, openSeaUrl };
};
