import {
  Box,
  Button,
  Loader,
  Typography,
} from "@hexocean/braintrust-ui-components";
import { useCurrentFreelancerProfileCompletion } from "@js/apps/freelancer/hooks/use-current-freelancer-profile-completion";
import { RouterLink } from "@js/components/link";

import { NFTCard } from "../../components";

import { Title } from "./utils";

export const FreelancerNotApproved = () => {
  const { data: profileCompletion, isFetching: loading } =
    useCurrentFreelancerProfileCompletion();

  const isProfileCompleted = profileCompletion?.completion_percent === 100;

  if (loading) {
    return <Loader centered />;
  }

  return (
    <>
      <Box mb={5}>
        <Title mb={2}>
          Become an Approved Talent to mint your Braintrust NFT.
        </Title>
        <Typography component="p" size="large" mb={2}>
          {isProfileCompleted ? (
            <>
              Welcome back! Check your email for a link to schedule your
              Approved Talent screening call. After that, you'll be Braintrust
              official and able to mint your membership NFT 🧠
            </>
          ) : (
            <>
              Welcome to Braintrust! First get your profile to 100%, then
              complete the Approved Talent screening process. After that, you'll
              be Braintrust official and able to mint your membership NFT 🧠
            </>
          )}
        </Typography>
        {!isProfileCompleted && (
          <Button
            variant="positive-2"
            size="medium"
            to="/talent/dashboard/welcome/"
            RouterLink={RouterLink}
          >
            Go to dashboard
          </Button>
        )}
      </Box>
      <NFTCard
        actionSlot={
          <Button
            variant="disabled-grey"
            disabledType="dark"
            size="medium"
            disabled
          >
            Pending approval...
          </Button>
        }
      />
    </>
  );
};
