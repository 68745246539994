import React from "react";
import SignUpModalEnvelope from "@static/onboarding/sign-up-modal-envelope.svg";

import { Box, Typography } from "@hexocean/braintrust-ui-components";
import { SignUpConfirmationContent } from "@js/apps/onboarding/components/sign-up-confirmation-content";

export const SignUpModalContentStepTwo = () => {
  return (
    <Box
      maxWidth="100vw"
      width="704px"
      display="flex"
      flexDirection="column"
      alignItems="center"
      boxSizing="border-box"
      p="32px 32px 48px"
    >
      <Typography component="h2" variant="label" size="large" mb={3.5}>
        Finish setting up your account
      </Typography>
      <Box mt={10} textAlign="center">
        <SignUpConfirmationContent
          title="Verify your email address"
          align="center"
          envelopeIcon={
            <SignUpModalEnvelope style={{ width: 120, height: 120 }} />
          }
        />
      </Box>
    </Box>
  );
};
