import type { ReactNode } from "react";
import _ from "underscore";

import { Box, Loader, Typography } from "@hexocean/braintrust-ui-components";
import { CreateOfferPageContent } from "@js/apps/jobs/apps/offers/components/create-offer-page-content";
import { createFormInstance } from "@js/forms/components";
import { useAppSelector } from "@js/hooks/redux";

import {
  EmployerNavigation,
  EmployerTopStripeContent,
  ReviewBeforeSendingModal,
  TalentInfo,
} from "../../components";
import {
  useCreateOffer,
  useCreateOrEditOfferMeta,
  useEditOffer,
} from "../../hooks";
import { OfferFormContentContainer, OfferLayout } from "../../layout";
import type { CreateOfferFormData, EditOfferFormData } from "../../types";
import { assertInitialized } from "../../utils";

const CreateOfferFormInstance = createFormInstance<
  CreateOfferFormData,
  unknown
>("create-offer-form");
const EditOfferFormInstance = createFormInstance<EditOfferFormData, unknown>(
  "edit-offer-form",
);

export const CreateOfferPage = () => {
  const { loading, isTransactionProceeding, initialValues, ...formProps } =
    useCreateOffer();
  const isDepositRequired = useAppSelector(
    (state) => state.employer.employerProfile?.offer_deposit_required,
  );
  if (loading || _.isEmpty(initialValues)) {
    return (
      <Layout loading={true}>
        {isTransactionProceeding ? (
          <Typography component="h1" textAlign="center">
            The transaction is being verified...
          </Typography>
        ) : null}
      </Layout>
    );
  }

  assertInitialized(initialValues);

  return (
    <CreateOfferFormInstance
      initialValues={initialValues}
      hideError
      {...formProps}
    >
      <Layout twoColumnsLayout={isDepositRequired}>
        <CreateOfferPageContent
          showDepositRequiredSection={isDepositRequired}
        />
      </Layout>
    </CreateOfferFormInstance>
  );
};

export const EditOfferPage = () => {
  const { loading, initialValues, isTransactionProceeding, ...formProps } =
    useEditOffer();

  if (loading || _.isEmpty(initialValues)) {
    return (
      <Layout loading={true}>
        {isTransactionProceeding ? (
          <Typography component="h1" textAlign="center">
            The transaction is being verified...
          </Typography>
        ) : null}
      </Layout>
    );
  }

  assertInitialized(initialValues);

  return (
    <EditOfferFormInstance
      initialValues={initialValues}
      hideError
      {...formProps}
    >
      <Layout>
        <CreateOfferPageContent />
      </Layout>
    </EditOfferFormInstance>
  );
};

type LayoutProps = {
  children: ReactNode;
  loading?: boolean;
  twoColumnsLayout?: boolean;
};
const Layout = ({ children, loading, twoColumnsLayout }: LayoutProps) => {
  const meta = useCreateOrEditOfferMeta();

  if (loading || !meta) {
    return (
      <OfferLayout navigationSocket={null}>
        <Box maxWidth={787} component="main" margin="auto">
          <Loader centered />
          {children}
        </Box>
      </OfferLayout>
    );
  }

  return (
    <OfferLayout
      topStripeSocket={<EmployerTopStripeContent offerStatus={meta.status} />}
      navigationSocket={
        <EmployerNavigation
          jobId={meta.job_id}
          jobTitle={meta.job_title}
          offerStatus={meta.status}
          interlocutor={meta.bid.freelancer.user.id}
          bid={meta.bid}
          offerId={meta.employer_offer_id}
        />
      }
      talentInfoSocket={
        <OfferFormContentContainer twoColumnsLayout={twoColumnsLayout}>
          <TalentInfo bid={meta.bid} />
        </OfferFormContentContainer>
      }
    >
      <OfferFormContentContainer twoColumnsLayout={twoColumnsLayout}>
        <Box component="main">{children}</Box>
      </OfferFormContentContainer>

      <ReviewBeforeSendingModal />
    </OfferLayout>
  );
};
