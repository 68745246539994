import classNames from "classnames";

import { IconButton, Typography } from "@hexocean/braintrust-ui-components";
import { MoreVertIcon } from "@hexocean/braintrust-ui-components/Icons";
import { useUser } from "@js/apps/common/hooks";
import { HelpOfferActionMenu } from "@js/apps/messenger/components/help-offer-actions";
import type { SystemMessage } from "@js/types/messenger";

import { formatSentDate } from "../../utils";

import { SystemMessageCard } from "./system-message-card";

type SystemMessageProps = {
  systemMessage: SystemMessage;
};

export const SystemMessageComponent = ({
  systemMessage,
}: SystemMessageProps) => {
  const user = useUser();
  if (!user) return null;

  const view =
    "author_id" in systemMessage.system_message_target &&
    systemMessage.system_message_target.author_id === user.id
      ? "HELPER"
      : "ASKER";

  const sentDate = formatSentDate(systemMessage.sent);
  const isActive = systemMessage.system_message_active;

  const showReportButton =
    isActive &&
    "reported" in systemMessage.system_message_target &&
    !systemMessage.system_message_target.reported &&
    systemMessage.message_type === ENUMS.MessageType.HELP_OFFER_CREATED;

  return (
    <div className="message message--center">
      <Typography
        component="p"
        size="small"
        color="grey-2"
        mt={4}
        mb={1}
        align="center"
      >
        {sentDate}
      </Typography>
      <div
        className={classNames("system-message", {
          "system-message--inactive": !isActive,
        })}
      >
        <SystemMessageCard message={systemMessage} view={view} />

        {showReportButton && (
          <HelpOfferActionMenu
            offerReceiverName={
              systemMessage.system_message_target.receiver.user.first_name
            }
            offer={systemMessage.system_message_target}
            isOfferAuthor={view === "HELPER"}
          >
            <IconButton
              className="system-message__action-menu-button"
              aria-label="more menu"
              variant="transparent"
              size="x-small"
              shape="squared"
            >
              <MoreVertIcon />
            </IconButton>
          </HelpOfferActionMenu>
        )}
      </div>
    </div>
  );
};
