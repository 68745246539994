import cs from "classnames";

import { Box } from "@hexocean/braintrust-ui-components";
import { createFiltersFormAndHook } from "@js/apps/filters";

import { CategoriesFilterField } from "./categories-filter-field";
import type { WalletActivityFiltersOptionValue } from "./constants";
import { WALLEC_ACTIVITY_VALUES } from "./constants";

export type WalletActivityFilters = {
  category: WalletActivityFiltersOptionValue;
  pending: boolean | null;
};

export type WalletActivityFiltersParams = Pick<
  WalletActivityFilters,
  "pending"
> & {
  category:
    | Exclude<WalletActivityFiltersOptionValue, "all" | "pending">
    | undefined;
};

export const defaultFormValues: WalletActivityFilters = {
  category: WALLEC_ACTIVITY_VALUES.ALL,
  pending: null,
};

export const defaultParamsValues: WalletActivityFiltersParams = {
  category: undefined,
  pending: null,
};

export const [WalletActivityFiltersForm, useWalletActivityFilters] =
  createFiltersFormAndHook<WalletActivityFilters, WalletActivityFiltersParams>({
    useAllURLParams: false,
    defaultFormValues: defaultFormValues,
    defaultParamsValues: defaultParamsValues,
    submitOnChange: true,
    mapParamsToValues: (params) => {
      const { category, pending: isPendingFilter } = params;
      const newValues = {
        ...params,
      } as WalletActivityFilters;

      const isAllCategory = !category;

      if (isPendingFilter) {
        newValues.category = "pending";
        newValues.pending = true;
      } else if (isAllCategory) {
        newValues.category = "all";
        newValues.pending = null;
      }

      return newValues;
    },
    mapValuesToParams: (values) => {
      const category = values.category;
      switch (category) {
        case "pending":
          return { ...values, category: undefined, pending: true };
        case "all":
          return { ...values, category: undefined, pending: null };
        default:
          return { ...values, category, pending: null };
      }
    },
  });

export type WalletActivityFiltersCarouselProps = {
  className?: string;
};

export const WalletActivityFiltersCarousel = ({
  className,
}: WalletActivityFiltersCarouselProps) => {
  return (
    <Box className={cs("activity-filters-carousel", className)}>
      <WalletActivityFiltersForm form="wallet-activity-filters-form">
        <CategoriesFilterField />
      </WalletActivityFiltersForm>
    </Box>
  );
};
