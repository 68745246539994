import { useGetEliteTalentsQuery } from "@js/apps/freelancer/api";

export const useEliteTalentProfiles = () => {
  const { data, isLoading } = useGetEliteTalentsQuery();

  return {
    talents: data?.results || [],
    total: data?.count,
    loading: isLoading,
  };
};
