import { useCallback } from "react";
import { useNavigate } from "react-router-dom";

export const useRedirectToActivePost = () => {
  const navigate = useNavigate();

  const redirectToActivePost = useCallback(
    (postId: number, spaceId?: number | null) => {
      if (spaceId) {
        navigate(`/spaces/${spaceId}/post/${postId}`);
        return;
      }
      navigate(`/feed?activePost=${postId}`);
    },
    [navigate],
  );

  return redirectToActivePost;
};
