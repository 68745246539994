import React from "react";
import _ from "underscore";

import { AccountBalanceIcon } from "@hexocean/braintrust-ui-components/Icons";
import type { PaymentMethod } from "@js/types/payments";

import { isACHPaymentMethod } from "../../utils";

import styles from "./style.module.scss";

type PaymentMethodVendorImageProps = {
  paymentMethod: PaymentMethod;
  className?: string;
};

const PaymentMethodVendorImage = ({
  paymentMethod,
  ...props
}: PaymentMethodVendorImageProps) => {
  return isACHPaymentMethod(paymentMethod) ? (
    <AccountBalanceIcon
      titleAccess="ACH payment method"
      className={styles.stripeAchPaymentMethodIcon}
    />
  ) : (
    <img
      alt={`${paymentMethod.method.brand || "Vendor"} Logo`}
      src={`${SETTINGS.STATIC_URL}payment-icons/${
        paymentMethod.method.brand || "default"
      }.svg`}
      {..._.pick(props, "className")}
    />
  );
};

export default PaymentMethodVendorImage;
