export const GENERATE_AI_JOB_DESCRIPTION = "GENERATE_AI_JOB_DESCRIPTION";
export const UPLOAD_JOB_DESCRIPTION = "UPLOAD_JOB_DESCRIPTION";

export const FETCH_JOB_TO_EDIT = "FETCH_JOB_TO_EDIT";
export const FETCH_JOB_TO_EDIT_SUCCESS = "FETCH_JOB_TO_EDIT_SUCCESS";
export const FETCH_JOB_TO_EDIT_FAILED = "FETCH_JOB_TO_EDIT_FAILED";
export const CLEAR_JOB_TO_EDIT = "CLEAR_JOB_TO_EDIT";

export const SET_FIELDS_TO_DISPLAY_CHANGES_SAVED_MARKER =
  "SET_FIELDS_TO_DISPLAY_CHANGES_SAVED_MARKER";
