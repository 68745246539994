import type { CSSProperties, ReactNode } from "react";
import React, { useState } from "react";
import { touch } from "redux-form";
import cs from "classnames";

import {
  Box,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@hexocean/braintrust-ui-components";
import type { TypedWrappedFieldProps } from "@js/forms/utils";
import type { PaymentMethod } from "@js/types/payments";

import type { TextFieldProps } from "..";

import styles from "./style.module.scss";

const CustomRadioButton = ({
  active,
  error,
}: {
  active?: boolean;
  error?: boolean;
}) => {
  return (
    <div
      className={cs(styles.customRadioButton, {
        [styles.customRadioButtonActive]: active,
        [styles.customRadioButtonError]: error,
      })}
    >
      <div
        className={cs(styles.customRadioButtonCircle, {
          [styles.customRadioButtonCircleActive]: active,
        })}
      />
    </div>
  );
};

type RadioListFieldProps = TypedWrappedFieldProps<string> &
  Omit<TextFieldProps, "input" | "label"> & {
    options: { label: ReactNode; value: string | PaymentMethod | boolean }[];
    label?: string;
    borderRadio?: boolean;
    adornment?: (option: string | PaymentMethod | boolean) => ReactNode;
    yellowOutline?: boolean;
    errorStyle?: CSSProperties;
  };

export const RadioListField = (props: RadioListFieldProps) => {
  const {
    options = [],
    label,
    input: { name, value, onChange },
    meta: { form, error, touched, dispatch },
    style,
    borderRadio = true,
    adornment,
    yellowOutline,
    errorStyle,
    disabled = false,
  } = props;
  const [defaultValue] = useState(value);

  const handleChange = (newValue) => {
    // If checked already checked element then it means I want to uncheck it.
    dispatch(touch(form, name));
    onChange(newValue === value ? "" : newValue);
  };

  return (
    <FormControl sx={style} component="fieldset">
      {error && touched && (
        <div
          style={{ ...errorStyle }}
          className="general-form-error general-form-error__align-left"
        >
          {error}
        </div>
      )}
      <RadioGroup defaultValue={defaultValue} aria-label={label} name={name}>
        {options.map((option) => {
          const valueToCompare = JSON.stringify(option.value);
          const isChecked = valueToCompare === JSON.stringify(value);

          return (
            <Box mb={2} key={JSON.stringify(option.value)}>
              <FormControlLabel
                className={cs({
                  [styles.formControlLabelChosenField]:
                    isChecked && borderRadio,
                  [styles.formControlLabelChosenFieldNoBorder]:
                    isChecked && !borderRadio,
                  [styles.formControlLabelBorder]: borderRadio,
                })}
                classes={{
                  label: cs(styles.formControlLabel, {
                    [styles.formControlLabelError]: error && touched,
                  }),
                }}
                value={option.value}
                onChange={() => handleChange(option.value)}
                control={
                  <Radio
                    icon={
                      <CustomRadioButton
                        active={false}
                        error={error && touched}
                      />
                    }
                    checkedIcon={<CustomRadioButton active={true} />}
                    checked={isChecked}
                    classes={{
                      checked: cs({
                        [styles.formControlLabelYellowChecked]: yellowOutline,
                      }),
                    }}
                  />
                }
                label={<Typography component="span">{option.label}</Typography>}
                disabled={disabled}
              />
              {adornment && adornment(option.value)}
            </Box>
          );
        })}
      </RadioGroup>
    </FormControl>
  );
};
