import React from "react";

import { Box, Typography } from "@hexocean/braintrust-ui-components";
import { ShareIcon } from "@hexocean/braintrust-ui-components/Icons";
import type { JobListingJob } from "@js/types/jobs";

import { ReferToJobButton } from "../refer-to-job";

type ShareThisJobCardProps = {
  job: JobListingJob;
  bgColor?: string;
};

const DEFAULT_BG_COLOR = "var(--soft-yellow)";
const BUTTON_BG_COLOR = "var(--medium-orange)";
const BUTTON_COLOR = "var(--dark-orange)";

export const ShareThisJobBubble: React.FC<ShareThisJobCardProps> = ({
  job,
  bgColor = DEFAULT_BG_COLOR,
}) => {
  return (
    <Box
      sx={{
        color: BUTTON_COLOR,
        backgroundColor: bgColor,
        ...styles.mainContainer,
      }}
    >
      <Box sx={{ ...styles.insideContent }}>
        <ShareIcon style={{ stroke: BUTTON_COLOR }} />
        <Typography component="h2" size="large" ml={2}>
          Share and Refer
        </Typography>
      </Box>
      <ReferToJobButton
        children={null}
        job={job}
        size="medium"
        style={{
          backgroundColor: BUTTON_BG_COLOR,
          color: BUTTON_COLOR,
        }}
      />
    </Box>
  );
};

const styles = {
  mainContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    borderRadius: "48px",
    maxHeight: "48px",
    maxWidth: "432px",
    padding: "16px 20px",
  },
  insideContent: {
    display: "flex",
    alignItems: "center",
    marginRight: "16px",
    fontSize: "20px",
    lineHeight: "32px",
  },
};
