import { Typography } from "@hexocean/braintrust-ui-components";
import { formatCurrency } from "@js/utils";

import styles from "../styles.module.scss";

type DueAndOverdueAmountsProps = {
  due: number;
  overdue: number;
};

export const DueAndOverdueAmounts = ({
  due,
  overdue,
}: DueAndOverdueAmountsProps) => {
  return (
    <>
      {due !== undefined && (
        <Typography
          component="h1"
          variant="title"
          fontWeight={400}
          size="small"
          className={styles.detail}
        >
          <Typography
            component="span"
            size="small"
            variant="label"
            className={styles.detailTitle}
          >
            Due within 30 days
          </Typography>
          {formatCurrency(due)}
        </Typography>
      )}
      {overdue !== undefined && (
        <Typography
          component="h1"
          variant="title"
          fontWeight={400}
          size="small"
          className={styles.detail}
        >
          <Typography
            component="span"
            size="small"
            variant="label"
            className={styles.detailTitle}
          >
            Overdue
          </Typography>
          {formatCurrency(overdue)}
        </Typography>
      )}
    </>
  );
};
