import { API } from "@js/api";
import { walletListenerRecipe } from "@js/apps/dashboard/wallet-listener";
import type {
  CanWithdrawToken,
  EmployerReferralsReturnType,
  EmployerStatsSummaryType,
  FreelancerReferralsReturnType,
  FreelancerStatsSummaryType,
} from "@js/types/dashboard";
import { combineResults } from "@js/utils";
import { createListenerIdFromArgs, listenerWrapper } from "@js/utils/websocket";

import {
  DEFAULT_EMPLOYER_REFERRALS_ORDER_BY,
  DEFAULT_TALENT_REFERRALS_ORDER_BY,
} from "./constants";
import type {
  GetEmployerReferralsParams,
  GetFaqEntriesResponse,
  GetReferralLinksParams,
  GetReferralLinksResponse,
  GetTalentReferralsParams,
  GetTemplatesEntriesResponse,
  GetWalletActivityParamsType,
  GetWalletActivityReturnType,
  GetWalletBalanceParamsType,
  GetWalletBalanceReturnType,
  WalletActivityWithdrawalTransaction,
} from "./types";

const dashboardWalletApi = API.injectEndpoints({
  endpoints: (build) => ({
    getWalletActivity: build.query<
      GetWalletActivityReturnType,
      GetWalletActivityParamsType
    >({
      query: (params) => ({
        url: "/wallet_activity/",
        method: "GET",
        params,
      }),
      providesTags: ["WalletActivityList"],
      async onCacheEntryAdded(
        _arg,
        { cacheDataLoaded, cacheEntryRemoved, dispatch },
      ) {
        const invalidateCache = () =>
          dispatch(API.util.invalidateTags(["WalletActivityList"]));

        listenerWrapper({
          cacheDataLoaded,
          cacheEntryRemoved,
          updateRecipe: walletListenerRecipe(invalidateCache),
          listenerId: createListenerIdFromArgs(
            _arg,
            "help_offers_listener_wallet_activity_list",
          ),
        });
      },
    }),
    getWalletBalance: build.query<
      GetWalletBalanceReturnType,
      GetWalletBalanceParamsType
    >({
      query: ({ balanceHolderId }) => ({
        url: `/balances/${balanceHolderId}`,
        method: "GET",
      }),
      providesTags: ["WalletBalance"],
      async onCacheEntryAdded(
        _arg,
        { cacheDataLoaded, cacheEntryRemoved, dispatch },
      ) {
        const invalidateCache = () =>
          dispatch(API.util.invalidateTags(["WalletBalance"]));

        listenerWrapper({
          cacheDataLoaded,
          cacheEntryRemoved,
          updateRecipe: walletListenerRecipe(invalidateCache),
          listenerId: createListenerIdFromArgs(
            _arg,
            "help_offers_listener_wallet_balance",
          ),
        });
      },
    }),
  }),
});

export const {
  useGetWalletActivityQuery,
  useLazyGetWalletActivityQuery,
  useGetWalletBalanceQuery,
} = dashboardWalletApi;

const faqApi = API.injectEndpoints({
  endpoints: (build) => ({
    getFaqEntries: build.query<GetFaqEntriesResponse, void>({
      query: () => ({
        url: `/faq_section_entries/`,
        method: "GET",
      }),
      keepUnusedDataFor: 1000000,
    }),
  }),
});

export const { useGetFaqEntriesQuery } = faqApi;

const templatesApi = API.injectEndpoints({
  endpoints: (build) => ({
    getTemplatesEntries: build.query<GetTemplatesEntriesResponse, void>({
      query: () => ({
        url: `/templates_section_entries/`,
        method: "GET",
      }),
      keepUnusedDataFor: 1000000,
    }),
  }),
});

export const { useGetTemplatesEntriesQuery } = templatesApi;

const referralsApi = API.injectEndpoints({
  endpoints: (build) => ({
    getFreelancerReferrals: build.query<
      FreelancerReferralsReturnType,
      GetTalentReferralsParams
    >({
      query: (params) => {
        const orderBy = params.order_by || DEFAULT_TALENT_REFERRALS_ORDER_BY;
        const ordering = params.order_dir === "asc" ? orderBy : `-${orderBy}`;
        return {
          url: "/freelancer_referrals/",
          method: "GET",
          params: {
            search: params.search,
            page: params.page,
            ordering,
          },
        };
      },
      serializeQueryArgs: ({ endpointName, queryArgs }) => {
        const serializeQueryArgs: GetTalentReferralsParams = {
          ...queryArgs,
        };
        delete serializeQueryArgs.page;

        return `${endpointName}(${JSON.stringify(serializeQueryArgs)})`;
      },
      forceRefetch({ currentArg, previousArg }) {
        const defaultPage = 1;
        const previousArgPage = previousArg?.page ?? defaultPage;
        const currentArgPage = currentArg?.page ?? defaultPage;
        const isFetchingCachedPage = previousArgPage >= currentArgPage;

        return !isFetchingCachedPage;
      },
      merge: (currentCache, newItems) => {
        const combinedFreelancerReferralsResults = combineResults(
          currentCache.results,
          newItems.results,
        );

        return {
          ...newItems,
          results: combinedFreelancerReferralsResults,
        };
      },
    }),
    getFreelancerReferralsSummary: build.query<
      FreelancerStatsSummaryType,
      void
    >({
      query: () => ({
        url: "/freelancer_referrals/summary/",
        method: "GET",
      }),
    }),
    getEmployerReferrals: build.query<
      EmployerReferralsReturnType,
      GetEmployerReferralsParams
    >({
      query: (params) => {
        const orderBy = params.order_by || DEFAULT_EMPLOYER_REFERRALS_ORDER_BY;
        const ordering = params.order_dir === "asc" ? orderBy : `-${orderBy}`;
        return {
          url: "/employer_referrals/",
          method: "GET",
          params: {
            search: params.search,
            page: params.page,
            ordering,
          },
        };
      },
      serializeQueryArgs: ({ endpointName, queryArgs }) => {
        const serializeQueryArgs: GetEmployerReferralsParams = {
          ...queryArgs,
        };
        delete serializeQueryArgs.page;

        return `${endpointName}(${JSON.stringify(serializeQueryArgs)})`;
      },
      merge: (currentCache, newItems) => {
        const combinedEmployerReferralsResults = combineResults(
          currentCache.results,
          newItems.results,
        );

        return {
          ...newItems,
          results: combinedEmployerReferralsResults,
        };
      },
      forceRefetch({ currentArg, previousArg }) {
        const defaultPage = 1;
        const previousArgPage = previousArg?.page ?? defaultPage;
        const currentArgPage = currentArg?.page ?? defaultPage;
        const isFetchingCachedPage = previousArgPage >= currentArgPage;

        return !isFetchingCachedPage;
      },
    }),
    getEmployerReferralsSummary: build.query<EmployerStatsSummaryType, void>({
      query: () => ({
        url: "/employer_referrals/summary/",
        method: "GET",
      }),
    }),
    getReferralLinks: build.query<
      GetReferralLinksResponse,
      GetReferralLinksParams
    >({
      query: (params) => ({
        url: "/referral_links/",
        method: "GET",
        params,
      }),
    }),
  }),
});

export const {
  useGetFreelancerReferralsQuery,
  useGetFreelancerReferralsSummaryQuery,
  useGetEmployerReferralsQuery,
  useGetEmployerReferralsSummaryQuery,
  useGetReferralLinksQuery,
} = referralsApi;

export type WithdrawBalancePayload = {
  amount: string;
  wallet_address: string;
  code?: string;
  is_backup_code?: boolean;
};

type WithdrawBalanceResponse = WalletActivityWithdrawalTransaction | void;

type ConfirmTransactionPayload = {
  code: string;
};

export type CanConfirmTransactionResponse = {
  amount: string;
  wallet_address: string;
};

const withdrawalsApi = API.injectEndpoints({
  endpoints: (build) => ({
    fetchCanWithdrawTokens: build.query<CanWithdrawToken, void>({
      query: () => ({
        url: "/token_withdrawal_transactions/can_withdraw_token/",
        method: "GET",
      }),
    }),

    withdrawBalance: build.mutation<
      WithdrawBalanceResponse,
      WithdrawBalancePayload
    >({
      query: (values) => ({
        url: "/token_withdrawal_transactions/",
        method: "POST",
        data: values,
      }),
      invalidatesTags: ["WalletActivityList", "WalletBalance"],
    }),
    canConfirmWithdrawalTransaction: build.mutation<
      CanConfirmTransactionResponse,
      ConfirmTransactionPayload
    >({
      query: (code) => ({
        url: "/token_withdrawal_transactions/can_confirm/",
        method: "POST",
        data: code,
      }),
    }),
    confirmWithdrawalTransaction: build.mutation<
      void,
      ConfirmTransactionPayload
    >({
      query: (code) => ({
        url: "/token_withdrawal_transactions/confirm/",
        method: "POST",
        data: code,
      }),
      invalidatesTags: ["WalletActivityList", "WalletBalance"],
    }),
  }),
});

export const {
  useLazyFetchCanWithdrawTokensQuery,
  useWithdrawBalanceMutation,
  useCanConfirmWithdrawalTransactionMutation,
  useConfirmWithdrawalTransactionMutation,
} = withdrawalsApi;
