import React from "react";

import {
  Box,
  Button,
  Grid,
  RoundedBox,
} from "@hexocean/braintrust-ui-components";
import { ArrowTopRightIcon } from "@hexocean/braintrust-ui-components/Icons";
import type { FreelancerLocationMismatch } from "@js/apps/admin/types";
import { RouterLink } from "@js/components/link";
import { LocationMismatchAlert } from "@js/components/location-mismatch-alert";
import type { User } from "@js/types/auth";
import type { EmployerBid, EmployerBidListBid, Job } from "@js/types/jobs";

import { BidActionsMenu } from "../bid-actions-menu";
import { BidCTAButton } from "../bid-cta-button";
import { ReactionsFeedbackField } from "../bid-reactions";

import { BidTabs } from "./tabs/bid-tabs";

import styles from "./right-panel.module.scss";

export type BidListRightPanelProps = {
  job: Job;
  bid: EmployerBidListBid | EmployerBid | undefined;
  closeDrawer: () => void;
  locationMismatchData?: Pick<
    FreelancerLocationMismatch,
    "last_active_session_mismatch" | "billing_location_mismatch"
  > & { user: Pick<User, "first_name"> };
};

export const BidListRightPanel = ({
  job,
  bid,
  closeDrawer,
  locationMismatchData,
}: BidListRightPanelProps) => {
  if (!bid || !job) return null;

  return (
    <Grid item xs={12} sm={8}>
      {!!locationMismatchData && (
        <LocationMismatchAlert
          userFirstName={locationMismatchData.user.first_name}
          sessionLocationMismatch={Boolean(
            locationMismatchData.last_active_session_mismatch,
          )}
          billingLocationMismatch={Boolean(
            locationMismatchData.billing_location_mismatch,
          )}
          className={styles.locationAlert}
        />
      )}
      <RoundedBox
        sx={{
          bgcolor: "var(--white)",
          overflow: "hidden",
          p: { xs: 3, sm: 4 },
          borderRadius: { xs: "0px", sm: "16px" },
          margin: { xs: "0 -24px", sm: "auto" },
          width: { xs: "calc(100% + 48px)", sm: "100%" },
        }}
      >
        <Box
          display="flex"
          justifyContent="space-between"
          mb={2}
          whiteSpace="nowrap"
          flexWrap="wrap"
          gap="8px"
        >
          <Box display="flex" gap={1}>
            <Box>
              <ReactionsFeedbackField bid={bid} isDrawer />
            </Box>
            <Box>
              <Button
                variant="white-violet"
                shape="squared"
                to={bid.freelancer.full_link}
                rel="noopener noreferrer"
                endIcon={<ArrowTopRightIcon style={{ fontSize: 24 }} />}
                target="_blank"
                RouterLink={RouterLink}
              >
                View profile
              </Button>
            </Box>
          </Box>
          <Box display="flex" gap={1}>
            <Box display="flex" gap={1}>
              <BidCTAButton bid={bid} job={job} closeDrawer={closeDrawer} />
            </Box>
            <Box>
              <BidActionsMenu bid={bid} job={job} closeDrawer={closeDrawer} />
            </Box>
          </Box>
        </Box>
        <Box mt={4} mb={3}>
          <BidTabs bid={bid} job={job} />
        </Box>
      </RoundedBox>
    </Grid>
  );
};
