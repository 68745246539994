import React from "react";

import { Box, Button } from "@hexocean/braintrust-ui-components";
import { useMediaQuery } from "@hexocean/braintrust-ui-components/hooks";
import { RouterLink } from "@js/components/link";
import type { TopNavigationProps } from "@js/layouts/app";
import { TopNavigationComponent } from "@js/layouts/app";
import { getCurrentPathEncoded } from "@js/utils";

interface PublicSpaceTopNavigationProps extends TopNavigationProps {
  handleSignUp: () => void;
}

export const PublicSpacePageTopNavigation = ({
  handleSignUp,
  ...props
}: PublicSpaceTopNavigationProps) => {
  const smallMobile = useMediaQuery(400);
  const isMobile = useMediaQuery("sm");
  const buttonsSize = isMobile ? "x-small" : "small";

  return (
    <TopNavigationComponent
      bgcolor={"var(--soft-green)"}
      px={0}
      logoSize={smallMobile ? 100 : 128}
      {...props}
    >
      <Box display="flex" sx={{ gap: 1 }}>
        <Button
          variant="secondary"
          href={`/auth/login/?next=${getCurrentPathEncoded()}`}
          RouterLink={RouterLink}
          size={buttonsSize}
        >
          Log in
        </Button>
        <Button
          variant="primary"
          className="btn-guest"
          onClick={handleSignUp}
          size={buttonsSize}
        >
          Create account
        </Button>
      </Box>
    </TopNavigationComponent>
  );
};
