import { Field } from "redux-form";

import { useCanManageJobsOnBehalfOfClient } from "@js/apps/jobs/hooks";

import { PostJobAsBadge } from "./post-job-as-badge";
import { PostJobForClientModal } from "./post-job-for-client";

export const CanManageOnBehalfOfClientModule = ({
  badgeOnly,
}: {
  badgeOnly: boolean;
}) => {
  const canManageJobsOnBehalfOfClient = useCanManageJobsOnBehalfOfClient();

  if (!canManageJobsOnBehalfOfClient) {
    return null;
  }

  if (badgeOnly) {
    return <Field name="employer" component={PostJobAsBadge} />;
  }

  return (
    <>
      <PostJobForClientModal />
      <Field name="employer" component={PostJobAsBadge} />
    </>
  );
};
