import { useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import _ from "underscore";

import { useAccountType } from "@js/apps/common/hooks";
import { jobsApi } from "@js/apps/jobs/api";
import { useCanManageJobsOnBehalfOfClient } from "@js/apps/jobs/hooks";
import { useAppDispatch, useAppSelector } from "@js/hooks";
import type { JobFormValues } from "@js/types/jobs";

import { updateJob } from "../../actions";
import { useFetchJobToEdit } from "../../hooks/fetch-job-to-edit";
import {
  getInitialValues,
  getInitialValuesOnBehalfOfAClient,
  prepareCreateJobSubmitFormValues,
} from "../../utils";

export const useEditJob = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const jobId = Number(id);
  const job = useAppSelector((state) => state.jobs.jobToEdit);
  const canManageJobAsCoreMember = useCanManageJobsOnBehalfOfClient();
  const { isEmployer } = useAccountType();

  useFetchJobToEdit(jobId);

  const onSubmit = (values: JobFormValues) => {
    const preparedValues = prepareCreateJobSubmitFormValues(values);

    return dispatch(
      updateJob(jobId, {
        ..._.pick(preparedValues, [
          "title",
          "contract_type",
          "deadline",
          "start_date",
          "expected_hours_per_week",
          "openings_number",
          "role",
          "introduction",
          "description",
          "requirements",
          "budget_maximum_usd",
          "budget_minimum_usd",
          "level",
          "payment_type",
          "new_application_questions",
          "auto_invite_talent",
          "locations",
          "locations_strongly_required",
          "timezones",
          "timezone_overlap",
          "new_skills",
          "top_skills",
          "is_private",
          "experience_level",
          "is_resume_required",
          "job_subscribers",
          ...(isEmployer ? ["job_subscriber_invitations"] : []),
          "is_ai_generated",
        ]),
      }),
    );
  };

  const onSubmitSuccess = () => {
    dispatch(
      jobsApi.util.invalidateTags([
        { type: "Jobs", id: jobId },
        { type: "JobBidsLocations", id: jobId },
        { type: "JobSubscribers", id: jobId },
        { type: "JobSubscriberInvitations", id: jobId },
        "EmployerJobsMinimal",
      ]),
    );
    navigate(`/jobs/${jobId}/invite_talent/`);
  };

  const initialValues = useMemo(() => {
    if (!job) return {};

    if (canManageJobAsCoreMember) {
      return getInitialValuesOnBehalfOfAClient(job);
    }

    return getInitialValues(job);
  }, [job, canManageJobAsCoreMember]);

  const loading = !job;

  const hideUnsavedChangesWarningURLs = useMemo(() => {
    return [`/jobs/${jobId}/edit/`, `/jobs/${jobId}/edit/set_up/`];
  }, [jobId]);

  return {
    job,
    loading,
    onSubmit,
    onSubmitSuccess,
    initialValues,
    unsavedChangesWarning: true,
    hideUnsavedChangesWarningURLs,
  };
};
