import { useEffect, useState } from "react";

import { bulkSaveTaxonomyItems } from "@js/apps/common/actions";
import { useUser } from "@js/apps/common/hooks";
import { CommonConfirmationModal, ModalInstance } from "@js/components/modal";
import { useAppDispatch, useAppSelector } from "@js/hooks";
import { sanitize } from "@js/services";
import type { AppDispatch } from "@js/store";
import type { FreelancerWorkExperience } from "@js/types/freelancer";
import { deepClone, typeGuard } from "@js/utils";

import {
  createWorkExperience,
  deleteWorkExperience,
  fetchFreelancerPublicProfile,
  updateWorkExperience,
} from "../../actions";
import { freelancerApi } from "../../api";
import { ADD_ANOTHER_ITEM } from "../../constants";
import { FREELANCER_WORK_HISTORY_FORM_ID } from "../../forms/work-history-form";

export const useWorkExperience = (persistModalInstance = false) => {
  const [submitType, setSubmitType] = useState<string | null>(null);
  const dispatch = useAppDispatch();

  const freelancerId = useUser()?.freelancer;
  const profile = useAppSelector((state) => state.freelancer.publicProfile);
  const currentTalentId = useAppSelector(
    (state) => state.freelancer.publicProfile?.id,
  );

  useEffect(() => {
    if (freelancerId && !profile) {
      dispatch(fetchFreelancerPublicProfile(freelancerId));
    }
  }, [dispatch, freelancerId, profile]);

  const onSubmit = async (values, methodDispatch) => {
    const newValue = deepClone(values);

    if (newValue.new_company) {
      const companyName =
        typeof newValue.new_company === "string"
          ? newValue.new_company
          : newValue.new_company.name;
      const companies = await bulkSaveTaxonomyItems(
        "companies",
        { companies: [{ name: companyName }] },
        "new_company",
      );
      if (!companies || !Array.isArray(companies)) {
        return;
      }

      const company = companies[0];
      if (!company || !typeGuard<unknown, { id: unknown }>(company, "id")) {
        return;
      }

      newValue.new_company = company.id;
    }

    return values.id
      ? methodDispatch(updateWorkExperience(values.id, newValue))
      : methodDispatch(createWorkExperience(newValue));
  };

  const onSubmitSuccess = (_: unknown, methodDispatch: AppDispatch, props) => {
    if (!freelancerId) {
      return;
    }

    if (freelancerId === currentTalentId) {
      methodDispatch(fetchFreelancerPublicProfile(freelancerId));
    }

    methodDispatch(
      freelancerApi.util.invalidateTags([
        "FreelancerWorkExperience",
        "FreelancerProfileCompletion",
      ]),
    );

    if (submitType === ADD_ANOTHER_ITEM) {
      props.reset(FREELANCER_WORK_HISTORY_FORM_ID);
      return;
    }

    if (!persistModalInstance) {
      ModalInstance.close();
    }
  };

  const onUpdateJobSuccess = async () => {
    if (freelancerId) {
      dispatch(freelancerApi.util.invalidateTags(["FreelancerWorkExperience"]));
      await dispatch(fetchFreelancerPublicProfile(freelancerId));
    }
    CommonConfirmationModal.close();
  };

  const removeWorkHistory = async (work: { id: number }) => {
    await dispatch(deleteWorkExperience(work.id));

    if (freelancerId) {
      dispatch(freelancerApi.util.invalidateTags(["FreelancerWorkExperience"]));
      await dispatch(fetchFreelancerPublicProfile(freelancerId));
    }
    CommonConfirmationModal.close();
  };

  const getInitialValues = (
    initialWork: FreelancerWorkExperience | undefined,
  ) =>
    initialWork
      ? {
          id: initialWork.id,
          title: initialWork.title,
          new_company: initialWork.company,
          month_from: initialWork.month_from,
          month_to: initialWork.month_to,
          year_from: initialWork.year_from && String(initialWork.year_from),
          year_to: initialWork.year_to && String(initialWork.year_to),
          description: sanitize(initialWork.description, { ALLOWED_TAGS: [] }),
          is_currently_working: !initialWork.year_to && !initialWork.month_to,
        }
      : null;

  return {
    freelancerWork: profile?.freelancer_work_experience,
    getInitialValues,
    onSubmit,
    onSubmitSuccess,
    onUpdateJobSuccess,
    removeWorkHistory,
    setSubmitType,
    submitType,
  };
};
