import { useEffect, useMemo, useState } from "react";
import type { Control, FieldValues, UseControllerProps } from "react-hook-form";
import { useController } from "react-hook-form";

import { TextField } from "@hexocean/braintrust-ui-components";
import type { ComboBoxProps } from "@hexocean/braintrust-ui-components/";
import { ComboBox } from "@hexocean/braintrust-ui-components/";
import { StandardInput } from "@hexocean/braintrust-ui-components/components/Input/";
import type {
  UserSearchParams,
  UserSearchResult,
} from "@js/components/autocomplete-new/api";
import {
  useGetUserInitialItemQuery,
  useGetUserSearchQuery,
} from "@js/components/autocomplete-new/api";
import { useSearchPhrase } from "@js/components/autocomplete-new/hooks/search-phrase";
import { useSynchronizeSingleValueInternalStateWithFormValue } from "@js/components/autocomplete-new/hooks/synchronize-with-form-value";

type RhfUserSearchComboboxFieldProps<T extends FieldValues> =
  UseControllerProps<T> &
    Partial<
      Omit<
        ComboBoxProps<UserSearchResult & { isInitialItem?: boolean }, false>,
        "component"
      >
    > & {
      label: string | undefined;
      endpointParams?: Partial<UserSearchParams>;
      inputType?: "standardInput" | "textField";
      id: string;
      control: Control<T>;
      helperText?: string;
    };

export const RhfUserSearchComboboxField = <T extends FieldValues>({
  label,
  endpointParams,
  inputType = "textField",
  helperText,
  ...props
}: RhfUserSearchComboboxFieldProps<T>) => {
  const { field, fieldState, formState } = useController(props);
  const [value, setValue] = useState<UserSearchResult | null>(null);
  const { onInputChange, searchPhrase } = useSearchPhrase();

  const { data: users } = useGetUserSearchQuery({
    search: searchPhrase,
    ...endpointParams,
  });

  const defaultValue = formState.defaultValues
    ? formState.defaultValues[props.name]
    : undefined;

  const skip = fieldState.isDirty || !defaultValue || !!value; // "dirty" is cleared with every search so we need to rely on "value" too
  const { data: initialItem, isLoading: initialTaxonomyLoading } =
    useGetUserInitialItemQuery(Number(defaultValue), {
      skip,
    });

  const options = useMemo(() => {
    // include initialItem in options to avoid MUI warning related to isOptionEqualToValue
    if (initialItem) {
      return [...(users || []), { ...initialItem, isInitialItem: true }];
    }

    return users || [];
  }, [users, initialItem]);

  useSynchronizeSingleValueInternalStateWithFormValue({
    initialTaxonomyLoading,
    formValue: field.value,
    isValueEqualFormValue: field.value === value?.id,
    findOption: (option) => {
      return option.id === field.value;
    },
    setValue,
    options,
  });

  useEffect(() => {
    if (initialItem) {
      setValue(initialItem);
    }
  }, [initialItem]);

  return (
    <ComboBox<UserSearchResult & { isInitialItem?: boolean }, false>
      initialTaxonomiesLoading={initialTaxonomyLoading}
      options={options}
      renderInput={(params) => {
        if (inputType === "textField") {
          return (
            <TextField
              {...params}
              size="small"
              label={label || "User"}
              error={fieldState.invalid}
              helperText={fieldState.error?.message || helperText}
            />
          );
        }

        return (
          <StandardInput
            floatingLabel
            {...params}
            size="small"
            label={label || "User"}
            error={fieldState.invalid}
            helperText={fieldState.error?.message || helperText}
          />
        );
      }}
      value={value || null}
      onInputChange={onInputChange}
      onChange={(_ev, valueArg) => {
        setValue(valueArg);
        field.onChange(valueArg?.id || null);
      }}
      filterOptions={(optionsArg) => {
        return optionsArg.filter((opt) => !opt.isInitialItem);
      }}
      onBlur={field.onBlur}
      disabled={field.disabled}
      getOptionLabel={(option) => option.name_and_email}
      {...props}
    />
  );
};
