import { API } from "@js/api";
import type { PaginatedResult } from "@js/types/generic";
import type {
  CreateJobDraftPayload,
  EmployerJobMinimal,
  EmployerOwnJob,
  GetExpandedJobDetailsQueryArgs,
  GetExpandedJobDetailsResponse,
  GetJobApplicationInsightsParams,
  GetJobDraftResponse,
  GetJobDraftsResponse,
  GetJobParams,
  GetOwnJobsParams,
  GetSavedJobsParams,
  GetSmartMatchingStatusArgs,
  GetSmartMatchingStatusResponse,
  InviteTalentProps,
  Job,
  JobApplicationInsightsResponse,
  JobDraft,
  JobListingJob,
  JobSubscriber,
  JobSubscriberInvitation,
  MoveATSJobsToDraftActionArgs,
  NewestJobsProps,
  RemoveInviteProps,
  SimilarJobs,
  SimilarJobsProps,
  TalentInviteSuggestion,
  TalentInviteSuggestions,
  UpdateJobDraftPayload,
  UpdateJobSubscriberInvitationsParams,
  UpdateJobSubscribersParams,
} from "@js/types/jobs";
import { combineResults } from "@js/utils";

import type { JobFilters } from "../common/components/filters";
import { buildFiltersURL, formatFilters } from "../common/filters";

import { prepareDraftResponse } from "./apps/create-job/utils";

export const inviteApi = API.injectEndpoints({
  endpoints: (build) => ({
    getTalentInviteSuggestions: build.query<TalentInviteSuggestions, number>({
      query: (jobId) => ({
        url: `/talent/talent_invite_suggestion_list/?job=${jobId}`,
        method: "GET",
      }),
      providesTags: ["TalentInviteSuggestionList"],
    }),
    sendInvitation: build.mutation<TalentInviteSuggestion, InviteTalentProps>({
      query: ({ jobId, freelancersIds }) => ({
        url: `/manage_jobs/${jobId}/invite/`,
        method: "POST",
        data: { freelancers: freelancersIds },
      }),
    }),

    removeInvitation: build.mutation<void, RemoveInviteProps>({
      query: ({ jobId, freelancerId }) => ({
        url: `/manage_jobs/${jobId}/remove_talent_invite_suggestion/`,
        method: "POST",
        data: { freelancer: freelancerId },
      }),
    }),
  }),
});

type GetJobsParams = Partial<JobFilters> & { page?: number };

export const jobsApi = API.injectEndpoints({
  endpoints: (builder) => ({
    getJobs: builder.query<PaginatedResult<JobListingJob>, GetJobsParams>({
      query: (jobParams) => {
        const role = jobParams.role?.length
          ? jobParams.role.join(",")
          : undefined;
        const skills = jobParams.skills?.length
          ? jobParams.skills.join(",")
          : undefined;

        return {
          url: `/jobs/`,
          method: "GET",
          params: { ...jobParams, role: role, skills: skills },
        };
      },
      providesTags: [{ type: "Jobs", id: "LIST" }],
    }),
    getJob: builder.query<Job, GetJobParams>({
      query: ({ id, referrer }) => ({
        url: `/jobs/${id}`,
        method: "GET",
        params: { referrer },
      }),
      providesTags: (_res, _err, arg) => [{ type: "Jobs", id: arg.id }],
    }),
    getJobApplicationInsights: builder.query<
      JobApplicationInsightsResponse,
      GetJobApplicationInsightsParams
    >({
      query: ({ id }) => ({
        url: `/jobs/${id}/application_insights`,
        method: "GET",
      }),
      providesTags: (_res, _err, arg) => [{ type: "Jobs", id: arg.id }],
    }),
    getSavedJobs: builder.query<
      PaginatedResult<JobListingJob>,
      GetSavedJobsParams | undefined
    >({
      query: (arg) => ({
        url: "/jobs/saved_jobs",
        method: "GET",
        params: arg,
      }),
      serializeQueryArgs: ({ endpointName, queryArgs }) => {
        const serializeQueryArgs: Partial<GetSavedJobsParams> = {
          ...queryArgs,
        };
        delete serializeQueryArgs.page;

        return `${endpointName}(${JSON.stringify(serializeQueryArgs)})`;
      },
      forceRefetch({ currentArg, previousArg }) {
        const isFetchingCachedPage =
          !!previousArg?.page &&
          !!currentArg?.page &&
          previousArg?.page >= currentArg?.page;

        if (isFetchingCachedPage) {
          return false;
        }
        // serialized query arg makes it so we are not fetching on page change ...
        // ... force refetch when any param changes, including page (page must be higher)
        const paramsKeys: Array<keyof GetSavedJobsParams> = ["page"];
        const hasArgChanged = paramsKeys.some(
          (paramKey) => currentArg?.[paramKey] !== previousArg?.[paramKey],
        );

        return hasArgChanged;
      },
      merge: (currentCache, newItems) => {
        const combinedJobsResults = combineResults(
          currentCache.results,
          newItems.results,
        );

        return {
          ...newItems,
          results: combinedJobsResults,
        };
      },
      providesTags: ["SavedJobs"],
    }),
    /* Response type here should be narrowed but that's needs a refactor of JobListingItem  */
    getEmployerOpenJobs: builder.query<JobListingJob[], { employerId: number }>(
      {
        query: ({ employerId }) => ({
          url: `/employer_open_jobs/?employer=${employerId}`,
          method: "GET",
        }),
        providesTags: ["EmployerOpenJobs"],
      },
    ),
    getRelevantJobs: builder.query<
      PaginatedResult<JobListingJob>,
      Record<string, unknown>
    >({
      query: (filters) => ({
        url: `/jobs_talent_home/relevant_jobs/${buildFiltersURL(
          filters,
        ).search()}`,
        method: "GET",
      }),
      providesTags: ["RelevantJobs"],
    }),

    getOwnJobs: builder.query<
      PaginatedResult<EmployerOwnJob>,
      GetOwnJobsParams
    >({
      query: (params) => ({
        url: `/manage_jobs/`,
        method: "GET",
        params,
      }),
      providesTags: ["OwnJobs"],
    }),

    getJobDrafts: builder.query<GetJobDraftsResponse, { limit?: number }>({
      query: (params) => ({
        url: `/jobs_drafts/`,
        method: "GET",
        params,
      }),
      providesTags: [{ type: "JobDrafts", id: "LIST" }],
    }),

    getJobDraft: builder.query<JobDraft, { id: number }>({
      query: ({ id }) => ({
        url: `/jobs_drafts/${id}/`,
        method: "GET",
      }),
      providesTags: (_res, _err, arg) => [{ type: "JobDrafts", id: arg.id }],
      transformResponse: (response: GetJobDraftResponse): JobDraft => {
        return prepareDraftResponse(response);
      },
    }),

    deleteJobDraft: builder.mutation<void, { id: number }>({
      query: ({ id }) => ({
        url: `/jobs_drafts/${id}/`,
        method: "DELETE",
      }),
      invalidatesTags: [{ type: "JobDrafts", id: "LIST" }],
    }),

    createJobDraft: builder.mutation<
      GetJobDraftResponse,
      CreateJobDraftPayload
    >({
      query: (data) => ({
        url: `/jobs_drafts/`,
        method: "POST",
        data,
      }),
    }),

    updateJobDraft: builder.mutation<JobDraft, UpdateJobDraftPayload>({
      query: (data) => ({
        url: `/jobs_drafts/${data.data.id}/`,
        method: "PUT",
        data,
      }),
      transformResponse: (response: GetJobDraftResponse): JobDraft => {
        return prepareDraftResponse(response);
      },
      async onQueryStarted(arg, { queryFulfilled, dispatch }) {
        try {
          const { data } = await queryFulfilled;

          dispatch(
            jobsApi.util.updateQueryData(
              "getJobDraft",
              { id: arg.data.id },
              () => {
                return data;
              },
            ),
          );
        } catch {
          /* Do nothing. */
        }
      },
    }),

    prepareSmartMatching: builder.query<void, void>({
      query: () => ({
        url: `/manage_jobs/prepare_smart_matching/`,
        method: "GET",
      }),
      keepUnusedDataFor: 0,
    }),
    getSmartMatchingStatus: builder.query<
      GetSmartMatchingStatusResponse,
      GetSmartMatchingStatusArgs
    >({
      query: (params) => ({
        url: `/jobs/${params.id}/smart_matching_status/`,
        method: "GET",
      }),
    }),
    getSimilarJobs: builder.query<SimilarJobs, SimilarJobsProps>({
      query: ({ jobId, pageSize }) => ({
        url: `/jobs_similar_jobs/?job_id=${jobId}&page_size=${pageSize}`,
        method: "GET",
      }),
      providesTags: ["SimilarJobs"],
    }),

    getNewestJobs: builder.query<SimilarJobs, NewestJobsProps>({
      query: (filters) => ({
        url: `/jobs_talent_home/newest_jobs/?${new URLSearchParams(
          formatFilters(filters),
        ).toString()}`,
        method: "GET",
      }),
      providesTags: ["NewestJobs"],
    }),
    getExpandedJobDetails: builder.query<
      GetExpandedJobDetailsResponse,
      GetExpandedJobDetailsQueryArgs
    >({
      query: (id) => ({
        url: `/jobs/${id}/expanded_details_job_list/`,
        method: "GET",
        //Response status validation is silenced due to a problem with redirection on 404 errors.
        //https://app.asana.com/0/960700181513369/1203058392812609
        validateStatus: () => true,
      }),
    }),
    moveATSJobsToDraft: builder.mutation<
      JobDraft[],
      MoveATSJobsToDraftActionArgs
    >({
      query: (data) => ({
        url: `/jobs_drafts/ats_batch/`,
        method: "POST",
        data,
      }),
      invalidatesTags: ["JobDrafts"],
    }),
    getEmployerJobsMinimal: builder.query<EmployerJobMinimal[], void>({
      query: (params) => ({
        url: `/employer_jobs_minimal/`,
        method: "GET",
        params,
      }),
      providesTags: [{ type: "EmployerJobsMinimal", id: "LIST" }],
    }),
  }),
});

const publishJobsAfterEmailVerificationApi = API.injectEndpoints({
  endpoints: (build) => ({
    publishPendingJobs: build.mutation<
      { failed: number; created: number },
      void
    >({
      query: () => {
        return {
          url: `/manage_jobs/publish_pending_jobs/`,
          method: "POST",
        };
      },
      invalidatesTags: ["JobDrafts"],
    }),
  }),
});

const jobOwnerApi = API.injectEndpoints({
  endpoints: (build) => ({
    changeJobOwner: build.mutation<
      { success: boolean },
      { jobId: number; newOwnerId: number }
    >({
      query: ({ jobId, newOwnerId }) => {
        return {
          url: `/manage_jobs/${jobId}/change_owner/`,
          method: "POST",
          data: { new_owner: newOwnerId },
        };
      },
      invalidatesTags: (_result, _error, arg) => [
        { type: "Jobs", id: arg.jobId },
        { type: "JobSubscribers", id: arg.jobId },
      ],
    }),
    getJobOwners: build.query<
      {
        op_owners: number[];
        sale_owners: number[];
      },
      { jobId: number }
    >({
      query: ({ jobId }) => {
        return {
          url: `/jobs/${jobId}/get_owners/`,
          method: "GET",
        };
      },
      providesTags: (_res, _err, arg) => [{ type: "JobOwners", id: arg.jobId }],
    }),
    changeJobOwners: build.mutation<
      void,
      {
        jobId: number;
        op_owners?: number[];
        sale_owners?: number[];
      }
    >({
      query: ({ jobId, ...data }) => ({
        url: `/jobs/${jobId}/change_owners/`,
        method: "POST",
        data,
      }),
      invalidatesTags: (_res, _err, arg) => [
        { type: "JobOwners", id: arg.jobId },
        { type: "Jobs", id: arg.jobId },
        { type: "Jobs", id: "LIST" },
        "EmployerOpenJobs",
        "SavedJobs",
      ],
    }),
  }),
});

const jobSubscribersApi = API.injectEndpoints({
  endpoints: (build) => ({
    getJobSubscribers: build.query<JobSubscriber[], { jobId: number }>({
      query: ({ jobId }) => {
        return {
          url: `/jobs/${jobId}/job_subscribers/`,
          method: "GET",
        };
      },
      providesTags: (_res, _err, arg) => [
        { type: "JobSubscribers", id: arg.jobId },
      ],
    }),
    getJobSubscriberInvitations: build.query<
      JobSubscriberInvitation[],
      { jobId: number }
    >({
      query: ({ jobId }) => {
        return {
          url: `/jobs/${jobId}/job_subscriber_invitations/`,
          method: "GET",
        };
      },
      providesTags: (_res, _err, arg) => [
        { type: "JobSubscriberInvitations", id: arg.jobId },
      ],
    }),
    updateJobSubscribers: build.mutation<
      JobSubscriber[],
      UpdateJobSubscribersParams
    >({
      query: ({ jobId, jobSubscribers }) => {
        return {
          url: `/jobs/${jobId}/job_subscribers/batch`,
          method: "PUT",
          data: jobSubscribers,
        };
      },
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;

          dispatch(
            jobSubscribersApi.util.updateQueryData(
              "getJobSubscribers",
              { jobId: arg.jobId },
              () => {
                return data;
              },
            ),
          );
        } catch (error) {
          dispatch(
            jobSubscribersApi.util.invalidateTags([
              { type: "JobSubscribers", id: arg.jobId },
              { type: "JobSubscriberInvitations", id: arg.jobId },
              { type: "EmployerTeamMembers", id: "LIST" },
            ]),
          );
        }
      },
      invalidatesTags: ["OwnJobs"],
    }),
    updateJobSubscriberInvitations: build.mutation<
      JobSubscriberInvitation[],
      UpdateJobSubscriberInvitationsParams
    >({
      query: ({ jobId, jobSubscriberInvitations }) => {
        return {
          url: `/jobs/${jobId}/job_subscriber_invitations/batch`,
          method: "PUT",
          data: jobSubscriberInvitations,
        };
      },
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;

          dispatch(
            jobSubscribersApi.util.updateQueryData(
              "getJobSubscriberInvitations",
              { jobId: arg.jobId },
              () => {
                return data;
              },
            ),
          );
        } catch (error) {
          dispatch(
            jobSubscribersApi.util.invalidateTags([
              { type: "JobSubscribers", id: arg.jobId },
              { type: "JobSubscriberInvitations", id: arg.jobId },
              { type: "EmployerTeamMembers", id: "LIST" },
            ]),
          );
        }
      },
    }),
    subscribeToJob: build.mutation<
      {
        job: number;
        id: number;
      },
      { jobId: number; team_member_id: number }
    >({
      query: ({ jobId, ...data }) => ({
        url: `/jobs/${jobId}/job_subscribers/`,
        method: "POST",
        data,
      }),
      invalidatesTags: (_result, _error, arg) => [
        "OwnJobs",
        { type: "JobSubscribers", id: arg.jobId },
      ],
    }),
    unsubscribeFromJob: build.mutation<
      void,
      { jobId: number; team_member_id: number }
    >({
      query: ({ jobId, ...data }) => ({
        url: `/jobs/${jobId}/job_subscribers/`,
        method: "DELETE",
        data,
      }),
      invalidatesTags: (_result, _error, arg) => [
        "OwnJobs",
        { type: "JobSubscribers", id: arg.jobId },
      ],
    }),
  }),
});

const applicationsBoostApi = API.injectEndpoints({
  endpoints: (build) => ({
    getApplicationBoosts: build.mutation({
      query: () => {
        return {
          url: `/user/purchase/boost/`,
          method: "POST",
        };
      },
      invalidatesTags: ["WalletBalance"],
    }),
  }),
});

export const {
  useGetTalentInviteSuggestionsQuery,
  useLazyGetTalentInviteSuggestionsQuery,
  useSendInvitationMutation,
  useRemoveInvitationMutation,
} = inviteApi;

export const {
  useGetJobQuery,
  useGetJobsQuery,
  useLazyGetJobsQuery,
  useGetRelevantJobsQuery,
  useGetOwnJobsQuery,
  useLazyGetOwnJobsQuery,
  useGetJobDraftsQuery,
  useLazyGetJobDraftsQuery,
  useGetJobDraftQuery,
  usePrepareSmartMatchingQuery,
  useGetSimilarJobsQuery,
  useGetNewestJobsQuery,
  useGetExpandedJobDetailsQuery,
  useGetSavedJobsQuery,
  useGetEmployerOpenJobsQuery,
  useGetSmartMatchingStatusQuery,
  useLazyGetSmartMatchingStatusQuery,
  useMoveATSJobsToDraftMutation,
  useGetEmployerJobsMinimalQuery,
  useUpdateJobDraftMutation,
  useCreateJobDraftMutation,
  useDeleteJobDraftMutation,
  useGetJobApplicationInsightsQuery,
} = jobsApi;

export const { usePublishPendingJobsMutation } =
  publishJobsAfterEmailVerificationApi;

export const {
  useGetJobOwnersQuery,
  useChangeJobOwnersMutation,
  useChangeJobOwnerMutation,
} = jobOwnerApi;

export const {
  useGetJobSubscribersQuery,
  useGetJobSubscriberInvitationsQuery,
  useUpdateJobSubscriberInvitationsMutation,
  useUpdateJobSubscribersMutation,
  useSubscribeToJobMutation,
  useUnsubscribeFromJobMutation,
} = jobSubscribersApi;

export const { useGetApplicationBoostsMutation } = applicationsBoostApi;
