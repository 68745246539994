import { useCallback } from "react";
import { useNavigate } from "react-router-dom";

import { Box, Button, Typography } from "@hexocean/braintrust-ui-components";
import { ArrowRightIcon } from "@hexocean/braintrust-ui-components/Icons";
import {
  FRONTEND_STORAGE_KEYS,
  useSetStorageValueMutation,
} from "@js/apps/common/frontend-storage";
import { useUser } from "@js/apps/common/hooks";
import { OpenMergeComponentButton } from "@js/apps/employer/components/merge-link-component";
import { OnboardingPage } from "@js/apps/onboarding/components/onboarding-page";
import { RouterLink } from "@js/components/link";

export const OnboardingIntegrateATS = () => {
  const user = useUser();
  const navigate = useNavigate();
  const [setStorageValue] = useSetStorageValueMutation();

  const handleIntegrationSuccess = useCallback(async () => {
    await setStorageValue({
      key: FRONTEND_STORAGE_KEYS.is_ats_introduced,
      value: true,
    });

    navigate("/employer/dashboard/");
  }, [navigate, setStorageValue]);

  if (!user?.employer) {
    return null;
  }

  return (
    <OnboardingPage>
      <Box display="flex" flexDirection="column" gap={1} mb={4}>
        <Typography component="p" variant="paragraph">
          We’re almost done.
        </Typography>

        <Typography component="p" variant="title" size="small" fontWeight={400}>
          Sync jobs from your ATS platform
        </Typography>

        <Typography component="p" variant="paragraph">
          Experience the ease of syncing jobs between our platform and your ATS.
          This automation eliminates manual data entry, saving you time and
          reducing the risk of errors.
        </Typography>
      </Box>

      <OpenMergeComponentButton
        sx={{ mb: 4 }}
        onIntegrationSuccess={handleIntegrationSuccess}
      />

      <Box display="flex" gap={2} justifyContent="flex-end">
        <Button
          RouterLink={RouterLink}
          to="/onboarding/schedule-meeting"
          variant="secondary"
          shape="squared"
          size="medium"
        >
          Back
        </Button>

        <Button
          RouterLink={RouterLink}
          to="/"
          variant="positive"
          shape="squared"
          size="medium"
          endIcon={<ArrowRightIcon />}
        >
          Skip
        </Button>
      </Box>
    </OnboardingPage>
  );
};
