import React from "react";
import type { NavigateFunction } from "react-router-dom";
import {
  useLocation,
  useNavigate as useRouterNavigate,
  useParams,
} from "react-router-dom";

export const useNavigate = (): NavigateFunction => {
  const nativeNavigate = useRouterNavigate();

  const navigate = React.useCallback(
    (to, options?) => {
      nativeNavigate(to, {
        ...options,
        state: {
          prevPath: window.location.pathname,
          ...(options?.state || {}),
        },
      });
    },
    [nativeNavigate],
  );

  return navigate;
};

/** @deprecated Use `React Router hooks` instead */
export type WithRouterProps = {
  location: ReturnType<typeof useLocation>;
  params: Record<string, string>;
  navigate: ReturnType<typeof useRouterNavigate>;
};

/** @deprecated Use `React Router hooks` instead */
export const withRouter = <Props extends WithRouterProps>(
  Component: React.ComponentType<Props>,
) => {
  return (props: Omit<Props, keyof WithRouterProps>) => {
    const location = useLocation();
    const params = useParams();
    const navigate = useRouterNavigate();

    return (
      <Component
        {...(props as Props)}
        location={location}
        params={params}
        navigate={navigate}
      />
    );
  };
};
