import type { ReactElement } from "react";
import React, { useMemo, useState } from "react";
import cs from "classnames";

import {
  MultipleSelect,
  Pagination,
  PaginationWrapper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@hexocean/braintrust-ui-components";
import { useLocalStorageState } from "@js/apps/common/hooks/use-local-storage-state";
import { RouterLink } from "@js/components/link";

import { getCursorText } from "../../utils/string";

import { TableScrollNav, useTableScroll } from "./table-scroll-nav";
import { TableSortLabel } from "./table-sort-label";

export type TableColumn = {
  id?: number;
  orderBy: string | null;
  label: string;
};

type ReportTableProps<T, F> = {
  id: string;
  filters?: F;
  changeFilters?: (newFilters: F) => void;
  columns?: TableColumn[];
  children: (arg: T) => ReactElement | undefined;
  getKey?: (row: T) => string;
  data: T[];
  pagination?: boolean;
  total?: number;
  page?: number;
  perPage?: number;
  noColumnSelect?: boolean;
  emptyState?: ReactElement;
  useParentWidth?: boolean;
  tableScrollNav?: boolean;
};

export const ReportTable = <
  TableItem extends { id: string | number },
  Filters,
>({
  id,
  filters,
  changeFilters,
  columns = [],
  children,
  data,
  pagination = true,
  total,
  page,
  perPage,
  noColumnSelect = false,
  emptyState,
  useParentWidth = false,
  tableScrollNav = true,
}: ReportTableProps<TableItem, Filters>) => {
  const { tableContentRef, startScrolling, stopScrolling } = useTableScroll();

  if (!id)
    throw new Error(
      "ReportTable requires unique and constant id due to local storage cache.",
    );
  if (
    pagination &&
    (total === null || total === undefined || !page || !perPage)
  )
    throw new Error(
      "Provide required props: total, page, perPage or set pagination={false}.",
    );

  const reportTableId = `report-table__${id}`;

  const [storedIndexesToOmit, setIndexesToOmit] = useLocalStorageState<
    number[]
  >({
    key: reportTableId,
    initialValue: [],
  });

  const indexesToOmit = useMemo(() => {
    if (noColumnSelect) {
      return [];
    }

    return storedIndexesToOmit;
  }, [storedIndexesToOmit, noColumnSelect]);

  const columnsToDisplay = useMemo(() => {
    return columns.filter((_column, index) => !indexesToOmit.includes(index));
  }, [columns, indexesToOmit]);

  const displayEmptyState = !data.length && emptyState;
  if (displayEmptyState) {
    if (emptyState) return emptyState;

    return (
      <div className="report-table width-100">
        <h2>No data records yet.</h2>
      </div>
    );
  }

  const cursorText =
    pagination &&
    typeof page !== "undefined" &&
    typeof total !== "undefined" &&
    typeof perPage !== "undefined" &&
    getCursorText(page, true, data.length, total, perPage);

  const options = columns.map((cell) => ({
    value: cell.label,
    label: cell.label,
  }));

  const handleChange = (values, setValue) => {
    if (noColumnSelect) return;

    const omitThoseIndexes: number[] = [];
    columns.forEach((cell, index) => {
      if (!values.includes(cell.label)) {
        omitThoseIndexes.push(index);
      }
    });

    if (omitThoseIndexes.length < columns.length) {
      setIndexesToOmit(omitThoseIndexes);
      setValue(values);
    }
  };
  const showPagination = pagination && !!total && !!perPage;

  return (
    <div className="report-table mt">
      <div className="report-table__cursor-cnt">
        <div className="report-table__cursor">
          {pagination && (
            <div className="report-table__pagination-summary">
              Showing {cursorText} of {total}
            </div>
          )}
          {!noColumnSelect && (
            <div className="report-table__columns-select">
              <ColumnSelect
                initialValue={options
                  .filter((_option, index) => !indexesToOmit.includes(index))
                  .map((option) => option.value)}
                handleChange={handleChange}
                options={options}
              />
            </div>
          )}
          {tableScrollNav && (
            <TableScrollNav
              startScrolling={startScrolling}
              stopScrolling={stopScrolling}
            />
          )}
        </div>
      </div>
      <div
        className={cs({
          "report-table__wrapper-cnt": !useParentWidth,
          "report-table_wrapper-cnt--parent-width": useParentWidth,
        })}
      >
        <div className="report-table__wrapper-content" ref={tableContentRef}>
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                {columnsToDisplay.map((props) => {
                  return (
                    <TableCell key={props.id ?? props.label} align="center">
                      <TableSortLabel
                        {...props}
                        onChange={changeFilters}
                        filters={filters}
                      />
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>

            <TableBody>
              {data?.map((row) => {
                const renderedRow = children(row);
                const filteredChildren = React.Children.map(
                  renderedRow?.props.children,
                  (child, index) => {
                    if (indexesToOmit.includes(index)) return null;
                    return child;
                  },
                );

                if (renderedRow) {
                  const newRow = React.cloneElement(renderedRow, {
                    // props
                    key: row.id,
                    children: filteredChildren,
                  });

                  return newRow;
                }

                return undefined;
              })}
            </TableBody>
          </Table>
        </div>
      </div>
      {showPagination && (
        <PaginationWrapper>
          <Pagination count={total} perPage={perPage} RouterLink={RouterLink} />
        </PaginationWrapper>
      )}
    </div>
  );
};

const ColumnSelect = ({ initialValue, options, handleChange }) => {
  const [value, setValue] = useState(initialValue);

  return (
    <MultipleSelect
      InputLabelProps={{
        shrink: options.length !== value.length,
      }}
      value={value}
      onChange={(ev) => handleChange(ev.target.value, setValue)}
      renderValue={(val) => {
        const valueToRener = val as Array<unknown>;

        if (options.length === valueToRener.length) return null;

        return `${options.length - valueToRener.length} hidden`;
      }}
      label="Columns"
      options={options}
    />
  );
};
