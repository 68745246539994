import type { RefObject } from "react";
import { useCallback } from "react";
import { useEffect, useState } from "react";

import type { Hashtag } from "@js/apps/give-and-get-help/types";

type useMenuFocusControlProps = {
  enabled: boolean;
  onSelect: (item: Hashtag) => void;
  list: Hashtag[];
  hashtagMenuRef?: RefObject<HTMLDivElement>;
};

export const useMenuFocusControl = ({
  enabled,
  onSelect,
  list,
  hashtagMenuRef,
}: useMenuFocusControlProps) => {
  const [focusedIndex, setFocusedIndex] = useState(0);

  const handleScrollList = useCallback(
    (toIndex: number) => {
      const menuEl = hashtagMenuRef?.current;

      if (menuEl) {
        const listContainer = menuEl.querySelector("ul") as HTMLElement;
        const listItem = menuEl.querySelectorAll("li")[toIndex] as HTMLElement;

        if (!listItem || !listContainer) return;

        const paddingTop = 5;

        const itemPosition = calculateItemPosition(listItem);
        const [areaMin, areaMax] = calculateContainerArea(listContainer);

        const containerHeight = listContainer.clientHeight;

        if (itemPosition.start < areaMin) {
          listContainer.scrollTop =
            listItem.offsetTop - listContainer.offsetTop - paddingTop;
        }

        if (itemPosition.end > areaMax) {
          listContainer.scrollTop =
            listItem.offsetTop - containerHeight + itemPosition.height;
        }
      }
    },
    [hashtagMenuRef],
  );

  const handleValueChangedByArrows = useCallback(
    (diff: number) => {
      setFocusedIndex((currentValue) => {
        const nextValue = currentValue + diff;
        if (nextValue >= 0 && nextValue < list.length) {
          handleScrollList(nextValue);
          return nextValue;
        } else {
          return currentValue;
        }
      });
    },
    [setFocusedIndex, handleScrollList, list],
  );

  useEffect(() => {
    if (focusedIndex > list.length - 1 && focusedIndex !== 0) {
      setFocusedIndex(0);
    }
  }, [focusedIndex, list]);

  useEffect(() => {
    if (!enabled) {
      setFocusedIndex(0);
    }
    const handleKeyDown = (e: KeyboardEvent) => {
      const { key } = e;
      switch (key) {
        case "ArrowDown":
          e.preventDefault();
          handleValueChangedByArrows(1);
          break;
        case "ArrowUp":
          e.preventDefault();
          handleValueChangedByArrows(-1);
          break;
        case "Enter":
          e.preventDefault();
          onSelect(list[focusedIndex]);
          break;
        default:
          break;
      }
    };
    if (enabled) {
      document.addEventListener("keydown", handleKeyDown);
    }
    return () => document.removeEventListener("keydown", handleKeyDown);
  }, [
    enabled,
    focusedIndex,
    setFocusedIndex,
    list,
    onSelect,
    handleValueChangedByArrows,
  ]);

  const isFocused = (index: number) => index === focusedIndex;

  const setFocusedItem = (index: number) => {
    if (focusedIndex !== index) {
      setFocusedIndex(index);
    }
  };

  return { isFocused, setFocusedItem };
};

const calculateContainerArea = (
  container: HTMLElement,
): [areaMin: number, areaMax: number] => {
  const containerScrollTop = container.scrollTop;
  const containerHeight = container.clientHeight;

  const containerArea = [
    containerScrollTop,
    containerScrollTop + containerHeight,
  ];

  const [areaMin, areaMax] = containerArea;

  return [areaMin, areaMax];
};

const calculateItemPosition = (item: HTMLElement) => {
  const height = item.offsetHeight;

  return {
    height,
    start: item.offsetTop,
    end: item.offsetTop + height,
  };
};
