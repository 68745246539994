import { useMemo } from "react";
import type { TypedWrappedFieldProps } from "redux-form";

import { useGetEmployerJobsMinimalQuery } from "@js/apps/jobs/api";

type UseProjectFieldProps = {
  input: TypedWrappedFieldProps<number>["input"];
};

const allProjectsOption = { id: -1, title: "All Projects" };

export const useProjectField = ({ input }: UseProjectFieldProps) => {
  const { data: employerJobsMinimal } = useGetEmployerJobsMinimalQuery();

  const options: Array<{ id: number; title: string }> = useMemo(() => {
    return [allProjectsOption, ...(employerJobsMinimal ?? [])];
  }, [employerJobsMinimal]);

  const valueMapped = useMemo(() => {
    if (!input.value) {
      return allProjectsOption;
    }

    return options.find((option) => option.id === input.value) ?? null;
  }, [input.value, options]);

  return {
    value: valueMapped,
    options,
  };
};
