import React from "react";
import classNames from "classnames";

import { IconButton } from "@hexocean/braintrust-ui-components";
import {
  ArrowLeftIcon,
  CloseIcon,
} from "@hexocean/braintrust-ui-components/Icons";
import { RouterLink } from "@js/components/link";
import { Logo } from "@js/components/logo";

import { getQuery } from "../../utils";
import type { CoreLayoutProps } from "../core";
import { CoreLayout } from "../core";

type FullPageLayoutProps = CoreLayoutProps & {
  closeURL?: string;
  closeIcon?: string;
  className?: string;
  narrow?: boolean;
  centered?: boolean;
  showLogo?: boolean;
  back?: boolean;
  headerLeft?: React.ReactNode;
  headerRight?: React.ReactNode;
  children: React.ReactNode;
};

const FullPageLayout = ({
  children,
  className,
  closeURL,
  closeIcon,
  narrow,
  centered,
  showLogo,
  back,
  headerLeft,
  headerRight,
  ...rest
}: FullPageLayoutProps) => {
  const backURL = back && (getQuery("back") || getQuery("prevPath"));
  const buttonURL = backURL || closeURL || "";

  return (
    <CoreLayout
      {...rest}
      className={classNames("full-page-layout", className, {
        narrow,
        centered,
      })}
    >
      <div className="full-page-layout-header flex-left-right">
        <div>{headerLeft}</div>
        <div className="left">
          {!!buttonURL && (
            <RouterLink to={buttonURL}>
              <IconButton
                aria-label={closeIcon || backURL ? "Go back" : "Close"}
                variant="transparent"
              >
                {closeIcon || backURL ? <ArrowLeftIcon /> : <CloseIcon />}
              </IconButton>
            </RouterLink>
          )}
        </div>
        <div>{headerRight}</div>
      </div>
      <div className="full-page-layout-content">
        {showLogo && <Logo variant="symbol" noLink />}
        {children}
      </div>
    </CoreLayout>
  );
};

export default FullPageLayout;
