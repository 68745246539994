import {
  Box,
  Divider,
  RoundedBox,
  Typography,
} from "@hexocean/braintrust-ui-components";
import { CircleCheckIcon } from "@hexocean/braintrust-ui-components/Icons";
import { useAccountType } from "@js/apps/common/hooks";
import type { OfferCommon } from "@js/types/jobs";

type AdditionalInfoProps = {
  offer: OfferCommon;
  bottomSocket?: React.ReactNode;
};

export const AdditionalInfo = ({
  offer,
  bottomSocket,
}: AdditionalInfoProps) => {
  const { isFreelancer } = useAccountType();

  const paperworkLabel = offer.require_paperwork
    ? "Additional paperwork required — Braintrust will follow up"
    : "No extra paperwork required";

  const equipmentLabel = offer.talent_has_own_equipment
    ? isFreelancer
      ? "You will use your own equipment"
      : "Talent will use their own equipment"
    : "Some equipment will be provided";

  return (
    <RoundedBox display="flex" flexDirection="column" gap={2.5}>
      <Typography component="span" variant="label" size="large">
        📎 Additional info
      </Typography>
      <Divider color="beige" />
      <InfoRow label={paperworkLabel} />
      <Divider color="beige" />
      <InfoRow label={equipmentLabel} />
      <Box pl={3}>{bottomSocket}</Box>
    </RoundedBox>
  );
};

const InfoRow = ({ label }) => {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="flex-start"
      gap={1.6}
    >
      <CircleCheckIcon sx={{ fontSize: "16px", color: "var(--positive-2)" }} />
      {label}
    </Box>
  );
};
