import { useEffect, useState } from "react";

import {
  createTaxonomyItem,
  searchTaxonomyItems,
} from "@js/apps/common/actions";
import { useUser } from "@js/apps/common/hooks";
import {
  createNewCertificate,
  deleteCertificate,
  fetchFreelancerPublicProfile,
  updateCertificate,
} from "@js/apps/freelancer/actions";
import { CERTIFICATES_FORM_ID } from "@js/apps/freelancer/forms/certificates-form/constants";
import { ADD_ANOTHER_CERTIFICATE } from "@js/apps/freelancer/hooks/edit-certificates/constants";
import { CommonConfirmationModal, ModalInstance } from "@js/components/modal";
import { useAppDispatch, useAppSelector } from "@js/hooks";
import type { Certificate, Issuer } from "@js/types/common";
import { deepClone, isEqualTaxonomyName } from "@js/utils";

import type { FormData } from "../../forms/certificates-form";

export const useCertificates = () => {
  const dispatch = useAppDispatch();
  const [submitType, setSubmitType] = useState<string | null>(null);

  const freelancerId = useUser()?.freelancer;
  const profile = useAppSelector((state) => state.freelancer.publicProfile);

  useEffect(() => {
    if (freelancerId && !profile) {
      dispatch(fetchFreelancerPublicProfile(freelancerId));
    }
  }, [dispatch, freelancerId, profile]);

  const createCertificate = (phrase: string) =>
    dispatch(createTaxonomyItem(phrase, "certificates", "new_certificate"));
  const createIssuer = (phrase: string) =>
    dispatch(createTaxonomyItem(phrase, "certificates", "issuer"));

  const onSubmit = async (values) => {
    const newValue = deepClone(values);

    if (newValue.new_certificate) {
      const foundCertificates = (await searchTaxonomyItems({
        phrase: newValue.new_certificate,
        endpoint: "certificates",
        immediate: true,
        params: {},
        dispatch,
      })) as Certificate[];

      let certificate = foundCertificates.find(
        ({ name }) => name === newValue.new_certificate,
      );

      if (
        !certificate ||
        !isEqualTaxonomyName(certificate.name, newValue.new_certificate)
      ) {
        certificate = (await createCertificate(
          newValue.new_certificate,
        )) as Certificate;
      }

      newValue.new_certificate = certificate.id;
    }

    if (newValue.issuer) {
      const foundIssuer = (await searchTaxonomyItems({
        phrase: newValue.issuer,
        endpoint: "certificates",
        immediate: true,
        params: {},
        dispatch,
      })) as Issuer[];
      let issuer = foundIssuer.find(({ name }) => name === newValue.issuer);

      if (!issuer || !isEqualTaxonomyName(issuer.name, newValue.issuer)) {
        issuer = (await createIssuer(newValue.issuer)) as Issuer;
      }
    }

    return values.id
      ? dispatch(updateCertificate(values.id, newValue))
      : dispatch(createNewCertificate(newValue));
  };

  const onSubmitSuccess = async (_, methodDispatch, props) => {
    if (freelancerId) {
      methodDispatch(fetchFreelancerPublicProfile(freelancerId));
    }

    if (submitType === ADD_ANOTHER_CERTIFICATE) {
      props.reset(CERTIFICATES_FORM_ID);
      setSubmitType(null);
    } else {
      ModalInstance.close();
      CommonConfirmationModal.close();
    }
  };

  const getInitialValues = (
    editedCertificateId: number,
  ): FormData | undefined => {
    if (!profile) return;

    const { freelancer_certificates } = profile;

    const editedCertificate = freelancer_certificates.find(
      (certificate) => certificate.id === editedCertificateId,
    );

    let initialValues = {};
    if (editedCertificateId !== undefined && editedCertificate) {
      initialValues = {
        id: editedCertificate.id,
        new_certificate: editedCertificate.certificate
          ? editedCertificate.certificate.name
          : editedCertificate.certificate,
        issuer: editedCertificate.issuer,
        year_of_issuing: String(editedCertificate.year_of_issuing),
      };
    }

    return initialValues as FormData;
  };

  const onDeleteCertificate = async (certificateId: number) => {
    await dispatch(deleteCertificate(certificateId));

    if (freelancerId) {
      dispatch(fetchFreelancerPublicProfile(freelancerId));
    }
    CommonConfirmationModal.close();
  };

  const onUpdateCertificateSuccess = async () => {
    if (freelancerId) {
      dispatch(fetchFreelancerPublicProfile(freelancerId));
    }
    CommonConfirmationModal.close();
  };

  return {
    onSubmit,
    getInitialValues,
    onDeleteCertificate,
    onSubmitSuccess,
    onUpdateCertificateSuccess,
    setSubmitType,
    profile,
  };
};
