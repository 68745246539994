import { useNavigate } from "react-router-dom";
import PoweredByCoinbase from "@static/on-ramp/poweredByCoinbase.svg";

import {
  Box,
  Button,
  Typography,
  useMediaQuery,
} from "@hexocean/braintrust-ui-components";
import { SwapVerticalIcon } from "@hexocean/braintrust-ui-components/Icons";
import { fetchCurrentUser } from "@js/apps/auth/actions";
import { OfferVisual } from "@js/apps/common/components/offer-visual";
import { useGetApplicationBoostsMutation } from "@js/apps/jobs/api";
import { useCoinbasePay } from "@js/apps/on-ramp/hooks/useCoinbase";
import { ModalInstance } from "@js/components/modal";
import { Snackbar } from "@js/components/snackbar";
import { useAppDispatch, useAppSelector } from "@js/hooks";

import { openBoostSuccessModal } from "./boost-success-modal/boost-success-modal";

import styles from "./styles.module.scss";

type ApplicationBoostModalProps = {
  btrst: boolean;
  walletAddress: string;
  transactionUuid: string;
  requiredBTRST: number;
  boostCost: number;
};

export const ApplicationBoostModalRight = ({
  btrst,
  walletAddress,
  transactionUuid,
  requiredBTRST,
  boostCost,
}: ApplicationBoostModalProps) => {
  const tokensToBuy = requiredBTRST;
  const [getApplicationBoosts, { isLoading }] =
    useGetApplicationBoostsMutation();
  const isMobile = useMediaQuery("sm");
  const user = useAppSelector((state) => state.auth.user);
  const { onRampURL } = useCoinbasePay({
    transactionUuid,
    walletAddress,
    tokensToBuy,
  });

  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const ClickOnCoinbase = () => {
    if (onRampURL) {
      ModalInstance.close();
      window.open(onRampURL, "_blank", "noreferrer");
    } else {
      Snackbar.error("onRamp URl is null");
    }
  };

  const clickOnPurchaseBoost = async () => {
    if (user?.boost_credit !== undefined && user?.boost_credit > 0) {
      Snackbar.info(
        `Can't purchase boosts, You have ${user?.boost_credit} boost credits remaining.`,
      );
    } else {
      const boost: any = await getApplicationBoosts({});

      if ("error" in boost) {
        return Snackbar.error(
          boost?.error?.data?.error_message || "Error something happend!",
        );
      }
      dispatch(fetchCurrentUser());
      ModalInstance.close();
      openBoostSuccessModal(navigate);
    }
  };

  const topImageSection = () => {
    if (btrst) {
      return (
        <Box>
          <img
            className={styles.left_main_container}
            src={`${SETTINGS.STATIC_URL}boostmodal/boostmodalwithbtrstbanner.svg`}
            alt="banner"
          />
        </Box>
      );
    } else {
      return (
        <OfferVisual
          wrapperClassName={styles["onramp-modal__offer-visual"]}
          exchangeFrom={{
            currency: "USD",
            avatar_thumbnail: `${SETTINGS.STATIC_URL}flags/us-round.svg`,
            has_avatar_set: false,
            gravatar: "",
          }}
          exchangeTo={{
            currency: "BTRST",
            avatar_thumbnail: "",
            has_avatar_set: false,
            gravatar: "",
          }}
          icon={<SwapVerticalIcon />}
          isInverse
          largePill={!isMobile}
        />
      );
    }
  };

  const totalEstimateSection = () => {
    if (btrst) {
      return (
        <>
          <Box display={"flex"} justifyContent={"space-between"} gap={1}>
            <Typography component="p" size="medium">
              3 Application Boosts
            </Typography>
            <Typography component="p" size="medium">
              39 BTRST
            </Typography>
          </Box>
          <Box display={"flex"} justifyContent={"space-between"} gap={1}>
            <Typography component="p" size="medium" fontWeight={500}>
              Total
            </Typography>
            <Typography component="p" size="medium" fontWeight={500}>
              39 BTRST
            </Typography>
          </Box>
        </>
      );
    } else {
      return (
        <>
          <Box display={"flex"} justifyContent={"space-between"} gap={1}>
            <Typography component="p" size="medium">
              BTRST
            </Typography>
            <Typography component="p" size="medium">
              {boostCost.toFixed(2)} BTRST
            </Typography>
          </Box>

          <Box display={"flex"} justifyContent={"space-between"} gap={1}>
            <Typography component="p" size="medium" fontWeight={500}>
              Total you need to buy
            </Typography>
            <Typography component="p" size="medium" fontWeight={500}>
              {requiredBTRST.toFixed(2)} BTRST
            </Typography>
          </Box>
        </>
      );
    }
  };

  return (
    <Box display={"flex"} justifyContent={"center"} py={5}>
      <Box maxWidth={"361px"} p={2}>
        {topImageSection()}

        <Box>
          <Typography
            component="h2"
            variant="title"
            size="small"
            fontWeight={400}
            mt={3}
            mb={2}
          >
            {btrst
              ? "Purchase Application Boosts"
              : "Purchase BTRST for Application Boosts"}
          </Typography>
        </Box>

        <Box display={"flex"} flexDirection={"column"} gap={2}>
          <hr />

          <Box display={"flex"} flexDirection={"column"} gap={1}>
            {totalEstimateSection()}
          </Box>

          <hr />

          <Box bgcolor={"#F5F8FF"} p={2} borderRadius={3}>
            <Typography component="p" size="small">
              We'll notify you when your BTRST {btrst ? "are" : "is"} available.
              This could take up to 5 minutes.
            </Typography>
          </Box>

          <Box mt={1}>
            <Button
              disabled={isLoading}
              onClick={btrst ? clickOnPurchaseBoost : ClickOnCoinbase}
              variant="positive"
              sx={{
                height: "56px",
                borderRadius: "10px !important",
                width: "100%",
              }}
              endIcon={
                <img
                  src={`${SETTINGS.STATIC_URL}boostmodal/back-right.svg`}
                  alt="diagonalright"
                />
              }
            >
              {btrst
                ? "Purchase Application Boosts"
                : "Get started with Coinbase"}
            </Button>
          </Box>
          <Box mt={2}>
            <PoweredByCoinbase />
          </Box>
        </Box>
      </Box>{" "}
    </Box>
  );
};
