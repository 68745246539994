import React from "react";

import { Box } from "@hexocean/braintrust-ui-components";

type ColorSwitchingLoaderProps = {
  width?: number;
};

export const ColorSwitchingLoader: React.FC<ColorSwitchingLoaderProps> = ({
  width = 120,
}) => {
  return (
    <Box
      display="flex"
      position="absolute"
      top={0}
      bottom={0}
      left={0}
      right={0}
      justifyContent="center"
      alignItems="center"
    >
      <Box className="color-switching-loader" width={width}>
        <svg className="circular" viewBox="25 25 50 50">
          <circle
            className="path"
            cx="50"
            cy="50"
            r="20"
            fill="none"
            strokeWidth="2"
            strokeMiterlimit="10"
          />
        </svg>
      </Box>
    </Box>
  );
};
