import {
  Box,
  Button,
  IconButton,
  Typography,
} from "@hexocean/braintrust-ui-components";
import {
  CloseIcon,
  ErrorSVGIcon,
} from "@hexocean/braintrust-ui-components/Icons";

import type { ATSIntegrationErrorListItem } from ".";
import { showDetailsModal } from "./integration-error-detail-modal";

import style from "./style.module.scss";

type IntegrationErrorItemProps = {
  error: ATSIntegrationErrorListItem;
  hideError: (ATSIntegrationErrorListItem) => void;
};

export const IntegrationErrorItem = ({
  error,
  hideError,
}: IntegrationErrorItemProps) => {
  return (
    <Box className={style.integrationErrorItem}>
      <Box className={style.integrationErrorItemContent}>
        <ErrorSVGIcon className={style.errorIcon} />
        <Typography component="span">{error.name}</Typography>
      </Box>
      <Button
        variant="transparent"
        size="x-small"
        className={style.integrationErrorItemButton}
        onClick={() => showDetailsModal(error)}
      >
        Show details
      </Button>
      <IconButton
        size="small"
        variant="tertiary"
        aria-label="Close error"
        onClick={() => hideError(error)}
      >
        <CloseIcon
          sx={{
            borderRadius: "50%",
          }}
        />
      </IconButton>
    </Box>
  );
};
