import classNames from "classnames";

import { Typography } from "@hexocean/braintrust-ui-components";
import { RouterLink } from "@js/components/link";

import type { INVOICE_LISTING_FILTERS_OPTIONS } from "../../constants";
import type { InvoiceFiltersFetchParams } from "../../filters";

import { useInvoiceListingFiltersItem } from "./use-invoice-listing-fitlers-item";

import styles from "./styles.module.scss";

export type InvoiceListingFiltersItemProps = {
  filters: Partial<InvoiceFiltersFetchParams>;
  pathname: string;
  option: (typeof INVOICE_LISTING_FILTERS_OPTIONS)[number];
};

export const InvoiceListingFiltersItem = ({
  filters,
  pathname,
  option,
}: InvoiceListingFiltersItemProps) => {
  const { isActive, to } = useInvoiceListingFiltersItem({
    filters,
    pathname,
    query: option.query,
  });

  return (
    <Typography
      to={to}
      component="link"
      RouterLink={RouterLink}
      className={classNames(styles.tabButton, {
        [styles.isActive]: isActive,
      })}
      size="small"
      color={!isActive ? "grey-2" : "primary"}
      fontWeight={500}
      textTransform="uppercase"
    >
      {option.name}
    </Typography>
  );
};
