/* eslint-disable react/jsx-pascal-case */
import React from "react";

import {
  Box,
  Button,
  capitalize,
  Tooltip,
} from "@hexocean/braintrust-ui-components";
import { ArrowRightIcon } from "@hexocean/braintrust-ui-components/Icons";
import { useJobActionBarContext } from "@js/apps/jobs/components/job-details-action-bar/job-context";
import { useGetDefaultActionsContextValue } from "@js/apps/jobs/hooks/get-default-job-actions";
import { RouterLink } from "@js/components/link";
import {
  assertUnreachable,
  capitalizeEachFirstLetter,
  dateFromNow,
  dateToFromNowDaily,
  isDateAfterToday,
} from "@js/utils";

import { WithJobActionsContext } from "../../job-actions";
import { useJobAction } from "../../job-actions/jobs-actions-object";
import { Status } from "../../status";
import { FREELANCER_TITLES, TALENT_STATUS } from "../constants";
import { DefaultActionBar } from "../default-action-bar";

import { useTalentActionBarContext } from "./context";

export const TalentActionBar = () => {
  const { job } = useTalentActionBarContext();

  if (!job) return null;

  switch (job.job_status) {
    case ENUMS.JobStatus.OPEN:
    case ENUMS.JobStatus.ACTIVE_NOT_FILLED: {
      return <TalentActionBar_JobOpen />;
    }
    case ENUMS.JobStatus.ACTIVE:
    case ENUMS.JobStatus.ACTIVE_FILLED: {
      return <TalentActionBar_JobActive />;
    }
    case ENUMS.JobStatus.ON_HOLD: {
      return <TalentActionBar_JobOnHold />;
    }
    case ENUMS.JobStatus.COMPLETED_HIRED: {
      return <TalentActionBar_CompletedHired />;
    }
    case ENUMS.JobStatus.COMPLETED_NOT_HIRED: {
      return <TalentActionBar_CompletedNotHired />;
    }
    case ENUMS.JobStatus.COMPLETED: {
      return null;
    }
    default: {
      assertUnreachable(job.job_status);
      return null;
    }
  }
};

const TalentActionBar_JobOpen = () => {
  const { talentStatus, job } = useTalentActionBarContext();

  switch (talentStatus) {
    case TALENT_STATUS.NO_ACTION: {
      return <Status job={job} longTile bid={undefined} variant="mobile-top" />;
    }

    case TALENT_STATUS.APPLICATION_IN_REVIEW:
      return <ApplicationInReview />;
    case TALENT_STATUS.PROPOSED: {
      return <YouApplied />;
    }
    case TALENT_STATUS.IN_SCREENING: {
      return <YouAreInScreening />;
    }
    case TALENT_STATUS.OFFER_MADE: {
      return <YouGotTheOffer />;
    }
    case TALENT_STATUS.DECLINED:
    case TALENT_STATUS.OFFER_DECLINED: {
      return <YouWereNotHired />;
    }
    case TALENT_STATUS.HIRED: {
      return <YouGotTheJob />;
    }
    default: {
      assertUnreachable(talentStatus);
      return null;
    }
  }
};

const TalentActionBar_JobActive = () => {
  const { talentStatus } = useTalentActionBarContext();

  switch (talentStatus) {
    case TALENT_STATUS.NO_ACTION: {
      return (
        <NotAcceptingProposals subtitle="Project is Active. Client Hired Braintrust Talent" />
      );
    }
    case TALENT_STATUS.PROPOSED:
    case TALENT_STATUS.IN_SCREENING:
    case TALENT_STATUS.DECLINED:
    case TALENT_STATUS.OFFER_DECLINED:
    case TALENT_STATUS.APPLICATION_IN_REVIEW:
    case TALENT_STATUS.OFFER_MADE: {
      return <YouWereNotHired />;
    }
    case TALENT_STATUS.HIRED: {
      return <YouGotTheJob />;
    }
    default: {
      assertUnreachable(talentStatus);
      return null;
    }
  }
};

const TalentActionBar_JobOnHold = () => {
  const { talentStatus } = useTalentActionBarContext();

  switch (talentStatus) {
    case TALENT_STATUS.NO_ACTION: {
      return <NotAcceptingProposals subtitle="Job is Paused." />;
    }
    case TALENT_STATUS.PROPOSED:
    case TALENT_STATUS.IN_SCREENING:
      return <YouAppliedButOnHold />;
    case TALENT_STATUS.APPLICATION_IN_REVIEW:
      return <ApplicationInReview />;
    case TALENT_STATUS.OFFER_MADE:
      return <YouGotTheOffer />;
    case TALENT_STATUS.DECLINED:
    case TALENT_STATUS.OFFER_DECLINED:
      return <YouWereNotHired />;
    case TALENT_STATUS.HIRED: {
      return <YouGotTheJob />;
    }
    default: {
      assertUnreachable(talentStatus);
      return null;
    }
  }
};

const TalentActionBar_CompletedHired = () => {
  const { talentStatus } = useTalentActionBarContext();

  switch (talentStatus) {
    case TALENT_STATUS.NO_ACTION: {
      return (
        <NotAcceptingProposals subtitle="Client Hired Braintrust Talent. Project is Complete." />
      );
    }
    case TALENT_STATUS.PROPOSED:
    case TALENT_STATUS.IN_SCREENING:
    case TALENT_STATUS.OFFER_MADE:
    case TALENT_STATUS.DECLINED:
    case TALENT_STATUS.OFFER_DECLINED:
    case TALENT_STATUS.APPLICATION_IN_REVIEW:
      return <YouWereNotHired />;
    case TALENT_STATUS.HIRED: {
      return <YouFinishedTheJob />;
    }
    default: {
      assertUnreachable(talentStatus);
      return null;
    }
  }
};

const TalentActionBar_CompletedNotHired = () => {
  const { talentStatus } = useTalentActionBarContext();

  switch (talentStatus) {
    case TALENT_STATUS.NO_ACTION: {
      return (
        <NotAcceptingProposals subtitle="Job Closed. No Braintrust Talent Hired." />
      );
    }
    case TALENT_STATUS.PROPOSED:
    case TALENT_STATUS.IN_SCREENING:
    case TALENT_STATUS.OFFER_MADE:
    case TALENT_STATUS.DECLINED:
    case TALENT_STATUS.OFFER_DECLINED:
    case TALENT_STATUS.APPLICATION_IN_REVIEW:
      return <YouWereNotHired />;
    case TALENT_STATUS.HIRED:
      return null;
    default: {
      assertUnreachable(talentStatus);
      return null;
    }
  }
};

const YouApplied = (props) => {
  return (
    <DefaultActionBar
      title={FREELANCER_TITLES.YOU_APPLIED}
      bgcolor="var(--dark-blue)"
      subtitle={<ProposalSubmittedTimeAgo />}
      actions={[
        <ViewProposalButton key="view-proposal-button" />,
        <BrowseOpenJobsButton key="open-jobs-button" />,
      ]}
      {...props}
    />
  );
};

const ApplicationInReview = (props) => {
  return (
    <DefaultActionBar
      title={FREELANCER_TITLES.APPLICATION_IN_REVIEW}
      bgcolor="var(--dark-blue)"
      subtitle={<ProposalSubmittedTimeAgo />}
      actions={[<BrowseOpenJobsButton key="open-jobs-button" />]}
      {...props}
    />
  );
};

const YouAreInScreening = (props) => {
  return (
    <DefaultActionBar
      title={FREELANCER_TITLES.YOU_ARE_IN_SCREENING}
      bgcolor="var(--dark-blue)"
      subtitle={<ProposalSubmittedTimeAgo />}
      actions={[
        <ViewProposalButton key="view-proposal-button" />,
        <BrowseOpenJobsButton key="open-jobs-button" />,
      ]}
      {...props}
    />
  );
};

const YouWereNotHired = (props) => {
  return (
    <DefaultActionBar
      title={FREELANCER_TITLES.YOU_WERE_NOT_HIRED}
      bgcolor="var(--negative-1)"
      subtitle={<ProposalSubmittedTimeAgo />}
      actions={[<BrowseOpenJobsButton key="open-jobs-button" />]}
      {...props}
    />
  );
};

const YouGotTheOffer = (props) => {
  return (
    <DefaultActionBar
      title={FREELANCER_TITLES.YOU_GOT_THE_OFFER}
      bgcolor="var(--dark-blue)"
      subtitle={<ProposalSubmittedTimeAgo />}
      actions={[
        <ViewOfferButton key="view-offer-button" />,
        <BrowseOpenJobsButton key="open-jobs-button" />,
      ]}
      {...props}
    />
  );
};

const YouGotTheJob = (props) => {
  const { job } = useJobActionBarContext();
  const getSubtitle = () => {
    const startDate = job?.project_starts_at || job?.start_date;
    if (startDate) {
      return isDateAfterToday(startDate)
        ? `Project starts at ${startDate}`
        : `Project started ${startDate}`;
    }
  };

  const jobActionsContextValue = useGetDefaultActionsContextValue();

  return (
    <DefaultActionBar
      title={FREELANCER_TITLES.YOU_GOT_THE_JOB}
      subtitle={getSubtitle()}
      bgcolor="var(--positive-1)"
      actions={[
        <WithJobActionsContext.Provider
          key="submit-invoice"
          value={jobActionsContextValue}
        >
          <SubmitInvoice />
        </WithJobActionsContext.Provider>,
        <ViewOfferButton key="view-offer-button" />,
      ]}
      {...props}
    />
  );
};

const NotAcceptingProposals = (props) => {
  return (
    <DefaultActionBar
      title={FREELANCER_TITLES.NOT_ACCEPTING_PROPOSALS}
      bgcolor="var(--negative-1)"
      subtitle={<ProposalSubmittedTimeAgo />}
      actions={[<ViewOpenJobs key="view-open-jobs" />]}
      {...props}
    />
  );
};

const YouAppliedButOnHold = (props) => {
  return (
    <DefaultActionBar
      title={FREELANCER_TITLES.YOU_APPLIED_BUT_ON_HOLD}
      bgcolor="var(--negative-1)"
      subtitle={<ProposalSubmittedTimeAgo />}
      actions={[<ViewOpenJobs key="view-open-jobs" />]}
      {...props}
    />
  );
};

const YouFinishedTheJob = (props) => {
  const { job } = useTalentActionBarContext();

  return (
    <DefaultActionBar
      title={FREELANCER_TITLES.YOU_FINISHED_THE_JOB}
      subtitle={`Project Ended ${capitalize(
        dateToFromNowDaily(job?.completed_at),
      )}`}
      bgcolor="var(--dark-blue)"
      actions={[
        <BrowseOpenJobsButton key="open-jobs-button" />,
        <ViewInvoicesButton key="view-invoice-button" />,
      ]}
      {...props}
    />
  );
};

const ViewProposalButton = () => {
  const { job, bid } = useTalentActionBarContext();

  return (
    <Button
      variant="secondary"
      inverse
      to={`/jobs/${job?.id}/proposals/${bid?.id}/edit?back=${encodeURIComponent(
        window.location.pathname,
      )}`}
      RouterLink={RouterLink}
    >
      View Application
    </Button>
  );
};

const BrowseOpenJobsButton = () => {
  return (
    <Button variant="primary" to="/jobs/" inverse RouterLink={RouterLink}>
      Browse Open Jobs
      <Box ml={1} display="flex" alignItems="center">
        <ArrowRightIcon />
      </Box>
    </Button>
  );
};

const ViewOpenJobs = () => {
  return (
    <Button variant="primary" inverse to="/jobs/" RouterLink={RouterLink}>
      View Open Jobs
      <Box ml={1} display="flex" alignItems="center">
        <ArrowRightIcon />
      </Box>
    </Button>
  );
};

const ViewOfferButton = () => {
  const { bid } = useTalentActionBarContext();

  return (
    <Button
      variant="primary"
      inverse
      RouterLink={RouterLink}
      to={`/jobs/${bid?.job.id}/offers/${
        bid?.current_offer?.id
      }/?back=${encodeURIComponent(window.location.pathname)}`}
    >
      View Offer
    </Button>
  );
};

const ProposalSubmittedTimeAgo = () => {
  const { bid } = useTalentActionBarContext();

  if (!bid) return null;

  const postedAgo = dateFromNow(bid?.created);

  return <>Application Submitted {capitalizeEachFirstLetter(postedAgo)}</>;
};

const SubmitInvoice = () => {
  const { job } = useTalentActionBarContext();
  const { handleJobAction, JobActionModalInstance, createInvoiceAction } =
    useJobAction();
  const invoiceAction = createInvoiceAction(job);

  if (!invoiceAction) return null;

  const isBtnDisabled = invoiceAction.state === ENUMS.JobActionStates.DISABLED;

  return (
    <>
      <Tooltip title={invoiceAction.reason} disabled={!isBtnDisabled}>
        <span style={{ display: "inline-block" }}>
          <Button
            variant="secondary"
            inverse={!isBtnDisabled}
            disabled={isBtnDisabled}
            onClick={() => {
              if (job) handleJobAction(job, ENUMS.JobActions.CREATE_INVOICE);
            }}
          >
            Submit Invoice
          </Button>
        </span>
      </Tooltip>
      <JobActionModalInstance />
    </>
  );
};

const ViewInvoicesButton = () => {
  return (
    <Button
      variant="primary"
      inverse
      to="/talent/invoices/"
      RouterLink={RouterLink}
    >
      View Invoices
    </Button>
  );
};
