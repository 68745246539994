import type { MaybeDrafted } from "@reduxjs/toolkit/dist/query/core/buildThunks";

import type { PaginatedResult } from "@js/types/generic";
import type { JobListingJob } from "@js/types/jobs";
import { findEntity, findEntityIndex, normalizePaginatedData } from "@js/utils";

type JobsDraft = MaybeDrafted<PaginatedResult<JobListingJob> | JobListingJob[]>;
type SavedJobsDraft = MaybeDrafted<PaginatedResult<JobListingJob>>;

export const addSavedJobId = (
  { id, job }: { id: string; job: string },
  draft: JobsDraft,
) => {
  const normalizedDraft = normalizePaginatedData(draft);

  const jobEntity = findEntity(normalizedDraft, Number(job));

  if (!jobEntity) return;

  jobEntity.saved_job_id = Number(id);
};

export const deleteSavedJobId = (id: number, draft: JobsDraft) => {
  const normalizedDraft = normalizePaginatedData(draft);

  const jobEntity = findEntity(normalizedDraft, id, "saved_job_id");

  if (!jobEntity) return;

  jobEntity.saved_job_id = null;
};

export const deleteSavedJob = (id: number, draft: SavedJobsDraft) => {
  const jobEntityIndex = findEntityIndex(draft.results, id, "saved_job_id");

  if (jobEntityIndex === -1) return;

  draft.count -= 1;
  draft.results.splice(jobEntityIndex, 1);
};
