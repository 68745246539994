import type { WrappedFieldProps } from "redux-form";

import { useJobErrorSectionErrors } from "@js/apps/jobs/apps/create-job/components/job-error-section/use-job-error-section-errors";
import { ErrorItem, ErrorSection } from "@js/components/error-section";

export const JobErrorSection = ({ meta }: WrappedFieldProps) => {
  const { requiredErrors, errors, getMessage } = useJobErrorSectionErrors(meta);

  if (!meta.submitFailed) return null;

  return (
    <ErrorSection
      meta={meta}
      requiredErrors={requiredErrors}
      errorMessage={getMessage()}
    >
      <>
        <ErrorItem
          names={["title"]}
          sectionName="Job Title"
          message="Please add a job title"
          requiredErrors={requiredErrors}
        />
        <ErrorItem
          names={["openings_number"]}
          sectionName="Openings Role"
          message="Please specify openings available"
          requiredErrors={requiredErrors}
          customLink="set_up/#openings-number"
        />
        <ErrorItem
          names={["role"]}
          sectionName="Category"
          message="Please select a category for this job"
          requiredErrors={requiredErrors}
        />
        <ErrorItem
          names={[
            "contract_type",
            "expected_hours_per_week",
            "location",
            "timezone",
            "timezone_overlap",
          ]}
          sectionName="Job Details"
          message="Please fill the job details"
          requiredErrors={requiredErrors}
        />
        <ErrorItem
          names={["new_skills", "top_skills"]}
          sectionName="Skills"
          message="Please select skills and choose your top skills"
          requiredErrors={requiredErrors}
        />
        <ErrorItem
          names={["experience_level", "role"]}
          sectionName="Experience"
          message="Please select years of experience"
          requiredErrors={requiredErrors}
        />
        <ErrorItem
          names={[
            "min_rate",
            "max_rate",
            "min_annual_rate",
            "max_annual_rate",
            "fixed_rate",
            "payment_type",
          ]}
          sectionName="Rate"
          message="Please add a pay rate"
          requiredErrors={requiredErrors}
        />
        <ErrorItem
          names={["requirements"]}
          sectionName="Requirements"
          message="Please specify requirements for this job"
          requiredErrors={requiredErrors}
        />
        <ErrorItem
          names={["description"]}
          sectionName="Job Description"
          message="Please add a job description"
          requiredErrors={requiredErrors}
        />
        <ErrorItem
          names={["new_application_questions"]}
          sectionName="Application Questions"
          message="Please add questions for talent"
          requiredErrors={requiredErrors}
        />
        <ErrorItem
          names={["job_subscriber_invitations"]}
          sectionName="Add Team Members"
          message="Please review and adjust team member invitations"
          requiredErrors={errors}
          sectionLink={false}
        />
      </>
    </ErrorSection>
  );
};
