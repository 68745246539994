import React from "react";
import { submit } from "redux-form";

import { Typography } from "@hexocean/braintrust-ui-components";
import { PERSONAL_INFO_FORM_ID } from "@js/apps/common/constants";
import { EmployerEditWizard } from "@js/apps/employer/components/employer-edit-wizard";
import { PersonalInfoForm } from "@js/apps/employer/forms/personal-info";
import { usePersonalInfoForm } from "@js/apps/employer/hooks";
import { Snackbar } from "@js/components/snackbar";
import { useAppDispatch, useAppSelector } from "@js/hooks";

const EmployerEditPersonalInfoPage = () => {
  const { onSubmit, initialValues } = usePersonalInfoForm();

  const dispatch = useAppDispatch();

  const onContinue = () => dispatch(submit(PERSONAL_INFO_FORM_ID));

  const isAvatarUploading = useAppSelector(
    (state) => state.employer.avatarFieldUploading,
  );

  return (
    <EmployerEditWizard
      pageTitle="Edit Profile | Personal Info"
      continueButtonColor="secondary"
      onContinue={onContinue}
      disableSubmit={isAvatarUploading}
    >
      <Typography
        component="h1"
        variant="title"
        textTransform="uppercase"
        className="wizard-title"
      >
        Personal Info
      </Typography>
      <PersonalInfoForm
        initialValues={initialValues}
        onSubmit={onSubmit}
        onSubmitSuccess={() => {
          Snackbar.success("Saved Successfully!");
        }}
      />
    </EmployerEditWizard>
  );
};

export default EmployerEditPersonalInfoPage;
