import cs from "classnames";

import {
  Avatar,
  Box,
  ButtonCore,
  Typography,
} from "@hexocean/braintrust-ui-components";
import type { MessengerContextData } from "@js/apps/messenger/context/messenger-context";
import type { MessageRoom } from "@js/types/messenger";
import { formatDate } from "@js/utils";
import { DateFormats } from "@js/utils/date/types";

import { HelpOfferStatusBar } from "../action-bar/help-offer-status-bar";

type RoomListItemProps = {
  room: MessageRoom;
  isSelectedRoom: boolean;
  changeRoom: MessengerContextData["setActiveRoom"];
};

export const RoomListItem = ({
  room,
  isSelectedRoom = false,
  changeRoom,
}: RoomListItemProps) => {
  const {
    participants,
    interlocutors,
    name,
    active_help_offers_count,
    pending_help_offers_count,
    last_message_timestamp,
    last_message_content,
    unread_message_count,
    is_last_message_spam,
    last_message_author_id,
  } = room;

  const participant = participants.find(({ id }) => id === interlocutors[0]);

  if (!participant) {
    return <></>;
  }

  const warningMessage =
    participant.id !== last_message_author_id
      ? "Your message has been flagged for spam."
      : "This user’s message has been flagged for spam.";

  return (
    <ButtonCore
      aria-label={name}
      disableRipple
      onClick={() => !isSelectedRoom && changeRoom(room.id)}
      className={cs("room-list__item", {
        "room-list__item--active": isSelectedRoom,
      })}
      role="button"
      tabIndex={0}
    >
      <div className="room-list__item-head">
        <Avatar
          className="room-list__item-avatar"
          size="md"
          src={participant}
        />

        <div className="room-list__item-info">
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography
              component="span"
              variant="label"
              fontWeight={500}
              ellipsis
            >
              {name}
            </Typography>

            <Typography
              component="span"
              variant="paragraph"
              size="small"
              color="tertiary"
              whiteSpace="nowrap"
              sx={{
                pl: 0.5,
              }}
            >
              {formatRoomLastMessageTimestamp(last_message_timestamp)}
            </Typography>
          </Box>
          <div className="room-list__desc">
            {is_last_message_spam ? (
              <Typography
                component="span"
                fontWeight={400}
                size="small"
                textAlign="left"
              >
                ⚠️ {warningMessage}
              </Typography>
            ) : (
              <Typography
                component="span"
                fontWeight={400}
                size="small"
                title={last_message_content}
                ellipsis
              >
                {last_message_content}
              </Typography>
            )}
            {unread_message_count > 0 && (
              <Typography
                component="span"
                fontWeight={400}
                variant="label"
                size="small"
                className="room-list__unread-count"
              >
                {unread_message_count}
              </Typography>
            )}
          </div>
          {!!(active_help_offers_count || pending_help_offers_count) && (
            <HelpOfferStatusBar
              participant={participant}
              activeOffers={active_help_offers_count}
              pendingOffers={pending_help_offers_count}
            />
          )}
        </div>
      </div>
    </ButtonCore>
  );
};

const formatRoomLastMessageTimestamp = (timestamp: string | null) => {
  if (!timestamp) return;

  const sentTimestamp = new Date(timestamp).getTime();
  const currentTimestamp = Date.now();
  const diff = currentTimestamp - sentTimestamp;
  const minuteInMs = 1000 * 60;
  const hourInMs = minuteInMs * 60;
  const dayInMs = hourInMs * 24;

  if (diff > dayInMs * 7) {
    return formatDate(sentTimestamp, DateFormats["Jan 1, 1970"]);
  }
  if (diff > dayInMs) {
    return `${Math.floor(diff / dayInMs)}d ago`;
  }
  if (diff > hourInMs) {
    return `${Math.floor(diff / hourInMs)}h ago`;
  }
  if (diff > minuteInMs) {
    return `${Math.floor(diff / minuteInMs)}m ago`;
  }

  return "just now";
};
/** This wrapper exists just to silent eslint rule  */
export const roomListItemContent = (props: RoomListItemProps) => {
  return <RoomListItem {...props} />;
};
