import {
  Box,
  Grid,
  Rating,
  Typography,
} from "@hexocean/braintrust-ui-components";
import { openLocationMismatchModal } from "@js/apps/admin/components/location-mismatch-modal";
import { RatingCard } from "@js/apps/common/components";
import { useIsNodeStaff } from "@js/apps/common/hooks";
import { LocationWarning } from "@js/apps/freelancer/components/location-warning";
import { ApplicationBoostFeatureFlagWrapper } from "@js/apps/jobs/components/application-boost-feature-flag-wrapper";
import { ApprovedBadge } from "@js/apps/jobs/components/approved-badge-with-tooltip";
import { InvitedBadge } from "@js/apps/jobs/components/invited-badge";
import { JobSkillChip } from "@js/apps/skills/components";
import { Datetime } from "@js/components/date";
import { SocialProfiles } from "@js/components/social-profiles";
import type { EmployerBidListBid, Job } from "@js/types/jobs";
import { getEnumLabel, pluralize } from "@js/utils";
import { DateTimeFormats } from "@js/utils/date/types";

import { BidAvatarWithScoreMatch } from "../bid-avatar-with-score-match";
import {
  getBidRole,
  getBidUserName,
  getRateLabel,
} from "../bid-list-item/helpers";
import { BoostBidBadge } from "../boosted-bid/boosted-bid-badge";
import { MatchSummaryChip } from "../match-summary";

export type BidDetailsLeftPanelProps = {
  bid: EmployerBidListBid;
  job: Job;
};

const getBidAvailabilityText = (bid: EmployerBidListBid) => {
  const { freelancer_availability, freelancer_availability_specific_date } =
    bid;

  if (!freelancer_availability) {
    return "Not specified";
  }

  if (
    freelancer_availability_specific_date &&
    freelancer_availability === ENUMS.FreelancerAvailability.SPECIFIC_DATE
  ) {
    return `Available on ${freelancer_availability_specific_date}`;
  }

  if (freelancer_availability !== ENUMS.FreelancerAvailability.SPECIFIC_DATE) {
    return `Available ${getEnumLabel(
      ENUMS.FreelancerAvailabilityLabels,
      freelancer_availability,
    ).toLowerCase()}`;
  }
};

export const BidDetailsLeftPanel = ({ bid, job }: BidDetailsLeftPanelProps) => {
  const isNodeStaff = useIsNodeStaff();
  const isLocationMismatched =
    bid.freelancer.location_mismatch || !bid.freelancer.location;
  const displayLocationMismatch = isLocationMismatched && isNodeStaff;
  const handleLocationWarningClick = () => {
    openLocationMismatchModal({ userId: bid.freelancer.id });
  };

  const showMatchScore =
    ENUMS.SmartMatchingLabels[bid.match_label] !==
    ENUMS.SmartMatchingLabels.unavailable;

  return (
    <Grid item xs={12} sm={4}>
      <Box display="flex" columnGap={2}>
        <Box width={103} height={103} minWidth={50} flexShrink={0}>
          <BidAvatarWithScoreMatch bid={bid} avatarTextSize="medium" />
        </Box>
        <Box>
          <Box
            sx={{
              display: "inline-flex",
              gap: 1,
              mb: 2,
              overflow: "hidden",
              overflowWrap: "anywhere",
            }}
          >
            <Typography
              component="span"
              variant="paragraph"
              size="large"
              multilineEllipsis={2}
            >
              {getBidUserName(bid)}
            </Typography>
            <Box sx={{ mt: "3px" }}>
              <ApprovedBadge
                isApproved={bid.freelancer.user.freelancer_approved}
              />
            </Box>
          </Box>
          <Typography component="p" variant="label" size="small">
            {getBidRole(bid)}
          </Typography>
          {(bid.freelancer.location || displayLocationMismatch) && (
            <Typography
              component="span"
              variant="label"
              size="small"
              display="flex"
              gap={0.5}
              alignItems="center"
            >
              {bid.freelancer.location}
              {displayLocationMismatch && (
                <LocationWarning onClick={handleLocationWarningClick} />
              )}
            </Typography>
          )}
        </Box>
      </Box>

      <Box mt={3} mb={2} display="flex" columnGap={1}>
        {showMatchScore && <MatchSummaryChip matchLabel={bid.match_label} />}
        <ApplicationBoostFeatureFlagWrapper>
          {bid?.is_boosted && <BoostBidBadge />}
        </ApplicationBoostFeatureFlagWrapper>

        <InvitedBadge bid={bid} />
      </Box>

      <RatingCard freelancer={bid.freelancer} shortTile color="soft-beige" />

      <Box mt={2}>
        <Typography component="span" variant="label" size="small">
          Links
        </Typography>
        <Box mt={1} display="flex">
          <SocialProfiles
            IconButtonProps={{
              size: "medium",
              variant: "transparent-border-beige",
            }}
            profiles={bid.freelancer.external_profiles}
          />
        </Box>
      </Box>

      <Box mt={2}>
        <Box display="flex">
          {bid.payment_amount && (
            <Box pr={6}>
              <Typography
                component="p"
                variant="label"
                size="small"
                textTransform="lowercase"
                className="capitalize-first-letter"
              >
                {getEnumLabel(ENUMS.JobPaymentTypeLabels, bid.payment_type)}
              </Typography>
              <Typography
                component="p"
                variant="paragraph"
                size="medium"
                sx={{ mt: 1 }}
              >
                {getRateLabel(job, bid, { hideSuffix: true })}
              </Typography>
            </Box>
          )}

          {bid.freelancer.years_experience && (
            <Box>
              <Typography component="p" variant="label" size="small">
                Experience
              </Typography>
              <Typography
                component="p"
                variant="paragraph"
                size="medium"
                sx={{ mt: 1 }}
              >
                {bid.freelancer.years_experience +
                  " year" +
                  pluralize(bid.freelancer.years_experience)}{" "}
                in{" "}
                <Typography component="span" variant="label">
                  {bid.freelancer.role.name}
                </Typography>
              </Typography>
            </Box>
          )}
        </Box>

        {getBidAvailabilityText(bid) && (
          <Box mt={2}>
            <Typography component="p" variant="label" size="small">
              Start date
            </Typography>
            <Typography
              component="p"
              variant="paragraph"
              size="medium"
              sx={{ mt: 1 }}
            >
              {getBidAvailabilityText(bid)}
            </Typography>
          </Box>
        )}
      </Box>

      {!!bid.freelancer.superpower_skills.length && (
        <Box mt={2}>
          <Typography component="p" variant="label" size="small">
            Top {bid.freelancer.superpower_skills.length} skills
          </Typography>
          <Box mt={1.5} display="flex" flexWrap="wrap" gap={1}>
            {bid.freelancer.superpower_skills.map((item) => (
              <JobSkillChip key={item.id} skill={item.skill} />
            ))}
          </Box>
        </Box>
      )}

      {isNodeStaff && (
        <>
          {bid.freelancer.average_work_quality && (
            <Box mt={2}>
              <Typography component="p" variant="label" size="small">
                Work quality
              </Typography>
              <Typography
                component="p"
                variant="paragraph"
                size="medium"
                sx={{ mt: 1 }}
              >
                <ReviewItem
                  count={bid.freelancer.review_count}
                  rate={Number(bid.freelancer.average_work_quality).toFixed(1)}
                />
              </Typography>
            </Box>
          )}

          {bid.freelancer.average_responsiveness && (
            <Box mt={2}>
              <Typography component="p" variant="label" size="small">
                Responsiveness
              </Typography>
              <Typography
                component="p"
                variant="paragraph"
                size="medium"
                sx={{ mt: 1 }}
              >
                <ReviewItem
                  count={bid.freelancer.review_count}
                  rate={Number(bid.freelancer.average_responsiveness).toFixed(
                    1,
                  )}
                />
              </Typography>
            </Box>
          )}
        </>
      )}

      <Box my={2}>
        <Typography component="p" variant="label" size="small">
          Applied
        </Typography>
        <Typography
          component="p"
          variant="paragraph"
          size="medium"
          sx={{ mt: 1 }}
        >
          <Datetime
            format={DateTimeFormats["January 1, 1970 at 0:00 am"]}
            date={bid.created}
          />
        </Typography>
      </Box>
    </Grid>
  );
};

const ReviewItem = ({
  count,
  rate,
}: {
  count: number | null;
  rate: string;
}) => {
  return (
    <Box display="flex" alignItems="center">
      <Typography
        component="span"
        variant="label"
        sx={{ pr: 0.5 }}
        size="medium"
      >
        {rate}
      </Typography>
      <Rating
        name="half-rating"
        value={Number(rate)}
        precision={0.5}
        readOnly
        size="small"
        sx={{
          mr: 1,
          mt: 1,
        }}
      />
      {count && (
        <Typography
          component="span"
          sx={{ color: "var(--grey-3)" }}
          variant="label"
          size="medium"
        >
          ({count})
        </Typography>
      )}
    </Box>
  );
};
