import { useCallback, useEffect, useState } from "react";

import { useAccountType } from "@js/apps/common/hooks";
import { useAppSelector } from "@js/hooks";
import { LocalStorage } from "@js/services";

export enum ApplicationInsightType {
  HOME_PAGE_CARD = "application_insight_homepage_card",
  JOB_LIST_BANNER = "application_insight_job_list_banner",
}

type ApplicationInsightIntervalAds = {
  isVerifying: boolean;
  showJobInsightAds: boolean;
  setCloseJobInsightAds: () => void;
};

const useApplicationInsightIntervalAds = (
  type: ApplicationInsightType,
): ApplicationInsightIntervalAds => {
  const [show, setShow] = useState<boolean>(false);
  const [isVerifying, setIsVerifying] = useState<boolean>(true);

  const user = useAppSelector((state) => state.auth.user);
  const { isFreelancer } = useAccountType();

  const getDismissTimeKey = useCallback(() => {
    return type === ApplicationInsightType.HOME_PAGE_CARD
      ? LocalStorage.keys.APPLICATION_INSIGHT_HOMEPAGE_CARD_CLOSE_TIMESTAMP
      : LocalStorage.keys.APPLICATION_INSIGHT_JOB_LIST_BANNER_CLOSE_TIMESTAMP;
  }, [type]);

  const isPastTwoHours = (dismissTime: string | null): boolean => {
    if (!dismissTime) {
      return true;
    }
    const twoHoursLater = new Date(
      parseInt(dismissTime, 10) + 2 * 60 * 60 * 1000,
    );
    return new Date() > twoHoursLater;
  };

  useEffect(() => {
    if (!isFreelancer) {
      setIsVerifying(false);
      return;
    }

    const dismissTimeKey = getDismissTimeKey();
    const dismissTime = LocalStorage.getItem(dismissTimeKey);

    if (!user?.can_view_insights && isPastTwoHours(dismissTime)) {
      setShow(true);
    } else {
      setShow(false);
    }

    setIsVerifying(false);
  }, [
    getDismissTimeKey,
    isFreelancer,
    type,
    user?.can_view_insights,
    user?.freelancer_approved,
  ]);

  const handleCloseJobAds = useCallback(() => {
    setShow(false);
    const localStorageTimeStampKey = getDismissTimeKey();
    LocalStorage.setItem(
      localStorageTimeStampKey,
      String(new Date().getTime()),
    );
  }, [getDismissTimeKey]);

  return {
    isVerifying,
    showJobInsightAds: show,
    setCloseJobInsightAds: handleCloseJobAds,
  };
};

export default useApplicationInsightIntervalAds;
