import type { ReactNode } from "react";
import { forwardRef } from "react";

import { useMediaQuery } from "@hexocean/braintrust-ui-components";
import { Avatar, Box, Typography } from "@hexocean/braintrust-ui-components";
import { ActionsMenu } from "@js/apps/give-and-get-help/components/actions-menu";
import { CommentsSection } from "@js/apps/give-and-get-help/components/comments";
import { PostInteractsSection } from "@js/apps/give-and-get-help/components/post/post-interacts-section";
import { PostContext } from "@js/apps/give-and-get-help/context";
import { getReplyFormId } from "@js/apps/give-and-get-help/form/utils";
import { ReplyForm } from "@js/apps/give-and-get-help/forms/reply-form";
import { useDisableSpecificPostActions } from "@js/apps/give-and-get-help/hooks/use-disable-specific-post-actions";
import { RouterLink } from "@js/components/link";
import type { IPost } from "@js/types/give-and-get-help";

import { PostTitle } from "../../post-title";
import type { OnPostAction } from "../base-post-item/use-base-post-item";
import { useBasePostItem } from "../base-post-item/use-base-post-item";

import { SocialProofPostBackground } from "./social-proof-post-background";
import { getCelebratoryPostMeta } from "./utils";

import styles from "./style.module.scss";

export type BaseSocialProofPostItemProps = {
  postData: IPost;
  restrictTogglingReplies?: boolean;
  isPublic?: boolean;
  isSinglePostView?: boolean;
  onReplyClick?: OnPostAction;
  onReactionClick?: OnPostAction;
  onViewCommentClick?: OnPostAction;
  disablePostSubscription?: boolean;
};

export const BaseSocialProofPostItem = forwardRef<
  HTMLDivElement,
  BaseSocialProofPostItemProps
>(
  (
    {
      postData,
      restrictTogglingReplies = false,
      isPublic = false,
      isSinglePostView = false,
      onReplyClick,
      onReactionClick,
      onViewCommentClick,
    },
    ref,
  ) => {
    const { postUrl, onAddReply, onReplyFormClick, contextValue, internalRef } =
      useBasePostItem({
        postData,
        isPublic,
        forwardRef: ref,
        onReactionClick,
        onReplyClick,
        onViewCommentClick,
      });
    const isMobile = useMediaQuery("sm");

    const { backgroundColor, celebratoryBackground, confettiFileName } =
      getCelebratoryPostMeta(postData.celebratory_color);

    return (
      <PostContext.Provider value={contextValue}>
        <Box data-testid={`post-${postData.id}`}>
          <Box
            className={styles.postWrapper}
            ref={internalRef}
            bgcolor={backgroundColor}
          >
            <Box
              p={2}
              pb={0}
              style={{
                background: `url("${SETTINGS.STATIC_URL}give-get-help/confetti/${confettiFileName}") no-repeat top center`,
              }}
            >
              <SocialProofPostBackground
                backgroundColor={backgroundColor}
                celebratoryBackground={celebratoryBackground}
              />
              <Box className={styles.postWrapperHeader}>
                <ActionsMenu
                  entity={postData}
                  urlToCopy={postUrl}
                  type="post"
                  isCelebratoryPost={true}
                />
              </Box>
              <Box className={styles.postWrapperContent}>
                <RouterLink
                  to={`/talent/${postData.freelancer.id}/`}
                  style={{ textDecoration: "none" }}
                >
                  <Avatar
                    src={postData.freelancer.user}
                    size={isMobile ? 80 : "xl"}
                  />
                </RouterLink>
                <Box textAlign="center" pt="10px">
                  <PostTitle
                    isSinglePostView={isSinglePostView}
                    title={postData.title}
                    postUrl={postUrl}
                  />
                </Box>

                <Typography
                  component="p"
                  variant="paragraph"
                  size={isMobile ? "medium" : "large"}
                  fontWeight={400}
                  textAlign="center"
                >
                  {postData.text}
                </Typography>
              </Box>
              <Box className={styles.postWrapperFooter}>
                <PostInteractsSection
                  postData={postData}
                  restrictTogglingReplies={restrictTogglingReplies}
                />
              </Box>
            </Box>
            <Box className={styles.postWrapperReplyForm}>
              <ReplyFormWrapper isPublic={contextValue.isPublic}>
                <ReplyForm
                  type="reply-to-post"
                  formId={getReplyFormId(postData.id)}
                  id={postData.id}
                  onReplyClick={onReplyFormClick}
                  onAddReply={onAddReply}
                  isCelebratoryPost={true}
                />
              </ReplyFormWrapper>

              <CommentsSection
                isPublic={isPublic}
                isSinglePostView={isSinglePostView}
                post={postData}
              />
            </Box>
          </Box>
        </Box>
      </PostContext.Provider>
    );
  },
);

const ReplyFormWrapper = ({
  children,
  isPublic,
}: {
  children: ReactNode;
  isPublic: boolean;
}) => {
  const disablePostActions = useDisableSpecificPostActions();

  if (disablePostActions || isPublic) return null;

  return children;
};
