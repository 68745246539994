import { Button } from "@hexocean/braintrust-ui-components";
import { useCompleteHelpOffer } from "@js/apps/common/hooks/use-help-offer/use-complete-help-offer";
import { useRequestHelpOfferApproval } from "@js/apps/common/hooks/use-help-offer/use-request-help-offer-approval";
import type { HelpOfferButtonsStatusProps } from "@js/apps/messenger/components/action-bar/action-bar-offer-buttons/types";

export const HelpOfferButtonsAccepted = ({
  offer,
  isOfferAuthor,
}: HelpOfferButtonsStatusProps): JSX.Element | null => {
  const { handleCompleteOffer, isAccepting } = useCompleteHelpOffer({ offer });
  const { handleRequestApproval, isRequestingApproval } =
    useRequestHelpOfferApproval({ offer });

  const disableButtons = isRequestingApproval || isAccepting;

  return (
    <>
      <Button
        variant="primary"
        shape="squared"
        className="help-offer-action-buttons__primary"
        onClick={isOfferAuthor ? handleRequestApproval : handleCompleteOffer}
        disabled={disableButtons}
      >
        Mark complete
      </Button>
    </>
  );
};
