import { Alert, Typography } from "@hexocean/braintrust-ui-components";
import { RouterLink } from "@js/components/link";

export const FreelancerHeroInfo = () => {
  return (
    <div>
      <Alert type="warning" className="mb" withIcon>
        <Typography component="p" size="small">
          You are paid as soon as the Client pays Braintrust. Learn more about
          payments here:&nbsp;
          <Typography
            component="link"
            href={SETTINGS.PAYMENTS_URL}
            rel="noopener noreferrer"
            target="_blank"
            variant="label"
            size="small"
            RouterLink={RouterLink}
          >
            {SETTINGS.PAYMENTS_URL}
          </Typography>
        </Typography>
      </Alert>
      <Alert type="warning" className="mb" withIcon>
        <Typography component="p" size="small">
          Once the invoice payment is processing, the money transfer can happen
          in a few hours or up to a few days depending on your bank. If you have
          any other questions, learn more in the&nbsp;
          <Typography
            component="link"
            href={SETTINGS.SUPPORT_URLS.TALENT_FAQ_ARTICLE_URL}
            target="_blank"
            rel="noopener noreferrer"
            variant="label"
            size="small"
            RouterLink={RouterLink}
          >
            help center
          </Typography>
        </Typography>
      </Alert>
    </div>
  );
};
