import { SubmissionError } from "redux-form";
import axios from "axios";

import { SECTIONS_WITH_CHANGES_SAVED } from "@js/apps/jobs/apps/create-job/components/changes-saved-marker/constant";
import { Snackbar } from "@js/components/snackbar";
import type { AppDispatch, AppThunkAction } from "@js/store";
import type {
  JobCreator,
  JobDraft,
  JobToEdit,
  PreparedJobDraftData,
} from "@js/types/jobs";

import { jobsApi } from "../../api";

import * as actionTypes from "./action-types";
import {
  mapManageJobErrors,
  type prepareCreateJobSubmitFormValues,
} from "./utils";

type CreateJobPayload = {
  dry_run: boolean;
  data: PreparedJobDraftData;
  public_after_verification: boolean;
};

export type CreateJobResponse = string | { id: number };

export const createJob =
  ({ dry_run, data, public_after_verification }: CreateJobPayload) =>
  (dispatch: AppDispatch): Promise<CreateJobResponse> => {
    const { id } = data;

    return new Promise((resolve, reject) =>
      axios
        .post<CreateJobResponse>(`/api/manage_jobs/`, {
          ...data,
          dry_run,
          public_after_verification,
        })
        .then(async (response) => {
          if (!dry_run && id) {
            await dispatch(deleteDraft(id));
            dispatch(
              jobsApi.util.invalidateTags([
                { type: "EmployerJobsMinimal" },
                "EmployerHasExceededDailyJobsLimit",
              ]),
            );
          }

          resolve(response.data);
        })
        .catch((error) => {
          const newErrors = mapManageJobErrors(data, error.response.data);
          reject(new SubmissionError(newErrors));
        }),
    );
  };

export const deleteDraft = (id: number) => (dispatch: AppDispatch) =>
  axios
    .delete(`/api/jobs_drafts/${id}/`)
    .then(() => {
      dispatch(
        jobsApi.util.invalidateTags([{ type: "JobDrafts", id: "LIST" }]),
      );
    })
    .catch(() => Snackbar.error("Failed to delete draft."));

export const updateJob = (
  id: number,
  values: Partial<ReturnType<typeof prepareCreateJobSubmitFormValues>>,
): AppThunkAction<Promise<JobDraft>> => {
  return () => {
    return new Promise((resolve, reject) =>
      axios
        .put(`/api/manage_jobs/${id}/`, values)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          const newErrors = mapManageJobErrors(values, error.response.data);
          reject(new SubmissionError(newErrors));
        }),
    );
  };
};

export const patchJob = (id: number, values: Partial<JobToEdit>) =>
  new Promise((resolve, reject) =>
    axios
      .patch(`/api/manage_jobs/${id}/`, values)
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        const newErrors = mapManageJobErrors(values, error.response.data);
        reject(new SubmissionError(newErrors));
      }),
  );

export const clearJobToEdit = () => ({
  type: actionTypes.CLEAR_JOB_TO_EDIT,
});

type FetchJobToEditResponse = {
  lookup: unknown;
  can_change_role: unknown;
  creator: JobCreator;
} & Omit<JobToEdit, "new_application_questions" | "creator">;

export const fetchJobToEdit =
  (id: number): AppThunkAction<Promise<FetchJobToEditResponse>> =>
  (dispatch) => {
    return new Promise((resolve, reject) => {
      dispatch({
        type: actionTypes.FETCH_JOB_TO_EDIT,
      });

      return axios
        .get<FetchJobToEditResponse>(`/api/manage_jobs/${id}/`)
        .then((response) => {
          dispatch({
            type: actionTypes.FETCH_JOB_TO_EDIT_SUCCESS,
            payload: response.data,
          });

          resolve(response.data);
        })
        .catch((e) => {
          dispatch({
            type: actionTypes.FETCH_JOB_TO_EDIT_FAILED,
          });

          reject(e);
        });
    });
  };

export const setFieldsToDisplayChangesSavedMarker = (
  changedFields: Partial<JobDraft>,
) => {
  const keys = Object.keys(changedFields);

  return {
    type: actionTypes.SET_FIELDS_TO_DISPLAY_CHANGES_SAVED_MARKER,
    payload: keys.filter((field) =>
      SECTIONS_WITH_CHANGES_SAVED.some((section) => section === field),
    ),
  };
};

export const hideAllChangesSavedMarkers = () => ({
  type: actionTypes.SET_FIELDS_TO_DISPLAY_CHANGES_SAVED_MARKER,
  payload: [],
});
