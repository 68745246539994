import { useEffect } from "react";
import type { AxiosError } from "axios";

import { updateVisitsCounter } from "@js/apps/common/actions";
import { useIsNodeStaff } from "@js/apps/common/hooks";
import { Snackbar } from "@js/components/snackbar";
import { useAppDispatch, useAppSelector } from "@js/hooks";
import type {
  FreelancerForNodeStaff,
  FreelancerPublic,
} from "@js/types/freelancer";
import { UNEXPECTED_NETWORK_ERROR_MESSAGE } from "@js/utils";

import { fetchFreelancerPublicProfile } from "../../actions";

type UseFreelancerProfileType =
  | {
      isAccessToNodeStaff: true;
      profile: FreelancerForNodeStaff;
      workSampleItems;
    }
  | {
      isAccessToNodeStaff: false;
      profile: FreelancerPublic;
      workSampleItems;
    };

export type UseFreelancerPublicProfileReturnType = UseFreelancerProfileType & {
  user: FreelancerPublic["user"] | null;
};

export const useFreelancerPublicProfile = (
  freelancerId: number | null | undefined,
): UseFreelancerPublicProfileReturnType => {
  const dispatch = useAppDispatch();
  const isNodeStaff = useIsNodeStaff();
  const user = useAppSelector((state) => state.auth.user);
  const profile = useAppSelector((state) => state.freelancer.publicProfile);
  const workSampleItems = useAppSelector(
    (state) => state.freelancer.workSampleItems,
  );

  useEffect(() => {
    if (!freelancerId) {
      return;
    }

    const loadTalentProfile = async (id: number) => {
      try {
        await dispatch(fetchFreelancerPublicProfile(id, { unaltered: true }));
      } catch (error) {
        const axiosError = error as AxiosError;
        if (!!axiosError && !axiosError.response?.status) {
          Snackbar.error(UNEXPECTED_NETWORK_ERROR_MESSAGE);
        }
      }

      updateVisitsCounter(id, ENUMS.VisitsCounterType.TALENT);
    };
    loadTalentProfile(freelancerId);
  }, [dispatch, freelancerId]);

  if (isNodeStaff) {
    return {
      user,
      profile: profile as FreelancerForNodeStaff,
      workSampleItems,
      isAccessToNodeStaff: isNodeStaff,
    };
  }

  return {
    user,
    profile: profile as FreelancerPublic,
    workSampleItems,
    isAccessToNodeStaff: isNodeStaff,
  };
};
