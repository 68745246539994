import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";

import { Box, Carousel, Typography } from "@hexocean/braintrust-ui-components";
import {
  FRONTEND_STORAGE_KEYS,
  useGetStorageValueQuery,
} from "@js/apps/common/frontend-storage";
import { Modal } from "@js/components/modal";

import { HelloEmoji, IntroductionSlide } from "./components";
import { INTRODUCTION_SLIDES } from "./slides-data";

import styles from "./style.module.scss";

export const GGHIntroductionModalInstance = Modal(
  "give-get-help-introduction-modal",
  {
    keepOnBackdropClick: false,
    closeButton: true,
    closeButtonProps: {
      variant: "tertiary",
      size: "x-small",
      className: styles.closeButton,
    },
    padding: false,
    className: styles.modal,
  },
);

const GGHIntroductionModal = () => {
  const { data: isFirstLoginToApp } = useGetStorageValueQuery({
    key: FRONTEND_STORAGE_KEYS.IS_FIRST_LOGIN_TO_APP,
  });
  const navigate = useNavigate();

  const handleNextSlide = useCallback(
    (isLastSlide: boolean) => {
      if (isLastSlide) {
        if (!isFirstLoginToApp) {
          navigate("/feed/");
        }
        closeGGHIntroductionModal();
      }
    },
    [isFirstLoginToApp, navigate],
  );

  return (
    <>
      <Box className={styles.topContent}>
        <Box className={styles.contentHeader}>
          <HelloEmoji />
          <Typography component="h2" fontWeight={500} size="medium">
            Introducing Career Help on Braintrust!
          </Typography>
        </Box>
      </Box>
      <Box className={styles.bottomContent}>
        <Box className={styles.carouselWrapper}>
          <Carousel
            threshold={10}
            className="buc-carousel"
            pagination={{
              clickable: true,
              clickableClass: "swiper-pagination-bullet-clickable",
              bulletClass: "swiper-pagination-bullet",
              bulletActiveClass: "swiper-pagination-bullet-active",
              modifierClass: "swiper-pagination-",
            }}
            slides={INTRODUCTION_SLIDES.map(
              ({ id, header, subheader, images }, index) => {
                const isLastSlide = INTRODUCTION_SLIDES.length - 1 === index;
                return (
                  <IntroductionSlide
                    key={id}
                    header={header}
                    subheader={subheader}
                    images={images}
                    onButtonClick={() => handleNextSlide(isLastSlide)}
                    buttonLabel={isLastSlide ? "Get started" : "Next"}
                  />
                );
              },
            )}
          />
        </Box>
      </Box>
    </>
  );
};

export const openGGHIntroductionModal = ({
  onClose,
}: {
  onClose: () => void;
}) =>
  GGHIntroductionModalInstance.open({
    onClose,
    children: <GGHIntroductionModal />,
  });

const closeGGHIntroductionModal = () => GGHIntroductionModalInstance.close();
