import {
  BoltIcon,
  RefreshIcon,
} from "@hexocean/braintrust-ui-components/Icons";
import type { MessageRoom, Participant } from "@js/types/messenger";

import { HelpOfferStatusBadge } from "./help-offer-status-badge";

type HelpOfferStatusBarProps = {
  activeOffers?: MessageRoom["active_help_offers_count"];
  pendingOffers?: MessageRoom["pending_help_offers_count"];
  participant?: Participant;
};

export const HelpOfferStatusBar = ({
  activeOffers,
  pendingOffers,
  participant,
}: HelpOfferStatusBarProps): JSX.Element | null => {
  if ((!activeOffers && !pendingOffers) || !participant) {
    return null;
  }

  return (
    <div className="help-offer-status-bar">
      <HelpOfferStatusBadge
        status="active"
        count={activeOffers}
        icon={<BoltIcon className="messenger-action-bar__icon bolt-icon" />}
      />
      <HelpOfferStatusBadge
        status="pending"
        count={pendingOffers}
        icon={
          <RefreshIcon className="messenger-action-bar__icon refresh-icon" />
        }
      />
    </div>
  );
};
