import { SubmissionError } from "redux-form";
import { createAction } from "@reduxjs/toolkit";
import axios from "axios";

import {
  AVATAR_FIELD_UPLOADING,
  CLEAR_EMPLOYER_PUBLIC_PROFILE,
  FETCH_EMPLOYER_PROFILE,
  FETCH_EMPLOYER_PROFILE_FAILED,
  FETCH_EMPLOYER_PROFILE_SUCCESS,
  FETCH_EMPLOYER_PUBLIC_PROFILE,
  FETCH_EMPLOYER_PUBLIC_PROFILE_FAILED,
  FETCH_EMPLOYER_PUBLIC_PROFILE_SUCCESS,
  FETCH_EMPLOYER_TEAM_MEMBERS,
  FETCH_EMPLOYER_TEAM_MEMBERS_FAILED,
  FETCH_EMPLOYER_TEAM_MEMBERS_SUCCESS,
  UPDATE_EMPLOYER_PROFILE_SUCCESS,
} from "@js/apps/employer/action-types";
import { Events } from "@js/services/analytics/constants";
import type { AppDispatch } from "@js/store";
import { type AppThunkAction } from "@js/store";
import type { UserMinimalProfile } from "@js/types/auth";
import type { Employer } from "@js/types/employer";

import { deepClone } from "../../utils";

import { employerApi } from "./api";

export const fetchEmployerProfile =
  (employerId?: Employer["id"]): AppThunkAction<Promise<any>> =>
  (dispatch) => {
    const url = employerId
      ? `/api/manage_employers/${employerId}/`
      : `/api/manage_employers/`;

    return new Promise((resolve) => {
      dispatch({
        type: FETCH_EMPLOYER_PROFILE,
      });

      return axios
        .get(url)
        .then((response) => {
          dispatch({
            type: FETCH_EMPLOYER_PROFILE_SUCCESS,
            payload: response.data,
          });

          resolve(response.data);
        })
        .catch(() =>
          dispatch({
            type: FETCH_EMPLOYER_PROFILE_FAILED,
          }),
        );
    });
  };

export const updateEmployerProfile =
  (values, employerProfile): AppThunkAction<Promise<any>> =>
  (dispatch) =>
    new Promise((resolve, reject) => {
      const newValues = deepClone(values);
      delete newValues.logo;

      const url = `/api/manage_employers/${
        employerProfile ? `${employerProfile.id}/` : ""
      }`;

      return axios
        .patch(url, newValues)
        .then((response) => {
          dispatch({
            type: UPDATE_EMPLOYER_PROFILE_SUCCESS,
            payload: response.data,
          });

          resolve(response.data);
        })
        .catch((error) => reject(new SubmissionError(error.response.data)));
    });

export const fetchEmployerPublicProfile =
  (id): AppThunkAction<Promise<any>> =>
  (dispatch) =>
    new Promise((resolve, reject) => {
      dispatch({
        type: FETCH_EMPLOYER_PUBLIC_PROFILE,
      });

      return axios
        .get(`/api/employers/${id}/`)
        .then((response) => {
          dispatch({
            type: FETCH_EMPLOYER_PUBLIC_PROFILE_SUCCESS,
            payload: response.data,
          });

          resolve(response.data);
        })
        .catch((error) => {
          dispatch({
            type: FETCH_EMPLOYER_PUBLIC_PROFILE_FAILED,
          });

          return reject(error.response.data);
        });
    });

export const fetchEmployerTeamMemberInvitations =
  (): AppThunkAction<Promise<any>> => (dispatch) =>
    new Promise((resolve) => {
      dispatch({
        type: FETCH_EMPLOYER_TEAM_MEMBERS,
      });

      axios
        .get("/api/invitations_to_manage_employer/")
        .then((response) => {
          dispatch({
            type: FETCH_EMPLOYER_TEAM_MEMBERS_SUCCESS,
            payload: response.data,
          });

          resolve(response.data);
        })
        .catch(() =>
          dispatch({
            type: FETCH_EMPLOYER_TEAM_MEMBERS_FAILED,
          }),
        );
    });

export const deleteEmployerTeamMemberInvitation = (id) =>
  new Promise((resolve, reject) =>
    axios
      .delete(`/api/invitations_to_manage_employer/${id}/`)
      .then((response) => resolve(response.data))
      .catch((error) => reject(new SubmissionError(error.response.data))),
  );

export const resendEmployerTeamMemberInvitation = ({ id, ...restValues }) =>
  new Promise((resolve, reject) =>
    axios
      .post(`/api/invitations_to_manage_employer/${id}/resend/`, restValues)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error.response.data)),
  );

export const addEmployerTeamMember = (values) =>
  new Promise((resolve, reject) =>
    axios
      .post("/api/manage_employers/invite_new_user/", values)
      .then((response) => resolve(response.data))
      .catch((error) => reject(new SubmissionError(error.response.data))),
  );

export const deleteEmployerTeamMember =
  (id: UserMinimalProfile["id"]) => (dispatch: AppDispatch) =>
    new Promise((resolve, reject) =>
      axios
        .post(`/api/manage_employers/remove_team_member/`, { user: id })
        .then((response) => {
          dispatch(
            employerApi.util.invalidateTags([
              { type: "EmployerTeamMembers", id: "LIST" },
              "JobSubscribers",
            ]),
          );
          resolve(response.data);
        })
        .catch((error) => reject(new SubmissionError(error.response.data))),
    );

export const preventEmployerTeamMemberInvoiceView =
  (id) => (dispatch: AppDispatch) =>
    new Promise((resolve, reject) =>
      axios
        .post(`/api/manage_employers/prevent_from_viewing_invoices/`, {
          user: id,
        })
        .then((response) => {
          dispatch(
            employerApi.util.invalidateTags([
              { type: "EmployerTeamMembers", id: "LIST" },
            ]),
          );
          resolve(response.data);
        })
        .catch((error) => reject(new SubmissionError(error.response.data))),
    );

export const allowEmployerTeamMemberInvoiceView =
  (id) => (dispatch: AppDispatch) =>
    new Promise((resolve, reject) =>
      axios
        .post(`/api/manage_employers/allow_to_view_invoices/`, { user: id })
        .then((response) => {
          dispatch(
            employerApi.util.invalidateTags([
              { type: "EmployerTeamMembers", id: "LIST" },
            ]),
          );
          resolve(response.data);
        })
        .catch((error) => reject(new SubmissionError(error.response.data))),
    );

export const generateJobDescriptionUsingAIInit =
  (employerId?: Employer["id"]): AppThunkAction<Promise<any>> =>
  () =>
    new Promise((resolve, reject) => {
      return axios
        .post("/api/job_post_assistant/initial_request/", {
          short_description: "",
          employer_id: employerId,
        })
        .then((response) => resolve(response.data))
        .catch((error) => reject(error));
    });

export const generateJobDescriptionUsingAI =
  (
    employerId?: Employer["id"],
    jobOverview: string = "",
  ): AppThunkAction<Promise<any>> =>
  () =>
    new Promise((resolve, reject) => {
      return axios
        .post("/api/job_post_assistant/validate/", {
          short_description: jobOverview,
          employer_id: employerId,
        })
        .then((response) => resolve(response.data))
        .catch((error) => {
          return reject(
            new SubmissionError({
              _error:
                error?.response?.data?.detail ||
                "Oops! It seems there's an issue generating a job description. Please try again later",
            }),
          );
        });
    });

export const clearEmployerPublicProfile = () => ({
  type: CLEAR_EMPLOYER_PUBLIC_PROFILE,
});

export const avatarFieldUploading = (isLoading: boolean) => ({
  type: AVATAR_FIELD_UPLOADING,
  payload: isLoading,
});

export const shareApplicantClicked = createAction(Events.CLICK_SHARE_APPLICANT);

export const shareApplicantBulkClicked = createAction(
  Events.CLICK_SHARE_APPLICANT_BULK,
);

export const shareProfileClicked = createAction(Events.CLICK_SHARE_PROFILE);

export const clickSyncJobsATSModal = createAction(
  Events.CLICK_SYNC_JOBS_ATS_MODAL,
);

export const clickSyncATSHomeDashboard = createAction(
  Events.CLICK_SYNC_JOBS_ATS_HOME_DASHBOARD,
);

export const clickNavATSIntegration = createAction(
  Events.CLICK_NAV_ATS_INTEGRATION,
);

export const clickAddIntegrationATS = createAction(
  Events.CLICK_ADD_INTEGRATION_ATS,
);

export const clickResyncATS = createAction(Events.CLICK_RESYNC_ATS);

export const clickDisconnectATS = createAction(Events.CLICK_DISCONNECT_ATS);

export const clickSelectJobsATS = createAction(Events.CLICK_SELECT_JOBS_ATS);

export const clickMoveToDraftsATS = createAction(
  Events.CLICK_MOVE_TO_DRAFTS_ATS,
);

export const clickPublishJobsATS = createAction(Events.CLICK_PUBLISH_JOBS_ATS);
