import { Box } from "@hexocean/braintrust-ui-components";
import { useMessengerContext } from "@js/apps/messenger/context/messenger-context";
import { useActiveRoomData } from "@js/apps/messenger/hooks/use-active-room-data";

import { MessagesBox } from "../messages-box";
import type { MessengerProps } from "../messenger";
import { TextBox } from "../textbox";

export const SingleRoomBox = ({ textBoxProps }: MessengerProps) => {
  const { activeRoom } = useMessengerContext();
  const { activeRoomData } = useActiveRoomData(activeRoom);
  return (
    <Box className="single-room-box">
      <div className="generic-messenger__content">
        <MessagesBox room={activeRoomData} />
        <TextBox room={activeRoomData} {...textBoxProps} />
      </div>
    </Box>
  );
};
