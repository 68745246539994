import { useCallback, useEffect } from "react";
import * as Sentry from "@sentry/react";

import { useUser } from "@js/apps/common/hooks";
import { fetchFreelancerProfile } from "@js/apps/freelancer/actions";
import { useFreelancerProfile } from "@js/apps/freelancer/hooks";
import { useMarkCareerHelpAsVisitedMutation } from "@js/apps/give-and-get-help/api";
import { useAppDispatch } from "@js/hooks";

export const useMarkCareerHelpVisited = () => {
  const user = useUser();
  const freelancerProfile = useFreelancerProfile();
  const dispatch = useAppDispatch();
  const [markCareerHelpAsVisited] = useMarkCareerHelpAsVisitedMutation();

  const markGGHAsVisited = useCallback((): Promise<void | string> => {
    return markCareerHelpAsVisited()
      .unwrap()
      .then(() => {
        if (user?.freelancer) {
          dispatch(fetchFreelancerProfile(user?.freelancer));
        }
      })
      .catch((error) =>
        Sentry.captureException("MarkCareerHelpVisited", {
          extra: { error },
        }),
      );
  }, [markCareerHelpAsVisited, dispatch, user?.freelancer]);

  useEffect(() => {
    if (
      !!freelancerProfile &&
      !freelancerProfile?.user?.has_visited_career_help &&
      !user?.is_impersonated_session
    ) {
      markGGHAsVisited();
    }
  }, [freelancerProfile, user?.is_impersonated_session, markGGHAsVisited]);
};
