import React from "react";

import { Box } from "@hexocean/braintrust-ui-components";
import { useUser } from "@js/apps/common/hooks";
import type { FreelancerBid, Job } from "@js/types/jobs";

import { JobDetailsActionBar } from "../job-details-action-bar";

import { JobDetailsHeader } from "./job-details-header";

type JobHeaderProps = {
  job: Job;
  bid?: FreelancerBid;
  className?: string;
  withDetailsHeader?: boolean;
};

export const JobHeader = ({
  job,
  bid,
  className,
  withDetailsHeader,
}: JobHeaderProps) => {
  const user = useUser();

  return (
    <div className={className}>
      {job && (
        <>
          {user && (
            <Box mb={6}>
              <JobDetailsActionBar job={job} bid={bid} />
            </Box>
          )}
          {withDetailsHeader && <JobDetailsHeader job={job} bid={bid} />}
        </>
      )}
    </div>
  );
};
