import React from "react";
import type { FormErrors, WrappedFieldProps } from "redux-form";
import { Field, getFormSyncErrors } from "redux-form";
import _ from "underscore";

import { useAppSelector } from "@js/hooks";

type SyncErrorsProps = WrappedFieldProps & {
  children: (args: {
    isError: boolean;
    syncErrors: FormErrors;
  }) => React.ReactNode;
  fields: string[];
};

const SyncErrors = ({ meta, children, fields }: SyncErrorsProps) => {
  const syncErrors = useAppSelector((state) =>
    getFormSyncErrors(meta.form)(state),
  );
  const isError = fields.some((key) => !!syncErrors[key]);
  const filteredSyncErrors = _.pick(syncErrors, fields);

  return children({ isError, syncErrors: filteredSyncErrors });
};

export const SubscribeSyncErrors = (
  props: Pick<SyncErrorsProps, "fields" | "children">,
) => {
  return <Field name="" component={SyncErrors as any} {...props} />;
};
