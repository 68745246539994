import type { WrappedFieldProps } from "redux-form";

import { TextField, Typography } from "@hexocean/braintrust-ui-components";
import { DateField } from "@js/forms/fields";

import styles from "./styles.module.scss";

export const IssuedAtField = ({ input, meta }: WrappedFieldProps) => {
  return (
    <DateField
      input={input}
      meta={meta}
      label="Date"
      id="issued_at"
      readOnly
      disabled
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            variant="standard"
            size="small"
            className={styles.issuedAtTextField}
            id="issued_at"
            label={
              <Typography
                component="span"
                variant="label"
                size="large"
                color="primary"
                display="block"
              >
                Date
              </Typography>
            }
            error={!!meta.error}
            helperText={meta.error}
          />
        );
      }}
    />
  );
};
