import type { WrappedFieldProps } from "redux-form";
import { Field } from "redux-form";

import { Box, Divider, Typography } from "@hexocean/braintrust-ui-components";
import { useMediaQuery } from "@hexocean/braintrust-ui-components/hooks";
import { FlagIcon } from "@hexocean/braintrust-ui-components/Icons";
import { BooleanTileButton } from "@js/apps/jobs/apps/offers/fields/boolean-tile";
import { FieldSectionPanelWithImportance } from "@js/apps/jobs/forms/fields";
import { DoubleDatePopoverPickerField } from "@js/forms/fields";
import { HoursPerWeekSliderField } from "@js/forms/fields/hours-per-week-slider";
import { renderValueLabelFormatForHoursPerWeekField } from "@js/forms/fields/hours-per-week-slider/utils";
import { shouldDisplayError } from "@js/forms/utils";

import { useCreateOrEditOfferMeta } from "../../hooks/create-or-edit-offer-meta";

export const DateSection = ({ proposed_start_date, proposed_end_date }) => {
  const displayError = shouldDisplayError([
    proposed_start_date,
    proposed_end_date,
  ]);
  const startDateError = proposed_start_date.meta.error;
  const endDateError = proposed_end_date.meta.error;

  const errorMessage =
    startDateError && endDateError
      ? "Please add start and end date"
      : startDateError || endDateError;

  return (
    <FieldSectionPanelWithImportance
      title="🗓️ Dates"
      fieldImportance="required"
      isError={displayError}
    >
      <Typography component="p" variant="paragraph" mb={2.25}>
        Confirm approximate start and end dates.
      </Typography>
      {displayError && (
        <Typography mb={2} mt={-2} component="p" variant="paragraph" error>
          {errorMessage}
        </Typography>
      )}
      <DoubleDatePopoverPickerField
        id="proposed-start-and-end-date"
        label="Add start/end dates"
        labelIcon={<FlagIcon sx={{ fontSize: "16px", marginRight: "8px" }} />}
        names={["proposed_start_date", "proposed_end_date"]}
        proposed_start_date={proposed_start_date}
        proposed_end_date={proposed_end_date}
        hideError
      />
    </FieldSectionPanelWithImportance>
  );
};

export const WorkingHoursSection = ({ meta, input }: WrappedFieldProps) => {
  const isMobile = useMediaQuery("sm");
  const displayError = shouldDisplayError({ input, meta });
  const errorMessage = meta.error as string;
  const offerMeta = useCreateOrEditOfferMeta();

  return (
    <FieldSectionPanelWithImportance
      title="⌛ Hours per week"
      fieldImportance="required"
      isError={displayError}
    >
      <Typography
        component="p"
        id="anticipated-hours-label"
        variant="paragraph"
        mb={1}
        sx={{
          overflowWrap: "anywhere",
        }}
      >
        Approximately how many hours per week will {offerMeta?.talent_name} be
        working?
      </Typography>

      {displayError && (
        <Typography mb={2} component="p" variant="paragraph" error>
          {errorMessage}
        </Typography>
      )}

      <Box pt={{ xs: 12, sm: 10 }} pl={1.8}>
        <Field
          name="anticipated_weekly_hours"
          component={HoursPerWeekSliderField}
          aria-labelledby="anticipated-hours-label"
          valueLabelDisplay="on"
          size="medium"
          showErrors
          valueLabelFormat={(value) =>
            renderValueLabelFormatForHoursPerWeekField(value, isMobile)
          }
        />
      </Box>
    </FieldSectionPanelWithImportance>
  );
};

export const DocumentsAndEquipmentSection = ({
  talent_has_own_equipment,
  require_paperwork,
}) => {
  const displayError = shouldDisplayError([
    talent_has_own_equipment,
    require_paperwork,
  ]);
  const offerMeta = useCreateOrEditOfferMeta();

  return (
    <FieldSectionPanelWithImportance
      title="📎 Documents and equipment"
      fieldImportance="required"
      isError={displayError}
    >
      <Box display="flex" flexDirection="column" gap={2}>
        <DocumentsAndEquipmentSectionItem
          field={require_paperwork}
          title={`Do you require additional paperwork for ${offerMeta?.talent_name}?`}
          subtitle="Braintrust will coordinate additional paperwork (NDA, MSA, SOW, etc.)"
        />
        <Divider color="beige" />
        <DocumentsAndEquipmentSectionItem
          field={talent_has_own_equipment}
          title={`Will you provide any equipment for ${offerMeta?.talent_name}?`}
          subtitle={`If so, Braintrust will gather shipping information from ${offerMeta?.talent_name}`}
          revertBooleans
        />
      </Box>
    </FieldSectionPanelWithImportance>
  );
};

type DocumentsAndEquipmentSectionItemProps = {
  title: string;
  subtitle: string;
  field: WrappedFieldProps;
  revertBooleans?: boolean;
};

const DocumentsAndEquipmentSectionItem = ({
  title,
  subtitle,
  field,
  revertBooleans,
}: DocumentsAndEquipmentSectionItemProps) => {
  return (
    <Box
      display="flex"
      justifyContent="space-between"
      sx={{
        flexDirection: { xs: "column", sm: "row" },
        gap: 1,
        overflowWrap: "anywhere",
      }}
    >
      <Box>
        <Typography component="p" variant="paragraph" size="large">
          {title}
        </Typography>
        <Typography
          component="p"
          variant="paragraph"
          size="medium"
          color="tertiary"
        >
          {subtitle}
        </Typography>
      </Box>
      <Box>
        <BooleanTileButton
          value={field.input.value}
          onChange={field.input.onChange}
          revertBooleans={revertBooleans}
        />
      </Box>
    </Box>
  );
};
