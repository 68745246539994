import { useMemo } from "react";

import {
  MessageIcon,
  PersonAddIcon,
} from "@hexocean/braintrust-ui-components/Icons";
import type { TeamMemberInvitationsAutocompleteProps } from "@js/apps/employer/components/team-member-invitations-autocomplete";
import {
  TeamMemberInvitationsActionButton,
  TeamMemberInvitationsAutocomplete,
} from "@js/apps/employer/components/team-member-invitations-autocomplete";
import type {
  JobSubscriberData,
  JobSubscriberInvitationData,
} from "@js/types/jobs";

type SubscribeTeamMembersAutocompleteProps = {
  jobSubscribersValue: JobSubscriberData[];
  jobSubscriberInvitationsValue: JobSubscriberInvitationData[];
} & Pick<
  TeamMemberInvitationsAutocompleteProps,
  "teamMembers" | "toggleInvitation" | "toggleTeamMemberId"
>;

export const SubscribeTeamMembersAutocomplete = ({
  jobSubscriberInvitationsValue,
  jobSubscribersValue,
  ...rest
}: SubscribeTeamMembersAutocompleteProps) => {
  const selectedTeamMemberIds = useMemo(() => {
    return jobSubscribersValue.map(
      (jobSubscriber) => jobSubscriber.team_member_id,
    );
  }, [jobSubscribersValue]);

  const selectedInvitations = useMemo(() => {
    return jobSubscriberInvitationsValue.map(
      (jobSubscriberInvitation) => jobSubscriberInvitation.email,
    );
  }, [jobSubscriberInvitationsValue]);

  return (
    <TeamMemberInvitationsAutocomplete
      {...rest}
      selectedTeamMemberIds={selectedTeamMemberIds}
      selectedInvitations={selectedInvitations}
      memberActionButton={<SubscribeTeamMembersAddMemberBtn />}
      invitationActionButton={<SubscribeTeamMembersInviteBtn />}
    />
  );
};

const SubscribeTeamMembersInviteBtn = () => {
  return (
    <TeamMemberInvitationsActionButton label="Invite" Icon={MessageIcon} />
  );
};

const SubscribeTeamMembersAddMemberBtn = () => {
  return <TeamMemberInvitationsActionButton label="Add" Icon={PersonAddIcon} />;
};
