/* eslint-disable no-void */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { memo, useLayoutEffect, useRef } from "react";
import CanvasConfetti from "canvas-confetti";

// https://www.npmjs.com/package/canvas-confetti

/**
 * @framerIntrinsicWidth auto
 * @framerIntrinsicHeight 100%
 *
 * @framerSupportedLayoutWidth fixed
 * @framerSupportedLayoutHeight fixed
 */
export const Confetti = memo((props: { doIt?: boolean; style?: any }) => {
  const { doIt, style } = props;
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const confettiRef = useRef<any>(null)!;

  // Setup Confetti
  useLayoutEffect(() => {
    confettiRef.current = CanvasConfetti.create(canvasRef.current!, {
      resize: true,
    });
  }, [confettiRef]);

  // Trigger Confetti
  useLayoutEffect(() => {
    const timer = setTimeout(
      () =>
        void confettiRef.current({
          colors: ["#49B989", "#CBA0FF", "#EDCCA1"],
          particleCount: 300,
          spread: 200,
          ticks: 300,
          shapes: ["square"],
          startVelocity: 30,
          // scalar: 1,
          origin: {
            y: 0.5,
          },
        }),
      150,
    );

    return () => clearTimeout(timer);
  }, [confettiRef, doIt]);

  return <canvas ref={canvasRef} style={style} />;
});
