import React from "react";

import type { IconButtonProps as DSIconButtonProps } from "@hexocean/braintrust-ui-components";
import { IconButton } from "@hexocean/braintrust-ui-components";
import { LinkIcon } from "@hexocean/braintrust-ui-components/Icons";
import { RouterLink } from "@js/components/link";
import type { ExternalProfile } from "@js/types/common";

type SocialProfilesProps = {
  profiles: ExternalProfile[];
  IconStyle?: any;
  IconButtonProps?: Omit<DSIconButtonProps, "to" | "href" | "aria-label">;
};

export const SocialProfiles = ({
  profiles,
  IconStyle,
  IconButtonProps,
}: SocialProfilesProps): JSX.Element | null => {
  if (!profiles?.length) {
    return null;
  }

  return (
    <ul className="social-profiles-list list-reset">
      {profiles.map(({ id, public_url: url, site }) => {
        return (
          <li key={id} title={site.name} className="social-profiles-list__item">
            <IconButton
              variant="secondary"
              size="medium"
              aria-label={`Open user ${site.name} profile`}
              {...IconButtonProps}
              to={url}
              target="_blank"
              rel="noopener noreferrer"
              RouterLink={RouterLink}
            >
              {site.logo ? (
                <img
                  src={site.logo.thumbnail}
                  alt={site.name}
                  style={IconStyle}
                />
              ) : (
                <LinkIcon titleAccess="Custom link" style={{ fontSize: 23 }} />
              )}
            </IconButton>
          </li>
        );
      })}
    </ul>
  );
};
