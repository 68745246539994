import React from "react";
import { useLocation, useNavigate } from "react-router-dom";

export const useGoBackHistory = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const prevPath = location.state?.prevPath;

  const enhancedNavigate = React.useCallback(
    (defaultUrl: string) => {
      if (prevPath) {
        navigate(-1);
        return;
      }

      navigate(defaultUrl);
    },
    [prevPath, navigate],
  );

  return enhancedNavigate;
};
