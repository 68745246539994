import {
  ArrowUpIcon,
  CheckIcon,
} from "@hexocean/braintrust-ui-components/Icons";

export type ActivityIconProps = {
  variant: "deposit" | "withdraw" | "send" | "receive" | "referral";
};

export const ActivityIcon = ({ variant }: ActivityIconProps) => {
  const Icon = variant === "referral" ? CheckIcon : ArrowUpIcon;

  return (
    <div
      className={`activity-list-item-icon activity-list-item-icon--${variant}`}
    >
      <Icon className="activity-list-item-icon__svg" />
    </div>
  );
};
