import React from "react";

import {
  Box,
  Button,
  Rating,
  Typography,
} from "@hexocean/braintrust-ui-components";
import {
  useHelpServicesMessageUser,
  useScrollToReviewSection,
} from "@js/apps/freelancer/hooks";
import { useGetTalentReviewsSummaryQuery } from "@js/apps/reviews/api";
import type { FreelancerPublic } from "@js/types/freelancer";

type MessageUserAndReviewSectionProps = {
  isOwnProfile: boolean;
  profile: FreelancerPublic;
};

export const MessageUserAndReviewSection = ({
  isOwnProfile,
  profile,
}: MessageUserAndReviewSectionProps) => {
  const { data: reviewSummary } = useGetTalentReviewsSummaryQuery({
    id: profile.id,
  });

  const { navigateToMessenger } = useHelpServicesMessageUser({
    receiverUserId: profile.user.id,
  });
  const isTalentHasAnyReviews =
    !reviewSummary?.average_rating || Number(reviewSummary?.average_rating) > 0;

  const scrollToReviewSection = useScrollToReviewSection();

  if (isOwnProfile && !isTalentHasAnyReviews) {
    return null;
  }

  return (
    <div className="message-user-and-review-section">
      {!isOwnProfile && (
        <Button
          variant="positive-2"
          shape="squared"
          size="medium"
          onClick={navigateToMessenger}
          fullWidth
        >
          <Typography
            component="span"
            ellipsis
            title={`Message ${profile.user.first_name}`}
            fontWeight={500}
          >
            Message {profile.user.first_name}
          </Typography>
        </Button>
      )}
      {isTalentHasAnyReviews && (
        <Box textAlign="center">
          {isOwnProfile && (
            <Typography
              component="p"
              variant="paragraph"
              size="small"
              fontWeight={500}
              mb={1}
            >
              My Reviews
            </Typography>
          )}
          <Box display="flex" alignItems="center">
            <Rating
              variant="stars"
              size="small"
              precision={0.5}
              value={Number(reviewSummary?.average_rating)}
              className="message-user-and-review-section__stars-rating"
              readOnly
            />
            <Typography
              className="message-user-and-review-section__reviews-link"
              ml={1}
              component="span"
              size="medium"
              onClick={scrollToReviewSection}
            >
              {Number(reviewSummary?.average_rating)} out of 5
            </Typography>
          </Box>
        </Box>
      )}
    </div>
  );
};
