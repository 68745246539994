import React from "react";

import type {
  BoxProps,
  TypographySizes,
} from "@hexocean/braintrust-ui-components";
import {
  Box,
  Button,
  RoundedBox,
  Typography,
} from "@hexocean/braintrust-ui-components";
import { useMediaQuery } from "@hexocean/braintrust-ui-components/hooks";
import { useIsNodeStaff } from "@js/apps/common/hooks";
import { AppliedChip } from "@js/apps/jobs/components/applied-chip";
import { CompleteProfileButton } from "@js/apps/jobs/components/complete-profile-button";

import { BidButton } from "../../apps/bids/components";

export type StatusBoxProps = {
  color: string;
  status: string;
  fullSize?: boolean;
  fullHeight?: boolean;
  content?: JSX.Element | null;
  longTile?: boolean;
  variant?: "mobile-top";
};

const StatusBox = ({
  color,
  status,
  content,
  longTile,
  variant,
  ...props
}: StatusBoxProps) => {
  return (
    <BaseStatusBox
      color={color}
      mobileStatus={
        <Typography component="h2" color="white" size="large">
          {status}
        </Typography>
      }
      statusTextContent={
        <>
          <Typography
            mb={1.5}
            component="span"
            variant="label"
            color="white"
            size="medium"
            fontWeight={400}
          >
            Status
          </Typography>
          <Typography
            mb={2}
            component="h2"
            variant="title"
            color="white"
            size="small"
            fontWeight={400}
          >
            {status}
          </Typography>
        </>
      }
      content={content}
      longTile={longTile}
      variant={variant}
      {...props}
    />
  );
};

type CompleteProfileStatusBoxProps = {
  longTile?: boolean;
  variant?: "mobile-top";
};

export const CompleteProfileStatusBox = ({
  longTile,
  variant,
  ...props
}: CompleteProfileStatusBoxProps) => {
  return (
    <BaseStatusBox
      color="var(--medium-green)"
      mobileStatus={
        <Typography component="h2" size="large">
          Complete your profile to apply for jobs!
        </Typography>
      }
      statusTextContent={
        <>
          <Typography
            mb={1.5}
            component="h2"
            variant="title"
            size="small"
            fontWeight={400}
          >
            Complete your profile to apply for jobs!
          </Typography>
          {longTile && (
            <Typography
              mb={2}
              component="h3"
              variant="paragraph"
              maxWidth="fit-content"
            >
              When your profile is 100%, you can apply to jobs and go through
              our Approved Talent screening.
            </Typography>
          )}
        </>
      }
      content={<CompleteProfileButton />}
      longTile={longTile}
      variant={variant}
      boxProps={{
        position: {
          xs: "static",
          md: longTile ? "static" : "absolute",
        },
        bottom: 20,
      }}
      {...props}
    />
  );
};

type BaseStatusBoxProps = {
  color: string;
  mobileStatus?: JSX.Element | null;
  statusTextContent?: JSX.Element | null;
  content?: JSX.Element | null;
  longTile?: boolean;
  fullSize?: boolean;
  fullHeight?: boolean;
  variant?: "mobile-top";
  boxProps?: BoxProps;
};

const BaseStatusBox = ({
  color,
  mobileStatus,
  statusTextContent,
  content,
  longTile,
  variant,
  fullSize,
  fullHeight,
  boxProps,
  ...props
}: BaseStatusBoxProps) => {
  if (variant === "mobile-top") {
    return (
      <RoundedBox
        display={{ xs: "block", md: "none" }}
        height="100%"
        bgcolor={color}
        p={0}
        py={2}
        px={3}
        {...props}
      >
        <Box>{mobileStatus}</Box>
      </RoundedBox>
    );
  }

  return (
    <RoundedBox
      height="100%"
      display={longTile ? "flex" : "block"}
      justifyContent={longTile ? "space-between" : ""}
      alignItems={longTile ? "center" : ""}
      bgcolor={color}
      position="relative"
      flexWrap="wrap"
      {...props}
    >
      <Box>{statusTextContent}</Box>
      <Box
        position={longTile || fullSize || fullHeight ? "static" : "absolute"}
        width={fullSize ? "100%" : "initial"}
        maxWidth={longTile && fullSize ? "180px" : "100%"}
        bottom={40}
        {...boxProps}
      >
        {content}
      </Box>
    </RoundedBox>
  );
};

export const TalentBidButtonOrAppliedTag = ({ hasFreelancerBid, job, bid }) => {
  if (hasFreelancerBid) {
    return <AppliedChip size="default" tagText="You applied!" />;
  }

  if (job.can_bid) {
    return (
      <BidButton
        style={{
          paddingLeft: 27,
          paddingRight: 27,
          width: "100%",
        }}
        variant="positive"
        shape="squared"
        job={job}
        bid={bid}
      />
    );
  }

  return <DisabledProposalButtonWithTooltip />;
};

export const DisabledProposalButtonWithTooltip = () => {
  return (
    <Button
      style={{ padding: "6.5px 27px", width: "100%" }}
      variant="positive"
      children="Apply"
      shape="squared"
      disabled
      disabledType="opacity"
    />
  );
};

export const AcceptingProposalsStatus = (props) => {
  return (
    <StatusBox
      color="var(--positive-1)"
      status="Accepting Applications"
      {...props}
    />
  );
};

const Subtitle = ({
  children,
  size = "large",
}: {
  children: React.ReactNode;
  size?: TypographySizes;
}) => {
  return (
    <Typography
      maxWidth={200}
      component="p"
      variant="paragraph"
      size={size}
      color="white"
    >
      {children}
    </Typography>
  );
};

export const ClientHiredBraintrustTalent = ({ count, ...props }) => {
  const isNodeStaff = useIsNodeStaff();

  return (
    <StatusBox
      color="var(--dark-blue)"
      status="Project Hired"
      {...props}
      content={
        <Subtitle>
          {isNodeStaff && count
            ? `Client Hired ${count} Braintrust Talent`
            : "Client Hired Braintrust Talent"}
        </Subtitle>
      }
    />
  );
};

export const NoBraintrustTalentHired = (props) => {
  return (
    <StatusBox
      color="var(--negative-1)"
      status="Job Closed"
      {...props}
      content={<Subtitle>No Braintrust Talent Hired</Subtitle>}
    />
  );
};

export const NotAcceptingProposals = (props) => {
  return (
    <StatusBox
      color="var(--negative-1)"
      status="Job Paused"
      {...props}
      content={<Subtitle>Not Accepting Applications</Subtitle>}
    />
  );
};

export const YouHiredXBraintrustTalent = ({ count, ...props }) => {
  return (
    <StatusBox
      color="var(--dark-blue)"
      status="Project Hired"
      content={<Subtitle>You Hired {count} Braintrust Talent</Subtitle>}
      {...props}
    />
  );
};

export const NotAcceptingProposalsPublic = ({
  size,
  status,
  ...props
}: {
  size?: TypographySizes;
  status: string;
}) => {
  const isMobile = useMediaQuery("md");

  return (
    <StatusBox
      color="var(--negative-1)"
      status={status}
      {...props}
      fullHeight={isMobile}
      content={
        <Subtitle size={size}>Sign up to see other opportunities</Subtitle>
      }
    />
  );
};
