import type { ReactNode } from "react";
import { forwardRef } from "react";
import cs from "classnames";

import { Box } from "@hexocean/braintrust-ui-components";
import { PostInteractsSection } from "@js/apps/give-and-get-help/components//post/post-interacts-section";
import { ActionsMenu } from "@js/apps/give-and-get-help/components/actions-menu";
import { CommentsSection } from "@js/apps/give-and-get-help/components/comments";
import { Poll } from "@js/apps/give-and-get-help/components/poll";
import { PostAttachments } from "@js/apps/give-and-get-help/components/post-attachments";
import { PostCategoryBadge } from "@js/apps/give-and-get-help/components/post-category-and-budget-badges";
import { PostBudgetBadge } from "@js/apps/give-and-get-help/components/post-category-and-budget-badges";
import { PostDetailsWrapper } from "@js/apps/give-and-get-help/components/post-comment-details/post-details-wrapper/";
import { PostTitle } from "@js/apps/give-and-get-help/components/post-title";
import { ReadMore } from "@js/apps/give-and-get-help/components/read-more";
import { PostContext } from "@js/apps/give-and-get-help/context";
import { getReplyFormId } from "@js/apps/give-and-get-help/form/utils";
import { ReplyForm } from "@js/apps/give-and-get-help/forms/reply-form";
import { useHandlePostCommentActions } from "@js/apps/give-and-get-help/hooks";
import { useDisableSpecificPostActions } from "@js/apps/give-and-get-help/hooks/use-disable-specific-post-actions";
import type { IPost } from "@js/types/give-and-get-help";

import type { OnPostAction } from "./use-base-post-item";
import { useBasePostItem } from "./use-base-post-item";

export type BasePostItemProps = {
  postData: IPost;
  restrictTogglingReplies?: boolean;
  isPublic?: boolean;
  isSinglePostView?: boolean;
  onReplyClick?: OnPostAction;
  onReactionClick?: OnPostAction;
  onViewCommentClick?: OnPostAction;
  disablePostSubscription?: boolean;
};

export const BasePostItem = forwardRef<HTMLDivElement, BasePostItemProps>(
  (
    {
      postData,
      restrictTogglingReplies = false,
      isPublic = false,
      isSinglePostView = false,
      onReplyClick,
      onReactionClick,
      onViewCommentClick,
    },
    ref,
  ) => {
    const {
      postUrl,
      postLinksMetadata,
      shouldDisplayPostAttachments,
      onAddReply,
      onReplyFormClick,
      contextValue,
      internalRef,
      shouldDisplayAdminBadge,
    } = useBasePostItem({
      postData,
      isPublic,
      forwardRef: ref,
      onReactionClick,
      onReplyClick,
      onViewCommentClick,
    });
    const { handleCollapsePost, handleExpandPost, handleUserNameClicked } =
      useHandlePostCommentActions({
        entity: postData,
        entityRef: internalRef,
      });

    const showOfferBanner = postData.budget;

    return (
      <PostContext.Provider value={contextValue}>
        <Box data-testid={`post-${postData.id}`}>
          {showOfferBanner && (
            <Box className="offer-banner">
              {postData.budget && <PostBudgetBadge budget={postData.budget} />}
            </Box>
          )}
          <Box
            className={cs("post-wrapper", {
              "post-wrapper--with-banner": showOfferBanner,
            })}
            ref={internalRef}
          >
            <Box className="post-wrapper__header">
              <Box className="post-wrapper__header__details-badge">
                <PostDetailsWrapper
                  freelancer={postData.freelancer}
                  created={postData.created}
                  handleUserNameClicked={handleUserNameClicked}
                  shouldDisplayAdminBadge={shouldDisplayAdminBadge}
                />
                <PostCategoryBadge category={postData.category} />
              </Box>
              <div className="post-wrapper__actions">
                <ActionsMenu
                  entity={postData}
                  urlToCopy={postUrl}
                  type="post"
                />
              </div>
            </Box>
            <Box className="post-wrapper__content">
              <PostTitle
                isSinglePostView={isSinglePostView}
                title={postData.title}
                postUrl={postUrl}
              />
              <Box className="post-wrapper__description">
                <ReadMore
                  id={postData.id}
                  onExpand={handleExpandPost}
                  onCollapse={handleCollapsePost}
                  description={postData.text}
                  postHashtags={postData.post_hashtags}
                  postData={postData}
                />
              </Box>
              {!!postData.poll_options.length && (
                <Box mt={2}>
                  <Poll
                    postId={postData.id}
                    poll_text={postData.poll_text}
                    poll_votes_count={postData.poll_votes_count}
                    poll_options={postData.poll_options}
                  />
                </Box>
              )}
              {shouldDisplayPostAttachments && (
                <Box mt={2} overflow="hidden">
                  <PostAttachments
                    attachments={[
                      ...postLinksMetadata,
                      ...postData.attachments,
                    ]}
                  />
                </Box>
              )}
            </Box>
            <Box className="post-wrapper__footer">
              <PostInteractsSection
                postData={postData}
                restrictTogglingReplies={restrictTogglingReplies}
              />
            </Box>
            <ReplyFormWrapper isPublic={contextValue.isPublic}>
              <ReplyForm
                type="reply-to-post"
                formId={getReplyFormId(postData.id)}
                id={postData.id}
                onReplyClick={onReplyFormClick}
                onAddReply={onAddReply}
              />
            </ReplyFormWrapper>

            <CommentsSection
              isPublic={isPublic}
              isSinglePostView={isSinglePostView}
              post={postData}
            />
          </Box>
        </Box>
      </PostContext.Provider>
    );
  },
);

const ReplyFormWrapper = ({
  children,
  isPublic,
}: {
  children: ReactNode;
  isPublic: boolean;
}) => {
  const disablePostActions = useDisableSpecificPostActions();

  if (disablePostActions || isPublic) return null;

  return children;
};
