import { IconButton, Tooltip } from "@hexocean/braintrust-ui-components";
import { InfoIcon } from "@hexocean/braintrust-ui-components/Icons";
import { shortenAddress } from "@js/apps/web3/utils";
import { assertUnreachable } from "@js/utils";

import type {
  WalletActivity,
  WalletActivityMigrationTransaction,
} from "../../types";
import { InternalTransferStatusLabel } from "../internal-transfer-status-label";
import { openMigrationActivityInformModal } from "../migration-activity-inform-modal";
import { WithdrawalStatusLabel } from "../withdrawal-status-label";

import { ActivityItem } from "./activity-item";
import { getActivityTypeDetails } from "./utils";

export type ActivityListProps = {
  activityList: WalletActivity[];
};

export const ActivityList = ({ activityList }: ActivityListProps) => {
  return (
    <div className="activity-list">
      {activityList.map((activity) => (
        <ListItem key={activity.id} activity={activity} />
      ))}
    </div>
  );
};

type ListItemProps = {
  activity: WalletActivity;
};

const ListItem = ({ activity }: ListItemProps): JSX.Element | null => {
  const activityType = activity.type;

  if (isMigrationActivity(activity)) {
    const shortenWalletAddress = activity.migration_safe_wallet_address ? (
      <Tooltip title={activity.migration_safe_wallet_address} maxWidth={350}>
        <span>{shortenAddress(activity.migration_safe_wallet_address, 8)}</span>
      </Tooltip>
    ) : null;

    return (
      <ActivityItem
        label={
          <InternalTransferStatusLabel
            status={activity.migration_status}
            refund_requested={false}
            frozen={false}
          />
        }
        title={
          <>
            {activity.description}
            <IconButton
              aria-label="Open balance transfer information modal"
              variant="transparent"
              sx={{ padding: "0 !important" }}
              disableRipple
              onClick={openMigrationActivityInformModal}
            >
              <InfoIcon sx={{ fontSize: "20px", cursor: "pointer" }} />
            </IconButton>
          </>
        }
        subtitle={shortenWalletAddress}
        createdDate={activity.created}
        transferAmount={parseFloat(activity.credit)}
        transferType="transfer"
        className="activity-list-item--migration-balance-item"
        iconVariant="withdraw"
      />
    );
  }

  switch (activityType) {
    case ENUMS.WalletActivitySourceType.BALANCE_ITEM: {
      const { title, iconVariant, subtitle } = getActivityTypeDetails(activity);
      const isInEscrow = !!activity?.source?.has_tokens_in_escrow;
      const refundRequested = !!activity?.source?.refund_requested;

      const credit = parseFloat(activity.credit);
      const debit = parseFloat(activity.debit);
      const transferAmount = !!credit ? credit : debit;
      const transferType = !!credit ? "credit" : "debit";

      return (
        <ActivityItem
          label={
            <InternalTransferStatusLabel
              frozen={isInEscrow}
              refund_requested={refundRequested}
            />
          }
          title={title}
          createdDate={activity.created}
          transferType={transferType}
          transferAmount={transferAmount}
          subtitle={subtitle}
          iconVariant={iconVariant}
          frozen={isInEscrow}
        />
      );
    }
    case ENUMS.WalletActivitySourceType.WITHDRAWAL_TRANSACTION: {
      const shortenWalletAddress = activity.withdrawer_method.method
        .wallet_address ? (
        <Tooltip
          title={activity.withdrawer_method.method.wallet_address}
          maxWidth={350}
        >
          <span>
            {shortenAddress(
              activity.withdrawer_method.method.wallet_address,
              8,
            )}
          </span>
        </Tooltip>
      ) : null;

      const disabled =
        activity.status === ENUMS.WithdrawalTransactionStatus.FAILED ||
        activity.status === ENUMS.WithdrawalTransactionStatus.LINK_EXPIRED;

      return (
        <ActivityItem
          label={<WithdrawalStatusLabel status={activity.status} />}
          title={`Transfer of ${activity.currency}`}
          subtitle={shortenWalletAddress}
          createdDate={activity.created}
          completedDate={activity.completed_at}
          transferAmount={parseFloat(activity.amount)}
          transferType="debit"
          disabled={disabled}
          className={`activity-item__status-${activity.status}`}
          iconVariant="withdraw"
        />
      );
    }

    default:
      assertUnreachable(activityType);
      return null;
  }
};

const isMigrationActivity = (
  activity: WalletActivity,
): activity is WalletActivityMigrationTransaction => {
  return (
    "created_during_safe_wallets_migration" in activity &&
    !!activity.created_during_safe_wallets_migration
  );
};
