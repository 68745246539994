import { Button, Typography } from "@hexocean/braintrust-ui-components";
import {
  useCompleteHelpOffer,
  useRequestHelpOfferRevision,
} from "@js/apps/common/hooks/use-help-offer";
import type { HelpOfferButtonsStatusProps } from "@js/apps/messenger/components/action-bar/action-bar-offer-buttons/types";

export const HelpOfferButtonsApprovalRequested = ({
  offer,
  isOfferAuthor,
  isMobile,
  type,
}: HelpOfferButtonsStatusProps): JSX.Element | null => {
  const { handleCompleteOffer, isAccepting } = useCompleteHelpOffer({ offer });
  const { handleRequestRevision, isRequestingRevision } =
    useRequestHelpOfferRevision({ offer });

  const disableButtons = isRequestingRevision || isAccepting;
  const isDashboardOffer = type === "dashboard";

  if (isOfferAuthor)
    return !isDashboardOffer ? (
      <Typography
        component="span"
        className="help-offer-action-buttons__status"
      >
        Final review requested
      </Typography>
    ) : null;

  return (
    <>
      {(!isMobile || isDashboardOffer) && (
        <Button
          variant="transparent-border-beige"
          shape="squared"
          className="help-offer-action-buttons__secondary"
          onClick={handleRequestRevision}
          disabled={disableButtons}
        >
          Ask for a revision
        </Button>
      )}
      <Button
        variant="primary"
        shape="squared"
        className="help-offer-action-buttons__primary"
        onClick={handleCompleteOffer}
        disabled={disableButtons}
      >
        Mark complete
      </Button>
    </>
  );
};
