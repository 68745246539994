import { Typography } from "@hexocean/braintrust-ui-components";
import { FieldSectionPanelWithImportance } from "@js/apps/jobs/forms/fields/field-section-panel-img";

export const BoostFieldPanel = ({
  title,
  description,
  displayError,
  error,
  children,
}) => {
  return (
    <FieldSectionPanelWithImportance
      title={title}
      image={`${SETTINGS.STATIC_URL}jobs/boost-icon-rounded.svg`}
      description={description}
      fieldImportance="optional"
      isError={displayError}
      tooltipMessage={
        <>
          <Typography component="p" mb={1.5}>
            Stand out as a high-intent candidate by boosting this job as your
            top choice.{" "}
          </Typography>
        </>
      }
    >
      {children}
      {displayError && (
        <Typography mt={2} error component="p" variant="paragraph">
          {error}
        </Typography>
      )}
    </FieldSectionPanelWithImportance>
  );
};
