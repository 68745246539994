import {
  IconButton,
  Menu,
  Tooltip,
  Typography,
} from "@hexocean/braintrust-ui-components";
import { MoreVertIcon } from "@hexocean/braintrust-ui-components/Icons";
import { getBlockedPaymentTime } from "@js/apps/invoices/utils";
import type { EmployerInvoice } from "@js/types/invoices";

import { MarkAsPaidAction } from "../../mark-as-paid-action";

import { MarkAsPaidModalContent } from "./mark-as-paid-modal-content";

type SingleInvoiceActionsMobileContentProps = {
  isPayButtonVisible: boolean;
  isPaymentTemporaryBlocked: boolean;
  invoice: EmployerInvoice;
  handleInvoicePayment: () => void;
  handleDownloadInvoiceAsPDF: () => void;
  markInvoiceAsPaid: (ids: number[]) => void;
};

export const SingleInvoiceActionsMobileContent = ({
  isPayButtonVisible,
  isPaymentTemporaryBlocked,
  handleInvoicePayment,
  markInvoiceAsPaid,
  handleDownloadInvoiceAsPDF,
  invoice,
}: SingleInvoiceActionsMobileContentProps) => {
  return (
    <Menu
      anchor={
        <IconButton size="small" variant="transparent" aria-label="Open menu">
          <MoreVertIcon />
        </IconButton>
      }
    >
      {isPayButtonVisible && (
        <Tooltip
          disableHoverListener={!isPaymentTemporaryBlocked}
          disableFocusListener={!isPaymentTemporaryBlocked}
          disableTouchListener={!isPaymentTemporaryBlocked}
          disabled={!isPaymentTemporaryBlocked}
          placement="top"
          title={
            <>
              <Typography component="p" width="fit-content">
                This invoice is being edited and should be available to pay in{" "}
                {getBlockedPaymentTime(invoice.payment_blocked_until)}.
              </Typography>
            </>
          }
        >
          <span>
            <Menu.Item
              onClick={handleInvoicePayment}
              disabled={isPaymentTemporaryBlocked}
            >
              Pay Invoice
            </Menu.Item>
          </span>
        </Tooltip>
      )}
      <MarkAsPaidAction
        type="menuitem"
        invoices={[invoice]}
        markAsPaid={markInvoiceAsPaid}
        modalContent={<MarkAsPaidModalContent />}
      />
      <Menu.Item onClick={handleDownloadInvoiceAsPDF}>Download PDF</Menu.Item>
    </Menu>
  );
};
