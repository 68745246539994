import { Box, Typography } from "@hexocean/braintrust-ui-components";
import { MyWalletBalance } from "@js/apps/dashboard/components";

type LifetimeEarningsDetailProps = {
  label: string;
  legend: string;
  balance: string | number;
  bgcolor: string;
};

export const LifetimeEarningsDetail = (props: LifetimeEarningsDetailProps) => {
  const { balance, label, legend, bgcolor } = props;

  return (
    <Box className="lifetime-earnings__detail" bgcolor={bgcolor}>
      <div>
        <Typography variant="label" component="label" size="medium">
          {label}
        </Typography>
        <Typography variant="paragraph" component="p" size="small">
          {legend}
        </Typography>
      </div>
      <MyWalletBalance
        balance={balance}
        hideCurrency
        size="small"
        fontWeight={400}
        iconStyle={{ width: 18, height: 18, marginRight: 2 }}
        textStyle={{ display: "flex", alignItems: "center", fontSize: "20px" }}
      />
    </Box>
  );
};
