import { useCallback, useEffect, useRef, useState } from "react";

// Really simple hook which manages seconds only. Please consider some hook from npm if you need more features
export const useCountdown = (seconds = 3) => {
  const [start, setStartCountdown] = useState(false);
  const [countdown, setCountDown] = useState(seconds);
  const interval = useRef<ReturnType<typeof setInterval> | null>(null);

  const startCountdown = useCallback(() => setStartCountdown(true), []);

  useEffect(() => {
    if (start) {
      interval.current = setInterval(() => {
        setCountDown((prev) => prev - 1);
      }, 1000);
    }
  }, [start]);

  useEffect(() => {
    if (countdown === 0 && interval.current) {
      clearInterval(interval.current);
    }
  }, [countdown]);

  return {
    startCountdown,
    countdown,
    countdownFinished: countdown === 0,
  };
};
