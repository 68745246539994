import React from "react";

import { Chip, Typography } from "@hexocean/braintrust-ui-components";
import { useMediaQuery } from "@hexocean/braintrust-ui-components/hooks";
import {
  CircleCheckOutlineIcon,
  InfoIcon,
} from "@hexocean/braintrust-ui-components/Icons";
import { useIsCategorySuggestedContext } from "@js/apps/give-and-get-help/context";
import { useSuggestCategory } from "@js/apps/give-and-get-help/hooks/suggest-category";
import { Emoji } from "@js/components/emoji";
import { ModalInstance } from "@js/components/modal";

export const CreateNewCategoryPills = ({
  onSuggestCategoryCancel,
  onSuggestCategorySuccess,
  onSuggestCategoryFail,
}: {
  onSuggestCategoryCancel: () => void;
  onSuggestCategorySuccess: () => void;
  onSuggestCategoryFail: () => void;
}) => {
  const { openSuggestCategoryModal } = useSuggestCategory();
  const { isCategorySuggested } = useIsCategorySuggestedContext();
  const isSmall = useMediaQuery("sm");

  if (isCategorySuggested) {
    return (
      <Chip
        fullWidth={isSmall}
        color="green"
        style={{
          whiteSpace: "normal",
        }}
        className="suggest-category-modal__chip"
        icon={
          <CircleCheckOutlineIcon
            sx={{
              fontSize: "16px",
              color: "var(--positive-2)",
            }}
          />
        }
        LabelProps={{
          ellipsis: false,
          size: isSmall ? "small" : "medium",
          fontWeight: 400,
        }}
        label={
          <>
            Your category suggestion was sent to the team{" "}
            <Emoji emoji="🎉" className="suggest-category-modal__chip__emoji" />
          </>
        }
      />
    );
  }

  return (
    <Chip
      fullWidth={isSmall}
      color="soft-grey"
      LabelProps={{
        ellipsis: false,
      }}
      icon={<InfoIcon />}
      label={
        <Typography size={isSmall ? "small" : "medium"} component="span">
          Need a different category?{" "}
          <Typography
            component="button"
            sx={{
              textDecoration: "underline",
              border: "none",
              backgroundColor: "transparent",
              color: "currentColor",
              padding: 0,
              cursor: "pointer",
            }}
            size={isSmall ? "small" : "medium"}
            onClick={() => {
              ModalInstance.close();
              openSuggestCategoryModal({
                onCancel: onSuggestCategoryCancel,
                onSubmitSuccess: onSuggestCategorySuccess,
                onSubmitFail: onSuggestCategoryFail,
              });
            }}
          >
            Let us know
          </Typography>
        </Typography>
      }
    />
  );
};
