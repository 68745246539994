import React from "react";

import { Typography } from "@hexocean/braintrust-ui-components";

export const JobSectionTitle = ({ children, ...props }) => (
  <Typography
    {...props}
    component="h2"
    variant="paragraph"
    fontWeight={500}
    size="medium"
  >
    {children}
  </Typography>
);
