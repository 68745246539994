export const matchFontColorToBackgroundColor = (bgColorVariable: string) => {
  if (!bgColorVariable) return null;

  const baseTone = bgColorVariable.match(/soft|medium|dark/)?.[0];
  const tonesTransformMap = {
    soft: "medium",
    medium: "dark",
    dark: "medium",
  };

  try {
    if (!baseTone) throw new Error("Wrong background variable provided.");
    return bgColorVariable.replace(baseTone, tonesTransformMap[baseTone]);
  } catch (e: unknown) {
    console.error(e);
    return null;
  }
};

export const matchDarkColorToLighterBackgroundColor = (
  bgColorVariable: string,
) => {
  if (!bgColorVariable) return null;

  const baseTone = bgColorVariable.match(/soft|medium|dark/)?.[0];
  const tonesTransformMap = {
    soft: "soft",
    medium: "soft",
    dark: "medium",
  };

  try {
    if (!baseTone) throw new Error("Wrong background variable provided.");
    return bgColorVariable.replace(baseTone, tonesTransformMap[baseTone]);
  } catch (e: unknown) {
    console.error(e);
    return null;
  }
};
