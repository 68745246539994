import { USER_BANNED } from "./actions";

const INITIAL_STATE = { banList: {} };

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case USER_BANNED:
      return {
        ...state,
        banList: { ...state.banList, [action.payload]: true },
      };
    default:
      return state;
  }
}
