import { ReviewDraftsButton } from "@js/apps/employer/components/review-import-drafts-button";

export const BarCtaButton = () => {
  return (
    <ReviewDraftsButton
      label="Review my drafts"
      size="medium"
      variant="primary"
      shape="default"
    />
  );
};
