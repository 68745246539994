export const FOLLOW_POST = "follow_post";
export const UNFOLLOW_POST = "unfollow_post";

export const FollowPostMessages = {
  [UNFOLLOW_POST]: "Conversation unfollowed",
  [FOLLOW_POST]: "Conversation followed",
} as const;

export const FollowPostLabel = {
  [UNFOLLOW_POST]: "Unfollow this conversation",
  [FOLLOW_POST]: "Follow this conversation",
} as const;
