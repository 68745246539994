import { type ReactNode } from "react";
import cs from "classnames";

import { Box, Button, Typography } from "@hexocean/braintrust-ui-components";
import { Braintrust } from "@hexocean/braintrust-ui-components/Icons";
import { RouterLink } from "@js/components/link";

import { useShowMessage } from "./use-show-message";
import { useUnmarkMessageAsSpam } from "./use-unmark-messageas-spam";

import styles from "./styles.module.scss";

type AntiSpamTombstoneWrapperProps = {
  isOwner: boolean;
  children: ReactNode;
};

export const AntiSpamTombstoneWrapper = ({
  isOwner,
  children,
}: AntiSpamTombstoneWrapperProps) => {
  if (isOwner) return <OwnerTombstoneContent messageComponent={children} />;
  return <ReceiverTombstoneContent messageComponent={children} />;
};

type TombstoneContentProps = {
  messageComponent: ReactNode;
};

export const OwnerTombstoneContent = ({
  messageComponent,
}: TombstoneContentProps) => {
  const { showMessage, toggleBtn, scrollToRef } = useShowMessage();

  return (
    <div className={cs(styles.container, styles.containerRed)}>
      <Header />

      <Typography component="h2" variant="label">
        ⚠️ Your message has been flagged for spam.
      </Typography>
      <Typography component="p" variant="paragraph">
        Our system detected certain characteristics that may align with spam,
        and as a precautionary measure, we flagged the message for the receiver.
        If you believe this message is not spam and should be reviewed for
        delivery, please contact our support team at{" "}
        <RouterLink
          target="_blank"
          to={`mailto:${SETTINGS.SUPPORT_EMAIL}`}
          rel="noreferrer"
          className={styles.link}
        >
          {SETTINGS.SUPPORT_EMAIL}
        </RouterLink>
        .
      </Typography>

      <ShowMessageBtn showMessage={showMessage} toggleBtn={toggleBtn} />

      <div ref={scrollToRef}>{showMessage && messageComponent}</div>
    </div>
  );
};

export const ReceiverTombstoneContent = ({
  messageComponent,
}: TombstoneContentProps) => {
  const { showMessage, toggleBtn, scrollToRef } = useShowMessage();

  return (
    <div className={styles.container}>
      <Header />

      <Typography component="h2" variant="label">
        ⚠️ This message has been flagged for spam.
      </Typography>

      <Typography component="p" variant="paragraph">
        This message could contain unsafe content, so exercise caution regarding
        suspicious links and refrain from sharing personal information to avoid
        falling victim to phishing attempts.
      </Typography>

      <ShowMessageBtn showMessage={showMessage} toggleBtn={toggleBtn} />

      <div ref={scrollToRef}>{showMessage && messageComponent}</div>
    </div>
  );
};

type ReportNotSpamBtnProps = {
  messageId: number;
  roomId: number;
};

export const ReportNotSpamBtn = ({
  messageId,
  roomId,
}: ReportNotSpamBtnProps) => {
  const { handleUnMarkAsSpam } = useUnmarkMessageAsSpam({
    messageId,
    roomId,
  });

  return (
    <Button
      variant="secondary-thin"
      size="x-small"
      shape="squared"
      className={styles.reportNotSpamButton}
      onClick={handleUnMarkAsSpam}
    >
      Report not spam
    </Button>
  );
};

type ShowMessageButtonProps = {
  showMessage: boolean;
  toggleBtn: () => void;
};

const ShowMessageBtn = ({ showMessage, toggleBtn }: ShowMessageButtonProps) => {
  return (
    <Box ml={-2}>
      <Button
        variant="transparent-blue"
        size="x-small"
        className={styles.showMessageButton}
        onClick={toggleBtn}
      >
        {showMessage ? "Hide" : "Show"} message
      </Button>
    </Box>
  );
};

const Header = () => (
  <Typography
    component="h3"
    variant="label"
    size="small"
    className={styles.header}
  >
    <BraintrustLogo />
    Braintrust Automated Message
  </Typography>
);

const BraintrustLogo = () => (
  <div className={styles.logo}>
    <Braintrust viewBox="0.2 0 16 16" />
  </div>
);
