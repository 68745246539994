import { useAppSelector } from "@js/hooks";

export const useAccountType = () => {
  const accountType = useAppSelector((state) => state.auth.user?.account_type);
  const isFreelancerApproved = useAppSelector(
    (state) => state.auth.user?.freelancer_approved,
  );

  const isFreelancer = accountType === ENUMS.AccountType.FREELANCER;
  const isEmployer = accountType === ENUMS.AccountType.EMPLOYER;

  return {
    isFreelancerApproved,
    isFreelancer,
    isEmployer,
    accountType,
  };
};
