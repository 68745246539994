import React, { useEffect, useMemo } from "react";
import type { WrappedFieldProps } from "redux-form";

import type { FormValue } from "@hexocean/braintrust-ui-components";
import { AI_VALUE, RANGE_VALUE } from "@js/apps/jobs/apps/create-job/constants";
import { OptionTileButtons } from "@js/components/option-tile-buttons";
import type { OptionWithPrimitiveValue } from "@js/types/common";
import { shortenValue } from "@js/utils";

import { RatePopover } from "./rate-popover";

type RateCustomFieldProps = {
  min_rate: WrappedFieldProps;
  max_rate: WrappedFieldProps;
  hourly_rate: WrappedFieldProps;
  rateOptions: Array<OptionWithPrimitiveValue<string>>;
  is_ai_generated: WrappedFieldProps;
  ai_min_rate: WrappedFieldProps;
  ai_max_rate: WrappedFieldProps;
  custom_min_rate: WrappedFieldProps;
  custom_max_rate: WrappedFieldProps;
};

export const RateCustomField = ({
  min_rate,
  max_rate,
  hourly_rate,
  rateOptions,
  is_ai_generated,
  ai_min_rate,
  ai_max_rate,
  custom_min_rate,
  custom_max_rate,
}: RateCustomFieldProps) => {
  const handleClick = (value: FormValue): void => {
    hourly_rate.input.onChange(value);

    if (value === AI_VALUE) {
      min_rate.input.onChange(ai_min_rate.input.value);
      max_rate.input.onChange(ai_max_rate.input.value);
    }
  };

  const isRangeSelected = hourly_rate.input.value === RANGE_VALUE;
  const isAiGenerated = is_ai_generated?.input?.value;

  const rateOptionsList = useMemo(() => {
    const isRateTheSame = ai_min_rate.input.value === ai_max_rate.input.value;
    const isNewOption = isRateTheSame
      ? !rateOptions.find((option) => option.value === ai_min_rate.input.value)
      : true;

    if (
      isAiGenerated &&
      !!ai_min_rate.input.value &&
      !!ai_max_rate.input.value &&
      isNewOption
    ) {
      return [
        ...rateOptions,
        {
          label: generateAiOptionLabel({ ai_min_rate, ai_max_rate }),
          value: AI_VALUE,
        },
      ];
    }
    return rateOptions;
  }, [ai_min_rate, ai_max_rate, isAiGenerated, rateOptions]);

  useEffect(() => {
    if (isRangeSelected) {
      min_rate.input.onChange(custom_min_rate.input.value);
      max_rate.input.onChange(custom_max_rate.input.value);
    }
  }, [custom_min_rate, custom_max_rate, isRangeSelected, max_rate, min_rate]);

  return (
    <div>
      <OptionTileButtons
        value={hourly_rate.input.value}
        onChange={(rate) => handleClick(rate)}
        options={rateOptionsList}
        renderCustomOptionButton={(props) => {
          return (
            <RatePopover
              {...props}
              onClick={() => {
                if (props?.onClick) props.onClick();
                hourly_rate.input.onChange(RANGE_VALUE);
                min_rate.input.onChange(custom_min_rate.input.value);
                max_rate.input.onChange(custom_max_rate.input.value);
              }}
              isAiGenerated={isAiGenerated}
              isRangeSelected={isRangeSelected}
              min_rate={custom_min_rate}
              max_rate={custom_max_rate}
            />
          );
        }}
      />
    </div>
  );
};

const generateAiOptionLabel = ({ ai_min_rate, ai_max_rate }) => {
  const minRate = ai_min_rate.input.value;
  const maxRate = ai_max_rate.input.value;

  const isRateTheSame = !!(minRate === maxRate);
  if (isRateTheSame) {
    return `$${shortenValue(minRate, 6)}`;
  }

  return `$${shortenValue(minRate, 6)}-${shortenValue(maxRate, 6)}`;
};
