import { useEditATSIntegrationMutation } from "@js/apps/employer/api";
import { Snackbar } from "@js/components/snackbar";
import type { ATSIntegration } from "@js/types/employer";

export const useATSJobPostSettingsSection = (integration: ATSIntegration) => {
  const [editATSIntegration] = useEditATSIntegrationMutation();
  const handleAutoFillChange = (_ev: unknown, checked: boolean) => {
    editATSIntegration({ id: integration.id, auto_fill_job_fields: checked })
      .unwrap()
      .catch(() => {
        Snackbar.error("Something went wrong.");
      });
  };

  return {
    handleAutoFillChange,
  };
};
