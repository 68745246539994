import React from "react";
import { change, reduxForm } from "redux-form";

import { Typography } from "@hexocean/braintrust-ui-components";
import type { useAccountType } from "@js/apps/common/hooks";
import { ModalConfirm, ModalInstance } from "@js/components/modal";
import type { FormProps } from "@js/forms/components/form";
import { Form } from "@js/forms/components/form";
import { useAppDispatch } from "@js/hooks";
import type { NotificationSetting } from "@js/types/notifications";

import { Section, SectionRow } from "../helpers";

import { NotificationField } from "./notification-field";

export const NOTIFICATIONS_FORM_ID = "notifications-form";

const ModalContent = ({ name, newValue }) => {
  const dispatch = useAppDispatch();

  return (
    <ModalConfirm
      onConfirm={() => {
        dispatch(change(NOTIFICATIONS_FORM_ID, name, newValue));
        ModalInstance.close();
      }}
      confirmText="Select Anyway"
      onCancel={ModalInstance.close}
    >
      <Typography component="h2" variant="title" fontWeight={400} mb={6}>
        The first 48 hours are CRITICAL
      </Typography>
      <Typography component="p" mb={2}>
        The numbers are in! And we've found that applications submitted in the
        first 48 hours are most likely to be selected.
      </Typography>
      <Typography component="p">
        By choosing weekly, you may not be notified about new roles in time!
      </Typography>
    </ModalConfirm>
  );
};

const onChangeIndividualGroup =
  (notification) => (event, newValue, _previousValue, name) => {
    if (
      notification ===
        ENUMS.NotificationTypes.FREELANCER_NEW_JOB_SAVED_FILTERS &&
      newValue === ENUMS.BatchFreelancerNewOpenJobsNotificationOptions.WEEKLY
    ) {
      event.preventDefault();
      ModalInstance.open({
        className: "max-width-500",
        children: <ModalContent name={name} newValue={newValue} />,
      });
    }
  };

type NotificationsFormProps = {
  submit: () => void;
  error: FormProps["error"];
  submitting: boolean;
  generalSettingsGroups: {
    individual: NotificationSetting[];
    jobs: NotificationSetting[];
    rewards: NotificationSetting[];
  };
  accountType: ReturnType<typeof useAccountType>;
};

const NotificationsForm = ({
  submit,
  error,
  submitting,
  generalSettingsGroups,
}: NotificationsFormProps) => {
  return (
    <Form
      className="notifications-form"
      onSubmit={submitting ? null : submit}
      error={error}
    >
      <Section title="Notifications">
        <Typography
          component="p"
          style={{
            textAlign: "right",
            paddingRight: 16,
            margin: "-34px 0 8px",
          }}
        >
          Email
        </Typography>
        {generalSettingsGroups["individual"].map((setting) => (
          <SectionRow divider key={setting.notification}>
            <NotificationField
              name={`general_notifications_settings.${setting.notification}`}
              onChange={onChangeIndividualGroup(setting.notification)}
              {...setting}
            />
          </SectionRow>
        ))}
        {generalSettingsGroups["jobs"].map((setting) => (
          <SectionRow divider key={setting.notification}>
            <NotificationField
              name={`general_notifications_settings.${setting.notification}`}
              {...setting}
            />
          </SectionRow>
        ))}
        {generalSettingsGroups["rewards"].map((setting) => {
          return (
            <SectionRow key={setting.notification}>
              <NotificationField
                name={`general_notifications_settings.${setting.notification}`}
                {...setting}
              />
            </SectionRow>
          );
        })}
      </Section>
    </Form>
  );
};

export default reduxForm<any, any>({
  form: NOTIFICATIONS_FORM_ID,
  enableReinitialize: true,
})(NotificationsForm);
