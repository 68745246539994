import { useMemo } from "react";
import type { TypedWrappedFieldProps } from "redux-form";

import { useMediaQuery } from "@hexocean/braintrust-ui-components/hooks";
import { shouldDisplayError } from "@js/forms/utils";
import type { FreelancerSkill } from "@js/types/freelancer";

import type {
  FreelancerSkillOption,
  JobSkillOption,
  SimpleSkill,
} from "../types";

import { useGetPopularSkillsOptions } from "./use-get-popular-skills-options";

type UseCreateJobSkillsLogicArgs = {
  newSkillsFormField: TypedWrappedFieldProps<number[]>;
  topSkillsFormField: TypedWrappedFieldProps<number[]>;
  roleId: number | undefined;
  mapSkillToOption: (skill: SimpleSkill) => JobSkillOption;
};

export const useCreateJobSkillsLogic = ({
  newSkillsFormField,
  topSkillsFormField,
  roleId,
  mapSkillToOption,
}: UseCreateJobSkillsLogicArgs) => {
  const isMobile = useMediaQuery("sm");
  const displayError = shouldDisplayError([
    newSkillsFormField,
    topSkillsFormField,
  ]);

  const newMeta = {
    ...newSkillsFormField.meta,
    error: null,
  };

  const errorMessage = newSkillsFormField.meta.error;
  const selectedSkills = newSkillsFormField.input.value;

  const { popularSkillsOptions } = useGetPopularSkillsOptions<JobSkillOption>({
    mapSkillToOption,
    role: roleId,
    selectedSkills: selectedSkills,
    skillsLimit: isMobile ? 5 : 13,
  });

  return {
    displayError,
    newMeta,
    error: errorMessage,
    popularSkillsOptions,
  };
};

type Skill = Pick<FreelancerSkill, "id" | "is_superpower" | "skill">;

type UseEditFreelancerProfileSkillsLogicArgs = {
  newSkillsFormField: TypedWrappedFieldProps<Skill[]>;
  roleId: number;
  mapSkillToOption: (skill: SimpleSkill) => FreelancerSkillOption;
};

export const useEditFreelancerProfileSkillsLogic = ({
  newSkillsFormField,
  roleId,
  mapSkillToOption,
}: UseEditFreelancerProfileSkillsLogicArgs) => {
  const isMobile = useMediaQuery("sm");
  const displayError = shouldDisplayError([newSkillsFormField]);

  const newMeta = {
    ...newSkillsFormField.meta,
    error: null,
  };

  const fieldValues = newSkillsFormField.input.value;
  const selectedSkillsIds = useMemo(() => {
    if (!fieldValues) {
      return [];
    }
    // state.freelancer.freelancerSkills has { id: freelancerSkillId, skill { id: skillId ...} ... },
    // so we need to filter by skill.id (which is equal to option.id from popular_skills endpoint)
    return fieldValues.map(({ skill }) => skill.id);
  }, [fieldValues]);

  const { popularSkillsOptions } =
    useGetPopularSkillsOptions<FreelancerSkillOption>({
      mapSkillToOption,
      role: roleId,
      selectedSkills: selectedSkillsIds,
      skillsLimit: isMobile ? 5 : 13,
    });

  return {
    displayError,
    newMeta,
    // we are already displaying error message at the top of the skills card
    // no need to duplicate this message
    error: undefined,
    popularSkillsOptions,
  };
};
