import React from "react";
import { Outlet } from "react-router-dom";

import { LocationMismatchModalInstance } from "@js/apps/admin/components/location-mismatch-modal";
import { useAppRedirects } from "@js/apps/common/hooks/app-redirects";
import { useForcePageRemount } from "@js/apps/common/hooks/force-page-remount";
import { MessengerProvider } from "@js/apps/messenger/components/messenger-provider";
import { usePollWalletStatus } from "@js/apps/on-ramp/hooks/use-poll-wallet-status";
import { useShowPendingOnrampTransaction } from "@js/apps/on-ramp/hooks/use-show-pending-onramp-transaction";
import { CommonConfirmationModal, ModalInstance } from "@js/components/modal";
import RequestLoader from "@js/components/request-loader";
import { RedirectWarningnModal, useAppSelector } from "@js/hooks";
import { useUpdateScrollPosition } from "@js/hooks/update-scroll-position";
import { AmplitudeAnalyticsProvider } from "@js/services/analytics";

import LiveSiteNotifications from "../../notifications/components/live-site-notifications";
import { OTPModals } from "../components";
import { AccountDependantProviders } from "../components/account-dependant-providers";
import { useConfirmPageApiError } from "../hooks";
import { GoogleAnalytics } from "../services/google-analytics";

const App = () => {
  const appLayoutKey = useAppSelector((state) => state.common.appLayoutKey);

  useUpdateScrollPosition();
  useForcePageRemount();
  useAppRedirects();
  useConfirmPageApiError();
  usePollWalletStatus();
  useShowPendingOnrampTransaction();

  return (
    <React.Fragment key={appLayoutKey}>
      <RequestLoader />
      <Outlet />
      <LiveSiteNotifications />
      <AmplitudeAnalyticsProvider />
      <GoogleAnalytics />
      <ModalInstance />
      <MessengerProvider />
      <CommonConfirmationModal />
      <RedirectWarningnModal />
      <OTPModals />
      <LocationMismatchModalInstance />
      <AccountDependantProviders />
    </React.Fragment>
  );
};

export default App;
