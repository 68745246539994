import { useAppSelector } from "@js/hooks";
import { hasGroup, hasGroupPermission } from "@js/utils";

/**
    @param group - SETTINGS.GROUP
*/

type Group = EnumType<typeof SETTINGS.GROUP>;

export const useHasGroupPermission = (group: Group): boolean => {
  const user = useAppSelector((state) => state.auth.user);
  return hasGroupPermission(user, group);
};

export const useHasAtLeastOneGroupPermission = (groups: Group[]): boolean => {
  const user = useAppSelector((state) => state.auth.user);
  return groups.some((group) => hasGroupPermission(user, group));
};

// for ui purpose eg if we would need to render different ui depending on group
// in most cases you need to use useHasGroupPermission
export const useHasGroup = (group: Group): boolean => {
  const user = useAppSelector((state) => state.auth.user);
  return hasGroup(user, group);
};
