import { Box, Typography } from "@hexocean/braintrust-ui-components";
import { PersonIcon, StarIcon } from "@hexocean/braintrust-ui-components/Icons";
import { CategoryBudgetBadgeContainer } from "@js/apps/give-and-get-help/components/post-category-and-budget-badges";
import type {
  ProfileCardRatingItemProps,
  ProfileCardRatingProps,
  ProfileCardServicesProps,
} from "@js/apps/give-and-get-help/components/profile-card/profile-card.types";
import { RouterLink } from "@js/components/link";
import { pluralize } from "@js/utils";

import { getCategoryLabel } from "../category-and-budget-utils";

export const ProfileCardServices = ({ services }: ProfileCardServicesProps) => {
  return (
    <Box display="flex" gap={1} flexWrap="wrap">
      {services.map((category) => (
        <CategoryBudgetBadgeContainer
          key={category.name}
          categoryColor={category.color}
          padding="4px 12px"
        >
          {getCategoryLabel(category, false, { size: "small" })}
        </CategoryBudgetBadgeContainer>
      ))}
    </Box>
  );
};

export const ProfileCardRating = ({
  averageRating,
  reviewCount,
  talentId,
}: ProfileCardRatingProps) => {
  if (!averageRating) {
    return null;
  }

  const linkToProfile = `/talent/${talentId}#review-section`;

  return (
    <Box
      className="profile-card-services-with-rating"
      component={RouterLink}
      to={linkToProfile}
      target="_blank"
    >
      <ProfileCardRatingItem
        icon={<StarIcon style={{ fontSize: "16px", color: "var(--orange)" }} />}
        label={`${averageRating}`}
      />
      <ProfileCardRatingItem
        icon={<PersonIcon style={{ fontSize: "16px" }} />}
        label={`${reviewCount} review${pluralize(reviewCount, {
          zeroPlural: true,
        })}`}
      />
    </Box>
  );
};

const ProfileCardRatingItem = ({ icon, label }: ProfileCardRatingItemProps) => {
  return (
    <Box
      display="flex"
      alignItems="center"
      gap={0.25}
      bgcolor="var(--off-white)"
      borderRadius={0.5}
      px={1}
    >
      {icon}
      <Typography
        size="small"
        component="span"
        variant="paragraph"
        whiteSpace="nowrap"
      >
        {label}
      </Typography>
    </Box>
  );
};
