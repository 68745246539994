import { useCallback, useMemo, useState } from "react";
import type { WrappedFieldProps } from "redux-form";
import { debounce } from "underscore";

import {
  useGetPopularHashtagsQuery,
  useLazyGetHashtagsAutocompleteQuery,
} from "@js/apps/give-and-get-help/api";
import type { Hashtag, PostHashtag } from "@js/apps/give-and-get-help/types";

import { areHashtagsEqual } from "../utils/are-hashtags-equal";
import { getHashtagName } from "../utils/get-hashtag-name";
import { getHashtagRawName } from "../utils/get-hashtag-raw-name";

const parseDownloadedHashtags = (hashtags: Hashtag[]) => {
  return hashtags.map((hashtag) => ({
    ...hashtag,
    name: "#" + hashtag.name,
  }));
};

type useHashtagCollectionProps = {
  input: WrappedFieldProps["input"];
};

export const useHashtagCollection = ({ input }: useHashtagCollectionProps) => {
  const [isQueryStarted, setIsQueryStarted] = useState(false);

  const addedHashtags: Array<Hashtag | PostHashtag> = useMemo(
    () => input.value || [],
    [input.value],
  );

  const setAddedHashtags = (value: Array<Hashtag | PostHashtag>) =>
    input.onChange(value);

  const {
    data: popularHashtagsData = [],
    isLoading: isLoadingPopularHashtags,
  } = useGetPopularHashtagsQuery();
  const popularHashtags = parseDownloadedHashtags(popularHashtagsData);

  const [
    getHashtagsAutocomplete,
    { isFetching, data: hashtagsAutocompleteData = [] },
  ] = useLazyGetHashtagsAutocompleteQuery();
  const hashtagsAutocomplete = parseDownloadedHashtags(
    hashtagsAutocompleteData,
  );

  const [shouldReturnPopular, setShouldReturnPopular] = useState(true);

  const downloadedHashtags = shouldReturnPopular
    ? popularHashtags
    : hashtagsAutocomplete;

  const addHashtagsByNames = (hashtagsNames: string[]) => {
    const hashtags = downloadedHashtags.filter((downloadedHashtag) =>
      hashtagsNames.some((hashtagName) =>
        areHashtagsEqual(downloadedHashtag.name, hashtagName),
      ),
    );

    if (hashtags.length) {
      setAddedHashtags([
        ...new Map(
          [...addedHashtags, ...hashtags].map((item) => [item["name"], item]),
        ).values(),
      ]);
    }
  };

  const removeHashtagsByNames = (hashtagsNames: string[]) => {
    setAddedHashtags(
      addedHashtags.filter((addedHashtag) => {
        return !hashtagsNames.some((hashtagName) => {
          return areHashtagsEqual(getHashtagName(addedHashtag), hashtagName);
        });
      }),
    );
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const getHashtagsAutocompleteDebounced = useCallback(
    debounce((query: string) => {
      setIsQueryStarted(false);
      const hashtagQuery = getHashtagRawName(query);
      setShouldReturnPopular(!hashtagQuery.length);

      if (hashtagQuery.length) {
        return getHashtagsAutocomplete(hashtagQuery);
      }
    }, 500),
    [],
  );

  const searchHashtags = useCallback(
    (query = "") => {
      setIsQueryStarted(true);
      getHashtagsAutocompleteDebounced(query);
    },
    [setIsQueryStarted, getHashtagsAutocompleteDebounced],
  );

  const hashtagPropositions = useMemo(() => {
    const uniqueHashtagPropositions = downloadedHashtags.filter(
      (downloadedHashtag) => {
        return !addedHashtags.some((hashtag) =>
          areHashtagsEqual(getHashtagName(hashtag), downloadedHashtag.name),
        );
      },
    );

    return uniqueHashtagPropositions;
  }, [addedHashtags, downloadedHashtags]);

  return {
    addedHashtags,
    addHashtagsByNames,
    removeHashtagsByNames,
    searchHashtags,
    isLoading: isQueryStarted || isFetching,
    hashtagPropositions,
    popularHashtags,
    isLoadingPopularHashtags,
  };
};
