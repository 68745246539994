import React from "react";

import { Button } from "@hexocean/braintrust-ui-components";
import { TrashIcon } from "@hexocean/braintrust-ui-components/Icons";
import { useIsProfileOwnerContext } from "@js/apps/common/profile/is-profile-owner-context";
import {
  fetchFreelancerProfile,
  fetchFreelancerPublicProfile,
} from "@js/apps/freelancer/actions";
import { useDeleteResumeMutation } from "@js/apps/freelancer/api";
import { openDeleteResumeModal } from "@js/apps/freelancer/components/profile-resume/modals/delete-resume";
import { useAppDispatch, useAppSelector } from "@js/hooks";

export const DeleteResumeButton = () => {
  const [deleteResumeMutation] = useDeleteResumeMutation();
  const { isProfileOwner } = useIsProfileOwnerContext();
  const hasUploadedResume = useAppSelector(
    (state) => state.freelancer.publicProfile?.resume,
  );
  const freelancerId = useAppSelector(
    (state) => state.freelancer.publicProfile?.id,
  );
  const dispatch = useAppDispatch();

  const deleteResume = async () => {
    if (freelancerId) {
      await deleteResumeMutation({ id: freelancerId });
      // TODO after freelancer actions refactor: remove fetches and update state trough api.useDeleteResumeMutation
      await dispatch(fetchFreelancerPublicProfile(freelancerId));
      await dispatch(fetchFreelancerProfile(freelancerId));
    }
  };
  const openConfirmationModal = () => {
    openDeleteResumeModal({
      onConfirm: deleteResume,
    });
  };

  if (!hasUploadedResume || !isProfileOwner) return null;

  return (
    <Button
      variant="transparent"
      startIcon={<TrashIcon />}
      onClick={openConfirmationModal}
    >
      Delete
    </Button>
  );
};
