import React from "react";

import { Box, Button, Typography } from "@hexocean/braintrust-ui-components";

import { useClearFilters } from "../../apps/listing/hooks/use-clear-filters";
import { useJobLocationContext } from "../../context";

type JobsEmptyStateProps = {
  title: string;
  description: string;
};

export const JobsEmptyState = ({ title, description }: JobsEmptyStateProps) => {
  const location = useJobLocationContext();
  const { clearFilters } = useClearFilters();
  return (
    <Box
      textAlign="center"
      borderRadius={2}
      py={{ xs: 4, md: 8, lg: 10 }}
      px={{ xs: 2 }}
    >
      <Box maxWidth={626} margin="auto">
        <img src={`${SETTINGS.STATIC_URL}jobs/jobs-empty-state.svg`} alt="" />
        <Typography component="p" variant="label" mt={2} mb={2}>
          {title}
        </Typography>
        <Typography component="p" fontWeight={400} mb={2}>
          {description}
        </Typography>
        <Button
          size="x-small"
          variant="secondary"
          onClick={() => clearFilters(location)}
        >
          Clear all filters
        </Button>
      </Box>
    </Box>
  );
};
