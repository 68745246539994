import { Box } from "@hexocean/braintrust-ui-components";
import { useDashboardProfileCompletionNudge } from "@js/apps/freelancer/hooks";
import { useAppSelector } from "@js/hooks";

import { useOpenProfileCompletionModalByQueryParam } from "../../profile-completion-sequence/hooks/use-open-profile-completion-modal-by-query-param";
import { openProfileCompletionSequenceModal } from "../../profile-completion-sequence/profile-completion-sequence";
import { SectionPanel } from "../utils";

import { Card } from "./card";

export const ProfileCompletionNudgeSection = () => {
  const {
    isLoading,
    isProfileCompleted,
    missingRoleCount,
    missingSkillsCount,
    missingExperienceCount,
    missingAboutCount,
  } = useDashboardProfileCompletionNudge();

  const profile = useAppSelector((state) => state.freelancer.freelancerProfile);

  useOpenProfileCompletionModalByQueryParam(profile);

  const handleAddAreaClick = () => {
    if (!profile) return;
    openProfileCompletionSequenceModal(profile);
  };

  if (isLoading || isProfileCompleted) {
    return null;
  }

  return (
    <SectionPanel
      isNudge
      title="Complete your profile to apply for jobs"
      subtitle="Clients need to know more about you to see if you’re a match for their open positions."
    >
      <Box className="profile-completion-nudge-section__card-list">
        <Card
          icon="👋"
          iconBgColor="var(--soft-blue)"
          missingItems={missingAboutCount}
          onClick={handleAddAreaClick}
        >
          Bio
        </Card>
        <Card
          icon="🔰"
          iconBgColor="var(--soft-green)"
          missingItems={missingRoleCount}
          onClick={handleAddAreaClick}
        >
          Roles
        </Card>
        <Card
          icon="🔧"
          iconBgColor="var(--soft-violet)"
          missingItems={missingSkillsCount}
          onClick={handleAddAreaClick}
        >
          Skills
        </Card>
        <Card
          icon="💼"
          iconBgColor="var(--soft-orange)"
          missingItems={missingExperienceCount}
          onClick={handleAddAreaClick}
        >
          Work history
        </Card>
      </Box>
    </SectionPanel>
  );
};
