import { Box, Typography } from "@hexocean/braintrust-ui-components";

type SetWorkAvailabilityProps = {
  onClick: (e: React.MouseEvent<HTMLElement>) => void;
};

export const SetWorkAvailability = ({ onClick }: SetWorkAvailabilityProps) => {
  return (
    <Box className="set-work-availability" onClick={onClick}>
      <Typography component="span" size="small" fontWeight={500} color="grey-1">
        Set work availability
      </Typography>
    </Box>
  );
};
