import {
  IconButton,
  Menu,
  useMediaQuery,
} from "@hexocean/braintrust-ui-components";
import { MoreVertIcon } from "@hexocean/braintrust-ui-components/Icons";
import type { FreelancerInvoice } from "@js/types/invoices";

import { MarkAsPaidAction } from "../../mark-as-paid-action";

import { useSingleInvoiceActionMenu } from "./use-single-invoice-action-menu";

export type SingleInvoiceActionMenuProps = {
  invoice: FreelancerInvoice;
  prevPath?: string;
  onMarkAsPaid: (invoices: number[]) => void;
  onlyMobileMenu: boolean;
  cancelFreelancerInvoice: (id: number) => Promise<unknown>;
};

export const FreelancerSingleInvoiceActionMenu = ({
  invoice,
  prevPath,
  onMarkAsPaid,
  onlyMobileMenu,
  cancelFreelancerInvoice,
}: SingleInvoiceActionMenuProps) => {
  const isMobile = useMediaQuery("md");
  const { onCancel, onCopy, onDownload, onEdit } = useSingleInvoiceActionMenu({
    invoice,
    prevPath,
    cancelFreelancerInvoice,
  });

  return (
    <Menu
      id={`invoices-listing-item-actions-menu=${invoice.id}`}
      anchor={
        <IconButton
          variant="transparent"
          aria-controls={`invoices-listing-item-actions-menu=${invoice.id}`}
          aria-haspopup="true"
          aria-label={`invoice ${invoice.number} actions`}
        >
          <MoreVertIcon />
        </IconButton>
      }
    >
      <Menu.Item key="copy" onClick={onCopy}>
        Copy
      </Menu.Item>
      <Menu.Item key="download" onClick={onDownload}>
        Download PDF
      </Menu.Item>
      {(isMobile || onlyMobileMenu) && (
        <MarkAsPaidAction
          type="menuitem"
          invoices={[invoice]}
          markAsPaid={onMarkAsPaid}
        />
      )}
      <Menu.Item
        onClick={onEdit}
        disabled={!invoice.can_be_edited}
        key="edit invoice"
      >
        Edit Invoice
      </Menu.Item>
      <Menu.Item
        key="cancel"
        disabled={!invoice.can_be_cancelled}
        onClick={onCancel}
      >
        Cancel
      </Menu.Item>
    </Menu>
  );
};
