import React from "react";
import classNames from "classnames";
import _ from "underscore";

import { Typography } from "@hexocean/braintrust-ui-components";
import type { StandardTextFieldProps } from "@js/forms/fields/text";

import { StandardTextField } from "../text";

/**
 * Example usage:
 *
 * ```
 * <Field
 *     name="message"
 *     minLength={4}
 *     maxLength={10}
 *     label="Your Message"
 *     component={TextareaField} />
 * ```
 */

type TextareaFieldProps = StandardTextFieldProps & {
  minLength?: number;
  maxLabel?: number;
  maxLength?: number;
  markdown?: boolean;
};

export const StandardTextareaField = (props: TextareaFieldProps) => {
  const {
    minLength,
    maxLabel,
    maxLength,
    input: { value },
    fullWidth,
    className,
    markdown,
    rows = 4,
    minRows,
    maxRows,
  } = props;

  return (
    <div
      className={classNames("textarea", className)}
      style={fullWidth ? { width: "100%" } : {}}
    >
      <StandardTextField
        multiline
        floatingLabel
        size="small"
        rows={minRows || maxRows ? undefined : rows}
        {..._.omit(
          props,
          "rows",
          "minLength",
          "maxLength",
          "maxLabel",
          "className",
          "markdown",
        )}
      />
      <Typography
        component="p"
        color="grey-4"
        className="textarea__helper-text"
      >
        {minLength && <span>min. {minLength} char</span>}
        {maxLabel && <span>max. {maxLength} char</span>}
        {maxLength && (
          <span>
            {String(value).trim().length} / {maxLength}
          </span>
        )}
        {markdown && (
          <span>
            You can use
            <Typography
              component="a"
              fontWeight={500}
              className="textarea__link"
              href="https://help.github.com/en/github/writing-on-github/basic-writing-and-formatting-syntax"
              target="_blank"
              rel="noopener noreferrer"
            >
              basic markdown syntax
            </Typography>
            .
          </span>
        )}
      </Typography>
    </div>
  );
};
