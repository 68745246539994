import React, { useCallback } from "react";

import {
  IconButton,
  InputAdornment,
  RegularTextField,
} from "@hexocean/braintrust-ui-components";
import {
  CloseIcon,
  SearchSVGIcon,
} from "@hexocean/braintrust-ui-components/Icons";
import type { ATSIntegration } from "@js/types/employer";

import styles from "./style.module.scss";

export const ATSJobsSearch = ({
  name,
  searchValue,
  onSearchValueChange,
}: {
  name: ATSIntegration["integration"];
  searchValue: string;
  onSearchValueChange: (value: string) => void;
}) => {
  const handleSearchValueChange = useCallback(
    (ev: React.ChangeEvent<HTMLInputElement>) => {
      onSearchValueChange(ev.target.value);
    },
    [onSearchValueChange],
  );
  return (
    <RegularTextField
      variant="outlined"
      size="medium"
      value={searchValue}
      onChange={handleSearchValueChange}
      placeholder={`Search for ${name} jobs`}
      inputProps={{
        "aria-label": `Search for ${name} jobs`,
      }}
      className={styles.atsJobsSearch}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchSVGIcon />
          </InputAdornment>
        ),
        endAdornment: searchValue ? (
          <InputAdornment position="end">
            <IconButton
              aria-label="clear search"
              onClick={() => onSearchValueChange("")}
              variant="transparent"
              sx={{
                p: "0px !important",
              }}
              size="medium"
            >
              <CloseIcon />
            </IconButton>
          </InputAdornment>
        ) : undefined,
      }}
    />
  );
};
