import { useCallback, useState } from "react";

import { openCropperModal } from "@js/components/cropper/cropper-modal";
import { imageDimensionsValidator } from "@js/utils/dom";

import {
  getFileWithId,
  readFileAsDataURL,
  validateFilesSize,
  validateImageType,
} from "../../helpers/file-upload";
import type { CropAndUploadOptions, UploadType } from "../../types";
import type { UseUploadSingleFileArg } from "../use-upload-single-file";
import { useUploadSingleFile } from "../use-upload-single-file";

export type UseCropAndUploadImageArg = UseUploadSingleFileArg;

export const useCropAndUploadImage = (arg?: UseCropAndUploadImageArg) => {
  const [imageError, setImageError] = useState<string | undefined>();
  const {
    uploadSingleFile,
    uploadedFile,
    isUploading,
    error: uploadError,
    resetFileState: resetUploadFileState,
  } = useUploadSingleFile(arg);

  const cropAndUploadImage = useCallback(
    ({
      file,
      squared,
      minWidth,
      minHeight,
      uploadType,
      maxSize,
      cropperWidth,
      cropperHeight,
      validFormats,
    }: {
      file: File;
      uploadType: UploadType;
      validFormats?: string[];
    } & CropAndUploadOptions) => {
      const validationError =
        validateImageType([file], validFormats) ||
        validateFilesSize([file], maxSize);
      if (validationError) {
        setImageError(validationError);
        return;
      }

      setImageError(undefined);

      readFileAsDataURL({
        file,
        onFileLoad: (img: HTMLImageElement) => {
          const isSizeValid = imageDimensionsValidator(
            img,
            minWidth,
            minHeight,
            (validatorError) => setImageError(validatorError),
          );
          if (!isSizeValid) {
            return;
          }

          openCropperModal({
            file,
            onCrop: async (croppedFile: File) => {
              const fileWithId = getFileWithId(croppedFile);

              uploadSingleFile({ file: fileWithId, uploadType });
            },
            circularCrop: !squared,
            width: cropperWidth,
            height: cropperHeight,
          });
        },
        onError: (err: string) => setImageError(err),
      });
    },
    [uploadSingleFile],
  );

  const resetFileState = (resetUploadedFile?: boolean) => {
    setImageError(undefined);
    resetUploadFileState(resetUploadedFile);
  };

  const error = uploadError || imageError;

  return {
    cropAndUploadImage,
    error,
    isUploading,
    uploadedFile,
    resetFileState,
  };
};
