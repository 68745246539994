import type { TypographyProps } from "@hexocean/braintrust-ui-components";
import { Box, Button, Typography } from "@hexocean/braintrust-ui-components";
import { ArrowTopRightIcon } from "@hexocean/braintrust-ui-components/Icons";
import { BasicInfo } from "@js/apps/jobs/components";
import { RouterLink } from "@js/components/link";
import type { Job } from "@js/types/jobs";
import { getPaymentAmountWithSuffix } from "@js/utils";

type BasicJobInfoProps = {
  job: Job;
};

export const BasicJobInfo = ({ job }: BasicJobInfoProps) => (
  <Box mt={1.25}>
    <BasicInfo job={job} />
  </Box>
);

type PaymentAmountProps = Partial<TypographyProps> & {
  paymentAmount: string;
  paymentType: string;
};

export const PaymentAmount = ({
  paymentAmount,
  paymentType,
  ...props
}: PaymentAmountProps) => {
  return (
    <Typography component="p" variant="paragraph" size="large" {...props}>
      {getPaymentAmountWithSuffix(paymentAmount, paymentType, {
        amountOptions: { removeDecimal: true },
      })}
    </Typography>
  );
};

export const ViewJobButton = ({ jobId }) => (
  <Button
    variant="primary"
    to={`/jobs/${jobId}`}
    rel="noopener noreferrer"
    target="_blank"
    RouterLink={RouterLink}
    fullWidth={false}
    sx={{
      display: { xs: "none", sm: "flex" },
      flexShrink: 0,
      height: 40,
      width: "122px !important",
    }}
  >
    View job
    <ArrowTopRightIcon />
  </Button>
);
