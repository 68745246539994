import type { ComponentType } from "react";
import React from "react";
import type { TypedWrappedFieldProps } from "redux-form";

import type {
  StandardInputProps,
  TextFieldProps as TextFieldPropsBUC,
} from "@hexocean/braintrust-ui-components";
import {
  StandardInput,
  TextField as TextFieldCustom,
} from "@hexocean/braintrust-ui-components";

type WrappedFieldStringOrNumber =
  | TypedWrappedFieldProps<string>
  | TypedWrappedFieldProps<number>;

export type TextFieldErrorProps = {
  mapErrorCode?: (errorCode: string, isSignup?: boolean) => string | null;
  hideError?: boolean;
};

export const ReduxFormTextFieldFactory =
  <T extends TextFieldPropsBUC>(Component: React.ElementType) =>
  ({
    input,
    meta,
    helperText,
    mapErrorCode,
    hideError,
    showErrorWhen = "touched",
    ...props
  }: T & WrappedFieldStringOrNumber & TextFieldErrorProps) => {
    const { error, submitFailed } = meta;
    const helperTextToDisplay =
      error && (meta[showErrorWhen] || submitFailed) && !hideError
        ? Array.isArray(error)
          ? error.map((err) => (
              <span className="m0 block" key={err}>
                {mapErrorCode ? mapErrorCode(err) : err}
              </span>
            ))
          : error
        : helperText;

    return (
      <Component
        {...props}
        {...input}
        onChange={(e) => {
          if (props.onChange) {
            props?.onChange(e);
          }
          input.onChange(e);
        }}
        onDrop={
          props.disabled
            ? () => {
                return null;
              }
            : input.onDrop
        }
        helperText={helperTextToDisplay}
        error={(submitFailed || meta[showErrorWhen]) && !!error}
      />
    );
  };

export type TextFieldProps = TextFieldPropsBUC &
  TextFieldErrorProps &
  WrappedFieldStringOrNumber;

export const TextField: ComponentType<TextFieldProps> =
  ReduxFormTextFieldFactory<TextFieldPropsBUC>(TextFieldCustom);

export type StandardTextFieldProps = StandardInputProps &
  TextFieldErrorProps &
  WrappedFieldStringOrNumber;

/** @deprecated — use `TextField` with `variant="standard"` instead */
export const StandardTextField: ComponentType<StandardTextFieldProps> =
  ReduxFormTextFieldFactory<StandardInputProps>(StandardInput);
