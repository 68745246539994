export const validateStyles = (styles, allowedProperties = []) => {
  const output: string[] = [];

  allowedProperties.forEach((prop) => {
    if (styles[prop]) {
      output.push(prop + ":" + styles[prop] + ";");
    }
  });

  return output;
};
