import { useState } from "react";
import type Swiper from "swiper";

import { SessionStorage } from "@js/services";

export const useUserCommentsCarousel = () => {
  const [initSlide, setInitSlide] = useState(() => {
    try {
      const value = SessionStorage.getItem("initSlide");
      return value !== null ? JSON.parse(value) : 0;
    } catch (e) {
      return 0;
    }
  });

  const handleSlideChange = (swiper: Swiper) => {
    const newInitSlide = swiper.realIndex === 0 ? 0 : swiper.realIndex - 1;
    setInitSlide(newInitSlide);
    SessionStorage.setItem("initSlide", JSON.stringify(newInitSlide));
  };

  return [initSlide, handleSlideChange];
};
