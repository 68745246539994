export const hasPermission = (user, permission) => {
  if (!user) return false;
  if (user.is_superuser) return true;
  return user.permissions.includes(permission);
};

type Group = EnumType<typeof SETTINGS.GROUP>;
// for action purpose - if user can trigger some action
export const hasGroupPermission = (user, group: Group) => {
  if (!user) return false;
  if (user.is_superuser) return true;
  if (!user.groups) return false;
  return user.groups.includes(group);
};

// for UI purpose - e.g if group needs different UI than admin
export const hasGroup = (user, group: Group) => {
  if (!user) return false;
  if (!user.groups) return false;
  return user.groups.includes(group);
};
