import { Box } from "@hexocean/braintrust-ui-components";

import { useReferralsSort } from "../../hooks/use-referrals-sort";
import type { OrderByDirectionType, UserReferralType } from "../../types";

import { ReferralsSortByButton } from "./referrals-sort-by-button";
import { ReferralsSortDirectionButton } from "./referrals-sort-direction-button";

import style from "./style.module.scss";

export type ReferralsSortButtonColorVariantsType = "violet" | "green";

export type ReferralsSortButtonProps<T> = {
  defaultOrderDirection?: OrderByDirectionType;
  colorVariant?: ReferralsSortButtonColorVariantsType;
  defaultOrderByParamValue: T;
  orderByOptions: ReadonlyArray<{ label: string; value: T }>;
  type: UserReferralType;
};

export const ReferralsSortButton = <T,>({
  defaultOrderByParamValue,
  orderByOptions,
  defaultOrderDirection = "desc",
  colorVariant = "violet",
  type,
}: ReferralsSortButtonProps<T>) => {
  const {
    sortByHandler,
    currentOrderBy,
    sortDirectionHandler,
    currentOrderDirection,
  } = useReferralsSort({
    orderByOptions,
    defaultOrderByParamValue,
    defaultOrderDirection,
    type,
  });

  return (
    <Box className={style.referralSortButton}>
      <ReferralsSortByButton
        onChange={sortByHandler}
        options={orderByOptions}
        currentValue={currentOrderBy}
        colorVariant={colorVariant}
      />
      <ReferralsSortDirectionButton
        onChange={sortDirectionHandler}
        direction={currentOrderDirection}
        colorVariant={colorVariant}
      />
    </Box>
  );
};
