import React from "react";
import { isPristine as isPristineSelector, reset, submit } from "redux-form";

import { useAccountType } from "@js/apps/common/hooks";
import { ClientNotificationSettings } from "@js/apps/settings/views/client-notifications";
import { useAppDispatch, useAppSelector } from "@js/hooks";

import { NotificationsContainer } from "../../containers/notifications";
import NotificationsForm, {
  NOTIFICATIONS_FORM_ID,
} from "../../forms/notifications";
import { SettingsLayout } from "../layout";

const NotificationsPage = () => {
  const dispatch = useAppDispatch();
  const isPristine = useAppSelector((state) =>
    isPristineSelector(NOTIFICATIONS_FORM_ID)(state),
  );
  const { isEmployer, isFreelancer } = useAccountType();

  return (
    <SettingsLayout
      pageTitle="Notifications"
      onSave={() => dispatch(submit(NOTIFICATIONS_FORM_ID))}
      onCancel={() => dispatch(reset(NOTIFICATIONS_FORM_ID))}
      onCancelDisabled={isPristine}
    >
      {isEmployer && (
        <>
          <ClientNotificationSettings />
        </>
      )}
      {isFreelancer && (
        <NotificationsContainer
          render={(props) => <NotificationsForm {...props} />}
        />
      )}
    </SettingsLayout>
  );
};

export default NotificationsPage;
