import { SubmissionError } from "redux-form";

import { useAppSelector } from "@js/hooks";
import type { WorkSampleItem } from "@js/types/freelancer";

import {
  useAddPortfolioItemMutation,
  useDeletePortfolioItemMutation,
  useUpdatePortfolioItemMutation,
} from "../../api";

export const useFreelancerProjectsManager = () => {
  const projects = useAppSelector((state) => state.freelancer.workSampleItems);
  const [addPortfolioItem] = useAddPortfolioItemMutation();
  const [updatePortfolioItem] = useUpdatePortfolioItemMutation();
  const [deletePortfolioItem] = useDeletePortfolioItemMutation();

  const handleAddProject = async (values: Omit<WorkSampleItem, "id">) => {
    await addPortfolioItem(values)
      .unwrap()
      .catch((error) => {
        throw new SubmissionError(error.data);
      });
  };

  const handleEditProject = async (values: WorkSampleItem) => {
    await updatePortfolioItem(values)
      .unwrap()
      .catch((error) => {
        throw new SubmissionError(error.data);
      });
  };

  const handleDeleteProject = async (projectId: number) => {
    await deletePortfolioItem(projectId);
  };

  return {
    projects,
    handleAddProject,
    handleEditProject,
    handleDeleteProject,
  };
};
