import * as Sentry from "@sentry/react";

import type { SocketSendQueue } from "@js/components/websocket";

import { SOCKET_URL, SocketReadyStateMap, SocketStatus } from "../constants";

export class WebSocketClient {
  private webSocketBridge: WebSocket | undefined;

  private readonly _onOpen: () => void;

  private readonly _onClose: () => void;

  private readonly _onReceive: (event: MessageEvent) => void;

  private readonly _onError: (event: WebSocketEventMap["error"]) => void;

  private readonly _changeSocketStatus: (
    status: EnumType<typeof SocketStatus>,
  ) => void;

  constructor(
    onOpen,
    onClose,
    onReceive,
    onError,
    changeSocketStatus: WebSocketClient["_changeSocketStatus"],
  ) {
    this._onOpen = onOpen;
    this._onClose = onClose;
    this._onReceive = onReceive;
    this._onError = onError;
    this._changeSocketStatus = changeSocketStatus;
  }

  public connect = (): void => {
    try {
      this._changeSocketStatus(SocketStatus.CONNECTING_INITIALIZED);
      this._initConnect();
      this._attachListeners();
    } catch (e: any) {
      this._changeSocketStatus(SocketStatus.CONNECTING_FAILED);
      Sentry.captureException("WebSocket service is not available.", {
        extra: { error: e },
      });
      return e;
    }
  };

  public disconnect = (): void => {
    if (
      this.webSocketBridge?.close &&
      typeof this.webSocketBridge.close === "function"
    ) {
      this.webSocketBridge.close();
    }

    this._changeSocketStatus(SocketStatus.DISCONNECTED);
  };

  public send = (params: SocketSendQueue | undefined): void => {
    if (params) {
      const { stream, data } = params;
      if (this.webSocketBridge) {
        this.webSocketBridge.send(
          JSON.stringify({
            stream,
            payload: data,
          }),
        );
      }
    }
  };

  public isOpen = (): boolean => {
    if (!this.webSocketBridge) return false;

    const readyState = this.webSocketBridge.readyState;
    return readyState === SocketReadyStateMap.OPEN;
  };

  public isConnecting = (): boolean => {
    if (!this.webSocketBridge) return false;

    const readyState = this.webSocketBridge.CONNECTING;
    return readyState === SocketReadyStateMap.CONNECTING;
  };

  public isClosed = (): boolean => {
    if (!this.webSocketBridge) return false;

    const readyState = this.webSocketBridge.readyState;
    return readyState === SocketReadyStateMap.CLOSED;
  };

  public isTransitioning = (): boolean => {
    return !this.isOpen() && !this.isClosed();
  };

  private _initConnect = (): void => {
    if (!this.webSocketBridge) {
      this.webSocketBridge = new WebSocket(SOCKET_URL);
    }
  };

  private _attachListeners = (): void => {
    this.webSocketBridge?.addEventListener("close", this._onClose);
    this.webSocketBridge?.addEventListener("open", this._onOpen);
    this.webSocketBridge?.addEventListener("message", this._onReceive);
    this.webSocketBridge?.addEventListener("error", this._onError);
  };
}
