import React from "react";

import {
  Button,
  RoundedBox,
  Typography,
} from "@hexocean/braintrust-ui-components";
import { ArrowTopRightIcon } from "@hexocean/braintrust-ui-components/Icons";
import { useAccountType, useUser } from "@js/apps/common/hooks";
import {
  isInterviewAvailable,
  useGetLastInterviewQuery,
} from "@js/apps/interview";
import { RouterLink } from "@js/components/link";

export const ScreeningCard = (): JSX.Element | null => {
  const { isFreelancerApproved, isEmployer } = useAccountType();
  const { data: interview } = useGetLastInterviewQuery(undefined, {
    skip: isFreelancerApproved || isEmployer,
  });
  const isInterviewAvailableForUser = isInterviewAvailable(interview);
  const user = useUser();

  if (isInterviewAvailableForUser && user && user.freelancer) {
    return (
      <ScreeningCardBase
        title="Start your screening!"
        btnText="Start screening"
        link={`/talent/${user.freelancer}?interview=true`}
        content={
          "Only applications from individuals who have been approved as Talent are shown to clients, so it's essential to complete your screening to land a job on Braintrust."
        }
      />
    );
  }

  return null;
};

type ScreeningCardBaseProps = {
  title: string;
  content: JSX.Element | string;
  btnText?: string;
  link?: string;
};

const ScreeningCardBase = ({
  title,
  content,
  btnText,
  link,
}: ScreeningCardBaseProps) => {
  return (
    <RoundedBox bgcolor="var(--medium-green)">
      <Typography component="p" variant="paragraph" size="large" mb={2}>
        {title}
      </Typography>
      <Typography component="p" variant="paragraph">
        {content}
      </Typography>
      {!!btnText && (
        <Button
          variant="primary"
          to={link}
          rel="noopener noreferrer"
          target="_blank"
          sx={{ mt: 2 }}
          RouterLink={RouterLink}
        >
          {btnText}{" "}
          <ArrowTopRightIcon
            style={{
              fontSize: 24,
              marginLeft: 12,
            }}
          />
        </Button>
      )}
    </RoundedBox>
  );
};
