import { MyWorkListingPage } from "../my-work-listing-page";

const MY_JOBS_NAVIGATION_ITEMS = [
  {
    label: "Active jobs",
    path: "/talent/dashboard/my_jobs/history/active_jobs",
  },
  {
    label: "Past jobs",
    path: "/talent/dashboard/my_jobs/history/past_jobs",
  },
];

export const DashboardMyJobsPage = (): JSX.Element => {
  return (
    <MyWorkListingPage
      items={MY_JOBS_NAVIGATION_ITEMS}
      pageTitle="Jobs History"
    />
  );
};
