import React from "react";
import { reduxForm } from "redux-form";

import { Form } from "../form";

/*

Simple wrapper which allows to display error e.g. in confirmation modal without setting it in local state.

Example:

const Send = SendForm('some-unique-id');

<Send onSubmit={() => promiseAction()
    .then(() => {
        Snackbar.success('Successful');
        ConfirmationModal.close();
    })
    .catch((error) => {
        throw new SubmissionError(error);
    })}>
    {(submit) =>
        <ModalConfirm
            onConfirm={submit}
            onCancel={ConfirmationModal.close}>
            {content}
        </ModalConfirm>}
</Send>

 */

type SendFormProps = {
  children: (submit: () => void) => React.ReactNode;
  onSubmit: (values) => void;
};
export const SendForm = (formId) =>
  reduxForm<unknown, SendFormProps>({ form: formId })(
    ({ children, onSubmit, submit, error }) => {
      return (
        <Form onSubmit={onSubmit} error={error}>
          {children(submit)}
        </Form>
      );
    },
  );
