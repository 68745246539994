import { useLocation } from "react-router-dom";

import {
  Table,
  TableBody,
  TableHead,
} from "@hexocean/braintrust-ui-components";
import type { FreelancerInvoice } from "@js/types/invoices";

import type { InvoiceFiltersFetchParams } from "../../filters";
import { FreelancerInvoiceJobFilterForm } from "../../forms/freelancer-invoice-job-filter";
import { InvoiceSearchFilter } from "../../forms/invoice-search-filter";
import { InvoiceListingFilters } from "../invoice-listing-filters";

import { FreelancerInvoiceListingTableHead } from "./freelancer-invoice-listing-table-head";
import { FreelancerInvoicesActionsMenu } from "./freelancer-invoices-actions-menu";
import { FreelancerSingleInvoiceRow } from "./freelancer-single-invoice-row";

export type FreelancerInvoicesListingTableNewProps = {
  filters: Partial<InvoiceFiltersFetchParams>;
  invoiceList: FreelancerInvoice[];
  onlyMobileMenu?: boolean;
  prevPath?: string;
  onSubmit: (filters: Partial<InvoiceFiltersFetchParams>) => void;
  onInvoicesPaid?: () => void;
  cancelFreelancerInvoice: (id: number) => Promise<unknown>;
};

export const FreelancerInvoicesListingTable = ({
  filters,
  invoiceList,
  onlyMobileMenu = false,
  prevPath,
  onSubmit,
  onInvoicesPaid,
  cancelFreelancerInvoice,
}: FreelancerInvoicesListingTableNewProps) => {
  const { pathname } = useLocation();

  return (
    <>
      <div className="invoices-listing-toolbar">
        <InvoiceListingFilters
          filters={filters}
          pathname={pathname || "/talent/invoices/"}
        />
        <div className="flex">
          <InvoiceSearchFilter />
          <FreelancerInvoiceJobFilterForm />
          <FreelancerInvoicesActionsMenu filters={filters} />
        </div>
      </div>
      {invoiceList.length ? (
        <div className="table-wrapper">
          <Table className="invoices-listing mw-table-call">
            <TableHead>
              <FreelancerInvoiceListingTableHead
                filters={filters}
                onlyMobileMenu={onlyMobileMenu}
                onSubmit={onSubmit}
              />
            </TableHead>
            <TableBody>
              {invoiceList.map((invoice) => (
                <FreelancerSingleInvoiceRow
                  key={invoice.id}
                  invoice={invoice}
                  onlyMobileMenu={onlyMobileMenu}
                  prevPath={prevPath}
                  onInvoicesPaid={onInvoicesPaid}
                  cancelFreelancerInvoice={cancelFreelancerInvoice}
                />
              ))}
            </TableBody>
          </Table>
        </div>
      ) : (
        <div>Sorry - no invoices found. Please try changing the filters.</div>
      )}
    </>
  );
};
