import type { ReactNode } from "react";

import { Box } from "@hexocean/braintrust-ui-components";

type CommentContentWrapperProps = {
  children: ReactNode;
  onHideReplies: () => void;
  isShowingReplies: boolean;
};

export const CommentContentWrapper = ({
  children,
  onHideReplies,
  isShowingReplies,
}: CommentContentWrapperProps) => {
  const isClickEnabled = isShowingReplies;
  const handleContentClick = () => {
    if (!isClickEnabled) {
      return;
    }
    onHideReplies();
  };

  return (
    <Box
      onClick={handleContentClick}
      sx={{ cursor: isClickEnabled ? "default" : undefined }}
      role={isClickEnabled ? "button" : undefined}
      aria-label={isClickEnabled ? "Hide comment replies" : undefined}
    >
      {children}
    </Box>
  );
};
