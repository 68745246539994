import { Box, Button, Typography } from "@hexocean/braintrust-ui-components";
import { useMediaQuery } from "@hexocean/braintrust-ui-components/hooks";
import { CopyIcon } from "@hexocean/braintrust-ui-components/Icons";
import { shortenAddress } from "@js/apps/web3/utils";
import { RouterLink } from "@js/components/link";
import { ModalInstance } from "@js/components/modal";
import { Snackbar } from "@js/components/snackbar";
import { useCopyToClipboard } from "@js/hooks";

export const WalletAddress = ({ address }) => {
  const { handleCopy } = useCopyToClipboard(() => {
    Snackbar.success("Wallet address copied!");
  });
  const isMobile = useMediaQuery("sm");
  const isBetweenMobileAndMedium = useMediaQuery(750);

  const shortAddressCharsPerBreakpoint = isMobile ? 2 : 8;
  const shortAddress = shortenAddress(address, shortAddressCharsPerBreakpoint);

  return (
    <Button
      onClick={() => openWalletImportantNoticeModal(address, handleCopy)}
      shape="squared"
      size="x-small"
      variant="secondary-thin"
      endIcon={<CopyIcon />}
    >
      <span>
        {isMobile || isBetweenMobileAndMedium ? shortAddress : address}
      </span>
    </Button>
  );
};

const openWalletImportantNoticeModal = (
  address,
  onCopyAddress: ReturnType<typeof useCopyToClipboard>["handleCopy"],
) => {
  ModalInstance.open({
    closeButton: true,
    closeButtonProps: {
      variant: "tertiary",
      size: "x-small",
    },
    children: (
      <Box maxWidth={675}>
        <Typography component="h2" variant="label" size="large" mb={4}>
          Important notice
        </Typography>

        <Typography
          component="p"
          variant="title"
          size="medium"
          fontWeight={400}
          mb={1}
        >
          Be careful to only deposit BTRST tokens on Base network to your wallet
        </Typography>

        <Typography component="p" variant="paragraph" size="medium" mb={1}>
          In order to work with the Braintrust network, your wallet is only able
          to manage BTRST tokens on the Base network.
        </Typography>

        <Typography component="p" variant="paragraph" size="medium" mb={4}>
          If you transfer any other token type into your Braintrust wallet, or
          to any other network, they will not be visible in the Braintrust
          platform and you will not be able to transfer them to another wallet.
        </Typography>

        <Box display="flex" gap={1} justifyContent="end">
          <Button
            shape="squared"
            variant="secondary"
            href={SETTINGS.SUPPORT_URLS.BRAINTRUST_PLATFORM_WALLET_ARTICLE_URL}
            RouterLink={RouterLink}
            target="_blank"
          >
            Learn more
          </Button>
          <Button
            shape="squared"
            variant="primary"
            onClick={() => onCopyAddress(address)}
          >
            Copy address
          </Button>
        </Box>
      </Box>
    ),
  });
};
