import { useState } from "react";
import { SubmissionError } from "redux-form";

import { Snackbar } from "@js/components/snackbar";
import type { HelpOffer } from "@js/types/give-and-get-help";

import { useUpdateHelpOfferMutation } from "../../api";
import { getInitialBudget } from "../../utils";

type OfferInitialValues = {
  budget?: string;
};

export const useEditOfferCard = (offer: HelpOffer) => {
  const [updateHelpOffer] = useUpdateHelpOfferMutation();
  const [editMode, setEditMode] = useState(false);
  const [initialValues] = useState<OfferInitialValues>({
    budget: getInitialBudget(offer.budget),
  });

  const turnEditModeOn = () => {
    setEditMode(true);
  };

  const turnEditModeOff = () => {
    setEditMode(false);
  };

  const onSubmit = async (data) => {
    const newOffer = { ...offer };
    newOffer.budget = data.budget;
    await updateHelpOffer({ id: newOffer.id, data: newOffer })
      .unwrap()
      .catch((error) => {
        throw new SubmissionError(error);
      });
  };

  const onSubmitSuccess = () => {
    Snackbar.success("Offer has been updated.");
    turnEditModeOff();
  };

  const onSubmitFail = () => {
    Snackbar.error("Offer has not been updated.");
  };

  return {
    initialValues,
    editMode,
    onSubmit,
    onSubmitSuccess,
    onSubmitFail,
    turnEditModeOn,
    turnEditModeOff,
  };
};
