import { SubmissionError } from "redux-form";

import { fetchFreelancerPublicProfile } from "@js/apps/freelancer/actions";
import { useGetHelpOffersQuery } from "@js/apps/give-and-get-help/api";
import {
  useCreateTalentHelpReviewMutation,
  useGetOpenReviewProcessesQuery,
} from "@js/apps/reviews/api";
import type { HelpReviewFormData } from "@js/apps/reviews/forms";
import { Snackbar } from "@js/components/snackbar";
import { useAppDispatch, useQueryParams } from "@js/hooks";
import type { FreelancerPublic } from "@js/types/freelancer";
import { deepClone, typeGuard } from "@js/utils";

export const useWriteHelpOfferReviewProps = ({
  profile,
}: {
  profile: FreelancerPublic;
}) => {
  const dispatch = useAppDispatch();
  const query = useQueryParams();
  const [createTalentHelpReview] = useCreateTalentHelpReviewMutation();

  const reviewSubjectId = profile.id;
  const reviewSubjectName = profile.user.first_name;

  const { isFetching, currentData: offers } = useGetHelpOffersQuery({
    recipient: reviewSubjectId,
  });

  const offerId = query.offer;

  const {
    data: openReviewProcessesList,
    isLoading: isLoadingOpenReviewProcessesList,
    refetch: refetchOpenReviewProcesses,
  } = useGetOpenReviewProcessesQuery({
    content_type: ENUMS.ReviewType.HELP_OFFER_REVIEW,
    subject: reviewSubjectId,
  });

  const reviewProcessItem = offerId
    ? openReviewProcessesList?.find(
        (reviewProcess) => reviewProcess.help_offer?.id === Number(offerId),
      )
    : openReviewProcessesList?.[0];

  const offerForReview =
    reviewProcessItem &&
    offers?.find((offer) => offer.id === reviewProcessItem.help_offer?.id);

  const onSubmit = async (data: HelpReviewFormData) => {
    const newValues = deepClone<any>(data);
    newValues.score = Number(data.score);

    const response = await createTalentHelpReview({
      subject: reviewSubjectId,
      review_process: reviewProcessItem?.id,
      ...newValues,
    });

    if (typeGuard<typeof response, { error: unknown }>(response, "error")) {
      throw new SubmissionError((response.error as any).data);
    }
  };

  const onSubmitSuccess = () => {
    Snackbar.toast({
      header: "Your review has been submitted ❤️",
      buttonText: "Got it",
      content: `Thank you for giving review to ${reviewSubjectName}. They’ll find it useful to improve their offers.`,
      bgcolor: "var(--medium-green)",
    });

    refetchOpenReviewProcesses();
    return dispatch(fetchFreelancerPublicProfile(reviewSubjectId));
  };

  return {
    offerForReview,
    onSubmit,
    onSubmitSuccess,
    isLoadingOpenReviewProcessesList,
    isFetching,
    openReviewProcessesList,
  };
};
