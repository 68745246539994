import React from "react";

import {
  Box,
  Button,
  Stack,
  Typography,
} from "@hexocean/braintrust-ui-components";
import { LinkIcon } from "@hexocean/braintrust-ui-components/Icons";
import { clickSyncJobsATSModal } from "@js/apps/employer/actions";
import { RouterLink } from "@js/components/link";
import { ModalInstance } from "@js/components/modal";
import { useAppDispatch } from "@js/hooks";

import styles from "./styles.module.scss";

export const openATSIntegrationPopupModal = ({
  onClose,
}: {
  onClose: () => void;
}) => {
  const handleOnClose = () => {
    onClose?.();
    ModalInstance.close();
  };

  ModalInstance.open({
    className: styles.atsIntegrationsPopup,
    padding: false,
    keepOnBackdropClick: true,
    children: <ATSIntegrationPopupContent onCancel={handleOnClose} />,
  });
};

export const ATSIntegrationPopupContent = ({ onCancel }) => {
  const dispatch = useAppDispatch();
  return (
    <Stack direction="column">
      <Box
        bgcolor="var(--soft-violet)"
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="180px"
      >
        <img
          src={`${SETTINGS.STATIC_URL}employer/ats-integration.svg`}
          alt="ats-integration"
          width={317}
          height={96}
        />
      </Box>
      <Stack direction="column" useFlexGap spacing={1} p={3}>
        <Box
          px={1}
          bgcolor="var(--medium-violet)"
          width="fit-content"
          borderRadius="4px"
          color="var(--dark-violet)"
          fontWeight={500}
        >
          🌟 New feature
        </Box>
        <Typography component="h1" variant="label" size="large">
          ATS Integration is now available!
        </Typography>
        <Typography component="p" variant="paragraph" size="medium">
          Sync jobs from your ATS platform. Connect with your ATS platform to
          maximize your time and streamline your workflow.
        </Typography>
        <Stack direction="row" mt={1}>
          <Button
            variant="primary"
            size="small"
            shape="squared"
            onClick={() => {
              onCancel();
              dispatch(clickSyncJobsATSModal());
            }}
            to="/employer/edit/ats_integration"
            RouterLink={RouterLink}
          >
            <LinkIcon style={{ paddingRight: "8px" }} /> Sync jobs
          </Button>
          <Button
            variant="transparent"
            fontWeight={400}
            sx={{
              textDecoration: "underline !important",
            }}
            onClick={onCancel}
          >
            Dismiss
          </Button>
        </Stack>
      </Stack>
    </Stack>
  );
};
