import { Box, Typography } from "@hexocean/braintrust-ui-components";
import { useMediaQuery } from "@hexocean/braintrust-ui-components/hooks";
import { RouterLink } from "@js/components/link";

import { usePostContext } from "../../context";

type PostTitleProps = {
  isSinglePostView?: boolean;
  title: string;
  postUrl: string;
};

export const PostTitle = ({
  isSinglePostView,
  title,
  postUrl,
}: PostTitleProps) => {
  const isMobile = useMediaQuery("sm");
  const { isPublic } = usePostContext();

  const postTitleVariant = isMobile ? "paragraph" : "title";
  const postTitleSize = isMobile ? "large" : "small";
  const shouldTitleBeLink = isSinglePostView || isPublic;

  return shouldTitleBeLink ? (
    <Typography
      className="post-wrapper__post-title"
      component="div"
      fontWeight={400}
      variant={postTitleVariant}
      size={postTitleSize}
    >
      <Box className="capitalize-first-letter" display="inline">
        {title}
      </Box>
    </Typography>
  ) : (
    <Typography
      className="post-wrapper__post-title"
      component={"link"}
      target="_blank"
      href={postUrl}
      rel="noopener"
      fontWeight={400}
      variant={postTitleVariant}
      size={postTitleSize}
      RouterLink={RouterLink}
    >
      <Box className="capitalize-first-letter" display="inline">
        {title}
      </Box>
    </Typography>
  );
};
