import { type FC, useMemo } from "react";

import { Box, Typography } from "@hexocean/braintrust-ui-components";
import { useMediaQuery } from "@hexocean/braintrust-ui-components/hooks";
import { BasicAccordion } from "@js/apps/on-ramp/components/onramp-modal/accordion";

import { INSIGHT_BTRST_COST } from "../../constants";

type DescriptionProps = {
  isSufficientBtrst: boolean;
  requiredTotalBtrst: number;
};

const Description: FC<DescriptionProps> = ({
  isSufficientBtrst,
  requiredTotalBtrst,
}) => {
  const isTablet = useMediaQuery("lg");

  const accordionContent = useMemo(
    () => [
      {
        title: "How does it work?",
        body: (
          <>
            <Typography component="p" size="small">
              Application Insights provides personalized feedback for each job,
              using Braintrust AI.
            </Typography>
            <ul>
              <li>
                <Typography
                  component="p"
                  variant="paragraph"
                  size="small"
                  color="grey-1"
                >
                  Identify jobs where you are a great match{" "}
                </Typography>
              </li>
              <li>
                <Typography
                  component="p"
                  variant="paragraph"
                  size="small"
                  color="grey-1"
                >
                  Compare your standing among others{" "}
                </Typography>
              </li>
              <li>
                <Typography
                  component="p"
                  variant="paragraph"
                  size="small"
                  color="grey-1"
                >
                  Discover strengths and areas to improve
                </Typography>
              </li>
            </ul>
          </>
        ),
      },
      {
        title: "How much is it?",
        body: `For ${INSIGHT_BTRST_COST} BTRST tokens, unlock 30 days of Application Insights. No recurring charges. Renew at your convenience.`,
      },
      ...(isSufficientBtrst
        ? []
        : [
            {
              title: "Why do we need to use Coinbase?",
              body: "Coinbase is the largest online exchange that allows people to buy, sell, and trade cryptocurrencies, including BTRST, on the Blockchain. We chose Coinbase for it’s trusted reputation, secure platform, and easy-to-use interface that makes it ideal for beginner to advanced cryptocurrency traders.",
            },
            {
              title: "Is it safe to use Coinbase?",
              body: (
                <>
                  <Typography component="p" size="small">
                    Coinbase is the most trusted crypto exchange:
                  </Typography>
                  <Typography component="a" size="small">
                    <a
                      href="https://www.coinbase.com/security"
                      target="_blank"
                      rel="noreferrer"
                    >
                      https://www.coinbase.com/security
                    </a>
                  </Typography>
                </>
              ),
            },
            {
              title: "How will I know when my BTRST is available?",
              body: "We'll notify you when your BTRST is available. This could take up to 3 hours. You can view your token balance anytime in your Braintrust Wallet.",
            },
          ]),
    ],
    [isSufficientBtrst],
  );

  return (
    <Box
      position="relative"
      display="flex"
      justifyContent="center"
      flexDirection="column"
      gap={3}
      boxSizing="border-box"
      maxWidth={isTablet ? "100%" : "560px"}
      padding={isTablet ? "20px" : "0 80px"}
      sx={{ backgroundColor: "var(--soft-green)" }}
    >
      <Box position="absolute" top={isTablet ? 0 : -32} right={0}>
        <img
          src={`${SETTINGS.STATIC_URL}jobs/job-insight-bg.svg`}
          alt="job-insight-bg-image"
        />
      </Box>
      <Box zIndex={1}>
        <Typography
          component="h3"
          variant="title"
          size="medium"
          fontWeight={400}
          zIndex={1}
        >
          {isSufficientBtrst ? (
            <>
              Apply smart with <br />
              Application Insights.
            </>
          ) : (
            <>
              Add{" "}
              {requiredTotalBtrst < 0
                ? requiredTotalBtrst * -1
                : requiredTotalBtrst}{" "}
              BTRST to your wallet to purchase Application Insights
            </>
          )}
        </Typography>
        <Typography
          component="p"
          variant="paragraph"
          size="medium"
          fontWeight={isSufficientBtrst ? 400 : 500}
        >
          Identify promising opportunities and refine your job search with
          personalized feedback.
        </Typography>
        {isSufficientBtrst ? null : (
          <Typography component="p" variant="paragraph" size="small">
            Looks like you don't have enough BTRST for Application Insights. No
            worries. You can securely buy BTRST through Coinbase using USD or
            the fiat of your choice. Once the tokens are in your wallet, we'll
            help you get Application Insights.
          </Typography>
        )}
        <Box
          sx={{
            "& .MuiAccordionSummary-content": {
              margin: 0,
            },
            "& .MuiAccordionSummary-root": {
              minHeight: 0,
              marginBottom: 2,
            },
          }}
        >
          <BasicAccordion options={accordionContent} />
        </Box>
      </Box>
    </Box>
  );
};

export default Description;
