import React from "react";
import cs from "classnames";

import { useGetCompletionAreaToEditMap } from "@js/apps/freelancer/hooks";
import type { ProfileCompletion } from "@js/types/freelancer";

import { ProfileCompletionFeedbackLabels } from "./labels";

import styles from "./style.module.scss";

export type ProfileCompletionFeedbackProps = {
  missingAreas: ProfileCompletion["missing_areas"];
  className?: string;
};

export const ProfileCompletionFeedback = ({
  missingAreas = [],
}: ProfileCompletionFeedbackProps): JSX.Element | null => {
  const completionAreaToEditMap = useGetCompletionAreaToEditMap();

  if (missingAreas.length === 0) {
    return null;
  }

  return (
    <ul className={cs("list-reset", styles.profileCompletionFeedback)}>
      {missingAreas.map((item) => {
        const Label = ProfileCompletionFeedbackLabels[item.keywords];
        const link = completionAreaToEditMap[item.keywords].link;

        return (
          <li
            key={item.keywords}
            className={styles.profileCompletionFeedbackItem}
          >
            <Label
              to={link}
              number={item.items_count}
              progress={item.progress}
            />
          </li>
        );
      })}
    </ul>
  );
};
