import { AddRecipientsModal } from "@js/apps/withdrawal/components/add-recipients/recipients-listing/recipients-modal";

import { AddRecipientsModalContent } from "./add-recipients-modal-content";

export const AddRecipients = (props) => {
  return (
    <AddRecipientsModal>
      <AddRecipientsModalContent {...props} />
    </AddRecipientsModal>
  );
};
