import type { MouseEvent } from "react";
import { useRef } from "react";
import cs from "classnames";
import Linkify from "linkify-react";
import { z } from "zod";

import { Button } from "@hexocean/braintrust-ui-components";
import { parseTextWithHashtags } from "@js/components/rich-text-editor/custom-plugins/hashtag-plugin/utils/parse-text-with-hashtags";
import { Sanitize } from "@js/components/sanitize";
import { useAppDispatch, useNavigate } from "@js/hooks";
import type { IPost } from "@js/types/give-and-get-help";

import { clickPostHashtag } from "../../actions";
import { HASHTAG_QUERY_PARAM, POST_HASHTAG_QUERY_PARAM } from "../../constants";
import { usePostLocationContext } from "../../context/post-location";
import { useHandleReadMore } from "../../hooks/use-handle-read-more";
import type { PostHashtag } from "../../types";

export type ReadMoreProps = {
  id: number;
  description: string;
  onExpand?: () => void;
  onCollapse?: () => void;
  postHashtags?: PostHashtag[];
  postData?: IPost;
};

export const ReadMore = ({
  id,
  description,
  onExpand,
  onCollapse,
  postHashtags,
  postData,
}: ReadMoreProps): JSX.Element => {
  const contentRef = useRef<HTMLDivElement | null>(null);
  const { fullContent, showButton, showFullText, showMore } = useHandleReadMore(
    {
      id,
      content: description,
      parentRef: contentRef,
      onExpand,
      onCollapse,
    },
  );

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const postLocation = usePostLocationContext();

  const handlePostHashtagClick = (hashtagElementDataset: unknown) => {
    if (!postData) {
      return;
    }

    const parseResult = postHashtagDatasetSchema.safeParse(
      hashtagElementDataset,
    );

    if (!parseResult.success) {
      return;
    }
    const { id: postHashtagId, hashtag_id, hashtag_name } = parseResult.data;
    const postHashtag = {
      id: postHashtagId,
      hashtag_id,
      hashtag_name,
    };

    const spaceId = postData.space;

    dispatch(
      clickPostHashtag({
        hashtag_id: postHashtag.hashtag_id,
        post_id: postData.id,
        post_level: postData.level,
        slot_position: postData.slot_position,
        post_location: postLocation,
      }),
    );
    const redirectLink = spaceId
      ? `/spaces/${spaceId}/discussion?${HASHTAG_QUERY_PARAM}=${postHashtag.hashtag_id}`
      : `/feed/?${HASHTAG_QUERY_PARAM}=${postHashtag.hashtag_id}&${POST_HASHTAG_QUERY_PARAM}=${postHashtag.id}`;

    navigate(redirectLink);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handleContentClick = (ev: MouseEvent<HTMLElement>) => {
    const target = ev.target as HTMLElement;

    if (target.classList.contains("post-hashtag-link")) {
      handlePostHashtagClick(target.dataset);
    }
  };

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
    <div ref={contentRef} onClick={handleContentClick}>
      <Linkify>
        <Sanitize
          input={fullContent}
          postSanitizeContent={(sanitizedText) =>
            parseTextWithHashtags({
              text: sanitizedText,
              hashtags: postHashtags,
            })
          }
          whiteSpacePreLine
          markdown={false}
          className="read-more-button__sanitized"
        />
      </Linkify>

      {!showFullText && showButton && (
        <span className="read-more-button__separator">·</span>
      )}

      {showButton && (
        <Button
          fontWeight={400}
          className={cs("read-more-button", {
            "read-more-button--more": !showFullText,
            "read-more-button--less": showFullText,
          })}
          variant="no-padding"
          onClick={showMore}
        >
          Read {showFullText ? "less" : "more"}
        </Button>
      )}
    </div>
  );
};

const postHashtagDatasetSchema = z.object({
  id: z.coerce.number().int(),
  hashtag_id: z.coerce.number().int(),
  hashtag_name: z.string(),
});
