import { Box, Typography } from "@hexocean/braintrust-ui-components";
import { useAppSelector } from "@js/hooks";
import type { ProfileCompletionAreasMap } from "@js/types/freelancer";

import { OpenModalButton } from "./open-modal-button";

type FeedbackLabelProps = {
  number: number;
  name: string;
  customLinkName: string | null;
  customNamePrefix: string | null;
  pluralize?: boolean;
  progress: number;
};

const FeedbackLabel = ({
  number,
  name,
  customLinkName = null,
  customNamePrefix = null,
  pluralize,
  progress,
}: FeedbackLabelProps) => {
  const profile = useAppSelector((state) => state.freelancer.freelancerProfile);

  if (!profile) {
    return null;
  }

  return (
    <Box display="flex" width={1}>
      <Typography
        component="p"
        variant="paragraph"
        color="inherit"
        flexGrow={1}
        pr={1}
      >
        {customNamePrefix ?? "Add"} {number ? `${number}` : ""}{" "}
        <OpenModalButton
          customLinkName={customLinkName}
          name={name}
          pluralize={pluralize}
          number={number}
          profile={profile}
        />
      </Typography>

      <Typography component="p">{progress}%</Typography>
    </Box>
  );
};

export const ProfileCompletionFeedbackLabels: ProfileCompletionAreasMap<
  typeof FeedbackLabel
> = {
  [ENUMS.ProfileCompletionArea.FreelancerFullNameCompletionDetail]: (props) => {
    return (
      <FeedbackLabel
        {...props}
        customNamePrefix="Add a"
        customLinkName="Full Name"
      />
    );
  },
  [ENUMS.ProfileCompletionArea.FreelancerLocationCompletionDetail]: (props) => {
    return (
      <FeedbackLabel
        {...props}
        customNamePrefix="Add a"
        customLinkName="Location"
      />
    );
  },
  [ENUMS.ProfileCompletionArea.FreelancerRoleCompletionDetail]: (props) => {
    return <FeedbackLabel {...props} name="Role" customNamePrefix="Add your" />;
  },
  [ENUMS.ProfileCompletionArea.FreelancerSkillCompletionDetail]: (props) => {
    return (
      <FeedbackLabel
        {...props}
        name="Skill"
        customNamePrefix="Add your"
        pluralize
      />
    );
  },
  [ENUMS.ProfileCompletionArea.FreelancerSuperPowersCompletionDetail]: (
    props,
  ) => {
    return <FeedbackLabel {...props} name="Top skill" pluralize />;
  },
  [ENUMS.ProfileCompletionArea.FreelancerWorkExperienceCompletionDetail]: (
    props,
  ) => {
    return <FeedbackLabel {...props} name="Work Experience" />;
  },
};
