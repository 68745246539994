import { useEffect, useMemo, useState } from "react";
import uuid4 from "uuid4/browser";

import type {
  AutocompleteChangeReason,
  ComboBoxProps,
} from "@hexocean/braintrust-ui-components";
import { ComboBox } from "@hexocean/braintrust-ui-components";
import {
  KeyboardArrowDownIcon,
  LocationPinSmallIcon,
} from "@hexocean/braintrust-ui-components/Icons";
import { CUSTOM_JOB_LOCATIONS } from "@js/apps/common/constants";
import type { PlacesServicesTypes } from "@js/hooks/google-maps";
import { useGoogleMaps } from "@js/hooks/google-maps";

import type { BackendValue, ComboBoxValue } from "./types";
import { ValueType } from "./types";

import styles from "./styles.module.scss";

export type GoogleComboboxProps<DisableClearable extends boolean> = Omit<
  ComboBoxProps<ComboBoxValue, DisableClearable>,
  "onChange" | "options" | "initialTaxonomiesLoading" | "value"
> & {
  value?: BackendValue;
  onChange: (value: ComboBoxValue, reason: AutocompleteChangeReason) => void;
  placesServiceTypes?: PlacesServicesTypes;
  useCustomLocations?: boolean;
  locationIcon?: boolean;
  onGetLocationStart?: () => void;
  onGetLocationEnd?: () => void;
};

export const GoogleCombobox = <DisableClearable extends boolean>({
  value: valueToMap,
  onChange,
  placesServiceTypes = "cities",
  useCustomLocations = false,
  locationIcon = true,
  onGetLocationStart,
  onGetLocationEnd,
  ...props
}: GoogleComboboxProps<DisableClearable>) => {
  const {
    placePredictions,
    getPlacePredictions,
    isPlacePredictionsLoading,
    getPlaceDetails,
    getPlaceDetailsAbortController,
  } = useGoogleMaps();
  /** https://developers.google.com/maps/documentation/places/web-service/session-tokens */
  const [sessionToken, setSessionToken] = useState(uuid4());

  const value = useMemo((): ComboBoxValue => {
    if (valueToMap !== "" && typeof valueToMap === "string") {
      return {
        id: valueToMap,
        name: valueToMap,
        type: ValueType.initial_mapped_from_string,
        selected: true,
      };
    }

    if (valueToMap) {
      return {
        ...valueToMap,
        selected: true,
      };
    }

    return null;
  }, [valueToMap]);

  const options = useMemo((): ComboBoxValue[] => {
    const mappedPredictions = placePredictions.map((place) => ({
      type: ValueType.prediction,
      id: place.place_id,
      name: place.description,
    }));

    const initial = !!value ? [value] : [];

    if (useCustomLocations) {
      return [...initial, ...CUSTOM_JOB_LOCATIONS, ...mappedPredictions];
    } else {
      return [...initial, ...mappedPredictions];
    }
  }, [value, useCustomLocations, placePredictions]);

  useEffect(() => {
    if (
      value === null ||
      (typeof value !== "string" && value?.type === ValueType.custom)
    ) {
      getPlaceDetailsAbortController.abort();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value?.type]);

  return (
    <ComboBox<ComboBoxValue, DisableClearable>
      // @ts-expect-error `null` fallback is needed, but it is not accepted due to conditional `DisableClearable` type.
      value={value || null}
      onInputChange={(_event, input, reason) => {
        if (reason === "reset") {
          return;
        }

        getPlacePredictions({
          input,
          types: placesServiceTypes,
          sessionToken,
        });
      }}
      onChange={(_event, newValue, reason) => {
        if (newValue?.type === ValueType.custom || !newValue) {
          onChange?.(newValue, reason);
        }

        if (newValue?.type === ValueType.prediction && newValue.id) {
          onGetLocationStart?.();
          getPlaceDetails(
            {
              sessionToken,
              placeId: newValue.id,
            },
            (placeDetails) => {
              setSessionToken(uuid4());
              if (placeDetails) {
                onChange?.(
                  {
                    type: ValueType.detail,
                    id: placeDetails.place_id,
                    ...placeDetails,
                  },
                  reason,
                );
              } else {
                onChange?.(null, reason);
              }

              onGetLocationEnd?.();
            },
          );
        }
      }}
      getOptionLabel={(option) => {
        if (!option) {
          return "";
        }

        const optionType = option.type;
        switch (optionType) {
          case ValueType.initial_mapped_from_string:
          case ValueType.custom:
          case ValueType.prediction: {
            return option.name;
          }
          case ValueType.detail: {
            return option.formatted_address || option.name || "";
          }
          default: {
            optionType satisfies never;
            // @ts-expect-error to keep support for the the old data which doesn't contain `type`.
            return option.formatted_address || option.name || "";
          }
        }
      }}
      loading={isPlacePredictionsLoading}
      filterOptions={(opts, { inputValue }) => {
        const filteredOptions = opts.filter((option) => {
          const isCustom = option?.type === ValueType.custom;
          /**
           * Initial value needs to be in options list to fix MUI warning,
           * but we don't want to display it in options list as it is faked object (not compatible with the backend).
           */
          const isInitial =
            option?.type === ValueType.initial_mapped_from_string ||
            !!option?.selected;

          if (inputValue) {
            return !isCustom && !isInitial;
          }

          return isCustom;
        });

        return filteredOptions;
      }}
      isOptionEqualToValue={(option, val) => {
        return option?.id === val?.id;
      }}
      classes={
        locationIcon
          ? { popupIndicatorOpen: styles.popupIndicatorOpen }
          : undefined
      } // Prevent location icon from rotating on open
      popupIcon={
        locationIcon ? <LocationPinSmallIcon /> : <KeyboardArrowDownIcon />
      }
      {...props}
      options={options}
      initialTaxonomiesLoading={false}
    />
  );
};
