import { useCallback, useEffect, useMemo, useState } from "react";

import { useEffectRef } from "@js/hooks/use-effect-ref";

import { FILE_UPLOAD_STATE_STATUS } from "../../constants";
import { validateFilesSize } from "../../helpers/file-upload";
import type { FileId, UploadedFile, UploadFileArg } from "../../types";
import type { UseUploadFilesArg } from "../use-upload-files";
import { useUploadFiles } from "../use-upload-files";

export type UseUploadSingleFileArg = Omit<UseUploadFilesArg, "onUpload"> & {
  onUpload?: (arg: UploadedFile) => void;
  onUploadError?: (arg: string) => void;
};

export const useUploadSingleFile = ({
  onBeforeUpload,
  onAfterUpload,
  onUpload,
  onUploadError,
}: UseUploadSingleFileArg = {}) => {
  const [fileValidationError, setFileValidationError] = useState<string | null>(
    null,
  );
  const onUploadRef = useEffectRef(onUpload);
  const onUploadErrorRef = useEffectRef(onUploadError);
  const {
    uploadFiles,
    deleteAllFiles,
    cancelAllFilesUpload,
    fileUploadsState,
  } = useUploadFiles({ onBeforeUpload, onAfterUpload });

  const [fileId, setFileId] = useState<FileId | undefined>();
  const [lastUploadedFile, setLastUploadedFile] = useState<
    UploadedFile | undefined
  >();

  const fileState = useMemo(
    () =>
      fileUploadsState.find(
        (fileUploadState) => fileUploadState.fileId === fileId,
      ),
    [fileId, fileUploadsState],
  );

  // read error and uploading status only for the currently tracked file
  const error = fileValidationError ?? fileState?.errorMessage;
  const isUploading = fileState?.status === FILE_UPLOAD_STATE_STATUS.UPLOADING;
  const uploadedFile = fileState?.file ?? lastUploadedFile;

  const resetFileState = useCallback((resetUploadedFile = false) => {
    setFileId(undefined);
    setFileValidationError(null);

    if (resetUploadedFile) {
      setLastUploadedFile(undefined);
    }
  }, []);

  const uploadSingleFile = useCallback(
    async ({
      file,
      uploadType,
      maxSize,
      timeoutMs,
    }: Pick<UploadFileArg, "file" | "uploadType"> & {
      maxSize?: number;
      timeoutMs?: number;
    }) => {
      setFileId(file.fileId);
      deleteAllFiles();
      const validationError = validateFilesSize([file], maxSize);
      if (validationError) {
        setFileValidationError(validationError);

        return;
      }

      setFileValidationError(null);

      await uploadFiles({ files: [file], uploadType, timeoutMs });
    },
    [uploadFiles, deleteAllFiles],
  );

  const cancelFileUpload = useCallback(
    () => cancelAllFilesUpload(),
    [cancelAllFilesUpload],
  );

  useEffect(() => {
    if (fileState?.status === "error") {
      return onUploadErrorRef?.current?.(fileState.errorMessage ?? "");
    }
  }, [fileState?.errorMessage, fileState?.status, onUploadErrorRef]);

  useEffect(() => {
    if (!fileState?.file) {
      return;
    }

    // memoize uploaded file, so we have access to it ...
    // ... even when a new file is being uploaded
    setLastUploadedFile(fileState?.file);

    onUploadRef?.current?.(fileState.file);
  }, [fileState, onUploadRef]);

  return {
    uploadSingleFile,
    cancelFileUpload,
    resetFileState,
    uploadedFile,
    isUploading,
    error,
    uploadingFileName: fileState?.name,
  };
};
