import React from "react";

import { Typography } from "@hexocean/braintrust-ui-components";
import { FieldSectionPanelWithImportance } from "@js/apps/jobs/forms/fields/field-section-panel";

export const RateFieldPanel = ({
  title,
  description,
  displayError,
  error,
  children,
}) => {
  return (
    <FieldSectionPanelWithImportance
      title={title}
      description={description}
      fieldImportance="required"
      isError={displayError}
    >
      {children}
      {displayError && (
        <Typography mt={2} error component="p" variant="paragraph">
          {error}
        </Typography>
      )}
    </FieldSectionPanelWithImportance>
  );
};
