import React from "react";

import { Typography } from "@hexocean/braintrust-ui-components";
import {
  LocationPinSmallIcon,
  VerifiedIcon,
} from "@hexocean/braintrust-ui-components/Icons";
import type { ProfilePersonalDataProps } from "@js/apps/give-and-get-help/components/profile-card/profile-card.types";

export const ProfilePersonalData = ({
  name,
  isApproved,
  headline,
  location,
}: ProfilePersonalDataProps) => (
  <>
    <div className="profile-card__name">
      <Typography component="p" size="large" variant="label" ellipsis>
        {name}
      </Typography>
      {isApproved && <VerifiedIcon className="profile-card__name-verified" />}
    </div>
    <div className="profile-card__details">
      {headline && (
        <Typography variant="paragraph" component="p" size="small">
          {headline}
        </Typography>
      )}
      <ProfileCardLocation location={location} />
    </div>
  </>
);

const ProfileCardLocation = ({ location }: { location: string | null }) => {
  if (!location) {
    return null;
  }

  return (
    <div className="profile-card__location">
      <LocationPinSmallIcon />
      <Typography variant="paragraph" component="p" size="small">
        {location}
      </Typography>
    </div>
  );
};
