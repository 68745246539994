import React from "react";

import {
  Box,
  RegularTab,
  RegularTabs,
  Typography,
} from "@hexocean/braintrust-ui-components";
import type { Reaction, StickerValue } from "@js/types/give-and-get-help";

import { StickerIcon } from "../sticker-icon";

type ReactionsDrawerTabsProps = {
  entityReactions: Reaction[];
  selectedReactionValue: string;
  onReactionChange: (reactionValue: StickerValue) => void;
};

export const ReactionsDrawerTabs = ({
  entityReactions,
  selectedReactionValue,
  onReactionChange,
}: ReactionsDrawerTabsProps) => {
  return (
    <RegularTabs
      aria-label="reactions tabs"
      value={selectedReactionValue}
      sx={{ ".MuiTabs-flexContainer": { gap: 1.5 }, px: 2.5 }}
      data-testid="reactions-drawer-tabs"
    >
      {entityReactions.map((reaction) => {
        const { sticker } = reaction;
        return (
          <RegularTab
            key={sticker}
            value={sticker}
            onClick={() => onReactionChange(sticker)}
            label={<ReactionTabLabel reaction={reaction} />}
            sx={{ minWidth: "55px", p: 0 }}
            data-reaction={sticker}
            data-testid={`reaction-tab-${sticker}`}
          />
        );
      })}
    </RegularTabs>
  );
};

type ReactionTabLabelProps = {
  reaction: Reaction;
};
const ReactionTabLabel = ({ reaction }: ReactionTabLabelProps) => {
  const { sticker: reactionValue, count } = reaction;

  return (
    <Box
      sx={{
        display: "flex",
        gap: "6px",
        alignItems: "center",
      }}
    >
      <Box
        component="div"
        sx={{
          p: "5px",
          borderRadius: "8px",
          boxShadow: "inset 0 0 0 1px var(--soft-beige)",
          display: "inline-flex",
        }}
      >
        <StickerIcon stickerValue={reactionValue} sx={{ fontSize: "1.3rem" }} />
      </Box>
      <Typography component="span" fontWeight={500} size="small">
        {count}
      </Typography>
    </Box>
  );
};
