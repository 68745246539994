import { Box, Button, Loader } from "@hexocean/braintrust-ui-components";
import { BID_TYPES } from "@js/apps/common/constants";
import { MyWorkEmptyState } from "@js/apps/freelancer/components";
import { BidListItem } from "@js/apps/freelancer/components/bid-list/bid-item";
import { useFreelancerBids } from "@js/apps/jobs/apps/bids";
import { ApplicationBoostFeatureFlagWrapper } from "@js/apps/jobs/components/application-boost-feature-flag-wrapper";
import { JobListBoostBanner } from "@js/apps/jobs/components/job-list-boost-banner";
import { RouterLink } from "@js/components/link";
import type { FreelancerBid } from "@js/types/jobs";

export const FreelancerDashboardCurrentBidsPage = () => {
  const { bids, isLoading } = useFreelancerBids(BID_TYPES.CURRENT);

  return (
    <>
      <BidList
        bids={bids}
        loading={isLoading}
        emptyStateMessage="Applications you have submitted will appear here."
      />
    </>
  );
};

export const FreelancerDashboardPastBidsPage = () => {
  const { bids, isLoading } = useFreelancerBids(BID_TYPES.HISTORICAL);

  return (
    <>
      <BidList
        bids={bids}
        loading={isLoading}
        emptyStateMessage="Closed applications will appear here."
      />
    </>
  );
};

type BidListProps = {
  bids?: FreelancerBid[];
  loading: boolean;
  emptyStateMessage: string;
};

const BidList = ({ bids, loading, emptyStateMessage }: BidListProps) => {
  if (loading) return <Loader />;

  if (!bids?.length)
    return (
      <MyWorkEmptyState
        title="No applications yet"
        description={emptyStateMessage}
        icon="✏️"
        iconBackgroundColor="var(--medium-violet)"
        cta={
          <Button
            sx={{ mt: 2 }}
            variant="white-border-beige"
            shape="squared"
            to="/jobs/"
            RouterLink={RouterLink}
          >
            Search jobs
          </Button>
        }
      />
    );

  return (
    <Box display="flex" flexDirection="column" gap={2}>
      <ApplicationBoostFeatureFlagWrapper>
        <JobListBoostBanner />
      </ApplicationBoostFeatureFlagWrapper>

      <Box>
        {bids.map((bid) => (
          <BidListItem bid={bid} key={bid.id} />
        ))}
      </Box>
    </Box>
  );
};
