import type { LinksFormData } from "@js/apps/give-and-get-help/components";
import {
  CREATE_POST_FORM_ID,
  EDIT_POST_FORM_ID,
  POST_FIELD_CATEGORY,
} from "@js/apps/give-and-get-help/form/constants";
import { createFormInstance } from "@js/forms/components";
import { maxLength } from "@js/forms/validators";
import type {
  LinkMetadataModel,
  PollOption,
} from "@js/types/give-and-get-help";
import { validateAttachments } from "@js/utils/redux-form";

import { DESCRIPTION_MAX_LENGTH } from "../constants";
import { POST_FIELD_TEXT } from "../forms/fields/post-description-field/constants";
import type { Hashtag, PostHashtag } from "../types";

export type CreateOrEditPostFormData = {
  title: string;
  text: string;
  category: number;
  budget?: string | null;
  links?: LinksFormData;
  attachments: Array<{ id?: number; isLoading?: boolean }>;
  poll_options?: PollOption[];
  poll_text?: string;
  id?: number;
  links_from_text?: string[];
  excluded_links_from_text?: string[];
  links_metadata?: LinkMetadataModel[];
  links_metadata_from_text?: LinkMetadataModel[];
  post_hashtags?: (PostHashtag | Hashtag)[];
  self_promotion_response?: number | null;
  self_promotion_response_ignored?: boolean;
};

const validate = (values) => {
  const errors = {} as { [field in string]?: string };
  const charLimit = maxLength(DESCRIPTION_MAX_LENGTH)(values[POST_FIELD_TEXT]);
  if (!values[POST_FIELD_TEXT]) {
    errors[POST_FIELD_TEXT] = "This field is required.";
  }
  if (charLimit) {
    errors[POST_FIELD_TEXT] = charLimit;
  }

  if (!values[POST_FIELD_CATEGORY]) {
    errors[POST_FIELD_CATEGORY] = "Category is required.";
  }

  const attachmentsError = validateAttachments(values);
  if (attachmentsError) {
    errors["attachments"] = attachmentsError;
  }

  return errors;
};

export const CreatePostForm = createFormInstance<
  CreateOrEditPostFormData,
  unknown
>(CREATE_POST_FORM_ID, {
  validate,
  touchOnChange: true,
  enableReinitialize: true,
});

export const EditPostForm = createFormInstance<
  CreateOrEditPostFormData,
  unknown
>(EDIT_POST_FORM_ID, {
  touchOnChange: true,
  validate,
});
