import type { FC, PropsWithChildren } from "react";
import React from "react";

import {
  Divider,
  Tooltip,
  Typography,
} from "@hexocean/braintrust-ui-components";
import { useMediaQuery } from "@hexocean/braintrust-ui-components/hooks";
import { InfoIcon } from "@hexocean/braintrust-ui-components/Icons";
import type { HelpOffer } from "@js/types/give-and-get-help";
import { formatCurrency, formatTokenAmount, round } from "@js/utils";

export const ReceiptDetails: FC<
  PropsWithChildren<{
    offer: HelpOffer;
  }>
> = ({ offer, children }) => {
  const isMobile = useMediaQuery("sm");
  const textSize = isMobile ? "small" : "medium";

  const budgetMoreThanFee = +offer.budget > +offer.fee;
  const missingTokens = offer.missing_tokens ? Number(offer.missing_tokens) : 0;
  const totalUSD = +offer.budget + +offer.fee;
  const totalDisplayed = getTotalDisplayed({ missingTokens, totalUSD });

  return (
    <div className="receipt-details">
      <Divider color="beige" />
      {budgetMoreThanFee && (
        <div className="receipt-details__row">
          <Typography component="p" variant="paragraph" size={textSize}>
            {offer.author.user.first_name_possessive} offer price
          </Typography>
          <Typography component="p" variant="paragraph" size={textSize}>
            ${offer.budget} in BTRST
          </Typography>
        </div>
      )}

      {budgetMoreThanFee && (
        <div className="receipt-details__row">
          <div style={{ display: "flex", alignItems: "center", gap: "6px" }}>
            <Typography component="p" variant="paragraph" size={textSize}>
              {SETTINGS.ONRAMP_PAYMENT_PERCENTAGE_FEE_RATE}% service fee
            </Typography>
            <Tooltip
              style={{ cursor: "pointer", verticalAlign: "middle" }}
              title="This fee is calculated from the offer price."
            >
              <InfoIcon
                style={{
                  color: "var(--dark-blue)",
                  fontSize: isMobile ? 20 : 24,
                }}
              />
            </Tooltip>
          </div>
          <Typography component="p" variant="paragraph" size={textSize}>
            ${offer.fee} in BTRST
          </Typography>
        </div>
      )}

      <div className="receipt-details__row">
        <Typography component="p" variant="label" size={textSize}>
          {!!missingTokens ? "Total you need to buy" : "Total"}
        </Typography>
        <Typography component="p" variant="label" size={textSize}>
          {totalDisplayed}
        </Typography>
      </div>
      {children}
      <Divider color="beige" />
    </div>
  );
};

const getTotalDisplayed = ({
  missingTokens,
  totalUSD,
}: {
  missingTokens: number | undefined;
  totalUSD: number;
}): string => {
  if (!missingTokens) {
    return `${formatCurrency(totalUSD)} in BTRST`;
  }

  return `${formatTokenAmount(round(missingTokens, 0.1))} BTRST`;
};
