import { useCallback } from "react";
import cs from "classnames";

import {
  Box,
  Button,
  IconButton,
  Rating,
  Typography,
} from "@hexocean/braintrust-ui-components";
import { EditPen } from "@hexocean/braintrust-ui-components/Icons";
import { useUser } from "@js/apps/common/hooks";
import { COMPLETION_NUDGE_PARAMS } from "@js/apps/freelancer/constants";
import type { FreelancerPublic } from "@js/types/freelancer";

import { useOpenModalsFromCompletionNudge } from "../../hooks";
import { openRoleModal } from "../role-module";

import { getExperienceData } from "./helpers";

type ExperienceModuleProps = {
  profile: FreelancerPublic;
};

export const ExperienceModule = ({ profile }: ExperienceModuleProps) => {
  const user = useUser();

  const { label, legend, experienceAsArrow } = getExperienceData(profile.roles);

  const isOwnProfile = user && profile.id === user.freelancer;
  const isAnyRoleDefined = profile.roles.length > 0;

  const publicEmptyState = !isOwnProfile && !isAnyRoleDefined;
  const ownEmptyState = isOwnProfile && !isAnyRoleDefined;

  const handleOpenModal = useCallback(() => {
    openRoleModal();
  }, []);

  useOpenModalsFromCompletionNudge({
    requiredCondition: isOwnProfile,
    queryParam: COMPLETION_NUDGE_PARAMS.YEARS_OF_EXPERIENCE,
    openModal: handleOpenModal,
  });

  return (
    <Box
      className={cs("experience", {
        "profile-empty-state": ownEmptyState,
      })}
    >
      {isOwnProfile && !!isAnyRoleDefined && (
        <IconButton
          variant="black-outlined"
          size="x-small"
          className="experience__edit-btn"
          aria-label="Edit experience"
          onClick={handleOpenModal}
        >
          <EditPen />
        </IconButton>
      )}
      <Typography
        component="h3"
        variant="title"
        size="small"
        fontWeight={400}
        mb={2}
      >
        {label}
      </Typography>
      {legend && (
        <Typography
          component="h4"
          variant="paragraph"
          size="medium"
          fontWeight={400}
          mb={2}
        >
          {legend}
        </Typography>
      )}
      {publicEmptyState && (
        <Typography
          component="h3"
          variant="paragraph"
          fontStyle="italic"
          mb={2}
          multipleEllipsis
        >
          {profile.user.first_name} hasn’t added years of experience, yet.
        </Typography>
      )}
      <Rating
        variant="triangles"
        size="large"
        color="violet"
        value={experienceAsArrow}
        max={4}
        readOnly
      />
      {ownEmptyState && (
        <Button
          variant="black-outlined"
          size="medium"
          shape="squared"
          onClick={handleOpenModal}
          sx={{ mt: 2 }}
        >
          Add role
        </Button>
      )}
    </Box>
  );
};
