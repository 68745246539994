import React from "react";

import { Box } from "@hexocean/braintrust-ui-components";
import { useEmployerInvoiceListingTable } from "@js/apps/invoices/hooks/employer-invoice-listing-table";
import { PaymentModalInstance } from "@js/apps/payments/components/payment-modal";
import {
  ACHPaymentSuccessModal,
  CCPaymentMethodSuccessModal,
} from "@js/apps/payments/components/payment-success-modal";

import { InvoicesTypeField } from "../../fields";
import { EmployerListingFiltersForm } from "../../forms";

import { EmployerInvoicesActions } from "./employer-invoices-actions";
import { InvoiceTable } from "./invoice-table";

export type EmployerInvoicesListingTableProps = {
  onInvoicesPaid: () => void;
  onInvoicesPaymentFailed: () => void;
};

export const EmployerInvoicesListingTable = ({
  onInvoicesPaid,
  onInvoicesPaymentFailed,
}: EmployerInvoicesListingTableProps) => {
  const {
    invoiceList,
    onCloseSuccessModal,
    selectedInvoicesIds,
    handleSingleInvoicePayment,
    startPaymentFlow,
    markInvoiceAsPaid,
    openInvoicePreview,
  } = useEmployerInvoiceListingTable({
    onInvoicesPaid,
    onInvoicesPaymentFailed,
  });

  return (
    <>
      <EmployerListingFiltersForm>
        <Box mb={4}>
          <InvoicesTypeField />
        </Box>
        <EmployerInvoicesActions
          selectedInvoicesIds={selectedInvoicesIds}
          invoiceList={invoiceList}
          markInvoiceAsPaid={markInvoiceAsPaid}
          startPaymentFlow={startPaymentFlow}
        />
        {invoiceList.length ? (
          <InvoiceTable
            openInvoicePreview={openInvoicePreview}
            startPaymentFlow={startPaymentFlow}
            handleSingleInvoicePayment={handleSingleInvoicePayment}
            markInvoiceAsPaid={markInvoiceAsPaid}
          />
        ) : (
          <div>Sorry - no invoices found. Please try changing the filters.</div>
        )}
      </EmployerListingFiltersForm>
      <PaymentModalInstance />
      <ACHPaymentSuccessModal onClose={onCloseSuccessModal} />
      <CCPaymentMethodSuccessModal onClose={onCloseSuccessModal} />
    </>
  );
};
