import React from "react";
import * as Sentry from "@sentry/react";

export const ErrorBoundary = ({ children }) => {
  return (
    <Sentry.ErrorBoundary fallback={<Fallback />}>
      {children}
    </Sentry.ErrorBoundary>
  );
};

const Fallback = () => {
  const reloadPage = () => window.location.reload();

  return (
    <div className="error-boundary">
      <a href="/">
        <img
          src={`${SETTINGS.STATIC_URL}logo/logo-symbol.svg`}
          alt=""
          className="error-boundary__logo"
        />
      </a>
      <p className="error-boundary__title">
        Sorry - an unexpected error occurred.
        <br />
        Please try reloading the page.
      </p>
      <button onClick={reloadPage} className="error-boundary__button">
        Refresh page
      </button>
    </div>
  );
};
