import type { WrappedFieldInputProps } from "redux-form";
import _ from "underscore";

/**
 * @description by default `input.onChange` argument `value` is an object,
 * but form may require `string` instead so you can use this function as adapter.
 * */
export const overwriteInputOnChange = (input: WrappedFieldInputProps) => {
  return {
    ...input,
    onChange: (_ev, value) => {
      if (_.isObject(value) && "value" in value) {
        input.onChange(value.value);
      } else if (!value) {
        input.onChange(null);
      }
    },
  };
};

export const transformValueToPrimitive = (
  value: unknown,
  key: string,
): string | null | void => {
  if (typeof value === "string") {
    return value;
  } else if (_.isObject(value) && key in value) {
    return value[key];
  } else if (!value) {
    return null;
  }
};
