import { Box, Typography } from "@hexocean/braintrust-ui-components";

export const AttachmentList = ({ attachments }) => {
  if (!attachments?.length) {
    return null;
  }

  return (
    <Box>
      <Typography component="h4" variant="paragraph" size="large" mb={1}>
        Attachments
      </Typography>
      {attachments.map(({ id, attachment, safe_file_name: fileName }) => (
        <Box
          key={id}
          component="a"
          href={attachment.file_download_url}
          download
          sx={{
            padding: "5px 10px",
            borderRadius: "5px",
            display: "flex",
            justifyContent: "space-between",
            gap: "24px",
            marginBottom: "12px",
            alignItems: "center",
            background: "var(--soft-blue)",
            border: "1px solid var(--soft-blue)",
            ":hover": {
              background: "var(--medium-blue)",
              borderColor: "var(--dark-blue)",
              textDecoration: "none",
            },
          }}
        >
          <Typography component="p" ellipsis>
            {attachment.name || fileName}
          </Typography>
          <Typography
            component="p"
            size="small"
            sx={{ textDecoration: "underline" }}
          >
            Download
          </Typography>
        </Box>
      ))}
    </Box>
  );
};
