import { RoundedBox } from "@hexocean/braintrust-ui-components";
import { EmployerLogoNameAndRating } from "@js/apps/employer/components";

export const EmployerInfo = ({ job, employer, ...props }) => {
  return (
    <RoundedBox px={4} py={3} {...props}>
      <EmployerLogoNameAndRating
        employer={employer}
        jobId={job.id}
        logoSize="medium"
      />
    </RoundedBox>
  );
};
