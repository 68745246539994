export const MODULE_EDIT_MODAL_PROPERTIES = {
  closeButton: true,
  closeButtonProps: {
    variant: "tertiary",
    size: "x-small",
  },
  keepOnBackdropClick: true,
  className: "profile-page-modal",
} as const;

export const TALENT_LOCATION = {
  talent_listing: "talent_listing",
  talent_search: "talent_search",
  legacy_talent_search: "legacy_talent_search",
} as const;

export const ADD_ANOTHER_ITEM = "ADD_ANOTHER_ITEM";

export const MAX_YEARS_OF_EXPERIENCE = 99;
export const MIN_YEARS_OF_EXPERIENCE = 0;
export const COMPLETION_NUDGE_PARAMS = {
  SKILLS: "edit_skills",
  EXPERIENCE: "edit_experience",
  PROJECTS: "edit_projects",
  BIO: "edit_bio",
  GENERAL_INFO: "general_info",
  WORK_EXPERIENCE: "work_history",
  YEARS_OF_EXPERIENCE: "years_of_experience",
};
