import { Field } from "redux-form";

import { Box, IconButton, Tooltip } from "@hexocean/braintrust-ui-components";
import { useMediaQuery } from "@hexocean/braintrust-ui-components/hooks";
import { EditPen } from "@hexocean/braintrust-ui-components/Icons";
import { fetchCurrentUser } from "@js/apps/auth/actions";
import {
  AvailableForWorkStatus,
  SetWorkAvailability,
} from "@js/apps/available-for-work/components";
import { CoachMarkTooltip } from "@js/apps/available-for-work/components/coach-mark";
import { fetchFreelancerProfile } from "@js/apps/freelancer/actions";
import { freelancerApi } from "@js/apps/freelancer/api";
import { createFormInstance } from "@js/forms/components";
import { AvatarField } from "@js/forms/fields";

import type { AvatarFormData } from "./hook";

const AvatarFormInstance = createFormInstance<AvatarFormData, unknown>(
  "avatar-form",
  {
    onChange: (values, _dispatch, props, previousValues) => {
      if (previousValues.avatar_id !== values.avatar_id && props.submit) {
        props.submit();
      }
    },
    onSubmitSuccess: (freelancerId: number, dispatch) => {
      dispatch(fetchFreelancerProfile(freelancerId));
      dispatch(
        freelancerApi.util.invalidateTags(["FreelancerProfileCompletion"]),
      );
      dispatch(fetchCurrentUser());
    },
  },
);

type ProfnetProfilePageAvatarOwnerComponentProps = {
  onAvatarFormSubmit: (data: AvatarFormData) => Promise<number>;
  onAvatarEditClick: () => void;
  onAvailableForWorkStatusClick: (
    e: React.MouseEvent<HTMLElement, MouseEvent>,
  ) => void;
  defaultAvatarSrc?: string | null;
  customInputRef: React.MutableRefObject<HTMLInputElement | undefined>;
  isAvailableForWork: boolean;
  tooltipTitle: React.ReactNode;
  showWorkingHoursCoachMark: boolean;
  showAvailableForWorkCoachMark: boolean;
  handleAvailableForWorkCoachMarkClose: () => void;
  handleWorkingHoursCoachMarkClose: () => void;
};

export const ProfnetProfilePageAvatarOwnerComponent = ({
  onAvatarFormSubmit,
  onAvatarEditClick,
  onAvailableForWorkStatusClick,
  customInputRef,
  isAvailableForWork,
  tooltipTitle,
  showWorkingHoursCoachMark,
  showAvailableForWorkCoachMark,
  handleAvailableForWorkCoachMarkClose,
  handleWorkingHoursCoachMarkClose,
}: ProfnetProfilePageAvatarOwnerComponentProps) => {
  const isMobile = useMediaQuery("sm");

  return (
    <Box className="profnet-profile-page-avatar">
      <Box className="profnet-profile-page-avatar__field">
        <AvatarFormInstance onSubmit={onAvatarFormSubmit}>
          <Field
            name="avatar"
            updateFieldName="avatar_id"
            component={AvatarField}
            type="talent"
            uploadType={ENUMS.UploadType.USER_AVATAR_IMAGE_UPLOAD}
            customRef={customInputRef}
            isMobile={isMobile}
          />
          <IconButton
            className="profnet-profile-page-avatar__btn"
            variant="black-outlined"
            size="x-small"
            onClick={onAvatarEditClick}
            aria-label="Edit avatar"
          >
            <EditPen />
          </IconButton>
        </AvatarFormInstance>
        <CoachMarkTooltip
          showWorkingHoursCoachMark={showWorkingHoursCoachMark}
          showAvailableForWorkCoachMark={showAvailableForWorkCoachMark}
          handleAvailableForWorkCoachMarkClose={
            handleAvailableForWorkCoachMarkClose
          }
          handleWorkingHoursCoachMarkClose={handleWorkingHoursCoachMarkClose}
        >
          <Box className="availability-status-container">
            {isAvailableForWork ? (
              <Tooltip
                title={tooltipTitle}
                color="var(--black)"
                disableInteractive
              >
                <Box>
                  <AvailableForWorkStatus
                    onClick={onAvailableForWorkStatusClick}
                  />
                </Box>
              </Tooltip>
            ) : (
              <SetWorkAvailability onClick={onAvailableForWorkStatusClick} />
            )}
          </Box>
        </CoachMarkTooltip>
      </Box>
    </Box>
  );
};
