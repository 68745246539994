import React from "react";

import { Button, Typography } from "@hexocean/braintrust-ui-components";
import { RouterLink } from "@js/components/link";

export const EmptyState = () => (
  <div className="my-wallet__activity-feed-empty-state">
    <img
      src={`${SETTINGS.STATIC_URL}wallet/empty-activity.svg`}
      alt="no activity"
    />
    <Typography variant="paragraph" component="h2" mb={1} mt={2}>
      Your BTRST activity will go here!
    </Typography>
    <Typography
      variant="paragraph"
      component="p"
      size="small"
      mb={2}
      maxWidth="566px"
      margin="0 auto 16px"
    >
      BTRST is a crypto token that helps reward our community for contributing
      to Braintrust and helping it grow. You can earn BTRST by inviting and
      vetting talent, referring clients, helping others with their career,
      building new features and projects for Braintrust, and more. Your BTRST
      activity will appear here for any tokens you earn or spend on the
      Braintrust platform.
    </Typography>
    <Button
      variant="secondary"
      size="x-small"
      to="/feed/"
      RouterLink={RouterLink}
      target="_blank"
    >
      Start earning
    </Button>
  </div>
);
