import React from "react";

import { FreelancerInvoicesListingPageContent } from "@js/apps/invoices/views/freelancer/content";

import DashboardLayout from "./layout";

const FreelancerDashboardInvoicesPage = () => (
  <DashboardLayout
    pageTitle="Invoices"
    contentClassName="my-jobs-dashboard-layout"
  >
    <FreelancerInvoicesListingPageContent onlyMobileMenu />
  </DashboardLayout>
);

export default FreelancerDashboardInvoicesPage;
