import { useCallback, useEffect } from "react";

import {
  FRONTEND_STORAGE_KEYS,
  useGetStorageValueQuery,
  useSetStorageValueMutation,
} from "@js/apps/common/frontend-storage";
import { useGetEmployerInfoQuery } from "@js/apps/employer/api";
import type { User } from "@js/types/auth";

import { openATSIntegrationPopupModal } from ".";

export const useAtsIntegrationPopupModal = ({
  user,
}: {
  user: User | null;
}) => {
  const { data: employerProfile } = useGetEmployerInfoQuery(
    { employerId: user?.employer as number },
    { skip: user === null || !user?.employer },
  );
  const {
    data: hasAtsIntegrationIntroduced,
    isLoading: isLoadingAtsIntegrationIntroduced,
  } = useGetStorageValueQuery(
    { key: FRONTEND_STORAGE_KEYS.is_ats_introduced },
    { skip: !employerProfile?.can_create_linked_account },
  );
  const [setStorageValue] = useSetStorageValueMutation();

  const dismissAtsIntegrationModal = useCallback(() => {
    setStorageValue({
      key: FRONTEND_STORAGE_KEYS.is_ats_introduced,
      value: true,
    });
  }, [setStorageValue]);

  useEffect(() => {
    if (
      employerProfile?.can_create_linked_account &&
      !hasAtsIntegrationIntroduced &&
      !isLoadingAtsIntegrationIntroduced
    ) {
      openATSIntegrationPopupModal({ onClose: dismissAtsIntegrationModal });
    }
  }, [
    employerProfile,
    isLoadingAtsIntegrationIntroduced,
    hasAtsIntegrationIntroduced,
    dismissAtsIntegrationModal,
  ]);
};
