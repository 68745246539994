import type { FormErrors } from "redux-form";
import { getFormSubmitErrors } from "redux-form";

import { useAppSelector } from "@js/hooks";

import type { InvoiceFormData } from "../../forms/add-invoice/types";

export const useInvoiceSubmitErrors = (form: string) => {
  const submitErrors: FormErrors<InvoiceFormData> = useAppSelector((state) =>
    getFormSubmitErrors(form)(state),
  );

  const getTaxNameError = (index: number): string[] | null => {
    const err =
      submitErrors.new_items && submitErrors.new_items[index]?.tax_name;
    return err || null;
  };

  const getTaxRateError = (index: number): string[] | null => {
    const err =
      submitErrors.new_items && submitErrors.new_items[index]?.tax_rate;
    return err || null;
  };

  return {
    getTaxNameError,
    getTaxRateError,
  };
};
