import React, { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import type { Dispatch } from "redux";
import type { DecoratedFormProps } from "redux-form";
import { SubmissionError } from "redux-form";
import { isEqual, isObject, omit } from "underscore";

import { useIsNodeStaff } from "@js/apps/common/hooks";
import { fetchEmployerProfile } from "@js/apps/employer/actions";
import { useHandleTransaction } from "@js/apps/payments/hooks/use-handle-transaction";
import { Snackbar } from "@js/components/snackbar";
import {
  useAppDispatch,
  useAppSelector,
  useGoBackHistory,
  useNavigate,
} from "@js/hooks/";
import { isErrorWithErrors } from "@js/types/errors";

import {
  useGetEmployerOfferQuery,
  useUpdateEmployerOfferMutation,
} from "../../api";
import {
  ReviewBeforeSendingModal,
  ReviewBeforeSendingModalContent,
} from "../../components";
import { OFFER_FIELDS } from "../../constants";
import type { EditOfferFormData, UpdateOfferDataReturnData } from "../../types";
import { getEditOfferInitialValues } from "../../utils";
import { useCreateOrEditOfferSnackbar } from "../create-or-edit-offer-snackbar";

type OnSuccessResultData = UpdateOfferDataReturnData & {
  transactionError?: Record<string, string>;
};

export const useEditOffer = (): {
  loading: boolean;
  isTransactionProceeding: boolean;
  initialValues: Partial<EditOfferFormData>;
  onSubmit: (values: EditOfferFormData) => void;
  onSubmitSuccess: (
    result: OnSuccessResultData | undefined,
    methodDispatch: Dispatch<any>,
    props: DecoratedFormProps<EditOfferFormData>,
  ) => void;
} => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const goBack = useGoBackHistory();

  const [loading, setLoading] = useState(true);
  const { id: jobId, offerId } = useParams();
  const isNodeStaff = useIsNodeStaff();

  const [updateOffer] = useUpdateEmployerOfferMutation();
  const {
    data: offer,
    isFetching: isFetchingOffer,
    error: fetchingOfferError,
  } = useGetEmployerOfferQuery(Number(offerId), {
    skip: offerId === undefined,
  });
  const { handleTransactionCreated, loading: transactionLoading } =
    useHandleTransaction();

  const bidDetails = offer?.bid;

  const { displayOfferSnackbar, loadingTopBar } = useCreateOrEditOfferSnackbar({
    jobId,
    bidDetails,
  });

  const isFetchingEmployerProfile = useAppSelector(
    (state) => state.employer.fetchingEmployerProfile,
  );

  useEffect(() => {
    let isCancelled = false;

    try {
      if (isFetchingOffer) return;

      if (fetchingOfferError) {
        // in case of error user will be redirected immediately because of axios interceptor
        const errorMessage =
          (isObject(fetchingOfferError) && fetchingOfferError?.data?.detail) ||
          "Could not load offer";

        throw Error(errorMessage);
      }

      if (isCancelled) {
        return;
      }

      if (!isNodeStaff) {
        dispatch(fetchEmployerProfile());

        if (isCancelled) {
          return;
        }
      }

      setLoading(false);
    } catch (error) {
      const _error = error as { response?: { data: string } };

      Snackbar.error(_error.response?.data || "Something went wrong");
      // don't redirect immediately to make sure user see error in context of visited offer
      setTimeout(() => {
        goBack(`/jobs/${jobId}/proposals/`);
      }, 3000);
    }

    return () => {
      isCancelled = true;
    };
  }, [
    dispatch,
    fetchingOfferError,
    goBack,
    isFetchingOffer,
    isNodeStaff,
    jobId,
    offerId,
  ]);

  const onSubmit = useCallback(
    async (values: EditOfferFormData) => {
      try {
        await new Promise((resolve, reject) => {
          ReviewBeforeSendingModal.open({
            children: (
              <ReviewBeforeSendingModalContent
                onSubmit={resolve}
                values={values}
              />
            ),
            onClose: () => reject("Closed by user"),
          });
        });

        const data = await updateOffer({
          id: Number(offerId),
          data: {
            ...omit(values, "deposit_payment_method"),
            deposit_payment_method_id: !isNodeStaff
              ? values.deposit_payment_method?.id
              : undefined,
          },
        })
          .unwrap()
          .catch((err) => {
            throw new SubmissionError({
              ...err.data,
              [OFFER_FIELDS.deposit_payment_method]:
                err.data.deposit_payment_method_id,
            });
          });

        if (data.payment_transaction && values.deposit_payment_method) {
          const transactionData = await handleTransactionCreated(
            data.payment_transaction,
            values.deposit_payment_method,
          );

          // We should use try catch here but right now we can't as
          // transaction is linked to offer and returned only if offer is created
          // which will cause that we will not be redirected even if offer was created successfully
          // TODO: Try to use try catch when deposit will be migrated to employer level
          if (transactionData?.error) {
            return {
              transactionError: transactionData.error,
              ...data,
            };
          }
        }

        return data;
      } catch (err: unknown) {
        if (isErrorWithErrors(err)) {
          throw new SubmissionError(err.errors);
        }
        throw new SubmissionError({ _error: "Failed to edit offer" });
      } finally {
        ReviewBeforeSendingModal.close();
      }
    },
    [handleTransactionCreated, isNodeStaff, offerId, updateOffer],
  );

  const onSubmitSuccess = useCallback(
    (
      result: OnSuccessResultData | undefined,
      _methodDispatch: Dispatch<any>,
      props: DecoratedFormProps<EditOfferFormData>,
    ) => {
      if (!result) return;

      const values = props.values as EditOfferFormData;
      const initialValues = props.initialValues;

      if (!isNodeStaff) {
        dispatch(fetchEmployerProfile());
      }

      navigate(`/jobs/${result.bid.job.id}/proposals/`);

      const depositPaymentMethod = values?.deposit_payment_method;
      const initialDepositPaymentMethod = initialValues?.deposit_payment_method;

      const paymentMethodChanged = !isEqual(
        depositPaymentMethod,
        initialDepositPaymentMethod,
      );

      if (paymentMethodChanged) {
        displayOfferSnackbar({ result, depositPaymentMethod });
      }
    },
    [dispatch, displayOfferSnackbar, isNodeStaff, navigate],
  );

  const initialValues = React.useMemo(() => {
    return getEditOfferInitialValues(offer);
  }, [offer]);

  return {
    loading:
      loading ||
      isFetchingOffer ||
      transactionLoading ||
      isFetchingEmployerProfile ||
      loadingTopBar,
    initialValues: initialValues || {},
    isTransactionProceeding: transactionLoading,
    onSubmit,
    onSubmitSuccess,
  };
};
