import { Button } from "@hexocean/braintrust-ui-components";

export const ReviewOfferButton = () => {
  return (
    <>
      <Button
        variant="positive"
        shape="squared"
        size="medium"
        sx={{ marginTop: "8px" }}
        type="submit"
      >
        Review offer
      </Button>
    </>
  );
};
