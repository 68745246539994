import { Outlet } from "react-router-dom";

import { FormLayoutWrapper } from "@js/apps/common/components";
import { EDIT_JOB_FORM_ID } from "@js/apps/jobs/apps/create-job/constants";
import { useEditJob } from "@js/apps/jobs/apps/create-job/hooks/edit-job";
import { JobFormContextProvider } from "@js/apps/jobs/context/job-form-context/job-form-context";
import type { ReduxFormConfiguration } from "@js/forms/components/redux-form";
import type { OverwriteContentComponentProps } from "@js/layouts/app";
import { AppLayout } from "@js/layouts/app";

import { useJobDescriptionUpdate } from "../../hooks/use-job-description-update";

export const EditJobFormLayoutWrapper = () => {
  return (
    <AppLayout
      disableSubheaderPortal
      OverwriteContentComponent={EditJobWrapperContent}
      children={null}
    />
  );
};

export const EditJobWrapperContent = (
  props: OverwriteContentComponentProps,
) => {
  const { loading, ...formProps } = useEditJob();
  const updateJobDescriptionContext = useJobDescriptionUpdate({
    formId: EDIT_JOB_FORM_ID,
  });

  return (
    <FormLayoutWrapper
      formId={EDIT_JOB_FORM_ID}
      formProps={formProps as Partial<ReduxFormConfiguration>}
      loading={loading}
      {...props}
    >
      <JobFormContextProvider {...updateJobDescriptionContext}>
        <Outlet />
      </JobFormContextProvider>
    </FormLayoutWrapper>
  );
};
