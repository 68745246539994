import { z } from "zod";

import type { PlaceDetailsResponse } from "@js/hooks";

export const OnboardingOrganizationFieldsSchema = z.object({
  name: z.string(),
  organization_size: z.nativeEnum(ENUMS.OrganizationSize).optional(),
  industry_id: z.number().optional(),
  website: z.string().optional(),
  google_full_location: z.custom<PlaceDetailsResponse>().nullable(),
});

export type OnboardingOrganizationFields = z.infer<
  typeof OnboardingOrganizationFieldsSchema
>;
