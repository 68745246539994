import type { Dispatch, SetStateAction } from "react";
import { useNavigate } from "react-router-dom";

import {
  Alert,
  IconButton,
  Typography,
} from "@hexocean/braintrust-ui-components";
import { useMediaQuery } from "@hexocean/braintrust-ui-components/hooks";
import { CloseIcon } from "@hexocean/braintrust-ui-components/Icons";
import { ModalInstance } from "@js/components/modal";
import { Snackbar } from "@js/components/snackbar";
import { formatTokenAmount } from "@js/utils";

import {
  type CanConfirmTransactionResponse,
  useConfirmWithdrawalTransactionMutation,
} from "../../api";

import styles from "./styles.module.scss";

type onConfirmType = ({
  transactionResponse,
  setAlerts,
}: {
  transactionResponse: CanConfirmTransactionResponse;
  setAlerts: Dispatch<SetStateAction<JSX.Element | null>>;
}) => Promise<void>;

export const useOnConfirmWithdrawal = ({
  code,
}: {
  code: string | undefined;
}): onConfirmType => {
  const [confirmWithdrawalTransaction] =
    useConfirmWithdrawalTransactionMutation();

  const isTablet = useMediaQuery("md");
  const navigate = useNavigate();

  const onConfirm = async ({
    transactionResponse,
    setAlerts,
  }: {
    transactionResponse: CanConfirmTransactionResponse;
    setAlerts: Dispatch<SetStateAction<JSX.Element | null>>;
  }) => {
    if (!code) return;

    await confirmWithdrawalTransaction({ code })
      .unwrap()
      .then(() => {
        ModalInstance.close();
        navigate("/talent/dashboard/my_wallet/");
        setAlerts(
          <Alert
            type="success"
            withIcon
            alignIcon={isTablet ? "top" : "center"}
            className={styles.walletAlert}
            rounded
          >
            <div className={styles.walletAlertWrapper}>
              <Typography component="p">
                Your BTRST transfer of{" "}
                <strong>{formatTokenAmount(transactionResponse.amount)}</strong>{" "}
                tokens is now processing. This may take up to 24 hours, for
                security reasons.
              </Typography>
              <IconButton
                size="x-small"
                variant="transparent"
                aria-label="Close alert button"
                onClick={() => setAlerts(null)}
              >
                <CloseIcon
                  sx={{
                    borderRadius: "50%",
                  }}
                />
              </IconButton>
            </div>
          </Alert>,
        );
      })
      .catch((error) => {
        Snackbar.error(
          error?.data?.code ||
            "Sorry! Something went wrong during confirming transaction.",
        );
      });
  };
  return onConfirm;
};
