import { useNavigate } from "react-router-dom";

import { Button, Typography } from "@hexocean/braintrust-ui-components";
import { useMediaQuery } from "@hexocean/braintrust-ui-components/hooks";
import { KeyboardArrowRightIcon } from "@hexocean/braintrust-ui-components/Icons";
import { FeedHashtagsHeaderContainer } from "@js/apps/common/components/feed-hashtags-header-container";
import { useHashtagSearchParam } from "@js/apps/common/hooks/use-hashtag-search-params";
import { useGetHashtagByIdQuery } from "@js/apps/give-and-get-help/api";

export type FeedHeaderProps = {
  spaceId: number;
  backButtonText?: string;
};

export const SpaceSubheader = ({
  spaceId,
  backButtonText = "Go to Discussion",
}: FeedHeaderProps) => {
  const isMobile = useMediaQuery("sm");
  const navigate = useNavigate();

  const hashtagId = useHashtagSearchParam();
  const { data } = useGetHashtagByIdQuery(hashtagId as number, {
    skip: !hashtagId,
  });

  const hashtagName = data?.name ?? "";

  const onGoDiscussionClick = () => {
    navigate(`/spaces/${spaceId}/discussion`);
  };

  if (!hashtagId) return null;

  return (
    <FeedHashtagsHeaderContainer>
      <Typography
        component="h1"
        variant="title"
        textAlign="left"
        size="medium"
        fontWeight={400}
        maxWidth="100%"
        ellipsis
      >
        #{hashtagName}
      </Typography>

      <Button
        variant="secondary"
        fontWeight={500}
        endIcon={<KeyboardArrowRightIcon />}
        size={isMobile ? "x-small" : "small"}
        onClick={onGoDiscussionClick}
      >
        {backButtonText}
      </Button>
    </FeedHashtagsHeaderContainer>
  );
};
