import { useCallback, useRef } from "react";
import { useNavigate } from "react-router-dom";

import { useIsNodeStaff, useUser } from "@js/apps/common/hooks";
import { updateBidStatus } from "@js/apps/jobs/actions";
import { BID_SUBMIT_TYPE } from "@js/apps/jobs/constants";
import {
  useGetOrCreateMessageRoomMutation,
  useManualSendMessageMutation,
} from "@js/apps/messenger/api";
import { ModalInstance } from "@js/components/modal";
import { Snackbar } from "@js/components/snackbar";
import { useAppDispatch } from "@js/hooks";
import { saveRejectOfferMessage } from "@js/services";
import { assertUnreachable } from "@js/utils";

import {
  useUpdateEmployerBidFeedbackMutation,
  useUpdateEmployerBidInBulkMutation,
  useUpdateEmployerBidSectionMutation,
} from "../../api";
import { resetBidsTotal } from "../../components/bulk-select-actions/bid-bulk-selection-slice";
import type { SendMessageFormValues } from "../../forms";
import {
  getRoomContext,
  handleFailureForRestrictedApplications,
} from "../../helpers";
import type { RejectOfferMessageWrapperProps } from "../../models";
import { useSelectedRejectableIds } from "../use-selected-rejectable-ids";

type SubmitTypes = keyof typeof BID_SUBMIT_TYPE;

type UseRejectOfferMessageSubmitTypeProps = Pick<
  RejectOfferMessageWrapperProps,
  "bid" | "nextUrl" | "refetchBidList"
>;

export const useRejectOfferMessageSubmitType = ({
  bid,
  nextUrl,
  refetchBidList,
}: UseRejectOfferMessageSubmitTypeProps) => {
  const [getOrCreateMessageRoom] = useGetOrCreateMessageRoomMutation();
  const [manualSendMessage] = useManualSendMessageMutation();
  const dispatch = useAppDispatch();
  const submitType = useRef<SubmitTypes>();
  const navigate = useNavigate();
  const { selectedRejectableIds } = useSelectedRejectableIds();
  const user = useUser();
  const isNodeStaff = useIsNodeStaff();
  const [updateEmployerBidSection] = useUpdateEmployerBidSectionMutation();
  const [updateEmployerBidInBulk] = useUpdateEmployerBidInBulkMutation();
  const [updateEmployerBidFeedback] = useUpdateEmployerBidFeedbackMutation();

  const updateSubmitType = useCallback((type: SubmitTypes) => {
    submitType.current = type;
  }, []);

  const onSubmit = async (values: SendMessageFormValues) => {
    if (!submitType.current) return;

    switch (submitType.current) {
      case BID_SUBMIT_TYPE.WITH_FEEDBACK: {
        if (!user || !bid || !nextUrl) {
          return;
        }
        saveRejectOfferMessage(bid.id, values.message);
        ModalInstance.close();
        navigate(nextUrl, {
          state: { bidId: bid.id },
        });
        dispatch(resetBidsTotal());
        break;
      }
      case BID_SUBMIT_TYPE.WITHOUT_FEEDBACK: {
        if (!user || !bid) {
          return;
        }

        await updateEmployerBidSection({
          bidId: bid.id,
          status: ENUMS.BidStatus.REJECTED,
        });
        await updateEmployerBidFeedback({
          bidId: bid.id,
          feedback: { decline_reason_for_talent: values.message },
        });

        const room = await getOrCreateMessageRoom({
          context: { participants: [user.id, bid?.freelancer.user.id] },
        }).unwrap();

        await manualSendMessage({
          payload: {
            message_content: values.message,
            author: user.id,
          },
          room_context: {
            content_type: ENUMS.RoomTypeContentType.P2P_ROOM_TYPE,
            context: getRoomContext(user, isNodeStaff, room, user.id),
          },
        })
          .unwrap()
          .catch(() =>
            Snackbar.error(
              "Sending message failed. Please refresh the page and try again",
            ),
          );

        Snackbar.toast({
          header: `You rejected ${bid?.freelancer.user.first_name} for this role.`,
        });
        ModalInstance.close();
        if (refetchBidList) refetchBidList();
        dispatch(resetBidsTotal());
        break;
      }
      case BID_SUBMIT_TYPE.WITH_FEEDBACK_BULK: {
        if (!user) {
          return;
        }
        if (selectedRejectableIds.length) {
          await updateEmployerBidInBulk({
            status: ENUMS.BidStatus.REJECTED,
            pks: selectedRejectableIds,
          })
            .unwrap()
            .then((bids) => {
              bids.forEach((el) => {
                dispatch(updateBidStatus(el));
              });
              dispatch(resetBidsTotal());
              ModalInstance.close();
            })
            .catch(() =>
              Snackbar.error(
                "Updating status failed. Please refresh the page and try again",
              ),
            );
        }

        if (!selectedRejectableIds.length) {
          handleFailureForRestrictedApplications();
        }
        break;
      }
      case undefined:
        break;
      default: {
        assertUnreachable(submitType.current);
      }
    }
  };

  return {
    updateSubmitType,
    onSubmit,
  };
};
