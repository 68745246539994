import React from "react";

import { Box, Typography } from "@hexocean/braintrust-ui-components";
import type { LocationSelectReason } from "@js/apps/freelancer/types";
import type { PopularLocationButtonSelectProps } from "@js/apps/jobs/apps/bids/forms/bid-location-filter";
import { ButtonSelectField } from "@js/forms/fields/button-select";

type RecommendedLocationButtonSelectProps = Pick<
  PopularLocationButtonSelectProps,
  "input" | "meta" | "label"
> & {
  options: { label: string; value: string | null }[];
};
export const RecommendedLocationButtonSelect = ({
  label,
  options,
  input,
  meta,
}: RecommendedLocationButtonSelectProps) => {
  return (
    <Box mt={2}>
      <Typography
        component="label"
        display="block"
        mb={1}
        variant="label"
        size="small"
        fontWeight={400}
      >
        {label}
      </Typography>
      <ButtonSelectField
        labelledBy="sub-category-label"
        fontWeight={400}
        typographySize="small"
        variant="white-violet"
        shape="squared"
        multiple
        options={options}
        input={{
          ...input,
          onChange: (data, reason) => {
            input.onChange(data, reason as LocationSelectReason);
          },
        }}
        meta={meta}
      />
    </Box>
  );
};
