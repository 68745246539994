import { useMediaQuery } from "@hexocean/braintrust-ui-components/hooks";
import { CtaClickedProvider } from "@js/apps/common/context";
import { useUser } from "@js/apps/common/hooks";
import {
  AppliedJobsSection,
  DashboardHeader,
  EventsSection,
  GetInspiredByTopTalentSection,
  JobsSection,
  NewsAndArticlesSection,
  ProfileCompletionNudgeSection,
  ReferAndEarnSection,
} from "@js/apps/freelancer/components";
import { DashboardGiveGetHelpModule } from "@js/apps/freelancer/components/dashboard";
import { FirstSteps } from "@js/apps/freelancer/components/dashboard/first-steps";
import { useHasArticles } from "@js/apps/freelancer/components/dashboard/news-and-articles-section/hooks";
import { SuggestedSpacesSection } from "@js/apps/freelancer/components/dashboard/suggested-spaces-section";
import { PostLocation } from "@js/apps/give-and-get-help/context/post-location";
import { JobLocationContext } from "@js/apps/jobs/context";

import DashboardLayout from "../layout";

import { useFreelancerDashboardWelcome } from "./use-freelancer-dashboard-welcome";

export const FreelancerDashboardWelcomePage = () => {
  const user = useUser();
  const { isAccountJobMode, freelancerProfile } =
    useFreelancerDashboardWelcome(user);
  const hasArticles = useHasArticles();
  const isMobile = useMediaQuery("md");

  return (
    <JobLocationContext.Provider value={JobLocationContext.Values.talent_home}>
      <PostLocation.Provider value={PostLocation.Values.talent_home}>
        <CtaClickedProvider
          location={ENUMS.UserClicksEventCTALocation.DASHBOARD}
        >
          <DashboardLayout pageTitle="Welcome" pageColor="var(--soft-violet)">
            <div className="freelancer-dashboard-welcome">
              <DashboardHeader />
              <FirstSteps />
              {isAccountJobMode && <JobsSection />}
              <div className="freelancer-dashboard-welcome__column-left">
                <AppliedJobsSection />
                {isAccountJobMode && <ProfileCompletionNudgeSection />}
                <SuggestedSpacesSection />
                {freelancerProfile && <GetInspiredByTopTalentSection />}
                {!isMobile && user?.is_verified && <ReferAndEarnSection />}
                {!isAccountJobMode && <ProfileCompletionNudgeSection />}
              </div>
              <div className="freelancer-dashboard-welcome__column-right">
                <DashboardGiveGetHelpModule />
                {!isAccountJobMode && <JobsSection />}
                <EventsSection />
                {hasArticles && <NewsAndArticlesSection />}
                {isMobile && user?.is_verified && <ReferAndEarnSection />}
              </div>
            </div>
          </DashboardLayout>
        </CtaClickedProvider>
      </PostLocation.Provider>
    </JobLocationContext.Provider>
  );
};
