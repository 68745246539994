import { useEffect, useState } from "react";
import cx from "classnames";

import { useUser } from "@js/apps/common/hooks";
import { useGetHelpOffersQuery } from "@js/apps/give-and-get-help/api";
import type { HelpOffer } from "@js/types/give-and-get-help";
import type { Participant } from "@js/types/messenger";

import { ActionBarSingleHelpOffer } from "./action-bar-single-help-offer";

type ActionBarProps = {
  participant: Participant;
};

const actionBarAllowedStatuses: EnumType<typeof ENUMS.HelpOfferStatus>[] = [
  ENUMS.HelpOfferStatus.PENDING,
  ENUMS.HelpOfferStatus.ACCEPTED,
  ENUMS.HelpOfferStatus.COMPLETED,
];

/* Leaving a review by mistake was implemented too soon.
 *  Please remove the usage of the flag below and this comment once the reviewing feature is implemented.
 */
const LEAVE_REVIEW_TURN_ON_FLAG = false;

const isAllowedState = (
  helpOffer: HelpOffer,
  state: "REFUND_REVIEW",
  options?: { userId?: number },
) => {
  if (LEAVE_REVIEW_TURN_ON_FLAG && state === "REFUND_REVIEW") {
    return (
      helpOffer.status === ENUMS.HelpOfferStatus.COMPLETED &&
      helpOffer.refund_issued &&
      helpOffer.reported &&
      options?.userId === helpOffer.receiver?.user.id
    );
  }

  return false;
};

export const ActionBar = ({
  participant,
}: ActionBarProps): JSX.Element | null => {
  const user = useUser();

  const { isFetching, data: helpOffers } = useGetHelpOffersQuery({
    other_user: participant.id,
  });

  const [filteredHelpOffers, setFilteredHelpOffers] = useState<HelpOffer[]>([]);

  useEffect(() => {
    if (!helpOffers) return;

    const helpOfferFiltered = helpOffers.filter((helpOffer) => {
      return (
        actionBarAllowedStatuses.includes(helpOffer.status) ||
        isAllowedState(helpOffer, "REFUND_REVIEW", { userId: user?.id })
      );
    });

    const helpOfferWithoutCompletedAndReviewed = helpOfferFiltered.filter(
      (offer) =>
        !(
          !!offer.review_completed &&
          offer.status === ENUMS.HelpOfferStatus.COMPLETED
        ),
    );

    const helpOffersFilteredForAsker =
      helpOfferWithoutCompletedAndReviewed.filter(
        (offer) =>
          !(
            offer.status === ENUMS.HelpOfferStatus.COMPLETED &&
            offer.author_id === user?.id
          ),
      );

    setFilteredHelpOffers(helpOffersFilteredForAsker);
  }, [helpOffers, user?.id]);

  if (isFetching || filteredHelpOffers.length === 0) return <></>;

  return (
    <>
      <div
        className={cx("messenger-action-bar", {
          "messenger-action-bar--visible": filteredHelpOffers.length,
        })}
      >
        {filteredHelpOffers.map((offer) => {
          const isOfferAuthor = offer.author_id === user?.id;

          return (
            <ActionBarSingleHelpOffer
              key={offer.id}
              offer={offer}
              isOfferAuthor={isOfferAuthor}
            />
          );
        })}
      </div>
    </>
  );
};
