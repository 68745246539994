import React from "react";
import { Field, formValueSelector, reduxForm } from "redux-form";

import { Button } from "@hexocean/braintrust-ui-components";
import { FileUploadField } from "@js/apps/file-upload";
import { Form } from "@js/forms/components/form";
import { useAppSelector } from "@js/hooks";

import styles from "./add-w-form.module.scss";

const ADD_W8_FORM_ID = "add-w8-form";

const selector = formValueSelector(ADD_W8_FORM_ID);

type AddW8FormProps = {
  onClose: () => void;
};

export type AddW8FormData = {
  w8_form: number;
};

const defaultExistingAttachments = [];

export const AddW8Form = reduxForm<AddW8FormData, AddW8FormProps>({
  form: ADD_W8_FORM_ID,
})(({ submit, error, submitting, onClose }) => {
  const existingAttachments = useAppSelector(
    (state) => selector(state, "attachments") || defaultExistingAttachments,
  );
  const w8FormValue = useAppSelector((state) => selector(state, "w8_form"));

  return (
    <Form onSubmit={submitting ? null : submit} error={error}>
      <Field
        id="w8_form"
        name="w8_form"
        component={FileUploadField}
        className={styles.fileDropzone}
        uploadType={ENUMS.UploadType.FREELANCER_W8_ATTACHMENT_UPLOAD_TYPE}
        existingFiles={existingAttachments.map((i) => ({
          id: i.id,
          attachment: i.attachment,
        }))}
        options={{
          maxSize: SETTINGS.W_FORM_FILE_SIZE_LIMIT,
        }}
      />
      <div className="buttons right mt++">
        <Button variant="primary" onClick={onClose}>
          Upload Later
        </Button>
        <Button
          disabled={!w8FormValue}
          variant="primary"
          onClick={submitting ? undefined : submit}
        >
          Finish
        </Button>
      </div>
    </Form>
  );
});
