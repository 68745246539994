import { type FC, useCallback, useEffect } from "react";
import Decimal from "decimal.js";

import { Box } from "@hexocean/braintrust-ui-components";
import { useMediaQuery } from "@hexocean/braintrust-ui-components/hooks";
import {
  usePurchaseUpgradeMutation,
  useReserveUpgradeMutation,
} from "@js/apps/auth/api";
import { useMyWalletPage } from "@js/apps/dashboard/hooks";
import { useLazyInitCoinbasePaymentQuery } from "@js/apps/on-ramp/api";
import { useCoinbasePay } from "@js/apps/on-ramp/hooks/useCoinbase";
import { ModalInstance } from "@js/components/modal";
import { Snackbar } from "@js/components/snackbar";
import { useAppDispatch } from "@js/hooks";

import Description from "./components/description";
import PurchaseSummary from "./components/purchase-summary";
import { openSuccessPurchaseModal } from "./components/success-purchase-modal";
import { INSIGHT_BTRST_COST } from "./constants";

import styles from "./styles.module.scss";

export const ApplicationInsightModal: FC = () => {
  const [reserveUpgrade, { isLoading: isLoadingReserveUpgrade }] =
    useReserveUpgradeMutation();
  const [purchaseUpgrade, { isLoading: isLoadingPurchaseUpgrade }] =
    usePurchaseUpgradeMutation();
  const { balance } = useMyWalletPage();
  const dispatch = useAppDispatch();

  const parsedBalance = new Decimal(balance?.available_tokens ?? "0");
  const insightCost = new Decimal(INSIGHT_BTRST_COST);
  const requiredTotalBtrst = parsedBalance.minus(insightCost);
  const isSufficientBtrst = requiredTotalBtrst.greaterThan(-1);
  const formattedRequiredTotalBtrst = requiredTotalBtrst
    .toDecimalPlaces(2, Decimal.ROUND_UP)
    .toNumber();

  const [initPayment, { data, isLoading: isLoadingCoinbase }] =
    useLazyInitCoinbasePaymentQuery();
  const { onRampURL } = useCoinbasePay({
    transactionUuid: data?.payment_uuid || "",
    walletAddress: data?.safe_wallet_address || "",
    tokensToBuy: isSufficientBtrst ? formattedRequiredTotalBtrst * -1 : 0,
  });

  const isTablet = useMediaQuery("lg");

  useEffect(() => {
    initPayment();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onPurchase = useCallback(async () => {
    if (isSufficientBtrst) {
      try {
        await purchaseUpgrade({
          upgrade_type: "insights",
        }).unwrap();
        ModalInstance.close();
        openSuccessPurchaseModal(dispatch);
      } catch {
        Snackbar.error("Something went wrong!");
      }
    } else {
      if (onRampURL) {
        await reserveUpgrade({ upgrade_type: "insights" });
        ModalInstance.close();
        window.open(onRampURL, "_blank", "noreferrer");
      } else {
        Snackbar.error("onRamp URl is null");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSufficientBtrst, onRampURL, purchaseUpgrade, reserveUpgrade]);

  return (
    <Box
      minWidth={isTablet ? "100%" : "1248px"}
      minHeight={isTablet ? "100%" : "800px"}
      padding={2}
      boxSizing="border-box"
      display="flex"
      gap={2}
      flexDirection={isTablet ? "column" : "row"}
    >
      <Description
        isSufficientBtrst={isSufficientBtrst}
        requiredTotalBtrst={formattedRequiredTotalBtrst}
      />
      <PurchaseSummary
        isLoadingCoinbase={isLoadingCoinbase}
        isPurchasing={isLoadingPurchaseUpgrade || isLoadingReserveUpgrade}
        isSufficientBtrst={isSufficientBtrst}
        requiredTotalBtrst={formattedRequiredTotalBtrst}
        onPurchase={onPurchase}
      />
    </Box>
  );
};

export const openInsightPurchaseModal = async () => {
  ModalInstance.open({
    className: styles.overlayContainer,
    padding: false,
    closeButton: true,
    closeButtonProps: {
      style: {
        backgroundColor: "rgba(var(--black-rgb), 0.04)",
      },
    },
    children: <ApplicationInsightModal />,
  });
};
