import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import cs from "classnames";

import type { TabsProps } from "@hexocean/braintrust-ui-components";
import { Box, Button, Tabs } from "@hexocean/braintrust-ui-components";
import { ArrowDropDownIcon } from "@hexocean/braintrust-ui-components/Icons";
import type { DashboardNavItem } from "@js/apps/dashboard/nav-items";
import { RouterLink } from "@js/components/link";
import { checkIfAnySubLinkIsActive } from "@js/layouts/app/utils/check-if-any-sub-link-is-active";

import { useSidebarNavButton } from "./hooks/use-app-layout-sidebar";

type AppLayoutSidebarNavProps = {
  items: Array<DashboardNavItem>;
  bgcolor?: string;
  itemVariant?: string;
  className?: string;
} & Partial<TabsProps>;

export const AppLayoutSidebarNav: React.FC<
  React.PropsWithChildren<AppLayoutSidebarNavProps>
> = ({ className, ...props }) => {
  return (
    <nav className={className}>
      <SidebarNavTabs {...props} />
    </nav>
  );
};

const SidebarNavTabs = ({
  items,
  onlyActiveOnIndex,
  itemVariant,
  ...props
}: AppLayoutSidebarNavProps) => {
  const { pathname } = useLocation();

  return (
    <Tabs
      items={items}
      pathname={pathname}
      direction="column"
      onlyActiveOnIndex={onlyActiveOnIndex}
      spacing={1}
      renderItem={({ isActive, item }) => {
        return (
          <SidebarNavButtonBox
            isActive={isActive}
            item={item}
            itemVariant={itemVariant}
            pathname={pathname}
            onlyActiveOnIndex={onlyActiveOnIndex}
          />
        );
      }}
      {...props}
    />
  );
};

type SidebarNavButtonBoxProps = Pick<
  AppLayoutSidebarNavProps,
  "itemVariant"
> & {
  isActive: boolean;
  item: DashboardNavItem;
  pathname: string;
  onlyActiveOnIndex?: boolean;
};

const SidebarNavButtonBox = ({
  isActive,
  item,
  itemVariant,
  pathname,
  onlyActiveOnIndex,
}: SidebarNavButtonBoxProps) => {
  const [isExpanded, setIsExpanded] = useState(
    isActive ||
      item.defaultToActive ||
      checkIfAnySubLinkIsActive(item.subLinks || []),
  );

  const handleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const isLastLevelOfNestedList = !item.subLinks?.some((s) => !!s.subLinks);

  return (
    <Box className="app-layout__sidebar-nav-wrapper">
      <SidebarNavButton
        isActive={isActive}
        item={item}
        itemVariant={itemVariant}
        expandable={!!item.subLinks?.length}
        isExpanded={!!item.subLinks?.length ? isExpanded : undefined}
        handleExpand={!!item.subLinks?.length ? handleExpand : undefined}
        pathname={pathname}
      />

      {!!item.subLinks?.length && isExpanded && (
        <SidebarNavTabs
          className={cs("app-layout__sidebar-nav-sub-nav", {
            "app-layout__sidebar-nav-sub-nav--last": isLastLevelOfNestedList,
          })}
          items={item.subLinks}
          pathname={pathname}
          onlyActiveOnIndex={onlyActiveOnIndex}
          spacing={1}
          renderItem={({ isActive: itemIsActive, item: itemToRender }) => {
            if (itemToRender.subLinks?.length) {
              return (
                <SidebarNavButtonBox
                  isActive={itemIsActive}
                  item={itemToRender}
                  itemVariant={itemVariant}
                  pathname={pathname}
                  onlyActiveOnIndex={onlyActiveOnIndex}
                />
              );
            }
            return (
              <SidebarNavButton
                isActive={itemIsActive}
                item={itemToRender}
                itemVariant={itemVariant}
                pathname={pathname}
              />
            );
          }}
        />
      )}
    </Box>
  );
};

export type SidebarNavButtonProps = Pick<
  AppLayoutSidebarNavProps,
  "itemVariant"
> &
  Pick<SidebarNavButtonBoxProps, "isActive" | "item" | "pathname"> & {
    expandable?: boolean;
    isExpanded?: boolean;
    handleExpand?: () => void;
  };

const SidebarNavButton = ({
  item,
  isActive,
  expandable,
  itemVariant,
  isExpanded,
  handleExpand,
  pathname,
}: SidebarNavButtonProps) => {
  const {
    path,
    label,
    btnVariant,
    isAnEmployerNavItem,
    Icon,
    hasSubLinkActive,
    sidebarItemSelect,
  } = useSidebarNavButton({
    item,
    itemVariant,
    pathname,
    expandable,
    handleExpand,
  });

  return (
    <Button
      to={path}
      onClick={sidebarItemSelect}
      variant={btnVariant}
      fontWeight={isAnEmployerNavItem ? 500 : 400}
      inverse={isAnEmployerNavItem}
      className={cs("app-layout__sidebar-nav-item", {
        "is-active": isActive && !hasSubLinkActive,
      })}
      size="medium"
      startIcon={Icon ? <Icon /> : undefined}
      RouterLink={RouterLink}
    >
      {label}
      {expandable ? (
        <ExpandButton isExpanded={isExpanded} handleExpand={handleExpand} />
      ) : null}
    </Button>
  );
};

type ExpandButtonProps = {
  isExpanded?: boolean;
  handleExpand?: () => void;
};

type ClickEventType = React.MouseEvent<HTMLButtonElement, MouseEvent> &
  React.MouseEvent<HTMLAnchorElement, MouseEvent>;

const ExpandButton = ({ isExpanded, handleExpand }: ExpandButtonProps) => {
  const handleOnClick = (event: ClickEventType) => {
    event.preventDefault();
    event.stopPropagation();

    if (handleExpand) {
      handleExpand();
    }
  };

  return (
    <Button
      variant="transparent"
      onClick={handleOnClick}
      disableRipple
      style={{
        padding: 0,
        minWidth: "initial",
        marginLeft: "auto",
      }}
    >
      <ArrowDropDownIcon
        style={{
          transform: isExpanded ? "rotate(180deg)" : "initial",
        }}
      />
    </Button>
  );
};
