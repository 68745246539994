import { useMemo } from "react";
import cs from "classnames";

import type { BoxProps } from "@hexocean/braintrust-ui-components";
import { Box } from "@hexocean/braintrust-ui-components";
import type { Hashtag } from "@js/apps/give-and-get-help/types";

import { TrendingHashtagsCarousel } from "./trending-hashtags-carousel";

export type TrendingHashtagsProps = BoxProps & {
  hashtags: Hashtag[];
};

export const TrendingHashtags = ({
  hashtags,
  className,
  ...boxProps
}: TrendingHashtagsProps) => {
  const options = useMemo(
    () =>
      hashtags.map((hashtag) => ({
        label: hashtag.name,
        value: hashtag.id,
      })),
    [hashtags],
  );
  return (
    <Box className={cs(className, "hashtags-carousel")} {...boxProps}>
      <TrendingHashtagsCarousel options={options} />
    </Box>
  );
};

export const shouldRenderHashtagsModule = (
  numberOfposts: number,
  currentPostIndex: number,
  desiredPosition: number,
): boolean => {
  const hasEnoughPosts = numberOfposts > desiredPosition;

  const shouldAppearNext =
    hasEnoughPosts && currentPostIndex === desiredPosition;

  const shouldAppearLast =
    !hasEnoughPosts && currentPostIndex + 1 === numberOfposts;

  return shouldAppearNext || shouldAppearLast;
};
