import type { z } from "zod";

import { useQueryParams } from "@js/hooks/query-params";
import type { RhfFormValues } from "@js/rhf/types";

export const useParsedQueryParams = <T extends z.ZodRawShape>(
  zodSchema: z.ZodObject<T>,
) => {
  const originalParams = useQueryParams();
  const parsedFilters = zodSchema.safeParse(originalParams);

  return {
    data: parsedFilters.success ? parsedFilters.data : undefined,
    success: parsedFilters.success,
    parsedObject: parsedFilters,
    originalParams,
  };
};

export const useParsedQueryParamsWithDefaults = <T extends z.ZodRawShape>(
  zodSchema: z.ZodObject<T>,
  defaultValues: RhfFormValues<z.ZodObject<T>>,
) => {
  const { parsedObject, ...result } = useParsedQueryParams(zodSchema);

  return {
    ...result,
    data: parsedObject.success
      ? { ...defaultValues, ...parsedObject.data }
      : undefined,
  };
};
