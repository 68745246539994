import { motion } from "framer-motion";

import { Box } from "@hexocean/braintrust-ui-components";
import type { User } from "@js/types/auth";

import { ReviewMock } from "./review-mock";
import { ReviewTarget } from "./review-target";

type AddReviewAnimatedBoxProps = {
  reviewer: User;
};

export const AddReviewAnimatedBox = ({
  reviewer,
}: AddReviewAnimatedBoxProps) => {
  return (
    <Box className="add-review-animated-box" p={2.5}>
      <motion.div
        className="add-review-animated-box__bg-art"
        animate={{ y: -60 }}
        transition={{
          from: -250,
          type: "spring",
          stiffness: 75,
        }}
        style={{
          backgroundImage: `url(${SETTINGS.STATIC_URL}give-get-help/ggh-review-bg-art.svg)`,
        }}
      ></motion.div>

      <Box display="flex" alignItems="center" flexDirection="column">
        <ReviewTarget reviewer={reviewer} />
        <ReviewMock />
      </Box>
    </Box>
  );
};
