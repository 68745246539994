import React from "react";

import {
  Grid,
  Loader,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@hexocean/braintrust-ui-components";
import { useWithRecaptcha } from "@js/apps/common/hooks";

import { InviteByEmailForm } from "../../forms/invite-team-member";
import type { EmployerTeamMembersType } from "../../hooks/employer-team-members";
import { useEmployerTeamMembers } from "../../hooks/employer-team-members";

import { TeamMemberActionConfirmationModal } from "./actions/open-confirmation-modal";
import { InvitedMember, TeamMember } from "./components/member";

export const EmployerTeamMembers = () => {
  useWithRecaptcha();

  const {
    loading,
    teamMembers,
    onSubmit,
    getMemberActions,
    getInvitedMemberActions,
  } = useEmployerTeamMembers();

  return (
    <>
      <Grid item xs={12}>
        <Header />
      </Grid>
      <Grid item xs={12} className="mt+ mb+">
        <InviteByEmailForm onSubmit={onSubmit} />
      </Grid>
      <Grid item xs={12} className="mt+">
        {loading ? (
          <Loader centered className="posr" />
        ) : (
          <TeamMembersTable
            teamMembers={teamMembers}
            getMemberActions={getMemberActions}
            getInvitedMemberActions={getInvitedMemberActions}
          />
        )}
        <TeamMemberActionConfirmationModal />
      </Grid>
    </>
  );
};

const Header = () => {
  return (
    <>
      <Typography
        component="h1"
        variant="title"
        textTransform="uppercase"
        mb={2}
      >
        Team Members
      </Typography>
      <div>
        Add other people from your company via email. Members not on the
        Braintrust network will receive an invitation email to join.
      </div>
    </>
  );
};

type TeamMembersTableProps = Pick<
  EmployerTeamMembersType,
  "teamMembers" | "getMemberActions" | "getInvitedMemberActions"
>;

const TeamMembersTable = ({
  teamMembers,
  getMemberActions,
  getInvitedMemberActions,
}: TeamMembersTableProps) => {
  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Team Member</TableCell>
            <TableCell>Joined</TableCell>
            <TableCell align="center">Can View Invoices</TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {teamMembers.map((teamMember) => {
            return "invitee" in teamMember ? (
              <InvitedMember
                key={teamMember.id}
                teamMember={teamMember}
                getActions={getInvitedMemberActions}
              />
            ) : (
              <TeamMember
                key={teamMember.id}
                teamMember={teamMember}
                getActions={getMemberActions}
              />
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
