import {
  Box,
  Button,
  Loader,
  Stack,
  Typography,
} from "@hexocean/braintrust-ui-components";
import {
  ActivityHistoryButton,
  ActivityList,
} from "@js/apps/dashboard/components";
import { WalletActivityFiltersCarousel } from "@js/apps/dashboard/components/activities-filter";
import { useWalletActivityFeed } from "@js/apps/dashboard/hooks/use-wallet-activity-feed";
import { handleDownloadFile } from "@js/utils";

import { EmptyState } from "./empty-state";

const downloadActivityCSV = () =>
  handleDownloadFile({
    endpoint: "/api/token_balance_items/",
    params: { format: "csv" },
  });

const ACTIVITY_FEED_LIST_LIMIT = 7;

export const ActivityFeed = () => {
  const { total, isShowingAll, isLoading, activityList, onShowAllClick } =
    useWalletActivityFeed({ limit: ACTIVITY_FEED_LIST_LIMIT });

  const showEmptyState = !total && !isLoading;
  const showActivityList = !isLoading && !showEmptyState;

  return (
    <div className="my-wallet__activity-feed">
      <div className="my-wallet__activity-feed-content">
        <div className="my-wallet__activity-feed-header">
          <Typography
            component="h2"
            variant="title"
            size="small"
            fontWeight={400}
          >
            Activity
          </Typography>
          {showActivityList && (
            <ActivityHistoryButton download={downloadActivityCSV} />
          )}
        </div>
        <WalletActivityFiltersCarousel className="my-wallet__activity-feed-filters" />
        {isLoading && <ActivityFeedLoader />}
        {showEmptyState && <EmptyState />}
        {showActivityList && <ActivityList activityList={activityList} />}
      </div>
      {showActivityList && ACTIVITY_FEED_LIST_LIMIT < total && (
        <Stack
          direction="row"
          sx={{
            justifyContent: "space-between",
            alignItems: "center",
            mt: "20px",
            gap: 2,
            minHeight: "42px",
          }}
        >
          <Typography component="p" color="tertiary">
            Showing {isShowingAll ? `all ` : `${ACTIVITY_FEED_LIST_LIMIT}/`}
            {total} results
          </Typography>
          {!isShowingAll && (
            <Button
              variant="medium-green"
              shape="squared"
              onClick={onShowAllClick}
            >
              Show all
            </Button>
          )}
        </Stack>
      )}
    </div>
  );
};

const ActivityFeedLoader = () => {
  return (
    <Box sx={{ position: "relative", minHeight: "200px" }}>
      <Loader centered />
    </Box>
  );
};
