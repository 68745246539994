import { useMemo } from "react";

import { Loader } from "@hexocean/braintrust-ui-components";
import { useMediaQuery } from "@hexocean/braintrust-ui-components/hooks";
import { useUser } from "@js/apps/common/hooks";
import type { MessageRoom, Participant } from "@js/types/messenger";
import { dateDifference } from "@js/utils";

import { useMessageBox } from "../../hooks/use-message-box";
import { ActionBar } from "../action-bar/action-bar";
import { GenericMessengerMessage } from "../message";

import { MessageBoxHeader } from "./header";

import styles from "./styles.module.scss";

type MessageBoxProps = {
  room: MessageRoom | undefined;
};

export const MessagesBox = ({ room }: MessageBoxProps) => {
  const user = useUser();

  const participant = useMemo(() => {
    return room?.participants.find(({ id }) => id === room.interlocutors[0]);
  }, [room]);

  if (!room || !participant) {
    return <NoMessagesMessage />;
  }

  const showActionBar = !!user && !user.employer;

  return (
    <>
      <MessageBoxHeader
        participant={participant}
        isReportedByUser={!!room.show_contact_support}
      />
      {showActionBar && <ActionBar participant={participant} />}
      <MessageBoxContent key={room.id} participant={participant} room={room} />
    </>
  );
};

type MessageBoxContentProps = {
  room: MessageRoom;
  participant: Participant;
};

const MessageBoxContent = ({ room, participant }: MessageBoxContentProps) => {
  const { isReady, messages, scrollBoxRef } = useMessageBox(room);
  const isMobile = useMediaQuery("md");

  if (!isReady) {
    return <MessagesLoader />;
  }

  if (!messages?.length) {
    return <NoMessagesMessage />;
  }

  const shouldDisplayUserData = (messageIndex: number) => {
    if (messageIndex === 0) return true;

    const message = messages[messageIndex];
    const previousMessage = messages[messageIndex - 1];

    if (message.author?.id !== previousMessage.author?.id) return true;

    const TIME_INTERVAL_IN_MINUTES = 15;
    const diff = dateDifference(message.sent, previousMessage.sent, "minutes");

    return diff > TIME_INTERVAL_IN_MINUTES;
  };

  return (
    <div
      className={styles.messagesBox}
      ref={scrollBoxRef}
      style={{
        height: isMobile ? "calc(100vh - 256px)" : undefined,
      }}
    >
      {messages.map((message, index) => (
        <GenericMessengerMessage
          key={message.id}
          isOwner={message.author?.id !== participant.id}
          message={message}
          showUserData={shouldDisplayUserData(index)}
          wasReceiverHiredByClient={!!room.is_talent_hired_by_client}
        />
      ))}
    </div>
  );
};

const NoMessagesMessage = () => {
  return (
    <div className={styles.messagesBox}>
      <div className={styles.messagesBoxNoMessages}>No messages</div>
    </div>
  );
};

const MessagesLoader = () => {
  return (
    <div className={styles.messagesBox}>
      <Loader centered />
    </div>
  );
};
