import { ModalInstance } from "@js/components/modal";

import { BoostModalContent } from "./boost-modal-content";

export const ViewBoostModal = (boostCredit: number, bidId: number) => {
  return ModalInstance.open({
    padding: false,
    closeButtonProps: {
      style: {
        backgroundColor: "rgba(var(--black-rgb), 0.04)",
      },
    },
    children: <BoostModalContent boostCredit={boostCredit} bidId={bidId} />,
  });
};
