import type { ReactElement } from "react";
import * as Sentry from "@sentry/browser";

import { RouterLink } from "@js/components/link";
import { LocalStorage, SessionStorage } from "@js/services";
import type { User } from "@js/types/auth";

import { SIGN_UP_LINK } from "./constants";

export const sentrySetUser = (user: User) =>
  Sentry.getCurrentScope().setUser({
    id: String(user.id),
    email: user.email,
    name: user.public_name,
    accountType: user.account_type,
    approved:
      user.account_type === ENUMS.AccountType.FREELANCER
        ? user.freelancer_approved
        : undefined,
    backendRevision: SETTINGS.BACKEND_REVISION,
  });

export const getUserBrowserTimezone = (): string => {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
};

export const mapMessageCodeToUserMessage = (
  message: string | undefined,
): string | null => {
  switch (message) {
    case "session_expired":
      return "Session expired. Please sign in again.";

    case "password_reset":
      return "Password has been reset. Please sign in with the new password.";

    case "from_password_change":
      return "Password successfully changed. Please sign in again.";

    case ENUMS.AuthFailedErrorCodes.EXTRA_AUTH_REQUIRED:
      return "To finish connecting with LinkedIn please sign in using your existing credentials.";

    case "user_logged_out":
      return "You were logged out. Please sign in again to continue using the site.";

    default:
      return null;
  }
};

export const mapErrorCodeToUserError = (
  code: EnumType<typeof ENUMS.AuthFailedErrorCodes> | string | undefined,
): ReactElement | string | null => {
  switch (code) {
    case ENUMS.AuthFailedErrorCodes.NO_ACCOUNT:
      return (
        <>
          <p className="mt0">Account not found.</p>
          <p>
            <span className="black">
              Please{" "}
              <RouterLink to={SIGN_UP_LINK}>
                create one with your email.
              </RouterLink>
            </span>
          </p>
        </>
      );

    case ENUMS.AuthFailedErrorCodes.AUTH_CANCELLED:
      return "Authentication cancelled.";

    case ENUMS.AuthFailedErrorCodes.ACCOUNT_ALREADY_EXISTS:
      return "Account already exists.";

    case ENUMS.AuthFailedErrorCodes.NO_SOCIAL_ACCOUNT:
      return (
        "Your account already exists. Please sign in with email or sign up with " +
        "LinkedIn to connect it to your account"
      );

    case ENUMS.AuthFailedErrorCodes.ACCOUNT_DISABLED:
      return "Your account is disabled. Please email activate@usebraintrust.com with your account email to re-enable it.";

    case ENUMS.AuthFailedErrorCodes.LAST_NAME_HIDDEN:
      return "We can't read your last name from LinkedIn - it's probably hidden. Please change your privacy settings in order to use LinkedIn sign up.";

    case ENUMS.ConfirmAction.DELETE_ACCOUNT:
      return "You have to be logged in to continue deleting your account.";

    default:
      return null;
  }
};

export const mapEmailErrorCode = (error: string, isSignup?: boolean) => {
  switch (error) {
    case ENUMS.EmailValidationError.INVALID:
      return "Invalid email format.";
    case ENUMS.EmailValidationError.REQUIRED:
    case ENUMS.EmailValidationError.RESTRICTED:
      return isSignup
        ? "Signup could not be processed at this time."
        : "Change could not be processed at this time.";
    case ENUMS.EmailValidationError.MAX_LENGTH:
      return "Sorry, the email address you entered is too long. Try to enter a shorter address.";
    default:
      return error || null;
  }
};

// synchronize login between tabs
const syncLoginCallback = (event: StorageEvent) => {
  if (event.key === LocalStorage.keys.LOGGED_IN && event.newValue === "true") {
    location.reload();
  }
};

export const syncLoginWatchLocalStorage = () => {
  window.addEventListener("storage", syncLoginCallback);
};

export const syncLoginRemoveWatcher = () => {
  window.removeEventListener("storage", syncLoginCallback);
};

export const syncLoginSaveToLocalStorage = () => {
  LocalStorage.setItem(LocalStorage.keys.LOGGED_IN, "true");
};

export const setFreelancerTimezoneUpdated = () => {
  SessionStorage.setItem(
    SessionStorage.keys.FREELANCER_TIMEZONE_UPDATED,
    "true",
  );
};

export const getIsFreelancerTimezoneUpdated = () => {
  return Boolean(
    SessionStorage.getItem(SessionStorage.keys.FREELANCER_TIMEZONE_UPDATED),
  );
};
