import React from "react";

import {
  Button,
  Tooltip,
  Typography,
} from "@hexocean/braintrust-ui-components";

type WithdrawalButtonProps = {
  onClick: () => void;
  disabled: boolean;
  disabledReason?: string | null;
};

export const WithdrawalButton = ({
  onClick,
  disabled,
  disabledReason,
}: WithdrawalButtonProps) => {
  return (
    <Tooltip
      data-testid="withdrawal-button-tooltip"
      disabled={!disabled}
      title={
        <Typography component="p" style={{ maxWidth: 220 }}>
          {disabledReason}
        </Typography>
      }
    >
      <span style={{ cursor: "pointer" }}>
        <Button
          onClick={onClick}
          disabled={disabled}
          size="medium"
          variant="white-grey"
          shape="squared"
        >
          Transfer
        </Button>
      </span>
    </Tooltip>
  );
};
