import { useEffect, useMemo, useState } from "react";
import classNames from "classnames";
import _ from "underscore";

import {
  Box,
  Button,
  Divider,
  IconButton,
  Typography,
} from "@hexocean/braintrust-ui-components";
import { CloseIcon, EditPen } from "@hexocean/braintrust-ui-components/Icons";
import { ModalInstance } from "@js/components/modal";
import { Snackbar } from "@js/components/snackbar";
import { SortableList } from "@js/components/sortable-list/motion";
import type { SpaceRule } from "@js/types/spaces";

import { useGetSpaceRulesQuery, useReorderSpaceRulesMutation } from "../../api";

import { MAX_NUMBER_OF_RULES } from "./constants";
import { openCreateRuleModal } from "./create-rule-modal";
import { openDeleteRuleModal } from "./delete-rule-modal";
import { openEditRuleModal } from "./edit-rule-modal";

import style from "./style.module.scss";

type SortableRuleListProps = {
  spaceId: number;
};

const SortableRuleList = ({ spaceId }: SortableRuleListProps) => {
  const { data: rules } = useGetSpaceRulesQuery({
    id: spaceId,
  });
  // we need to have a duplicated rules state so the sortable list can play with it
  const [sortableRules, setSortableRules] = useState<SpaceRule[]>();
  const [reorderSpaceRulesMutation] = useReorderSpaceRulesMutation();

  const handleCreateRuleClick = () => {
    if (!rules) {
      return;
    }
    openCreateRuleModal({
      ruleNumber: rules.length + 1,
      spaceId,
      onClose: () => openSortableRuleListModal(spaceId),
    });
  };

  const handleMaxNumberOfRulesLimitReached = () => {
    Snackbar.error(`Max ${MAX_NUMBER_OF_RULES} rules are allowed`);
  };

  const debouncedReorderSpaceRulesMutation = useMemo(
    () =>
      _.debounce(
        (reorderedRules: SpaceRule[]) =>
          reorderSpaceRulesMutation({
            spaceId,
            rulesIds: reorderedRules.map((rule) => rule.id),
          }),
        750,
      ),
    [reorderSpaceRulesMutation, spaceId],
  );

  const handleReorder = (reorderedRules: SpaceRule[]) => {
    setSortableRules(reorderedRules);
    debouncedReorderSpaceRulesMutation(reorderedRules);
  };

  useEffect(() => {
    if (!rules) {
      return;
    }
    setSortableRules(rules);
  }, [rules]);

  if (!sortableRules) {
    return <></>;
  }

  const hasReachedRulesLimit = sortableRules.length >= MAX_NUMBER_OF_RULES;

  return (
    <Box className={style.container}>
      <Box className={style.ruleTitleWrapper}>
        <Typography component="p" variant="paragraph" size="large" mb={4}>
          Rules for this space
        </Typography>
      </Box>

      <SortableList<SpaceRule>
        role="list"
        items={sortableRules}
        onReorder={handleReorder}
        className={classNames(style.ruleList, style.ruleListLargeGap)}
      >
        {(rule, index) => {
          return (
            <SortableRuleListItem
              spaceId={spaceId}
              rule={rule}
              key={rule.id}
              ruleNumber={index + 1}
            />
          );
        }}
      </SortableList>

      <Button
        onClick={
          hasReachedRulesLimit
            ? handleMaxNumberOfRulesLimitReached
            : handleCreateRuleClick
        }
        className={style.ruleListButton}
        variant="off-white-grey-no-border"
      >
        Add a rule
      </Button>
    </Box>
  );
};

type SortableRuleListItemProps = {
  spaceId: number;
  rule: SpaceRule;
  ruleNumber: number;
};

const SortableRuleListItem = ({
  spaceId,
  rule,
  ruleNumber,
}: SortableRuleListItemProps) => {
  const { title, description } = rule;

  const handleEditRuleClick = () => {
    openEditRuleModal({
      rule,
      ruleNumber,
      spaceId,
      onClose: () => openSortableRuleListModal(spaceId),
    });
  };

  const handleDeleteRuleClick = () => {
    openDeleteRuleModal({
      ruleId: rule.id,
      ruleNumber,
      spaceId,
      onClose: () => openSortableRuleListModal(spaceId),
    });
  };

  return (
    <>
      <Box className={style.sortableRule} component="li">
        <Box>
          <Typography component="p" variant="label" multilineEllipsis={2}>
            {title}
          </Typography>

          <Typography component="p" variant="paragraph" multilineEllipsis={2}>
            {description}
          </Typography>
        </Box>

        <Box className={style.sortableRuleButtonsWrapper}>
          <IconButton
            onClick={handleEditRuleClick}
            variant="tertiary"
            size="x-small"
            aria-label="edit rule"
            children={<EditPen />}
          />

          <IconButton
            onClick={handleDeleteRuleClick}
            variant="tertiary"
            size="x-small"
            aria-label="delete rule"
            children={<CloseIcon />}
          />
        </Box>
      </Box>
      <Divider color="light-grey" className={style.divider} />
    </>
  );
};

const openSortableRuleListModal = (spaceId: number) => {
  ModalInstance.open({
    className: style.ruleListModal,
    padding: false,
    closeButton: true,
    closeButtonProps: { variant: "tertiary", size: "x-small" },
    children: <SortableRuleList spaceId={spaceId} />,
  });
};

export { openSortableRuleListModal };
