import * as actionTypes from "@js/apps/messenger/action-types";
import { Modal } from "@js/components/modal";

import type { MessengerModalProps } from "./types";

export const GenericMessengerModalInstance = Modal("generic-messenger-modal");

export const openGenericMessenger = (params: MessengerModalProps) => {
  return {
    type: actionTypes.OPEN_MESSENGER_MODAL,
    payload: params,
  };
};

export const closeGenericMessenger = () => {
  return {
    type: actionTypes.CLOSE_MESSENGER_MODAL,
  };
};
