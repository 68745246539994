import { ChainId, Token } from "@uniswap/sdk";
import type { AbstractConnector } from "@web3-react/abstract-connector";

// get abis and addresses from copied <deployments githb files BTRST contracts>
import { BTRST as BTRSTS } from "../abi";
import {
  fortmatic,
  injected,
  isLocalhostNetwork,
  portis,
  SupportedChainId,
  walletconnect,
  walletlink,
} from "../connectors";
// deployed Ropsten Contract Addresses
const BTRST_ADDRESS = BTRSTS.address;

export const BTRST: { [chainId in SupportedChainId]: Token } = {
  [SupportedChainId.MAINNET]: new Token(
    ChainId.MAINNET,
    BTRST_ADDRESS,
    18,
    "BTRST",
    "BTRST",
  ),
  [SupportedChainId.ROPSTEN]: new Token(
    ChainId.ROPSTEN,
    BTRST_ADDRESS,
    18,
    "BTRST",
    "BTRST",
  ),
  [SupportedChainId.GÖRLI]: new Token(
    ChainId.GÖRLI,
    BTRST_ADDRESS,
    18,
    "BTRST",
    "BTRST",
  ),
  [SupportedChainId.LOCALHOST]: new Token(
    SupportedChainId.LOCALHOST as any,
    BTRST_ADDRESS,
    18,
    "BTRST",
    "BTRST",
  ),
};

export const NETWORK_NAME: { [chainId in ChainId | SupportedChainId]: string } =
  {
    1: "Mainnet",
    3: "Ropsten",
    4: "Rinkeby",
    5: "Görli",
    42: "Kovan",
    1337: "Localhost",
  };

type WalletInfo = {
  connector?: AbstractConnector;
  name: string;
  iconName: string;
  description: string;
  href: string | null;
  color: string;
  primary?: true;
  mobile?: true;
  mobileOnly?: true;
  disabled?: boolean;
};

export const SUPPORTED_WALLETS: { [key: string]: WalletInfo } = {
  INJECTED: {
    connector: injected,
    name: "Injected",
    iconName: "arrow-right.svg",
    description: "Injected web3 provider.",
    href: null,
    color: "#010101",
    primary: true,
    disabled: true,
  },
  METAMASK: {
    connector: injected,
    name: "MetaMask",
    iconName: "metamask.png",
    description: "Easy-to-use browser extension.",
    href: null,
    color: "#E8831D",
    disabled: false,
  },
  WALLET_CONNECT: {
    connector: walletconnect,
    name: "WalletConnect",
    iconName: "walletConnectIcon.svg",
    description: "Connect to Trust Wallet, Rainbow Wallet and more...",
    href: null,
    color: "#4196FC",
    mobile: true,
    disabled: false,
  },
  WALLET_LINK: {
    connector: walletlink,
    name: "Coinbase Wallet",
    iconName: "coinbaseWalletIcon.svg",
    description: "Use Coinbase Wallet app on mobile device",
    href: null,
    color: "#315CF5",
    disabled: false,
  },
  COINBASE_LINK: {
    name: "Open in Coinbase Wallet",
    iconName: "coinbaseWalletIcon.svg",
    description: "Open in Coinbase Wallet app.",
    href: "https://go.cb-w.com/mtUDhEZPy1",
    color: "#315CF5",
    mobile: true,
    mobileOnly: true,
    disabled: true,
  },
  Portis: {
    connector: portis,
    name: "Portis",
    iconName: "portisIcon.png",
    description: "Login using Portis hosted wallet",
    href: null,
    color: "#4A6C9B",
    mobile: true,
    disabled: false || isLocalhostNetwork,
  },
  FORTMATIC: {
    connector: fortmatic,
    name: "Fortmatic",
    iconName: "fortmaticIcon.png",
    description: "Login using Fortmatic hosted wallet",
    href: null,
    color: "#6748FF",
    mobile: true,
    disabled: true,
  },
};

export const NetworkContextName = "NETWORK";
