import { SubmissionError } from "redux-form";
import axios from "axios";

import type { WithRecaptchaCode } from "@js/types/common";

import type {
  PhoneVerificationFormValues,
  TwoFaCode,
} from "./phone-verification-form";

export const onboardingSendPhoneVerificationCode = (
  values: WithRecaptchaCode<PhoneVerificationFormValues["phone_number"]>,
) => {
  return new Promise((resolve, reject) =>
    axios
      .post(`/api/phone_numbers/send_verification_code/`, values)
      .then((response) => resolve(response.data))
      .catch((error) => {
        const errors = error.response.data;

        if (errors && "country_iso2" in errors) {
          errors.phone_number = "Country code is not valid.";
        }

        reject(new SubmissionError(errors));
      }),
  );
};

export const onboardingCheckPhoneVerificationCode = (
  data: {
    verification_code: string;
    is_backup_code?: boolean;
  } & TwoFaCode &
    PhoneVerificationFormValues["phone_number"],
) => {
  return new Promise((resolve, reject) => {
    return axios
      .post(`/api/phone_numbers/`, data)
      .then((response) => resolve(response.data))
      .catch((error) => reject(new SubmissionError(error.response.data)));
  });
};

export const mapPhoneVerificationErrors = (error) => {
  const mainErrorObj = error.errors;
  if (mainErrorObj?.code && mainErrorObj.code[0].includes("password")) {
    return { password: mainErrorObj.code[0], _error: "Submitting failed !" };
  }
  if (mainErrorObj?.code)
    return { code: mainErrorObj.code[0], _error: "Submitting failed !" };
  if (mainErrorObj?.verification_code)
    return {
      verification_code: mainErrorObj.verification_code[0],
      _error: "Submitting failed !",
    };
  if (mainErrorObj?.phone_number)
    return {
      code: mainErrorObj.phone_number[0],
      _error: "Submitting failed !",
    };

  return { _error: "" };
};
