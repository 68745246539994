import GghCelebratoryBackground from "@static/give-get-help/ggh-celebratory-background.svg";

import { Box } from "@hexocean/braintrust-ui-components";

import styles from "./styles.module.scss";

export const SocialProofPostBackground = ({
  celebratoryBackground,
  backgroundColor,
}) => {
  return (
    <>
      <GghCelebratoryBackground
        color={celebratoryBackground}
        className={styles.celebratoryBackground}
      />
      <Box
        className={styles.verticalGradient}
        style={{
          background: `linear-gradient(270deg, ${backgroundColor} 0%, rgba(249, 246, 255, 0) 85%)`,
        }}
      />
      <Box className={styles.horizontalGradient} />
    </>
  );
};
