import { useRef } from "react";
import { useCallback, useEffect, useState } from "react";
import { isEqual } from "underscore";

import {
  fetchEmployerInvoices,
  fetchEmployerInvoicesSummary,
} from "@js/apps/invoices/actions";
import { useEmployerListingParams } from "@js/apps/invoices/forms";
import { Snackbar } from "@js/components/snackbar";
import { useAppDispatch, useAppSelector } from "@js/hooks";
import type { EmployerInvoice, Invoice } from "@js/types/invoices";

import { useLazyGetAllEmployerInvoicesIdsQuery } from "../../api";
import type { EmployerListingParamsValues } from "../../types";

export const useEmployerInvoiceListing = () => {
  const dispatch = useAppDispatch();
  const { filters, areFiltersDefault } = useEmployerListingParams();
  const prevFilters = useRef(filters);

  const invoicesSummary = useAppSelector(
    (state) => state.invoices.invoicesSummary,
  );
  const invoiceList = useAppSelector(
    (state) => state.invoices.invoiceList,
  ) as EmployerInvoice[];

  const total = useAppSelector((state) => state.invoices.total);
  const loading = useAppSelector(
    (state) =>
      state.invoices.fetchingInvoiceList || state.freelancer.fetchingJobList,
  );

  const fetchInvoices = useCallback(() => {
    dispatch(fetchEmployerInvoicesSummary());
    dispatch(fetchEmployerInvoices(filters));
  }, [dispatch, filters]);

  useEffect(() => {
    fetchInvoices();
  }, [fetchInvoices]);

  const {
    onClick,
    selectedInvoicesIds,
    setSelectedInvoicesIds,
    onSelectAcrossAllPages,
    checkIfItemIsSelected,
    resetSelectedInvoicesIds,
    toggleSelectCurrentPage,
  } = useSelectInvoiceListingItems({ invoiceList, filters });

  useEffect(() => {
    const { page: prevPage, ...restPrevFilters } = prevFilters?.current || {};
    const { page, ...restFilters } = filters;

    if (!isEqual(restPrevFilters, restFilters)) {
      resetSelectedInvoicesIds();
    }

    prevFilters.current = filters;
  }, [filters, resetSelectedInvoicesIds]);

  return {
    invoicesSummary,
    invoiceList,
    total,
    loading,
    fetchInvoices,
    areFiltersDefault,
    page: filters.page,
    onSelectItem: onClick,
    toggleSelectCurrentPage,
    selectedInvoicesIds,
    setSelectedInvoicesIds,
    onSelectAcrossAllPagesClick: onSelectAcrossAllPages,
    checkIfItemIsSelected,
    resetSelectedInvoicesIds,
  };
};

type UseSelectInvoiceListingItemArg = {
  invoiceList: Invoice[];
  filters: Partial<EmployerListingParamsValues>;
};

const useSelectInvoiceListingItems = ({
  invoiceList,
  filters,
}: UseSelectInvoiceListingItemArg) => {
  const [selectedInvoicesIds, setSelectedInvoicesIds] = useState<number[]>([]);
  const [getAllEmployerInvoicesIds] = useLazyGetAllEmployerInvoicesIdsQuery();

  const onClick = (id: number) => {
    const itemExists = selectedInvoicesIds.some((item) => item === id);

    setSelectedInvoicesIds((prev) => {
      if (itemExists) {
        return prev.filter((item) => item !== id);
      }
      return [...prev, id];
    });
  };

  const toggleSelectCurrentPage = (select: boolean) => {
    setSelectedInvoicesIds((prev) => {
      const invoicesIdsOnCurrentPage = invoiceList.map(({ id }) => id);
      return select
        ? [...prev, ...invoicesIdsOnCurrentPage]
        : prev.filter((item) => !invoicesIdsOnCurrentPage.includes(item));
    });
  };

  const checkIfItemIsSelected = (id: number) => {
    return selectedInvoicesIds.includes(id);
  };

  const onSelectAcrossAllPages = async (mode: "select" | "unselect") => {
    if (mode === "unselect") {
      return setSelectedInvoicesIds([]);
    }

    const allInvoicesIds = await getAllEmployerInvoicesIds(filters, true);
    if (!allInvoicesIds.data) {
      Snackbar.error("Something went wrong! Please try again.");

      return;
    }

    setSelectedInvoicesIds(allInvoicesIds.data);
  };

  const resetSelectedInvoicesIds = useCallback(() => {
    setSelectedInvoicesIds([]);
  }, []);

  return {
    onClick,
    toggleSelectCurrentPage,
    selectedInvoicesIds,
    setSelectedInvoicesIds,
    onSelectAcrossAllPages,
    checkIfItemIsSelected,
    resetSelectedInvoicesIds,
  };
};
