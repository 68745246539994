import { Button } from "@hexocean/braintrust-ui-components";
import { RouterLink } from "@js/components/link";
import { useCurrentPathEncoded } from "@js/hooks";

import styles from "../styles.module.scss";

type CreateInvoiceButtonsProps = {
  canFreelancerCopyLatestInvoice?: boolean;
  copyFreelancerLatestInvoiceId?: number;
};

export const CreateInvoiceButtons = ({
  canFreelancerCopyLatestInvoice,
  copyFreelancerLatestInvoiceId,
}: CreateInvoiceButtonsProps) => {
  const location = useCurrentPathEncoded();

  return (
    <div className={styles.buttonsContainer}>
      {canFreelancerCopyLatestInvoice && (
        <Button
          to={`/talent/invoices/add_new/?prevPath=${location}&copy=${copyFreelancerLatestInvoiceId}`}
          variant="secondary"
          RouterLink={RouterLink}
        >
          Copy last Invoice
        </Button>
      )}
      <Button
        to={`/talent/invoices/add_new/?prevPath=${location}`}
        variant="primary"
        RouterLink={RouterLink}
      >
        Create Invoice
      </Button>
    </div>
  );
};
