import React from "react";

export default ({ countryCode }: { countryCode: string }) => {
  const flag = countryCode || "international";

  return (
    <img
      src={`${SETTINGS.STATIC_URL}flags/${flag.toLowerCase()}.svg`}
      alt={flag.toUpperCase()}
    />
  );
};
