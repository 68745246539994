import { Divider, Typography } from "@hexocean/braintrust-ui-components";

import { useGetTemplatesEntriesQuery } from "../../api";
import { ConnectorContainer } from "../connector-container";

import { TemplatesList } from "./templates-list";

export const Templates = () => {
  const { data: templates, isLoading } = useGetTemplatesEntriesQuery();
  return (
    <ConnectorContainer title="Templates" isLoading={isLoading}>
      {!!templates && (
        <>
          <Typography
            component="p"
            variant="label"
            size="large"
            fontWeight={400}
            sx={{ mb: 2, mt: 4 }}
          >
            Here are some sample messages for reaching out to contacts. Feel
            free to edit as you please, and keep in mind, we’ve found that{" "}
            <strong>more casual</strong> tends to work better.
          </Typography>
          <Divider color="primary" />
          <TemplatesList items={templates} />
        </>
      )}
    </ConnectorContainer>
  );
};
