export const WALLEC_ACTIVITY_VALUES = {
  ALL: "all",
  PENDING: "pending",
  DEBIT: ENUMS.WalletActivityCategory.DEBIT,
  CREDIT: ENUMS.WalletActivityCategory.CREDIT,
} as const;

export type WalletActivityFiltersOptionValue = ObjectValues<
  typeof WALLEC_ACTIVITY_VALUES
>;

export type WalletActivityFiltersOption = {
  value: WalletActivityFiltersOptionValue;
  label: string;
};

export const walletActivityFiltersOptions: Array<WalletActivityFiltersOption> =
  [
    { value: WALLEC_ACTIVITY_VALUES.ALL, label: "All" },
    { value: WALLEC_ACTIVITY_VALUES.CREDIT, label: "Receive" },
    { value: WALLEC_ACTIVITY_VALUES.DEBIT, label: "Send" },
    { value: WALLEC_ACTIVITY_VALUES.PENDING, label: "Pending" },
  ];
