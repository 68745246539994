import React from "react";

import { ALL_OFFERS } from "@js/apps/freelancer/views/dashboard/help-offers/filters";
import { HELP_OFFERS_1_1_FILTER_STATUS_FORM_ID } from "@js/apps/freelancer/views/dashboard/help-offers/my-help-offers-listing";
import { useAppSelector } from "@js/hooks";

import { HelpOfferList } from "./help-offers-list";

const useGetCurrentStatusFilter = () => {
  const statusFilterForm = useAppSelector(
    (state) => state.form?.[HELP_OFFERS_1_1_FILTER_STATUS_FORM_ID],
  );

  return statusFilterForm?.values?.status !== ALL_OFFERS
    ? statusFilterForm?.values?.status
    : undefined;
};

export const FreelancerDashboardGettingHelpOffersPage = () => {
  const status = useGetCurrentStatusFilter();

  return (
    <HelpOfferList
      direction={ENUMS.HelpOfferDirectionFilter.RECEIVED}
      status={status}
    />
  );
};

export const FreelancerDashboardGivingHelpOffersPage = () => {
  const status = useGetCurrentStatusFilter();

  return (
    <HelpOfferList
      direction={ENUMS.HelpOfferDirectionFilter.SENT}
      status={status}
    />
  );
};
