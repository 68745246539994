import React from "react";

import type { ATSIntegration } from "@js/types/employer";

import { ATSIntegrationConnectedSectionContainer } from "../ats-integration-connected-section-container";

import { IntegrationErrors } from "./integration-errors/index";
import { MyIntegrationItem } from "./my-integration-item";

type MyIntegrationSectionProps = { integration: ATSIntegration };

export const MyIntegrationSection = ({
  integration,
}: MyIntegrationSectionProps) => {
  return (
    <ATSIntegrationConnectedSectionContainer title="My integration">
      <IntegrationErrors integrationId={integration.id} />
      <MyIntegrationItem integration={integration} />
    </ATSIntegrationConnectedSectionContainer>
  );
};
