import { z } from "zod";

import { openDecoratedSuccessSnackbar } from "@js/components/decorated-success-snackbar";
import { Snackbar } from "@js/components/snackbar";

import {
  useGetEmployerTeamMembersQuery,
  useShareTalentWithEmployerTeamMemberInvitationsMutation,
  useShareTalentWithEmployerTeamMembersMutation,
} from "../../api";
import {
  closeCommonTeamMembersModal,
  openCommonTeamMembersModal,
} from "../common-team-members-modal";

import { ShareTalentWithTeamMembersModalContent } from "./share-talent-with-team-members-modal-content";

type ShareTalentWithTeamMembersModalProps = {
  talentId: number;
};

export const ShareTalentWithTeamMembersModal = ({
  talentId,
}: ShareTalentWithTeamMembersModalProps) => {
  const { data: teamMembers, isLoading: isLoadingTeamMembers } =
    useGetEmployerTeamMembersQuery();
  const [
    shareTalentWithEmployerTeamMemberInvitations,
    { isLoading: isSharingWithInvitations },
  ] = useShareTalentWithEmployerTeamMemberInvitationsMutation();
  const [
    shareTalentWithEmployerTeamMembers,
    { isLoading: isSharingWithTeamMembers },
  ] = useShareTalentWithEmployerTeamMembersMutation();

  const handleShareTalent = async ({
    emailInvitations,
    teamMemberIds,
    message,
  }: {
    emailInvitations: string[];
    teamMemberIds: number[];
    message?: string;
  }) => {
    if (!emailInvitations.length && !teamMemberIds.length) {
      return closeShareTalentWithTeamMembersModal();
    }

    try {
      if (emailInvitations.length) {
        await shareTalentWithEmployerTeamMemberInvitations({
          talent: talentId,
          emails: emailInvitations,
          message,
        }).unwrap();
      }

      if (teamMemberIds.length) {
        await shareTalentWithEmployerTeamMembers({
          talent: talentId,
          team_members: teamMemberIds,
          message,
        }).unwrap();
      }

      handleShareTalentSuccess();

      closeShareTalentWithTeamMembersModal();
    } catch (error) {
      handleShareTalentWithTeamMemberInvitations(error);
    }
  };

  const isLoading = isLoadingTeamMembers;
  const isSaving = isSharingWithInvitations || isSharingWithTeamMembers;
  return (
    <ShareTalentWithTeamMembersModalContent
      isLoading={isLoading}
      isSaving={isSaving}
      onSave={handleShareTalent}
      teamMembers={teamMembers}
    />
  );
};

export const openShareTalentWithTeamMembersModal = (
  arg: ShareTalentWithTeamMembersModalProps,
) => {
  openCommonTeamMembersModal({
    children: <ShareTalentWithTeamMembersModal {...arg} />,
  });
};

export const closeShareTalentWithTeamMembersModal = () => {
  closeCommonTeamMembersModal();
};

const handleShareTalentSuccess = () => {
  openDecoratedSuccessSnackbar("Shared talent profile with your team");
};

const shareTalentInvitationsError = z.object({
  data: z.object({ emails: z.object({ email: z.string() }) }),
});

const handleShareTalentWithTeamMemberInvitations = (error: unknown) => {
  const parsedError = shareTalentInvitationsError.safeParse(error);
  if (!parsedError.success) {
    Snackbar.error("Something went wrong!");

    return;
  }

  Snackbar.error(parsedError.data.data.emails.email);
};
