export type IsoYear = `${number}${number}${number}${number}`;
export type IsoMonth = `${number}${number}`;
export type IsoDay = `${number}${number}`;
export type IsoHours = `${number}${number}`;
export type IsoMinutes = `${number}${number}`;
export type IsoSeconds = `${number}${number}`;
export type IsoMilliseconds = `${number}${number}${number}`;

export type IsoDate = `${IsoYear}-${IsoMonth}-${IsoDay}`;

export type IsoDateIsoTime =
  `${IsoHours}:${IsoMinutes}:${IsoSeconds}.${IsoMilliseconds}`;

export type IsoDateTime = `${IsoDate}T${IsoDateIsoTime}Z`;

export type IsoDateOrIsoDateTime = IsoDate | IsoDateTime;

export type DateOffset = number | string;

export enum DateTimeFormats {
  "1970-12-31T00:00:00Z" = "YYYY-MM-DDTHH:mm:ssZ",
  "1970-12-31T0:00:00Z" = "YYYY-MM-DDTH:mm:ssZ",
  "1970-12-31 0:00:00 PM" = "YYYY-MM-DD h:mm:ss A",
  "January 1st 1970, 0:00:00 pm" = "MMMM Do YYYY, h:mm:ss a",
  "1970-12-31 23:59" = "YYYY-MM-DD HH:mm", // ex. 1970-12-31 00:00
  "12-31-1970 23:59:59" = "MM-DD-YYYY HH:mm:ss", // ex. 12-31-1970 00:00:00
  "Jan 01 1970 0:00 AM" = "MMM DD, YYYY h:mm A",
  "January 1, 1970 at 0:00 am" = "MMMM D, YYYY [at] h:mm a",
}

export enum DateFormats {
  yearFourDigits = "YYYY",
  monthTwoDigits = "MM",
  dayOfTheMonth = "D",
  dayOfTheMonthTwoDigits = "DD",
  "Jan" = "MMM",
  "January" = "MMMM",
  "1970-12-31" = "YYYY-MM-DD", // ex. 1970-01-01
  "January 1, 1970" = "MMMM D, YYYY",
  "Jan 1, 1970" = "MMM D, YYYY",
  "Jan 1970" = "MMM YYYY",
  "January 1970" = "MMMM YYYY",
  "January 01" = "MMMM DD",
  "12-31-1970" = "MM-DD-YYYY", // ex. 01-01-1970
  "12/31/70" = "MM/DD/YY", // ex. 01/01/70
  "January, 1970" = "MMMM, YYYY",
  "January 1st, 1970" = "MMMM Do, YYYY",
  "12/31/1970" = "MM/DD/YYYY", // ex. 01/01/1970
  "31/12/1970" = "DD/MM/YYYY", // ex. 01/01/1970
  "1/1/1970" = "D/M/YYYY", // ex. 31/12/1970
}

export type DateFormatsName = keyof typeof DateFormats;
export type DateFormatsValue = `${DateFormats}`;

export type TimeFormatsName = keyof typeof DateTimeFormats;
export type TimeFormatsValue = `${DateTimeFormats}`;
