import { useCallback } from "react";

import { useAppDispatch, useAppSelector } from "@js/hooks";

import {
  deselectJob,
  getSelectedATSJobsIds,
  selectJob,
} from "../../ats-jobs-slice";

export const useSelectATSJob = () => {
  const dispatch = useAppDispatch();
  const selectedATSJobsIds = useAppSelector(getSelectedATSJobsIds);

  const checkIfAnyJobIsSelected = useCallback(
    (id: string) => {
      return selectedATSJobsIds?.some((selectedId) => selectedId === id);
    },
    [selectedATSJobsIds],
  );

  const deselectATSJob = useCallback(
    (id: string) => dispatch(deselectJob(id)),
    [dispatch],
  );

  const selectATSJob = useCallback(
    (id: string) => dispatch(selectJob(id)),
    [dispatch],
  );

  const toggleSelect = useCallback(
    (id: string) => {
      if (checkIfAnyJobIsSelected(id)) {
        return deselectATSJob(id);
      }

      return selectATSJob(id);
    },
    [deselectATSJob, selectATSJob, checkIfAnyJobIsSelected],
  );

  return {
    toggleSelect,
    checkIfAnyJobIsSelected,
    selectedATSJobs: selectedATSJobsIds,
  };
};
