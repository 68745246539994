import { Field, Fields } from "redux-form";

import {
  JOB_PAYMENT_TYPE_LABELS,
  OFFER_FIELDS,
} from "@js/apps/jobs/apps/offers/constants";
import type { CreateOrEditOfferFormData } from "@js/apps/jobs/apps/offers/types";
import { getPaymentMethodContent } from "@js/apps/jobs/apps/offers/utils";
import { ReviewBeforeSendingSummaryItem } from "@js/apps/jobs/components";
import { Date } from "@js/components/date";
import type { TypedWrappedFieldInputProps } from "@js/forms/utils";
import { formatCurrency } from "@js/utils";
import type { IsoDate } from "@js/utils/date/types";
import { DateFormats } from "@js/utils/date/types";

export const PayFieldItem = () => {
  return (
    <Fields
      names={[OFFER_FIELDS.payment_amount, OFFER_FIELDS.payment_type]}
      component={Pay}
    />
  );
};

export const DatesFieldItem = () => {
  return (
    <Fields
      names={[OFFER_FIELDS.proposed_start_date, OFFER_FIELDS.proposed_end_date]}
      component={Dates}
    />
  );
};

export const CommitmentFieldItem = () => {
  return (
    <Field
      name={OFFER_FIELDS.anticipated_weekly_hours}
      component={Commitment}
    />
  );
};

export const EquipmentSummaryFieldItem = () => {
  return (
    <Field
      name={OFFER_FIELDS.talent_has_own_equipment}
      component={EquipmentSummary}
    />
  );
};

export const AdditionalPaperworkFieldItem = () => {
  return (
    <Field
      name={OFFER_FIELDS.require_paperwork}
      component={AdditionalPaperwork}
    />
  );
};

export const PaymentMethodFieldItem = () => {
  return (
    <Field
      name={OFFER_FIELDS.deposit_payment_method}
      component={PaymentMethod}
    />
  );
};

type FieldProps<K extends keyof CreateOrEditOfferFormData> = {
  input: TypedWrappedFieldInputProps<CreateOrEditOfferFormData[K]>;
};

const EquipmentSummary = ({
  input,
}: FieldProps<"talent_has_own_equipment">) => {
  return (
    <ReviewBeforeSendingSummaryItem
      sectionName="Equipment summary"
      sectionContent={
        input.value
          ? "Talent will use their own equipment"
          : "Some equipment will be provided"
      }
    />
  );
};

const AdditionalPaperwork = ({ input }: FieldProps<"require_paperwork">) => {
  return (
    <ReviewBeforeSendingSummaryItem
      sectionName="Additional Paperwork"
      sectionContent={input.value ? "Yes" : "No"}
    />
  );
};

type PayProps = {
  payment_amount: FieldProps<"payment_amount">;
  payment_type: FieldProps<"payment_type">;
};

const Pay = ({ payment_amount, payment_type }: PayProps) => {
  return (
    <ReviewBeforeSendingSummaryItem
      sectionName="Pay"
      sectionContent={`${formatCurrency(payment_amount.input.value)} ${
        JOB_PAYMENT_TYPE_LABELS[payment_type.input.value]
      }`}
    />
  );
};

type DatesProps = {
  proposed_start_date: FieldProps<"proposed_start_date">;
  proposed_end_date: FieldProps<"proposed_end_date">;
};

const Dates = ({ proposed_start_date, proposed_end_date }: DatesProps) => {
  const startDate = proposed_start_date.input.value as IsoDate;

  const endDate = proposed_end_date.input.value as IsoDate;

  const content = (
    <>
      {startDate && (
        <>
          Starts <Date date={startDate} format={DateFormats["Jan 1, 1970"]} />,{" "}
        </>
      )}
      {endDate && (
        <>
          Ends <Date date={endDate} format={DateFormats["Jan 1, 1970"]} />
        </>
      )}
    </>
  );

  return (
    <ReviewBeforeSendingSummaryItem
      sectionName="Dates"
      sectionContent={content}
    />
  );
};

const Commitment = ({ input }: FieldProps<"anticipated_weekly_hours">) => {
  return (
    <ReviewBeforeSendingSummaryItem
      sectionName="Commitment"
      sectionContent={`${input.value} hours per week`}
    />
  );
};

const PaymentMethod = ({ input }: FieldProps<"deposit_payment_method">) => {
  if (!input.value) return null;

  const content = getPaymentMethodContent(input.value);

  return (
    <ReviewBeforeSendingSummaryItem
      sectionName="$500 Deposit details"
      sectionContent={content}
    />
  );
};
