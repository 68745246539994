import { useParams } from "react-router-dom";

import { Button, Typography } from "@hexocean/braintrust-ui-components";
import { ModalInstance } from "@js/components/modal";

import { NftShareList } from "../share-list";

export const openShareModal = () =>
  ModalInstance.open({
    closeButton: false,
    className: "nft-share-modal",
    children: <ShareModalContent />,
  });

const ShareModalContent = () => {
  const { tokenId } = useParams();
  const _tokenId = Number(tokenId);

  return (
    <>
      <Typography variant="title" component="h2" fontWeight={400} mb={7}>
        Share your NFT
      </Typography>
      <NftShareList tokenId={_tokenId} />
      <Button
        variant="transparent"
        onClick={() => ModalInstance.close()}
        className="nft-share-modal__btn-close"
      >
        Close
      </Button>
    </>
  );
};
