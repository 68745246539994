import { useState } from "react";

import { Snackbar } from "@js/components/snackbar";
import type { Invoice } from "@js/types/invoices";

import {
  markEmployerInvoicesAsPaid,
  markFreelancerInvoicesAsPaid,
} from "../../actions";

type UseInvoicePreviewProps = {
  invoice: Invoice;
  invoiceEndpoint: "freelancer_invoices" | "employer_invoices";
  markInvoiceAsPaidCallback: () => void;
};

export const useInvoicePreview = ({
  invoice,
  invoiceEndpoint,
  markInvoiceAsPaidCallback,
}: UseInvoicePreviewProps) => {
  const [isLoading, setIsLoading] = useState(true);

  const handleDocumentLoadSuccess = () => {
    setIsLoading(false);
  };

  const markAsPaid = () => {
    const markAsPaidAction =
      invoiceEndpoint === "freelancer_invoices"
        ? markFreelancerInvoicesAsPaid
        : markEmployerInvoicesAsPaid;

    return markAsPaidAction([invoice.id])
      .then(() => markInvoiceAsPaidCallback())
      .catch((error) => Snackbar.error(error));
  };

  return { isLoading, handleDocumentLoadSuccess, markAsPaid };
};
