export const enumToOptions = <
  EnumObject extends Record<string, string>,
  Keys extends keyof EnumObject,
>(
  labels: EnumObject,
): { value: Keys; label: EnumObject[Keys] }[] => {
  const keys = Object.keys(labels) as unknown as Keys[];

  const arr = keys.map((key) => ({
    label: labels[key],
    value: key,
  }));

  return arr;
};

export const enumArrayToOptions = (enumObj) => {
  return enumObj.map((en) => ({ value: en[0], label: en[1] }));
};

export const enumToArray = (
  enumObj: Record<string, string>,
): Array<[string, string]> => {
  const arr = Object.keys(enumObj).map(
    (key) => [key, enumObj[key]] as [string, string],
  );
  return arr;
};

export const getEnumLabel = <
  EnumObj extends Record<string, string>,
  Value extends keyof EnumObj,
>(
  enumObj: EnumObj,
  value: Value,
) => {
  if (enumObj[value]) return enumObj[value] as EnumObj[keyof EnumObj];
  return "";
};

export const countrySelectOptions = SETTINGS.COUNTRY_ISO2_TO_COUNTRY_DATA.map(
  (country) => ({
    value: country.code,
    label: country.name,
  }),
);
