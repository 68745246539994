import React, { useCallback } from "react";

import {
  Button,
  IconButton,
  Loader,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@hexocean/braintrust-ui-components";
import { CloseIcon } from "@hexocean/braintrust-ui-components/Icons";
import {
  addRecipientOnboardingLink,
  removeRecipientOnboardingLink,
} from "@js/apps/withdrawal/actions";
import {
  useCreateDashboardLinkMutation,
  useCreateOnboardingLinkMutation,
} from "@js/apps/withdrawal/api/api";
import { AddRecipientsModal } from "@js/apps/withdrawal/components/add-recipients/recipients-listing/recipients-modal";
import { Snackbar } from "@js/components/snackbar";
import { useAppDispatch, useAppSelector } from "@js/hooks";
import { typeGuard } from "@js/utils";

import "./style.scss";

const ViewDashboardButton = ({
  id,
  disabled,
}: {
  id: string;
  disabled: boolean;
}): JSX.Element => {
  const [createDashboardLink, { isSuccess, isLoading }] =
    useCreateDashboardLinkMutation();
  const redirectToStripe = useCallback(
    async (linkId: string) => {
      const data = await createDashboardLink(linkId);
      if ("error" in data) {
        Snackbar.error("Sorry, something when wrong. Please try again.");
      } else {
        window.open(data.data.url, "_blank");
      }
    },
    [createDashboardLink],
  );

  if (isLoading) {
    return <Loader />;
  }

  return (
    <Button
      variant="tertiary"
      disabled={disabled || isSuccess}
      onClick={() => redirectToStripe(id)}
      size="x-small"
    >
      View
    </Button>
  );
};

const ViewOnboardingButton = ({
  id,
  disabled,
}: {
  id: string;
  disabled?: boolean;
}) => {
  const [createOnboardingLink, { isLoading }] =
    useCreateOnboardingLinkMutation();
  const recipientsOnboardingLinks = useAppSelector(
    (state) => state.withdrawal.recipientsOnboardingLinks,
  );
  const dispatch = useAppDispatch();

  const handleClick = useCallback(async () => {
    let onboardingUrl = recipientsOnboardingLinks[id];
    if (!onboardingUrl) {
      const data = await createOnboardingLink(id)
        .unwrap()
        .catch((error: unknown) => {
          if (
            typeGuard<
              unknown,
              {
                data: {
                  detail: number;
                };
              }
            >(error, "data")
          ) {
            Snackbar.error("Sorry, Stripe onboarding link not found.");
          } else {
            Snackbar.error("Sorry, something when wrong. Please try again.");
          }
        });

      if (data?.url) {
        dispatch(addRecipientOnboardingLink({ id, link: data.url }));
        onboardingUrl = data?.url;
      }
    }

    window.location.href = onboardingUrl;
  }, [id, createOnboardingLink, recipientsOnboardingLinks, dispatch]);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <Button
      disabled={disabled}
      variant="tertiary"
      size="x-small"
      onClick={handleClick}
    >
      Go to Stripe onboarding
    </Button>
  );
};

export const RecipientsListing = ({
  currentWithdrawalMethod,
  deleteRecipient,
}) => {
  const dispatch = useAppDispatch();
  const handleDeleteRecipient = (data) => {
    deleteRecipient(data);
    dispatch(removeRecipientOnboardingLink(data));

    AddRecipientsModal.close();
  };

  return !!currentWithdrawalMethod?.method.recipients.length ? (
    <div className="recipients-table-wrapper">
      <Table sx={{ width: "100%" }}>
        <TableHead>
          <TableRow>
            <TableCell className="recipients-table-wrapper__cell" align="left">
              Company Node
            </TableCell>
            <TableCell className="recipients-table-wrapper__cell" align="left">
              Status
            </TableCell>
            <TableCell className="recipients-table-wrapper__cell" align="left">
              Account
            </TableCell>
            <TableCell className="recipients-table-wrapper__cell" align="left">
              Delete
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {currentWithdrawalMethod?.method.recipients.map((recipient) => {
            return (
              <TableRow key={recipient.id}>
                <TableCell className="recipients-table-wrapper__cell">
                  {recipient.company_node_name}
                </TableCell>
                <TableCell className="recipients-table-wrapper__cell">
                  <RecipientStatus recipient={recipient} />
                </TableCell>
                <TableCell className="recipients-table-wrapper__cell">
                  <ViewDashboardButton
                    id={recipient.id}
                    disabled={!recipient.account_onboarded}
                  />
                </TableCell>
                <TableCell className="recipients-table-wrapper__cell">
                  <IconButton
                    aria-label="delete"
                    onClick={() => handleDeleteRecipient(recipient.id)}
                    variant="tertiary"
                    size="x-small"
                  >
                    <CloseIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </div>
  ) : (
    <Typography component="p">No recipients added yet.</Typography>
  );
};

const RecipientStatus = ({ recipient }): JSX.Element | null => {
  if (!recipient.account_onboarded) {
    return <ViewOnboardingButton id={recipient.id} />;
  }
  if (recipient.payouts_enabled) {
    return <>Active</>;
  }
  if (!recipient.payouts_enabled) {
    return <>Payouts paused - check account</>;
  }
  return null;
};
