import React from "react";
import cs from "classnames";

import { Typography } from "@hexocean/braintrust-ui-components";
import { getTalentOfferStatus } from "@js/apps/jobs/apps/offers/components/offer-status";
import type { TalentOfferStatus } from "@js/types/jobs";

type FreelancerOfferStatusProps = {
  offerStatus: TalentOfferStatus | null;
};

export const FreelancerOfferStatusCaption = ({
  offerStatus,
}: FreelancerOfferStatusProps) => {
  const { color, label } = getTalentOfferStatus(offerStatus);

  return (
    <Typography
      className={cs({
        [`freelancer-offer-status-color--${color}`]: !!color,
      })}
      ml={1.5}
      component="p"
      fontWeight={500}
      size="small"
    >
      {label}
    </Typography>
  );
};
