import { useEffect, useState } from "react";

import { Box, Button, Typography } from "@hexocean/braintrust-ui-components";
import { LongArrowLeftIcon } from "@hexocean/braintrust-ui-components/Icons";
import { Modal } from "@js/components/modal";

import { JobOverviewForm } from "../../forms/job-overview";
import { useGenerateAI } from "../../hooks/generate-ai";

import { LoadingModalInstance, openLoadingModal } from "./loading-modal";

type CreateJobAiProps = {
  onBack: () => void;
};

export const CreateJobAiModalInstance = Modal("create-job-ai-modal", {
  keepOnBackdropClick: false,
  className: "create-job-ai-modal",
  closeButton: true,
  closeButtonProps: {
    variant: "tertiary",
    size: "x-small",
  },
});

const CreateJobAiModal = ({ onBack }: CreateJobAiProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { onSubmit, onSubmitSuccess } = useGenerateAI();

  useEffect(() => {
    if (isLoading) {
      openLoadingModal();
    } else {
      LoadingModalInstance.close();
    }
  }, [isLoading]);

  useEffect(() => {
    return () => CreateJobAiModalInstance.close();
  }, []);

  return (
    <>
      <Box
        sx={{
          display: isLoading ? "none" : "flex",
          flexDirection: "column",
          gap: "16px",
        }}
      >
        <Button
          variant="transparent"
          startIcon={<LongArrowLeftIcon />}
          style={{
            width: "fit-content",
            padding: "0 4px",
          }}
          onClick={onBack}
        >
          Back
        </Button>
        <img
          src={`${SETTINGS.STATIC_URL}jobs/ai-job.svg`}
          alt="ai job"
          width={57}
          style={{ marginLeft: "-5px" }}
        />
        <Typography
          variant="label"
          component="span"
          className="create-job-ai-modal-label"
        >
          ✍️️ Include a brief job overview to assist our AI in crafting a job
          for you.
        </Typography>
        <JobOverviewForm
          onSubmit={onSubmit}
          setFormIsLoading={setIsLoading}
          onSubmitSuccess={onSubmitSuccess}
        />
      </Box>
      <LoadingModalInstance />
    </>
  );
};

export const openCreateJobAiModal = (props: CreateJobAiProps) => {
  const onBack = () => {
    props.onBack();
    CreateJobAiModalInstance.close();
  };

  CreateJobAiModalInstance.open({
    onClose: onBack,
    children: <CreateJobAiModal onBack={onBack} />,
  });
};
