import uuid4 from "uuid4/browser";

import type { SavedTalentFilter } from "@js/types/jobs";

export const addSessionTokenForEveryPlaceId = (
  savedFilters: SavedTalentFilter[],
) =>
  savedFilters.map((savedSearch) => {
    if (!savedSearch.filters?.place_id) return savedSearch;
    const sessionTokens: string[] = [];
    const placeIds = savedSearch.filters?.place_id.split(",");
    placeIds.forEach(() => sessionTokens.push(uuid4()));

    return {
      ...savedSearch,
      filters: {
        ...savedSearch.filters,
        session_token: sessionTokens.join(","),
      },
    };
  });
