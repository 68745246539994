import React from "react";

import { useAccountType } from "@js/apps/common/hooks";
import { openSubscribeTeamMembersToJobModal } from "@js/apps/employer/components/subscribe-team-members-to-job-modal";
import type { Job } from "@js/types/jobs";

import { useGetJobSubscribersQuery } from "../../api";
import { AddTeamMembersButton } from "../add-team-members-button";

type JobManagementHeaderAddTeamMembersButtonProps = { job: Job };

export const JobManagementHeaderAddTeamMembersButton = ({
  job,
}: JobManagementHeaderAddTeamMembersButtonProps) => {
  const { isEmployer } = useAccountType();
  const { data: jobSubscribers } = useGetJobSubscribersQuery(
    { jobId: job.id },
    { skip: !isEmployer },
  );
  if (!isEmployer) {
    return <></>;
  }

  return (
    <AddTeamMembersButton
      jobSubscribers={jobSubscribers ?? []}
      onClick={() => {
        openSubscribeTeamMembersToJobModal({
          jobId: job.id,
          jobOwnerId: job.creator.id,
        });
      }}
    />
  );
};
