import { SubmissionError } from "redux-form";

import { openAddServicesSecondStep } from "@js/apps/freelancer/components/ggh-services/modals/add-ggh-services";
import { useClearAddServicesForm } from "@js/apps/freelancer/forms/add-ggh-services-form";
import { useGetHelpServicesQuery } from "@js/apps/give-and-get-help/api";
import { useCreateHelpServiceMutation } from "@js/apps/give-and-get-help/api";
import { getInitialBudget } from "@js/apps/give-and-get-help/utils";
import { ModalInstance } from "@js/components/modal";
import { useNavigate } from "@js/hooks";
import type { PostComment } from "@js/types/give-and-get-help";

export const useGghServicesNudge = (
  postCategoryId: number,
  comment: PostComment,
) => {
  const { freelancer, help_offer, text } = comment;
  const { data: helpServices } = useGetHelpServicesQuery({
    freelancerId: freelancer.id,
  });
  const [createHelpService] = useCreateHelpServiceMutation();
  const { destroyForm } = useClearAddServicesForm();
  const navigate = useNavigate();

  const isCategoryAlreadyAdded = helpServices?.find(
    (service) => service.category.id === postCategoryId,
  );

  const onSubmit = async (formData) => {
    const promise = createHelpService(formData).unwrap();
    try {
      await promise;
    } catch (error: any) {
      throw new SubmissionError(error.data);
    }
  };

  const onSubmitSuccess = () => {
    ModalInstance.close();
    navigate(`/talent/${freelancer.id}/#services-section`);
  };

  const onClose = () => {
    destroyForm();
    ModalInstance.close();
  };

  const openAddServiceModal = () =>
    openAddServicesSecondStep({
      onClose: onClose,
      initialValues: {
        category: postCategoryId,
        description: text || "",
        budget: getInitialBudget(help_offer?.budget),
      },
      onSubmit,
      onSubmitSuccess,
    });

  return { isCategoryAlreadyAdded, openAddServiceModal };
};
