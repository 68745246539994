import { useParams } from "react-router-dom";
import cs from "classnames";

import { Button, IconButton } from "@hexocean/braintrust-ui-components";
import { useMediaQuery } from "@hexocean/braintrust-ui-components/hooks";
import { EditPen } from "@hexocean/braintrust-ui-components/Icons";
import { openCreatePostModal } from "@js/apps/give-and-get-help/components/create-or-edit-post-modal/modal/open-modal";
import {
  PostLocation,
  usePostLocationContext,
} from "@js/apps/give-and-get-help/context/post-location";
import { useAppDispatch } from "@js/hooks";

import { useNewPostModalVisibleURL } from "../../hooks/create-or-edit-post";

import styles from "./create-post-fab.module.scss";

type CreatePostFABProps = {
  disabled?: boolean;
  containerClassName?: string;
};

export const CreatePostFAB = ({
  disabled,
  containerClassName,
}: CreatePostFABProps) => {
  const isMobile = useMediaQuery(650);
  const { handleOpenCreatePostModal } = useCreatePostFAB();

  return (
    <div className={styles.wrapper}>
      <div className={cs(styles.container, containerClassName)}>
        {isMobile ? (
          <IconButton
            className={cs(styles.button, styles.buttonMobile)}
            variant="positive"
            size="medium"
            shape="squared"
            onClick={handleOpenCreatePostModal}
            disabled={disabled}
            aria-label="Add post"
          >
            <EditPen style={{ fontSize: "25px" }} />
          </IconButton>
        ) : (
          <Button
            className={styles.button}
            variant="positive"
            size="medium"
            shape="squared"
            onClick={handleOpenCreatePostModal}
            startIcon={<EditPen style={{ fontSize: "25px" }} />}
            disabled={disabled}
          >
            Post
          </Button>
        )}
      </div>
    </div>
  );
};

const useCreatePostFAB = () => {
  const { spaceId } = useParams();
  const dispatch = useAppDispatch();
  const location = usePostLocationContext();
  const { addModalVisibleUrlParam } = useNewPostModalVisibleURL();
  const isSpacePage =
    location === PostLocation.Values.space ||
    location === PostLocation.Values.single_space_post_view;

  const handleOpenCreatePostModal = () => {
    const isMainCareerHelp = location === PostLocation.Values.feed;

    if (!isMainCareerHelp) {
      addModalVisibleUrlParam();
    }

    return dispatch(
      openCreatePostModal({
        composer_location: location,
        spaceId: isSpacePage ? Number(spaceId) : undefined,
      }),
    );
  };

  return { handleOpenCreatePostModal };
};
