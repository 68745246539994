import React from "react";
import { createContext } from "react";

import type { EmployerInvoice } from "@js/types/invoices";

type EmployerInvoiceListingContextValue = {
  invoiceList: EmployerInvoice[];
  selectedInvoicesIds: number[];
  onSelectItem: (id: number) => void;
  toggleSelectCurrentPage: (select: boolean) => void;
  setSelectedInvoicesIds: React.Dispatch<React.SetStateAction<number[]>>;
  onSelectAcrossAllPagesClick: (mode: "select" | "unselect") => void;
  totalInvoicesCount: number;
  checkIfItemIsSelected: (id: number) => boolean;
  resetSelectedInvoicesIds: () => void;
};

export const EmployerInvoiceListingContext =
  createContext<EmployerInvoiceListingContextValue | null>(null);

export const useEmployerInvoiceListingContext = () => {
  const context = React.useContext(EmployerInvoiceListingContext);
  if (!context) {
    throw new Error(
      "useEmployerInvoiceListingContext must be used within a EmployerInvoiceListingContextProvider",
    );
  }
  return context;
};
