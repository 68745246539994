import { TableCell, TableRow } from "@hexocean/braintrust-ui-components";
import { CheckIcon, CloseIcon } from "@hexocean/braintrust-ui-components/Icons";

type TableRowComponentProps = {
  isInvitation?: boolean;
  name: string;
  canViewInvoices?: boolean;
  children: JSX.Element;
};

export const TableRowComponent = ({
  isInvitation,
  name,
  canViewInvoices,
  children,
}: TableRowComponentProps) => {
  return (
    <TableRow>
      <TableCell>{name}</TableCell>
      <TableCell>{isInvitation ? "Invited" : "Joined"}</TableCell>
      <TableCell align="center">
        {isInvitation ? null : canViewInvoices ? <CheckIcon /> : <CloseIcon />}
      </TableCell>
      <TableCell align="right">{children}</TableCell>
    </TableRow>
  );
};
