import { Menu } from "@hexocean/braintrust-ui-components";
import { RouterLink } from "@js/components/link";

type MenuItemWrapperProps = {
  label: string | JSX.Element;
  onClick?: () => void;
  to?: string;
  href?: string;
  icon?: JSX.Element;
  rel?: string;
  target?: string;
};

export const MenuItemWrapper = ({
  label,
  onClick,
  to,
  href,
  icon,
  rel,
  target,
}: MenuItemWrapperProps) => {
  const routerLinkProps = {
    component: to || href ? RouterLink : undefined,
    to,
    href,
  };
  return (
    <Menu.Item
      {...routerLinkProps}
      rel={rel}
      target={target}
      onClick={onClick}
      className="help-offer-card__menu-item"
    >
      {label}
      {icon || undefined}
    </Menu.Item>
  );
};
