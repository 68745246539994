import React from "react";

import { Typography } from "@hexocean/braintrust-ui-components";
import { RouterLink } from "@js/components/link";

const JobNotFound = () => {
  return (
    <>
      <img
        className="not-found-page-image"
        src={`${SETTINGS.STATIC_URL}jobs/bkg-empty-no-job.png`}
        alt=""
      />
      <Typography component="h1" className="not-found-page-title">
        Job not found.
      </Typography>
      <Typography
        component="p"
        variant="title"
        size="medium"
        fontWeight={400}
        className="not-found-page-info"
      >
        Return to the <RouterLink to="/">homepage</RouterLink> or contact us
        about the problem
      </Typography>
    </>
  );
};

export default JobNotFound;
