import React from "react";

import {
  Box,
  Button,
  Popover,
  RoundedBox,
  Typography,
} from "@hexocean/braintrust-ui-components";
import {
  ArrowTopRightIcon,
  EarthIcon,
  SettingsRoundedIcon,
} from "@hexocean/braintrust-ui-components/Icons";
import { ChangeJobVisibilityForm } from "@js/apps/employer/forms/change-job-visibility";
import { JobActionMenu } from "@js/apps/jobs/components/job-action-menu";
import { RouterLink } from "@js/components/link";
import type { Job } from "@js/types/jobs";
import { dateFromNow, pluralize } from "@js/utils";

import { WithJobActionsContext } from "../job-actions";

import { JobManagementHeaderAddTeamMembersButton } from "./job-management-header-add-team-members-button";
import { useJobManagementHeader } from "./use-job-management-header";

type JobManagementHeaderProps = {
  job: Job;
};

export const JobManagementHeader: React.FC<JobManagementHeaderProps> = ({
  job,
}) => {
  const {
    jobActionsContextValue,
    showJobTitleTooltip,
    updateTooltipVisibility,
    onChangeVisibility,
  } = useJobManagementHeader({ job });

  return (
    <WithJobActionsContext.Provider value={jobActionsContextValue}>
      <RoundedBox
        component="header"
        sx={{
          display: { lg: "flex" },
          justifyContent: "space-between",
          p: 3,
          mb: 2,
          position: "relative",
        }}
      >
        <Box
          sx={{
            mb: { xs: 1, md: 0 },
            paddingRight: { xs: 0, md: "46px" },
            minWidth: 0,
          }}
        >
          <Typography
            component="h2"
            color="primary"
            variant="label"
            size="large"
            ellipsis
            paddingRight={{ xs: 6, md: 0 }}
            className="capitalize-first-letter"
            fontWeight={400}
            title={showJobTitleTooltip ? job.title : ""}
            onMouseEnter={updateTooltipVisibility}
          >
            {job.title}
          </Typography>
          <Typography component="p" size="small">
            {`Posted ${dateFromNow(job.published_at)}. `}
            {job.openings_number > 1 &&
              `${job.openings_number} open role${pluralize(
                job.openings_number,
              )}.`}
          </Typography>
        </Box>
        <Box
          sx={{
            gap: "8px",
            display: "flex",
            flexWrap: "wrap",
            flexShrink: 0,
            alignItems: "center",
            paddingRight: { xs: 0, md: "46px" },
          }}
        >
          {!job.completed_at && (
            <>
              <Popover
                anchor={
                  <Button
                    variant="white-violet"
                    shape="squared"
                    startIcon={<EarthIcon />}
                  >
                    Visibility
                  </Button>
                }
              >
                <Box p={3} mr={1} display="flex" flexDirection="column" gap={1}>
                  <Typography component="p">Post visibility</Typography>
                  <ChangeJobVisibilityForm
                    initialValues={{ is_private: job.is_private }}
                    onSubmit={onChangeVisibility}
                  />
                </Box>
              </Popover>
              <JobManagementHeaderAddTeamMembersButton job={job} />
              <Button
                variant="white-violet"
                shape="squared"
                inverse
                endIcon={<SettingsRoundedIcon />}
                to={`/jobs/${job.id}/edit/?back=proposals`}
                RouterLink={RouterLink}
              >
                Edit Job
              </Button>
            </>
          )}
          <Button
            variant="white-violet"
            shape="squared"
            to={`/jobs/${job.id}`}
            rel="noopener noreferrer"
            endIcon={<ArrowTopRightIcon style={{ fontSize: 24 }} />}
            target="_blank"
            RouterLink={RouterLink}
          >
            View Job Post
          </Button>
          <Box
            sx={{
              position: "absolute",
              top: "28px",
              right: "16px",
            }}
          >
            <JobActionMenu job={job} filterActions={filterJobActions} />
          </Box>
        </Box>
      </RoundedBox>
    </WithJobActionsContext.Provider>
  );
};

const filterJobActions = (jobActions: Job["job_actions"]) =>
  jobActions.filter(
    (jobAction) => jobAction.action !== ENUMS.JobActions.ADD_TEAM_MEMBERS,
  );
