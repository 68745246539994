import { useCallback } from "react";

import { useIsNodeStaff } from "@js/apps/common/hooks";
import { openCreateJobThrottlingModal } from "@js/apps/jobs/apps/create-job/views/create-job/create-job-throttling-modal";
import { useAppSelector } from "@js/hooks";

import { useLazyGetHasEmployerExceededDailyJobPostingLimitQuery } from "../../api";

export const useCheckJobsLimit = () => {
  const employerId = useAppSelector(
    (state) => state.employer.employerProfile?.id,
  );
  const isNodeStaff = useIsNodeStaff();
  const [checkIfExceededDailyJobPostingLimit] =
    useLazyGetHasEmployerExceededDailyJobPostingLimitQuery();

  const checkJobsLimit = useCallback(
    async ({ onLimitNotExceeded }: { onLimitNotExceeded: () => void }) => {
      if (isNodeStaff) {
        return onLimitNotExceeded();
      }

      if (!employerId) {
        return;
      }

      const { data, isError } = await checkIfExceededDailyJobPostingLimit(
        { employerId },
        true,
      );

      const hasExceededDailyJobsLimit =
        !isError && !!data?.has_exceeded_daily_job_posting_limit;
      if (hasExceededDailyJobsLimit) {
        return openCreateJobThrottlingModal();
      }

      return onLimitNotExceeded();
    },
    [employerId, isNodeStaff, checkIfExceededDailyJobPostingLimit],
  );

  return {
    checkJobsLimit,
  };
};
