import React from "react";

import { Box, Typography } from "@hexocean/braintrust-ui-components";
import { EmployerLogo } from "@js/apps/employer/components/employer-logo";
import { JobItemCompanyLogo } from "@js/apps/jobs/components/job-item";
import { useGetEmployerInitialItemQuery } from "@js/components/autocomplete-new/api";
import { useIsImageBackgroundTransparent } from "@js/hooks";
export const PostJobAsBadge = ({ input }) => {
  const selectedEmployerId = input.value;
  const { data: selectedEmployerData } = useGetEmployerInitialItemQuery(
    Number(selectedEmployerId),
    {
      skip: !selectedEmployerId,
    },
  );

  const isTransparent = useIsImageBackgroundTransparent(
    selectedEmployerData ? selectedEmployerData?.logo_thumbnail : "",
  );

  return selectedEmployerData ? (
    <Box className="posting-job-as-badge">
      <Typography component="p" mb={1}>
        Posting job as:
      </Typography>
      {selectedEmployerData?.has_logo_set ? (
        <JobItemCompanyLogo
          logoThumbnail={selectedEmployerData?.logo}
          alt=""
          isTransparent={isTransparent}
        />
      ) : (
        <EmployerLogo
          employer={selectedEmployerData}
          size="small"
          variant="listing"
        />
      )}
    </Box>
  ) : null;
};
