import type { RootState } from "@js/store";
import type { PaginatedResult } from "@js/types/generic";

import { isNotNullable, typeGuard } from "../typescript";

type IDKey = number | string | null;

export const findEntity = <T extends { id: IDKey }>(
  entities: T[] | null,
  id: IDKey,
  customKey?: keyof T,
) => {
  if (customKey) {
    return entities?.find((entity) => entity[customKey] === id);
  }

  return entities?.find((entity) => entity.id === id);
};

export const findEntityIndex = <T extends { id: IDKey }>(
  entities: T[],
  id: IDKey,
  customKey?: keyof T,
) => {
  if (customKey) {
    return entities?.findIndex((entity) => entity[customKey] === id);
  }

  return entities.findIndex((entity) => entity.id === id);
};

type DataToNormalize<T> = T[] | PaginatedResult<T>;

export const normalizePaginatedData = <T>(draft: DataToNormalize<T>): T[] => {
  if (
    typeGuard<PaginatedResult<T> | T[], PaginatedResult<T>>(draft, "results")
  ) {
    return draft.results;
  } else {
    return draft;
  }
};

export const getQueriesByEndpoint = (state: RootState, endpoint: string) => {
  const allQueries = Object.values(state.api.queries).filter(isNotNullable);

  return allQueries.filter((query) => query.endpointName === endpoint);
};
