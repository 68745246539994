import { useCallback, useEffect, useState } from "react";
import { SubmissionError } from "redux-form";

import {
  addBidNote as addBidNoteAction,
  deleteBidNote as deleteBidNoteAction,
  fetchBidNotes as fetchBidNotesAction,
} from "@js/apps/jobs/actions";
import { Snackbar } from "@js/components/snackbar";
import type { NoteItem } from "@js/types/admin";

export const useBidComments = (id: number) => {
  const [comments, setComments] = useState<NoteItem[] | null>(null);
  const [fetchingComments, setFetchingComments] = useState(false);

  const fetchComments = useCallback(
    (options = { loaderOff: false }) => {
      if (!options.loaderOff) {
        setFetchingComments(true);
      }

      fetchBidNotesAction({ id }).then((data) => {
        setComments(data as NoteItem[]);
        setFetchingComments(false);
      });
    },
    [id],
  );

  useEffect(() => {
    fetchComments();
  }, [fetchComments]);

  const deleteComment = (note: NoteItem) => {
    deleteBidNoteAction(note.id).then(() => {
      fetchComments({ loaderOff: true });
      Snackbar.success("Comment successfully deleted.");
    });
  };

  const onSubmit = (values) => {
    return addBidNoteAction({
      bid_id: id,
      ...values,
    }).catch((error) => {
      throw new SubmissionError(error);
    });
  };

  const onSubmitSuccess = (_result, _dispatch, props) => {
    props.reset();
    fetchComments({ loaderOff: true });
    Snackbar.success("Comment successfully added.");
  };

  return {
    fetchingComments,
    comments,
    deleteComment,
    onSubmit,
    onSubmitSuccess,
  };
};
