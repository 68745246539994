import { useEffect } from "react";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import type { RootNode } from "lexical";
import {
  $createParagraphNode,
  $createTextNode,
  $getRoot,
  $setSelection,
  ParagraphNode,
  TextNode,
} from "lexical";

import type { Hashtag, PostHashtag } from "@js/apps/give-and-get-help/types";

import { $createCustomHashtagNode } from "../components/hashtag-node";
import { areHashtagsEqual } from "../utils/are-hashtags-equal";
import { getHashtagName } from "../utils/get-hashtag-name";
import { splitTextByHashtags } from "../utils/parse-text-with-hashtags";

type useInitHashtagsProps = {
  hashtags: Array<PostHashtag | Hashtag>;
};

export const useInitHashtags = ({ hashtags }: useInitHashtagsProps) => {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    editor.update(() => {
      const root = $getRoot();
      const recreatedHashtags: string[] = [];

      const traverseNodes = (node: RootNode | ParagraphNode) => {
        if (node instanceof TextNode) {
          return;
        }

        if (!(node instanceof ParagraphNode)) {
          return node
            .getChildren<ParagraphNode>()
            .forEach((childrenNode) => traverseNodes(childrenNode));
        }

        const textContent = node.getTextContent();
        const textArray = splitTextByHashtags(textContent);

        if (!textArray.length) {
          return;
        }

        const paragraphChildrenNodes: TextNode[] = [];
        let shouldReplace = false;
        textArray.forEach((textFragment) => {
          if (!textFragment.includes("#")) {
            const textNode = $createTextNode(textFragment);
            paragraphChildrenNodes.push(textNode);

            return;
          }

          const hashtagData = hashtags.find((hashtag) => {
            return areHashtagsEqual(textFragment, getHashtagName(hashtag));
          });

          const isHashtagOnList = !!hashtagData;

          if (isHashtagOnList && !recreatedHashtags.includes(textFragment)) {
            recreatedHashtags.push(textFragment);
            const hashtagNode = $createCustomHashtagNode(textFragment);
            paragraphChildrenNodes.push(hashtagNode);
            shouldReplace = true;
          } else {
            const textNode = $createTextNode(textFragment);
            paragraphChildrenNodes.push(textNode);
          }
        });

        if (!paragraphChildrenNodes.length || !shouldReplace) {
          return;
        }

        const paragraph = $createParagraphNode();
        paragraphChildrenNodes.forEach((childrenNode) =>
          paragraph.append(childrenNode),
        );
        node.replace(paragraph);
      };

      traverseNodes(root);

      $setSelection(null);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editor]);
};
