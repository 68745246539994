import { useNavigate } from "react-router-dom";

import {
  IconButton,
  Menu,
  RoundedBox,
  Typography,
} from "@hexocean/braintrust-ui-components";
import { MoreVertIcon } from "@hexocean/braintrust-ui-components/Icons";
import { deleteDraft } from "@js/apps/jobs/apps/create-job/actions";
import { RouterLink } from "@js/components/link";
import { UserProfile } from "@js/components/user-profile";
import { useAppDispatch } from "@js/hooks";
import type { JobDraft } from "@js/types/jobs";

import { useCheckJobsLimit } from "../../hooks/check-jobs-limit";
import { ImportedFromAtsLabel } from "../imported-from-ats-label";

import style from "./style.module.scss";

type DraftItemProps = {
  id: JobDraft["id"];
  title: JobDraft["title"];
  isAtsImported: JobDraft["ats_imported"];
  creator: JobDraft["creator"];
};

export const DraftItem = ({
  id,
  title,
  isAtsImported,
  creator,
}: DraftItemProps) => {
  return (
    <RoundedBox aria-label="job draft">
      <div className={style.myJobsDraftItem}>
        <div className={style.myJobsDraftItemInfo}>
          {isAtsImported && <ImportedFromAtsLabel />}
          <Typography
            mt={1}
            component="link"
            to={`/jobs/add_new/${id}`}
            RouterLink={RouterLink}
            className={style.myJobsDraftItemTitle}
          >
            {title || "Title placeholder"}
          </Typography>
        </div>
        <div className={style.myJobsDraftItemProfilesActions}>
          <UserProfile profile={creator} title="Job owner" />
        </div>
        <div className={style.myJobsDraftItemMenu}>
          <JobDraftsActionMenu id={id} />
        </div>
      </div>
    </RoundedBox>
  );
};

const JobDraftsActionMenu = ({ id }: { id: number }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { checkJobsLimit } = useCheckJobsLimit();
  return (
    <Menu
      id={`job-draft-action-menu-${id}`}
      anchor={
        <IconButton
          className={style.myJobsDraftItemMenuButton}
          aria-label="job draft action menu"
          aria-controls={`job-draft-action-action-menu-${id}`}
          aria-haspopup="true"
          size="medium"
          variant="transparent"
        >
          <MoreVertIcon />
        </IconButton>
      }
    >
      <Menu.Item
        key="Edit Job"
        onClick={() =>
          checkJobsLimit({
            onLimitNotExceeded: () => navigate(`/jobs/add_new/${id}`),
          })
        }
      >
        Edit Job
      </Menu.Item>
      <Menu.Item
        key="Duplicate Job Draft"
        onClick={() =>
          checkJobsLimit({
            onLimitNotExceeded: () =>
              navigate(`/jobs/add_new?copy_draft_id=${id}`),
          })
        }
      >
        Duplicate Job Draft
      </Menu.Item>
      <Menu.Item
        key="Delete Job Draft"
        onClick={() => {
          dispatch(deleteDraft(id));
        }}
      >
        Delete Job Draft
      </Menu.Item>
    </Menu>
  );
};
