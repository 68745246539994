import React from "react";
import { Field, reduxForm } from "redux-form";
import cs from "classnames";
import _ from "underscore";

import { Grid, Typography } from "@hexocean/braintrust-ui-components";
import {
  CheckIcon,
  CloseIcon,
  LockIcon,
  UnlockIcon,
} from "@hexocean/braintrust-ui-components/Icons";
import { Form } from "@js/forms/components/form";
import { SwitchField, ToggleButtonGroupField } from "@js/forms/fields";
import type { AccountType } from "@js/types/auth";
import { getEnumLabel } from "@js/utils";

import { Section, SectionRow, SectionSubtitle } from "../helpers";
const VISIBILITY_FORM_ID = "visibility-form";

type VisibilityFormContentProps = {
  submit: () => void;
  error?: string;
  submitting: boolean;
  accountType: AccountType;
};

const VisibilityFormContent = ({
  submit,
  error,
  submitting,
  accountType,
}: VisibilityFormContentProps) => (
  <Form onSubmit={submitting ? null : submit} error={error}>
    <Section title="Privacy">
      <Grid
        container
        spacing={4}
        className={cs({ "section-disabled": submitting })}
      >
        <Grid item xs={12}>
          <SectionSubtitle>Profile privacy</SectionSubtitle>
        </Grid>
        <Grid item xs={12}>
          <Typography component="p" mb={4}>
            You can keep certain details of your profile or job history on the
            Braintrust marketplace private.
          </Typography>
          {accountType === ENUMS.AccountType.FREELANCER && (
            <>
              <SectionRow divider>
                <SwitchFieldWrapper
                  label="Show profile visits count to other users"
                  id="profile_visits_count_visibility"
                  name="profile_visits_count_visibility"
                  customValue="public"
                  disabled={submitting}
                  customUncheckedValue="private"
                />
              </SectionRow>
              <SectionRow>
                <>
                  <SwitchFieldWrapper
                    label="Hide BTRST balance on Home page and navigation panel"
                    id="hide_token_balance"
                    name="hide_token_balance"
                    disabled={submitting}
                  />
                  <Typography component="p" ml={2} mt={1}>
                    Your BTRST balance is always private to you. Hiding this
                    balance enables you to keep this private if you share your
                    screen with a colleague. Your wallet page will continue to
                    show your balance regardless of this selection.
                  </Typography>
                </>
              </SectionRow>
            </>
          )}
          <SectionRow divider>
            <SwitchFieldWrapper
              label="Display number of jobs"
              id="show_number_of_jobs"
              name="show_number_of_jobs"
              disabled={submitting}
            />
          </SectionRow>
        </Grid>
        {accountType === ENUMS.AccountType.FREELANCER && (
          <>
            <Grid item xs={12}>
              <SectionSubtitle
                containerProps={{ mt: 2 }}
                action={
                  <Field
                    id="profile_visibility"
                    name="profile_visibility"
                    component={ToggleButtonGroupField}
                    disableUnselecting
                    options={[
                      {
                        value: ENUMS.FreelancerProfileVisibility.PUBLIC,
                        label: getEnumLabel(
                          ENUMS.FreelancerProfileVisibilityLabels,
                          ENUMS.FreelancerProfileVisibility.PUBLIC,
                        ),
                        icon: <UnlockIcon />,
                        disabled: submitting,
                      },
                      {
                        value: ENUMS.FreelancerProfileVisibility.PRIVATE,
                        label: getEnumLabel(
                          ENUMS.FreelancerProfileVisibilityLabels,
                          ENUMS.FreelancerProfileVisibility.PRIVATE,
                        ),
                        icon: <LockIcon />,
                        disabled: submitting,
                      },
                    ]}
                  />
                }
              >
                Profile Visibility
              </SectionSubtitle>
              <Typography component="p" mt={3} mb={3}>
                You can keep certain details of your profile or job history on
                the Braintrust marketplace private.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography component="p" variant="label" mb={4}>
                Additional info
              </Typography>
              <SectionRow
                title="Public"
                subtitle="Anyone can find or favorite your profile
                                inside of Braintrust, and they can contact you."
                divider
              />
              <SectionRow
                title="Private"
                subtitle="You will not show up in searches inside of Braintrust.
                                Those with a direct share link to your profile
                                or have favorited you can still contact you."
              />
            </Grid>
          </>
        )}
      </Grid>
    </Section>
  </Form>
);

type SwitchFieldWrapperProps = {
  label: string;
  id: string;
  name: string;
  disabled: boolean;
  customValue?: unknown;
  customUncheckedValue?: unknown;
};

const SwitchFieldWrapper = (props: SwitchFieldWrapperProps) => {
  return (
    <>
      <Field
        component={SwitchField}
        LabelProps={{ labelPlacement: "start" }}
        icon={<CloseIcon />}
        checkedIcon={<CheckIcon />}
        {...props}
      />
    </>
  );
};

export type VisibilityFormValues = {
  hide_token_balance?: false;
  profile_visibility?: "public" | "private";
  profile_visits_count_visibility?: "public" | "private";
  show_number_of_jobs: boolean;
};

export const VisibilityForm = reduxForm<VisibilityFormValues, any>({
  form: VISIBILITY_FORM_ID,
  enableReinitialize: true,
  onChange: (values, _dispatch, { submit }, previousValues) => {
    if (
      !_.isEmpty(previousValues) &&
      JSON.stringify(values) !== JSON.stringify(previousValues)
    ) {
      if (submit) {
        return submit();
      }
    }
  },
})(VisibilityFormContent);
