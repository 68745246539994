import { useEffect } from "react";
import smartlookClient from "smartlook-client";

import { useUser } from "@js/apps/common/hooks";
import { useAppSelector } from "@js/hooks";

export const SmartlookProvider = ({ children }) => {
  const user = useUser();
  const freelancer = useAppSelector((state) => state.freelancer);
  const employer = useAppSelector((state) => state.employer);

  useEffect(() => {
    if (!smartlookClient.initialized() && SETTINGS.SMARTLOOK_PROJECT_ID) {
      smartlookClient.init(SETTINGS.SMARTLOOK_PROJECT_ID);
    }
  }, []);

  useEffect(() => {
    if (user && smartlookClient.initialized()) {
      smartlookClient.identify(user.id, {
        is_staff:
          user.has_node_staff_permissions && !user.is_impersonated_session,
        public_name: user.public_name,
        email: user.email,
        company_name: freelancer?.freelancerProfile?.company_name || "",
        talent_hired_count: employer?.employerProfile?.talent_hired_count || "",
        organization_size: employer?.employerProfile?.organization_size || "",
      });
    }
  }, [
    user,
    freelancer?.freelancerProfile?.company_name,
    employer?.employerProfile?.talent_hired_count,
    employer?.employerProfile?.organization_size,
  ]);

  return children;
};
