import React from "react";
import LinkifyLib from "linkify-react";

import { getLinkifyRenderOptions } from "./linkify-render-options";

type LinkifyProps = React.ComponentProps<typeof LinkifyLib>;

export const Linkify: React.FC<React.PropsWithChildren<LinkifyProps>> = ({
  options,
  children,
  ...props
}) => {
  const defaultOptions = getLinkifyRenderOptions();

  return (
    <LinkifyLib options={{ ...defaultOptions, ...options }} {...props}>
      {children}
    </LinkifyLib>
  );
};
