import { useCallback } from "react";

import { Box } from "@hexocean/braintrust-ui-components";
import { useSearchEventQueryId } from "@js/apps/tracking/search-event-slice";
import {
  jobResultListItemClicked,
  postResultListItemClicked,
  talentResultListItemClicked,
} from "@js/apps/universal-search/actions";
import { useAppDispatch } from "@js/hooks";

import type { UniversalTabValue } from "../../constants";
import type { UniversalSearchCurrentData } from "../../hooks/use-universal-search-form";
import { UniversalSearchResultsHeader } from "../universal-search-results-header";

import { CareerSearchListItem } from "./career-search-list-item";
import { JobSearchListItem } from "./job-search-list-item";
import { PeopleSearchListItem } from "./people-search-list-item";
import { SearchList } from "./search-list";

export type SearchResultsProps = {
  currentTab: UniversalTabValue;
  currentData: UniversalSearchCurrentData;
  isAfterSearch?: boolean;
};

export const SearchResults = ({
  currentTab,
  currentData,
  isAfterSearch,
}: SearchResultsProps) => {
  const {
    careerResults,
    peopleResults,
    jobsResults,
    handleCareerSearchListItemClick,
    handlePeopleSearchListItemClick,
    handleJobSearchListItemClick,
  } = useSearchResults({ isAfterSearch, currentData });

  return (
    <>
      <Box sx={{ px: 2, pt: 3 }}>
        <UniversalSearchResultsHeader
          currentTab={currentTab}
          results={currentData}
        />
      </Box>
      <Box sx={{ p: 1, overflow: "auto", height: "100%" }}>
        <SearchList>
          {currentTab === "CAREER_HELP" &&
            careerResults.map((post, idx: number) => (
              <CareerSearchListItem
                key={post.id}
                post={post}
                onClick={() =>
                  handleCareerSearchListItemClick({ post, itemPosition: idx })
                }
              />
            ))}
          {currentTab === "PEOPLE" &&
            peopleResults.map((talent, idx: number) => (
              <PeopleSearchListItem
                key={talent.id}
                freelancer={talent}
                onClick={() =>
                  handlePeopleSearchListItemClick({ talent, itemPosition: idx })
                }
              />
            ))}
          {currentTab === "JOBS" &&
            jobsResults.map((job, idx: number) => (
              <JobSearchListItem
                key={job.id}
                job={job}
                onClick={() =>
                  handleJobSearchListItemClick({ job, itemPosition: idx })
                }
              />
            ))}
        </SearchList>
      </Box>
    </>
  );
};

type UseSearchResults = Pick<
  SearchResultsProps,
  "isAfterSearch" | "currentData"
>;

const useSearchResults = ({ isAfterSearch, currentData }: UseSearchResults) => {
  const careerResults = currentData.postResults?.results ?? [];
  const peopleResults = currentData.talentResults?.results ?? [];
  const jobsResults = currentData.jobResults?.results ?? [];
  const dispatch = useAppDispatch();
  const searchQueryId = useSearchEventQueryId(
    ENUMS.SearchEventOption.MAIN_SEARCH_BOX,
  );

  const handleCareerSearchListItemClick = useCallback(
    ({ post, itemPosition }) => {
      if (isAfterSearch) {
        dispatch(
          postResultListItemClicked({
            post_id: post.id,
            result_position: itemPosition,
            search_query_id: searchQueryId,
          }),
        );
      }
    },
    [isAfterSearch, searchQueryId, dispatch],
  );

  const handlePeopleSearchListItemClick = useCallback(
    ({ talent, itemPosition }) => {
      if (isAfterSearch) {
        dispatch(
          talentResultListItemClicked({
            talent_id: talent.id,
            result_position: itemPosition,
            search_query_id: searchQueryId,
          }),
        );
      }
    },
    [isAfterSearch, dispatch, searchQueryId],
  );

  const handleJobSearchListItemClick = useCallback(
    ({ job, itemPosition }) => {
      if (isAfterSearch) {
        dispatch(
          jobResultListItemClicked({
            job_id: job.id,
            result_position: itemPosition,
            search_query_id: searchQueryId,
          }),
        );
      }
    },
    [isAfterSearch, dispatch, searchQueryId],
  );

  return {
    careerResults,
    peopleResults,
    jobsResults,
    handleCareerSearchListItemClick,
    handlePeopleSearchListItemClick,
    handleJobSearchListItemClick,
  };
};
