import { Box, Typography } from "@hexocean/braintrust-ui-components";
import { ThunderIcon } from "@hexocean/braintrust-ui-components/Icons";

type AvailableForWorkStatusProps = {
  short?: boolean;
  onClick?: (e: React.MouseEvent<HTMLElement>) => void;
};

export const AvailableForWorkStatus = ({
  short,
  onClick,
}: AvailableForWorkStatusProps) => {
  if (short) {
    return (
      <Box className="available-for-work-status short">
        <ThunderIcon />
      </Box>
    );
  }

  return (
    <Box className="available-for-work-status regular" onClick={onClick}>
      <ThunderIcon />
      <Typography
        component="span"
        size="small"
        color="dark-violet"
        fontWeight={500}
      >
        Looking for Work
      </Typography>
    </Box>
  );
};
