import type { IconButtonProps } from "@hexocean/braintrust-ui-components";
import { Box, IconButton, Tooltip } from "@hexocean/braintrust-ui-components";
import { InfoIcon } from "@hexocean/braintrust-ui-components/Icons";
import type { ProfileCompletion } from "@js/types/freelancer";

import { ProfileCompletionFeedback } from "../feedback";

export type IconWithProfileCompletionFeedbackProps = {
  missingAreas: ProfileCompletion["missing_areas"];
} & Partial<IconButtonProps>;

export const IconWithProfileCompletionFeedback = ({
  missingAreas,
  variant = "transparent",
  size = "small",
  ...props
}: IconWithProfileCompletionFeedbackProps) => {
  return (
    <Tooltip
      title={
        <Box p={2}>
          <ProfileCompletionFeedback missingAreas={missingAreas} />
        </Box>
      }
    >
      <span>
        <IconButton
          aria-label="how you can improve your profile completion"
          variant={variant}
          size={size}
          style={{
            pointerEvents: "none",
          }}
          {...props}
        >
          <InfoIcon />
        </IconButton>
      </span>
    </Tooltip>
  );
};
