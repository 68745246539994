import React from "react";

import { AppLayout } from "@js/layouts/app";

import { FreelancerInvoicesListingPageContent } from "./content";

const FreelancerInvoicesListingPage = () => (
  <AppLayout pageTitle="Invoices" flexColumn className="wider">
    <FreelancerInvoicesListingPageContent />
  </AppLayout>
);

export default FreelancerInvoicesListingPage;
