import {
  Box,
  List,
  ListItem,
  Typography,
} from "@hexocean/braintrust-ui-components";
import { ModalInstance } from "@js/components/modal";
import type { ATSIntegrationError } from "@js/types/employer";
import { formatDatetime } from "@js/utils";
import { DateTimeFormats } from "@js/utils/date/types";

import type { ATSIntegrationErrorListItem } from ".";

import style from "./style.module.scss";

type IntegrationErrorDetailsListProps = {
  error: ATSIntegrationErrorListItem;
};

const IntegrationErrorDetailsList = ({
  error,
}: IntegrationErrorDetailsListProps) => {
  return (
    <Box className={style.integrationErrorDetailsListContainer}>
      <Typography component="h2" variant="label" pr={6}>
        {error.name} (issue details)
      </Typography>
      <List className={style.integrationErrorDetailsList}>
        {error.details.map((detail) => {
          return (
            <IntegrationErrorDetailsListItem key={detail.id} detail={detail} />
          );
        })}
      </List>
    </Box>
  );
};

const IntegrationErrorDetailsListItem = ({
  detail,
}: {
  detail: ATSIntegrationError;
}) => {
  return (
    <ListItem key={detail.id} disableGutters>
      <Box className={style.integrationErrorDetailsListItemContent}>
        <Typography component="h3" variant="label">
          {formatDatetime(
            detail.last_incident_time || detail.first_incident_time,
            DateTimeFormats["1970-12-31 0:00:00 PM"],
          )}
        </Typography>
        {detail.error_details.map((errorDetail, idx, arr) => {
          return (
            <Typography
              component="p"
              key={errorDetail}
              mb={idx < arr.length - 1 ? 1 : 0}
            >
              {errorDetail}
            </Typography>
          );
        })}
      </Box>
    </ListItem>
  );
};

export const showDetailsModal = (error: ATSIntegrationErrorListItem) => {
  ModalInstance.open({
    className: style.integrationErrorDetailsModal,
    children: <IntegrationErrorDetailsList error={error} />,
    closeButton: true,
  });
};
