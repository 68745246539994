import { useMemo } from "react";
import { Outlet } from "react-router-dom";
import { useLocation } from "react-router-dom";

import {
  Box,
  RegularTab,
  RegularTabs,
  Typography,
} from "@hexocean/braintrust-ui-components";
import { useUser } from "@js/apps/common/hooks";
import { UniversalSearchContextProvider } from "@js/apps/universal-search/context/universal-search-context";
import { RouterLink } from "@js/components/link";
import { normalizePath } from "@js/utils/url";

import { ReferCardsSection, ReferTopSection } from "../../components";
import { Faq } from "../../components/faq";
import { Templates } from "../../components/templates";
import { FreelancerOrEmployerDashboardLayout } from "../layout";

import style from "./style.module.scss";

const EarnPage = () => {
  const user = useUser();
  const { pathname } = useLocation();

  const earnTabs = useMemo(() => {
    const isInReferClientProgram = !!user?.refer_client_program_opted_in_at;
    const isLinkedConnectionsUploaded = !!user?.linked_connections_uploaded_at;
    const shouldDisplayReferProgramTabFirst =
      !isInReferClientProgram && !isLinkedConnectionsUploaded;
    const optInReferClientsProgramTab = {
      label: "More ways to earn",
      path: `/talent/dashboard/earn/refer_clients_program`,
    };

    const referTabs = [
      {
        label: "Your Client referrals",
        path: `/talent/dashboard/earn/refer_clients`,
      },
      {
        label: "Your Talent referrals",
        path: `/talent/dashboard/earn/refer_talent`,
      },
    ];
    if (shouldDisplayReferProgramTabFirst) {
      referTabs.unshift(optInReferClientsProgramTab);
    } else {
      referTabs.push(optInReferClientsProgramTab);
    }

    return referTabs;
  }, [user]);

  const selectedTabVal = useMemo(() => {
    const currentPathNormalized = normalizePath(pathname);

    const currentTab = earnTabs.find(({ path }) => {
      const tabPathNormalized = normalizePath(path);
      return currentPathNormalized.endsWith(tabPathNormalized);
    });

    return currentTab ?? earnTabs[0];
  }, [pathname, earnTabs]);

  return (
    <UniversalSearchContextProvider initialTab="CAREER_HELP">
      <FreelancerOrEmployerDashboardLayout pageTitle="Earn">
        <ReferTopSection />
        <ReferCardsSection />
        <Box mt={5.5} mb={4}>
          <RegularTabs
            aria-label="refer-and-earn-tabs"
            value={selectedTabVal.path}
            classes={{ root: style.underlinedTabs }}
            variant="scrollable"
            scrollButtons={false}
          >
            {earnTabs.map(({ label, path }) => {
              return (
                <RegularTab
                  key={label}
                  value={path}
                  component={RouterLink}
                  to={path}
                  classes={{ root: style.underlinedTab }}
                  label={
                    <Typography component="span" variant="label" size="medium">
                      {label}
                    </Typography>
                  }
                />
              );
            })}
          </RegularTabs>
        </Box>
        <Box sx={{ mb: { xs: 3, md: 4 } }}>
          <Outlet />
        </Box>
        <Faq />
        <Templates />
      </FreelancerOrEmployerDashboardLayout>
    </UniversalSearchContextProvider>
  );
};

export default EarnPage;
