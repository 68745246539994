import React, { useCallback } from "react";

import { Box } from "@hexocean/braintrust-ui-components";
import { useScript } from "@js/hooks";

type TGrnhse = {
  Grnhse?: {
    Iframe: {
      load: () => void;
    };
  };
};

export const GreenhouseJobBoardPage: React.FC = () => {
  const onLoad = useCallback(() => {
    (window as unknown as TGrnhse & Window).Grnhse?.Iframe.load();
  }, []);
  const onUnload = useCallback(() => {
    (window as unknown as TGrnhse & Window).Grnhse = undefined;
  }, []);

  useScript({
    id: "greenhouse_job_board_script_id",
    src: SETTINGS.GREENHOUSE_BRAINTRUST_JOB_BOARD_SCRIPT_URL,
    onLoad,
    onUnload,
  });

  return <Box id="grnhse_app" />;
};
