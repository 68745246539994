import {
  TableCell,
  TableRow,
  useMediaQuery,
} from "@hexocean/braintrust-ui-components";
import type { FreelancerInvoice } from "@js/types/invoices";
import { dateToFromNowDaily, formatCurrency, getEnumLabel } from "@js/utils";

import { MarkAsPaidAction } from "../../mark-as-paid-action";
import { FreelancerSingleInvoiceActionMenu } from "../freelancer-single-invoice-action-menu";

import { useFreelancerSingleInvoiceRow } from "./use-freelancer-single-invoice-row";

export type FreelancerSingleInvoiceRowProps = {
  invoice: FreelancerInvoice;
  onlyMobileMenu: boolean;
  prevPath?: string;
  onInvoicesPaid?: () => void;
  cancelFreelancerInvoice: (id: number) => Promise<unknown>;
};

export const FreelancerSingleInvoiceRow = ({
  invoice,
  onlyMobileMenu,
  prevPath,
  onInvoicesPaid,
  cancelFreelancerInvoice,
}: FreelancerSingleInvoiceRowProps) => {
  const isMobile = useMediaQuery("md");
  const { handleKeyPress, openInvoicePreview, markInvoicesAsPaid } =
    useFreelancerSingleInvoiceRow({
      onInvoicesPaid,
      invoice,
    });

  return (
    <TableRow
      key={invoice.id}
      tabIndex={0}
      onKeyDown={handleKeyPress}
      onClick={openInvoicePreview}
      className="invoices-listing-item"
    >
      <TableCell className={`invoices-listing-item-status ${invoice.status}`}>
        {getEnumLabel(ENUMS.InvoiceStatusLabels, invoice.status)}
      </TableCell>
      <TableCell>{invoice.employer_name}</TableCell>
      <TableCell className="gray bw">{invoice.number}</TableCell>
      <TableCell align="right" className="gray">
        {invoice.issued_at}
      </TableCell>
      <TableCell align="right" className={`gray ${invoice.status}`}>
        {dateToFromNowDaily(invoice.due_date)}
      </TableCell>
      <TableCell align="right">{formatCurrency(invoice.gross_total)}</TableCell>
      {!isMobile && !onlyMobileMenu && (
        <TableCell
          onClick={(ev) => ev.stopPropagation()}
          className="invoices-listing-item-actions"
        >
          <MarkAsPaidAction
            invoices={[invoice]}
            markAsPaid={markInvoicesAsPaid}
          />
        </TableCell>
      )}
      <TableCell
        onClick={(ev) => ev.stopPropagation()}
        className="invoices-listing-item-actions-menu"
      >
        <FreelancerSingleInvoiceActionMenu
          cancelFreelancerInvoice={cancelFreelancerInvoice}
          onlyMobileMenu={!!onlyMobileMenu}
          invoice={invoice}
          prevPath={prevPath}
          onMarkAsPaid={(invoices) => {
            markInvoicesAsPaid(invoices);
          }}
        />
      </TableCell>
    </TableRow>
  );
};
