import { API } from "@js/api";

export type InitCoinbasePaymentResponseWithExistingWallet = {
  wallet_creation_in_progress: false;
  safe_wallet_address: string;
  payment_uuid: string;
};

type InitCoinbasePaymentResponseWithoutExistingWallet = {
  wallet_creation_in_progress: true;
  safe_wallet_address: null;
  payment_uuid: null;
};

export type InitCoinbasePaymentResponse =
  | InitCoinbasePaymentResponseWithExistingWallet
  | InitCoinbasePaymentResponseWithoutExistingWallet;

const coinbaseApi = API.injectEndpoints({
  endpoints: (build) => ({
    initCoinbasePayment: build.query<
      | InitCoinbasePaymentResponseWithExistingWallet
      | InitCoinbasePaymentResponseWithoutExistingWallet,
      void
    >({
      query: () => ({
        url: `/onramp_initial_payment_info/`,
        method: "GET",
      }),
    }),
  }),
});

export const { useLazyInitCoinbasePaymentQuery, useInitCoinbasePaymentQuery } =
  coinbaseApi;
