import cs from "classnames";

import {
  Box,
  Checkbox,
  Stack,
  Typography,
} from "@hexocean/braintrust-ui-components";
import type { ATSJob } from "@js/types/jobs";

import { useSelectATSJob } from "../hooks";

import styles from "../style.module.scss";

type JobItemProps = {
  job: ATSJob;
  index: number;
  jobsCount: number;
  disabled: boolean;
};

export const JobItem = ({ job, index, jobsCount, disabled }: JobItemProps) => {
  const { toggleSelect, checkIfAnyJobIsSelected } = useSelectATSJob();
  const isJobSelected = checkIfAnyJobIsSelected(job.external_id);

  return (
    <Stack
      className={cs(styles.jobItem, { [styles.jobItemDisabled]: disabled })}
      borderColor={isJobSelected ? "var(--black)" : "var(--grey-5)"}
    >
      <Box display="flex" alignItems="center" overflow="hidden">
        <Box display="flex" alignItems="center" flexShrink={0}>
          <Typography
            component="span"
            size="small"
            color="grey-2"
            width={`${jobsCount.toString().length * 10}px`}
            fontWeight={500}
          >
            {index + 1}
          </Typography>
          <Checkbox
            onChange={() => toggleSelect(job.external_id)}
            checked={!!isJobSelected}
            aria-label="Select job"
            disabled={disabled}
            noMargin
            checkedColor="yellow"
            sx={{ flexShrink: 0 }}
          />
        </Box>
        <Typography component="span" size="small" ellipsis>
          {job.title}
        </Typography>
      </Box>
      <Box
        display="flex"
        alignItems="center"
        flexShrink={0}
        sx={{ width: { xs: "66px", sm: "126px" } }}
      >
        <Typography component="span" size="small" ellipsis>
          {job.remote_id}
        </Typography>
      </Box>
    </Stack>
  );
};
