import { useCallback } from "react";
import _ from "underscore";

import { useAppDispatch } from "@js/hooks";
import { useEffectRef } from "@js/hooks/use-effect-ref";
import type { JobDraft, JobFormValues } from "@js/types/jobs";
import { getObjectsDifferences } from "@js/utils/data";

import { setFieldsToDisplayChangesSavedMarker } from "../../actions";
import {
  isUpdateJobDraftPayload,
  prepareJobDraftPayload,
  prepareLatestDraftDataToSave,
} from "../../utils";
import { useJobFormUpdateJobDraftMutation } from "../use-job-form-update-job-draft-mutation";

export const useSaveLastJobDraft = () => {
  const dispatch = useAppDispatch();
  const [updateJobDraft, { isLoading }] = useJobFormUpdateJobDraftMutation();

  const isLoadingRef = useEffectRef(isLoading);
  const saveLastJobDraft = useCallback(
    ({
      values,
      lastSavedDraft,
    }: {
      values: JobFormValues;
      lastSavedDraft: JobDraft | undefined;
    }) => {
      if (isLoadingRef.current) {
        return;
      }

      if (_.isEmpty(values)) {
        return;
      }

      const draftDataToSave = prepareLatestDraftDataToSave(
        values,
        lastSavedDraft,
      );
      if (!draftDataToSave) {
        return;
      }

      const preparedJobDraftPayload = prepareJobDraftPayload({
        data: draftDataToSave,
      });
      if (!isUpdateJobDraftPayload(preparedJobDraftPayload)) {
        return;
      }

      // We can't rely on prevValues as it won't include every changed field, because this function is debounced
      const changedFields = getObjectsDifferences(
        draftDataToSave,
        lastSavedDraft,
      );
      dispatch(setFieldsToDisplayChangesSavedMarker(changedFields));

      return updateJobDraft(preparedJobDraftPayload);
    },
    [dispatch, updateJobDraft, isLoadingRef],
  );

  return { saveLastJobDraft };
};
