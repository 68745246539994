import React from "react";
import { Field, reduxForm } from "redux-form";

import { Box } from "@hexocean/braintrust-ui-components";
import { CompanyNodeSearchComboboxField } from "@js/components/autocomplete-new/combobox/company-node";
import { Form } from "@js/forms/components/form";
import { required } from "@js/forms/validators";
import type { CompanyNodeBase } from "@js/types/common";

const ADD_RECIPIENTS_FORM_ID = "add-recipients-for-stripe";

type OwnProps = {
  recipients: Array<CompanyNodeBase>;
  children: React.ReactNode;
};

type FormData = {
  company_node?: string;
  code?: string;
  code_is_required?: boolean;
  stripe_withdrawal_method: number;
};

export const AddRecipientsForm = reduxForm<FormData, OwnProps>({
  form: ADD_RECIPIENTS_FORM_ID,
  enableReinitialize: true,
})(({ submit, submitting, recipients, children }) => {
  return (
    <Box>
      <Form onSubmit={submitting ? null : submit}>
        <Box>
          <Field
            name="company_node"
            component={CompanyNodeSearchComboboxField}
            options={recipients}
            validate={[required]}
            label="Select Company Node for Stripe account"
          />
          {children}
        </Box>
      </Form>
    </Box>
  );
});
