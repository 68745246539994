import type { WrappedFieldProps } from "redux-form";
import { Field } from "redux-form";

import { Box, Button } from "@hexocean/braintrust-ui-components";
import type { ReportPayload } from "@js/apps/give-and-get-help/hooks";
import { ModalInstance } from "@js/components/modal";
import { createFormInstance } from "@js/forms/components";
import { RadioListField, TextareaField } from "@js/forms/fields";
import { maxLength, requiredWithSpecificMessage } from "@js/forms/validators";

import { ReportBody } from "./report-body";

export type ReportType =
  | "post"
  | "comment"
  | "user"
  | "message"
  | "offer"
  | "refund"
  | "review";
type ReportFormProps = {
  onSubmit: ((reportReason: ReportPayload) => Promise<unknown>) | (() => void);
  closeModal: () => void;
  type: ReportType;
  reasons: Array<{ label: string; value: string }>;
  name?: string;
};

type FormData = {
  reason: string;
  reason_other?: string;
  message_to_moderator?: string;
};

const maxLengthValidator = maxLength(1000);
const ReportFormInstance = createFormInstance<FormData, unknown>("report-form");

export const ReportForm = ({
  type,
  onSubmit,
  closeModal,
  name,
  reasons,
}: ReportFormProps) => {
  const handleSubmit = (values: FormData) => {
    return onSubmit({ ...values });
  };

  const handleSubmitSuccess = () => {
    closeModal();
  };

  return (
    <ReportFormInstance
      onSubmit={handleSubmit}
      onSubmitSuccess={handleSubmitSuccess}
    >
      <Box display="flex" flexDirection="column">
        <ReportBody name={name || "Helper"} reportType={type}>
          <Field
            style={{ marginBottom: "16px" }}
            name="reason"
            options={reasons}
            component={RadioListField}
            yellowOutline
            validate={[
              requiredWithSpecificMessage("This question is required."),
            ]}
            borderRadio={false}
          />
          <Field
            name="message_to_moderator"
            component={ReasonField}
            validate={[maxLengthValidator]}
            reportType={type}
          />
        </ReportBody>
        <Box ml="auto" mt={2}>
          <Button variant="destructive" shape="squared" type="submit">
            Submit report
          </Button>
        </Box>
      </Box>
    </ReportFormInstance>
  );
};

const ReasonField = ({
  input,
  meta,
  reportType,
}: WrappedFieldProps & { reportType: ReportType }) => {
  const label =
    reportType === "offer"
      ? "Message to the moderator"
      : "Please add an explanation";

  return (
    <TextareaField
      placeholder="Explain why you are submitting this report."
      id={"message_to_moderator"}
      input={input}
      meta={meta}
      maxLength={1000}
      label={label}
    />
  );
};

export const handleOpenReportModal = ({
  type,
  onSubmit,
  name,
  reasons,
}: Omit<ReportFormProps, "closeModal">) => {
  ModalInstance.open({
    closeButton: true,
    closeButtonProps: {
      variant: "tertiary",
      size: "x-small",
    },
    keepOnBackdropClick: true,
    className: "report-modal",
    children: (
      <ReportForm
        type={type}
        onSubmit={onSubmit}
        closeModal={ModalInstance.close}
        name={name}
        reasons={reasons}
      />
    ),
  });
};
