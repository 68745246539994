import type { HTMLAttributes } from "react";
import match from "autosuggest-highlight/match";
import parse from "autosuggest-highlight/parse";
import cs from "classnames";

import type { AutocompleteRenderOptionState } from "@hexocean/braintrust-ui-components";
import { Typography } from "@hexocean/braintrust-ui-components";
import type { Hashtag } from "@js/apps/give-and-get-help/types";

type HashtagListItemProps = {
  optionProps: HTMLAttributes<HTMLLIElement>;
  option: Hashtag;
  optionState: AutocompleteRenderOptionState;
  isFocused: boolean;
  onMouseEnter: () => void;
};

export const HashtagListItem = ({
  optionProps,
  option,
  optionState,
  isFocused,
  onMouseEnter,
}: HashtagListItemProps) => {
  const { className } = optionProps;
  const { inputValue } = optionState;

  const matches = match(option.name, inputValue, { insideWords: false });
  const parts = parse(option.name, matches);

  return (
    <li
      {...optionProps}
      className={cs(className, "hashtag-menu__item", {
        "hashtag-menu__item--focused": isFocused,
      })}
      onMouseEnter={onMouseEnter}
    >
      <Typography component="p" variant="paragraph" size="medium">
        {parts.map(({ text, highlight }) => (
          <span
            key={text}
            className={cs({
              "hashtag-menu__item-content--highlighted": highlight,
            })}
          >
            {text}
          </span>
        ))}
      </Typography>
    </li>
  );
};
