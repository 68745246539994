import { IconButton } from "@hexocean/braintrust-ui-components";
import { CloseIcon } from "@hexocean/braintrust-ui-components/Icons";

import style from "./style.module.scss";

type BarCloseButtonProps = {
  onClose: () => void;
};

export const BarCloseButton = ({ onClose }: BarCloseButtonProps) => {
  return (
    <IconButton
      variant="tertiary"
      aria-label="Close ATS info bar"
      className={style.atsInfoBarCloseButton}
      onClick={onClose}
    >
      <CloseIcon />
    </IconButton>
  );
};
