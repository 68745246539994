import { useMemo } from "react";
import type { WrappedFieldProps } from "redux-form";

import type { FormValue } from "@hexocean/braintrust-ui-components";
import { transformBudgetOptions } from "@js/apps/give-and-get-help/components/category-and-budget-utils";
import { OptionTileButtons } from "@js/components/option-tile-buttons";
import type { OptionWithPrimitiveValue } from "@js/types/common";

import { CustomRateButton } from "./components";

export type BudgetFieldProps = WrappedFieldProps & {
  options: OptionWithPrimitiveValue[];
  onChange?: (data: FormValue) => void;
};

export const BudgetField = ({
  input,
  meta,
  options,
  onChange,
}: BudgetFieldProps) => {
  const paymentButtonsDisabled = !SETTINGS.GIVE_GET_HELP_BTRST_BUTTONS_ENABLED;
  const paymentButtonsHidden = SETTINGS.GIVE_GET_HELP_BTRST_BUTTONS_HIDDEN;

  const processedOptions = useMemo(() => {
    const transformedOptions = transformBudgetOptions(
      options,
      paymentButtonsDisabled,
      paymentButtonsHidden,
    );

    return transformedOptions;
  }, [options, paymentButtonsDisabled, paymentButtonsHidden]);

  const handleRateChange = (data: FormValue) => {
    if (data === input.value) {
      input.onChange(null);
    } else {
      input.onChange(data);
      onChange?.(data);
    }
  };

  return (
    <OptionTileButtons
      value={input.value}
      onChange={handleRateChange}
      error={meta.error}
      options={processedOptions}
      className="budget-field__wrapper"
      selectedClassName="selected"
      selectedVariant="medium-violet"
      renderCustomOptionButton={({
        isSelected,
        onClick,
        itemA11yProps,
        handleChange,
      }) => (
        <CustomRateButton
          options={options}
          input={input}
          meta={meta}
          onClick={onClick}
          isSelected={isSelected}
          paymentButtonsDisabled={paymentButtonsDisabled}
          paymentButtonsHidden={paymentButtonsHidden}
          handleChange={handleChange}
          itemA11yProps={itemA11yProps}
        />
      )}
    />
  );
};
