import type { MouseEvent } from "react";
import { useState } from "react";

import type { ChangeJobVisibilityFormData } from "@js/apps/employer/forms/change-job-visibility";
import { useAppDispatch } from "@js/hooks";
import type { Job } from "@js/types/jobs";
import { isEllipsisVisible } from "@js/utils";

import { jobsApi } from "../../api";
import { patchJob } from "../../apps/create-job/actions";
import { useGetDefaultActionsContextValue } from "../../hooks/get-default-job-actions";

type UseJobManagementHeaderProps = {
  job: Job;
};

export const useJobManagementHeader = ({
  job,
}: UseJobManagementHeaderProps) => {
  const dispatch = useAppDispatch();
  const jobActionsContextValue = useGetDefaultActionsContextValue();

  const onChangeVisibility = ({ is_private }: ChangeJobVisibilityFormData) => {
    patchJob(job.id, { is_private }).then(() => {
      dispatch(jobsApi.util.invalidateTags([{ type: "Jobs", id: job.id }]));
    });
  };

  const [showJobTitleTooltip, setShowJobTitleTooltip] = useState(false);
  const updateTooltipVisibility = (event: MouseEvent<HTMLSpanElement>) => {
    if (!event.target || !(event.target instanceof Element)) return;
    setShowJobTitleTooltip(isEllipsisVisible(event.target));
  };

  return {
    jobActionsContextValue,
    showJobTitleTooltip,
    updateTooltipVisibility,
    onChangeVisibility,
  };
};
