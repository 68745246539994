import { saveViewEventNamesToBackend } from "@js/routes/gather-routes/actions";
import { mapRouteToViewEvent } from "@js/routes/gather-routes/map-route-to-view-event";
import {
  getRouteRules,
  isIgnoredRoute,
  mapRoutesWithRulesToRoutesWithAnonymityFlags,
} from "@js/routes/gather-routes/utils";
import { stripSlashes } from "@js/utils";

import type { RoutesRootObject } from "./gather-routes-types";

type RouteFlags = { is_anonymous: boolean; might_be_anonymous: boolean };
type NestedRoute = {
  parent: string;
  childrenRoutes: GatherRoutesReturnType;
  rules: string[];
};
type GatherRoutesReturnType = NestedRoute[];
type GatherRoutesType = (routes: RoutesRootObject) => GatherRoutesReturnType;
export type MappedEventWithFlags = {
  name: string;
} & RouteFlags;

export type MappedRouteWithFlags = {
  path: string;
} & RouteFlags;

export const gatherFrontendRoutes = (frontendRoutes: JSX.Element) => () => {
  const parsedFrontendRoutes = parseFrontendRoutes(frontendRoutes);

  saveViewEventNamesToBackend(parsedFrontendRoutes).catch((e) =>
    console.error(e),
  );
};

export const parseFrontendRoutes = (frontendRoutes: JSX.Element) => {
  const routes = gatherRoutes(frontendRoutes as unknown as RoutesRootObject);
  const squashedRoutes = squashRoutes(routes);
  const routesWithAnonymityFlags =
    mapRoutesWithRulesToRoutesWithAnonymityFlags(squashedRoutes);
  const formattedRoutes = reformatRoutes(routesWithAnonymityFlags);

  const eventNames = formattedRoutes.map((route): MappedEventWithFlags => {
    return {
      is_anonymous: route.is_anonymous,
      might_be_anonymous: route.might_be_anonymous,
      name: mapRouteToViewEvent(route.path),
    };
  });
  const deduplicatedEventNames = eventNames.reduce<MappedEventWithFlags[]>(
    (acc, curr) => {
      if (!acc.some((item) => item.name === curr.name)) {
        acc.push(curr);
      }
      return acc;
    },
    [],
  );

  const sortedEventNames = deduplicatedEventNames.sort((aEvent, bEvent) =>
    aEvent.name.localeCompare(bEvent.name),
  );

  return sortedEventNames;
};

export const findChildrenArray = (
  routes: RoutesRootObject,
  currDepth = 0,
  maxDepth = 5,
): RoutesRootObject[] | RoutesRootObject => {
  //required to prevent infinite recursion
  if (currDepth === maxDepth) return routes;
  if (Array.isArray(routes)) return routes;

  if ("children" in routes.props && routes.props.children) {
    if (Array.isArray(routes.props.children)) {
      return routes.props.children;
    } else if ("props" in routes.props.children) {
      if ("path" in routes.props.children.props) {
        return [routes.props.children];
      }
      return findChildrenArray(routes.props.children, currDepth + 1, maxDepth);
    }
  }
  return [];
};

export const gatherRoutes: GatherRoutesType = (routes) => {
  const allRoutes: GatherRoutesReturnType = [];

  //it might be nested in a few layers, so we need to find the children array
  const childrenArray = findChildrenArray(routes);

  if (!Array.isArray(childrenArray)) {
    return allRoutes;
  }

  //filter is needed for cases when we conditionally place some route
  childrenArray.filter(Boolean).forEach((child) => {
    if (Array.isArray(child)) {
      child.forEach((c) => {
        const rules = getRouteRules(c);

        const isIgnored = isIgnoredRoute(c);
        if (isIgnored) return;

        const childrenRoutes = gatherRoutes(c);
        if (childrenRoutes.length > 0) {
          allRoutes.push({
            parent: c.props.path ?? "",
            childrenRoutes,
            rules,
          });
        } else {
          allRoutes.push({
            parent: c.props.path ?? "",
            childrenRoutes: [],
            rules,
          });
        }
      });
      return;
    }

    const isIgnored = isIgnoredRoute(child);
    if (isIgnored) return;

    const rules = getRouteRules(child);
    if (child && "children" in child.props && child.props.children) {
      allRoutes.push({
        parent: ("path" in child.props && child.props.path) || "",
        childrenRoutes: gatherRoutes(child),
        rules,
      });
    } else if (child && "path" in child.props && child.props.path) {
      allRoutes.push({ parent: child.props.path, childrenRoutes: [], rules });
    }
  });

  return allRoutes;
};

export const squashRoutes = (routes: GatherRoutesReturnType) => {
  const allRoutes: { path: string; rules: string[] }[] = [];

  routes.forEach((route) => {
    // If it's an object, it's a parent route with children, we assume that parent is a complete route
    //we could check if it's a complete route by checking its element, or if first child is `index` route, but it's not worth the effort
    allRoutes.push({ path: route.parent, rules: route.rules });

    const childRoutes = route.childrenRoutes;
    const childRoutesWithNestedRoutesAndParentPath = childRoutes.map(
      (childRoute): NestedRoute => ({
        ...childRoute,
        parent: route.parent + "/" + childRoute.parent,
        rules: [...route.rules, ...childRoute.rules],
      }),
    );

    allRoutes.push(...squashRoutes(childRoutesWithNestedRoutesAndParentPath));
  });

  return allRoutes.filter(Boolean);
};

export const reformatRoutes = (
  routes: MappedRouteWithFlags[],
): MappedRouteWithFlags[] => {
  return routes.map((route) => {
    //removes asterisks, replaces double slashes with single slash, and removes trailing slash
    return {
      ...route,
      path: stripSlashes(
        replaceDoubledSlashesWithSingleSlash(removeAsterisks(route.path)),
      ),
    };
  });
};

export const removeAsterisks = (route: string) => {
  return route.replaceAll(/\*/gim, "");
};

export const replaceDoubledSlashesWithSingleSlash = (route: string) => {
  return route.replaceAll(/\/{2,}/gim, "/");
};
