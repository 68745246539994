import _ from "underscore";

import type {
  CommentsEventPayload,
  GetPostsParams,
  PostsEventPayload,
} from "@js/apps/give-and-get-help/types";
import { assertUnreachable } from "@js/utils";

import { isCommentRelatedEvent } from "../comments-update-recipe";

import type { Draft } from "./helpers";
import {
  addPostCommentReaction,
  addPostReaction,
  addVote,
  deletePostCommentReaction,
  deletePostReaction,
  isPostOrCommentsRelatedBatchedEvents,
  isPostRelatedEvent,
  isSpacePostAddedRelatedEvent,
  patchPostsOnCommentDeleted,
  patchPostsOnPostAdded,
  patchPostsOnPostDeleted,
  updatePost,
  updatePostComment,
  updatePostOnCommentAdd,
} from "./helpers";

type PostsUpdateRecipeOptions = {
  isMyPostsPage?: boolean;
};

type PostsUpdateRecipe = (
  args: GetPostsParams,
  opts?: PostsUpdateRecipeOptions,
) => (event: MessageEvent, draft: Draft) => void;

// Works for Feed Posts and My Posts
export const postsUpdateRecipe: PostsUpdateRecipe =
  (args, opts) => (event, draft) => {
    handleAppropriateEvent(event, draft, args, opts);
  };

const handleAppropriateEvent = (
  event: MessageEvent,
  draft: Draft,
  args: GetPostsParams,
  opts?: PostsUpdateRecipeOptions,
) => {
  if (!event.data) return;

  if (isCommentRelatedEvent(event)) {
    handleCommentsUpdateEvent({ draft, eventData: event.data });

    return;
  }

  if (isSpacePostAddedRelatedEvent(event)) {
    patchPostsOnPostAdded({ addedPost: event.data.data, draft, args });

    return;
  }

  if (isPostRelatedEvent(event) && opts?.isMyPostsPage) {
    return handleMyPostsUpdateEvent({ eventData: event.data, draft });
  }

  if (isPostOrCommentsRelatedBatchedEvents(event)) {
    event.data.data.forEach((eventData) => {
      handleAppropriateEvent(
        {
          ...event,
          data: {
            ..._.omit(eventData, "event_type"),
            broadcast_type: eventData.event_type,
          },
        },
        draft,
        args,
        opts,
      );
    });

    return;
  }

  if (isPostRelatedEvent(event)) {
    return handlePostsUpdateEvent({ eventData: event.data, draft });
  }
};

export const handlePostsUpdateEvent = ({
  eventData,
  draft,
}: {
  eventData: NonNullable<PostsEventPayload>;
  draft: Draft;
}) => {
  const broadcast_type = eventData.broadcast_type;
  switch (broadcast_type) {
    case ENUMS.BroadcastType.POST_ADDED: {
      break;
    }
    case ENUMS.BroadcastType.POST_UPDATED:
      updatePost(eventData, draft);
      break;

    case ENUMS.BroadcastType.POST_DELETED:
    case ENUMS.BroadcastType.POST_MOVED_TO_SPACE:
      patchPostsOnPostDeleted(eventData.data.id, draft);
      break;

    case ENUMS.BroadcastType.REACTION_ADDED:
      addPostReaction(eventData, draft);
      break;

    case ENUMS.BroadcastType.REACTION_DELETED:
      deletePostReaction(eventData, draft);
      break;

    case ENUMS.BroadcastType.VOTE_ADDED:
      addVote(eventData, draft);
      break;

    case ENUMS.BroadcastType.YOU_VOTED:
      // do nothing - optimistic update
      break;

    default:
      assertUnreachable(broadcast_type);
  }
};

export const handleMyPostsUpdateEvent = ({
  eventData,
  draft,
}: {
  eventData: NonNullable<PostsEventPayload>;
  draft: Draft;
}) => {
  const broadcast_type = eventData.broadcast_type;
  switch (broadcast_type) {
    case ENUMS.BroadcastType.POST_ADDED: {
      break;
    }
    case ENUMS.BroadcastType.POST_UPDATED:
      updatePost(eventData, draft);
      break;

    case ENUMS.BroadcastType.POST_DELETED:
      patchPostsOnPostDeleted(eventData.data.id, draft);
      break;
    case ENUMS.BroadcastType.POST_MOVED_TO_SPACE:
      //Post is not deleted, so it should stay in my posts page
      break;

    case ENUMS.BroadcastType.REACTION_ADDED:
      addPostReaction(eventData, draft);
      break;

    case ENUMS.BroadcastType.REACTION_DELETED:
      deletePostReaction(eventData, draft);
      break;

    case ENUMS.BroadcastType.VOTE_ADDED:
      addVote(eventData, draft);
      break;

    case ENUMS.BroadcastType.YOU_VOTED:
      // do nothing - optimistic update
      break;

    default:
      assertUnreachable(broadcast_type);
  }
};

export const handleCommentsUpdateEvent = ({
  eventData,
  draft,
}: {
  eventData: NonNullable<CommentsEventPayload>;
  draft: Draft;
}) => {
  const broadcast_type = eventData.broadcast_type;

  switch (broadcast_type) {
    case ENUMS.BroadcastType.POST_ADDED: {
      updatePostOnCommentAdd(eventData, draft);
      break;
    }
    case ENUMS.BroadcastType.POST_UPDATED:
      updatePostComment(eventData, draft);
      break;

    case ENUMS.BroadcastType.POST_DELETED:
      patchPostsOnCommentDeleted({ draft, deletedComment: eventData.data });
      break;

    case ENUMS.BroadcastType.REACTION_ADDED:
      addPostCommentReaction(eventData, draft);
      break;

    case ENUMS.BroadcastType.REACTION_DELETED:
      deletePostCommentReaction(eventData, draft);
      break;

    default:
      assertUnreachable(broadcast_type);
  }
};
