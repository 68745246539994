import { Field } from "redux-form";

import { ReduxFormButtonSelectFactory } from "@js/forms/fields/button-select";

import { walletActivityFiltersOptions } from "../constants";
import { WalletActivityFiltersButtons } from "../wallet-activity-filters-buttons";

export const ButtonSelectField = ReduxFormButtonSelectFactory(
  WalletActivityFiltersButtons,
);

export const CategoriesFilterField = () => {
  return (
    <Field
      name="category"
      multiple={false}
      options={walletActivityFiltersOptions}
      variant="white-violet"
      selectedVariant="violet-with-dark-border--violet-text"
      selectedFontWeight={500}
      fontWeight={400}
      size="medium"
      component={ButtonSelectField}
    />
  );
};
