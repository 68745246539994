/**
 * Pairs session tokens with corresponding place IDs.
 *
 * @param sessionTokenFormFieldValues - An array of session token form field values.
 *
 * @param placeIdFormFieldValues - An array of place ID form field values.
 *
 * @returns An array of objects with paired place IDs and session tokens.
 *
 * @description This function creates pairs of session tokens and corresponding place IDs,
 * generating an array of objects where each object represents a pair. If either the session tokens or place IDs
 * are not provided, an empty array is returned.
 *
 * @example
 * // Example Input:
 * const sessionTokens = ["0b7288e8-3d55-4e4a-a155-78f8f837463e", "8d593531-7c24-4a10-87f5-c7479b62a611"];
 *
 * const placeIds = ["ChIJ0RhONcBEFkcRv4pHdrW2a7Q", "ChIJAZ-GmmbMHkcR_NPqiCq-8HI"];
 *
 * pairTokensWithIds(sessionTokens, placeIds);
 *
 * // Example Output:
 *  [
 *    { id: "ChIJ0RhONcBEFkcRv4pHdrW2a7Q",
 *      session_token: "0b7288e8-3d55-4e4a-a155-78f8f837463e"
 *    },
 *
 *    { id: "ChIJAZ-GmmbMHkcR_NPqiCq-8HI",
 *      session_token: "8d593531-7c24-4a10-87f5-c7479b62a611"
 *    }
 *  ]
 */
const pairSessionTokensWithPlaceIds = (
  placeIdFormFieldValues: string[] | null,
  sessionTokenFormFieldValues: string[] | null,
) => {
  if (!sessionTokenFormFieldValues?.length || !placeIdFormFieldValues) {
    return [];
  }

  return sessionTokenFormFieldValues.map((sessionToken, index) => ({
    id: placeIdFormFieldValues[index],
    session_token: sessionToken,
  }));
};

export { pairSessionTokensWithPlaceIds };
