import type { ReactNode } from "react";
import { Component } from "react";
import type { ConnectedProps } from "react-redux";
import { connect } from "react-redux";
import type { To } from "react-router-dom";

import { buildFiltersURL, getChangeFilters } from "@js/apps/common/filters";
import type { WithRouterProps } from "@js/hooks";
import { withRouter } from "@js/hooks";
import type { RootState } from "@js/store";
import type { FreelancerInvoice } from "@js/types/invoices";
import { getURL } from "@js/utils";
import { mapTypedDispatchToProps } from "@js/utils/store";

import { cancelFreelancerInvoice as cancelFreelancerInvoiceAction } from "../../actions";
import type { InvoiceFiltersFetchParams } from "../../filters";

type InvoicesListingPageContainerClassType = ConnectedProps<typeof connector> &
  WithRouterProps & {
    queryParams: Record<string, any>;
    action: (filters: Partial<InvoiceFiltersFetchParams>) => void;
    render(
      renderData: {
        page?: number;
        total: number;
        loading: boolean;
        filters: Partial<InvoiceFiltersFetchParams>;
        fetchInvoices: () => void;
        changeFilters: (newFilters: Partial<InvoiceFiltersFetchParams>) => void;
        cancelFreelancerInvoice: (id: FreelancerInvoice["id"]) => Promise<void>;
        canFreelancerCopyLatestInvoice: boolean;
        copyFreelancerLatestInvoiceId?: FreelancerInvoice["id"];
      } & Omit<ConnectedProps<typeof connector>, "lastInvoice" | "dispatch">,
    ): ReactNode;
  };

class InvoicesListingPageContainer extends Component<InvoicesListingPageContainerClassType> {
  componentDidMount() {
    this.fetchInvoices();
  }

  componentDidUpdate(prevProps) {
    if (
      JSON.stringify(this.filters) !== JSON.stringify(prevProps.queryParams)
    ) {
      this.fetchInvoices();
    }
  }

  get filters() {
    return this.props.queryParams;
  }

  fetchInvoices = () => {
    this.props.action(this.filters);
  };

  cancelFreelancerInvoice = (id: FreelancerInvoice["id"]) => {
    return this.props.dispatch(cancelFreelancerInvoiceAction(id)).then(() => {
      this.fetchInvoices();
    });
  };

  changeFilters = (newFilters: Partial<InvoiceFiltersFetchParams>) => {
    const { navigate } = this.props;
    if (JSON.stringify(newFilters) !== JSON.stringify(this.filters)) {
      const to: To = getURL(
        buildFiltersURL(getChangeFilters(this.filters, newFilters)).toString(),
      );
      navigate(to);
    }
  };

  shouldComponentUpdate(nextProps) {
    return JSON.stringify(this.props) !== JSON.stringify(nextProps);
  }

  render() {
    const {
      render,
      invoiceList,
      total,
      loading,
      invoicesSummary,
      lastInvoice,
    } = this.props;

    return render({
      invoicesSummary,
      invoiceList,
      page: this.filters.page as number | undefined,
      total,
      loading,
      filters: this.filters,
      fetchInvoices: this.fetchInvoices,
      changeFilters: this.changeFilters,
      cancelFreelancerInvoice: this.cancelFreelancerInvoice,
      canFreelancerCopyLatestInvoice: Boolean(lastInvoice?.id),
      copyFreelancerLatestInvoiceId: lastInvoice?.id,
    });
  }
}

const mapStateToProps = (state: RootState) => ({
  invoicesSummary: state.invoices.invoicesSummary,
  invoiceList: state.invoices.invoiceList,
  total: state.invoices.total,
  loading:
    state.invoices.fetchingInvoiceList || state.freelancer.fetchingJobList,
  lastInvoice: state.invoices.lastInvoice,
});

const connector = connect(mapStateToProps, mapTypedDispatchToProps);

export default withRouter(connector(InvoicesListingPageContainer));
