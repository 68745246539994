import { Avatar, Box, Typography } from "@hexocean/braintrust-ui-components";
import type { User } from "@js/types/auth";

type ReviewTargetProps = {
  reviewer: User;
};

export const ReviewTarget = ({ reviewer }: ReviewTargetProps) => {
  return (
    <Box className="review-target" p={2} mb={1.5} borderRadius={4}>
      <Avatar alt="" size="lg" src={reviewer} />
      <Typography
        variant="paragraph"
        component="p"
        size="medium"
        align="center"
        fontWeight={500}
        fontSize={1.125}
        mt={1}
      >
        {reviewer.first_name}
      </Typography>
    </Box>
  );
};
