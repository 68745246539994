import React from "react";
import cs from "classnames";

import type { SwiperClass } from "@hexocean/braintrust-ui-components";
import {
  Box,
  Button,
  Typography,
  useSwiper,
  useSwiperSlide,
} from "@hexocean/braintrust-ui-components";
import { useMediaQuery } from "@hexocean/braintrust-ui-components/hooks";
import { Emoji } from "@js/components/emoji";

import styles from "./style.module.scss";

export const HelloEmoji = () => (
  <Box className={styles.helloEmoji}>
    <Emoji emoji="🙌" size="16px" />
  </Box>
);

type IntroductionSlideProps = {
  header: string;
  subheader: string;
  images: { [key: string]: string };
  onButtonClick: () => void;
  buttonLabel: string;
};

type IntroductionSlidePaginationProps = {
  swiper: SwiperClass;
};

const Pagination = ({ swiper }: IntroductionSlidePaginationProps) => {
  return (
    <Box
      className={`introduction-modal-swiper-pagination swiper-pagination-clickable swiper-pagination-bullets swiper-pagination-horizontal`}
    >
      {swiper?.pagination?.bullets?.map((_, idx) => {
        return (
          // Disable click-events-have-key-events as we have separate button to navigate pagination bullets by keyboard
          // eslint-disable-next-line jsx-a11y/click-events-have-key-events
          <span
            onClick={() => {
              swiper.slideTo(idx);
            }}
            // eslint-disable-next-line react/no-array-index-key
            key={idx}
            role="button"
            className={cs(
              "swiper-pagination-bullet",
              "swiper-pagination-bullet-clickable",
              swiper.activeIndex === idx && "swiper-pagination-bullet-active",
            )}
          ></span>
        );
      })}
    </Box>
  );
};

export const IntroductionSlide = ({
  header,
  subheader,
  images,
  onButtonClick,
  buttonLabel,
}: IntroductionSlideProps): JSX.Element => {
  const isSmall = useMediaQuery("sm");
  const imageKey = isSmall ? "sm" : "lg";
  const swiper = useSwiper();
  useSwiperSlide();
  return (
    <>
      <Box mb={2} display="flex" justifyContent="center">
        <img
          src={images[imageKey]}
          alt="GGH Introduction image"
          width={isSmall ? "343px" : "632px"}
          height={isSmall ? "192px" : "348px"}
        />
      </Box>
      <Box className={styles.introductionSlideDescription}>
        <Pagination swiper={swiper} />
        <Typography
          component="h1"
          variant="title"
          className={isSmall ? "" : "swiper-no-swiping"}
          size={isSmall ? "small" : "medium"}
          fontWeight={400}
          mt={1}
        >
          {header}
        </Typography>
        <Typography
          maxWidth={568}
          component="p"
          mt={1}
          className={isSmall ? "" : "swiper-no-swiping"}
          size={isSmall ? "small" : "medium"}
        >
          {subheader}
        </Typography>
        <Button
          className={styles.nextButton}
          variant="primary"
          shape="squared"
          onClick={() => {
            onButtonClick();
            swiper.slideNext();
          }}
        >
          {buttonLabel}
        </Button>
      </Box>
    </>
  );
};
