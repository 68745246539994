import { API } from "@js/api";
import type { JobOwner } from "@js/types/admin";
import type {
  ATSIntegration,
  ATSIntegrationError,
  Employer,
  EmployerTeamMemberType,
} from "@js/types/employer";
import type { ATSJob } from "@js/types/jobs";

import type { OnboardingOrganizationFields } from "../onboarding/components/organization/schema";

import type { AssignOwnersFormDataTypes } from "./components/assign-owners-modal";

export type EmployerDefaultJobOwners = {
  default_op_owners: JobOwner[];
  default_sale_owners: JobOwner[];
};

export type NewDefaultJobOwnersPayload = AssignOwnersFormDataTypes & {
  employerId: number;
};

export type CreateEmployerInvitationPayload = {
  first_name: string;
  last_name: string;
  email: string;
  organization_name: string;
  company_node: number;
};

type UpdateEmployerInfoQueryArg = {
  employerId: number;
  newData: OnboardingOrganizationFields;
  params?: Record<"set_account_address", boolean>;
};

export type ShareBidsWithEmployerTeamMembersParams = {
  bids: number[];
  team_members: number[];
  message?: string;
};

export type ShareBidsWithEmployerTeamMemberInvitationsParams = {
  bids: number[];
  emails: string[];
  message?: string;
};

export type ShareTalentWithEmployerTeamMembersParams = {
  talent: number;
  team_members: number[];
  message?: string;
};

export type ShareTalentWithEmployerTeamMemberInvitationsParams = {
  talent: number;
  emails: string[];
  message?: string;
};

const assignsJobOwnersApi = API.injectEndpoints({
  endpoints: (build) => ({
    getEmployerDefaultJobOwners: build.query<EmployerDefaultJobOwners, number>({
      query: (employerId) => ({
        url: `/manage_employers/${employerId}/get_default_owners/`,
        method: "GET",
      }),
    }),
    changeDefaultOwners: build.mutation<void, NewDefaultJobOwnersPayload>({
      query: ({ employerId, ...ownersData }) => ({
        url: `/manage_employers/${employerId}/change_job_owners/`,
        method: "POST",
        data: { ...ownersData },
      }),
    }),
    createEmployerInvitation: build.mutation<
      void,
      CreateEmployerInvitationPayload
    >({
      query: ({ ...employerData }) => ({
        url: "/employers/",
        method: "POST",
        data: { ...employerData },
      }),
    }),
  }),
});

export const employerApi = API.injectEndpoints({
  endpoints: (build) => ({
    getEmployerInfo: build.query<Employer, { employerId: number }>({
      query: ({ employerId }) => ({
        url: `/manage_employers/${employerId}/`,
        method: "GET",
      }),
      providesTags: ["EmployerData"],
    }),

    updateEmployerInfo: build.mutation<Employer, UpdateEmployerInfoQueryArg>({
      query: ({ employerId, newData, params }) => {
        return {
          url: `/manage_employers/${employerId}/`,
          method: "PATCH",
          data: newData,
          params: params,
        };
      },
      invalidatesTags: ["EmployerData"],
    }),

    getHasEmployerExceededDailyJobPostingLimit: build.query<
      { has_exceeded_daily_job_posting_limit: boolean },
      { employerId: number }
    >({
      query: ({ employerId }) => ({
        url: `/manage_employers/${employerId}/has_exceeded_daily_job_posting_limit/`,
        method: "GET",
      }),
      providesTags: ["EmployerHasExceededDailyJobsLimit"],
    }),

    getEmployerTeamMembers: build.query<EmployerTeamMemberType[], void>({
      query: () => ({
        url: `/employer_team_members/`,
        method: "GET",
      }),
      keepUnusedDataFor: 10000000,
      providesTags: [{ type: "EmployerTeamMembers", id: "LIST" }],
    }),

    shareBidsWithEmployerTeamMembers: build.mutation<
      void,
      ShareBidsWithEmployerTeamMembersParams
    >({
      query: (data) => ({
        url: `/employer_share_bids/`,
        method: "POST",
        data,
      }),
      async onQueryStarted(_arg, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (error) {
          dispatch(
            employerApi.util.invalidateTags([
              { type: "EmployerTeamMembers", id: "LIST" },
            ]),
          );
        }
      },
    }),

    shareBidsWithEmployerTeamMemberInvitations: build.mutation<
      void,
      ShareBidsWithEmployerTeamMemberInvitationsParams
    >({
      query: (data) => ({
        url: `/employer_share_bids_invitations/`,
        method: "POST",
        data,
      }),
      async onQueryStarted(_arg, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (error) {
          dispatch(
            employerApi.util.invalidateTags([
              { type: "EmployerTeamMembers", id: "LIST" },
            ]),
          );
        }
      },
    }),

    shareTalentWithEmployerTeamMembers: build.mutation<
      void,
      ShareTalentWithEmployerTeamMembersParams
    >({
      query: (data) => ({
        url: `/employer_share_talent/`,
        method: "POST",
        data,
      }),
      async onQueryStarted(_arg, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (error) {
          dispatch(
            employerApi.util.invalidateTags([
              { type: "EmployerTeamMembers", id: "LIST" },
            ]),
          );
        }
      },
    }),

    shareTalentWithEmployerTeamMemberInvitations: build.mutation<
      void,
      ShareTalentWithEmployerTeamMemberInvitationsParams
    >({
      query: (data) => ({
        url: `/employer_share_talent_invitations/`,
        method: "POST",
        data,
      }),
      async onQueryStarted(_arg, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
        } catch (error) {
          dispatch(
            employerApi.util.invalidateTags([
              { type: "EmployerTeamMembers", id: "LIST" },
            ]),
          );
        }
      },
    }),
  }),
});

type CreateLinkTokenResponse = {
  link_token: string;
  end_user_origin_id: string;
};

type SaveAccountTokenArgs = {
  public_token: string;
  end_user_origin_id: string;
};

type EditATSIntegrationParams = {
  id: number;
} & Pick<ATSIntegration, "auto_fill_job_fields">;

type ATSIntegrationIssuesPrams = {
  id: number;
};

const atsIntegrationApi = API.injectEndpoints({
  endpoints: (build) => ({
    createLinkToken: build.mutation<CreateLinkTokenResponse, void>({
      query: () => ({
        url: `/merge_api/create_link_token/`,
        method: "POST",
      }),
    }),
    saveAccountToken: build.mutation<void, SaveAccountTokenArgs>({
      query: (args) => ({
        url: `/merge_api/save_account_metadata/`,
        method: "POST",
        data: {
          ...args,
        },
      }),
      invalidatesTags: [{ type: "EmployerATSIntegrations", id: "LIST" }],
    }),
    getATSIntegrations: build.query<ATSIntegration[], void>({
      query: () => ({
        url: `/linked_accounts/`,
        method: "GET",
      }),
      providesTags: [{ type: "EmployerATSIntegrations", id: "LIST" }],
    }),
    relinkATSIntegrations: build.query<
      Pick<CreateLinkTokenResponse, "link_token">,
      { integrationId: number }
    >({
      query: ({ integrationId }) => ({
        url: `/linked_accounts/${integrationId}/relink`,
        method: "GET",
      }),
    }),
    deleteATSIntegration: build.mutation<void, { id: number }>({
      query: ({ id }) => ({
        url: `/linked_accounts/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: [{ type: "EmployerATSIntegrations", id: "LIST" }],
    }),
    editATSIntegration: build.mutation<void, EditATSIntegrationParams>({
      query: ({ id, ...data }) => ({
        url: `/linked_accounts/${id}`,
        method: "PATCH",
        data,
      }),
      onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
        const { id: updatedIntegrationId, auto_fill_job_fields } = arg;
        const patch = dispatch(
          atsIntegrationApi.util.updateQueryData(
            "getATSIntegrations",
            undefined,
            (draft) => {
              const updatedATSIntergation = draft.find(
                (integration) => integration.id === updatedIntegrationId,
              );
              if (!updatedATSIntergation) {
                return;
              }

              updatedATSIntergation.auto_fill_job_fields = auto_fill_job_fields;
            },
          ),
        );

        try {
          await queryFulfilled;
        } catch (error) {
          patch.undo();
          dispatch(
            atsIntegrationApi.util.invalidateTags([
              { type: "EmployerATSIntegrations", id: "LIST" },
            ]),
          );
        }
      },
    }),
    getATSIntegrationErrors: build.query<
      ATSIntegrationError[],
      ATSIntegrationIssuesPrams
    >({
      query: ({ id }) => ({
        url: `/linked_accounts/${id}/issues`,
        method: "GET",
      }),
    }),
    getATSIntegrationJobs: build.query<ATSJob[], number>({
      query: (id) => {
        return {
          url: `/linked_accounts/${id}/synced_jobs/`,
          method: "GET",
        };
      },
      providesTags: ["ATSSyncedJobs"],
    }),
  }),
});

export const {
  useLazyGetEmployerDefaultJobOwnersQuery,
  useGetEmployerDefaultJobOwnersQuery,
  useChangeDefaultOwnersMutation,
  useCreateEmployerInvitationMutation,
} = assignsJobOwnersApi;

export const {
  useGetEmployerInfoQuery,
  useUpdateEmployerInfoMutation,
  useGetEmployerTeamMembersQuery,
  useGetHasEmployerExceededDailyJobPostingLimitQuery,
  useLazyGetHasEmployerExceededDailyJobPostingLimitQuery,
  useShareBidsWithEmployerTeamMemberInvitationsMutation,
  useShareBidsWithEmployerTeamMembersMutation,
  useShareTalentWithEmployerTeamMemberInvitationsMutation,
  useShareTalentWithEmployerTeamMembersMutation,
} = employerApi;

export const {
  useCreateLinkTokenMutation,
  useSaveAccountTokenMutation,
  useGetATSIntegrationsQuery,
  useLazyGetATSIntegrationsQuery,
  useDeleteATSIntegrationMutation,
  useEditATSIntegrationMutation,
  useGetATSIntegrationErrorsQuery,
  useGetATSIntegrationJobsQuery,
  useLazyGetATSIntegrationJobsQuery,
  useLazyRelinkATSIntegrationsQuery,
  util: atsIntegrationApiUtil,
} = atsIntegrationApi;
