import { Box, Typography } from "@hexocean/braintrust-ui-components";
import { JobItemEmployerData } from "@js/apps/jobs/components/job-item";
import { JobTypeTagWithTooltip } from "@js/apps/jobs/components/job-type-tag-with-tooltip";
import { RouterLink } from "@js/components/link";
import type { FreelancerOfferJob } from "@js/types/jobs";

import styles from "./style.module.scss";

type MyWorkBaseCardProps = {
  job: FreelancerOfferJob;
  topStatusTag?: JSX.Element;
  bottomSection?: JSX.Element;
  rightSection?: JSX.Element;
};

export const MyWorkBaseCard = ({
  job,
  topStatusTag,
  bottomSection,
  rightSection,
}: MyWorkBaseCardProps) => {
  return (
    <Box className={styles.card}>
      <Box className={styles.cardEmployer}>
        <JobItemEmployerData employer={job.employer} isSmallJobItemCard />
      </Box>
      <Box className={styles.cardContent}>
        <Box className={styles.cardContentLeftSection}>
          <Box className={styles.cardStatus}>
            <JobTypeTagWithTooltip jobType={job.job_type} />
            {topStatusTag}
          </Box>
          <Box mt={2}>
            <Typography
              className={styles.cardTitle}
              component="h2"
              variant="title"
              fontWeight={400}
              size="small"
              RouterLink={RouterLink}
              to={`/jobs/${job.id}`}
              target="_blank"
              rel="noopener"
            >
              {job.title}
            </Typography>
          </Box>
          {bottomSection}
        </Box>
        {rightSection}
      </Box>
    </Box>
  );
};
