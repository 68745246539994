import { useCallback } from "react";
import type { DecoratedFormProps } from "redux-form";
import { SubmissionError } from "redux-form";
import type { Dispatch } from "@reduxjs/toolkit";

import { fetchFreelancerPublicProfile } from "@js/apps/freelancer/actions";
import { ModalInstance } from "@js/components/modal";
import { useAppDispatch, useAppSelector } from "@js/hooks";

import {
  useSaveFreelancerRoleMutation,
  useUpdateFreelancerRoleMutation,
} from "../../api";
import type { StepBeingEdited } from "../../components/profile-completion-sequence/hooks/use-profile-completion";
import type { RolesFormData } from "../../forms/role-form";

type UseRoleModuleFormProps = {
  onSave: (addAnother?: boolean) => void;
  isEdit?: boolean;
  persistModalInstance?: boolean;
  setStepBeingEdited?: React.Dispatch<React.SetStateAction<StepBeingEdited>>;
};

export const useRoleModuleForm = ({
  onSave,
  isEdit,
  persistModalInstance,
  setStepBeingEdited,
}: UseRoleModuleFormProps) => {
  const [saveRole] = useSaveFreelancerRoleMutation();
  const [updateRole] = useUpdateFreelancerRoleMutation();
  const dispatch = useAppDispatch();
  const profile = useAppSelector((state) => state.freelancer.freelancerProfile);

  const onSucceedSave = useCallback(
    (data: RolesFormData, reset: DecoratedFormProps["reset"]) => {
      onSave(data.add_another);

      if (reset) {
        reset();
      }

      if (!data.add_another && !isEdit && !persistModalInstance) {
        ModalInstance.close();
      }
    },
    [onSave, isEdit, persistModalInstance],
  );

  const handleSave = useCallback(
    async (
      data: RolesFormData,
      _dispatch: Dispatch<any>,
      { reset }: DecoratedFormProps,
    ) => {
      if (!data.selected_role || !data.years_experience) {
        return;
      }

      if (data.add_another && setStepBeingEdited) {
        setStepBeingEdited("Role");
      }

      if (!data.add_another && setStepBeingEdited) {
        setStepBeingEdited(undefined);
      }

      try {
        if (isEdit && data.id) {
          await updateRole({
            id: data.id,
            primary: !!data.primary,
            role: data.selected_role,
            years_experience: data.years_experience,
            primary_freelancer_role: data.primary_freelancer_role,
          }).unwrap();
        } else {
          await saveRole({
            primary: !!data.primary,
            role: data.selected_role,
            years_experience: data.years_experience,
          }).unwrap();
        }

        onSucceedSave(data, reset);
      } catch (err: any) {
        if (isValidSubmissionError(err)) {
          throw new SubmissionError(err.data);
        }
        throw new SubmissionError({ _error: "Unknown error" });
      }
    },
    [setStepBeingEdited, isEdit, onSucceedSave, updateRole, saveRole],
  );

  const handleSubmitSuccess = () => {
    if (!profile) return;

    dispatch(fetchFreelancerPublicProfile(profile.id));
  };

  return { handleSave, handleSubmitSuccess };
};

// TODO: remove this function once issue with wrong error format gets fixed on backend (https://app.asana.com/0/1202098411876250/1204235388985845/f)
const isValidSubmissionError = (error: any) => {
  const knownProperties: Array<keyof RolesFormData> = [
    "add_another",
    "primary",
    "primary_freelancer_role",
    "selected_role",
    "years_experience",
  ];
  return (
    !!error &&
    !!error.data &&
    typeof error.data === "object" &&
    ("_error" in error.data || knownProperties.some((p) => p in error.data))
  );
};
