import React from "react";

import ApplicationUpdater from "../../state/application/updater";
import MulticallUpdater from "../../state/multicall/updater";
import TransactionUpdater from "../../state/transactions/updater";
import Polling from "../polling";
import Web3ReactManager from "../web3-react-manager";

if (!!window.ethereum) {
  window.ethereum.autoRefreshOnNetworkChange = false;
}

const AppContent = ({ children }) => {
  return (
    <>
      <ApplicationUpdater />
      <TransactionUpdater />
      <MulticallUpdater />
      <Polling />
      <Web3ReactManager>{children}</Web3ReactManager>
    </>
  );
};
export default AppContent;
