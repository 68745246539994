import React from "react";

import { Box, Button } from "@hexocean/braintrust-ui-components";
import { useUser } from "@js/apps/common/hooks";
import { MyWorkEmptyState } from "@js/apps/freelancer/components";
import { RouterLink } from "@js/components/link";
import { useCurrentPathEncoded } from "@js/hooks";

import styles from "./style.module.scss";

export const FreelancerInvoiceListEmptyState = () => {
  const user = useUser();
  const location = useCurrentPathEncoded();
  const url = `/talent/invoices/add_new/?prevPath=${location}`;

  return (
    <div className={styles.freelancerEmptyState}>
      <MyWorkEmptyState
        title="No Invoices yet"
        description="Invoices that you submit on your jobs will appear here."
        icon="💸"
        iconBackgroundColor="var(--medium-green)"
        cta={
          <Box className={styles.freelancerEmptyStateButtons}>
            <Button
              variant="white-border-beige"
              shape="squared"
              to="/jobs/"
              RouterLink={RouterLink}
            >
              Search jobs
            </Button>
            {(user?.freelancer_approved ||
              user?.freelancer_has_grant_job_type_bids) && (
              <Button
                to={url}
                variant="white-border-beige"
                shape="squared"
                RouterLink={RouterLink}
              >
                Create Invoice
              </Button>
            )}
          </Box>
        }
      />
    </div>
  );
};
