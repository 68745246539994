import type {
  Button,
  IconButton,
  IconButtonProps,
} from "@hexocean/braintrust-ui-components";

export enum PostFooterIcons {
  "post-file" = "post-file",
  "post-image" = "post-image",
  "post-link" = "post-link",
  "post-poll" = "post-poll",
}

export type PostFooterActionButtonModel = Partial<IconButtonProps> & {
  Component?: typeof IconButton | typeof Button;
};

export type PostFooterActions = `${PostFooterIcons}`;
export type ReplyFooterActions = "add-offer" | "add-attachment-reply";

export type FooterActions = PostFooterActions | ReplyFooterActions;

export type PostFooterButtonProps = {
  name: PostFooterActions;
  props?: PostFooterActionButtonModel;
  hideOnMobile?: boolean;
  disable?: boolean;
};
export type PostFooterIconsModel = {
  [key in PostFooterActions]: React.ElementType;
};
