import { useCallback, useEffect, useRef, useState } from "react";

import { Snackbar } from "@js/components/snackbar";
import type { ATSJob } from "@js/types/jobs";

type UseATSIntegrationJobsPollingArg = {
  integrationId: number;
  refetchATSJobs: () => Promise<ATSJob[] | undefined>;
  atsJobs: ATSJob[] | undefined;
};

export const useATSIntegrationJobsPolling = ({
  integrationId,
  refetchATSJobs,
  atsJobs,
}: UseATSIntegrationJobsPollingArg) => {
  const [syncJobsInProgress, setSyncJobsInProgress] = useState<boolean>(true);
  const timeoutRef = useRef<number>();

  const pollSyncJobsStatus = useCallback(
    async (id: number) => {
      try {
        setSyncJobsInProgress(true);

        const response = await refetchATSJobs();

        if (response?.length) {
          setSyncJobsInProgress(false);

          return;
        }

        timeoutRef.current = window.setTimeout(
          () => pollSyncJobsStatus(id),
          2000,
        );
      } catch (error) {
        setSyncJobsInProgress(false);
        Snackbar.error("Something went wrong during job sync.");
      }
    },
    [refetchATSJobs],
  );

  const isPollingInitializedRef = useRef(false);
  useEffect(() => {
    if (!atsJobs || isPollingInitializedRef.current) {
      return;
    }

    if (atsJobs.length) {
      setSyncJobsInProgress(false);

      return;
    }

    isPollingInitializedRef.current = true;
    timeoutRef.current = window.setTimeout(
      () => pollSyncJobsStatus(integrationId),
      2000,
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [integrationId, atsJobs]);

  useEffect(() => {
    return () => {
      clearTimeout(timeoutRef.current);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { syncJobsInProgress };
};
