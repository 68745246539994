import { useEffect } from "react";

import { fetchFreelancerProfile } from "@js/apps/freelancer/actions";
import { useAppDispatch, useAppSelector } from "@js/hooks";
import type { User } from "@js/types/auth";

export const useFreelancerDashboardWelcome = (user: User | null) => {
  const freelancerProfile = useAppSelector(
    (state) => state.freelancer.freelancerProfile,
  );
  const dispatch = useAppDispatch();

  const isAccountJobMode =
    freelancerProfile?.user.account_mode === ENUMS.JoiningReason.GET_A_JOB;

  useEffect(() => {
    if (user?.freelancer && !freelancerProfile) {
      dispatch(fetchFreelancerProfile(user.freelancer));
    }
  }, [dispatch, user, freelancerProfile]);

  return { isAccountJobMode, freelancerProfile };
};
