import type {
  EmployerInvoice,
  Invoice,
  InvoiceBatch,
} from "@js/types/invoices";

import * as actionTypes from "./action-types";

type InvoicesState = {
  invoiceList: Invoice[] | EmployerInvoice[];
  invoicesSummary: {
    overdue: number;
    due_within_30_days: number;
  };
  fetchingInvoiceList: boolean;
  total: number;

  fetchingDependentInvoicesList: boolean;
  dependentInvoicesList: EmployerInvoice[];

  fetchingTotalIncludingCCPaymentFee: boolean;

  totalIncludingCCPaymentFee: {
    invoices_total: string;
    fee: string;
    fee_charged: boolean;
    total_with_fee: string;
  } | null;

  fetchingInvoiceBatches: boolean;
  invoiceBatches: InvoiceBatch[];

  fetchingLastInvoice: boolean;
  lastInvoice: Invoice | null;
};

const INITIAL_STATE: InvoicesState = {
  invoiceList: [],
  invoicesSummary: {
    overdue: 0,
    due_within_30_days: 0,
  },
  fetchingInvoiceList: false,
  total: 0,

  fetchingDependentInvoicesList: false,
  dependentInvoicesList: [],

  fetchingTotalIncludingCCPaymentFee: false,
  totalIncludingCCPaymentFee: null,

  fetchingInvoiceBatches: false,
  invoiceBatches: [],

  fetchingLastInvoice: false,
  lastInvoice: null,
};

export default (state = INITIAL_STATE, action): InvoicesState => {
  switch (action.type) {
    case actionTypes.FETCH_EMPLOYER_INVOICES_SUMMARY_SUCCESS:
    case actionTypes.FETCH_FREELANCER_INVOICES_SUMMARY_SUCCESS:
      return {
        ...state,
        invoicesSummary: action.payload,
      };

    case actionTypes.FETCH_EMPLOYER_INVOICES_SUMMARY_FAILED:
    case actionTypes.FETCH_FREELANCER_INVOICES_SUMMARY_FAILED:
      return {
        ...state,
        invoicesSummary: {
          overdue: 0,
          due_within_30_days: 0,
        },
      };

    case actionTypes.FETCH_FREELANCER_INVOICES:
    case actionTypes.FETCH_EMPLOYER_INVOICES:
      return {
        ...state,
        fetchingInvoiceList: true,
        invoiceList: [],
        total: 0,
      };

    case actionTypes.FETCH_FREELANCER_INVOICES_SUCCESS:
    case actionTypes.FETCH_EMPLOYER_INVOICES_SUCCESS:
      return {
        ...state,
        fetchingInvoiceList: false,
        invoiceList: action.payload.results,
        total: action.payload.count,
      };

    case actionTypes.FETCH_FREELANCER_INVOICES_FAILED:
    case actionTypes.FETCH_EMPLOYER_INVOICES_FAILED:
      return { ...state, fetchingInvoiceList: false };

    case actionTypes.FETCH_DEPENDENT_EMPLOYER_INVOICES:
      return { ...state, fetchingDependentInvoicesList: true };
    case actionTypes.FETCH_DEPENDENT_EMPLOYER_INVOICES_SUCCESS:
      return {
        ...state,
        fetchingDependentInvoicesList: false,
        dependentInvoicesList: action.payload.results,
      };
    case actionTypes.FETCH_DEPENDENT_EMPLOYER_INVOICES_FAILED:
      return {
        ...state,
        fetchingDependentInvoicesList: false,
        dependentInvoicesList: INITIAL_STATE.dependentInvoicesList,
      };

    case actionTypes.FETCH_TOTAL_INCLUDING_CC_PAYMENT_FEE:
      return { ...state, fetchingTotalIncludingCCPaymentFee: true };
    case actionTypes.FETCH_TOTAL_INCLUDING_CC_PAYMENT_FEE_SUCCESS:
      return {
        ...state,
        fetchingTotalIncludingCCPaymentFee: false,
        totalIncludingCCPaymentFee: action.payload,
      };
    case actionTypes.FETCH_TOTAL_INCLUDING_CC_PAYMENT_FEE_FAILED:
      return { ...state, fetchingTotalIncludingCCPaymentFee: false };

    case actionTypes.FETCH_INVOICE_BATCHES:
      return {
        ...state,
        fetchingInvoiceBatches: true,
        invoiceBatches: [],
      };
    case actionTypes.FETCH_INVOICE_BATCHES_SUCCESS:
      return {
        ...state,
        fetchingInvoiceBatches: false,
        invoiceBatches: action.payload,
      };
    case actionTypes.FETCH_INVOICE_BATCHES_FAILED:
      return {
        ...state,
        fetchingInvoiceBatches: false,
        invoiceBatches: [],
      };
    case actionTypes.FETCH_FREELANCER_LAST_INVOICE:
      return {
        ...state,
        fetchingLastInvoice: true,
        lastInvoice: null,
      };
    case actionTypes.FETCH_FREELANCER_LAST_INVOICE_SUCCESS:
      return {
        ...state,
        fetchingLastInvoice: false,
        lastInvoice: action.payload,
      };
    case actionTypes.FETCH_FREELANCER_LAST_INVOICE_FAILED:
      return {
        ...state,
        fetchingLastInvoice: false,
        lastInvoice: null,
      };
    default:
      return state;
  }
};
