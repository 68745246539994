import { Button } from "@hexocean/braintrust-ui-components";
import type { HelpOfferButtonsStatusProps } from "@js/apps/messenger/components/action-bar/action-bar-offer-buttons/types";
import { useCancelHelpOfferRefund } from "@js/apps/messenger/hooks/use-cancel-help-offer-refund";
import { useIssueHelpOfferRefund } from "@js/apps/messenger/hooks/use-issue-offer-refund";

export const HelpOfferButtonsRefundRequested = ({
  offer,
  isOfferAuthor,
}: HelpOfferButtonsStatusProps): JSX.Element | null => {
  const { openIssueHelpOfferRefundModal } = useIssueHelpOfferRefund(offer);
  const { openCancelHelpOfferRefundModal } = useCancelHelpOfferRefund(offer);

  if (isOfferAuthor)
    return (
      <Button
        variant="primary"
        shape="squared"
        className="help-offer-action-buttons__primary"
        onClick={openIssueHelpOfferRefundModal}
      >
        Issue a refund
      </Button>
    );

  return (
    <>
      <Button
        variant="primary"
        shape="squared"
        className="help-offer-action-buttons__primary"
        onClick={openCancelHelpOfferRefundModal}
      >
        Cancel refund
      </Button>
    </>
  );
};
