import type { ReactElement } from "react";
import React from "react";
import classNames from "classnames";

import { Box } from "@hexocean/braintrust-ui-components";
import { KeyboardArrowRightIcon } from "@hexocean/braintrust-ui-components/Icons";

/**
 * Example usage:
 *
 * ```
 * <Breadcrumbs step={2}>
 *     <Crumb title="Step 1" />
 *     <Crumb
 *         title="Step 2"
 *         path="/step2" />
 *     <Crumb
 *         title="Step 3"
 *         path="/step3" />
 *     <Crumb title="Step 4" />
 * </Breadcrumbs>
 * ```
 */

type BreadcrumbsProps = {
  step: number;
  maxVisitedStep: number;
  hideSeparator?: boolean;
  oneActive?: boolean;
};

export const Breadcrumbs: React.FC<
  React.PropsWithChildren<BreadcrumbsProps>
> = ({ children, step, maxVisitedStep, hideSeparator, oneActive }) =>
  React.Children.count(children) ? (
    <Box
      aria-label="wizard steps"
      className="breadcrumbs"
      sx={{ fontWeight: "500" }}
    >
      <ul className="breadcrumbs--list">
        {React.Children.map(children, (child, index) => {
          if (React.isValidElement(child)) {
            const _index = index + 1;

            let completed = false;
            let separatorCompleted = false;
            let pointerEventsForceActive = false;

            if (step && maxVisitedStep) {
              completed = _index <= maxVisitedStep;
              separatorCompleted = _index <= maxVisitedStep;
            } else if (step && !oneActive) {
              completed = _index <= step;
              separatorCompleted = _index < step;
            } else if (oneActive) {
              pointerEventsForceActive = true;
            }

            const childEl =
              oneActive || completed || _index === step
                ? child
                : React.cloneElement(child as ReactElement, {
                    path: null,
                  }); // to make link disabled

            return (
              <li
                className={classNames({
                  disabled: !completed,
                  "force-active-pointer-events": pointerEventsForceActive,
                })}
              >
                <div className="breadcrumbs-crumb-inner">
                  {childEl}
                  {_index !== React.Children.count(children) && (
                    <KeyboardArrowRightIcon
                      className={classNames("separator", {
                        disabled: !separatorCompleted,
                        "separator-hidden": hideSeparator,
                      })}
                      style={{
                        visibility: hideSeparator ? "hidden" : "visible",
                      }}
                    />
                  )}
                </div>
              </li>
            );
          }
        })}
      </ul>
    </Box>
  ) : null;

export * from "./crumb";
