import { useEffect } from "react";
import { useParams } from "react-router-dom";

import {
  FRONTEND_STORAGE_KEYS,
  useGetStorageValueQueryState,
} from "@js/apps/common/frontend-storage";
import { useAccountType } from "@js/apps/common/hooks";
import { fetchEmployerProfile } from "@js/apps/employer/actions";
import { useGetJobQuery } from "@js/apps/jobs/api";
import { useAppDispatch, useAppSelector, useNavigate } from "@js/hooks/";

import { useGetEmployerOfferQuery } from "../../api";

export const useEmployerOffer = () => {
  const dispatch = useAppDispatch();
  const { isEmployer } = useAccountType();

  const { isLoading: isLoadingPaymentMethodFailedTopBar } =
    useGetStorageValueQueryState({
      key: FRONTEND_STORAGE_KEYS.PAYMENT_METHOD_FAILED_TOP_BAR,
    });
  const { id, offerId } = useParams();
  const { data: job, isLoading: isJobLoading } = useGetJobQuery({
    id: Number(id),
  });

  const navigate = useNavigate();
  const {
    data: offer,
    isFetching,
    error,
  } = useGetEmployerOfferQuery(Number(offerId), {
    skip: offerId === undefined,
    refetchOnMountOrArgChange: true,
  });

  useEffect(() => {
    if (isFetching) return;
    if (offer?.bid.job.id !== Number(id) || !offerId || error) {
      navigate("/page-not-found/", { replace: true });
    }
  }, [offer, isFetching, error, id, offerId, dispatch, navigate]);

  useEffect(() => {
    // fetch employer profile to check if deposit payment failed
    if (!fetchingEmployerProfile && isEmployer) {
      dispatch(fetchEmployerProfile());
    }
    // eslint-disable-next-line
  }, [dispatch]);

  const fetchingEmployerProfile = useAppSelector(
    (state) => state.employer.fetchingEmployerProfile,
  );

  const isLoading =
    isFetching ||
    isJobLoading ||
    fetchingEmployerProfile ||
    isLoadingPaymentMethodFailedTopBar;

  return {
    loading: isLoading,
    offer,
    offerId: Number(offerId),
    job,
  };
};
