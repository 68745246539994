import { useCallback } from "react";

import { useActiveWeb3React } from "@js/apps/web3/hooks";

export const useSignMessage = () => {
  const { library } = useActiveWeb3React();

  const signMessage = useCallback(
    async (message: string) => {
      if (!library) {
        throw new Error("No web3 provider found");
      }

      const signer = library.getSigner();
      const signature = await signer.signMessage(message);

      return signature;
    },
    [library],
  );

  return signMessage;
};
