import { useEffect } from "react";

import { TOGGLE_HIDE_REQUEST_LOADER } from "@js/apps/common/action-types";
import { setPostsToShowPulseAnimation } from "@js/apps/common/actions";
import { useUser } from "@js/apps/common/hooks";
import { useAppDispatch, useAppSelector } from "@js/hooks";

import { useFeedVisits } from "../use-feed-visits";
import { useInfinityPostLoadingMyPosts } from "../use-infinity-post-loading-my-posts";
import { useSubscribeFeed } from "../websocket-subscribe";

export const useMyPostsPage = () => {
  useSubscribeFeed();
  const user = useUser();

  const {
    posts,
    hasMore,
    handleFetchingMore,
    loadingPosts,
    fetchingPosts,
    visitedFeedThreeTimes,
    postsCount,
  } = useInfinityPostLoadingMyPosts();

  useFeedVisits({ visitedFeedThreeTimes });

  const dispatch = useAppDispatch();
  const hideRequestLoader: boolean | undefined = useAppSelector(
    (state) => state.common.hideRequestLoader,
  );

  useEffect(() => {
    if (!hideRequestLoader) {
      dispatch({
        type: TOGGLE_HIDE_REQUEST_LOADER,
      });
    }

    return () => {
      dispatch({
        type: TOGGLE_HIDE_REQUEST_LOADER,
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    return () => {
      dispatch(setPostsToShowPulseAnimation([]));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  return {
    loading: loadingPosts,
    loadingPosts,
    posts,
    hasMore,
    handleFetchingMore,
    fetchingPosts,
    postsCount,
    isUserBanned: !!user?.is_banned_from_posting,
  };
};
