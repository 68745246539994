import type { BackendValue, ComboBoxValue } from "./types";
import { ValueType } from "./types";

export const mapValue = (valueToMap: BackendValue): ComboBoxValue => {
  if (valueToMap !== "" && typeof valueToMap === "string") {
    return {
      id: valueToMap,
      name: valueToMap,
      type: ValueType.initial_mapped_from_string,
      /**
       * Values needs to be in options list to fix MUI warning,
       * but we don't want to display it in options list,
       * so we filter them out by `selected` property.
       */
      selected: true,
    };
  }

  if (valueToMap) {
    return {
      ...valueToMap,
      selected: true,
    };
  }

  return null;
};
