import { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import _ from "underscore";

import {
  useGetFreelancerInvoiceQuery,
  useLazyGetEmployerAddressQuery,
} from "@js/apps/invoices/api";
import { formatOffersDate } from "@js/apps/jobs/apps/offers/utils";
import { ModalConfirm, openModalAndWaitForInput } from "@js/components/modal";
import { useAppDispatch } from "@js/hooks";

import { editFreelancerInvoice, lockInvoice } from "../../actions";
import { useJobSelectList } from "../job-select-list";

import { getEditInvoiceInitialValues } from "./helpers";

export const useEditInvoice = () => {
  const dispatch = useAppDispatch();
  const [blockedUntil, setBlockedUntil] = useState("");
  const navigate = useNavigate();
  const { id: invoiceId } = useParams();
  const { data: jobList } = useJobSelectList();

  const { data: invoice, isLoading: isLoadingInvoice } =
    useGetFreelancerInvoiceQuery(Number(invoiceId), {
      skip: !invoiceId,
      refetchOnMountOrArgChange: true,
    });

  const [fetchEmployersAddress] = useLazyGetEmployerAddressQuery();

  const lockInvoiceAndSetBlockedUntil = async (id: number) => {
    const { payment_blocked_until } = await lockInvoice(id);
    const local = formatOffersDate(payment_blocked_until, "YYYY-MM-DD HH:mm");
    setBlockedUntil(local);
  };

  useEffect(() => {
    if (invoice?.id) {
      lockInvoiceAndSetBlockedUntil(invoice.id);
    }
  }, [invoice?.id]);

  const onSubmit = async (values) => {
    await openModalAndWaitForInput({
      children: (
        <ModalConfirm confirmText="Edit Invoice">
          Are you sure all invoice details are accurate?
        </ModalConfirm>
      ),
    });
    return dispatch(editFreelancerInvoice(invoice?.id, values));
  };

  const onSubmitSuccess = () => {
    navigate("/talent/invoices/");
  };

  const initialValues = useMemo(() => {
    if (!invoice) return null;

    return getEditInvoiceInitialValues(invoice);
  }, [invoice]);

  const onChange = (values, _dispatch, _props, previousValues) => {
    if (values.job === previousValues.job) {
      return;
    }

    const job = _.findWhere(jobList, { id: values.job });

    if (job) {
      fetchEmployersAddress(job.employer.id);
    }
  };

  const loading = isLoadingInvoice || !initialValues || !blockedUntil;

  return {
    enableReinitialize: true,
    onSubmit,
    onSubmitSuccess,
    onChange,
    initialValues,
    existingAttachments: invoice?.attachments,
    loading,
    canChangeJob: false,
    blockedUntil,
  };
};
