import { Box, Typography } from "@hexocean/braintrust-ui-components";
import { BraintrustIcon } from "@hexocean/braintrust-ui-components/Icons";

import { Video } from "../nft-video-img";

type NFTCardProps = {
  actionSlot?: React.ReactNode;
};

export const NFTCard = ({ actionSlot }: NFTCardProps) => {
  return (
    <div className="nft-card">
      <Typography component="h4" color="primary">
        Braintrust NFT
      </Typography>
      {actionSlot}
      <Box className="nft-card__image-box">
        <Video
          src={`${SETTINGS.STATIC_URL}nft/nft.mp4`}
          className="nft-card__image"
        />
        <Box className="nft-card__image-badge">
          <BraintrustIcon style={{ width: "16px", height: "16px" }} />
          <Typography component="span" size="small">
            Free
          </Typography>
        </Box>
      </Box>
    </div>
  );
};
