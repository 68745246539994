import * as React from "react";

import { useAppSelector } from "@js/hooks";

import DashboardEmployerLayout from "../../employer/views/dashboard/layout";
import DashboardFreelancerLayout from "../../freelancer/views/dashboard/layout";

export const FreelancerOrEmployerDashboardLayout = ({ children, ...rest }) => {
  const user = useAppSelector((state) => state.auth.user);

  if (!user) {
    throw Error("User data are missing");
  }

  const DashboardLayout = user.freelancer
    ? DashboardFreelancerLayout
    : DashboardEmployerLayout;

  return <DashboardLayout {...rest}>{children}</DashboardLayout>;
};
