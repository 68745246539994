import type { ButtonProps } from "@hexocean/braintrust-ui-components";
import { IconButton } from "@hexocean/braintrust-ui-components";
import { MessageIcon } from "@hexocean/braintrust-ui-components/Icons";
import { useUser } from "@js/apps/common/hooks";
import { useOpenMessengerModal } from "@js/apps/messenger/hooks/use-open-messenger-modal";

type MessageButtonProps = {
  interlocutor: number;
  size?: ButtonProps["size"];
};

export const MessageButton = ({
  interlocutor,
  size = "medium",
}: MessageButtonProps) => {
  const user = useUser();
  const { openMessengerModal } = useOpenMessengerModal();

  return (
    <IconButton
      variant="transparent-border-beige"
      shape="squared"
      aria-label="Send message"
      size={size}
      onClick={() =>
        user &&
        openMessengerModal({
          context: {
            participants: [user?.id, interlocutor],
          },
        })
      }
      sx={{ width: "48px", height: "48px" }}
    >
      <MessageIcon style={{ fontSize: "16px", padding: "3px" }} />
    </IconButton>
  );
};
