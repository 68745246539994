import { useSnackbar } from "notistack";

import type { AlertProps } from "@hexocean/braintrust-ui-components";
import {
  Alert,
  Box,
  IconButton,
  Typography,
} from "@hexocean/braintrust-ui-components";
import { CloseIcon } from "@hexocean/braintrust-ui-components/Icons";
import { Snackbar } from "@js/components/snackbar";

type MintAlertContentProps = {
  type: AlertProps["type"];
  message: React.ReactNode;
  persist?: boolean;
};

export const MintAlertContent = ({
  type,
  message,
}: Omit<MintAlertContentProps, "persist">) => {
  const { closeSnackbar } = useSnackbar();

  return (
    <Alert type={type} withIcon alignIcon="center">
      <Box minWidth={{ xs: 258, sm: 368, md: 468, lg: 568 }}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography component="span">{message}</Typography>
          <IconButton
            variant="off-white-grey-no-border"
            onClick={() => closeSnackbar()}
            aria-label="Close"
          >
            <CloseIcon />
          </IconButton>
        </Box>
      </Box>
    </Alert>
  );
};

export const openMintingAlert = ({
  persist,
  ...contentProps
}: MintAlertContentProps) => {
  return Snackbar.custom(<MintAlertContent {...contentProps} />, {
    anchorOrigin: {
      vertical: "top",
      horizontal: "center",
    },
    persist,
  });
};
