import { REVIEW_APPROVAL_DURATION } from "@js/apps/give-and-get-help/constants";
import type { HelpOffer } from "@js/types/give-and-get-help";
import { assertUnreachable, matchFontColorToBackgroundColor } from "@js/utils";
import { dateDifference } from "@js/utils";

export const getStatusMessage = (
  offer: HelpOffer,
  helpOfferStatus: EnumType<typeof ENUMS.HelpOfferStatusFilter>,
  isAsker: boolean,
) => {
  switch (helpOfferStatus) {
    case ENUMS.HelpOfferStatusFilter.ACCEPTED:
      if (isAsker) return "New 🚀";
      break;
    case ENUMS.HelpOfferStatusFilter.DECLINED:
      if (isAsker) return "You’ve declined this offer";
      break;
    case ENUMS.HelpOfferStatusFilter.COMPLETED:
      if (isAsker) return "You marked offer as complete 🎉";
      return `${offer.receiver.user.first_name} marked offer as complete 🎉`;
    case ENUMS.HelpOfferStatusFilter.REPORTED:
      return `You reported ${offer.author.user.first_name}’s offer`;
    case ENUMS.HelpOfferStatusFilter.REFUND_REQUESTED:
      if (!offer.refund_issued && isAsker) return "You requested a refund";
      break;
    case ENUMS.HelpOfferStatusFilter.EXPIRED:
    case ENUMS.HelpOfferStatusFilter.PENDING:
    case ENUMS.HelpOfferStatusFilter.DELETED:
    case ENUMS.HelpOfferStatusFilter.CREATED:
      return null;
    case ENUMS.HelpOfferStatusFilter.PENDING_APPROVAL: {
      if (!isAsker || !offer.marked_complete_at) return null;
      const timeLeft = getTimeLeftForApproval(offer.marked_complete_at);
      return `You have ${timeLeft} hours to decide`;
    }
    default:
      assertUnreachable(helpOfferStatus);
      return null;
  }
};

export const getHelpOfferStatusTagColors = (
  helpOfferStatus: EnumType<typeof ENUMS.HelpOfferStatusFilter>,
) => {
  let bgColor: string;

  switch (helpOfferStatus) {
    case ENUMS.HelpOfferStatusFilter.ACCEPTED:
      bgColor = "--medium-green";
      break;
    case ENUMS.HelpOfferStatusFilter.DECLINED:
      bgColor = "--medium-grey";
      break;
    case ENUMS.HelpOfferStatusFilter.COMPLETED:
      bgColor = "--medium-violet";
      break;
    case ENUMS.HelpOfferStatusFilter.PENDING:
      bgColor = "--medium-yellow";
      break;
    case ENUMS.HelpOfferStatusFilter.REPORTED:
    case ENUMS.HelpOfferStatusFilter.REFUND_REQUESTED:
    case ENUMS.HelpOfferStatusFilter.EXPIRED:
    case ENUMS.HelpOfferStatusFilter.DELETED:
    case ENUMS.HelpOfferStatusFilter.CREATED:
      bgColor = "";
      break;
    case ENUMS.HelpOfferStatusFilter.PENDING_APPROVAL:
      bgColor = "--medium-blue";
      break;
    default:
      assertUnreachable(helpOfferStatus);
      bgColor = "";
  }

  const fontColor = matchFontColorToBackgroundColor(bgColor) || "--grey-1";

  return {
    bgColor,
    fontColor,
  };
};

const getTimeLeftForApproval = (date: string): number => {
  const completedAtDate = new Date(date);
  const currentDate = new Date();
  const hourDifference = dateDifference(currentDate, completedAtDate, "h");
  const roundedHourDifference = Math.floor(hourDifference);
  const timeLeft = REVIEW_APPROVAL_DURATION - roundedHourDifference;
  return timeLeft > 0 ? timeLeft : 0;
};
