import { Loader, Typography } from "@hexocean/braintrust-ui-components";

import { InvoicePageLayout } from "../../components";
import { AddInvoiceForm } from "../../forms/add-invoice";
import { useAddInvoice } from "../../hooks";

export const AddInvoicePage = () => {
  const {
    enableReinitialize,
    onSubmit,
    onSubmitFail,
    onChange,
    onSubmitSuccess,
    initialValues,
    loading,
    canChangeJob,
    isCopy,
  } = useAddInvoice();

  return (
    <InvoicePageLayout pageTitle="New Invoice">
      <Typography component="h1" variant="title" textTransform="uppercase">
        New Invoice
      </Typography>
      {loading ? (
        <Loader centered />
      ) : (
        <AddInvoiceForm
          enableReinitialize={enableReinitialize}
          isCopy={isCopy}
          canChangeJob={canChangeJob}
          onSubmitFail={onSubmitFail}
          onSubmitSuccess={onSubmitSuccess}
          onSubmit={onSubmit}
          onChange={onChange}
          initialValues={initialValues}
        />
      )}
    </InvoicePageLayout>
  );
};
