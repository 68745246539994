import React from "react";

import {
  Button,
  Loader,
  Pagination,
  PaginationWrapper,
} from "@hexocean/braintrust-ui-components";
import { useUser } from "@js/apps/common/hooks";
import { fetchFreelancerJobsSelect } from "@js/apps/freelancer/actions";
import { RouterLink } from "@js/components/link";
import { useAppDispatch } from "@js/hooks";
import { getCurrentPathEncoded } from "@js/utils";

import { FETCH_FREELANCER_LAST_INVOICE_SUCCESS } from "../../action-types";
import {
  fetchFreelancerInvoices,
  fetchFreelancerInvoicesSummary,
  fetchFreelancerLastInvoice,
} from "../../actions";
import {
  FreelancerInvoiceListEmptyState,
  FreelancerInvoicesListingTable,
  Hero,
} from "../../components";
import { InvoicePreviewModalInstance } from "../../components/invoice-preview";
import InvoiceListingContainer from "../../containers/invoice-listing";
import { useInvoiceFilters } from "../../filters/";

export const FreelancerInvoicesListingPageContent = ({
  onlyMobileMenu = false,
}) => {
  const { filters: invoiceFilters, isAnyFilterApplied } = useInvoiceFilters();
  const dispatch = useAppDispatch();
  const user = useUser();

  return (
    <>
      <InvoiceListingContainer
        action={async (filters) => {
          dispatch(fetchFreelancerInvoicesSummary());
          dispatch(fetchFreelancerJobsSelect());

          const invoiceList = await dispatch(fetchFreelancerInvoices(filters));

          const hasFetchedAllInvoices =
            !isAnyFilterApplied && invoiceList.count > 0;

          // we already have all invoices, there is no need to do any more fetch requests
          if (hasFetchedAllInvoices) {
            dispatch({
              type: FETCH_FREELANCER_LAST_INVOICE_SUCCESS,
              payload: invoiceList.results[0],
            });
            return;
          }

          // we have fetched **some** invoices (filtered by query params)
          // we still need to fetch **all** invoices and extract the latest one
          if (invoiceList.count > 0) {
            dispatch(fetchFreelancerLastInvoice());
          }
        }}
        queryParams={invoiceFilters}
        render={({
          changeFilters,
          fetchInvoices,
          cancelFreelancerInvoice,
          filters,
          invoiceList,
          invoicesSummary,
          loading,
          page,
          total,
          canFreelancerCopyLatestInvoice,
          copyFreelancerLatestInvoiceId,
        }) => {
          if (loading) return <Loader className="center" />;

          return (
            <>
              {invoiceList.length || isAnyFilterApplied ? (
                <>
                  <Hero
                    showCreateButton={
                      user?.freelancer_approved ||
                      user?.freelancer_has_grant_job_type_bids
                    }
                    additionalActions={
                      <Button
                        variant="secondary"
                        href={SETTINGS.SUPPORT_URLS.TALENT_FAQ_ARTICLE_URL}
                        className="mt+"
                        RouterLink={RouterLink}
                      >
                        Discover How It Works
                      </Button>
                    }
                    isAnyInvoice={!!invoiceList.length}
                    due={invoicesSummary.due_within_30_days}
                    overdue={invoicesSummary.overdue}
                    canFreelancerCopyLatestInvoice={
                      canFreelancerCopyLatestInvoice
                    }
                    copyFreelancerLatestInvoiceId={
                      copyFreelancerLatestInvoiceId
                    }
                  />
                  <FreelancerInvoicesListingTable
                    prevPath={getCurrentPathEncoded()}
                    filters={filters}
                    onlyMobileMenu={onlyMobileMenu}
                    onSubmit={changeFilters}
                    onInvoicesPaid={fetchInvoices}
                    cancelFreelancerInvoice={cancelFreelancerInvoice}
                    invoiceList={invoiceList}
                  />
                  <PaginationWrapper mt={0}>
                    <Pagination
                      page={page}
                      count={total}
                      perPage={SETTINGS.INVOICE_LISTING_INVOICES_PER_PAGE}
                      RouterLink={RouterLink}
                    />
                  </PaginationWrapper>
                </>
              ) : (
                <FreelancerInvoiceListEmptyState />
              )}
            </>
          );
        }}
      />
      <InvoicePreviewModalInstance />
    </>
  );
};
