import { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

import {
  FRONTEND_STORAGE_KEYS,
  useGetStorageValueQuery,
  useSetStorageValueMutation,
} from "@js/apps/common/frontend-storage";
import { useAccountType } from "@js/apps/common/hooks";
import { useAppSelector } from "@js/hooks";
import { isDaysFromTodayEqualOrHigher } from "@js/utils/date";

type usePaymentMethodFailedTopBarType = () => {
  showTopBar: boolean;
  dismissBar: () => Promise<unknown>;
  editBillingInformation: () => void;
};

const DAYS_THRESHOLD = 1;

export const usePaymentMethodFailedTopBar: usePaymentMethodFailedTopBarType =
  () => {
    const [dismissed, setDismissed] = useState(false);
    const navigate = useNavigate();
    const { isEmployer } = useAccountType();

    const currentOfferDeposit = useAppSelector(
      (state) => state.employer.employerProfile?.current_offer_deposit,
    );

    const isFetchingEmployerProfile = useAppSelector(
      (state) => state.employer.fetchingEmployerProfile,
    );

    // We need to additionally check if offer_id is null as
    // it can be when we cancel offer and there is no more offers
    // (current deposit then is not null)
    const shouldDisplayTopBar =
      isEmployer &&
      !isFetchingEmployerProfile &&
      currentOfferDeposit &&
      currentOfferDeposit.deposit_status ===
        ENUMS.OfferDepositStatus.PAYMENT_FAILED &&
      !!currentOfferDeposit.offer_id;

    const [setStorageValue] = useSetStorageValueMutation();
    const { data: storedDate, isLoading: isLoadingPaymentMethodFailedTopBar } =
      useGetStorageValueQuery(
        { key: FRONTEND_STORAGE_KEYS.PAYMENT_METHOD_FAILED_TOP_BAR },
        { skip: !shouldDisplayTopBar },
      );

    const dismissBar = () => {
      const currentDate = new Date().toUTCString();
      setDismissed(Boolean(currentDate));
      return setStorageValue({
        key: FRONTEND_STORAGE_KEYS.PAYMENT_METHOD_FAILED_TOP_BAR,
        value: currentDate,
      });
    };

    const showTopBar = useMemo(() => {
      if (
        !shouldDisplayTopBar ||
        isLoadingPaymentMethodFailedTopBar ||
        dismissed
      ) {
        return false;
      }

      if (!storedDate || typeof storedDate !== "string") {
        return true;
      }
      const shouldDisplayTopBarAgain = isDaysFromTodayEqualOrHigher(
        storedDate,
        DAYS_THRESHOLD,
      );

      return shouldDisplayTopBarAgain;
    }, [
      isLoadingPaymentMethodFailedTopBar,
      shouldDisplayTopBar,
      dismissed,
      storedDate,
    ]);

    const editBillingInformation = () => {
      if (!currentOfferDeposit || !currentOfferDeposit.offer_id) return;

      const { offer_id, job_id } = currentOfferDeposit;

      navigate(`/jobs/${job_id}/offers/${offer_id}/edit`);
    };

    return {
      dismissBar,
      showTopBar,
      editBillingInformation,
    };
  };
