import type { ConfigProps, TypedWrappedFieldProps } from "redux-form";
import { Field, isPristine } from "redux-form";

import { Button, Grid, RoundedBox } from "@hexocean/braintrust-ui-components";
import { createFormInstance } from "@js/forms/components";
import { useAppSelector } from "@js/hooks";
import type { InvoiceAttachment } from "@js/types/invoices";

import { AttachmentsField, NotesToRecipientField } from "../../fields";

import { InvoiceErrors } from "./components/invoice-errors";
import { Header, InvoiceItemsTable, InvoiceTotal } from "./components";
import type { InvoiceFormData } from "./types";
import { INVOICE_FORM_ID } from "./types";

type AddInvoiceFormComponentProps = Omit<
  ConfigProps<InvoiceFormData>,
  "form"
> & {
  enableReinitialize: boolean;
  isCopy: boolean;
  isEdit?: boolean;
  canChangeJob: boolean;
  existingAttachments?: InvoiceAttachment[];
};

const AddInvoiceFormInstance = createFormInstance<InvoiceFormData, unknown>(
  INVOICE_FORM_ID,
);

export const AddInvoiceForm = ({
  existingAttachments,
  isEdit,
  isCopy,
  canChangeJob,
  ...props
}: AddInvoiceFormComponentProps) => (
  <AddInvoiceFormInstance className="add-invoice-form" {...props}>
    <RoundedBox square className="add-invoice-form__wrapper">
      <Header isCopy={isCopy} canChangeJob={canChangeJob} isEdit={isEdit} />
      <div className="add-invoice-form__content">
        <InvoiceItemsTable />
        <Grid container spacing={3}>
          <Grid item xs={12} sm={8}>
            <NotesToRecipientField />
            <AttachmentsField existingAttachments={existingAttachments} />
          </Grid>
          <Grid
            item
            xs={12}
            sm={4}
            className="add-invoice-form__total-container"
          >
            <Field name="new_items" component={InvoiceTotal} />
          </Grid>
        </Grid>
      </div>
    </RoundedBox>
    <div className="add-invoice-form__actions">
      <InvoiceErrors />
      <Field name="" component={SendButton} isEdit={!!isEdit} />
    </div>
  </AddInvoiceFormInstance>
);

type SendButtonProps = TypedWrappedFieldProps & {
  isEdit: boolean;
};

const SendButton = ({ meta, isEdit }: SendButtonProps) => {
  const pristine = useAppSelector((state) => isPristine(meta.form)(state));

  return (
    <Button
      variant="primary"
      type="submit"
      disabled={isEdit ? pristine : false}
    >
      Send
    </Button>
  );
};
