import { useCallback, useRef } from "react";

import { Snackbar } from "@js/components/snackbar";
import { useAppDispatch } from "@js/hooks";
import type { GetOrCreateMessageRoomParams } from "@js/types/messenger";

import { useGetOrCreateMessageRoomMutation } from "../api";
import { openDMLimitReachedModal } from "../components/dm-limit-reached-modal";
import { openGenericMessenger } from "../messengers/modal/manage-messenger-modal";
import { getHasReachedRoomCreationLimit } from "../utils";

export const useOpenMessengerModal = () => {
  const dispatch = useAppDispatch();
  const [getOrCreateMessageRoom] = useGetOrCreateMessageRoomMutation();
  const isLoadingRef = useRef(false);

  const openMessengerModal = useCallback(
    (
      getOrCreateParams: GetOrCreateMessageRoomParams,
      options: { initialMessage?: string } = {},
    ) => {
      if (isLoadingRef.current) {
        return;
      }
      isLoadingRef.current = true;

      return getOrCreateMessageRoom(getOrCreateParams)
        .unwrap()
        .then((room) => {
          dispatch(
            openGenericMessenger({
              roomId: room.id,
              initialMessage: options.initialMessage,
            }),
          );
        })
        .catch((error) => {
          const hasReachedCreationRoomLimit =
            getHasReachedRoomCreationLimit(error);

          if (hasReachedCreationRoomLimit) {
            openDMLimitReachedModal(error.data.maximum_new_room_limit_reached);

            return;
          }

          Snackbar.error("Cannot open messenger");
        })
        .finally(() => {
          isLoadingRef.current = false;
        });
    },
    [dispatch, getOrCreateMessageRoom],
  );

  return { openMessengerModal };
};
