import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import { LocalStorage } from "@js/services";

type UseLoadSessionSavedFiltersOnMountParams = {
  isAnyFilterApplied?: boolean;
  filtersStorageKey?: string;
};

export const useLoadSessionSavedFiltersOnMount = ({
  isAnyFilterApplied,
  filtersStorageKey = LocalStorage.keys.JOB_LISTING_FILTERS,
}: UseLoadSessionSavedFiltersOnMountParams) => {
  const navigate = useNavigate();
  const [params] = useSearchParams();

  const [isReadyToFetchListing, setIsReadyToFetchListing] = useState(
    Boolean(isAnyFilterApplied),
  );

  useEffect(() => {
    const savedFilters = LocalStorage.getItem(filtersStorageKey);

    if (
      savedFilters &&
      !isAnyFilterApplied &&
      savedFilters !== params.toString()
    ) {
      navigate({ search: savedFilters }, { replace: true });
    }

    setIsReadyToFetchListing(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { isReadyToFetchListing };
};
