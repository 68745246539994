import { useLocation } from "react-router-dom";

import { UnderlinedTabs } from "@hexocean/braintrust-ui-components";
import { RouterLink } from "@js/components/link";
import type { Job } from "@js/types/jobs";
import { pluralize } from "@js/utils";

type JobManagementNavProps = {
  job: Job;
  totalBidsCount: number;
};

export const JobManagementNav = ({
  job,
  totalBidsCount,
}: JobManagementNavProps) => {
  const { pathname } = useLocation();
  const navLinks = getNavLinks({ job, bidsCount: totalBidsCount });

  return (
    <UnderlinedTabs
      data-testid="job-management-nav"
      items={navLinks}
      pathname={pathname}
      labelUnActiveColor="grey"
      spacing={3}
      itemProps={{
        sx: { p: "0" },
        variant: "title",
        size: "small",
        fontWeight: 400,
      }}
      RouterLink={RouterLink}
    />
  );
};

export const getNavLinks = ({ job, bidsCount }) => {
  return [
    {
      label: "Invite Talent",
      path: `/jobs/${job.id}/invite_talent/`,
    },
    {
      label: `${bidsCount} Application${pluralize(bidsCount, {
        zeroPlural: true,
      })}`,
      path: `/jobs/${job.id}/proposals/`,
    },
  ];
};
