import React, { useMemo } from "react";
import cs from "classnames";

import {
  Box,
  ButtonCore,
  Typography,
} from "@hexocean/braintrust-ui-components";
import { ModalInstance } from "@js/components/modal";
import type { MySpace } from "@js/types/spaces";

import { MySpacesList } from "../spaces-list";

import style from "./style.module.scss";

type MySpacesModuleProps = {
  spaces: MySpace[];
};

export const MySpacesModule = ({ spaces }: MySpacesModuleProps) => {
  const spacesQuantity = spaces?.length || 0;
  const maxSpacesToShowInModule = spacesQuantity > 3;

  const userSpaces = useMemo(() => {
    return maxSpacesToShowInModule ? spaces.slice(0, 3) : spaces;
  }, [maxSpacesToShowInModule, spaces]);

  if (spaces.length === 0) {
    return null;
  }

  return (
    <div id="services-section" className={cs("my-spaces-module")}>
      <Box display="flex" flexDirection="column" gap={2}>
        <Typography component="h2" size="large" color="primary">
          👯 I’m part of these spaces
        </Typography>

        <MySpacesList spaces={userSpaces} />
      </Box>
      {maxSpacesToShowInModule && (
        <Box
          component={ButtonCore}
          sx={{ textDecoration: "underline" }}
          fontWeight={400}
          fontSize={16}
          onClick={() => openModalWithAllSpaces(spaces)}
        >
          See all
        </Box>
      )}
    </div>
  );
};

const openModalWithAllSpaces = (spaces) => {
  return ModalInstance.open({
    className: style.allMySpacesModal,
    closeButton: true,
    closeButtonProps: {
      variant: "tertiary",
      size: "x-small",
    },
    children: (
      <Box display="flex" flexDirection="column" gap={2}>
        <Typography component="h2" size="large" color="primary">
          👯 I’m part of these spaces
        </Typography>

        <MySpacesList spaces={spaces} className={style.modal} />
      </Box>
    ),
  });
};
