import { useCallback } from "react";

import { GENERATE_AI_JOB_DESCRIPTION } from "@js/apps/jobs/apps/create-job/action-types";
import { useJobRole } from "@js/apps/roles/hooks";
import { useGetSkillsQuery } from "@js/apps/skills/api";
import type { ReduxFormConfiguration } from "@js/forms/components/redux-form";
import { useAppSelector, useNavigate } from "@js/hooks";
import { prepareDOMInput } from "@js/services/dom-sanitize";
import { formatAmountString } from "@js/utils";

import { generateJobDescriptionUsingAI } from "../../actions";
import type { JobOverviewFormData } from "../../forms/job-overview";

type JobOverviewFormConfigProps = ReduxFormConfiguration<
  JobOverviewFormData,
  any
>;

type useGenerateAIConfig = () => {
  onSubmit: JobOverviewFormConfigProps["onSubmit"];
  onSubmitSuccess: JobOverviewFormConfigProps["onSubmitSuccess"];
};

export const useGenerateAI: useGenerateAIConfig = () => {
  const employerProfile = useAppSelector(
    (state) => state.employer.employerProfile,
  );
  const userProfile = useAppSelector((state) => state.auth.user);
  const navigate = useNavigate();
  const { roleOptions: roles } = useJobRole();
  const { data: skills } = useGetSkillsQuery({});

  const onSubmit: JobOverviewFormConfigProps["onSubmit"] = useCallback(
    async (values, dispatch) =>
      dispatch(
        generateJobDescriptionUsingAI(
          employerProfile?.id || userProfile?.id,
          values.job_overview,
        ),
      ),
    [employerProfile, userProfile],
  );

  const formatToJobData = useCallback(
    (result) => {
      const topSkills =
        skills
          ?.filter((skill) =>
            result?.job_top_skills?.includes(skill?.name?.toLocaleLowerCase()),
          )
          .map((skill) => skill.id) || [];

      return {
        title: result?.job_title,
        job_type: result?.job_type,
        openings_number: result?.number_of_openings,
        role: roles.find(
          (e) => e.label.toLocaleLowerCase() === result?.job_category,
        )?.value,
        description: prepareDOMInput(result?.detailed_description),
        contract_type: result?.contract_length,
        expected_hours_per_week: result?.expected_hours_per_week,
        experience_level: result?.experience_level,
        skills: [
          ...new Set(
            topSkills.concat(
              skills
                ?.filter((skill) =>
                  result?.job_skills?.includes(
                    skill?.name?.toLocaleLowerCase(),
                  ),
                )
                .map((skill) => skill.id) || [],
            ),
          ),
        ],
        top_skills: topSkills.slice(0, 3),
        budget_maximum_usd: formatAmountString(result?.budget_maximum_usd),
        budget_minimum_usd: formatAmountString(result?.budget_minimum_usd),
        ai_max_rate: formatAmountString(result?.budget_maximum_usd),
        ai_min_rate: formatAmountString(result?.budget_minimum_usd),
        payment_type: result?.job_payment_type,
        is_resume_required: result?.is_resume_required,
        application_questions: result?.application_questions?.map(
          (question) => ({
            question,
          }),
        ),
        is_ai_generated: true,
        flow_entry: "ai",
      };
    },
    [roles, skills],
  );

  const onSubmitSuccess: JobOverviewFormConfigProps["onSubmitSuccess"] =
    useCallback(
      (result, dispatch) => {
        dispatch({
          type: GENERATE_AI_JOB_DESCRIPTION,
          payload: formatToJobData(result),
        });
        navigate("/jobs/add_new");
      },
      [formatToJobData, navigate],
    );

  return { onSubmit, onSubmitSuccess };
};
