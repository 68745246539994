import * as React from "react";
import type { NumberFormatValues } from "react-number-format";
import { useSearchParams } from "react-router-dom";
import { Field, Fields } from "redux-form";
import { isEmpty } from "underscore";

import {
  GraterOrEqualZeroIntegerNumberFormat,
  Popover,
  PopoverAnchorButton,
  Typography,
} from "@hexocean/braintrust-ui-components";
import { DollarIcon } from "@hexocean/braintrust-ui-components/Icons";
import { RateForm } from "@js/apps/common/components/filters/config";
import { useHandleFilterApplied } from "@js/apps/freelancer/hooks/use-handle-filter-applied";
import { OptionTileButtons } from "@js/components/option-tile-buttons";
import { DollarNumberField } from "@js/forms/fields";
import { PossibleFilters } from "@js/types/common";

import { JobPopoverFilterButton } from "../../components";
import {
  CUSTOM_RATE_VALUE,
  isRateOptionSelected,
  RATE_OPTIONS,
} from "../../const";

const formId = "job-filters__rate";

export const RateFilter = () => {
  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const [params] = useSearchParams();
  const { handleFilterApplied } = useHandleFilterApplied();
  const handleSubmitSideAction = (values: Record<string, unknown>) => {
    handleFilterApplied({
      filter_type: PossibleFilters.RATE,
      filter_value: values,
    });
  };

  const hourlyBudgetMinimum = params.get("hourly_budget_minimum_usd");
  const isActive = Boolean(hourlyBudgetMinimum);

  const buttonLabel = isActive ? `$${hourlyBudgetMinimum}/hr` : "Rate";

  return (
    <JobPopoverFilterButton
      isOpen={isOpen}
      startIcon={<DollarIcon viewBox="-6 3 20 20" />}
      setIsOpen={setIsOpen}
      label={buttonLabel}
      isActive={isActive}
      popoverContent={
        <RateForm
          form={formId}
          onSubmitSuccess={() => {
            setIsOpen(false);
          }}
          onSubmitSideAction={handleSubmitSideAction}
        >
          {({ submit }) => {
            return (
              <Fields
                submit={submit}
                names={["hourly_budget_minimum_usd", "custom_rate"]}
                component={RateField}
              />
            );
          }}
        </RateForm>
      }
    />
  );
};

const RateField = (props) => {
  const { submit, hourly_budget_minimum_usd, custom_rate } = props;

  const minBudget = hourly_budget_minimum_usd.input.value;

  const isCustomRate = React.useMemo(() => {
    return (
      minBudget === CUSTOM_RATE_VALUE &&
      custom_rate.input.value &&
      isEmpty(isRateOptionSelected(minBudget))
    );
  }, [minBudget, custom_rate.input.value]);

  const handleChange = (newRateValue) => {
    hourly_budget_minimum_usd.input.onChange(newRateValue);
  };

  const handleReset = () => {
    hourly_budget_minimum_usd.input.onChange(null);
    custom_rate.input.onChange(null);
  };

  return (
    <JobPopoverFilterButton.Content
      sx={{
        maxWidth: "initial !important",
      }}
      onReset={handleReset}
      onApply={submit}
    >
      <OptionTileButtons
        value={minBudget}
        onChange={(rate: any) => handleChange(rate)}
        options={RATE_OPTIONS}
        canSingleValueBeEmpty
        selectedClassName="selected"
        className="rate-filter__button"
        renderCustomOptionButton={() => {
          return (
            <CustomRatePopover
              rateValue={custom_rate.input.value}
              onCustomRateClick={() => {
                hourly_budget_minimum_usd.input.onChange(CUSTOM_RATE_VALUE);
              }}
              isCustomRate={isCustomRate}
            />
          );
        }}
      />
    </JobPopoverFilterButton.Content>
  );
};

const CustomRatePopover = ({ rateValue, isCustomRate, onCustomRateClick }) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const buttonRef = React.useRef<HTMLButtonElement>(null);

  const getPopoverButtonLabel = () => {
    if (isCustomRate && rateValue) {
      return `$${rateValue}/hr`;
    }

    return "Custom";
  };

  return (
    <Popover
      open={isOpen}
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
      onOpenChange={(open) => {
        setIsOpen(open);
      }}
      anchor={
        <PopoverAnchorButton
          selectedClassName="selected"
          isOpen={isOpen}
          ref={buttonRef}
          onClick={onCustomRateClick}
          isSelected={isCustomRate}
        >
          {getPopoverButtonLabel()}
        </PopoverAnchorButton>
      }
      PaperProps={{
        sx: {
          padding: "16px",
          border: "1px solid var(--grey-4)",
        },
      }}
    >
      <Typography variant="paragraph" component="p" size="small">
        Enter minimum hourly rate
      </Typography>
      <Field
        id="custom_rate"
        name="custom_rate"
        className="rate-filter__input"
        placeholder="100"
        component={DollarNumberField}
        NumberFormat={GraterOrEqualZeroIntegerNumberFormat}
        inputProps={{
          isAllowed: checkLimit,
          onKeyPress: (ev) => {
            if (ev.key === "Enter") {
              setIsOpen(false);
            }
          },
        }}
      />
    </Popover>
  );
};

const MAX_RATE_VALUE = 1000;
const MIN_RATE_VALUE = 0;
const checkLimit = (values: NumberFormatValues) => {
  if (!values.floatValue && values.floatValue !== MIN_RATE_VALUE) {
    return true;
  }

  return (
    values.floatValue > MIN_RATE_VALUE && values.floatValue < MAX_RATE_VALUE
  );
};
