import { Button, Loader } from "@hexocean/braintrust-ui-components";
import { PrintIcon } from "@hexocean/braintrust-ui-components/Icons";
import { PdfViewer } from "@js/apps/common/components/pdf-viewer";
import { Modal } from "@js/components/modal";
import type { Invoice } from "@js/types/invoices";

import { MarkAsPaidAction } from "../mark-as-paid-action";

import { useInvoicePreview } from "./use-invoice-preview";

export const InvoicePreviewModalInstance = Modal("invoice-preview", {
  className: "invoice-preview-modal",
});

type InvoicePreviewModalContentProps = {
  invoice: Invoice;
  invoiceEndpoint: "freelancer_invoices" | "employer_invoices";
  markInvoiceAsPaidCallback: () => void;
  onPay?: (invoice: Invoice) => void;
};

export const openInvoicePreviewModal = (
  props: InvoicePreviewModalContentProps,
) => {
  InvoicePreviewModalInstance.open({
    children: <InvoicePreviewModalContent {...props} />,
  });
};

export const closeInvoicePreviewModal = () => {
  InvoicePreviewModalInstance.close();
};

const InvoicePreviewModalContent = ({
  invoice,
  invoiceEndpoint = "freelancer_invoices",
  markInvoiceAsPaidCallback,
  onPay,
}: InvoicePreviewModalContentProps) => {
  const { isLoading, handleDocumentLoadSuccess, markAsPaid } =
    useInvoicePreview({
      invoice,
      invoiceEndpoint,
      markInvoiceAsPaidCallback,
    });

  return (
    <div className="invoice-preview-modal-content">
      <div className="invoice-preview-modal-content-card-element">
        {!!invoice.pdf_file && isLoading && <Loader />}
        <PdfViewer
          onLoadSuccess={handleDocumentLoadSuccess}
          sourceFile={invoice.pdf_file?.file}
        />
      </div>
      <div className="buttons">
        <div>
          {!!invoice.pdf_file && (
            <a
              href={invoice.pdf_file?.file}
              rel="noopener noreferrer"
              target="_blank"
              className="print-button"
            >
              <PrintIcon className="print-icon" /> Print
            </a>
          )}
        </div>
        <div>
          {invoiceEndpoint === "employer_invoices" &&
            invoice.can_be_paid &&
            onPay && (
              <Button
                onClick={() => onPay(invoice)}
                variant="secondary"
                color="secondary"
              >
                Pay Invoice
              </Button>
            )}
          <MarkAsPaidAction
            className="mark-as-paid"
            invoices={[invoice]}
            markAsPaid={markAsPaid}
          />
        </div>
      </div>
    </div>
  );
};
