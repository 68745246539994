import type { WithdrawalState } from "@js/types/withdrawals";
import { sortTransferWiseRequirements } from "@js/utils/sort";

import * as actionTypes from "./action-types";

const INITIAL_STATE: WithdrawalState = {
  fetchingTransferWiseCurrencies: false,
  transferWiseCurrencies: [],
  fetchingWithdrawalMethods: false,
  withdrawalMethods: [],
  creatingWithdrawalMethod: false,
  resendConfirmationEmail: false,
  formRequirements: {},
  fetchingCompanyNodes: false,
  companyNodes: [],
  addingRecipients: false,
  recipientsOnboardingLinks: {},
};

export default (state = INITIAL_STATE, action): WithdrawalState => {
  switch (action.type) {
    case actionTypes.FETCH_TRANSFERWISE_CURRENCIES:
      return {
        ...state,
        fetchingTransferWiseCurrencies: true,
        transferWiseCurrencies: [],
      };
    case actionTypes.FETCH_TRANSFERWISE_CURRENCIES_SUCCESS:
      return {
        ...state,
        fetchingTransferWiseCurrencies: false,
        transferWiseCurrencies: action.payload,
      };
    case actionTypes.FETCH_TRANSFERWISE_CURRENCIES_FAILED:
      return {
        ...state,
        fetchingTransferWiseCurrencies: false,
        transferWiseCurrencies: [],
      };
    case actionTypes.FETCH_WITHDRAWAL_METHODS:
      return {
        ...state,
        fetchingWithdrawalMethods: true,
        withdrawalMethods: [],
      };
    case actionTypes.FETCH_WITHDRAWAL_METHODS_SUCCESS:
      return {
        ...state,
        fetchingWithdrawalMethods: false,
        withdrawalMethods: action.payload,
      };
    case actionTypes.FETCH_WITHDRAWAL_METHODS_FAILED:
      return {
        ...state,
        fetchingWithdrawalMethods: false,
        withdrawalMethods: [],
      };
    case actionTypes.CREATE_WITHDRAWAL_METHOD:
      return { ...state, creatingWithdrawalMethod: true };
    case actionTypes.CREATE_WITHDRAWAL_METHOD_SUCCESS:
      return { ...state, creatingWithdrawalMethod: false };
    case actionTypes.CREATE_WITHDRAWAL_METHOD_FAILED:
      return action?.payload
        ? {
            ...state,
            creatingWithdrawalMethod: false,
            resendConfirmationEmail:
              action?.payload.can_resend_confirmation_email,
          }
        : {
            ...state,
            creatingWithdrawalMethod: false,
          };
    case actionTypes.FETCH_WITHDRAWAL_METHODS_OTHER_REQUIREMENTS:
    case actionTypes.FETCH_WITHDRAWAL_METHODS_CURRENCY_REQUIREMENTS:
      return { ...state, creatingWithdrawalMethod: true };
    case actionTypes.FETCH_WITHDRAWAL_METHODS_OTHER_REQUIREMENTS_CLEAR:
      return { ...state, formRequirements: {} };
    case actionTypes.FETCH_WITHDRAWAL_METHODS_OTHER_REQUIREMENTS_SUCCESS:
    case actionTypes.FETCH_WITHDRAWAL_METHODS_CURRENCY_REQUIREMENTS_SUCCESS:
      return {
        ...state,
        creatingWithdrawalMethod: false,
        formRequirements: sortTransferWiseRequirements(action.payload),
      };
    case actionTypes.FETCH_WITHDRAWAL_METHODS_CURRENCY_REQUIREMENTS_FAILED:
      return {
        ...state,
        creatingWithdrawalMethod: false,
        formRequirements: {},
      };
    case actionTypes.FETCH_COMPANY_NODES:
      return { ...state, fetchingCompanyNodes: true, companyNodes: [] };
    case actionTypes.FETCH_COMPANY_NODES_SUCCESS:
      return {
        ...state,
        fetchingCompanyNodes: false,
        companyNodes: action.payload,
      };
    case actionTypes.FETCH_COMPANY_NODES_FAILED:
      return { ...state, fetchingCompanyNodes: false, companyNodes: [] };
    case actionTypes.ADD_STRIPE_RECIPIENTS:
      return { ...state, addingRecipients: true };
    case actionTypes.ADD_STRIPE_RECIPIENTS_SUCCESS:
      return { ...state, addingRecipients: false };
    case actionTypes.ADD_STRIPE_RECIPIENTS_FAILED:
      return { ...state, addingRecipients: false };
    case actionTypes.ADD_STRIPE_RECIPIENTS_ONBOARDING_LINK:
      return {
        ...state,
        recipientsOnboardingLinks: {
          ...state.recipientsOnboardingLinks,
          [action.payload.id]: action.payload.link,
        },
      };
    case actionTypes.REMOVE_STRIPE_RECIPIENTS_ONBOARDING_LINK: {
      const newRecipientsOnboardingLinks = {
        ...state.recipientsOnboardingLinks,
      };

      delete newRecipientsOnboardingLinks[action.payload];

      return {
        ...state,
        recipientsOnboardingLinks: newRecipientsOnboardingLinks,
      };
    }
    default:
      return state;
  }
};
