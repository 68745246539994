import { useEffect } from "react";

import { ModalInstance } from "@js/components/modal";
import { useAppSelector } from "@js/hooks";
import { formatCurrency } from "@js/utils";

type UseCCPaymentFeeProps = {
  onConfirm: () => void;
};

export const useCCPaymentFee = ({ onConfirm }: UseCCPaymentFeeProps) => {
  const { fetchingTotalIncludingCCPaymentFee, totalIncludingCCPaymentFee } =
    useAppSelector((state) => state.invoices);

  useEffect(() => {
    return () => {
      ModalInstance.close();
    };
  }, []);

  const loading =
    fetchingTotalIncludingCCPaymentFee ||
    !totalIncludingCCPaymentFee ||
    (totalIncludingCCPaymentFee && !totalIncludingCCPaymentFee.total_with_fee);

  const valueToPay = formatCurrency(
    totalIncludingCCPaymentFee ? totalIncludingCCPaymentFee.total_with_fee : 0,
  );

  const handleConfirm = () => {
    onConfirm();
    ModalInstance.close();
  };

  return {
    loading,
    valueToPay,
    totalIncludingCCPaymentFee,
    handleConfirm,
  };
};
