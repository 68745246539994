import type { TalentMixedLocation } from "@js/apps/freelancer/types";
import type { PopularLocation } from "@js/types/common";

type PrepareLocationData = {
  (parameters: {
    value: (TalentMixedLocation | string)[];
    popularLocations: PopularLocation[];
  }): TalentMixedLocation;
};

const prepareLocationData: PrepareLocationData = ({
  value,
  popularLocations,
}) => {
  const last = value[value.length - 1];
  if (typeof last !== "string") {
    // if new location is added to the list (via typing in or selecting in GooglePlacesField), then we have single location data
    return last as TalentMixedLocation;
  }

  const selectedLocation = popularLocations.find(
    // if location is chosen from popular list, then in value we have [id], therefore we should find it.
    (loc) => loc.place_id === last || loc.custom_location === last,
  );

  if (!selectedLocation) {
    return last as TalentMixedLocation;
  }

  return selectedLocation as TalentMixedLocation;
};

export { prepareLocationData };
