import React from "react";

import { Box, Tooltip, Typography } from "@hexocean/braintrust-ui-components";
import { BraintrustIcon } from "@hexocean/braintrust-ui-components/Icons";
import { round } from "@js/utils";
import { formatTokenAmount, truncateBalanceToDisplay } from "@js/utils/string";

type ReferralTokenBalanceProps = {
  tokenBalance: string | undefined;
  tokenSize: "medium" | "large";
  colorVariant: "green" | "violet";
};

export const ReferralTokenBalance = ({
  tokenBalance,
  tokenSize,
  colorVariant,
}: ReferralTokenBalanceProps) => {
  const formattedTruncatedTokenBalance = tokenBalance
    ? truncateBalanceToDisplay(formatTokenAmount(round(tokenBalance, 0.1)), 5)
    : "0";

  const formattedTokenBalance = tokenBalance
    ? formatTokenAmount(tokenBalance)
    : undefined;

  return (
    <Tooltip placement="top" title={formattedTokenBalance}>
      <Box
        sx={{
          cursor: "pointer",
          boxSizing: "border-box",
          maxWidth: 180,
          bgcolor: `var(--soft-${colorVariant})`,
          color: "var(--black)",
          borderRadius: "100px",
          padding: { xs: "0 8px", md: "4px 10px" },
          display: "inline-flex",
          alignItems: "center",
          whiteSpace: "nowrap",
        }}
      >
        {Number(tokenBalance) > 0 && (
          <Typography
            color="primary"
            variant="paragraph"
            size={tokenSize}
            component="span"
            mr={0.25}
          >
            +
          </Typography>
        )}
        <BraintrustIcon
          sx={{
            mr: 0.25,
            color: "var(--black)",
            fontSize: { xs: "14px", md: "22px" },
          }}
        />
        <span className="sr-only">balance: </span>
        <Typography
          color="primary"
          variant="label"
          size={tokenSize}
          component="span"
        >
          {formattedTruncatedTokenBalance}
        </Typography>
      </Box>
    </Tooltip>
  );
};
