import { useATSInfoBar } from "./ats-info-bar";
import { AllPublishedBar } from "./bar-all-published";
import { NotPublishedBar } from "./bar-not-published";
import { NotPublishedAutoFillBar } from "./bar-not-published-auto-fill";
import { PartiallyPublishedBar } from "./bar-partially-published";
import { PartiallyPublishedAutoFillBar } from "./bar-partially-published-auto-fill";

export const ATSInfoBar = () => {
  const {
    variant,
    open,
    close,
    publishedJobsCount,
    jobsAddedToDraft,
    jobsCount,
    integrationName,
  } = useATSInfoBar();

  return open && !!variant ? (
    <>
      {variant === "all-published" && <AllPublishedBar onClose={close} />}
      {variant === "partially-published-auto-fill" && (
        <PartiallyPublishedAutoFillBar
          jobsCount={jobsCount}
          publishedJobsCount={publishedJobsCount}
          onClose={close}
          integrationName={integrationName}
        />
      )}
      {variant === "not-published-auto-fill" && (
        <NotPublishedAutoFillBar
          onClose={close}
          jobsAdded={jobsAddedToDraft}
          integrationName={integrationName}
        />
      )}
      {variant === "partially-published" && (
        <PartiallyPublishedBar
          jobsCount={jobsCount}
          publishedJobsCount={publishedJobsCount}
          onClose={close}
          integrationName={integrationName}
        />
      )}
      {variant === "not-published" && (
        <NotPublishedBar
          onClose={close}
          jobsAdded={jobsAddedToDraft}
          integrationName={integrationName}
        />
      )}
    </>
  ) : null;
};
