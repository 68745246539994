import { Typography, useMediaQuery } from "@hexocean/braintrust-ui-components";

import styles from "./team-member-invitations-empty-state.module.scss";

export const TeamMemberInvitationsEmptyState = () => {
  const isMobile = useMediaQuery("sm");

  return (
    <div className={styles.emptyState}>
      <div className={styles.emptyStateIcon}>👀</div>
      <Typography
        variant="paragraph"
        component="p"
        size={isMobile ? "small" : "medium"}
      >
        We couldn’t find anyone on Braintrust with that name
      </Typography>
    </div>
  );
};
