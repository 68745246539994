import React from "react";

import { Box } from "@hexocean/braintrust-ui-components";
import type { HelpOffer } from "@js/types/give-and-get-help";
import { matchFontColorToBackgroundColor } from "@js/utils";

export const HELP_OFFER_META_STATUS = {
  PENDING_APPROVAL: "PENDING_APPROVAL",
  REPORTED: "REPORTED",
  REFUND_REQUESTED: "REFUND_REQUESTED",
} as const;

const HELP_OFFER_META_STATUS_LABELS = {
  PENDING_APPROVAL: "Pending approval",
  REPORTED: "Reported",
  REFUND_REQUESTED: "Refund requested",
};

type HelpOfferStatusTagStatus =
  | HelpOffer["status"]
  | EnumType<typeof HELP_OFFER_META_STATUS>;

type HelpOfferStatusTagProps = {
  status: HelpOfferStatusTagStatus;
};

export const HelpOfferStatusTag = ({ status }: HelpOfferStatusTagProps) => {
  const statusBgColors = {
    [ENUMS.HelpOfferStatus.PENDING]: "--medium-yellow",
    [ENUMS.HelpOfferStatus.DECLINED]: "--medium-grey",
    [ENUMS.HelpOfferStatus.ACCEPTED]: "--medium-green",
    [ENUMS.HelpOfferStatus.COMPLETED]: "--medium-violet",
    [HELP_OFFER_META_STATUS.REFUND_REQUESTED]: "--medium-red",
    [HELP_OFFER_META_STATUS.PENDING_APPROVAL]: "--medium-blue",
    [HELP_OFFER_META_STATUS.REPORTED]: "--medium-red",
  };

  const bgColor = statusBgColors[status];
  const fontColor = matchFontColorToBackgroundColor(bgColor) || "--grey-1";

  return (
    <Box
      className="dashboard-help-offer-card__offer-status-tag"
      bgcolor={`var(${bgColor})`}
      color={`var(${fontColor})`}
      fontWeight={500}
    >
      {ENUMS.HelpOfferStatusLabels[status] ||
        HELP_OFFER_META_STATUS_LABELS[status]}
    </Box>
  );
};
