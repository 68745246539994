import { useCallback, useState } from "react";

import { useEffectRef } from "@js/hooks/use-effect-ref";
import { LocalStorage } from "@js/services";

export const useLocalStorageState = <T>({
  key,
  initialValue,
}: {
  key: string;
  initialValue: T;
}): [T, (value: T | ((prevState?: T) => T)) => void] => {
  const [storedValue, setStoredValue] = useState<T>(() => {
    try {
      const item = LocalStorage.getItem(key);

      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      return initialValue;
    }
  });

  const storedValueRef = useEffectRef(storedValue);

  const setValue = useCallback(
    (value: T | ((prevState?: T) => T)): void => {
      try {
        // Allow value to be a function so we have the same API as useState
        const valueToStore =
          value instanceof Function ? value(storedValueRef.current) : value;

        setStoredValue(valueToStore);
        LocalStorage.setItem(key, JSON.stringify(valueToStore));
      } catch (e) {
        void e;
      }
    },
    [key, storedValueRef],
  );

  return [storedValue, setValue] as const;
};
