import type { ButtonProps } from "@hexocean/braintrust-ui-components";
import { Button, Tooltip } from "@hexocean/braintrust-ui-components";
import {
  CheckIcon,
  EditPencilIcon,
} from "@hexocean/braintrust-ui-components/Icons";
import { submitProposalClicked } from "@js/apps/jobs/actions";
import { isOfferStatusPending } from "@js/apps/jobs/apps/offers/utils";
import { RouterLink } from "@js/components/link";
import { useAppDispatch } from "@js/hooks";
import type { FreelancerBid, Job, MainJobListingJob } from "@js/types/jobs";
import { getURLWithBackLink } from "@js/utils";

export type BidButtonProps = {
  job: Job | MainJobListingJob;
  bid?: FreelancerBid;
  openInNewTab?: boolean;
} & Optional<ButtonProps, "variant">;

export const BidButton = ({
  job,
  bid,
  variant = "primary",
  inverse,
  openInNewTab,
  ...props
}: BidButtonProps) => {
  const dispatch = useAppDispatch();

  if (!job || job.openings_left < 1) return null;

  let button: JSX.Element | null = null;
  const offerStatus = bid?.current_offer?.status;

  if (bid?.status === ENUMS.BidStatus.APPLICATION_IN_REVIEW) {
    button = null;
  } else if (
    bid?.current_offer?.id &&
    offerStatus === ENUMS.OfferStatus.ACCEPTED
  ) {
    button = (
      <Button
        variant={variant}
        inverse={inverse}
        to={getURLWithBackLink(
          `/jobs/${bid.job.id}/offers/${bid.current_offer.id}`,
        )}
        RouterLink={RouterLink}
        target={openInNewTab ? "_blank" : undefined}
        {...props}
      >
        <CheckIcon fontSize="inherit" /> View Accepted Offer
      </Button>
    );
  } else if (
    bid?.current_offer?.id &&
    offerStatus &&
    isOfferStatusPending(offerStatus)
  ) {
    button = (
      <Button
        variant={variant}
        inverse={inverse}
        to={getURLWithBackLink(
          `/jobs/${bid.job.id}/offers/${bid?.current_offer.id}`,
        )}
        RouterLink={RouterLink}
        target={openInNewTab ? "_blank" : undefined}
        {...props}
      >
        <CheckIcon fontSize="inherit" /> View Pending Offer
      </Button>
    );
  } else if (
    bid?.current_offer?.id &&
    offerStatus === ENUMS.OfferStatus.REJECTED
  ) {
    button = (
      <Button
        variant={variant}
        inverse={inverse}
        to={getURLWithBackLink(
          `/jobs/${bid.job.id}/offers/${bid?.current_offer.id}`,
        )}
        RouterLink={RouterLink}
        target={openInNewTab ? "_blank" : undefined}
        {...props}
      >
        View Rejected Offer
      </Button>
    );
  } else if (bid && !bid.can_edit) {
    button = (
      <Button
        variant={variant}
        inverse={inverse}
        to={getURLWithBackLink(`/jobs/${bid.job.id}/proposals/${bid.id}/`)}
        RouterLink={RouterLink}
        target={openInNewTab ? "_blank" : undefined}
        startIcon={<CheckIcon fontSize="inherit" />}
        {...props}
      >
        View Application
      </Button>
    );
  } else if (bid && bid.can_edit) {
    button = (
      <Button
        variant={variant}
        inverse={inverse}
        RouterLink={RouterLink}
        target={openInNewTab ? "_blank" : undefined}
        to={getURLWithBackLink(`/jobs/${bid.job.id}/proposals/${bid.id}/edit/`)}
        startIcon={<EditPencilIcon style={{ fontSize: 22 }} />}
        {...props}
      >
        Edit Application
      </Button>
    );
  } else if (job.job_status === ENUMS.JobStatus.ON_HOLD) {
    button = (
      <Tooltip title="You can't submit an application on a Job that is Paused.">
        <span>
          <Button
            variant={variant}
            inverse={inverse}
            disabled={true}
            target={openInNewTab ? "_blank" : undefined}
          >
            Apply
          </Button>
        </span>
      </Tooltip>
    );
  } else {
    button = (
      <Button
        variant={variant}
        inverse={inverse}
        target={openInNewTab ? "_blank" : undefined}
        {...props}
        onClick={() => {
          dispatch(
            submitProposalClicked({ job, search: window.location.search }),
          );
        }}
        to={getURLWithBackLink(`/jobs/${job.id}/proposals/new/`)}
        RouterLink={RouterLink}
      >
        Apply
      </Button>
    );
  }

  return button;
};
