import {
  FETCH_INDUSTRIES_SUCCESS,
  FETCH_POPULAR_LOCATION_OPTIONS_SUCCESS,
  FORCE_PAGE_REMOUNT,
  HIDE_LEFT_SIDEBAR,
  SEARCH_TAXONOMY_SUCCESS,
  SET_ACTIVE_MESSENGER_ROOM,
  SET_LAYOUT_BG_COLOR,
  SET_POSTS_TO_SHOW_PULSE_ANIMATION,
  SHOW_LEFT_SIDEBAR,
  TOGGLE_HIDE_REQUEST_LOADER,
} from "@js/apps/common/action-types";
import type { CommonState } from "@js/types/common";

const INITIAL_STATE: CommonState = {
  appLayoutKey: 1,

  hideRequestLoader: false,
  showLeftSidebar: true,

  layoutBgColor: undefined,

  searchResults: [],

  projectCategories: [],
  projectSubcategories: [],

  industries: [],

  fetchingCommonSkills: false,
  commonSkills: {}, // roleId to common skills map

  fetchingRoleTypeSkills: false,
  roleTypeSkills: {},

  fetchingBalance: false,

  popularLocations: [],
  stats: {},
  fetchingStats: false,

  activeMessengerRoom: undefined,

  showPulseAnimationForPosts: [],
};

export default function (state = INITIAL_STATE, action): CommonState {
  switch (action.type) {
    case SET_POSTS_TO_SHOW_PULSE_ANIMATION:
      return {
        ...state,
        showPulseAnimationForPosts: action.payload.showPulseAnimationForPosts,
      };

    case FORCE_PAGE_REMOUNT:
      return { ...state, appLayoutKey: state.appLayoutKey + 1 };

    case SHOW_LEFT_SIDEBAR:
      return {
        ...state,
        showLeftSidebar: true,
      };

    case HIDE_LEFT_SIDEBAR:
      return {
        ...state,
        showLeftSidebar: false,
      };

    case SET_LAYOUT_BG_COLOR:
      return {
        ...state,
        layoutBgColor: action.payload,
      };

    case SEARCH_TAXONOMY_SUCCESS:
      return { ...state, searchResults: action.payload };

    case FETCH_POPULAR_LOCATION_OPTIONS_SUCCESS:
      return { ...state, popularLocations: action.payload };

    case FETCH_INDUSTRIES_SUCCESS:
      return { ...state, industries: action.payload };

    case SET_ACTIVE_MESSENGER_ROOM:
      return { ...state, activeMessengerRoom: action.payload.roomId };

    case TOGGLE_HIDE_REQUEST_LOADER:
      return { ...state, hideRequestLoader: !state.hideRequestLoader };

    default:
      return state;
  }
}
