import { Box, Typography } from "@hexocean/braintrust-ui-components";
import { RouterLink } from "@js/components/link";

import styles from "./styles.module.scss";

export const EmployerInvoiceListEmptyState = () => (
  <Box sx={{ maxWidth: "605px", margin: "60px auto", textAlign: "center" }}>
    <img
      src={`${SETTINGS.STATIC_URL}invoices/bkg-empty-invoices.png`}
      alt="Empty Invoices"
      width="250"
      height="250"
      className={styles.image}
    />
    <Typography component="h1" variant="title" fontWeight={400} mt={6} mb={4}>
      No Invoices
    </Typography>
    <Typography component="p" variant="paragraph" size="medium">
      Once you receive an invoice for your first job, you will see it here.
      Let's{" "}
      <Typography
        component="link"
        RouterLink={RouterLink}
        to="/jobs/add_new/"
        className="text-underline"
      >
        post a job
      </Typography>
      !
    </Typography>
  </Box>
);
