import React, { forwardRef } from "react";
import type { SnackbarKey } from "notistack";
import { SnackbarContent, useSnackbar } from "notistack";
import _ from "underscore";

import type { BoxProps } from "@hexocean/braintrust-ui-components";
import { Box, Button, Typography } from "@hexocean/braintrust-ui-components";

export type SnackbarToastProps = Partial<
  Omit<BoxProps, "ref" | "id" | "content">
> & {
  header: React.ReactNode;
  content?:
    | React.ReactNode
    | ((closeSnackbar: VoidFunction) => React.ReactNode);
  buttonText?: string;
};

// Credits: https://notistack.com/v2.x/examples/advanced/custom-component
export const ToastSnackbarContent = forwardRef<
  HTMLDivElement,
  SnackbarToastProps & { id: SnackbarKey }
>(({ header, content, buttonText = "Okay", ...props }, ref) => {
  const { id, ...rest } = props;
  const { closeSnackbar } = useSnackbar();

  return (
    <SnackbarContent ref={ref}>
      <Box
        bgcolor="var(--medium-yellow)"
        p={3}
        maxWidth="410px"
        boxSizing="border-box"
        boxShadow="var(--elevation-4)"
        borderRadius="10px"
        {...rest}
      >
        <Typography
          component="p"
          variant="label"
          sx={{ wordBreak: "break-word" }}
          size="large"
          mb={1}
        >
          {header}
        </Typography>
        <Typography component="p" sx={{ wordBreak: "break-word" }} mb={1.5}>
          {_.isFunction(content) ? content(() => closeSnackbar(id)) : content}
        </Typography>
        <Box textAlign="right">
          <Button variant="primary" onClick={() => closeSnackbar(id)}>
            {buttonText}
          </Button>
        </Box>
      </Box>
    </SnackbarContent>
  );
});
