import React, { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import ReactGA from "react-ga";
import ReactGA4 from "react-ga4";
import type { AbstractConnector } from "@web3-react/abstract-connector";
import { UnsupportedChainIdError, useWeb3React } from "@web3-react/core";
import { WalletConnectConnector } from "@web3-react/walletconnect-connector";

import {
  Alert,
  Box,
  Button,
  Typography,
} from "@hexocean/braintrust-ui-components";
import { usePrevious } from "@js/apps/common/hooks";
import { OVERLAY_READY } from "@js/apps/web3/connectors/constants";
import { RouterLink } from "@js/components/link";
import { Modal } from "@js/components/modal";
import { closeModal, openModal } from "@js/components/modal/actions-deprecated";
import { useAppSelector } from "@js/hooks";

import { fortmatic, injected, portis } from "../../connectors";
import { SUPPORTED_WALLETS } from "../../constants";
import { activateInjectedProvider } from "../../utils/activate-injected-provider";

import Option from "./option";
import PendingView from "./pending-view";

import styles from "./style.module.scss";

const WALLET_VIEWS = {
  OPTIONS: "options",
  OPTIONS_SECONDARY: "options_secondary",
  ACCOUNT: "account",
  PENDING: "pending",
};

const VOTE_WALLET_MODAL_ID = "vote-modal";

const WalletModalInstance = Modal(VOTE_WALLET_MODAL_ID, {
  className: styles.voteModal,
});

const WalletModal = () => {
  // important that these are destructed from the account-specific web3-react context
  const { active, account, connector, activate, error } = useWeb3React();

  const [walletView, setWalletView] = useState(WALLET_VIEWS.ACCOUNT);

  const [pendingWallet, setPendingWallet] = useState<
    AbstractConnector | undefined
  >();

  const [pendingError, setPendingError] = useState<boolean>();

  const [errorCode, setErrorCode] = useState<number>();

  const walletModalOpen = useAppSelector(
    (state) => state.modal[VOTE_WALLET_MODAL_ID],
  );

  const previousAccount = usePrevious(account);

  // close on connection, when logged out before
  useEffect(() => {
    if (account && !previousAccount && walletModalOpen) {
      WalletModal.closeModal();
    }
  }, [account, previousAccount, walletModalOpen]);

  // always reset to account view
  useEffect(() => {
    if (walletModalOpen) {
      setPendingError(false);
      setWalletView(WALLET_VIEWS.ACCOUNT);
    }
  }, [walletModalOpen]);

  // close modal when a connection is successful
  const activePrevious = usePrevious(active);
  const connectorPrevious = usePrevious(connector);
  useEffect(() => {
    if (
      walletModalOpen &&
      ((active && !activePrevious) ||
        (connector && connector !== connectorPrevious && !error))
    ) {
      setWalletView(WALLET_VIEWS.ACCOUNT);
    }
  }, [
    setWalletView,
    active,
    error,
    connector,
    walletModalOpen,
    activePrevious,
    connectorPrevious,
  ]);

  const tryActivation = async (
    abstractConnector: AbstractConnector | undefined,
  ) => {
    let name = "";
    Object.keys(SUPPORTED_WALLETS).map((key) => {
      if (abstractConnector === SUPPORTED_WALLETS[key].connector) {
        return (name = SUPPORTED_WALLETS[key].name);
      }
      return true;
    });
    // log selected wallet
    ReactGA.event({
      category: "Wallet",
      action: "Change Wallet",
      label: name,
    });
    ReactGA4.event({
      category: "Wallet",
      action: "Change Wallet",
      label: name,
    });
    setPendingWallet(abstractConnector); // set wallet for pending view
    setWalletView(WALLET_VIEWS.PENDING);

    // if the connector is walletconnect and the user has already tried to connect, manually reset the connector
    if (
      abstractConnector instanceof WalletConnectConnector &&
      abstractConnector.walletConnectProvider?.wc?.uri
    ) {
      abstractConnector.walletConnectProvider = undefined;
    }

    activateInjectedProvider(
      SUPPORTED_WALLETS.WALLET_CONNECT.name === name ? "CoinBase" : "MetaMask",
    );

    if (abstractConnector)
      activate(abstractConnector, undefined, true).catch((err) => {
        if (err instanceof UnsupportedChainIdError) {
          activate(abstractConnector); // a little janky...can't use setError because the connector isn't set
        } else {
          setPendingError(true);
          setErrorCode(err.code);
          console.error("Wallet Activation Failed:", err);
        }
      });
  };

  // close wallet modal if fortmatic modal is active
  useEffect(() => {
    fortmatic?.on(OVERLAY_READY, () => {
      WalletModal.closeModal();
    });
  }, []);

  // get wallets user can switch too, depending on device/browser
  function getOptions() {
    const isMetamask = window.ethereum && window.ethereum.isMetaMask;
    return Object.keys(SUPPORTED_WALLETS).map((key) => {
      const option = SUPPORTED_WALLETS[key];
      // check for mobile options
      if (isMobile) {
        //disable portis on mobile for now
        if (option.connector === portis) {
          return null;
        }

        if (!window.web3 && !window.ethereum && option.mobile) {
          return (
            <Option
              onClick={() => {
                if (option.connector !== connector && !option.href)
                  tryActivation(option.connector);
              }}
              id={`connect-${key}`}
              key={key}
              active={option.connector && option.connector === connector}
              link={option.href}
              header={option.name}
              disabled={option.disabled}
              icon={`${SETTINGS.STATIC_URL}vote/${option.iconName}`}
            />
          );
        }

        return null;
      }

      // overwrite injected when needed
      if (option.connector === injected) {
        // don't show injected if there's no injected provider
        if (!(window.web3 || window.ethereum)) {
          if (option.name === "MetaMask") {
            return (
              <Option
                id={`connect-${key}`}
                key={key}
                header="Install Metamask"
                subheader={null}
                link="https://metamask.io/"
                disabled={option.disabled}
                icon={`${SETTINGS.STATIC_URL}vote/metamask.png`}
              />
            );
          } else {
            return null; //dont want to return install twice
          }
        }
        // don't return metamask if injected provider isn't metamask
        else if (option.name === "MetaMask" && !isMetamask) {
          return null;
        }
        // likewise for generic
        else if (option.name === "Injected" && isMetamask) {
          return null;
        }
      }

      // return rest of options
      return (
        !isMobile &&
        !option.mobileOnly && (
          <Option
            id={`connect-${key}`}
            onClick={() => {
              if (option.connector === connector) {
                WalletModal.closeModal();
              } else if (!option.href) {
                tryActivation(option.connector);
              }
            }}
            key={key}
            active={option.connector === connector}
            link={option.href}
            header={option.name}
            disabled={option.disabled}
            subheader={null} //use option.descriptio to bring back multi-line
            icon={`${SETTINGS.STATIC_URL}vote/${option.iconName}`}
          />
        )
      );
    });
  }

  function getModalContent() {
    if (error) {
      return (
        <>
          <Typography
            component="h2"
            variant="title"
            fontWeight={400}
            pt={4}
            pb={4}
          >
            {error instanceof UnsupportedChainIdError
              ? "Wrong Network"
              : "Error connecting"}
          </Typography>
          <Typography component="p">
            {error instanceof UnsupportedChainIdError
              ? "Please connect to the appropriate Ethereum network."
              : "Error connecting. Try refreshing the page."}
          </Typography>
        </>
      );
    }
    return (
      <>
        <Typography
          component="h2"
          variant="title"
          fontWeight={400}
          pt={4}
          pb={8}
        >
          Connect external Crypto wallet
        </Typography>
        <Box mb={4}>
          {walletView === WALLET_VIEWS.PENDING ? (
            <PendingView
              connector={pendingWallet}
              errorCode={errorCode}
              error={pendingError}
              setPendingError={setPendingError}
              tryActivation={tryActivation}
            />
          ) : (
            getOptions()
          )}
        </Box>
        {walletView !== WALLET_VIEWS.PENDING && (
          <Alert
            type="info"
            withIcon
            alignIcon="center"
            className={styles["vote-wallet__alert"]}
          >
            <Typography component="p" marginRight="auto">
              Don’t have a wallet, or have questions?
            </Typography>
            <Button
              variant="tertiary"
              href={SETTINGS.SUPPORT_URLS.HELP_CENTER_URL}
              target="_blank"
              rel="noopener noreferrer"
              RouterLink={RouterLink}
            >
              Learn More
            </Button>
          </Alert>
        )}
        {walletView !== WALLET_VIEWS.ACCOUNT && (
          <Box mt={4} className="right">
            <Button
              variant="primary"
              onClick={() => {
                setPendingError(false);
                setWalletView(WALLET_VIEWS.ACCOUNT);
              }}
            >
              Back
            </Button>
          </Box>
        )}
      </>
    );
  }

  return <WalletModalInstance>{getModalContent()}</WalletModalInstance>;
};

WalletModal.openModal = () => openModal(VOTE_WALLET_MODAL_ID);
WalletModal.closeModal = () => closeModal(VOTE_WALLET_MODAL_ID);

export default WalletModal;
