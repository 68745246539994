import { Stack } from "@hexocean/braintrust-ui-components";
import {
  CreatePostModalInstance,
  EditPostModalInstance,
} from "@js/apps/give-and-get-help/components/create-or-edit-post-modal/modal/modal-instances";
import { CreatePostFAB } from "@js/apps/give-and-get-help/components/create-post";
import {
  useMarkCareerHelpVisited,
  useMyPostsPage,
} from "@js/apps/give-and-get-help/hooks";
import { UniversalSearchContextProvider } from "@js/apps/universal-search/context/universal-search-context";
import { AppLayout } from "@js/layouts/app";

import { MyPostsHeader, PostsLoadingState } from "../../components";
import { VirtualizedPostsSection } from "../../components/virtualized-posts-section";
import { PostLocation } from "../../context/post-location";

import styles from "./styles.module.scss";

export const MyPostsPage = () => {
  const {
    posts,
    hasMore,
    loading,
    fetchingPosts,
    postsCount,
    isUserBanned,
    handleFetchingMore,
  } = useMyPostsPage();

  useMarkCareerHelpVisited();

  return (
    <UniversalSearchContextProvider initialTab="CAREER_HELP">
      <PostLocation.Provider value={PostLocation.Values.my_posts}>
        <AppLayout
          bgcolor="var(--soft-green)"
          pageTitle="My Posts"
          className={styles.myPostsPage}
        >
          <Stack
            sx={{
              width: "100%",
              maxWidth: "944px",
              mx: "auto",
              justifyItems: "center",
              px: { sm: 2 },
            }}
          >
            <MyPostsHeader postsCount={postsCount} />

            {loading ? (
              <PostsLoadingState />
            ) : (
              <VirtualizedPostsSection
                posts={posts}
                hasMore={hasMore}
                fetchMore={handleFetchingMore}
                fetchingPosts={fetchingPosts}
              />
            )}
            <CreatePostModalInstance />
            <CreatePostFAB disabled={isUserBanned} />
          </Stack>
          <EditPostModalInstance />
        </AppLayout>
      </PostLocation.Provider>
    </UniversalSearchContextProvider>
  );
};
