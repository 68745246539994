import { useMemo } from "react";
import type { WrappedFieldProps } from "redux-form";

import { Box, Typography } from "@hexocean/braintrust-ui-components";

type CharacterIndicatorProps = {
  input: WrappedFieldProps["input"];
  maxLength?: number;
  minimumLengthToDisplayCounter?: number;
};

export const CharacterIndicator = ({
  input,
  maxLength,
  minimumLengthToDisplayCounter,
}: CharacterIndicatorProps) => {
  const displayIndicator = useMemo(() => {
    if (!!maxLength) {
      if (!!minimumLengthToDisplayCounter) {
        return (
          !!input.value.length &&
          input.value.length >= minimumLengthToDisplayCounter
        );
      }

      return !!input.value.length;
    }

    return false;
  }, [minimumLengthToDisplayCounter, input.value.length, maxLength]);

  return displayIndicator && !!maxLength ? (
    <Box className="editor-character-indicator">
      <Typography
        component="span"
        size="small"
        color={input.value.length > maxLength ? "negative" : "grey-3"}
      >
        {`${input.value.length} / ${maxLength}`}
      </Typography>
    </Box>
  ) : null;
};
