import { Outlet, Route, useLocation } from "react-router-dom";

import { Box, Button } from "@hexocean/braintrust-ui-components";
import { Tabs } from "@hexocean/braintrust-ui-components";
import { RouterLink } from "@js/components/link";

import { SimpleForm } from "./form";
import { SingleFieldForm } from "./single-field";

const RhfTestFormPage = ({ children }) => {
  const location = useLocation();

  return (
    <Box p={3}>
      <Tabs
        pathname={location.pathname}
        items={[
          {
            path: "/rhf/form",
            label: "Form",
          },
          {
            path: "/rhf/field",
            label: "Field",
          },
        ]}
        renderItem={(item) => {
          return (
            <Button
              shape="squared"
              variant={item.isActive ? "primary" : "secondary"}
              RouterLink={RouterLink}
              to={item.item.path}
            >
              {item.item.label}
            </Button>
          );
        }}
      />
      {children}
    </Box>
  );
};

export const RhfTestRoutes =
  process.env.DEPLOYMENT !== "production" ? (
    <Route
      path="rhf"
      data-ignored={true}
      element={
        <RhfTestFormPage>
          <Outlet />
        </RhfTestFormPage>
      }
    >
      <Route path="form" element={<SimpleForm />} />
      <Route path="field" element={<SingleFieldForm />} />
    </Route>
  ) : null;
