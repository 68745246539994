import { useEffect } from "react";

import { useAppDispatch } from "@js/hooks";

import { clearJobToEdit, fetchJobToEdit } from "../../actions";

export const useFetchJobToEdit = (jobId: number | undefined) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (jobId) {
      dispatch(fetchJobToEdit(jobId));
    }
  }, [dispatch, jobId]);

  useEffect(() => {
    return () => {
      dispatch(clearJobToEdit());
    };
  }, [dispatch]);
};
