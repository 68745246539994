import { change, reset, submit } from "redux-form";

import { Button, Typography } from "@hexocean/braintrust-ui-components";
import { fetchCurrentUser } from "@js/apps/auth/actions";
import {
  closeAddWithdrawalMethodOTPModal,
  openAddWithdrawalMethodOTPModal,
} from "@js/apps/withdrawal/components/add-withdrawal-method/withdrawal-method-otp-modal-instance";
import { Modal } from "@js/components/modal";
import { useAppDispatch, useAppSelector } from "@js/hooks";
import type { WithdrawalFormRequirements } from "@js/types/withdrawals";

import {
  fetchCurrencyRequirementsClear,
  resendConfirmAddWithdrawalMethod,
} from "../../actions";
import type { WithdrawalMethodsContainerRenderProps } from "../../containers/withdrawal-methods";
import {
  ADD_STRIPE_WITHDRAWAL_METHOD_FORM_ID,
  AddStripWithdrawalMethodForm,
} from "../../forms/add-stripe-withdrawal-method";
import AddTransferWiseWithdrawalForm, {
  ADD_TRANSFERWISE_WITHDRAWAL_FORM_ID,
} from "../../forms/add-transferwise-withdrawal";

export const AddWithdrawalMethodModal = Modal("add-withdrawal-method", {
  keepOnBackdropClick: true,
  closeButton: false,
});

type AddWithdrawalMethodProps =
  Partial<WithdrawalMethodsContainerRenderProps> & {
    formRequirements: WithdrawalFormRequirements;
  };

export const AddWithdrawalMethod = ({
  createTransferWiseWithdrawalMethod,
  createStripeWithdrawalMethod,
  choosenWithdrawalMethod,
  formRequirements,
  creatingWithdrawalMethod,
}: AddWithdrawalMethodProps) => {
  const dispatch = useAppDispatch();

  const close = () => {
    dispatch(fetchCurrencyRequirementsClear());
    dispatch(fetchCurrentUser());
    AddWithdrawalMethodModal.close();
    closeAddWithdrawalMethodOTPModal();
  };

  const onSubmit = () => {
    switch (choosenWithdrawalMethod) {
      case ENUMS.WithdrawalContentType.TransferWiseWithdrawalMethod:
        return dispatch(submit(ADD_TRANSFERWISE_WITHDRAWAL_FORM_ID));
      case ENUMS.WithdrawalContentType.StripeWithdrawalMethod:
        return dispatch(submit(ADD_STRIPE_WITHDRAWAL_METHOD_FORM_ID));
      default:
        return null;
    }
  };

  const handleSubmit = (data) => {
    switch (choosenWithdrawalMethod) {
      case ENUMS.WithdrawalContentType.TransferWiseWithdrawalMethod:
        return (
          createTransferWiseWithdrawalMethod &&
          createTransferWiseWithdrawalMethod(data)
            .then((res) => {
              dispatch(reset(ADD_TRANSFERWISE_WITHDRAWAL_FORM_ID));
              return res;
            })
            .then(({ key }) =>
              dispatch(change(ADD_TRANSFERWISE_WITHDRAWAL_FORM_ID, "key", key)),
            )
            .then(close)
            .catch((exception) => {
              if (exception.errors && exception.errors.code_is_required) {
                openAddWithdrawalMethodOTPModal();
              } else {
                closeAddWithdrawalMethodOTPModal();
              }

              throw exception;
            })
        );
      case ENUMS.WithdrawalContentType.StripeWithdrawalMethod:
        return (
          createStripeWithdrawalMethod &&
          createStripeWithdrawalMethod().then(close)
        );
      default:
        return null;
    }
  };

  return (
    <div className="add-withdrawal-method">
      <AddWithdrawalMethodModal>
        {choosenWithdrawalMethod ===
        ENUMS.WithdrawalContentType.TransferWiseWithdrawalMethod ? (
          <TransferWiseMethodContent
            creatingWithdrawalMethod={creatingWithdrawalMethod}
            formRequirements={formRequirements}
            handleSubmit={handleSubmit}
            close={close}
            onSubmit={onSubmit}
          />
        ) : (
          <StripeMethodContent
            creatingWithdrawalMethod={creatingWithdrawalMethod}
            handleSubmit={handleSubmit}
            close={close}
            onSubmit={onSubmit}
          />
        )}
      </AddWithdrawalMethodModal>
    </div>
  );
};

type TransferWiseMethodContentProps = {
  close: () => void;
  formRequirements: WithdrawalFormRequirements;
  handleSubmit: (data: Record<string, unknown>) => void;
  onSubmit: () => void;
  creatingWithdrawalMethod: boolean | undefined;
};

const TransferWiseMethodContent = ({
  formRequirements,
  handleSubmit,
  onSubmit,
  close,
  creatingWithdrawalMethod,
}: TransferWiseMethodContentProps) => {
  return (
    <div className="add-withdrawal-method-modal-content">
      <Typography component="h3" variant="title" fontWeight={400}>
        Add Banking Information
      </Typography>
      <div className="add-withdrawal-method-modal-content-card-element">
        <AddTransferWiseWithdrawalForm
          formRequirements={formRequirements}
          onSubmit={handleSubmit}
        />
      </div>
      <div className="buttons right">
        <Button variant="transparent" color="secondary" onClick={close}>
          Cancel
        </Button>
        <Button
          variant="transparent"
          color="secondary"
          disabled={creatingWithdrawalMethod}
          onClick={onSubmit}
        >
          Save
        </Button>
      </div>
    </div>
  );
};

type StripeMethodContentProps = {
  close: () => void;
  handleSubmit: (data: Record<string, unknown>) => void;
  onSubmit: () => void;
  creatingWithdrawalMethod: boolean | undefined;
};
const StripeMethodContent = ({
  handleSubmit,
  onSubmit,
  close,
  creatingWithdrawalMethod,
}: StripeMethodContentProps) => {
  const showResendEmail = useAppSelector(
    (state) => state.withdrawal.resendConfirmationEmail,
  );
  const forms = useAppSelector((state) => state.form);
  const isForm = !!forms["create-stripe-withdrawal-method"];
  return (
    <div className="add-withdrawal-method-modal-content">
      <Typography component="h3" variant="title" fontWeight={400}>
        Add Stripe Account
      </Typography>
      <div className="add-withdrawal-method-modal-content-card-element">
        <AddStripWithdrawalMethodForm onSubmit={handleSubmit} />
        {isForm && showResendEmail && (
          <Button
            variant="secondary"
            size="x-small"
            onClick={resendConfirmAddWithdrawalMethod}
          >
            Resend confirmation e-mail
          </Button>
        )}
      </div>
      <div className="buttons right mt+">
        <Button variant="transparent" color="secondary" onClick={close}>
          Cancel
        </Button>
        <Button
          variant="transparent"
          color="primary"
          disabled={creatingWithdrawalMethod}
          onClick={onSubmit}
        >
          Add Stripe account
        </Button>
      </div>
    </div>
  );
};
