import { Box, Button, Menu, Tooltip } from "@hexocean/braintrust-ui-components";
import { useMediaQuery } from "@hexocean/braintrust-ui-components/hooks";
import { KeyboardArrowDownIcon } from "@hexocean/braintrust-ui-components/Icons";
import { useIsEmployerUserVerified } from "@js/apps/common/hooks/is-employer-verified";
import { RouterLink } from "@js/components/link";

import style from "./style.module.scss";

type OfferButtonProps = {
  editUrl: string;
  previewUrl: string;
};

export const OfferButton = ({ editUrl, previewUrl }: OfferButtonProps) => {
  const isMobile = useMediaQuery(420);
  const isEmployerVerified = useIsEmployerUserVerified();

  return (
    <Tooltip
      title={
        isEmployerVerified ? "" : "Please verify your email to see this offer."
      }
      disabled={isEmployerVerified}
    >
      <Box>
        <Button
          variant="primary"
          shape="squared"
          RouterLink={RouterLink}
          to={previewUrl}
          disabled={!isEmployerVerified}
          size={isMobile ? "x-small" : "small"}
          sx={{ height: "40px" }}
          style={{
            paddingRight: 0,
            borderRadius: "8px 0 0 8px",
          }}
        >
          View offer
        </Button>
        <Menu
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchor={
            <Button
              variant="primary"
              shape="squared"
              disabled={!isEmployerVerified}
              aria-label="Open view offer menu"
              endIcon={<KeyboardArrowDownIcon />}
              style={{
                borderRadius: "0 8px 8px 0",
                minWidth: "unset",
                paddingLeft: 0,
                height: "40px",
              }}
            />
          }
        >
          <Menu.Item
            className={style.viewOrEditOfferBtn}
            component={RouterLink}
            disabled={!isEmployerVerified}
            to={editUrl}
          >
            Edit Offer
          </Menu.Item>
        </Menu>
      </Box>
    </Tooltip>
  );
};
