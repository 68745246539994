import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import cs from "classnames";

import { Box, Typography } from "@hexocean/braintrust-ui-components";
import { Sanitize } from "@js/components/sanitize";
import type { ApplicationAnswer, ApplicationQuestion } from "@js/types/jobs";
import { mapKeys } from "@js/utils";

type ApplicationQaListProps = {
  questions: ApplicationQuestion[];
  answers: ApplicationAnswer[];
  header?: JSX.Element;
  showSingleQuestion?: boolean;
  height?: number;
  link?: string;
};

export const ApplicationQAList = ({
  questions,
  answers,
  header,
  showSingleQuestion,
  height,
  link,
}: ApplicationQaListProps) => {
  const navigate = useNavigate();

  const [showOverlay, setShowOverlay] = useState(false);
  const contentBox = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);

  const _answers = mapKeys(answers, "application_question");
  const questionsToDisplay = showSingleQuestion
    ? filterQuestionToDisplay(questions, _answers).slice(0, 1)
    : filterQuestionToDisplay(questions, _answers);

  useEffect(() => {
    if (contentBox && contentRef) {
      const contentWrapper =
        contentBox.current?.getBoundingClientRect().height || 0;
      const contentHeight =
        contentRef.current?.getBoundingClientRect().height || 0;

      const _showOverlay = contentHeight > contentWrapper;

      setShowOverlay(_showOverlay);
    }
  }, [showOverlay]);

  const shouldOpenOverlayOnClick = link && !!height && showOverlay;

  const handleOverlayClick = () => {
    if (shouldOpenOverlayOnClick) {
      navigate(link);
    }
  };

  if (!questionsToDisplay.length) return null;

  return (
    <>
      {!!header && header}
      {questionsToDisplay.map(({ id, question }) => {
        return (
          <div
            key={id}
            className={cs("application-question-answer-list", {
              "application-question-answer-list--clickable":
                shouldOpenOverlayOnClick,
            })}
          >
            <Typography component="p" variant="label" size="small">
              {question}
            </Typography>
            <Box
              ref={contentBox}
              height={height}
              className={cs("text-content", {
                "overlay-text-shadow": !!height && showOverlay,
              })}
              onClick={handleOverlayClick}
            >
              {_answers[id] ? (
                <Box ref={contentRef}>
                  <Sanitize input={_answers[id].answer} />
                </Box>
              ) : (
                <span className="color-gray">No Answer.</span>
              )}
            </Box>
          </div>
        );
      })}
    </>
  );
};

type Answers = {
  [key: number]: ApplicationAnswer;
};

const filterQuestionToDisplay = (
  questions: ApplicationQuestion[],
  answers: Answers,
) => {
  return questions.filter(
    (question) =>
      (question.is_active &&
        !Object.keys(answers).includes(`${question.original_question}`)) ||
      (!question.is_active && Object.keys(answers).includes(`${question.id}`)),
  );
};
