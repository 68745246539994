import React from "react";

import { AutoBlockUserWarningModalProvider } from "./components/auto-block-warning-modal";
import { CanViewInvoicesProvider } from "./components/can-view-invoices-provider";

export const EmployerGlobalProvider = () => {
  return (
    <>
      <CanViewInvoicesProvider />
      <AutoBlockUserWarningModalProvider />
    </>
  );
};
