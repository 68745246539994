import type { WrappedFieldProps } from "redux-form";

import { Box, Checkbox, Typography } from "@hexocean/braintrust-ui-components";
import { clickCheckAvailabilityOption } from "@js/apps/freelancer/actions";
import { useAppDispatch } from "@js/hooks";

type AvailabilityOptionsFieldProps = {
  availability_for_work_options: WrappedFieldProps;
  availability_for_work: WrappedFieldProps;
  options: {
    value: EnumType<typeof ENUMS.FreelancerAvailabilityForWork>;
    label: string;
  }[];
};

export const AvailabilityOptionsField = ({
  availability_for_work_options,
  availability_for_work,
  options,
}: AvailabilityOptionsFieldProps) => {
  const dispatch = useAppDispatch();
  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean,
  ) => {
    let newValues: string[];

    if (!checked) {
      newValues = availability_for_work_options.input.value.filter(
        (optionValue) => optionValue !== event.target.value,
      );
    } else {
      newValues = [
        ...availability_for_work_options.input.value,
        event.target.value,
      ];
    }
    availability_for_work_options.input.onChange(newValues);
  };

  const handleAvailabilityClick = (availabilityOption) =>
    dispatch(clickCheckAvailabilityOption(availabilityOption));

  return (
    <>
      <Box display="flex" flexDirection="column">
        {options.map((option) => {
          const isChecked = availability_for_work_options.input.value.includes(
            option.value,
          );

          return (
            <Checkbox
              key={option.value}
              checked={isChecked}
              label={option.label}
              value={option.value}
              disabled={availability_for_work.input.value === false}
              onChange={handleChange}
              onClick={() =>
                !isChecked && handleAvailabilityClick(option.value)
              }
            />
          );
        })}
      </Box>
    </>
  );
};

export const AvailabilityOptionsFieldError = ({ meta }: WrappedFieldProps) => {
  const error = meta.touched && meta.error;

  if (error) {
    return (
      <Typography component="p" size="small" error>
        {error}
      </Typography>
    );
  }

  return null;
};
