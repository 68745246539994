import React from "react";
import { useLocation } from "react-router-dom";

import {
  Box,
  Button,
  Grid,
  Typography,
  UnderlinedTabs,
} from "@hexocean/braintrust-ui-components";
import { useMediaQuery } from "@hexocean/braintrust-ui-components/hooks";
import { useAccountType } from "@js/apps/common/hooks";
import { RouterLink } from "@js/components/link";
import { AppLayout } from "@js/layouts/app";

type OwnProps = {
  children: React.ReactNode;
  pageTitle?: string;
  onSave?: () => void;
  onCancel?: () => void;
  isSaveDisabled?: boolean;
  onCancelDisabled?: boolean;
};

const getMenuItems = () => {
  const menuItems = [
    {
      path: "/settings/account_info/",
      label: "Account",
    },
    {
      path: "/settings/privacy_security/",
      label: "Privacy & Security",
    },
    {
      path: "/settings/payments/",
      label: "Payments",
    },
    {
      path: "/settings/notifications/",
      label: "Notifications",
    },
  ].filter(Boolean) as { path: string; label: string }[];

  return menuItems;
};

export const SettingsLayout = ({
  children,
  pageTitle,
  onSave,
  onCancel,
  isSaveDisabled = false,
  onCancelDisabled = false,
}: OwnProps): JSX.Element => {
  const { pathname } = useLocation();
  const menuItems = getMenuItems();
  const isMobile = useMediaQuery("sm");
  const { isEmployer } = useAccountType();

  const showSaveAndCancelButtons =
    !isEmployer || pathname.includes("account_info");

  return (
    <AppLayout pageTitle={pageTitle} spacing={isMobile ? 0 : 8} showFooter>
      <Grid
        container
        item
        xs={12}
        alignItems="center"
        justifyContent="space-between"
        mb={isMobile ? 3 : 0}
        gap={1}
      >
        <Typography
          variant="display"
          component="h1"
          size={isMobile ? "small" : "medium"}
          fontWeight={400}
          className="dib underline"
        >
          Settings
        </Typography>
        {showSaveAndCancelButtons && (
          <Box>
            {onCancel && (
              <Button
                variant="secondary"
                onClick={onCancel}
                size="medium"
                disabled={onCancelDisabled}
              >
                Cancel
              </Button>
            )}
            {onSave && (
              <Button
                variant="primary"
                onClick={onSave}
                disabled={isSaveDisabled}
                size="medium"
                style={{ marginLeft: 8 }}
              >
                Save
              </Button>
            )}
          </Box>
        )}
      </Grid>
      <Grid item xs={12}>
        <Box mb={4}>
          <UnderlinedTabs
            items={menuItems}
            pathname={pathname}
            RouterLink={RouterLink}
          />
        </Box>
      </Grid>
      <Grid item xs={12}>
        {children}
      </Grid>
    </AppLayout>
  );
};
