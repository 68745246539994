import React from "react";
import type { WrappedFieldsProps } from "redux-form";
import { Fields } from "redux-form";

import { Box, Typography } from "@hexocean/braintrust-ui-components";
import { FieldSectionPanel } from "@js/apps/jobs/forms/fields";
import { CreateJobSkillsField } from "@js/apps/skills/fields";
import { RouterLink } from "@js/components/link";
import { shouldDisplayError } from "@js/forms/utils";

export const SkillsSection = ({
  new_skills,
  top_skills,
}: WrappedFieldsProps) => {
  const fields = [new_skills, top_skills];
  const displayError = shouldDisplayError(fields);

  return (
    <FieldSectionPanel
      title="Skills"
      description="Target the right Talent with the skills you need"
      isError={displayError}
    >
      <Box display="flex" flexDirection="column" maxHeight="750px">
        <Typography component="p" size="large" mb={2}>
          Add as many skills as you need to, marking at least 1 top skill to
          strengthen your matches
        </Typography>
        <Typography component="p" size="small" mb={2}>
          How does this help market your job and curate your Talent
          applications?{" "}
          <Typography
            component="a"
            variant="paragraph"
            to={SETTINGS.SKILL_MANAGEMENT_COMPONENT_JOB_LINK}
            RouterLink={RouterLink}
            pointer
            size="small"
          >
            Learn more
          </Typography>
          .
        </Typography>
        <Fields
          names={["is_ai_generated", "role", "new_skills", "top_skills"]}
          component={CreateJobSkillsField}
        />
      </Box>
    </FieldSectionPanel>
  );
};
