import React from "react";

import type { ButtonProps } from "@hexocean/braintrust-ui-components";
import { Button } from "@hexocean/braintrust-ui-components";
import { openProfileCompletionSequenceModal } from "@js/apps/freelancer/components/profile-completion-sequence/profile-completion-sequence";
import {
  useFirstUnfinishedSectionLink,
  useFreelancerProfile,
} from "@js/apps/freelancer/hooks";
import { useCurrentFreelancerProfileCompletion } from "@js/apps/freelancer/hooks/use-current-freelancer-profile-completion";
import { RouterLink } from "@js/components/link";

type CompleteProfileButtonProps = {
  openInNewTab?: boolean;
} & Partial<ButtonProps>;

export const CompleteProfileButton = ({
  openInNewTab,
  ...props
}: CompleteProfileButtonProps) => {
  const freelancer = useFreelancerProfile();
  const { data: profileCompletion } = useCurrentFreelancerProfileCompletion();
  const link = useFirstUnfinishedSectionLink(profileCompletion);

  return (
    <Button
      variant="primary"
      onClick={(ev: React.MouseEvent) => {
        if (freelancer) {
          ev.preventDefault();
          openProfileCompletionSequenceModal(freelancer);
        }
      }}
      to={link}
      RouterLink={RouterLink}
      target={openInNewTab ? "_blank" : undefined}
      {...props}
    >
      Complete profile
    </Button>
  );
};
