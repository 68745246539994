import type { HTMLAttributes, ReactNode } from "react";
import cs from "classnames";

import { InputAdornment } from "@hexocean/braintrust-ui-components";
import { PersonAddIcon } from "@hexocean/braintrust-ui-components/Icons";
import { InvitationsAutocomplete } from "@js/components/autocomplete-new/invitations";
import { EMAIL_REGEX } from "@js/forms/validators";
import type { EmployerTeamMemberType } from "@js/types/employer";

import type { EmployerTeamMemberOptionType } from "../../hooks/use-team-member-invitations";
import { useTeamMemberInvitations } from "../../hooks/use-team-member-invitations";

import { TeamMemberInvitationsEmptyState } from "./team-member-invitations-empty-state";
import { TeamMemberInvitationsInvitationItem } from "./team-member-invitations-invitation-item";
import { TeamMemberInvitationsMemberItem } from "./team-member-invitations-member-item";

import styles from "./styles.module.scss";

export type TeamMemberInvitationsAutocompleteProps = {
  toggleTeamMemberId: (teamMemberId: number) => void;
  toggleInvitation: (email: string) => void;
  selectedInvitations: string[];
  selectedTeamMemberIds: number[];
  teamMembers: EmployerTeamMemberType[];
  invitationActionButton: ReactNode;
  memberActionButton: ReactNode;
};

export const TeamMemberInvitationsAutocomplete = ({
  toggleInvitation,
  toggleTeamMemberId,
  selectedInvitations,
  selectedTeamMemberIds,
  teamMembers,
  invitationActionButton,
  memberActionButton,
}: TeamMemberInvitationsAutocompleteProps) => {
  const {
    isOpen,
    inputValue,
    setInputValue,
    handleClose,
    handleOpen,
    teamMemberInvitationsValue,
  } = useTeamMemberInvitations({
    teamMembers,
    selectedTeamMemberIds,
  });

  const handleChange = (values: EmployerTeamMemberOptionType[]) => {
    const selectedOption = values[values.length - 1];
    if (!selectedOption) {
      return;
    }

    if ("inputValue" in selectedOption) {
      toggleInvitation(selectedOption.inputValue);
    } else if (selectedOption.id) {
      toggleTeamMemberId(selectedOption.id);
    }
  };

  const renderTeamMemberOption = (
    renderOptionProps: HTMLAttributes<HTMLLIElement>,
    option: EmployerTeamMemberOptionType,
  ) => {
    if ("inputValue" in option) {
      const isInvited = selectedInvitations.some((emailInvitation) => {
        return emailInvitation === option.inputValue;
      });

      return (
        <li
          {...renderOptionProps}
          key={option.inputValue}
          className={cs(styles.option, renderOptionProps.className, {
            [styles.invitedOption]: isInvited,
          })}
          aria-disabled={isInvited}
        >
          <TeamMemberInvitationsInvitationItem
            email={option.inputValue}
            isInvited={isInvited}
            invitationActionButton={invitationActionButton}
          />
        </li>
      );
    }

    const teamMember = teamMembers.find(
      (member) => "id" in option && member.id === option.id,
    );

    if (!teamMember) {
      return <></>;
    }

    return (
      <li
        {...renderOptionProps}
        key={teamMember.id}
        className={cs(styles.option, renderOptionProps.className)}
      >
        <TeamMemberInvitationsMemberItem
          teamMember={teamMember}
          memberActionButton={memberActionButton}
        />
      </li>
    );
  };

  const filterTeamMemberOptions = (
    options: EmployerTeamMemberOptionType[],
    params: { inputValue: string },
  ) => {
    const trimmedInput = params.inputValue.trim();
    const optionsCopy = options.filter((option) => {
      return (
        "public_name" in option &&
        option.public_name.toLowerCase().includes(trimmedInput.toLowerCase())
      );
    });

    if (trimmedInput !== "" && trimmedInput.match(EMAIL_REGEX)) {
      optionsCopy.push({
        inputValue: trimmedInput,
      });
    }

    return optionsCopy;
  };

  return (
    <InvitationsAutocomplete
      open={isOpen}
      onOpen={handleOpen}
      onClose={handleClose}
      multiple
      options={teamMembers}
      className={
        !inputValue ? styles.autocompletePlaceholderPadding : undefined
      }
      inputValue={inputValue}
      value={teamMemberInvitationsValue}
      onInputChange={(_event, input) => {
        setInputValue(input);
      }}
      noOptionsText={<TeamMemberInvitationsEmptyState />}
      onChange={(_event, newValue, reason) => {
        if (!newValue) {
          return;
        }

        if (reason === "clear" || reason === "removeOption") {
          setInputValue("");
          return;
        }

        handleChange(newValue);
      }}
      filterSelectedOptions
      placeholder="Add people by name or email..."
      renderOption={renderTeamMemberOption}
      filterOptions={filterTeamMemberOptions}
      getOptionLabel={(option) => {
        if ("inputValue" in option) {
          return option.inputValue;
        }

        return option.public_name;
      }}
      inputProps={{
        startAdornment: !inputValue ? (
          <InputAdornment position="end" sx={{ ml: 3 }}>
            <PersonAddIcon className={styles.personAddIcon} />
          </InputAdornment>
        ) : null,
      }}
    />
  );
};
