import { useCallback } from "react";
import { skipToken } from "@reduxjs/toolkit/query";

import {
  addLocationToCustomLocationField,
  addLocationToLocationField,
  addLocationToPlaceIdField,
  removeDuplicatedLocationFromCustomLocationField,
  removeDuplicatedLocationFromLocationField,
  removeDuplicatedLocationFromPlaceIdField,
  removeLocationFromCustomLocationField,
  removeLocationFromLocationField,
  removeLocationFromPlaceIdField,
  reset,
} from "@js/apps/common/hooks/use-talent-location-filter-utils";
import { useGetTalentPopularLocationsQuery } from "@js/apps/freelancer/api";
import type {
  LocationSelectReason,
  TalentMixedLocation,
} from "@js/apps/freelancer/types";
import { useGetBidRecommendedLocationsQuery } from "@js/apps/jobs/apps/bids/api";
import type { TypedWrappedFieldProps } from "@js/forms/utils";

import { isLocationDuplicated } from "./is-location-duplicated";
import { prepareLocationData } from "./prepare-location-data";

/*
Location is used to render all selected locations on the UI, other fields (custom_location, place_id, session_token)
are used to prepare payload for backend.
*/
export type UseTalentLocationFilterFieldManagerArg = {
  location: TypedWrappedFieldProps<TalentMixedLocation[] | null>;
  custom_location: TypedWrappedFieldProps<string[] | null>;
  place_id: TypedWrappedFieldProps<string[] | null>;
  session_token: TypedWrappedFieldProps<string[] | null>;
  include_verified_locations_only?: TypedWrappedFieldProps<boolean | null>;
  jobId?: number;
};

export const useTalentLocationFilterFieldManager = ({
  location,
  custom_location,
  place_id,
  session_token,
  include_verified_locations_only,
  jobId,
}: UseTalentLocationFilterFieldManagerArg) => {
  const { data: popularLocations, isLoading: isLoadingPopularLocations } =
    useGetTalentPopularLocationsQuery(undefined, {
      skip: Boolean(jobId),
    });

  const {
    data: bidRecommendedLocations,
    isLoading: isLoadingBidRecommendedLocations,
  } = useGetBidRecommendedLocationsQuery(jobId ? { jobId } : skipToken);

  const recommendedLocations =
    popularLocations || bidRecommendedLocations?.locations;

  const handleLocationRemoval = useCallback(
    (value: TalentMixedLocation[]) => {
      removeLocationFromPlaceIdField({
        updatedLocationValue: value,
        sessionTokenField: session_token,
        placeIdField: place_id,
      });

      removeLocationFromCustomLocationField(value, custom_location);
      removeLocationFromLocationField(value, location);
    },
    [session_token, place_id, custom_location, location],
  );

  const handleLocationAdd = useCallback(
    (
      singleLocationData: TalentMixedLocation,
      resetLocationsArray?: boolean,
    ) => {
      addLocationToPlaceIdField({
        locationToAdd: singleLocationData,
        placeIdField: place_id,
        sessionTokenField: session_token,
        resetLocationsArray: resetLocationsArray,
      });

      addLocationToCustomLocationField({
        locationToAdd: singleLocationData,
        customLocationField: custom_location,
        resetLocationsArray: resetLocationsArray,
      });

      addLocationToLocationField({
        locationToAdd: singleLocationData,
        locationField: location,
        resetLocationsArray: resetLocationsArray,
      });
    },
    [custom_location, location, session_token, place_id],
  );

  const handleDuplicatedLocationRemoval = useCallback(
    (singleLocationData: TalentMixedLocation) => {
      removeDuplicatedLocationFromPlaceIdField({
        locationToRemove: singleLocationData,
        placeIdField: place_id,
        sessionTokenField: session_token,
      });

      removeDuplicatedLocationFromCustomLocationField(
        singleLocationData,
        custom_location,
      );

      removeDuplicatedLocationFromLocationField(singleLocationData, location);
    },
    [custom_location, place_id, session_token, location],
  );

  const updateLocationFilterForm = useCallback(
    (
      locationsData: (TalentMixedLocation | string)[],
      reason?: LocationSelectReason,
      //This boolean should be used to when you wish to reset an array and add a new location at the same time (async/sync issue).
      resetLocationsArray?: boolean,
    ) => {
      const singleLocationData: TalentMixedLocation = prepareLocationData({
        value: locationsData,
        popularLocations: recommendedLocations ?? [],
      });
      const isDuplicated = isLocationDuplicated({
        locationData: singleLocationData,
        customLocationField: custom_location,
        placeIdField: place_id,
      });

      if (reason === "removeOption" || reason === "clear") {
        handleLocationRemoval(locationsData as TalentMixedLocation[]);
      } else if (isDuplicated) {
        handleDuplicatedLocationRemoval(singleLocationData);
      } else {
        handleLocationAdd(singleLocationData, resetLocationsArray);
      }
    },
    [
      recommendedLocations,
      custom_location,
      place_id,
      handleLocationRemoval,
      handleDuplicatedLocationRemoval,
      handleLocationAdd,
    ],
  );

  const handleReset = () => {
    reset({
      locationField: location,
      customLocationField: custom_location,
      placeIdField: place_id,
      sessionTokenField: session_token,
      includeVerifiedLocationsOnlyField: include_verified_locations_only,
    });
  };

  return {
    handleReset: handleReset,
    handleLocationSelection: updateLocationFilterForm,
    recommendedLocations: recommendedLocations || [],
    recommendedByJobRequired: bidRecommendedLocations?.required_locations,
    isRecommendedLocationsLoading:
      isLoadingPopularLocations || isLoadingBidRecommendedLocations,
  };
};
