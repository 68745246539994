import React from "react";

import { Loader } from "@hexocean/braintrust-ui-components";
import { useGetHelpOffersQuery } from "@js/apps/give-and-get-help/api";
import { PostLocation } from "@js/apps/give-and-get-help/context/post-location";
import { useHideHelpOffersExtraMsg } from "@js/apps/give-and-get-help/hooks";

import { DashboardHelpOfferCard } from "../help-offers/help-offer-card";

import { OffersEmptyState } from "./empty-state";

type OfferListProps = {
  direction?: EnumType<typeof ENUMS.HelpOfferDirectionFilter>;
  status?: EnumType<typeof ENUMS.HelpOfferStatus>;
  otherUserId?: number;
};

export const HelpOfferList = ({
  direction = ENUMS.HelpOfferDirectionFilter.RECEIVED,
  status,
  otherUserId,
}: OfferListProps) => {
  const { isFetching, currentData: helpOffers } = useGetHelpOffersQuery({
    direction,
    status,
    other_user: otherUserId,
  });
  // we should display the extra msg if it hasn't been shown before
  const { offersWithExtraMsgNotShown } = useHideHelpOffersExtraMsg(helpOffers);

  if (isFetching) {
    return <Loader centered />;
  }

  if (!helpOffers?.length) {
    return (
      <PostLocation.Provider value={PostLocation.Values.dashboard_help_offers}>
        <OffersEmptyState />
      </PostLocation.Provider>
    );
  }

  return (
    <div className="help-offer-list">
      {helpOffers.map((offer) => (
        <DashboardHelpOfferCard
          key={offer.id}
          offer={offer}
          shouldShowMessage={offersWithExtraMsgNotShown.includes(offer.id)}
        />
      ))}
    </div>
  );
};
