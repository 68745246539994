import { useOpenMessengerModal } from "@js/apps/messenger/hooks/use-open-messenger-modal";
import type { UserBubbleProps } from "@js/components/user-bubble";
import { UserBubble } from "@js/components/user-bubble";
import { useAppSelector } from "@js/hooks";
import type { Job, JobCreator, JobOwnersUserData } from "@js/types/jobs";

type MatchItemProps = Omit<UserBubbleProps, "user"> & {
  color?: string;
  action?: () => void;
  job: Job;
  user: JobOwnersUserData | JobCreator;
};

export const MatchItem = ({
  user,
  title,
  name,
  actionIconType,
  to,
  color,
  job,
  action,
}: MatchItemProps) => {
  const loggedUserId = useAppSelector((state) => state.auth.user?.id);
  const { openMessengerModal } = useOpenMessengerModal();

  const onClickAction = () => {
    if (action) {
      action();
      return;
    }

    if (!user?.can_be_messaged) {
      return;
    }

    if (loggedUserId && actionIconType === "view" && loggedUserId !== user.id) {
      openMessengerModal(
        { context: { participants: [user.id, loggedUserId] } },
        { initialMessage: `Related to Job ID ${job.id} - ${job.title}: ` },
      );
    }
  };

  return (
    <UserBubble
      color={color}
      user={user}
      actionIconType={user?.can_be_messaged || to ? actionIconType : undefined}
      title={title}
      name={name}
      to={to}
      onClick={onClickAction}
    />
  );
};
