import { Typography } from "@hexocean/braintrust-ui-components";
import { useMediaQuery } from "@hexocean/braintrust-ui-components/hooks";

export const Title = ({ children, ...props }) => {
  const isMobile = useMediaQuery("sm");

  return (
    <Typography
      component="h1"
      variant="title"
      size={isMobile ? "small" : "medium"}
      fontWeight={400}
      {...props}
    >
      {children}
    </Typography>
  );
};

export const TitleSmall = ({ children, ...props }) => {
  const isMobile = useMediaQuery("sm");

  return (
    <Typography
      component="p"
      variant={isMobile ? "paragraph" : "title"}
      size={isMobile ? "large" : "small"}
      fontWeight={400}
      {...props}
    >
      {children}
    </Typography>
  );
};
