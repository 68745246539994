import { useCallback, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { isEqual } from "underscore";

import type {
  TalentFiltersFetchParams,
  TalentFiltersFormValuesType,
} from "@js/apps/common/components/filters";
import { useTalentFilters } from "@js/apps/common/components/filters";
import { valuesToSearchParams } from "@js/apps/filters/utils";
import { savedFilterLoaded } from "@js/apps/jobs/apps/listing/actions";
import { useGetActiveFilterName } from "@js/apps/jobs/apps/listing/hooks/active-filter-name";
import { useLoadSessionSavedFiltersOnMount } from "@js/apps/jobs/apps/listing/hooks/session-filters";
import { getFiltersStorageKey } from "@js/apps/jobs/apps/listing/utils";
import {
  popularSearchTabToUrl,
  searchTabToUrl,
} from "@js/apps/universal-search/constants";
import { Snackbar } from "@js/components/snackbar";
import { useAppDispatch } from "@js/hooks";
import { LocalStorage } from "@js/services";
import { convertValuesToString } from "@js/utils";

import { useGetTalentsQuery } from "../../api";
import { TALENT_LOCATION } from "../../constants";
import { APPROVAL_STATUS_VALUES } from "../../views/talent-list/fields/approval-status-filter/constants";

export const useTalentListing = (
  location: EnumType<typeof TALENT_LOCATION>,
) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [, setSearchParams] = useSearchParams();

  const loadNewFilters = (savedFilters) => {
    const { filters: newFilters } = savedFilters;
    const mappedFilters = mapApprovedParam(newFilters);
    const newSearchParams = valuesToSearchParams(mappedFilters);
    setSearchParams(newSearchParams);

    dispatch(savedFilterLoaded({ name: savedFilters.name, location }));
  };

  const filtersStorageKey = getFiltersStorageKey(location);
  const { filters, isAnyFilterApplied } = useTalentFilters();
  const { isReadyToFetchListing } = useLoadSessionSavedFiltersOnMount({
    isAnyFilterApplied,
    filtersStorageKey,
  });

  const {
    refetch: fetchTalents,
    isFetching,
    isUninitialized,
    data: talents,
    isSuccess,
    isError,
    error,
    originalArgs,
  } = useGetTalentsQuery(filters, {
    skip: !isReadyToFetchListing,
    refetchOnMountOrArgChange: true,
  });

  const { loadedFilters } = useGetActiveFilterName(location);

  const resetLoadedFilters = useCallback(
    (filtersToReset: Partial<TalentFiltersFetchParams>) => {
      if (!shouldUnsetSavedFilterName({ filtersToReset, loadedFilters })) {
        return;
      }

      dispatch(
        savedFilterLoaded({
          location,
          name: null,
        }),
      );
    },
    [dispatch, loadedFilters, location],
  );

  useEffect(() => {
    if (!isError) return;

    //not having error status equals to no response code from request, which means that request didn't go off due to no network connection
    if (
      error !== null &&
      typeof error === "object" &&
      !("status" in error) &&
      originalArgs
    ) {
      Snackbar.error("Failed to fetch talents due to network error");
      //in case of error and some previous data existing in cache, we make next request (latest succeeded one) using cached response
      setSearchParams(valuesToSearchParams(originalArgs));
    } else {
      Snackbar.error("Wrong filter value provided, failed to fetch talents");
      navigate(
        location === TALENT_LOCATION.talent_search
          ? searchTabToUrl["PEOPLE"]
          : popularSearchTabToUrl["PEOPLE"],
        { replace: true },
      );
    }
  }, [isError, error, navigate, originalArgs, setSearchParams, location]);

  useEffect(() => {
    const searchWithoutIgnoredParams = window.location.search.replace(
      /page=\d/,
      "",
    );

    if (!isSuccess) return;

    resetLoadedFilters(filters);

    if (isAnyFilterApplied) {
      LocalStorage.setItem(
        filtersStorageKey,
        searchWithoutIgnoredParams.slice(1),
      );
    } else {
      LocalStorage.removeItem(filtersStorageKey);
    }
  }, [
    isSuccess,
    resetLoadedFilters,
    isAnyFilterApplied,
    filtersStorageKey,
    filters,
  ]);

  return {
    fetchTalents,
    talents: talents?.results || [],
    count: talents?.count || 0,
    total_count: talents?.total_count || 0,
    loading: isFetching || isUninitialized,
    isAnyFilterApplied,
    filters,
    loadNewFilters,
  };
};

const shouldUnsetSavedFilterName = ({
  filtersToReset,
  loadedFilters,
}: {
  filtersToReset: Partial<TalentFiltersFetchParams>;
  loadedFilters: Record<string, unknown> | undefined;
}) => {
  if (!loadedFilters) {
    return;
  }

  const filtersWithoutSessionToken = {
    ...filtersToReset,
  };
  delete filtersWithoutSessionToken.session_token;

  const filtersWithStringifiedValues = convertValuesToString(
    filtersWithoutSessionToken,
  );

  return !isEqual(filtersWithStringifiedValues, loadedFilters);
};

const mapApprovedParam = (filters: Partial<TalentFiltersFormValuesType>) => {
  const newFilters = { ...filters };

  if (newFilters.approved === true) {
    newFilters.talent_status = [APPROVAL_STATUS_VALUES.approved];
  }

  if (newFilters.approved === false) {
    newFilters.talent_status = [APPROVAL_STATUS_VALUES.notApproved];
  }

  return newFilters;
};
