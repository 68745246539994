import { useMemo, useState } from "react";
import type { WrappedFieldProps } from "redux-form";

import { useIsNodeStaff } from "@js/apps/common/hooks";
import { useSynchronizeSingleValueInternalStateWithFormValue } from "@js/components/autocomplete-new/hooks/synchronize-with-form-value";
import { enumToOptions } from "@js/utils";

type UseStatusFieldProps = {
  input: WrappedFieldProps["input"];
};

export const useInvoiceStatusField = ({ input }: UseStatusFieldProps) => {
  const isNodeStaff = useIsNodeStaff();

  const options = useMemo(() => {
    const statusOptions = [
      { value: "", label: "Any" },
      ...enumToOptions(ENUMS.InvoiceStatusLabels),
    ];

    return isNodeStaff
      ? statusOptions
      : statusOptions.filter(
          (option) => option.label !== ENUMS.InvoiceStatusLabels.written_off,
        );
  }, [isNodeStaff]);

  const [value, setValue] = useState<{
    value: string;
    label: string;
  } | null>(null);

  useSynchronizeSingleValueInternalStateWithFormValue({
    formValue: input.value,
    isValueEqualFormValue: input.value === value?.value,
    findOption: (option) => {
      return option.value === input.value;
    },
    setValue,
    options,
    initialTaxonomyLoading: !options.length,
  });

  return { options, value, setValue };
};
