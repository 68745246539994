import React from "react";

import { useGGHIntroductionModal } from "../../hooks/use-ggh-introduction-modal";

import { GGHIntroductionModalInstance } from "./ggh-introduction-modal";

export const GGHIntroductionModalProvider = () => {
  useGGHIntroductionModal();

  return <GGHIntroductionModalInstance />;
};
