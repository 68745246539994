import { useEffect } from "react";

import { fetchFreelancerOffers } from "@js/apps/freelancer/actions";
import { useAppDispatch, useAppSelector } from "@js/hooks";

type offerTypeToFetchType = EnumType<typeof ENUMS.TalentOffersSectionFilter>;

export const useFreelancerOffers = (offerTypeToFetch: offerTypeToFetchType) => {
  const dispatch = useAppDispatch();
  const loading: boolean = useAppSelector(
    (state) => state.jobs.fetchingFreelancerOffers,
  );
  const offers = useAppSelector((state) => state.freelancer.offers) || [];

  useEffect(() => {
    dispatch(
      fetchFreelancerOffers({
        section: offerTypeToFetch,
      }),
    );
  }, [dispatch, offerTypeToFetch]);

  return {
    offers,
    loading,
  };
};
