import { useLayoutEffect } from "react";

import { useScroll } from "@js/apps/jobs/context";

export const useJobBidListScroll = () => {
  const { scrollY, removeScrollY } = useScroll();

  useLayoutEffect(() => {
    if (scrollY) {
      window.scrollTo(0, parseInt(scrollY, 10));
      setTimeout(() => {
        removeScrollY();
      }, 1000);
    }
  }, [removeScrollY, scrollY]);
};
