import React, { useContext, useMemo } from "react";

import { isOfferStatusPending } from "@js/apps/jobs/apps/offers/utils";
import type { FreelancerBid, Job } from "@js/types/jobs";

import { TALENT_STATUS } from "../constants";

const TalentActionBarContext = React.createContext<{
  job: Job | null;
  bid: FreelancerBid | undefined;
  talentStatus: EnumType<typeof TALENT_STATUS>;
}>({
  job: null,
  bid: undefined,
  talentStatus: TALENT_STATUS.NO_ACTION,
});

export const TalentActionBarProvider = ({ job, bid, children }) => {
  const talentStatus = useMemo(() => getTalentStatus(bid), [bid]);

  const value = useMemo(
    () => ({
      job,
      bid,
      talentStatus,
    }),
    [job, bid, talentStatus],
  );

  return (
    <TalentActionBarContext.Provider value={value}>
      {children}
    </TalentActionBarContext.Provider>
  );
};

export const useTalentActionBarContext = () => {
  const value = useContext(TalentActionBarContext);

  if (!value) {
    throw new Error("There is no TalentActionBarProvider");
  }

  return value;
};

const getTalentStatus = (
  bid: FreelancerBid,
): EnumType<typeof TALENT_STATUS> => {
  // offer has higher priority

  if (bid?.current_offer) {
    if (isOfferStatusPending(bid?.current_offer.status)) {
      return TALENT_STATUS.OFFER_MADE;
    }

    if (bid?.current_offer.status === ENUMS.OfferStatus.ACCEPTED) {
      return TALENT_STATUS.HIRED;
    }

    if (bid?.current_offer.status === ENUMS.OfferStatus.REJECTED) {
      return TALENT_STATUS.OFFER_DECLINED;
    }
  }

  // bid section

  if (bid?.status === ENUMS.BidStatus.INTERVIEWING) {
    return TALENT_STATUS.IN_SCREENING;
  }

  if (bid?.status === ENUMS.BidStatus.NEW) {
    return TALENT_STATUS.PROPOSED;
  }

  if (bid?.status === ENUMS.BidStatus.REJECTED) {
    return TALENT_STATUS.DECLINED;
  }

  if (bid?.status === ENUMS.BidStatus.APPLICATION_IN_REVIEW) {
    return TALENT_STATUS.APPLICATION_IN_REVIEW;
  }

  return TALENT_STATUS.NO_ACTION;
};
