import { useCallback, useMemo } from "react";
import { Field, SubmissionError } from "redux-form";

import {
  Box,
  Button,
  Stack,
  useMediaQuery,
} from "@hexocean/braintrust-ui-components";
import { fetchCurrentUser } from "@js/apps/auth/actions";
import {
  useOptInIntoReferClientProgramMutation,
  useSendSelfSignupDataMutation,
} from "@js/apps/auth/api";
import { useUser } from "@js/apps/common/hooks";
import { openDecoratedSuccessSnackbar } from "@js/components/decorated-success-snackbar";
import { createFormInstance } from "@js/forms/components";
import { TextField } from "@js/forms/fields/text";
import { required } from "@js/forms/validators";
import { useAppDispatch, useAppSelector } from "@js/hooks";

type JoinClientsReferralProgramFormProps = {
  onSubmitSuccess: () => void;
};

export const JoinClientsReferralProgramForm = ({
  onSubmitSuccess,
}: JoinClientsReferralProgramFormProps) => {
  const isMobile = useMediaQuery("sm");
  const user = useUser();
  const dispatch = useAppDispatch();
  const externalProfiles = useAppSelector(
    (state) => state.freelancer.freelancerProfile?.external_profiles,
  );
  const linkedInProfile = useMemo(
    () =>
      externalProfiles?.find(
        ({ site }) => site.id === SETTINGS.QUESTIONNAIRE_LINKEDIN_SITE_ID,
      ),
    [externalProfiles],
  );
  const isFetchingCurrentUser = useAppSelector(
    (state) => state.auth.fetchingCurrentUser,
  );

  const [sendSelfSignupData] = useSendSelfSignupDataMutation();
  const [
    optInIntoReferClientProgram,
    { isLoading: loadingOptInIntoReferProgram },
  ] = useOptInIntoReferClientProgramMutation();

  const handleOnSubmitSuccess = useCallback(async () => {
    sendSelfSignupData({ page_name: "Earn" });

    openDecoratedSuccessSnackbar(
      "Your LinkedIn URL has been successfully shared.",
    );

    onSubmitSuccess();
  }, [onSubmitSuccess, sendSelfSignupData]);

  const isLoading = useMemo(() => {
    return loadingOptInIntoReferProgram || isFetchingCurrentUser || !user;
  }, [loadingOptInIntoReferProgram, isFetchingCurrentUser, user]);

  const onSubmit = (values: ClientReferralProgramFormValues) => {
    return optInIntoReferClientProgram(values)
      .unwrap()
      .then(() => {
        return dispatch(fetchCurrentUser());
      })
      .catch((error) => {
        throw new SubmissionError(error?.data);
      });
  };

  return (
    <JoinClientReferralProgramForm
      onSubmit={onSubmit}
      onSubmitSuccess={handleOnSubmitSuccess}
      initialValues={{
        refer_client_program_linkedin_url: linkedInProfile?.public_url,
      }}
    >
      <Stack
        direction={isMobile ? "column" : "row"}
        sx={{ alignItems: "flex-start", flexWrap: "nowrap", gap: 1.5 }}
      >
        <Field
          label="Your LinkedIn URL"
          validate={[required]}
          id="refer_client_program_linkedin_url"
          name="refer_client_program_linkedin_url"
          component={TextField}
          sx={{ maxWidth: "23rem" }}
        />
        <Box pt={isMobile ? "0" : "36px"}>
          <Button
            variant="primary"
            type="submit"
            sx={{ textWrap: "nowrap" }}
            disabled={isLoading}
          >
            Share
          </Button>
        </Box>
      </Stack>
    </JoinClientReferralProgramForm>
  );
};

export type ClientReferralProgramFormValues = {
  refer_client_program_linkedin_url: string;
};

const JoinClientReferralProgramForm = createFormInstance<
  ClientReferralProgramFormValues,
  unknown
>("joinClientReferralProgramForm", {
  touchOnBlur: false,
  enableReinitialize: true,
});
