import { useEffect, useMemo } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import { useShouldDisplayL1RoleTab } from "@js/apps/common/hooks";
import { useFreelancerRole } from "@js/apps/freelancer/hooks";
import { JobLocationContext } from "@js/apps/jobs/context";

import { JobsListingPage } from "../listing";

export const RoleJobsPage = () => {
  const navigate = useNavigate();
  const { role } = useFreelancerRole();
  const [searchParams] = useSearchParams();
  const searchRole = searchParams.get("role");
  const shouldDisplayL1Tab = useShouldDisplayL1RoleTab();

  useEffect(() => {
    if (!role) {
      navigate("/jobs");
    } else {
      if (searchRole && parseInt(searchRole) !== role.id) {
        const path = shouldDisplayL1Tab ? "/role-jobs" : "/jobs";
        navigate(path);
      }
    }
  }, [role, navigate, searchRole, shouldDisplayL1Tab]);

  const defaultFilters = useMemo(() => {
    if (!role?.id) {
      return { role: [] };
    }

    return { role: [role.id] };
  }, [role]);

  if (!role) {
    return null;
  }

  return (
    <JobsListingPage
      defaultFilters={defaultFilters}
      bgcolor={`soft-${role.color}`}
      location={JobLocationContext.Values.jobs_landing_page}
    />
  );
};
