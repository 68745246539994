import { useMediaQuery } from "@hexocean/braintrust-ui-components/hooks";
import type { EmployerInvoice } from "@js/types/invoices";

import {
  SingleInvoiceActionsContent,
  SingleInvoiceActionsMobileContent,
} from "./components";
import { useSingleInvoiceActions } from "./use-single-invoice-actions";

type SingleInvoiceActionsProps = {
  invoice: EmployerInvoice;
  startPaymentFlow: () => void;
  handleSingleInvoicePayment: (
    invoice: EmployerInvoice,
    startPaymentFlow: () => void,
  ) => void;
  markInvoiceAsPaid: (ids: number[]) => void;
};

export const SingleInvoiceActions = ({
  invoice,
  startPaymentFlow,
  handleSingleInvoicePayment,
  markInvoiceAsPaid,
}: SingleInvoiceActionsProps) => {
  const isMobile = useMediaQuery("md");
  const {
    isPayButtonVisible,
    isPaymentTemporaryBlocked,
    handleInvoicePayment,
    handleDownloadInvoiceAsPDF,
  } = useSingleInvoiceActions({
    invoice,
    startPaymentFlow,
    handleSingleInvoicePayment,
  });

  if (isMobile) {
    return (
      <SingleInvoiceActionsMobileContent
        isPayButtonVisible={isPayButtonVisible}
        isPaymentTemporaryBlocked={isPaymentTemporaryBlocked}
        handleInvoicePayment={handleInvoicePayment}
        markInvoiceAsPaid={markInvoiceAsPaid}
        handleDownloadInvoiceAsPDF={handleDownloadInvoiceAsPDF}
        invoice={invoice}
      />
    );
  }

  return (
    <SingleInvoiceActionsContent
      isPayButtonVisible={isPayButtonVisible}
      isPaymentTemporaryBlocked={isPaymentTemporaryBlocked}
      handleInvoicePayment={handleInvoicePayment}
      markInvoiceAsPaid={markInvoiceAsPaid}
      handleDownloadInvoiceAsPDF={handleDownloadInvoiceAsPDF}
      invoice={invoice}
    />
  );
};
