import type { Middleware } from "@reduxjs/toolkit";

import { GenericMessengerModalContent } from "@js/apps/messenger/messengers/modal";
import { GenericMessengerModalInstance } from "@js/apps/messenger/messengers/modal/manage-messenger-modal";

import * as actionTypes from "./action-types";

export const toggleMessengerModalMiddleware: Middleware =
  // Middleware change type to unknown
  () => (next) => (action: any) => {
    switch (action?.type) {
      case actionTypes.OPEN_MESSENGER_MODAL: {
        GenericMessengerModalInstance.open({
          children: <GenericMessengerModalContent {...action.payload} />,
          closeButton: true,
          className: "generic-messenger-modal",
          padding: false,
          containerScrollableNoMobilePadding: true,
        });
        return next(action);
      }
      case actionTypes.CLOSE_MESSENGER_MODAL: {
        GenericMessengerModalInstance.close();
        return next(action);
      }
      default: {
        return next(action);
      }
    }
  };
