import React, { useRef } from "react";

import type { BoxProps } from "@hexocean/braintrust-ui-components";
import { Box, IconButton, Menu } from "@hexocean/braintrust-ui-components";
import { MoreVertIcon } from "@hexocean/braintrust-ui-components/Icons";
import { useCancelFreelancerBidMutation } from "@js/apps/freelancer/api";
import { proposalCancel } from "@js/apps/jobs/actions";
import { CancelBidConfirmationModal } from "@js/apps/jobs/apps/bids";
import { RouterLink } from "@js/components/link";
import { Snackbar } from "@js/components/snackbar";
import { useAppDispatch } from "@js/hooks";
import type { FreelancerBid } from "@js/types/jobs";

type ActionMenuProps = BoxProps & {
  bid: FreelancerBid;
};

export const ActionMenu = ({
  bid,
  ...props
}: ActionMenuProps): JSX.Element | null => {
  const dispatch = useAppDispatch();
  const CancelBidConfirmationModalRef = useRef<any>();
  const [cancelFreelancerBid] = useCancelFreelancerBidMutation();

  const onCancelBid = () =>
    cancelFreelancerBid({ id: bid.id })
      .unwrap()
      .then(() => {
        dispatch(proposalCancel({ id: bid.id }));
        Snackbar.success(`Application has been canceled.`);
      })
      .catch(
        (error) => error?.response?.data && Snackbar.error(error.response.data),
      );

  if (!bid.can_edit && !bid.can_cancel) return null;
  return (
    <Box {...props}>
      <Menu
        anchor={
          <IconButton variant="transparent" aria-label="Open menu">
            <MoreVertIcon />
          </IconButton>
        }
      >
        {bid.can_edit && (
          <Menu.Item
            to={`/jobs/${bid.job.id}/proposals/${bid.id}/edit/`}
            component={RouterLink}
          >
            Edit
          </Menu.Item>
        )}
        {bid.can_cancel && (
          <Menu.Item
            onClick={() => {
              CancelBidConfirmationModalRef.current.open();
            }}
          >
            Cancel
          </Menu.Item>
        )}
      </Menu>
      <CancelBidConfirmationModal
        ref={CancelBidConfirmationModalRef}
        onConfirm={onCancelBid}
        bid={bid}
      />
    </Box>
  );
};
