import { Field } from "redux-form";

import { Box, Typography } from "@hexocean/braintrust-ui-components";
import { CanManageOnBehalfOfClientModule } from "@js/apps/jobs/apps/create-job/components/can-manage-job-on-behalf-of-client-module";
import { JobDescriptionUpdateStatusTag } from "@js/apps/jobs/apps/create-job/components/job-description-update-status-tag";
import {
  JobRoleField,
  JobTypeField,
} from "@js/apps/jobs/apps/create-job/fields";
import { OpeningsNumberField } from "@js/apps/jobs/apps/create-job/fields/openings-number-field";
import {
  jobTitleLengthValidator,
  jobTitleValidator,
  maxOpeningsNumberValidator,
} from "@js/apps/jobs/apps/create-job/validators";
import { StandardTextField } from "@js/forms/fields";
import { required } from "@js/forms/validators";

type CreateOrEditJobSetUpFieldsProps = { isEditForm?: boolean };

export const CreateOrEditJobSetUpFields = ({
  isEditForm,
}: CreateOrEditJobSetUpFieldsProps) => {
  return (
    <Box>
      <CanManageOnBehalfOfClientModule badgeOnly={false} />

      <JobDescriptionUpdateStatusTag />

      <Typography component="h1" variant="title" size="large" fontWeight={400}>
        Let's set up your new job
      </Typography>

      <Box mb={5} mt={7}>
        <Field
          name="title"
          id="title"
          validate={[jobTitleValidator, jobTitleLengthValidator]}
          component={StandardTextField}
          size="large"
          label="Job title"
          labelSize="medium"
          InputLabelProps={{
            sx: {
              transform: "translate(0, -1.5px) scale(1.0)",
            },
          }}
          placeholder="Enter job title here"
          floatingLabel={false}
        />
      </Box>

      <Box mb={6}>
        <Field name="job_type" validate={[required]} component={JobTypeField} />
      </Box>

      <Field
        name="openings_number"
        component={OpeningsNumberField}
        validate={[required, maxOpeningsNumberValidator]}
        labelProps={{ mb: 2 }}
        isEditForm={isEditForm}
      />

      <JobRoleField />
    </Box>
  );
};
