import { getUTMQueryParamsSearch } from "@js/utils";

export const getNavLinkToUrl = ({
  url,
  persistUTMSearchParams,
  search,
}: {
  url: string;
  persistUTMSearchParams?: boolean;
  search: string;
}) => {
  if (!persistUTMSearchParams) {
    return url;
  }

  const onlyUTMsearch = getUTMQueryParamsSearch(search);

  const [urlWithoutSearch, urlSearch] = url.split("?");
  if (!urlSearch) {
    return `${urlWithoutSearch}?${onlyUTMsearch}`;
  }

  const mergedSearch = mergeSearch(urlSearch, onlyUTMsearch);

  return `${urlWithoutSearch}?${mergedSearch}`;
};

const mergeSearch = (search: string, searchToAppend: string) => {
  if (!searchToAppend) {
    return search;
  }

  const searchUrlParams = new URLSearchParams(search);
  const searchToAppendUrlParams = new URLSearchParams(searchToAppend);

  const combinedSearchParams = new URLSearchParams({
    ...Object.fromEntries(searchUrlParams),
    ...Object.fromEntries(searchToAppendUrlParams),
  });

  return combinedSearchParams.toString();
};

export const getSignUpLinkUrl = (search: string) => {
  return `/auth/sign_up${search}`;
};

export const getHireTalentLinkUrl = (search: string) => {
  return `/auth/sign_up/create_account/?${mergeSearch(
    "new_employer_self_serve",
    search,
  )}`;
};
