import React from "react";
import { Field } from "redux-form";

import { CheckIcon, CloseIcon } from "@hexocean/braintrust-ui-components/Icons";
import { SwitchField, ToggleButtonGroupField } from "@js/forms/fields";
import { enumArrayToOptions } from "@js/utils";

import { SectionSubtitle } from "../helpers";

export const NotificationField = ({
  name,
  label,
  notification,
  options = [],
  ...props
}) => {
  return !options.length ? (
    <Field
      {...props}
      name={name}
      id={notification}
      label={label}
      icon={<CloseIcon />}
      checkedIcon={<CheckIcon />}
      component={SwitchField}
      color="primary"
      LabelProps={{ labelPlacement: "start" }}
    />
  ) : (
    <SectionSubtitle
      size="medium"
      action={
        <Field
          {...props}
          name={name}
          id={notification}
          component={ToggleButtonGroupField}
          disableUnselecting
          options={enumArrayToOptions(options)}
        />
      }
      mt={1}
      mb={1}
    >
      {label}
    </SectionSubtitle>
  );
};
