export const TALENT_STATUS = {
  NO_ACTION: "NO_ACTION",
  PROPOSED: "PROPOSED",
  IN_SCREENING: "IN_SCREENING",
  DECLINED: "DECLINED",
  OFFER_DECLINED: "OFFER_DECLINED",
  OFFER_MADE: "OFFER_MADE",
  APPLICATION_IN_REVIEW: "APPLICATION_IN_REVIEW",
  HIRED: "HIRED",
} as const;

export const DEFAULT_LISTING_BG_COLOR = "var(--off-white)";
export const ALTERNATIVE_LISTING_BG_COLOR = "var(--white)";
export const FALLBACK_BG_COLOR = "var(--medium-violet)";

export const BID_SUBMIT_TYPE = {
  WITH_FEEDBACK: "WITH_FEEDBACK",
  WITHOUT_FEEDBACK: "WITHOUT_FEEDBACK",
  WITH_FEEDBACK_BULK: "WITH_FEEDBACK_BULK",
} as const;
