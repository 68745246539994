import React, { Children, cloneElement, Component, useEffect } from "react";
import { isElement } from "react-dom/test-utils";
import type { ConnectedProps } from "react-redux";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import classNames from "classnames";
import _ from "underscore";

import type { IconButtonProps } from "@hexocean/braintrust-ui-components";
import { styled } from "@hexocean/braintrust-ui-components";
import {
  IconButton,
  Modal as BUCModal,
  Paper,
} from "@hexocean/braintrust-ui-components";
import { CloseIcon } from "@hexocean/braintrust-ui-components/Icons";
import type { RootState } from "@js/store";
import { mapTypedDispatchToProps } from "@js/utils/store";

import { closeModalAction, openModalAction } from "./actions";
import { CancelledByUser } from "./cancel-by-user";

/**
 * Example usage:
 *
 * ```
 * const TestModal = Modal('test');
 *
 * <TestModal>
 *     Hello, I'm a test modal!
 * </TestModal>
 * <a onClick={() => TestModal.open()}>
 *     Open Test Modal
 * </a>
 * ```
 */

const StyledPaper = styled(Paper)(() => ({
  borderRadius: 12,
  overflow: "hidden",
  boxShadow: `0px 1px 2px rgba(0, 0, 0, 0.04),
                 0px 20px 100px rgba(0, 0, 0, 0.12),
                 0px 8px 16px rgba(0, 0, 0, 0.04)`,
}));

export type ModalInstanceProps = {
  isModalOpen?: boolean;
  children?: React.ReactNode;
  closeButton?: boolean;
  closeButtonProps?: Partial<IconButtonProps>;
  padding?: boolean;
  containerScrollableNoMobilePadding?: boolean;
  keepOnBackdropClick?: boolean;
  disableEnforceFocus?: boolean;
  backdropInvisible?: boolean;
  disablePortal?: boolean;
  disableEscapeKeyDown?: boolean;
  className?: string;
  onClose?: (ev?) => void;
  onOpen?: (ev?) => void;
  onKeyDown?: (ev?) => void;
};

const initialInstanceProps: Partial<ModalInstanceProps> = {
  closeButton: true,
  padding: true,
  containerScrollableNoMobilePadding: false,
  keepOnBackdropClick: false,
  disableEnforceFocus: false,
  backdropInvisible: false,
  disablePortal: false,
  disableEscapeKeyDown: false,
} as const;

type ManagedModalProps = ConnectedProps<ReturnType<typeof connector>> &
  ModalInstanceProps;
export const Modal = (
  id: string | number,
  customProps?: ModalInstanceProps,
) => {
  let instance: null | ManagedModal = null;
  let instanceProps = {
    ...initialInstanceProps,
    ...customProps,
  };

  class ManagedModal extends Component<ManagedModalProps> {
    id;

    mouseDownTarget;

    constructor(props) {
      super(props);
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      instance = this;
      this.id = id;

      this.mouseDownTarget = React.createRef();
    }

    componentDidUpdate(prevProps) {
      if (prevProps.isModalOpen && !this.props.isModalOpen) {
        if (instanceProps && instanceProps.onClose) {
          instanceProps.onClose();
          return;
        }

        if (this.props?.onClose) {
          this.props.onClose();
          return;
        }
      }

      if (!prevProps.isModalOpen && this.props.isModalOpen) {
        if (instanceProps && instanceProps.onOpen) {
          instanceProps.onOpen();
          return;
        }

        if (this.props.onOpen) {
          this.props.onOpen();
          return;
        }
      }
    }

    static close() {
      if (instance) {
        instance.props.dispatch(closeModalAction(instance.id));
      }
    }

    static open(event: React.MouseEvent): void;
    static open(props?: Omit<ModalInstanceProps, "isModalOpen">): void;

    static open(
      props: Omit<ModalInstanceProps, "isModalOpen"> | React.MouseEvent = {},
    ) {
      instanceProps = { ...initialInstanceProps, ...customProps, ...props };

      if (instance) {
        instance.props.dispatch(openModalAction(instance.id));
      }
    }

    get closeButton() {
      // let <ModalInstance closeButton={false} /> override instance props
      if (
        (this.props.closeButton === undefined && instanceProps.closeButton) ||
        this.props.closeButton
      ) {
        return (
          <IconButton
            size="small"
            variant="transparent"
            {...instanceProps.closeButtonProps}
            className={classNames(
              "modal-close-button",
              instanceProps.closeButtonProps?.className,
            )}
            aria-label="Close modal"
            onClick={ManagedModal.close}
          >
            <CloseIcon
              sx={{
                borderRadius: "50%",
                padding: "0.5rem",
              }}
            />
          </IconButton>
        );
      }

      return null;
    }

    handleMouseDown = (event) => {
      this.mouseDownTarget.current = event.target;
    };

    handleBackdropClick = (event) => {
      if (
        event.target !== event.currentTarget ||
        event.target !== this.mouseDownTarget.current ||
        event.target.clientWidth < event.pageX
      ) {
        return;
      }

      this.mouseDownTarget.current = null;

      if (!instanceProps.keepOnBackdropClick) {
        ManagedModal.close();
      }
    };

    render() {
      const props = _.omit(
        instanceProps,
        "closeButton",
        "padding",
        "containerScrollableNoMobilePadding",
        "keepOnBackdropClick",
        "closeButtonProps",
        "disablePortal",
        "backdropInvisible",
        "onOpen",
        "onClose",
        "disableEnforceFocus",
        "disableEscapeKeyDown",
      );
      const contentClassName = classNames(
        "modal-content",
        instanceProps.className || undefined,
        { "with-padding": instanceProps.padding },
      );

      const children = instanceProps.children || this.props.children;
      const content = isElement(children) ? children : <>{children}</>;

      return (
        <BUCModal
          className="modal-content-container"
          open={this.props.isModalOpen ?? false}
          onClose={(_event, reason) => {
            if (
              instanceProps.keepOnBackdropClick &&
              reason === "backdropClick"
            ) {
              return;
            }

            if (
              reason === "escapeKeyDown" &&
              instanceProps.disableEscapeKeyDown
            ) {
              return;
            }

            ManagedModal.close();
          }}
          disableEnforceFocus={instanceProps.disableEnforceFocus}
          disablePortal={instanceProps.disablePortal}
          slotProps={{
            backdrop: instanceProps.backdropInvisible
              ? {
                  invisible: true,
                }
              : {
                  className: "modal-backdrop--black",
                },
          }}
        >
          {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
          <div
            data-testid="modal-container"
            className={classNames("modal-content-container-scrollable", {
              "modal-content-container-scrollable--no-padding":
                instanceProps.containerScrollableNoMobilePadding,
            })}
            onKeyDown={
              instanceProps.onKeyDown ? instanceProps.onKeyDown : undefined
            }
            onMouseDown={this.handleMouseDown}
            onMouseUp={this.handleBackdropClick}
            role="dialog"
            aria-modal={true}
          >
            <StyledPaper className={contentClassName}>
              {this.closeButton}
              {Children.map(content, (child) => {
                if (!React.isValidElement(child)) return;

                return cloneElement(child, { ...props, ...child.props });
              })}
            </StyledPaper>
          </div>
        </BUCModal>
      );
    }
  }

  return connector(id)(ManagedModal);
};

const mapStateToProps = (state: RootState, id: string | number) => ({
  isModalOpen: state.modal[id] || false,
});

const connector = (id: string | number) =>
  connect(
    (state: RootState) => mapStateToProps(state, id),
    mapTypedDispatchToProps,
  );

const openAsyncModal = (config, modalInstance = ModalInstance) =>
  new Promise<void>((resolve, reject) => {
    modalInstance.open({
      ...config,
      onConfirm: () => {
        resolve();
      },
      onClose: () => {
        reject(new CancelledByUser());
      },
      onCancel: () => {
        reject(new CancelledByUser());
      },
    });
  });

export const openModalAndWaitForInput = async (
  config,
  modalInstance = ModalInstance,
) => {
  try {
    await openAsyncModal(config, modalInstance);
  } catch (error) {
    // rethrow
    const _err = error;
    throw _err;
  } finally {
    modalInstance.close();
  }
};

type CloseModalOnLocationChangeProps = {
  children: JSX.Element | JSX.Element[];
  closeModal: () => void;
};

export const CloseModalOnLocationChange = ({
  children,
  closeModal,
}: CloseModalOnLocationChangeProps) => {
  const location = useLocation();

  useEffect(() => {
    if (closeModal) {
      return () => {
        closeModal();
      };
    }
  }, [location, closeModal]);

  return <>{children}</>;
};

export const ModalInstance = Modal("modal-instance-1", {
  closeButton: false,
});
export const CommonConfirmationModal = Modal("common-confirmation-modal", {
  closeButton: false,
});
export * from "./confirm";
export * from "./prompt";
