export const INVOICE_LISTING_FILTERS_OPTIONS = [
  {
    name: "unpaid",
    query: {
      unpaid: true,
    },
  },
  {
    name: "paid",
    query: {
      paid: true,
    },
  },
  {
    name: "all invoices",
    query: {},
  },
  {
    name: "invoice batches",
    query: {
      invoice_batches: true,
    },
  },
] as const;
