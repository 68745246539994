import React from "react";
import { Field } from "redux-form";

import { Typography } from "@hexocean/braintrust-ui-components";
import { recreateOTPAuthBackupCodes } from "@js/apps/auth/actions";
import { OTPAuthCodeField } from "@js/apps/common/forms/fields/otp/otp-auth-code";
import { Modal, ModalConfirm } from "@js/components/modal";
import { createFormInstance } from "@js/forms/components";
import { CheckboxField } from "@js/forms/fields/checkbox";

import { openAuthBackupCodesModal } from "../backup-codes-modal";

const RECREATE_OTP_AUTH_BACKUP_CODES_MODAL_ID =
  "recreate-otp-auth-backup-codes-modal";

const ModalInstance = Modal(RECREATE_OTP_AUTH_BACKUP_CODES_MODAL_ID, {
  className: "recreate-otp-auth-backup-codes-modal",
  closeButton: false,
  keepOnBackdropClick: true,
});

const FormInstance = createFormInstance("recreate-otp-auth-form");
const initialValues: { is_backup_code: boolean } = { is_backup_code: false };

const closeRecreateOTPAuthBackupCodesModal = () => ModalInstance.close();
export const openRecreateOTPAuthBackupCodesModal = () => ModalInstance.open();

export const RecreateOTPAuthBackupCodesForm = () => {
  const onCancel = closeRecreateOTPAuthBackupCodesModal;

  const onSubmit = (values) => {
    return recreateOTPAuthBackupCodes(values)
      .then((data) => openAuthBackupCodesModal(data.backup_codes))
      .then(onCancel);
  };

  return (
    <FormInstance onSubmit={onSubmit} initialValues={initialValues}>
      <Typography
        component="h3"
        variant="title"
        fontWeight={400}
        size="small"
        className="mt0 mb+"
      >
        Recreate 2-Step Authentication Backup Codes
      </Typography>
      <ModalConfirm
        onCancel={onCancel}
        confirmText="Continue"
        cancelText="Back"
        onConfirm="submit"
        buttonsSquareShape
        buttonsOnEnds
        confirmButtonVariant="positive-2"
      >
        Enter the 6-digit code generated by your Authenticator app.
        <OTPAuthCodeField />
        <Field
          name="is_backup_code"
          component={CheckboxField}
          label="Use backup code instead"
        />
      </ModalConfirm>
    </FormInstance>
  );
};

const RecreateOTPAuthBackupCodesModal = () => {
  return (
    <ModalInstance>
      <RecreateOTPAuthBackupCodesForm />
    </ModalInstance>
  );
};

export default RecreateOTPAuthBackupCodesModal;
