import { useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import cs from "classnames";

import {
  Box,
  Button,
  Paper,
  Stack,
  Typography,
} from "@hexocean/braintrust-ui-components";
import { KeyboardArrowDownIcon } from "@hexocean/braintrust-ui-components/Icons";
import { useIntersectionObserver } from "@js/hooks/use-intersection-observer";

import { RouterLink } from "../link";
import { Logo } from "../logo";

import type { NavLink } from "./constants";
import { NAV_LINKS } from "./constants";
import {
  getHireTalentLinkUrl,
  getNavLinkToUrl,
  getSignUpLinkUrl,
} from "./helpers";
import { PublicTopHeader } from "./public-top-header";

import styles from "./public-top-navigation-desktop.module.scss";

export const PublicTopNavigationDesktop = () => {
  const anchorRef = useRef(null);
  const { search } = useLocation();

  const isNearTopEdge = useIntersectionObserver(
    anchorRef,
    { threshold: 0.75 },
    true,
  );

  return (
    <>
      <Box ref={anchorRef} className={styles.pageOffset}></Box>
      <PublicTopHeader
        className={cs({ [styles.headerShort]: !isNearTopEdge })}
        offsetByScrollbar
        sx={{ px: "15px" }}
      >
        <Stack direction="row" className={cs(styles.navContainer)}>
          <Stack className={styles.logoContainer}>
            <Logo />
          </Stack>
          <Stack direction="row" sx={{ height: "100%", alignItems: "center" }}>
            <Box component="nav" aria-label="Main menu" sx={{ height: "100%" }}>
              <Stack
                component="ul"
                direction="row"
                sx={{ alignItems: "center" }}
                className={styles.navLinks}
              >
                {NAV_LINKS.map(({ subLinks, ...linkData }) => {
                  if (!subLinks?.length) {
                    return <BasicNavLink key={linkData.label} {...linkData} />;
                  }

                  return (
                    <NavLinkWithDropdown
                      key={linkData.label}
                      subLinks={subLinks}
                      {...linkData}
                    />
                  );
                })}
              </Stack>
            </Box>

            <Stack direction="row" sx={{ gap: "20px", pl: 1 }}>
              <Button
                variant="secondary"
                size="x-small"
                className={cs(styles.button, styles.buttonSignUp)}
                to={getSignUpLinkUrl(search)}
                RouterLink={RouterLink}
              >
                Sign Up
              </Button>
              <Button
                variant="primary"
                size="x-small"
                className={cs(styles.button, styles.buttonHire)}
                to={getHireTalentLinkUrl(search)}
                RouterLink={RouterLink}
              >
                Hire Talent
              </Button>
            </Stack>
          </Stack>
        </Stack>
      </PublicTopHeader>
    </>
  );
};

type NavLinkWithUnderlineProps = Pick<NavLink, "url" | "label">;

const NavLinkWithUnderline = ({ label, url }: NavLinkWithUnderlineProps) => {
  return (
    <Typography
      component="link"
      to={url}
      RouterLink={RouterLink}
      className={styles.navLink}
      fontWeight={500}
    >
      {label}
    </Typography>
  );
};

type BasicNavLinkProps = Pick<
  NavLink,
  "url" | "label" | "persistUTMSearchParams"
>;

const BasicNavLink = ({
  url,
  label,
  persistUTMSearchParams,
}: BasicNavLinkProps) => {
  const { search } = useLocation();
  const toUrl = getNavLinkToUrl({ url, search, persistUTMSearchParams });

  return (
    <Stack className={styles.navLinkContainer}>
      <NavLinkWithUnderline url={toUrl} label={label} />
    </Stack>
  );
};

type NavLinkWithDropdownProps = BasicNavLinkProps &
  Pick<NavLinkDropdownProps, "subLinks">;

const NavLinkWithDropdown = ({
  url,
  label,
  subLinks,
}: NavLinkWithDropdownProps) => {
  const [isHover, setIsHover] = useState(false);
  const handleMouseEnter = () => {
    setIsHover(true);
  };

  const handleMouseLeave = () => {
    setIsHover(false);
  };

  const isOpen = isHover;
  const menuId = `${label}-menu`;

  return (
    <>
      <Stack
        className={styles.navLinkContainer}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        aria-haspopup="true"
        aria-expanded={isOpen ? "true" : undefined}
        aria-controls={menuId}
      >
        <NavLinkWithUnderline url={url} label={label} />
        <KeyboardArrowDownIcon
          className={cs(styles.arrowIcon, { [styles.arrowIconUp]: isOpen })}
        />
        <NavLinkDropdown subLinks={subLinks} id={menuId} isOpen={isOpen} />
      </Stack>
    </>
  );
};

type NavLinkDropdownProps = {
  subLinks: NonNullable<NavLink["subLinks"]>;
  id: string;
  isOpen: boolean;
};
const NavLinkDropdown = ({ isOpen, subLinks, id }: NavLinkDropdownProps) => {
  const { pathname, search } = useLocation();

  return (
    <Paper
      className={cs(styles.menu, { [styles.menuOpen]: isOpen })}
      elevation={4}
      id={id}
    >
      <Stack direction="row" component="ul" className={styles.menuList}>
        {subLinks.map(({ label, url, persistUTMSearchParams }) => {
          const isActive = pathname.startsWith(url);

          return (
            <Box key={label} component="li" className={styles.menuItem}>
              <Typography
                component="link"
                to={getNavLinkToUrl({ url, search, persistUTMSearchParams })}
                RouterLink={RouterLink}
                className={cs(styles.navLink, styles.navSubLink, {
                  [styles.navLinkActive]: isActive,
                })}
                fontWeight={400}
              >
                {label}
              </Typography>
            </Box>
          );
        })}
      </Stack>
    </Paper>
  );
};
