import { Box, Button, Typography } from "@hexocean/braintrust-ui-components";
import { BID_SUBMIT_TYPE } from "@js/apps/jobs/constants";
import { getRejectOfferMessage } from "@js/services";

import { BidSendMessage } from "../../forms/bid-send-message";
import { useRejectOfferMessageSubmitType } from "../../hooks/use-reject-offer-message-submit-type";
import type { RejectOfferMessageWrapperProps } from "../../models";

export const RejectOfferMessageWrapper = ({
  bid,
  nextUrl,
  refetchBidList,
  bidList,
  job,
}: RejectOfferMessageWrapperProps) => {
  return (
    <RejectOfferMessage
      refetchBidList={refetchBidList}
      nextUrl={nextUrl}
      bid={bid}
      bidList={bidList}
      job={job}
    />
  );
};

const RejectOfferMessage = ({
  nextUrl,
  bid,
  refetchBidList,
  bidList,
  job,
}: RejectOfferMessageWrapperProps) => {
  const name = bid?.freelancer.user.first_name || "";
  const greeting = name ? `Hi ${name}` : "Hi";
  const initialMessage =
    `${greeting}, \n\nI wanted to let you know we decided to not move forward with ` +
    `your application for ${
      job?.title || "this role"
    }. Please don't hesitate to apply to future roles! \n\nThanks`;

  const message = getRejectOfferMessage(bid?.id) || initialMessage;

  const headlineName = bidList ? `${bidList.length} applicants` : name;

  const { updateSubmitType, onSubmit } = useRejectOfferMessageSubmitType({
    bid,
    nextUrl,
    refetchBidList,
  });

  return (
    <Box
      boxSizing="border-box"
      bgcolor="var(--soft-orange)"
      p={3}
      maxWidth={576}
    >
      <Typography pr={3} component="h2" variant="label">
        Let {headlineName} know you won’t be hiring them
      </Typography>
      <Typography component="p" size="small" mb={2}>
        Talent prefer to get a rejection vs. being left in the dark. This is
        especially true if you’ve moved someone to the interview stage. It helps
        them do better next time ❤️
      </Typography>
      {bid && (
        <BidSendMessage
          form="send-interview-request"
          onSubmit={onSubmit}
          initialValues={{
            message,
          }}
          ButtonProps={{
            className: "typography--small",
            children: "Add feedback",
            variant: "medium-orange",
            onClick: () => updateSubmitType(BID_SUBMIT_TYPE.WITH_FEEDBACK),
            type: "submit",
          }}
          additionalButtons={[
            <Button
              key="send-message-without-feedback-button"
              variant="transparent"
              className="typography--small"
              onClick={() => updateSubmitType(BID_SUBMIT_TYPE.WITHOUT_FEEDBACK)}
              type="submit"
            >
              Send without feedback
            </Button>,
          ]}
          minRows={4}
        />
      )}

      {bidList?.length && (
        <BidSendMessage
          form="send-interview-request"
          onSubmit={onSubmit}
          initialValues={{
            message,
          }}
          ButtonProps={{
            className: "typography--small",
            children: `Send ${bidList.length} rejections`,
            variant: "medium-orange",
            onClick: () => updateSubmitType(BID_SUBMIT_TYPE.WITH_FEEDBACK_BULK),
            type: "submit",
          }}
          minRows={4}
          bulkRejection
        />
      )}
    </Box>
  );
};
