import { Button, Typography } from "@hexocean/braintrust-ui-components";
import { ArrowRightTopIcon } from "@hexocean/braintrust-ui-components/Icons";
import { JobActionMenu } from "@js/apps/jobs/components/job-action-menu";
import { RouterLink } from "@js/components/link";
import type { EmployerOwnJob } from "@js/types/jobs";
import { dateFromNow, pluralize } from "@js/utils";

import style from "./style.module.scss";

export const OpenJobItem = ({ job }: { job: EmployerOwnJob }) => {
  return (
    <div className={style.openJobItem}>
      <div className={style.openJobItemTitle}>
        <Typography component="h3" size="large" ellipsis>
          {job.title}
        </Typography>
        <Typography component="p" size="small" color="grey-1">
          {job.total_bids_count} Application
          {pluralize(job.total_bids_count, { zeroPlural: true })} |{" "}
          {dateFromNow(job.created)}
        </Typography>
      </div>
      <div>
        <Button
          to={`/jobs/${job.id}/proposals/`}
          shape="squared"
          variant="transparent-border-grey-4"
          RouterLink={RouterLink}
        >
          Manage job <ArrowRightTopIcon />
        </Button>
        <JobActionMenu job={job} />
      </div>
    </div>
  );
};
