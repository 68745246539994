import { useEffect, useMemo, useState } from "react";

import {
  Button,
  Loader,
  Pagination,
  PaginationWrapper,
} from "@hexocean/braintrust-ui-components";
import { SaveOutlinedIcon } from "@hexocean/braintrust-ui-components/Icons";
import { WithJobActionsContext } from "@js/apps/jobs/components/job-actions/with-job-actions";
import { JobsListing } from "@js/apps/jobs/components/listing";
import { JobLocationContext } from "@js/apps/jobs/context";
import { RouterLink } from "@js/components/link";
import { NavigateWithSnackbarToastReceiver } from "@js/components/snackbar";
import { useQueryParams } from "@js/hooks";
import { AppLayout } from "@js/layouts/app";

import { Header, SavedFiltersDrawer } from "../../components";
import { JobsListFilters } from "../../components/jobs-list-filters";
import type { UseJobListingArg } from "../../hooks";
import { useJobListing } from "../../hooks";

type JobsListingPageProps = {
  bgcolor?: string;
} & Partial<UseJobListingArg>;

export const JobsListingPage = ({
  defaultFilters,
  location = JobLocationContext.Values.job_listing,
  bgcolor = "soft-blue",
}: JobsListingPageProps) => {
  const {
    jobs,
    loading,
    total,
    filters,
    filtersFromParams,
    isAnyFilterApplied,
    loadNewFilters,
    deleteSavedFilter,
    refetchJobs,
  } = useJobListing({ defaultFilters, location });

  const query = useQueryParams();
  const showSavedSearchesDrawerParam =
    query["show_saved_searches_drawer"] === "true";
  const [savedFiltersDrawerOpen, setSavedFiltersDrawerOpen] = useState(false);

  useEffect(() => {
    if (showSavedSearchesDrawerParam) setSavedFiltersDrawerOpenHandler();
  }, [showSavedSearchesDrawerParam]);

  const setSavedFiltersDrawerOpenHandler = () => {
    setSavedFiltersDrawerOpen(true);
  };

  const withJobActionContextProviderValue = useMemo(
    () => ({
      afterAction: refetchJobs,
    }),
    [refetchJobs],
  );

  return (
    <AppLayout pageTitle="Jobs" className="wider" bgcolor={`var(--${bgcolor})`}>
      <SavedFiltersDrawer
        location={location}
        onChange={loadNewFilters}
        open={savedFiltersDrawerOpen}
        setOpen={setSavedFiltersDrawerOpen}
        onRemove={deleteSavedFilter}
      />
      <div className="listing-page">
        <JobLocationContext.Provider value={location}>
          <Header
            buttons={[
              <Button
                key="Saved searches"
                variant="primary"
                startIcon={<SaveOutlinedIcon style={{ fontSize: "17px" }} />}
                onClick={setSavedFiltersDrawerOpenHandler}
              >
                Saved searches
              </Button>,
            ]}
            filtersSocket={
              <JobsListFilters
                count={total}
                defaultRole={defaultFilters?.role}
                filters={filters}
                filtersFromParams={filtersFromParams}
                isAnyFilterApplied={isAnyFilterApplied}
                location={location}
                refetchJobs={refetchJobs}
              />
            }
          />
          <WithJobActionsContext.Provider
            value={withJobActionContextProviderValue}
          >
            <div className="listing-page-content">
              {loading ? (
                <Loader centered />
              ) : (
                <>
                  <NavigateWithSnackbarToastReceiver />
                  <JobsListing jobs={jobs} searchResults={isAnyFilterApplied} />
                  <PaginationWrapper mt={0}>
                    <Pagination
                      RouterLink={RouterLink}
                      count={total}
                      perPage={SETTINGS.JOBS_LISTING_JOBS_PER_PAGE}
                    />
                  </PaginationWrapper>
                </>
              )}
            </div>
          </WithJobActionsContext.Provider>
        </JobLocationContext.Provider>
      </div>
    </AppLayout>
  );
};
