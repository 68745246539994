import cs from "classnames";

import { Box, Typography } from "@hexocean/braintrust-ui-components";
import { WarningOrange } from "@hexocean/braintrust-ui-components/Icons";
import { RouterLink } from "@js/components/link";
import { pluralize } from "@js/utils";

import { BarCloseButton } from "./bar-close-button";
import { BarCtaButton } from "./bar-cts-button";

import style from "./style.module.scss";

type NotPublishedAutoFillBarProps = {
  jobsAdded: number;
  onClose: () => void;
  integrationName: string;
};

export const NotPublishedBar = ({
  jobsAdded,
  onClose,
  integrationName,
}: NotPublishedAutoFillBarProps) => {
  return (
    <Box className={cs(style.atsInfoBar, style.warning)}>
      <Box className={style.atsInfoBarHeader}>
        <WarningOrange
          className={cs(
            style.atsInfoBarHeaderIcon,
            style.atsInfoBarHeaderIconWarning,
          )}
          viewBox="0 0 48 48"
        />
        <Typography
          component="h2"
          fontWeight={500}
          className={style.atsInfoBarHeaderText}
        >
          We’ve added {jobsAdded} job{pluralize(jobsAdded)} from{" "}
          {integrationName}!
        </Typography>
        <BarCloseButton onClose={onClose} />
      </Box>
      <Typography
        component="p"
        size="large"
        className={style.atsInfoBarTextContent}
      >
        Your jobs have been moved to your{" "}
        <Typography
          component="link"
          variant="link"
          to="#job-drafts"
          size="large"
          RouterLink={RouterLink}
        >
          Job drafts
        </Typography>{" "}
        because required fields are missing. We've provided our top suggestions
        based on the provided information. Please review our recommendations or
        edit your job before publishing it on Braintrust.
      </Typography>
      <Box className={style.atsInfoBarCtaContainer}>
        <BarCtaButton />
      </Box>
    </Box>
  );
};
