import type { WrappedFieldProps } from "redux-form";
import _ from "underscore";

import {
  ComboBoxMultiple,
  PaperWithVioletOptions,
  TextField,
} from "@hexocean/braintrust-ui-components";

import { useGetSkillsQuery } from "../../api";
import { NotFoundText, SearchIcon } from "../components";
import { StyledSkillsAutocomplete } from "../styled";

export const TalentProfileSkillsCombobox = ({
  input,
  meta,
}: WrappedFieldProps) => {
  const { options, isLoading } = useGetSkillsQuery(
    { ordering: "name" },
    {
      selectFromResult: (result) => {
        const data = (result.data || []).map((option) => {
          // adjust to form format and the backend format
          return {
            id: option.id,
            skill: option,
            is_superpower: false,
          };
        });

        return {
          isLoading: result.isLoading,
          options: data,
        };
      },
    },
  );

  return (
    <ComboBoxMultiple<{ id: number; skill: { id: number; name: string } }, true>
      initialTaxonomiesLoading={isLoading}
      value={input.value}
      options={options}
      isOptionEqualToValue={(option, value) => {
        return option.skill.id === value.skill.id;
      }}
      component={StyledSkillsAutocomplete}
      PaperComponent={PaperWithVioletOptions}
      onChange={(_ev, values) => {
        const unique = getUniqueSkills(values);
        input.onChange(unique);
      }}
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            size="small"
            helperText={meta.error}
            error={meta.error?.length > 0}
            placeholder="Search skills"
            InputProps={{
              ...params.InputProps,
              startAdornment: <SearchIcon />,
            }}
          />
        );
      }}
      noOptionsText={<NotFoundText />}
      getOptionLabel={(option) => option.skill.name}
      groupBy={(option) => {
        const firstLetter = option.skill.name[0].toUpperCase();
        return /[0-9]/.test(firstLetter) ? "0-9" : firstLetter;
      }}
      disableClearable
      disableValueRemoval
      filterSelectedOptions
    />
  );
};

const getUniqueSkills = (values) => {
  const unique = _.uniq(values, false, (value) => {
    return value.skill.id;
  });
  return unique;
};
