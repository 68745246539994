import { useEffect, useRef } from "react";
import type { LexicalEditor, NodeKey } from "lexical";
import { $getNodeByKey, TextNode } from "lexical";

import { useEffectRef } from "@js/hooks/use-effect-ref";

import { CustomHashtagNode } from "../components/hashtag-node";

type UseHashtagMutationProps = {
  editor: LexicalEditor;
  onHashtagsCreated: (hashtagName: string[]) => void;
  onHashtagsRemoved: (hashtagsNames: string[]) => void;
  onHashtagClick: () => void;
};

export const useHashtagMutation = ({
  editor,
  onHashtagsCreated,
  onHashtagsRemoved,
  onHashtagClick,
}: UseHashtagMutationProps) => {
  const hashtagNodeListRef = useRef(new Map<NodeKey, CustomHashtagNode>());

  const onHashtagsCreatedRef = useEffectRef(onHashtagsCreated);
  const onHashtagsRemovedRef = useEffectRef(onHashtagsRemoved);
  const onHashtagClickRef = useEffectRef(onHashtagClick);

  useEffect(() => {
    if (!editor.hasNodes([CustomHashtagNode])) {
      throw new Error(
        "CustomHashtagNode: CustomHashtagNode not registered on editor",
      );
    }

    const hashtagNodeList = hashtagNodeListRef.current;

    const removeListener = editor.registerMutationListener(
      CustomHashtagNode,
      (nodeMutations) => {
        const handleHashtagsCreated = (nodeKeys: string[]) => {
          editor.getEditorState().read(() => {
            const hashtagsNames: string[] = [];

            nodeKeys.forEach((nodeKey) => {
              const hashtagNode = $getNodeByKey<CustomHashtagNode>(nodeKey);
              const hashtagElement = editor.getElementByKey(nodeKey);

              if (hashtagNode && hashtagElement) {
                const hashtagName = hashtagNode.__hashtag;
                hashtagsNames.push(hashtagName);

                hashtagElement.onclick = () => onHashtagClickRef.current();
                hashtagNodeList.set(nodeKey, hashtagNode);
              }
            });

            if (hashtagsNames.length) {
              onHashtagsCreatedRef.current?.(hashtagsNames);
            }
          });
        };

        const handleHashtagsDestroyed = (nodeKeys: string[]) => {
          const hashtagsNames = nodeKeys
            .map((nodeKey) => {
              const hashtagName = hashtagNodeList.get(nodeKey)?.__hashtag;
              hashtagNodeList.delete(nodeKey);

              return hashtagName;
            })
            .filter((hashtagName): hashtagName is string => !!hashtagName);

          if (hashtagsNames.length) {
            onHashtagsRemovedRef.current?.(hashtagsNames);
          }
        };

        const nodeKeysDestroyed: string[] = [];
        const nodeKeysCreated: string[] = [];

        for (const [nodeKey, mutation] of nodeMutations) {
          if (mutation === "created") {
            nodeKeysCreated.push(nodeKey);
          } else if (mutation === "destroyed") {
            nodeKeysDestroyed.push(nodeKey);
          }
        }

        editor.update(() => {
          nodeKeysCreated.forEach((nodeKeyCreated) => {
            const tryingToAddHashtagNode =
              $getNodeByKey<CustomHashtagNode>(nodeKeyCreated);

            if (tryingToAddHashtagNode) {
              const tryingToAddHashtagName = tryingToAddHashtagNode.__hashtag;

              for (const hashtag of hashtagNodeList.values()) {
                const existingHashtagName = hashtag.__hashtag;

                if (tryingToAddHashtagName === existingHashtagName) {
                  editor.update(() => {
                    tryingToAddHashtagNode.replace(
                      new TextNode(tryingToAddHashtagNode.__hashtag),
                    );
                  });
                }
              }
            }
          });

          setTimeout(() => {
            editor.update(() => {
              if (nodeKeysCreated.length) {
                handleHashtagsCreated(nodeKeysCreated);
              }

              if (nodeKeysDestroyed.length) {
                handleHashtagsDestroyed(nodeKeysDestroyed);
              }
            });
          }, 0);
        });
      },
    );
    return () => removeListener();
  }, [editor, onHashtagsCreatedRef, onHashtagsRemovedRef, onHashtagClickRef]);
};
