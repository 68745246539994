import type { TextFieldUnionProps } from "@hexocean/braintrust-ui-components";
import { TextField } from "@hexocean/braintrust-ui-components";

import { useJobLocationExpandableField } from "./hook";

type JobLocationExpandableTextFieldProps = TextFieldUnionProps;

export const JobLocationExpandableTextField = ({
  className,
  ["aria-labelledby"]: ariaLabelledBy,
  ...props
}: JobLocationExpandableTextFieldProps) => {
  const { spanRef, spanText, textWidth } = useJobLocationExpandableField(props);

  return (
    <span className="expandable-job-location-text-field">
      <span
        aria-hidden="true"
        className="expandable-job-location-text-field__content"
        ref={spanRef}
      >
        {spanText}
      </span>
      <TextField
        {...props}
        inputProps={{
          ...props.inputProps,
          "aria-labelledby": ariaLabelledBy,
          style: { width: textWidth },
        }}
      />
    </span>
  );
};
