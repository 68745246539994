import { Box } from "@hexocean/braintrust-ui-components";
import {
  LocationDetail,
  TimezoneDetail,
  WorkTimeDetail,
} from "@js/apps/jobs/components/job-basic-details";

import type { JobItemProps } from "../job-item";

export const JobForYouBasicInfo = ({ job }: Pick<JobItemProps, "job">) => {
  return (
    <Box display="flex" gap="8px 24px" flexWrap="wrap">
      <WorkTimeDetail job={job} variant="jobs-for-you-card" shortened />
      <LocationDetail job={job} variant="jobs-for-you-card" />
      <TimezoneDetail job={job} variant="jobs-for-you-card" shortenedLabel />
    </Box>
  );
};
