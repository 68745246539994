import InfiniteScroll from "react-infinite-scroll-component";

import { BookmarksLoader } from "@js/apps/bookmarks/components/bookmarks-loader";
import { BookmarksEmptyState } from "@js/apps/bookmarks/components/empty-state";
import { PostLocation } from "@js/apps/give-and-get-help/context/post-location";

import { BookmarksPostsSection } from "../components/bookmarks-posts-section";
import { usePostsBookmarkPage } from "../hooks";

export const CareerHelpBookmarksPage = () => {
  const { handleFetchingMore, hasMore, posts, isLoading } =
    usePostsBookmarkPage();

  if (isLoading) {
    return <BookmarksLoader />;
  }

  if (!posts?.length) {
    return (
      <BookmarksEmptyState
        ctaLabel="Go to Career Help"
        ctaLink="/feed"
        description="Your saved Career Help posts will live here"
      />
    );
  }

  return (
    <div className="bookmarks-listing">
      <PostLocation.Provider value={PostLocation.Values.bookmark}>
        <div className="bookmarks-listing-content">
          <InfiniteScroll
            dataLength={posts?.length}
            next={() => (handleFetchingMore ? handleFetchingMore() : undefined)}
            hasMore={hasMore}
            scrollThreshold={0.6}
            loader={<BookmarksLoader />}
          >
            <BookmarksPostsSection posts={posts} />
          </InfiniteScroll>
        </div>
      </PostLocation.Provider>
    </div>
  );
};
