import type { DashboardNavItem } from "@js/apps/dashboard/nav-items";
import { findCurrentNavItem } from "@js/apps/dashboard/nav-items";

export const checkIfAnySubLinkIsActive = (subLinks: DashboardNavItem[]) => {
  const subLinksWithNestedSubLinks = subLinks.filter(
    (subLink) => subLink.subLinks,
  ) as unknown as Required<DashboardNavItem>[];
  let flag = false;
  if (subLinksWithNestedSubLinks.length) {
    subLinksWithNestedSubLinks.forEach((subLink) => {
      flag = flag || !!findCurrentNavItem({ items: subLink.subLinks });
    });
  }
  return flag || !!findCurrentNavItem({ items: subLinks });
};
