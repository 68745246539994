import type { INVOICE_LISTING_FILTERS_OPTIONS } from "../../constants";
import type { InvoiceFiltersFetchParams } from "../../filters";

export type UseInvoiceListingFiltersItemProps = {
  filters: Partial<InvoiceFiltersFetchParams>;
  pathname: string;
  query: (typeof INVOICE_LISTING_FILTERS_OPTIONS)[number]["query"];
};

export const useInvoiceListingFiltersItem = ({
  filters,
  pathname,
  query,
}: UseInvoiceListingFiltersItemProps) => {
  const isShowAllOption =
    !("paid" in filters) &&
    !("unpaid" in filters) &&
    !("invoice_batches" in filters);

  const isActive =
    Object.keys(query)[0] in filters ||
    (isShowAllOption && !Object.keys(query).length);

  const searchParamsString = new URLSearchParams(
    query as Record<string, string>,
  ).toString();
  const to = `${pathname}?${searchParamsString}`;

  return {
    isActive,
    to,
  };
};
