import React from "react";

import { useIsNodeStaff } from "@js/apps/common/hooks";

import {
  AcceptingProposalsStatus,
  ClientHiredBraintrustTalent,
  NoBraintrustTalentHired,
  NotAcceptingProposals,
  TalentBidButtonOrAppliedTag,
} from "./components";

export const TalentStatus = ({ job, bid, ...props }) => {
  const isNodeStaff = useIsNodeStaff();
  const hasFreelancerBid = job.has_freelancer_bid;

  switch (job.job_status) {
    case ENUMS.JobStatus.OPEN:
      return (
        <AcceptingProposalsStatus
          {...props}
          fullHeight
          content={
            <TalentBidButtonOrAppliedTag
              hasFreelancerBid={hasFreelancerBid}
              job={job}
              bid={bid}
            />
          }
        />
      );

    case ENUMS.JobStatus.ACTIVE:
    case ENUMS.JobStatus.ACTIVE_NOT_FILLED:
    case ENUMS.JobStatus.ACTIVE_FILLED: {
      // we assume node staff prefers to see hired count than proposal button
      if (!isNodeStaff && job.openings_left > 0) {
        return (
          <AcceptingProposalsStatus
            {...props}
            fullHeight
            content={
              <TalentBidButtonOrAppliedTag
                hasFreelancerBid={hasFreelancerBid}
                job={job}
                bid={bid}
              />
            }
          />
        );
      }

      return (
        <ClientHiredBraintrustTalent
          fullHeight
          count={job.hired_bids_count}
          {...props}
        />
      );
    }
    case ENUMS.JobStatus.ON_HOLD:
      return <NotAcceptingProposals fullHeight {...props} />;

    case ENUMS.JobStatus.COMPLETED_HIRED:
      return (
        <ClientHiredBraintrustTalent
          fullHeight
          count={job.hired_bids_count}
          color="var(--black)"
          status="Complete"
          {...props}
        />
      );

    case ENUMS.JobStatus.COMPLETED_NOT_HIRED:
      return <NoBraintrustTalentHired fullHeight {...props} />;

    default:
      return null;
  }
};
