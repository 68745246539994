import { Box } from "@hexocean/braintrust-ui-components";
import type { ATSIntegration } from "@js/types/employer";

import styles from "../style.module.scss";

export const IntegrationLogo = ({
  color,
  src,
  name,
}: {
  color: ATSIntegration["color"];
  src: ATSIntegration["logo_square"];
  name: ATSIntegration["integration"];
}) => {
  return (
    <Box
      className={styles.integrationLogo}
      sx={{
        border: `2px solid ${color || "black"}`,
      }}
    >
      <img
        src={src}
        width="100%"
        height="100%"
        style={{
          borderRadius: "50%",
        }}
        alt={`${name} logo`}
      />
    </Box>
  );
};
