import axios from "axios";

import type { AppThunkAction } from "@js/store";

import { Snackbar } from "../../components/snackbar";

import * as actionTypes from "./action-types";

export const loadSiteNotifications =
  (): AppThunkAction<Promise<any>> => (dispatch) => {
    dispatch({
      type: actionTypes.LOAD_SITE_NOTIFICATIONS,
    });

    return axios
      .get("/api/site_notifications/")
      .then((response) => {
        dispatch({
          type: actionTypes.LOAD_SITE_NOTIFICATIONS_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: actionTypes.LOAD_SITE_NOTIFICATIONS_FAILED,
          payload: error.response
            ? error.response.data
            : "Sorry. Loading site notifications failed!",
        });
      });
  };

export const siteNotificationMarkAsRead =
  (siteNotificationId): AppThunkAction<Promise<any>> =>
  (dispatch) =>
    axios
      .post(`/api/site_notifications/${siteNotificationId}/mark_as_read/`)
      .then(() => {
        dispatch({
          type: actionTypes.MARK_SITE_NOTIFICATION_AS_READ,
          payload: siteNotificationId,
        });
      })
      .catch((error) =>
        Snackbar.error(error?.response?.data || "Sorry! Something went wrong."),
      );

export const siteNotificationsMarkAllAsRead =
  (): AppThunkAction<Promise<any>> => (dispatch) =>
    axios
      .post(`/api/site_notifications/mark_all_as_read/`)
      .then(() => {
        dispatch({
          type: actionTypes.MARK_ALL_SITE_NOTIFICATIONS_AS_READ,
        });
      })
      .catch((error) =>
        Snackbar.error(error?.response?.data || "Sorry! Something went wrong."),
      );
