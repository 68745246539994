import { Typography } from "@hexocean/braintrust-ui-components";
import { MyWalletBalance } from "@js/apps/dashboard/components";
import type { Balance } from "@js/types/common";

import { LifetimeEarningsDetail } from "./earnings-detail";

type LifetimeEarningsProps = {
  balance?: Balance;
};

export const LifetimeEarnings = (props: LifetimeEarningsProps) => (
  <div className="lifetime-earnings">
    <Typography variant="title" component="h2" size="small">
      Lifetime earnings
    </Typography>
    <MyWalletBalance
      balance={props.balance?.token_lifetime_earnings ?? 0}
      hideCurrency
      iconStyle={{ width: 36, height: 36, marginRight: 8 }}
      textStyle={{ display: "flex", alignItems: "center" }}
    />
    <div className="lifetime-earnings__details-group">
      <LifetimeEarningsDetail
        label="Referrals"
        legend="From referrals"
        balance={props.balance?.tokens_via_referrals ?? 0}
        bgcolor="var(--soft-yellow)"
      />

      <LifetimeEarningsDetail
        label="Community"
        legend="From community help"
        balance={props.balance?.tokens_via_community ?? 0}
        bgcolor="var(--soft-blue)"
      />

      <LifetimeEarningsDetail
        label="Other"
        legend="All other earnings"
        balance={props.balance?.tokens_via_others ?? 0}
        bgcolor="var(--soft-violet)"
      />
    </div>
  </div>
);
