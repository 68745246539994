import { MyWorkListingPage } from "../../my-work-listing-page";

const MY_OFFERS_NAVIGATION_ITEMS = [
  {
    label: ENUMS.TalentOffersSectionFilterLabels.current,
    path: "/talent/dashboard/my_jobs/offers/current_offers",
  },
  {
    label: ENUMS.TalentOffersSectionFilterLabels.past,
    path: "/talent/dashboard/my_jobs/offers/past_offers",
  },
];

export const MyOffersListingPage = () => (
  <MyWorkListingPage items={MY_OFFERS_NAVIGATION_ITEMS} pageTitle="Offers" />
);
