import { $getListDepth, $isListItemNode, $isListNode } from "@lexical/list";
import type { LinkMatcher } from "@lexical/react/LexicalAutoLinkPlugin";
import type { ElementNode, RangeSelection } from "lexical";
import { $getSelection, $isElementNode, $isRangeSelection } from "lexical";

const URL_MATCHER =
  /((https?:\/\/(www\.)?)|(www\.))[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/;

export const LINK_MATCHERS: LinkMatcher[] = [
  (text) => {
    const match = URL_MATCHER.exec(text);
    if (match === null) {
      return null;
    }
    const fullMatch = match[0];
    return {
      index: match.index,
      length: fullMatch.length,
      text: fullMatch,
      url: fullMatch.startsWith("http") ? fullMatch : `https://${fullMatch}`,
    };
  },
];

export const createInitialState = (text: string) => {
  const child = {
    detail: 0,
    format: 0,
    mode: "normal",
    style: "",
    text,
    type: "text",
    version: 1,
  };

  const initialState = {
    root: {
      children: [
        {
          children: [child],
          direction: null,
          format: "",
          indent: 0,
          type: "paragraph",
          version: 1,
        },
      ],
      direction: null,
      format: "",
      indent: 0,
      type: "root",
      version: 1,
    },
  };

  return JSON.stringify(initialState);
};

const getElementNodesInSelection = (
  selection: RangeSelection,
): Set<ElementNode> => {
  const nodesInSelection = selection.getNodes();

  if (nodesInSelection.length === 0) {
    return new Set([
      selection.anchor.getNode().getParentOrThrow(),
      selection.focus.getNode().getParentOrThrow(),
    ]);
  }

  return new Set(
    nodesInSelection.map((n) => ($isElementNode(n) ? n : n.getParentOrThrow())),
  );
};

const getTotalListDepth = () => {
  const selection = $getSelection();

  if (!$isRangeSelection(selection)) {
    return 0;
  }

  const elementNodesInSelection: Set<ElementNode> =
    getElementNodesInSelection(selection);

  let totalDepth = 0;

  for (const elementNode of elementNodesInSelection) {
    if ($isListNode(elementNode)) {
      totalDepth = Math.max($getListDepth(elementNode), totalDepth);
    } else if ($isListItemNode(elementNode)) {
      const parent = elementNode.getParent();

      if ($isListNode(parent)) {
        totalDepth = Math.max($getListDepth(parent), totalDepth);
      }
    }
  }
  return totalDepth;
};

export const isListIncreaseIndentPermitted = (maxDepth: number): boolean => {
  return getTotalListDepth() <= maxDepth;
};

export const isListDecreaseIndentPermitted = (): boolean => {
  return getTotalListDepth() > 1;
};
