import { List, ListItem } from "@hexocean/braintrust-ui-components";
import {
  LinkedinSVGIcon,
  OpenSeaIcon,
  XIcon,
} from "@hexocean/braintrust-ui-components/Icons";
import { useNftShareSocialLinks } from "@js/apps/nft/hooks";

type NftShareListProps = {
  tokenId: number;
};

export const NftShareList = ({ tokenId }: NftShareListProps) => {
  const { linkedInUrl, twitterUrl, openSeaUrl } =
    useNftShareSocialLinks(tokenId);

  return (
    <List className="minting-success-modal__share-links">
      <ListItem className="minting-success-modal__social">
        <a
          href={linkedInUrl}
          target="_blank"
          rel="noopener noreferrer"
          className="minting-success-modal__link"
        >
          <LinkedinSVGIcon />
          LinkedIn
        </a>
      </ListItem>
      <ListItem className="minting-success-modal__social">
        <a
          href={twitterUrl}
          target="_blank"
          rel="noopener noreferrer"
          className="minting-success-modal__link"
        >
          <XIcon viewBox="-150 -150 1560 1595" />X
        </a>
      </ListItem>
      <ListItem className="minting-success-modal__social">
        <a
          href={openSeaUrl}
          target="_blank"
          rel="noopener noreferrer"
          className="minting-success-modal__link"
        >
          <OpenSeaIcon />
          OpenSea
        </a>
      </ListItem>
    </List>
  );
};
