import React from "react";

import {
  Box,
  EmployerLogo,
  Typography,
} from "@hexocean/braintrust-ui-components";
import { BookmarkJobButton } from "@js/apps/bookmarks/components";
import { useCtaClickedContext } from "@js/apps/common/context";
import { useAccountType, useUser } from "@js/apps/common/hooks";
import { EmployerLogoWithNameAndColor } from "@js/apps/employer/components/profile/employer-logo-with-name-and-color";
import { BasicInfo } from "@js/apps/jobs/components/basic-info";
import { JobBudgetWithPaymentTypeTooltip } from "@js/apps/jobs/components/job-budget-with-payment-type-tooltip";
import { JobForYouBasicInfo } from "@js/apps/jobs/components/job-for-you-basic-info";
import { getSkillForNotFreelancerView } from "@js/apps/jobs/components/job-item/utils";
import { JobSkills } from "@js/apps/jobs/components/job-skills";
import { JobTags } from "@js/apps/jobs/components/job-type-and-role-tags/job-tags";
import { JobTypeTagWithTooltip } from "@js/apps/jobs/components/job-type-tag-with-tooltip";
import { ShareJobButton } from "@js/apps/jobs/components/share-job-button";
import { ViewJobButton } from "@js/apps/jobs/components/view-job-button";
import {
  DEFAULT_LISTING_BG_COLOR,
  FALLBACK_BG_COLOR,
} from "@js/apps/jobs/constants";
import { RouterLink } from "@js/components/link";
import type { JobListingJob } from "@js/types/jobs";

import { EmplyerNameAndJobTitle } from "../employer-name-and-job-title";

type JobCarouselItemProps = {
  job: JobListingJob;
  hideSkills?: boolean;
  borderColor?: string;
};

export const JobCarouselItem = ({
  job,
  hideSkills,
  borderColor = "var(--soft-beige)",
}: JobCarouselItemProps) => {
  const { isFreelancer } = useAccountType();

  const skillToDisplay = isFreelancer
    ? job.main_skills
    : getSkillForNotFreelancerView(job.job_skills);

  const { logo_background_color } = job.employer;

  return (
    <Box
      className="job-carousel-item"
      border={`1px solid ${borderColor}`}
      tabIndex={0}
    >
      <Box
        bgcolor={`var(${logo_background_color || DEFAULT_LISTING_BG_COLOR})`}
        className="job-carousel-item__header"
      >
        <EmployerLogoWithNameAndColor
          employer={job.employer}
          jobId={job.id}
          size="medium"
          gap={14}
          nameStyle={{ fontSize: "16px" }}
          openInNewTab
        />
      </Box>
      <Box className="job-carousel-item__content">
        <Box>
          <JobTags
            jobType={job.job_type}
            role={job.role}
            mt={0}
            isInvitedByMatcher={job.invited_by_matcher}
            isInvitedByClient={job.invited_by_client}
            isInterested={job.interested}
          />

          <Typography
            component="h5"
            variant="paragraph"
            size="large"
            mt={1.5}
            multilineEllipsis={2}
            className="capitalize-first-letter"
          >
            {job.title}
          </Typography>

          <Box className="job-carousel-item__budget">
            <JobBudgetWithPaymentTypeTooltip
              job={job}
              variant="title"
              size="small"
              fontWeight={400}
            />
          </Box>

          <BasicInfo job={job} flexDirection="column" displayContractType />
        </Box>

        <Box
          className="job-carousel-item__cta"
          height={hideSkills ? "unset" : "192px"}
        >
          {!hideSkills && (
            <Box mt={2}>
              <JobSkills skills={skillToDisplay} />
            </Box>
          )}
          <Box className="job-carousel-item__actions">
            {isFreelancer && <BookmarkJobButton job={job} />}
            <ViewJobButton
              variant="primary"
              job={job}
              className="width-100"
              style={{ height: 32 }}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export const JobForYouCarouselItem = ({
  job,
}: Omit<JobCarouselItemProps, "hideSkills" | "borderColor">) => {
  const { ctaClicked } = useCtaClickedContext();
  const { isFreelancer } = useAccountType();
  const user = useUser();

  const { logo_background_color, logo_thumbnail } = job.employer;
  const bgColor = logo_thumbnail
    ? `var(${logo_background_color || DEFAULT_LISTING_BG_COLOR})`
    : FALLBACK_BG_COLOR;
  return (
    <Box className="job-for-you-carousel-item" tabIndex={0}>
      <Box className="job-for-you-carousel-item__header">
        <EmployerLogo
          className="job-for-you-carousel-item__logo"
          src={{ logo_thumbnail, name: job.employer.name }}
          fallbackTextColor="dark-violet"
          bgColor={bgColor}
          fallbackTextSize="medium"
        />
        <Box className="job-for-you-carousel-item__actions">
          <JobTypeTagWithTooltip jobType={job.job_type} />
          <Box display="flex" alignItems="center" height="fit-content">
            {isFreelancer && (
              <ShareJobButton
                job={job}
                className="job-for-you-carousel-item__share"
              />
            )}
            {isFreelancer && (
              <BookmarkJobButton
                disabled={!user?.is_verified}
                job={job}
                className="job-for-you-carousel-item__save-job"
              />
            )}
          </Box>
        </Box>
      </Box>

      <Box className="job-for-you-carousel-item__content">
        <Box>
          <EmplyerNameAndJobTitle job={job} />
        </Box>

        <Box>
          <JobBudgetWithPaymentTypeTooltip
            job={job}
            variant="title"
            size="small"
            fontWeight={400}
          />
        </Box>

        <Box className="job-for-you-carousel-item__info">
          <JobForYouBasicInfo job={job} />
        </Box>
      </Box>

      <RouterLink
        to={`/jobs/${job.id}`}
        onClick={() => {
          ctaClicked(ENUMS.UserClicksEventCTAName.VIEW_JOB);
        }}
      >
        <Box className="job-for-you-carousel-item__link" />
      </RouterLink>
    </Box>
  );
};
