import React from "react";
import { submit } from "redux-form";

import { Loader, Typography } from "@hexocean/braintrust-ui-components";
import { EmployerEditWizard } from "@js/apps/employer/components/employer-edit-wizard";
import {
  AVATAR_UPDATED_FIELD_NAME,
  OrganizationInfoForm,
} from "@js/apps/employer/forms/organization-info";
import { useEmployerOrganizationInfo } from "@js/apps/employer/hooks/employer-organization-info";
import { useAppDispatch, useAppSelector } from "@js/hooks";

const EmployerEditOrganizationInfoPage = () => {
  const dispatch = useAppDispatch();
  const onContinue = () => {
    dispatch(submit(OrganizationInfoForm.defaultProps.form));
  };

  const { onSubmit, initialValues, onSubmitSuccess, isLoading } =
    useEmployerOrganizationInfo({
      avatarUpdatedFieldName: AVATAR_UPDATED_FIELD_NAME,
    });

  const isAvatarUploading = useAppSelector(
    (state) => state.employer.avatarFieldUploading,
  );

  if (isLoading) {
    return <Loader centered />;
  }

  return (
    <EmployerEditWizard
      pageTitle="Edit Profile | Organization Info"
      continueButtonColor="secondary"
      onContinue={onContinue}
      disableSubmit={isAvatarUploading}
    >
      <Typography
        component="h1"
        variant="title"
        textTransform="uppercase"
        className="wizard-title"
      >
        Organization Info
      </Typography>
      <OrganizationInfoForm
        initialValues={initialValues}
        onSubmit={onSubmit}
        onSubmitSuccess={onSubmitSuccess}
      />
    </EmployerEditWizard>
  );
};

export default EmployerEditOrganizationInfoPage;
