import type { ForwardedRef } from "react";
import { useImperativeHandle, useMemo, useRef } from "react";

import { useUser } from "@js/apps/common/hooks";
import { replyClicked } from "@js/apps/give-and-get-help/actions";
import {
  type BasePostContextType,
  usePostLocationContext,
} from "@js/apps/give-and-get-help/context";
import { markAddedComment } from "@js/apps/give-and-get-help/posts-comments-slice";
import {
  createPostOrCommentURL,
  getLinksMetadata,
} from "@js/apps/give-and-get-help/utils";
import { useIsPostAuthorASpaceAdmin } from "@js/apps/spaces/hooks/use-is-post-author-a-space-admin";
import { useAppDispatch } from "@js/hooks";
import type { IPost } from "@js/types/give-and-get-help";

export type OnPostAction = (postId: number, spaceId?: number | null) => void;

export type UseBasePostItemProps = {
  postData: IPost;
  isPublic?: boolean;
  forwardRef?: ForwardedRef<HTMLDivElement>;
  onReactionClick?: OnPostAction;
  onReplyClick?: OnPostAction;
  onViewCommentClick?: OnPostAction;
};

export const useBasePostItem = ({
  postData,
  isPublic = false,
  forwardRef,
  onReactionClick,
  onReplyClick,
  onViewCommentClick,
}: UseBasePostItemProps) => {
  const dispatch = useAppDispatch();
  const postLocation = usePostLocationContext();
  const internalRef = useRef<HTMLDivElement>(null);
  const user = useUser();

  const postUrl = createPostOrCommentURL({
    postId: postData.id,
    spaceId: postData.space,
  });

  const spaceId = postData.space;
  const postAuthorId = postData.freelancer.id;
  const isSpaceAdmin = useIsPostAuthorASpaceAdmin(spaceId, postAuthorId);

  const postLinksMetadata = useMemo(
    () => getLinksMetadata(postData.links_metadata),
    [postData.links_metadata],
  );

  const hasAttachments = Boolean(postData.attachments.length);
  const hasLinks = Boolean(postData.links_metadata.length);
  const shouldDisplayPostAttachments = hasAttachments || hasLinks;

  const contextValue = useMemo((): BasePostContextType => {
    return {
      postData,
      isPublic,
      onReactionClick,
      onReplyClick,
      onViewCommentClick,
    };
  }, [postData, isPublic, onReactionClick, onReplyClick, onViewCommentClick]);

  useImperativeHandle(
    forwardRef,
    () => {
      return internalRef.current as HTMLDivElement;
    },
    [],
  );

  const onAddReply = (commentId: number) => {
    dispatch(markAddedComment({ postId: postData.id, commentId }));
  };

  const onReplyFormClick = (postId: number) => {
    onReplyClick?.(postId, postData.space);
    dispatch(replyClicked({ ...postData, post_location: postLocation }));
  };

  const disablePoll = !!user?.is_banned_from_posting || !user?.is_verified;

  return {
    onAddReply,
    onReplyFormClick,
    postUrl,
    postLinksMetadata,
    shouldDisplayPostAttachments,
    contextValue,
    internalRef,
    disablePoll,
    shouldDisplayAdminBadge: isSpaceAdmin,
  };
};
