import { useUser } from "@js/apps/common/hooks";
import type { ApplicationInsightMatchSummary } from "@js/types/jobs";

type MatchPlacement = {
  label: string;
  summaryType: ApplicationInsightMatchSummary["summary_type"];
};

export const useMatchPlacement = (
  matchLabel: EnumType<typeof ENUMS.SmartMatchingLabel> | null,
  numberOfApplicants: number,
): MatchPlacement => {
  const isBadMatch = matchLabel === ENUMS.SmartMatchingLabel.BAD;
  let placementLabel = "";

  if (matchLabel === ENUMS.SmartMatchingLabel.BAD) {
    placementLabel = "You're at the bottom 25%";
  } else if (matchLabel === ENUMS.SmartMatchingLabel.OK) {
    placementLabel = "You're in the Top 75%";
  } else if (matchLabel === ENUMS.SmartMatchingLabel.GOOD) {
    placementLabel = "You're in the Top 25%";
  } else if (matchLabel === ENUMS.SmartMatchingLabel.GREAT) {
    placementLabel = "You're in the Top 10%";
  }

  return {
    label: `There are ${numberOfApplicants} applicant${numberOfApplicants > 1 ? "s" : ""}. ${placementLabel}`,
    summaryType: !isBadMatch ? "positive" : "constructive",
  };
};

export const useScoreMatchBadge = (
  matchLabel: EnumType<typeof ENUMS.SmartMatchingLabel> | null,
) => {
  const user = useUser();
  const showBadge =
    matchLabel !== ENUMS.SmartMatchingLabel.UNAVAILABLE &&
    matchLabel !== ENUMS.SmartMatchingLabel.RECOMMENDED_BY_MATCHER;

  return {
    user,
    showBadge,
  };
};

export const formatMatchLabel = (
  matchLabel: EnumType<typeof ENUMS.SmartMatchingLabel> | null,
) => {
  if (!matchLabel) return "";

  const label = ENUMS.SmartMatchingLabels[matchLabel];

  return label;
};

export const getMatchLabelColors = (
  matchLabel: EnumType<typeof ENUMS.SmartMatchingLabel> | null,
  isForMainBadge: boolean = false,
) => {
  switch (matchLabel) {
    case ENUMS.SmartMatchingLabel.GREAT: {
      return {
        backgroundColor: isForMainBadge ? "var(--positive-2)" : "#E4F7EE",
        textColor: isForMainBadge ? "var(--white)" : "var(--positive-1)",
      };
    }

    case ENUMS.SmartMatchingLabel.GOOD: {
      return {
        backgroundColor: "var(--medium-blue)",
        textColor: "var(--info-1)",
      };
    }

    case ENUMS.SmartMatchingLabel.OK: {
      return {
        backgroundColor: "var(--warning-2)",
        textColor: "var(--dark-yellow)",
      };
    }

    case ENUMS.SmartMatchingLabel.BAD: {
      return {
        backgroundColor: "var(--medium-red)",
        textColor: "var(--dark-red)",
      };
    }

    case ENUMS.SmartMatchingLabel.RECOMMENDED_BY_MATCHER:
    case ENUMS.SmartMatchingLabel.UNAVAILABLE:
    default: {
      return {
        backgroundColor: "",
        textColor: "",
      };
    }
  }
};
