import type { UploadsState } from "@js/types/uploads";

import * as actionTypes from "./action-types";

const INITIAL_STATE: UploadsState = {
  // Updates received via websocket-client.
  uploadsStatusUpdates: [],
};

export default function (state = INITIAL_STATE, action): UploadsState {
  switch (action.type) {
    case actionTypes.UPLOAD_STATUS_UPDATE: {
      const newStateAfterUploadStatusUpdate = {
        ...state,
        uploadsStatusUpdates: [...state.uploadsStatusUpdates, action.payload],
      };

      return newStateAfterUploadStatusUpdate;
    }

    default:
      return state;
  }
}
