import { dateDifferenceFromNow, isDateValid } from "@js/utils";

import type {
  EmployerListingFormValues,
  EmployerListingParamsValues,
} from "../types";

export const getBlockedPaymentTime = (blockedUntil: string | null) => {
  const dateDifference = dateDifferenceFromNow(blockedUntil, "minutes");
  if (dateDifference > 1) return `${dateDifference} minutes`;
  if (dateDifference === 1) return "a minute";
  return "a few seconds";
};

export const isPaymentStillBlocked = (blockedUntil: string | null) => {
  if (!blockedUntil) return false;

  const nowDate = new Date();
  const blockedUntilDate = new Date(blockedUntil);

  return blockedUntilDate > nowDate;
};

export const mapValuesToParamsEmployerListingFilters = (
  values: EmployerListingFormValues,
) => {
  const newFilters = {
    ...values,
  } as any;

  const isDateAfterValid = isDateValid(newFilters.date_after);
  const isDateBeforeValid = isDateValid(newFilters.date_before);

  if (!isDateAfterValid) {
    delete newFilters.date_after;
  }

  if (!isDateBeforeValid) {
    delete newFilters.date_before;
  }

  if (newFilters.payee_name) {
    if (newFilters.payee_name === -1) {
      newFilters.fee_invoices_only = true;
      delete newFilters.freelancer;
    } else {
      newFilters.freelancer = newFilters.payee_name;
      delete newFilters.fee_invoices_only;
    }
  }

  delete newFilters.type;
  delete newFilters.payee_name;

  return newFilters as EmployerListingParamsValues;
};

export const mapParamsToValuesEmployerListingFilters = (
  values: EmployerListingParamsValues,
) => {
  const newFilters = {
    ...values,
  } as any;

  if (newFilters.freelancer) {
    newFilters.payee_name = newFilters.freelancer;
  } else if (newFilters.fee_invoices_only) {
    newFilters.payee_name = -1;
  } else {
    newFilters.payee_name = null;
  }

  delete newFilters.fee_invoices_only;
  delete newFilters.freelancer;

  return newFilters as EmployerListingFormValues;
};
