import { Button } from "@hexocean/braintrust-ui-components";
import { assertUnreachable } from "@js/utils";

import { RoleModuleForm } from "../../forms/role-form";
import { useRoleModal } from "../../hooks";
import type { StepBeingEdited } from "../profile-completion-sequence/hooks/use-profile-completion";

import { RemoveRole } from "./remove-role";
import { RolesList } from "./roles-list";

type RoleModalProps = {
  persistModalInstance?: boolean;
  saveButtonText?: string;
  setStepBeingEdited?: React.Dispatch<React.SetStateAction<StepBeingEdited>>;
};

export const RoleModal = ({
  persistModalInstance = false,
  setStepBeingEdited,
  saveButtonText,
}: RoleModalProps) => {
  const {
    roles,
    modal,
    canSaveAndAddAnother,
    maxRoleCountReached,
    handleAdd,
    handleEdit,
    handleDelete,
    handleSaveRole,
    handleCancel,
  } = useRoleModal();

  switch (modal.kind) {
    case "list":
      return (
        <div>
          <RolesList
            roles={roles}
            onEdit={handleEdit}
            onDelete={handleDelete}
          />
          {!maxRoleCountReached && (
            <Button
              variant="tertiary-dark"
              size="medium"
              onClick={handleAdd}
              sx={{ mt: 4 }}
            >
              Add role
            </Button>
          )}
        </div>
      );

    case "add":
      return (
        <div>
          <RolesList
            roles={roles}
            onEdit={handleEdit}
            onDelete={handleDelete}
          />
          <RoleModuleForm
            canSaveAndAddAnother={canSaveAndAddAnother}
            key={modal.initialValues ? "add" : "add-initial-values"}
            initialValues={modal.initialValues}
            onCancel={handleCancel}
            onSave={handleSaveRole}
            persistModalInstance={persistModalInstance}
            setStepBeingEdited={setStepBeingEdited}
            saveButtonText={saveButtonText}
          />
        </div>
      );

    case "edit":
      return (
        <RoleModuleForm
          canSaveAndAddAnother={canSaveAndAddAnother}
          initialValues={modal.initialValues}
          isEdit
          roles={roles}
          onCancel={handleCancel}
          onSave={handleSaveRole}
          persistModalInstance={persistModalInstance}
        />
      );

    case "remove":
      return (
        <RemoveRole
          role={modal.role}
          onCancel={handleCancel}
          onRemove={() => {
            handleSaveRole();
          }}
        />
      );

    default:
      assertUnreachable(modal);
      return null;
  }
};
