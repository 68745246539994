import { useEffect, useState } from "react";

import { Box, Button, Typography } from "@hexocean/braintrust-ui-components";
import {
  ADD_ANOTHER_ITEM,
  MODULE_EDIT_MODAL_PROPERTIES,
} from "@js/apps/freelancer/constants";
import { WorkHistoryForm } from "@js/apps/freelancer/forms/work-history-form";
import { useWorkExperience } from "@js/apps/freelancer/hooks/edit-work-history";
import { ModalInstance } from "@js/components/modal";

import type { StepBeingEdited } from "../../profile-completion-sequence/hooks/use-profile-completion";
import { WorkHistoryCard } from "../card";

import {
  openEditWorkHistoryModal,
  openRemoveWorkHistoryModal,
} from "./edit-and-remove-work-history";

export const openAddWorkHistoryModal = () => {
  return ModalInstance.open({
    children: <AddWorkHistoryModal />,
    ...MODULE_EDIT_MODAL_PROPERTIES,
  });
};

type AddWorkHistoryModalProps = {
  persistModalInstance?: boolean;
  saveButtonText?: string;
  setStepBeingEdited?: React.Dispatch<React.SetStateAction<StepBeingEdited>>;
};

export const AddWorkHistoryModal = ({
  persistModalInstance = false,
  saveButtonText,
  setStepBeingEdited,
}: AddWorkHistoryModalProps) => {
  const {
    freelancerWork,
    onSubmit,
    onSubmitSuccess,
    onUpdateJobSuccess,
    getInitialValues,
    setSubmitType,
    removeWorkHistory,
    submitType,
  } = useWorkExperience(persistModalInstance);

  const [showForm, setShowForm] = useState<boolean>(!freelancerWork?.length);

  useEffect(() => {
    if (submitType !== ADD_ANOTHER_ITEM) {
      setShowForm(!freelancerWork?.length);
    }
  }, [freelancerWork, submitType]);

  return (
    <Box>
      <Typography
        variant="title"
        component="h2"
        size="small"
        fontWeight={400}
        mb={4}
      >
        💼 Work history
      </Typography>
      {!!freelancerWork?.length && (
        <Box
          mb={3}
          display="grid"
          gap={4}
          borderBottom="1px solid var(--grey-4)"
        >
          {freelancerWork.map((work) => {
            return (
              <WorkHistoryCard
                editMode
                key={work.id}
                work={work}
                onEdit={() => {
                  openEditWorkHistoryModal({
                    onSubmit,
                    onSubmitSuccess: onUpdateJobSuccess,
                    initialValues: getInitialValues(work),
                  });
                  setShowForm(false);
                }}
                onDelete={() =>
                  openRemoveWorkHistoryModal({
                    work,
                    removeWorkHistory,
                  })
                }
              />
            );
          })}
        </Box>
      )}
      {!showForm ? (
        <Button
          variant="tertiary"
          type="submit"
          onClick={() => setShowForm(true)}
        >
          Add a job
        </Button>
      ) : (
        <>
          <Typography variant="paragraph" component="p" size="large" mb={3}>
            Add a job
          </Typography>
          <WorkHistoryForm
            onSubmit={onSubmit}
            onSubmitSuccess={onSubmitSuccess}
            setSubmitType={setSubmitType}
            persistModalInstance={persistModalInstance}
            saveButtonText={saveButtonText}
            setStepBeingEdited={setStepBeingEdited}
          />
        </>
      )}
    </Box>
  );
};
