import React, { useEffect } from "react";
import { useSnackbar } from "notistack";

import { Box } from "@hexocean/braintrust-ui-components";
import { fetchNotificationsSettings } from "@js/apps/settings/actions";
import { useAppDispatch, useAppSelector } from "@js/hooks";

import { EmailNotificationTurnedOffContent } from "../../components/email-notification-turned-off-content";

type useEmailNotificationTurnedOffSnackBarProps = {
  isDrawerOpen: boolean;
};

export const useEmailNotificationTurnedOffSnackBar = ({
  isDrawerOpen,
}: useEmailNotificationTurnedOffSnackBarProps) => {
  const dispatch = useAppDispatch();

  const { closeSnackbar, enqueueSnackbar } = useSnackbar();

  const notificationSettings = useAppSelector(
    (state) => state.settings.notifications.general_notifications_settings,
  );
  const savedFiltersJobs = useAppSelector(
    (state) => state.savedFilters.filtersJobs,
  );

  useEffect(() => {
    dispatch(fetchNotificationsSettings());
  }, [dispatch]);

  useEffect(() => {
    const isEmailNotificationTurnedOn = notificationSettings.find(
      (setting) =>
        setting.notification ===
        ENUMS.NotificationTypes.FREELANCER_NEW_JOB_SAVED_FILTERS,
    )?.value;

    const isSavedFilterWithNotificationsOn = savedFiltersJobs.some(
      (filter) => filter.new_job_notification_enabled === true,
    );

    if (
      isDrawerOpen &&
      !isEmailNotificationTurnedOn &&
      isSavedFilterWithNotificationsOn
    ) {
      showMessage();
    }

    if (!isDrawerOpen) {
      closeSnackbar();
    }

    return () => {
      closeSnackbar();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDrawerOpen, notificationSettings, savedFiltersJobs]);

  const showMessage = () =>
    enqueueSnackbar(
      <EmailNotificationTurnedOffContent closeSnackbar={closeSnackbar} />,
      {
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "right",
        },
        persist: true,
        preventDuplicate: true,
        content: (key, message) => <Box id={`${key}`}>{message}</Box>,
      },
    );

  return {
    showMessage,
    closeMessage: closeSnackbar,
  };
};
