import { Loader, Typography } from "@hexocean/braintrust-ui-components";
import {
  WithJobActionsContext,
  type WithJobActionsContextData,
} from "@js/apps/jobs/components/job-actions";
import { RouterLink } from "@js/components/link";
import type { EmployerOwnJob } from "@js/types/jobs";

import { useOpenJobsModule } from "../../hooks/open-jobs-module";

import { OpenJobItem } from "./open-job-item";

import style from "./style.module.scss";

export const OpenJobsModule = () => {
  const { openJobs, totalOpenJobsCount, isLoading, jobActionContextValue } =
    useOpenJobsModule();

  return (
    <div>
      <div className={style.openJobsModuleHeader}>
        <Typography
          component="h2"
          variant="title"
          size="small"
          fontWeight={400}
        >
          Open jobs
        </Typography>
        <Typography
          component="link"
          variant="link"
          to="/employer/dashboard/my_jobs"
          size="large"
          RouterLink={RouterLink}
        >
          View all jobs
        </Typography>
      </div>
      {isLoading ? (
        <Loader />
      ) : (
        <OpenJobListing
          openJobCount={totalOpenJobsCount}
          openJobs={openJobs}
          contextValue={jobActionContextValue}
        />
      )}
    </div>
  );
};

type OpenJobListingProps = {
  openJobCount: number;
  openJobs: EmployerOwnJob[];
  contextValue: WithJobActionsContextData;
};

const OpenJobListing = ({
  openJobCount,
  openJobs,
  contextValue,
}: OpenJobListingProps) => {
  if (openJobCount === 0) {
    return <EmptyState />;
  }

  return (
    <WithJobActionsContext.Provider value={contextValue}>
      {openJobs.map((job) => (
        <OpenJobItem key={job.id} job={job} />
      ))}
    </WithJobActionsContext.Provider>
  );
};

const EmptyState = () => (
  <div className={style.openJobsModuleEmptyState}>
    <Typography component="h3" size="large">
      You have no open jobs
    </Typography>
    <Typography component="p" size="medium">
      View paused or completed jobs in{" "}
      <Typography
        component="link"
        RouterLink={RouterLink}
        to="/employer/dashboard/my_jobs"
      >
        My jobs
      </Typography>
      .
    </Typography>
  </div>
);
