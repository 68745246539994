import { useEffect, useRef } from "react";

import { Box, IconButton } from "@hexocean/braintrust-ui-components";
import {
  ArrowLeftIcon,
  ArrowRightIcon,
} from "@hexocean/braintrust-ui-components/Icons";

export const useTableScroll = () => {
  const tableContentRef = useRef<HTMLDivElement>(null);
  const scrollAmount = 10;
  const scrollIntervalRef = useRef<number>();

  const startScrolling = (direction: "right" | "left") => {
    scrollIntervalRef.current = window.setInterval(() => {
      if (tableContentRef.current) {
        tableContentRef.current.scrollLeft +=
          direction === "right" ? scrollAmount : -scrollAmount;
      }
    }, 10);
  };

  const stopScrolling = () => {
    clearInterval(scrollIntervalRef.current);
  };

  useEffect(
    () => () => {
      clearInterval(scrollIntervalRef.current);
    },
    [],
  );

  return {
    tableContentRef,
    startScrolling,
    stopScrolling,
  };
};

type TableScrollNavProps = {
  startScrolling: (direction: "right" | "left") => void;
  stopScrolling: () => void;
};

export const TableScrollNav = ({
  startScrolling,
  stopScrolling,
}: TableScrollNavProps) => {
  return (
    <Box display="flex" justifyContent="center" gap={1}>
      <span>
        <IconButton
          variant="secondary"
          aria-label="previous"
          size="small"
          onMouseDown={() => startScrolling("left")}
          onMouseUp={stopScrolling}
          onMouseLeave={stopScrolling}
          onTouchStart={() => startScrolling("left")}
          onTouchEnd={stopScrolling}
        >
          <ArrowLeftIcon />
        </IconButton>
      </span>
      <span>
        <IconButton
          variant="secondary"
          aria-label="next"
          size="small"
          onMouseDown={() => startScrolling("right")}
          onMouseUp={stopScrolling}
          onMouseLeave={stopScrolling}
          onTouchStart={() => startScrolling("right")}
          onTouchEnd={stopScrolling}
        >
          <ArrowRightIcon />
        </IconButton>
      </span>
    </Box>
  );
};
