import type {
  MySpacesDraft,
  SpaceAddedPostEvent,
  UserAddedToSpaceEvent,
} from "@js/apps/spaces/types";

export const mySpacesUpdateRecipe = (
  event: MessageEvent<SpaceAddedPostEvent | UserAddedToSpaceEvent>,
  draft: MySpacesDraft,
) => {
  if (!event.data) return;
  const eventData = event.data;
  const broadcast_type = eventData.broadcast_type;

  switch (broadcast_type) {
    case ENUMS.BroadcastType.SPACE_POST_ADDED:
      if (eventData.data.space) {
        const space = draft.find((s) => s.id === eventData.data.space);
        if (space && space.notifications_enabled) {
          space.notifications_count += 1;
        }
      }
      break;
    case ENUMS.BroadcastType.USER_ADDED_TO_SPACE:
      if (eventData.data.id) {
        const spaceAlreadyExists = draft.some(
          (existingSpace) => existingSpace.id === eventData.data.id,
        );
        if (!spaceAlreadyExists) {
          draft.push(eventData.data);
        }
      }
      break;
    default:
      break;
  }
};
