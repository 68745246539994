import { store } from "@js/store";

import * as actions from "./action-types";

/** @deprecated use `SomeModalInstance.open()`*/
export function openModal(modalId: string) {
  return store.dispatch({
    type: actions.OPEN_MODAL,
    payload: modalId,
  });
}

/** @deprecated use `SomeModalInstance.close()`*/
export function closeModal(modalId: string) {
  return store.dispatch({
    type: actions.CLOSE_MODAL,
    payload: modalId,
  });
}
