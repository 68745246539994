import React from "react";
import type { Opts } from "linkifyjs";

import { Typography } from "@hexocean/braintrust-ui-components";

import { RouterLink } from "../link";

export const getLinkifyRenderOptions = (): Opts => {
  const renderLink = ({ attributes, content }) => {
    const { href, ...props } = attributes;

    return (
      <Typography
        component="link"
        to={href}
        RouterLink={RouterLink}
        fontWeight={500}
        variant="link"
        color="blue"
        noTextDecoration
        {...props}
      >
        {content}
      </Typography>
    );
  };

  const options = {
    rel: "noopener noreferrer",
    target: {
      url: "_blank",
    },
    render: {
      url: renderLink,
    },
  };

  return options;
};
