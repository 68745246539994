import type { ReactNode } from "react";
import { useMemo } from "react";

import { ModalInstance } from "@js/components/modal";
import type { Freelancer } from "@js/types/freelancer";

import { ProfileCompletionSequenceContext } from "./context/profile-completion-sequence-context";
import { useProfileCompletion } from "./hooks/use-profile-completion";
import { About, ProfileCompleted, Role, Skills, WorkExperience } from "./steps";

import styles from "./styles.module.scss";

export const openProfileCompletionSequenceModal = (profile: Freelancer) => {
  ModalInstance.open({
    children: (
      <CompletionSequence profile={profile}>
        <CompletionSequence.About />
        <CompletionSequence.Role />
        <CompletionSequence.Skills />
        <CompletionSequence.WorkExperience />
        <CompletionSequence.ProfileCompleted />
      </CompletionSequence>
    ),
    closeButton: false,
    keepOnBackdropClick: true,
    padding: false,
    className: `${styles.wrapper}`,
  });
};

type CompletionSequenceProps = {
  children: ReactNode;
  profile: Freelancer;
};

const CompletionSequence = ({ children, profile }: CompletionSequenceProps) => {
  const {
    setStepBeingEdited,
    shouldRender,
    shouldCloseProfileCompletionSequence,
  } = useProfileCompletion();

  const memoizedContextValue = useMemo(
    () => ({
      profile,
      setStepBeingEdited,
      shouldRender,
      shouldCloseProfileCompletionSequence,
    }),
    [
      profile,
      setStepBeingEdited,
      shouldRender,
      shouldCloseProfileCompletionSequence,
    ],
  );

  if (!profile) return null;

  if (shouldCloseProfileCompletionSequence) {
    ModalInstance.close();
  }

  return (
    <ProfileCompletionSequenceContext.Provider value={memoizedContextValue}>
      {children}
    </ProfileCompletionSequenceContext.Provider>
  );
};

CompletionSequence.About = About;
CompletionSequence.Role = Role;
CompletionSequence.Skills = Skills;
CompletionSequence.WorkExperience = WorkExperience;
CompletionSequence.ProfileCompleted = ProfileCompleted;
