import cs from "classnames";

import {
  Box,
  Button,
  Carousel,
  Typography,
} from "@hexocean/braintrust-ui-components";
import { FlowerIcon } from "@hexocean/braintrust-ui-components/Icons";
import { WALLET_INFO_CARDS } from "@js/apps/dashboard/constants";
import { RouterLink } from "@js/components/link";
import type { Balance } from "@js/types/common";

import style from "./style.module.scss";

interface InfoCardsProps {
  balance?: Balance;
}

export const InfoCards = ({ balance }: InfoCardsProps) => {
  const userHasEscrowOrUnvestedTokens = Boolean(
    Number(balance?.escrow_tokens) || Number(balance?.unvested_tokens),
  );

  const slides = [
    {
      slideComponent: <Card variant="escrow" key="escrow" />,
      shouldRender: userHasEscrowOrUnvestedTokens,
    },
    {
      slideComponent: <Card variant="feed" key="feed" />,
      shouldRender: true,
    },
    {
      slideComponent: <Card variant="governance" key="governance" />,
      shouldRender: true,
    },
  ];

  const filteredSlides = slides.filter((slide) => slide.shouldRender);

  return (
    <Carousel
      slides={filteredSlides.map(({ slideComponent }) => slideComponent)}
      className={style.infoCardsSlider}
      style={{ height: "100%" }}
      pagination={{
        clickable: true,
      }}
      spaceBetween={16}
    />
  );
};

type CardProps = {
  variant: keyof typeof WALLET_INFO_CARDS;
};

const Card = ({ variant }: CardProps) => {
  const Icon = WALLET_INFO_CARDS[variant].icon;

  return (
    <Box
      className={cs(style.infoCard, {
        [style.infoCardEscrow]: variant === "escrow",
        [style.infoCardFeed]: variant === "feed",
        [style.infoCardGovernance]: variant === "governance",
      })}
    >
      <Box className={style.infoCardHeader}>
        <Box className={style.infoCardHeaderIcon}>
          <FlowerIcon style={{ width: 49, height: 48, color: "white" }} />
          <Icon className={style.infoCardHeaderIconItem} />
        </Box>
        <Typography variant="label" component="h2" size="large">
          {WALLET_INFO_CARDS[variant].header}
        </Typography>
      </Box>
      <Box className={style.infoCardBody}>
        <Typography variant="paragraph" component="p" size="small">
          {WALLET_INFO_CARDS[variant].text}
        </Typography>
        <Button
          shape="squared"
          RouterLink={RouterLink}
          to={WALLET_INFO_CARDS[variant].link}
          target="_blank"
          variant={WALLET_INFO_CARDS[variant].buttonVariant}
          className={style.infoCardButton}
        >
          {WALLET_INFO_CARDS[variant].buttonText}
        </Button>
      </Box>
    </Box>
  );
};
