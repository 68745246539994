import { Button, Typography } from "@hexocean/braintrust-ui-components";
import { useGetNftDetailsQuery } from "@js/apps/nft/api";
import { ModalInstance } from "@js/components/modal";

import { NftShareList } from "../share-list";

export const openMintingSuccessModal = (tokenId: number) =>
  ModalInstance.open({
    closeButton: false,
    padding: false,
    className: "minting-success-modal",
    children: <MintingSuccessModalContent tokenId={tokenId} />,
  });

const MintingSuccessModalContent = ({ tokenId }) => {
  const { data: nftDetails } = useGetNftDetailsQuery(tokenId);

  if (!nftDetails) {
    return null;
  }

  return (
    <>
      <div className="minting-success-modal__text">
        <Typography mb={2.5} variant="title" component="h3" size="medium">
          🎉
        </Typography>
        <Typography
          mb={1.25}
          variant="title"
          component="h3"
          fontWeight={400}
          size="medium"
        >
          Congrats! You minted your Braintrust membership NFT.
        </Typography>
        <Typography mb={2.5} component="p">
          You now have an on-chain membership to Braintrust. You can deposit
          $BTRST to unlock special features, use your Braintrust profile in
          other web3 apps, and more.
        </Typography>
        <Button
          variant="primary"
          size="medium"
          className="minting-success-modal__view-nft-btn"
          onClick={() => ModalInstance.close()}
        >
          View my NFT
        </Button>
        <a
          href={nftDetails?.transaction_link}
          target="_blank"
          rel="noopener noreferrer"
          className="minting-success-modal__transaction-link"
        >
          View Transaction on Etherscan
        </a>
      </div>
      <div className="minting-success-modal__social-wrapper">
        <Typography fontWeight={500} mb={4} component="p">
          Share your NFT
        </Typography>
        <NftShareList tokenId={tokenId} />
      </div>
    </>
  );
};
