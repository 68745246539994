import uuid4 from "uuid4/browser";

import type { TalentMixedLocation } from "@js/apps/freelancer/types";
import type { TypedWrappedFieldProps } from "@js/forms/utils";

type BindSessionTokenToLocation = {
  (parameters: {
    singleLocationData: TalentMixedLocation;
    sessionTokenField: TypedWrappedFieldProps<string[] | null>;
  }): void;
};

//Each ID in place_id field must have a session token, if it does not then it must be generated.
const bindSessionTokenToLocation: BindSessionTokenToLocation = ({
  singleLocationData,
  sessionTokenField,
}) => {
  const sessionTokens = sessionTokenField.input.value
    ? [...sessionTokenField.input.value]
    : [];

  const sessionToken = singleLocationData?.session_token
    ? singleLocationData?.session_token
    : uuid4();

  sessionTokens.push(sessionToken);

  sessionTokenField.input.onChange(sessionTokens);
};

export { bindSessionTokenToLocation };
