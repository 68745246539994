import { Typography } from "@hexocean/braintrust-ui-components";
import { Date } from "@js/components/date";
import type { IsoDateOrIsoDateTime } from "@js/utils/date/types";
import { DateFormats } from "@js/utils/date/types";

export const OfferDate = ({ offer }) => {
  const offerDateByStatus = {
    [ENUMS.OfferStatus.NEW]: { label: "Created", date: offer.created },
    [ENUMS.OfferStatus.UPDATED]: { label: "Updated", date: offer.updated_at },
  };
  const offerDate = offerDateByStatus[offer.status] || {
    label: "",
    date: offer.created,
  };

  return (
    <Typography component="p" textAlign="right" size="medium">
      {offerDate.label}{" "}
      <Date
        date={offerDate.date as IsoDateOrIsoDateTime}
        format={DateFormats["January 1, 1970"]}
      />
    </Typography>
  );
};
