import { useMemo } from "react";

import { useGetOwnJobsQuery } from "@js/apps/jobs/api";

export const useOpenJobsModule = () => {
  const {
    data,
    isLoading,
    isFetching,
    refetch: refetchJobs,
  } = useGetOwnJobsQuery(
    {
      status: ENUMS.JobStatus.OPEN,
    },
    { refetchOnMountOrArgChange: true },
  );

  const withJobActionContextProviderValue = useMemo(
    () => ({
      afterAction: () => refetchJobs(),
    }),
    [refetchJobs],
  );

  const openJobsToDisplay = useMemo(
    () =>
      data && data.results.length > 3
        ? data.results.slice(0, 3)
        : data?.results,
    [data],
  );

  return {
    openJobs: openJobsToDisplay || [],
    totalOpenJobsCount: data?.count || 0,
    isLoading: isLoading || isFetching,
    jobActionContextValue: withJobActionContextProviderValue,
  };
};
