export const checkForMissingResume = (
  fields: Record<string, object | null | undefined>,
  isResumeRequired: boolean,
): boolean => {
  if (!isResumeRequired) {
    return false;
  }

  if (
    isResumeRequired &&
    fields["resume"] !== null &&
    fields["resume"] !== undefined
  ) {
    return false;
  }

  return true;
};

export const checkForMissingRequirementAnswers = (
  fields: Record<string, boolean | null>,
) => {
  return (
    ("timezone_requirement_met" in fields &&
      fields["timezone_requirement_met"] === null) ||
    ("location_requirement_met" in fields &&
      fields["location_requirement_met"] === null)
  );
};
