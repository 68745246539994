import { Typography } from "@hexocean/braintrust-ui-components";

interface StatusLabelProps {
  children: React.ReactNode;
  variant: "success" | "failed" | "pending";
}

export const StatusLabel = ({ children, variant }: StatusLabelProps) => {
  return (
    <Typography
      component="span"
      className={`activity-status activity-status--${variant}`}
    >
      {children}
    </Typography>
  );
};
