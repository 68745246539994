import { useNavigate } from "react-router-dom";
import { Field, Fields } from "redux-form";

import {
  StatusForApplicationQuestions,
  StatusForCategory,
  StatusForExperience,
  StatusForJobDescription,
  StatusForProjectDetails,
  StatusForRate,
  StatusForSkills,
  StatusForTitle,
} from "@js/apps/jobs/apps/create-job/components/summarize-module/statuses";
import { ReviewBeforeSendingSummaryBox } from "@js/apps/jobs/components";
import { ModalInstance } from "@js/components/modal";
import { required } from "@js/forms/validators";

import { useCheckJobsLimit } from "../../hooks/check-jobs-limit";

type StatusFieldsForReviewDraftsModalProps = {
  id: number | undefined;
  isLastJobToPublish: boolean;
};

export const StatusFieldsForReviewDraftsModal = ({
  id,
  isLastJobToPublish,
}: StatusFieldsForReviewDraftsModalProps) => {
  const navigate = useNavigate();
  const { checkJobsLimit } = useCheckJobsLimit();

  const handleEditCTAClick = () => {
    checkJobsLimit({
      onLimitNotExceeded: () =>
        navigate(
          `/jobs/add_new/${id}/set_up/${isLastJobToPublish ? "" : "?back_to_review_modal=true"}`,
          {},
        ),
    });
    ModalInstance.close();
  };

  return (
    <ReviewBeforeSendingSummaryBox style={{ maxWidth: "100%" }}>
      <Field
        name="title"
        component={StatusForTitle}
        validate={[required]}
        onEditClick={handleEditCTAClick}
      />
      <Fields
        names={["role"]}
        component={StatusForCategory}
        validate={{
          role: required,
        }}
        onEditClick={handleEditCTAClick}
      />
      <Fields
        names={[
          "contract_type",
          "expected_hours_per_week",
          "locations",
          "timezones",
        ]}
        component={StatusForProjectDetails}
        validate={{
          contract_type: required,
          expected_hours_per_week: required,
        }}
        onEditClick={handleEditCTAClick}
      />
      <Fields
        names={["role", "new_skills", "top_skills"]}
        component={StatusForSkills}
        validate={{
          new_skills: required,
          top_skills: required,
        }}
        onEditClick={handleEditCTAClick}
      />
      <Field
        name="experience_level"
        component={StatusForExperience}
        validate={[required]}
        onEditClick={handleEditCTAClick}
      />
      <Fields
        names={[
          "hourly_rate",
          "min_rate",
          "max_rate",
          "min_annual_rate",
          "max_annual_rate",
          "fixed_rate",
          "payment_type",
        ]}
        component={StatusForRate}
        onEditClick={handleEditCTAClick}
      />
      <Fields
        names={["description", "introduction"]}
        component={StatusForJobDescription}
        validate={{
          description: required,
        }}
        onEditClick={handleEditCTAClick}
      />
      <Field
        name="new_application_questions"
        component={StatusForApplicationQuestions}
        onEditClick={handleEditCTAClick}
      />
    </ReviewBeforeSendingSummaryBox>
  );
};
