import { Box, Typography } from "@hexocean/braintrust-ui-components";

export const MarkAsPaidModalContent = () => {
  return (
    <Box display="flex" flexDirection="column" gap={2}>
      <Typography component="p" color="negative" fontWeight={500}>
        Warning: Have you already transferred funds to talent?
      </Typography>
      <Typography component="p">
        Please confirm you have already paid this talent directly. If you have
        not, please pay this invoice and payment will be sent to talent.
      </Typography>
    </Box>
  );
};
