import React from "react";

import {
  Loader,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@hexocean/braintrust-ui-components";
import type { InvoiceBatch } from "@js/types/invoices";

import { InvoicesTypeField } from "../../fields";

type InvoiceBatchesViewProps = {
  loading: boolean;
  invoiceBatches: InvoiceBatch[];
};

export const InvoiceBatchesView = ({
  loading,
  invoiceBatches,
}: InvoiceBatchesViewProps): JSX.Element => {
  return (
    <>
      <Typography
        variant="display"
        component="h1"
        size="small"
        fontWeight={400}
        underline="yellow"
        mt={4}
        mb={4}
      >
        Invoices
      </Typography>
      <div className="invoices-listing-toolbar">
        <InvoicesTypeField />
      </div>
      {loading ? (
        <div className="posr">
          <Loader centered />
        </div>
      ) : !invoiceBatches.length ? (
        <Typography component="h2" size="large" fontWeight={400}>
          No invoice batches yet
        </Typography>
      ) : (
        <div className="table-wrapper">
          <Table className="invoices-listing mw-table-call">
            <TableHead>
              <TableRow>
                <TableCell>Frequency</TableCell>
                <TableCell>Period Start</TableCell>
                <TableCell>Period End</TableCell>
                <TableCell>PDF File</TableCell>
                <TableCell>Summary PDF File</TableCell>
                <TableCell>ZIP File</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {invoiceBatches.map((invoice) => {
                return (
                  <TableRow key={invoice.pdf_file.id}>
                    <TableCell>{hasValue(invoice.frequency)}</TableCell>
                    <TableCell>{hasValue(invoice.period_start)}</TableCell>
                    <TableCell>{hasValue(invoice.period_end)}</TableCell>
                    <TableCell>
                      <DownloadURL href={invoice.pdf_file.file} />
                    </TableCell>
                    <TableCell>
                      <DownloadURL href={invoice.summary_page_pdf_file?.file} />
                    </TableCell>
                    <TableCell>
                      <DownloadURL href={invoice.zip_file?.file} />
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </div>
      )}
    </>
  );
};

const hasValue = (value?: string): React.ReactNode => {
  return !!value ? value : "-";
};

const DownloadURL = ({ href }: { href?: string }): JSX.Element => {
  return !!href ? (
    <a download href={href}>
      Download
    </a>
  ) : (
    <>-</>
  ); // wrapped due to type issue
};
