import { useMemo } from "react";

import {
  Box,
  Button,
  Loader,
  Menu,
  Tooltip,
} from "@hexocean/braintrust-ui-components";
import { KeyboardArrowDownIcon } from "@hexocean/braintrust-ui-components/Icons";
import { useGetEmployerInvoicesMinialQuery } from "@js/apps/invoices/api";
import { MarkAsPaidAction } from "@js/apps/invoices/components/mark-as-paid-action";
import type { EmployerInvoice } from "@js/types/invoices";
import { handleDownloadFile } from "@js/utils";

import { PaySelectedAction } from "./pay-selected-action";

export const ACTIONS_SELECTED_INVOICES_LIMIT = 20;

export type EmployerInvoicesGlobalActionsProps =
  EmployerInvoicesActionMenuItemsProps & {
    selectedCount: number;
  };

export const EmployerInvoicesGlobalActions = ({
  selectedCount,
  ...actionsProps
}: EmployerInvoicesGlobalActionsProps) => {
  const isSelectedInvoicesLimitExceeded =
    selectedCount > ACTIONS_SELECTED_INVOICES_LIMIT;

  return (
    <Tooltip
      title={`Limit of ${ACTIONS_SELECTED_INVOICES_LIMIT} selected invoices exceeded. Please unselect some.`}
      disabled={!isSelectedInvoicesLimitExceeded}
    >
      <Box>
        <Menu
          anchor={
            <Button
              variant="secondary"
              endIcon={<KeyboardArrowDownIcon />}
              disabled={selectedCount === 0 || isSelectedInvoicesLimitExceeded}
            >
              Actions
            </Button>
          }
        >
          <EmployerInvoicesActionMenuItems {...actionsProps} />{" "}
        </Menu>
      </Box>
    </Tooltip>
  );
};

type EmployerInvoicesActionMenuItemsProps = {
  selectedInvoicesIds: number[];
  invoiceList: EmployerInvoice[];
  markInvoiceAsPaid: (ids: number[]) => Promise<void>;
  startPaymentFlow: () => void;
};

const EmployerInvoicesActionMenuItems = ({
  selectedInvoicesIds,
  markInvoiceAsPaid,
  startPaymentFlow,
  invoiceList,
}: EmployerInvoicesActionMenuItemsProps) => {
  const { data: selectedInvoices, isFetching } =
    useGetEmployerInvoicesMinialQuery(
      { ids: selectedInvoicesIds },
      { skip: !selectedInvoicesIds.length },
    );

  const invoiceToDownloadPdf = useMemo(() => {
    if (selectedInvoicesIds.length !== 1) {
      return;
    }
    const [selectedInvoiceId] = selectedInvoicesIds;
    return invoiceList.find((invoice) => invoice.id === selectedInvoiceId);
  }, [invoiceList, selectedInvoicesIds]);

  if (isFetching) {
    return (
      <Box sx={{ minHeight: "3rem", position: "relative", minWidth: "138px" }}>
        <Loader centered />
      </Box>
    );
  }

  return (
    <>
      <PaySelectedAction
        selectedInvoices={selectedInvoices ?? []}
        startPaymentFlow={startPaymentFlow}
      />

      <MarkAsPaidAction
        type="menuitem"
        invoices={selectedInvoices ?? []}
        markAsPaid={(invoicesIds) => markInvoiceAsPaid(invoicesIds)}
      >
        Mark as Paid
      </MarkAsPaidAction>
      {!!invoiceToDownloadPdf?.pdf_file?.file && (
        <Menu.Item
          onClick={() => {
            handleDownloadFile({
              endpoint: invoiceToDownloadPdf.pdf_file.file,
              blank: true,
            });
          }}
        >
          Download PDF
        </Menu.Item>
      )}
    </>
  );
};
