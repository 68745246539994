import type { ChangeEvent, MouseEventHandler } from "react";
import { useState } from "react";
import cx from "classnames";

import {
  Box,
  Button,
  Checkbox,
  Typography,
} from "@hexocean/braintrust-ui-components";
import { useUser } from "@js/apps/common/hooks";
import { useCreateStringDependsOnSelectedFilters } from "@js/apps/jobs/apps/listing/hooks/use-create-string-depends-on-selected-filters";
import { JOB_LOCATION } from "@js/apps/jobs/context";
import type { Skill } from "@js/types/admin";
import type { SavedJobFilter } from "@js/types/jobs";

type SavedJobSearchItemProps = {
  savedFilter: SavedJobFilter;
  onApply: () => void;

  onEmailNotificationsToggle: (
    event: ChangeEvent<HTMLInputElement>,
    filterId: number,
    enableNotifications: boolean,
  ) => void;

  isSelected: boolean;
  onRemoveClick: MouseEventHandler;
  processingSavedFilters: boolean;
  filterSkillsData: Skill[];
};

export const SavedJobSearchItem = ({
  savedFilter,
  onApply,
  onEmailNotificationsToggle,
  isSelected,
  onRemoveClick,
  processingSavedFilters,
  filterSkillsData,
}: SavedJobSearchItemProps) => {
  const [isHovering, setIsHovering] = useState(false);

  const { new_job_notification_enabled: jobNotificationEnabled, id: filterId } =
    savedFilter;

  const [checked, setChecked] = useState(jobNotificationEnabled);

  const filtersString = useCreateStringDependsOnSelectedFilters(
    savedFilter.filters,
    JOB_LOCATION.job_listing,
    filterSkillsData,
  );

  const disabled = processingSavedFilters;

  const user = useUser();
  const shouldShowEmailCheckbox = user?.account_type === "freelancer";

  return (
    <Box
      onClick={onApply}
      display="flex"
      flexDirection="column"
      alignItems="flex-start"
      className={cx("saved-search-item", {
        "button-shape--squared": true,
        "button-variant--white-violet": !isHovering,
        selected: isSelected && !disabled,
        "saved-search-item--squared": isSelected && !disabled,
        "MuiButtonBase-root": true,
        "Mui-disabled": disabled,
        "disabled-button": disabled,
        "saved-search-item--disabled": disabled,
      })}
    >
      <Box sx={{ width: "100%", display: "flex", gap: "1.5rem" }}>
        <Box className="saved-search-item__labels" component="span">
          <Typography component="span" fontWeight={500}>
            {savedFilter.name}
          </Typography>
          <Typography component="span" color="tertiary">
            {filtersString}
          </Typography>
        </Box>

        <Button
          onMouseOver={() => setIsHovering(true)}
          onMouseOut={() => setIsHovering(false)}
          className="saved-search-item__remove-button"
          disabled={disabled}
          variant="secondary"
          size="medium"
          onClick={onRemoveClick}
        >
          Remove
        </Button>
      </Box>
      {shouldShowEmailCheckbox && (
        <Box onClick={(event) => event.stopPropagation()}>
          <Checkbox
            checked={checked}
            onClick={() => setChecked(!checked)}
            onChange={(event) =>
              onEmailNotificationsToggle(event, filterId, event.target.checked)
            }
            children="Emailing new matching jobs"
          />
        </Box>
      )}
    </Box>
  );
};
