import { useMemo } from "react";

import { useGetWalletActivityQuery } from "@js/apps/dashboard/api";

import { useWalletActivityQueryArg } from "./use-wallet-activity-query-arg";

type UseWalletActivityFeedParams = {
  limit: number;
};

export const useWalletActivityFeed = ({
  limit,
}: UseWalletActivityFeedParams) => {
  const { queryArg, showAllResults } = useWalletActivityQueryArg({
    limit,
  });

  const {
    isLoading: isLoadingActivityListData,
    currentData: activityListData,
  } = useGetWalletActivityQuery(queryArg);

  const activityList = useMemo(() => {
    return activityListData?.results ?? [];
  }, [activityListData]);

  const isLoading = isLoadingActivityListData || !activityListData;
  const total = activityListData?.overall_total || 0;

  const onShowAllClick = () => {
    if (!total) {
      return;
    }

    showAllResults(total);
  };

  const isShowingAll = !!total && queryArg.limit === total;

  return {
    total,
    activityList,
    isLoading,
    isShowingAll,
    onShowAllClick,
  };
};
