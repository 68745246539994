import React from "react";

import { Stack } from "@hexocean/braintrust-ui-components";
import { CtaClickedProvider } from "@js/apps/common/context";
import { useHashtagSearchParam } from "@js/apps/common/hooks/use-hashtag-search-params";
import { EditPostModalInstance } from "@js/apps/give-and-get-help/components/create-or-edit-post-modal/modal/modal-instances";
import { CreatePostSection } from "@js/apps/give-and-get-help/components/create-post";
import { PostLocation } from "@js/apps/give-and-get-help/context/post-location";
import { useMarkCareerHelpVisited } from "@js/apps/give-and-get-help/hooks";
import { UniversalSearchContextProvider } from "@js/apps/universal-search/context/universal-search-context";
import { AppLayout } from "@js/layouts/app";

import { FeedHashtagHeader, FeedHeader } from "../../components";
import { FeedPostsSection } from "../../components/feed-posts-section";

import styles from "./styles.module.scss";

export const FeedPage = () => {
  useMarkCareerHelpVisited();

  const hashtagId = useHashtagSearchParam();

  const postLocationValue = hashtagId
    ? PostLocation.Values.hashtag
    : PostLocation.Values.feed;

  return (
    <UniversalSearchContextProvider initialTab="CAREER_HELP">
      <PostLocation.Provider value={postLocationValue}>
        <CtaClickedProvider
          location={ENUMS.UserClicksEventCTALocation.DASHBOARD}
        >
          <AppLayout
            bgcolor="var(--soft-green)"
            pageTitle="Feed"
            className={styles.feedPage}
          >
            <Stack
              sx={{
                mx: "auto",
                px: { sm: 2 },
                maxWidth: "944px",
                width: "100%",
                justifyItems: "center",
              }}
            >
              <Stack sx={{ px: { xs: 2, sm: 0 } }}>
                {hashtagId ? (
                  <FeedHashtagHeader hashtagId={hashtagId} />
                ) : (
                  <FeedHeader showGetInspiredButton />
                )}

                <CreatePostSection showCreatePostFAB />
              </Stack>
              <FeedPostsSection />
            </Stack>
            <EditPostModalInstance />
          </AppLayout>
        </CtaClickedProvider>
      </PostLocation.Provider>
    </UniversalSearchContextProvider>
  );
};
