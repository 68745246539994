import React from "react";

import { useAccountType } from "@js/apps/common/hooks";
import { useCurrentFreelancerProfileCompletion } from "@js/apps/freelancer/hooks/use-current-freelancer-profile-completion";
import type { FreelancerBid, Job } from "@js/types/jobs";

import type { StatusBoxProps } from "./components";
import { CompleteProfileStatusBox } from "./components";
import { EmployerStatus } from "./employer";
import { TalentStatus } from "./talent";

type StatusProps = Pick<StatusBoxProps, "variant" | "longTile" | "fullSize"> & {
  job: Job | null;
  bid: FreelancerBid | undefined;
  className?: string;
};

export const Status = ({
  job,
  bid,
  variant,
  longTile,
  className,
  ...props
}: StatusProps) => {
  const { isFreelancer, isEmployer, isFreelancerApproved } = useAccountType();
  const { data: profileCompletion, isLoading: isLoadingProfileCompletion } =
    useCurrentFreelancerProfileCompletion();

  const isProfileCompleted = !!profileCompletion?.profile_completion_hit_at;

  if (isLoadingProfileCompletion) {
    return <></>;
  }

  if (isFreelancer && !isFreelancerApproved && !isProfileCompleted) {
    return <CompleteProfileStatusBox longTile={longTile} {...props} />;
  }

  if (isEmployer) {
    return (
      <EmployerStatus
        job={job}
        variant={variant}
        className={className}
        {...props}
      />
    );
  }

  return (
    <TalentStatus
      job={job}
      bid={bid}
      variant={variant}
      className={className}
      {...props}
    />
  );
};
