import type { ChangeEvent } from "react";

import {
  Box,
  Button,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@hexocean/braintrust-ui-components";
import { SingleInvoiceActions } from "@js/apps/invoices/components/single-invoice-actions";
import { useEmployerInvoiceListingContext } from "@js/apps/invoices/context/employer-invoice-listing";
import { useInvoiceTableComponent } from "@js/apps/invoices/hooks/invoice-table-component";
import { TableSortLabelField } from "@js/components/table";
import type { EmployerInvoice } from "@js/types/invoices";
import { dateToFromNowDaily, formatCurrency, getEnumLabel } from "@js/utils";

const SORTABLE_CELLS = [
  { key: 1, align: "left", orderBy: "payee_name", label: "Payee" },
  { key: 2, align: "center", orderBy: "number", label: "Invoice Number" },
  { key: 3, align: "right", orderBy: "issued_at", label: "Date" },
  { key: 4, align: "right", orderBy: "due_date", label: "Due" },
  { key: 5, align: "right", orderBy: "_gross_total", label: "Amount" },
] as const;

export type InvoiceTableProps = {
  openInvoicePreview: (invoice: EmployerInvoice) => void;
  startPaymentFlow: () => void;
  handleSingleInvoicePayment: (
    invoice: EmployerInvoice,
    pay: () => void,
  ) => void;
  markInvoiceAsPaid: (ids: number[]) => Promise<void>;
};

export const InvoiceTable = ({
  openInvoicePreview,
  startPaymentFlow,
  handleSingleInvoicePayment,
  markInvoiceAsPaid,
}: InvoiceTableProps) => {
  const {
    onSelectAcrossAllPagesClick,
    onSelectItem,
    invoiceList,
    toggleSelectCurrentPage,
    checkIfItemIsSelected,
    totalInvoicesCount,
  } = useEmployerInvoiceListingContext();

  const {
    showSelectInvoicesOnAllPages,
    isAnySelectedOnCurrentPage,
    areAllInvoicesAcrossAllPagesSelected,
  } = useInvoiceTableComponent();

  const handleSelectAllCheckboxClick = (
    event: ChangeEvent<HTMLInputElement>,
  ) => {
    const isCheckboxChecked = event.target.checked;
    toggleSelectCurrentPage(isCheckboxChecked);
  };

  return (
    <div className="table-wrapper">
      <Box visibility={showSelectInvoicesOnAllPages ? "visible" : "hidden"}>
        <Button
          variant="transparent-blue"
          aria-label={`${areAllInvoicesAcrossAllPagesSelected ? "Unselect" : "Select"} all (${totalInvoicesCount}) invoices across all pages`}
          onClick={() =>
            onSelectAcrossAllPagesClick(
              areAllInvoicesAcrossAllPagesSelected ? "unselect" : "select",
            )
          }
        >
          {areAllInvoicesAcrossAllPagesSelected ? "Unselect" : "Select"} all (
          {totalInvoicesCount}) invoices across all pages
        </Button>
      </Box>
      <Table className="invoices-listing mw-table-call">
        <TableHead>
          <TableRow>
            <TableCell padding="checkbox">
              <Checkbox
                variant="bold"
                noMargin
                inputProps={{ "aria-label": `select all invoices` }}
                onChange={handleSelectAllCheckboxClick}
                checked={isAnySelectedOnCurrentPage}
              />
            </TableCell>
            <TableCell>Status</TableCell>
            {SORTABLE_CELLS.map(({ key, align, ...rest }) => (
              <TableCell key={key} align={align}>
                <TableSortLabelField key={key} {...rest} />
              </TableCell>
            ))}
            <TableCell
              className="invoices-listing-row"
              width="100"
              align="center"
            >
              Actions
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {invoiceList.map((invoice) => {
            return (
              <TableRow
                tabIndex={0}
                onKeyDown={(ev) => {
                  const target = ev.target as HTMLElement;
                  const isTableRow = target.tagName === "TR";

                  if (!ev.shiftKey && ev.key === "Enter" && isTableRow) {
                    ev.preventDefault();
                    openInvoicePreview(invoice);
                  }
                }}
                onClick={() => openInvoicePreview(invoice)}
                className="invoices-listing-item"
                key={invoice.id}
              >
                <TableCell
                  onClick={(ev) => ev.stopPropagation()}
                  className="invoice-checkbox"
                  padding="checkbox"
                >
                  <Checkbox
                    variant="bold"
                    noMargin
                    inputProps={{
                      "aria-label": `select invoice ${invoice.number}`,
                    }}
                    checked={checkIfItemIsSelected(invoice.id)}
                    onClick={() => onSelectItem(invoice.id)}
                  />
                </TableCell>
                <TableCell
                  className={`invoices-listing-item-status ${invoice.status}`}
                >
                  {getEnumLabel(ENUMS.InvoiceStatusLabels, invoice.status)}
                </TableCell>
                <TableCell>{invoice.payee_name}</TableCell>
                <TableCell className="gray bw">{invoice.number}</TableCell>
                <TableCell align="right" className="gray">
                  {invoice.issued_at}
                </TableCell>
                <TableCell align="right" className={`gray ${invoice.status}`}>
                  {dateToFromNowDaily(invoice.due_date)}
                </TableCell>
                <TableCell align="right">
                  {formatCurrency(invoice.gross_total)}
                </TableCell>
                <TableCell
                  onClick={(ev) => ev.stopPropagation()}
                  className="invoices-listing-row"
                >
                  <SingleInvoiceActions
                    invoice={invoice}
                    startPaymentFlow={startPaymentFlow}
                    handleSingleInvoicePayment={handleSingleInvoicePayment}
                    markInvoiceAsPaid={markInvoiceAsPaid}
                  />
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </div>
  );
};
