import { useCallback, useEffect, useMemo, useState } from "react";
import uuid4 from "uuid4/browser";

import { useUser } from "@js/apps/common/hooks";
import { WebSocketManagerContainer } from "@js/components/websocket";
import { useWebsocket } from "@js/hooks";
import type { PostCategory } from "@js/types/give-and-get-help";
import { formatDate, getToday, isNumeric } from "@js/utils";

type sendMessageFunCallbacks = {
  onSent?: () => void;
  onDelivered?: () => void;
  onCannotSend?: () => void;
};

type UseSendMessageType = {
  room?: number;
  canSendMessage?: boolean;
};

const USE_SEND_MESSAGE_LISTENER_ID = "use-send-message-listener";

export const useSendMessage = ({
  room,
  canSendMessage,
}: UseSendMessageType) => {
  const user = useUser();
  const { send, isRunning } = useWebsocket();
  const [messageError, setMessageError] = useState(null);

  const subscribers = useMemo(() => ({}), []);

  const notify = useCallback(
    ({ data }) => {
      if (data.broadcast_type !== ENUMS.BroadcastType.MESSAGE_STATUS) {
        return;
      }

      if (data.error_message) {
        setMessageError(data.error_message);
      } else if (messageError) {
        setMessageError(null);
      }
      Object.keys(subscribers).forEach((key) => subscribers[key](data));
    },
    [messageError, subscribers],
  );

  useEffect(() => {
    WebSocketManagerContainer.attachListener(
      notify,
      USE_SEND_MESSAGE_LISTENER_ID,
    );

    return () => {
      WebSocketManagerContainer.removeListener(USE_SEND_MESSAGE_LISTENER_ID);
    };
  }, [notify]);

  useEffect(() => {
    setMessageError(null);
  }, [room]);

  const canSend =
    typeof canSendMessage === "boolean"
      ? canSendMessage
      : !!user && !!isRunning && !!room;

  const sendMessage = (
    content: {
      message?: string;
      budget?: string;
      category?: PostCategory["id"];
      calendar_link?: string;
      attachment_ids?: number[];
    },
    callbacks?: sendMessageFunCallbacks,
    authorId?: number,
  ) => {
    const {
      onSent = () => {
        /* empty  */
      },
      onDelivered = () => {
        /* empty  */
      },
      onCannotSend = () => {
        /* empty  */
      },
    } = callbacks || {};

    if (
      (canSend && content.message && content.message.trim().length > 0) ||
      (isNumeric(content.budget) && content.category !== undefined) ||
      content.calendar_link?.trim() ||
      (content.attachment_ids && content.attachment_ids?.length > 0)
    ) {
      const messageMock = createMessageMock(content);
      const id = messageMock.optimistic_id;

      const listener = (payload) => {
        if (payload.optimistic_id === id) {
          onDelivered();
          delete subscribers[messageMock.optimistic_id];
        }
      };

      subscribers[id] = listener;

      send(ENUMS.StreamType.MESSAGE, {
        room: messageMock.room,
        message_content: messageMock.content.message || null,
        budget: messageMock.content.budget,
        category: messageMock.content.category,
        calendar_link: messageMock.content.calendar_link,
        attachments_ids: messageMock.content.attachment_ids,
        optimistic_id: id,
        author: authorId,
      });

      onSent();
    }

    if (!canSend) {
      onCannotSend();
    }
  };

  const createMessageMock = useCallback(
    (content) => {
      const uuid = uuid4();

      return {
        id: uuid,
        room,
        author: user,
        content,
        sent: formatDate(getToday(), undefined),
        created: null,
        optimistic_id: uuid,
      };
    },
    [user, room],
  );

  return {
    sendMessage,
    canSendMessage: canSend,
    messageError,
  };
};
