import { Box } from "@hexocean/braintrust-ui-components";
import { MyWorkBaseCard } from "@js/apps/freelancer/components";
import {
  BasicJobInfo,
  PaymentAmount,
  ViewJobButton,
} from "@js/apps/freelancer/components/my-work-base-component/components";
import type { Job } from "@js/types/jobs";

type ListItemProps = {
  job: Job;
};

export const ListItem = ({ job }: ListItemProps) => {
  return (
    <MyWorkBaseCard
      job={job}
      bottomSection={<BasicJobInfo job={job} />}
      rightSection={
        <Box
          display="flex"
          flexDirection="column"
          alignItems="flex-end"
          height="100%"
          justifyContent="center"
          gap={1}
          minWidth="160px"
        >
          <PaymentAmount
            paymentAmount={job.payment_amount}
            paymentType={job.payment_type}
          />
          <ViewJobButton jobId={job.id} />
        </Box>
      }
    />
  );
};
