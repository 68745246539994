import { useNavigate } from "react-router-dom";

import { Box, Button, Typography } from "@hexocean/braintrust-ui-components";
import { ModalInstance } from "@js/components/modal";

export const BoostSuccessModal = () => {
  const navigate = useNavigate();

  const topImageSection = () => {
    return (
      <Box>
        <Box>
          <img
            src={`${SETTINGS.STATIC_URL}boostmodal/boostmodalwithbtrstbanner.svg`}
            alt="BTRST Banner"
          />
        </Box>
      </Box>
    );
  };

  const footer = () => {
    return (
      <Box display={"flex"} py={1}>
        <Box mr={2}>
          <img
            src={`${SETTINGS.STATIC_URL}boostmodal/increaseBoost.png`}
            alt="BTRST Banner"
          />
        </Box>
        <Box>
          <Typography component="p" size="small" fontWeight={400}>
            Boost your job applications to increase your chances of getting
            hired.
          </Typography>
        </Box>
      </Box>
    );
  };
  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      justifyContent={"center"}
      py={2}
      px={3}
      maxWidth={"365px"}
      id="boost-success-modal"
    >
      <Box>
        {" "}
        <Typography
          component="h2"
          variant="label"
          size="medium"
          fontWeight={500}
          mt={1}
        >
          Application Boost activated
        </Typography>
      </Box>
      <Box pt={3}>{topImageSection()}</Box>
      <Box>
        <Typography
          component="p"
          variant="paragraph"
          size="medium"
          fontWeight={400}
          mt={1}
        >
          Congratulations! Your Application Boosts are now ready
        </Typography>
      </Box>
      <Box>
        <Typography
          component="p"
          variant="paragraph"
          size="small"
          fontWeight={400}
          mt={1}
        >
          What’s next?
        </Typography>
      </Box>
      <Box>{footer()}</Box>
      <Box display={"flex"} justifyContent={"end"}>
        <Button
          onClick={() => {
            ModalInstance.close();
            navigate("role-jobs");
          }}
          variant="positive"
          sx={{
            height: "42px",
            borderRadius: "10px !important",

            // width: "10%",
          }}
        >
          See open jobs
        </Button>
      </Box>
    </Box>
  );
};
