import { COMPLETION_NUDGE_PARAMS } from "@js/apps/freelancer/constants";
import { useAppSelector } from "@js/hooks";
import type { ProfileCompletionAreasMap } from "@js/types/freelancer";

const completionAreas = ENUMS.ProfileCompletionArea;

export const useGetCompletionAreaToEditMap = (): ProfileCompletionAreasMap<{
  link: string;
}> => {
  const freelancerId = useAppSelector((state) => state.auth.user?.freelancer);
  const baseURL = `/talent/${freelancerId}`;

  return {
    [completionAreas.FreelancerRoleCompletionDetail]: {
      link: `${baseURL}/?${COMPLETION_NUDGE_PARAMS.YEARS_OF_EXPERIENCE}=true`,
    },
    [completionAreas.FreelancerSkillCompletionDetail]: {
      link: `${baseURL}/?${COMPLETION_NUDGE_PARAMS.SKILLS}=true`,
    },
    [completionAreas.FreelancerSuperPowersCompletionDetail]: {
      link: `${baseURL}/?${COMPLETION_NUDGE_PARAMS.SKILLS}=true`,
    },
    [completionAreas.FreelancerWorkExperienceCompletionDetail]: {
      link: `${baseURL}/?${COMPLETION_NUDGE_PARAMS.WORK_EXPERIENCE}=true`,
    },
    [completionAreas.FreelancerFullNameCompletionDetail]: {
      link: `${baseURL}/?${COMPLETION_NUDGE_PARAMS.GENERAL_INFO}=true`,
    },
    [completionAreas.FreelancerLocationCompletionDetail]: {
      link: `${baseURL}/?${COMPLETION_NUDGE_PARAMS.GENERAL_INFO}=true`,
    },
  };
};
