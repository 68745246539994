import { useAppDispatch, useAppSelector } from "@js/hooks";

import {
  deselectAllJobs,
  getSelectedATSJobsIds,
  selectAllJobs,
} from "../../ats-jobs-slice";

export const useSelectedATSJobs = () => {
  const dispatch = useAppDispatch();
  const selectedJobsIds = useAppSelector(getSelectedATSJobsIds);

  const deselectJobs = () => {
    dispatch(deselectAllJobs());
  };

  const selectJobs = (jobIds: string[]) => {
    return dispatch(selectAllJobs(jobIds));
  };

  return {
    deselectJobs,
    selectJobs,
    selectedJobsIds,
  };
};
