import type { TextNode } from "lexical";
import { $getSelection, $isRangeSelection } from "lexical";

type splitNodeProps = {
  cursorPosition: number;
  hashtagPosition: number;
  query: string;
};

export const splitNode = ({
  cursorPosition,
  hashtagPosition,
  query,
}: splitNodeProps): TextNode | null => {
  const selection = $getSelection();

  if (!$isRangeSelection(selection) || !selection.isCollapsed()) {
    return null;
  }

  const anchor = selection.anchor;

  if (anchor.type !== "text") {
    return null;
  }

  const anchorNode = anchor.getNode();

  // position of existing complete query text (hashtag)
  const position = anchorNode
    .getTextContent()
    .toLowerCase()
    .indexOf(query.toLowerCase(), hashtagPosition);

  const existingTag = newNodeFromExistingTag(anchorNode, position, query);

  if (existingTag) return existingTag;

  return newNodeFromIncompleteTag(anchorNode, cursorPosition, hashtagPosition);
};

const newNodeFromExistingTag = (
  anchorNode: TextNode,
  position: number,
  query: string,
): TextNode | null => {
  if (position === 0) {
    const [newNode] = anchorNode.splitText(query.length);
    return newNode;
  } // when at the begginning of input

  if (position > 0) {
    const [, partWithHashtag] = anchorNode.splitText(position);
    const [newNode] = partWithHashtag.splitText(query.length);
    return newNode;
  }
  // for position -1
  return null;
};

const newNodeFromIncompleteTag = (
  anchorNode: TextNode,
  cursorPosition: number,
  hashtagPosition: number,
): TextNode => {
  if (hashtagPosition === 0) {
    const [newNode] = anchorNode.splitText(cursorPosition);
    return newNode;
  } // when at the begginning of input;

  const [, newNode] = anchorNode.splitText(hashtagPosition, cursorPosition);
  return newNode;
};
