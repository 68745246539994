import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { Box, Typography } from "@hexocean/braintrust-ui-components";
import { useMint } from "@js/apps/nft/hooks";
import { ColorSwitchingLoader } from "@js/components/color-switching-loader";
import { Modal } from "@js/components/modal";

import { openMintingSuccessModal } from "../minting-success-modal";
import { Video } from "../nft-video-img";

const MintingModalInstance = Modal("minting-modal", {
  closeButton: false,
  keepOnBackdropClick: true,
  disableEscapeKeyDown: true,
  closeButtonProps: {
    style: {
      backgroundColor: "rgba(var(--black-rgb), 0.04)",
      width: 36,
      height: 36,
      margin: "11px 11px 0 0",
    },
  },
});

export const MintingModal: React.FC = () => {
  const { tokenId, status, mintingHashUrl } = useMint();
  const navigate = useNavigate();

  useEffect(() => {
    if (status === ENUMS.NFTMintingStatus.MINTED) {
      MintingModalInstance.close();
      navigate(`/nft/${tokenId}/`);
      openMintingSuccessModal(Number(tokenId));
    }
    if (status === ENUMS.NFTMintingStatus.MINTING) {
      MintingModalInstance.open();
    }
  }, [navigate, status, tokenId, mintingHashUrl]);

  return (
    <MintingModalInstance>
      <MintingContent mintingHashUrl={mintingHashUrl} />
    </MintingModalInstance>
  );
};

type OwnProps = {
  mintingHashUrl: string | null;
};

const MintingContent = ({ mintingHashUrl }: OwnProps) => {
  return (
    <Box
      px={1.5}
      py={4}
      display="flex"
      flexDirection="column"
      alignItems="center"
    >
      <Typography
        component="p"
        variant="title"
        fontWeight={400}
        mb={1}
        size="medium"
      >
        ✨ Minting in progress...
      </Typography>
      This process might take a few minutes.
      {!!mintingHashUrl && (
        <a
          href={mintingHashUrl}
          target="_blank"
          rel="noopener noreferrer"
          className="minting-progress-modal__transaction-link"
        >
          View Progress on Etherscan
        </a>
      )}
      <Box position="relative" mt={3}>
        <ColorSwitchingLoader />
        <Video
          src={`${SETTINGS.STATIC_URL}nft/nft.mp4`}
          style={{
            height: "468px",
            borderRadius: "24px",
          }}
        />
      </Box>
    </Box>
  );
};
