import { useEffect, useState } from "react";
import type { TypedWrappedFieldProps } from "redux-form";

import { useAppDispatch } from "@js/hooks";

import { EDIT_POST_FORM_ID, EDIT_REPLY_FORM_ID } from "../../form/constants";

type useOnUserStartedTypingArgs = {
  /** @param type pass null if you don't want to trigger event */
  action: {
    type: string | null;
    payload?: unknown;
  };
} & TypedWrappedFieldProps<string>;

export const useOnUserStartedTyping = ({
  input,
  meta,
  action,
}: useOnUserStartedTypingArgs) => {
  const dispatch = useAppDispatch();
  const [isValueOnMountEqualInitialValue] = useState(
    areTheSame(input.value, meta.initial),
  );
  const [alreadyDispatched, setAsDispatched] = useState(isEditForm(meta.form));
  useEffect(() => {
    if (
      action.type !== null &&
      meta.dirty &&
      !alreadyDispatched &&
      isValueOnMountEqualInitialValue // to make sure it is not triggered again when going back from step 2 to step 1
    ) {
      setAsDispatched(true); // to avoid dispatching in case when user deletes whole input and starts typing again
      dispatch(action);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meta.dirty]);
};

const areTheSame = (value1, value2) => {
  if (!value1 && !value2) {
    // '' and undefined
    return true;
  }

  return value1 === value2;
};

const isEditForm = (formId: string): boolean => {
  // we don't want to trigger events in edit mode;
  if (
    formId.startsWith(EDIT_POST_FORM_ID) ||
    formId.startsWith(EDIT_REPLY_FORM_ID)
  )
    return true;
  return false;
};
