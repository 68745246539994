import type { ElementRef } from "react";
import { useEffect, useRef } from "react";
import type { WrappedFieldProps } from "redux-form";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import {
  $createTextNode,
  COMMAND_PRIORITY_NORMAL,
  COPY_COMMAND,
} from "lexical";

import type { Hashtag } from "@js/apps/give-and-get-help/types";

import { $getRoot } from "../..";

import { HashtagMenu } from "./components/hashtag-menu";
import { $createCustomHashtagNode } from "./components/hashtag-node";
import { useHashtagCollection } from "./hooks/use-hashtag-collection";
import { useHashtagMutation } from "./hooks/use-hashtag-mutation";
import { useInitHashtags } from "./hooks/use-init-hashtags";
import { useSelectedHashtag } from "./hooks/use-selected-hashtag";
import { splitNode } from "./utils/split-node";

export const HashtagPlugin = ({ input }: WrappedFieldProps) => {
  const anchorRef = useRef<ElementRef<"div">>(null);

  const [editor] = useLexicalComposerContext();
  const editorState = editor.getEditorState();

  const inputTextRef = useRef("");
  const setInputText = (value: string) => (inputTextRef.current = value);

  const getEditorElement = () => {
    const editorSelector = ".editor-input";

    return anchorRef.current?.parentElement?.querySelector?.(editorSelector);
  };

  const { isHashtagActive, hashtag, getAnchorElement, getCursorPosition } =
    useSelectedHashtag({
      editorElement: getEditorElement(),
      editor: editor,
    });

  const {
    addHashtagsByNames,
    removeHashtagsByNames,
    searchHashtags,
    hashtagPropositions,
    isLoading,
    addedHashtags,
  } = useHashtagCollection({ input });

  const handleHashtagClick = () => {
    // Do nothing.
    // PostHashtagId does not exists at this stage, so we can't generate valid url without it
    return;
  };

  useHashtagMutation({
    editor,
    onHashtagsCreated: addHashtagsByNames,
    onHashtagsRemoved: removeHashtagsByNames,
    onHashtagClick: handleHashtagClick,
  });

  useEffect(() => {
    editorState.read(() => {
      const root = $getRoot();
      const textContent = root.getTextContent();

      setInputText(textContent);
    });
  }, [editor, editorState]);

  useEffect(() => {
    searchHashtags(hashtag);
  }, [hashtag, searchHashtags]);

  useEffect(() => {
    // without this, we are unable to copy text when hashtags are present
    return editor.registerCommand(
      COPY_COMMAND,
      () => {
        return true;
      },
      COMMAND_PRIORITY_NORMAL,
    );
  }, [editor]);

  const handleSelect = (selectedOption: Hashtag) => {
    editor.update(() => {
      const node = $createCustomHashtagNode(selectedOption.name);
      const cursorPosition = getCursorPosition();

      const textNodeContainingQuery = splitNode({
        cursorPosition: cursorPosition,
        hashtagPosition: cursorPosition - hashtag.length,
        query: selectedOption.name,
      });
      if (!textNodeContainingQuery) {
        return;
      }

      textNodeContainingQuery.replace(node);
      const nextSibling = node.getNextSibling();

      // if next sibling already has space before, select beggining of next node
      if (nextSibling?.getTextContent().startsWith(" ")) {
        nextSibling.selectNext(1, 1);
      } else {
        const spaceNode = $createTextNode(" ");

        node.insertAfter(spaceNode);
        spaceNode.select();
      }
    });
  };

  useInitHashtags({ hashtags: addedHashtags });

  return (
    <>
      <div ref={anchorRef}></div>
      <HashtagMenu
        anchorElement={getAnchorElement()}
        onSelect={handleSelect}
        isLoading={isLoading}
        currentHashtagText={hashtag}
        isHashtagActive={isHashtagActive}
        hashtags={hashtagPropositions}
      />
    </>
  );
};
