import { useMemo } from "react";
import type { TypedWrappedFieldProps } from "redux-form";
import { Field } from "redux-form";

import { Box, TextField } from "@hexocean/braintrust-ui-components";
import { EmployerSearchComboboxField } from "@js/components/autocomplete-new/combobox/employer";
import { UserSearchComboboxField } from "@js/components/autocomplete-new/combobox/user";
import { required } from "@js/forms/validators";

import { POST_JOB_FOR_CLIENT_FIELDS } from "../constants";

export const EmployerAndHiringManagerField = ({ employer, hiring_manager }) => {
  return (
    <>
      <Box mb={2}>
        <Field
          validate={[required]}
          name={POST_JOB_FOR_CLIENT_FIELDS.employer}
          component={EmployerField}
          onChange={() => {
            hiring_manager.input.onChange(null);
          }}
        />
      </Box>
      <Box mb={2}>
        <Field
          validate={[required]}
          name={POST_JOB_FOR_CLIENT_FIELDS.hiring_manager}
          component={HiringManagerField}
          selectedEmployer={employer.input.value}
        />
      </Box>
    </>
  );
};

const EmployerField = (props) => {
  return (
    <EmployerSearchComboboxField
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            label="Select client"
            size="medium"
            placeholder="Select client"
          />
        );
      }}
      {...props}
    />
  );
};

type HiringManagerFieldProps = TypedWrappedFieldProps & {
  selectedEmployer: number;
};
const HiringManagerField = ({
  selectedEmployer,
  input,
  meta,
}: HiringManagerFieldProps) => {
  const endpointParams = useMemo(() => {
    return {
      hiring_manager: true,
      page_size: 100,
      for_employer: selectedEmployer,
    };
  }, [selectedEmployer]);

  return (
    <UserSearchComboboxField
      key={selectedEmployer} // to make sure every employer has unique options
      input={input}
      meta={meta}
      endpointParams={endpointParams}
      disabled={!selectedEmployer}
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            size="medium"
            label="Add hiring manager"
            placeholder="Select Hiring Manager"
            error={(meta.touched || meta.submitFailed) && meta.error}
          />
        );
      }}
    />
  );
};
