import type { ProviderContext } from "notistack";
import { useSnackbar } from "notistack";

// eslint-disable-next-line @typescript-eslint/no-unused-vars, import/no-mutable-exports
export let useSnackbarRef: ProviderContext;

export const SnackbarUtilsConfigurator = () => {
  useSnackbarRef = useSnackbar();
  return null;
};
