import { actionTypes } from "redux-form";
import _ from "underscore";

import { Snackbar } from "../components/snackbar";

let reduxFormFileUploadInterval: NodeJS.Timeout;

export const reduxFormFileUploadMiddleware = (store) => (next) => (action) => {
  if (action.type !== actionTypes.SUBMIT) {
    return next(action);
  }
  const isUploading = store.getState().uploads.uploading;
  const uploadsStartedFileIds = store.getState().uploads.uploadsStartedFileIds;

  if (!isUploading) {
    return next(action);
  }

  Snackbar.info("File upload not completed - please wait.");
  clearInterval(reduxFormFileUploadInterval);

  reduxFormFileUploadInterval = setInterval(() => {
    const isStillUploading = store.getState().uploads.uploading;
    if (isStillUploading) {
      return;
    }

    clearInterval(reduxFormFileUploadInterval);
    const uploadsSucceededFileIds = store
      .getState()
      .uploads.uploadsSucceeded.map(({ fileId }) => fileId);

    const remainingFilesToUpload = _.without(
      uploadsStartedFileIds,
      ...uploadsSucceededFileIds,
    );

    if (!remainingFilesToUpload.length) {
      return next(action);
    }
  }, 200);
};
