import { useNavigate } from "react-router-dom";

import { useUser } from "@js/apps/common/hooks";
import { useGetOrCreateMessageRoomMutation } from "@js/apps/messenger/api";
import { Snackbar } from "@js/components/snackbar";

export type UseMessageUserConfig = {
  receiverUserId: number;
  initialMessage?: string;
};

export const useHelpServicesMessageUser = ({
  receiverUserId,
  initialMessage,
}: UseMessageUserConfig) => {
  const user = useUser();
  const navigate = useNavigate();
  const [getOrCreateMessageRoom] = useGetOrCreateMessageRoomMutation();

  const navigateToMessenger = () => {
    if (!user) return;
    return getOrCreateMessageRoom({
      context: {
        participants: [user?.id, receiverUserId],
        initiated_by_help_service_request: true, // bypass dm limit
      },
    })
      .unwrap()
      .then((room) => {
        const commonParams = {
          "room-id": room.id.toString(),
          textboxPlaceholder: "Tell them what you need help with...",
        };
        const params = initialMessage
          ? {
              ...commonParams,
              initialMessage,
            }
          : commonParams;

        const stringifyParams = new URLSearchParams(params).toString();

        navigate(`/messages?${stringifyParams}`);
      })
      .catch(() => {
        Snackbar.error("Cannot open messenger");
      });
  };

  return {
    navigateToMessenger,
  };
};
