import { Box, Button, Typography } from "@hexocean/braintrust-ui-components";
import { RouterLink } from "@js/components/link";

export type CardTypeItem = keyof typeof firstStepsCardTypesData;

type FirstStepsCardProps = {
  type: CardTypeItem;
  isMobile?: boolean;
};

export const firstStepsCardTypesData = {
  job: {
    title: "Find your next gig",
    cta: "Browse jobs",
    link: "/jobs",
    bgcolor: "var(--soft-orange)",
    bgimg: `${SETTINGS.STATIC_URL}dashboard/first-step-card-pattern-3.svg`,
  },
  community: {
    title: "Give and get career help",
    cta: "Browse posts",
    link: "/feed",
    bgcolor: "var(--soft-violet)",
    bgimg: `${SETTINGS.STATIC_URL}dashboard/first-step-card-pattern-2.svg`,
  },
  earn: {
    title: "Refer and earn",
    cta: "Earn today",
    link: "/talent/dashboard/earn/",
    bgcolor: "var(--soft-green)",
    bgimg: `${SETTINGS.STATIC_URL}dashboard/first-step-card-pattern-1.svg`,
  },
} as const;

export const FirstStepsCard = ({ type, isMobile }: FirstStepsCardProps) => {
  const { title, cta, link, bgcolor, bgimg } = firstStepsCardTypesData[type];
  const titleVariant = isMobile ? "paragraph" : "title";
  const titleSize = isMobile ? "large" : "small";

  return (
    <Box
      className="first-steps-card"
      bgcolor={bgcolor}
      sx={{ backgroundImage: `url(${bgimg})` }}
    >
      <Typography
        component="span"
        variant={titleVariant}
        size={titleSize}
        fontWeight={400}
      >
        {title}
      </Typography>
      <Button
        variant="primary"
        shape="squared"
        size="small"
        RouterLink={RouterLink}
        to={link}
      >
        {cta}
      </Button>
    </Box>
  );
};
