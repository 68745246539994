import { useMemo } from "react";

import type { CreatableComboBoxProps } from "@hexocean/braintrust-ui-components";
import { CreatableComboBox } from "@hexocean/braintrust-ui-components";

import { useGetDegreesQuery } from "../../api";
import { useSearchPhrase } from "../../hooks/search-phrase";

export type DegreesCreatableComboboxProps = Omit<
  CreatableComboBoxProps<false>,
  "options"
>;

export const DegreesCreatableCombobox = (
  props: DegreesCreatableComboboxProps,
) => {
  const { onInputChange, searchPhrase } = useSearchPhrase();

  const { data } = useGetDegreesQuery({
    search: searchPhrase,
    limit: 100,
  });

  const options = useMemo(() => {
    return (data || []).map((opt) => ({
      value: opt.name,
      label: opt.name,
    }));
  }, [data]);

  return (
    <CreatableComboBox
      onInputChange={onInputChange}
      options={options}
      {...props}
    />
  );
};
