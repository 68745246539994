import { HelpOfferButtonsLeaveReview } from "@js/apps/messenger/components/action-bar/action-bar-offer-buttons/help-offer-buttons-leave-review";
import type { HelpOffer } from "@js/types/give-and-get-help";

import { ActionBarOfferButtons } from "./action-bar-offer-buttons/action-bar-offer-buttons";
import { HelpOfferActionMenuWrapper } from "./help-offer-action-menu-wrapper";

type ActionBarOfferStatusProps = {
  offer: HelpOffer;
  isOfferAuthor: boolean;
};

export const ActionBarOfferStatus = ({
  offer,
  isOfferAuthor,
}: ActionBarOfferStatusProps): JSX.Element | null => {
  return (
    <div className="help-offer-action-buttons">
      <ActionBarOfferButtons
        offer={offer}
        isOfferAuthor={isOfferAuthor}
        type="messenger"
        CompletedStatusComponent={HelpOfferButtonsLeaveReview}
      />
      <HelpOfferActionMenuWrapper offer={offer} isOfferAuthor={isOfferAuthor} />
    </div>
  );
};
