import { resendEmployerTeamMemberInvitation } from "@js/apps/employer/actions";
import {
  allowEmployerTeamMemberInvoiceView,
  deleteEmployerTeamMember,
  deleteEmployerTeamMemberInvitation,
  preventEmployerTeamMemberInvoiceView,
} from "@js/apps/employer/actions";
import { Snackbar } from "@js/components/snackbar";
import { getValuesWithReCaptchaCode } from "@js/forms/utils";
import type { AppDispatch } from "@js/store";
import { isCaptchaError, isErrorWithMessage } from "@js/types/errors";

import type { FetchDataType } from "./open-confirmation-modal";
import { openConfirmModal } from "./open-confirmation-modal";

type GetActionsProps = {
  fetchData: FetchDataType;
  memberId: number;
  isLoggedUser?: boolean;
  dispatch?: AppDispatch;
};
type GetActionsReturnType = {
  resend: () => void;
  preventInvoiceView: () => void;
  allowInvoiceView: () => void;
  removeTeamMember: () => void;
  deleteTeamMemberInvitation: () => void;
};

export const getActions = ({
  fetchData,
  memberId,
  isLoggedUser,
  dispatch,
}: GetActionsProps): GetActionsReturnType => {
  const resend = () => {
    openConfirmModal({
      confirmText:
        "Are you sure you want to resend invitation to this team member?",
      onConfirm: () => handleResentInvitation({ id: memberId }),
      onSuccess: fetchData,
    });
  };

  const preventInvoiceView = () => {
    if (!dispatch) return;

    openConfirmModal({
      confirmText:
        "Are you sure you want to prevent this member from viewing Invoices?",
      onConfirm: () => dispatch(preventEmployerTeamMemberInvoiceView(memberId)),
      onSuccess: fetchData,
      successMessage: "Saved successfully.",
      errorMessage: "Sorry, something went wrong.",
    });
  };

  const allowInvoiceView = () => {
    if (!dispatch) return;

    openConfirmModal({
      confirmText:
        "Are you sure you want to allow this member to view Invoices?",
      onConfirm: () => dispatch(allowEmployerTeamMemberInvoiceView(memberId)),
      onSuccess: fetchData,
      successMessage: "Saved successfully.",
      errorMessage: "Sorry, something went wrong.",
    });
  };

  const removeTeamMember = () => {
    if (!dispatch) return;

    openConfirmModal({
      confirmText: "Are you sure you want to delete this team member?",
      onConfirm: () => dispatch(deleteEmployerTeamMember(memberId)),
      onSuccess: () => {
        if (isLoggedUser) return location.reload();
        fetchData();
      },
      successMessage: "The team member has been deleted.",
      errorMessage: "Sorry, you cannot remove this member.",
    });
  };

  const deleteTeamMemberInvitation = () => {
    openConfirmModal({
      confirmText: "Are you sure you want to delete this team member?",
      onConfirm: () => deleteEmployerTeamMemberInvitation(memberId),
      onSuccess: fetchData,
      successMessage: "The team member has been deleted.",
      errorMessage: "Sorry, you cannot remove this member.",
    });
  };

  return {
    resend,
    preventInvoiceView,
    allowInvoiceView,
    removeTeamMember,
    deleteTeamMemberInvitation,
  };
};

const handleResentInvitation = async (values: { id: number }) => {
  try {
    await resendEmployerTeamMemberInvitation(values);
    return Snackbar.success("The invitation has been resent.");
  } catch (e) {
    if (isCaptchaError(e) && e?.captcha === ENUMS.CaptchaErrorValue.REQUIRED) {
      const withRecaptchaValues = await getValuesWithReCaptchaCode(
        values,
        ENUMS.CaptchaActions.RESEND_EMPLOYER_INVITATION,
      );

      return handleResentInvitation(withRecaptchaValues);
    }

    if (isErrorWithMessage(e)) {
      return Snackbar.error(e._error);
    }
  }
};
