import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { usePostsFilters } from "@js/apps/common/components/filters";

import { useInfinityPostLoading } from "../use-infinity-post-loading";
import { useSubscribeFeed } from "../websocket-subscribe";

const feedPath = "/feed";

export const useSearchPosts = () => {
  const { filters: postsFilters } = usePostsFilters();
  const { search } = postsFilters;
  const navigate = useNavigate();
  const {
    posts,
    hasMore,
    handleFetchingMore,
    loadingPosts,
    fetchingPosts,
    postsCount,
  } = useInfinityPostLoading({ search });

  const handleCtaClick = () => {
    navigate(feedPath);
  };

  useEffect(() => {
    if (!search) {
      navigate(feedPath, { preventScrollReset: true });
    }
  }, [navigate, search]);

  useSubscribeFeed();

  return {
    searchTerm: postsFilters.search,
    handleCtaClick,
    postsFilters,
    loading: loadingPosts,
    posts,
    hasMore,
    handleFetchingMore,
    fetchingPosts,
    postsCount,
  };
};
