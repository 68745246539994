/* eslint-disable max-len */
import _ from "underscore";
import { z } from "zod";

import type { TalentMixedLocation } from "@js/apps/freelancer/types";
import { sanitize } from "@js/services";
import type { CustomLocation } from "@js/types/common";
import type {
  CreateJobDraftPayload,
  GetJobDraftResponse,
  Job,
  JobDraft,
  JobDraftTimezone,
  JobFormValues,
  JobSubscriberData,
  JobSubscriberInvitationData,
  JobTimezone,
  JobToEdit,
  LocationPayload,
  PreparedJobDraftData,
  TimezoneOption,
  TimezonePayload,
  UpdateJobDraftPayload,
  UploadedJob,
} from "@js/types/jobs";
import { assertUnreachable, deepClone, typeGuard } from "@js/utils";

import { timezoneOptions } from "./components/project-details-section-module/location-timezone-section/location-and-timezone-fields/timezone-with-overlap/job-timezone-field/helpers";
import {
  AI_VALUE,
  LOCATIONS_TAGS_CHARACTERS_LIMIT,
  RANGE_VALUE,
  RATE_OPTIONS,
} from "./constants";

type JobTypeMap<T> = Record<EnumType<typeof ENUMS.JobType>, T>;

const EXPECTED_HOURS_PER_WEEK: JobTypeMap<number | undefined> = {
  [ENUMS.JobType.DIRECT_HIRE]: 40,
  [ENUMS.JobType.FREELANCE]: undefined,
  [ENUMS.JobType.GRANT]: 15,
} as const;

const CONTRACT_TYPE: JobTypeMap<
  EnumType<typeof ENUMS.JobContractType> | undefined
> = {
  [ENUMS.JobType.DIRECT_HIRE]: ENUMS.JobContractType.LONG,
  [ENUMS.JobType.FREELANCE]: undefined,
  [ENUMS.JobType.GRANT]: ENUMS.JobContractType.SHORT,
} as const;

const PAYMENT_TYPE: JobTypeMap<EnumType<typeof ENUMS.JobPaymentType>> = {
  [ENUMS.JobType.DIRECT_HIRE]: ENUMS.JobPaymentType.ANNUAL,
  [ENUMS.JobType.FREELANCE]: ENUMS.JobPaymentType.HOURLY,
  [ENUMS.JobType.GRANT]: ENUMS.JobPaymentType.FIXED_PRICE,
} as const;

const fieldsToSanitize = ["requirements", "introduction"];
export const sanitizationRegex = />[^<]*[^<>\s]+[^>]*</i;

export const sanitizeRichEditorContent = <
  T extends { requirements?: string; introduction?: string } | undefined,
>(
  formValues: T,
): T => {
  if (formValues) {
    fieldsToSanitize.forEach((editorField) => {
      if (!sanitizationRegex.test(formValues[editorField])) {
        formValues[editorField] = "";
      }
    });
  }

  return formValues;
};

export const prepareDraftResponse = (
  response: GetJobDraftResponse,
): JobDraft => {
  const responseWithoutData: Omit<GetJobDraftResponse, "data"> & {
    data?: undefined;
  } = {
    ...response,
    data: undefined,
  };

  delete responseWithoutData.data;

  const draft: JobDraft = {
    ...responseWithoutData,
    ...response.data,
  };

  return draft;
};

export const extractJobLocationAndTimezoneInitialValues = (
  job:
    | Partial<Pick<JobDraft, "timezones" | "locations">>
    | Partial<Pick<Job, "timezones" | "locations">>,
) => {
  const locationAndTimezone: {
    locations?: TalentMixedLocation[];
    timezones?: TimezoneOption[];
  } = {};

  if (job?.locations?.length) {
    locationAndTimezone.locations = mapLocationsToFrontendFormat(job.locations);
  }

  if (job.timezones?.length) {
    locationAndTimezone.timezones = mapTimezonesToFrontendFormat(job.timezones);
  }

  return locationAndTimezone;
};

export const prepareJobDraftFormValues = (
  valuesArg: JobFormValues,
): PreparedJobDraftData => {
  let values: PreparedJobDraftData = deepClone({
    ...valuesArg,
    jobOwnerId: undefined,
    isDeletingJobDraft: undefined,
    timezones: valuesArg.timezones?.length
      ? mapTimezonesToBackendFormat(valuesArg.timezones)
      : undefined,
    locations: valuesArg.locations?.length
      ? mapLocationsToBackendFormat(valuesArg.locations)
      : undefined,
  }); // not sure if deep clone is required, but in case

  if (!values.timezones?.length) {
    values.timezone_overlap = null;
    delete values.timezones;
  }

  if (!values.locations?.length) {
    delete values.locations;
    values.locations_strongly_required = false;
  }

  values.op_owners =
    !!values.op_owners && values.op_owners.length > 0
      ? values.op_owners.map((owner) => owner?.id || owner)
      : [];
  values.sale_owners =
    !!values.sale_owners && values.sale_owners.length > 0
      ? values.sale_owners.map((owner) => owner?.id || owner)
      : [];

  values = mapRateValuesToBackendFormat(values);

  return values;
};

export const prepareLatestDraftDataToSave = (
  values: JobFormValues,
  lastSavedDraft: JobDraft | undefined,
): PreparedJobDraftData | undefined => {
  const valuesCopy = deepClone(values);

  const filteredValues = removeUndefinedKeys(
    prepareJobDraftFormValues(valuesCopy),
  );

  const toCompare = _.pick(lastSavedDraft, Object.keys(filteredValues)) ?? {};

  // If you wonder why there is additional request after creating a new draft:
  // It is because we initialize form with values depending on job_type (eg contract_type).
  // To avoid request we would need to submit those values on draft set up.
  // Form values are sanitized to avoid unnecessary requests when rich editor field doesn't contain content
  // but editor itself fills value with HTML tags (f.e. only white spaces or enters)
  const areEqual = _.isEqual(
    sanitizeRichEditorContent(filteredValues),
    sanitizeRichEditorContent(toCompare),
  );

  if (areEqual) {
    return;
  }

  return filteredValues;
};

const removeUndefinedKeys = <T extends object>(obj: T): T => {
  const objectWithRemovedKeys = Object.keys(obj).reduce((prev, current) => {
    if (obj[current] !== undefined) {
      prev[current] = obj[current];
    }

    return prev;
  }, {} as T);

  return objectWithRemovedKeys;
};

export const prepareCreateJobSubmitFormValues = (valuesArg: JobFormValues) => {
  const preparedDraftValues = prepareJobDraftFormValues(valuesArg);

  const jobDraftId = preparedDraftValues.job_draft_id || preparedDraftValues.id;

  return {
    ...preparedDraftValues,
    job_draft_id: jobDraftId,
  };
};

const manageJobErrorsSchema = z
  .object({
    email: z.string().optional(),
    timezone_overlap: z.coerce.string().optional(),
    budget_minimum_usd: z.string().array().optional(),
    budget_maximum_usd: z.string().array().optional(),
    new_application_questions: z
      .array(z.object({ question: z.coerce.string().optional() }))
      .optional(),
  })
  .catchall(z.coerce.string().optional());

type ManageJobErrors = z.infer<typeof manageJobErrorsSchema>;

type ManageJobErrorsMapped = ManageJobErrors & {
  fixed_rate?: string[];
  min_rate?: string[];
  max_rate?: string[];
  hourly_rate?: string[];
  min_annual_rate?: string[];
  max_annual_rate?: string[];
  job_subscriber_invitations?: string;
  payment_type?: string;
};

type MapManageJobErrorsJobValues = Partial<
  Pick<
    PreparedJobDraftData,
    | "payment_type"
    | "budget_minimum_usd"
    | "budget_maximum_usd"
    | "timezone_overlap"
  >
>;

export const mapRateErrorsToFrontend = (
  values: MapManageJobErrorsJobValues,
  errors: ManageJobErrors,
) => {
  const newErrors: ManageJobErrorsMapped = {
    ...errors,
  };

  // temp until values are not properly typed
  const paymentType: EnumType<typeof ENUMS.JobPaymentType> | undefined =
    values.payment_type;

  if (!paymentType) {
    newErrors.payment_type = "Required";
    return newErrors;
  }

  switch (paymentType) {
    case ENUMS.JobPaymentType.FIXED_PRICE: {
      if (errors.budget_minimum_usd || errors.budget_maximum_usd) {
        newErrors.fixed_rate =
          errors.budget_minimum_usd || errors.budget_maximum_usd;
      }

      break;
    }
    case ENUMS.JobPaymentType.HOURLY: {
      const isRateTheSame =
        values.budget_minimum_usd === values.budget_maximum_usd;
      const isRangeSelected = isRateTheSame
        ? !RATE_OPTIONS.find(
            (option) => option.value === values.budget_minimum_usd,
          )
        : true;

      if (isRangeSelected) {
        newErrors.min_rate = errors.budget_minimum_usd;
        newErrors.max_rate = errors.budget_maximum_usd;
      } else {
        newErrors.hourly_rate =
          errors.budget_minimum_usd || errors.budget_maximum_usd;
      }
      break;
    }
    case ENUMS.JobPaymentType.ANNUAL: {
      newErrors.min_annual_rate = errors.budget_minimum_usd;
      newErrors.max_annual_rate = errors.budget_maximum_usd;
      break;
    }
    default: {
      assertUnreachable(paymentType);
      break;
    }
  }

  delete newErrors.budget_minimum_usd;
  delete newErrors.budget_maximum_usd;

  return newErrors;
};

export const mapManageJobErrors = (
  values: MapManageJobErrorsJobValues,
  errors: unknown,
) => {
  const parseErrorsResult = manageJobErrorsSchema.safeParse(errors);
  if (!parseErrorsResult.success) {
    return errors as Record<string, string | undefined>;
  }

  let mappedErrors: ManageJobErrorsMapped = {};

  mappedErrors = mapRateErrorsToFrontend(values, parseErrorsResult.data);

  if (parseErrorsResult.data.email) {
    mappedErrors.job_subscriber_invitations = parseErrorsResult.data.email;
  }

  return mappedErrors;
};

export const mapRateValuesToBackendFormat = (values) => {
  const newValues = {
    ...values,
  };

  if (!newValues.payment_type) return newValues;

  // temp until values are not properly typed
  const paymentType: EnumType<typeof ENUMS.JobPaymentType> =
    newValues.payment_type;

  switch (paymentType) {
    case ENUMS.JobPaymentType.FIXED_PRICE: {
      newValues.budget_minimum_usd = newValues.fixed_rate;
      newValues.budget_maximum_usd = newValues.fixed_rate;
      break;
    }
    case ENUMS.JobPaymentType.HOURLY: {
      if (
        newValues.hourly_rate === RANGE_VALUE ||
        newValues.hourly_rate === AI_VALUE
      ) {
        newValues.budget_minimum_usd = newValues.min_rate || newValues.max_rate;
        newValues.budget_maximum_usd = newValues.max_rate || newValues.min_rate;
      } else {
        newValues.budget_minimum_usd = newValues.hourly_rate;
        newValues.budget_maximum_usd = newValues.hourly_rate;
      }
      break;
    }
    case ENUMS.JobPaymentType.ANNUAL: {
      newValues.budget_minimum_usd =
        newValues.min_annual_rate || newValues.max_annual_rate;
      newValues.budget_maximum_usd =
        newValues.max_annual_rate || newValues.min_annual_rate;
      break;
    }
    case undefined: {
      break;
    }
    default: {
      assertUnreachable(paymentType);
      break;
    }
  }

  delete newValues.hourly_rate;
  delete newValues.fixed_rate;
  delete newValues.min_rate;
  delete newValues.max_rate;
  delete newValues.max_annual_rate;
  delete newValues.min_annual_rate;
  delete newValues.custom_min_rate;
  delete newValues.custom_max_rate;

  return newValues;
};

export const mapRateValuesToFrontendFormat = (values) => {
  const newValues = {
    ...values,
  };

  if (!newValues.payment_type) return newValues;

  // temp until values are not properly typed
  const paymentType: EnumType<typeof ENUMS.JobPaymentType> =
    newValues.payment_type;

  switch (paymentType) {
    case ENUMS.JobPaymentType.FIXED_PRICE: {
      // in real world min and max will be always the same, but in case we choose bigger value
      newValues.fixed_rate = newValues.budget_minimum_usd;
      newValues.fixed_rate = newValues.budget_maximum_usd;
      break;
    }
    case ENUMS.JobPaymentType.HOURLY: {
      const isRateTheSame =
        newValues.budget_minimum_usd === newValues.budget_maximum_usd;
      const isRangeSelected = isRateTheSame
        ? !RATE_OPTIONS.find(
            (option) => option.value === newValues.budget_minimum_usd,
          )
        : true;

      // backend should always provide budget_minimum_usd
      if (
        values.is_ai_generated &&
        isRangeSelected &&
        newValues.budget_minimum_usd === newValues.ai_min_rate &&
        newValues.budget_maximum_usd === newValues.ai_max_rate
      ) {
        newValues.min_rate = newValues.budget_minimum_usd;
        newValues.max_rate = newValues.budget_maximum_usd;
        newValues.hourly_rate = AI_VALUE;
      } else if (isRangeSelected && newValues.budget_minimum_usd) {
        newValues.min_rate = newValues.custom_min_rate =
          newValues.budget_minimum_usd;
        newValues.max_rate = newValues.custom_max_rate =
          newValues.budget_maximum_usd;
        newValues.hourly_rate = RANGE_VALUE;
      } else {
        newValues.hourly_rate = newValues.budget_minimum_usd;
        newValues.hourly_rate = newValues.budget_maximum_usd;
      }
      break;
    }
    case ENUMS.JobPaymentType.ANNUAL: {
      newValues.min_annual_rate = newValues.budget_minimum_usd;
      newValues.max_annual_rate = newValues.budget_maximum_usd;
      break;
    }
    case undefined: {
      break;
    }
    default: {
      assertUnreachable(paymentType);
      break;
    }
  }

  delete newValues.budget_minimum_usd;
  delete newValues.budget_maximum_usd;

  return newValues;
};

const getCommonInitialValues = (job) => {
  return mapRateValuesToFrontendFormat({
    auto_invite_talent:
      job?.job_fill_support_type !== ENUMS.JobFillSupportType.BYOT &&
      !job?.is_private,
    budget_minimum_usd: job?.budget_minimum_usd || job.min_rate,
    budget_maximum_usd: job.budget_maximum_usd || job.max_rate,
    contract_type: !_.isEmpty(job)
      ? job?.contract_type || CONTRACT_TYPE[job?.job_type] || undefined
      : undefined,
    deadline: job.deadline,
    expected_hours_per_week: !_.isEmpty(job)
      ? job?.expected_hours_per_week ||
        EXPECTED_HOURS_PER_WEEK[job?.job_type] ||
        20
      : undefined,
    is_private: job.is_private ?? false,
    introduction: job.introduction ? sanitize(job.introduction) : "",
    job_type: job.job_type,
    level: job.level,
    locations_strongly_required: job.locations_strongly_required,
    openings_number: job?.openings_number || 1,
    payment_type: job?.payment_type || PAYMENT_TYPE[job.job_type] || "hourly",
    is_resume_required: job.is_resume_required ?? false,
    requirements: job.requirements ? sanitize(job.requirements) : "",
    description: job.description ? sanitize(job.description) : "",
    role: job.role,
    start_date: job.start_date,
    timezone_overlap: job.timezone_overlap,
    title: job.title ? sanitize(job.title, { ALLOWED_TAGS: [] }) : "",
    top_skills: job.top_skills,
    experience_level: job.experience_level || undefined,
    ...extractJobLocationAndTimezoneInitialValues(job),
    is_ai_generated: job.is_ai_generated,
    flow_entry: job.flow_entry || "custom",
    ai_min_rate: job.ai_min_rate,
    ai_max_rate: job.ai_max_rate,
  });
};

export const getInitialValues = (job: JobToEdit) => {
  const jobOwnerId = job.hiring_manager ?? job.creator?.id; // TODO remove optional chaining in job.creator?.id when ai generated job types are fixed

  return {
    ...getCommonInitialValues(job),
    id: job.id,
    new_skills: job.skills,
    new_application_questions:
      getSanitizedQuestions(job.application_questions) || [],
    jobOwnerId,
    job_subscribers: job.job_subscribers ?? [],
    job_subscriber_invitations: job.job_subscriber_invitations ?? [],
    auto_invite_talent: job.auto_invite_talent,
  };
};

export const getInitialValuesOnBehalfOfAClient = (job: JobToEdit) => {
  return {
    ...getInitialValues(job),
    id: job.id,
    employer: resolveEmployerIdFromJob(job),
  };
};

export const getCopyJobInitialValues = (job: JobToEdit) => {
  return {
    ...getInitialValues(job),
    id: undefined,
    original_job: job.id,
    new_application_questions:
      getSanitizedQuestions(
        job.application_questions?.map((q) => {
          return { ...q, id: undefined };
        }),
      ) || [],
  };
};

export const getCopyJobInitialValuesOnBehalfOfAClient = (job: JobToEdit) => {
  return {
    ...getCopyJobInitialValues(job),
    job_subscribers: [],
    job_subscriber_invitations: [],
    employer: resolveEmployerIdFromJob(job),
    hiring_manager: job.hiring_manager,
    op_owners: job.op_owners,
    sale_owners: job.sale_owners,
    job_fill_support_type: job.job_fill_support_type,
  };
};

export type GetDraftInitialValuesArg = {
  draft: Partial<
    Omit<JobDraft, "job_subscribers" | "job_subscriber_invitations">
  > & {
    job_subscribers?: JobSubscriberData[];
    job_subscriber_invitations?: JobSubscriberInvitationData[];
  };
  userId: number;
};

export const getDraftInitialValues = ({
  draft,
  userId,
}: GetDraftInitialValuesArg) => {
  const jobOwnerId = draft.hiring_manager ?? userId;
  const defaultJobSubscribers: JobFormValues["job_subscribers"] = [
    { team_member_id: jobOwnerId },
  ];
  const defaultJobSubscriberInvitations: JobFormValues["job_subscriber_invitations"] =
    [];
  const defaultApplicationQuestions = [
    {
      question: "Why are you the best candidate for this job?",
      order: 0,
      can_be_changed: true,
    },
  ];

  return {
    ...getCommonInitialValues(draft),
    id: draft.id,
    external_id: draft.external_id,
    original_job: draft.original_job,
    new_skills: draft.new_skills || [],
    new_application_questions: !!draft?.new_application_questions
      ? getSanitizedQuestions(draft?.new_application_questions)
      : defaultApplicationQuestions,
    job_subscribers: draft.job_subscribers ?? defaultJobSubscribers,
    job_subscriber_invitations:
      draft.job_subscriber_invitations ?? defaultJobSubscriberInvitations,
    jobOwnerId,
  };
};

export const getCopyDraftInitialValues = (arg: GetDraftInitialValuesArg) => {
  return {
    ...getDraftInitialValues(arg),
    id: undefined,
  };
};

export const getDraftInitialValuesOnBehalfOfAClient = (
  arg: GetDraftInitialValuesArg & { isEmployer: boolean },
) => {
  const { draft, userId, isEmployer } = arg;
  const jobOwnerId = isEmployer
    ? draft.hiring_manager ?? userId
    : draft.hiring_manager; // do not use current user Id if not employer
  const jobSubscribersOnBehalfOfAClient: JobFormValues["job_subscribers"] =
    jobOwnerId ? [{ team_member_id: jobOwnerId }] : [];

  return {
    ...getDraftInitialValues({ userId, draft }),
    job_subscribers: jobSubscribersOnBehalfOfAClient,
    employer: draft.employer,
    hiring_manager: draft.hiring_manager,
    op_owners: draft.op_owners,
    sale_owners: draft.sale_owners,
    job_fill_support_type: draft.job_fill_support_type,
  };
};

export const getCopyDraftInitialValuesOnBehalfOfAClient = (
  arg: GetDraftInitialValuesArg & { isEmployer: boolean },
) => {
  return {
    ...getDraftInitialValuesOnBehalfOfAClient(arg),
    id: undefined,
  };
};

const resolveEmployerIdFromJob = (job: JobToEdit | Job): number => {
  return typeof job.employer === "object" ? job.employer.id : job.employer;
};

// Mapping Locations & Timezones to Frontend <-> Backend formats
export const mapTimezonesToFrontendFormat = (
  timezones: JobTimezone[] | JobDraftTimezone[],
) => {
  const mappedTimezones = timezones.map((timezone) => {
    return timezoneOptions.find(
      (option) =>
        option.id === timezone.timezone || option.id === timezone.timezone_id,
    );
  }) as TimezoneOption[];

  return mappedTimezones;
};

export const mapTimezonesToBackendFormat = (
  timezones: TimezoneOption[],
): TimezonePayload[] => {
  const mappedTimezones = timezones.map((timezone) => {
    // We need to store `timezone_id` to make sure it is mapped properly to frontend format (within drafts)
    return { timezone: timezone.value, timezone_id: timezone.id };
  });

  return mappedTimezones;
};

export const mapLocationsToFrontendFormat = (
  locations: object[],
): TalentMixedLocation[] => {
  const mappedLocations = locations
    .map((location) => {
      if (!("google_full_location" in location)) {
        return undefined;
      }

      if (
        location.google_full_location &&
        typeGuard<object, CustomLocation>(
          location.google_full_location,
          "custom",
        ) &&
        location.google_full_location.custom
      ) {
        return {
          ...location.google_full_location,
          formatted_address: location.google_full_location.name,
        } as unknown as TalentMixedLocation;
      }

      return location.google_full_location;
    })
    .filter((location): location is TalentMixedLocation => Boolean(location));

  return mappedLocations;
};

export const mapLocationsToBackendFormat = (
  locations: TalentMixedLocation[],
) => {
  const mappedLocations = locations.map((location) => {
    if (location.custom_location) {
      const JobCustomLocationsKeys = Object.keys(
        ENUMS.JobCustomLocationTypeLabels,
      );

      const isUserCustomLocation = JobCustomLocationsKeys.includes(
        location.custom_location,
      );

      if (isUserCustomLocation) {
        return {
          location: location.formatted_address,
          google_full_location: {
            id: location.custom_location,
            name: location.name,
            custom: true,
          },
        };
      }
    }

    return {
      location: location.formatted_address ?? location.name,
      google_full_location: location,
    };
  }) as LocationPayload[];

  return mappedLocations;
};

export type Entities = TimezoneOption | TalentMixedLocation;

export const prepareArrayWithTagsCharactersLimit = <T extends Entities>(
  entities: T[],
  tagsCharactersLimit: number,
  getName: (item: T) => string,
  focused: boolean,
): T[] => {
  if (focused) return entities;

  let lengthCounter = 0;

  const tags = entities.reduce((accumulator: T[], currentItem: T) => {
    const itemName = getName(currentItem);

    const hasExceededCharacterLimit =
      lengthCounter + itemName.length >= tagsCharactersLimit;

    lengthCounter += itemName.length;

    if (hasExceededCharacterLimit && accumulator.length > 0) {
      return accumulator;
    }

    return [...accumulator, currentItem];
  }, []);

  return tags;
};

export const prepareAutocompleteValue = ({
  value,
  isLast,
  isInputFocused,
  isLimitReached,
  limit = LOCATIONS_TAGS_CHARACTERS_LIMIT,
}: {
  value: string;
  isLast: boolean;
  isInputFocused: boolean;
  isLimitReached: boolean;
  limit?: number;
}): JSX.Element => {
  let ellipsis = "";
  let processedValue = value;

  if (!isLast || isInputFocused) {
    ellipsis = ` |`;
  } else if (isLimitReached) {
    ellipsis = ` | ...`;
  }

  if (processedValue.length > limit && !isInputFocused) {
    processedValue = processedValue.slice(0, limit);
    ellipsis = "...";
  }

  return (
    <span
      key={value}
      style={{
        marginRight: "5px",
      }}
    >
      {`${processedValue}${ellipsis}`}
    </span>
  );
};

const getSanitizedQuestions = <TQueestion extends { question: string }>(
  questions: TQueestion[],
): TQueestion[] => {
  return questions.map((questionItem) => ({
    ...questionItem,
    question: sanitize(questionItem.question, { ALLOWED_TAGS: [] }),
  }));
};

export const isUpdateJobDraftPayload = (
  payload: UpdateJobDraftPayload | CreateJobDraftPayload,
): payload is UpdateJobDraftPayload =>
  "id" in payload.data && !!payload.data.id;

type SaveDraftArg = {
  data: Optional<PreparedJobDraftData, "id">;
  original_job?: number | null;
};

type SaveDraftPayload = UpdateJobDraftPayload | CreateJobDraftPayload;

export const prepareJobDraftPayload = (
  draftDataToSave: SaveDraftArg,
): SaveDraftPayload => {
  const draftDataToSaveClone = deepClone(draftDataToSave);
  const preparedJobDraftPayloadData: SaveDraftPayload["data"] & {
    job_subscribers?: undefined;
    job_subscriber_invitations?: undefined;
  } = {
    ...draftDataToSaveClone.data,
    job_subscribers: undefined,
    job_subscriber_invitations: undefined,
  };

  delete preparedJobDraftPayloadData.job_subscribers;
  delete preparedJobDraftPayloadData.job_subscriber_invitations;

  return {
    ...draftDataToSaveClone,
    data: preparedJobDraftPayloadData,
    job_subscribers: draftDataToSaveClone.data.job_subscribers,
    job_subscriber_invitations:
      draftDataToSaveClone.data.job_subscriber_invitations,
  };
};

export type GetCreateJobInitialValuesArg = {
  userId: number | undefined;
  isEmployer: boolean;
  copyDraftId: number | undefined;
  draft: JobDraft | undefined;
  jobToCopy: JobToEdit | null;
  canManageJobAsCoreMember: boolean;
  aiGenerated: Job | null;
  uploadedJobDescription: UploadedJob | null;
};
export const getCreateJobInitialValues = ({
  userId,
  draft,
  canManageJobAsCoreMember,
  jobToCopy,
  aiGenerated,
  copyDraftId,
  isEmployer,
  uploadedJobDescription,
}: GetCreateJobInitialValuesArg): JobFormValues | undefined => {
  if (!userId) {
    return;
  }

  if (canManageJobAsCoreMember && jobToCopy && !draft) {
    return getCopyJobInitialValuesOnBehalfOfAClient(jobToCopy);
  }

  if (canManageJobAsCoreMember && aiGenerated && !draft) {
    // TODO fix type on aiGenerated
    return getCopyJobInitialValuesOnBehalfOfAClient(
      aiGenerated as unknown as JobToEdit,
    );
  }

  if (canManageJobAsCoreMember && copyDraftId) {
    return getCopyDraftInitialValuesOnBehalfOfAClient({
      draft: draft || {},
      userId,
      isEmployer,
    });
  }

  if (canManageJobAsCoreMember && uploadedJobDescription && !draft) {
    return getDraftInitialValuesOnBehalfOfAClient({
      draft: uploadedJobDescription,
      userId,
      isEmployer,
    });
  }

  if (canManageJobAsCoreMember) {
    return getDraftInitialValuesOnBehalfOfAClient({
      draft: draft || {},
      userId,
      isEmployer,
    });
  }

  if (copyDraftId && draft) {
    return getCopyDraftInitialValues({ draft, userId });
  }

  if (jobToCopy && !draft) {
    return getCopyJobInitialValues(jobToCopy);
  }

  if (aiGenerated && !draft) {
    // TODO fix type on aiGenerated
    return getCopyJobInitialValues(aiGenerated as unknown as JobToEdit);
  }

  if (uploadedJobDescription && !draft) {
    return getDraftInitialValues({
      draft: uploadedJobDescription,
      userId,
    });
  }

  return getDraftInitialValues({
    draft: draft || {},
    userId,
  });
};
