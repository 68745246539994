import { useCallback } from "react";
import axios from "axios";

import { useCreateJobDraftMutation } from "@js/apps/jobs/api";
import { Snackbar } from "@js/components/snackbar";
import type { PreparedJobDraftData } from "@js/types/jobs";

import { isUpdateJobDraftPayload, prepareJobDraftPayload } from "../../utils";
import { useJobFormUpdateJobDraftMutation } from "../use-job-form-update-job-draft-mutation";

type CreateOrUpdateJobDraftArg = {
  data: Optional<PreparedJobDraftData, "id">;
  original_job?: number | null;
};

export const useCreateOrUpdateJobDraft = () => {
  const [updateJobDraft] = useJobFormUpdateJobDraftMutation();
  const [createJobDraft] = useCreateJobDraftMutation();

  const createOrUpdateJobDraft = useCallback(
    async (values: CreateOrUpdateJobDraftArg) => {
      const preparedJobDraftPayload = prepareJobDraftPayload(values);

      try {
        if (isUpdateJobDraftPayload(preparedJobDraftPayload)) {
          return await updateJobDraft(preparedJobDraftPayload).unwrap();
        } else {
          return await createJobDraft(preparedJobDraftPayload).unwrap();
        }
      } catch (error: unknown) {
        if (
          axios.isAxiosError(error) &&
          error?.response?.status === 400 &&
          error?.response.data &&
          isSaveDraftJobTypeErrorResponse(error?.response.data)
        ) {
          Snackbar.error(error?.response.data?.data.job_type);
        } else {
          Snackbar.error("Failed to save draft.");
        }
      }
    },
    [createJobDraft, updateJobDraft],
  );

  return { createOrUpdateJobDraft };
};

type SaveDraftJobTypeErrorResponse = {
  data: {
    job_type?: string;
  };
};

const isSaveDraftJobTypeErrorResponse = (
  e: unknown | SaveDraftJobTypeErrorResponse,
): e is SaveDraftJobTypeErrorResponse => {
  return (
    (e as SaveDraftJobTypeErrorResponse)?.data &&
    (e as SaveDraftJobTypeErrorResponse)?.data?.job_type !== undefined
  );
};
