import { useCallback, useEffect, useState } from "react";

import { useActiveWeb3React } from "@js/apps/web3/hooks";

import { useGetNftStatusQuery, useMintTokenMutation } from "../api";

import { useSignMessage } from "./sign-message";

export const useMint = () => {
  const [pollingInterval, setPollingInterval] = useState(0);
  const { data, refetch } = useGetNftStatusQuery(undefined, {
    pollingInterval,
  });
  const [mintToken] = useMintTokenMutation();
  const { account } = useActiveWeb3React();
  const signMessage = useSignMessage();

  useEffect(() => {
    if (data?.status === ENUMS.NFTMintingStatus.MINTED) {
      setPollingInterval(0);
    }
    if (data?.status === ENUMS.NFTMintingStatus.MINTING) {
      setPollingInterval(5000);
    }
  }, [data]);

  const mint = useCallback(async () => {
    if (!account) {
      throw new Error("No wallet connected");
    }

    const signature = await signMessage(account);
    const mintData = { wallet: account, signature };

    const res = await mintToken(mintData).unwrap();

    setPollingInterval(5000);
    refetch();

    return res;
  }, [account, mintToken, refetch, signMessage]);

  return {
    mint,
    tokenId: data?.nft_token_id || null,
    status: data?.status || null,
    mintingHashUrl: data?.nft_minting_hash_url || null,
  };
};
