import { Box } from "@hexocean/braintrust-ui-components";
import { BraintrustIcon } from "@hexocean/braintrust-ui-components/Icons";

import styles from "../style.module.scss";

export const BraintrustLogo = () => {
  return (
    <Box className={styles.braintrustLogo}>
      <BraintrustIcon
        style={{ width: "19px", height: "19px", color: "var(--white)" }}
        aria-label="Braintrust logo"
      />
    </Box>
  );
};
