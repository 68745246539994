import React, { Component } from "react";
import ReactDOM from "react-dom";
import { connect } from "react-redux";

import { Button } from "@hexocean/braintrust-ui-components";
import { openGenericMessenger } from "@js/apps/messenger/messengers/modal/manage-messenger-modal";
import { RouterLink } from "@js/components/link";
import { isInIframe } from "@js/utils";
import { mapTypedDispatchToProps } from "@js/utils/store";

import { siteNotificationMarkAsRead } from "../../actions";
import { SiteNotification } from "../site-notification";

export class LiveSiteNotificationsComponent extends Component<any, any> {
  state = {
    toDisplay: [] as any,
    classNames: {},
  };

  componentDidUpdate(prevProps) {
    if (
      prevProps.siteNotifications !== null &&
      this.props.siteNotifications &&
      this.props.siteNotifications.length > prevProps.siteNotifications.length
    ) {
      const newNotification = this.props.siteNotifications[0];

      if (this.canNewNotificationBeDisplayed(newNotification)) {
        this.displayNotification(newNotification);
      }
    }
  }

  canNewNotificationBeDisplayed(notification) {
    if (isInIframe()) return false;
    if (notification.silent) return false;

    const roomId = notification.context && notification.context.room_id;
    if (roomId && roomId === this.props.activeMessengerRoom) {
      return false;
    }

    return true;
  }

  displayNotification(newNotification) {
    this.setState((prevState) => ({
      toDisplay: [...prevState.toDisplay, newNotification],
    }));
  }

  hideNotification(notificationId) {
    this.setState((prevState) => ({
      classNames: {
        ...prevState.classNames,
        [notificationId]: "hiding",
      },
    }));

    setTimeout(() => {
      this.setState((prevState) => ({
        toDisplay: prevState.toDisplay.filter((n) => n.id !== notificationId),
      }));
    }, 1000);
  }

  renderButton = (button, context) => {
    const {
      id,
      notification,
      text,
      href,
      is_hide_modal_button: isHideModalButton,
    } = button;

    const hide = () => {
      this.hideNotification(notification);
      this.props.dispatch(siteNotificationMarkAsRead(notification));
    };

    const hideAndAct = () => {
      hide();
      if (context && context.room_id) {
        this.props.dispatch(openGenericMessenger({ roomId: context.room_id }));
      }
    };

    // use 'to' prop if it is internal url
    const linkProps =
      href && (href.startsWith("http://") || href.startsWith("https://"))
        ? { href: href }
        : { to: href };

    return (
      <Button
        key={id}
        {...linkProps}
        variant={isHideModalButton ? "secondary" : "primary"}
        onClick={isHideModalButton ? hide : hideAndAct}
        RouterLink={RouterLink}
      >
        {text}
      </Button>
    );
  };

  render() {
    const portalsElement = document.getElementById("portals");

    if (portalsElement) {
      return ReactDOM.createPortal(
        <div className="live-site-notifications">
          {this.state.toDisplay.map((siteNotification) => (
            <SiteNotification
              live
              key={siteNotification.id}
              notification={siteNotification}
              closeNotificationsPopup={() =>
                this.hideNotification(siteNotification.id)
              }
              className={this.state.classNames[siteNotification.id]}
            >
              <div className="right live-site-notification-buttons">
                {siteNotification.buttons.map((button) =>
                  this.renderButton(button, siteNotification.context),
                )}
              </div>
            </SiteNotification>
          ))}
        </div>,
        portalsElement,
      );
    }

    return null;
  }
}

const mapStateToProps = (state) => ({
  siteNotifications: state.notifications.siteNotifications,
  activeMessengerRoom: state.common.activeMessengerRoom,
});

export default connect(
  mapStateToProps,
  mapTypedDispatchToProps,
)(LiveSiteNotificationsComponent);
