import type { ChangeEvent } from "react";
import React, { Suspense, useState } from "react";
import type { DocumentProps, PageProps } from "react-pdf";

import {
  Box,
  Pagination,
  PaginationWrapper,
} from "@hexocean/braintrust-ui-components";
import { useMediaQuery } from "@hexocean/braintrust-ui-components/hooks";
import { RouterLink } from "@js/components/link";
import { Snackbar } from "@js/components/snackbar";
import { handleImplicitLinkClick } from "@js/hooks";
import { lazyWithRetry } from "@js/utils";

import styles from "./style.module.scss";

const Document = lazyWithRetry<DocumentProps>(() =>
  import(/* webpackChunkName: "react-pdf" */ "react-pdf").then((module) => ({
    default: module.Document,
  })),
);

const Page = lazyWithRetry<PageProps>(() =>
  import(/* webpackChunkName: "react-pdf" */ "react-pdf").then((module) => ({
    default: module.Page,
  })),
);
import(/* webpackChunkName: "react-pdf" */ "react-pdf").then((module) => {
  module.pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${module.pdfjs.version}/pdf.worker.js`;
});

type PdfViewerProps = {
  sourceFile: string;
  onLoadSuccess: DocumentProps["onLoadSuccess"];
  adjustCustomScale?: () => number;
  onPassword?: () => void;
  hidePagination?: boolean;
  confirmOnRedirect?: boolean;
};
export const PdfViewer = ({
  sourceFile,
  onLoadSuccess,
  adjustCustomScale,
  onPassword = () => {
    Snackbar.error("Failed to open pdf: This file is password-protected.");
  },
  hidePagination,
  confirmOnRedirect = true,
}: PdfViewerProps): JSX.Element => {
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const isMobile = useMediaQuery(400);
  const isTablet = useMediaQuery("sm");
  const isDesktop = useMediaQuery("md");

  const adjustScaleToScreen = () => {
    if (isMobile) {
      return 0.6;
    }
    if (isTablet) {
      return 0.85;
    }
    if (isDesktop) {
      return 0.95;
    }
    return 1.0;
  };

  const scaleFunc = adjustCustomScale ? adjustCustomScale : adjustScaleToScreen;

  const onDocumentLoadSuccess: DocumentProps["onLoadSuccess"] = async (pdf) => {
    setTotalPages(pdf.numPages);
    if (onLoadSuccess) onLoadSuccess(pdf);
  };

  const handleChange = (event: ChangeEvent<unknown>, value: number) => {
    event.preventDefault();
    setCurrentPage(value);
  };

  const displayPagination = sourceFile && !hidePagination && totalPages > 1;

  return (
    <Suspense>
      <Box
        id="pdf-viewer-top-element"
        className={styles.pdfViewerWrapper}
        onClick={
          confirmOnRedirect
            ? (e) => {
                handleImplicitLinkClick(e, "a, #pdf-viewer-top-element");
              }
            : undefined
        }
      >
        <Document
          file={sourceFile}
          onLoadSuccess={onDocumentLoadSuccess}
          onPassword={onPassword}
          externalLinkTarget={"_blank"}
        >
          <Page pageNumber={currentPage} scale={scaleFunc()} />
        </Document>
      </Box>
      {displayPagination && (
        <PaginationWrapper mt={0}>
          <Pagination
            RouterLink={RouterLink}
            getTargetPath={() => ""}
            page={currentPage}
            perPage={1}
            count={totalPages}
            onChange={handleChange}
          />
        </PaginationWrapper>
      )}
    </Suspense>
  );
};
