import { actionTypes as gghActionTypes } from "@js/apps/give-and-get-help/action-types";
import type { ClickTrendingHashtagPayload } from "@js/apps/give-and-get-help/actions";
import { footerActionButtonsAnalyticsHandler } from "@js/middlewares/analytics/footer-action-buttons-analytics-handler";
import { handleHashtags } from "@js/middlewares/analytics/handle-hashtags";
import type { TrackableUserAction } from "@js/middlewares/analytics/types";
import { Events } from "@js/services/analytics";
import { postEventProperties } from "@js/services/analytics/constants";
import { logEvent } from "@js/services/analytics/event-logging";

type AllowedKeys = keyof typeof postEventProperties;
export type PostEventProperties = Partial<Record<AllowedKeys, unknown>>;
export const handleFeedAnalyticsActions = (action: TrackableUserAction) => {
  switch (action.type) {
    case gghActionTypes["footer-action-button-clicked"]: {
      footerActionButtonsAnalyticsHandler(action.payload);
      break;
    }

    case gghActionTypes["create/modal-opened"]: {
      logEvent(Events.INITIATE_POST_COMPOSER, action.payload);
      break;
    }

    case gghActionTypes["create/modal-closed"]: {
      logEvent(Events.CLOSE_POST_COMPOSER, action.payload);
      break;
    }

    case gghActionTypes["create/finish-create-post"]: {
      logEvent(Events.FINISH_CREATE_POST, action.payload);
      break;
    }

    case gghActionTypes["create/category-added"]: {
      logEvent(Events.ADD_CATEGORY, action.payload);
      break;
    }

    case gghActionTypes["create/budget-added"]: {
      logEvent(Events.ADD_BUDGET, action.payload);
      break;
    }

    case gghActionTypes["create/next-step-clicked"]: {
      logEvent(Events.CLICK_NEXT_STEP, action.payload);
      break;
    }

    case gghActionTypes["create/user-started-typing-title"]: {
      logEvent(Events.ADD_TITLE, action.payload);
      break;
    }

    case gghActionTypes["create/user-started-typing-description"]: {
      logEvent(Events.ADD_CONTENT, action.payload);
      break;
    }

    case gghActionTypes["create/user-started-typing-poll-question"]: {
      logEvent(Events.ADD_POLL_QUESTION, action.payload);
      break;
    }

    case gghActionTypes["create/user-started-typing-poll-option"]: {
      logEvent(Events.ADD_POLL_OPTIONS, action.payload);
      break;
    }

    case gghActionTypes["post-sort-order-clicked"]: {
      if (action.payload === ENUMS.JobsListOrder.NEWEST) {
        logEvent(Events.POST_SORT_ORDER_RECENT);
        break;
      }
      logEvent(Events.POST_SORT_ORDER_TOP);
      break;
    }

    case gghActionTypes["report-clicked"]: {
      const { entity, post_location } = action.payload;

      const eventProperties: PostEventProperties = {
        [postEventProperties.post_id]: entity?.id,
        [postEventProperties.slot_position]: entity?.slot_position,
        [postEventProperties.post_location]: post_location,
        [postEventProperties.post_level]: entity?.level,
      };

      handleHashtags({
        post_location,
        eventProperties,
      });

      logEvent(Events.CLICK_REPORT_POST, eventProperties);
      break;
    }

    case gghActionTypes["report-sent"]: {
      const { postId, reportReason } = action.payload;

      const eventProperties: PostEventProperties = {
        [postEventProperties.post_id]: postId,
        [postEventProperties.report_reason]: reportReason,
      };

      logEvent(Events.CLICK_SEND_REPORT_POST, eventProperties);
      break;
    }

    case gghActionTypes["menu-clicked"]: {
      const { entity, post_location } = action.payload;

      const eventProperties: PostEventProperties = {
        [postEventProperties.post_id]: entity?.id,
        [postEventProperties.slot_position]: entity?.slot_position,
        [postEventProperties.post_location]: post_location,
        [postEventProperties.post_level]: entity?.level,
      };

      handleHashtags({
        post_location,
        eventProperties,
      });

      logEvent(Events.CLICK_POST_OPTION, eventProperties);
      break;
    }

    case gghActionTypes["copy-clicked"]: {
      const { entity, post_location, linkUrl } = action.payload;

      const eventProperties: PostEventProperties = {
        [postEventProperties.post_id]: entity?.id,
        [postEventProperties.slot_position]: entity?.slot_position,
        [postEventProperties.post_location]: post_location,
        [postEventProperties.link_URL]: linkUrl,
        [postEventProperties.post_level]: entity?.level,
      };

      handleHashtags({
        post_location,
        eventProperties,
      });

      logEvent(Events.COPY_LINK, eventProperties);
      break;
    }

    case gghActionTypes["reply-clicked"]: {
      const { id, slot_position, post_location, level } = action.payload;

      const eventProperties: PostEventProperties = {
        [postEventProperties.post_id]: id,
        [postEventProperties.slot_position]: slot_position,
        [postEventProperties.post_location]: post_location,
        [postEventProperties.post_level]: level,
      };

      handleHashtags({
        post_location,
        eventProperties,
      });

      logEvent(Events.CLICK_REPLY, eventProperties);
      break;
    }

    case gghActionTypes["save-post-clicked"]: {
      const { id, slot_position, post_location, level } = action.payload;

      const eventProperties: PostEventProperties = {
        [postEventProperties.post_id]: id,
        [postEventProperties.slot_position]: slot_position,
        [postEventProperties.post_location]: post_location,
        [postEventProperties.post_level]: level,
      };

      logEvent(Events.SAVE_POST, eventProperties);

      break;
    }

    case gghActionTypes["reply-user-started-typing"]: {
      const { post, comment, post_location } = action.payload;

      const eventProperties: PostEventProperties = {
        [postEventProperties.post_id]: comment ? comment.id : post?.id,
        [postEventProperties.slot_position]: post?.slot_position,
        [postEventProperties.post_location]: post_location,
        [postEventProperties.post_level]: comment?.level ?? post?.level,
      };

      handleHashtags({
        post_location,
        eventProperties,
      });

      logEvent(Events.START_REPLY, eventProperties);
      break;
    }

    case gghActionTypes["responded-to-post"]: {
      const { post, post_location } = action.payload;

      const eventProperties: PostEventProperties = {
        [postEventProperties.post_id]: post.id,
        [postEventProperties.slot_position]: post.slot_position,
        [postEventProperties.post_location]: post_location,
        [postEventProperties.post_level]: post?.level,
      };

      handleHashtags({
        post_location,
        eventProperties,
      });

      logEvent(Events.RESPOND_TO_POST, eventProperties);
      break;
    }

    case gghActionTypes["interested-clicked"]: {
      const { comment, post, post_location } = action.payload;

      const eventProperties: PostEventProperties = {
        [postEventProperties.post_id]: comment?.id,
        [postEventProperties.slot_position]: post?.slot_position,
        [postEventProperties.post_location]: post_location,
        [postEventProperties.post_level]: comment?.level,
      };

      handleHashtags({
        post_location,
        eventProperties,
      });

      logEvent(Events.CLICK_INTERESTED, eventProperties);
      break;
    }

    case gghActionTypes["offer-price-clicked"]: {
      const { comment, post, data, post_location } = action.payload;

      const eventProperties: PostEventProperties = {
        [postEventProperties.post_id]: comment?.id ?? post?.id,
        [postEventProperties.slot_position]: post?.slot_position,
        [postEventProperties.post_location]: post_location,
        [postEventProperties.price_option]: data,
        [postEventProperties.post_level]: comment?.level ?? post?.level,
      };

      handleHashtags({
        post_location,
        eventProperties,
      });

      logEvent(Events.ADD_OFFER_PRICE_REPLY, eventProperties);
      break;
    }

    case gghActionTypes["viewed"]: {
      const { postId, entity, post_location } = action.payload;

      const eventProperties: PostEventProperties = {
        [postEventProperties.post_id]: postId,
        [postEventProperties.slot_position]: entity.slot_position,
        [postEventProperties.post_location]: post_location,
        [postEventProperties.post_level]: entity.level,
      };

      handleHashtags({
        post_location,
        eventProperties,
      });

      logEvent(Events.VIEW_POST, eventProperties);
      break;
    }

    case gghActionTypes["expanded"]: {
      const { entity, post_location } = action.payload;

      const eventProperties: PostEventProperties = {
        [postEventProperties.post_id]: entity.id,
        [postEventProperties.slot_position]: entity.slot_position,
        [postEventProperties.post_location]: post_location,
        [postEventProperties.post_level]: entity.level,
      };

      handleHashtags({
        post_location,
        eventProperties,
      });

      logEvent(Events.EXPAND_POST, eventProperties);
      break;
    }

    case gghActionTypes["collapsed"]: {
      const { entity, post_location } = action.payload;

      const eventProperties: PostEventProperties = {
        [postEventProperties.post_id]: entity.id,
        [postEventProperties.slot_position]: entity.slot_position,
        [postEventProperties.post_location]: post_location,
        [postEventProperties.post_level]: entity.level,
      };

      handleHashtags({
        post_location,
        eventProperties,
      });

      logEvent(Events.COLLAPSE_POST, eventProperties);
      break;
    }

    case gghActionTypes["user-name-clicked"]: {
      const { entity, post_location } = action.payload;

      const eventProperties: PostEventProperties = {
        [postEventProperties.post_id]: entity.id,
        [postEventProperties.slot_position]: entity.slot_position,
        [postEventProperties.post_location]: post_location,
        [postEventProperties.post_level]: entity.level,
      };

      handleHashtags({
        post_location,
        eventProperties,
      });

      logEvent(Events.CLICK_USER_PROFILE, eventProperties);
      break;
    }
    case gghActionTypes["view-profile-hover-state-clicked"]: {
      logEvent(Events.CLICK_VIEW_PROFILE_HOVER, action.payload);
      break;
    }

    case gghActionTypes["talent-name-hover-state-clicked"]: {
      logEvent(Events.CLICK_TALENT_NAME_HOVER, action.payload);
      break;
    }

    case gghActionTypes["career-help-through-my-posts-clicked"]: {
      logEvent(Events.CLICK_CAREER_HELP_THROUGH_MY_POSTS, action.payload);
      break;
    }

    case gghActionTypes["follow-clicked"]: {
      const { entity, post_location } = action.payload;

      const eventProperties: PostEventProperties = {
        [postEventProperties.post_id]: entity.id,
        [postEventProperties.slot_position]: entity.slot_position,
        [postEventProperties.post_location]: post_location,
        [postEventProperties.post_level]: entity.level,
      };

      logEvent(Events.FOLLOW_POST, eventProperties);
      break;
    }

    case gghActionTypes["unfollow-clicked"]: {
      const { entity, post_location } = action.payload;

      const eventProperties: PostEventProperties = {
        [postEventProperties.post_id]: entity.id,
        [postEventProperties.slot_position]: entity.slot_position,
        [postEventProperties.post_location]: post_location,
        [postEventProperties.post_level]: entity.level,
      };

      logEvent(Events.UNFOLLOW_POST, eventProperties);
      break;
    }

    case gghActionTypes["get-inspired-btn-clicked"]: {
      logEvent(Events.CLICK_GET_INSPIRED);
      break;
    }

    case Events.CLICK_POST_HASHTAG: {
      logEvent(Events.CLICK_POST_HASHTAG, action.payload);
      break;
    }

    case Events.CLICK_TRENDING_HASHTAG: {
      const { hashtag_id }: ClickTrendingHashtagPayload = action.payload;

      logEvent(Events.CLICK_TRENDING_HASHTAG, { hashtag_id });
      break;
    }
    default: {
      break;
    }
  }
};
