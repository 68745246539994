import { useGetFreelancerJobsQuery } from "@js/apps/freelancer/api";

export const useFreelancerJobs = ({ completed }: { completed: boolean }) => {
  const { isLoading, data: jobs } = useGetFreelancerJobsQuery({
    completed,
    show_jobs_of_removed_users: true,
  });

  return {
    jobs,
    isLoading,
  };
};
