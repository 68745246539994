import { SubmissionError } from "redux-form";
import axios from "axios";

import {
  ADD_NOT_INTERESTED_JOB_ID,
  CERTIFICATE_ADDED,
  CERTIFICATE_DELETED,
  CERTIFICATE_EDITED,
  CLICK_AVAILABILITY_TOGGLE,
  CLICK_CHECK_AVAILABILITY_OPTION,
  CLICK_INVITE_TALENT_AFTER_SEARCH,
  CLICK_SET_AVAILABILITY,
  CLICK_TALENT_AFTER_SEARCH,
  CLICK_UPDATE_AVAILABILITY_MENU,
  CLICK_UPDATE_AVAILABILITY_PROFILE,
  CLOSE_POST_ETIQUETTE_PANEL,
  DELETE_NOT_INTERESTED_JOB_ID,
  FETCH_FREELANCER_EXTERNAL_SITES,
  FETCH_FREELANCER_JOBS_SELECT,
  FETCH_FREELANCER_JOBS_SELECT_FAILED,
  FETCH_FREELANCER_JOBS_SELECT_SUCCESS,
  FETCH_FREELANCER_PROFILE,
  FETCH_FREELANCER_PROFILE_FAILED,
  FETCH_FREELANCER_PROFILE_SUCCESS,
  FETCH_FREELANCER_PUBLIC_PROFILE_SUCCESS,
  FETCHING_FREELANCER_ARTICLES,
  FETCHING_FREELANCER_ARTICLES_FAILED,
  FETCHING_FREELANCER_ARTICLES_SUCCESS,
  FETCHING_FREELANCER_EVENTS,
  FETCHING_FREELANCER_EVENTS_FAILED,
  FETCHING_FREELANCER_EVENTS_SUCCESS,
  FETCHING_FREELANCER_OFFERS,
  FETCHING_FREELANCER_OFFERS_FAILED,
  FETCHING_FREELANCER_OFFERS_SUCCESS,
  FREELANCER_PROFILE_CLICKED,
  FREELANCER_PROFILE_VIEWED,
  NAV_ITEM_CLICKED,
  SCHOOL_ADDED,
  SCHOOL_DELETED,
  SCHOOL_EDITED,
  SKILLS_SAVED,
  UPDATE_CERTIFICATES_POSITION,
  UPDATE_FREELANCER_PROFILE_SUCCESS,
  UPDATE_WORK_SAMPLE_POSITION,
  WORK_EXPERIENCE_ADDED,
  WORK_EXPERIENCE_DELETED,
  WORK_EXPERIENCE_EDITED,
} from "@js/apps/freelancer/action-types";
import type { NAV_ITEM_IDS } from "@js/constants";
import type { AppThunkAction } from "@js/store";
import type { Freelancer } from "@js/types/freelancer";

import { Snackbar } from "../../components/snackbar";

import type { TALENT_LOCATION } from "./constants";

export const fetchFreelancerProfile =
  (freelancerId: number): AppThunkAction<Promise<Freelancer>> =>
  (dispatch) =>
    new Promise((resolve) => {
      const url = `/api/manage_freelancers/${freelancerId}/`;
      dispatch({
        type: FETCH_FREELANCER_PROFILE,
      });

      return axios
        .get<Freelancer>(url)
        .then((response) => {
          dispatch({
            type: FETCH_FREELANCER_PROFILE_SUCCESS,
            payload: response.data,
          });

          resolve(response.data);
        })
        .catch(() => {
          dispatch({
            type: FETCH_FREELANCER_PROFILE_FAILED,
          });
        });
    });

export const updateFreelancerProfile =
  (
    values,
    freelancerProfile,
    updateMethod = "patch",
  ): AppThunkAction<Promise<any>> =>
  (dispatch) =>
    new Promise((resolve, reject) => {
      const method = freelancerProfile ? updateMethod : "post";
      const freelancerId =
        typeof freelancerProfile === "number"
          ? freelancerProfile
          : freelancerProfile?.id;
      const url = `/api/manage_freelancers/${
        freelancerId ? `${freelancerId}/` : ""
      }`;

      return axios[method](url, values)
        .then((response) => {
          dispatch({
            type: UPDATE_FREELANCER_PROFILE_SUCCESS,
            payload: response.data,
          });

          resolve(response.data);
        })
        .catch((error) => {
          return reject(new SubmissionError(error.response?.data));
        });
    });

export const updateCompanyName = (freelancerId, values) =>
  new Promise((resolve, reject) => {
    return axios
      .patch(`/api/manage_freelancers/${freelancerId}/`, values)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error.response.data));
  });

export const fetchFreelancerPublicProfile =
  (id: number, params?: { unaltered: boolean }): AppThunkAction<Promise<any>> =>
  (dispatch) =>
    new Promise((resolve, reject) =>
      axios
        .get(`/api/freelancers/${id}/`, { params })
        .then((response) => {
          dispatch({
            type: FETCH_FREELANCER_PUBLIC_PROFILE_SUCCESS,
            payload: response.data,
          });

          resolve(response.data);
        })
        .catch((error) => reject(error)),
    );

export const deleteCertificate =
  (certificateId: number): AppThunkAction<Promise<any>> =>
  (dispatch) =>
    axios
      .delete(`/api/freelancer_certificates/${certificateId}/`)
      .then((response) => {
        dispatch(certificateDeleted());
        return response;
      });

export const createNewCertificate =
  (values): AppThunkAction<Promise<any>> =>
  (dispatch) =>
    new Promise((resolve, reject) => {
      return axios
        .post(`/api/freelancer_certificates/`, values)
        .then((response) => {
          dispatch(certificateAdded());
          resolve(response.data);
        })
        .catch((error) => reject(new SubmissionError(error.response.data)));
    });

export const updateCertificate =
  (id, values): AppThunkAction<Promise<any>> =>
  (dispatch) =>
    new Promise((resolve, reject) => {
      axios
        .patch(`/api/freelancer_certificates/${id}/`, values)
        .then((response) => {
          dispatch(certificateEdited());
          resolve(response.data);
        })
        .catch((error) => reject(new SubmissionError(error.response.data)));
    });

export const deleteSchool =
  (schoolId): AppThunkAction<Promise<any>> =>
  (dispatch) =>
    axios.delete(`/api/freelancer_schools/${schoolId}/`).then((response) => {
      dispatch(schoolDeleted());
      return response;
    });

export const createNewSchool =
  (values): AppThunkAction<Promise<any>> =>
  (dispatch) =>
    new Promise((resolve, reject) => {
      return axios
        .post(`/api/freelancer_schools/`, values)
        .then((response) => {
          dispatch(schoolAdded());
          resolve(response.data);
        })
        .catch((error) => reject(new SubmissionError(error.response.data)));
    });

export const updateSchool =
  (id, values): AppThunkAction<Promise<any>> =>
  (dispatch) =>
    new Promise((resolve, reject) => {
      axios
        .patch(`/api/freelancer_schools/${id}/`, values)
        .then((response) => {
          dispatch(schoolEdited());
          resolve(response.data);
        })
        .catch((error) => reject(new SubmissionError(error.response.data)));
    });

export const deleteWorkExperience =
  (workExperienceId): AppThunkAction<Promise<any>> =>
  (dispatch) =>
    axios
      .delete(`/api/freelancer_work_experience/${workExperienceId}/`)
      .then((response) => {
        dispatch(workExperienceDeleted());
        return response;
      });

export const createWorkExperience =
  (values): AppThunkAction<Promise<any>> =>
  (dispatch) =>
    new Promise((resolve, reject) => {
      axios
        .post(`/api/freelancer_work_experience/`, values)
        .then((response) => {
          dispatch(workExperienceAdded());
          resolve(response.data);
        })
        .catch((error) => reject(new SubmissionError(error.response.data)));
    });

export const updateWorkExperience =
  (id, values): AppThunkAction<Promise<any>> =>
  (dispatch) =>
    new Promise((resolve, reject) => {
      axios
        .patch(`/api/freelancer_work_experience/${id}/`, values)
        .then((response) => {
          dispatch(workExperienceEdited());
          resolve(response.data);
        })
        .catch((error) => reject(new SubmissionError(error.response.data)));
    });

export const fetchExternalSites =
  (): AppThunkAction<Promise<any>> => (dispatch) =>
    new Promise((resolve) =>
      axios.get(`/api/external_sites/`).then((response) => {
        dispatch({
          type: FETCH_FREELANCER_EXTERNAL_SITES,
          payload: response.data,
        });

        resolve(response.data);
      }),
    );

export const fetchFreelancerJobsSelect =
  (): AppThunkAction<Promise<any>> => (dispatch) =>
    new Promise((resolve) => {
      dispatch({
        type: FETCH_FREELANCER_JOBS_SELECT,
      });

      return axios
        .get(`/api/freelancer_jobs_select_project/`)
        .then((response) => {
          dispatch({
            type: FETCH_FREELANCER_JOBS_SELECT_SUCCESS,
            payload: response.data,
          });

          resolve(response.data);
        })
        .catch(() =>
          dispatch({
            type: FETCH_FREELANCER_JOBS_SELECT_FAILED,
          }),
        );
    });

export const fetchFreelancerOffers =
  (params?: {
    section: EnumType<typeof ENUMS.TalentOffersSectionFilter>;
  }): AppThunkAction<Promise<any>> =>
  (dispatch) =>
    new Promise((resolve) => {
      const controller = new AbortController();

      dispatch({
        type: FETCHING_FREELANCER_OFFERS,
      });

      return axios
        .get(`/api/freelancer_offers/`, { params, signal: controller.signal })
        .then((response) => {
          dispatch({
            type: FETCHING_FREELANCER_OFFERS_SUCCESS,
            payload: response.data,
          });

          resolve(response.data);
        })
        .catch((error) => {
          if (axios.isCancel(error)) {
            return;
          }

          dispatch({
            type: FETCHING_FREELANCER_OFFERS_FAILED,
          });

          Snackbar.error(error.response.data);
        });
    });

export const fetchEvents =
  (params): AppThunkAction<Promise<any>> =>
  (dispatch) =>
    new Promise((resolve) => {
      dispatch({
        type: FETCHING_FREELANCER_EVENTS,
      });

      return axios
        .get(`/api/events/`, { params })
        .then((response) => {
          dispatch({
            type: FETCHING_FREELANCER_EVENTS_SUCCESS,
            payload: response.data,
          });

          resolve(response.data);
        })
        .catch((error) => {
          dispatch({
            type: FETCHING_FREELANCER_EVENTS_FAILED,
          });

          Snackbar.error(error.response.data);
        });
    });

export const fetchArticles =
  (params): AppThunkAction<Promise<any>> =>
  (dispatch) =>
    new Promise((resolve) => {
      dispatch({
        type: FETCHING_FREELANCER_ARTICLES,
      });

      return axios
        .get("/api/articles/", { params })
        .then((response) => {
          dispatch({
            type: FETCHING_FREELANCER_ARTICLES_SUCCESS,
            payload: response.data,
          });

          resolve(response.data);
        })
        .catch(() => {
          dispatch({
            type: FETCHING_FREELANCER_ARTICLES_FAILED,
          });

          Snackbar.error({ message: "Failed to fetch articles" });
        });
    });

export const skillsSaved = (payload) => ({
  type: SKILLS_SAVED,
  payload,
});

const workExperienceAdded = () => ({
  type: WORK_EXPERIENCE_ADDED,
});

const certificateAdded = () => ({
  type: CERTIFICATE_ADDED,
});

const schoolAdded = () => ({
  type: SCHOOL_ADDED,
});

const workExperienceEdited = () => ({
  type: WORK_EXPERIENCE_EDITED,
});

const certificateEdited = () => ({
  type: CERTIFICATE_EDITED,
});

const schoolEdited = () => ({
  type: SCHOOL_EDITED,
});

const workExperienceDeleted = () => ({
  type: WORK_EXPERIENCE_DELETED,
});

const certificateDeleted = () => ({
  type: CERTIFICATE_DELETED,
});

const schoolDeleted = () => ({
  type: SCHOOL_DELETED,
});

export const freelancerProfileViewed = () => ({
  type: FREELANCER_PROFILE_VIEWED,
});

export const freelancerProfileClicked = () => ({
  type: FREELANCER_PROFILE_CLICKED,
});

export const updateWorkSamplePosition = (items) => ({
  type: UPDATE_WORK_SAMPLE_POSITION,
  payload: items,
});

export const updateCertificatesPosition = (items) => ({
  type: UPDATE_CERTIFICATES_POSITION,
  payload: items,
});

export const navItemClicked = (payload: {
  nav_item_id: NAV_ITEM_IDS;
  nav_item_href?: string;
  context_url: string;
}) => {
  return {
    type: NAV_ITEM_CLICKED,
    payload,
  };
};

enum TalentSourceComponents {
  TALENT_NAME = "Talent name",
  TALENT_AVATAR = "Talent avatar",
  INVITE_BUTTON = "Invite button",
}

export type TalentAfterSearchPayload = {
  talentId: number;
  resultPosition: number;
  location: EnumType<typeof TALENT_LOCATION>;
  searchQueryId: number | undefined;
};

export const clickTalentNameAfterSearch = (
  payload: TalentAfterSearchPayload,
) => {
  return {
    type: CLICK_TALENT_AFTER_SEARCH,
    payload: { ...payload, component: TalentSourceComponents.TALENT_NAME },
  };
};

export const clickTalentAvatarAfterSearch = (
  payload: TalentAfterSearchPayload,
) => {
  return {
    type: CLICK_TALENT_AFTER_SEARCH,
    payload: { ...payload, component: TalentSourceComponents.TALENT_AVATAR },
  };
};

export const clickInviteAfterSearch = (payload: TalentAfterSearchPayload) => {
  return {
    type: CLICK_INVITE_TALENT_AFTER_SEARCH,
    payload: { ...payload, component: TalentSourceComponents.INVITE_BUTTON },
  };
};

export const clickUpdateAvailabilityMenu = () => ({
  type: CLICK_UPDATE_AVAILABILITY_MENU,
});

export const clickUpdateAvailabilityProfile = () => ({
  type: CLICK_UPDATE_AVAILABILITY_PROFILE,
});

export const clickAvailabilityToggle = (payload) => ({
  type: CLICK_AVAILABILITY_TOGGLE,
  payload,
});

export const clickCheckAvailabilityOption = (payload) => ({
  type: CLICK_CHECK_AVAILABILITY_OPTION,
  payload,
});

export const clickSetAvailability = () => ({
  type: CLICK_SET_AVAILABILITY,
});

export const closePostEtiquettePanel = () => ({
  type: CLOSE_POST_ETIQUETTE_PANEL,
});

export const addNotInterestedJobId = (payload) => ({
  type: ADD_NOT_INTERESTED_JOB_ID,
  payload,
});

export const deleteNotInterestedJobId = (payload) => ({
  type: DELETE_NOT_INTERESTED_JOB_ID,
  payload,
});
