import React from "react";

import { useIsNodeStaff } from "@js/apps/common/hooks";
import { useAccountType } from "@js/apps/common/hooks";
import type { FreelancerBid, Job } from "@js/types/jobs";

import { CoreTeamActionBarProvider } from "./core-team/core-team-context";
import { TalentActionBarProvider } from "./talent/context";
import { CoreTeamActionBar } from "./core-team";
import { EmployerActionBar } from "./employer";
import { JobActionBarProvider } from "./job-context";
import { TalentActionBar } from "./talent";

type JobDetailsActionBarProps = {
  job: Job;
  bid?: FreelancerBid;
};

export const JobDetailsActionBar = ({ job, bid }: JobDetailsActionBarProps) => {
  const { isFreelancerApproved, isEmployer, isFreelancer } = useAccountType();
  const isNodeStaff = useIsNodeStaff();

  if (isNodeStaff) {
    return (
      <CoreTeamActionBarProvider job={job}>
        <CoreTeamActionBar />
      </CoreTeamActionBarProvider>
    );
  }

  if (isFreelancer) {
    if (!isFreelancerApproved && job.job_type !== ENUMS.JobType.GRANT) {
      return null;
    }
  }

  if (isEmployer) {
    return (
      <JobActionBarProvider job={job}>
        <EmployerActionBar />
      </JobActionBarProvider>
    );
  }

  return (
    <TalentActionBarProvider job={job} bid={bid}>
      <JobActionBarProvider job={job}>
        <TalentActionBar />
      </JobActionBarProvider>
    </TalentActionBarProvider>
  );
};
