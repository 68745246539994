import { API } from "@js/api";
import type { SettingsState } from "@js/types/settings";

type NotificationsSettingsResponse = SettingsState["notifications"];
type UpdateNotificationsSettingsParams =
  SettingsState["notifications"]["general_notifications_settings"];

const settingsApi = API.injectEndpoints({
  endpoints: (build) => ({
    getNotificationsSettings: build.query<NotificationsSettingsResponse, void>({
      query: () => ({
        url: "/notification_settings/",
        method: "GET",
      }),
    }),
    updateNotificationsSettings: build.mutation<
      NotificationsSettingsResponse,
      UpdateNotificationsSettingsParams
    >({
      query: (data) => ({
        url: "/notification_settings/",
        method: "PATCH",
        data: {
          general_notifications_settings: data,
        },
      }),
      async onQueryStarted(_arg, { dispatch, queryFulfilled }) {
        try {
          const { data: updatedPost } = await queryFulfilled;
          dispatch(
            settingsApi.util.updateQueryData(
              "getNotificationsSettings",
              undefined,
              (draft) => {
                Object.assign(draft, updatedPost);
              },
            ),
          );
        } catch (e) {
          console.error(e);
        }
      },
    }),
    savePassword: build.mutation<
      void,
      {
        current_password: string;
        password1: string;
        password2: string;
      }
    >({
      query: (payload) => ({
        url: `/account_settings/change_password/`,
        method: "POST",
        data: payload,
      }),
    }),
  }),
});

export const {
  useGetNotificationsSettingsQuery,
  useUpdateNotificationsSettingsMutation,
  useSavePasswordMutation,
} = settingsApi;
