import { useABExperiment } from "@js/apps/experiments";

export const useShowBuyBTRSTButton = () => {
  const { isParticipating, experiment } = useABExperiment(
    SETTINGS.EXPERIMENT_ENUMS.Experiments.BuyBTRSTViaCoinbase,
  );

  const isBuyBTRSTViaCoinbaseEnabledVariant =
    experiment?.variant ===
    SETTINGS.EXPERIMENT_ENUMS[
      SETTINGS.EXPERIMENT_ENUMS.Experiments.BuyBTRSTViaCoinbase
    ].BuyBTRSTViaCoinbaseEnabled;

  return {
    showBuyBTRSTButton: isParticipating && isBuyBTRSTViaCoinbaseEnabledVariant,
  };
};
