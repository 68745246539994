import { submit } from "redux-form";

import { Typography } from "@hexocean/braintrust-ui-components";
import { ModalConfirm } from "@js/components/modal";
import { createFormInstance } from "@js/forms/components";
import { useAppDispatch } from "@js/hooks";

type FormData = {
  budget: string;
};

const ISSUE_OFFER_REFUND_FORM = "issue-offer-refund";

export const IssueOfferRefundForm = createFormInstance<FormData, unknown>(
  ISSUE_OFFER_REFUND_FORM,
);

type IssueOfferRefundModalFieldsProps = {
  budget?: string | number;
  askerFirstName: string;
  offerCategory: string;
};

export const IssueOfferRefundModalFields = ({
  budget,
  askerFirstName,
  offerCategory,
}: IssueOfferRefundModalFieldsProps) => {
  const dispatch = useAppDispatch();
  return (
    <ModalConfirm
      confirmText="Issue refund"
      onConfirm={() => dispatch(submit(ISSUE_OFFER_REFUND_FORM))}
      confirmButtonStyle={{
        borderRadius: "12px",
      }}
    >
      <Typography mb={3} component="p" variant="label" size="large">
        Issue a refund
      </Typography>
      <Typography mb={3} component="p" variant="title" fontWeight={400}>
        Issue a full refund to {askerFirstName}
      </Typography>
      <Typography mb={3} component="p" variant="paragraph" size="medium">
        A refund of{" "}
        <Typography component="span" fontWeight={500}>
          ${budget}{" "}
        </Typography>
        in{" "}
        <Typography component="span" fontWeight={500}>
          BTRST{" "}
        </Typography>
        will be transferred from your wallet back to{" "}
        <Typography component="span" fontWeight={500}>
          {askerFirstName}
        </Typography>
        . Your offer for{" "}
        <Typography component="span" fontWeight={500}>
          {offerCategory}{" "}
        </Typography>
        will be marked{" "}
        <Typography component="span" fontWeight={500}>
          Complete.
        </Typography>
      </Typography>

      {/* TODO The options button for partial refund are postponed. Uncomment and import for Partial Refund */}
      {/*<RefundBudgetField budget={+budget} />*/}
    </ModalConfirm>
  );
};
