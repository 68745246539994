import type { getFreelancerNavMenuItems } from "@js/apps/dashboard/nav-items";
import { SpacesSubNavLabel } from "@js/apps/spaces/components";
import type { GetMySpacesResponse } from "@js/apps/spaces/types";

type Arg = ReturnType<typeof getFreelancerNavMenuItems>;
export const applySpacesSubElementsToFreelancerNavbar = (
  elements: Arg,
  data: GetMySpacesResponse[] | undefined,
): Arg => {
  return elements.map((item) => {
    if (item.path === "/feed") {
      const originalSubLinks = item.subLinks;
      return {
        ...item,
        subLinks: originalSubLinks?.map((subLink) => {
          if (subLink.path === "/spaces") {
            return {
              ...subLink,
              subLinks:
                data?.map((space) => ({
                  path: `/spaces/${space.id}`,
                  label: (
                    <SpacesSubNavLabel
                      key={space.name}
                      spaceName={space.name}
                      notificationCounter={space.notifications_count}
                      avatar={space?.avatar_thumbnail}
                    />
                  ),
                })) || [],
            };
          }
          return subLink;
        }),
      };
    }
    return item;
  });
};
