import React from "react";
import { useLocation } from "react-router-dom";
import { Outlet } from "react-router-dom";

import { Box, UnderlinedTabs } from "@hexocean/braintrust-ui-components";
import { useMediaQuery } from "@hexocean/braintrust-ui-components/hooks";
import { RouterLink } from "@js/components/link";

import DashboardLayout from "./layout";
type Item = {
  label: string;
  path: string;
};

type MyWorkListingPageProps = {
  items: Item[];
  pageTitle: string;
};

export const MyWorkListingPage = ({
  items,
  pageTitle,
}: MyWorkListingPageProps) => {
  const { pathname } = useLocation();
  const isMobile = useMediaQuery(500);
  const labelSize = isMobile ? "medium" : "large";

  return (
    <DashboardLayout
      pageTitle={pageTitle}
      contentClassName="dashboard-layout-content"
    >
      <Box className="listings-content-wrapper">
        <Box
          component="nav"
          mb={{ xs: 3, sm: 4 }}
          mt="5px"
          display="flex"
          flexDirection={{ xs: "column", sm: "row" }}
          justifyContent="space-between"
          alignItems="flex-end"
        >
          <UnderlinedTabs
            items={items}
            pathname={pathname}
            labelUnActiveColor="tertiary"
            fontWeight={400}
            labelSize={labelSize}
            RouterLink={RouterLink}
            resetLeftPadding
            spacing={0}
            itemsGap="24px"
            itemProps={{
              className: "listings-content-wrapper__tab",
            }}
            padding="0"
            margin="0"
          />
        </Box>
        <Outlet />
      </Box>
    </DashboardLayout>
  );
};
