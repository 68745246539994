import { useState } from "react";
import { Field, isSubmitting } from "redux-form";

import {
  Box,
  Button,
  Chip,
  Typography,
} from "@hexocean/braintrust-ui-components";
import { GraterOrEqualZeroNumberFormat } from "@hexocean/braintrust-ui-components";
import { InfoIcon } from "@hexocean/braintrust-ui-components/Icons";
import { RouterLink } from "@js/components/link";
import { createFormInstance } from "@js/forms/components";
import { CheckboxField, NumberField, TextField } from "@js/forms/fields";
import { maxLength } from "@js/forms/validators";
import { useAppSelector } from "@js/hooks";
import { useNumberToWords } from "@js/hooks/number-to-words";
import { formatTokenAmount, pluralize } from "@js/utils";

const maxLengthValidator = maxLength(42);

export const WITHDRAWAL_FORM_ID = "withdrawalForm";

const validate = (values) => {
  const errors: Record<string, string> = {};
  if (!values.consent) {
    errors.consent = "Please agree to the terms and conditions";
  }
  if (!values.confirm_wallet) {
    errors.confirm_wallet =
      "Please confirm the wallet is using the Base network";
  }
  return errors;
};

type FormData = {
  amount: string;
  wallet_address: string;
  consent: boolean;
  is_backup_code: boolean;
  confirm_wallet: boolean;
};

type FormProps = {
  onCancel: () => void;
};

export const WithdrawalBTRSTFormInstance = createFormInstance<
  FormData,
  unknown
>(WITHDRAWAL_FORM_ID, {
  enableReinitialize: true,
  destroyOnUnmount: true,
  validate,
});

export const WithdrawalBTRSTFormContent = ({ onCancel }: FormProps) => {
  const [tokenNum, setTokenNum] = useState<string>("");
  const {
    result: tokenNumInWords,
    normalizedValue,
    error,
  } = useNumberToWords(tokenNum);
  const _isSubmitting = useAppSelector((state) =>
    isSubmitting(WITHDRAWAL_FORM_ID)(state),
  );

  return (
    <>
      <Box mt={4} mb={3}>
        <Field
          component={NumberField}
          onChange={(e) => {
            return setTokenNum(e.toString());
          }}
          helperText={
            <>
              {error && (
                <Typography component="p" size="small" color="negative">
                  {error}
                </Typography>
              )}
              {tokenNumInWords && (
                <Typography component="p" size="small">
                  Withdrawing {tokenNumInWords} BTRST
                  {pluralize(Math.floor(+normalizedValue))}
                </Typography>
              )}
              You must transfer at least {SETTINGS.TOKEN_MIN_WITHDRAWAL_AMOUNT}{" "}
              tokens, and no more than{" "}
              {formatTokenAmount(SETTINGS.TOKEN_MAX_WITHDRAWAL_AMOUNT)}.
            </>
          }
          id="amount"
          label="Amount of BTRST to transfer"
          name="amount"
          placeholder="ex.: 2000"
          NumberFormat={GraterOrEqualZeroNumberFormat}
          InputProps={{ style: { maxWidth: 200 } }}
        />
      </Box>
      <Box mb={1}>
        <Field
          component={TextField}
          id="wallet_address"
          label="Wallet address"
          validate={[maxLengthValidator]}
          name="wallet_address"
          placeholder="Enter Base-compatible wallet address"
        />
      </Box>
      <Box bgcolor="var(--medium-orange)" borderRadius="8px" py={1} px={1.5}>
        <Typography component="p" variant="label" size="small" fontWeight={500}>
          Make sure the wallet you are using is setup on the Base network.
        </Typography>
        <Typography component="p" variant="label" size="small" fontWeight={400}>
          Your tokens will transfer to this wallet.
        </Typography>
      </Box>
      <Box mb={3} mt={3}>
        <Field
          component={CheckboxField}
          id="confirm_wallet"
          name="confirm_wallet"
          label={
            <span>I have confirmed the wallet is using the Base network.</span>
          }
        />
        <Field
          component={CheckboxField}
          id="consent"
          name="consent"
          label={
            <span>
              I agree to the&nbsp;
              <RouterLink
                style={{ textDecoration: "underline" }}
                target="_blank"
                rel="noopener noreferrer"
                to="https://www.usebraintrust.com/btrst-purchaser-terms"
              >
                terms and conditions
              </RouterLink>
              .
            </span>
          }
        />
      </Box>
      <Box mb={4}>
        <Chip
          color="soft-grey"
          icon={<InfoIcon />}
          label={
            <Typography component="span" variant="paragraph" size="small">
              View our{" "}
              <Typography
                component="link"
                variant="link"
                size="small"
                style={{
                  color: "var(--dark-grey)",
                }}
                href={
                  SETTINGS.SUPPORT_URLS.HOW_TO_GET_TOKEN_ON_COINBASE_ARTICLE_URL
                }
                target="_blank"
                RouterLink={RouterLink}
              >
                Wallet FAQs
              </Typography>
              .
            </Typography>
          }
          onClick={function noRefCheck() {}}
          onFocusVisible={function noRefCheck() {}}
        />
      </Box>
      <Box textAlign="right">
        {onCancel && (
          <Button variant="secondary" size="medium" onClick={onCancel}>
            Cancel
          </Button>
        )}
        <Button
          variant="primary"
          size="medium"
          disabled={_isSubmitting}
          type="submit"
          style={{ marginLeft: 8 }}
        >
          Continue to authentication
        </Button>
      </Box>
    </>
  );
};
