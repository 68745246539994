import { Typography } from "@hexocean/braintrust-ui-components";
import { useMediaQuery } from "@hexocean/braintrust-ui-components/hooks";
import { pluralize } from "@js/utils";

type HelpOfferStatusBadgeProps = {
  count?: number | null;
  status: "pending" | "active";
  icon: JSX.Element;
};

export const HelpOfferStatusBadge = ({
  count = 0,
  status,
  icon,
}: HelpOfferStatusBadgeProps): JSX.Element | null => {
  const isMobile = useMediaQuery("sm");
  if (!count) {
    return null;
  }
  return (
    <div className={`btn-reset offer-badge offer-badge__${status}`}>
      {!isMobile && icon}
      <Typography
        size="small"
        variant="paragraph"
        component="p"
        fontWeight={500}
      >
        {count} {status} {!isMobile && "offer" + pluralize(count)}
      </Typography>
    </div>
  );
};
