import React from "react";

import { Box, Button, Typography } from "@hexocean/braintrust-ui-components";
import { useSignUpEmailConfirmPage } from "@js/apps/onboarding/hooks/sign-up-confirm";
import { ModalInstance } from "@js/components/modal";

const DEFAULT_TITLE = "Click the link in your email!";

type SignUpConfirmationContentProps = {
  title?: string;
  align?: "center" | "left" | "right";
  envelopeIcon?: React.ReactNode;
};

export const SignUpConfirmationContent = ({
  title = DEFAULT_TITLE,
  align = "left",
  envelopeIcon,
}: SignUpConfirmationContentProps) => {
  const { email, handleEmailResend } = useSignUpEmailConfirmPage();

  if (!email) return null;

  return (
    <>
      <Box textAlign={align}>
        {envelopeIcon ? (
          envelopeIcon
        ) : (
          <img
            src={`${SETTINGS.STATIC_URL}onboarding/mail.svg`}
            alt="Email"
            width={92}
          />
        )}
      </Box>
      <Box textAlign={align}>
        <Typography
          component="h1"
          variant="title"
          fontWeight={400}
          mt={3}
          mb={2}
        >
          {title}
        </Typography>
        <Typography component="p">
          Click the link in the email we just sent to{" "}
          <Typography component="span" fontWeight={500} underline="yellow">
            {email}
          </Typography>
          <br />
          to verify your new Braintrust account. If you don’t see an email
          within 15 minutes,
          <br />
          check your spam folder or try to{" "}
          <Button
            variant="transparent"
            className="resend-verification-mail-button"
            onClick={handleEmailResend}
          >
            resend the email
          </Button>
          .
        </Typography>
      </Box>
    </>
  );
};

export const openEmailConfirmationModal = () => {
  ModalInstance.open({
    padding: true,
    closeButton: true,
    children: <SignUpConfirmationContent align="center" />,
  });
};
