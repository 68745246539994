//Root library
//Editor
import { LexicalComposer } from "@lexical/react/LexicalComposer";
import { $getRoot } from "lexical";

//Plugins

const AutoLinkPlugin = lazyWithRetry(async () => {
  const module = await import(
    /* webpackChunkName: "lexical-plugins" */ "@lexical/react/LexicalAutoLinkPlugin"
  );
  return { default: module.AutoLinkPlugin };
});
const RichTextPlugin = lazyWithRetry(async () => {
  const module = await import(
    /* webpackChunkName: "lexical-plugins" */ "@lexical/react/LexicalRichTextPlugin"
  );
  return { default: module.RichTextPlugin };
});

const ContentEditable = lazyWithRetry(async () => {
  const module = await import(
    /* webpackChunkName: "lexical-plugins" */ "@lexical/react/LexicalContentEditable"
  );
  return { default: module.ContentEditable };
});
const EditorRefPlugin = lazyWithRetry(async () => {
  const module = await import(
    /* webpackChunkName: "lexical-plugins" */ "@lexical/react/LexicalEditorRefPlugin"
  );
  return { default: module.EditorRefPlugin };
});
const OnChangePlugin = lazyWithRetry(async () => {
  const module = await import(
    /* webpackChunkName: "lexical-plugins" */ "@lexical/react/LexicalOnChangePlugin"
  );
  return { default: module.OnChangePlugin };
});
const HistoryPlugin = lazyWithRetry(async () => {
  const module = await import(
    /* webpackChunkName: "lexical-plugins" */ "@lexical/react/LexicalHistoryPlugin"
  );
  return { default: module.HistoryPlugin };
});

//Nodes
import { AutoLinkNode } from "@lexical/link";
//Types
import type { EditorState } from "lexical";

import { lazyWithRetry } from "@js/utils";

export {
  $getRoot,
  AutoLinkNode,
  AutoLinkPlugin,
  ContentEditable,
  EditorRefPlugin,
  EditorState,
  HistoryPlugin,
  LexicalComposer,
  OnChangePlugin,
  RichTextPlugin,
};
