import type { TypedWrappedFieldProps } from "redux-form";

import {
  Box,
  Button,
  Loader,
  Typography,
} from "@hexocean/braintrust-ui-components";
import type {
  LocationSelectReason,
  TalentMixedLocation,
} from "@js/apps/freelancer/types";
import type { PopularLocation } from "@js/types/common";

import { usePopularLocationsButtons } from "./hook";

type PopularLocationsButtonsProps = {
  input: TypedWrappedFieldProps<TalentMixedLocation[] | null>["input"];
  onChange: (
    data: Array<TalentMixedLocation | string>,
    reason: LocationSelectReason,
  ) => void;
};

export const PopularLocationsButtons = ({
  input,
  onChange,
}: PopularLocationsButtonsProps) => {
  const {
    handleOnClick,
    isBtnSelected,
    isBtnDisabled,
    popularLocationOptions,
  } = usePopularLocationsButtons({ input, onChange });

  if (!popularLocationOptions.length) return <Loader />;

  const generatedPopularLocationButtons = popularLocationOptions.map(
    (option) => {
      const { id: locationId, label } = getPopularLocationData(option);

      return (
        <div
          key={locationId}
          style={{
            cursor: isBtnDisabled(locationId) ? "not-allowed" : "initial",
          }}
        >
          <Button
            aria-pressed={isBtnSelected(locationId) ? true : false}
            variant={isBtnSelected(locationId) ? "primary" : "secondary"}
            disabled={isBtnDisabled(locationId)}
            shape="squared"
            className="create-job__popular-locations-buttons"
            onClick={() => handleOnClick(locationId)}
          >
            {label}
          </Button>
        </div>
      );
    },
  );

  return (
    <Box display="flex" flexDirection="column" mt={2}>
      <Typography
        component="label"
        mb={1}
        variant="label"
        size="medium"
        fontWeight={400}
      >
        Popular Talent locations
      </Typography>
      <Box display="flex" flexWrap="wrap" gap={1}>
        {generatedPopularLocationButtons}
      </Box>
    </Box>
  );
};

const getPopularLocationData = (location: PopularLocation) => {
  if (location.custom_location) {
    return {
      id: location.custom_location,
      label: location.name || location.custom_location,
    };
  }

  return {
    id: location.place_id as string,
    label: location.full_location?.formatted_address,
  };
};
