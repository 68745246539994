import { useEffect, useRef } from "react";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import {
  COMMAND_PRIORITY_CRITICAL,
  KEY_ENTER_COMMAND,
  KEY_ESCAPE_COMMAND,
} from "lexical";

type useReplaceEditorCommandsProps = {
  active: boolean;
  onEscape?: () => void;
};

const commandsToReplace = [KEY_ENTER_COMMAND, KEY_ESCAPE_COMMAND];

export const useReplaceEditorCommands = ({
  active,
  onEscape,
}: useReplaceEditorCommandsProps) => {
  const [editor] = useLexicalComposerContext();
  const commandsListenersRef = useRef<{ [command: string]: () => void }>({});

  const overrideListeners = () => {
    commandsToReplace.forEach((command) => {
      commandsListenersRef.current[command as string] = editor.registerCommand(
        command,
        () => {
          if (command === KEY_ESCAPE_COMMAND) {
            onEscape?.();
          }
          return true;
        },
        COMMAND_PRIORITY_CRITICAL,
      );
    });
  };

  const unregisterOverridedListeners = () => {
    commandsToReplace.forEach((command) => {
      commandsListenersRef.current[command as string]();
    });
  };

  useEffect(() => {
    if (active) {
      overrideListeners();
    } else {
      unregisterOverridedListeners();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [active]);
};
