import type { Location, NavigateFunction } from "react-router-dom";

import { Snackbar } from "@js/components/snackbar";
import { assertUnreachable } from "@js/utils";

import { confirmAction } from "../../actions";

const INITIAL_PATH_FOR_PAYMENT_ACTIONS = "/settings/payments";

type getConfirmActionFun = (
  action: EnumType<typeof ENUMS.ConfirmAction>,
  options: {
    closeModal: () => void;
    navigate: NavigateFunction;
    sourceLocation?: Location;
  },
) => {
  content: string;
  initialPathForAction: string;
  onConfirm: (query: Record<string, any>) => Promise<any>;
} | null;

export const getConfirmAction: getConfirmActionFun = (action, options) => {
  const { closeModal, navigate, sourceLocation } = options;

  switch (action) {
    case ENUMS.ConfirmAction.SET_WM_AS_DEFAULT: {
      return {
        content: "Do you want to confirm the banking information change?",
        initialPathForAction: INITIAL_PATH_FOR_PAYMENT_ACTIONS,
        onConfirm: (location) => {
          return confirmAction(location)
            .then(() => {
              closeModal();
              navigate(INITIAL_PATH_FOR_PAYMENT_ACTIONS);
              Snackbar.success("Banking information changed.");
            })
            .catch((error) => Snackbar.error(error));
        },
      };
    }
    case ENUMS.ConfirmAction.CONFIRM_WM: {
      return {
        content: "Do you want to confirm the banking information add?",
        initialPathForAction: INITIAL_PATH_FOR_PAYMENT_ACTIONS,
        onConfirm: (location) => {
          return confirmAction(location)
            .then(() => {
              closeModal();
              navigate(INITIAL_PATH_FOR_PAYMENT_ACTIONS);
              Snackbar.success("Banking information added.");
            })
            .catch((error) => Snackbar.error(error));
        },
      };
    }
    case ENUMS.ConfirmAction.DELETE_ACCOUNT: {
      return {
        content: "Do you want to delete your account?",
        initialPathForAction: "/",
        onConfirm: (location) => {
          return confirmAction(location)
            .then(() => {
              Snackbar.success("Account deleted.");
              closeModal();
            })
            .then(() => window.location.reload())
            .catch((error) => {
              if (
                error?.detail ===
                "Authentication credentials were not provided."
              ) {
                window.location.href = `/auth/login/?next=${encodeURIComponent(
                  `${sourceLocation?.pathname}${sourceLocation?.search}`,
                )}&error_code=${ENUMS.ConfirmAction.DELETE_ACCOUNT}`;
              } else {
                Snackbar.error(error);
              }
            });
        },
      };
    }
    case ENUMS.ConfirmAction.CONFIRM_CHANGE_EMAIL:
    case ENUMS.ConfirmAction.CONFIRM_SIGNUP_EMAIL: {
      // addressed in custom pages
      return null;
    }
    default: {
      assertUnreachable(action);
      return null;
    }
  }
};
