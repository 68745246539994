import { Field } from "redux-form";

import { SelectField } from "@js/forms/fields";
import { useAppSelector } from "@js/hooks";
import type { JobSelect } from "@js/types/freelancer";
import { deduplicateArrayOfObjects } from "@js/utils";

import { InvoiceJobFiltersInstance } from "../../filters";

const INVOICE_JOB_FILTER_FORM = "invoiceJobFilterForm";

export const FreelancerInvoiceJobFilterForm = () => {
  const { jobSelectList } = useAppSelector((state) => state.freelancer);

  const deduplicatedJobs = getDeduplicatedJobs(jobSelectList);

  return (
    <InvoiceJobFiltersInstance form={INVOICE_JOB_FILTER_FORM}>
      <Field
        id="invoice-job-filter"
        name="job"
        label="Projects"
        variant="standard"
        options={deduplicatedJobs}
        component={SelectField}
      />
      <input type="submit" className="invisible" />
    </InvoiceJobFiltersInstance>
  );
};

const getDeduplicatedJobs = (jobs: JobSelect[]) => {
  return deduplicateArrayOfObjects(
    [
      {
        label: "All Projects",
        value: "",
      },
      ...jobs.map(({ id, title, employer }) => ({
        label: (
          <>
            <span className="color-gray-2"> {employer.name}</span>&nbsp;
            {title}
          </>
        ),
        value: id,
      })),
    ],
    "value",
  );
};
