import cs from "classnames";

import {
  Box,
  Button,
  IconButton,
  Typography,
} from "@hexocean/braintrust-ui-components";
import { EditPen } from "@hexocean/braintrust-ui-components/Icons";
import { useUser } from "@js/apps/common/hooks";
import type { FreelancerPublic } from "@js/types/freelancer";

import { CertificatesCard } from "./card";
import { openCreateCertificateModal } from "./modals";

type CertificatesModuleProps = {
  profile: FreelancerPublic;
};

export const CertificatesModule = ({ profile }: CertificatesModuleProps) => {
  const { id, freelancer_certificates: certificates } = profile;
  const user = useUser();
  const isOwnProfile = user && id === user.freelancer;
  const ownEmptyState = !certificates.length && isOwnProfile;

  return (
    <Box
      className={cs("certificates", {
        "profile-empty-state": ownEmptyState,
      })}
    >
      <Box height="100%" display="flex" flexDirection="column" gap={2}>
        <Box display="flex" justifyContent="space-between">
          <Typography
            component="h2"
            size="large"
            sx={{ color: "var(--grey-1)" }}
          >
            Certificates
          </Typography>
          {isOwnProfile && !!certificates.length && (
            <IconButton
              variant="black-outlined"
              size="x-small"
              className="experience__edit-btn"
              aria-label="Edit certificates"
              onClick={openCreateCertificateModal}
            >
              <EditPen />
            </IconButton>
          )}
        </Box>

        {!!certificates.length ? (
          certificates.map((certificate) => {
            return (
              <CertificatesCard
                key={certificate.id}
                certificate={certificate}
              />
            );
          })
        ) : (
          <EmptyState profile={profile} ownEmptyState={ownEmptyState} />
        )}
      </Box>
    </Box>
  );
};

type EmptyStateProps = {
  profile: FreelancerPublic;
  ownEmptyState: boolean | null;
};

const EmptyState = ({ profile, ownEmptyState }: EmptyStateProps) => {
  return (
    <Box mt={1.5}>
      <Typography
        component="p"
        fontStyle="italic"
        mt={-1}
        mb={2}
        multipleEllipsis
      >
        {ownEmptyState
          ? "Add your certifications. "
          : `${profile.user.first_name} hasn’t added their certifications, yet.`}
      </Typography>
      {ownEmptyState && (
        <Button
          variant="black-outlined"
          size="medium"
          shape="squared"
          onClick={openCreateCertificateModal}
        >
          Add certificates
        </Button>
      )}
    </Box>
  );
};
