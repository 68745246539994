type NavBaseLink = {
  url: string;
  label: string;
  persistUTMSearchParams?: boolean;
};

type NavSubLink = NavBaseLink;

export type NavLink = NavBaseLink & {
  subLinks?: NavSubLink[];
};

export const NAV_LINKS: NavLink[] = [
  {
    url: "https://www.usebraintrust.com/solutions",
    label: "For Companies",
    subLinks: [
      {
        url: "https://www.usebraintrust.com/solutions",
        label: "Solutions",
      },
      {
        url: "https://www.usebraintrust.com/why-braintrust",
        label: "Why Braintrust",
      },
      {
        url: "https://www.usebraintrust.com/braintrust-for-enterprise",
        label: "How it Works",
      },
      {
        url: "https://www.usebraintrust.com/skills",
        label: "Skills & Capabilities",
      },

      {
        url: "https://www.usebraintrust.com/clients",
        label: "Clients",
      },
      {
        url: "https://www.usebraintrust.com/resources",
        label: "Resources",
      },
    ],
  },
  {
    url: "https://www.usebraintrust.com/how-it-works-for-talent",
    label: "For Talent",
    subLinks: [
      {
        url: "/jobs",
        label: "Jobs",
        persistUTMSearchParams: true,
      },
      {
        url: "https://www.usebraintrust.com/how-it-works-for-talent",
        label: "How it Works",
      },
      {
        url: "https://www.usebraintrust.com/professional-network",
        label: "Professional Network",
      },
      {
        url: "https://www.usebraintrust.com/benefits",
        label: "Benefits",
      },
      {
        url: "https://www.usebraintrust.com/resources?tab=talent",
        label: "Resources",
      },
      {
        url: "https://www.usebraintrust.com/refer-clients",
        label: "Refer & Earn",
      },
    ],
  },
  {
    url: "https://www.usebraintrust.com/about",
    label: "About Us",
    subLinks: [
      {
        url: "https://www.usebraintrust.com/about",
        label: "Mission",
      },
      {
        url: "https://www.usebraintrust.com/press",
        label: "Press",
      },
      {
        url: "https://www.usebraintrust.com/community",
        label: "Community",
      },
      {
        url: "https://www.usebraintrust.com/btrst-token",
        label: "BTRST",
      },
      {
        url: "https://info.app.usebraintrust.com/",
        label: "Network Dashboard",
      },
    ],
  },
  {
    url: "https://www.usebraintrust.com/blog",
    label: "Blog",
  },
  {
    url: "/auth/login",
    label: "Log in",
    persistUTMSearchParams: true,
  },
];
