import React, { useState } from "react";
import type { WrappedFieldsProps } from "redux-form";
import { change, Fields, stopSubmit } from "redux-form";

import { BasicInput, Box, Button } from "@hexocean/braintrust-ui-components";
import { ClearIcon } from "@hexocean/braintrust-ui-components/Icons";
import { useLazyGetLinkMetadataQuery } from "@js/apps/common/api";
import { useEffectOnce } from "@js/apps/common/hooks/use-effect-once";
import { useResourcesContext } from "@js/apps/give-and-get-help/context";
import {
  CREATE_POST_FORM_ID,
  EDIT_POST_FORM_ID,
} from "@js/apps/give-and-get-help/form/constants";
import { shouldDisplayError } from "@js/forms/utils";
import { useAppDispatch } from "@js/hooks";
import { typeGuard } from "@js/utils";

import styles from "./style.module.scss";
export type UrlFieldError = {
  data: {
    status: number;
    url: string[];
  };
};

export const CreatePostLinkField = ({
  toggleField,
  autoFocus,
  isEditing,
}: CreatePostLinkFieldComponentProps) => {
  return (
    <Fields
      names={[
        "links_metadata",
        "links",
        "links_from_text",
        "link_input_value",
        "edited_link",
        "url",
      ]}
      component={CreatePostLinkFieldComponent}
      maxLinksNumber={SETTINGS.POST_LINK_ATTACHMENTS_LIMIT}
      toggleField={toggleField}
      autoFocus={autoFocus}
      isEditing={isEditing}
    />
  );
};

type CreatePostLinkFieldComponentProps = {
  toggleField?: () => void;
  autoFocus?: boolean;
  isEditing?: boolean;
};
const CreatePostLinkFieldComponent: React.FC<
  WrappedFieldsProps & CreatePostLinkFieldComponentProps
> = ({
  links_metadata: { input, meta },
  link_input_value,
  links,
  links_from_text,
  edited_link,
  url,
  maxLinksNumber,
  toggleField,
  autoFocus,
  isEditing,
}) => {
  const value = link_input_value.input.value ?? "";
  const setValue = (newValue: string) =>
    link_input_value.input.onChange(newValue);
  const [submitButtonDisabled, setSubmitButtonDisabled] = useState(false);
  const displayError = shouldDisplayError(url);
  const dispatch = useAppDispatch();
  const [getLinkMetadata] = useLazyGetLinkMetadataQuery();
  const { setIsLinkMetaLoading } = useResourcesContext();

  const addLink = (newLink: { value: string; id: string }) =>
    links.input.onChange([...links.input.value, newLink]);

  const formId = !!isEditing ? EDIT_POST_FORM_ID : CREATE_POST_FORM_ID;

  const uniqueLinkValidator = (link: string) => {
    const linksFromComponent = Array.isArray(links.input.value)
      ? links.input.value.map((el) => el.value)
      : [];
    const addedLinks = [...linksFromComponent, ...links_from_text.input.value];

    if (
      addedLinks.some(
        (addedLink) =>
          addedLink === link && addedLink !== edited_link.input.value,
      )
    ) {
      return "This link has already been added.";
    }
  };

  const onSubmit = async () => {
    if (submitButtonDisabled) return;

    const linksFromTextCount = links_from_text.input.value
      ? links_from_text.input.value.length
      : 0;
    const linksCount = links.input.value ? links.input.value.length : 0;
    const totalLinksCount = linksFromTextCount + linksCount;

    if (maxLinksNumber <= totalLinksCount) {
      dispatch(
        stopSubmit(url.meta.form || formId, {
          url: "Can't add more links",
        }),
      );

      return;
    }

    const urlValidationErrors = [uniqueLinkValidator(value)];

    const urlValidationError = urlValidationErrors.find(Boolean);

    if (urlValidationError) {
      return dispatch(
        stopSubmit(url.meta.form || formId, {
          url: urlValidationError,
        }),
      );
    }

    setSubmitButtonDisabled(true);
    setIsLinkMetaLoading(true);
    dispatch(change(meta.form, "edited_link", ""));

    try {
      const { data, isError, error } = await getLinkMetadata(
        { url: value },
        true,
      );

      if (isError && typeGuard<unknown, UrlFieldError>(error, "data")) {
        dispatch(stopSubmit(formId, error));
        return dispatch(
          stopSubmit(url.meta.form || formId, {
            url: error.data.url[0],
          }),
        );
      }

      input.onChange([...input.value, { ...data, id: data?.url || value }]);
      addLink({ value, id: data?.url || value });
      setValue("");
      toggleField?.();
    } finally {
      setSubmitButtonDisabled(false);
      setIsLinkMetaLoading(false);
    }
  };

  const getErrorMessage = () => {
    return displayError && url.meta.error;
  };

  useEffectOnce(() => {
    if (!isEditing) {
      return;
    }
    setValue(edited_link.input.value);
  });

  return (
    <Box position="relative">
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        p={3}
        gap={2}
        boxSizing="border-box"
        height={80}
        borderRadius={4}
        border="1px solid var(--grey-4)"
        boxShadow="var(--elevation-6)"
        bgcolor="var(--white)"
      >
        <BasicInput
          errorMessage={getErrorMessage()}
          onChange={(event) => {
            setValue(event.target.value);
          }}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              e.preventDefault();
              onSubmit();
            }
          }}
          sx={{
            flexGrow: 1,
          }}
          autoFocus={autoFocus}
          value={value}
          fontSize={20}
          autoComplete="off"
          placeholder="Enter URL..."
        />
        <Button
          className={styles.addLinkButton}
          variant="primary"
          disabled={submitButtonDisabled}
          onClick={() => {
            onSubmit();
          }}
          shape="squared"
          size="x-small"
        >
          Add
        </Button>
      </Box>
      {!!toggleField && (
        <ClearIcon
          className={styles.closeButton}
          onClick={() => toggleField()}
        />
      )}
    </Box>
  );
};
