import { useEffect } from "react";

import { Box, Button, Typography } from "@hexocean/braintrust-ui-components";
import { useUser } from "@js/apps/common/hooks";
import { useEmployerGuardCheck } from "@js/apps/nft/hooks";
import WalletModal from "@js/apps/web3/components/wallet-modal";
import { useActiveWeb3React } from "@js/apps/web3/hooks";
import { useMounted } from "@js/hooks/mounted";

import {
  MintConfirmationModal,
  MintingModal,
  NFTCard,
  openMintingAlert,
} from "../../components";

import { Title, TitleSmall } from "./utils";

export const FreelancerApproved = () => {
  const user = useUser();
  const mounted = useMounted();
  const { account } = useActiveWeb3React();
  const employerCheckResult = useEmployerGuardCheck(user);

  useEffect(() => {
    if (mounted && account) {
      openMintingAlert({
        type: "success",
        message: "Your wallet is connected!",
      });
    }
  }, [account, mounted]);

  return (
    <>
      <Box mb={5}>
        <TitleSmall mb={2}>Hello {user?.first_name},</TitleSmall>
        <Title>
          {account
            ? "Let’s mint your Braintrust NFT!"
            : "First, connect your wallet"}
        </Title>
      </Box>
      <NFTCard
        actionSlot={
          account ? (
            <MintConfirmationModal />
          ) : (
            <Button
              variant="info-2"
              size="medium"
              disabled={employerCheckResult?.disabled}
              onClick={() => WalletModal.openModal()}
            >
              {employerCheckResult?.buttonText ?? "Connect wallet"}
            </Button>
          )
        }
      />
      <MintingModal />
      <Typography component="p" mt={3.5}>
        Your NFT is your new on-chain membership to Braintrust. Deposit $BTRST
        in it to unlock special features. Use your Braintrust profile in other
        web3 apps.
      </Typography>
    </>
  );
};
