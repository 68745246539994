import { useEffect } from "react";

import { Box } from "@hexocean/braintrust-ui-components";
import { useLazyGetEmployerDefaultJobOwnersQuery } from "@js/apps/employer/api";
import { UserSearchMultipleField } from "@js/components/autocomplete-new/combobox-multiple/user";

export const JobOwnersFields = ({ employer, op_owners, sale_owners }) => {
  const [getEmployerDefaultOwners] = useLazyGetEmployerDefaultJobOwnersQuery();
  const employerId = employer.input.value;

  useEffect(() => {
    if (!employerId) return;

    getEmployerDefaultOwners(employerId)
      .unwrap()
      .then((data) => {
        const ops = data?.default_op_owners.map((owner) => {
          return {
            id: owner.id,
            name: owner.public_name,
          };
        });
        const sales = data?.default_sale_owners.map((owner) => {
          return {
            id: owner.id,
            name: owner.public_name,
          };
        });

        op_owners.input.onChange(ops);
        sale_owners.input.onChange(sales);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [employerId]);

  return (
    <>
      <Box mb={2}>
        <UserSearchMultipleField
          input={op_owners.input}
          meta={op_owners.meta}
          label="Add Talent Specialist"
          placeholder="Select Owner(s)"
          endpointParams={{ return_ops_only: true, page_size: 100 }}
        />
      </Box>
      <Box>
        <UserSearchMultipleField
          input={sale_owners.input}
          meta={sale_owners.meta}
          label="Add Sales Specialist"
          placeholder="Select Owner(s)"
          endpointParams={{ return_sales_only: true, page_size: 100 }}
        />
      </Box>
    </>
  );
};
