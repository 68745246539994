import type { EmployerTeamMemberType } from "@js/types/employer";
import type {
  JobSubscriberData,
  JobSubscriberInvitationData,
} from "@js/types/jobs";

export const getSortedTeamMembers = ({
  teamMembers,
  jobSubscribers,
  jobOwnerId,
}: {
  teamMembers: EmployerTeamMemberType[];
  jobSubscribers: JobSubscriberData[];
  jobOwnerId: number;
}) => {
  const unsubbedTeamMembers: EmployerTeamMemberType[] = [];
  const subbedTeamMembers: EmployerTeamMemberType[] = [];
  const jobOwnerTeamMember = teamMembers.find(
    (teamMember) => teamMember.id === jobOwnerId,
  );

  for (const teamMember of teamMembers) {
    if (teamMember.id === jobOwnerId) {
      continue;
    }

    const isSubbed = jobSubscribers.some(
      (jobSub) => jobSub.team_member_id === teamMember.id,
    );
    if (isSubbed) {
      subbedTeamMembers.push(teamMember);
    } else {
      unsubbedTeamMembers.push(teamMember);
    }
  }

  return [
    ...unsubbedTeamMembers,
    jobOwnerTeamMember,
    ...subbedTeamMembers,
  ].filter((member): member is EmployerTeamMemberType => Boolean(member));
};

export const getNewJobSubscriberInvitations = ({
  jobSubscriberInvitations,
  editedJobSubscriberInvitations,
}: {
  jobSubscriberInvitations: JobSubscriberInvitationData[];
  editedJobSubscriberInvitations: JobSubscriberInvitationData[];
}) => {
  return editedJobSubscriberInvitations.filter((jobSubInvitation) => {
    const hasAlreadyBeenInvited = jobSubscriberInvitations.some(
      (currentJobSubInvitation) =>
        jobSubInvitation.email === currentJobSubInvitation.email,
    );

    return !hasAlreadyBeenInvited;
  });
};

export const areJobSubscribersChanged = ({
  jobSubscribers,
  editedJobSubscribers,
}: {
  jobSubscribers: JobSubscriberData[];
  editedJobSubscribers: JobSubscriberData[];
}) => {
  if (jobSubscribers.length !== editedJobSubscribers.length) {
    return true;
  }

  return editedJobSubscribers.some((editedJobSubscriber) => {
    return jobSubscribers.every((jobSubscriber) => {
      return (
        editedJobSubscriber.team_member_id !== jobSubscriber.team_member_id
      );
    });
  });
};

export const areJobSubscriberInvitationsChanged = ({
  jobSubscriberInvitations,
  editedJobSubscriberInvitations,
}: {
  jobSubscriberInvitations: JobSubscriberInvitationData[];
  editedJobSubscriberInvitations: JobSubscriberInvitationData[];
}) => {
  if (
    jobSubscriberInvitations.length !== editedJobSubscriberInvitations.length
  ) {
    return true;
  }

  return editedJobSubscriberInvitations.some((editedJobSubscriber) => {
    return jobSubscriberInvitations.every((jobSubscriber) => {
      return editedJobSubscriber.email !== jobSubscriber.email;
    });
  });
};
