import { Box } from "@hexocean/braintrust-ui-components";
import {
  ContractTypeDetail,
  LocationDetail,
  TimezoneDetail,
  WorkTimeDetail,
} from "@js/apps/jobs/components/job-basic-details";

import type { JobItemProps } from "../job-item";

type BasicInfoPropsTypes = Omit<JobItemProps, "clickLocation"> & {
  flexDirection?: React.CSSProperties["flexDirection"];
  displayContractType?: boolean;
};

export const BasicInfo = ({
  job,
  flexDirection,
  displayContractType,
}: BasicInfoPropsTypes) => {
  return (
    <Box
      display="flex"
      columnGap={2.5}
      rowGap={1}
      flexDirection={flexDirection ?? { xs: "column", sm: "row" }}
      flexWrap="wrap"
    >
      <LocationDetail job={job} variant="listing" />
      <TimezoneDetail job={job} variant="listing" />
      <WorkTimeDetail job={job} variant="listing" shortened />
      {displayContractType && (
        <ContractTypeDetail job={job} variant="listing" />
      )}
    </Box>
  );
};
