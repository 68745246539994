import { useEffect } from "react";
import { useMatch, useNavigate } from "react-router-dom";

import { useMint } from "@js/apps/nft/hooks/mint";

export const useLockWhileMinting = () => {
  const navigate = useNavigate();
  const isOnMintPage = useMatch("/nft/mint");
  const { status } = useMint();

  useEffect(() => {
    if (status === ENUMS.NFTMintingStatus.MINTING && !isOnMintPage) {
      navigate("/nft/mint/");
    }
  }, [isOnMintPage, navigate, status]);
};
