import React from "react";

import {
  Alert,
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@hexocean/braintrust-ui-components";
import {
  ArrowRightIcon,
  BraintrustIcon,
} from "@hexocean/braintrust-ui-components/Icons";
import { ApprovedBadge } from "@js/apps/freelancer/components/approved-badge";
import { useGetNftUserDataQuery } from "@js/apps/nft/api";
import { openMintingAlert } from "@js/apps/nft/components/minting-alert";
import { useMint } from "@js/apps/nft/hooks";
import { useActiveWeb3React } from "@js/apps/web3/hooks";
import { Modal } from "@js/components/modal";

import { Video } from "../nft-video-img";

import styles from "./style.module.scss";

const ConfirmMintModalInstance = Modal("confirm-mint-modal", {
  keepOnBackdropClick: true,
  closeButtonProps: {
    style: {
      backgroundColor: "rgba(var(--black-rgb), 0.04)",
      width: 36,
      height: 36,
      margin: "11px 11px 0 0",
    },
  },
  className: styles.mintConfirmationModal,
});

export const MintConfirmationModal: React.FC = () => {
  const { mint, status } = useMint();
  if (status !== ENUMS.NFTMintingStatus.NOT_MINTED) return null;

  return (
    <>
      <Button
        variant="medium-green"
        size="medium"
        sx={{ color: "var(--black)" }}
        endIcon={<ArrowRightIcon />}
        onClick={() => {
          ConfirmMintModalInstance.open();
        }}
      >
        Mint NFT
      </Button>
      <ConfirmMintModalInstance>
        <ConfirmContent mint={mint} />
      </ConfirmMintModalInstance>
    </>
  );
};

const ConfirmContent = ({ mint }: { mint: () => Promise<any> }) => {
  const { data: userData } = useGetNftUserDataQuery();
  const { account } = useActiveWeb3React();

  const handleClick = async () => {
    try {
      await mint();
    } catch (error: any) {
      ConfirmMintModalInstance.close();
      openMintingAlert({
        type: "error",
        message: (
          <>
            Minting failed. This could be an error with your wallet or with our
            system. Please try again.
          </>
        ),
      });
      console.error(error);
    }
  };

  const tableRows = [
    {
      label: "User name",
      value: userData?.user_name,
    },
    {
      label: "NFT artwork link",
      value: userData?.image,
    },
    {
      label: "Membership Tier",
      value: userData?.membership_tier,
    },
    {
      label: "Braintrust Internal User ID",
      value: "#" + userData?.user_id,
    },
    {
      label: "Braintrust Approval Status",
      value: userData?.approved ? (
        <Box display="flex" alignItems="center">
          <ApprovedBadge boxProps={{ mr: 0.5 }} /> Approved
        </Box>
      ) : (
        "Not Approved"
      ),
    },
    {
      label: "Token Balance",
      value: (
        <Box display="flex" alignItems="center">
          <BraintrustIcon sx={{ mr: 1 }} /> {userData?.token_balance}
        </Box>
      ),
    },
    {
      label: "Braintrust profile",
      value: userData?.full_link,
    },
  ];

  return (
    <Box>
      <Typography
        component="h3"
        fontWeight={400}
        variant="title"
        size="medium"
        align="center"
      >
        Are you ready to mint your NFT?
      </Typography>
      <Box
        display="flex"
        flexDirection={{ xs: "column", lg: "row" }}
        justifyContent="center"
        mt={8}
        gap={4}
      >
        <Box display="flex" flexDirection="column" gap={4}>
          <Box p={2} borderRadius={1.5} border="1px solid var(--soft-beige)">
            <Typography component="p" variant="paragraph" size="medium">
              <strong>NFT:</strong> “Braintrust NFT”
            </Typography>
            <Typography
              sx={{ mt: 2 }}
              component="p"
              variant="paragraph"
              size="medium"
            >
              <strong>Wallet:</strong> {account}
            </Typography>
          </Box>
          <Video
            src={`${SETTINGS.STATIC_URL}nft/nft.mp4`}
            className="nft-card__image"
          />
        </Box>
        <Box display="flex" flexDirection="column" gap={4}>
          <Typography component="h3" variant="label" size="medium">
            Public on-chain profile
          </Typography>

          <Table
            sx={{
              border: "1px solid var(--soft-beige)",
              maxHeight: 274,
              borderCollapse: "separate",
              borderRadius: 2,
            }}
          >
            <TableBody>
              {tableRows.map(({ label, value }) => {
                return (
                  <TableRow key={label}>
                    <TableCell sx={{ padding: "6px 20px" }}>
                      <Typography
                        component="span"
                        variant="label"
                        size="medium"
                      >
                        {label}
                      </Typography>
                    </TableCell>
                    <TableCell sx={{ padding: "6px 20px" }}>
                      <Typography
                        component="span"
                        variant="paragraph"
                        size="medium"
                      >
                        {value}
                      </Typography>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>

          <Alert
            type="info"
            withIcon
            className={styles["mint-confirmation-modal__alert"]}
          >
            This NFT can't be transferred to another wallet. Your Braintrust
            profile will be publicly visible through the Ethereum blockchain.
          </Alert>

          <Box
            mt="auto"
            gap={1}
            display="flex"
            justifyContent="flex-end"
            alignSelf="flex-end"
          >
            <Button
              variant="secondary"
              onClick={ConfirmMintModalInstance.close}
            >
              Go back
            </Button>
            <Button variant="primary" onClick={handleClick}>
              Mint my NFT
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
