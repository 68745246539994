import type { RefObject } from "react";
import { useEffect, useMemo } from "react";
import _ from "underscore";

import type { JobDraft, JobFormValues } from "@js/types/jobs";

import { useSaveLastJobDraft } from "../use-save-last-job-draft";

type UseSaveLastJobDraftOnChangeArg = {
  formStateRef: RefObject<{
    invalid: boolean;
    lastSavedDraft: JobDraft | undefined;
    wasDirty: boolean;
  }>;
};

export const useSaveLastJobDraftOnChange = ({
  formStateRef,
}: UseSaveLastJobDraftOnChangeArg) => {
  const { saveLastJobDraft } = useSaveLastJobDraft();

  const saveLastDraftDebounced = useMemo(() => {
    return _.debounce(
      (
        values: JobFormValues,
        _dispatch: unknown,
        props: { dirty: boolean; submitting: boolean },
      ) => {
        if (!formStateRef.current || values.isDeletingJobDraft) {
          return;
        }

        if (props.dirty) {
          formStateRef.current.wasDirty = true;
        }
        // do not use invalid and other attributes from props as they are stale (redux form bug in onChange)
        // we need to use the form state in order to retrieve the actual values
        if (formStateRef.current.invalid || !formStateRef.current.wasDirty) {
          return;
        }
        saveLastJobDraft({
          values,
          lastSavedDraft: formStateRef.current.lastSavedDraft,
        });
      },
      2000,
    );
  }, [formStateRef, saveLastJobDraft]);

  // cancel saveLastDraft call after unmount
  useEffect(
    () => () => {
      saveLastDraftDebounced.cancel();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return { onChange: saveLastDraftDebounced };
};
