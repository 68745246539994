import { useCompleteProfileTopBar } from "./hook";
import { CompleteProfileTopBarView } from "./view";

type CompleteProfileTopBarProps = {
  onTopbarClose?: () => void;
};

export const CompleteProfileTopBar = ({
  onTopbarClose,
}: CompleteProfileTopBarProps) => {
  const { showTopBar, dismissBar, completeProfile } =
    useCompleteProfileTopBar();

  return (
    <CompleteProfileTopBarView
      showTopBar={showTopBar}
      onCompleteProfileClick={completeProfile}
      onCloseClick={() => {
        dismissBar();
        onTopbarClose?.();
      }}
    />
  );
};
