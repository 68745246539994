import { store } from "@js/store";

import { startRequestLoader, stopRequestLoader } from "./actions";

const { dispatch } = store;

// In case something went wrong and we haven't registered request finish, force stop loader after X time.
const FORCE_STOP_LOADER_TIMEOUT = 60 * 1000; // 60 seconds

let numberOfCurrentlyRunningRequests = 0;
let forceStopLoaderTimeout;

export const requestStarted = () => {
  if (numberOfCurrentlyRunningRequests === 0) {
    dispatch(startRequestLoader());
  }

  numberOfCurrentlyRunningRequests += 1;

  clearTimeout(forceStopLoaderTimeout);

  forceStopLoaderTimeout = setTimeout(() => {
    dispatch(stopRequestLoader());
    numberOfCurrentlyRunningRequests = 0;
  }, FORCE_STOP_LOADER_TIMEOUT);
};

export const requestFinished = () => {
  if (numberOfCurrentlyRunningRequests === 1) {
    dispatch(stopRequestLoader());
    clearTimeout(forceStopLoaderTimeout);
  }

  numberOfCurrentlyRunningRequests -= 1;
};
