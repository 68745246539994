import type { ChangeEvent, FC, MouseEvent } from "react";
import { useMemo } from "react";

import {
  Box,
  Grid,
  IconButton,
  SwipeableDrawer,
  Typography,
} from "@hexocean/braintrust-ui-components";
import { ClearIcon } from "@hexocean/braintrust-ui-components/Icons";
import {
  fetchSavedFilters,
  updateNewJobNotification,
} from "@js/apps/common/components/save-filters/actions";
import { useSkillsData } from "@js/apps/skills/hooks";
import { useAppDispatch, useAppSelector } from "@js/hooks";
import type { Skill } from "@js/types/admin";
import type { SavedJobFilter } from "@js/types/jobs";

import { useGetActiveFilterName } from "../../hooks/active-filter-name";
import { useEmailNotificationTurnedOffSnackBar } from "../../hooks/email-notification-turned-off-snackbar";
import { useSavedSearches } from "../../hooks/saved-searches";
import { SavedJobSearchItem } from "../saved-job-search-item";
import { SavedSearchesEmptyState } from "../saved-searches-empty-state";

type SavedFiltersDrawerProps = {
  open: boolean;
  setOpen: (input: boolean) => void;
  onChange: (filter: SavedJobFilter) => void;
  onRemove: (filter: SavedJobFilter) => void;
  location: string;
};

export const SavedFiltersDrawer: FC<SavedFiltersDrawerProps> = ({
  open,
  setOpen,
  onChange,
  onRemove,
  location,
}) => {
  const dispatch = useAppDispatch();
  const { savedFilterName } = useGetActiveFilterName(location);
  const { savedFilters, processingItems, savedFiltersSkillsIds } =
    useSavedSearches();
  const notificationSettings = useAppSelector(
    (state) => state.settings.notifications.general_notifications_settings,
  );
  const { skillsData } = useSkillsData(savedFiltersSkillsIds);

  useEmailNotificationTurnedOffSnackBar({
    isDrawerOpen: open,
  });

  const closeDrawer = () => {
    setOpen(false);
    fetchSavedFilters("job_filters");
  };

  const onOpen = () => null;

  const onEmailNotificationsToggle = (
    event: ChangeEvent<HTMLInputElement>,
    filterId: number,
    enableNotifications: boolean,
  ) => {
    event.stopPropagation();
    dispatch(updateNewJobNotification(filterId, enableNotifications));
  };

  const onRemoveClick = (filter: SavedJobFilter, event: MouseEvent) => {
    event.stopPropagation();
    onRemove(filter);
  };

  const generateSavedFilters = savedFilters.map((filter) => {
    const isItemProcessing = processingItems.includes(filter.id);
    const filterSkills =
      typeof filter?.filters?.skills === "string"
        ? filter.filters.skills
        : null;
    const filterSkillsData: Skill[] = [];
    if (Array.isArray(skillsData) && filterSkills) {
      filterSkills?.split(",").forEach((skillId) => {
        const skillObject = skillsData.find(
          (skill) => skill.id.toString() === skillId,
        );
        if (skillObject) {
          filterSkillsData.push(skillObject);
        }
      });
    }

    return (
      <SavedJobSearchItem
        key={filter.id}
        onApply={() => {
          onChange(filter);
          closeDrawer();
        }}
        onEmailNotificationsToggle={onEmailNotificationsToggle}
        isSelected={filter.name === savedFilterName}
        savedFilter={filter}
        processingSavedFilters={isItemProcessing}
        onRemoveClick={(event) => onRemoveClick(filter, event)}
        filterSkillsData={filterSkillsData}
      />
    );
  });

  const hasNotification = useMemo(() => {
    const isSavedFilterWithNotificationsOn = savedFilters.find(
      (filter) => filter.new_job_notification_enabled === true,
    );
    const isEmailNotificationTurnedOn = notificationSettings.find(
      (setting) =>
        setting.notification ===
        ENUMS.NotificationTypes.FREELANCER_NEW_JOB_SAVED_FILTERS,
    )?.value;

    return !isEmailNotificationTurnedOn && isSavedFilterWithNotificationsOn;
  }, [notificationSettings, savedFilters]);

  return (
    <SwipeableDrawer
      open={open}
      onClose={closeDrawer}
      onOpen={onOpen}
      anchor="right"
    >
      <Box
        sx={{ width: { xs: "90vw" }, maxWidth: "543px", overflowX: "hidden" }}
        p={3}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography component="p" size="small" color="grey">
            Saved searches
          </Typography>
          <IconButton
            variant="transparent"
            onClick={closeDrawer}
            size="medium"
            aria-label="Delete saved search"
          >
            <ClearIcon />
          </IconButton>
        </Box>
        <Grid
          container
          flexDirection="column"
          className={
            hasNotification ? "grid-with-notification-bottom" : undefined
          }
        >
          {savedFilters.length > 0 ? (
            generateSavedFilters
          ) : (
            <SavedSearchesEmptyState closeDrawer={closeDrawer} />
          )}
        </Grid>
      </Box>
    </SwipeableDrawer>
  );
};
