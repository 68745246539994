import { Box } from "@hexocean/braintrust-ui-components";
import { Modal, ModalConfirm } from "@js/components/modal";
import { Snackbar } from "@js/components/snackbar";

export type FetchDataType = () => Promise<[unknown, unknown]>;

type openConfirmModalType = {
  confirmText: string;
  onConfirm: () => Promise<unknown>;
  onSuccess: () => void;
  successMessage?: string;
  errorMessage?: string;
};

export const TeamMemberActionConfirmationModal = Modal(
  "team-member-action-confirmation-modal",
);

export const openConfirmModal = ({
  confirmText,
  onConfirm,
  onSuccess,
  successMessage,
  errorMessage,
}: openConfirmModalType) => {
  return TeamMemberActionConfirmationModal.open({
    children: (
      <ModalConfirm
        onConfirm={() => {
          onConfirm()
            .then(() => {
              onSuccess();
              if (successMessage) Snackbar.success(successMessage);
            })
            .catch((error) => {
              if (errorMessage)
                Snackbar.error(error.errors._error || errorMessage);
            });

          TeamMemberActionConfirmationModal.close();
        }}
        onCancel={TeamMemberActionConfirmationModal.close}
      >
        <Box sx={{ pr: 6, mt: 1, maxWidth: "400px" }}>{confirmText}</Box>
      </ModalConfirm>
    ),
  });
};
