import {
  Button,
  Tooltip,
  Typography,
} from "@hexocean/braintrust-ui-components";
import { getBlockedPaymentTime } from "@js/apps/invoices/utils";
import type { EmployerInvoice } from "@js/types/invoices";

import { MarkAsPaidAction } from "../../mark-as-paid-action";

import { MarkAsPaidModalContent } from "./mark-as-paid-modal-content";

type SingleInvoiceActionsContentProps = {
  isPayButtonVisible: boolean;
  isPaymentTemporaryBlocked: boolean;
  invoice: EmployerInvoice;
  handleInvoicePayment: () => void;
  handleDownloadInvoiceAsPDF: () => void;
  markInvoiceAsPaid: (ids: number[]) => void;
};

export const SingleInvoiceActionsContent = ({
  isPayButtonVisible,
  isPaymentTemporaryBlocked,
  invoice,
  handleInvoicePayment,
  markInvoiceAsPaid,
  handleDownloadInvoiceAsPDF,
}: SingleInvoiceActionsContentProps) => {
  return (
    <div>
      {isPayButtonVisible && (
        <Tooltip
          disableHoverListener={!isPaymentTemporaryBlocked}
          disableFocusListener={!isPaymentTemporaryBlocked}
          disableTouchListener={!isPaymentTemporaryBlocked}
          disabled={!isPaymentTemporaryBlocked}
          placement="top"
          title={
            <Typography component="p" width="fit-content">
              This invoice is being edited and should be available to pay in{" "}
              {getBlockedPaymentTime(invoice.payment_blocked_until)}.
            </Typography>
          }
        >
          <span>
            <Button
              variant="transparent"
              onClick={handleInvoicePayment}
              disabled={isPaymentTemporaryBlocked}
            >
              Pay Invoice
            </Button>
          </span>
        </Tooltip>
      )}
      <MarkAsPaidAction
        invoices={[invoice]}
        markAsPaid={markInvoiceAsPaid}
        modalContent={<MarkAsPaidModalContent />}
      />
      <Button
        className="no-wrap"
        variant="transparent"
        onClick={handleDownloadInvoiceAsPDF}
      >
        Download PDF
      </Button>
    </div>
  );
};
