import { Box, Button, Typography } from "@hexocean/braintrust-ui-components";
import { ModalInstance } from "@js/components/modal";

export const CancelRefundRequestModal = ({
  cancelRefund,
}: {
  cancelRefund: (closeModal: () => void) => Promise<void>;
}) => {
  const onCancelClick = async (): Promise<void> => {
    await cancelRefund(ModalInstance.close);
  };

  return (
    <Box className="cancel-refund-request-modal-content">
      <Typography
        component="h4"
        className="cancel-refund-request-modal-content__modal-title"
      >
        Cancel refund request
      </Typography>
      <Box>
        <Typography
          component="p"
          className="cancel-refund-request-modal-content__main-information"
        >
          Canceling this refund request will make the offer active again.
        </Typography>
        <Typography
          component="p"
          className="cancel-refund-request-modal-content__additional-information"
        >
          When you are ready to close out this offer, don't forget to mark the
          offer as complete.
        </Typography>
      </Box>
      <Box className="cancel-refund-request-modal-content__button">
        <Button variant="primary" shape="squared" onClick={onCancelClick}>
          Cancel refund
        </Button>
      </Box>
    </Box>
  );
};
