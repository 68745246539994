import { API } from "@js/api";
import type { UnverifiedUser } from "@js/types/auth";

import type { ClientReferralProgramFormValues } from "../dashboard/views/refer-clients-program/join-clients-referral-program-form";

type BanUserFromPostingParams = {
  userId: number;
  postId: number;
};
type SendSelfSignupDataParams = {
  page_name: "Earn" | "Signup";
};
type PurchaseUpgradeParams = {
  upgrade_type: "boost" | "insights";
};

const usersApi = API.injectEndpoints({
  endpoints: (build) => ({
    banUserFromPosting: build.mutation<void, BanUserFromPostingParams>({
      query: ({ userId, postId }) => ({
        url: `/users/${userId}/ban_from_posting/`,
        method: "POST",
        data: {
          post: postId,
        },
      }),
    }),
    sendSelfOnboardingData: build.mutation<void, void>({
      query: () => ({
        url: `/users/send_self_serve_onboarding_data/`,
        method: "POST",
      }),
    }),
    sendSelfSignupData: build.mutation<void, SendSelfSignupDataParams>({
      query: ({ page_name }) => ({
        url: `/users/send_connector_signup_data/?page_name=${page_name}`,
        method: "POST",
      }),
    }),
    getUnfinishedUser: build.query<UnverifiedUser, string>({
      query: (key) => ({
        url: `/user/registration/check_key/`,
        method: "GET",
        params: {
          confirmation_key: key,
        },
      }),
      keepUnusedDataFor: 0,
    }),
    optInIntoReferClientProgram: build.mutation<
      void,
      ClientReferralProgramFormValues
    >({
      query: (values) => ({
        url: `/users/optin_into_refer_client_program/`,
        method: "POST",
        data: values,
      }),
    }),
    purchaseUpgrade: build.mutation<void, PurchaseUpgradeParams>({
      query: ({ upgrade_type }) => ({
        url: `/user/purchase/${upgrade_type}/`,
        method: "POST",
      }),
      invalidatesTags: ["WalletBalance", "Jobs"],
    }),
    reserveUpgrade: build.mutation<void, PurchaseUpgradeParams>({
      query: ({ upgrade_type }) => ({
        url: `/user/purchase/${upgrade_type}/`,
        method: "POST",
      }),
      invalidatesTags: ["WalletBalance"],
    }),
  }),
});

export const {
  useBanUserFromPostingMutation,
  useSendSelfOnboardingDataMutation,
  useSendSelfSignupDataMutation,
  useGetUnfinishedUserQuery,
  useOptInIntoReferClientProgramMutation,
  usePurchaseUpgradeMutation,
  useReserveUpgradeMutation,
} = usersApi;
