import React from "react";
import cs from "classnames";

import type { ToggleDrawer } from "@hexocean/braintrust-ui-components";
import {
  Box,
  ButtonCore,
  Typography,
} from "@hexocean/braintrust-ui-components";
import { SettingsRoundedIcon } from "@hexocean/braintrust-ui-components/Icons";
import { ChangesSavedMarker } from "@js/apps/jobs/apps/create-job/components/changes-saved-marker";

type RequirementCardProps = {
  label: string;
  detail: string;
  icon?: JSX.Element;
  toggleDrawer: ToggleDrawer;
  type: "locations" | "timezones";
};

export const RequirementCard = ({
  label,
  detail,
  icon,
  toggleDrawer,
  type,
}: RequirementCardProps) => {
  return (
    <ButtonCore
      className={cs("requirement-card-button", {
        "button-variant--white-violet": true,
        "button-shape--squared": true,
      })}
      onClick={toggleDrawer}
    >
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        width="100%"
      >
        <Box width="100%" display="flex" justifyContent="space-between" p={1}>
          <Box display="flex" alignItems="center">
            <Box
              width={41}
              minWidth={41}
              height={41}
              minHeight={41}
              bgcolor="rgba(229, 236, 236, 07)"
              display="grid"
              sx={{
                placeItems: "center",
                mr: 2,
                alignContent: "center",
                justifyContent: "center",
              }}
              borderRadius={20}
            >
              {icon}
            </Box>
            <Box textAlign="left">
              <Typography component="p" variant="label" size="small">
                {label}
              </Typography>
              <Typography
                component="p"
                variant="label"
                size="large"
                multilineEllipsis={2}
              >
                {detail}
              </Typography>
            </Box>
          </Box>
          <Box
            display="grid"
            sx={{ placeItems: "center", gridAutoFlow: "column" }}
          >
            <ChangesSavedMarker variant="minimal" sections={[type]} />
            <SettingsRoundedIcon style={{ paddingLeft: 8 }} />
          </Box>
        </Box>
      </Box>
    </ButtonCore>
  );
};
