import { Typography } from "@hexocean/braintrust-ui-components";
import { fetchCurrentUser } from "@js/apps/auth/actions";
import { useUpdateTaxFormMutation } from "@js/apps/freelancer/api";
import { Modal } from "@js/components/modal";
import { Snackbar } from "@js/components/snackbar";
import { useAppDispatch, useAppSelector } from "@js/hooks";

import type { AddW9FormData } from "./add-w9-form";
import { AddW9Form } from "./add-w9-form";

const AddW9ModalContent = () => {
  const dispatch = useAppDispatch();
  const freelancerId = useAppSelector((state) => state.auth.user?.freelancer);
  const [updateTaxForm] = useUpdateTaxFormMutation();

  const onSubmit = (values: AddW9FormData) => {
    if (!freelancerId) {
      return;
    }

    updateTaxForm({
      freelancerId,
      formType: "w9",
      formFileId: values.w9_form,
    }).then(() => {
      dispatch(fetchCurrentUser());

      AddW9Modal.close();
      Snackbar.success(`Saved Successfully.`);
    });
  };

  return (
    <>
      <Typography
        className="mt0 mb+"
        component="h3"
        variant="title"
        fontWeight={400}
        size="small"
      >
        W-9 Form
      </Typography>
      <Typography component="p">
        Upload a completed W-9. In case of an error, the newest form uploaded
        will be used by Braintrust.
      </Typography>
      <AddW9Form onSubmit={onSubmit} onClose={AddW9Modal.close} />
    </>
  );
};

export const AddW9Modal = Modal("add-w9-modal", {
  closeButton: false,
  keepOnBackdropClick: true,
  children: <AddW9ModalContent />,
});
