import type { TalentMixedLocation } from "@js/apps/freelancer/types";
import type { TypedWrappedFieldProps } from "@js/forms/utils";

import { bindSessionTokenToLocation } from "./bind-session-token-to-location";
import { clearSessionTokenFieldFromRemovedLocation } from "./clear-session-token-Field-from-removed-location";
import { pairSessionTokensWithPlaceIds } from "./get-session-tokens-with-ids";

type RemoveLocationFromLocationField = (
  updatedLocationValue: TalentMixedLocation[],
  locationField: TypedWrappedFieldProps<TalentMixedLocation[] | null>,
) => void;

const removeLocationFromLocationField: RemoveLocationFromLocationField = (
  value,
  locationField,
) => {
  locationField.input.onChange(value);
};

type RemoveLocationFromPlaceIdField = (params: {
  updatedLocationValue: TalentMixedLocation[];
  sessionTokenField: TypedWrappedFieldProps<string[] | null>;
  placeIdField: TypedWrappedFieldProps<string[] | null>;
}) => void;

const removeLocationFromPlaceIdField: RemoveLocationFromPlaceIdField = ({
  updatedLocationValue,
  sessionTokenField,
  placeIdField,
}) => {
  const placeIds = updatedLocationValue
    .filter((el) => !el.custom && (el.place_id || el.id))
    .map((mapEl) => (mapEl.id || mapEl.place_id) as string);

  const placeIdFieldValue = placeIdField.input.value;
  const sessionTokenFieldValue = sessionTokenField.input.value;

  const sessionTokensWithIds = pairSessionTokensWithPlaceIds(
    placeIdFieldValue,
    sessionTokenFieldValue,
  );

  clearSessionTokenFieldFromRemovedLocation({
    currentPlaceIds: placeIds,
    sessionTokenField: sessionTokenField,
    sessionTokensWithIds: sessionTokensWithIds,
  });

  placeIdField.input.onChange(placeIds);
};

type RemoveLocationFromCustomLocationField = (
  updatedLocationValue: TalentMixedLocation[],
  customLocationField: TypedWrappedFieldProps<string[] | null>,
) => void;

const removeLocationFromCustomLocationField: RemoveLocationFromCustomLocationField =
  (updatedLocationValue, customLocationField) => {
    const customLocations = updatedLocationValue
      .filter((loc) => loc.custom)
      .filter((loc) => loc.id || loc.custom_location)
      .map((el) => (el.id || el.custom_location) as string);

    customLocationField.input.onChange(customLocations);
  };

type AddLocationToLocationField = (params: {
  locationToAdd: TalentMixedLocation;
  locationField: TypedWrappedFieldProps<TalentMixedLocation[] | null>;
  resetLocationsArray?: boolean;
}) => void;

const addLocationToLocationField: AddLocationToLocationField = ({
  locationToAdd,
  locationField,
  resetLocationsArray,
}) => {
  const locationsShownToUser =
    resetLocationsArray || !locationField.input.value
      ? []
      : [...locationField.input.value];

  const formattedLocation = {
    ...locationToAdd,
  };

  if (!formattedLocation.formatted_address && !formattedLocation.custom) {
    formattedLocation.formatted_address =
      locationToAdd.name || locationToAdd.full_location?.formatted_address;
  }

  locationsShownToUser.push(formattedLocation);
  locationField.input.onChange(locationsShownToUser);
};

type AddLocationToPlaceIdField = (params: {
  locationToAdd: TalentMixedLocation;
  placeIdField: TypedWrappedFieldProps<string[] | null>;
  sessionTokenField: TypedWrappedFieldProps<string[] | null>;
  resetLocationsArray?: boolean;
}) => void;

const addLocationToPlaceIdField: AddLocationToPlaceIdField = ({
  locationToAdd,
  placeIdField,
  sessionTokenField,
  resetLocationsArray,
}) => {
  const placeIds =
    resetLocationsArray || !placeIdField.input.value
      ? []
      : [...placeIdField.input.value];

  if (locationToAdd?.id && !locationToAdd?.custom) {
    placeIds.push(locationToAdd?.id);
    bindSessionTokenToLocation({
      singleLocationData: locationToAdd,
      sessionTokenField: sessionTokenField,
    });
  } else if (locationToAdd?.place_id) {
    placeIds.push(locationToAdd.place_id);
    bindSessionTokenToLocation({
      singleLocationData: locationToAdd,
      sessionTokenField: sessionTokenField,
    });
  }

  placeIdField.input.onChange(placeIds);
};

type AddLocationToCustomLocationField = (params: {
  locationToAdd: TalentMixedLocation;
  customLocationField: TypedWrappedFieldProps<string[] | null>;
  resetLocationsArray?: boolean;
}) => void;

const addLocationToCustomLocationField: AddLocationToCustomLocationField = ({
  locationToAdd,
  customLocationField,
  resetLocationsArray,
}) => {
  const customLocations =
    resetLocationsArray || !customLocationField.input.value
      ? []
      : [...customLocationField.input.value];

  if (locationToAdd?.custom_location) {
    customLocations.push(locationToAdd?.custom_location);
  } else if (locationToAdd?.id && locationToAdd?.custom) {
    customLocations.push(locationToAdd?.id);
  }

  customLocationField.input.onChange(customLocations);
};

type RemoveDuplicatedLocationFromPlaceIdField = (params: {
  locationToRemove: TalentMixedLocation;
  placeIdField: TypedWrappedFieldProps<string[] | null>;
  sessionTokenField: TypedWrappedFieldProps<string[] | null>;
}) => void;

const removeDuplicatedLocationFromPlaceIdField: RemoveDuplicatedLocationFromPlaceIdField =
  ({ locationToRemove, placeIdField, sessionTokenField }) => {
    let updatedPlaceIds = placeIdField.input.value
      ? [...placeIdField.input.value]
      : [];

    if (locationToRemove?.id) {
      updatedPlaceIds = updatedPlaceIds.filter(
        (id) => id !== locationToRemove.id,
      );
    }

    if (locationToRemove?.place_id) {
      updatedPlaceIds = updatedPlaceIds.filter(
        (placeId) => placeId !== locationToRemove.place_id,
      );

      const placeIdFieldValue = placeIdField.input.value;
      const sessionTokenFieldValue = sessionTokenField.input.value;

      const sessionTokensWithIds = pairSessionTokensWithPlaceIds(
        placeIdFieldValue,
        sessionTokenFieldValue,
      );

      clearSessionTokenFieldFromRemovedLocation({
        currentPlaceIds: updatedPlaceIds,
        sessionTokenField: sessionTokenField,
        sessionTokensWithIds: sessionTokensWithIds,
      });
    }

    placeIdField.input.onChange(updatedPlaceIds);
  };

type RemoveDuplicatedLocationFromCustomLocationField = (
  locationToRemove: TalentMixedLocation,
  customLocationField: TypedWrappedFieldProps<string[] | null>,
) => void;

const removeDuplicatedLocationFromCustomLocationField: RemoveDuplicatedLocationFromCustomLocationField =
  (locationToRemove, customLocationField) => {
    let customLocations = customLocationField.input.value
      ? [...customLocationField.input.value]
      : [];

    if (locationToRemove?.custom_location) {
      customLocations = customLocations.filter(
        (customLocation) =>
          customLocation !== locationToRemove?.custom_location,
      );
    }

    if (locationToRemove?.id) {
      customLocations = customLocations.filter(
        (customLocation) => customLocation !== locationToRemove?.id,
      );
    }

    customLocationField.input.onChange(customLocations);
  };

type RemoveDuplicatedLocationFromLocationField = (
  locationToRemove: TalentMixedLocation,
  locationField: TypedWrappedFieldProps<TalentMixedLocation[] | null>,
) => void;

const removeDuplicatedLocationFromLocationField: RemoveDuplicatedLocationFromLocationField =
  (locationToRemove, locationField) => {
    let locationsShownToUser = locationField.input.value
      ? [...locationField.input.value]
      : [];

    if (locationToRemove?.id) {
      locationsShownToUser = locationsShownToUser.filter(
        (loc) =>
          loc?.id !== locationToRemove.id &&
          loc?.custom_location !== locationToRemove.id,
      );
    }

    if (locationToRemove?.place_id) {
      locationsShownToUser = locationsShownToUser.filter(
        (loc) => loc?.place_id !== locationToRemove.place_id,
      );
    }

    if (locationToRemove?.custom_location) {
      locationsShownToUser = locationsShownToUser.filter(
        (loc) =>
          loc?.custom_location !== locationToRemove?.custom_location &&
          loc?.id !== locationToRemove?.custom_location,
      );
    }

    locationField.input.onChange(locationsShownToUser);
  };

type Reset = (params: {
  locationField: TypedWrappedFieldProps<TalentMixedLocation[] | null>;
  customLocationField: TypedWrappedFieldProps<string[] | null>;
  placeIdField: TypedWrappedFieldProps<string[] | null>;
  sessionTokenField: TypedWrappedFieldProps<string[] | null>;
  includeVerifiedLocationsOnlyField?: TypedWrappedFieldProps<boolean | null>;
}) => void;

const reset: Reset = ({
  locationField,
  customLocationField,
  placeIdField,
  sessionTokenField,
  includeVerifiedLocationsOnlyField,
}) => {
  locationField.input.onChange(null);
  customLocationField.input.onChange(null);
  placeIdField.input.onChange(null);
  sessionTokenField.input.onChange(null);
  includeVerifiedLocationsOnlyField?.input.onChange(null);
};

export {
  addLocationToCustomLocationField,
  addLocationToLocationField,
  addLocationToPlaceIdField,
  removeDuplicatedLocationFromCustomLocationField,
  removeDuplicatedLocationFromLocationField,
  removeDuplicatedLocationFromPlaceIdField,
  removeLocationFromCustomLocationField,
  removeLocationFromLocationField,
  removeLocationFromPlaceIdField,
  reset,
};
