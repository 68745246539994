import { useEffect, useMemo, useState } from "react";
import { formValueSelector, SubmissionError, submit } from "redux-form";
import * as Sentry from "@sentry/react";
import { useStripe } from "@stripe/react-stripe-js";

import { useEmployerInvoiceListingContext } from "@js/apps/invoices/context/employer-invoice-listing";
import {
  useLazyGetPaymentTransactionQuery,
  usePayInvoicesMutation,
} from "@js/apps/payments/api";
import {
  ACHPaymentSuccessModalInstance,
  CCPaymentSuccessModalInstance,
} from "@js/apps/payments/components/payment-success-modal";
import type { PayFormValues } from "@js/apps/payments/forms/pay";
import { PAY_FORM_ID } from "@js/apps/payments/forms/pay";
import { Snackbar } from "@js/components/snackbar";
import { useAppDispatch, useAppSelector } from "@js/hooks";
import { useEffectRef } from "@js/hooks/use-effect-ref";
import type { PaymentMethod, PaymentTransaction } from "@js/types/payments";
import { getErrorsForRequiredFields } from "@js/utils";

import { fetchTotalIncludingCCPaymentFee } from "../../actions";
import {
  employerInvoicesApi,
  useGetEmployerInvoicesMinialQuery,
} from "../../api";
import { openCcPaymentFeeModal } from "../../components/cc-payment-fee";
import { openPayDependentInvoiceFirstMessage } from "../../components/pay-dependent-invoices-first";
import {
  getEmployerInvoicesThatCannotBePaid,
  getInvoicesTotal,
} from "../../logic";

const payFormValuesSelector = formValueSelector(PAY_FORM_ID);

type UsePayEmployerInvoicesProps = {
  onInvoicesPaymentFailed: () => void;
  closePaymentModal: () => void;
};

export const usePayEmployerInvoices = ({
  onInvoicesPaymentFailed,
  closePaymentModal,
}: UsePayEmployerInvoicesProps) => {
  const dispatch = useAppDispatch();
  const { selectedInvoicesIds, setSelectedInvoicesIds } =
    useEmployerInvoiceListingContext();
  const {
    data: selectedInvoicesData,
    isFetching: isFetchingSelectedInvoicesData,
  } = useGetEmployerInvoicesMinialQuery({
    ids: selectedInvoicesIds,
  });
  const [payInvoices] = usePayInvoicesMutation();
  const [loading, setLoading] = useState(false);
  const [payRequestError, setPayRequestError] = useState<
    Record<string, string[]> | undefined
  >();
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [
    getTransaction,
    { data: transactionResult, isFetching: fetchingTransaction },
  ] = useLazyGetPaymentTransactionQuery();

  const isTransactionProcessing = useMemo(() => {
    return (
      transactionResult?.status === ENUMS.PaymentStatus.PROCESSING ||
      fetchingTransaction
    );
  }, [fetchingTransaction, transactionResult?.status]);

  const totalIncludingCCPaymentFee = useAppSelector(
    (state) => state.invoices.totalIncludingCCPaymentFee,
  );

  const amount = useMemo(() => {
    if (!selectedInvoicesData) {
      return "";
    }

    return getInvoicesTotal(selectedInvoicesData).toString();
  }, [selectedInvoicesData]);

  const onBeforePayment = (
    paymentMethod: PaymentMethod,
    processPayment: () => void,
  ) => {
    if (
      paymentMethod?.content_type ===
      ENUMS.PaymentContentType.StripeCreditCardPaymentMethod
    ) {
      openCcPaymentFeeModal({
        onConfirm: () => {
          processPayment();
        },
      });

      return;
    }

    return processPayment();
  };

  const getCanSelectedInvoicesBePaid = async (paymentMethod: PaymentMethod) => {
    if (!selectedInvoicesData) {
      return false;
    }

    const invoicesToPay = selectedInvoicesData;
    const cannotBePaid = getEmployerInvoicesThatCannotBePaid(invoicesToPay);

    if (cannotBePaid) {
      openPayDependentInvoiceFirstMessage({
        invoicesThatCannotBePaid: cannotBePaid,
        onConfirmIncludingInvoices: (toInclude) => {
          setSelectedInvoicesIds((prevSelected) => [
            ...prevSelected,
            ...toInclude,
          ]);
        },
      });

      return false;
    }

    if (
      paymentMethod &&
      paymentMethod.content_type ===
        ENUMS.PaymentContentType.StripeCreditCardPaymentMethod
    ) {
      try {
        await dispatch(
          fetchTotalIncludingCCPaymentFee(
            invoicesToPay.map((invoice) => invoice.id),
          ),
        );
      } catch (error) {
        Snackbar.error((error as any)._error);
        return false;
      }
    }

    return true;
  };

  const selectedPaymentMethod = useAppSelector((state) =>
    payFormValuesSelector(state, "paymentMethod"),
  );

  const valueToPay = useMemo(() => {
    if (!selectedPaymentMethod) {
      return;
    }

    if (
      selectedPaymentMethod.content_type ===
      ENUMS.PaymentContentType.StripeACHPaymentMethod
    ) {
      return amount;
    }

    return totalIncludingCCPaymentFee
      ? totalIncludingCCPaymentFee.total_with_fee
      : amount;
  }, [amount, selectedPaymentMethod, totalIncludingCCPaymentFee]);

  const stripe = useStripe();
  const isLoading =
    loading || isTransactionProcessing || isFetchingSelectedInvoicesData;

  const onInvoicesPaymentFailedRef = useEffectRef(onInvoicesPaymentFailed);
  const errorMessageRef = useEffectRef(errorMessage);
  useEffect(
    () => () => {
      if (!errorMessageRef.current) {
        return;
      }

      onInvoicesPaymentFailedRef.current();
    },
    [errorMessageRef, onInvoicesPaymentFailedRef],
  );

  const handleTransactionCreated = (
    transaction: PaymentTransaction,
    paymentMethod: PaymentMethod,
  ) => {
    if (!stripe) {
      console.error("Stripe is not available");
      Snackbar.error(
        "Stripe is not available, please refresh the page and try again.",
      );
      return;
    }

    if (
      paymentMethod.content_type ===
      ENUMS.PaymentContentType.StripeCreditCardPaymentMethod
    ) {
      setLoading(true);

      if (!transaction.gateway_response.client_secret) {
        setLoading(false);
        setErrorMessage("No Client secret");

        Sentry.captureException(
          // @ts-ignore -- Error 2nd arg isn't supported by all browsers
          new Error(`Payment Failed: No clientSecret`),
        );
        return;
      }

      stripe
        .confirmCardPayment(transaction.gateway_response.client_secret, {
          payment_method: paymentMethod.method.payment_method_id,
        })
        .then((response) => {
          if (response.error) {
            setLoading(false);
            setErrorMessage(
              response.error.message || "Unexpected stripe error.",
            );

            Sentry.captureException(
              // @ts-ignore -- Error 2nd arg isn't supported by all browsers
              new Error(`Payment Failed: ${response.error?.code}`, {
                cause: response.error,
              }),
            );
          } else {
            setLoading(false);
            setErrorMessage(null);

            pollTransactionStatus(transaction);
          }
        });
    } else if (
      paymentMethod.content_type ===
      ENUMS.PaymentContentType.StripeACHPaymentMethod
    ) {
      setLoading(false);
      setErrorMessage(null);

      dispatch(
        employerInvoicesApi.util.invalidateTags(["EmployerInvoicesMinimal"]),
      );
      closePaymentModal();

      ACHPaymentSuccessModalInstance.open();
    } else {
      // Depends on payment method but in most cases (for immediate payments)
      // probably just call `pollTransactionStatus(transaction);` here.
    }
  };

  const handleSubmitPaymentForm = async (values: PayFormValues) => {
    const { paymentMethod } = values;
    setLoading(true);

    return payInvoices({
      invoices: selectedInvoicesIds,
      payment_method: paymentMethod.id,
      amount: valueToPay,
    })
      .unwrap()
      .then((transaction) => {
        setPayRequestError(undefined);
        return handleTransactionCreated(transaction, paymentMethod);
      })
      .catch((error) => {
        dispatch(
          employerInvoicesApi.util.invalidateTags(["EmployerInvoicesMinimal"]),
        );
        Sentry.captureException(new Error(JSON.stringify(error)));

        const isAmountError = Object.keys(error.data).includes("amount");
        const isAmountRequiredError =
          isAmountError &&
          !!Object.keys(getErrorsForRequiredFields(error.data, ["amount"]))
            .length;

        if (isAmountError && !isAmountRequiredError) {
          Snackbar.error(error.data["amount"]);
        }

        if (error.data && typeof error.data === "object") {
          setPayRequestError(error.data);
        }

        setLoading(false);
        throw new SubmissionError(error.data);
      });
  };

  const pollTransactionStatus = async (transaction: PaymentTransaction) => {
    return getTransaction(transaction.id)
      .unwrap()
      .then((updatedTransaction) => {
        if (updatedTransaction.status === ENUMS.PaymentStatus.SUCCESS) {
          setErrorMessage(null);

          closePaymentModal();

          dispatch(
            employerInvoicesApi.util.invalidateTags([
              "EmployerInvoicesMinimal",
            ]),
          );

          CCPaymentSuccessModalInstance.open();
        } else if (updatedTransaction.status === ENUMS.PaymentStatus.FAILURE) {
          setErrorMessage(
            `Payment failed. Please try again. ${transaction.error || ""}`,
          );
        } else {
          setTimeout(() => pollTransactionStatus(transaction), 2000);
        }
      });
  };

  const onPayClick = async () => {
    const selectedInvoicesCanBePaid = await getCanSelectedInvoicesBePaid(
      selectedPaymentMethod,
    );

    if (!selectedInvoicesCanBePaid) {
      return;
    }

    const processPayment = () => dispatch(submit(PAY_FORM_ID));
    const result = onBeforePayment(selectedPaymentMethod, processPayment);

    return result;
  };

  return {
    payRequestError,
    isLoading,
    errorMessage,
    stripe,
    handleSubmitPaymentForm,
    onPayClick,
    amount,
    selectedInvoicesData,
  };
};
