import { SubmissionError } from "redux-form";

import {
  followPostClicked,
  unfollowPostClicked,
} from "@js/apps/give-and-get-help/actions";
import {
  useFollowPostMutation,
  useUnfollowPostMutation,
} from "@js/apps/give-and-get-help/api";
import { usePostLocationContext } from "@js/apps/give-and-get-help/context/post-location";
import { Snackbar } from "@js/components/snackbar";
import { useAppDispatch } from "@js/hooks";
import type { IPost } from "@js/types/give-and-get-help";

import { FOLLOW_POST, FollowPostMessages, UNFOLLOW_POST } from "./constants";

export const useFollowPost = () => {
  const [followPost] = useFollowPostMutation();
  const [unfollowPost] = useUnfollowPostMutation();
  const dispatch = useAppDispatch();
  const postLocation = usePostLocationContext();

  const reportErrorHandler = (error) => {
    throw new SubmissionError(error.data);
  };

  const trackFollow = (entity) => {
    dispatch(followPostClicked({ entity, post_location: postLocation }));
  };

  const trackUnfollow = (entity) => {
    dispatch(unfollowPostClicked({ entity, post_location: postLocation }));
  };

  const handleFollowPost = (entity: IPost): Promise<void> => {
    const { followed } = entity;
    const followAction = followed ? unfollowPost : followPost;
    const trackingAction = followed ? trackUnfollow : trackFollow;
    trackingAction(entity);
    return followAction(entity)
      .unwrap()
      .then(() => {
        Snackbar.success(
          followed
            ? FollowPostMessages[UNFOLLOW_POST]
            : FollowPostMessages[FOLLOW_POST],
        );
      })
      .catch(reportErrorHandler);
  };

  return {
    handleFollowPost,
  };
};
