import { useAppSelector } from "@js/hooks";
import type { RootState } from "@js/store";

import { useActiveWeb3React } from "../../hooks";

export function useBlockNumber(): number | undefined {
  const { chainId } = useActiveWeb3React();

  return useAppSelector(
    (state: RootState) => state.application.blockNumber[chainId ?? -1],
  );
}
