import type { ChangeEvent } from "react";
import React from "react";

export const SelectConnector = ({
  SelectComponent,
  input,
  meta,
  multiple = false,
  ...rest
}) => {
  input.value = multiple && !Array.isArray(input.value) ? [] : input.value;
  input.onDrop = null;
  const onChange = (ev: ChangeEvent<HTMLSelectElement>) => {
    input.onChange(ev.target.value);
  };

  return (
    <SelectComponent
      error={meta.touched && meta?.error}
      multiple={multiple}
      {...input}
      onChange={onChange}
      {...rest}
    />
  );
};
