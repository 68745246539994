import React from "react";
import { Field } from "redux-form";

import { Typography } from "@hexocean/braintrust-ui-components";
import { useTrackClientSignUpTermsClicked } from "@js/apps/onboarding/hooks";
import { RouterLink } from "@js/components/link";
import { CheckboxField } from "@js/forms/fields/checkbox";

export const TOSField: React.FC = () => {
  const { handleTermsClick } = useTrackClientSignUpTermsClicked();

  return (
    <Field
      name="accept_terms_of_use"
      id="terms-of-service"
      data-testid="terms-of-service"
      onClick={handleTermsClick}
      component={CheckboxField}
    >
      <Typography component="span" color="secondary">
        I agree to the{" "}
        <Typography
          component="a"
          href={SETTINGS.TERMS_DOC_URL}
          target="_blank"
          className="policy-field-link"
          rel="noopener noreferrer"
          onClick={(e) => e.stopPropagation()}
          RouterLink={RouterLink}
        >
          Terms of Service
        </Typography>{" "}
        and{" "}
        <Typography
          component="a"
          href={SETTINGS.PRIVACY_DOC_URL}
          target="_blank"
          className="policy-field-link"
          rel="noopener noreferrer"
          onClick={(e) => e.stopPropagation()}
          RouterLink={RouterLink}
        >
          Privacy Policy
        </Typography>
      </Typography>
    </Field>
  );
};
