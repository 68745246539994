import { useEffect } from "react";

import { Box } from "@hexocean/braintrust-ui-components";
import { openReviewsDraftsJobModal } from "@js/apps/employer/components/review-drafts-modal/modal-content";
import {
  CantManageMessage,
  InviteTalentEmptyState,
  JobManagementHeader,
  JobManagementNav,
} from "@js/apps/jobs/components";
import { JobManagement } from "@js/apps/jobs/layout";
import { useQueryParams } from "@js/hooks";

import { InvitesLoadingStateTalentsWithSmartMatching } from "../../components/loading-state-talents-with-smart-matching";
import { TalentInviteSuggestionList } from "../../components/talent-invite-suggestion-list";
import { useGetTalentInviteSuggestionsWithSmartMatching } from "../../hooks/get-talent-invite-suggestions-with-smart-matching";

import { useInviteTalentByUrl } from "./use-invite-talent-by-url";

export const InviteTalentPage = () => {
  const { back_to_review_modal } = useQueryParams();
  const shouldBackToReviewModal = back_to_review_modal === "true";
  const { hasInvitationUrl } = useInviteTalentByUrl();

  useEffect(() => {
    if (shouldBackToReviewModal) {
      openReviewsDraftsJobModal();
    }
  }, [shouldBackToReviewModal]);

  return (
    <JobManagement.Layout pageTitle="Job - Invite Talent">
      {hasInvitationUrl ? null : <InviteTalentPageContent />}
    </JobManagement.Layout>
  );
};

const InviteTalentPageContent = () => {
  const {
    talentInviteSuggestions,
    isSmartMatchingInProgress,
    totalBidsCount,
    job,
    analyzingProfilesCount,
  } = useGetTalentInviteSuggestionsWithSmartMatching();

  if (!job || !analyzingProfilesCount) {
    return <></>;
  }

  if (!job.user_can_manage) {
    return (
      <CantManageMessage
        title="You can't manage talent invitations in this job."
        to={`/jobs/${job?.id}`}
      />
    );
  }

  return (
    <JobManagement.ContentLayout
      header={<JobManagementHeader job={job} />}
      main={
        <JobManagement.ContentBox>
          <JobManagementNav job={job} totalBidsCount={totalBidsCount} />
          {isSmartMatchingInProgress ? (
            <InvitesLoadingStateTalentsWithSmartMatching
              analyzingProfilesCount={analyzingProfilesCount}
            />
          ) : talentInviteSuggestions?.length ? (
            <TalentInviteSuggestionList
              talentInviteSuggestions={talentInviteSuggestions}
              job={job}
            />
          ) : (
            <Box my={6}>
              <InviteTalentEmptyState job={job} />
            </Box>
          )}
        </JobManagement.ContentBox>
      }
    />
  );
};
