import React, { useEffect } from "react";
import _ from "underscore";

import { Loader } from "@hexocean/braintrust-ui-components";
import { useGetRolesQuery } from "@js/apps/roles/api";
import { Snackbar } from "@js/components/snackbar";
import { useAppDispatch, useAppSelector } from "@js/hooks";
import { deepClone } from "@js/utils";

import { useAccountType } from "../../common/hooks";
import { fetchNotificationsSettings, saveNotifications } from "../actions";

export const NotificationsContainer = ({ render }) => {
  const dispatch = useAppDispatch();
  const notifications = useAppSelector((state) => state.settings.notifications);
  const loading = useAppSelector(
    (state) => state.settings.notificationsLoading,
  );
  const { data: roles } = useGetRolesQuery();
  const accountType = useAccountType();

  useEffect(() => {
    dispatch(fetchNotificationsSettings());
  }, [dispatch]);

  const onSubmit = (values: any) => {
    const generalClone = deepClone(
      notifications.general_notifications_settings,
    );
    const generalNewValues = generalClone.map((notification) => {
      notification.value =
        values.general_notifications_settings[notification.notification];
      return notification;
    });

    const newNotifications = {
      general_notifications_settings: generalNewValues,
    };

    return saveNotifications(newNotifications).then(() => {
      Snackbar.success("Saved successfully");
      return dispatch(fetchNotificationsSettings());
    });
  };

  if (!roles?.length || loading) {
    return <Loader className="center db" />;
  }

  const initialGeneralSettings =
    notifications?.general_notifications_settings?.reduce((prev, next) => {
      prev[next.notification] = next.value;
      return prev;
    }, {});

  const initialValues = {
    general_notifications_settings: initialGeneralSettings,
  };

  const generalSettingsGroups = _.groupBy(
    notifications.general_notifications_settings,
    (i) => i.group,
  );

  return render({
    accountType,
    onSubmit,
    generalSettingsGroups,
    initialValues,
  });
};
