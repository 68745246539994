import { useMemo } from "react";

import { useGetRolesQuery } from "./api";

export const useJobRole = () => {
  const { featuredRoles } = useFeaturedRoles();

  const roleOptions = useMemo(() => {
    return featuredRoles.map((role) => {
      return {
        label: role.name,
        value: role.id,
      };
    });
  }, [featuredRoles]);

  return {
    roleOptions,
  };
};

export const useFeaturedRoles = () => {
  const { data: roles } = useGetRolesQuery();
  const featuredRoles = useMemo(() => {
    if (!roles) {
      return [];
    }

    return roles.filter((role) => role.is_featured);
  }, [roles]);

  return { featuredRoles };
};

export const useGetRoleData = (id: number | undefined) => {
  const { data: roles } = useGetRolesQuery();

  const roleData = useMemo(() => {
    return roles?.find((role) => role.id === id);
  }, [roles, id]);

  return { data: roleData };
};

export const useGetRoleName = (id: number | undefined): string | undefined => {
  const { data: role } = useGetRoleData(id);

  return role?.name;
};
