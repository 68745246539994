import { useCallback } from "react";
import { useNavigate } from "react-router-dom";

import { useUser } from "@js/apps/common/hooks";

export const useCheckIsEmailVerified = () => {
  const user = useUser();
  const navigate = useNavigate();

  const redirectIfUnverified = useCallback(() => {
    if (!user?.is_verified) {
      navigate("/email_confirm/");
    }
  }, [user, navigate]);

  return {
    redirectIfUnverified,
  };
};
