import { useState } from "react";

import { useFirstUnfinishedSectionLink } from "@js/apps/freelancer/hooks";
import { useCurrentFreelancerProfileCompletion } from "@js/apps/freelancer/hooks/use-current-freelancer-profile-completion";
import { useAppSelector } from "@js/hooks";
import { assertUnreachable } from "@js/utils";

export type StepBeingEdited = undefined | "Role" | "Work Experience";

type ProfileCompletionSteps =
  | "Role"
  | "Work Experience"
  | "About"
  | "Skills"
  | "Profile Completed";

export const useProfileCompletion = () => {
  const [stepBeingEdited, setStepBeingEdited] =
    useState<StepBeingEdited>(undefined);

  const { data: profileCompletion } = useCurrentFreelancerProfileCompletion();

  const isApproved = useAppSelector(
    (state) => state.freelancer.freelancerProfile?.approved,
  );

  const isProfileCompleted = profileCompletion?.completion_percent === 100;

  const linkToFirstUnfinishedSection =
    useFirstUnfinishedSectionLink(profileCompletion);

  function shouldRender(step: ProfileCompletionSteps) {
    switch (step) {
      case "About": {
        return linkToFirstUnfinishedSection.includes("general_info");
      }

      case "Role": {
        const isANextUnfinishedSection = linkToFirstUnfinishedSection.includes(
          "years_of_experience",
        );
        const isCurrentlyBeingEdited = stepBeingEdited === "Role";

        return isANextUnfinishedSection || isCurrentlyBeingEdited;
      }

      case "Skills": {
        const isANextUnfinishedSection =
          linkToFirstUnfinishedSection.includes("edit_skills");
        const isOtherStepBeingEdited = stepBeingEdited !== undefined;

        return isANextUnfinishedSection && !isOtherStepBeingEdited;
      }

      case "Work Experience": {
        const isANextUnfinishedSection =
          linkToFirstUnfinishedSection.includes("work_history");
        const isCurrentlyBeingEdited = stepBeingEdited === "Work Experience";

        return isANextUnfinishedSection || isCurrentlyBeingEdited;
      }

      case "Profile Completed": {
        const isOtherStepBeingEdited = stepBeingEdited !== undefined;

        return !isApproved && !isOtherStepBeingEdited && isProfileCompleted;
      }

      default: {
        assertUnreachable(step);
        return;
      }
    }
  }

  return {
    shouldRender,
    setStepBeingEdited,
    shouldCloseProfileCompletionSequence: isApproved && isProfileCompleted,
  };
};
