import { API } from "@js/api";
import {
  roomListUpdateRecipe,
  roomMessagesUpdateRecipe,
  roomUpdateRecipe,
  unreadMessagesCountUpdateRecipe,
} from "@js/apps/messenger/api-handlers/update-cache-handlers";
import type { RootState } from "@js/store";
import type * as I from "@js/types/messenger";
import {
  combineResults,
  createListenerIdFromArgs,
  listenerWrapper,
} from "@js/utils";

import {
  patchRoomListOnGetSingleRoom,
  patchRoomListOnUnmarkMessageAsSpam,
  patchRoomMessagesOnUnmarkMessageAsSpam,
  patchRoomOnUnmarkMessageAsSpam,
} from "./api-handlers/helpers";

export const messengerApi = API.injectEndpoints({
  endpoints: (build) => ({
    getRoomMessages: build.query<
      I.GetRoomMessagesReturnType,
      I.GetRoomMessagesParamsType
    >({
      query: (params) => ({
        url: "/room_messages/",
        method: "GET",
        params,
      }),
      keepUnusedDataFor: 10000000000, // messages cache is updated through ws messages
      serializeQueryArgs: ({ endpointName, queryArgs }) => {
        const serializeQueryArgs = { ...queryArgs };
        delete serializeQueryArgs.cursor;

        return `${endpointName}(${JSON.stringify(serializeQueryArgs)})`;
      },
      merge: (currentCache, newItems) => {
        const results = combineResults(currentCache.results, newItems.results);

        return {
          ...newItems,
          results,
        };
      },
      forceRefetch({ currentArg, previousArg }) {
        const isFetchingNextMessages =
          !!currentArg?.cursor && previousArg?.cursor !== currentArg.cursor;

        return isFetchingNextMessages;
      },
      async onCacheEntryAdded(
        _arg,
        { updateCachedData, cacheDataLoaded, cacheEntryRemoved },
      ) {
        listenerWrapper({
          updateCachedData,
          cacheDataLoaded,
          cacheEntryRemoved,
          updateRecipe: roomMessagesUpdateRecipe(!!_arg.cursor, _arg.room),
          listenerId: createListenerIdFromArgs(_arg, `room-messages-listener`),
        });
      },
      providesTags: ["MessengerRoomMessagesList"],
    }),
    getRoom: build.query<I.GetRoomReturnType, I.GetRoomParamType>({
      query: ({ id }) => ({
        url: `/message_rooms/${id}`,
        method: "GET",
      }),
      keepUnusedDataFor: 10000000, // updated through ws messages
      async onCacheEntryAdded(
        _arg,
        { updateCachedData, cacheDataLoaded, cacheEntryRemoved, getState },
      ) {
        listenerWrapper({
          updateCachedData,
          cacheDataLoaded,
          cacheEntryRemoved,
          updateRecipe: roomUpdateRecipe({
            getState: getState as () => RootState,
          }),
          listenerId: `message-rooms-listener`,
        });
      },
      async onQueryStarted(_arg, { queryFulfilled, dispatch }) {
        try {
          const { data: room } = await queryFulfilled;

          // put the room in the room list in case it does not exist
          dispatch(
            messengerApi.util.updateQueryData(
              "getRoomList",
              undefined,
              (draft) => {
                patchRoomListOnGetSingleRoom({ draft, room });
              },
            ),
          );
        } catch (e) {
          void e;
        }
      },
      providesTags: (_result, _error, arg) => [
        { type: "MessengerRoomList", id: arg.id },
      ],
    }),
    getRoomList: build.query<
      I.GetRoomListReturnType,
      I.GetRoomListParamsType | void
    >({
      query: (params) => ({
        url: "/message_rooms/",
        method: "GET",
        params,
      }),
      async onCacheEntryAdded(
        _arg,
        { updateCachedData, cacheDataLoaded, cacheEntryRemoved, getState },
      ) {
        listenerWrapper({
          updateCachedData,
          cacheDataLoaded,
          cacheEntryRemoved,
          updateRecipe: roomListUpdateRecipe({
            getState: getState as () => RootState,
          }),
          listenerId: `message-rooms-listener`,
        });
      },
      providesTags: [{ type: "MessengerRoomList", id: "LIST" }],
      keepUnusedDataFor: 0,
      merge: (currentCache, newItems) => {
        const combinedRoomsResults = combineResults(
          currentCache.results,
          newItems.results,
        );

        return {
          ...newItems,
          results: combinedRoomsResults,
        };
      },
      serializeQueryArgs: ({ endpointName, queryArgs }) => {
        const serializeQueryArgs: I.GetRoomListParamsType = {
          name: queryArgs ? queryArgs.name : "",
        };

        return `${endpointName}(${JSON.stringify(serializeQueryArgs)})`;
      },
      forceRefetch({ currentArg, previousArg }) {
        const defaultPage = 1;
        const previousArgPage = previousArg?.page ?? defaultPage;
        const currentArgPage = currentArg?.page ?? defaultPage;
        const isFetchingCachedPage = previousArgPage >= currentArgPage;

        return !isFetchingCachedPage;
      },
    }),
    getUnreadMessagesCount: build.query<
      I.GetUnreadMessagesCountReturnType,
      void
    >({
      query: () => ({
        url: "/room_messages/unread_messages_count",
        method: "GET",
      }),
      onCacheEntryAdded(
        _arg,
        { updateCachedData, cacheDataLoaded, cacheEntryRemoved, getState },
      ) {
        listenerWrapper({
          updateCachedData,
          cacheDataLoaded,
          cacheEntryRemoved,
          updateRecipe: unreadMessagesCountUpdateRecipe(
            getState as () => RootState,
          ),
          listenerId: `unread-messages-count-listener`,
        });
      },
      keepUnusedDataFor: 10000000,
    }),
    getOrCreateMessageRoom: build.mutation<
      I.MessageRoom,
      I.GetOrCreateMessageRoomParams
    >({
      query: ({
        context,
        jobId,
        contentType = ENUMS.RoomTypeContentType.P2P_ROOM_TYPE,
      }) => ({
        url: "/message_rooms/get_or_create/",
        method: "POST",
        data: {
          content_type: contentType,
          context,
        },
        params: {
          in_context_of_job: jobId,
        },
      }),
      invalidatesTags: [{ type: "MessengerRoomList", id: "LIST" }],
    }),
    manualSendMessage: build.mutation<
      void,
      {
        payload: {
          message_content: string;
          author?: number;
        };
        room_context: {
          content_type: EnumType<typeof ENUMS.RoomTypeContentType>;
          context: { participants: number[] };
        };
      }
    >({
      query: (data) => ({
        url: "/send_messages/",
        method: "POST",
        data,
      }),
    }),
    manualSendMessageInBulk: build.mutation<
      void,
      {
        payload: {
          message_content: string;
          author?: number;
        };
        room_context: {
          content_type: EnumType<typeof ENUMS.RoomTypeContentType>;
          sender: number;
          receivers: number[];
        };
      }
    >({
      query: (data) => ({
        url: "/send_messages/in_bulk/",
        method: "POST",
        data,
      }),
    }),
    reportUser: build.mutation<
      I.ReportUserServerResponse,
      I.ReportUserQueryArgs
    >({
      query: ({ id, ...data }) => ({
        url: `/users/${id}/report/`,
        method: "POST",
        data: { ...data },
      }),
    }),
    reportMessage: build.mutation<
      I.ReportUserServerResponse,
      I.ReportUserMessageQueryArgs
    >({
      query: ({ id, ...data }) => ({
        url: `/room_messages/${id}/report/`,
        method: "POST",
        data: { ...data },
      }),
    }),
    unmarkMessageAsSpam: build.mutation<void, I.UnmarkMessageAsSpamQueryArgs>({
      query: ({ messageId, ...data }) => ({
        url: `/room_messages/${messageId}/mark_as_not_spam/`,
        method: "POST",
        data: { ...data },
      }),
      async onQueryStarted(
        { room: roomId, messageId },
        { queryFulfilled, dispatch, getState },
      ) {
        const state = getState() as RootState;

        const patchResults: Array<{ undo: () => void }> = [];
        const roomMessagesPatch = dispatch(
          messengerApi.util.updateQueryData(
            "getRoomMessages",
            { room: roomId },
            (draft) => {
              patchRoomMessagesOnUnmarkMessageAsSpam({ draft, messageId });
            },
          ),
        );
        patchResults.push(roomMessagesPatch);

        const roomMessages = messengerApi.endpoints.getRoomMessages.select({
          room: roomId,
        })(state)?.data;
        const isLastMessage = roomMessages?.results[0]?.id === messageId;

        if (isLastMessage) {
          const roomListPatch = dispatch(
            messengerApi.util.updateQueryData(
              "getRoomList",
              undefined,
              (draft) => {
                patchRoomListOnUnmarkMessageAsSpam({ draft, roomId });
              },
            ),
          );

          patchResults.push(roomListPatch);

          const roomPatch = dispatch(
            messengerApi.util.updateQueryData(
              "getRoom",
              { id: roomId },
              (draft) => {
                patchRoomOnUnmarkMessageAsSpam({ room: draft });
              },
            ),
          );

          patchResults.push(roomPatch);
        }

        try {
          await queryFulfilled;
        } catch (e) {
          patchResults.forEach((patch) => patch.undo());
        }
      },
    }),
    cancelReports: build.mutation<void, number>({
      query: (id) => ({
        url: `/users/${id}/cancel_reports/`,
        method: "POST",
      }),
    }),
    deleteOfferFromMessage: build.mutation<void, number>({
      query: (id) => ({
        url: `/help_offers/${id}/`,
        method: "DELETE",
      }),
      invalidatesTags: ["HelpOffersList"],
    }),
  }),
});

export const {
  useGetUnreadMessagesCountQuery,
  useGetRoomListQuery,
  useGetRoomQuery,
  useGetRoomMessagesQuery,
  useGetOrCreateMessageRoomMutation,
  useManualSendMessageMutation,
  useManualSendMessageInBulkMutation,
  useReportUserMutation,
  useReportMessageMutation,
  useUnmarkMessageAsSpamMutation,
  useCancelReportsMutation,
  useDeleteOfferFromMessageMutation,
} = messengerApi;

export const useGetRoomListQueryState =
  messengerApi.endpoints.getRoomList.useQueryState;
