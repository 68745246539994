import React from "react";
import type { WrappedFieldArrayProps } from "redux-form";
import { Field } from "redux-form";

import { Box, FormHelperText } from "@hexocean/braintrust-ui-components";
import { TextField } from "@js/forms/fields";
import type { ExternalSite } from "@js/types/common";

import styles from "./style.module.scss";

type ExternalProfilesFieldProps = WrappedFieldArrayProps & {
  externalSites: ExternalSite[];
};

const Label = ({ logo, name }) => {
  return (
    <Box display="flex" alignItems="flex-start" gap={1}>
      <img src={logo} alt={name} className="external-profiles__label-icon" />
      {name}
    </Box>
  );
};

export const ExternalProfilesField = ({
  fields,
  meta,
  externalSites = [],
}: ExternalProfilesFieldProps) => {
  return (
    <div className={styles.bioFieldWrapper}>
      {fields.map((name, index) => {
        const profile = fields.get(index);
        const site = externalSites.find(({ id }) => id === profile.new_site);

        if (site === undefined) {
          return null;
        }

        return (
          <Field
            key={name}
            id={site.name}
            placeholder={site.placeholder}
            label={
              <div className={styles.externalProfilesLabel}>
                {site.logo && (
                  <Label logo={site.logo?.thumbnail} name={site.name} />
                )}
              </div>
            }
            name={`${name}.public_url`}
            component={TextField}
          />
        );
      })}
      {meta.error && <FormHelperText error>{meta.error}</FormHelperText>}
    </div>
  );
};
