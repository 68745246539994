import type { ReactNode } from "react";
import { Suspense } from "react";
import type { WrappedFieldProps } from "redux-form";

import { Loader } from "@hexocean/braintrust-ui-components";
import { AutoLinkNode, AutoLinkPlugin } from "@js/components/rich-text-editor";
import { CommonLexicalEditorLazy } from "@js/components/rich-text-editor/common-editor";
import { HashtagPlugin } from "@js/components/rich-text-editor/custom-plugins/hashtag-plugin";
import { CustomHashtagNode } from "@js/components/rich-text-editor/custom-plugins/hashtag-plugin/components/hashtag-node";
import { LINK_MATCHERS } from "@js/components/rich-text-editor/utils";

import {
  POST_FIELD_HASHTAGS,
  POST_FIELD_TEXT,
} from "../post-description-field/constants";

export type PostLexicalTextfieldProps = {
  disabled?: boolean;
  [POST_FIELD_TEXT]: WrappedFieldProps;
  [POST_FIELD_HASHTAGS]?: WrappedFieldProps;
  onChange: (text: string) => void;
  placeholderElement: ReactNode;
  inputClassName?: string;
  "data-testid"?: string;
};

export const PostLexicalTextfield = ({
  onChange,
  disabled,
  [POST_FIELD_TEXT]: textField,
  [POST_FIELD_HASHTAGS]: hashtagsField,
  placeholderElement,
  inputClassName,
  "data-testid": dataTestId,
}: PostLexicalTextfieldProps) => {
  return (
    <Suspense fallback={<Loader centered />}>
      <CommonLexicalEditorLazy
        data-testid={dataTestId}
        name={textField.input.name}
        className={inputClassName}
        disabled={disabled}
        placeholder={placeholderElement}
        {...textField}
        onChange={onChange}
        output="text"
        additionalPlugins={
          [
            <AutoLinkPlugin matchers={LINK_MATCHERS} key="auto-link-plugin" />,
            !!hashtagsField ? (
              <HashtagPlugin {...hashtagsField} key="hashtag-plugin" />
            ) : null,
          ].filter(Boolean) as JSX.Element[]
        }
        additionalNodes={[AutoLinkNode, CustomHashtagNode]}
      />
    </Suspense>
  );
};
