import cx from "classnames";

import type { BoxProps } from "@hexocean/braintrust-ui-components";
import { RoundedBox } from "@hexocean/braintrust-ui-components";
import { AppLayout } from "@js/layouts/app";

export const JobManagementLayout = (props) => {
  return (
    <AppLayout
      pageTitle="Job Applications"
      coreClassName="job-management-page"
      bgcolor="var(--soft-blue)"
      {...props}
    />
  );
};

type JobManagementContentLayoutProps = {
  header: React.ReactNode;
  main: React.ReactNode;
};

export const JobManagementContentLayout = ({
  header,
  main,
}: JobManagementContentLayoutProps) => {
  return (
    <div className="job-management-page__content">
      {header}
      {main}
    </div>
  );
};

export const JobManagementContentBox = ({ className, ...props }: BoxProps) => {
  return (
    <RoundedBox
      component="main"
      className={cx(className, "job-management-page__main")}
      {...props}
    />
  );
};

export const JobManagement = {
  Layout: JobManagementLayout,
  ContentLayout: JobManagementContentLayout,
  ContentBox: JobManagementContentBox,
};
