import React from "react";

import { useAppSelector } from "@js/hooks";

import { EmployerVisibility } from "./employer-visibility";
import { FreelancerVisibility } from "./freelancer-visibility";

export const VisibilitySection = () => {
  const accountType = useAppSelector((state) => state.auth.user?.account_type);
  if (accountType === ENUMS.AccountType.FREELANCER) {
    return <FreelancerVisibility />;
  }

  return <EmployerVisibility />;
};
