import React from "react";

import {
  AlignLeftIcon,
  ChatIcon,
  EarnIcon,
  JobsIcon,
  PeopleIcon,
  WalletIcon,
  WelcomeIcon,
  WorkIcon,
} from "@hexocean/braintrust-ui-components/Icons";
import { trimURL } from "@js/utils";

import { WalletLabelWithTokenAmount } from "../common/components/wallet-label-with-btrust-amount";
import { GiveGetHelpLabel } from "../give-and-get-help/components/give-get-help-label";
import { SpacesNavLabel } from "../spaces/components";

export const getFreelancerNavMenuItems = (
  jobsPath: string,
): DashboardNavItem[] => {
  return [
    createNavItem({
      path: "/talent/dashboard/welcome/",
      ...NavMenuLabels.home,
    }),
    createNavItem({
      path: jobsPath,
      ...NavMenuLabels.jobs,
    }),
    createNavItem({
      path: "/feed",
      ...NavMenuLabels.community,
      subLinks: [
        {
          ...createNavItem({
            path: "/spaces",
            ...NavMenuLabels.spaces,
          }),
          defaultToActive: true,
          distinctPathFromParent: true,
        },
        {
          path: "my_posts",
          ...NavMenuLabels.myPosts,
        },
      ],
    }),
    createNavItem({
      path: "/talent/dashboard/my_jobs",
      ...NavMenuLabels.myWork,
      hideInSidebarNav: true,
      subLinks: [
        {
          path: "invites",
          label: "Invites",
        },
        {
          path: "my_proposals",
          label: "Applications",
          onlyActiveOnIndex: false,
          subPath: [
            "/talent/dashboard/my_jobs/my_proposals/open_applications",
            "/talent/dashboard/my_jobs/my_proposals/past_applications",
          ],
        },
        {
          path: "offers",
          label: "Job Offers",
          onlyActiveOnIndex: false,
          subPath: [
            "/talent/dashboard/my_jobs/offers/current_offers",
            "/talent/dashboard/my_jobs/offers/past_offers",
          ],
        },
        {
          path: "history",
          label: "My Jobs",
          onlyActiveOnIndex: false,
          subPath: [
            "/talent/dashboard/my_jobs/history/active_jobs",
            "/talent/dashboard/my_jobs/history/past_jobs",
          ],
        },
        {
          path: "help_offers",
          label: "1:1 Help Offers",
          onlyActiveOnIndex: false,
          subPath: [
            "/talent/dashboard/my_jobs/help_offers/getting_help",
            "/talent/dashboard/my_jobs/help_offers/giving_help",
          ],
        },
        {
          path: "invoices",
          label: "Invoices",
        },
      ],
    }),
    createNavItem({
      path: "/talent/dashboard/my_wallet/",
      ...NavMenuLabels.wallet,
    }),
    createNavItem({
      path: "/talent/dashboard/earn/",
      ...NavMenuLabels.earn,
    }),
  ];
};

export const findCurrentNavItem = ({
  items,
  exact,
}: {
  items: DashboardNavItem[];
  exact?: boolean;
}) => {
  const linkItem = items.find(({ path }) => {
    const currentPathnameTrimmed = trimURL(window.location.pathname);
    const linkPathnameTrimmed = trimURL(path).split("?")[0];
    if (exact) {
      return currentPathnameTrimmed === linkPathnameTrimmed;
    }

    return currentPathnameTrimmed.includes(linkPathnameTrimmed);
  });

  return linkItem;
};

type Path = string;
type Label = React.ReactNode;
type CanAccess = boolean;

export type DashboardNavItem = {
  path: Path;
  label: Label;
  icon?: React.ElementType;
  variant?: string;
  canAccess?: CanAccess;
  subLinks?: Array<DashboardNavSubItem>;
  hideInSidebarNav?: boolean;
  defaultToActive?: boolean;
};

type DashboardNavSubItem = {
  path: Path;
  label: Label;
  subPath?: string[];
  subLinks?: Array<DashboardNavSubItem>;
  defaultToActive?: boolean;
  onlyActiveOnIndex?: boolean;
  distinctPathFromParent?: boolean;
};

const createNavItem = (arg: DashboardNavItem) => {
  return {
    icon: undefined,
    canAccess: true,
    hideInSidebarNav: false,
    defaultToActive: false,
    ...arg,
    subLinks: (arg.subLinks || []).map((link) => {
      return {
        ...link,
        path: `${link.distinctPathFromParent ? "" : arg.path + "/"}${link.path}`,
      };
    }),
  };
};

const NavMenuLabels = {
  home: {
    label: "Home",
    icon: WelcomeIcon,
  },
  jobs: {
    label: "Jobs",
    icon: JobsIcon,
  },
  community: {
    label: <GiveGetHelpLabel />,
    icon: ChatIcon,
  },
  spaces: {
    label: <SpacesNavLabel />,
    icon: PeopleIcon,
  },
  myPosts: {
    label: "My posts",
    icon: AlignLeftIcon,
  },
  myWork: {
    label: "My Work",
  },
  wallet: {
    label: <WalletLabelWithTokenAmount />,
    icon: WalletIcon,
  },
  earn: {
    label: "Refer and Earn",
    icon: EarnIcon,
  },
  invoices: {
    label: "Invoices",
    icon: EarnIcon,
  },
  my_jobs: {
    label: "My Jobs",
    icon: JobsIcon,
    variant: "transparent-with-hover",
  },
  talent: {
    label: "Talent",
    icon: WorkIcon,
    variant: "transparent-with-hover",
  },
};

export const getNavMenuColorVariant = (currentItem?: DashboardNavItem) => {
  if (!currentItem) {
    return getNavMenuColorForPathname(window.location.pathname);
  }

  switch (currentItem.label) {
    case NavMenuLabels.home.label:
      return "nav-violet";
    case NavMenuLabels.jobs.label:
    case NavMenuLabels.my_jobs.label:
    case NavMenuLabels.talent.label: {
      return "nav-blue";
    }
    case NavMenuLabels.spaces.label:
    case NavMenuLabels.community.label:
    case NavMenuLabels.wallet.label:
      return "nav-green";
    case NavMenuLabels.earn.label:
    case NavMenuLabels.invoices.label:
    default:
      return "nav-default-white";
  }
};

const pathnameColorVariants = [
  { regexp: /^(\/talent\/\d+)/, variant: "nav-violet" },
  { regexp: /\/jobs\/\d+\/offers\/\d+\/accepted\/?$/, variant: "nav-green" },
  { regexp: /^(\/talent\/?$|\/jobs\/?|\/employers\/\d+)/, variant: "nav-blue" },
  { regexp: /^(\/feed\/?|\/spaces\/?)/, variant: "nav-green" },
  { regexp: /^(\/messages\/?)/, variant: "nav-orange" },
];
const getNavMenuColorForPathname = (pathname: string) => {
  const matchingVariantData = pathnameColorVariants.find(({ regexp }) =>
    regexp.test(pathname),
  );

  return matchingVariantData?.variant ?? "nav-default-white";
};
