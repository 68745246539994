import type { ReferCardProps } from "@js/apps/common/components";
import { ReferModalContent } from "@js/apps/common/components";

import styles from "./style.module.scss";

export const ReferCardsSection = () => {
  return (
    <div className={styles.referCardsSection}>
      <ReferClientsCard />
      <ReferTalentsCard />
    </div>
  );
};

const commonProps: {
  descriptionProps: ReferCardProps["descriptionProps"];
  footerProps: ReferCardProps["footerProps"];
} = {
  descriptionProps: {
    sx: {
      minHeight: { xs: undefined, md: "7.5rem", lg: "5.5rem" },
    },
  },
  footerProps: {
    sx: {
      display: "flex",
      alignItems: "flex-end",
      minHeight: "4rem",
      pt: { xs: 3, sm: 1 },
      margin: 0,
      boxSizing: "border-box",
    },
  },
};

const ReferClientsCard = () => {
  return (
    <ReferModalContent
      hideImage
      className={styles.referCard}
      bgcolor="soft-blue"
      headline={SETTINGS.EMPLOYER_REFERRAL_HEADLINE}
      subtext={SETTINGS.EMPLOYER_REFERRAL_SUBTEXT}
      finePrint={SETTINGS.EMPLOYER_REFERRAL_FINE_PRINT}
      shareLinksParams={{
        twitterText: SETTINGS.EMPLOYER_REFERRAL_TWITTER_SHARE_COPY,
        subject: SETTINGS.EMPLOYER_REFERRAL_SHARE_COPY_EMAIL_SUBJECT,
        body: SETTINGS.EMPLOYER_REFERRAL_SHARE_COPY_EMAIL_BODY,
      }}
      copyLinkProps={{ variant: "card", iconColorVariant: "medium-blue" }}
      type="client"
      {...commonProps}
    />
  );
};

const ReferTalentsCard = () => {
  return (
    <ReferModalContent
      hideImage
      className={styles.referCard}
      type="talent"
      headline="Refer Talent"
      subtext="Invite Talent to sign up using your link, and you’ll earn 1% of their earnings in BTRST for any job they land on Braintrust."
      copyLinkProps={{ variant: "card" }}
      {...commonProps}
    />
  );
};
