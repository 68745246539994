export const FRONTEND_STORAGE_KEYS = {
  entered_the_page_first_time_at: "entered_the_page_first_time_at",
  feed_visits: "feed_visits",
  PAYMENT_METHOD_FAILED_TOP_BAR: "PAYMENT_METHOD_FAILED_TOP_BAR",
  GIVE_GET_INTRODUCTION_MODAL_VISITED: "GIVE_GET_INTRODUCTION_MODAL_VISITED",
  IS_FIRST_LOGIN_TO_APP: "IS_FIRST_LOGIN_TO_APP",
  complete_profile_top_bar_dismissed_at:
    "complete_profile_top_bar_dismissed_at",
  is_bookmarks_introduced: "is_bookmarks_introduced",
  working_hours_coach_mark_shown: "working_hours_coach_mark_shown",
  is_ATS_integration_module_dismissed: "is_ATS_integration_module_dismissed",
  is_ats_introduced: "is_ats_introduced",
};

export type FrontendStorageKey = ObjectValues<typeof FRONTEND_STORAGE_KEYS>;
