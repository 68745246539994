import { Field } from "redux-form";

import { Grid } from "@hexocean/braintrust-ui-components";
import IndustrySelectField from "@js/apps/employer/fields/industry-select";
import { GooglePlacesSingleField, TextField } from "@js/forms/fields";
import { required } from "@js/forms/validators";

export const OnboardingOrganizationContent = () => {
  return (
    <Grid container spacing={3} marginTop={1} marginBottom={3}>
      <Grid item xs={12} sm={6}>
        <Field
          id="name"
          name="name"
          color="secondary"
          component={TextField}
          label="Organization name*"
          placeholder="Enter the company name"
          validate={[required]}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Field
          id="industry_id"
          name="industry_id"
          component={IndustrySelectField}
          label="Industry*"
          placeholder="Select the Industry"
          validate={[required]}
          menuVariant="violet"
        />
      </Grid>

      <Grid item xs={12}>
        <Field
          name="google_full_location"
          component={GooglePlacesSingleField}
          label="Location*"
          validate={[required]}
        />
      </Grid>

      <Grid item xs={12}>
        <Field
          id="website"
          name="website"
          component={TextField}
          label="Website*"
          placeholder="Enter the company website"
          validate={[required]}
        />
      </Grid>
    </Grid>
  );
};
