import { Box, Button, Typography } from "@hexocean/braintrust-ui-components";

type EmptyTalentListComponentTypes = {
  onClear: () => void;
};

export const EmptyTalentList = ({ onClear }: EmptyTalentListComponentTypes) => {
  return (
    <Box mx="auto" textAlign="center">
      <img
        src={`${SETTINGS.STATIC_URL}freelancer/talent-empty-state.svg`}
        alt=""
      />
      <Box mt={1} mb={1}>
        <Typography component="h2" fontWeight={500} size="medium">
          Sorry, there are no talent available for that search
        </Typography>
      </Box>
      <Typography component="p" size="medium">
        Try removing filters or changing some of your search criteria
      </Typography>
      <Button
        className="mt"
        variant="secondary"
        onClick={onClear}
        size="x-small"
        type="button"
      >
        Clear all filters
      </Button>
    </Box>
  );
};
