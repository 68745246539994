import { useMoveATSJobsToDraftMutation } from "@js/apps/jobs/api";
import type { ATSJob, MoveATSJobsToDraftActionArgs } from "@js/types/jobs";

export const useMoveATSJobsToDraft = () => {
  const [moveATSJobsToDraft] = useMoveATSJobsToDraftMutation();

  const moveJobsToDraft = async (atsJobs: ATSJob[]) => {
    const payload = { batch: transformATSJobsToToBatch(atsJobs) };
    return await moveATSJobsToDraft(payload).unwrap();
  };

  return {
    moveATSJobsToDraft: moveJobsToDraft,
  };
};

const transformATSJobsToToBatch = (
  aTSJobs: ATSJob[],
): MoveATSJobsToDraftActionArgs["batch"] => {
  if (!aTSJobs.length) {
    return [];
  }

  return aTSJobs.map((job) => {
    return {
      external_id: job.external_id,
      data: {
        title: job.title,
        description: job.description,
      },
    };
  });
};
