import { useMemo, useState } from "react";

import {
  FRONTEND_STORAGE_KEYS,
  useGetStorageValueQuery,
  useSetStorageValueMutation,
} from "@js/apps/common/frontend-storage";
import { openProfileCompletionSequenceModal } from "@js/apps/freelancer/components/profile-completion-sequence/profile-completion-sequence";
import { useCurrentFreelancerProfileCompletion } from "@js/apps/freelancer/hooks/use-current-freelancer-profile-completion";
import { useAppSelector } from "@js/hooks";
import { isDaysFromTodayEqualOrHigher } from "@js/utils";

const DAYS_THRESHOLD = 2;

export const useCompleteProfileTopBar = () => {
  const [dismissed, setDismissed] = useState(false);
  const profile = useAppSelector((state) => state.freelancer.freelancerProfile);
  const { data: profileCompletion } = useCurrentFreelancerProfileCompletion();
  const isProfileCompleted = !!profileCompletion?.profile_completion_hit_at;
  const shouldDisplayTopBar = !!profileCompletion && !isProfileCompleted;

  const [setStorageValue] = useSetStorageValueMutation();
  const { data: storedDate, isLoading } = useGetStorageValueQuery(
    { key: FRONTEND_STORAGE_KEYS.complete_profile_top_bar_dismissed_at },
    { skip: !shouldDisplayTopBar },
  );

  const dismissBar = () => {
    const currentDate = new Date().toUTCString();
    setDismissed(Boolean(currentDate));
    return setStorageValue({
      key: FRONTEND_STORAGE_KEYS.complete_profile_top_bar_dismissed_at,
      value: currentDate,
    });
  };

  const showTopBar = useMemo(() => {
    if (!shouldDisplayTopBar || isLoading || dismissed) {
      return false;
    }

    if (!storedDate || typeof storedDate !== "string") {
      return true;
    }
    const shouldDisplayTopBarAgain = isDaysFromTodayEqualOrHigher(
      storedDate,
      DAYS_THRESHOLD,
    );

    return shouldDisplayTopBarAgain;
  }, [isLoading, shouldDisplayTopBar, dismissed, storedDate]);

  const completeProfile = () => {
    if (!profile) return;
    openProfileCompletionSequenceModal(profile);
  };

  return {
    dismissBar,
    showTopBar,
    completeProfile,
  };
};
