import type { MaybeDrafted } from "@reduxjs/toolkit/dist/query/core/buildThunks";

import { WebSocketManagerContainer } from "@js/components/websocket";
import type { UpdateCachedData } from "@js/types/give-and-get-help";

type ExtendedUpdateRecipe<D> = (
  event: MessageEvent,
  draft: MaybeDrafted<D>,
) => void | MaybeDrafted<D>;

type UpdateRecipe = (event: MessageEvent) => void;

type ListenersWrapperConfig<T, E> = {
  cacheDataLoaded: T;
  cacheEntryRemoved: E;
  listenerId: string;
};

type ListenersConfigDrafted<D> =
  | {
      updateCachedData?: never;
      updateRecipe: UpdateRecipe;
    }
  | {
      updateCachedData?: UpdateCachedData<D>;
      updateRecipe: ExtendedUpdateRecipe<D>;
    };

type ConnectedConfig<D, T, E> = ListenersWrapperConfig<T, E> &
  ListenersConfigDrafted<D>;

export const listenerWrapper = async <D, T, E>({
  updateCachedData,
  cacheDataLoaded,
  cacheEntryRemoved,
  updateRecipe,
  listenerId,
}: ConnectedConfig<D, T, E>) => {
  try {
    await cacheDataLoaded;

    const listener = createListener({ updateRecipe, updateCachedData });

    WebSocketManagerContainer.attachListener(listener, listenerId);
  } catch (e) {
    console.error(e);
  }

  await cacheEntryRemoved;

  WebSocketManagerContainer.removeListener(listenerId);
};

export const createListener = <D,>({
  updateRecipe,
  updateCachedData,
}: ListenersConfigDrafted<D>) => {
  if (updateCachedData) {
    const listener = (event: MessageEvent) => {
      return updateCachedData((draft) => updateRecipe(event, draft));
    };

    return listener;
  }

  return updateRecipe as UpdateRecipe;
};

export const createListenerIdFromArgs = <
  T extends Record<string, number | string | boolean>,
>(
  args: T,
  baseId: string,
) => {
  const argsArr = Object.entries(args)
    .filter(
      (value) => Boolean(value[1]) || value[1] === 0 || value[1] === false,
    )
    .map((value) => `${value[0]}=${value[1]}`);

  if (argsArr.length) {
    return `${baseId}-${argsArr.join("-")}`;
  }

  return baseId;
};
