import { useMemo } from "react";
import _ from "underscore";

export const useDebouncedMutation = (useMutation, wait = 1000) => {
  const [mutation, results] = useMutation();
  const debouncedMutation = useMemo(
    () => _.debounce(mutation, wait),
    [mutation, wait],
  );

  return [debouncedMutation, results];
};
