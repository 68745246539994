import React from "react";

import type { IconButtonProps } from "@hexocean/braintrust-ui-components";
import { IconButton, Menu } from "@hexocean/braintrust-ui-components";
import {
  MoreVertIcon,
  ThumbDownEmojiIcon,
} from "@hexocean/braintrust-ui-components/Icons";
import { useChangeProposalSection } from "@js/apps/admin/hooks/change-proposal-section";
import { useIsNodeStaff } from "@js/apps/common/hooks/is-node-staff";
import { shareApplicantClicked } from "@js/apps/employer/actions";
import { openShareBidsWithTeamMembersModal } from "@js/apps/employer/components/share-bids-with-team-members-modal";
import { useCanShareWithTeamMembers } from "@js/apps/employer/hooks/use-can-share-with-team-members";
import { useScroll } from "@js/apps/jobs/context";
import { useBidsContext } from "@js/apps/jobs/context/refetch-bids-context";
import { getMakeOfferUrl } from "@js/apps/jobs/utils";
import { RouterLink } from "@js/components/link";
import { Snackbar } from "@js/components/snackbar";
import { useAppDispatch } from "@js/hooks/redux";
import type { EmployerBidListBid, Job } from "@js/types/jobs";

import {
  useUnRejectBidMutation,
  useUpdateMatcherRecommendationMutation,
} from "../../api";
import { openOptionalFeesModal } from "../bid-menu";
import { openRejectOfferMessageModal } from "../bid-messages-modals";
import { openMakeOfferMessageModal } from "../make-offer-message";

import { hideApplication } from "./hide-application";

type BidActionsMenuProps = {
  bid: EmployerBidListBid;
  job: Job;
  sx?: IconButtonProps["sx"];
  closeDrawer?: () => void;
  includeMakeOfferAction?: boolean;
};

export const BidActionsMenu = ({
  bid,
  job,
  sx,
  closeDrawer,
  includeMakeOfferAction = false,
}: BidActionsMenuProps) => {
  const dispatch = useAppDispatch();
  const isNodeStaff = useIsNodeStaff();
  const isFreelanceHired = bid.status === ENUMS.BidStatus.HIRED;
  const displayDeclineAction =
    bid.status !== ENUMS.BidStatus.REJECTED &&
    bid.status !== ENUMS.BidStatus.HIRED &&
    !job.completed_at &&
    job.openings_number > job.hired_bids_count;
  const displayUnRejectAction = bid.status === ENUMS.BidStatus.REJECTED;
  const displayMakeOfferAction =
    includeMakeOfferAction &&
    bid.can_make_offer &&
    (bid.freelancer.approved || job.job_type === ENUMS.JobType.GRANT) &&
    (bid.status === ENUMS.BidStatus.NEW ||
      bid.status === ENUMS.BidStatus.VIEWED);
  const makeOfferUrl = getMakeOfferUrl({ jobId: job.id, bidId: bid.id });
  const displayOptionalFees = isFreelanceHired && isNodeStaff;
  const displayShareBid = useCanShareWithTeamMembers();

  const [updateMatcherRecommendation] =
    useUpdateMatcherRecommendationMutation();

  const { refetchBidList } = useBidsContext();
  const { setScrollY } = useScroll();

  const [unRejectBid] = useUnRejectBidMutation();

  const { handleHideProposal } = useChangeProposalSection();

  if (!refetchBidList) return null;

  return (
    <Menu
      anchor={
        <IconButton
          sx={{
            width: "42px",
            height: "42px",
            ...sx,
          }}
          aria-label="more actions"
          variant="white-violet"
          shape="squared"
        >
          <MoreVertIcon />
        </IconButton>
      }
    >
      <Menu.Item
        href={`/talent/${bid.freelancer.id}`}
        component={RouterLink}
        target="_blank"
        rel="noopener noreferrer"
      >
        View profile
      </Menu.Item>
      {displayShareBid && (
        <Menu.Item
          onClick={() => {
            dispatch(shareApplicantClicked());
            openShareBidsWithTeamMembersModal({
              jobOwnerId: job.creator.id,
              bidIds: [bid.id],
            });
          }}
        >
          Share applicant
        </Menu.Item>
      )}
      {displayMakeOfferAction && (
        <Menu.Item
          onClick={() => {
            if (!!closeDrawer) closeDrawer();
            openMakeOfferMessageModal({
              bid,
              nextUrl: makeOfferUrl,
            });
          }}
        >
          Make offer
        </Menu.Item>
      )}
      {displayDeclineAction && (
        <Menu.Item
          onClick={() => {
            const scrollYPosition = window.scrollY;
            setScrollY(scrollYPosition);
            if (!!closeDrawer) closeDrawer();
            openRejectOfferMessageModal({
              bid,
              nextUrl: `/jobs/${job.id}/proposals/${bid.id}/rejection_feedback`,
              refetchBidList,
              job,
            });
          }}
        >
          Send rejection
        </Menu.Item>
      )}
      {displayUnRejectAction && (
        <Menu.Item
          disabled={!bid.can_unreject}
          tooltipText={bid.can_unreject_explanation || undefined}
          onClick={() => {
            unRejectBid({ bidId: bid.id })
              .unwrap()
              .then(() => refetchBidList())
              .catch((error) => {
                const errorMessage = error?.data[0] || "Un-rejection failed.";
                Snackbar.error(errorMessage);
              });
          }}
        >
          Un-reject
        </Menu.Item>
      )}
      {!isFreelanceHired && !bid.is_hidden && (
        <Menu.Item
          onClick={async () =>
            await hideApplication(bid.id, refetchBidList, handleHideProposal)
          }
        >
          Hide
          <ThumbDownEmojiIcon sx={{ fontSize: 17, marginLeft: "5px" }} />
        </Menu.Item>
      )}
      {displayOptionalFees && (
        <Menu.Item
          onClick={() => openOptionalFeesModal({ bid, refetchBidList })}
        >
          Optional fees
        </Menu.Item>
      )}
      {isNodeStaff && bid.freelancer.approved && (
        <Menu.Item
          onClick={() => {
            updateMatcherRecommendation({
              bidId: bid.id,
              isRecommended: !bid.recommended_by_matcher,
            });
          }}
        >
          {bid.recommended_by_matcher ? "Remove" : "Add"} Matcher badge
        </Menu.Item>
      )}
    </Menu>
  );
};
