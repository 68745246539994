type DotBadgeProps = {
  show: boolean;
  children: React.ReactNode;
};

export const DotBadge = ({ show, children }: DotBadgeProps) => {
  return (
    <div className="dot-badge">
      {show && <div className="dot-badge-dot" />}
      {children}
    </div>
  );
};
