import React from "react";
import { createRoot } from "react-dom/client";

import { applyReduxFieldPatch } from "./utils/redux-form";
import App from "./app";
import { configureServices } from "./configure";

configureServices();
applyReduxFieldPatch();

const renderApp = () => {
  const container = document.getElementById("root");
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const root = createRoot(container!);

  root.render(<App />);
};

renderApp();
export { createApprovalStatusString } from "@js/apps/jobs/apps/listing/utils/create-approval-status-string";
export { createHelpCategoryString } from "@js/apps/jobs/apps/listing/utils/create-help-category-string";
export { createRateString } from "@js/apps/jobs/apps/listing/utils/create-rate-string";
export { createSkillsString } from "@js/apps/jobs/apps/listing/utils/create-skills-string";
export { createYearsOfExperienceString } from "@js/apps/jobs/apps/listing/utils/create-years-of-experience-string";
export { useSubscribableNotifications } from "@js/apps/settings/hooks/use-subscribable-notifications";
