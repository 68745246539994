import { useEffect, useState } from "react";
import { matchPath, useLocation, useNavigate } from "react-router-dom";
import { SubmissionError } from "redux-form";

import {
  useCreateHelpServiceMutation,
  useDeleteHelpServiceMutation,
  useEditHelpServiceMutation,
  useGetHelpServicesQuery,
} from "@js/apps/give-and-get-help/api";
import { useGetHelpServicesDraftQuery } from "@js/apps/give-and-get-help/api";
import { useIsCategorySuggestedContext } from "@js/apps/give-and-get-help/context";
import { getInitialBudget } from "@js/apps/give-and-get-help/utils";
import { Snackbar } from "@js/components/snackbar";
import type { HelpService } from "@js/types/give-and-get-help";
import { typeGuard } from "@js/utils";

import type { ServiceFormFields } from "../../forms/add-ggh-services-form";

export const ADD_SERVICE_STEP = {
  CATEGORY_FORM_MODAL_OPENED: "CATEGORY_FORM_MODAL_OPENED",
  BUDGET_FORM_MODAL_OPENED: "BUDGET_FORM_MODAL_OPENED",
  SERVICES_LIST_MODAL_OPENED: "SERVICES_LIST_MODAL_OPENED",
} as const;

export type StepType = EnumType<typeof ADD_SERVICE_STEP>;

type Response =
  | {
      data: HelpService;
    }
  | {
      error: unknown;
    };

export const useAddServices = (
  freelancerId: number,
  initialStep?: StepType,
  preselectedValues?: Partial<ServiceFormFields>,
) => {
  const {
    data: services,
    isLoading,
    isError,
  } = useGetHelpServicesQuery({
    freelancerId,
  });
  const [createHelpService] = useCreateHelpServiceMutation();
  const [deleteHelpService] = useDeleteHelpServiceMutation();
  const [editHelpService] = useEditHelpServiceMutation();
  const navigate = useNavigate();

  const { setIsCategorySuggested } = useIsCategorySuggestedContext();
  const { refetch } = useGetHelpServicesDraftQuery();
  const { pathname } = useLocation();

  const [initialValues, setInitialValues] = useState<
    Partial<ServiceFormFields> | undefined
  >(preselectedValues);

  const [step, setStepObject] = useState<{
    current: StepType;
    prev: StepType | null;
  }>({
    current: initialStep || ADD_SERVICE_STEP.CATEGORY_FORM_MODAL_OPENED,
    prev: null,
  });

  useEffect(() => {
    if (isError) {
      Snackbar.error("Failed to fetch Services.");
    }
  }, [isError]);

  const setStep = (newStep: StepType) => {
    setStepObject({
      current: newStep,
      prev: step.current,
    });
  };

  const goBack = () => {
    if (step.prev) {
      setStep(step.prev);
    }
  };

  const handleCategorySubmit = () =>
    setStep(ADD_SERVICE_STEP.BUDGET_FORM_MODAL_OPENED);

  const onSubmit = async ({
    category,
    description,
    budget,
    id,
  }: ServiceFormFields) => {
    const dataToSend = {
      category,
      description,
      budget,
      draft: false,
    };

    const response = id
      ? await editHelpService({ id, data: dataToSend })
      : await createHelpService(dataToSend);

    if (typeGuard<Response, { error: unknown }>(response, "error")) {
      throw new SubmissionError((response.error as any).data);
    }
    refetch();
  };

  const isTalentProfilePage = !!matchPath("/talent/:id", pathname);

  const onSubmitSuccess = (
    _result: unknown,
    _dispatch: unknown,
    props: { destroy: () => void },
  ) => {
    if (props.destroy) props.destroy();
    onDiscardChanges();
    setIsCategorySuggested(false);

    if (!isTalentProfilePage) {
      navigate(`/talent/${freelancerId}/#services-section`);
    }
  };

  const deleteService = (serviceId: number) =>
    deleteHelpService({ id: serviceId });

  const editService = (service: HelpService) => {
    setInitialValues({
      id: service.id,
      category: service.category.id,
      description: service?.description || "",
      budget: getInitialBudget(service.budget),
    });
    setStepObject({
      current: ADD_SERVICE_STEP.BUDGET_FORM_MODAL_OPENED,
      prev: ADD_SERVICE_STEP.CATEGORY_FORM_MODAL_OPENED,
    });
  };

  const onDiscardChanges = () => {
    setStep(ADD_SERVICE_STEP.SERVICES_LIST_MODAL_OPENED);
    setInitialValues(undefined);
  };

  const currentStep = step.current;

  return {
    currentStep,
    goBack,
    initialValues,
    setStep,
    handleCategorySubmit,
    onSubmit,
    onSubmitSuccess,
    isLoading,
    services,
    deleteService,
    editService,
    onDiscardChanges,
  };
};
