import { Button } from "@hexocean/braintrust-ui-components";
import { getMakeOfferUrl } from "@js/apps/jobs/utils";
import type { EmployerBidListBid, Job } from "@js/types/jobs";

import { openMakeOfferMessageModal } from "../../make-offer-message";

import { CannotMakeOfferExplanation } from "./cannot-make-offer-explanation";

type MakeOfferButtonProps = {
  bid: EmployerBidListBid;
  job: Job;
  children: string;
};

export const MakeOfferButton = ({
  bid,
  job,
  children,
}: MakeOfferButtonProps) => {
  const makeOfferUrl = getMakeOfferUrl({ jobId: job.id, bidId: bid.id });

  return (
    <CannotMakeOfferExplanation bid={bid}>
      <Button
        disabled={!bid.can_make_offer}
        onClick={() =>
          openMakeOfferMessageModal({
            bid,
            nextUrl: makeOfferUrl,
          })
        }
        variant="primary"
        shape="squared"
      >
        {children}
      </Button>
    </CannotMakeOfferExplanation>
  );
};
