import { useAppSelector } from "@js/hooks";

import {
  useGetFreelancerSkillsQuery,
  useGetFreelancerWorkExperienceQuery,
} from "../api";

import { useCurrentFreelancerProfileCompletion } from "./use-current-freelancer-profile-completion";

const REQUIREMENTS = {
  ROLE_COUNT: 1,
  SKILLS_COUNT: 5,
  EXPERIENCE_COUNT: 1,
  ABOUT_COUNT: 2,
} as const;

export const useDashboardProfileCompletionNudge = () => {
  const { data: profileCompletion, isFetching: isFetchingProfileCompletion } =
    useCurrentFreelancerProfileCompletion();

  const freelancerProfile = useAppSelector(
    (state) => state.freelancer.freelancerProfile,
  );
  const isProfileCompleted =
    Number(profileCompletion?.completion_percent) >= 100;

  const commonSkip =
    !freelancerProfile ||
    !profileCompletion ||
    isFetchingProfileCompletion ||
    isProfileCompleted;

  const {
    data: freelancerWorkExperience,
    isLoading: isLoadingFreelancerWorkExperience,
  } = useGetFreelancerWorkExperienceQuery(undefined, {
    skip: commonSkip,
  });

  const { data: freelancerSkills, isLoading: isLoadingFreelancerSkills } =
    useGetFreelancerSkillsQuery(
      { id: freelancerProfile?.id as number },
      { skip: commonSkip },
    );

  const freelancerRoleCount = freelancerProfile?.roles?.length || 0;
  const freelancerSkillsCount = freelancerSkills?.length || 0;
  const freelancerExperienceCount = freelancerWorkExperience?.length || 0;
  const freelancerAboutCount =
    [
      freelancerProfile?.user.first_name,
      freelancerProfile?.user.address,
    ].filter(Boolean).length || 0;

  const isLoading =
    isLoadingFreelancerWorkExperience ||
    isFetchingProfileCompletion ||
    isLoadingFreelancerSkills;

  return {
    isLoading,
    isProfileCompleted,
    missingSkillsCount: REQUIREMENTS.SKILLS_COUNT - freelancerSkillsCount,
    missingExperienceCount:
      REQUIREMENTS.EXPERIENCE_COUNT - freelancerExperienceCount,
    missingAboutCount: REQUIREMENTS.ABOUT_COUNT - freelancerAboutCount,
    missingRoleCount: REQUIREMENTS.ROLE_COUNT - freelancerRoleCount,
  };
};
