import { useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import {
  FRONTEND_STORAGE_KEYS,
  useLazyGetStorageValueQuery,
  useSetStorageValueMutation,
} from "@js/apps/common/frontend-storage";
import { useUser } from "@js/apps/common/hooks";
import { useQueryParams } from "@js/hooks";

import { openGGHIntroductionModal } from "../../components/ggh-introduction-modal/ggh-introduction-modal";

export const useGGHIntroductionModal = () => {
  const user = useUser();
  const { pathname } = useLocation();
  const { action } = useQueryParams();
  const [modalWasOpen, setModalWasOpen] = useState<boolean>(false);
  const [getStorageValue] = useLazyGetStorageValueQuery();
  const [setStorageValue] = useSetStorageValueMutation();

  const handleModalClose = useCallback(() => {
    setStorageValue({
      key: FRONTEND_STORAGE_KEYS.GIVE_GET_INTRODUCTION_MODAL_VISITED,
      value: true,
    });
  }, [setStorageValue]);

  useEffect(() => {
    if (modalWasOpen || !user?.is_verified) {
      return;
    }

    if (action === ENUMS.ConfirmAction.DELETE_ACCOUNT) {
      return;
    }

    const isLogoutPage = /^\/auth\/logout\/?$/.test(pathname);
    if (isLogoutPage) {
      return;
    }

    let isCancelled = false;
    const openIntroductionModal = async () => {
      const introductionModalVisitedResponse = await getStorageValue(
        { key: FRONTEND_STORAGE_KEYS.GIVE_GET_INTRODUCTION_MODAL_VISITED },
        true,
      );
      if (isCancelled || introductionModalVisitedResponse.isError) {
        return;
      }

      const hasSeenModal = !!introductionModalVisitedResponse.data;
      if (hasSeenModal) {
        setModalWasOpen(true);
        return;
      }

      const isFeedPage = pathname.startsWith("/feed");
      if (isFeedPage) {
        openGGHIntroductionModal({
          onClose: handleModalClose,
        });
        setModalWasOpen(true);
        return;
      }

      const isFirstLoginToAppResponse = await getStorageValue(
        { key: FRONTEND_STORAGE_KEYS.IS_FIRST_LOGIN_TO_APP },
        true,
      );
      if (isCancelled) {
        return;
      }

      const isFirstLoginToApp = !!isFirstLoginToAppResponse.data;
      const shouldOpenModal = !isFirstLoginToApp;

      if (!shouldOpenModal) {
        return;
      }

      openGGHIntroductionModal({
        onClose: handleModalClose,
      });
      setModalWasOpen(true);
    };

    openIntroductionModal();

    return () => {
      isCancelled = true;
    };
  }, [getStorageValue, pathname, modalWasOpen, handleModalClose, user, action]);
};
