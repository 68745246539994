import { useMemo } from "react";
import InfiniteScroll from "react-infinite-scroll-component";

import { Box } from "@hexocean/braintrust-ui-components";
import { BookmarksLoader } from "@js/apps/bookmarks/components/bookmarks-loader";
import { WithJobActionsContext } from "@js/apps/jobs/components/job-actions/with-job-actions";
import { JobsListing } from "@js/apps/jobs/components/listing";
import { JobLocationContext } from "@js/apps/jobs/context";

import { BookmarksEmptyState } from "../components/empty-state";
import { useSavedJobs } from "../hooks/use-saved-jobs";

export const JobsBookmarksPage = () => {
  const {
    handleFetchingMore,
    hasMore,
    jobsToDisplay,
    refetchJobs,
    loadingJobsToDisplay,
  } = useSavedJobs();

  const withJobActionContextProviderValue = useMemo(
    () => ({
      afterAction: refetchJobs,
    }),
    [refetchJobs],
  );

  if (loadingJobsToDisplay) {
    return <BookmarksLoader />;
  }

  if (!jobsToDisplay?.length) {
    return (
      <BookmarksEmptyState
        ctaLabel="Search jobs"
        ctaLink="/jobs"
        title="Your saved jobs will appear here."
        description="Select the bookmark icon on a job you’re interested in to save it for later."
        imgSrc={`${SETTINGS.STATIC_URL}bookmarks/empty-state-img-saved-jobs.svg`}
      />
    );
  }

  return (
    <Box className="bookmarks-listing" sx={{ px: { xs: 2, sm: 0 } }}>
      <JobLocationContext.Provider value={JobLocationContext.Values.saved_jobs}>
        <WithJobActionsContext.Provider
          value={withJobActionContextProviderValue}
        >
          <div className="bookmarks-listing-content">
            <InfiniteScroll
              dataLength={jobsToDisplay?.length} //This is important field to render the next data
              next={() => handleFetchingMore()}
              hasMore={hasMore}
              scrollThreshold={0.6}
              loader={<BookmarksLoader />}
            >
              <JobsListing jobs={jobsToDisplay} />
            </InfiniteScroll>
          </div>
        </WithJobActionsContext.Provider>
      </JobLocationContext.Provider>
    </Box>
  );
};
