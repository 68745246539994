import _ from "underscore";
import URI from "urijs";

import { parsePrimitive } from "@js/utils";

import type { RoleParams } from "../common/components/filters/forms/role-form";
import type { SkillsParams } from "../common/components/filters/forms/skills-form";
import { ROLE_FILTER_SCHEMA } from "../common/components/filters/schemas/role-filter-schema";
import { SKILLS_FILTER_SCHEMA } from "../common/components/filters/schemas/skills-filter-schema";

export const clearObjectEmptyKeysAndEmptyArrays = <
  T extends Record<string, any>,
>(
  val: T,
): Partial<T> => {
  const values = Object.keys(val).reduce((prev, current) => {
    const newValue = Array.isArray(val[current])
      ? val[current].length > 0
        ? val[current]
        : undefined
      : val[current];

    if (newValue || newValue === false) {
      prev[current] = newValue;
    }

    return prev;
  }, {});
  return values;
};

export const mapAllArraysInObjectToConcatenatedString = (
  val: Record<string, any>,
  separator = ",",
) => {
  const values = Object.keys(val).reduce((prev, current) => {
    const newValue = Array.isArray(val[current])
      ? val[current].join(separator)
      : val[current];

    prev[current] = newValue;

    return prev;
  }, {});
  return values;
};

// make sure skills and role are normalized to always be number[]
const roleQueryParamKey: keyof RoleParams = "role";
const skillsQueryParamKey: keyof SkillsParams = "skills";

export const normalizeRolesAndSkillsFormat = (
  searchParamValues: Record<string, unknown>,
) => {
  const hasRoleParam = Boolean(searchParamValues[roleQueryParamKey]);
  const hasSkillsParam = Boolean(searchParamValues[skillsQueryParamKey]);
  const noRoleAndSkillsInParams = !hasRoleParam && !hasSkillsParam;

  if (noRoleAndSkillsInParams) return;

  if (hasRoleParam) {
    const roleParam = searchParamValues[roleQueryParamKey];
    const normalizedValue = ROLE_FILTER_SCHEMA.parse(roleParam);
    searchParamValues[roleQueryParamKey] = normalizedValue;
  }

  if (hasSkillsParam) {
    const skillsParam = searchParamValues[skillsQueryParamKey];
    const normalizedValue = SKILLS_FILTER_SCHEMA.parse(skillsParam);
    searchParamValues[skillsQueryParamKey] = normalizedValue;
  }
};

export const valuesToSearchParams = (values) => {
  const params = new URLSearchParams();

  Object.keys(values).forEach((key) => {
    const value = values[key];
    if (Array.isArray(value)) {
      if (!value.filter(isNotEmpty).length) return;

      params.append(key, value.join(","));
    } else if (isNotEmpty(value)) {
      params.append(key, value);
    }
  });

  return params;
};

const isNotEmpty = (value) => {
  return value !== null && value !== undefined && value !== "";
};

export const getSearchParamsAsValues = (searchParams: URLSearchParams) => {
  const query = URI("?" + searchParams.toString()).search(true);
  return query;
};

export const parseSearchParamsToValues = <T>(
  searchParams: URLSearchParams,
  defaultFormValues: T,
): T => {
  const query = getSearchParamsAsValues(searchParams);

  const parsed = Object.keys(query).reduce((prev, key) => {
    if (Array.isArray(defaultFormValues[key])) {
      const queryValueAsArray = Array.isArray(query[key])
        ? query[key]
        : query[key].split(",");
      prev[key] = queryValueAsArray
        .map(parsePrimitive)
        .filter((val) => val !== null && val !== undefined && val !== "");
      return prev;
    }

    prev[key] = parsePrimitive(query[key]);
    return prev;
  }, {});

  return {
    ...defaultFormValues,
    ...parsed,
  };
};

export const pickValues = (
  values: Record<string, any>,
  keysToPick: string[],
) => {
  const filteredValues = _.pick(values, keysToPick);

  return filteredValues;
};
