import { useLayoutEffect, useState } from "react";
import ReactDOM from "react-dom";

export const SubheaderPortal = ({ children }) => {
  const [container, setContainer] = useState<Element | null>();

  useLayoutEffect(() => {
    const element = document.getElementById("subheader-portal");
    if (element) {
      setContainer(document.getElementById("subheader-portal"));
    }
  }, []);

  if (!container) return null;

  return ReactDOM.createPortal(children, container);
};
