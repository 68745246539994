import type { ComponentProps } from "react";

import { Button } from "@hexocean/braintrust-ui-components";
import { AddIcon } from "@hexocean/braintrust-ui-components/Icons";
import { useMergeLinkComponent } from "@js/apps/employer/hooks/merge-link-component";

export const OpenMergeComponentButton = ({
  sx,
  onIntegrationSuccess,
}: {
  sx?: ComponentProps<typeof Button>["sx"];
  onIntegrationSuccess?: () => void;
}) => {
  const { handleClickButton, isMergeLinkComponentInit, disabledButton } =
    useMergeLinkComponent({ onSuccess: onIntegrationSuccess });

  return (
    <Button
      variant="primary"
      disabled={disabledButton}
      onClick={handleClickButton}
      sx={sx}
      style={{ padding: "11px 20px" }}
    >
      {isMergeLinkComponentInit ? (
        "Preparing ATS integration window..."
      ) : (
        <>
          <AddIcon /> Add ATS integration
        </>
      )}
    </Button>
  );
};
