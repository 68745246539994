import { Box, Typography } from "@hexocean/braintrust-ui-components";

import styles from "../style.module.scss";

export const ATSJobsEmptyState = () => {
  return (
    <Box className={styles.jobListEmptyState}>
      <img
        src={`${SETTINGS.STATIC_URL}jobs/ats-jobs-empty-state.svg`}
        alt="ATS Jobs empty state"
        width="72px"
        height="72px"
        aria-label="no-ats-jobs-found"
      />
      <Typography component="p" mt={4} fontWeight={500}>
        {" "}
        We've found 0 jobs in your ATS platform.
      </Typography>
    </Box>
  );
};
