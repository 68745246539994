import { assertUnreachable } from "@js/utils";

import { RANGE_VALUE } from "../../constants";

import { RATE_FIELDS_KEYS } from "./constants";

export const rateValidator = (_value, allValues, _props, name) => {
  if (name === RATE_FIELDS_KEYS.job_type) {
    return undefined;
  }

  if (name === RATE_FIELDS_KEYS.payment_type) {
    return undefined;
  }

  const paymentType = allValues[RATE_FIELDS_KEYS.payment_type] as EnumType<
    typeof ENUMS.JobPaymentType
  >;

  if (!paymentType) {
    return "Please add a pay rate";
  }

  switch (paymentType) {
    case ENUMS.JobPaymentType.ANNUAL: {
      if (
        !allValues[RATE_FIELDS_KEYS.min_annual_rate] &&
        !allValues[RATE_FIELDS_KEYS.max_annual_rate]
      ) {
        return "Please add a pay rate";
      }

      return undefined;
    }
    case ENUMS.JobPaymentType.HOURLY: {
      if (!allValues[RATE_FIELDS_KEYS.hourly_rate]) {
        return "Please add a pay rate";
      }

      if (allValues[RATE_FIELDS_KEYS.hourly_rate] === RANGE_VALUE) {
        if (
          !allValues[RATE_FIELDS_KEYS.custom_min_rate] &&
          !allValues[RATE_FIELDS_KEYS.custom_max_rate]
        ) {
          return "Please add a pay rate";
        }
      }

      return undefined;
    }
    case ENUMS.JobPaymentType.FIXED_PRICE: {
      if (!allValues[RATE_FIELDS_KEYS.fixed_rate]) {
        return "Please add a pay rate";
      }

      return undefined;
    }
    default: {
      assertUnreachable(paymentType);
      return undefined;
    }
  }
};
