import { useEffect, useRef, useState } from "react";

import type { EmployerInvoice } from "@js/types/invoices";
import { handleDownloadFile } from "@js/utils";

import { isPaymentStillBlocked } from "../../utils";

type UseSingleInvoiceActionsProps = {
  invoice: EmployerInvoice;
  handleSingleInvoicePayment: (
    invoice: EmployerInvoice,
    startPaymentFlow: () => void,
  ) => void;
  startPaymentFlow: () => void;
};

export const useSingleInvoiceActions = ({
  invoice,
  handleSingleInvoicePayment,
  startPaymentFlow,
}: UseSingleInvoiceActionsProps) => {
  const [isPaymentTemporaryBlocked, setIsPaymentTemporaryBlocked] = useState(
    isPaymentStillBlocked(invoice.payment_blocked_until),
  );
  const isPaymentTemporaryBlockedIntervalRef =
    useRef<ReturnType<typeof setInterval>>();

  useEffect(() => {
    setIsPaymentTemporaryBlocked(
      isPaymentStillBlocked(invoice.payment_blocked_until),
    );
  }, [invoice]);

  useEffect(() => {
    const interval = isPaymentTemporaryBlockedIntervalRef.current;
    clearInterval(interval);

    if (isPaymentTemporaryBlocked) {
      isPaymentTemporaryBlockedIntervalRef.current = setInterval(() => {
        setIsPaymentTemporaryBlocked(
          isPaymentStillBlocked(invoice.payment_blocked_until),
        );
      }, 5000);

      return () => clearInterval(interval);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPaymentTemporaryBlocked]);

  const handleInvoicePayment = () => {
    return handleSingleInvoicePayment(invoice, startPaymentFlow);
  };

  const handleDownloadInvoiceAsPDF = () => {
    return handleDownloadFile({ endpoint: invoice.pdf_file.file, blank: true });
  };

  const isPayButtonVisible =
    (invoice.can_be_paid || isPaymentTemporaryBlocked) &&
    invoice.status !== ENUMS.InvoiceStatus.CANCELLED;

  return {
    isPayButtonVisible,
    isPaymentTemporaryBlocked,
    handleInvoicePayment,
    handleDownloadInvoiceAsPDF,
  };
};
