import { useRef, useState } from "react";
import type { LexicalEditor, TextNode } from "lexical";
import { $getRoot } from "lexical";

import type { SelectionProps } from "./use-selection";
import { useSelection } from "./use-selection";

type useSelectedHashtagProps = {
  editor: LexicalEditor;
  editorElement?: Element | null;
};

export const useSelectedHashtag = ({
  editorElement,
  editor,
}: useSelectedHashtagProps) => {
  const cursorPositionRef = useRef(-1);
  const anchorElementRef = useRef<HTMLElement>(document.body);

  const [isHashtagActive, setIsHashtagActive] = useState(false);
  const [hashtag, setHashtag] = useState("");

  const selectHashtag = (selectedHashtag: string) => {
    setHashtag("#" + selectedHashtag);
    setIsHashtagActive(true);
  };

  const unselectHashtag = () => {
    setHashtag("");
    setIsHashtagActive(false);
  };

  const handleSelection = ({
    selectedNode,
    cursorPosition,
    anchorElement,
    keyPressed,
  }: SelectionProps) => {
    const text = selectedNode?.textContent ?? "";
    cursorPositionRef.current = cursorPosition;
    anchorElementRef.current = anchorElement;

    const isSelectedNodeInsideA = isInsideLinkNode(selectedNode);

    const nearestHashtagOnLeftPosition = text.lastIndexOf(
      "#",
      cursorPosition - 1,
    );

    const isHashDetected =
      !isSelectedNodeInsideA && nearestHashtagOnLeftPosition !== -1;

    if (!isHashDetected || cursorPosition === 0) {
      unselectHashtag();

      if (keyPressed === "ARROW_LEFT") {
        handleArrowLeft(text);
      }

      if (keyPressed === "ARROW_RIGHT") {
        handleArrowRight(text);
      }

      return;
    }

    const stringBetweenHashAndCursor = text.substring(
      nearestHashtagOnLeftPosition,
      cursorPosition,
    );

    if (stringBetweenHashAndCursor.match(/\s/)) {
      unselectHashtag();
      return;
    }

    const hashtagName = extractHashtagName(
      text,
      cursorPosition,
      nearestHashtagOnLeftPosition,
    );

    if (keyPressed === "ARROW_LEFT") {
      handleArrowLeft(text);
    }

    if (keyPressed === "ARROW_RIGHT") {
      handleArrowRight(text);
    }

    selectHashtag(hashtagName);
  };

  const extractHashtagName = (
    text: string,
    cursorPosition: number,
    nearestHashtagOnLeftPosition: number,
  ): string => {
    const stringAfterCursor = text.substring(cursorPosition);
    const nextWhiteSpacePosition = stringAfterCursor.search(/\s/);

    const endPosition =
      nextWhiteSpacePosition !== -1
        ? cursorPosition + nextWhiteSpacePosition
        : text.length;
    const startPosition = nearestHashtagOnLeftPosition + 1;

    return text.slice(startPosition, endPosition);
  };

  const getTextNodes = (text: string) => {
    const root = $getRoot();
    const allNodes = root.getAllTextNodes();
    const currentNodeIndex = allNodes.findIndex(
      (node) => node.getTextContent() === text,
    );

    const currentNode = allNodes[currentNodeIndex];
    const nextNode = allNodes[currentNodeIndex + 1];
    const secondNextNode = allNodes[currentNodeIndex + 2];

    return { currentNode, nextNode, secondNextNode };
  };

  const handleArrowLeft = (text: string) => {
    editor.update(() => {
      const { currentNode } = getTextNodes(text);

      if (shouldSelectCurrentNode(currentNode)) {
        currentNode.select(0);
      }
    });
  };

  const isHashtag = (str: string): boolean => /^#[\w]+[^\s]$/.test(str);

  const handleArrowRight = (text: string) => {
    if (text === " ") {
      return;
    }

    editor.update(() => {
      const { currentNode, nextNode, secondNextNode } = getTextNodes(text);

      if (shouldSelectSecondNextNode(nextNode, secondNextNode)) {
        secondNextNode.select(0);
        return;
      }

      if (shouldSelectNextNode(currentNode, nextNode)) {
        nextNode.select(0);
        return;
      }

      if (shouldSelectCurrentNode(currentNode)) {
        currentNode.select(currentNode.getTextContent().length);
        return;
      }

      if (selectionShouldNotBeChanged(currentNode)) {
        return;
      }
    });
  };

  // It checks if the currentNode is not a hashtag and if the cursor position is within the text content of the currentNode
  const shouldSelectNextNode = (
    currentNode?: TextNode,
    nextNode?: TextNode,
  ) => {
    if (!currentNode || !nextNode) return false;

    if (
      !isHashtag(currentNode.getTextContent()) &&
      cursorPositionRef.current < currentNode.getTextContent().length
    ) {
      return false;
    }

    return isHashtag(nextNode.getTextContent());
  };

  // It determines whether to select the second next node based on conditions:
  // 1. The first next node is a space
  // 2. The second next node is a hashtag
  const shouldSelectSecondNextNode = (
    firstNextNode?: TextNode,
    secondNextNode?: TextNode,
  ) => {
    if (!firstNextNode || !secondNextNode) return false;

    return (
      firstNextNode.getTextContent() === " " &&
      isHashtag(secondNextNode.getTextContent())
    );
  };

  const isInsideLinkNode = (node) => {
    if (node.getParent) {
      return node.getParent()?.getType() === "autolink";
    } else {
      return node?.parentElement?.parentElement?.tagName === "A";
    }
  };

  // It checks if the currentNode is a hashtag
  const shouldSelectCurrentNode = (currentNode?: TextNode) => {
    if (!currentNode) return false;

    const isInsideAutoLinkNode = isInsideLinkNode(currentNode);

    const text = currentNode.getTextContent();
    const isTextHashtag = isHashtag(text);

    return !isInsideAutoLinkNode && isTextHashtag;
  };

  //It checks if the currentNode is not a white space (regular text node with content)
  const selectionShouldNotBeChanged = (currentNode?: TextNode) => {
    if (!currentNode) return false;

    return currentNode.getTextContent() !== " ";
  };

  useSelection({
    editorElement,
    onSelection: handleSelection,
    onSelectionOutside: unselectHashtag,
  });

  const getCursorPosition = () => cursorPositionRef.current;
  const getAnchorElement = () => anchorElementRef.current;

  return {
    isHashtagActive,
    hashtag,
    getAnchorElement,
    getCursorPosition,
    unselectHashtag,
  };
};
