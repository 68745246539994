import React from "react";

import { Box, Typography } from "@hexocean/braintrust-ui-components";

import { OpenMergeComponentButton } from "../merge-link-component";

export const AtsIntegrationEmptyState = () => {
  return (
    <Box>
      <Typography mt={1.5} mb={4} component="p" size="large">
        You have not configured an ATS integration. Connect your ATS platform
        and sync jobs directly to Braintrust.
      </Typography>
      <OpenMergeComponentButton />
    </Box>
  );
};
