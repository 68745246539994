import { useMemo } from "react";

import {
  Box,
  Button,
  Divider,
  Loader,
} from "@hexocean/braintrust-ui-components";
import { AddTeamMemberIcon } from "@hexocean/braintrust-ui-components/Icons";
import { JobSubscribersList } from "@js/apps/jobs/components/job-subscribers";
import { getNewJobSubscriberInvitations } from "@js/apps/jobs/components/job-subscribers/job-subscribers-list/helpers";
import { SubscribeTeamMembersAutocomplete } from "@js/apps/jobs/components/subscribe-team-members-autocomplete";
import type { EmployerTeamMemberType } from "@js/types/employer";
import type {
  JobSubscriber,
  JobSubscriberData,
  JobSubscriberInvitation,
  JobSubscriberInvitationData,
} from "@js/types/jobs";

import { useEditedJobSubscribers } from "../../hooks/use-edited-job-subscribers";
import {
  CommonTeamMembersModalContentContainer,
  CommonTeamMembersModalCTAContainer,
  CommonTeamMembersModalHeaderContainer,
  CommonTeamMembersModalHeaderText,
  CommonTeamMembersModalListContainer,
  CommonTeamMembersModalText,
} from "../common-team-members-modal";

type OnSaveSubscribersArg = {
  editedJobSubscribers?: JobSubscriberData[];
  editedJobSubscriberInvitations?: JobSubscriberInvitationData[];
};

export type SubscribeTeamMembersToJobModalContentProps = {
  jobOwnerId: number;
  teamMembers: EmployerTeamMemberType[] | undefined;
  jobSubscribers: JobSubscriber[] | undefined;
  jobSubscriberInvitations: JobSubscriberInvitation[] | undefined;
  isSaving: boolean;
  isLoading: boolean;
  onSaveSubscribers: (arg: OnSaveSubscribersArg) => void;
};

export const SubscribeTeamMembersToJobModalContent = ({
  jobOwnerId,
  teamMembers,
  jobSubscribers,
  jobSubscriberInvitations,
  isSaving,
  isLoading,
  onSaveSubscribers,
}: SubscribeTeamMembersToJobModalContentProps) => {
  const {
    editedJobSubscribers,
    editedJobSubscriberInvitations,
    hasChangedSubscribers,
    hasChangedSubscriberInvitations,
    toggleJobSubscriber,
    toggleJobSubscriberInvitation,
  } = useEditedJobSubscribers({ jobSubscribers, jobSubscriberInvitations });

  const hasChangedValues =
    hasChangedSubscriberInvitations || hasChangedSubscribers;
  const disableSubmit = isSaving || !hasChangedValues;

  const isAddingNewTeamMember = useMemo(() => {
    if (!jobSubscriberInvitations) {
      return [];
    }

    const newJobSubscriberInvitations = getNewJobSubscriberInvitations({
      jobSubscriberInvitations,
      editedJobSubscriberInvitations,
    });

    return !!newJobSubscriberInvitations.length;
  }, [jobSubscriberInvitations, editedJobSubscriberInvitations]);

  const handleSubmit = () => {
    if (disableSubmit) {
      return;
    }

    const onSaveSubscribersArg: OnSaveSubscribersArg = {};

    if (hasChangedSubscriberInvitations) {
      onSaveSubscribersArg.editedJobSubscriberInvitations =
        editedJobSubscriberInvitations;
    }

    if (hasChangedSubscribers) {
      onSaveSubscribersArg.editedJobSubscribers = editedJobSubscribers;
    }

    onSaveSubscribers(onSaveSubscribersArg);
  };

  return (
    <CommonTeamMembersModalContentContainer
      sx={{ maxHeight: "735px" }}
      onSubmit={handleSubmit}
    >
      <CommonTeamMembersModalHeaderContainer>
        <AddTeamMemberIcon sx={{ fontSize: "40px" }} />
        <CommonTeamMembersModalHeaderText>
          Subscribe team members to this job
        </CommonTeamMembersModalHeaderText>
      </CommonTeamMembersModalHeaderContainer>

      <CommonTeamMembersModalText>
        Invite team members to stay in the loop with updates and notifications
        for this job. If they're not on Braintrust yet, you can invite them to
        join your organization by entering their email addresses
      </CommonTeamMembersModalText>

      <SubscribeTeamMembersAutocomplete
        teamMembers={teamMembers ?? []}
        toggleInvitation={(invitationEmail) =>
          toggleJobSubscriberInvitation({ email: invitationEmail })
        }
        toggleTeamMemberId={(teamMemberId) =>
          toggleJobSubscriber({ team_member_id: teamMemberId })
        }
        jobSubscriberInvitationsValue={editedJobSubscriberInvitations}
        jobSubscribersValue={editedJobSubscribers}
      />

      <CommonTeamMembersModalListContainer
        isLoading={isLoading || !jobSubscribers || !teamMembers}
      >
        {!!jobSubscribers && !!teamMembers && (
          <JobSubscribersList
            jobOwnerId={jobOwnerId}
            jobSubscribers={jobSubscribers}
            editedJobSubscribers={editedJobSubscribers}
            jobSubscriberInvitations={editedJobSubscriberInvitations}
            teamMembers={teamMembers}
            toggleJobSubscriber={toggleJobSubscriber}
            toggleJobSubscriberInvitation={toggleJobSubscriberInvitation}
          />
        )}
      </CommonTeamMembersModalListContainer>

      <Box>
        <Divider sx={{ borderColor: "var(--grey-4)" }} />
      </Box>

      <CommonTeamMembersModalCTAContainer>
        <Button
          variant="positive"
          shape="squared"
          type="submit"
          disabled={disableSubmit}
          endIcon={isSaving ? <Loader size={20} color="inherit" /> : undefined}
        >
          {isAddingNewTeamMember ? "Add to job" : "Save"}
        </Button>
      </CommonTeamMembersModalCTAContainer>
    </CommonTeamMembersModalContentContainer>
  );
};
