import { ProfileCompleteConfirmationModalContent } from "@js/apps/freelancer/components/profile-complete-confirmation-modal";

import { useProfileCompletionSequenceContext } from "../context/profile-completion-sequence-context";

export const ProfileCompleted = () => {
  const { shouldRender } = useProfileCompletionSequenceContext();

  if (!shouldRender("Profile Completed")) {
    return null;
  }

  return <ProfileCompleteConfirmationModalContent />;
};
