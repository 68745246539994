import type { BoxProps } from "@hexocean/braintrust-ui-components";
import {
  Box,
  InputAdornment,
  Typography,
} from "@hexocean/braintrust-ui-components";
import { BraintrustIcon } from "@hexocean/braintrust-ui-components/Icons";

export const EndAdornment = (props: BoxProps) => {
  return (
    <Box display="flex" alignItems="center" gap={0.5} {...props}>
      in <BraintrustIcon style={{ fontSize: 16 }} /> BTRST
    </Box>
  );
};

export type EndAdornmentComponentProps = {
  focus: boolean;
  disabled?: boolean;
};

export const CustomBudgetRateEndAdornment = ({
  focus,
  disabled,
}: EndAdornmentComponentProps): JSX.Element => (
  <InputAdornment
    disableTypography
    position="end"
    sx={{ color: "inherit", cursor: "pointer" }}
  >
    <EndAdornment
      color={focus || disabled ? "var(--grey-2)" : "inherit"}
      fontWeight={500}
    />
  </InputAdornment>
);

export const CustomBudgetRateStartAdornment = ({
  isDisabled,
}: {
  isDisabled?: boolean;
}) => (
  <InputAdornment
    sx={{ marginRight: "-16px", color: "inherit" }}
    disableTypography
    position="start"
  >
    <Typography
      variant="paragraph"
      component="span"
      fontWeight={500}
      color={isDisabled ? "grey-2" : "inherit"}
    >
      $
    </Typography>
  </InputAdornment>
);
