import type { To } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import { CommonConfirmationModal, ModalConfirm } from "@js/components/modal";
import { Snackbar } from "@js/components/snackbar";
import type { FreelancerInvoice } from "@js/types/invoices";
import { getCurrentPathEncoded } from "@js/utils";

export type UseSingleInvoiceActionMenuProps = {
  cancelFreelancerInvoice: (id: number) => Promise<unknown>;
  invoice: FreelancerInvoice;
  prevPath?: string;
};

export const useSingleInvoiceActionMenu = ({
  cancelFreelancerInvoice,
  invoice,
  prevPath,
}: UseSingleInvoiceActionMenuProps) => {
  const navigate = useNavigate();

  const onConfirm = (invoiceId: number) => {
    cancelFreelancerInvoice(invoiceId)
      .then(() => {
        Snackbar.success("Invoice successfully cancelled.");
        CommonConfirmationModal.close();
      })
      .catch((error) => {
        Snackbar.error(error);
      });
  };

  const onCancel = () => {
    CommonConfirmationModal.open({
      children: (
        <ModalConfirm
          confirmText="Cancel Invoice"
          onConfirm={() => {
            onConfirm(invoice.id);
          }}
          onCancel={CommonConfirmationModal.close}
        >
          Are you sure you want to cancel invoice number {invoice.number}?
        </ModalConfirm>
      ),
    });
  };

  const onCopy = () => {
    const to: To = `/talent/invoices/add_new?copy=${
      invoice.id
    }${prevPath ? `&prevPath=${prevPath}` : ""}`;
    navigate(to);
  };

  const onDownload = () => {
    const link = document.createElement("a");
    link.href = invoice.pdf_file.file;
    link.setAttribute("download", invoice.pdf_file.name);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const onEdit = async () => {
    navigate(
      `/talent/invoices/edit/${invoice.id}/?prevPath=${getCurrentPathEncoded()}`,
      { replace: true },
    );
  };

  return { onCancel, onCopy, onDownload, onEdit };
};
