import { Box } from "@hexocean/braintrust-ui-components";

import { RoleModal } from "../../role-module/role-modal";
import { useProfileCompletionSequenceContext } from "../context/profile-completion-sequence-context";

import { ProfileCompletionSequenceCloseBtn } from "./close-btn";

export const Role = () => {
  const { shouldRender, setStepBeingEdited } =
    useProfileCompletionSequenceContext();

  if (!shouldRender("Role")) {
    return null;
  }

  return (
    <Box sx={{ padding: 4 }}>
      <ProfileCompletionSequenceCloseBtn />
      <RoleModal
        persistModalInstance
        saveButtonText="Save and continue"
        setStepBeingEdited={setStepBeingEdited}
      />
    </Box>
  );
};
