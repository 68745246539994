import type { WrappedFieldMetaProps } from "redux-form";

import { useFormErrors } from "@js/forms/hooks";

import { PostSelfPromotionWarningMessage } from "../../self-promotion-warning";
import { CREATE_POST_FORM_SELF_PROMOTION_FIELD_ID } from "../use-check-for-self-promotion";

export const useCreateOrEditFormWarnings = ({
  meta,
}: {
  meta: WrappedFieldMetaProps;
}): {
  warningMessage: string | JSX.Element;
} => {
  const { syncWarnings } = useFormErrors(meta);

  const getWarningMessage = () => {
    const warn = syncWarnings[CREATE_POST_FORM_SELF_PROMOTION_FIELD_ID];

    if (warn) return <PostSelfPromotionWarningMessage />;

    return "";
  };

  const warningMessage = getWarningMessage();

  return {
    warningMessage,
  };
};
