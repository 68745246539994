import type { TypedWrappedFieldProps } from "redux-form";

import {
  ComboBox,
  TextField as TextInput,
} from "@hexocean/braintrust-ui-components";

import { useProjectField } from "./use-project-field";

export const ProjectField = ({
  input,
  meta,
}: TypedWrappedFieldProps<number>) => {
  const { value, options } = useProjectField({ input });

  return (
    <ComboBox
      value={value}
      initialTaxonomiesLoading={false}
      getOptionLabel={(option) => option.title}
      disableClearable={value?.id === -1}
      renderInput={(params) => {
        return (
          <TextInput
            {...params}
            label="Project"
            error={!!meta.error}
            helperText={meta.error}
          />
        );
      }}
      renderOption={(props, option) => {
        return (
          <li {...props} key={option.id}>
            {option.title}
          </li>
        );
      }}
      isOptionEqualToValue={(option, valueArg) => {
        return option.id === valueArg?.id;
      }}
      options={options}
      onChange={(_ev, valueArg) => {
        const valueId = valueArg?.id;
        const newInputValue = !valueId || valueId === -1 ? null : valueId;
        input.onChange(newInputValue);
      }}
    />
  );
};
