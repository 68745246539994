import React from "react";
import type { TypedWrappedFieldProps } from "redux-form";
import { touch } from "redux-form";

import type { CheckboxProps } from "@hexocean/braintrust-ui-components";
import { Checkbox } from "@hexocean/braintrust-ui-components";

export type CheckboxFieldProps = CheckboxProps &
  TypedWrappedFieldProps<boolean | unknown> & {
    customValue?: unknown;
    customUncheckedValue?: unknown;
    hideError?: boolean;
  };

export const ConnectedCheckboxFieldFactory =
  (Component: React.FC<React.PropsWithChildren<CheckboxProps>>) =>
  ({
    input: { name, value, onChange },
    meta: { dispatch, form, error, touched },
    customValue,
    customUncheckedValue = null,
    hideError = false,
    ...rest
  }: CheckboxFieldProps): JSX.Element => {
    const isChecked = customValue ? value === customValue : !!value;

    const handleOnChange = (_, checked: boolean) => {
      dispatch(touch(form, name));
      onChange(
        customValue ? (checked ? customValue : customUncheckedValue) : checked,
      );
    };

    return (
      <Component
        onChange={handleOnChange}
        error={!hideError && touched && error ? error : ""}
        checked={isChecked}
        {...rest}
      />
    );
  };

export const CheckboxField = ConnectedCheckboxFieldFactory(Checkbox);
