import amplitude from "amplitude-js";
import Cookies from "js-cookie";

import { AMPLITUDE_API_KEY, TRACKING_PROXY_API } from "../../constants";
import { pruneUnsentTrackingEvents } from "../../utils";

export const initializeAmplitude = (
  userId: number | undefined,
  config = { token: false },
) => {
  if (!SETTINGS.TRACKING_ENABLED) return;

  // We are using amplitude-js SDK version instead of newer v1 or v2 SDKs, because new SDKs doesn't support passing custom headers.
  // We need to pass CSRF token in order to make requests to our tracking proxy.
  // In case we need it in the future, backend could exempt tracking endpoint from csrf protection.

  // ---

  // We aren't using an actual API key here, because we aren't using Amplitude app to store and manage our events.
  // doesn't return instance, so we need to getInstance() later
  const formattedUserId =
    typeof userId !== "undefined" ? String(userId) : undefined;

  amplitude.getInstance().init(AMPLITUDE_API_KEY, formattedUserId, {
    includeUtm: true,
    includeReferrer: true,
    includeGclid: true,
    apiEndpoint: TRACKING_PROXY_API,
    forceHttps: SETTINGS.AMPLITUDE_FORCE_HTTPS,
    headers: config.token ? { "X-CSRFToken": Cookies.get("csrftoken") } : {},
    batchEvents: false,
    saveEvents: false,
  });

  // make sure non anonymous events from previous initialization are not sent ...
  // ... when the instance is re-initialized without userId
  if (!userId) {
    pruneUnsentTrackingEvents();
  }
};
