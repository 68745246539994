import {
  Box,
  Button,
  Loader,
  Stack,
  Typography,
} from "@hexocean/braintrust-ui-components";

export type InterviewModalContentProps = {
  isLoading: boolean;
  interviewUrl: string | undefined;
  onClose: () => void;
};

export const InterviewModalContent = ({
  isLoading,
  interviewUrl,
  onClose,
}: InterviewModalContentProps) => {
  const showEmptyState = !isLoading && !interviewUrl;

  return (
    <Stack
      sx={{
        gap: 2,
        height: "100%",
        py: 2,
        boxSizing: "border-box",
      }}
    >
      <Box sx={{ flex: 1 }}>
        {isLoading && <Loader centered />}
        {showEmptyState && <InterviewEmptyState />}
        {!isLoading && !!interviewUrl && (
          <Box
            component="iframe"
            sx={{ width: "100%", height: "100%", border: "initial" }}
            data-testid="interview-iframe"
            // styling: https://help.hireflix.com/article/c6l3n1qgrw-integrating-hireflix-in-your-ats
            src={`${interviewUrl}?simple=true&backgroundColor=ffffff&primaryColor=141417`}
            allow="camera;microphone"
          />
        )}
      </Box>
      <Button
        sx={{ alignSelf: "end", mr: 2 }}
        variant="black-outlined"
        onClick={onClose}
      >
        Close
      </Button>
    </Stack>
  );
};

const InterviewEmptyState = () => {
  return (
    <Box sx={{ display: "grid", placeItems: "center", height: "100%", mt: 2 }}>
      <Typography component="p" variant="paragraph" size="large">
        Interview is not available
      </Typography>
    </Box>
  );
};
