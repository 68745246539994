import { useEffect, useState } from "react";

import type { WalletActivityFiltersParams } from "../../components/activities-filter";
import { useWalletActivityFilters } from "../../components/activities-filter";
import type { GetWalletActivityParamsType } from "../../types";

type UseWalletActivityQueryArgParams = { limit: number };

export const useWalletActivityQueryArg = ({
  limit,
}: UseWalletActivityQueryArgParams) => {
  const { filters } = useWalletActivityFilters();
  const { category, pending } = filters;
  const [queryArg, setQueryArg] = useState(() =>
    getInitialQueryArg({ category, pending }, limit),
  );

  useEffect(() => {
    setQueryArg((prev) => {
      const newQueryArg = getQueryArg({
        category,
        pending,
      });
      const hasCategoryChanged = prev.category !== newQueryArg.category;
      const hasPendingChanged = prev.pending !== newQueryArg.pending;
      const hasArgChanged = hasCategoryChanged || hasPendingChanged;

      return {
        ...newQueryArg,
        limit: hasArgChanged ? limit : prev.limit,
      };
    });
  }, [category, pending, limit]);

  const showAllResults = (total: number) => {
    setQueryArg((prev) => {
      return { ...prev, limit: total };
    });
  };

  return { queryArg, showAllResults };
};

const getQueryArg = ({
  category,
  pending,
}: Partial<WalletActivityFiltersParams>): Omit<
  GetWalletActivityParamsType,
  "limit"
> => {
  return {
    category: category,
    pending: pending ?? undefined,
  };
};

const getInitialQueryArg = (
  filters: Partial<WalletActivityFiltersParams>,
  limit: number,
): GetWalletActivityParamsType => {
  const queryArg = getQueryArg(filters);
  return {
    ...queryArg,
    limit,
  };
};
