import { useCallback } from "react";
import { skipToken } from "@reduxjs/toolkit/query";

import {
  useGetJobQuery,
  useLazyGetTalentInviteSuggestionsQuery,
} from "@js/apps/jobs/api";
import { useIdParam } from "@js/hooks/use-id-param";

import { usePollingSmartMatchingStatus } from "../polling-smart-matching-status";

export const useGetTalentInviteSuggestionsWithSmartMatching = () => {
  const id = useIdParam();
  const { data: job, isLoading: isJobLoading } = useGetJobQuery(
    id ? { id } : skipToken,
  );
  const totalBidsCount = job?.bids_count.total_bids_count || 0;
  const [
    getTalentInviteSuggestions,
    {
      data: talentInviteSuggestions,
      isLoading: isLoadingTalentInviteSuggestions,
    },
  ] = useLazyGetTalentInviteSuggestionsQuery();

  const onSmartMatchingCompleted = useCallback(
    async (jobId: number) => {
      await getTalentInviteSuggestions(jobId).unwrap();
    },
    [getTalentInviteSuggestions],
  );

  const { isSmartMatchingInProgress, analyzingProfilesCount } =
    usePollingSmartMatchingStatus({ onSmartMatchingCompleted, jobId: id });

  return {
    talentInviteSuggestions: talentInviteSuggestions?.results || [],
    isSmartMatchingInProgress:
      isSmartMatchingInProgress ||
      isJobLoading ||
      isLoadingTalentInviteSuggestions,
    totalBidsCount,
    job,
    analyzingProfilesCount,
    jobId: id,
  };
};
