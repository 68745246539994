type failureCallbackType = () => string;

export const SessionStorage = {
  setItem: (key: string, value: string): void => {
    try {
      sessionStorage.setItem(key, value);
    } catch (error) {
      // ignore
    }
  },
  getItem: (
    key: string,
    failureCallback?: failureCallbackType,
  ): string | null => {
    try {
      return sessionStorage.getItem(key);
    } catch (error) {
      // ignore
    }

    if (failureCallback) {
      return failureCallback();
    }

    return null;
  },
  removeItem: (key: string): void => {
    try {
      sessionStorage.removeItem(key);
    } catch (error) {
      // ignore
    }
  },
  keys: {
    TALENT_RESUME_UPLOADED: "TALENT_RESUME_UPLOADED",
    NAVIGATE_WITH_SNACKBAR_DATA: "NAVIGATE_WITH_SNACKBAR_DATA",
    FREELANCER_TIMEZONE_UPDATED: "FREELANCER_TIMEZONE_UPDATED",
  } as const,
};
