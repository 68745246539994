import { useEffect } from "react";
import type { WrappedFieldsProps } from "redux-form";

import { Typography } from "@hexocean/braintrust-ui-components";
import { budgetAdded } from "@js/apps/give-and-get-help/actions";
import { usePostLocationContext } from "@js/apps/give-and-get-help/context/post-location";
import { BudgetField } from "@js/apps/give-and-get-help/forms/fields/budget-field";
import { useAppDispatch } from "@js/hooks";

import type { getBudgetOptionsObj } from "../category-and-budget-utils";

type CreatePostBudgetProps = WrappedFieldsProps & {
  budgetOptions: ReturnType<typeof getBudgetOptionsObj>;
};

export const CreatePostBudget = ({
  budget,
  category,
  budgetOptions,
}: CreatePostBudgetProps) => {
  const dispatch = useAppDispatch();
  const location = usePostLocationContext();

  useEffect(() => {
    if (!budget.input.value) return;

    dispatch(
      budgetAdded({ value: budget.input.value, composer_location: location }),
    );
  }, [dispatch, budget.input.value, location]);

  const options = budgetOptions[category.input.value];
  if (!options) return null;

  return (
    <>
      <Typography component="h2" variant="label" mb={2} mt={4}>
        Set a budget for 1:1 help
      </Typography>
      <BudgetField options={options} input={budget.input} meta={budget.meta} />
    </>
  );
};
