import { Loader, Typography } from "@hexocean/braintrust-ui-components";
import { useMediaQuery } from "@hexocean/braintrust-ui-components/hooks";
import { useGetFreelancerBidsPaginatedQuery } from "@js/apps/freelancer/api";

import { AppliedJobsList } from "./applied-jobs-list";

export const AppliedJobsSection = () => {
  const isMobile = useMediaQuery("sm");
  const { data: mostRecentBids, isLoading } =
    useGetFreelancerBidsPaginatedQuery({
      ordering: "-created",
      page_size: 3,
      current: true,
    });
  const noBids = !isLoading && !mostRecentBids?.results.length;

  if (noBids) {
    return <></>;
  }

  return (
    <div className="applied-jobs-section">
      <Typography
        component="h3"
        variant={isMobile ? "label" : "title"}
        mb={2}
        size={isMobile ? "large" : "small"}
      >
        I’ve applied to
      </Typography>

      {isLoading || !mostRecentBids ? (
        <Loader />
      ) : (
        <AppliedJobsList bids={mostRecentBids.results} />
      )}
    </div>
  );
};
