import { useNavigate } from "react-router-dom";
import { Field, SubmissionError } from "redux-form";

import { Box, Button, Typography } from "@hexocean/braintrust-ui-components";
import { RouterLink } from "@js/components/link";
import { ConfirmButtonsWrapper, ModalInstance } from "@js/components/modal";
import { Snackbar } from "@js/components/snackbar";
import { createFormInstance } from "@js/forms/components";
import { TextField } from "@js/forms/fields";
import { useAppDispatch } from "@js/hooks";
import type { FreelancerOffer } from "@js/types/jobs";
import { parseFormSubmitError } from "@js/utils/redux-form";

import { offersApi, useRejectJobOfferMutation } from "../../api";

type RejectOfferFormValues = { reject_reason: string };

const RejectOfferFormInstance = createFormInstance<
  RejectOfferFormValues,
  unknown
>("reject-offer-form");

const RejectOfferFields = () => {
  return (
    <Field
      InputLabelProps={{
        shrink: true,
      }}
      id="reject-reason"
      name="reject_reason"
      variant="outlined"
      multiline
      rows={2}
      placeholder="Give this Client feedback for next time. Your response will be sent directly to the Client."
      InputProps={{
        sx: {
          borderRadius: "20px",
        },
      }}
      label={
        <>
          Let the Client know why you're rejecting
          <Typography component="span" color="grey" variant="label" ml="10px">
            Required
          </Typography>
        </>
      }
      component={TextField}
    />
  );
};

type RejectOfferModalContentProps = {
  offer: FreelancerOffer;
};

const RejectOfferModalContent = ({ offer }: RejectOfferModalContentProps) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [rejectJobOffer] = useRejectJobOfferMutation();
  const onSubmit = async (values: RejectOfferFormValues) => {
    try {
      await rejectJobOffer({
        id: offer.id,
        checksum: offer.checksum,
        reject_reason: values.reject_reason,
      }).unwrap();
    } catch (error) {
      dispatch(
        offersApi.util.invalidateTags([
          { type: "FreelancerOffers", id: offer.id },
        ]),
      );
      throw new SubmissionError(
        parseFormSubmitError({
          error,
          defaultGeneralFormError: "Something went wrong",
        }),
      );
    }
  };
  return (
    <RejectOfferFormInstance
      onSubmit={onSubmit}
      onSubmitSuccess={() => {
        ModalInstance.close();
        navigate("/talent/dashboard/my_jobs/offers");
        Snackbar.toast({
          header: `You declined this offer.`,
          content: renderRejectSuccessSnackbarContent,
        });
      }}
    >
      <Box mb={2} mt={4} className="reject-offer-modal-content">
        <Typography mb={1} component="h2" variant="title" size="medium">
          Are you sure you wish to reject this offer?
        </Typography>
        <Typography component="p">
          Rejecting an offer cannot be undone.{" "}
          <Typography component="span" fontWeight={500}>
            If you want to negotiate the terms of this offer, DO NOT reject it.{" "}
          </Typography>
          Instead, go back and message the Client so they can send an updated
          offer if needed.
        </Typography>
      </Box>
      <RejectOfferFields />
      <ConfirmButtonsWrapper>
        <Button onClick={ModalInstance.close} variant="secondary">
          Go Back
        </Button>
        <Button type="submit" variant="primary">
          Yes, reject the offer
        </Button>
      </ConfirmButtonsWrapper>
    </RejectOfferFormInstance>
  );
};

const renderRejectSuccessSnackbarContent = (closeSnackbar: () => void) => {
  return (
    <>
      No worries—not every job is a good fit. There are plenty more jobs on
      Braintrust! You can{" "}
      <RouterLink
        style={{ textDecoration: "underline" }}
        onClick={() => closeSnackbar()}
        to="/jobs"
      >
        search for the next one here.
      </RouterLink>
    </>
  );
};

export const openRejectOfferModal = ({ offer }: { offer: FreelancerOffer }) => {
  ModalInstance.open({
    className: "max-width-500",
    closeButton: true,
    closeButtonProps: {
      variant: "tertiary",
      size: "x-small",
    },
    children: <RejectOfferModalContent offer={offer} />,
  });
};
