import { useParams } from "react-router-dom";

import { useFetchInitialOfferQuery } from "@js/apps/jobs/apps/bids/api/api";
import { useAppSelector } from "@js/hooks";
import type {
  EmployerOffer,
  EmployerOfferBid,
  MatchSummary,
} from "@js/types/jobs";

import { useGetEmployerOfferQuery } from "../../api";

type UseCrateOrEditOfferMetaReturnPayload = {
  employer_offer_id: number | undefined;
  bid: EmployerOfferBid | (number & EmployerOfferBid);
  job_id: number;
  job_title: string;
  job_type: EnumType<typeof ENUMS.JobType>;
  status: EnumType<typeof ENUMS.OfferStatus> | null;
  talent_name: string;
  isDepositRequired: boolean;
  job_fee_rate: string;
  match_label: keyof typeof ENUMS.SmartMatchingLabels;
  match_summary: MatchSummary[];
  match_score: number;
} | null;

export const useCreateOrEditOfferMeta =
  (): UseCrateOrEditOfferMetaReturnPayload => {
    const { bidId, offerId } = useParams();

    // bidId is for new offers, offerId for edit
    const { data: employerOffer } = useGetEmployerOfferQuery(Number(offerId), {
      skip: offerId === undefined,
    });
    const { data: initialOffer } = useFetchInitialOfferQuery(
      { bidId: Number(bidId) },
      { skip: bidId === undefined },
    );
    const isDepositRequired = useAppSelector(
      (state) => state.employer.employerProfile?.offer_deposit_required,
    );

    const offer = initialOffer || employerOffer;
    if ((bidId || offerId) && offer) {
      return {
        employer_offer_id:
          offer === employerOffer ? (offer as EmployerOffer).id : undefined,
        bid: offer.bid,
        job_id: offer.bid.job.id,
        job_title: offer.bid.job.title,
        job_type: offer.bid.job.job_type,
        status: offer.status || null, // null is required
        talent_name: offer.bid.freelancer.user.first_name,
        job_fee_rate: offer.job_fee_rate,
        isDepositRequired: !!isDepositRequired,
        match_label: offer.match_label,
        match_summary: offer.match_summary,
        match_score: offer.match_score,
      };
    }
    return null;
  };
