import type Decimal from "decimal.js";

const formatCurrencyDefaultOptions = {
  locale: "en-US",
  currency: SETTINGS.DEFAULT_CURRENCY,
  removeDecimal: false,
};

type FormatCurrencyOptions = {
  removeTrailingZeros?: boolean;
  removeDecimal?: boolean;
  locale?: Intl.LocalesArgument;
  currency?: Intl.NumberFormatOptions["currency"];
  disableGrouping?: boolean;
};

export const formatCurrency = (
  value: string | number | null | undefined | Decimal,
  options: FormatCurrencyOptions = {},
) => {
  const _options = { ...formatCurrencyDefaultOptions, ...options };

  const fractionDigits = getFractionDigits({
    removeDecimal: _options.removeDecimal,
    removeTrailingZeros: _options.removeTrailingZeros,
  });

  return value === null || isNaN(Number(value))
    ? false
    : Number(value).toLocaleString(_options.locale, {
        style: "currency",
        currency: _options.currency,
        minimumFractionDigits: fractionDigits.minimumFractionDigits,
        maximumFractionDigits: fractionDigits.maximumFractionDigits,
        useGrouping: !options.disableGrouping,
      });
};

const getFractionDigits = ({
  removeDecimal,
  removeTrailingZeros,
}: Pick<FormatCurrencyOptions, "removeDecimal" | "removeTrailingZeros">) => {
  if (removeDecimal) {
    return { minimumFractionDigits: 0, maximumFractionDigits: 0 };
  }

  if (removeTrailingZeros) {
    return { minimumFractionDigits: 0, maximumFractionDigits: undefined };
  }

  return {
    minimumFractionDigits: 2,
    maximumFractionDigits: undefined,
  };
};

type FormatBudgetOptions = Intl.NumberFormatOptions & {
  paymentType?: EnumType<typeof ENUMS.JobPaymentType>;
  hourlyRateSuffix?: string;
  removeDecimal?: boolean;
};

export const formatBudget = (
  min,
  max,
  options: FormatBudgetOptions = { paymentType: undefined },
) => {
  let content;

  if (!min && !max) {
    return null;
  } else if (min && !max) {
    content = `${formatCurrency(min, options)}`;
  } else if (!min && max) {
    content = `${formatCurrency(max, options)}`;
  } else if (min && max) {
    if (min === max) {
      content = formatCurrency(max, options);
    } else {
      content = `${formatCurrency(min, options)} – ${formatCurrency(
        max,
        options,
      )}`;
    }
  }

  if (
    options.paymentType &&
    options.paymentType === ENUMS.JobPaymentType.HOURLY
  ) {
    content += options.hourlyRateSuffix ?? "/hour";
  }

  if (
    options.paymentType &&
    options.paymentType === ENUMS.JobPaymentType.ANNUAL
  ) {
    content += "/yr";
  }

  return content;
};

export const formatBudgetWithPrefix = (min, max) => {
  let content;

  if (!min && !max) {
    return null;
  } else if (min && !max) {
    content = `$${formatNumberToPrefix(min)}`;
  } else if (!min && max) {
    content = `$${formatNumberToPrefix(max)}`;
  } else if (min && max) {
    if (min === max) {
      content = `$${formatNumberToPrefix(max)}`;
    } else {
      content = `$${formatNumberToPrefix(min)} – ${formatNumberToPrefix(max)}`;
    }
  }

  return content;
};

export const formatNumberToPrefix = (num) => {
  if (num >= 1000000000) {
    return (
      Math.floor(num / 1000000000)
        .toString()
        .replace(/\.0$/, "") + "G"
    );
  }
  if (num >= 1000000) {
    return (
      Math.floor(num / 1000000)
        .toString()
        .replace(/\.0$/, "") + "M"
    );
  }
  if (num >= 1000) {
    return (
      Math.floor(num / 1000)
        .toString()
        .replace(/\.0$/, "") + "k"
    );
  }
  return Math.floor(num);
};

export const getBidAmountWithSuffix = (bid) => {
  return getPaymentAmountWithSuffix(bid.payment_amount, bid.payment_type);
};

export const getPaymentAmountWithSuffix = (
  amount,
  paymentType,
  options?: { amountOptions },
) => {
  const suffix =
    paymentType === ENUMS.JobPaymentType.HOURLY
      ? "/hr"
      : paymentType === ENUMS.JobPaymentType.ANNUAL
        ? "/yr"
        : "";

  return formatCurrency(amount, options?.amountOptions) + suffix;
};

export const formatAmountString = (amountStr: string) => {
  const amount = Number(amountStr);
  return amount % 1 === 0 ? String(amount) : amountStr;
};
