import { Box, Typography } from "@hexocean/braintrust-ui-components";
import { AccountInfoForm } from "@js/apps/freelancer/forms/account-info";
import { useAccountInfo } from "@js/apps/freelancer/hooks";

import { useProfileCompletionSequenceContext } from "../context/profile-completion-sequence-context";

import { ProfileCompletionSequenceCloseBtn } from "./close-btn";

export const About = () => {
  const { profile, shouldRender } = useProfileCompletionSequenceContext();

  const { formConfig } = useAccountInfo(profile);

  if (!shouldRender("About")) {
    return null;
  }

  return (
    <Box className="profile-page-modal-account-info" sx={{ padding: 4 }}>
      <ProfileCompletionSequenceCloseBtn />
      <AccountInfoForm
        {...formConfig}
        headerComponent={
          <Typography component="h3" variant="title" size="small" mb={4}>
            👋 Bio
          </Typography>
        }
      />
    </Box>
  );
};
