import { Typography } from "@hexocean/braintrust-ui-components";
import { BudgetLabel } from "@js/apps/give-and-get-help/components/post-category-and-budget-badges";
import { getHelpOfferCompletedSystemMessageCardTitle } from "@js/apps/messenger/components/system-message/utils";
import { closeGenericMessenger } from "@js/apps/messenger/messengers/modal/manage-messenger-modal";
import { Emoji } from "@js/components/emoji";
import { RouterLink } from "@js/components/link";
import { useAppDispatch } from "@js/hooks";
import type { SystemMessage } from "@js/types/messenger";
import {
  ExpectedHelpOfferRefundIssuedResults,
  ExpectedReportsCancelledResults,
} from "@js/types/messenger";
import { ExpectedHelpOfferReviewSentResults } from "@js/types/messenger";
import { ExpectedHelpOfferRefundRequestCancelledResults } from "@js/types/messenger";
import { ExpectedHelpOfferRefundRequestedResults } from "@js/types/messenger";
import { ExpectedHelpOfferCompletedResults } from "@js/types/messenger";
import { ExpectedHelpOfferRevisionRequestedResults } from "@js/types/messenger";
import { ExpectedHelpOfferMarkedCompleteResults } from "@js/types/messenger";
import { ExpectedHelpOfferAcceptedResults } from "@js/types/messenger";
import { ExpectedHelpOfferExpiredResults } from "@js/types/messenger";
import { ExpectedHelpOfferDeclinedResults } from "@js/types/messenger";
import { ExpectedHelpOfferDeletedResults } from "@js/types/messenger";
import { ExpectedHelpOfferBudgetChangedResults } from "@js/types/messenger";
import { ExpectedHelpOfferCreatedResults } from "@js/types/messenger";
import { ExpectedUserReportedResults } from "@js/types/messenger";
import { ExpectedHelpOfferReportedResults } from "@js/types/messenger";
import { ExpectedMessageReportedResults } from "@js/types/messenger";
import { assertUnreachable, formatCurrency } from "@js/utils";

import { ReportSystemMessageCard } from "./report-system-message-card";
import {
  QuotedHelpOfferMessage,
  SystemMessageCardContent,
} from "./system-message-card-content";

type SystemMessageCardProps = {
  message: SystemMessage;
  view: "ASKER" | "HELPER";
};

// null if card does not exist e.g. REPORTED message on HELPER's side or USER_MESSAGE
export const SystemMessageCard = ({
  message,
  view,
}: SystemMessageCardProps): JSX.Element | null => {
  const dispatch = useAppDispatch();

  const messageType = message.message_type;
  const asker = view === "ASKER";

  switch (messageType) {
    case ENUMS.MessageType.HELP_OFFER_CREATED: {
      const parsedResults = ExpectedHelpOfferCreatedResults.parse(
        message.system_message_target,
      );

      const title = asker
        ? `${parsedResults.author.user.first_name} sent you an offer for 1:1 help`
        : `You're offering to help with`;

      const newOfferMessage = !parsedResults.comment ? undefined : (
        <QuotedHelpOfferMessage comment={parsedResults.comment} />
      );

      const offerCreatedSubtitle = (
        <>
          <b>{parsedResults.category.name}</b> for{" "}
          <BudgetLabel budget={parsedResults.budget} />
        </>
      );

      return (
        <SystemMessageCardContent
          emoji="🤝"
          emojiBackground="var(--medium-yellow)"
          title={title}
          subtitle={offerCreatedSubtitle}
          additionalMessage={newOfferMessage}
        />
      );
    }

    case ENUMS.MessageType.HELP_OFFER_BUDGET_CHANGED: {
      const parsedResults = ExpectedHelpOfferBudgetChangedResults.parse(
        message.system_message_target,
      );

      const title = asker
        ? `${parsedResults.author.user.first_name} updated the price on their 1:1 help offer`
        : "You updated the price on your 1:1 help offer";
      const offerModifiedSubtitle = (
        <>
          The new price is <BudgetLabel budget={parsedResults.budget} />
        </>
      );
      return (
        <SystemMessageCardContent
          emoji="💰"
          emojiBackground="var(--medium-blue)"
          title={title}
          subtitle={offerModifiedSubtitle}
        />
      );
    }

    case ENUMS.MessageType.HELP_OFFER_DELETED: {
      const parsedResults = ExpectedHelpOfferDeletedResults.parse(
        message.system_message_target,
      );

      const title = asker
        ? `${parsedResults.author.user.first_name} removed their 1:1 help offer`
        : "You removed your 1:1 help offer";

      const offerDeletedSubtitle = (
        <>
          <b>{parsedResults.category.name}</b> for{" "}
          <BudgetLabel budget={parsedResults.budget} />
        </>
      );

      return (
        <SystemMessageCardContent
          emoji="🗑️"
          emojiBackground="var(--medium-red)"
          title={title}
          subtitle={offerDeletedSubtitle}
        />
      );
    }

    case ENUMS.MessageType.HELP_OFFER_DECLINED: {
      const parsedResults = ExpectedHelpOfferDeclinedResults.parse(
        message.system_message_target,
      );

      const title = asker
        ? `You declined ${parsedResults.author.user.first_name_possessive} 1:1 help offer`
        : `${parsedResults.receiver.user.first_name} declined your 1:1 help offer`;

      const offerDeclinedSubtitle = (
        <>
          <b>{parsedResults.category.name}</b> for{" "}
          <BudgetLabel budget={parsedResults.budget} />
        </>
      );

      return (
        <SystemMessageCardContent
          emoji="🚫"
          emojiBackground="var(--medium-red)"
          title={title}
          subtitle={offerDeclinedSubtitle}
        />
      );
    }

    case ENUMS.MessageType.HELP_OFFER_EXPIRED: {
      const parsedResults = ExpectedHelpOfferExpiredResults.parse(
        message.system_message_target,
      );

      const title = asker
        ? `${parsedResults.author.user.first_name_possessive} 1:1 help offer expired`
        : `Your 1:1 help offer to ${parsedResults.receiver.user.first_name} expired`;
      const expiredSubtitle = `Offers expire after ${SETTINGS.NUMBER_OF_DAYS_FOR_AN_OFFER_TO_EXPIRE} days of inactivity`;
      return (
        <SystemMessageCardContent
          emoji="⌛"
          emojiBackground="var(--soft-beige)"
          title={title}
          subtitle={expiredSubtitle}
        />
      );
    }

    case ENUMS.MessageType.HELP_OFFER_ACCEPTED: {
      const parsedResults = ExpectedHelpOfferAcceptedResults.parse(
        message.system_message_target,
      );

      const title = asker
        ? `You accepted ${parsedResults.author.user.first_name_possessive} 1:1 help offer!`
        : `${parsedResults.receiver.user.first_name} accepted your 1:1 help offer!`;

      const offerAcceptedSubtitle = (
        <>
          <b>{parsedResults.category.name}</b> for{" "}
          <BudgetLabel budget={parsedResults.budget} />
        </>
      );

      return (
        <SystemMessageCardContent
          emoji="🥳"
          emojiBackground="var(--medium-green)"
          title={title}
          subtitle={offerAcceptedSubtitle}
        />
      );
    }

    case ENUMS.MessageType.HELP_OFFER_MARKED_COMPLETE: {
      const parsedResults = ExpectedHelpOfferMarkedCompleteResults.parse(
        message.system_message_target,
      );

      const title = asker
        ? `${parsedResults.author.user.first_name} asked you to confirm their work is complete`
        : `You asked ${parsedResults.receiver.user.first_name} to confirm your work is complete`;
      const number_of_hours =
        24 * SETTINGS.NUMBER_OF_DAYS_FOR_AN_OFFER_TO_COMPLETE;
      const confirmationSubtitle = asker
        ? `You have ${number_of_hours} hours to respond, or their offer will be marked complete.`
        : `They have ${number_of_hours} hours to respond, or your offer will be marked complete.`;

      return (
        <SystemMessageCardContent
          emoji="👀"
          emojiBackground="var(--medium-yellow)"
          title={title}
          subtitle={confirmationSubtitle}
        />
      );
    }

    case ENUMS.MessageType.HELP_OFFER_REVISION_REQUESTED: {
      const parsedResults = ExpectedHelpOfferRevisionRequestedResults.parse(
        message.system_message_target,
      );

      const title = asker
        ? `You asked ${parsedResults.author.user.first_name} for a revision of their work`
        : `${parsedResults.receiver.user.first_name} asked for a revision`;
      const revisionSubtitle = asker
        ? `Message ${parsedResults.author.user.first_name} below to provide more details about your request`
        : "After you address their revision, make sure to mark your work complete again.";

      return (
        <SystemMessageCardContent
          emoji="✍️"
          emojiBackground="var(--medium-blue)"
          title={title}
          subtitle={revisionSubtitle}
        />
      );
    }

    case ENUMS.MessageType.HELP_OFFER_COMPLETED: {
      const parsedResults = ExpectedHelpOfferCompletedResults.parse(
        message.system_message_target,
      );

      const title = getHelpOfferCompletedSystemMessageCardTitle(
        parsedResults,
        asker,
      );

      const isPaymentRequired = !!Number(parsedResults.budget);

      const completedSubtitle = asker ? (
        <>
          Don't forget to{" "}
          <Typography
            component="link"
            RouterLink={RouterLink}
            className="system-message-content__link"
            to={`/talent/${parsedResults.author.id}/?write_review=true&offer=${parsedResults.id}&content_type=${ENUMS.ReviewType.HELP_OFFER_REVIEW}`}
          >
            leave {parsedResults.author.user.first_name} a review
          </Typography>{" "}
          on their profile!
        </>
      ) : (
        <>
          {isPaymentRequired && (
            <>
              Your BTRST token payment is now available in{" "}
              <Typography
                onClick={() => dispatch(closeGenericMessenger())}
                component="link"
                RouterLink={RouterLink}
                className="system-message-content__link"
                to="/talent/dashboard/my_wallet/"
              >
                your wallet
              </Typography>
              !
            </>
          )}
        </>
      );

      return (
        <SystemMessageCardContent
          emoji="🎉"
          emojiBackground="var(--medium-violet)"
          title={title}
          subtitle={completedSubtitle}
        />
      );
    }

    case ENUMS.MessageType.HELP_OFFER_REPORTED: {
      const parsedResults = ExpectedHelpOfferReportedResults.parse(
        message.system_message_target,
      );

      const title = asker
        ? `You reported ${parsedResults.author.user.first_name_possessive} offer`
        : `You reported your help offer`;
      const reportedSubtitle =
        "We’re looking into this and will get back to you in the next 72 hours";
      const reportedMessage = (
        <Typography
          component="p"
          size="small"
          className="system-message-content__additional-message--reported"
          fontStyle={"italic"}
        >
          (Only you can see this message{" "}
          <Emoji
            size={13}
            emoji="🤐"
            className="system-message-content__additional-message--reported-emoji"
          />
          )
        </Typography>
      );
      return (
        <SystemMessageCardContent
          emoji="🔴"
          emojiBackground="var(--soft-red)"
          title={title}
          subtitle={reportedSubtitle}
          additionalMessage={reportedMessage}
        />
      );
    }

    case ENUMS.MessageType.USER_REPORTED: {
      const parsedResults = ExpectedUserReportedResults.parse(
        message.system_message_target,
      );

      return <ReportSystemMessageCard name={parsedResults.public_name} />;
    }

    case ENUMS.MessageType.MESSAGE_REPORTED: {
      const parsedResults = ExpectedMessageReportedResults.parse(
        message.system_message_target,
      );

      return (
        <ReportSystemMessageCard name={parsedResults.author.public_name} />
      );
    }

    case ENUMS.MessageType.HELP_OFFER_REFUND_REQUESTED: {
      const parsedResults = ExpectedHelpOfferRefundRequestedResults.parse(
        message.system_message_target,
      );

      const title = asker
        ? `You requested a refund`
        : `${parsedResults.receiver.user.first_name} requested a refund`;
      const refundRequestSubtitle = asker
        ? `Your refund request was sent to ${parsedResults.author.user.first_name} for review. If you are unable to resolve the issue with ${parsedResults.author.user.first_name}, use the “Report” option to get help from Braintrust.`
        : `They included the message below. You can approve their request and issue a refund or send them a message and try to resolve the issue.`;

      return (
        <SystemMessageCardContent
          emoji="↩️"
          emojiBackground="var(--soft-grey)"
          title={title}
          subtitle={refundRequestSubtitle}
        />
      );
    }

    case ENUMS.MessageType.HELP_OFFER_REFUND_REQUEST_CANCELED: {
      const parsedResults =
        ExpectedHelpOfferRefundRequestCancelledResults.parse(
          message.system_message_target,
        );

      const title = asker
        ? `You've cancelled your refund request`
        : `${parsedResults.receiver.user.first_name} cancelled their refund request`;

      return (
        <SystemMessageCardContent
          emoji="✖"
          emojiBackground="var(--medium-blue)"
          title={title}
        />
      );
    }

    case ENUMS.MessageType.HELP_OFFER_REFUND_ISSUED: {
      const parsedResults = ExpectedHelpOfferRefundIssuedResults.parse(
        message.system_message_target,
      );

      const title = asker
        ? `You received a full refund from ${parsedResults.author.user.first_name}`
        : `You issued a full refund to ${parsedResults.receiver.user.first_name}`;
      const refundRequestSubtitle = (
        <>
          <Typography
            component="span"
            style={{
              fontWeight: "bold",
              fontSize: "inherit",
            }}
          >
            {formatCurrency(
              asker
                ? +parsedResults.budget + +parsedResults.fee
                : +parsedResults.budget,
            )}{" "}
            in BTRST{" "}
          </Typography>
          was transferred {asker ? "to" : "from"}{" "}
          <Typography
            onClick={() => dispatch(closeGenericMessenger())}
            component="link"
            RouterLink={RouterLink}
            className="system-message-content__link"
            to="/talent/dashboard/my_wallet/"
          >
            your wallet
          </Typography>
          .
        </>
      );

      return (
        <SystemMessageCardContent
          emoji="💰"
          emojiBackground="var(--medium-green)"
          title={title}
          subtitle={refundRequestSubtitle}
        />
      );
    }

    case ENUMS.MessageType.HELP_OFFER_REVIEW_SENT: {
      const parsedResults = ExpectedHelpOfferReviewSentResults.parse(
        message.system_message_target,
      );

      const title = asker
        ? `You left ${parsedResults.author.user.first_name} a review of their 1:1 help`
        : `${parsedResults.receiver.user.first_name} left you a review of your 1:1 help`;
      const reviewSentSubtitle = (
        <>
          You can read the review on{" "}
          {asker ? parsedResults.author.user.first_name_possessive : "your"}{" "}
          <Typography
            component="link"
            RouterLink={RouterLink}
            className="system-message-content__link"
            to={parsedResults.author.user.profile_url}
            onClick={() => dispatch(closeGenericMessenger())}
          >
            profile
          </Typography>
          .
        </>
      );

      return (
        <SystemMessageCardContent
          emoji="🤩"
          emojiBackground="var(--medium-violet)"
          title={title}
          subtitle={reviewSentSubtitle}
        />
      );
    }

    case ENUMS.MessageType.USER_REPORTS_CANCELLED: {
      const parsedResults = ExpectedReportsCancelledResults.parse(
        message.system_message_target,
      );

      return (
        <ReportSystemMessageCard
          name={parsedResults.public_name}
          cancelledReport
        />
      );
    }

    default:
      assertUnreachable(messageType);
      return null;
  }
};
