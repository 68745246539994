import { API } from "@js/api";
import type {
  AllJobsReportStatistics,
  MoneyFlow,
  SuggestedCategory,
  TokenGrantPlan,
} from "@js/types/admin";
import type { CompanyNode } from "@js/types/common";

import type {
  CustomerInvoiceReportFiltersFormParamsType,
  GrantRewardsListFetchParams,
  MoneyFlowDashboardFetchParams,
  OfferReportFiltersFormParamsType,
  RewardRulesListFetchParams,
  TokenBalanceReportFetchParams,
} from "./filters";
import type {
  AddNoteArgs,
  AddNoteResponse,
  AddRewardRuleOrderParams,
  AddRewardRuleOrderResponse,
  AdminRewardRules,
  AdminRewardRulesQueryArg,
  ApprovedReferredTalentArgs,
  ChangeRewardRuleOrderParams,
  ChangeRewardRuleOrderResponse,
  CompanyNodeWithId,
  CreateTGPPayload,
  CustomerInvoiceReportResponse,
  DeactivateRewardRuleResponse,
  DeleteNoteArgs,
  EditRewardRuleOrderParams,
  EditRewardRuleOrderResponse,
  FreelancerLocationMismatch,
  GetDepositReportParams,
  GetDepositReportReturnType,
  GetDepositReportsParams,
  GetDepositReportsReturnType,
  GetDepositReportTotalReturnType,
  GetNotesArgs,
  GetNotesResponse,
  GetTokenTaxesReportReturnType,
  GrantRewardResponse,
  HelpOfferPaymentsReportFilters,
  HelpOfferPaymentsReportResponse,
  HideRewardRuleResponse,
  InitiatePaymentToTalentArgs,
  JobReportQueryArg,
  JobReportResponse,
  JobStatsQueryArg,
  MarkInvoiceAsPaidOffPlatformArgs,
  MarkInvoiceAsResolvedArgs,
  MarkInvoiceAsUnresolvedArgs,
  MarkInvoiceAsWrittenOffArgs,
  MarkMultipleInvoicesAsPaidOffPlatform,
  MarkNetdPaymentAsResolvedArgs,
  OfferReportResponse,
  ReferralReportResponse,
  ReferralsReportArgs,
  RetryWithdrawalTransactionQueryArg,
  RewardRulesResponse,
  ShowRewardRuleResponse,
  TalentsWithJobReportParams,
  TalentsWithJobReportResponse,
  TerminateTGPResponse,
  TGHQueryArg,
  TGHResponse,
  TokenBalanceReportResponse,
  TokenBalanceSummaryResponse,
  UpdateHealthStatusArgs,
} from "./types";

const adminDashboardApi = API.injectEndpoints({
  endpoints: (build) => ({
    hideWithdrawalTransaction: build.mutation<void, number>({
      query: (id) => ({
        url: `/admin_dashboard/${id}/hide_withdrawal_transaction/`,
        method: "POST",
      }),
      invalidatesTags: ["MoneyFlowDashboard"],
    }),

    retryWithdrawalTransaction: build.mutation<
      void,
      RetryWithdrawalTransactionQueryArg
    >({
      query: (queryArgs) => ({
        url: `/admin_dashboard/${queryArgs.withdrawalTransactionId}/retry_withdrawal/`,
        method: "POST",
        data: { invoice_id: queryArgs.invoiceId },
      }),
      invalidatesTags: ["MoneyFlowDashboard"],
    }),

    getSuggestedCategories: build.query<SuggestedCategory[], void>({
      query: () => ({
        url: `/suggested_post_categories`,
        method: "GET",
      }),
    }),

    getTokenTaxesReport: build.query<
      GetTokenTaxesReportReturnType,
      Record<string, unknown> | void
    >({
      query: (params) => ({
        url: `/token_vesting_report/`,
        method: "GET",
        params,
      }),
    }),

    getFreelancerLocationMismatch: build.query<
      FreelancerLocationMismatch,
      number
    >({
      query: (freelancerId: number) => ({
        url: `/freelancers/${freelancerId}/location_mismatch/`,
        method: "GET",
      }),
    }),

    getCompanyNodesIdAndNameOnly: build.query<CompanyNodeWithId[], void>({
      query: () => ({
        url: `/company_nodes/return_id_and_name_only/`,
        method: "GET",
      }),
    }),

    getCompanyNodes: build.query<CompanyNode[], void>({
      query: () => ({
        url: `/company_nodes`,
        method: "GET",
      }),
    }),

    getTokenGrantPlans: build.query<TokenGrantPlan[], void>({
      query: () => {
        return {
          url: "/token_grant_plans/",
          method: "GET",
        };
      },
      providesTags: ["TokenGrantPlans"],
    }),

    createTokenGrantPlan: build.mutation<TokenGrantPlan, CreateTGPPayload>({
      query: (payload) => {
        return {
          url: "/token_grant_plans/",
          method: "POST",
          data: payload,
        };
      },
      invalidatesTags: ["TokenGrantPlans"],
    }),

    terminateTokenGrantPlan: build.mutation<TerminateTGPResponse, number>({
      query: (id) => {
        return {
          url: `/token_grant_plans/${id}/terminate_plan/`,
          method: "POST",
        };
      },
      invalidatesTags: ["TokenGrantPlans"],
    }),

    getDepositReports: build.query<
      GetDepositReportsReturnType,
      GetDepositReportsParams
    >({
      query: (params) => {
        return {
          url: `/deposit_report/`,
          method: "GET",
          params,
        };
      },
    }),

    getDepositReportTotal: build.query<GetDepositReportTotalReturnType, void>({
      query: () => {
        return {
          url: `/deposit_report/total`,
          method: "GET",
        };
      },
    }),

    getDepositReportPerClient: build.query<
      GetDepositReportReturnType,
      GetDepositReportParams
    >({
      query: ({ employer, ordering }) => {
        return {
          url: `/deposit_report/${employer}`,
          method: "GET",
          params: ordering ? { ordering } : undefined,
        };
      },
    }),

    getTokenGrantsHistory: build.query<TGHResponse, TGHQueryArg>({
      query: (queryStringParams) => {
        return {
          url: "/token_grants/",
          params: queryStringParams,
          method: "GET",
        };
      },
    }),

    getJobReportStats: build.query<AllJobsReportStatistics, JobStatsQueryArg>({
      query: (queryStringParams) => {
        return {
          url: "/all_jobs_counts/",
          params: queryStringParams,
          method: "GET",
        };
      },
    }),

    getJobReport: build.query<JobReportResponse, JobReportQueryArg>({
      query: (queryStringParams) => {
        return {
          url: "/all_jobs_report/",
          method: "GET",
          params: queryStringParams,
        };
      },
    }),
    getMoneyFlowDashboard: build.query<
      MoneyFlow,
      Partial<MoneyFlowDashboardFetchParams>
    >({
      query: (queryStringParams) => {
        return {
          url: "/admin_dashboard/",
          method: "GET",
          params: queryStringParams,
        };
      },
      providesTags: ["MoneyFlowDashboard"],
    }),
    getOfferReport: build.query<
      OfferReportResponse,
      Partial<OfferReportFiltersFormParamsType>
    >({
      query: (queryStringParams) => {
        return {
          url: "/offer_report/",
          method: "GET",
          params: queryStringParams,
        };
      },
      providesTags: ["OfferReport"],
    }),

    getRewardRules: build.query<
      RewardRulesResponse,
      Partial<RewardRulesListFetchParams>
    >({
      query: (params) => {
        return {
          url: "/manage_reward_rules/",
          method: "GET",
          params,
        };
      },
      providesTags: ["RewardRules"],
    }),

    deactivateRewardRule: build.mutation<DeactivateRewardRuleResponse, number>({
      query: (id) => {
        return {
          url: `/manage_reward_rules/${id}/deactivate/`,
          method: "POST",
        };
      },
      invalidatesTags: ["RewardRules"],
    }),

    hideRewardRule: build.mutation<HideRewardRuleResponse, number>({
      query: (id) => {
        return {
          url: `/manage_reward_rules/${id}/hide/`,
          method: "POST",
        };
      },
      invalidatesTags: ["RewardRules"],
    }),
    showRewardRule: build.mutation<ShowRewardRuleResponse, number>({
      query: (id) => {
        return {
          url: `/manage_reward_rules/${id}/show/`,
          method: "POST",
        };
      },
      invalidatesTags: ["RewardRules"],
    }),
    changeRewardRuleOrder: build.mutation<
      ChangeRewardRuleOrderResponse,
      ChangeRewardRuleOrderParams
    >({
      query: ({ id, order }) => {
        return {
          url: `/manage_reward_rules/${id}/change_order/`,
          method: "POST",
          data: { order },
        };
      },
      invalidatesTags: ["RewardRules"],
    }),
    editRewardRule: build.mutation<
      EditRewardRuleOrderResponse,
      EditRewardRuleOrderParams
    >({
      query: (values) => {
        return {
          url: `/manage_reward_rules/${values.id}`,
          method: "PATCH",
          data: values,
        };
      },
      invalidatesTags: ["RewardRules"],
    }),
    addRewardRule: build.mutation<
      AddRewardRuleOrderResponse,
      AddRewardRuleOrderParams
    >({
      query: (values) => {
        return {
          url: `/manage_reward_rules/`,
          method: "POST",
          data: values,
        };
      },
      invalidatesTags: ["RewardRules"],
    }),
    getReferralReport: build.query<ReferralReportResponse, ReferralsReportArgs>(
      {
        query: (params) => {
          return {
            url: "/referral_report/",
            method: "GET",
            params,
          };
        },
        providesTags: ["ReferralReport"],
      },
    ),
    approveReferredTalent: build.mutation<null, ApprovedReferredTalentArgs>({
      query: (talentId) => {
        return {
          url: `/talent/${talentId}/approve/`,
          method: "POST",
        };
      },
      invalidatesTags: ["ReferralReport"],
    }),
    getGrantRewards: build.query<
      GrantRewardResponse,
      Partial<GrantRewardsListFetchParams>
    >({
      query: (params) => {
        return {
          url: "/manage_rewards/",
          method: "GET",
          params,
        };
      },
    }),
    getTalentsWithJobsReport: build.query<
      TalentsWithJobReportResponse,
      TalentsWithJobReportParams
    >({
      query: (params) => {
        return {
          url: "/talents_with_jobs_report/",
          method: "GET",
          params,
        };
      },
      providesTags: ["TalentsWithJobsReport"],
    }),
    updateHealthStatus: build.mutation<null, UpdateHealthStatusArgs>({
      query: ({ id, health_status }) => {
        return {
          url: `/talents_with_jobs_report/${id}/update_health_status/`,
          method: "POST",
          data: {
            health_status,
          },
        };
      },
      invalidatesTags: ["TalentsWithJobsReport"],
    }),
    getAdminRewardRules: build.query<
      AdminRewardRules,
      AdminRewardRulesQueryArg
    >({
      query: (params) => {
        return {
          url: "/reward_rules/",
          method: "GET",
          params,
        };
      },
    }),

    getCustomerInvoiceReport: build.query<
      CustomerInvoiceReportResponse,
      CustomerInvoiceReportFiltersFormParamsType
    >({
      query: (params) => {
        return {
          url: "/invoice_report/",
          method: "GET",
          params,
        };
      },
      providesTags: ["InvoiceReport"],
    }),

    initiatePaymentToTalent: build.mutation<null, InitiatePaymentToTalentArgs>({
      query: ({ invoiceId, amount }) => {
        return {
          url: `/invoice_report/${invoiceId}/pay_talent_by_netd/`,
          method: "POST",
          data: { amount },
        };
      },
      invalidatesTags: ["InvoiceReport"],
    }),

    markNetdPaymentAsResolved: build.mutation<
      null,
      MarkNetdPaymentAsResolvedArgs
    >({
      query: (invoiceId) => {
        return {
          url: `/invoice_report/${invoiceId}/mark_netd_as_resolved/`,
          method: "POST",
        };
      },
      invalidatesTags: ["InvoiceReport"],
    }),

    markInvoiceAsResolved: build.mutation<null, MarkInvoiceAsResolvedArgs>({
      query: (invoiceId) => {
        return {
          url: `/invoice_report/${invoiceId}/mark_invoice_as_paid_do_not_initiate_payment/`,
          method: "POST",
        };
      },
      invalidatesTags: ["InvoiceReport"],
    }),

    markInvoiceAsUnresolved: build.mutation<null, MarkInvoiceAsUnresolvedArgs>({
      query: (invoiceId) => {
        return {
          url: `/invoice_report/${invoiceId}/mark_netd_invoice_as_unresolved/`,
          method: "POST",
        };
      },
      invalidatesTags: ["InvoiceReport"],
    }),

    markInvoiceAsWrittenOff: build.mutation<null, MarkInvoiceAsWrittenOffArgs>({
      query: (invoiceId) => {
        return {
          url: `/invoice_report/${invoiceId}/mark_as_written_off/`,
          method: "POST",
        };
      },
      invalidatesTags: ["InvoiceReport"],
    }),

    markInvoiceAsPaidOffPlatform: build.mutation<
      null,
      MarkInvoiceAsPaidOffPlatformArgs
    >({
      query: ({ invoiceId, amount }) => {
        return {
          url: `/invoice_report/${invoiceId}/mark_as_paid_off_platform/`,
          method: "POST",
          data: { amount },
        };
      },
      invalidatesTags: ["InvoiceReport"],
    }),
    markMultipleInvoicesAsPaidOffPlatform: build.mutation<
      null,
      MarkMultipleInvoicesAsPaidOffPlatform
    >({
      query: ({ invoices, amount }) => {
        return {
          url: `/invoice_report/settle_employer_payments/`,
          method: "POST",
          data: { invoices, amount },
        };
      },
      invalidatesTags: ["InvoiceReport"],
    }),
    getTokenBalanceReport: build.query<
      TokenBalanceReportResponse,
      TokenBalanceReportFetchParams
    >({
      query: (params) => {
        return {
          url: "/token_balance_report_items/",
          method: "GET",
          params,
        };
      },
    }),
    getTokenBalanceReportSummary: build.query<
      TokenBalanceSummaryResponse,
      void
    >({
      query: () => {
        return {
          url: "/token_balance_report_summary/",
          method: "GET",
        };
      },
    }),
    getOfferNotes: build.query<GetNotesResponse, GetNotesArgs>({
      query: (params) => {
        return {
          url: "/offer_notes/",
          method: "GET",
          params,
        };
      },
      providesTags: ["OfferNotes"],
    }),
    addOfferNote: build.mutation<AddNoteResponse, AddNoteArgs>({
      query: (args) => {
        return {
          url: `/offer_notes/`,
          method: "POST",
          data: args,
        };
      },
      invalidatesTags: ["OfferNotes", "OfferReport"],
    }),
    deleteOfferNote: build.mutation<void, DeleteNoteArgs>({
      query: ({ id }) => {
        return {
          url: `/offer_notes/${id}/`,
          method: "DELETE",
        };
      },
      invalidatesTags: ["OfferNotes", "OfferReport"],
    }),
    getNotes: build.query<GetNotesResponse, GetNotesArgs>({
      query: (params) => {
        return {
          url: "/notes/",
          method: "GET",
          params,
        };
      },
      providesTags: (_res, _err, arg) => [
        { type: "Notes", noteType: arg.target_name },
      ],
    }),
    addNote: build.mutation<AddNoteResponse, AddNoteArgs>({
      query: (args) => {
        return {
          url: `/notes/`,
          method: "POST",
          data: args,
        };
      },
      invalidatesTags: (_res, _err, arg) => [
        { type: "Notes", noteType: arg.target.object_name },
      ],
    }),
    deleteNote: build.mutation<void, DeleteNoteArgs>({
      query: ({ id }) => {
        return {
          url: `/notes/${id}/`,
          method: "DELETE",
        };
      },
      invalidatesTags: (_res, _err, arg) => [
        { type: "Notes", noteType: arg.target_name },
      ],
    }),
    getHelpOfferPaymentsReport: build.query<
      HelpOfferPaymentsReportResponse,
      HelpOfferPaymentsReportFilters
    >({
      query: (params) => {
        return {
          url: `/help_offer_payments_report/`,
          method: "GET",
          params,
        };
      },
    }),
  }),
});

export const {
  useRetryWithdrawalTransactionMutation,
  useGetMoneyFlowDashboardQuery,
  useHideWithdrawalTransactionMutation,
  useGetSuggestedCategoriesQuery,
  useGetTokenTaxesReportQuery,
  useLazyGetTokenTaxesReportQuery,
  useGetFreelancerLocationMismatchQuery,
  useGetCompanyNodesIdAndNameOnlyQuery,
  useGetCompanyNodesQuery,
  useGetTokenGrantsHistoryQuery,
  useGetTokenGrantPlansQuery,
  useCreateTokenGrantPlanMutation,
  useTerminateTokenGrantPlanMutation,
  useGetDepositReportsQuery,
  useGetDepositReportTotalQuery,
  useGetDepositReportPerClientQuery,
  useGetJobReportStatsQuery,
  useGetJobReportQuery,
  useGetOfferReportQuery,
  useGetRewardRulesQuery,
  useDeactivateRewardRuleMutation,
  useHideRewardRuleMutation,
  useShowRewardRuleMutation,
  useChangeRewardRuleOrderMutation,
  useEditRewardRuleMutation,
  useAddRewardRuleMutation,
  useGetReferralReportQuery,
  useApproveReferredTalentMutation,
  useGetGrantRewardsQuery,
  useGetAdminRewardRulesQuery,
  useGetTalentsWithJobsReportQuery,
  useUpdateHealthStatusMutation,
  useGetOfferNotesQuery,
  useAddOfferNoteMutation,
  useDeleteOfferNoteMutation,
  useGetNotesQuery,
  useAddNoteMutation,
  useDeleteNoteMutation,
  useGetCustomerInvoiceReportQuery,
  useInitiatePaymentToTalentMutation,
  useMarkInvoiceAsResolvedMutation,
  useMarkNetdPaymentAsResolvedMutation,
  useMarkInvoiceAsUnresolvedMutation,
  useMarkInvoiceAsWrittenOffMutation,
  useMarkInvoiceAsPaidOffPlatformMutation,
  useMarkMultipleInvoicesAsPaidOffPlatformMutation,
  useGetTokenBalanceReportQuery,
  useGetTokenBalanceReportSummaryQuery,
  useGetHelpOfferPaymentsReportQuery,
} = adminDashboardApi;

export type { JobReportResponse, TerminateTGPResponse, TGHResponse };
