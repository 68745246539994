import { Outlet } from "react-router-dom";

import { Box, Typography } from "@hexocean/braintrust-ui-components";
import { NftWalletIcon } from "@hexocean/braintrust-ui-components/Icons";
import { useUser } from "@js/apps/common/hooks";
import { useLockWhileMinting } from "@js/apps/nft/hooks";
import { useActiveWeb3React } from "@js/apps/web3/hooks";
import { shortenAddress } from "@js/apps/web3/utils";
import { Logo } from "@js/components/logo";
import { NavLayoutContainer } from "@js/layouts/app";
import { Navbar } from "@js/layouts/app/navbar";
import { CoreLayout } from "@js/layouts/core";

export const NFTLayout = () => {
  const user = useUser();
  const { account } = useActiveWeb3React();

  useLockWhileMinting();

  return (
    <CoreLayout className="nft-layout">
      <NavLayoutContainer className="nft-layout__header">
        <div className="nft-layout__header-inner">
          <Logo variant="full" />
          {!!account && (
            <Box className="nft-layout__wallet-address">
              <NftWalletIcon />
              <Typography
                component="p"
                variant="label"
                size="small"
                color="primary"
              >
                {shortenAddress(account)}
              </Typography>
            </Box>
          )}
          {!!user && <Navbar showMenuOnly />}
        </div>
      </NavLayoutContainer>
      <div className="nft-layout__content">
        <Outlet />
      </div>
    </CoreLayout>
  );
};
