import { createContext, useContext, useMemo } from "react";

import { SessionStorage } from "@js/services";

type Props = {
  children: React.ReactNode;
};

type ProviderProps = {
  scrollY: string | null;
  setScrollY: (y: number) => void;
  removeScrollY: () => void;
};

const ScrollPositionContext = createContext<ProviderProps>({} as ProviderProps);

export const ScrollPositionProvider = ({ children }: Props) => {
  const scrollY = SessionStorage.getItem("scrollY");
  const scrollPositionContextProviderValues = useMemo(
    () => ({
      scrollY,
      setScrollY: setScrollPosition,
      removeScrollY: removeScrollPosition,
    }),
    [scrollY],
  );

  return (
    <ScrollPositionContext.Provider value={scrollPositionContextProviderValues}>
      {children}
    </ScrollPositionContext.Provider>
  );
};

function setScrollPosition(yPosition: number) {
  SessionStorage.setItem("scrollY", yPosition.toString());
}

function removeScrollPosition() {
  SessionStorage.removeItem("scrollY");
}

export const useScroll = () => {
  const context = useContext(ScrollPositionContext);

  if (!context) {
    throw new Error("ScrollPositionContext provider is missing");
  }

  return context;
};
