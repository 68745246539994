import { Button } from "@hexocean/braintrust-ui-components";
import { ShareIcon } from "@hexocean/braintrust-ui-components/Icons";
import { PulseV2 } from "@js/components/animations";

type SharePostButtonProps = {
  showPulseAnimation: boolean;
  onClick: () => void;
};

export const SharePostButton = ({
  showPulseAnimation,
  onClick,
}: SharePostButtonProps) => {
  return (
    <Button
      aria-label="Open share post menu"
      aria-haspopup="true"
      variant="white-border-beige"
      size="x-small"
      shape="squared"
      onClick={onClick}
      sx={{
        "&:hover": {
          backgroundColor: "var(--grey-4)",
        },
      }}
    >
      {showPulseAnimation && (
        <PulseV2
          style={{
            position: "absolute",
            zIndex: -1,
          }}
        />
      )}

      <ShareIcon
        sx={{
          width: "20px",
          height: "20px",
          path: {
            strokeWidth: 1.9,
          },
        }}
      />
    </Button>
  );
};
