import type { TypedWrappedFieldProps } from "redux-form";

import type { StandardInputProps } from "@hexocean/braintrust-ui-components";
import { IconButton, Tooltip } from "@hexocean/braintrust-ui-components";
import { InfoIcon } from "@hexocean/braintrust-ui-components/Icons";
import { StandardTextField } from "@js/forms/fields";

type FieldProps = TypedWrappedFieldProps<string> & StandardInputProps;

type InvoiceNumberFieldProps = FieldProps & {
  isEdit?: boolean;
};

export const InvoiceNumberField = ({
  isEdit,
  input,
  ...props
}: InvoiceNumberFieldProps) => {
  if (isEdit) {
    return (
      <div className="invoice-number-field__number" title="Invoice Number">
        {input.value}
      </div>
    );
  }

  return (
    <>
      <StandardTextField
        label="Invoice Number"
        size="small"
        variant="standard"
        input={input}
        {...props}
      />
      <Tooltip
        className="invoice-number-field__number-tooltip"
        title="You can change your invoice number to match your records."
      >
        <IconButton variant="transparent" aria-label="about invoice number">
          <InfoIcon />
        </IconButton>
      </Tooltip>
    </>
  );
};
