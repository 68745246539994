import type { WrappedFieldProps } from "redux-form";

import { Divider, Typography } from "@hexocean/braintrust-ui-components";
import { FieldSectionPanelWithImportance } from "@js/apps/jobs/forms/fields";
import { RouterLink } from "@js/components/link";
import { getError, shouldDisplayError } from "@js/forms/utils";

import { AmountAndPaymentTypeField } from "../../fields";
import { useCreateOrEditOfferMeta } from "../../hooks/create-or-edit-offer-meta";

type PayDetailsSectionProps = Record<string, WrappedFieldProps>;

export const FreelancerPayDetailsSection = (props: PayDetailsSectionProps) => {
  const { payment_amount, payment_type } = props;

  const displayError = shouldDisplayError([payment_amount, payment_type]);

  return (
    <FieldSectionPanelWithImportance
      title="💸 Pay details"
      fieldImportance="required"
      isError={displayError}
    >
      <Divider color="beige" sx={{ my: "20px" }} />
      <PayRate payment_amount={payment_amount} payment_type={payment_type} />
    </FieldSectionPanelWithImportance>
  );
};

export const DirectHirePayDetailsSection = (props: PayDetailsSectionProps) => {
  const { payment_amount, payment_type } = props;

  const displayError = shouldDisplayError([payment_amount, payment_type]);

  return (
    <FieldSectionPanelWithImportance
      title="💸 Pay details"
      fieldImportance="required"
      isError={displayError}
    >
      <Divider color="beige" sx={{ my: "20px" }} />
      <PayRate payment_amount={payment_amount} payment_type={payment_type} />
    </FieldSectionPanelWithImportance>
  );
};

export const GrantPayDetailsSection = (props: PayDetailsSectionProps) => {
  const { payment_amount, payment_type } = props;

  const displayError = shouldDisplayError([payment_amount]);

  return (
    <FieldSectionPanelWithImportance
      title="💸 Pay details"
      fieldImportance="required"
      isError={displayError}
    >
      <Divider color="beige" sx={{ my: "20px" }} />
      <PayRate payment_amount={payment_amount} payment_type={payment_type} />
    </FieldSectionPanelWithImportance>
  );
};

const PayRate = ({
  payment_amount,
  payment_type,
}: {
  payment_amount: WrappedFieldProps;
  payment_type: WrappedFieldProps;
}) => {
  const offerMeta = useCreateOrEditOfferMeta();

  return (
    <>
      <Typography
        component="p"
        mb={2.25}
        sx={{
          overflowWrap: "anywhere",
        }}
      >
        What pay rate are you offering {offerMeta?.talent_name}?
      </Typography>
      {shouldDisplayError(payment_amount) && (
        <Typography mb={2} mt={-2} component="p" variant="paragraph" error>
          {getError(payment_amount)}
        </Typography>
      )}
      <AmountAndPaymentTypeField
        radius="9px"
        payment_amount={payment_amount}
        payment_type={payment_type}
      />
      <Typography mt={1} size="small" component="p">
        You will pay an additional{" "}
        {offerMeta?.job_fee_rate || SETTINGS.PERCENTAGE_FEE_RATE}% to Braintrust
        for the Client Fee.
        <Typography
          ml={0.5}
          variant="link"
          target="_blank"
          to="https://www.usebraintrust.com/payments"
          component="link"
          size="small"
          RouterLink={RouterLink}
        >
          Learn more
        </Typography>
        .
      </Typography>
    </>
  );
};
