import React from "react";

import { Box, Carousel, Typography } from "@hexocean/braintrust-ui-components";
import { useMediaQuery } from "@hexocean/braintrust-ui-components/hooks";
import type { CardTypeItem } from "@js/apps/freelancer/components/dashboard/first-steps/first-steps-card";
import {
  FirstStepsCard,
  firstStepsCardTypesData,
} from "@js/apps/freelancer/components/dashboard/first-steps/first-steps-card";
import { Logo } from "@js/components/logo";

const cardTypes = Object.keys(firstStepsCardTypesData);
export const FirstSteps = () => {
  const isMobile = useMediaQuery("sm");
  const itemsSpacing = isMobile ? 8 : 32;

  return (
    <div className="first-steps-section">
      {isMobile && (
        <Box display="flex" flexDirection="column" gap={2}>
          <WelcomeSlide />
          {cardTypes.map((item) => (
            <FirstStepsCard
              key={item}
              type={item as CardTypeItem}
              isMobile={isMobile}
            />
          ))}
        </Box>
      )}
      {!isMobile && (
        <div className="first-steps-section__cards">
          <Carousel
            slides={[
              <WelcomeSlide key="welcome-info" />,
              cardTypes.map((item) => (
                <FirstStepsCard type={item as CardTypeItem} key={item} />
              )),
            ].flat()}
            slideProps={{
              style: {
                height: "auto",
                width: "fit-content",
                overflow: "hidden",
                borderRadius: "16px",
              },
            }}
            mousewheel={{
              forceToAxis: true,
            }}
            className="first-steps-section__cards-swiper"
            observer
            observeParents
            grabCursor
            threshold={10}
            spaceBetween={itemsSpacing}
            centerInsufficientSlides
          />
        </div>
      )}
    </div>
  );
};

const WelcomeSlide = () => {
  return (
    <div className="first-steps-section__copy">
      <Typography
        component="span"
        display="block"
        variant="paragraph"
        size="large"
      >
        Welcome to
      </Typography>
      <div className="first-steps-section__logo-wrapper">
        <Logo size={225} />
      </div>
      <Typography
        component="span"
        variant="paragraph"
        size="medium"
        display="block"
      >
        You now have full access to our network of job openings and thriving
        career help community. Where will your career take you today?
      </Typography>
    </div>
  );
};
