import type { NavigateFunction } from "react-router-dom";

import { ModalInstance } from "@js/components/modal";

import { BoostSuccessModal } from "./boots-success-modal-content";
export const openBoostSuccessModal = (navigate: NavigateFunction): void => {
  ModalInstance.open({
    padding: false,
    closeButton: true,
    keepOnBackdropClick: true,
    closeButtonProps: {
      style: {
        backgroundColor: "rgba(var(--black-rgb), 0.04)",
        padding: "2px",
      },
    },
    children: <BoostSuccessModal />,
  });
  const observer = new MutationObserver(() => {
    const modal = document.querySelector("#boost-success-modal");
    if (modal) {
      const parent = modal?.parentNode?.querySelector("button");
      parent?.addEventListener("click", function () {
        ModalInstance.close();
        navigate("role-jobs");
      });
      observer.disconnect();
    }
  });
  observer.observe(document.body, { childList: true, subtree: true });
};
