import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import type { IPost } from "@js/types/give-and-get-help";

export const useRedirectFromSpacesPostToFeedPost = (
  post: IPost | undefined,
) => {
  const navigate = useNavigate();

  useEffect(() => {
    if (post && !post.space) {
      navigate(`/feed/${post.id}`, { replace: true });
    }
  }, [navigate, post]);
};
