import { useMemo, useState } from "react";
import _ from "underscore";
import uuid4 from "uuid4/browser";

import type {
  AutocompleteChangeReason,
  ComboBoxMultipleProps,
} from "@hexocean/braintrust-ui-components";
import { ComboBoxMultiple } from "@hexocean/braintrust-ui-components";
import {
  KeyboardArrowDownIcon,
  LocationPinSmallIcon,
} from "@hexocean/braintrust-ui-components/Icons";
import { CUSTOM_JOB_LOCATIONS } from "@js/apps/common/constants";
import type { PlacesServicesTypes } from "@js/hooks/google-maps";
import { useGoogleMaps } from "@js/hooks/google-maps";

import { type BackendValue, type ComboBoxValue, ValueType } from "./types";
import { mapValue } from "./utils";

import styles from "./styles.module.scss";

export type GooglePlacesComboBoxMultipleProps<
  DisableClearable extends boolean,
> = DistributiveOmit<
  ComboBoxMultipleProps<ComboBoxValue, DisableClearable>,
  "onChange" | "options" | "initialTaxonomiesLoading" | "value" | "defaultValue"
> & {
  value?: BackendValue[] | null;
  onChange: (
    value: Array<ComboBoxValue> | null,
    reason: AutocompleteChangeReason,
  ) => void;
  placesServiceTypes?: PlacesServicesTypes;
  useCustomLocations?: boolean;
  locationIcon?: boolean;
};

export const GooglePlacesComboBoxMultiple = <DisableClearable extends boolean>({
  value: valueToMap,
  onChange,
  placesServiceTypes = "cities",
  useCustomLocations = false,
  locationIcon = true,
  ...props
}: GooglePlacesComboBoxMultipleProps<DisableClearable>) => {
  const {
    placePredictions,
    getPlacePredictions,
    isPlacePredictionsLoading,
    getPlaceDetails,
  } = useGoogleMaps();
  const [sessionToken, setSessionToken] = useState(uuid4());

  const value = useMemo(() => {
    if (Array.isArray(valueToMap)) {
      return valueToMap.map(mapValue);
    }

    return [];
  }, [valueToMap]);

  const options = useMemo((): ComboBoxValue[] => {
    const mappedPredictions = placePredictions.map((place) => ({
      type: ValueType.prediction,
      id: place.place_id,
      name: place.description,
    }));

    const initial = !!value ? value : [];

    if (useCustomLocations) {
      return _.uniq(
        [...initial, ...CUSTOM_JOB_LOCATIONS, ...mappedPredictions],
        "id",
      );
    } else {
      return _.uniq([...initial, ...mappedPredictions], "id");
    }
  }, [value, useCustomLocations, placePredictions]);

  return (
    <ComboBoxMultiple<ComboBoxValue, DisableClearable>
      value={value}
      onInputChange={(_event, input, reason) => {
        if (reason === "reset") {
          return;
        }

        getPlacePredictions({
          input,
          types: placesServiceTypes,
          sessionToken,
        });
      }}
      onChange={(_event, newValue, reason) => {
        if (!newValue) {
          onChange(null, reason);
          return;
        }

        if (reason === "removeOption") {
          onChange([...newValue], reason);
          return;
        }

        const selectedValue = newValue.find((val) => !val?.selected); // selected value does not have place details
        const currentValues = newValue.filter((val) => !!val?.selected); // selected previously

        if (selectedValue && selectedValue.type === ValueType.prediction) {
          getPlaceDetails(
            {
              sessionToken,
              placeId: selectedValue.id,
            },
            (placeDetails) => {
              setSessionToken(uuid4());

              onChange(
                [
                  ...currentValues,
                  {
                    ...placeDetails,
                    type: ValueType.detail,
                    id: placeDetails?.place_id,
                    name: placeDetails?.formatted_address,
                  },
                ],
                reason,
              );
            },
          );
          return;
        }

        onChange([...newValue], reason);
      }}
      loading={isPlacePredictionsLoading}
      classes={
        locationIcon
          ? { popupIndicatorOpen: styles.popupIndicatorOpen }
          : undefined
      } // Prevent location icon from rotating on open
      popupIcon={
        locationIcon ? <LocationPinSmallIcon /> : <KeyboardArrowDownIcon />
      }
      getOptionLabel={(option) => {
        if (!option) {
          return "";
        }

        const optionType = option.type;
        switch (optionType) {
          case ValueType.initial_mapped_from_string:
          case ValueType.custom:
          case ValueType.prediction: {
            return option.name;
          }
          case ValueType.detail: {
            return option.formatted_address || option.name || "";
          }
          default: {
            optionType satisfies never;
            // @ts-expect-error to keep support for the the old data which doesn't contain `type`.
            return option.formatted_address || option.name || "";
          }
        }
      }}
      filterOptions={(opts, { inputValue }) => {
        const filteredOptions = opts.filter((option) => {
          const isCustom = option?.type === ValueType.custom;
          /**
           * Initial value needs to be in options list to fix MUI warning,
           * but we don't want to display it in options list as it is faked object (not compatible with the backend).
           */
          const isInitial =
            option?.type === ValueType.initial_mapped_from_string ||
            !!option?.selected;

          if (inputValue) {
            return !isCustom && !isInitial;
          }

          return isCustom;
        });

        return filteredOptions;
      }}
      isOptionEqualToValue={(option, val) => {
        return (
          val?.id === option?.id ||
          // @ts-expect-error for compatibility, but should be adjusted in other places
          val?.place_id === option?.id ||
          // @ts-expect-error for compatibility, but should be adjusted in other places
          val?.custom_location === option?.id
        );
      }}
      {...props}
      options={options}
      initialTaxonomiesLoading={false}
      multiple={true}
    />
  );
};
