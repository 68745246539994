import * as React from "react";
import ReactGA from "react-ga";
import ReactGA4 from "react-ga4";
import TagManager from "react-gtm-module";
import type { createBrowserRouter } from "react-router-dom";
import { matchPath, matchRoutes } from "react-router-dom";
import * as Sentry from "@sentry/react";
import axios from "axios";
import Cookies from "js-cookie";
import { z } from "zod";

import { store } from "@js/store";

import { buildFiltersURL, paramsToObject } from "./apps/common/filters";
import { EmployerProfilePage } from "./apps/employer/views/profile";
import FreelancerProfilePage from "./apps/freelancer/views/profile";
import { TalentPreviewOfferPage } from "./apps/jobs/apps/offers/views/talent-preview-offer";
import JobDetailsPage from "./apps/jobs/views/job-details";
import {
  requestFinished,
  requestStarted,
} from "./components/request-loader/logic";
import { deleteAmplitudeCookies } from "./services/analytics/utils";
import { globalConfig } from "./constants";
import { isErrorUnwantedToLog } from "./sentry-ignored";
import { LocalStorage } from "./services";
import {
  getCurrentPathEncoded,
  getURL,
  UNEXPECTED_NETWORK_ERROR_MESSAGE,
} from "./utils";

/** https://github.com/colinhacks/zod/blob/master/ERROR_HANDLING.md#customizing-errors-with-zoderrormap */
const customErrorMap: z.ZodErrorMap = (issue, ctx) => {
  if (
    issue.code === z.ZodIssueCode.invalid_date &&
    ctx.defaultError === "Invalid date"
  ) {
    return { message: "Please enter a valid date" };
  }
  return { message: ctx.defaultError };
};

z.setErrorMap(customErrorMap);

const SENTRY_ERROR_RATE_LIMIT = 50;
let SentryErrorCount = 0;
let SentryErrorInterval;

export const configureServices = async () => {
  if (SETTINGS.SENTRY_ENABLED) {
    Sentry.init({
      dsn: SETTINGS.SENTRY_DSN,
      integrations: [
        Sentry.dedupeIntegration(),
        Sentry.extraErrorDataIntegration(),
        Sentry.replayIntegration(),
      ],
      replaysSessionSampleRate: 0,
      replaysOnErrorSampleRate: 1,
      beforeSend(event) {
        // additional condition to filter unwanted errors that cannot be handled by 'ignoreErrors' and 'denyUrls'
        if (isErrorUnwantedToLog(event)) {
          return null;
        }

        SentryErrorCount++;

        // Do not send errors to the sentry if the limit exceeded.
        if (SENTRY_ERROR_RATE_LIMIT <= SentryErrorCount) {
          if (!SentryErrorInterval) {
            SentryErrorInterval = setInterval(
              () => {
                clearInterval(SentryErrorInterval);
                SentryErrorCount = 0;
                SentryErrorInterval = undefined;
              },
              1000 * 60 * 60,
            ); // 1h
          }

          return null;
        }

        // Check if the error message or stack trace contains 'googletagmanager.com'
        const isGoogleTagManagerError = event.exception?.values?.some((value) =>
          value.stacktrace?.frames?.some((frame) =>
            frame.filename?.includes("googletagmanager.com"),
          ),
        );

        // Ignore errors from googletagmanager.com
        if (isGoogleTagManagerError) {
          return null;
        }

        try {
          const modalsData = store.getState().modal;
          event.extra = {
            ...event.extra,
            modalsData,
            backendRevision: SETTINGS.BACKEND_REVISION,
          };
          return event;
        } catch (error) {
          return event;
        }
      },
      // https://docs.sentry.io/platforms/javascript/configuration/filtering/#decluttering-sentry
      ignoreErrors: [
        // Random plugins/extensions
        "top.GLOBALS",
        // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
        "originalCreateNotification",
        "canvas.contentDocument",
        "MyApp_RemoveAllHighlights",
        "http://tt.epicplay.com",
        "Can't find variable: ZiteReader",
        "jigsaw is not defined",
        "ComboSearch is not defined",
        "http://loading.retry.widdit.com/",
        "atomicFindClose",
        // Facebook borked
        "fb_xd_fragment",
        // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to reduce this. (thanks @acdha)
        // See http://stackoverflow.com/questions/4113268/how-to-stop-javascript-injection-from-vodafone-proxy
        "bmi_SafeAddOnload",
        "EBCallBackMessageReceived",
        // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
        "conduitPage",
        // Generic error code from errors outside the security sandbox
        // You can delete this if using raven.js > 1.0, which ignores these automatically.
        // ---
        // Our custom list below
        "Script error.",
        "Mixpanel error",
        "instantSearchSDKJSBridgeClearHighlight",
        "zaloJSV2",
        "_avast_submit",
        "MetaMask - RPC Error: Request of type 'wallet_requestPermissions' already pending for origin",
        /MetaMask: Received invalid \w* parameter/i,
        "chrome-extension://",
        "moz-extension://",
        "safari-extension://",
        "webkit-masked-url://hidden/",
        "Network Error",
        "window.webkit.messageHandlers",
        "Unsupported chain id:",
        "UCShellJava.sdkEventFire",
        "Failed to execute 'removeChild' on 'Node'",
        "translate.goog",
        "ResizeObserver loop limit exceeded",
        "beacon",
        "gtag is not defined",
        "fbq is not defined",
        "google is not defined",
        "Can't find variable: gtag",
        "Can't find variable: fbq",
        "Can't find variable: google",
        "ChunkLoadError",
        /widget\/freddyfeedback/i,
        // https://github.com/getsentry/sentry-javascript/issues/3440#issuecomment-1233146122
        /Object Not Found Matching Id/i,
      ],
      denyUrls: [
        // Facebook flakiness
        /graph\.facebook\.com/i,
        // Facebook blocked
        /connect\.facebook\.net\/en_US\/all\.js/i,
        // Woopra flakiness
        /eatdifferent\.com\.woopra-ns\.com/i,
        /static\.woopra\.com\/js\/woopra\.js/i,
        // Chrome extensions
        /extensions\//i,
        /^chrome:\/\//i,
        // Other plugins
        /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
        /webappstoolbarba\.texthelp\.com\//i,
        /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
        /js\-agent\.newrelic\.com/i,
        /freddyfeedback\.com\/api\/v1\/survey\/.*/i,
        /gstatic\.com\/recaptcha/i,
        /googleads\.g\.doubleclick/,
        /googletagmanager\.com/i,
      ],
    });
  }

  if (SETTINGS.GOOGLE_ANALYTICS_V4_ID) {
    ReactGA4.initialize(SETTINGS.GOOGLE_ANALYTICS_V4_ID);
  }

  if (SETTINGS.GOOGLE_ANALYTICS_ID) {
    ReactGA.initialize(SETTINGS.GOOGLE_ANALYTICS_ID);

    if (SETTINGS.GOOGLE_OPTIMIZE_ID) {
      ReactGA.ga("require", SETTINGS.GOOGLE_OPTIMIZE_ID);
    }
  }

  if (SETTINGS.GOOGLE_TAG_MANAGER_ID) {
    TagManager.initialize({
      gtmId: SETTINGS.GOOGLE_TAG_MANAGER_ID,
    });
  }
};

// Related to firefox issue, when during fetching user reloads page, multiple snackbars are shown
window.addEventListener("beforeunload", () => {
  globalConfig.surpressDisplaySnackbarOnBeforeUnload = true;
});

export const configureRequestsLibrary = (
  router: ReturnType<typeof createBrowserRouter>,
) => {
  axios.interceptors.request.use((config) => {
    requestStarted();
    config.headers.set("X-CSRFToken", Cookies.get("csrftoken"));

    return config;
  });

  axios.defaults.headers.common["Backend-Revision"] = SETTINGS.BACKEND_REVISION;

  axios.interceptors.response.use(
    (response) => {
      requestFinished();
      return response;
    },
    (errorResponse) => {
      requestFinished();
      const { response } = errorResponse;
      const nonEmptyResponse = response || { data: {}, status: null };
      const { status, config } = nonEmptyResponse;

      switch (status) {
        case 404:
          if (config.method !== "get") {
            break;
          } else {
            const matches = matchRoutes(
              [
                {
                  element: <FreelancerProfilePage />,
                  path: "/talent/:id",
                },
                {
                  element: <EmployerProfilePage />,
                  path: "/employers/:id",
                },
                {
                  element: <TalentPreviewOfferPage />,
                  path: "/jobs/:jobId/offers/:id",
                },
                {
                  element: <JobDetailsPage />,
                  path: "/jobs/:id",
                },
              ],
              window.location?.pathname,
            );

            const matchWithError = matches?.find(
              (match) => match.route.element.type.HTTP_404_ID,
            );

            const Http404Code = matchWithError
              ? matchWithError.route.element.type.HTTP_404_ID
              : nonEmptyResponse.data.detail;

            if (Http404Code === "Invalid page.") {
              const filters = paramsToObject(window.location.search);
              const newUrl = buildFiltersURL(filters);
              newUrl.setQuery("page", 1);
              const to = getURL(newUrl.toString());
              router.navigate(to, { replace: true });
              return;
            }

            const isNotFoundPost = matchPath(
              {
                path: "/api/posts/:id",
              },
              config.url,
            );

            if (isNotFoundPost) {
              errorResponse.response = {
                ...errorResponse.response,
                ...nonEmptyResponse,
              };
              break;
            }

            if (Http404Code) {
              const url = `/page-not-found/?message=${Http404Code}&origin=${window.location.href}`;
              router.navigate(url, {
                state: nonEmptyResponse.data,
                replace: true,
              });
            }
          }

          break;
        case 403: {
          const matches = matchRoutes(
            [
              {
                element: <JobDetailsPage />,
                path: "/jobs/:id",
              },
            ],
            window.location?.pathname,
          );

          const matchWithError = matches?.find(
            (match) => match.route.element.type.HTTP_404_ID,
          );

          const Http403Code = matchWithError
            ? matchWithError.route.element.type.HTTP_403_ID
            : nonEmptyResponse.data.detail;

          if (Http403Code) {
            const url = `/access-denied/?message=${Http403Code}`;
            router.navigate(url, {
              state: nonEmptyResponse.data,
              replace: true,
            });
          }
          break;
        }
        case 401:
          if (global.currentRouteRequireAuth) {
            LocalStorage.clear();
            deleteAmplitudeCookies();
            window.location.href = `/auth/login/?message=session_expired&next=${getCurrentPathEncoded()}`;
          }
          break;
        case 429:
          router.navigate(`/429/`);
          break;
        default:
          if (
            axios.isCancel(errorResponse) ||
            errorResponse?.code === "ECONNABORTED" // it seems that cancelation without a token is not passing axios.isCancel(errorResponse) - cancelling request by browser when you change page
          ) {
            // Cancelled on purpose, return empty error
          } else if (!errorResponse.response) {
            // The request was made but no response was received.
            nonEmptyResponse.data = {
              _error: UNEXPECTED_NETWORK_ERROR_MESSAGE,
            };
          }

          errorResponse.response = {
            ...errorResponse.response,
            ...nonEmptyResponse,
          };
      }

      if (globalConfig.surpressDisplayGenericRequestError) {
        return {};
      } else {
        return Promise.reject(errorResponse);
      }
    },
  );
};
