import { useCallback, useState } from "react";
import { useLocation } from "react-router-dom";

import type { EmployerBidListBid } from "@js/types/jobs";
import { isInteractiveElementClicked } from "@js/utils";

import { useMarkBidAsViewedMutation } from "../../api";

type BidListProps = {
  bid: EmployerBidListBid;
  jobId: number;
};

type BidListItemReturnType = {
  notesCount: number;
  setNotesCount: (val: number) => void;
  linkToSidePanel: string;
  isSkillsAndQASectionEmpty: boolean;
  handleClickWithinItem: (
    ev: React.MouseEvent<HTMLDivElement>,
  ) => Promise<void>;
};

export const useBidListItem = ({
  bid,
  jobId,
}: BidListProps): BidListItemReturnType => {
  const [notesCount, setNotesCount] = useState(bid?.notes_count);
  const [markBidAsViewed] = useMarkBidAsViewedMutation();
  const { search } = useLocation();

  const linkToSidePanel = `/jobs/${jobId}/proposals/${bid.id}${search}`;
  const isSkillsAndQASectionEmpty =
    !bid.freelancer.superpower_skills.length && !bid.application_answers.length;

  const handleClickWithinItem = useCallback(
    async (event: React.MouseEvent<HTMLDivElement>) => {
      if (bid.status === ENUMS.BidStatus.NEW) {
        const interactiveElementClicked = isInteractiveElementClicked(event);

        if (!interactiveElementClicked) return;

        await markBidAsViewed({ bidId: bid.id });
      }
    },
    [bid, markBidAsViewed],
  );

  return {
    notesCount,
    setNotesCount,
    linkToSidePanel,
    isSkillsAndQASectionEmpty,
    handleClickWithinItem,
  };
};
