import { Box, Divider } from "@hexocean/braintrust-ui-components";
import type { MainJobListingJob } from "@js/types/jobs";

import { ScoreMatchBadge } from "../avatar-with-score-match-badge";
import { JobSkills } from "../job-skills";

import {
  JobItemActions,
  JobItemBasicInfo,
  JobItemBudget,
  JobItemButtons,
  JobItemEmployerData,
  JobItemExpandBtn,
  JobItemExpandedContent,
  JobItemHeader,
  JobItemTags,
} from "./components";
import { useJobItem } from "./hooks";

import styles from "./styles.module.scss";

export type JobItemProps = {
  job: MainJobListingJob;
  invitesTab?: boolean;
  resultPosition?: number;
  searchResults?: boolean;
};

export const JobItem = ({
  job,
  resultPosition,
  searchResults,
  invitesTab = false,
}: JobItemProps) => {
  const {
    isMobile,
    isTablet,
    jobType,
    skillToDisplay,
    expanded,
    handleSetExpanded,
    isApplied,
    location,
  } = useJobItem({
    job,
    searchResults,
    resultPosition,
  });

  return (
    <Box className={styles.wrapper} tabIndex={0}>
      <Box className={styles.topSection}>
        <JobItemEmployerData employer={job.employer} />
        <Box className={styles.header}>
          <JobItemHeader
            title={job.title}
            id={job.id}
            resultPosition={resultPosition}
            searchResults={searchResults}
          />
          <JobItemButtons
            isInvitesTab={invitesTab}
            isMobile={isMobile}
            isTablet={isTablet}
            isSearchResults={searchResults}
            resultPosition={resultPosition}
            job={job}
          />
          <JobItemTags job={job} jobType={jobType} />
          <JobItemActions job={job} showThreeDotsMenu />
          <JobItemBasicInfo job={job} />
          <JobItemBudget job={job} />
          <Box className={styles.divider}>
            <Divider color="beige" />
          </Box>
          <Box className={styles.footer}>
            <Box display="flex" alignItems="center" flexWrap="wrap" gap="8px">
              <ScoreMatchBadge matchLabel={job.match_label} jobId={job.id} />
              <Box marginTop="8px">
                <JobSkills skills={skillToDisplay} />
              </Box>
            </Box>
            <JobItemExpandBtn
              expanded={expanded}
              onExpand={handleSetExpanded}
              jobId={job.id}
            />
          </Box>
        </Box>
      </Box>

      {expanded && (
        <JobItemExpandedContent
          job={job}
          isApplied={isApplied}
          resultPosition={resultPosition}
          searchResults={searchResults}
          location={location}
        />
      )}
    </Box>
  );
};
