import type { TalentOfferStatus as TalentOfferStatusType } from "@js/types/jobs";
import { assertUnreachable } from "@js/utils";

import { OfferStatusChip } from "./offer-status-chip";

type EmployerOfferStatusProps = {
  offerStatus: EnumType<typeof ENUMS.OfferStatus> | null;
};

export const EmployerOfferStatus = ({
  offerStatus,
}: EmployerOfferStatusProps) => {
  const { color, label } = getEmployerOfferStatus(offerStatus);

  return <OfferStatusChip label={label} color={color} />;
};

type TalentOfferStatusProps = {
  offerStatus: TalentOfferStatusType;
};

export const TalentOfferStatus = ({ offerStatus }: TalentOfferStatusProps) => {
  const { color, label } = getTalentOfferStatus(offerStatus);

  return <OfferStatusChip label={label} color={color} />;
};

const getEmployerOfferStatus = (
  status: EnumType<typeof ENUMS.OfferStatus> | null,
) => {
  switch (status) {
    case null:
      return {
        color: "green",
        label: "Creating offer",
      };
    case ENUMS.OfferStatus.ACCEPTED:
      return {
        color: "green",
        label: "Offer accepted 🎉",
      };
    case ENUMS.OfferStatus.REJECTED:
      return {
        color: "red",
        label: "Offer declined",
      };
    case ENUMS.OfferStatus.CANCELLED:
      return {
        color: "red",
        label: "Canceled by Client",
      };
    case ENUMS.OfferStatus.EXPIRED:
      return {
        color: "grey",
        label: "Offer expired",
      };
    case ENUMS.OfferStatus.NEW:
    case ENUMS.OfferStatus.UPDATED:
      return {
        color: "blue",
        label: "Pending offer",
      };
    case ENUMS.OfferStatus.WAITING_FOR_DEPOSIT:
    case ENUMS.OfferStatus.DEPOSIT_PAYMENT_IN_PROGRESS:
    case ENUMS.OfferStatus.WAITING_FOR_DEPOSIT_ACH_CONFIRMATION:
      return {
        color: "blue",
        label: "Pending billing confirmation",
      };
    case ENUMS.OfferStatus.DEPOSIT_PAYMENT_FAILED:
      return {
        color: "red",
        label: "Payment method failed",
      };
    default:
      assertUnreachable(status);
      return {
        color: "blue",
        label: "Pending offer",
      };
  }
};

export const getTalentOfferStatus = (status: TalentOfferStatusType | null) => {
  switch (status) {
    case null:
    case ENUMS.OfferStatus.ACCEPTED:
      return {
        color: "green",
        label: "Offer accepted 🎉",
      };
    case ENUMS.OfferStatus.REJECTED:
      return {
        color: "red",
        label: "Offer declined",
      };
    case ENUMS.OfferStatus.CANCELLED:
      return {
        color: "red",
        label: "Canceled by Client",
      };
    case ENUMS.OfferStatus.EXPIRED:
      return {
        color: "grey",
        label: "Offer expired",
      };
    case ENUMS.OfferStatus.NEW:
      return {
        color: "blue",
        label: "New offer",
      };
    case ENUMS.OfferStatus.UPDATED:
      return {
        color: "blue",
        label: "Updated offer",
      };
    default:
      assertUnreachable(status);
      return {
        color: "blue",
        label: "New offer",
      };
  }
};
