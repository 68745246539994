import React from "react";

import { Box, Typography } from "@hexocean/braintrust-ui-components";

export const CheckMailStatus = () => (
  <Box textAlign="center">
    <img src={`${SETTINGS.STATIC_URL}auth/check-email.svg`} alt="check-email" />
    <Typography component="p">
      If this email address exists in our system, then we will send a password
      reset link.
    </Typography>
  </Box>
);
