import { type KeyboardEvent, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";

import { useAccountType } from "@js/apps/common/hooks";
import {
  isInterviewAvailable,
  useGetLastInterviewQuery,
} from "@js/apps/interview";
import { ModalInstance } from "@js/components/modal";

import { FreelancerScreenedModalContent } from "./freelancer-screened-modal-content";
import { StartScreeningModalContent } from "./start-screening-modal-content";

import styles from "./styles.module.scss";

type OpenBidCreateSuccessModalArg = {
  roleId: number;
  onKeyDown: (ev: KeyboardEvent) => void;
};

export const openBidCreateSuccessModal = ({
  roleId,
  onKeyDown,
}: OpenBidCreateSuccessModalArg) =>
  ModalInstance.open({
    children: (
      <BidSentSuccessfullyModalContent
        closeModal={ModalInstance.close}
        roleId={roleId}
      />
    ),
    closeButton: false,
    className: styles.recommendedJobsModal,
    keepOnBackdropClick: true,
    padding: false,
    onKeyDown: onKeyDown,
  });

type BidSentSuccessfullyModalContentProps = {
  roleId: number;
  closeModal: () => void;
};

const BidSentSuccessfullyModalContent = ({
  closeModal,
  roleId,
}: BidSentSuccessfullyModalContentProps) => {
  const mounted = useRef(false);
  const location = useLocation();
  const { isFreelancerApproved } = useAccountType();
  const { data: interview, isLoading } = useGetLastInterviewQuery(undefined, {
    skip: isFreelancerApproved,
  });
  const showStartScreeningModal = isInterviewAvailable(interview);

  useEffect(() => {
    return () => {
      if (mounted.current) {
        closeModal();
      } else {
        mounted.current = true;
      }
    };
  }, [location, closeModal]);

  if (isLoading) {
    return <></>;
  }

  if (showStartScreeningModal) {
    return <StartScreeningModalContent closeModal={closeModal} />;
  }

  return (
    <FreelancerScreenedModalContent roleId={roleId} closeModal={closeModal} />
  );
};
