import { Button } from "@hexocean/braintrust-ui-components";
import { openProfileCompletionSequenceModal } from "@js/apps/freelancer/components/profile-completion-sequence/profile-completion-sequence";
import { pluralize as pluralizeUtil } from "@js/utils";

export const OpenModalButton = ({
  customLinkName,
  name,
  pluralize,
  number,
  profile,
}) => {
  return (
    <Button
      onClick={() => openProfileCompletionSequenceModal(profile)}
      variant="transparent"
      disableRipple
      style={{
        fontFamily: "inherit",
        color: "inherit",
        fontWeight: "inherit",
        textDecoration: "underline",
        height: "100%",
        verticalAlign: "baseline",
        padding: 0,
        minWidth: 0,
      }}
    >
      {customLinkName || (
        <>
          {name}
          {pluralize && pluralizeUtil(number)}
        </>
      )}
    </Button>
  );
};
