import { useEffect, useState } from "react";
import type { TypedWrappedFieldProps } from "redux-form";
import cs from "classnames";

import { Box, RoundedBox } from "@hexocean/braintrust-ui-components";
import { AttachmentRenameInput, renameAttachment } from "@js/apps/file-upload";
import { StoreResumeCheckboxField } from "@js/apps/jobs/forms/fields/resume/store-resume";
import { useAppSelector } from "@js/hooks";
import { SessionStorage } from "@js/services";
import type { Attachment } from "@js/types/uploads";
import { deepClone } from "@js/utils";

import { ResumeUpload } from "./resume-upload";

const uploadType = ENUMS.UploadType.RESUME_ATTACHMENT_UPLOAD_TYPE;

export type ResumeFieldProps = TypedWrappedFieldProps<{
  id: number;
  attachment: Attachment;
}> & {
  hideCheckbox?: boolean;
  isProfileResumeAdded?: boolean;
  uploaderClassName?: string;
};

export const AddResumeField = ({
  uploaderClassName,
  ...resumeField
}: ResumeFieldProps) => {
  const { hasExistingOrUploadedResume, wasResumeAlreadyUploaded } =
    useAddResumeField(resumeField);

  return (
    <Box className="resume">
      {hasExistingOrUploadedResume ? (
        <ResumeAdded
          resumeField={resumeField}
          wasResumeOnceUploaded={wasResumeAlreadyUploaded}
        />
      ) : (
        <Box className={uploaderClassName}>
          <ResumeUpload
            {...resumeField}
            onUpload={(arg) => {
              resumeField.input.onChange(arg);
            }}
          />
        </Box>
      )}
    </Box>
  );
};

const useAddResumeField = ({
  isProfileResumeAdded,
  input,
}: ResumeFieldProps) => {
  const [wasResumeAlreadyUploaded, setWasResumeAlreadyUploaded] =
    useState(false);

  const hasExistingOrUploadedResume = !!input.value;

  useEffect(() => {
    const wasResumeOnceUploaded =
      SessionStorage.getItem(SessionStorage.keys.TALENT_RESUME_UPLOADED) ||
      undefined;

    if (isProfileResumeAdded || wasResumeOnceUploaded) {
      SessionStorage.setItem(
        SessionStorage.keys.TALENT_RESUME_UPLOADED,
        "true",
      );
      setWasResumeAlreadyUploaded(true);
    }

    if (!isProfileResumeAdded && hasExistingOrUploadedResume) {
      SessionStorage.setItem(
        SessionStorage.keys.TALENT_RESUME_UPLOADED,
        "true",
      );

      setWasResumeAlreadyUploaded((prev) => {
        if (!prev) return prev;
        return true;
      });
    }
  }, [hasExistingOrUploadedResume, isProfileResumeAdded]);

  return {
    wasResumeAlreadyUploaded,
    hasExistingOrUploadedResume,
  };
};

type ResumeAddedProps = {
  resumeField: ResumeFieldProps;
  wasResumeOnceUploaded: boolean;
};

const ResumeAdded = ({
  resumeField,
  wasResumeOnceUploaded,
}: ResumeAddedProps) => {
  const resume = resumeField.input.value;

  const profileResumeId = useAppSelector(
    (state) => state.freelancer.freelancerProfile?.resume?.id,
  );
  if (!resumeField.input.value) return null;

  const isResumeFromProfile = profileResumeId === resumeField.input.value?.id;

  const handleRenameResume = async (name: string) => {
    if (!!name) {
      const newResume = deepClone(resume);
      newResume.attachment.name = name;
      resumeField.input.onChange(newResume);
    }

    return renameAttachment({
      uuid: resume.attachment.id,
      uploadType,
      name,
    });
  };
  const handleRemoveResume = () => {
    resumeField.input.onChange(null);
  };

  const isStoreResumeAvailable =
    !resumeField.hideCheckbox && !isResumeFromProfile;

  return (
    <RoundedBox
      className={cs("resume-added", {
        "resume-added--with-store-resume": isStoreResumeAvailable,
      })}
    >
      <Box className="resume-added__header">
        <AttachmentRenameInput
          value={resume.attachment.name}
          loading={false}
          onRename={handleRenameResume}
          onDelete={handleRemoveResume}
        />
      </Box>
      {isStoreResumeAvailable && (
        <Box ml={2.5}>
          <StoreResumeCheckboxField
            wasResumeOnceUploaded={wasResumeOnceUploaded}
          />
        </Box>
      )}
    </RoundedBox>
  );
};
