import _ from "underscore";

import {
  Button,
  Divider,
  Loader,
  Stack,
} from "@hexocean/braintrust-ui-components";
import { PaymentConfirmationContent } from "@js/apps/invoices/components/employer-listing/payment-confirmation-content";
import { usePayEmployerInvoices } from "@js/apps/invoices/hooks/use-pay-employer-invoices";
import { Modal } from "@js/components/modal";
import { formatCurrency } from "@js/utils";

import { PaymentMethodsContainer } from "../../containers/payment-methods";
import { PayFormComponent } from "../../forms/pay";
import NullStripeFeedback from "../null-stripe-feedback";

import styles from "./style.module.scss";

const PAYMENT_MODAL_ID = "payment-modal";

export const PaymentModalInstance = Modal(PAYMENT_MODAL_ID);

export type PaymentModalContentProps = {
  onInvoicesPaymentFailed: () => void;
};

export const PaymentModalContent = ({
  onInvoicesPaymentFailed,
}: PaymentModalContentProps) => {
  const {
    isLoading,
    errorMessage,
    payRequestError,
    stripe,
    handleSubmitPaymentForm,
    onPayClick,
    amount,
    selectedInvoicesData,
  } = usePayEmployerInvoices({
    onInvoicesPaymentFailed,
    closePaymentModal,
  });

  return (
    <NullStripeFeedback stripe={stripe}>
      <div className={styles.paymentModal}>
        {isLoading && (
          <div className={styles.loader}>
            <Loader centered />
          </div>
        )}
        <PaymentConfirmationContent
          error={payRequestError}
          selectedInvoices={selectedInvoicesData ?? []}
        />
        <Divider sx={{ borderColor: "var(--grey-4)", my: { xs: 1, sm: 2 } }} />
        <PaymentMethodsContainer
          render={({
            fetchingPaymentMethods,
            paymentMethods,
            creatingPaymentMethod,
            addPaymentMethod,
            // canAddPaymentMethod -- no need to use; feedback handled by NullStripeFeedback
          }) => (
            <>
              <PayFormComponent
                fetchingPaymentMethods={fetchingPaymentMethods}
                paymentMethods={paymentMethods}
                onSubmit={handleSubmitPaymentForm}
                initialValues={{
                  paymentMethod: _.findWhere(paymentMethods, {
                    is_default: true,
                  }),
                }}
              />
              {errorMessage && (
                <div className="general-form-error">{errorMessage}</div>
              )}
              <Stack
                direction="row"
                sx={{
                  justifyContent: { xs: "end", sm: "space-between" },
                  gap: 1,
                  flexWrap: "wrap",
                }}
              >
                <Button
                  disabled={creatingPaymentMethod}
                  variant="secondary"
                  onClick={addPaymentMethod}
                >
                  {creatingPaymentMethod ? "Saving..." : "Add Payment Method"}
                </Button>
                <Stack direction="row" sx={{ gap: 1 }}>
                  <Button
                    variant="secondary"
                    onClick={PaymentModalInstance.close}
                  >
                    Cancel
                  </Button>
                  <Button
                    disabled={isLoading}
                    variant="primary"
                    onClick={onPayClick}
                  >
                    {isLoading ? "Paying..." : "Pay"}
                    {typeof amount !== "undefined" &&
                      ` ${formatCurrency(amount)}`}
                  </Button>
                </Stack>
              </Stack>
            </>
          )}
        />
      </div>
    </NullStripeFeedback>
  );
};

type OpenPaymentModalArg = PaymentModalContentProps;

export const openPaymentModal = (paymentModalProps: OpenPaymentModalArg) => {
  PaymentModalInstance.open({
    children: <PaymentModalContent {...paymentModalProps} />,
  });
};

const closePaymentModal = () => {
  PaymentModalInstance.close();
};
