import React, { useMemo } from "react";

import {
  Loader,
  Pagination,
  PaginationWrapper,
} from "@hexocean/braintrust-ui-components";
import { EmployerInvoiceListingContext } from "@js/apps/invoices/context/employer-invoice-listing";
import { useEmployerInvoiceListing } from "@js/apps/invoices/hooks/employer-invoice-listing";
import { RouterLink } from "@js/components/link";
import { useAppDispatch, useAppSelector } from "@js/hooks";
import { AppLayout } from "@js/layouts/app";

import { fetchEmployerInvoiceBatches } from "../../actions";
import { Hero, InvoiceBatchesView } from "../../components";
import { EmployerInvoiceListEmptyState } from "../../components/employer-invoices-empty-state";
import { EmployerInvoicesListingTable } from "../../components/employer-listing";
import { InvoicePreviewModalInstance } from "../../components/invoice-preview";

const EmployerInvoicesListingPage = () => {
  const {
    loading,
    invoiceList,
    invoicesSummary,
    fetchInvoices,
    areFiltersDefault,
    page,
    total,
    selectedInvoicesIds,
    onSelectItem,
    setSelectedInvoicesIds,
    onSelectAcrossAllPagesClick,
    checkIfItemIsSelected,
    toggleSelectCurrentPage,
    resetSelectedInvoicesIds,
  } = useEmployerInvoiceListing();

  const contextValue = useMemo(
    () => ({
      invoiceList,
      totalInvoicesCount: total,
      selectedInvoicesIds,
      onSelectItem,
      toggleSelectCurrentPage,
      setSelectedInvoicesIds,
      onSelectAcrossAllPagesClick,
      checkIfItemIsSelected,
      resetSelectedInvoicesIds,
    }),
    [
      invoiceList,
      selectedInvoicesIds,
      onSelectItem,
      setSelectedInvoicesIds,
      onSelectAcrossAllPagesClick,
      checkIfItemIsSelected,
      toggleSelectCurrentPage,
      resetSelectedInvoicesIds,
      total,
    ],
  );

  const isEmpty = !loading && areFiltersDefault && !invoiceList.length;
  const showLoader = loading && !invoiceList.length;
  const showContent = !loading || !!invoiceList.length;

  return (
    <AppLayout pageTitle="Invoices" flexColumn className="wider">
      <EmployerInvoiceListingContext.Provider value={contextValue}>
        {isEmpty && <EmployerInvoiceListEmptyState />}
        {!isEmpty && (
          <>
            <Hero
              isAnyInvoice={!!invoiceList.length}
              due={invoicesSummary.due_within_30_days}
              overdue={invoicesSummary.overdue}
            />
            <InvoicePreviewModalInstance />
            {showLoader && (
              <div className="posr">
                <Loader centered />
              </div>
            )}
            {showContent && (
              <>
                <EmployerInvoicesListingTable
                  onInvoicesPaid={fetchInvoices}
                  onInvoicesPaymentFailed={fetchInvoices}
                />
                <PaginationWrapper>
                  <Pagination
                    page={page}
                    count={total}
                    perPage={SETTINGS.INVOICE_LISTING_INVOICES_PER_PAGE}
                    RouterLink={RouterLink}
                  />
                </PaginationWrapper>
              </>
            )}
          </>
        )}
      </EmployerInvoiceListingContext.Provider>
    </AppLayout>
  );
};

export const EmployerBatchesPage = () => {
  const dispatch = useAppDispatch();

  React.useEffect(() => {
    dispatch(fetchEmployerInvoiceBatches());
  }, [dispatch]);
  const invoiceBatches = useAppSelector(
    (state) => state.invoices.invoiceBatches,
  );
  const loading = useAppSelector(
    (state) => state.invoices.fetchingInvoiceBatches,
  );

  return (
    <AppLayout pageTitle="Invoices" flexColumn className="wider">
      <InvoiceBatchesView loading={loading} invoiceBatches={invoiceBatches} />
    </AppLayout>
  );
};

export default EmployerInvoicesListingPage;
