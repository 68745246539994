import { useNavigate } from "react-router-dom";
import type { TypedWrappedFieldProps } from "redux-form";

import { Button } from "@hexocean/braintrust-ui-components";
import { deleteJobDraftClicked } from "@js/apps/jobs/actions";
import { useDeleteJobDraftMutation } from "@js/apps/jobs/api";
import { useIsSavingJobDraft } from "@js/apps/jobs/apps/create-job/hooks/use-is-saving-job-draft";
import { useJobFormContext } from "@js/apps/jobs/context/job-form-context/job-form-context";
import { useGetJobFlowEntry } from "@js/apps/jobs/hooks/use-get-job-flow-entry";
import { Snackbar } from "@js/components/snackbar";
import { useAppDispatch } from "@js/hooks";

export const DeleteDraftButton = ({
  input,
}: TypedWrappedFieldProps<boolean>) => {
  const { jobDraft } = useJobFormContext();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [deleteJobDraft] = useDeleteJobDraftMutation();
  const { isSavingJobDraft } = useIsSavingJobDraft();
  const { flow_entry } = useGetJobFlowEntry();

  if (!jobDraft) {
    return null;
  }

  const handleBtnClick = async () => {
    input.onChange(true);
    dispatch(deleteJobDraftClicked({ flow_entry }));
    try {
      await deleteJobDraft({ id: jobDraft.id }).unwrap();
      navigate("/employer/dashboard/my_jobs/");
    } catch (error) {
      Snackbar.error("Failed to delete draft.");
      input.onChange(false);
    }
  };

  const isDeletingJobDraft = !!input.value;
  const disableButton = isSavingJobDraft || isDeletingJobDraft;

  return (
    <Button
      onClick={handleBtnClick}
      type="button"
      variant="primary"
      shape="squared"
      disabled={disableButton}
    >
      Delete Job Draft
    </Button>
  );
};
