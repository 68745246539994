import { useMemo, useState } from "react";

import { useGetRolesQuery } from "@js/apps/roles/api";
import { useSendSpaceInvitationMutation } from "@js/apps/spaces/api";
import type { MemberInviteData } from "@js/apps/spaces/components/invite-to-space-modal/types";
import { ModalInstance } from "@js/components/modal";
import { Snackbar } from "@js/components/snackbar";
import { pluralize, typeGuard } from "@js/utils";

import type { SuggestedSpaceMemberData } from "../../types";

type UseInviteToSpaceModalProps = {
  spaceId: number;
};

const MAX_INVITES = 25;

export const useInviteToSpaceModal = ({
  spaceId,
}: UseInviteToSpaceModalProps) => {
  const [selectedMembers, setSelectedMembers] = useState<MemberInviteData[]>(
    [],
  );
  const [errorMessage, setErrorMessage] = useState<string>("");

  const [sendInvite] = useSendSpaceInvitationMutation();
  const { data: roles } = useGetRolesQuery();

  const selectedMembersWithRoles = useMemo(() => {
    return selectedMembers.map((member) => {
      const role = roles?.find((r) => r.id === member.role_id);
      if (role) {
        return {
          ...member,
          role,
        };
      }
      return member;
    });
  }, [selectedMembers, roles]);

  const resetErrorMessage = () => {
    setErrorMessage("");
  };

  const handleAddMember = (updatedMembers: MemberInviteData[]) => {
    const error = getModalErrorMessage({ selectedMembers: updatedMembers });

    if (error) {
      setErrorMessage(error);
      return;
    }

    setSelectedMembers(updatedMembers);
  };

  const removeMember = (member: MemberInviteData) => {
    resetErrorMessage();
    setSelectedMembers((prev) =>
      prev.filter(
        (prevMember) =>
          prevMember.id !== member.id ||
          prevMember.inputValue !== member.inputValue,
      ),
    );
  };

  const getSelectedMembersEmails = () => {
    return selectedMembers.reduce<string[]>((acc, member) => {
      if (member.inputValue) {
        acc.push(member.inputValue);
      }

      return acc;
    }, []);
  };

  const getSelectedUsers = () => {
    return selectedMembers
      .filter((member): member is SuggestedSpaceMemberData =>
        Boolean(member.id),
      )
      .map((member) => {
        return {
          id: member.id,
          public_name: member.public_name,
        };
      });
  };

  const handleInvite = async () => {
    try {
      await sendInvite({
        emails: getSelectedMembersEmails(),
        spaceId,
        users: getSelectedUsers(),
      }).unwrap();

      Snackbar.success(`Invite${pluralize(selectedMembers.length)} sent.`);
      ModalInstance.close();
    } catch (e: unknown) {
      if (e && isEmailsFieldErrorWithMessage(e)) {
        Snackbar.error(e.data.emails._error);
      } else {
        if (
          typeGuard<unknown, { data: object }>(e, "data") &&
          typeGuard<unknown, { _error: string }>(e.data, "_error")
        ) {
          Snackbar.error(e.data?._error);
        }
      }
    }
  };

  const isInviteDisabled = !selectedMembers.length;

  return {
    handleAddMember,
    removeMember,
    handleInvite,
    isInviteDisabled,
    selectedMembers: selectedMembersWithRoles,
    errorMessage,
  };
};

export const isEmailsFieldErrorWithMessage = (
  error,
): error is {
  data: {
    emails: {
      _error: string[];
    };
  };
} => {
  return error && error?.data?.emails?._error;
};

export const tooManySpacesMemberInvitesErrorMessage = `You can send ${MAX_INVITES} invite${pluralize(
  MAX_INVITES,
  { zeroPlural: true },
)} at a time.`;

const getModalErrorMessage = ({
  selectedMembers,
}: {
  selectedMembers: MemberInviteData[];
}) => {
  if (selectedMembers.length > MAX_INVITES) {
    return tooManySpacesMemberInvitesErrorMessage;
  }

  return "";
};
