import { ModalInstance } from "@js/components/modal";

import { ApplicationBoostModal } from "./boost-modal-content";

export const openBoostModal = async () => {
  ModalInstance.open({
    padding: false,
    closeButton: true,
    keepOnBackdropClick: true,
    closeButtonProps: {
      style: {
        backgroundColor: "rgba(var(--black-rgb), 0.04)",
      },
    },

    children: <ApplicationBoostModal />,
  });
};
