import { Box, Typography } from "@hexocean/braintrust-ui-components";
import { Modal } from "@js/components/modal";

export const LoadingModalInstance = Modal("generate-ai-loading-modal", {
  className: "generate-ai-loading-modal",
  closeButton: false,
  keepOnBackdropClick: true,
});

export const LoadingModal = () => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: "24px",
      }}
    >
      <Box
        sx={{
          position: "relative",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "86px",
          width: "86px",
        }}
      >
        <img
          src={`${SETTINGS.STATIC_URL}jobs/ai-job-loader.png`}
          className="generate-ai-loader"
          alt="loader"
        />
        <img
          src={`${SETTINGS.STATIC_URL}jobs/ai-job-icon-star.svg`}
          height="34px"
          width="34px"
          alt="loader icon"
        />
      </Box>

      <Typography component="span" mt={2} fontWeight={500} size="large">
        Infusing AI magic into your job description...
      </Typography>
    </Box>
  );
};

export const openLoadingModal = () =>
  LoadingModalInstance.open({
    onClose: () => LoadingModalInstance.close(),
    children: <LoadingModal />,
  });
