import React from "react";
import cs from "classnames";

import { Box } from "@hexocean/braintrust-ui-components";
import {
  ClearIcon,
  EditPencilIcon,
} from "@hexocean/braintrust-ui-components/Icons";

type AttachmentActionsWrapperProps = {
  onRemoveClick: (() => void) | undefined;
  onEditLinkClick?: () => void;
  closeButtonAlwaysVisible?: boolean;
  showBorder?: boolean;
  roundedBorder?: boolean;
  isFromText?: boolean;
};

export const AttachmentActionsWrapper: React.FC<
  React.PropsWithChildren<AttachmentActionsWrapperProps>
> = ({
  onRemoveClick,
  onEditLinkClick,
  closeButtonAlwaysVisible,
  showBorder,
  roundedBorder,
  children,
  isFromText,
}) => {
  const showClearIcon = !!onRemoveClick;
  return (
    <Box
      className={cs("attachment-actions--wrapper", {
        "attachment-actions--wrapper-with-border": showBorder,
        "attachment-actions--wrapper-with-border-rounded": roundedBorder,
        "attachment-actions--edit-link-mode": onEditLinkClick && !isFromText,
      })}
    >
      {children}
      {showClearIcon && (
        <ClearIcon
          onClick={onRemoveClick}
          data-testid="remove-attachment-button"
          className={cs("attachment-actions__close-icon", {
            "attachment-actions__close-icon--always-visible":
              closeButtonAlwaysVisible,
          })}
        />
      )}

      {onEditLinkClick && !isFromText && (
        <EditPencilIcon
          onClick={onEditLinkClick}
          className="attachment-actions__edit-icon"
        />
      )}
    </Box>
  );
};
