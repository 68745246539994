import type { TalentMixedLocation } from "@js/apps/freelancer/types";

export const isLocationOptionOnTheList = (
  locations: TalentMixedLocation[],
  option: TalentMixedLocation,
) => {
  return (
    Array.isArray(locations) &&
    !!locations.find(
      (loc) => loc.custom_location === option.id || loc.id === option.id,
    )
  );
};
