import { useCallback, useEffect } from "react";
import { matchPath, useLocation, useNavigate } from "react-router-dom";

import { useAccountType, useUser } from "@js/apps/common/hooks";

import {
  BLOCKED_EMPLOYER_NAVIGATE_TO_FROM_RESTRICTED_ROUTE,
  getIndexPageRedirect,
  getRoutesAvailableForBlockedEmployer,
  getRoutesAvailableForUnverifiedFreelancer,
  UNVERIFIED_FREELANCER_NAVIGATE_TO_FROM_RESTRICTED_ROUTE,
} from "./utils";

export const useAppRedirects = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const user = useUser();
  const { isEmployer, isFreelancer } = useAccountType();

  const handleRedirectFreelancer = useCallback(() => {
    // New sign up flow, if user is freelance with unverified email has access only to following pages
    const routesAvailableForUnverifiedFreelancer =
      getRoutesAvailableForUnverifiedFreelancer(user?.freelancer);
    const isLocationAvailabile = routesAvailableForUnverifiedFreelancer.some(
      (path) => matchPath(path, location.pathname),
    );

    if (!!user?.id && !user.is_verified && !isLocationAvailabile) {
      navigate(UNVERIFIED_FREELANCER_NAVIGATE_TO_FROM_RESTRICTED_ROUTE, {
        replace: true,
      });

      return;
    }

    if (location.pathname === "/") {
      navigate(
        getIndexPageRedirect(
          user?.id,
          ENUMS.AccountType.FREELANCER,
          location.search,
        ),
        { replace: true },
      );

      return;
    }
  }, [location, navigate, user?.id, user?.freelancer, user?.is_verified]);

  const handleRedirectEmployer = useCallback(() => {
    //handle blocking state for employer account when account will be suspicious
    const routesAvailableForBlockedEmployer =
      getRoutesAvailableForBlockedEmployer(user?.employer);
    const isLocationAvailabile = routesAvailableForBlockedEmployer.some(
      (path) => matchPath(path, location.pathname),
    );

    if (!!user?.id && user.is_blocked && !isLocationAvailabile) {
      navigate(BLOCKED_EMPLOYER_NAVIGATE_TO_FROM_RESTRICTED_ROUTE, {
        replace: true,
      });

      return;
    }

    if (location.pathname === "/") {
      navigate(
        getIndexPageRedirect(
          user?.id,
          ENUMS.AccountType.EMPLOYER,
          location.search,
        ),
        { replace: true },
      );

      return;
    }
  }, [location, navigate, user?.id, user?.employer, user?.is_blocked]);

  const handleRedirectNoneAccountType = useCallback(() => {
    if (location.pathname === "/") {
      navigate(getIndexPageRedirect(user?.id, undefined, location.search), {
        replace: true,
      });

      return;
    }
  }, [location, navigate, user?.id]);

  useEffect(() => {
    if (isFreelancer) {
      handleRedirectFreelancer();

      return;
    }

    if (isEmployer) {
      handleRedirectEmployer();

      return;
    }

    handleRedirectNoneAccountType();
  }, [
    isFreelancer,
    isEmployer,
    handleRedirectFreelancer,
    handleRedirectEmployer,
    handleRedirectNoneAccountType,
  ]);
};
