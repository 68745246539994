import type { CSSProperties, ReactNode, RefObject } from "react";
import React, { useState } from "react";
import { Virtuoso } from "react-virtuoso";

import { Box, List, Loader, Stack } from "@hexocean/braintrust-ui-components";
import type { MessageRoom } from "@js/types/messenger";

import { roomListItemContent } from "./list-item";

type RoomListItemsProps = {
  roomList: MessageRoom[];
  activeRoom: number | undefined;
  onChangeRoom: (roomId?: number) => void;
  hasMore: boolean;
  fetchNextRooms: () => void;
  fetchingRooms: boolean;
  isSearching: boolean;
};

const ROOM_LIST_ITEM_HEIGHT = 85;
const AT_BOTTOM_THRESHOLD = 5 * ROOM_LIST_ITEM_HEIGHT;
const INCREASE_VIEWPORT_BY = 3 * ROOM_LIST_ITEM_HEIGHT;

export const RoomListItems = ({
  roomList,
  activeRoom,
  onChangeRoom,
  hasMore,
  fetchingRooms,
  fetchNextRooms,
  isSearching,
}: RoomListItemsProps) => {
  const [initialTopMostItemIndex] = useState(() => {
    if (!!isSearching) {
      return 0;
    }
    const selectedRoomIndex = activeRoom
      ? roomList.findIndex((room) => room.id === activeRoom)
      : undefined;
    return selectedRoomIndex ?? 0;
  });

  return (
    <Stack sx={{ height: "100%" }}>
      <Virtuoso
        context={{ fetchingRooms }}
        totalCount={roomList.length}
        defaultItemHeight={ROOM_LIST_ITEM_HEIGHT}
        components={Components}
        initialTopMostItemIndex={initialTopMostItemIndex}
        atBottomThreshold={AT_BOTTOM_THRESHOLD}
        increaseViewportBy={INCREASE_VIEWPORT_BY}
        atBottomStateChange={(atBottom) => {
          // use atBottomStateChange instead of endReached to use customized atBottomThreshold
          if (!atBottom || !hasMore || fetchingRooms) {
            return;
          }

          fetchNextRooms();
        }}
        itemContent={(index) => {
          const room = roomList[index];

          return roomListItemContent({
            room,
            isSelectedRoom: room.id === activeRoom,
            changeRoom: onChangeRoom,
          });
        }}
      />
    </Stack>
  );
};

const RoomListLoader = () => {
  return (
    <Box
      display="grid"
      justifyContent="center"
      alignContent="center"
      width="100%"
      minHeight={ROOM_LIST_ITEM_HEIGHT}
    >
      <Loader />
    </Box>
  );
};

const Components = {
  List: React.forwardRef<
    HTMLDivElement,
    {
      style?: CSSProperties;
      children?: ReactNode;
      context?: { fetchingRooms: boolean };
    }
  >(({ style, children, context }, listRef) => {
    return (
      <List
        sx={{ gap: 2 }}
        style={style}
        ref={listRef as RefObject<HTMLUListElement>}
        className="room-list__list"
        disablePadding
      >
        {children}
        {!!context?.fetchingRooms && <RoomListLoader />}
      </List>
    );
  }),
};
