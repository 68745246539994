import { SubmissionError } from "redux-form";
import axios from "axios";

import { Snackbar } from "@js/components/snackbar";
import type { AppThunkAction } from "@js/store";
import type { FreelancerInvoice } from "@js/types/invoices";
import { serializeArrayParams } from "@js/utils";

import * as actionTypes from "./action-types";

export const createFreelancerInvoice =
  (values): AppThunkAction<Promise<any>> =>
  () =>
    new Promise((resolve, reject) =>
      axios
        .post(`/api/freelancer_invoices/`, values)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => reject(new SubmissionError(error.response.data))),
    );

export const editFreelancerInvoice =
  (id, values): AppThunkAction<Promise<any>> =>
  () =>
    new Promise((resolve, reject) =>
      axios
        .put(`/api/freelancer_invoices/${id}/`, values)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => reject(new SubmissionError(error.response.data))),
    );

export const lockInvoice = (
  id: number,
): Promise<{ payment_blocked_until: string }> =>
  new Promise((resolve, reject) =>
    axios
      .post(`/api/freelancer_invoices/${id}/block_payment_for_editing/`, {})
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => reject(new SubmissionError(error.response.data))),
  );

export const fetchFreelancerInvoicesSummary =
  (): AppThunkAction<Promise<any>> => (dispatch) =>
    new Promise((resolve) => {
      return axios
        .get(`/api/invoice_statistics/freelancer/`)
        .then((response) => {
          dispatch({
            type: actionTypes.FETCH_FREELANCER_INVOICES_SUMMARY_SUCCESS,
            payload: response.data,
          });

          resolve(response.data);
        })
        .catch(() =>
          dispatch({
            type: actionTypes.FETCH_FREELANCER_INVOICES_SUMMARY_FAILED,
          }),
        );
    });

export const fetchFreelancerInvoices =
  (params): AppThunkAction<Promise<any>> =>
  (dispatch) =>
    new Promise((resolve) => {
      dispatch({
        type: actionTypes.FETCH_FREELANCER_INVOICES,
      });

      return axios
        .get(`/api/freelancer_invoices/`, { params })
        .then((response) => {
          dispatch({
            type: actionTypes.FETCH_FREELANCER_INVOICES_SUCCESS,
            payload: response.data,
          });

          resolve(response.data);
        })
        .catch(() =>
          dispatch({
            type: actionTypes.FETCH_FREELANCER_INVOICES_FAILED,
          }),
        );
    });

const genericFetchEmployerInvoices =
  (fetchActionType, fetchSuccessActionType, fetchFailedActionType) =>
  (params): AppThunkAction<Promise<any>> =>
  (dispatch) =>
    new Promise((resolve) => {
      dispatch({
        type: fetchActionType,
      });

      return axios
        .get(`/api/employer_invoices/`, { params })
        .then((response) => {
          dispatch({
            type: fetchSuccessActionType,
            payload: response.data,
          });

          resolve(response.data);
        })
        .catch(() =>
          dispatch({
            type: fetchFailedActionType,
          }),
        );
    });

export const fetchEmployerInvoices = genericFetchEmployerInvoices(
  actionTypes.FETCH_EMPLOYER_INVOICES,
  actionTypes.FETCH_EMPLOYER_INVOICES_SUCCESS,
  actionTypes.FETCH_EMPLOYER_INVOICES_FAILED,
);

export const fetchDependentEmployerInvoices = genericFetchEmployerInvoices(
  actionTypes.FETCH_DEPENDENT_EMPLOYER_INVOICES,
  actionTypes.FETCH_DEPENDENT_EMPLOYER_INVOICES_SUCCESS,
  actionTypes.FETCH_DEPENDENT_EMPLOYER_INVOICES_FAILED,
);

export const fetchTotalIncludingCCPaymentFee =
  (invoicesIds): AppThunkAction<Promise<any>> =>
  (dispatch) =>
    new Promise((resolve, reject) => {
      dispatch({
        type: actionTypes.FETCH_TOTAL_INCLUDING_CC_PAYMENT_FEE,
      });

      return axios
        .post("/api/employer_invoices/calculate_credit_card_fee/", {
          invoices: invoicesIds,
        })
        .then((response) => {
          dispatch({
            type: actionTypes.FETCH_TOTAL_INCLUDING_CC_PAYMENT_FEE_SUCCESS,
            payload: response.data,
          });

          resolve(response.data);
        })
        .catch((error) => {
          dispatch({
            type: actionTypes.FETCH_TOTAL_INCLUDING_CC_PAYMENT_FEE_FAILED,
          });

          reject(error.response.data);
        });
    });

export const fetchEmployerInvoicesSummary =
  (): AppThunkAction<Promise<any>> => (dispatch) =>
    new Promise((resolve) => {
      return axios
        .get(`/api/invoice_statistics/employer/`)
        .then((response) => {
          dispatch({
            type: actionTypes.FETCH_EMPLOYER_INVOICES_SUMMARY_SUCCESS,
            payload: response.data,
          });

          resolve(response.data);
        })
        .catch(() =>
          dispatch({
            type: actionTypes.FETCH_EMPLOYER_INVOICES_SUMMARY_FAILED,
          }),
        );
    });

export const markEmployerInvoicesAsPaid = (invoicesIds: number[]) =>
  new Promise((resolve, reject) =>
    axios
      .post(`/api/employer_invoices/mark_as_paid/`, { invoices: invoicesIds })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => reject(error.response.data)),
  );

export const markFreelancerInvoicesAsPaid = (invoicesIds: number[]) =>
  new Promise((resolve, reject) =>
    axios
      .post(`/api/freelancer_invoices/mark_as_paid/`, { invoices: invoicesIds })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => reject(error.response.data)),
  );

export const cancelFreelancerInvoice =
  (invoiceId: FreelancerInvoice["id"]): AppThunkAction<Promise<any>> =>
  () =>
    new Promise((resolve, reject) =>
      axios
        .post(`/api/freelancer_invoices/${invoiceId}/cancel/`)
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => reject(error.response.data)),
    );

export const fetchEmployerInvoiceBatches =
  (): AppThunkAction<Promise<any>> => (dispatch) =>
    new Promise((resolve) => {
      dispatch({
        type: actionTypes.FETCH_INVOICE_BATCHES,
      });

      return axios
        .get(`/api/employer_batch_invoices/`)
        .then((response) => {
          dispatch({
            type: actionTypes.FETCH_INVOICE_BATCHES_SUCCESS,
            payload: response.data,
          });

          resolve(response.data);
        })
        .catch(() =>
          dispatch({
            type: actionTypes.FETCH_INVOICE_BATCHES_FAILED,
          }),
        );
    });

export const fetchFreelancerLastInvoice =
  (): AppThunkAction<Promise<any>> => (dispatch) =>
    new Promise((resolve) => {
      dispatch({
        type: actionTypes.FETCH_FREELANCER_LAST_INVOICE,
      });

      return axios
        .get("/api/freelancer_invoices/?ordering=-created&page=1")
        .then((response) => {
          const data = response.data;
          let lastInvoice = null;

          if (data.results && data.results?.length > 0) {
            lastInvoice = data.results[0];
          }

          dispatch({
            type: actionTypes.FETCH_FREELANCER_LAST_INVOICE_SUCCESS,
            payload: lastInvoice,
          });
          resolve(lastInvoice);
        })
        .catch(() =>
          dispatch({
            type: actionTypes.FETCH_FREELANCER_LAST_INVOICE_FAILED,
          }),
        );
    });

export const downloadInvoicesPDF = (params?: { ids: number[] }) =>
  axios
    .get("/api/employer_invoices/download_zip/", {
      responseType: "blob",
      params,
      paramsSerializer: serializeArrayParams,
    })
    .then((response) => {
      const downloadUrl = window.URL.createObjectURL(response.data);
      const link = document.createElement("a");
      link.href = downloadUrl;
      link.setAttribute("download", "invoices.zip");
      document.body.appendChild(link);
      link.click();
      link.remove();
    })
    .catch(() =>
      Snackbar.error(
        "Please, narrow your search criteria to enable PDF download.",
      ),
    );
