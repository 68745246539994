export { TALENT_STATUS } from "../../constants";

export const FREELANCER_TITLES = {
  NOT_ACCEPTING_PROPOSALS:
    "This job is not accepting applications, but plenty of jobs are still hiring 👉",
  YOU_WERE_NOT_HIRED:
    "You weren’t hired for this one, but more jobs are waiting to meet you 👀 👉",
  YOU_GOT_THE_OFFER: "You received an offer for this job! Nice work! 🎉 💪",
  YOU_GOT_THE_JOB: "You got the job! Nice work 🙌 💐",
  YOU_ARE_IN_SCREENING: "You’re in screening for this job. Good luck 🎉 💪",
  YOU_APPLIED: "You applied to this job. Good luck 🎉 💪",
  YOU_FINISHED_THE_JOB: "You finished the job! Nice work 🙌 💐",
  YOU_APPLIED_BUT_ON_HOLD:
    "You applied to this job, but it’s currently paused. Plenty of job are still hiring 👉",
  APPLICATION_IN_REVIEW: "Your application is being reviewed.",
} as const;
