import { INVOICE_LISTING_FILTERS_OPTIONS } from "../../constants";
import type { InvoiceFiltersFetchParams } from "../../filters";

import { InvoiceListingFiltersItem } from "./invoice-listing-filters-item";

import styles from "./styles.module.scss";

export type InvoiceListingFiltersProps = {
  filters: Partial<InvoiceFiltersFetchParams>;
  pathname: string;
};

const filterList = INVOICE_LISTING_FILTERS_OPTIONS.filter(
  (item) => !("invoice_batches" in item.query),
);

export const InvoiceListingFilters = ({
  filters,
  pathname,
}: InvoiceListingFiltersProps) => {
  return (
    <div className={styles.filters}>
      {filterList.map((option) => {
        return (
          <InvoiceListingFiltersItem
            key={option.name}
            filters={filters}
            pathname={pathname}
            option={option}
          />
        );
      })}
    </div>
  );
};
