import { useCallback, useMemo, useRef } from "react";
import { useNavigate } from "react-router-dom";
import type { FormErrors } from "redux-form";
import { isInvalid, SubmissionError } from "redux-form";

import { API } from "@js/api";
import { useAccountType, useUser } from "@js/apps/common/hooks";
import { freddyWidgetManager } from "@js/apps/common/services/freddy-feedback";
import { setCurrentPostedATSJobId } from "@js/apps/employer/components/ats-integration-modal/ats-jobs-slice";
import { openReviewsDraftsJobModal } from "@js/apps/employer/components/review-drafts-modal/modal-content";
import { useGetJobDraftQuery } from "@js/apps/jobs/api";
import {
  closeSummarizeJobModal,
  openSummarizeJobModal,
} from "@js/apps/jobs/apps/create-job/components/summarize-module";
import type { JobFormSubmitType } from "@js/apps/jobs/context/job-form-context/job-form-context";
import { JOB_FORM_SUBMIT_TYPE } from "@js/apps/jobs/context/job-form-context/job-form-context";
import { useCanManageJobsOnBehalfOfClient } from "@js/apps/jobs/hooks";
import { Snackbar } from "@js/components/snackbar";
import { useAppDispatch, useAppSelector, useQueryParams } from "@js/hooks";
import { useIdParam } from "@js/hooks/use-id-param";
import type { Job, JobDraft, JobFormValues } from "@js/types/jobs";
import { assertUnreachable, isNumeric } from "@js/utils";

import { createJob } from "../../actions";
import { CREATE_JOB_FORM_ID } from "../../constants";
import type { GetDraftInitialValuesArg } from "../../utils";
import {
  getCreateJobInitialValues,
  getDraftInitialValues,
  getDraftInitialValuesOnBehalfOfAClient,
  prepareCreateJobSubmitFormValues,
  prepareJobDraftFormValues,
  prepareLatestDraftDataToSave,
} from "../../utils";
import { jobEndDateValidator, jobStartDateValidator } from "../../validators";
import { useFetchJobToEdit } from "../fetch-job-to-edit";
import { useHandleFailedSubmission } from "../handle-failed-submission";
import { useCreateOrUpdateJobDraft } from "../use-create-or-update-job-draft";
import { useSaveLastJobDraftOnChange } from "../use-save-last-job-draft-on-change";

export const useCreateJob = () => {
  // proper submit type should be set when user clicks on submit button
  const submitType = useRef<JobFormSubmitType>();
  const updateSubmitType = useCallback((type: JobFormSubmitType) => {
    submitType.current = type;
  }, []);
  const user = useUser();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { isEmployer } = useAccountType();
  const draftId = useIdParam();
  const { back_to_review_modal, copy_draft_id, copy_job_id } = useQueryParams();
  const shouldBackToReviewModal = back_to_review_modal === "true";
  const copyDraftId = copy_draft_id ? Number(copy_draft_id) : undefined;
  const copyJobId = copy_job_id ? Number(copy_job_id) : undefined;
  const draftIdToFetch = draftId || copyDraftId;

  const { data: draft, isFetching: isFetchingJobDraft } = useGetJobDraftQuery(
    { id: draftIdToFetch as number },
    { skip: !draftIdToFetch, refetchOnMountOrArgChange: true },
  );
  const uploadedJobDescription = useAppSelector(
    (state) => state.jobs.uploadedJobDescription,
  );
  const aiGenerated = useAppSelector((state) => state.jobs.aiGenerated);

  const userId = user?.id;

  const jobToCopy = useAppSelector((state) => state.jobs.jobToEdit);
  const canManageJobAsCoreMember = useCanManageJobsOnBehalfOfClient();
  const onSubmitFail = useHandleFailedSubmission(draftId);

  const { createOrUpdateJobDraft } = useCreateOrUpdateJobDraft();
  const isFormInvalid = useAppSelector(isInvalid(CREATE_JOB_FORM_ID));

  const formStateRef = useRef<{
    invalid: boolean;
    lastSavedDraft: JobDraft | undefined;
    wasDirty: boolean;
  }>({
    invalid: isFormInvalid,
    lastSavedDraft: draft,
    wasDirty: false,
  });
  formStateRef.current.invalid = isFormInvalid;
  formStateRef.current.lastSavedDraft = draft;

  const { onChange } = useSaveLastJobDraftOnChange({ formStateRef });

  const invalidateDrafts = useCallback(() => {
    dispatch(API.util.invalidateTags([{ type: "JobDrafts", id: "LIST" }]));
  }, [dispatch]);

  useFetchJobToEdit(copyJobId);

  const handleJobSubmit = useCallback(
    async (values: JobFormValues) => {
      const shouldCreateDraft = !user?.is_verified;

      const data = prepareCreateJobSubmitFormValues(values);
      if (!shouldCreateDraft) {
        return dispatch(
          createJob({
            data,
            dry_run: false,
            public_after_verification: false,
          }),
        );
      }
      const draftDataToSave = prepareLatestDraftDataToSave(
        values,
        formStateRef.current.lastSavedDraft,
      );
      if (draftDataToSave) {
        await createOrUpdateJobDraft({
          data: draftDataToSave,
        });
      }

      return dispatch(
        createJob({
          data,
          dry_run: true,
          public_after_verification: true,
        }),
      );
    },
    [createOrUpdateJobDraft, dispatch, user?.is_verified],
  );

  const onSubmit = useCallback(
    async (values) => {
      switch (submitType.current) {
        case JOB_FORM_SUBMIT_TYPE.draft_set_up:
        case JOB_FORM_SUBMIT_TYPE.ats_job_draft_set_up:
        case JOB_FORM_SUBMIT_TYPE.draft: {
          const preparedValues = prepareJobDraftFormValues(
            values as JobFormValues,
          );

          return createOrUpdateJobDraft({
            data: preparedValues,
          });
        }

        case JOB_FORM_SUBMIT_TYPE.job_copy_draft_set_up: {
          if (!isNumeric(copyJobId)) {
            throw new SubmissionError({
              _error: `Missing or invalid job id to copy! job id: ${copyJobId}`,
            });
          }

          const preparedValues = prepareJobDraftFormValues(values);
          const original_job = Number(copyJobId);

          return createOrUpdateJobDraft({
            data: {
              ...preparedValues,
              locations: preparedValues.locations ?? [],
            },
            original_job,
          });
        }

        case JOB_FORM_SUBMIT_TYPE.job_dry_run: {
          const data = prepareCreateJobSubmitFormValues(values);

          return dispatch(
            createJob({
              dry_run: true,
              data: data,
              public_after_verification: false,
            }),
          );
        }

        case JOB_FORM_SUBMIT_TYPE.job: {
          return handleJobSubmit(values);
        }

        case undefined: {
          break;
        }

        default:
          assertUnreachable(submitType.current);
      }
    },
    [dispatch, copyJobId, handleJobSubmit, createOrUpdateJobDraft],
  );

  const onSubmitSuccess = useCallback(
    (
      response: Job | JobFormValues | Pick<Job, "id"> | undefined,
      _dispatch: unknown,
      props,
    ) => {
      if (!userId) {
        return;
      }

      switch (submitType.current) {
        case JOB_FORM_SUBMIT_TYPE.draft_set_up:
        case JOB_FORM_SUBMIT_TYPE.job_copy_draft_set_up: {
          if (canManageJobAsCoreMember) {
            props.initialize(
              getDraftInitialValuesOnBehalfOfAClient({
                draft: (response ?? {}) as GetDraftInitialValuesArg["draft"],
                userId,
                isEmployer,
              }),
            );
          } else {
            props.initialize(
              getDraftInitialValues({
                draft: (response ?? {}) as GetDraftInitialValuesArg["draft"],
                userId,
              }),
            );
          }

          navigate(`/jobs/add_new/${response?.id}/`);
          invalidateDrafts();
          break;
        }
        case JOB_FORM_SUBMIT_TYPE.draft: {
          const newDraftHasBeenCreated = draftId !== response?.id;
          if (newDraftHasBeenCreated) {
            navigate(`/jobs/add_new/${response?.id}/`);
            invalidateDrafts();
          }
          break;
        }

        case JOB_FORM_SUBMIT_TYPE.ats_job_draft_set_up: {
          navigate(`/jobs/add_new/${response?.id}/?back_to_review_modal=true`);
          invalidateDrafts();
          break;
        }
        case JOB_FORM_SUBMIT_TYPE.job_dry_run: {
          openSummarizeJobModal();
          break;
        }
        case JOB_FORM_SUBMIT_TYPE.job: {
          closeSummarizeJobModal();
          if (!user?.is_verified) {
            navigate("/employer/dashboard/my_jobs/");
            Snackbar.toast({
              header: "Your account is not verified",
              buttonText: "Got it",
              content: `Your job offer has been saved as a draft, to make it public,
                  verify your email address by clicking the verification link in
                  the email sent to the address you provided during
                  registration.`,
            });
            break;
          }
          invalidateDrafts();
          navigate(`/jobs/${response?.id}/invite_talent/`);
          if (shouldBackToReviewModal) {
            dispatch(setCurrentPostedATSJobId(response?.id));
            openReviewsDraftsJobModal();
          } else {
            // Displays Freddy Feedback after job creation when user is not in flow with ats job.
            freddyWidgetManager.showFreddyWidget(
              SETTINGS.FREDDY_FEEDBACK_JOB_WIDGET_ID,
            );
          }

          break;
        }

        case undefined: {
          break;
        }

        default:
          assertUnreachable(submitType.current);
      }
    },
    [
      canManageJobAsCoreMember,
      dispatch,
      draftId,
      invalidateDrafts,
      isEmployer,
      navigate,
      shouldBackToReviewModal,
      user?.is_verified,
      userId,
    ],
  );

  const initialValues = useMemo(() => {
    return getCreateJobInitialValues({
      userId,
      draft,
      jobToCopy,
      canManageJobAsCoreMember,
      aiGenerated,
      copyDraftId,
      isEmployer,
      uploadedJobDescription,
    });
  }, [
    userId,
    draft,
    jobToCopy,
    copyDraftId,
    canManageJobAsCoreMember,
    uploadedJobDescription,
    aiGenerated,
    isEmployer,
  ]);

  // we have to show loader whenever draft is being fetched to remount the form and reinitialize fields
  const loading = isFetchingJobDraft || (!!copyJobId && !jobToCopy);

  return {
    onSubmit,
    onSubmitSuccess,
    onSubmitFail,
    initialValues,
    loading,
    updateSubmitType,
    lastSavedDraft: draft,
    // we don't want to save onChange in set_up step
    onChange: draftId ? onChange : undefined,
    validate,
  };
};

const validate = (values: JobDraft) => {
  const errors: FormErrors<JobDraft> = {};

  errors["start_date"] = jobStartDateValidator(values.start_date);
  errors["deadline"] = jobEndDateValidator(values.deadline, values.start_date);

  return errors;
};
