import type { TalentMixedLocation } from "@js/apps/freelancer/types";
import type { TypedWrappedFieldProps } from "@js/forms/utils";

type IsLocationDuplicated = {
  (parameters: {
    locationData: TalentMixedLocation;
    placeIdField: TypedWrappedFieldProps<string[] | null>;
    customLocationField: TypedWrappedFieldProps<string[] | null>;
  }): boolean;
};

const isLocationDuplicated: IsLocationDuplicated = ({
  locationData,
  placeIdField,
  customLocationField,
}) => {
  if (
    locationData?.place_id &&
    placeIdField.input.value?.includes(locationData?.place_id)
  ) {
    return true;
  }

  if (
    locationData?.id &&
    customLocationField.input.value?.includes(locationData?.id)
  ) {
    return true;
  }

  if (
    locationData?.custom_location &&
    customLocationField.input.value?.includes(locationData?.custom_location)
  ) {
    return true;
  }

  return false;
};

export { isLocationDuplicated };
