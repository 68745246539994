import { BID_TYPES } from "@js/apps/common/constants";
import { useGetFreelancerBidsQuery } from "@js/apps/freelancer/api";

export type BidType = EnumType<typeof BID_TYPES>;

export const useFreelancerBids = (bidTypeToFetch: BidType) => {
  const { isLoading, data: bids } = useGetFreelancerBidsQuery({
    current: bidTypeToFetch === BID_TYPES.CURRENT,
    historical: bidTypeToFetch === BID_TYPES.HISTORICAL,
  });

  return {
    bids,
    isLoading,
  };
};
