import React from "react";

import {
  Box,
  Button,
  IconButton,
  Typography,
} from "@hexocean/braintrust-ui-components";
import {
  ArrowRightIcon,
  CloseIcon,
} from "@hexocean/braintrust-ui-components/Icons";
import { openInsightPurchaseModal } from "@js/apps/jobs/components/application-insight-purchase-modal";

type JobInsightsCardProps = {
  onClose: () => void;
};

const JobInsightsCard: React.FC<JobInsightsCardProps> = ({ onClose }) => {
  return (
    <Box
      width="362px"
      height="344px"
      overflow="unset"
      sx={{ background: "var(--white)" }}
    >
      <Box sx={{ background: "var(--medium-green)" }} height="109px">
        <img
          src={`${SETTINGS.STATIC_URL}jobs/job-insight-card.svg`}
          height="100%"
          width="100%"
          alt="job-insight-card"
        />
      </Box>
      <Box paddingX={3} paddingY={2}>
        <Typography component="p" size="large" mb={0.5}>
          Maximize your job search success
        </Typography>
        <Typography component="p" size="small">
          Get tailored Application Insights to identify promising jobs, enhance
          your application, and more.
        </Typography>
        <Box mt={2} textAlign="end">
          <Button
            variant="positive-2"
            shape="squared"
            size="small"
            endIcon={<ArrowRightIcon />}
            onClick={openInsightPurchaseModal}
          >
            Get Application Insights
          </Button>
        </Box>
      </Box>
      <IconButton
        sx={{
          position: "absolute",
          top: 2,
          right: 2,
        }}
        variant="transparent"
        size="x-small"
        aria-label="Close Job insight card"
        onClick={onClose}
      >
        <CloseIcon />
      </IconButton>
    </Box>
  );
};

export default JobInsightsCard;
