import { API } from "@js/api";
import type { PaginatedResult } from "@js/types/generic";

export type EmployerSearchMinimalResult = {
  id: number;
  name: string;
};

export type EmployerSearchResult = {
  name_color: string | null;
  link: string;
  full_link: string;
  has_logo_set: boolean;
  logo_background_color: string | null;
  total_jobs: number | null;
  logo: string;
  logo_thumbnail: string;
} & EmployerSearchMinimalResult;

export type UserSearchResult = {
  id: number;
  name_and_email: string;
};

export type UserSearchParams = {
  search?: string;
  return_ops_assigned_to_jobs_only?: boolean;
  hiring_manager?: boolean;
  page_size?: number;
  for_employer?: number;
  invoice_action_performer?: boolean;
  return_sales_only?: boolean;
  return_ops_only?: boolean;
};

export type TalentSearchMinimalResult = {
  id: number;
  name: string;
};

export type TalentSearchParams = {
  search?: string;
  public_name_search?: boolean;
};

export type BalanceHoldersSearchResult = {
  id: number;
  name: string;
};

type BalanceHoldersSearchParams = {
  search?: string;
  limit?: number;
};

export type JobMinimalSearchResult = {
  id: number;
  title: string;
  employer_name: string;
  freelancer_already_invited_to_this_job?: boolean;
};

type JobMinimalSearchParams = {
  search?: string;
  open?: boolean;
  annotate_invited_by_client_or_matcher?: boolean;
  freelancer?: number;
  ops_invitations?: boolean;
};

export type EmployerInvoicesPayeeSearchResult = {
  id: number;
  payee_name: string;
};

export type EmployerInvoicesPayeeSearchParams = {
  search?: string;
};

type CertificateSearchResult = {
  id: number;
  name: string;
  is_default: boolean;
  order: number | null;
};

type CertificateSearchParams = {
  search?: string;
  limit?: number;
};

type CompaniesSearchResult = PaginatedResult<{
  id: number;
  name: string;
  is_default: boolean;
  order: number | null;
}>;

type CompaniesSearchParams = {
  search?: string;
  page: number;
  page_size: number;
  limit?: number;
};

type DegreesSearchResult = {
  id: number;
  name: string;
  is_default: boolean;
  order: number | null;
};

type DegreesSearchParams = {
  search?: string;
  limit?: number;
};

type SchoolsSearchResult = {
  id: number;
  name: string;
  is_default: boolean;
  order: number | null;
};

type SchoolsSearchParams = {
  search?: string;
  limit?: number;
};

const taxonomiesApi = API.injectEndpoints({
  endpoints: (build) => ({
    getEmployersSearch: build.query<
      EmployerSearchMinimalResult[],
      Record<string, string>
    >({
      query: (params) => ({
        url: `/employer_search/return_id_and_name_only/`,
        method: "GET",
        params: {
          limit: 100,
          ...params,
        },
      }),
    }),
    getEmployerInitialItem: build.query<EmployerSearchResult, number>({
      query: (id) => ({
        url: `/employer_search/${id}/`,
        method: "GET",
      }),
    }),
    getUserSearch: build.query<UserSearchResult[], UserSearchParams>({
      query: (params) => ({
        url: `/user_search/`,
        method: "GET",
        params: {
          limit: 100,
          ...params,
        },
      }),
    }),
    getUserInitialItem: build.query<UserSearchResult, number>({
      query: (id) => ({
        url: `/user_search/${id}/`,
        method: "GET",
      }),
    }),
    getTalentSearch: build.query<
      TalentSearchMinimalResult[],
      TalentSearchParams
    >({
      query: (params?) => {
        return {
          url: `/talent/`,
          method: "GET",
          params: {
            limit: 100,
            public_name_search: true,
            ...params,
          },
        };
      },
      transformResponse: (
        result: PaginatedResult<TalentSearchMinimalResult>,
      ) => {
        return result.results;
      },
    }),
    getTalentInitialItem: build.query<TalentSearchMinimalResult, number>({
      query: (id) => ({
        url: `/talent/${id}/`,
        method: "GET",
        params: {
          public_name_search: true,
        },
      }),
    }),
    getBalanceHoldersSearch: build.query<
      BalanceHoldersSearchResult[],
      BalanceHoldersSearchParams
    >({
      query: (params) => ({
        url: `/balanceholders/`,
        method: "GET",
        params,
      }),
    }),
    getJobMinimalSearch: build.query<
      JobMinimalSearchResult[],
      JobMinimalSearchParams
    >({
      query: (params) => ({
        url: `/job_minimal_search/`,
        method: "GET",
        params,
      }),
      transformResponse: (result: JobMinimalSearchResult[]) => {
        /**
         * Backend may return few k items, so we limit response to not choke autocomplete component.
         * In the future some backend/frontend adjustments may be needed.
         */
        const shortened = result.slice(0, 100);
        return shortened;
      },
    }),
    getEmployerInvoicesPayee: build.query<
      EmployerInvoicesPayeeSearchResult[],
      EmployerInvoicesPayeeSearchParams
    >({
      query: (params) => ({
        url: `/employer_invoices/payees/`,
        method: "GET",
        params,
      }),
    }),
    getCertificates: build.query<
      CertificateSearchResult[],
      CertificateSearchParams
    >({
      query: ({ limit = 100, ...params }) => ({
        url: `/certificates/`,
        method: "GET",
        params: {
          limit,
          ...params,
        },
      }),
    }),
    getCompanies: build.query<CompaniesSearchResult, CompaniesSearchParams>({
      query: ({ limit = 100, ...params }) => ({
        url: `/companies/`,
        method: "GET",
        params: {
          limit,
          ...params,
        },
      }),
    }),
    getDegrees: build.query<DegreesSearchResult[], DegreesSearchParams>({
      query: ({ limit = 100, ...params }) => ({
        url: `/degrees/`,
        method: "GET",
        params: {
          limit,
          ...params,
        },
      }),
    }),
    getSchools: build.query<SchoolsSearchResult[], SchoolsSearchParams>({
      query: ({ limit = 100, ...params }) => ({
        url: `/schools/`,
        method: "GET",
        params: {
          limit,
          ...params,
        },
      }),
    }),
  }),
});

export const {
  useGetEmployersSearchQuery,
  useGetEmployerInitialItemQuery,
  useGetUserSearchQuery,
  useGetUserInitialItemQuery,
  useGetTalentInitialItemQuery,
  useGetTalentSearchQuery,
  useGetBalanceHoldersSearchQuery,
  useGetJobMinimalSearchQuery,
  useGetEmployerInvoicesPayeeQuery,
  useLazyGetUserInitialItemQuery,
  useGetCertificatesQuery,
  useGetCompaniesQuery,
  useGetDegreesQuery,
  useGetSchoolsQuery,
} = taxonomiesApi;
