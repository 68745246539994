import { useNavigate } from "react-router-dom";

import { Box, Button } from "@hexocean/braintrust-ui-components";
import { CheckCircleIcon } from "@hexocean/braintrust-ui-components/Icons";
import { useGetJobQuery } from "@js/apps/jobs/api";
import { ModalInstance } from "@js/components/modal";
import { useAppSelector } from "@js/hooks";

import { getCurrentPostedATSJobId } from "../ats-integration-modal/ats-jobs-slice";

import style from "./style.module.scss";

export const PostedJobSuccessfullyBadge = () => {
  const { showPostedJobBadge, jobTitle, onManageJobClick } =
    usePostedJobSuccessfullyBadge();

  if (!showPostedJobBadge) {
    return <></>;
  }

  return (
    <Box className={style.postedJobSuccessfullyWrapper}>
      <Box className={style.postedJobSuccessfullyWrapperMessage}>
        <CheckCircleIcon
          style={{ width: "24px", height: "24px", color: "var(--positive-2)" }}
        />
        Successfully posted the job {jobTitle}! 🎉
      </Box>
      <Button
        variant="transparent"
        fontWeight={400}
        sx={{
          textDecoration: "underline !important",
          padding: "0 4px !important",
          fontSize: { xs: "13px !important", sm: "16px !important" },
        }}
        onClick={onManageJobClick}
      >
        Manage job
      </Button>
    </Box>
  );
};

const usePostedJobSuccessfullyBadge = () => {
  const currentPostedJob = useAppSelector(getCurrentPostedATSJobId);
  const { data: job } = useGetJobQuery(
    { id: Number(currentPostedJob) },
    { skip: !currentPostedJob },
  );
  const navigate = useNavigate();
  const showPostedJobBadge = !!currentPostedJob && !!job;

  const onManageJobClick = () => {
    ModalInstance.close();
    navigate(`/jobs/${currentPostedJob}/invite_talent/`);
  };

  return {
    showPostedJobBadge,
    jobTitle: job?.title || "",
    onManageJobClick,
  };
};
