import { useCallback } from "react";

export const useScrollToReviewSection = () => {
  const scrollToReviewSection = useCallback(() => {
    const reviewSection = document.getElementById("review-section");

    if (!reviewSection) {
      throw new Error("No element with 'review-section' id found");
    }

    reviewSection.scrollIntoView({ behavior: "smooth" });
  }, []);

  return scrollToReviewSection;
};
