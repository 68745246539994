export const WITHDRAWALS_FETCHING = "WITHDRAWALS_FETCHING";
export const WITHDRAWALS_SUCCESS = "WITHDRAWALS_SUCCESS";
export const WITHDRAWALS_FAILED = "WITHDRAWALS_FAILED";
export const FETCHING_INVOICES_TO_SETTLE_EMPLOYERS_PAYMENT =
  "FETCHING_INVOICES_TO_SETTLE_EMPLOYERS_PAYMENT";
export const FETCHING_INVOICES_TO_SETTLE_EMPLOYERS_PAYMENT_SUCCESS =
  "FETCHING_INVOICES_TO_SETTLE_EMPLOYERS_PAYMENT_SUCCESS";
export const FETCHING_INVOICES_TO_SETTLE_EMPLOYERS_PAYMENT_FAILED =
  "FETCHING_INVOICES_TO_SETTLE_EMPLOYERS_PAYMENT_FAILED";
export const FETCHING_COMPANY_NODES = "FETCHING_COMPANY_NODES";
export const FETCHING_COMPANY_NODES_SUCCESS = "FETCHING_COMPANY_NODES_SUCCESS";
export const FETCHING_COMPANY_NODES_FAILED = "FETCHING_COMPANY_NODES_FAILED";
