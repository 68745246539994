import type { ButtonProps } from "@hexocean/braintrust-ui-components";
import { Button } from "@hexocean/braintrust-ui-components";
import {
  CheckIcon,
  PersonAddIcon,
} from "@hexocean/braintrust-ui-components/Icons";
import { inviteApi, useSendInvitationMutation } from "@js/apps/jobs/api";
import { useAppDispatch } from "@js/hooks";
import type { TalentInviteSuggestion } from "@js/types/jobs";

type InviteToJobButtonProps = {
  freelancer: TalentInviteSuggestion;
  jobId: number;
  disabled?: boolean;
  notificationHandler: () => void;
  onInvite?: () => void;
} & Pick<ButtonProps, "size">;

export const InviteToJobButton = ({
  freelancer,
  jobId,
  disabled,
  notificationHandler,
  onInvite,
  ...buttonProps
}: InviteToJobButtonProps) => {
  const dispatch = useAppDispatch();
  const [sendInvitation, { isLoading }] = useSendInvitationMutation();
  const refetchTalentInviteSuggestions = () => {
    dispatch(inviteApi.util.invalidateTags(["TalentInviteSuggestionList"]));
  };
  const isTalentInvitated = !!freelancer.invite_status;
  const label = isTalentInvitated ? (
    <>
      Invited! <CheckIcon style={{ fontSize: 20, marginLeft: 8 }} />
    </>
  ) : (
    <>
      Invite to job{" "}
      <PersonAddIcon
        style={{
          fontSize: 24,
          marginLeft: 8,
        }}
      />
    </>
  );

  return (
    <Button
      shape="squared"
      disabledType="opacity"
      disabled={disabled}
      variant={isTalentInvitated ? "dark-violet" : "primary"}
      sx={{
        pointerEvents: isTalentInvitated ? "none" : undefined,
        whiteSpace: "nowrap",
      }}
      onClick={() => {
        if (isTalentInvitated || isLoading) {
          return undefined;
        }

        onInvite?.();

        sendInvitation({ jobId, freelancersIds: [freelancer.id] }).then(() => {
          notificationHandler();
          refetchTalentInviteSuggestions();
        });
      }}
      {...buttonProps}
    >
      {label}
    </Button>
  );
};
