import {
  type KeyboardEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";
import type { FormErrors } from "redux-form";
import { SubmissionError } from "redux-form";
import { skipToken } from "@reduxjs/toolkit/query";

import { Typography } from "@hexocean/braintrust-ui-components";
import { fetchCurrentUser } from "@js/apps/auth/actions";
import { useUser } from "@js/apps/common/hooks";
import { fetchFreelancerProfile } from "@js/apps/freelancer/actions";
import { useCreateFreelancerBidMutation } from "@js/apps/freelancer/api";
import { proposalSubmitted } from "@js/apps/jobs/actions";
import { useGetJobQuery } from "@js/apps/jobs/api";
import { openBidCreateSuccessModal } from "@js/apps/jobs/components/bid-create-success-modal";
import { ModalConfirm, openModalAndWaitForInput } from "@js/components/modal";
import { WARNING_MODAL_TYPE } from "@js/forms/hooks/unsaved-changes-warning";
import { useAppSelector, useGoBackHistory } from "@js/hooks";
import { useAppDispatch } from "@js/hooks";
import { useIdParam } from "@js/hooks/use-id-param";
import { deepClone, typeGuard } from "@js/utils";

export const useCreateBid = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const jobId = useIdParam();
  const {
    currentData: job,
    isFetching,
    refetch,
  } = useGetJobQuery(jobId ? { id: jobId } : skipToken);
  const user = useUser();
  const freelancerResume = useAppSelector(
    (state) => state.freelancer.freelancerProfile?.resume,
  );
  const goBack = useGoBackHistory();
  const [wasCalendarModalShown, setWasCalendarModalShown] = useState(false);
  const [createFreelancerBid] = useCreateFreelancerBidMutation();
  const hasRefetched = useRef(false);

  useEffect(() => {
    if (job?.can_bid === false) {
      if (hasRefetched.current && !isFetching) {
        goBack("/talent/dashboard/my_jobs/my_proposals");
      } else {
        refetch();
        hasRefetched.current = true;
      }
    }
  }, [job, goBack, isFetching, refetch]);

  const handleEscClick = useCallback(
    (ev: KeyboardEvent) => {
      if (ev.key === "Escape") {
        navigate("/jobs/");
      }
    },
    [navigate],
  );

  const onSubmit = useCallback(
    async (values) => {
      const _values = deepClone(values);

      if (_values["freelancer_availability"] !== "specific_date") {
        delete _values["freelancer_availability_specific_date"];
      }

      _values.resume =
        typeof _values.resume === "number"
          ? _values.resume
          : _values.resume?.id;

      if (!_values["calendar_url"] && !wasCalendarModalShown) {
        setWasCalendarModalShown(true);
        try {
          await openModalAndWaitForInput({
            children: (
              <ModalConfirm confirmText="Okay">
                <Typography component="p" maxWidth="550px">
                  Are you sure you want to proceed without providing your
                  booking calendar URL for screening? Many of our clients begin
                  screening by selecting applicants who provide their
                  availability. It is simple to set up a free account at
                  calendly.com
                </Typography>
              </ModalConfirm>
            ),
          });
        } catch (_) {
          throw new SubmissionError({});
        }
      }

      return createFreelancerBid(_values)
        .unwrap()
        .then((response) => {
          dispatch(proposalSubmitted(response));
          return response;
        })
        .catch((error) => {
          const parsedError = parseError(error);

          throw new SubmissionError(parsedError as FormErrors);
        });
    },
    [wasCalendarModalShown, createFreelancerBid, dispatch],
  );

  const onSubmitSuccess = useCallback(
    async (application) => {
      if (!user?.freelancer_has_grant_job_type_bids) {
        await dispatch(fetchCurrentUser());
      }

      if (user?.freelancer) {
        await dispatch(fetchFreelancerProfile(user.freelancer));
      }

      openBidCreateSuccessModal({
        roleId: application.job.role?.id,
        onKeyDown: handleEscClick,
      });
    },
    [dispatch, user, handleEscClick],
  );

  const initialValues = useMemo(() => {
    return {
      payment_amount: job?.job_type === ENUMS.JobType.GRANT ? null : undefined,
      calendar_url: user?.calendar_link,
      job: job?.id,
      new_application_answers: job?.application_questions?.map((question) => {
        return {
          ...question,
          application_question: question.id,
          answer: undefined,
        };
      }),
      resume: freelancerResume ?? null,
      timezone_requirement_met: job?.timezone_required ? null : undefined,
      location_requirement_met: job?.locations_strongly_required
        ? null
        : undefined,
      is_boosted: job?.is_boosted ? true : false,
    };
  }, [user, job, freelancerResume]);

  return {
    job,
    loading: isFetching,
    onSubmit,
    onSubmitSuccess,
    initialValues,
    unsavedChangesWarning: true,
    unsavedChangesWarningModelType: WARNING_MODAL_TYPE.NEW_BID,
  };
};

type AnswerError = { answer?: string };

const parseError = (error: unknown) => {
  if (!error || typeof error !== "object") {
    return;
  }

  if (!typeGuard<unknown, { data: Record<string, unknown> }>(error, "data")) {
    return;
  }

  const parsedError: Record<string, string | AnswerError[]> = {};
  Object.entries(error.data).forEach(([key, value]) => {
    if (typeof value === "string") {
      parsedError[key] = value;
      return;
    }

    if (!Array.isArray(value) || !value.length) {
      return;
    }

    if (key === "new_application_answers") {
      parsedError[key] = value.map((item: unknown) => {
        if (!item || typeof item !== "object") {
          return {};
        }

        if (typeGuard<object, { _error: string }>(item, "_error")) {
          return {
            answer: item._error,
          };
        }

        return item;
      });

      return;
    }

    parsedError[key] = value[0];
  });

  return parsedError;
};
