import { Box } from "@hexocean/braintrust-ui-components";
import { SkillsEditModule } from "@js/apps/freelancer/forms/skills-edit-module";
import { useAccountInfo } from "@js/apps/freelancer/hooks";

import { useProfileCompletionSequenceContext } from "../context/profile-completion-sequence-context";

import { ProfileCompletionSequenceCloseBtn } from "./close-btn";

export const Skills = () => {
  const { shouldRender, profile } = useProfileCompletionSequenceContext();

  const { formConfig } = useAccountInfo(profile);

  if (!shouldRender("Skills")) {
    return null;
  }

  const { onSubmitSuccess } = formConfig;

  return (
    <Box sx={{ padding: 4 }}>
      <ProfileCompletionSequenceCloseBtn />
      <SkillsEditModule
        saveButtonText="Save and continue"
        onSubmitSuccess={onSubmitSuccess}
      />
    </Box>
  );
};
