import { useEffect, useMemo } from "react";

import {
  useLazyGetStorageValueQuery,
  useSetStorageValueMutation,
} from "@js/apps/common/frontend-storage";
import { useAppDispatch, useAppSelector } from "@js/hooks";

import { useActiveWeb3React } from "../../hooks";
import { useBlockNumber } from "../application/hooks"; //useAddPopup

import {
  addTransactions,
  checkedTransaction,
  finalizeTransaction,
} from "./actions";
import type { TransactionDetails } from "./reducer";

export function shouldCheck(
  lastBlockNumber: number,
  tx: { addedTime: number; receipt?: any; lastCheckedBlockNumber?: number },
): boolean {
  if (tx.receipt) return false;
  if (!tx.lastCheckedBlockNumber) return true;
  const blocksSinceCheck = lastBlockNumber - tx.lastCheckedBlockNumber;
  if (blocksSinceCheck < 1) return false;
  const minutesPending = (new Date().getTime() - tx.addedTime) / 1000 / 60;
  if (minutesPending > 60) {
    // every 10 blocks if pending for longer than an hour
    return blocksSinceCheck > 9;
  } else if (minutesPending > 5) {
    // every 3 blocks if pending more than 5 minutes
    return blocksSinceCheck > 2;
  } else {
    // otherwise every block
    return true;
  }
}

const TransactionUpdater = (): null => {
  const { chainId, library } = useActiveWeb3React();
  const [getStorageValue] = useLazyGetStorageValueQuery();
  const [setStorageValue] = useSetStorageValueMutation();

  const lastBlockNumber = useBlockNumber();

  const dispatch = useAppDispatch();
  const state = useAppSelector((appState) => appState.transactions);
  const isUserLoggedIn = useAppSelector((appState) => appState.auth.user);

  const transactions = useMemo(() => {
    return chainId ? state[chainId] ?? {} : {};
  }, [chainId, state]);

  useEffect(() => {
    if (!chainId || !isUserLoggedIn) {
      return;
    }

    let isCancelled = false;
    getStorageValue({ key: `vote-transactions[${chainId}]` }, true)
      .unwrap()
      .then((txs: unknown) => {
        if (isCancelled || !txs) {
          return;
        }

        dispatch(
          addTransactions({
            txs: txs as Record<string, TransactionDetails>,
            chainId,
          }),
        );
      })
      .catch(() => null);

    return () => {
      isCancelled = true;
    };
  }, [chainId, dispatch, isUserLoggedIn, getStorageValue]);

  useEffect(() => {
    if (chainId && state[chainId] && isUserLoggedIn) {
      setStorageValue({
        key: `vote-transactions[${chainId}]`,
        value: transactions,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chainId, transactions, isUserLoggedIn]);

  useEffect(() => {
    if (!chainId || !library || !lastBlockNumber) return;

    Object.keys(transactions)
      .filter((hash) => shouldCheck(lastBlockNumber, transactions[hash]))
      .forEach((hash) => {
        library
          .getTransactionReceipt(hash)
          .then((receipt) => {
            if (receipt) {
              dispatch(
                finalizeTransaction({
                  chainId,
                  hash,
                  receipt: {
                    blockHash: receipt.blockHash,
                    blockNumber: receipt.blockNumber,
                    contractAddress: receipt.contractAddress,
                    from: receipt.from,
                    status: receipt.status,
                    to: receipt.to,
                    transactionHash: receipt.transactionHash,
                    transactionIndex: receipt.transactionIndex,
                  },
                }),
              );
            } else {
              dispatch(
                checkedTransaction({
                  chainId,
                  hash,
                  blockNumber: lastBlockNumber,
                }),
              );
            }
          })
          .catch((error) => {
            console.error(`failed to check transaction hash: ${hash}`, error);
          });
      });
  }, [chainId, library, transactions, lastBlockNumber, dispatch]);

  return null;
};

export default TransactionUpdater;
