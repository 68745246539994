import { useEffect } from "react";
import { Field, FieldArray, Fields } from "redux-form";

import { Box } from "@hexocean/braintrust-ui-components";
import { hideLeftSidebar, showLeftSidebar } from "@js/apps/common/actions";
import {
  JobDescription,
  JobErrorSection,
  JobIntroduction,
  ProjectDetailsSectionModule,
  RequirementsSection,
  RoleSection,
} from "@js/apps/jobs/apps/create-job/components";
import { CanManageOnBehalfOfClientModule } from "@js/apps/jobs/apps/create-job/components/can-manage-job-on-behalf-of-client-module";
import { RateSectionModule } from "@js/apps/jobs/apps/create-job/components/rate-section-module";
import { SkillsSection } from "@js/apps/jobs/apps/create-job/components/skills-section";
import { AutoInviteContainer } from "@js/apps/jobs/apps/create-job/components/summarize-module";
import {
  EditableTitleField,
  ExperienceField,
  ResumeRequirementField,
  TextFieldProposalArray,
} from "@js/apps/jobs/apps/create-job/fields";
import {
  experienceValidator,
  jobDescriptionValidator,
  jobTitleValidator,
  projectDetailsValidator,
  skillsValidator,
} from "@js/apps/jobs/apps/create-job/validators";
import { FieldSectionPanel } from "@js/apps/jobs/forms/fields";
import { useAppDispatch, useAppSelector } from "@js/hooks";

import { AiJobPostBanner } from "../../components/ai-job-post-banner";
import { JobDescriptionUpdateStatusTag } from "../../components/job-description-update-status-tag";
import { SummarizeJobModalInstance } from "../../components/summarize-module";

type CreateOrEditJobMainFieldsProps = {
  isEditForm?: boolean;
};

export const CreateOrEditJobMainFields = ({
  isEditForm,
}: CreateOrEditJobMainFieldsProps) => {
  const dispatch = useAppDispatch();
  const jobToEdit = useAppSelector((state) => state.jobs.jobToEdit);

  useEffect(() => {
    dispatch(hideLeftSidebar());
    return () => {
      dispatch(showLeftSidebar());
    };
  }, [dispatch]);

  return (
    <Box maxWidth="787px">
      <CanManageOnBehalfOfClientModule badgeOnly={true} />
      <JobDescriptionUpdateStatusTag />
      <Fields names={["is_ai_generated"]} component={AiJobPostBanner} />
      <SummarizeJobModalInstance />
      <Box mb={3} id="category">
        <Fields
          names={["role", "openings_number"]}
          component={RoleSection}
          isEditForm={isEditForm}
        />
      </Box>

      {isEditForm && <AutoInviteContainer jobToEdit />}

      <Box my={3} id="job-title">
        <Field
          id="job-title"
          name="title"
          placeholder="Enter job title here"
          validate={[jobTitleValidator]}
          component={EditableTitleField}
        />
      </Box>
      <Field name="" component={JobErrorSection} />
      <Field
        name="description"
        component={JobDescription}
        validate={[jobDescriptionValidator]}
      />
      <Fields
        names={[
          "contract_type",
          "deadline",
          "start_date",
          "expected_hours_per_week",
          "location",
          "timezone",
          "timezone_overlap",
        ]}
        component={ProjectDetailsSectionModule}
        validate={{ contract_type: [projectDetailsValidator] }}
      />

      <Fields
        names={["experience_level", "role"]}
        component={ExperienceField}
        validate={{ experience_level: [experienceValidator] }}
      />

      <Fields
        names={["new_skills", "top_skills"]}
        component={SkillsSection}
        validate={[skillsValidator]}
      />

      <RateSectionModule />

      <Field name="is_resume_required" component={ResumeRequirementField} />

      {jobToEdit?.requirements && (
        <Field name="requirements" component={RequirementsSection} />
      )}

      {jobToEdit?.introduction && (
        <Field name="introduction" component={JobIntroduction} />
      )}

      <FieldSectionPanel
        title="Application questions (optional)"
        description="Talent is prompted to answer these when submitting an application"
      >
        <FieldArray
          name="new_application_questions"
          component={TextFieldProposalArray}
        />
      </FieldSectionPanel>
    </Box>
  );
};
