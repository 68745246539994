import { useEffect, useState } from "react";

import { fetchArticles } from "@js/apps/freelancer/actions";
import { useAppDispatch } from "@js/hooks";

export const useHasArticles = () => {
  const [hasArticles, setHasArticles] = useState(false);
  const dispatch = useAppDispatch();
  const ARTICLES_VISIBLE = 5;

  useEffect(() => {
    dispatch(fetchArticles({ page_size: ARTICLES_VISIBLE })).then(
      (response) => {
        if (response.count > 0) {
          setHasArticles(true);
        }
      },
    );
  }, [dispatch]);

  return hasArticles;
};
