import React from "react";
import { useParams } from "react-router-dom";

import { Box, UnderlinedTabs } from "@hexocean/braintrust-ui-components";
import { useIsProfileOwnerContext } from "@js/apps/common/profile/is-profile-owner-context";
import { RouterLink } from "@js/components/link";
import { useAppSelector } from "@js/hooks";

export const FreelancerProfilePageTabs = () => {
  const { isProfileOwner } = useIsProfileOwnerContext();
  const { id } = useParams();
  const profile = useAppSelector((state) => state.freelancer.publicProfile);
  const hasUploadedResume = profile?.resume;

  const shouldTabsBeRendered = hasUploadedResume || isProfileOwner;

  if (!shouldTabsBeRendered) {
    return null;
  }

  return (
    <Box className="profile-page-content__tabs">
      <UnderlinedTabs
        pathname={window.location.pathname}
        labelUnActiveColor="grey"
        spacing={4}
        sx={{
          mb: 5,
          "*": {
            p: 0,
          },
        }}
        itemProps={{
          sx: {
            p: 0,
          },
          variant: "title",
          size: "small",
          fontWeight: 400,
        }}
        items={[
          { path: `/talent/${id}/`, label: "About" },
          { path: `/talent/${id}/resume`, label: "Resume" },
        ]}
        RouterLink={RouterLink}
      />
    </Box>
  );
};
