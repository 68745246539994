import { IconButton, Typography } from "@hexocean/braintrust-ui-components";
import { ArrowRightIcon } from "@hexocean/braintrust-ui-components/Icons";
import { useCtaClickedContext } from "@js/apps/common/context";
import { BoxedEmployerLogo } from "@js/apps/employer/components/employer-logo";
import { ALTERNATIVE_LISTING_BG_COLOR } from "@js/apps/jobs/constants";
import { Date } from "@js/components/date";
import { RouterLink } from "@js/components/link";
import type { FreelancerBid } from "@js/types/jobs";
import { DateFormats } from "@js/utils/date/types";

import { EmplyerNameAndJobTitle } from "../../employer-name-and-job-title";

type AppliedJobsCardProps = {
  bid: FreelancerBid;
};

export const AppliedJobsCard = ({ bid }: AppliedJobsCardProps) => {
  const { ctaClicked } = useCtaClickedContext();

  const { logo_background_color, logo_thumbnail } = bid.job.employer;

  return (
    <div className="applied-jobs-section__card">
      <div className="applied-jobs-section__header">
        <Typography component="p" size="small" color="secondary">
          Applied <Date date={bid.created} format={DateFormats["January 01"]} />
        </Typography>

        <div className="applied-jobs-section__tag">
          <Typography component="p" size="small">
            In review
          </Typography>
        </div>
      </div>

      <div className="applied-jobs-section__info">
        <BoxedEmployerLogo
          employer={bid.job.employer}
          size="small"
          source={logo_thumbnail}
          bgcolor={`var(${
            logo_background_color || ALTERNATIVE_LISTING_BG_COLOR
          })`}
          style={{
            borderRadius: "12px",
            minWidth: "48px",
            height: "48px",
          }}
        />

        <EmplyerNameAndJobTitle
          job={bid.job}
          employerNameSize="medium"
          jobTitleSize="small"
        />

        <IconButton
          variant="transparent"
          size="medium"
          aria-label="Go to job"
          to={`/jobs/${bid.job.id}`}
          onClick={() =>
            ctaClicked(ENUMS.UserClicksEventCTAName.VIEW_APPLICATION)
          }
          sx={{ mr: -1 }}
          RouterLink={RouterLink}
        >
          <ArrowRightIcon />
        </IconButton>
      </div>
    </div>
  );
};
