import {
  FRONTEND_STORAGE_KEYS,
  useGetStorageValueQuery,
  useSetStorageValueMutation,
} from "@js/apps/common/frontend-storage";

type UseDismissATSModuleReturnType = {
  dismissed: boolean;
  handleDismissModule: () => void;
};

export const useDismissATSModule = (): UseDismissATSModuleReturnType => {
  const { data: isModuleDismissed, isLoading } = useGetStorageValueQuery({
    key: FRONTEND_STORAGE_KEYS.is_ATS_integration_module_dismissed,
  });

  const [setStorageValue] = useSetStorageValueMutation();

  const handleDismissModule = () => {
    return setStorageValue({
      key: FRONTEND_STORAGE_KEYS.is_ATS_integration_module_dismissed,
      value: true,
    });
  };

  return {
    dismissed: !!isModuleDismissed || isLoading,
    handleDismissModule,
  };
};
