import React from "react";

import { postFooterActionButtonClicked } from "@js/apps/give-and-get-help/actions";
import type { CreateOrEditPostMode } from "@js/apps/give-and-get-help/types";
import { useAppDispatch } from "@js/hooks";

import { usePostLocationContext } from "../../context/post-location";

import type { PostFooterButtonProps, PostFooterIconsModel } from "./models";
import {
  AddFileButton,
  AddImageButton,
  AddLinkButton,
  AddPollButton,
} from "./single-buttons";

export type CreateOrEditPostFooterActionsButtonsSectionProps = {
  mode: CreateOrEditPostMode;
  buttons: PostFooterButtonProps[];
};

export const CreateOrEditPostFooterActionsButtonsSection = ({
  mode,
  buttons,
}: CreateOrEditPostFooterActionsButtonsSectionProps) => {
  const dispatch = useAppDispatch();
  const postLocation = usePostLocationContext();

  const _buttons = buttons.map((el) => {
    const btnConfig = enhanceButtonConfigWithOnClick(el, () => {
      dispatch(
        postFooterActionButtonClicked({
          mode,
          name: el.name,
          post_location: postLocation,
        }),
      );
    });

    return btnConfig;
  });

  return <MapButtonsConfigsToElements buttons={_buttons} />;
};

const BUTTON_NAME_TO_COMPONENT_MAP: PostFooterIconsModel = {
  "post-file": AddFileButton,
  "post-image": AddImageButton,
  "post-link": AddLinkButton,
  "post-poll": AddPollButton,
};

type MapButtonsConfigsToElementsProps = {
  buttons: PostFooterButtonProps[];
};

const MapButtonsConfigsToElements = ({
  buttons,
}: MapButtonsConfigsToElementsProps) => {
  return (
    <>
      {buttons.map((el) => {
        const Component = BUTTON_NAME_TO_COMPONENT_MAP[el.name];

        return <Component key={el.name} {...el.props} />;
      })}
    </>
  );
};

const enhanceButtonConfigWithOnClick = (
  el: PostFooterButtonProps,
  cb: () => void,
) => {
  const handleClick = (ev) => {
    if (el.props?.onClick) {
      el.props?.onClick(ev);
    }

    cb();
  };

  return {
    ...el,
    props: {
      ...el.props,
      onClick: handleClick,
    },
  };
};
