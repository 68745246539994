import { assertUnreachable } from "@js/utils";

export function activateInjectedProvider(
  providerName: "MetaMask" | "CoinBase",
) {
  const { ethereum } = window;
  let provider;

  if (!ethereum?.providers) {
    return undefined;
  }

  switch (providerName) {
    case "CoinBase":
      provider = ethereum.providers.find(
        ({ isCoinbaseWallet }) => isCoinbaseWallet,
      );
      break;
    case "MetaMask":
      provider = ethereum.providers.find(({ isMetaMask }) => isMetaMask);
      break;
    default:
      assertUnreachable(providerName);
  }

  if (provider) {
    ethereum.setSelectedProvider(provider);
  }
}
