import * as React from "react";
import classNames from "classnames";

type TalentPublicNameProps = {
  name: string;
  approved?: boolean;
  className?: string;
  singleLine?: boolean;
};

export const TalentPublicName = ({
  approved,
  className,
  name,
  singleLine,
}: TalentPublicNameProps) => {
  return (
    <h6 className={classNames("talent-public-name", className)} title={name}>
      <span
        className={classNames("talent-public-name-text", {
          "talent-public-name-text-with-img": approved,
          "talent-public-name-text-single-line": singleLine,
        })}
      >
        {name}
      </span>
    </h6>
  );
};
