import { z } from "zod";

export const jobPublishRequiredDataSchema = z.object({
  budget_maximum_usd: z.string(),
  budget_minimum_usd: z.string(),
  contract_type: z.nativeEnum(ENUMS.JobContractType),
  expected_hours_per_week: z.number(),
  experience_level: z.union([z.nativeEnum(ENUMS.JobExperienceLevel), z.null()]),
  job_draft_id: z.number(),
  payment_type: z.nativeEnum(ENUMS.JobPaymentType),
  role: z.number(),
  new_skills: z.array(z.number()),
  top_skills: z.array(z.number()),
});
