import React from "react";

import {
  AcceptingProposalsStatus,
  ClientHiredBraintrustTalent,
  DisabledProposalButtonWithTooltip,
  NoBraintrustTalentHired,
  NotAcceptingProposals,
  YouHiredXBraintrustTalent,
} from "./components";

export const EmployerStatus = ({ job, ...props }) => {
  switch (job.job_status) {
    case ENUMS.JobStatus.OPEN:
      return (
        <AcceptingProposalsStatus
          {...props}
          content={<DisabledProposalButtonWithTooltip />}
        />
      );

    case ENUMS.JobStatus.ACTIVE: {
      if (job.user_is_owner) {
        return (
          <YouHiredXBraintrustTalent count={job.hired_bids_count} {...props} />
        );
      }

      return (
        <ClientHiredBraintrustTalent count={job.hired_bids_count} {...props} />
      );
    }
    case ENUMS.JobStatus.ON_HOLD:
      return <NotAcceptingProposals {...props} />;

    case ENUMS.JobStatus.COMPLETED_HIRED:
      if (job.user_is_owner) {
        return (
          <YouHiredXBraintrustTalent
            color="var(--black)"
            status="Complete"
            count={job.hired_bids_count}
            {...props}
          />
        );
      }

      return (
        <ClientHiredBraintrustTalent
          count={job.hired_bids_count}
          color="var(--black)"
          status="Complete"
          {...props}
        />
      );

    case ENUMS.JobStatus.COMPLETED_NOT_HIRED:
      return <NoBraintrustTalentHired {...props} />;

    default:
      return null;
  }
};
