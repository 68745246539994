import {
  Button,
  Checkbox,
  Divider,
  Stack,
  Tooltip,
  Typography,
} from "@hexocean/braintrust-ui-components";
import { InfoIcon } from "@hexocean/braintrust-ui-components/Icons";
import { clickSelectJobsATS } from "@js/apps/employer/actions";
import { useATSJobPostSettingsSection } from "@js/apps/employer/hooks/ats-job-post-settings-section";
import { useAppDispatch } from "@js/hooks";
import type { ATSIntegration } from "@js/types/employer";

import { openAtsIntegrationModal } from "../../ats-integration-modal";
import { ATSIntegrationConnectedSectionContainer } from "../ats-integration-connected-section-container";

type JobPostSettingsSectionProps = { integration: ATSIntegration };

const HIDE_AUTO_FILL = true;

export const JobPostSettingsSection = ({
  integration,
}: JobPostSettingsSectionProps) => {
  const dispatch = useAppDispatch();
  const { handleAutoFillChange } = useATSJobPostSettingsSection(integration);

  return (
    <ATSIntegrationConnectedSectionContainer title="Job post settings">
      <Stack>
        <Stack
          direction="row"
          sx={{
            justifyContent: "space-between",
            alignItems: "center",
            py: "20px",
          }}
        >
          <Typography component="span" variant="label" size="medium">
            Synced jobs
          </Typography>
          <Button
            variant="black-outlined"
            shape="squared"
            size="x-small"
            onClick={() => {
              openAtsIntegrationModal(integration);
              dispatch(clickSelectJobsATS());
            }}
          >
            Select {integration.integration} jobs
          </Button>
        </Stack>
        {!HIDE_AUTO_FILL && (
          <>
            <Divider color="light-grey" sx={{ mb: { xs: 2, sm: 3, md: 4 } }} />
            <Stack
              direction="row"
              sx={{
                justifyContent: "space-between",
                alignItems: "center",
                flexWrap: "wrap",
                gap: 1,
              }}
            >
              <Typography component="span" variant="label" size="medium">
                Auto-fill job fields{" "}
                <Tooltip
                  placement="top"
                  title={
                    <Typography
                      component="span"
                      variant="paragraph"
                      size="medium"
                    >
                      Our auto-fill feature is designed to fill in any missing
                      fields within your job posts. This functionality will
                      accelerate the publication of your job postings.
                    </Typography>
                  }
                >
                  <InfoIcon
                    sx={{ fontSize: "16px", verticalAlign: "text-bottom" }}
                  />
                </Tooltip>
              </Typography>
              <Checkbox
                noMargin
                label={`Auto-fill incoming jobs from ${integration.integration}`}
                checked={integration.auto_fill_job_fields}
                onChange={handleAutoFillChange}
                checkedColor="yellow"
                sx={{
                  p: 1,
                  mr: 0.5,
                }}
              />
            </Stack>
          </>
        )}
      </Stack>
    </ATSIntegrationConnectedSectionContainer>
  );
};
