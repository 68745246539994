import { useEffect } from "react";

import { useUser } from "@js/apps/common/hooks";
import { deleteSinglePostUpdateRecipe } from "@js/apps/give-and-get-help/utils/update-recipes/single-post-update-recipe";
import { WebSocketManagerContainer } from "@js/components/websocket";
import { useAppDispatch } from "@js/hooks";
import type { IPost } from "@js/types/give-and-get-help";

import { postsApi } from "../../api";

const DELETE_SINGLE_POST_LISTENER_ID = "delete-single-post-listener";

export const useHandleDeleteSinglePost = (post: IPost | undefined) => {
  const user = useUser();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (user && post) {
      const listener = (event: MessageEvent) => {
        dispatch(
          postsApi.util.updateQueryData("getPost", { id: post.id }, (draft) =>
            deleteSinglePostUpdateRecipe(user.id)(event, draft),
          ),
        );
      };

      WebSocketManagerContainer.attachListener(
        listener,
        DELETE_SINGLE_POST_LISTENER_ID,
      );
    }

    return () => {
      WebSocketManagerContainer.removeListener(DELETE_SINGLE_POST_LISTENER_ID);
    };
  }, [dispatch, post, user]);
};
