import React, { useContext, useMemo } from "react";

import type { Job } from "@js/types/jobs";

const JobActionBarContext = React.createContext<{
  job: Job | null;
  referredTalentHired: boolean | null;
}>({
  job: null,
  referredTalentHired: null,
});

export const JobActionBarProvider = ({ job, children }) => {
  const value = useMemo(
    () => ({
      job,
      referredTalentHired: !!job.referred_talent_hired_at,
    }),
    [job],
  );

  return (
    <JobActionBarContext.Provider value={value}>
      {children}
    </JobActionBarContext.Provider>
  );
};

export const useJobActionBarContext = () => {
  const value = useContext(JobActionBarContext);

  if (!value) {
    throw new Error("There is no JobActionBarProvider");
  }

  return value;
};
