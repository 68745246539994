import type { TypedWrappedFieldProps } from "redux-form";
import { Field } from "redux-form";

import { Checkbox } from "@hexocean/braintrust-ui-components";
import { getError, shouldDisplayError } from "@js/forms/utils";

import { BoostFieldPanel } from "./components";
import { getFieldDescription } from "./utils";

type BoostFieldProps = {
  boost: number;
  id?: string;
};

export const BoostItem = ({ boost }: BoostFieldProps) => {
  const title = "Boost your application.";
  const description = getFieldDescription(boost);

  return (
    <Field
      id="boost-application"
      name="is_boosted"
      title={title}
      boost={boost}
      description={description}
      component={BoostApplicationField}
    />
  );
};

type BoostRateFieldProps = {
  title: string;
  description: string;
  id: string;
  boost: number;
} & TypedWrappedFieldProps<boolean>;

const BoostApplicationField = ({
  title,
  description,
  boost,
  ...props
}: BoostRateFieldProps) => {
  const displayError = shouldDisplayError(props);

  return (
    <BoostFieldPanel
      title={title}
      description={description}
      displayError={displayError}
      error={getError(props)}
    >
      {boost > 0 && (
        <Checkbox
          onChange={(event) => props.input.onChange(event?.target?.checked)}
          className="fill-out-section-panel__checkbox"
        />
      )}
    </BoostFieldPanel>
  );
};
