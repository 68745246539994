import { isEqual } from "underscore";

import { useLocalStorageState } from "@js/apps/common/hooks/use-local-storage-state";
import { useGetATSIntegrationErrorsQuery } from "@js/apps/employer/api";
import { LocalStorage } from "@js/services";
import type { ATSIntegration, ATSIntegrationError } from "@js/types/employer";

import { IntegrationErrorItem } from "./integration-error-item";

export type ATSIntegrationErrorListItem = {
  name: ATSIntegrationError["error_description"];
  details: ATSIntegrationError[];
};

type ATSUseErrorListReturnType = {
  errorList: ATSIntegrationErrorListItem[];
  hideError: (error: ATSIntegrationErrorListItem) => void;
};

const useErrorList = (id: ATSIntegration["id"]): ATSUseErrorListReturnType => {
  const { data = [] } = useGetATSIntegrationErrorsQuery({ id });
  const [ignoredErrors, setIgnoredErrors] = useLocalStorageState<
    ATSIntegrationErrorListItem[]
  >({
    key: LocalStorage.keys.HIDDEN_ATS_INTEGRATION_ISSUES,
    initialValue: [],
  });

  const errorGroups = [
    ...new Set(data.map((error) => error.error_description)),
  ];

  const errorList = errorGroups
    .map((group) => {
      const errorGroupDetails = data.filter(
        (el) => el.error_description === group,
      );
      return {
        name: group,
        details: errorGroupDetails,
      };
    })
    .filter((group) => {
      // filter error groups closed by user
      const storedErrorGroup = ignoredErrors.find((storedGroup) => {
        return storedGroup.name === group.name;
      });

      if (!storedErrorGroup) return true;

      return !isEqual(storedErrorGroup.details, group.details);
    });

  const hideError = (error: ATSIntegrationErrorListItem) => {
    const existingErrorIndex = ignoredErrors.findIndex((storedGroup) => {
      return storedGroup.name === error.name;
    });

    if (existingErrorIndex !== -1) {
      ignoredErrors.splice(existingErrorIndex, 1);
    }
    setIgnoredErrors([...ignoredErrors, error]);
  };

  return { errorList, hideError };
};

type IntegrationErrorsProps = {
  integrationId: ATSIntegration["id"];
};

export const IntegrationErrors = ({
  integrationId,
}: IntegrationErrorsProps) => {
  const { errorList, hideError } = useErrorList(integrationId);

  return errorList.length ? (
    <>
      {errorList.map((errorGroup) => {
        return (
          <IntegrationErrorItem
            key={errorGroup.name}
            error={errorGroup}
            hideError={hideError}
          />
        );
      })}
    </>
  ) : null;
};
