export const FETCH_EMPLOYER_PROFILE = "FETCH_EMPLOYER_PROFILE";
export const FETCH_EMPLOYER_PROFILE_SUCCESS = "FETCH_EMPLOYER_PROFILE_SUCCESS";
export const FETCH_EMPLOYER_PROFILE_FAILED = "FETCH_EMPLOYER_PROFILE_FAILED";
export const UPDATE_EMPLOYER_PROFILE_SUCCESS =
  "UPDATE_EMPLOYER_PROFILE_SUCCESS";
export const FETCH_EMPLOYER_PUBLIC_PROFILE_SUCCESS =
  "FETCH_EMPLOYER_PUBLIC_PROFILE_SUCCESS";
export const FETCH_EMPLOYER_PUBLIC_PROFILE_FAILED =
  "FETCH_EMPLOYER_PUBLIC_PROFILE_FAILED";
export const FETCH_EMPLOYER_PUBLIC_PROFILE = "FETCH_EMPLOYER_PUBLIC_PROFILE";
export const FETCH_EMPLOYER_TEAM_MEMBERS = "FETCH_EMPLOYER_TEAM_MEMBERS";
export const FETCH_EMPLOYER_TEAM_MEMBERS_SUCCESS =
  "FETCH_EMPLOYER_TEAM_MEMBERS_SUCCESS";
export const FETCH_EMPLOYER_TEAM_MEMBERS_FAILED =
  "FETCH_EMPLOYER_TEAM_MEMBERS_FAILED";
export const CLEAR_EMPLOYER_PUBLIC_PROFILE = "CLEAR_EMPLOYER_PUBLIC_PROFILE";
export const AVATAR_FIELD_UPLOADING = "AVATAR_FIELD_UPLOADING";
