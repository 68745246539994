import React from "react";

import type {
  ChipSizes,
  TypographySizes,
} from "@hexocean/braintrust-ui-components";
import { Chip } from "@hexocean/braintrust-ui-components";

type AppliedChipProps = {
  size?: ChipSizes;
  typographySize?: TypographySizes;
  tagText: string;
};

export const AppliedChip = ({
  tagText,
  size,
  typographySize,
}: AppliedChipProps) => {
  return (
    <Chip
      variant="rounded"
      label={tagText}
      LabelProps={{
        component: "span",
        variant: "label",
      }}
      color="blue"
      size={size}
      typographySize={typographySize}
    />
  );
};
