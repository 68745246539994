import { Box, Typography } from "@hexocean/braintrust-ui-components";
import { DiamondIcon } from "@hexocean/braintrust-ui-components/Icons";
import type {
  ProfileCardRoleProps,
  ProfileCardRolesProps,
} from "@js/apps/give-and-get-help/components/profile-card/profile-card.types";
import { pluralize } from "@js/utils";

export const ProfileCardRoles = ({ roles }: ProfileCardRolesProps) => {
  return (
    <Box display="flex" flexWrap="wrap" gap={1}>
      {roles.map((role) => (
        <ProfileCardRole key={role.role.name} role={role} />
      ))}
    </Box>
  );
};

const ProfileCardRole = ({ role }: ProfileCardRoleProps) => {
  return (
    <Box className="profile-card__role">
      <DiamondIcon style={{ fontSize: "16px" }} />
      <Typography size="small" component="span" variant="paragraph">
        {role.role.name} ({role.years_experience} year
        {pluralize(role.years_experience)})
      </Typography>
    </Box>
  );
};
