import type { FC, KeyboardEvent } from "react";
import { useSearchParams } from "react-router-dom";
import type { TypedWrappedFieldProps } from "redux-form";
import { Field } from "redux-form";

import type { SearchDataType } from "@js/apps/common/components/filters";
import { SearchForm } from "@js/apps/common/components/filters/config";
import { useSearchEvents } from "@js/apps/common/hooks";
import { InversedSearchField } from "@js/forms/fields/search-inversed";
import { maxLength } from "@js/forms/validators";

const maxLengthValidator = maxLength(500);

type SearchFilterProps = {
  dataType: SearchDataType;
  placeholder: string;
  className?: string;
  refetch?: () => void;
};

const searchFormId = "job-bids-talent-filters__search";

export const SearchFilter: FC<SearchFilterProps> = ({
  dataType,
  placeholder,
  refetch,
  ...rest
}) => {
  const { sendSearchEventDebounced } = useSearchEvents(dataType);

  const handleSubmitSideAction = (values: Record<string, unknown>) => {
    if (typeof values.search !== "string") {
      return;
    }

    sendSearchEventDebounced(values.search);
  };

  return (
    <SearchForm
      form={searchFormId}
      onSubmitSideAction={!!dataType ? handleSubmitSideAction : undefined}
    >
      <Field
        name="search"
        dataType={dataType}
        component={SearchField}
        validate={[maxLengthValidator]}
        placeholder={placeholder}
        refetch={refetch}
        {...rest}
      />
    </SearchForm>
  );
};

type SearchFieldProps = SearchFilterProps & TypedWrappedFieldProps<string>;

const SearchField = ({
  dataType,
  placeholder,
  refetch,
  ...rest
}: SearchFieldProps) => {
  const dataTypeLowerCase = !!dataType ? dataType.toLocaleLowerCase() : "field";

  const { value } = rest.input;
  const [params] = useSearchParams();
  const searchParam = params.get("search");

  const refetchOnEnter = (e: KeyboardEvent<HTMLInputElement>) => {
    if (refetch && e.code === "Enter" && searchParam === value) {
      refetch();
    }
  };

  return (
    <>
      <InversedSearchField
        id={`search-${dataTypeLowerCase}`}
        className="swiper-no-swiping"
        placeholder={placeholder}
        onKeyDown={refetchOnEnter}
        {...rest}
      />
      <input type="submit" hidden />
    </>
  );
};
