import { openMintingAlert } from "@js/apps/nft/components/minting-alert";
import type { User } from "@js/types/auth";

export const useEmployerGuardCheck = (user: User | null) => {
  if (user?.employer) {
    openMintingAlert({
      type: "error",
      message:
        "Client account detected. Minting requires approved talent account",
      persist: true,
    });

    return {
      buttonText: "Requires talent account",
      disabled: true,
    };
  }
};
