import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";

import { Loader, Typography } from "@hexocean/braintrust-ui-components";
import { useMediaQuery } from "@hexocean/braintrust-ui-components/hooks";
import { useMessagesContext } from "@js/apps/messenger/context";
import type { MessengerContextData } from "@js/apps/messenger/context/messenger-context";

import { useRoomList } from "../../hooks/use-room-list";

import { RoomListItems } from "./room-list-items";
import { RoomListSearch } from "./search";

export type RoomListProps = MessengerContextData & {
  initialRoom?: number;
  isActiveRoomLoading: boolean;
};

export const RoomList = ({
  activeRoom,
  setActiveRoom,
  isActiveRoomLoading,
}: RoomListProps) => {
  const {
    isRoomListFetching,
    roomList,
    search,
    isSearching,
    setSearch,
    fetchNextRooms,
    hasMore,
  } = useRoomList({ activeRoom });
  const [searchParams, setSearchParams] = useSearchParams();
  const { setTextboxPlaceholder, resetTextBoxPlaceholder } =
    useMessagesContext();

  const isMobile = useMediaQuery("md");

  useEffect(() => {
    if (!isMobile && !activeRoom && roomList?.length && !isRoomListFetching) {
      // https://github.com/remix-run/react-router/issues/7460
      const timeout = setTimeout(() => setActiveRoom(roomList[0].id), 0);

      return () => clearTimeout(timeout);
    }
  }, [activeRoom, isMobile, isRoomListFetching, roomList, setActiveRoom]);

  useEffect(() => {
    const textBoxPlaceholder = searchParams.get("textboxPlaceholder");

    if (textBoxPlaceholder) {
      setTextboxPlaceholder(textBoxPlaceholder);
      searchParams.delete("textboxPlaceholder");
      setSearchParams(searchParams, { replace: true });
    }
  }, [searchParams, setSearchParams, setTextboxPlaceholder]);

  const handleChangeRoom = (roomId?: number) => {
    resetTextBoxPlaceholder();
    setActiveRoom(roomId);
  };

  const isLoading = isActiveRoomLoading || isRoomListFetching;
  const hasNoRooms = !roomList?.length && !isSearching;
  const showEmptyRoomListNote = !isLoading && hasNoRooms;
  const showNoResults = !isLoading && !roomList.length;
  const showRoomsList = !!roomList.length && !isActiveRoomLoading;
  const showLoader = isLoading && !roomList.length;

  if (showEmptyRoomListNote) {
    return (
      <div className="room-list">
        <Typography component="p" className="room-list__no-items">
          After you have sent or received a message, the conversation is stored
          here for easy access.
        </Typography>
      </div>
    );
  }

  return (
    <div className="room-list">
      <RoomListSearch value={search} onChange={setSearch} />
      {showRoomsList && (
        <RoomListItems
          roomList={roomList}
          activeRoom={activeRoom}
          onChangeRoom={handleChangeRoom}
          hasMore={hasMore}
          fetchNextRooms={fetchNextRooms}
          fetchingRooms={isRoomListFetching}
          isSearching={isSearching}
        />
      )}
      {showNoResults && (
        <Typography component="p" className="room-list__no-items">
          No results.
        </Typography>
      )}
      {showLoader && <Loader centered />}
    </div>
  );
};
