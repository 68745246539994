import type { PostHashtag } from "@js/apps/give-and-get-help/types";

import { areHashtagsEqual } from "./are-hashtags-equal";

type TextWithHashtags = {
  text: string;
  hashtags?: PostHashtag[];
};

export const splitTextByHashtags = (text: string): string[] => {
  const hashtagRegExp = /(#\w+)/gi;
  const textArray = text.split(hashtagRegExp);
  return textArray;
};

export const parseTextWithHashtags = ({ text, hashtags }: TextWithHashtags) => {
  if (!hashtags || !hashtags.length) return text;

  const uniqueHashtagsIds = new Set();

  const textArray = splitTextByHashtags(text);

  const parsedTextArray = textArray.map((textFragment) => {
    const hashtagData = hashtags.find((hashtag) =>
      areHashtagsEqual(textFragment, hashtag.hashtag_name),
    );

    if (hashtagData) {
      if (!uniqueHashtagsIds.has(hashtagData.id)) {
        uniqueHashtagsIds.add(hashtagData.id);
        return `<span
        class="post-hashtag-link"
        data-id="${hashtagData.id}"
        data-hashtag_id="${hashtagData.hashtag_id}"
        data-hashtag_name="${hashtagData.hashtag_name}"
      >${textFragment}</span> `;
      }
    }

    return textFragment;
  });

  return parsedTextArray.join("");
};
