import { createFiltersForm, FiltersManager } from "@js/apps/filters";

type InvoiceJobFiltersFormValues = {
  job?: number;
};

const defaultInvoiceJobFiltersFormValues: InvoiceJobFiltersFormValues = {
  job: undefined,
};

export const InvoiceJobFiltersInstance =
  createFiltersForm<InvoiceJobFiltersFormValues>(
    new FiltersManager({
      defaultFormValues: defaultInvoiceJobFiltersFormValues,
      submitOnChange: true,
    }),
    {
      className: "invoices-listing-filter-job",
    },
  );
