import { useUser } from "@js/apps/common/hooks";
import {
  findCurrentNavItem,
  getNavMenuColorVariant,
} from "@js/apps/dashboard/nav-items";
import { getEmployerNavItems } from "@js/apps/employer/utils";

export const useEmployerSidebar = () => {
  const user = useUser();
  const canViewInvoices = !!user?.can_view_employer_invoices;

  const EMPLOYER_NAV_ITEMS = getEmployerNavItems({ canViewInvoices });
  const currentLinkItem = findCurrentNavItem({
    items: EMPLOYER_NAV_ITEMS,
    exact: true,
  });
  const currentLinkVariant = getNavMenuColorVariant(currentLinkItem);

  return {
    sidebarItems: EMPLOYER_NAV_ITEMS,
    itemVariant: currentLinkVariant,
  };
};
