import type { AutocompleteRenderInputParams } from "@hexocean/braintrust-ui-components";
import { TextField } from "@hexocean/braintrust-ui-components";

export const HashtagAutocompleteInput = (
  params: AutocompleteRenderInputParams,
) => {
  return (
    <TextField
      {...params}
      className="hashtag-autocomplete-input"
      label="hashtags"
    />
  );
};
