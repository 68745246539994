import { useCallback } from "react";
import type { ReactJSXElement } from "@emotion/react/types/jsx-namespace";

import { useGetPostQuery } from "@js/apps/give-and-get-help/api";
import { PostLocation } from "@js/apps/give-and-get-help/context/post-location";
import { useFollowPost } from "@js/apps/give-and-get-help/hooks/use-follow-post";
import {
  FollowPostLabel,
  UNFOLLOW_POST,
} from "@js/apps/give-and-get-help/hooks/use-follow-post/constants";
import { Snackbar } from "@js/components/snackbar";
import type { IPost } from "@js/types/give-and-get-help";

type SiteNotificationExtraAction = typeof UNFOLLOW_POST;

type PostActionProps = {
  postId: number;
};

type NotificationActionButtonProps = {
  actionType: SiteNotificationExtraAction;
  contextData: PostActionProps;
};

const buttonMap: Record<
  SiteNotificationExtraAction,
  (props) => ReactJSXElement
> = {
  [UNFOLLOW_POST]: (props) => (
    <PostLocation.Provider value={PostLocation.Values.notification}>
      <UnfollowPostButton postId={props.post_id} />
    </PostLocation.Provider>
  ),
};

const UnfollowPostButton = ({ postId }: PostActionProps) => {
  const { handleFollowPost } = useFollowPost();
  const {
    data: post,
    isLoading,
    isFetching,
  } = useGetPostQuery({
    id: Number(postId),
  });

  const unfollowPost = useCallback(
    (e): Promise<void> => {
      e.preventDefault();
      return (
        handleFollowPost(post as IPost)
          /* snackbar handled in update recipe */
          .catch(() => Snackbar.error("Something went wrong."))
      );
    },
    [post, handleFollowPost],
  );
  const showButton = !isLoading && !isFetching && post?.followed;
  return showButton ? (
    <button
      className="site-notification-content__title site-notification-content__button"
      onClick={unfollowPost}
    >
      {FollowPostLabel[UNFOLLOW_POST]}
    </button>
  ) : null;
};

export const NotificationActionButton = (
  props: NotificationActionButtonProps,
) => {
  return <>{buttonMap[props.actionType](props.contextData)}</>;
};
