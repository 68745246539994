import _ from "underscore";

import { IconButton, Menu } from "@hexocean/braintrust-ui-components";
import { MoreVertIcon } from "@hexocean/braintrust-ui-components/Icons";
import type { InvoiceFiltersFetchParams } from "@js/apps/invoices/filters";
import { handleDownloadFile } from "@js/utils";

type FreelancerInvoicesActionsMenuProps = {
  filters: Partial<InvoiceFiltersFetchParams>;
};

export const FreelancerInvoicesActionsMenu = ({
  filters,
}: FreelancerInvoicesActionsMenuProps) => {
  const downloadCSV = () => {
    const filtersWithoutPage = _.omit(filters, "page");
    handleDownloadFile({
      endpoint: "/api/freelancer_invoices/",
      params: {
        ...filtersWithoutPage,
        format: "csv",
      },
    });
  };

  return (
    <Menu
      id="invoices-listing-secondary-actions-menu"
      anchor={
        <IconButton
          aria-controls="invoices-listing-secondary-actions-menu"
          aria-haspopup="true"
          aria-label="invoice secondary actions"
          variant="transparent"
        >
          <MoreVertIcon />
        </IconButton>
      }
    >
      <Menu.Item key="downloadCSV" onClick={downloadCSV}>
        Download as CSV
      </Menu.Item>
    </Menu>
  );
};
