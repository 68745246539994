import { useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";

import { Box } from "@hexocean/braintrust-ui-components";
import type { TalentFiltersFetchParams } from "@js/apps/common/components/filters";
import {
  FiltersBottomActionsContainer,
  FiltersBottomContainer,
  FiltersCarousel,
  FiltersCarouselContainer,
  FiltersClearAllButton,
  FiltersSortButton,
  ResultsCount,
} from "@js/apps/common/components/filters/components";
import { SEARCH_FILTER_DATA_TYPES } from "@js/apps/common/components/filters/constants";
import {
  RoleFilter,
  SearchFilter,
  ServicesFilter,
  SkillsFilter,
  useResetServicesFilterParamIfEmployer,
} from "@js/apps/common/components/filters/fields";
import { WorkingTimezoneTalentsFilter } from "@js/apps/common/components/filters/fields/working-timezone-talents-filter";
import { YearsOfExperienceFilter } from "@js/apps/common/components/filters/fields/years-of-experience-filter";
import { SaveTalentsFilters } from "@js/apps/common/components/save-filters/save-talents-filters";
import { SearchFilterLocationContext } from "@js/apps/common/context/search-filter-location-context";
import { useAccountType, useIsNodeStaff } from "@js/apps/common/hooks";
import { TALENT_LOCATION } from "@js/apps/freelancer/constants";
import { TalentLocationFilter } from "@js/apps/freelancer/forms/fields/talent-location-filter";
import {
  ApprovalStatusFilter,
  AvailabilityFilter,
} from "@js/apps/freelancer/views/talent-list/fields";
import { savedFilterLoaded } from "@js/apps/jobs/apps/listing/actions";
import { useGetActiveFilterName } from "@js/apps/jobs/apps/listing/hooks/active-filter-name";
import { useResetSearchEventQueryId } from "@js/apps/tracking/hooks/use-reset-search-event-query-id";
import { useResetUniversalSearchPhrase } from "@js/apps/universal-search/universal-search-slice";
import { useAppDispatch } from "@js/hooks";
import { LocalStorage } from "@js/services";
import { isNotNullable } from "@js/utils";

type SearchTalentsListFiltersProps = {
  isAnyFilterApplied: boolean;
  count: number;
  total_count?: number;
  filters: Partial<TalentFiltersFetchParams>;
  fetchTalents?: () => void;
};

const location = TALENT_LOCATION.talent_listing;
const searchOption = ENUMS.SearchEventOption.TALENT_SEARCH_BOX;

export const TalentsListFilters = ({
  isAnyFilterApplied,
  count,
  total_count,
  filters,
  fetchTalents,
}: SearchTalentsListFiltersProps) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const isNodeStaff = useIsNodeStaff();
  const { isFreelancer, isEmployer } = useAccountType();
  const { savedFilterName } = useGetActiveFilterName(location);
  const clearUniversalSearch = useResetUniversalSearchPhrase();

  const items = useMemo(
    () =>
      getTalentsFiltersItems({
        isFreelancer,
        isNodeStaff,
        isEmployer,
        fetchTalents,
      }),
    [isFreelancer, isNodeStaff, isEmployer, fetchTalents],
  );

  const { resetSearchEventQueryId } = useResetSearchEventQueryId();

  // cleanup effect
  useEffect(
    () => () => resetSearchEventQueryId(searchOption),
    [resetSearchEventQueryId],
  );

  const clearFilters = () => {
    clearUniversalSearch();
    resetSearchEventQueryId(searchOption);
    dispatch(
      savedFilterLoaded({
        location,
        name: null,
      }),
    );
    LocalStorage.removeItem(LocalStorage.keys.TALENT_LISTING_FILTERS);
    navigate(window.location.pathname);
  };

  useResetServicesFilterParamIfEmployer();

  return (
    <SearchFilterLocationContext.Provider value={searchOption}>
      <FiltersCarouselContainer>
        <FiltersCarousel items={items} />
      </FiltersCarouselContainer>
      <FiltersBottomContainer>
        <FiltersBottomActionsContainer>
          <ResultsCount
            dataType={SEARCH_FILTER_DATA_TYPES.talent}
            count={count}
            total_count={total_count}
          />
          {isAnyFilterApplied && (
            <>
              <SaveTalentsFilters
                savedFilterName={savedFilterName}
                filters={filters}
                location={location}
              />
              <FiltersClearAllButton onClick={clearFilters} />
            </>
          )}
        </FiltersBottomActionsContainer>
        <FiltersSortButton location={location} defaultValue="-score" />
      </FiltersBottomContainer>
    </SearchFilterLocationContext.Provider>
  );
};

const getTalentsFiltersItems = ({
  isNodeStaff,
  isFreelancer,
  isEmployer,
  fetchTalents,
}: {
  isNodeStaff: boolean;
  isFreelancer: boolean;
  isEmployer: boolean;
  fetchTalents?: () => void;
}) => {
  return [
    <Box key="search-filter" maxWidth={270}>
      <SearchFilter
        dataType={SEARCH_FILTER_DATA_TYPES.talent}
        refetch={fetchTalents}
        placeholder="Search talent"
      />
    </Box>,
    <RoleFilter key="role-filter" />,
    <SkillsFilter key="skills-filter" />,
    <TalentLocationFilter key="talent-location-filter" />,
    isEmployer || isNodeStaff ? (
      <WorkingTimezoneTalentsFilter key="working-timezone-talents-filter" />
    ) : null,
    isNodeStaff ? <ApprovalStatusFilter key="approval-status-filter" /> : null,
    isEmployer || isNodeStaff ? (
      <AvailabilityFilter key="availability-filter" />
    ) : null,
    <YearsOfExperienceFilter
      key="years-of-experience-filter"
      location={location}
    />,
    isFreelancer || isNodeStaff ? (
      <ServicesFilter key="services-filter" />
    ) : null,
  ].filter(isNotNullable);
};
