import React, { useState } from "react";

import {
  Box,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@hexocean/braintrust-ui-components";
import { MenuArrowDownIcon } from "@hexocean/braintrust-ui-components/Icons";

import { InsightTooltip } from "./insight-tooltip";
import {
  formatMatchLabel,
  getMatchLabelColors,
  useScoreMatchBadge,
} from "./utils";

type ScoreMatchBadgeProps = {
  jobId: number;
  matchLabel: EnumType<typeof ENUMS.SmartMatchingLabel> | null;
};

export const ScoreMatchBadge = ({
  jobId,
  matchLabel,
}: ScoreMatchBadgeProps) => {
  const [showInsightTooltip, setShowInsightTooltip] = useState<boolean>(false);
  const { user, showBadge } = useScoreMatchBadge(matchLabel);
  const matchLabelStyleProps = getMatchLabelColors(matchLabel, true);

  const isMobile = useMediaQuery("sm");

  if (!user || !showBadge || !matchLabel) {
    return null;
  }

  return (
    <Tooltip
      slotProps={{
        tooltip: {
          sx: {
            borderRadius: "24px",
            backgroundColor: "var(--white)",
            border: "1px solid var(--soft-beige)",
            padding: "20px",
          },
        },
        arrow: {
          sx: {
            fontSize: "1.5em",
            "&::before": {
              border: "1px solid var(--soft-beige)",
              backgroundColor: "var(--white)",
            },
          },
        },
      }}
      variant="white"
      title={<InsightTooltip jobId={jobId} matchLabel={matchLabel} />}
      placement={isMobile ? "bottom" : "right"}
      onOpen={() => setShowInsightTooltip(true)}
      onClose={() => setShowInsightTooltip(false)}
      open={showInsightTooltip}
    >
      <Box
        role="button"
        sx={{
          backgroundColor: matchLabelStyleProps.backgroundColor,
          "&:hover": {
            cursor: "pointer",
          },
        }}
        borderRadius={5}
        alignItems="center"
        display="flex"
        marginTop={1}
        paddingY={0.75}
        paddingLeft={1.75}
        paddingRight={1}
      >
        <Box>
          <Typography
            fontWeight={500}
            component="span"
            variant="paragraph"
            size="small"
            sx={{
              userSelect: "none",
              color: matchLabelStyleProps.textColor,
            }}
          >
            {formatMatchLabel(matchLabel)}
          </Typography>
          <Box
            marginTop={-0.5}
            marginBottom={0.5}
            borderTop={`1px dashed ${matchLabelStyleProps.textColor}`}
          />
        </Box>
        <MenuArrowDownIcon
          sx={{
            flex: 1,
            fontSize: "16px",
            color: matchLabelStyleProps.textColor,
            marginRight: "2px",
            strokeWidth: "2.5 !important",
          }}
        />
      </Box>
    </Tooltip>
  );
};
