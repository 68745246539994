import React from "react";

import { Box, Loader } from "@hexocean/braintrust-ui-components";
import { Form } from "@js/forms/components/form";
import type { ReduxFormConfiguration } from "@js/forms/fields";
import { reduxForm } from "@js/forms/fields";
import type { OverwriteContentComponentProps } from "@js/layouts/app";
type FormLayoutWrapperProps = OverwriteContentComponentProps & {
  formId: string;
  formProps: Partial<ReduxFormConfiguration>;
  loading?: boolean;
  children: React.ReactNode;
  aboveContentFullWidthSocket?: React.ReactNode;
  enableReinitialize?: boolean;
};

export const FormLayoutWrapper = ({
  alertsElement,
  contentBoxProps,
  headerBoxProps,
  formId,
  formProps,
  loading,
  children,
  aboveContentFullWidthSocket,
}: FormLayoutWrapperProps) => {
  if (loading) {
    return (
      <Box p={8}>
        <Loader />
      </Box>
    );
  }

  return (
    <LayoutGenericForm form={formId} {...formProps}>
      <Box
        id="subheader-portal"
        position="sticky"
        bgcolor="var(--white)"
        {...headerBoxProps}
      />
      {alertsElement}
      {aboveContentFullWidthSocket}
      <Box {...contentBoxProps}>{children}</Box>
    </LayoutGenericForm>
  );
};

export const LayoutGenericForm = reduxForm<any>()(({
  children,
  submitting,
  submit,
  error,
  form,
}) => {
  return (
    <Form
      id={form}
      error={error}
      onSubmit={submitting ? null : submit}
      preventSubmitOnEnter
    >
      {children}
    </Form>
  );
});
