import React, { useEffect } from "react";
import {
  Navigate,
  Route,
  useNavigate,
  useSearchParams,
} from "react-router-dom";

import { PROF_NET_ONBOARDING_PATHS } from "@js/apps/onboarding/constants";
import { isEmployerParamUsed } from "@js/apps/onboarding/utils";
import { ACCESS_RULE } from "@js/routes/constants";
import { ProtectedRoute } from "@js/routes/protected-route";

import { OnboardingFormWrapper } from "../components";
import {
  OnboardingCreateAccountPage,
  OnboardingGoalsPage,
  OnboardingInterestsPage,
} from "../views";
import { TestimonialsCarousel } from "../views/layout/user-comments-carousel";

const IndexRouteRedirect = () => {
  const [params] = useSearchParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (isEmployerParamUsed(params)) {
      navigate(
        {
          pathname: PROF_NET_ONBOARDING_PATHS.CREATE_ACCOUNT_PAGE.path,
          search: params.toString(),
        },
        { replace: true },
      );
    } else {
      navigate(
        {
          pathname: PROF_NET_ONBOARDING_PATHS.GOALS_PAGE.path,
          search: params.toString(),
        },
        { replace: true },
      );
    }
  }, [navigate, params]);

  return null;
};
export const OnboardingRoutes = (
  <>
    <Route
      path=""
      element={
        <ProtectedRoute rules={[ACCESS_RULE.NOT_AUTHENTICATED_OR_IN_IFRAME]}>
          <OnboardingFormWrapper />
        </ProtectedRoute>
      }
    >
      <Route index element={<IndexRouteRedirect />} />
      <Route
        path={PROF_NET_ONBOARDING_PATHS.GOALS_PAGE.routePath}
        element={
          <ProtectedRoute rules={[ACCESS_RULE.NOT_AUTHENTICATED_OR_IN_IFRAME]}>
            <OnboardingGoalsPage />
            <TestimonialsCarousel flow="sign-up" />
          </ProtectedRoute>
        }
      />
      <Route
        path={PROF_NET_ONBOARDING_PATHS.INTERESTS_PAGE.routePath}
        element={
          <ProtectedRoute rules={[ACCESS_RULE.NOT_AUTHENTICATED_OR_IN_IFRAME]}>
            <OnboardingInterestsPage />
            <TestimonialsCarousel flow="sign-up" />
          </ProtectedRoute>
        }
      />
      <Route
        path={PROF_NET_ONBOARDING_PATHS.CREATE_ACCOUNT_PAGE.routePath}
        element={
          <ProtectedRoute rules={[ACCESS_RULE.NOT_AUTHENTICATED_OR_IN_IFRAME]}>
            <OnboardingCreateAccountPage />
            <TestimonialsCarousel flow="sign-up" />
          </ProtectedRoute>
        }
      />
      <Route path="*" element={<Navigate to="/page-not-found" replace />} />
    </Route>
  </>
);
