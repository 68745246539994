import { Navigate, Route } from "react-router-dom";

import { ACCESS_RULE } from "@js/routes/constants";
import { ProtectedRoute } from "@js/routes/protected-route";

import { NFTLayout } from "./views/layout";
import { NFTManagementPage } from "./views/management";
import { NFTMembershipPage } from "./views/membership";
import { NFTMintPage } from "./views/mint";

export const NFTRoutes = [
  <Route path="mint" key="mint" element={<NFTLayout />}>
    <Route index element={<NFTMembershipPage />} />
  </Route>,
  <Route path="nft" key="nft" element={<NFTLayout />}>
    <Route index element={<Navigate to="/nft/mint/" />} />
    <Route
      path="mint"
      element={
        <ProtectedRoute rules={[ACCESS_RULE.ACCOUNT_TYPE_SELECTED]}>
          <NFTMintPage />
        </ProtectedRoute>
      }
    />
    <Route path=":tokenId" element={<NFTManagementPage />} />
  </Route>,
];
