import type { MaybeDrafted } from "@reduxjs/toolkit/dist/query/core/buildThunks";

import type { RootState } from "@js/store";
import type * as I from "@js/types/messenger";
import { assertUnreachable } from "@js/utils";

const roomListHelpOfferStatusActions = [
  ENUMS.HelpOfferMessageType.HELP_OFFER_ACCEPTED,
  ENUMS.HelpOfferMessageType.HELP_OFFER_COMPLETED,
  ENUMS.HelpOfferMessageType.HELP_OFFER_DELETED,
  ENUMS.HelpOfferMessageType.HELP_OFFER_CREATED,
  ENUMS.HelpOfferMessageType.HELP_OFFER_EXPIRED,
  ENUMS.HelpOfferMessageType.HELP_OFFER_DECLINED,
];

export const updateHelpOfferCounter = (
  status: EnumType<typeof ENUMS.HelpOfferStatus>,
  roomToUpdate: I.MessageRoom,
) => {
  const pendingHelpOffersCount = Number(roomToUpdate.pending_help_offers_count);
  const activeHelpOffersCount = Number(roomToUpdate.active_help_offers_count);

  switch (status) {
    case ENUMS.HelpOfferStatus.PENDING:
      roomToUpdate.pending_help_offers_count = pendingHelpOffersCount + 1;
      break;
    case ENUMS.HelpOfferStatus.ACCEPTED:
      roomToUpdate.pending_help_offers_count = Math.max(
        0,
        pendingHelpOffersCount - 1,
      );
      roomToUpdate.active_help_offers_count = activeHelpOffersCount + 1;
      break;
    case ENUMS.HelpOfferStatus.COMPLETED:
      roomToUpdate.active_help_offers_count = Math.max(
        0,
        activeHelpOffersCount - 1,
      );
      break;
    /* TODO decline logic is not implemented at the time of create this comment.
     *  It needs to be verify when Decline is logic implemented
     */
    case ENUMS.HelpOfferStatus.PENDING_APPROVAL:
    case ENUMS.HelpOfferStatus.REFUND_REQUESTED:
    case ENUMS.HelpOfferStatus.DECLINED:
    case ENUMS.HelpOfferStatus.DELETED:
    case ENUMS.HelpOfferStatus.EXPIRED:
      roomToUpdate.pending_help_offers_count = Math.max(
        0,
        pendingHelpOffersCount - 1,
      );
      break;
    case ENUMS.HelpOfferStatus.CREATED:
      break;
    default:
      assertUnreachable(status);
  }
};
type PatchRoomOnMessageReceiveArg = {
  message: I.Message;
  userId: number;
  room: MaybeDrafted<I.MessageRoom>;
};
const patchRoomOnMessageReceive = ({
  message,
  userId,
  room,
}: PatchRoomOnMessageReceiveArg) => {
  const shouldUpdateMessageCount = checkIfShouldUpdateCounter(userId, message);
  if (shouldUpdateMessageCount) {
    room.unread_message_count += 1;
  }

  room.last_message_id = message.id;
  room.last_message_content = message.last_message_content || "";
  room.last_message_timestamp = message.sent;

  if (room.should_show_calendar_link_prompt && message.calendar_link) {
    room.should_show_calendar_link_prompt = false;
  }

  if (message.message_type === ENUMS.MessageType.USER_REPORTED) {
    room.show_contact_support = true;
  }

  if (message.message_type === ENUMS.MessageType.USER_REPORTS_CANCELLED) {
    room.show_contact_support = false;
  }

  room.is_last_message_spam = !!message.is_spam;
  const messageAuthorId = message.author?.id;
  if (messageAuthorId) {
    room.last_message_author_id = messageAuthorId;
  }

  const isHelpOfferMessageType = roomListHelpOfferStatusActions.some(
    (type) => type === message.message_type,
  );

  if (
    message.message_type &&
    isHelpOfferMessageType &&
    message.system_message_target &&
    "status" in message.system_message_target
  ) {
    updateHelpOfferCounter(message.system_message_target.status, room);
  }
};

export const patchMessageRoomOnMessageSpamStatusChange = ({
  eventData,
  room,
}: {
  eventData: I.MessageSpamStatusEvent;
  room: MaybeDrafted<I.GetRoomReturnType>;
}) => {
  const isUpdatedMessageLast = room.last_message_id === eventData.message_id;
  if (!isUpdatedMessageLast) {
    return;
  }

  room.is_last_message_spam = eventData.is_marked_as_spam;
};

export const patchMessageRoomsOnMessageSpamStatusChange = ({
  eventData,
  roomList,
}: {
  eventData: I.MessageSpamStatusEvent;
  roomList: MaybeDrafted<I.GetRoomListReturnType>;
}) => {
  const updatedRoom = roomList.results.find(
    (message) => message.id === eventData.room_id,
  );
  if (!updatedRoom) {
    return;
  }

  patchMessageRoomOnMessageSpamStatusChange({
    eventData,
    room: updatedRoom,
  });
};

export const patchMessagesOnMessageSpamStatusChange = ({
  eventData,
  draft,
}: {
  eventData: I.MessageSpamStatusEvent;
  draft: Draft;
}) => {
  const updatedMessage = draft.results.find(
    (message) => message.id === eventData.message_id,
  );

  if (!updatedMessage) {
    return;
  }

  updatedMessage.is_spam = eventData.is_marked_as_spam;
};

export const patchRoomListOnMessageReceive = ({
  message,
  userId,
  roomList,
}: Pick<PatchRoomOnMessageReceiveArg, "message" | "userId"> & {
  roomList: MaybeDrafted<I.GetRoomListReturnType>;
}) => {
  const room = roomList.results.find((el) => el.id === message.room);
  if (!room) {
    return;
  }

  patchRoomOnMessageReceive({ message, room, userId });
};

export const patchMessagesOnHelpOfferChange = (
  message_id: number,
  status: EnumType<typeof ENUMS.HelpOfferStatus>,
  draft: MaybeDrafted<I.RoomMessagesPaginatedResult>,
) => {
  const draftMessages = draft.results;
  const message = draftMessages.find(
    (draftMessage) => draftMessage.id === message_id,
  );
  const messageIndex = draft.results?.findIndex(
    (draftMessage) => draftMessage.id === message_id,
  );

  if (status === ENUMS.HelpOfferStatus.DELETED && messageIndex > -1) {
    draftMessages[messageIndex].help_offer = null;
  }

  if (message?.help_offer?.status && message?.help_offer?.status !== status) {
    message.help_offer.status = status;
  }
};

export const patchMessagesOnSystemMessageReceive = (
  incomingMessage: I.Message,
  draft: MaybeDrafted<{
    previous: string | null;
    next: string | null;
    results: I.Message[];
  }>,
) => {
  const offerId = incomingMessage.id;
  const draftMessages = draft.results;
  for (const message of draftMessages) {
    if (offerId === message.id) continue;

    message.system_message_active = false;
  }
};

export const checkIfShouldUpdateCounter = (
  userId: number,
  message: I.Message,
) => {
  const isSystemMessage = !!message.system_message_target;
  const isReportedUserMessageType =
    message.message_type === ENUMS.MessageType.USER_REPORTED;
  const isMessageReportedMessageType =
    message.message_type === ENUMS.MessageType.MESSAGE_REPORTED;
  const isReportCancelledMessageType =
    message.message_type === ENUMS.MessageType.USER_REPORTS_CANCELLED;

  if (!isSystemMessage) {
    const isNotAuthor = !!message.author && userId !== message.author.id;

    return isNotAuthor && !!message.content;
  }

  if (isReportedUserMessageType) {
    return isReportedUserMessageType;
  }

  if (isMessageReportedMessageType) {
    return isMessageReportedMessageType;
  }

  if (isReportCancelledMessageType) {
    return isReportCancelledMessageType;
  }

  const isOfferAuthor = message.system_message_target.author_id === userId;
  const isOfferReceiver = message.system_message_target.receiver.id === userId;

  return isOfferAuthor || isOfferReceiver;
};

type Draft = MaybeDrafted<I.GetRoomMessagesReturnType>;

export const roomMessagesUpdateRecipe =
  (cursor: boolean, roomId?: number) =>
  (event: MessageEvent<I.RoomMessageEvent>, draft: Draft) => {
    if (cursor) {
      return;
    }

    const eventData = event.data;
    const broadcast_type = eventData.broadcast_type;

    switch (broadcast_type) {
      case ENUMS.BroadcastType.MESSAGE_SPAM_STATUS: {
        patchMessagesOnMessageSpamStatusChange({ eventData, draft });
        return;
      }
      case ENUMS.BroadcastType.MESSAGE: {
        const message = eventData.message;
        if (!message || roomId !== message.room) {
          return;
        }

        const indexOfExistingMessageWithTheSameId = draft.results.findIndex(
          (existingMessage) => existingMessage.id === message.id,
        );
        if (indexOfExistingMessageWithTheSameId > -1) {
          draft.results[indexOfExistingMessageWithTheSameId] = message;
        } else {
          draft.results.unshift(message);
        }

        delete message.optimistic_id;

        if (!!message.system_message_target) {
          patchMessagesOnSystemMessageReceive(message, draft);
        }

        return;
      }
      case ENUMS.BroadcastType.HELP_OFFER_UPDATED: {
        const status = eventData.data?.status;
        const messageId = eventData.data?.message_id;

        if (status && messageId) {
          patchMessagesOnHelpOfferChange(messageId, status, draft);
        }

        return;
      }

      default:
        return;
    }
  };

export const roomUpdateRecipe =
  ({ getState }: { getState: () => RootState }) =>
  (
    event: MessageEvent<I.RoomMessageEvent>,
    draft: MaybeDrafted<I.GetRoomReturnType>,
  ) => {
    const eventData = event.data;
    const broadcast_type = eventData.broadcast_type;

    switch (broadcast_type) {
      case ENUMS.BroadcastType.MESSAGE_SPAM_STATUS: {
        const { room_id } = eventData;
        if (room_id !== draft.id) {
          return;
        }

        patchMessageRoomOnMessageSpamStatusChange({
          eventData,
          room: draft,
        });

        return;
      }
      case ENUMS.BroadcastType.MESSAGE: {
        const state = getState();
        const user = state.auth.user;
        const message = eventData.message;
        if (!message || !user || message.room !== draft.id) {
          return;
        }

        patchRoomOnMessageReceive({ message, room: draft, userId: user.id });

        return;
      }
      case ENUMS.BroadcastType.CALENDAR_LINK_PROMPT_SHOWN: {
        const { room_id } = eventData;
        if (draft.id !== room_id) {
          return;
        }

        draft.should_show_calendar_link_prompt = false;

        return;
      }
      case ENUMS.BroadcastType.MESSAGE_MARK_AS_READ: {
        const { room_id } = eventData;
        if (draft.id !== room_id) {
          return;
        }

        draft.unread_message_count = 0;

        return;
      }
      default:
        return;
    }
  };

export const roomListUpdateRecipe =
  ({ getState }: { getState: () => RootState }) =>
  (
    event: MessageEvent<I.RoomMessageEvent>,
    draft: MaybeDrafted<I.GetRoomListReturnType>,
  ) => {
    const eventData = event.data;
    const broadcast_type = eventData.broadcast_type;

    switch (broadcast_type) {
      case ENUMS.BroadcastType.MESSAGE_SPAM_STATUS: {
        patchMessageRoomsOnMessageSpamStatusChange({
          eventData,
          roomList: draft,
        });
        return;
      }
      case ENUMS.BroadcastType.MESSAGE: {
        const message = eventData.message;
        const state = getState();
        const user = state.auth.user;
        if (!message || !user) {
          return;
        }
        patchRoomListOnMessageReceive({
          message,
          userId: user.id,
          roomList: draft,
        });
        return;
      }
      case ENUMS.BroadcastType.CALENDAR_LINK_PROMPT_SHOWN: {
        const { room_id } = eventData;

        draft.results.map((room) => {
          if (room_id === room.id) {
            room.should_show_calendar_link_prompt = false;
          }

          return room;
        });

        return;
      }
      case ENUMS.BroadcastType.MESSAGE_MARK_AS_READ: {
        const { room_id } = eventData;

        draft.results.map((room) => {
          if (room_id === room.id) {
            room.unread_message_count = 0;
          }

          return room;
        });

        return;
      }
      default:
        return;
    }
  };

export const unreadMessagesCountUpdateRecipe =
  (getState: () => RootState) =>
  (
    event: MessageEvent<I.RoomMessageEvent>,
    draft: MaybeDrafted<I.GetUnreadMessagesCountReturnType>,
  ) => {
    const eventData = event.data;
    const broadcast_type = eventData.broadcast_type;

    switch (broadcast_type) {
      case ENUMS.BroadcastType.MESSAGE_MARK_AS_READ: {
        const { messages_count: readMessagesCount = 0 } = eventData;

        draft.unread_messages_count =
          draft.unread_messages_count - readMessagesCount;

        return;
      }
      case ENUMS.BroadcastType.MESSAGE: {
        patchUnreadMessagesCountOnMessageReceive({
          eventData,
          getState,
          draft,
        });

        return;
      }
      default:
        return;
    }
  };

const patchUnreadMessagesCountOnMessageReceive = ({
  getState,
  eventData,
  draft,
}: {
  getState: () => RootState;
  eventData: I.MessengerMessageEvent;
  draft: MaybeDrafted<I.GetUnreadMessagesCountReturnType>;
}) => {
  if (!eventData.message) {
    return;
  }
  const state = getState();
  const user = state.auth.user;

  if (!user) {
    return;
  }

  const shouldUpdateMessageCount = checkIfShouldUpdateCounter(
    user.id,
    eventData.message,
  );

  if (!shouldUpdateMessageCount) {
    return;
  }

  draft.unread_messages_count = draft.unread_messages_count + 1;
};
