import { Field } from "redux-form";

import { StandardTextareaField } from "@js/forms/fields";

export const NotesToRecipientField = () => {
  return (
    <Field
      className="add-invoice-form-notes-field"
      variant="standard"
      label="NOTES TO RECIPIENT"
      placeholder="Notes"
      InputLabelProps={{ shrink: true }}
      id="notes_to_receipt"
      name="notes_to_receipt"
      rows={3}
      component={StandardTextareaField}
    />
  );
};
