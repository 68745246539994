import React, { useMemo } from "react";
import { getFormValues, reset, SubmissionError } from "redux-form";
import type { CountryCode } from "libphonenumber-js";

import { fetchCurrentUser } from "@js/apps/auth/actions";
import { US_COUNTRY_CODE } from "@js/apps/common/constants";
import { useUser } from "@js/apps/common/hooks";
import { Snackbar } from "@js/components/snackbar";
import { getValuesWithReCaptchaCode } from "@js/forms/utils";
import { useAppDispatch, useAppSelector } from "@js/hooks/redux";

import { UpdatePhoneNumberVerificationForm } from "./modal/update-phone-number-verification-form";
import type {
  PhoneVerificationFormValues,
  VerificationData,
} from "./phone-verification-form";
import { MOBILE_NUMBER_FORM_ID } from "./phone-verification-form";
import {
  PhoneVerificationForm,
  UpdatePhoneNumberVerificationModal,
} from "./phone-verification-form";
import {
  mapPhoneVerificationErrors,
  onboardingCheckPhoneVerificationCode,
  onboardingSendPhoneVerificationCode,
} from "./service";

export const PhoneVerification = () => {
  const dispatch = useAppDispatch();
  const user = useUser();
  const phoneNumberFormValues = useAppSelector(
    getFormValues(MOBILE_NUMBER_FORM_ID),
  ) as PhoneVerificationFormValues | undefined;

  const onSubmit = async (values: PhoneVerificationFormValues) => {
    const valuesWithCaptcha = await getValuesWithReCaptchaCode(
      values.phone_number,
      ENUMS.CaptchaActions.PHONE_VERIFICATION,
    );
    await onboardingSendPhoneVerificationCode(valuesWithCaptcha)
      .then(() => {
        UpdatePhoneNumberVerificationModal.open({
          children: (
            <UpdatePhoneNumberVerificationForm
              onSubmitVerification={onSubmitCheckVerificationCode}
              onCancel={UpdatePhoneNumberVerificationModal.close}
              phoneNumber={values.phone_number.phone_number}
              is_otp_enabled={user?.is_otp_enabled}
            />
          ),
        });
      })
      .catch((submissionError: SubmissionError) => {
        if (submissionError.errors) {
          throw submissionError;
        }

        Snackbar.error("Sorry, something went wrong. Try again later.");
      });
  };

  const onSubmitCheckVerificationCode = async (
    verificationData: VerificationData,
  ) => {
    if (!phoneNumberFormValues || !isVerificationDataValid(verificationData)) {
      return;
    }

    const valuesWithVerification = {
      ...verificationData,
      ...phoneNumberFormValues?.phone_number,
    };

    try {
      await onboardingCheckPhoneVerificationCode(valuesWithVerification);

      dispatch(reset(MOBILE_NUMBER_FORM_ID));
      await dispatch(fetchCurrentUser());

      UpdatePhoneNumberVerificationModal.close();
      Snackbar.success("Phone Number Successfully Updated.");
    } catch (error) {
      if (!(error instanceof SubmissionError)) {
        Snackbar.error("Sorry, something went wrong. Try again later.");

        return;
      }

      const mapped = mapPhoneVerificationErrors(error);
      if (mapped._error) {
        throw new SubmissionError(mapped);
      }
    }
  };

  const initialValues = useMemo(() => {
    if (!user) {
      return;
    }

    const initialCountryCode =
      user.phone_number?.country_iso2 ||
      user.address?.country ||
      US_COUNTRY_CODE;

    return {
      phone_number: {
        country_iso2: initialCountryCode as CountryCode,
        phone_number: user.phone_number?.phone_number || "",
      },
    };
  }, [user]);

  return (
    <PhoneVerificationForm
      onSubmit={onSubmit}
      initialValues={initialValues}
      isPhoneNumberSet={!!user?.phone_number?.id}
    />
  );
};

const isVerificationDataValid = (
  verificationData: VerificationData,
): verificationData is VerificationData & { verification_code: string } => {
  return !!verificationData.verification_code;
};
