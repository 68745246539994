import { useEffect, useMemo, useRef } from "react";
import type { CBPayInstanceType, InitOnRampParams } from "@coinbase/cbpay-js";
import { generateOnRampURL, initOnRamp } from "@coinbase/cbpay-js";

import { RETURNED_FROM_COINBASE_PARAM } from "@js/apps/on-ramp/constants";

export const CONTAINER_ID = "cbpay-container";

type UseCoinbasePayArg = {
  transactionUuid: string;
  walletAddress: string;
  tokensToBuy?: number;
};

export const useCoinbasePay = ({
  transactionUuid,
  walletAddress,
  tokensToBuy,
}: UseCoinbasePayArg) => {
  const timeoutRef = useRef<number>();
  const onRampInstanceOptions: InitOnRampParams = useMemo(
    (): InitOnRampParams => ({
      appId: SETTINGS.COINBASE_API_ID,
      target: `#${CONTAINER_ID}`,
      theme: "light",
      widgetParameters: {
        defaultExperience: "buy",
        presetCryptoAmount: tokensToBuy || 1,
        destinationWallets: [
          {
            address: walletAddress,
            supportedNetworks: ["base"],
            assets: ["BTRST"],
          },
        ],
      },
      embeddedContentStyles: {
        target: `#${CONTAINER_ID}`,
        height: "500px",
        width: "400px",
        position: "static",
      },
      onRequestedUrl: (url) => {
        console.error(url, "Coinbase Url Method");
      },
      onSuccess: () => {
        console.error("Coinbase Success Method");
        // handle navigation when user successfully completes the flow
      },
      onExit: (error) => {
        console.error(`Coinbase Error Method ${error}`);
        // handle navigation from dismiss / exit events due to errors
      },
      onEvent: (event) => {
        console.error(event, "Coinbase Event Method");
      },
      experienceLoggedIn: "embedded",
      experienceLoggedOut: "embedded",
    }),
    [walletAddress, tokensToBuy],
  );
  const onrampInstanceRef = useRef<CBPayInstanceType | null>();
  const initOnRampInstance = () => {
    initOnRamp(
      onRampInstanceOptions,
      (_error: Error | null, instance: CBPayInstanceType | null) => {
        if (!instance) {
          return;
        }
        onrampInstanceRef.current = instance;
        timeoutRef.current = window.setTimeout(() => {
          if (onrampInstanceRef.current) {
            onrampInstanceRef.current.open();
          }
        }, 500);
      },
    );
  };

  // cleanup effect
  useEffect(
    () => () => {
      clearTimeout(timeoutRef.current);
    },
    [],
  );

  const destroyOnRampInstance = () => {
    if (onrampInstanceRef.current) {
      onrampInstanceRef.current.destroy();
      onrampInstanceRef.current = null;
    }
  };

  const onRampURL = useMemo(() => {
    if (!transactionUuid || !walletAddress) {
      return null;
    }

    const generatedUrl = generateOnRampURL({
      appId: SETTINGS.COINBASE_API_ID,
      defaultExperience: "buy",
      presetCryptoAmount: tokensToBuy || 1,
      destinationWallets: [
        {
          address: walletAddress,
          supportedNetworks: ["base"],
          assets: ["BTRST"],
        },
      ],
      partnerUserId: transactionUuid,
    });

    if (!generatedUrl) {
      return null;
    }

    const redirectUrl = `${window.location.origin + window.location.pathname}?${RETURNED_FROM_COINBASE_PARAM}`;
    return `${generatedUrl}&redirectUrl=${encodeURIComponent(redirectUrl)}`;
  }, [transactionUuid, walletAddress, tokensToBuy]);

  return {
    initOnRampInstance,
    destroyOnRampInstance,
    onRampURL,
  };
};
