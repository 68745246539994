import React from "react";

import { Typography } from "@hexocean/braintrust-ui-components";

import { PostJobButton } from "../../views/dashboard/my-jobs/jobs";

import style from "./style.module.scss";

export const PrompToPostJob = ({
  talentCountsToDisplay,
}: {
  talentCountsToDisplay: string;
}) => {
  return (
    <div className={style.promptToPostJob}>
      <Typography
        component="h2"
        variant="title"
        size="small"
        fontWeight={400}
        mb={1}
      >
        Find your next hire
      </Typography>
      <Typography component="p" size="medium" mb={3}>
        More than {talentCountsToDisplay} talent are on the network ready for
        your next project.
      </Typography>
      <PostJobButton style={{ padding: "11px 20px" }} variant="primary" />
    </div>
  );
};
