import type { ReactNode } from "react";

import { Avatar, Box } from "@hexocean/braintrust-ui-components";
import type { EmployerTeamMemberType } from "@js/types/employer";

import { TeamMemberInvitationsItemContainer } from "./team-member-invitations-item-container";
import { TeamMemberInvitationsOptionLabel } from "./team-member-invitations-option-label";

export type TeamMemberInvitationsMemberItemProps = {
  teamMember: EmployerTeamMemberType;
  memberActionButton: ReactNode;
};

export const TeamMemberInvitationsMemberItem = ({
  teamMember,
  memberActionButton,
}: TeamMemberInvitationsMemberItemProps) => {
  const {
    avatar_thumbnail,
    first_name,
    gravatar,
    has_avatar_set,
    last_name,
    public_name,
  } = teamMember;

  return (
    <TeamMemberInvitationsItemContainer>
      <Avatar
        size="full"
        color="medium-violet"
        src={{
          avatar_thumbnail,
          first_name,
          gravatar,
          has_avatar_set,
          last_name,
        }}
      />
      <Box display="flex" gap={1} alignItems="center">
        <TeamMemberInvitationsOptionLabel publicName={public_name} />
      </Box>
      {memberActionButton}
    </TeamMemberInvitationsItemContainer>
  );
};
