import type { Event, StackFrame } from "@sentry/react";
import { isObject } from "underscore";

const itIsFreddyFeedback = (event: Event) => {
  const values = event?.exception?.values;
  if (!values) return false;

  const _itIsFreddyFeedback = values.some((value) => {
    const frames = value.stacktrace?.frames;

    if (!frames) return false;

    const isFramesArray = Array.isArray(frames);

    const conditionMet = (_frame: StackFrame) =>
      isObject(_frame) &&
      _frame.filename === "https://freddyfeedback.com/widget/freddyfeedback.js";

    if (isFramesArray) {
      return frames.some((frame) => conditionMet(frame));
    } else {
      return conditionMet(frames);
    }
  });

  return _itIsFreddyFeedback;
};

export const isErrorUnwantedToLog = (event) => {
  if (itIsFreddyFeedback(event)) {
    return true;
  }
  return false;
};
