import classNames from "classnames";

import { ButtonCore } from "@hexocean/braintrust-ui-components";
import { RouterLink } from "@js/components/link";

import styles from "./style.module.scss";

const OPTIONS = [
  {
    name: "all invoices",
    to: "/employer/invoices/",
  },
  {
    name: "invoice batches",
    to: "/employer/invoices/invoice_batches/",
  },
];

export const InvoicesTypeField = () => {
  return (
    <div className={styles.invoiceTypeFilter}>
      {OPTIONS.map(({ name, to }) => {
        const isActive = window.location.pathname === to;

        return (
          <ButtonCore
            key={to}
            to={to}
            className={classNames(styles.invoiceTypeFilterButton, {
              [styles.invoiceTypeFilterButtonIsActive]: isActive,
            })}
            RouterLink={RouterLink}
          >
            {name}
          </ButtonCore>
        );
      })}
    </div>
  );
};
