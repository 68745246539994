import React, { useEffect, useState } from "react";

import { Box, Loader, Typography } from "@hexocean/braintrust-ui-components";
import { RouterLink } from "@js/components/link";

import { useActiveWeb3React } from "../../hooks";
import { useBlockNumber } from "../../state/application/hooks";
import { getEtherscanLink } from "../../utils";

const Polling = () => {
  const { chainId } = useActiveWeb3React();

  const blockNumber = useBlockNumber();

  const [isMounted, setIsMounted] = useState(true);

  useEffect(
    () => {
      const timer1 = setTimeout(() => setIsMounted(true), 1000);

      // this will clear Timeout when component unmount like in willComponentUnmount
      return () => {
        setIsMounted(false);
        clearTimeout(timer1);
      };
    },
    [blockNumber], //useEffect will run only one time
    //if you pass a value to array, like this [data] than clearTimeout will run every time this value changes (useEffect re-run)
  );

  return (
    <Typography
      component="a"
      variant="link"
      size="small"
      href={
        chainId && blockNumber
          ? getEtherscanLink(chainId, blockNumber.toString(), "block")
          : ""
      }
      target="_blank"
      rel="noopener noreferrer"
      sx={{
        position: "fixed",
        right: "14px",
        bottom: "14px",
      }}
      RouterLink={RouterLink}
    >
      <Box
        sx={{
          opacity: isMounted ? "0.5" : "0.6",
          display: "inline-flex",
        }}
      >
        {blockNumber}
      </Box>
      <Box
        sx={{
          position: "relative",
          display: "inline-flex",
          marginLeft: "6px",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <PollingDot />
        {!isMounted && <Loader size={14} />}
      </Box>
    </Typography>
  );
};

const PollingDot = () => {
  return (
    <Box
      sx={{
        backgroundColor: "var(--grey-1)",
        height: 8,
        width: 8,
        borderRadius: "50%",
      }}
    />
  );
};
export default Polling;
