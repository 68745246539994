import type { TypographySizes } from "@hexocean/braintrust-ui-components";
import { Box, Typography } from "@hexocean/braintrust-ui-components";
import { useMediaQuery } from "@hexocean/braintrust-ui-components/hooks";
import { RouterLink } from "@js/components/link";

type SectionProps = {
  title: string;
  subtitle?: string;
  link?: string;
  linkName?: string;
  linkSize?: TypographySizes;
  isNudge?: boolean;
  children: React.ReactNode;
};

export const SectionPanel = ({
  title,
  subtitle,
  link,
  linkName,
  linkSize,
  children,
  isNudge,
}: SectionProps) => {
  const isMobile = useMediaQuery("sm");
  const isTablet = useMediaQuery("md");
  const showMobileLayout = isMobile || isTablet;

  return (
    <Box
      sx={{
        borderRadius: 4,
        bgcolor: "var(--white)",
        padding: { xs: 3, sm: 4, md: 3, lg: 4 },
        width: "100%",
        boxSizing: "border-box",
      }}
    >
      <Box mb={3}>
        <Box display="flex" justifyContent="space-between">
          <Typography
            component="h4"
            variant={showMobileLayout ? "label" : "title"}
            size={showMobileLayout ? "large" : "small"}
            fontWeight={isNudge ? 400 : 500}
          >
            {title}
          </Typography>
          {link && (
            <Typography
              component="link"
              variant="paragraph"
              to={link}
              target="_blank"
              whiteSpace="nowrap"
              ml={2}
              mt={0.5}
              mb={1}
              size={linkSize || "medium"}
              RouterLink={RouterLink}
            >
              {linkName}
            </Typography>
          )}
        </Box>
        {subtitle && (
          <Typography component="p" variant="paragraph" mt={0.5} mb={1}>
            {subtitle}
          </Typography>
        )}
      </Box>
      {children}
    </Box>
  );
};
