import React from "react";

import { Tooltip, Typography } from "@hexocean/braintrust-ui-components";
import { useMediaQuery } from "@hexocean/braintrust-ui-components/hooks";
import { InfoIcon } from "@hexocean/braintrust-ui-components/Icons";

type BalanceLabelProps = {
  hoverDescription?: string;
  mainBalance?: boolean;
  children: React.ReactNode;
};

export const BalanceLabel = (props: BalanceLabelProps) => {
  const { children, hoverDescription, mainBalance = false } = props;
  const sm = useMediaQuery("sm");

  return (
    <div className="my-wallet__balance-label">
      <Typography
        component="p"
        variant={mainBalance ? "paragraph" : "label"}
        size={mainBalance ? "large" : sm ? "small" : "medium"}
      >
        {children}
      </Typography>
      <Tooltip
        title={hoverDescription}
        leaveTouchDelay={5000}
        color="rgba(20, 20, 23, 0.8)"
      >
        <InfoIcon style={{ fontSize: "14px", cursor: "pointer" }} />
      </Tooltip>
    </div>
  );
};
