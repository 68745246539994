import type { MutableRefObject } from "react";
import { useState } from "react";

import {
  IconButton,
  PopoverWithHighlightedAnchor,
  Tooltip,
} from "@hexocean/braintrust-ui-components";
import { useMediaQuery } from "@hexocean/braintrust-ui-components/hooks";
import { CalendarIcon } from "@hexocean/braintrust-ui-components/Icons";
import { openSchedulePromptModal } from "@js/apps/messenger/components/schedule-prompt-modal";
import { PulseV2 } from "@js/components/animations";
import type { User } from "@js/types/auth";
import type { MessageRoom } from "@js/types/messenger";

import { usePulseCalendarIcon } from "../../hooks/use-pulse-calendar-icon";
import { useSendCalendarLinkPromptShown } from "../../hooks/use-send-calendar-link-prompt-shown";

import { SchedulePromptPopoverContent } from "./schedule-prompt-popover-content";

type SchedulePromptProps = {
  user: User;
  activeRoom: MessageRoom;
};

export const SchedulePrompt = ({
  user,
  activeRoom,
}: SchedulePromptProps): JSX.Element | null => {
  const recipientId = activeRoom.interlocutors[0];
  const recipient = activeRoom.participants.find(
    (roomUser) => roomUser.id === recipientId,
  );
  const sender = activeRoom.participants.find(
    (roomUser) => roomUser.id !== recipientId,
  );
  const isImpersonatedSession = user?.is_impersonated_session;

  const { shouldShowCalendarLinkPrompt, setShouldShowCalendarPromptFalse } =
    useSendCalendarLinkPromptShown(activeRoom, isImpersonatedSession);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const { calendarIconPulse, setCalendarIconPulse } = usePulseCalendarIcon(
    user.employer ? undefined : recipientId || undefined,
  );
  const isMobile = useMediaQuery("sm");

  const handleScheduleCallClick = (onClick: () => void) => {
    setCalendarIconPulse(false);

    if (isPopoverOpen) return setIsPopoverOpen(false);

    if (shouldShowCalendarLinkPrompt) {
      setShouldShowCalendarPromptFalse();
      onClick();
    } else {
      openSchedulePromptModal();
    }
  };

  if (!recipient || !sender) return null;

  const isButtonDisabled = !!user?.is_banned_from_posting;

  return (
    <PopoverWithHighlightedAnchor
      disablePortal
      onOpenChange={(isOpen) => setIsPopoverOpen(isOpen)}
      open={isPopoverOpen}
      renderAnchor={({ isOpen, anchorProps }) => {
        return (
          <Tooltip
            title="Add a calendar link"
            placement="top"
            disabled={isButtonDisabled}
          >
            <IconButton
              variant="transparent"
              aria-label="Open calendar"
              size="medium"
              sx={{
                "&": isOpen ? { backgroundColor: "white !important" } : {},
                "&:hover": { backgroundColor: "var(--yellow) !important" },
              }}
              disabled={isButtonDisabled}
              {...anchorProps}
              ref={anchorProps.ref as MutableRefObject<HTMLButtonElement>}
              //className must be here becasue anchorProps has className inside and orerride this one
              className="messenger-textbox__icon"
              onClick={(ev: React.MouseEvent<HTMLElement>) => {
                handleScheduleCallClick(() => anchorProps.onClick(ev));
              }}
            >
              {calendarIconPulse && (
                <PulseV2
                  style={{
                    position: "absolute",
                    zIndex: -1,
                  }}
                  repeat={5}
                />
              )}
              <CalendarIcon className="messenger-textbox__calendar--thinner" />
            </IconButton>
          </Tooltip>
        );
      }}
      transformOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      anchorOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      PaperProps={{
        sx: !isMobile
          ? {
              padding: "32px",
              paddingBottom: "24px",
              border: "1px solid var(--grey-4)",
            }
          : {
              padding: "24px",
              border: "1px solid var(--grey-4)",
              maxHeight: "calc(100% - 80px)",
              maxWidth: "calc(100% - 80px)",
            },
      }}
      offsetY={-16}
      transitionDuration={0}
    >
      <SchedulePromptPopoverContent
        sender={sender}
        recipient={recipient}
        setIsOpen={setIsPopoverOpen}
      />
    </PopoverWithHighlightedAnchor>
  );
};
