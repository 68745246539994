import { Button, IconButton } from "@hexocean/braintrust-ui-components";
import { CloseIcon } from "@hexocean/braintrust-ui-components/Icons";

import styles from "./style.module.scss";

type CommentOfferBoxActionsProps = {
  hasCreatedOrPendingStatus: boolean;
  isCommentAuthor: boolean;
  onRemoveOfferClick: () => void;
  onMessageButtonClick: () => Promise<void> | void;
  disabled: boolean;
  label: string;
};

export const CommentOfferBoxActions = ({
  hasCreatedOrPendingStatus,
  isCommentAuthor,
  disabled,
  onRemoveOfferClick,
  onMessageButtonClick,
  label,
}: CommentOfferBoxActionsProps) => {
  if (isCommentAuthor && hasCreatedOrPendingStatus) {
    return (
      <IconButton
        variant="transparent"
        onClick={onRemoveOfferClick}
        className={styles["comment-offer__button--delete"]}
        aria-label="Delete offer"
      >
        <CloseIcon />
      </IconButton>
    );
  }

  if (isCommentAuthor) {
    return null;
  }

  return (
    <Button
      className={styles["comment-offer__button"]}
      size="x-small"
      shape="squared"
      variant="primary"
      onClick={onMessageButtonClick}
      disabled={disabled}
    >
      {label}
    </Button>
  );
};
