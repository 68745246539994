import { useCallback } from "react";
import { useNavigate } from "react-router-dom";

import type { FilterLocation } from "@js/apps/filters/types";
import { TALENT_LOCATION } from "@js/apps/freelancer/constants";
import { JOB_LOCATION } from "@js/apps/jobs/context";
import { useResetUniversalSearchPhrase } from "@js/apps/universal-search/universal-search-slice";
import { LocalStorage } from "@js/services/local-storage";

export const useClearFilters = () => {
  const navigate = useNavigate();
  const clearUniversalSearch = useResetUniversalSearchPhrase();
  const clearFilters = useCallback(
    (location?: FilterLocation) => {
      if (location === JOB_LOCATION.jobs_landing_page) {
        LocalStorage.removeItem(LocalStorage.keys.JOB_LANDING_FILTERS);
      } else if (
        location === JOB_LOCATION.legacy_job_search ||
        location === JOB_LOCATION.job_search
      ) {
        LocalStorage.removeItem(LocalStorage.keys.JOBS_SEARCH_FILTERS);
        clearUniversalSearch();
      } else if (location === TALENT_LOCATION.talent_listing) {
        LocalStorage.removeItem(LocalStorage.keys.TALENT_LISTING_FILTERS);
      } else if (
        location === TALENT_LOCATION.legacy_talent_search ||
        location === TALENT_LOCATION.talent_search
      ) {
        clearUniversalSearch();
      } else {
        LocalStorage.removeItem(LocalStorage.keys.JOB_LISTING_FILTERS);
      }

      navigate(window.location.pathname);
    },
    [navigate, clearUniversalSearch],
  );

  return {
    clearFilters,
  };
};
