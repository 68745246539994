import cs from "classnames";

import type { IconButtonProps } from "@hexocean/braintrust-ui-components";
import { IconButton } from "@hexocean/braintrust-ui-components";
import { BookmarkIcon } from "@hexocean/braintrust-ui-components/Icons";
import type { AfterAction } from "@js/apps/jobs/components/job-actions/with-job-actions";
import { AnimatedStarsClick } from "@js/components/animations";
import type { MainJobListingJob } from "@js/types/jobs";

import { useBookmarkJobButton } from "./use-bookmark-job";

import styles from "./styles.module.scss";

type BookmarkJobButtonProps = Partial<IconButtonProps> & {
  job: MainJobListingJob;
  afterAction?: AfterAction;
};

export const BookmarkJobButton = ({
  job,
  afterAction,
  ...props
}: BookmarkJobButtonProps) => {
  const { isBookmarked, handleClick, loading } = useBookmarkJobButton({
    job,
    afterAction,
  });

  return (
    <AnimatedStarsClick
      prevent={isBookmarked === true}
      colors={["var(--warning-2)", "var(--medium-violet)", "#FFBB8D"]}
      starSize={10}
      starCount={15}
      maxRadius={40}
      minRadius={25}
    >
      <IconButton
        aria-label="save-job-button"
        aria-selected={isBookmarked}
        disableRipple
        variant={
          isBookmarked ? "positive-green-with-hover" : "transparent-with-hover"
        }
        name="save-job-button"
        size="small"
        onClick={handleClick}
        disabled={loading}
        {...props}
        className={cs(styles.saveJobIconButton, props?.className)}
      >
        {isBookmarked ? (
          <BookmarkIcon className={styles.saveJobIconButtonActive} />
        ) : (
          <BookmarkIcon />
        )}
      </IconButton>
    </AnimatedStarsClick>
  );
};
