import React, { useEffect } from "react";

import { Box, Loader } from "@hexocean/braintrust-ui-components";
import { useBidComments } from "@js/apps/jobs/apps/bids/hooks/bid-comments";
import { AddCommentForm } from "@js/apps/jobs/forms/add-comment";

import { JobBidComment } from "./job-bid-comment";

export const ADD_COMMENT_FORM_ID = "add-comment-form";

export type JobBidCommentListProps = {
  id: number;
  setNotesCount: (notesCount: number) => void;
};

export const JobBidCommentList = ({
  id,
  setNotesCount,
}: JobBidCommentListProps) => {
  const {
    fetchingComments,
    comments,
    deleteComment,
    onSubmit,
    onSubmitSuccess,
  } = useBidComments(id);

  useEffect(() => {
    if (comments) {
      setNotesCount(comments?.length);
    }
  }, [comments, setNotesCount]);

  return (
    <>
      <AddCommentForm
        bidId={id}
        onSubmit={onSubmit}
        onSubmitSuccess={onSubmitSuccess}
      />

      {!comments || fetchingComments ? (
        <Loader />
      ) : (
        <Box className="job-bid-comment-list">
          {comments.map((comment) => {
            return (
              <JobBidComment
                key={comment.id}
                comment={comment}
                onDelete={deleteComment}
              />
            );
          })}
        </Box>
      )}
    </>
  );
};
