import React from "react";

import { Typography } from "@hexocean/braintrust-ui-components";
import { RouterLink } from "@js/components/link";

const EmployerNotFound = () => {
  return (
    <>
      <Typography component="h1" className="not-found-page-title">
        404
      </Typography>
      <Typography
        component="p"
        variant="title"
        fontWeight={400}
        className="not-found-page-message"
      >
        Sorry - the requested employer profile can’t be found/is no longer
        active.
      </Typography>
      <Typography
        component="p"
        variant="title"
        size="medium"
        fontWeight={400}
        className="not-found-page-info"
      >
        Return to the <RouterLink to="/">homepage</RouterLink> or contact us
        about the problem
      </Typography>
    </>
  );
};

export default EmployerNotFound;
