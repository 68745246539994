import type { FC } from "react";
import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";

import {
  Box,
  Button,
  Grid,
  Rating,
  Typography,
} from "@hexocean/braintrust-ui-components";
import { useReviewModal } from "@js/apps/reviews/components/review-modal/use-review-modal";
import {
  closeWriteReviewModal,
  openWriteReviewModal,
  WriteReviewModalInstance,
} from "@js/apps/reviews/components/review-modal/write-review-modal-instance";
import {
  closeWriteReviewSuccessModal,
  openWriteReviewSuccessModal,
  WriteReviewSuccessModalInstance,
} from "@js/apps/reviews/components/review-modal/write-review-success-modal-instance";
import {
  getShouldAutoOpenJobReviewsModal,
  setAutoOpenJobReviewsModal,
} from "@js/apps/reviews/slice";
import { showWriteReviewFormAfterOpen } from "@js/apps/reviews/slice";
import ProgressBarWithLabel from "@js/components/progress-bar-with-label";
import { useAppDispatch, useAppSelector } from "@js/hooks";
import type { EmployerPublicProfile } from "@js/types/employer";
import type { FreelancerPublic } from "@js/types/freelancer";
import { pluralize } from "@js/utils";

import { ReviewList } from "../review-list";
import { WriteReview } from "../write-review";

type ReviewModalProps = {
  profile: EmployerPublicProfile | FreelancerPublic;
  reviewSubject: EnumType<typeof ENUMS.AccountType>;
};
export const ReviewModal: FC<ReviewModalProps> = ({
  profile,
  reviewSubject,
}) => {
  const {
    review_count: reviewCount,
    total_jobs: totalJobs,
    stars_breakdown: reviewRates,
    average_responsiveness: averageResponsiveness,
    average_work_quality: averageWorkQuality,
    average_rating: averageRating,
    can_user_review_job: canUserReview,
  } = profile;

  const [searchParams, setSearchParams] = useSearchParams();

  const {
    showWriteReviewFormAfterOpenReviewsModal,
    setShowWriteReviewForm,
    showWriteReviewForm,
    onSuccess,
  } = useReviewModal({
    closeReviewModal: closeWriteReviewModal,
  });

  const shouldOpenModalImmediately = useAppSelector(
    getShouldAutoOpenJobReviewsModal,
  );
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (shouldOpenModalImmediately) {
      dispatch(setAutoOpenJobReviewsModal(false));
      openWriteReviewModal();
    }
  }, [dispatch, shouldOpenModalImmediately]);

  useEffect(() => {
    return () => {
      dispatch(setAutoOpenJobReviewsModal(false));
    };
  }, [dispatch]);

  return (
    <>
      <WriteReviewModalInstance
        onOpen={() => {
          if (showWriteReviewFormAfterOpenReviewsModal) {
            setShowWriteReviewForm(true);
            dispatch(showWriteReviewFormAfterOpen(false));
          }
        }}
        onClose={() => {
          setShowWriteReviewForm(false);

          if (
            Array.from(searchParams).length > 0 &&
            !!searchParams.get("write_review")
          ) {
            setSearchParams({});
          }
        }}
      >
        {!!reviewCount ? (
          <>
            <div className="review-modal-head">
              <Typography
                variant="title"
                className="review-modal-head-title"
                component="h1"
                fontWeight={400}
              >
                Reviews
              </Typography>
              <Grid container spacing={4}>
                <Grid item xs={3} className="center">
                  {averageResponsiveness && averageWorkQuality && (
                    <div className="review-modal-head-summary-value">
                      {Number(averageRating)}
                    </div>
                  )}
                  {!!reviewCount && (
                    <Typography component="p" color="grey-4">
                      {reviewCount} review{pluralize(reviewCount)}
                    </Typography>
                  )}
                  {canUserReview && (
                    <Typography
                      component="p"
                      size="small"
                      color="grey-4"
                      className="write-review-button color-gray-4"
                      onClick={() => setShowWriteReviewForm((prev) => !prev)}
                    >
                      Write a review
                    </Typography>
                  )}
                </Grid>

                <Grid item xs={6}>
                  {Object.entries(reviewRates)
                    .reverse()
                    .map(([key, value]) => (
                      <div className="review-modal-head-stars-box" key={key}>
                        <Rating
                          value={parseFloat(key)}
                          iconStyle={{ width: "24px", height: "24px" }}
                          sx={{ pointerEvents: "none" }}
                        />

                        <Box>
                          <ProgressBarWithLabel
                            value={(Number(value) / (reviewCount * 2)) * 100}
                            sx={{
                              backgroundColor: "var(--grey-1)",
                              color: "var(--white)",
                            }}
                            color="inherit"
                          />
                          <Typography
                            component="span"
                            size="small"
                            color="grey-2"
                            className="review-modal-head-rate"
                          >
                            {Number(value)}
                          </Typography>
                        </Box>
                      </div>
                    ))}
                </Grid>

                <Grid item xs={3}>
                  {!!totalJobs && (
                    <Typography
                      component="h2"
                      variant="title"
                      fontWeight={400}
                      size="small"
                      className="review-modal-head-info"
                    >
                      <Typography
                        component="span"
                        size="small"
                        textTransform="uppercase"
                        color="grey-4"
                        fontWeight={500}
                        className="review-modal__total-jobs"
                        sx={{
                          display: "block",
                          letterSpacing: "2px",
                        }}
                      >
                        Total Jobs
                      </Typography>
                      {totalJobs}
                    </Typography>
                  )}
                </Grid>
              </Grid>
            </div>
            <div className="review-modal-body">
              {showWriteReviewForm && (
                <WriteReview
                  reviewSubject={reviewSubject}
                  reviewSubjectId={profile.id}
                  onSuccess={onSuccess}
                  onClose={closeWriteReviewModal}
                />
              )}
              <ReviewList
                direction="column"
                reviewSubject={reviewSubject}
                reviewSubjectId={profile.id}
              />
            </div>
          </>
        ) : (
          <div className="review-modal-body">
            <Typography
              variant="title"
              className="review-modal-head-title"
              component="h1"
              fontWeight={400}
            >
              Reviews
            </Typography>
            <WriteReview
              reviewSubject={reviewSubject}
              reviewSubjectId={profile.id}
              onSuccess={onSuccess}
              onClose={closeWriteReviewModal}
            />
          </div>
        )}
      </WriteReviewModalInstance>
      <WriteReviewSuccessModal />
    </>
  );
};

const WriteReviewSuccessModal = () => {
  return (
    <WriteReviewSuccessModalInstance>
      <div className="write-review-success-modal">
        <Typography variant="title" size="small" component="h2">
          Thank you
        </Typography>
        <Typography
          onClick={openWriteReviewSuccessModal}
          variant="paragraph"
          size="medium"
          component="p"
          fontWeight={400}
          mb={2}
          mt={2}
        >
          Thank you for submitting a review. Due to the double-blind review
          process it will display after both parties evaluate each other.
        </Typography>
        <div className="buttons right">
          <Button variant="transparent" onClick={closeWriteReviewSuccessModal}>
            Ok
          </Button>
        </div>
      </div>
    </WriteReviewSuccessModalInstance>
  );
};
