import { useUser } from "@js/apps/common/hooks";
import AppContent from "@js/apps/web3/components/app-content";
import WalletModal from "@js/apps/web3/components/wallet-modal";

import { FreelancerApproved } from "./freelancer-approved";
import { FreelancerNotApproved } from "./freelancer-not-approved";

export const NFTMintPage = () => {
  const user = useUser();

  return (
    <AppContent>
      <div className="nft-mint">
        {user?.freelancer_approved || user?.employer ? (
          <FreelancerApproved />
        ) : (
          <FreelancerNotApproved />
        )}
      </div>
      <div className="nft-mint__bg" />
      <WalletModal />
    </AppContent>
  );
};
