import { useUser } from "../user";

// if something is allowed for superuser, node staff, job ops and job sales, then it should check user.has_node_staff_permissions,
// without the need to check every group

export const useIsNodeStaff = () => {
  const user = useUser();

  if (!user) return false;

  return user.has_node_staff_permissions;
};
