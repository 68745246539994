import { useMemo } from "react";
import { useLocation } from "react-router-dom";

import {
  RegularTab,
  RegularTabs,
  Typography,
} from "@hexocean/braintrust-ui-components";
import { RouterLink } from "@js/components/link";

import styles from "./space-tabs.module.scss";

export type SpaceTabsProps = {
  spaceId: number;
  isSpaceMember: boolean;
};

const TABS = {
  DISCUSSION: {
    label: "Discussion",
    subPath: "/discussion/",
    visibleForNonMember: true,
  },
  ABOUT: { label: "About", subPath: "/about/", visibleForNonMember: true },
  PEOPLE: { label: "People", subPath: "/people/", visibleForNonMember: false },
} as const;

const TABS_LIST = Object.values(TABS);

export const SpaceTabs = ({ spaceId, isSpaceMember }: SpaceTabsProps) => {
  const { pathname } = useLocation();

  const tabsListToDisplay = useMemo(() => {
    return isSpaceMember
      ? TABS_LIST
      : TABS_LIST.filter(({ visibleForNonMember }) => !!visibleForNonMember);
  }, [isSpaceMember]);

  const selectedSpaceTabVal = useMemo(() => {
    const currentTab = tabsListToDisplay.find(({ subPath }) =>
      pathname.endsWith(subPath),
    );

    return currentTab ?? TABS.DISCUSSION;
  }, [pathname, tabsListToDisplay]);

  return (
    <RegularTabs
      aria-label="space tabs"
      value={selectedSpaceTabVal.subPath}
      sx={{ mt: 3, mb: 3 }}
    >
      {tabsListToDisplay.map(({ label, subPath }) => {
        return (
          <RegularTab
            key={label}
            value={subPath}
            component={RouterLink}
            to={`/spaces/${spaceId}${subPath}`}
            className={styles.tabLabel}
            label={
              <Typography component="span" variant="paragraph" size="medium">
                {label}
              </Typography>
            }
          />
        );
      })}
    </RegularTabs>
  );
};
