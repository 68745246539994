import type { WrappedFieldProps } from "redux-form";
import { Field } from "redux-form";

import { Box, Loader, Typography } from "@hexocean/braintrust-ui-components";
import { useJobRole } from "@js/apps/roles/hooks";
import { ButtonSelectField } from "@js/forms/fields/button-select";
import type { OptionWithPrimitiveValue } from "@js/types/common";

export const JobRoleField = ({
  hideError,
}: {
  hideError?: boolean;
}): JSX.Element => {
  const { roleOptions } = useJobRole();

  if (!roleOptions.length) {
    return <Loader />;
  }

  return (
    <>
      <Box mb={6}>
        <Typography
          id="category-label"
          mb={2}
          component="label"
          display="block"
          variant="label"
        >
          Choose a category for this job
        </Typography>
        <Field
          name="role"
          validate={[roleRequired]}
          options={roleOptions}
          labelledBy="category-label"
          // https://github.com/sargant/DefinitelyTyped/blob/32a4039140aa835b73b1faccd454cf4a00ebff6a/types/redux-form/redux-form-tests.tsx
          component={RoleField}
          hideError={hideError}
        />
      </Box>
    </>
  );
};

const roleRequired = (value: number): string | undefined => {
  return !value ? "Please choose correct role for your job." : undefined;
};

const RoleField = ({
  input,
  meta,
  options,
  labelledBy,
  hideError,
}: WrappedFieldProps & {
  labelledBy: string;
  options: OptionWithPrimitiveValue<number>[];
  hideError?: boolean;
}) => {
  return (
    <>
      {meta.error && meta.touched && !hideError && (
        <Typography component="p" color="negative" size="small">
          {meta.error}
        </Typography>
      )}
      <ButtonSelectField
        labelledBy={labelledBy}
        fontWeight={500}
        options={options}
        multiple={false}
        canSingleValueBeEmpty
        variant="white-violet"
        shape="squared"
        input={input}
        meta={meta}
      />
    </>
  );
};
