import * as React from "react";
import classNames from "classnames";

import { Grid, IconButton } from "@hexocean/braintrust-ui-components";
import { ArrowLeftIcon } from "@hexocean/braintrust-ui-components/Icons";
import { Logo } from "@js/components/logo";

import { withRouter } from "../../hooks";
import { getQuery } from "../../utils";
import { CoreLayout } from "../core";

class WelcomeLayout extends React.Component<any> {
  goBack() {
    const { navigate, backURL } = this.props;
    navigate(backURL || getQuery("back") || "/auth/");
  }

  get back() {
    if ((this.props.back && getQuery("back")) || this.props.backURL) {
      const BackIcon = this.props.backIcon || ArrowLeftIcon;
      return (
        <IconButton
          aria-label="go back"
          onClick={() => this.goBack()}
          variant="tertiary"
          sx={{ color: "var(--grey-4)", alignSelf: "center" }}
        >
          <BackIcon />
        </IconButton>
      );
    }

    return null;
  }

  get header() {
    const back = this.back;
    const { headerSlot } = this.props;

    if (back || headerSlot) {
      return (
        <div className="welcome-layout-header">
          <div className="welcome-layout-header-content">
            {back}
            {headerSlot}
          </div>
        </div>
      );
    }

    return null;
  }

  render() {
    const { className, showLogo, children, justify, fixedHeader, pageTitle } =
      this.props;

    return (
      <CoreLayout
        pageTitle={pageTitle}
        className={classNames("welcome-layout", className, {
          "with-fixed-header": fixedHeader,
        })}
      >
        {this.header}
        <div className="welcome-layout-container">
          <div className="welcome-layout-content">
            {showLogo && <Logo variant="symbol" />}
            <Grid
              container
              item
              xs={12}
              className="welcome-layout-container-content-page"
              justifyContent={justify}
            >
              {children}
            </Grid>
          </div>
        </div>
      </CoreLayout>
    );
  }
}

export default withRouter(WelcomeLayout);
