import React from "react";

import { RoundedBox } from "@hexocean/braintrust-ui-components";

import {
  ContractTypeDetail,
  DeadlineDetail,
  LocationDetail,
  TimezoneDetail,
  WorkTimeDetail,
} from "../details";

export const JobDetailsBasicDetails = ({ job, ...rest }) => {
  return (
    <RoundedBox {...rest}>
      <LocationDetail job={job} variant="details" />
      <TimezoneDetail job={job} variant="details" />
      <WorkTimeDetail job={job} variant="details" shortened />
      <ContractTypeDetail job={job} variant="details" />
      <DeadlineDetail job={job} variant="details" />
    </RoundedBox>
  );
};
