import { useEffect } from "react";

import { useQueryParams } from "@js/hooks";
import type { Freelancer } from "@js/types/freelancer";

import { openProfileCompletionSequenceModal } from "../profile-completion-sequence";

export const useOpenProfileCompletionModalByQueryParam = (
  profile?: Freelancer,
) => {
  const { openProfileCompletionModal } = useQueryParams();

  useEffect(() => {
    if (openProfileCompletionModal && profile) {
      openProfileCompletionSequenceModal(profile);
    }
  }, [openProfileCompletionModal, profile]);
};
