import { useEffect, useState } from "react";

import { fetchEmployerPublicProfile } from "@js/apps/employer/actions";
import { Snackbar } from "@js/components/snackbar";
import { useAppDispatch, useAppSelector } from "@js/hooks";
import { LocalStorage } from "@js/services/local-storage";

import { useGetJobQuery } from "../../api";
import { addViewedPublicJob } from "../../apps/public-listing/helpers/viewed-public-jobs";

type UsePublicJobDetailsArg = {
  jobId: number;
};

export const usePublicJobDetails = ({ jobId }: UsePublicJobDetailsArg) => {
  const [referrer] = useState(
    () => LocalStorage.getItem(LocalStorage.keys.SIGN_UP_REFERRER) ?? undefined,
  );

  const {
    data: job,
    isFetching,
    error,
  } = useGetJobQuery({ id: jobId, referrer });
  const dispatch = useAppDispatch();

  const employerProfile = useAppSelector(
    (state) => state.employer.employerPublicProfile,
  );

  useEffect(() => {
    if (!job) {
      return;
    }

    addViewedPublicJob(jobId);
    dispatch(fetchEmployerPublicProfile(job?.employer?.id)).catch(() => {
      Snackbar.error("Sorry, something went wrong. Please try again.");
    });
  }, [dispatch, jobId, job]);

  const loading = isFetching;

  return {
    loading,
    job,
    employerProfile,
    error,
  };
};
