import type { MouseEvent, TouchEventHandler } from "react";
import { useEffect, useRef, useState } from "react";
import { useMemo } from "react";
import _ from "underscore";

import { useIsTouchDevice } from "@js/apps/common/hooks";
import { usePostContext } from "@js/apps/give-and-get-help/context";
import { openSignUpModal } from "@js/apps/onboarding/components/sign-up-modal";
import type { StickerValue } from "@js/types/give-and-get-help";

import { getHoveredSticker } from "./helpers";

type UseStickersPopoverProps = {
  onStickerSelect: (value: StickerValue) => void;
};

export const useStickersPopover = ({
  onStickerSelect,
}: UseStickersPopoverProps) => {
  const { isPublic, postData, onReactionClick } = usePostContext();
  const isTouchDevice = useIsTouchDevice(); // the UX differs based on device type
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [hoveredSticker, setHoveredSticker] = useState<
    StickerValue | undefined
  >();
  const btnRef = useRef<HTMLButtonElement>(null);

  const isOpen = Boolean(anchorEl);
  const isOpenRef = useRef(isOpen); // used within throttled touch move handler
  useEffect(() => {
    isOpenRef.current = isOpen;
  }, [isOpen]);

  const handleSelectSticker = (sticker: StickerValue) => {
    onStickerSelect(sticker);
    handlePopoverClose();
  };

  const handleClick = (ev: MouseEvent<HTMLElement>) => {
    ev.stopPropagation();
    handlePopoverOpen();
  };

  const handlePopoverOpen = () => {
    if (isPublic) {
      openSignUpModal();
      return;
    }

    if (onReactionClick) {
      onReactionClick(postData.id, postData.space);
      return;
    }

    setAnchorEl(btnRef?.current);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const handleTouchStart: TouchEventHandler<HTMLElement> = () => {
    handlePopoverOpen();
  };

  const handleTouchEnd: TouchEventHandler<HTMLElement> = () => {
    if (hoveredSticker) {
      onStickerSelect(hoveredSticker);
    }

    setHoveredSticker(undefined);
    handlePopoverClose();
  };

  const handleTouchMove: TouchEventHandler<HTMLElement> = (event) => {
    if (!isOpenRef.current) {
      return;
    }

    const [touchTarget] = Array.from(event.changedTouches);
    if (!touchTarget) {
      setHoveredSticker(undefined);

      return;
    }

    const xPos = touchTarget.clientX;
    const yPos = touchTarget.clientY;

    setHoveredSticker(getHoveredSticker(xPos, yPos));
  };

  const handleTouchMoveThrottled = useMemo(
    () =>
      isTouchDevice
        ? _.throttle(handleTouchMove, 100, { leading: true, trailing: true })
        : undefined,
    [isTouchDevice],
  );

  return {
    handleTouchStart,
    handleTouchEnd,
    handlePopoverOpen,
    handlePopoverClose,
    handleSelectSticker,
    handleTouchMoveThrottled,
    btnRef,
    anchorEl,
    hoveredSticker,
    isOpen,
    isTouchDevice,
    handleClick,
  };
};
