import { useLocation, useNavigate } from "react-router-dom";

import { useEffectOnce } from "@js/apps/common/hooks/use-effect-once";
import { LocalStorage } from "@js/services";
import { isInIframe } from "@js/utils";

import { PROF_NET_ONBOARDING_PATHS } from "../../constants";
import { useReferrerQueryParams } from "../use-referrer-query-params";

export const useStoreReferrerAndRedirect = (referrer: string) => {
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = useReferrerQueryParams();

  const storeReferrerAndRedirect = () => {
    if (isInIframe()) {
      navigate(
        {
          pathname: PROF_NET_ONBOARDING_PATHS.GOALS_PAGE.path,
          search: location.search,
        },
        { replace: true },
      );
      return;
    }

    LocalStorage.setItem(LocalStorage.keys.SIGN_UP_REFERRER, referrer);

    const locationStateWithReferrer = { referrer };
    const navigateOptions = {
      replace: true,
      state: locationStateWithReferrer,
    };

    if (queryParams.job_id) {
      navigate(`/jobs/${queryParams.job_id}`, navigateOptions);

      return;
    }

    if (queryParams.jobs) {
      navigate(`/jobs`, navigateOptions);

      return;
    }

    navigate(
      {
        pathname: PROF_NET_ONBOARDING_PATHS.GOALS_PAGE.path,
        search: location.search,
      },
      navigateOptions,
    );
  };

  useEffectOnce(() => {
    storeReferrerAndRedirect();
  });
};
