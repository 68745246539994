import type { ConfigProps } from "redux-form";
import { Field } from "redux-form";

import { Box, Button } from "@hexocean/braintrust-ui-components";
import { GraterOrEqualZeroIntegerNumberFormat } from "@hexocean/braintrust-ui-components";
import { CommonConfirmationModal } from "@js/components/modal";
import { createFormInstance } from "@js/forms/components";
import { DegreesField, NumberField, SchoolsField } from "@js/forms/fields";
import { noFutureYearValidator, required } from "@js/forms/validators";

import { ADD_ANOTHER_ITEM } from "../../constants";

export type FormData = {
  degree: string;
  new_school: string;
  graduation_date: string;
};

export const EDUCATION_FORM_ID = "education-from";

const EducationFormInstance = createFormInstance<FormData, unknown>(
  EDUCATION_FORM_ID,
);

type EducationFormProps = {
  onSubmit: ConfigProps<FormData>["onSubmit"];
  onSubmitSuccess: ConfigProps<FormData>["onSubmitSuccess"];
  initialValues?: FormData;
  editMode?: boolean;
  setSubmitType?: (type: string | null) => void;
};

export const EducationForm = ({
  setSubmitType,
  editMode = false,
  ...props
}: EducationFormProps) => {
  return (
    <EducationFormInstance {...props}>
      <Box>
        <Box mb={4}>
          <Field
            id="degree"
            name="degree"
            component={DegreesField}
            label="Degree"
          />
        </Box>
        <Box mb={4}>
          <Field
            id="new_school"
            name="new_school"
            component={SchoolsField}
            validate={[required]}
            label="School*"
          />
        </Box>
        <Box maxWidth="128px">
          <Field
            id="graduation_date"
            name="graduation_date"
            inputProps={{ format: "####" }}
            NumberFormat={GraterOrEqualZeroIntegerNumberFormat}
            component={NumberField}
            validate={[noFutureYearValidator]}
            label="Graduation Year"
          />
        </Box>

        {!editMode && (
          <Box mt={3}>
            <Button
              variant="tertiary"
              onClick={() => setSubmitType && setSubmitType(ADD_ANOTHER_ITEM)}
              type="submit"
            >
              Save and add another
            </Button>
          </Box>
        )}
        <Box mt={4} gap={2} display="flex" justifyContent="flex-end">
          {editMode && (
            <Button
              variant="black-outlined"
              shape="squared"
              onClick={CommonConfirmationModal.close}
              type="submit"
            >
              Discard changes
            </Button>
          )}
          <Button variant="positive" shape="squared" type="submit">
            {editMode ? "Save changes" : "Save"}
          </Button>
        </Box>
      </Box>
    </EducationFormInstance>
  );
};
