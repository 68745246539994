import type { ReactNode } from "react";
import React from "react";
import { useSearchParams } from "react-router-dom";
import { Field } from "redux-form";

import { TableSortLabel as BUCTableSortLabel } from "@hexocean/braintrust-ui-components";

/** @description If you don't provide `filters` and `onChange` component will manipulate search params by itself using `react-router` hooks */
export const TableSortLabel = <TFilters,>({
  label,
  onChange,
  orderBy,
  filters,
}: {
  label: ReactNode;
  onChange?: (arg: TFilters) => void;
  orderBy?: string | null;
  filters?: unknown;
}) => {
  const [searchParams, setSearchParams] = useSearchParams();

  if (!orderBy) return <>{label}</>;

  const orderingParam = searchParams.get("ordering");

  const setNewFiltersBy = onChange && filters ? "onChange" : "setSearchParams";
  const orderingValue =
    setNewFiltersBy === "onChange"
      ? getFiltersWithOrdering(filters)?.ordering
      : orderingParam;

  const { active, direction, newOrdering } = getTableSortLabelProps(
    orderingValue,
    orderBy,
  );

  return (
    <BUCTableSortLabel
      active={active}
      direction={direction}
      onClick={() => {
        if (setNewFiltersBy === "onChange") {
          onChange?.({ ...(filters || {}), ordering: newOrdering } as TFilters);
        } else {
          searchParams.set("ordering", newOrdering);
          setSearchParams(searchParams);
        }
      }}
    >
      {label}
    </BUCTableSortLabel>
  );
};

export const TableSortLabelField = (props) => {
  return (
    <Field
      name="ordering"
      component={TableSortLabelFieldComponent}
      {...props}
    />
  );
};

const TableSortLabelFieldComponent = ({ orderBy, label, input }) => {
  if (!orderBy) return label;

  const { active, direction, newOrdering } = getTableSortLabelProps(
    input.value,
    orderBy,
  );

  return (
    <BUCTableSortLabel
      active={active}
      direction={direction}
      onClick={() => input.onChange(newOrdering)}
    >
      {label}
    </BUCTableSortLabel>
  );
};

const getTableSortLabelProps = (
  value: string | null | undefined,
  orderBy: string,
) => {
  const reversedOrder = `-${orderBy}`;
  const active = value === orderBy || value === reversedOrder;
  const direction: "desc" | "asc" = value === reversedOrder ? "desc" : "asc";
  const newOrdering = value === orderBy ? reversedOrder : orderBy;

  return {
    active,
    direction,
    newOrdering,
  };
};

const getFiltersWithOrdering = <TFilters,>(
  filters: unknown,
): TFilters & { ordering?: string } =>
  (filters || {}) as TFilters & { ordering?: string };
