import {
  Box,
  Loader,
  Tooltip,
  Typography,
} from "@hexocean/braintrust-ui-components";
import { useMediaQuery } from "@hexocean/braintrust-ui-components/hooks";
import { BraintrustIcon } from "@hexocean/braintrust-ui-components/Icons";
import { ReferButton } from "@js/apps/common/components";
import {
  BuyBTRSTButton,
  BuyBTRSTButtonWrapper,
} from "@js/apps/on-ramp/components/buy-BTRST-button";
import type { Balance } from "@js/types/common";
import { formatTokenAmount, truncateBalanceToDisplay } from "@js/utils";

import {
  MyWalletBalance,
  WithdrawalButton,
  WithdrawalModal,
} from "../../components";
import { useMyWalletPage } from "../../hooks";
import { FreelancerOrEmployerDashboardLayout } from "../layout";

import { ActivityFeed } from "./activity-feed";
import { BalanceLabel } from "./balance-label";
import { InfoCards } from "./info-cards";
import { LifetimeEarnings } from "./lifetime-earnings";
import { WalletAddress } from "./wallet-address";

const DashboardMyWalletPage = () => {
  return (
    <FreelancerOrEmployerDashboardLayout
      pageTitle="My Wallet"
      pageColor="var(--soft-green)"
      contentClassName="my-wallet-page"
    >
      <PageContent />
    </FreelancerOrEmployerDashboardLayout>
  );
};

const PageContent = () => {
  const {
    loading,
    balance,
    onClickWithdrawalButton,
    alerts,
    isWithdrawalLimitsExceeded,
    detailReason,
  } = useMyWalletPage();

  if (loading) {
    return <Loader centered />;
  }

  return (
    <div className="my-wallet">
      <div className="my-wallet__header">
        <Typography
          variant="title"
          size="large"
          fontWeight={400}
          component="h1"
        >
          Wallet
        </Typography>
        <div className="my-wallet__header-actions">
          <BuyBTRSTButtonWrapper>
            <BuyBTRSTButton />
          </BuyBTRSTButtonWrapper>
          <WithdrawalButton
            onClick={onClickWithdrawalButton}
            disabled={!!isWithdrawalLimitsExceeded}
            disabledReason={detailReason}
          />
          <WithdrawalModal />
          <ReferButton>Refer & Earn</ReferButton>
        </div>
      </div>

      {alerts && (
        <Box pt={4} pb={8}>
          {alerts}
        </Box>
      )}

      <MyWalletSummary balance={balance} />

      <div className="my-wallet__main">
        <LifetimeEarnings balance={balance} />
        <InfoCards balance={balance} />
        <ActivityFeed />
      </div>
    </div>
  );
};

type MyWalletSummaryProps = {
  balance?: Balance;
};

const MyWalletSummary = ({ balance }: MyWalletSummaryProps) => {
  const sm = useMediaQuery("sm");

  return (
    <div className="my-wallet__summary">
      <div className="my-wallet__main-balance">
        <Box display="flex" alignItems="center" gap={2} mb={2}>
          <BalanceLabel
            mainBalance
            hoverDescription="The number of BTRST tokens you currently have access to in your wallet."
          >
            Available balance
          </BalanceLabel>
          {balance?.safe_wallet_address && (
            <WalletAddress address={balance?.safe_wallet_address} />
          )}
        </Box>
        <MyWalletBalance
          hideCurrency
          variant="display"
          size="medium"
          className="wallet-balance"
          iconStyle={{ fontSize: "85%", marginRight: sm ? "12px" : "20px" }}
          balance={balance?.available_tokens ?? 0}
        />
      </div>

      <div className="my-wallet__summary-balances">
        {!!Number(balance?.escrow_tokens) && (
          <BalanceRow
            value={Number(balance?.escrow_tokens)}
            label="BTRST In Escrow"
            labelHoverDescription="The number of BTRST tokens being held as pending in your wallet until you complete the related offers."
          />
        )}
        {!!Number(balance?.unvested_tokens) && (
          <BalanceRow
            value={Number(balance?.unvested_tokens)}
            label="Unvested tokens"
            labelHoverDescription="The number of BTRST tokens awarded in a token grant that will be released to your wallet periodically."
          />
        )}
      </div>
    </div>
  );
};

type BalanceRowProps = {
  value: number;
  label: string;
  labelHoverDescription: string;
};

const BalanceRow = ({
  value,
  label,
  labelHoverDescription,
}: BalanceRowProps): JSX.Element => {
  const MAX_DIGITS_CAP = 6;

  const balanceToDisplay = truncateBalanceToDisplay(
    formatTokenAmount(value),
    MAX_DIGITS_CAP,
  );

  const sm = useMediaQuery("sm");

  return (
    <Box flexGrow={{ xs: 1, sm: 0 }} overflow="hidden">
      <Typography
        component="p"
        variant="title"
        size={sm ? "small" : "medium"}
        fontWeight={400}
        className="display-ellipsis"
      >
        <Box
          component="span"
          sx={{ display: "flex", alignItems: "center", gap: "0.5rem" }}
        >
          <BraintrustIcon style={{ fontSize: "65%" }} />
          <Tooltip title={formatTokenAmount(value)} placement="left">
            <span> {balanceToDisplay}</span>
          </Tooltip>
        </Box>
      </Typography>
      <BalanceLabel hoverDescription={labelHoverDescription}>
        {label}
      </BalanceLabel>
    </Box>
  );
};

export default DashboardMyWalletPage;
