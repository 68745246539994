import React from "react";

import { Box, Loader, Typography } from "@hexocean/braintrust-ui-components";
import { useUser } from "@js/apps/common/hooks";
import { useGetATSIntegrationsQuery } from "@js/apps/employer/api";
import { ATSIntegrationConnectedContent } from "@js/apps/employer/components/ats-integration-connected-content";
import { AtsIntegrationEmptyState } from "@js/apps/employer/components/ats-integration-empty-state";
import { EmployerEditWizard } from "@js/apps/employer/components/employer-edit-wizard";

export const ATSIntegrationPage = () => {
  const user = useUser();
  const employerId = user?.employer;
  const { data: integrations, isLoading } = useGetATSIntegrationsQuery(
    undefined,
    { skip: !employerId },
  );

  const integration = integrations?.[0];
  const showEmptyState = !isLoading && !integration;
  if (!employerId) {
    return;
  }

  return (
    <EmployerEditWizard
      pageTitle="Ats integration"
      className="employer-team-members"
    >
      <Box sx={{ width: "100%" }}>
        <Typography
          component="h1"
          variant="title"
          size="medium"
          textTransform="uppercase"
          sx={{ mb: { xs: 2, md: 3 } }}
        >
          ATS integration
        </Typography>
        {isLoading && <Loader centered />}
        {showEmptyState && <AtsIntegrationEmptyState />}
        {integration && (
          <ATSIntegrationConnectedContent integration={integration} />
        )}
      </Box>
    </EmployerEditWizard>
  );
};
