import { useGetExpandedJobDetailsQuery } from "../../api";

export const useGetExpandedJobDetails = (jobId: number) => {
  const { data, isLoading, isError } = useGetExpandedJobDetailsQuery(jobId);
  const jobDetails =
    data && typeof data === "object" && "introduction" in data
      ? data
      : undefined;

  //Since the error in query is silenced, we need to check...
  //...manually if the response is an error.
  //https://app.asana.com/0/960700181513369/1203058392812609
  const fetchError =
    !!data && typeof data === "object" && "detail" in data ? data : undefined;

  return {
    isFetchError: !!fetchError || isError,
    isLoading,
    jobDetails,
  };
};
