import { useShouldDisplayL1RoleTab } from "../display-l1-role-tab";

export const useJobPagePath = () => {
  const shouldDisplayL1Tab = useShouldDisplayL1RoleTab();

  const path = shouldDisplayL1Tab ? "/role-jobs" : "/jobs";
  const addAppliedJobFiltersToNavLink = window.location.pathname === path;

  return {
    jobsPath: `${path}${addAppliedJobFiltersToNavLink ? location.search : ""}`,
  };
};
