import type { useCreateOrEditOfferMeta } from "@js/apps/jobs/apps/offers/hooks";
import { ReviewBeforeSendingSummaryItem } from "@js/apps/jobs/components";
import { assertUnreachable } from "@js/utils";

import {
  AdditionalPaperworkFieldItem,
  CommitmentFieldItem,
  DatesFieldItem,
  EquipmentSummaryFieldItem,
  PayFieldItem,
  PaymentMethodFieldItem,
} from "../summary-fields";

type SummaryItemsProps = {
  meta: ReturnType<typeof useCreateOrEditOfferMeta>;
};

export const SummaryItems = ({ meta }: SummaryItemsProps) => {
  if (!meta) return null;

  const talentFullName =
    meta.bid.freelancer.user.public_name || meta.talent_name;

  switch (meta.job_type) {
    case ENUMS.JobType.DIRECT_HIRE: {
      return (
        <>
          <SendingTo name={talentFullName} />
          <PayFieldItem />
          <DatesFieldItem />
          <CommitmentFieldItem />
          <PaymentMethodFieldItem />
        </>
      );
    }
    case ENUMS.JobType.FREELANCE: {
      return (
        <>
          <SendingTo name={talentFullName} />
          <PayFieldItem />
          <DatesFieldItem />
          <CommitmentFieldItem />
          <EquipmentSummaryFieldItem />
          <AdditionalPaperworkFieldItem />
          <PaymentMethodFieldItem />
        </>
      );
    }
    case ENUMS.JobType.GRANT: {
      return (
        <>
          <SendingTo name={talentFullName} />
          <PayFieldItem />
          <DatesFieldItem />
          <CommitmentFieldItem />
          <PaymentMethodFieldItem />
        </>
      );
    }
    default: {
      assertUnreachable(meta.job_type);
      return null;
    }
  }
};

const SendingTo = ({ name }) => {
  return (
    <ReviewBeforeSendingSummaryItem
      sectionName="Sending to"
      sectionContent={name}
    />
  );
};
