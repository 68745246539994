import React from "react";

import { Tag } from "@js/apps/jobs/components/job-type-and-role-tags";

type JobItemInvitedByTagPropsTypes = {
  isInvitedByClient?: boolean;
  isInvitedByMatcher?: boolean;
  isInterested: boolean;
};

export const JobItemInvitedByTag = ({
  isInvitedByClient,
  isInvitedByMatcher,
  isInterested,
}: JobItemInvitedByTagPropsTypes) => {
  if ((isInvitedByMatcher || isInvitedByClient) && isInterested) {
    return (
      <Tag bgcolor="var(--medium-violet)">
        {isInvitedByMatcher ? "Invited by Matcher" : "Invited by Client"}
      </Tag>
    );
  } else return null;
};
