import { useCallback } from "react";

import { LocalStorage } from "@js/services";

import { useSendSelfSignupDataMutation } from "../../api";

export const useSendConnectorSignupData = () => {
  const [sendSelfSignupData] = useSendSelfSignupDataMutation();
  const signUpData = JSON.parse(
    LocalStorage.getItem(LocalStorage.keys.ONBOARDING_SAVED_DATA) ?? "{}",
  );
  const isReferAndEarnBTRSTSelected =
    signUpData.joining_reasons &&
    signUpData.joining_reasons.includes("refer_and_earn_btrst");

  const sendSignupConnectorData = useCallback(async () => {
    if (!isReferAndEarnBTRSTSelected) {
      return;
    }

    try {
      await sendSelfSignupData({ page_name: "Signup" }).unwrap();
      LocalStorage.removeItem(LocalStorage.keys.ONBOARDING_SAVED_DATA);
    } catch (e) {
      throw new Error("Failed to send Hubspot data");
    }
  }, [sendSelfSignupData, isReferAndEarnBTRSTSelected]);

  return { sendSignupConnectorData };
};
