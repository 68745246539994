import React from "react";
import classNames from "classnames";

import {
  Box,
  IconButton,
  Typography,
} from "@hexocean/braintrust-ui-components";
import { CloseIcon } from "@hexocean/braintrust-ui-components/Icons";
import type { RegisterValues } from "@js/apps/auth/actions";
import { register } from "@js/apps/auth/actions";
import { usePublicPagesDynamicIds } from "@js/apps/auth/hooks/public-pages-dynamic-id";
import { SignUpConfirmButton } from "@js/apps/onboarding/components/sign-up-confirm-button";
import { FirstOrLastNameField } from "@js/apps/onboarding/fields/first-name-field";
import { SignUpEmailField } from "@js/apps/onboarding/fields/sign-up-email-field";
import { SignupPasswordField } from "@js/apps/onboarding/fields/signup-password-field";
import { TOSField } from "@js/apps/onboarding/fields/tos-field";
import { ModalInstance } from "@js/components/modal";
import { createFormInstance } from "@js/forms/components";
import { getValuesWithReCaptchaCode } from "@js/forms/utils";
import { useAppDispatch, useQueryParams } from "@js/hooks";
import { LocalStorage } from "@js/services";

type SignUpModalFormValues = Omit<
  RegisterValues,
  "joining_reasons" | "interests"
>;

const Form = createFormInstance<SignUpModalFormValues, unknown>(
  "sign-up-modal-form",
);

export const SignUpModalContentStepOne = ({
  goToNextStep,
}: {
  goToNextStep: VoidFunction;
}) => {
  const dispatch = useAppDispatch();
  const { referrer, ...queryParams } = useQueryParams();
  const dynamicIds = usePublicPagesDynamicIds();

  const onSubmit = async (_values: SignUpModalFormValues) => {
    const values = await getValuesWithReCaptchaCode(
      { ..._values },
      ENUMS.CaptchaActions.SIGN_UP,
    );
    const data = await dispatch(
      register({
        values: { ...values, joining_reasons: [], interests: [] },
        referrer,
        queryParams,
        ...dynamicIds,
      }),
    );

    LocalStorage.setItem(
      LocalStorage.keys.REGISTRATION_EMAIL_LOCAL_STORAGE_KEY,
      data.email,
    );
  };

  const onSubmitSuccess = () => {
    goToNextStep();
  };

  return (
    <Form onSubmit={onSubmit} onSubmitSuccess={onSubmitSuccess}>
      <Box
        maxWidth="100vw"
        width="704px"
        display="flex"
        flexDirection="column"
        boxSizing="border-box"
        p="32px 32px 48px"
      >
        <IconButton
          size="small"
          variant="transparent"
          className={classNames("modal-close-button")}
          aria-label="Close modal"
          onClick={ModalInstance.close}
        >
          <CloseIcon
            style={{
              borderRadius: "50%",
              padding: "0.5rem",
            }}
          />
        </IconButton>
        <Box display="flex" flexDirection="column" alignItems="center">
          <Typography component="h2" variant="label" size="large" mb={3.5}>
            Create an account
          </Typography>
          <Typography
            component="p"
            variant="title"
            size="small"
            mb={4}
            fontWeight={400}
          >
            Join the conversation on Braintrust
          </Typography>
        </Box>
        <Box display="flex" flexDirection="column" gap={2} mb={4}>
          <Box display="flex" gap={2}>
            <FirstOrLastNameField variant="first_name" />
            <FirstOrLastNameField variant="last_name" />
          </Box>
          <SignUpEmailField />
          <SignupPasswordField />
          <TOSField />
        </Box>
        <SignUpConfirmButton />
      </Box>
    </Form>
  );
};
