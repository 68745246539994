import _ from "underscore";

export const removeEmptyElements = (arr) => {
  return arr.filter((item) => !_.isEmpty(item));
};

export function deepClone<T>(value: T): T {
  return JSON.parse(JSON.stringify(value));
}

export const mapKeys = (objArr, key) => {
  const objArrClone = deepClone(objArr);
  return objArrClone.reduce((obj, item) => {
    obj[item[key]] = item;
    return obj;
  }, {});
};

export const isFile = (obj) => obj instanceof File;

export const deduplicateArrayOfObjects = <T extends Record<string, unknown>>(
  array: T[],
  uniqKey: string | ((a: T) => any) = "id",
): T[] => {
  const deduped: T[] = [];
  const existingKeys: string[] = [];

  const getKey = (item) =>
    _.isFunction(uniqKey) ? uniqKey(item) : item[uniqKey];

  for (const item of array) {
    const key = getKey(item);

    if (!_.contains(existingKeys, key)) {
      existingKeys.push(key);
      deduped.push(item);
    }
  }

  return deduped;
};

// https://stackoverflow.com/a/49524571
// We want to treat first argument as the more important one/most recent when comparing
export const getObjectsDifferences = (firstObject, objectToCompare) => {
  const changes = (object, secondObject) => {
    return _.pick(
      {
        ..._.mapObject(secondObject, (value, key) => {
          const areObjectsEqual = _.isEqual(value, object[key]);

          if (areObjectsEqual) {
            return undefined;
          }

          if (_.isObject(value) && _.isObject(object[key])) {
            return changes(value, object);
          }

          return value;
        }),
        // required to check keys that secondObject has, but first one doesn't
        ..._.mapObject(object, (value, key) => {
          const areObjectsEqual = _.isEqual(value, secondObject[key]);

          if (areObjectsEqual) {
            return undefined;
          }

          if (_.isObject(value) && _.isObject(secondObject[key])) {
            return changes(value, secondObject);
          }

          return value;
        }),
      },
      (value) => {
        return value !== undefined;
      },
    );
  };

  return changes(firstObject, objectToCompare);
};

export const parsePrimitive = (value) => {
  try {
    return JSON.parse(value);
  } catch (error) {
    return value.toString();
  }
};

const isDirtyPrimitiveValue = (value: unknown) => {
  return typeof value === "undefined" || value === null || value === "";
};
export const clearEmpties = <T extends Record<string | number, unknown>>(
  obj: T,
): DeepPartial<T> => {
  // Recursive function
  Object.keys(obj).forEach((key: string) => {
    const val = obj[key];
    if (isDirtyPrimitiveValue(val)) {
      delete obj[key];

      return;
    }

    if (!val || typeof val !== "object") {
      return;
    }

    clearEmpties(val as Record<string, unknown>);
    if (!Object.keys(val).length) {
      delete obj[key];
    } else if (Array.isArray(val)) {
      (obj[key] as unknown[]) = val.filter(
        (arrayValue) => !isDirtyPrimitiveValue(arrayValue),
      );
    }
  });

  return obj;
};

// example [1,2,3,4,5] by 2 => [[1,2],[3,4],[5]]
export const paginateArrayBy = (items: unknown[], by: number) => {
  return items.reduce<Array<Array<unknown>>>((prev, current, index) => {
    if (index % by === 0) {
      prev.push([]);
    }

    prev[prev.length - 1].push(current);

    return prev;
  }, []);
};

export const combineResults = <
  T extends { id: string | number } = { id: string | number },
>(
  cachedResults: T[],
  newResults: T[],
): T[] => {
  const combinedResults: T[] = [];
  const newResultsCopy = [...newResults];

  // keep the right order of results by pushing cached first, not included in new results
  for (const cachedResult of cachedResults) {
    const newResultIndex = newResultsCopy.findIndex(
      ({ id }) => id === cachedResult.id,
    );
    if (newResultIndex !== -1) {
      const [newResult] = newResultsCopy.splice(newResultIndex, 1);
      combinedResults.push(newResult);
    } else {
      combinedResults.push(cachedResult);
    }
  }

  combinedResults.push(...newResultsCopy);

  return combinedResults;
};

export const convertValuesToString = (
  obj: Record<string, unknown>,
): Record<string, string> => {
  return _.mapObject(obj, (value) => String(value));
};

export const removeIds = <T extends { id?: number }>(
  entries?: T[],
): Omit<T, "id">[] => {
  if (!entries) {
    return [];
  }

  return entries.map((entry) => {
    const entryCopy = { ...entry };
    delete entryCopy.id;

    return entryCopy;
  });
};

export const extractExtensionFromFilename = (
  filename: string,
  dotPrefix = true,
) => {
  const dotIndex = filename.lastIndexOf(".");

  return filename.substring(dotPrefix ? dotIndex : dotIndex + 1);
};
