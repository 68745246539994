import React, { useEffect } from "react";

import { Loader } from "@hexocean/braintrust-ui-components";
import {
  deleteActiveSession,
  fetchActiveSessions,
} from "@js/apps/settings/actions";
import { Snackbar } from "@js/components/snackbar";
import { useAppDispatch, useAppSelector } from "@js/hooks";

import { SessionItem } from "../item";

export const SessionList = () => {
  const dispatch = useAppDispatch();
  const sessions = useAppSelector((state) => state.settings.sessions);
  const loading = useAppSelector(
    (state) => state.settings.fetchingActiveSessions,
  );

  useEffect(() => {
    dispatch(fetchActiveSessions());
  }, [dispatch]);

  const handleDeleteClick = (session) => {
    const canDeleteSession = !session.is_current_session;

    if (canDeleteSession) {
      dispatch(deleteActiveSession(session.id))
        .then(() => {
          Snackbar.success("Session deleted.");
        })
        .catch(() => {
          Snackbar.success("Session cannot be deleted.");
        });
    }
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <div>
      {sessions.map((session, index) => {
        const canDeleteSession = !session.is_current_session;

        return (
          <SessionItem
            key={session.id}
            num={index + 1}
            session={session}
            onDeleteClick={canDeleteSession ? handleDeleteClick : null}
          />
        );
      })}
    </div>
  );
};
