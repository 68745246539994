import {
  Box,
  Button,
  Stack,
  Typography,
} from "@hexocean/braintrust-ui-components";
import { useMediaQuery } from "@hexocean/braintrust-ui-components/hooks";
import { FlowerIcon } from "@hexocean/braintrust-ui-components/Icons";
import { Emoji } from "@js/components/emoji";
import type { Space } from "@js/types/spaces";

import { useJoinThisSpace } from "../../hooks/use-join-this-space";

import { JoinThisSpaceModalInstance } from "./join-this-space-modal-instance";

import styles from "./styles.module.scss";

export const JoinThisSpaceModalContent = ({ space }: { space: Space }) => {
  const spaceId = space.id;
  const isMobile = useMediaQuery("sm");
  const { handleJoinSpaceClick } = useJoinThisSpace(Number(spaceId));

  const handleJoinThisSpaceClick = () => {
    JoinThisSpaceModalInstance.close();
    handleJoinSpaceClick();
  };

  return (
    <Box>
      <Box className={styles.topContentWrapper}>
        <Box className={styles.topContent}>
          <Stack
            direction="row"
            sx={{
              gap: 1,
              alignItems: "center",
              justifyContent: { sm: "center" },
              mb: { xs: 11, sm: 4.5 },
              px: 3,
              marginInline: { sm: "auto" },
              maxWidth: "80%",
              flexWrap: "wrap",
            }}
          >
            <Box className={styles.handEmoji}>
              <Emoji emoji="🤝" />
            </Box>
            <Typography
              component="h2"
              fontWeight={500}
              size="medium"
              variant="label"
              sx={{ whiteSpace: "nowrap" }}
            >
              Become a member
            </Typography>
          </Stack>
          <Box className={styles.avatarWrapperIcon}>
            <FlowerIcon className={styles.flowerIcon} />
            <img
              src={`${SETTINGS.STATIC_URL}spaces/join-modal-avatar.svg`}
              alt="avatar"
              className={styles.avatarIcon}
            />
          </Box>

          <Box className={styles.cardsIcon}>
            <img
              src={`${SETTINGS.STATIC_URL}spaces/card-1.svg`}
              alt="card-1"
              className={styles.cardLeft}
            />
            <img
              src={`${SETTINGS.STATIC_URL}spaces/card-2.svg`}
              alt="card-2"
              className={styles.cardRight}
            />
          </Box>
        </Box>
      </Box>
      <Box className={styles.bottomContent}>
        <Typography
          component="h3"
          variant="title"
          className={styles.bottomText}
          size={isMobile ? "small" : "medium"}
          fontWeight={400}
          sx={{ maxWidth: { md: "80%" } }}
        >
          Be part of the discussion
        </Typography>
        <Typography
          component="p"
          variant="paragraph"
          className={styles.bottomText}
          size={isMobile ? "small" : "medium"}
          sx={{ maxWidth: { md: "75%" }, mb: { xs: 2, md: 3 } }}
        >
          Join{" "}
          <Typography
            component="span"
            variant="paragraph"
            size={isMobile ? "small" : "medium"}
            fontWeight={500}
          >
            {space?.name}
          </Typography>{" "}
          to leave comments, react, and connect with other members who share
          your interests.
        </Typography>
        <Box sx={{ textAlign: "center" }}>
          <Button variant="primary" onClick={handleJoinThisSpaceClick}>
            Join this space
          </Button>
        </Box>
      </Box>
    </Box>
  );
};
