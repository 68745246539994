import { Component } from "react";
import type { ConnectedProps } from "react-redux";
import { connect } from "react-redux";
import _ from "underscore";

import type { IconButtonProps } from "@hexocean/braintrust-ui-components";
import { IconButton, Popover } from "@hexocean/braintrust-ui-components";
import { BellIcon } from "@hexocean/braintrust-ui-components/Icons";
import { navItemClicked } from "@js/apps/freelancer/actions";
import { NAV_ITEM_IDS } from "@js/constants";
import type { RootState } from "@js/store";
import { mapTypedDispatchToProps } from "@js/utils/store";

import { BadgeNotification } from "../badge-notifications";
import { SiteNotifications } from "../site-notifications";

type SiteNotificationsIconComponentProps = {
  style: IconButtonProps["style"];
} & ConnectedProps<typeof connector>;

class SiteNotificationsIconComponent extends Component<SiteNotificationsIconComponentProps> {
  state = {
    panelOpen: false,
    anchorEl: null,
  };

  handleOpen = (e) => {
    this.setState({
      panelOpen: true,
      anchorEl: e.currentTarget,
    });
    this.props.dispatch(
      navItemClicked({
        nav_item_id: NAV_ITEM_IDS.NOTIFICATIONS_OPEN,
        context_url: window.location.pathname,
      }),
    );
  };

  handleClose = () => {
    this.setState({
      panelOpen: false,
    });
  };

  render() {
    const { siteNotifications, dispatch, ...restProps } = this.props;
    const newCount = _.where(siteNotifications ?? [], { read: false }).length;
    return (
      <Popover
        PaperProps={{ style: { overflow: "visible", borderRadius: 12 } }}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: 53,
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={this.state.panelOpen}
        onClose={this.handleClose}
        anchorEl={this.state.anchorEl}
        anchor={
          <IconButton
            variant="transparent"
            aria-label="open notifications"
            onClick={this.handleOpen}
            size="medium"
            {...restProps}
          >
            <BadgeNotification notificationLength={newCount}>
              <BellIcon
                sx={{ width: "24px", height: "24px", strokeWidth: "1.5px" }}
              />
            </BadgeNotification>
          </IconButton>
        }
      >
        <SiteNotifications
          close={this.handleClose}
          anchorEl={this.state.anchorEl}
        />
      </Popover>
    );
  }
}

const mapStateToProps = (state: RootState) => ({
  siteNotifications: state.notifications.siteNotifications,
});

const connector = connect(mapStateToProps, mapTypedDispatchToProps);

export default connector(SiteNotificationsIconComponent);
