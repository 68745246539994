import { IconButton } from "@hexocean/braintrust-ui-components";
import { CloseIcon } from "@hexocean/braintrust-ui-components/Icons";
import { ModalInstance } from "@js/components/modal";

export const ProfileCompletionSequenceCloseBtn = () => {
  return (
    <IconButton
      onClick={ModalInstance.close}
      variant="tertiary"
      aria-label="close modal"
      size="x-small"
      sx={{
        position: "absolute",
        top: { xs: "10px", sm: "20px" },
        right: { xs: "10px", sm: "24px" },
      }}
    >
      <CloseIcon
        sx={{
          borderRadius: "50%",
          padding: "0.5rem",
        }}
      />
    </IconButton>
  );
};
