import React from "react";
import { useLocation } from "react-router-dom";

import {
  Box,
  Button,
  UnderlinedTabs,
} from "@hexocean/braintrust-ui-components";
import { useAccountType } from "@js/apps/common/hooks";
import { useShouldDisplayL1RoleTab } from "@js/apps/common/hooks";
import { CreateJobAiModalInstance } from "@js/apps/employer/components/create-job-ai-modal";
import {
  CreateJobModalInstance,
  openCreateJobModal,
} from "@js/apps/employer/components/create-job-modal";
import { useCanManageJobsOnBehalfOfClient } from "@js/apps/jobs/hooks";
import { RouterLink } from "@js/components/link";
import { useAppSelector } from "@js/hooks";
import type { Freelancer } from "@js/types/freelancer";

const JOBS_TAB = {
  path: `/jobs`,
  label: "All jobs",
};
type HeaderProps = {
  filtersSocket?: JSX.Element;
  buttons?: React.ReactNode[];
};

export const Header = ({ buttons, filtersSocket }: HeaderProps) => {
  const { pathname } = useLocation();
  const canManageJobAsCoreMember = useCanManageJobsOnBehalfOfClient();
  const { isFreelancer } = useAccountType();
  const freelancerProfile = useAppSelector(
    (state) => state.freelancer.freelancerProfile,
  );
  const shouldDisplayL1Tab = useShouldDisplayL1RoleTab();

  const roleTab =
    isFreelancer && freelancerProfile && getRoleTab(freelancerProfile);
  const jobTabs =
    shouldDisplayL1Tab && roleTab ? [roleTab, JOBS_TAB] : [JOBS_TAB];

  return (
    <Box className="job-listing-header">
      {canManageJobAsCoreMember && (
        <Box
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
          mb={2}
        >
          <Button
            shape="squared"
            size="medium"
            variant="positive"
            onClick={openCreateJobModal}
          >
            Post Job for a Client
          </Button>
          <CreateJobModalInstance />
          <CreateJobAiModalInstance />
        </Box>
      )}
      <Box className="job-listing-header__top-section">
        <UnderlinedTabs
          labelVariant="title"
          fontWeight={400}
          labelUnActiveColor="grey"
          resetLeftPadding
          padding="0"
          spacing={0}
          itemsGap="20px 40px"
          pathname={pathname}
          RouterLink={RouterLink}
          items={jobTabs}
        />
        <Box className="job-listing-header__buttons">{buttons}</Box>
      </Box>

      <Box className="job-listing-header__search-section">{filtersSocket}</Box>
    </Box>
  );
};

const getRoleTab = (profile: Freelancer) => {
  if (!profile) {
    throw new Error("profile is missing");
  }

  const selectedRole =
    profile.roles.find((role) => role.primary) || profile.roles[0];

  if (!selectedRole) {
    return;
  }

  const jobLabel = selectedRole.role.name;

  return (
    jobLabel && {
      path: `/role-jobs`,
      label: `${jobLabel} jobs`,
    }
  );
};
