import type { WrappedFieldInputProps } from "redux-form";

import type {
  AutocompleteOwnerState,
  AutocompleteRenderGetTagProps,
  AutocompleteRenderOptionState,
} from "@hexocean/braintrust-ui-components";
import { Checkbox } from "@hexocean/braintrust-ui-components";
import { LOCATIONS_TAGS_CHARACTERS_LIMIT } from "@js/apps/jobs/apps/create-job/constants";
import {
  prepareArrayWithTagsCharactersLimit,
  prepareAutocompleteValue,
} from "@js/apps/jobs/apps/create-job/utils";
import type { TimezoneOption } from "@js/types/jobs";

import { useLocationAndTimezoneNotification } from "../../use-location-and-timezone-notification";

export const useJobTimezoneField = (input: WrappedFieldInputProps) => {
  const { showLimitNotification } = useLocationAndTimezoneNotification();

  const handleOnChange = (data: TimezoneOption[]) => {
    if (data.length === 11) {
      showLimitNotification("TIMEZONES", SETTINGS.JOB_TIMEZONES_LIMIT);
    } else {
      input.onChange(data);
    }
  };

  const renderOptionsHandler = (
    props: React.HTMLAttributes<HTMLLIElement>,
    option: TimezoneOption,
    { selected }: AutocompleteRenderOptionState,
  ) => {
    return (
      <li {...props}>
        <Checkbox
          sx={{
            backgroundColor: selected ? "var(--yellow)" : "",
          }}
          checked={selected}
        />
        {option.name}
      </li>
    );
  };

  const renderTagsHandler = (
    timezones: TimezoneOption[],
    _: AutocompleteRenderGetTagProps,
    { focused }: AutocompleteOwnerState<TimezoneOption, true, false, false>,
  ) => {
    const timezoneTagsPreparation = prepareArrayWithTagsCharactersLimit(
      timezones,
      LOCATIONS_TAGS_CHARACTERS_LIMIT,
      (item) => item.name,
      focused,
    );

    return timezoneTagsPreparation.map((timezone, index) => {
      return prepareAutocompleteValue({
        value: timezone.name,
        isLast: index === timezoneTagsPreparation.length - 1,
        isInputFocused: focused,
        isLimitReached: timezoneTagsPreparation.length < timezones.length,
      });
    });
  };

  return { handleOnChange, renderOptionsHandler, renderTagsHandler };
};
