import type { Spread } from "lexical";
import type {
  DOMConversionMap,
  DOMConversionOutput,
  DOMExportOutput,
  EditorConfig,
  LexicalNode,
  NodeKey,
  SerializedTextNode,
} from "lexical";
import { TextNode } from "lexical";

export type SerializedHashtagNode = Spread<
  {
    hashtagName: string;
    type: "hashtag";
    version: 1;
  },
  SerializedTextNode
>;

function convertHashtagElement(
  domNode: HTMLElement,
): DOMConversionOutput | null {
  const textContent = domNode.textContent;

  if (textContent !== null) {
    const node = $createCustomHashtagNode(textContent);
    return {
      node,
    };
  }

  return null;
}

export class CustomHashtagNode extends TextNode {
  __hashtag: string;

  constructor(hashtagName: string, key?: NodeKey) {
    super(hashtagName, key);
    this.__hashtag = hashtagName;
  }

  static getType(): string {
    return "custom-hashtag";
  }

  static clone(node: CustomHashtagNode): CustomHashtagNode {
    return new CustomHashtagNode(node.__hashtag);
  }

  static importJSON(serializedNode: SerializedHashtagNode): CustomHashtagNode {
    const node = $createCustomHashtagNode(serializedNode.hashtagName);
    node.setTextContent(serializedNode.text);
    node.setFormat(serializedNode.format);
    node.setDetail(serializedNode.detail);
    node.setMode(serializedNode.mode);
    node.setStyle(serializedNode.style);
    return node;
  }

  exportJSON(): SerializedHashtagNode {
    return {
      ...super.exportJSON(),
      hashtagName: this.__hashtag,
      type: "hashtag",
      version: 1,
    };
  }

  createDOM(config: EditorConfig): HTMLElement {
    const element = super.createDOM(config);
    element.className = "hashtag-node";

    return element;
  }

  exportDOM(): DOMExportOutput {
    const element = document.createElement("span");
    element.setAttribute("data-lexical-hashtag", "true");
    element.textContent = this.__text;
    return { element };
  }

  static importDOM(): DOMConversionMap | null {
    return {
      span: (domNode: HTMLElement) => {
        if (!domNode.hasAttribute("data-lexical-hashtag")) {
          return null;
        }
        return {
          conversion: convertHashtagElement,
          priority: 1,
        };
      },
    };
  }

  isTextEntity() {
    return true;
  }
}

export function $createCustomHashtagNode(
  hashtagName: string,
): CustomHashtagNode {
  const hashtagNode = new CustomHashtagNode(hashtagName);
  hashtagNode.setMode("segmented").toggleDirectionless();
  return hashtagNode;
}

export function $isCustomHashtagNode(
  node: LexicalNode | null | undefined,
): node is CustomHashtagNode {
  return node instanceof CustomHashtagNode;
}
