import React from "react";
import classnames from "classnames";

import { Grid, Typography } from "@hexocean/braintrust-ui-components";
import { useAppSelector } from "@js/hooks";

import {
  CreateInvoiceButtons,
  DueAndOverdueAmounts,
  FreelancerHeroInfo,
} from "./components";

import styles from "./styles.module.scss";

export type HeroProps = {
  title?: string;
  isAnyInvoice: boolean;
  due: number;
  overdue: number;
  showCreateButton?: boolean;
  additionalActions?: React.ReactNode;
  canFreelancerCopyLatestInvoice?: boolean;
  copyFreelancerLatestInvoiceId?: number;
};

export const Hero = ({
  title = "Invoices",
  showCreateButton = false,
  additionalActions,
  isAnyInvoice = true,
  due,
  overdue,
  canFreelancerCopyLatestInvoice,
  copyFreelancerLatestInvoiceId,
}: HeroProps) => {
  const user = useAppSelector((state) => state.auth.user);

  return (
    <>
      {user?.freelancer && <FreelancerHeroInfo />}

      <Grid
        container
        direction="row"
        justifyContent="space-between"
        className={classnames(styles.hero, "invoices-listing-hero mt+")}
      >
        <Grid item lg={9} md={8} xs={12}>
          <Grid container direction="row">
            <Typography
              variant="display"
              component="h1"
              size="small"
              fontWeight={400}
              className="underline mb+"
            >
              {title}
            </Typography>
            {isAnyInvoice && (
              <Grid item xs={12}>
                <Grid container direction="row" className={styles.details}>
                  <DueAndOverdueAmounts due={due} overdue={overdue} />
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
        {showCreateButton && (
          <Grid item lg={3} md={4} xs={12} className={styles.action}>
            <CreateInvoiceButtons
              canFreelancerCopyLatestInvoice={canFreelancerCopyLatestInvoice}
              copyFreelancerLatestInvoiceId={copyFreelancerLatestInvoiceId}
            />
          </Grid>
        )}
        {!!additionalActions && (
          <Grid item xs={12}>
            {additionalActions}
          </Grid>
        )}
      </Grid>
    </>
  );
};
