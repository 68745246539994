import {
  Box,
  IconButton,
  Typography,
} from "@hexocean/braintrust-ui-components";
import {
  CircleCheckIcon,
  EditPen,
  ErrorSVGIcon,
} from "@hexocean/braintrust-ui-components/Icons";

import styles from "./style.module.scss";

export const ReviewBeforeSendingSummaryBox = (props) => {
  return <div className={styles.reviewBeforeSendingSummary} {...props} />;
};

export type ReviewBeforeSendingSummaryItemProps = {
  sectionName: string;
  sectionContent?: string | JSX.Element;
  ellipsis?: boolean;
  isError?: boolean;
  onEditClick?: () => void;
};

export const ReviewBeforeSendingSummaryItem = ({
  sectionName,
  sectionContent,
  ellipsis = true,
  isError,
  onEditClick,
}: ReviewBeforeSendingSummaryItemProps) => {
  return (
    <div className={styles.reviewBeforeSendingSummaryItem}>
      <Box display="flex" maxWidth="90%">
        <Typography
          component="p"
          variant="label"
          sx={{ pr: 1, minWidth: "fit-content" }}
          size="medium"
        >
          {sectionName}
        </Typography>
        {!!sectionContent && !isError && (
          <Typography
            component="p"
            variant="paragraph"
            ellipsis={ellipsis}
            sx={{ mt: "2px" }}
            size="small"
          >
            {sectionContent}
          </Typography>
        )}
        {isError && <MissingFieldErrorMessage />}
      </Box>
      {!!onEditClick ? (
        <IconButton
          variant="black-outlined"
          size="x-small"
          aria-label="Edit field"
          onClick={onEditClick}
        >
          <EditPen />
        </IconButton>
      ) : (
        <CircleCheckIcon
          sx={{
            color: "var(--positive-2)",
            fontSize: 16,
          }}
        />
      )}
    </div>
  );
};

const MissingFieldErrorMessage = () => (
  <Typography
    component="p"
    variant="paragraph"
    color="negative"
    ellipsis={true}
    sx={{
      mr: "8px",
      mt: "2px",
      display: "inline-flex",
      alignItems: "center",
      gap: "6px",
    }}
    size="small"
  >
    <ErrorSVGIcon
      style={{
        color: "var(--negative-2)",
        width: "18px",
        height: "18px",
      }}
    />
    Missing required field
  </Typography>
);
