import React from "react";
import { Field } from "redux-form";

import { Grid } from "@hexocean/braintrust-ui-components";
import IndustrySelectField from "@js/apps/employer/fields/industry-select";
import { Form } from "@js/forms/components/form";
import {
  AvatarField,
  GoogleStandardSingleField,
  reduxForm,
  RichTextareaField,
  SelectField,
  StandardTextField,
} from "@js/forms/fields";
import { required } from "@js/forms/validators";
import type { PlaceDetailsResponse } from "@js/hooks";
import type { Employer } from "@js/types/employer";
import { enumToOptions } from "@js/utils";

const organizationSizeOptions = enumToOptions(ENUMS.OrganizationSizeLabels);

const ORGANIZATION_INFO_FORM_ID = "organizationInfoForm";
export const AVATAR_UPDATED_FIELD_NAME = "logo_id";

export type OrganizationInfoFormData = {
  logo: string;
  organization_size: Employer["organization_size"];
  name: string;
  industry_id: number;
  google_full_location: PlaceDetailsResponse | null;
  overview: string;
  website: string;
};

export const OrganizationInfoForm = reduxForm<OrganizationInfoFormData>({
  form: ORGANIZATION_INFO_FORM_ID,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  unsavedChangesWarning: true,
})(({ submit, error, submitting }) => {
  return (
    <Form onSubmit={submitting ? undefined : submit} error={error}>
      <Grid container spacing={4}>
        <Grid item sm={3} md={2} className="column-left">
          <Field
            name="logo"
            updateFieldName={AVATAR_UPDATED_FIELD_NAME}
            uploadType={ENUMS.UploadType.EMPLOYER_LOGO_IMAGE_UPLOAD}
            type="organization"
            title="Upload Company Logo"
            component={AvatarField}
          />
        </Grid>
        <Grid item sm={7} className="column-right">
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <Field
                id="organization_size"
                name="organization_size"
                variant="standard"
                component={SelectField}
                options={organizationSizeOptions}
                label="Organization Size"
              />
            </Grid>
            <Grid item xs={6} />
            <Grid item xs={12}>
              <Field
                id="name"
                name="name"
                variant="standard"
                component={StandardTextField}
                label="Organization Name"
                size="small"
              />
            </Grid>
            <Grid item xs={6}>
              <Field
                id="industry_id"
                name="industry_id"
                variant="standard"
                component={IndustrySelectField}
                label="Industry"
              />
            </Grid>
            <Grid item xs={6} />
            <Grid item xs={6}>
              <Field
                id="google_full_location"
                name="google_full_location"
                component={GoogleStandardSingleField}
                label="Location"
                validate={[required]}
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                component={RichTextareaField}
                toolbar={[
                  ["bold", "italic"],
                  ["align-left", "align-center", "align-right"],
                  ["list", "indent"],
                  ["emoji", "clear-formatting"],
                  ["undo", "redo"],
                ]}
                id="overview"
                name="overview"
                label="Tell us a bit about the organization"
                maxTextLength={600}
                minHeight={250}
              />
            </Grid>
            <Grid item xs={12}>
              <Field
                id="website"
                name="website"
                variant="standard"
                component={StandardTextField}
                label="Website Address"
                size="small"
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Form>
  );
});
