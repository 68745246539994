import { useCallback, useState } from "react";
import { useParams } from "react-router-dom";

import { useWithRecaptcha } from "@js/apps/common/hooks";
import {
  useGetPublicPostsQuery,
  useGetRelevantPublicPostsQuery,
} from "@js/apps/give-and-get-help/api";
import { openSignUpModal } from "@js/apps/onboarding/components/sign-up-modal";

export const usePublicPostPage = () => {
  useWithRecaptcha();
  const { id } = useParams();
  const { data: mainPostData, isLoading: isMainPostLoading } =
    useGetPublicPostsQuery({ id: id as string }, { skip: !id });
  const { data: relevantPostData, isLoading: isRelevantPostsLoading } =
    useGetRelevantPublicPostsQuery({ id: id as string }, { skip: !id });
  const [isCtaBannerHidden, setIsCtaBannerHidden] = useState(false);

  const handleBannerClose = useCallback(() => {
    setIsCtaBannerHidden(true);
  }, []);

  const handleSignUp = useCallback(() => {
    openSignUpModal();
  }, []);
  return {
    handleSignUp,
    handleBannerClose,
    isCtaBannerHidden,
    relevantPostData,
    isRelevantPostsLoading,
    isMainPostLoading,
    mainPostData,
  };
};
