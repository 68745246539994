import { Box } from "@hexocean/braintrust-ui-components";
import { JobBudgetWithPaymentTypeTooltip } from "@js/apps/jobs/components/job-budget-with-payment-type-tooltip";
import type { MainJobListingJob } from "@js/types/jobs";

import styles from "./styles.module.scss";

type JobItemBudgetProps = {
  job: MainJobListingJob;
};

export const JobItemBudget = ({ job }: JobItemBudgetProps) => {
  return (
    <Box className={styles.wrapper} sx={{ mx: 1, mt: { xs: 1, sm: 0 }, mb: 1 }}>
      <JobBudgetWithPaymentTypeTooltip job={job} />
    </Box>
  );
};
