import { useCancelRefundRequestMutation } from "@js/apps/give-and-get-help/api";
import { ModalInstance } from "@js/components/modal";
import { Snackbar } from "@js/components/snackbar";
import { LocalStorage } from "@js/services";
import type { HelpOffer } from "@js/types/give-and-get-help";

import { CancelRefundRequestModal } from "../components/cancel-refund-request-modal";

export const useCancelHelpOfferRefund = (offer: HelpOffer) => {
  const [cancelRefund] = useCancelRefundRequestMutation();

  const handleCancelRefund = async (closeModal: () => void) => {
    await cancelRefund({ offerId: offer.id, data: offer })
      .unwrap()
      .then(() => {
        closeModal();
        LocalStorage.removeItem(LocalStorage.keys.HELP_OFFER_REFUND_REQUESTED);
        Snackbar.success("Refund request cancelled");
      })
      .catch(() => {
        Snackbar.error("Something went wrong.");
      });
  };

  const openCancelHelpOfferRefundModal = () => {
    ModalInstance.open({
      closeButton: true,
      closeButtonProps: {
        variant: "tertiary",
        size: "x-small",
      },
      keepOnBackdropClick: true,
      className: "cancel-refund-request-modal",
      children: <CancelRefundRequestModal cancelRefund={handleCancelRefund} />,
    });
  };

  return { openCancelHelpOfferRefundModal };
};
