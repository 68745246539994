import React from "react";

import { useSuggestPostCategoryMutation } from "@js/apps/give-and-get-help/api";
import {
  SuggestCategoryForm,
  SuggestCategoryModalFields,
} from "@js/apps/give-and-get-help/components/suggest-category-modal";
import { ModalInstance } from "@js/components/modal";
import { Snackbar } from "@js/components/snackbar";

export const useSuggestCategory = () => {
  const [suggestCategory] = useSuggestPostCategoryMutation();

  const openSuggestCategoryModal = ({
    onSubmitSuccess,
    onSubmitFail,
    onCancel,
  }) => {
    ModalInstance.open({
      closeButton: false,
      className: "suggest-category-modal",
      keepOnBackdropClick: true,
      disableEscapeKeyDown: true,
      children: (
        <SuggestCategoryForm
          onSubmit={({ categoryName }) =>
            new Promise((resolve, reject) => {
              suggestCategory({ name: categoryName })
                .unwrap()
                .then(resolve)
                .catch(reject);
            })
          }
          onSubmitSuccess={() => {
            ModalInstance.close();
            onSubmitSuccess();
          }}
          onSubmitFail={(error) => {
            if (!error?.categoryName) {
              Snackbar.error("Failed to suggest a category.");
            }

            onSubmitFail();
          }}
        >
          <SuggestCategoryModalFields
            onCancel={() => {
              ModalInstance.close();
              onCancel();
            }}
          />
        </SuggestCategoryForm>
      ),
    });
  };

  return {
    openSuggestCategoryModal,
  };
};
