import type { PopperProps } from "@hexocean/braintrust-ui-components";
import { ClickAwayListener, Popper } from "@hexocean/braintrust-ui-components";

export type HashtagPopperProps = PopperProps & {
  onClickAway: () => void;
  anchorElement: HTMLElement;
  isMobile: boolean;
};

export const HashtagPopper = ({
  onClickAway,
  anchorElement,
  isMobile,
  ...props
}: HashtagPopperProps) => {
  return (
    <ClickAwayListener onClickAway={onClickAway}>
      <Popper
        {...props}
        anchorEl={anchorElement}
        placement={isMobile ? "bottom-start" : "bottom"}
      />
    </ClickAwayListener>
  );
};
