import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";

import type { COMPLETION_NUDGE_PARAMS } from "@js/apps/freelancer/constants";
import { getQuery } from "@js/utils";

type OpenModalsProps = {
  requiredCondition: boolean | null;
  queryParam: EnumType<typeof COMPLETION_NUDGE_PARAMS>;
  openModal: () => void;
};

export const useOpenModalsFromCompletionNudge = ({
  requiredCondition,
  queryParam,
  openModal,
}: OpenModalsProps): void => {
  const [, setSearchParams] = useSearchParams();
  useEffect(() => {
    const isQueryParamSet = getQuery(queryParam);

    if (requiredCondition && isQueryParamSet) {
      openModal();

      setSearchParams({});
    }
  }, [openModal, queryParam, requiredCondition, setSearchParams]);
};
