import { useMemo, useState } from "react";

import type { EmployerTeamMemberType } from "@js/types/employer";

export type EmployerTeamMemberOptionType =
  | {
      inputValue: string;
    }
  | EmployerTeamMemberType;

type UseTeamMemberInvitationsProps = {
  selectedTeamMemberIds: number[];
  teamMembers: EmployerTeamMemberType[];
};

export const useTeamMemberInvitations = ({
  teamMembers,
  selectedTeamMemberIds,
}: UseTeamMemberInvitationsProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [inputValue, setInputValue] = useState("");

  const handleOpen = () => {
    setIsOpen(true);
  };
  const handleClose = () => {
    setIsOpen(false);
  };

  const teamMemberInvitationsValue: EmployerTeamMemberOptionType[] = useMemo(
    () =>
      teamMembers.filter((teamMember) => {
        return selectedTeamMemberIds.some((selectedTeamMemberId) => {
          return selectedTeamMemberId === teamMember.id;
        });
      }),
    [selectedTeamMemberIds, teamMembers],
  );

  const hasOptions = teamMemberInvitationsValue.length < teamMembers.length;
  const isOpenAndNotEmpty = isOpen && (hasOptions || !!inputValue);

  return {
    inputValue,
    setInputValue,
    isOpen: isOpenAndNotEmpty,
    handleOpen,
    handleClose,
    teamMemberInvitationsValue,
  };
};
