import { TALENT_REFERRAL_SORT_VALUES_MAP } from "@js/apps/dashboard/constants";
import {
  CLICK_INVITE_TALENT_AFTER_SEARCH,
  CLICK_TALENT_AFTER_SEARCH,
} from "@js/apps/freelancer/action-types";
import type { TalentAfterSearchPayload } from "@js/apps/freelancer/actions";
import type { TrackableUserAction } from "@js/middlewares/analytics/types";
import { Events, EventsProperties } from "@js/services/analytics";
import { logEvent } from "@js/services/analytics/event-logging";

export const handleFreeLancerAnalyticsActions = (
  action: TrackableUserAction,
) => {
  switch (action.type) {
    case Events.TALENT_REFERRAL_COPY_LINK: {
      logEvent(Events.TALENT_REFERRAL_COPY_LINK);
      break;
    }

    case Events.TALENT_REFERRAL_EMAIL_LINK: {
      logEvent(Events.TALENT_REFERRAL_EMAIL_LINK);
      break;
    }
    case Events.TALENT_REFERRAL_LINKEDIN_LINK: {
      logEvent(Events.TALENT_REFERRAL_LINKEDIN_LINK);
      break;
    }
    case Events.TALENT_REFERRAL_TWITTER_LINK: {
      logEvent(Events.TALENT_REFERRAL_TWITTER_LINK);
      break;
    }
    case CLICK_TALENT_AFTER_SEARCH: {
      const { talentId, resultPosition, location, searchQueryId } =
        action.payload as TalentAfterSearchPayload;
      logEvent(Events.CLICK_TALENT_SEARCH, {
        [EventsProperties.talent_id]: talentId,
        [EventsProperties.result_position]: resultPosition,
        [EventsProperties.talent_location]: location,
        [EventsProperties.search_query_id]: searchQueryId,
      });

      break;
    }

    case CLICK_INVITE_TALENT_AFTER_SEARCH: {
      const { talentId, resultPosition, location, searchQueryId } =
        action.payload as TalentAfterSearchPayload;
      logEvent(Events.CLICK_TALENT_INVITE_SEARCH, {
        [EventsProperties.talent_id]: talentId,
        [EventsProperties.result_position]: resultPosition,
        [EventsProperties.talent_location]: location,
        [EventsProperties.search_query_id]: searchQueryId,
      });

      break;
    }

    case Events.REFERRAL_TALENT_SORT_ORDER: {
      const { sortBy } = action.payload;
      logEvent(Events.REFERRAL_TALENT_SORT_ORDER, {
        [EventsProperties.sort_filter]: TALENT_REFERRAL_SORT_VALUES_MAP[sortBy],
      });
      break;
    }

    case Events.CLICK_REFERRAL_TALENT_CSV: {
      logEvent(Events.CLICK_REFERRAL_TALENT_CSV);
      break;
    }

    default: {
      break;
    }
  }
};
