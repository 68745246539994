import { z } from "zod";

import {
  useGetJobSubscriberInvitationsQuery,
  useGetJobSubscribersQuery,
  useUpdateJobSubscriberInvitationsMutation,
  useUpdateJobSubscribersMutation,
} from "@js/apps/jobs/api";
import { openDecoratedSuccessSnackbar } from "@js/components/decorated-success-snackbar";
import { Snackbar } from "@js/components/snackbar";
import type {
  JobSubscriberData,
  JobSubscriberInvitationData,
} from "@js/types/jobs";

import { useGetEmployerTeamMembersQuery } from "../../api";
import {
  closeCommonTeamMembersModal,
  openCommonTeamMembersModal,
} from "../common-team-members-modal";

import { SubscribeTeamMembersToJobModalContent } from "./subscribe-team-members-to-job-modal-content";

type SubscribeTeamMembersToJobModalProps = {
  jobId: number;
  jobOwnerId: number;
};

const SubscribeTeamMembersToJobModal = ({
  jobId,
  jobOwnerId,
}: SubscribeTeamMembersToJobModalProps) => {
  const { data: jobSubscribers, isLoading: isLoadingJobSubscribers } =
    useGetJobSubscribersQuery({ jobId });
  const {
    data: jobSubscriberInvitations,
    isLoading: isLoadingJobSubscriberInvitations,
  } = useGetJobSubscriberInvitationsQuery({ jobId });
  const { data: teamMembers, isLoading: isLoadingTeamMembers } =
    useGetEmployerTeamMembersQuery();
  const [updateJobSubscribers, { isLoading: isSavingSubscribers }] =
    useUpdateJobSubscribersMutation();
  const [updateJobSubscriberInvitations, { isLoading: isSavingInvitations }] =
    useUpdateJobSubscriberInvitationsMutation();

  const handleSaveSubscribers = async ({
    editedJobSubscribers,
    editedJobSubscriberInvitations,
  }: {
    editedJobSubscribers?: JobSubscriberData[];
    editedJobSubscriberInvitations?: JobSubscriberInvitationData[];
  }) => {
    try {
      if (editedJobSubscriberInvitations) {
        await updateJobSubscriberInvitations({
          jobId,
          jobSubscriberInvitations: editedJobSubscriberInvitations,
        }).unwrap();
      }

      if (editedJobSubscribers) {
        await updateJobSubscribers({
          jobId,
          jobSubscribers: editedJobSubscribers,
        }).unwrap();
      }

      handleUpdateJobSubscribersSuccess({
        editedJobSubscriberInvitations,
        editedJobSubscribers,
      });

      closeSubscribeTeamMembersToJobModal();
    } catch (error) {
      handleUpdateJobSubscribersError(error);
    }
  };

  const isLoading =
    isLoadingJobSubscribers ||
    isLoadingTeamMembers ||
    isLoadingJobSubscriberInvitations;

  return (
    <SubscribeTeamMembersToJobModalContent
      jobOwnerId={jobOwnerId}
      teamMembers={teamMembers}
      jobSubscribers={jobSubscribers}
      jobSubscriberInvitations={jobSubscriberInvitations}
      isLoading={isLoading}
      isSaving={isSavingSubscribers || isSavingInvitations}
      onSaveSubscribers={handleSaveSubscribers}
    />
  );
};

const openSubscribeTeamMembersToJobModal = (
  props: SubscribeTeamMembersToJobModalProps,
) => {
  openCommonTeamMembersModal({
    children: <SubscribeTeamMembersToJobModal {...props} />,
  });
};

const closeSubscribeTeamMembersToJobModal = () => {
  closeCommonTeamMembersModal();
};

const updateJobSubscribersEmailErrorSchema = z.object({
  data: z.object({ email: z.string() }),
});

const handleUpdateJobSubscribersError = (error: unknown) => {
  const parsedError = updateJobSubscribersEmailErrorSchema.safeParse(error);
  if (!parsedError.success) {
    Snackbar.error("Something went wrong!");

    return;
  }

  Snackbar.error(parsedError.data.data.email);
};

const handleUpdateJobSubscribersSuccess = ({
  editedJobSubscribers,
  editedJobSubscriberInvitations,
}: {
  editedJobSubscribers?: JobSubscriberData[];
  editedJobSubscriberInvitations?: JobSubscriberInvitationData[];
}) => {
  if (!editedJobSubscribers && !editedJobSubscriberInvitations) {
    return;
  }

  openDecoratedSuccessSnackbar(
    "Job subscribers have been successfully updated",
  );
};

export { openSubscribeTeamMembersToJobModal };
