import { useEffect } from "react";

import { useAccountType } from "@js/apps/common/hooks";
import { fetchEmployerProfile } from "@js/apps/employer/actions";
import { useGetJobQuery } from "@js/apps/jobs/api";
import { useAppDispatch, useAppSelector } from "@js/hooks";

import { useFilteredBidList } from "../use-filtered-bid-list";

export const useJobBids = (jobId: number) => {
  const dispatch = useAppDispatch();
  const {
    bidList,
    isBidListLoading,
    refetchBidList,
    bidFilters,
    areFiltersDefault,
    total,
    isLastPage,
    resetFilters,
  } = useFilteredBidList({ jobId });
  const {
    data: job,
    refetch: refetchJob,
    isLoading,
  } = useGetJobQuery({ id: Number(jobId) });
  const { fetchingEmployerProfile } = useAppSelector((state) => state.employer);
  const { isEmployer } = useAccountType();

  useEffect(() => {
    // fetch employer profile to check if deposit payment failed
    if (!fetchingEmployerProfile && isEmployer) {
      dispatch(fetchEmployerProfile());
    }
    // eslint-disable-next-line
  }, [dispatch]);

  return {
    loading: !job || isLoading || fetchingEmployerProfile,
    jobBidsLoading: isBidListLoading,
    job,
    bidList,
    totalBidsCount: total || 0,
    refetchBidList,
    refetchJob,
    areFiltersDefault,
    isShowingHiddenBids: !!bidFilters?.is_hidden,
    isLastPage,
    resetFilters,
  };
};
