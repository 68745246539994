import React from "react";
import type { Control, FieldValues, UseControllerProps } from "react-hook-form";
import { useController } from "react-hook-form";

import type { DatePickerProps } from "@hexocean/braintrust-ui-components";
import { DatePicker } from "@hexocean/braintrust-ui-components";

export type RhfDatePickerFieldProps<T extends FieldValues> = Omit<
  DatePickerProps,
  "value" | "onChange"
> &
  UseControllerProps<T> & {
    id: DatePickerProps["id"];
    label: DatePickerProps["label"];
    control: Control<T>;
  };

export const RhfDatePicker = <T extends FieldValues>({
  helperText,
  ...props
}: RhfDatePickerFieldProps<T>) => {
  const { field, fieldState } = useController(props);

  return (
    <DatePicker
      {...props}
      onAccept={() => {
        field.onBlur(); // mark as touched
      }}
      disabled={field.disabled}
      onBlur={field.onBlur}
      onChange={field.onChange}
      value={field.value || null}
      error={fieldState.error?.message}
      helperText={fieldState.error?.message || helperText}
    />
  );
};
