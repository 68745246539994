import { REPORT_REASONS } from "@js/apps/common/constants";
import { ReportForm } from "@js/apps/common/forms/report";
import { ModalInstance } from "@js/components/modal";

export const openReportReviewModalInstance = (onReportReview) =>
  ModalInstance.open({
    closeButton: true,
    closeButtonProps: {
      variant: "tertiary",
      size: "x-small",
    },
    keepOnBackdropClick: true,
    className: "report-modal",
    children: (
      <ReportForm
        type="review"
        onSubmit={(reportReason) => onReportReview(reportReason)}
        reasons={REPORT_REASONS}
        closeModal={ModalInstance.close}
      />
    ),
  });
