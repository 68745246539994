import { Loader, Typography } from "@hexocean/braintrust-ui-components";
import { CreditCardIcon } from "@hexocean/braintrust-ui-components/Icons";
import { ModalConfirm, ModalInstance } from "@js/components/modal";

import { useCCPaymentFee } from "./use-cc-payment-fee";

type CcPaymentFeeModalProps = {
  onConfirm: () => void;
};

export const openCcPaymentFeeModal = ({
  onConfirm,
}: CcPaymentFeeModalProps) => {
  ModalInstance.open({
    closeButton: false,
    keepOnBackdropClick: true,
    className: "text-confirmation-modal",
    children: <CcPaymentFeeModalContent onConfirm={onConfirm} />,
  });
};

const CcPaymentFeeModalContent = ({ onConfirm }: CcPaymentFeeModalProps) => {
  const { loading, valueToPay, totalIncludingCCPaymentFee, handleConfirm } =
    useCCPaymentFee({
      onConfirm,
    });

  if (loading) {
    return <Loader />;
  }

  return (
    <ModalConfirm
      onCancel={ModalInstance.close}
      onConfirm={handleConfirm}
      confirmText="Pay"
    >
      <Typography
        className="mb+ header-with-icon"
        component="h1"
        variant="title"
        fontWeight={400}
        size="small"
      >
        <CreditCardIcon /> Payment Fee
      </Typography>
      {totalIncludingCCPaymentFee?.fee_charged ? (
        <>
          <Typography component="p">
            You've chosen to pay using Credit Card. This type of payment is
            charged a 3.9% processing fee. The new total is{" "}
            <strong>{valueToPay}</strong>. Proceed?
          </Typography>
          <Typography component="p" size="small" color="grey">
            Payment fee will be added to the appropriate fee invoice(s).
          </Typography>
        </>
      ) : (
        <Typography component="p">
          You are about to pay <strong>{valueToPay}</strong>. Proceed?
        </Typography>
      )}
    </ModalConfirm>
  );
};
