import { useMemo } from "react";

import {
  CreatableComboBox,
  TextField,
} from "@hexocean/braintrust-ui-components";

import { invoiceApi } from "../../api";

const defaultPONumbers = [];

const usePreviousPONumbers = (job: number) => {
  const { previousPONumbers } =
    invoiceApi.endpoints.getInitialInvoiceData.useQueryState(
      {
        job,
      },
      {
        selectFromResult: (result) => {
          return {
            previousPONumbers:
              result.data?.previous_po_numbers || defaultPONumbers,
          };
        },
      },
    );

  const options = useMemo(() => {
    return previousPONumbers.map((poNumber) => ({
      value: poNumber,
      label: poNumber,
    }));
  }, [previousPONumbers]);

  return options;
};

export const PreviousPONumbersAutocompleteField = ({
  job,
  new_items,
  names,
}) => {
  const previousPONumbers = usePreviousPONumbers(job.input.value);
  const input = new_items.filter(Boolean)[0].po_number.input;
  const meta = new_items.filter(Boolean)[0].po_number.meta;

  return (
    <CreatableComboBox
      options={previousPONumbers}
      id={names[0]}
      value={input.value}
      onChange={(_ev, value) => {
        if (value?.value) {
          input.onChange(value.value);
        } else if (value === null) {
          input.onChange(null);
        }
      }}
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            label="PO/Order "
            variant="standard"
            helperText={meta.touched && meta.error}
            error={!!meta.error}
          />
        );
      }}
    />
  );
};
