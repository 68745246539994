import { useGetSpaceMembersQuery } from "@js/apps/spaces/api";

export const useIsPostAuthorASpaceAdmin = (
  spaceId?: number | null,
  postAuthorId?: number,
) => {
  const { data: spaceMembers } = useGetSpaceMembersQuery(
    { id: spaceId as number },
    { skip: typeof spaceId !== "number" || Number.isNaN(spaceId) },
  );
  const spaceMember = spaceMembers?.results.find((member) => {
    if (member.user.freelancer_id !== postAuthorId) return false;
    return member.is_admin;
  });
  if (!spaceMember) return false;
  return spaceMember.is_admin;

  return true;
};
