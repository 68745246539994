export const FETCH_FREELANCER_INVOICES = "FETCH_FREELANCER_INVOICES";
export const FETCH_FREELANCER_INVOICES_SUCCESS =
  "FETCH_FREELANCER_INVOICES_SUCCESS";
export const FETCH_FREELANCER_INVOICES_FAILED =
  "FETCH_FREELANCER_INVOICES_FAILED";
export const FETCH_FREELANCER_INVOICES_SUMMARY_SUCCESS =
  "FETCH_FREELANCER_INVOICES_SUMMARY_SUCCESS";
export const FETCH_FREELANCER_INVOICES_SUMMARY_FAILED =
  "FETCH_FREELANCER_INVOICES_SUMMARY_FAILED";

export const FETCH_EMPLOYER_INVOICES = "FETCH_EMPLOYER_INVOICES";
export const FETCH_EMPLOYER_INVOICES_SUCCESS =
  "FETCH_EMPLOYER_INVOICES_SUCCESS";
export const FETCH_EMPLOYER_INVOICES_FAILED = "FETCH_EMPLOYER_INVOICES_FAILED";

export const FETCH_EMPLOYER_INVOICES_SUMMARY_SUCCESS =
  "FETCH_EMPLOYER_INVOICES_SUMMARY_SUCCESS";
export const FETCH_EMPLOYER_INVOICES_SUMMARY_FAILED =
  "FETCH_EMPLOYER_INVOICES_SUMMARY_FAILED";

export const FETCH_DEPENDENT_EMPLOYER_INVOICES =
  "FETCH_DEPENDENT_EMPLOYER_INVOICES";
export const FETCH_DEPENDENT_EMPLOYER_INVOICES_SUCCESS =
  "FETCH_DEPENDENT_EMPLOYER_INVOICES_SUCCESS";
export const FETCH_DEPENDENT_EMPLOYER_INVOICES_FAILED =
  "FETCH_DEPENDENT_EMPLOYER_INVOICES_FAILED";

export const FETCH_TOTAL_INCLUDING_CC_PAYMENT_FEE =
  "FETCH_TOTAL_INCLUDING_CC_PAYMENT_FEE";
export const FETCH_TOTAL_INCLUDING_CC_PAYMENT_FEE_SUCCESS =
  "FETCH_TOTAL_INCLUDING_CC_PAYMENT_FEE_SUCCESS";
export const FETCH_TOTAL_INCLUDING_CC_PAYMENT_FEE_FAILED =
  "FETCH_TOTAL_INCLUDING_CC_PAYMENT_FEE_FAILED";

export const FETCH_INVOICE_BATCHES = "FETCH_INVOICE_BATCHES";
export const FETCH_INVOICE_BATCHES_SUCCESS = "FETCH_INVOICE_BATCHES_SUCCESS";
export const FETCH_INVOICE_BATCHES_FAILED = "FETCH_INVOICE_BATCHES_FAILED";

export const FETCH_FREELANCER_LAST_INVOICE = "FETCH_FREELANCER_LAST_INVOICE";
export const FETCH_FREELANCER_LAST_INVOICE_SUCCESS =
  "FETCH_FREELANCER_LAST_INVOICE_SUCCESS";
export const FETCH_FREELANCER_LAST_INVOICE_FAILED =
  "FETCH_FREELANCER_LAST_INVOICE_FAILED";
