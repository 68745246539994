import { API } from "@js/api";

import type * as I from "./types";

const nftApi = API.injectEndpoints({
  endpoints: (build) => ({
    getNftStatus: build.query<I.NftStatus, void>({
      query: () => ({
        url: "/nft/status/",
        method: "GET",
      }),
    }),
    getNftUserData: build.query<I.NftUserPublicData, void>({
      query: () => ({
        url: "/nft/user_data/",
        method: "GET",
      }),
    }),
    getNftTokenMeta: build.query<I.NftTokenMeta, number>({
      query: (tokenId) => ({
        url: `/nft/${tokenId}/`,
        method: "GET",
      }),
    }),
    getNftDetails: build.query<I.NftDetails, number>({
      query: (tokenId) => ({
        url: `/nft/${tokenId}/details/`,
        method: "GET",
      }),
    }),
    mintToken: build.mutation<void, { wallet: string; signature: string }>({
      query: (data) => ({
        url: "/nft/mint/",
        method: "POST",
        data,
      }),
    }),
  }),
});

export const {
  useGetNftTokenMetaQuery,
  useGetNftStatusQuery,
  useGetNftUserDataQuery,
  useMintTokenMutation,
  useGetNftDetailsQuery,
} = nftApi;
