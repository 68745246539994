import { IconButton, Menu } from "@hexocean/braintrust-ui-components";
import { MoreVertIcon } from "@hexocean/braintrust-ui-components/Icons";

import type { ActionType } from "../../actions";

type ActionMenuProps = {
  id: number;
  actions: ActionType[];
};

export const ActionMenu = ({ id, actions }: ActionMenuProps) => {
  return (
    <Menu
      id={`team-members-${id}`}
      className="employer-team-members__menu"
      anchor={
        <IconButton
          aria-label={`${id}-menu`}
          aria-controls={`team-members-${id}`}
          aria-haspopup="true"
          variant="transparent"
        >
          <MoreVertIcon />
        </IconButton>
      }
      transformOrigin={{
        horizontal: "right",
        vertical: "top",
      }}
    >
      {actions.map(({ label, key, onClick }) => {
        return (
          <Menu.Item key={key} onClick={onClick}>
            {label}
          </Menu.Item>
        );
      })}
    </Menu>
  );
};
