import React from "react";
import classNames from "classnames";

import type { ButtonProps } from "@hexocean/braintrust-ui-components";
import { Button, Typography } from "@hexocean/braintrust-ui-components";
import { CheckIcon } from "@hexocean/braintrust-ui-components/Icons";
import { useCopyToClipboard } from "@js/hooks/copy-to-clipboard";

type ButtonCopyToClipboardProps = {
  text: string;
  successTextColor?: "dark" | "white";
  copiedAlternative?: React.ReactNode;
  onCopied?: () => void;
} & ButtonProps;

export const ButtonCopyToClipboard = React.forwardRef<
  HTMLButtonElement,
  React.PropsWithChildren<ButtonCopyToClipboardProps>
>(
  (
    {
      children = "Copy",
      variant = "secondary",
      className = "",
      text,
      color = "secondary",
      successTextColor = "dark",
      copiedAlternative = undefined,
      onCopied,
      ...rest
    },
    ref,
  ) => {
    const { copied, handleCopy } = useCopyToClipboard(onCopied);

    return (
      <div className="button-copy-to-clipboard">
        <Button
          {...rest}
          variant={variant}
          color={color}
          className={classNames("button-copy-to-clipboard-input", className)}
          onClick={() => handleCopy(text)}
          ref={ref}
        >
          {copied ? copiedAlternative || <CheckIcon /> : children || ""}
        </Button>
        {!copiedAlternative && copied && (
          <Typography
            component="span"
            size="small"
            color="grey-2"
            className={`button-copy-to-clipboard-message button-copy-to-clipboard-message--${successTextColor}`}
          >
            Copied to clipboard
          </Typography>
        )}
      </div>
    );
  },
);
