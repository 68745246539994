import { createFiltersForm, FiltersManager } from "@js/apps/filters";

type InvoiceSearchFiltersFormValues = {
  search?: string;
};

const defaultInvoiceSearchFiltersFormValues: InvoiceSearchFiltersFormValues = {
  search: undefined,
};

export const InvoiceSearchFiltersInstance =
  createFiltersForm<InvoiceSearchFiltersFormValues>(
    new FiltersManager({
      defaultFormValues: defaultInvoiceSearchFiltersFormValues,
    }),
    {
      className: "mr",
    },
  );
