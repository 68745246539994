import type { ChangeEvent } from "react";
import { useEffect, useRef, useState } from "react";
import { change, touch } from "redux-form";
import { registerField } from "redux-form";

import type {
  CropAndUploadImage,
  CropAndUploadOptions,
  UploadedFile,
  UploadType,
} from "@js/apps/file-upload";
import { useAppDispatch } from "@js/hooks";

import type { AvatarType } from ".";

type UseAvatarFieldProps = CropAndUploadOptions & {
  updateFieldName: string;
  uploadedFile?: UploadedFile;
  cropAndUploadImage: CropAndUploadImage;
  form: string;
  name: string;
  value: string;
  uploadType: UploadType;
  type: AvatarType;
  validFormats?: string[];
};

export const useAvatarField = ({
  updateFieldName,
  uploadedFile,
  cropAndUploadImage,
  form,
  name,
  value,
  minWidth,
  minHeight,
  uploadType,
  type,
  maxSize,
  validFormats,
}: UseAvatarFieldProps) => {
  const dispatch = useAppDispatch();
  const [isFocused, setIsFocused] = useState(false);
  const updateFieldNameRef = useRef(updateFieldName);
  useEffect(() => {
    if (!uploadedFile) {
      return;
    }

    dispatch(registerField(form, updateFieldNameRef.current, "Field"));
    dispatch(change(form, updateFieldNameRef.current, uploadedFile.id));
    setIsFocused(false);
  }, [uploadedFile, form, updateFieldNameRef, dispatch]);

  const previewImage = uploadedFile ? uploadedFile.attachment.file : value;

  const handleOnChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
    const [file] = Array.from(target.files || []);
    if (!file) return;

    dispatch(touch(form, name));

    setIsFocused(false);

    cropAndUploadImage({
      file,
      squared: type === "organization",
      minWidth,
      minHeight,
      uploadType,
      maxSize,
      validFormats,
    });

    target.value = "";
  };
  return {
    handleOnChange,
    isFocused,
    setIsFocused,
    previewImage,
  };
};
