import { useMemo } from "react";
import { useNavigate } from "react-router";
import classNames from "classnames";

import {
  Box,
  Button,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Paper,
  Typography,
} from "@hexocean/braintrust-ui-components";
import {
  ArrowRightIcon,
  EditPen,
} from "@hexocean/braintrust-ui-components/Icons";
import { ModalInstance } from "@js/components/modal";
import type { SpaceRule } from "@js/types/spaces";

import { openSortableRuleListModal } from "./sortable-rule-list-modal";

import style from "./style.module.scss";

export type RuleListProps = {
  spaceId: number;
  rules: SpaceRule[];
  shouldRenderEditRulesButton: boolean;
  showRulesPreviewOnly?: boolean;
  shouldRedirectOnReadMore?: boolean;
};

const RuleList = ({
  spaceId,
  rules,
  shouldRenderEditRulesButton,
  showRulesPreviewOnly,
  shouldRedirectOnReadMore,
}: RuleListProps) => {
  const navigate = useNavigate();
  const handleReadMoreClick = () => {
    if (shouldRedirectOnReadMore) {
      return navigate(`/spaces/${spaceId}/about/`);
    }
    openRuleListModal({ rules, spaceId });
  };

  const handleEditRulesClick = () => {
    openSortableRuleListModal(spaceId);
  };

  const listItems = rules.map((rule, index) => {
    return <RuleListItem rule={rule} ruleNumber={index + 1} key={rule.id} />;
  });

  const showFade = useMemo(() => {
    return (
      rules?.length > 1 ||
      (rules[0]?.description?.length && rules[0].description.length > 500)
    );
  }, [rules]);

  return (
    <Paper
      variant="outlined"
      className={classNames(style.container, {
        [style.containerLimitedHeight]: showRulesPreviewOnly,
      })}
    >
      <List className={style.ruleList}>
        <Box className={style.ruleTitleWrapper}>
          <Typography component="p" variant="paragraph" size="large">
            Rules
          </Typography>

          {shouldRenderEditRulesButton && (
            <IconButton
              variant="black-outlined"
              size="x-small"
              aria-label="edit rules"
              children={<EditPen />}
              onClick={handleEditRulesClick}
            />
          )}
        </Box>

        <Box
          className={classNames(style.ruleList, {
            [style.ruleListLimitedHeight]: showRulesPreviewOnly,
          })}
        >
          {listItems}
        </Box>

        {showRulesPreviewOnly && rules.length > 0 && (
          <>
            <Button
              className={style.readMoreWrapper}
              onClick={handleReadMoreClick}
              variant="no-padding"
            >
              Read more <ArrowRightIcon />
            </Button>

            {showFade && <Box className={style.fade} />}
          </>
        )}
      </List>
    </Paper>
  );
};

type RuleListItemProps = {
  rule: SpaceRule;
  ruleNumber: number;
};

const RuleListItem = ({ rule, ruleNumber }: RuleListItemProps) => {
  const { title, description } = rule;

  return (
    <ListItem disablePadding className={style.rule}>
      <Box className={style.ruleCounter}>
        <Typography component="p" variant="label">
          {ruleNumber}
        </Typography>
      </Box>

      <ListItemText
        className={style.ruleText}
        primary={
          <Typography component="p" variant="label" ellipsis>
            {title}
          </Typography>
        }
        secondary={
          <Typography
            component="span"
            variant="paragraph"
            display="block"
            className={style.ruleDescription}
          >
            {description}
          </Typography>
        }
      />
    </ListItem>
  );
};

const openRuleListModal = ({
  rules,
  spaceId,
}: {
  rules: SpaceRule[];
  spaceId: number;
}) => {
  ModalInstance.open({
    padding: false,
    closeButton: true,
    closeButtonProps: { variant: "tertiary", size: "x-small" },
    className: style.readMoreModal,
    children: (
      <RuleList
        spaceId={spaceId}
        rules={rules}
        shouldRenderEditRulesButton={false}
      />
    ),
  });
};

export { RuleList };
