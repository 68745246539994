import { SubmissionError } from "redux-form";
import axios from "axios";

import {
  FETCHING_INVOICES_TO_SETTLE_EMPLOYERS_PAYMENT,
  FETCHING_INVOICES_TO_SETTLE_EMPLOYERS_PAYMENT_FAILED,
  FETCHING_INVOICES_TO_SETTLE_EMPLOYERS_PAYMENT_SUCCESS,
  WITHDRAWALS_FAILED,
  WITHDRAWALS_FETCHING,
  WITHDRAWALS_SUCCESS,
} from "@js/apps/admin/action-types";
import { Snackbar } from "@js/components/snackbar";
import type { AppThunkAction } from "@js/store";
import { handleDownloadFile } from "@js/utils";

import type { CustomerInvoiceReportFiltersFormParamsType } from "./filters";

export const downloadCustomerInvoiceReportPDFs = (
  params: Partial<CustomerInvoiceReportFiltersFormParamsType>,
) =>
  axios
    .get("/api/invoice_report/download_zip/", { responseType: "blob", params })
    .then((response) => {
      const downloadUrl = window.URL.createObjectURL(response.data);
      const link = document.createElement("a");
      link.href = downloadUrl;
      link.setAttribute("download", "invoice_report.zip");
      document.body.appendChild(link);
      link.click();
      link.remove();
    })
    .catch(() =>
      Snackbar.error(
        "Please, narrow your search criteria to enable PDF download.",
      ),
    );

export const downloadCustomerInvoiceReportCSV = (
  params: Partial<CustomerInvoiceReportFiltersFormParamsType> & {
    format: string;
  },
) => {
  const { report } = params;
  const endpoint =
    report === "xero_invoice_report"
      ? "/api/xero_invoice_report/"
      : "/api/invoice_report/";

  handleDownloadFile({ endpoint, params });
};

export const approveTalent = (talentId) =>
  axios.post(`/api/talent/${talentId}/approve/`);

export const fetchWithdrawals =
  (params?): AppThunkAction<Promise<any>> =>
  (dispatch) =>
    new Promise((resolve) => {
      dispatch({
        type: WITHDRAWALS_FETCHING,
      });

      return axios
        .get(`/api/token_transactions/`, { params })
        .then((response) => {
          dispatch({
            type: WITHDRAWALS_SUCCESS,
            payload: response.data,
          });

          resolve(response.data);
        })
        .catch(() =>
          dispatch({
            type: WITHDRAWALS_FAILED,
          }),
        );
    });

export const saveTokenBalanceItem = (values) =>
  new Promise<{ key: string }>((resolve, reject) => {
    const cleanValues = { ...values };

    if (!cleanValues.debit) {
      // To avoid "This field may not be null." error.
      delete cleanValues.debit;
    }

    if (!cleanValues.credit) {
      // To avoid "This field may not be null." error.
      delete cleanValues.credit;
    }

    if (cleanValues.code) {
      // When 2FA code is required and we send new code "key" shouldn't be sent (so it won't be validated).
      delete cleanValues.key;
    }

    return axios
      .post(`/api/token_balance_items/`, cleanValues)
      .then((response) => resolve(response.data))
      .catch(({ response: { data } }) => reject(new SubmissionError(data)));
  });

export const fetchInvoicesToSettleEmployersPayment =
  ({ employer, amount }): AppThunkAction<Promise<any>> =>
  (dispatch) =>
    new Promise((resolve, reject) => {
      dispatch({
        type: FETCHING_INVOICES_TO_SETTLE_EMPLOYERS_PAYMENT,
      });

      return axios
        .get(`/api/invoice_report/unpaid_invoices/`, {
          params: { employer, amount },
        })
        .then((response) => {
          dispatch({
            type: FETCHING_INVOICES_TO_SETTLE_EMPLOYERS_PAYMENT_SUCCESS,
            payload: response.data,
          });

          resolve(response.data);
        })
        .catch((error) => {
          dispatch({
            type: FETCHING_INVOICES_TO_SETTLE_EMPLOYERS_PAYMENT_FAILED,
          });

          reject(new SubmissionError(error.response.data));
        });
    });

export const rejectOfferInTalentBehalf = (id) =>
  new Promise((resolve, reject) =>
    axios
      .post(`/api/offer_report/${id}/reject_offer_in_behalf_of_freelancer/`)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error.response.data)),
  );

export const markWithdrawalAsCompleted = (withdrawalTransactionId) =>
  new Promise((resolve, reject) => {
    return axios
      .post(
        `/api/token_transactions/${withdrawalTransactionId}/mark_as_completed/`,
      )
      .then(resolve)
      .catch((error) => {
        reject(error.response.data);
      });
  });
