import {
  Button,
  IconButton,
  Typography,
} from "@hexocean/braintrust-ui-components";
import { CloseIcon } from "@hexocean/braintrust-ui-components/Icons";

type CompleteProfileTopBarViewProps = {
  showTopBar: boolean;
  onCloseClick: () => void;
  onCompleteProfileClick: () => void;
};

export const CompleteProfileTopBarView = ({
  showTopBar,
  onCloseClick,
  onCompleteProfileClick,
}: CompleteProfileTopBarViewProps): JSX.Element | null => {
  if (!showTopBar) {
    return null;
  }

  return (
    <div className="complete-profile-bar">
      <Typography
        component="p"
        size="small"
        className="complete-profile-bar__copy"
      >
        Unlock the ability for clients to view your profile by getting approved.
        Start with completing your profile.{" "}
        <Button
          variant="no-padding"
          size="x-small"
          fontWeight={400}
          sx={{
            textDecoration: "underline !important",
            verticalAlign: "baseline",
          }}
          onClick={onCompleteProfileClick}
        >
          Complete profile
        </Button>
      </Typography>
      <IconButton
        size="small"
        variant="primary"
        aria-label="Dismiss complete profile bar"
        className="complete-profile-bar__dismiss-btn"
        onClick={onCloseClick}
      >
        <CloseIcon
          sx={{
            borderRadius: "50%",
          }}
        />
      </IconButton>
    </div>
  );
};
