import { z } from "zod";

import { API } from "@js/api";
import type { Address } from "@js/types/common";
import type {
  EmployerInvoiceMinimal,
  FreelancerInvoice,
} from "@js/types/invoices";

import type {
  GetAllEmployerInvoicesIdsParams,
  GetEmployerInvoicesMinimalParams,
} from "./types";

const EmployerMinimalSchema = z.object({
  name: z.string(),
  id: z.number(),
});

const FreelancerJobToIssueInvoiceSchema = z.object({
  employer: EmployerMinimalSchema,
  id: z.number(),
  invoice_can_be_created: z.boolean(),
  invoice_cannot_be_created_reason: z.string(),
  title: z.string(),
});

export type IFreelancerJobToIssueInvoice = z.infer<
  typeof FreelancerJobToIssueInvoiceSchema
>;

const InitialInvoiceDataSchema = z.object({
  company_node: z.object({
    name: z.string(),
    slug: z.string(),
  }),
  new_invoice_number: z.string(),
  po_number_required_since: z.union([z.string(), z.null()]),
  previous_po_numbers: z.array(z.string()),
});

export type IInitialInvoiceData = z.infer<typeof InitialInvoiceDataSchema>;

type InitialInvoiceDataRequestPayload = { job: number };

export const invoiceApi = API.injectEndpoints({
  endpoints: (build) => ({
    getFreelancerInvoice: build.query<FreelancerInvoice, number>({
      query: (id) => ({
        url: `/freelancer_invoices/${id}/`,
        method: "GET",
      }),
    }),
    getFreelancerJobsToIssueInvoice: build.query<
      IFreelancerJobToIssueInvoice[],
      void
    >({
      query: () => ({
        url: "/freelancer_jobs_select_project/",
        method: "GET",
      }),
    }),
    getInitialInvoiceData: build.query<
      IInitialInvoiceData,
      InitialInvoiceDataRequestPayload
    >({
      query: ({ job }) => ({
        url: `/freelancer_invoices/initial_invoice_data/`,
        params: { job },
        method: "GET",
      }),
    }),
    getEmployerAddress: build.query<Address, number | "all">({
      query: (id) => ({
        url: `/freelancer_employers/${id === "all" ? "" : `${id}/`}`,
        method: "GET",
      }),
      transformResponse(response: { address: Address }) {
        return response.address;
      },
    }),
  }),
});

export const {
  useGetFreelancerInvoiceQuery,
  useGetFreelancerJobsToIssueInvoiceQuery,
  useLazyGetInitialInvoiceDataQuery,
  useGetInitialInvoiceDataQuery,
  useGetEmployerAddressQuery,
  useLazyGetEmployerAddressQuery,
} = invoiceApi;

export const employerInvoicesApi = API.injectEndpoints({
  endpoints: (build) => ({
    getAllEmployerInvoicesIds: build.query<
      number[],
      GetAllEmployerInvoicesIdsParams | void
    >({
      query: (params) => ({
        url: `/employer_invoices/all_invoices_ids/`,
        method: "GET",
        params,
      }),
      providesTags: ["EmployerInvoicesIds"],
    }),

    getEmployerInvoicesMinial: build.query<
      EmployerInvoiceMinimal[],
      GetEmployerInvoicesMinimalParams
    >({
      query: (params) => ({
        url: `/employer_invoices_minimal/`,
        method: "GET",
        params: {
          ids: params.ids.join(","),
        },
      }),
      providesTags: ["EmployerInvoicesMinimal"],
    }),
  }),
});

export const {
  useLazyGetAllEmployerInvoicesIdsQuery,
  useGetEmployerInvoicesMinialQuery,
} = employerInvoicesApi;
