import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import classNames from "classnames";
import ResizeObserver from "resize-observer-polyfill";

import type { BoxProps, GridSpacing } from "@hexocean/braintrust-ui-components";
import {
  Box,
  Grid,
  IconButton,
  SwipeableDrawer,
} from "@hexocean/braintrust-ui-components";
import { useMediaQuery } from "@hexocean/braintrust-ui-components/hooks";
import { MenuIcon } from "@hexocean/braintrust-ui-components/Icons";
import { CSSVariable } from "@hexocean/braintrust-ui-components/utils";
import { UserSidebar } from "@js/apps/common/components/user-sidebar";
import { freddyWidgetManager } from "@js/apps/common/services/freddy-feedback";
import { BoostBanner } from "@js/apps/dashboard/components/boost-banner";
import { CompleteProfileTopBar } from "@js/apps/dashboard/components/complete-profile-bar";
import { PaymentMethodFailedTopBar } from "@js/apps/employer/components/payment-method-failed-top-bar";
import { NavigationProfileCompletionNudgeCard } from "@js/apps/freelancer/components";
import { ApplicationBoostFeatureFlagWrapper } from "@js/apps/jobs/components/application-boost-feature-flag-wrapper/index";
import { UniversalSearchForm } from "@js/apps/universal-search";
import { Logo } from "@js/components/logo";
import { useAppSelector } from "@js/hooks";
import { LocalStorage } from "@js/services";
import type { User } from "@js/types/auth";

import { CoreLayout } from "../core";

import { useAlertsPortal } from "./hooks/use-alerts-portal";
import Footer from "./footer";
import { Navbar } from "./navbar";

const HEADER_HEIGHT = 72;
const COMMON_Z_INDEX = 1000;
const SIDE_MENU_MIN_WIDTH = 240;

export type OverwriteContentComponentProps = {
  alertsElement: JSX.Element;
  headerBoxProps: {
    top: number;
    zIndex: number;
  };
  contentBoxProps: {
    className: string;
    overflow: string;
  };
};

type AppLayoutProps = {
  children: React.ReactNode;
  className?: string;
  coreClassName?: string;
  /**
   *  By default, all immediate children are aligned in row (inline), you can change this
   *  behavior by setting this to true (then all immediate children will be distributed
   *  in new lines).
   */
  flexColumn?: boolean;
  hideHeader?: boolean;
  hideMenu?: boolean;
  showFooter?: boolean;
  spacing?: GridSpacing;
  pageTitle?: string;
  marginTop?: number;
  showMenuToggleAllTheTime?: boolean;
  bgcolor?: string;
  OverwriteContentComponent?: (
    props: OverwriteContentComponentProps,
  ) => JSX.Element;
  renderOverwriteNavigationComponent?: (
    props: TopNavigationProps,
  ) => JSX.Element;
  disableSubheaderPortal?: boolean;
};

export const AppLayout: React.FC<React.PropsWithChildren<AppLayoutProps>> = ({
  children,
  className,
  coreClassName,
  flexColumn,
  spacing = 0,
  hideHeader,
  hideMenu,
  showFooter,
  showMenuToggleAllTheTime,
  bgcolor,
  OverwriteContentComponent,
  renderOverwriteNavigationComponent,
  disableSubheaderPortal,
  ...rest
}) => {
  const location = useLocation();
  const showLeftNavGlobal = useAppSelector(
    (state) => state.common.showLeftSidebar,
  );
  const layoutBgColor = useAppSelector((state) => state.common.layoutBgColor);

  const user = useAppSelector((state) => state.auth.user);

  const [open, setOpen] = useState(false);
  const [showBoostBanner, setShowBoostBanner] = useState(false);
  const [isCompleteProfileTopBarClose, setIsCompleteProfileTopBarClose] =
    useState(false);

  const isFreelancer = user?.account_type === ENUMS.AccountType.FREELANCER;
  const isFreelancerHomePage = location.pathname
    ? location.pathname.startsWith("/talent/dashboard/welcome")
    : false;

  useEffect(() => {
    const determineBoostBannerVisibility = () => {
      if (!isFreelancer || !isFreelancerHomePage) {
        setShowBoostBanner(false);
        return;
      }

      const dismissTime = LocalStorage.getItem(
        LocalStorage.keys.HOME_BOOST_BANNER_DISMISS_TIME,
      );

      if (!dismissTime) {
        setShowBoostBanner(true);
        return;
      }

      const twoHoursLater = new Date(
        parseInt(dismissTime, 10) + 2 * 60 * 60 * 1000,
      );

      if (new Date() > twoHoursLater) {
        setShowBoostBanner(true);
      } else {
        setShowBoostBanner(false);
      }
    };

    determineBoostBannerVisibility();
  }, [isFreelancer, isFreelancerHomePage]);

  const handleDismiss = () => {
    LocalStorage.setItem(
      LocalStorage.keys.HOME_BOOST_BANNER_DISMISS_TIME,
      String(new Date().getTime()),
    );
    setShowBoostBanner(false);
  };

  const handleTopbarClose = () => setIsCompleteProfileTopBarClose(true);

  const handleCloseDrawer = () => setOpen(false);

  const isTablet = useMediaQuery("md");

  const leftColumnBgColor = bgcolor || layoutBgColor;
  const sidebar = user ? <UserSidebar /> : null;

  const showLeftNav =
    !!user &&
    !hideMenu &&
    !isTablet &&
    !showMenuToggleAllTheTime &&
    showLeftNavGlobal;

  useEffect(() => {
    if (user?.id) {
      freddyWidgetManager.embedCrossPageFreddyWidget();
    }
  }, [location.pathname, user?.id]);

  return (
    <CoreLayout {...rest} className={classNames("app-layout", coreClassName)}>
      {!user?.freelancer_approved && (
        <CompleteProfileTopBar onTopbarClose={handleTopbarClose} />
      )}
      <ApplicationBoostFeatureFlagWrapper>
        {!user?.boost_credit && showBoostBanner && (
          <BoostBanner onDismiss={handleDismiss} />
        )}
      </ApplicationBoostFeatureFlagWrapper>
      <PaymentMethodFailedTopBar />
      {sidebar && (
        <NavDrawer
          leftColumnBgColor={leftColumnBgColor}
          sidebar={sidebar}
          setClose={handleCloseDrawer}
          open={open}
        />
      )}
      <Box display="flex" flexGrow={1} bgcolor={bgcolor || layoutBgColor}>
        {showLeftNav && sidebar && (
          <LeftColumn
            bgcolor={leftColumnBgColor}
            sidebar={sidebar}
            isCompleteProfileTopBarClose={isCompleteProfileTopBarClose}
          />
        )}
        <Box display="flex" minWidth={0} flexDirection="column" flexGrow={1}>
          {!hideHeader &&
            (renderOverwriteNavigationComponent ? (
              renderOverwriteNavigationComponent({
                isTablet,
                hideMenu,
                showMenuToggleAllTheTime,
                user,
                setOpen,
                disableSubheaderPortal,
              })
            ) : (
              <TopNavigationComponent
                bgcolor={bgcolor}
                isTablet={isTablet}
                hideMenu={hideMenu}
                showMenuToggleAllTheTime={showMenuToggleAllTheTime}
                user={user}
                setOpen={setOpen}
                disableSubheaderPortal={disableSubheaderPortal}
              />
            ))}
          {OverwriteContentComponent ? (
            <Box flexGrow={1}>
              <OverwriteContentComponent
                contentBoxProps={{
                  className: classNames("app-layout-content", className),
                  overflow: "hidden",
                }}
                headerBoxProps={{
                  top: HEADER_HEIGHT,
                  zIndex: COMMON_Z_INDEX - 1,
                }}
                alertsElement={<AlertsPortalTarget />}
              />
            </Box>
          ) : (
            <Box flexGrow={1} overflow="hidden">
              <AlertsPortalTarget />
              <Grid
                container
                spacing={spacing}
                className={classNames("app-layout-content", className)}
                style={{
                  flexDirection: flexColumn ? "column" : "row",
                  flexWrap: flexColumn ? "nowrap" : undefined,
                }}
              >
                {children}
              </Grid>
            </Box>
          )}
          {!!showFooter && <Footer />}
        </Box>
      </Box>
    </CoreLayout>
  );
};

const AlertsPortalTarget = React.memo(() => {
  const alertsPortalRef = useRef<HTMLDivElement>();
  const alertsHeight = useAlertsPortal(alertsPortalRef);

  return (
    <Box
      id="alerts-portal"
      ref={alertsPortalRef}
      zIndex={COMMON_Z_INDEX}
      overflow="hidden"
      position="sticky"
      height={alertsHeight}
    />
  );
});

type LeftColumnProps = {
  sidebar: JSX.Element;
  bgcolor?: string;
  isCompleteProfileTopBarClose?: boolean;
};

const useHandleLeftColumnWidth = (elRef) => {
  const [leftColumnWidth, setLeftColumnWidth] = useState(0);

  useEffect(() => {
    const el = elRef.current;
    const observer = new ResizeObserver((entries) => {
      const { width } = entries[0].contentRect;
      setLeftColumnWidth(width);
    });

    if (el) {
      observer.observe(el);
    }

    return () => {
      if (el) {
        observer.disconnect();
      }
    };
  }, [elRef]);

  useEffect(() => {
    CSSVariable.setValue("--left-column-width", `${leftColumnWidth}px`);
  }, [leftColumnWidth]);

  useEffect(() => {
    return () => {
      CSSVariable.setValue("--left-column-width", "0px");
    };
  }, []);

  return leftColumnWidth;
};

const LeftColumn = ({
  sidebar,
  bgcolor,
  isCompleteProfileTopBarClose,
}: LeftColumnProps) => {
  const elRef = useRef<HTMLElement>(null);
  useHandleLeftColumnWidth(elRef);

  const isBgBlack = bgcolor === "var(--black)";
  const PADDING_BLOCK = 32;
  const offsetTop = isCompleteProfileTopBarClose ? 0 : 32;

  return (
    <Box
      ref={elRef}
      zIndex={COMMON_Z_INDEX}
      display="flex"
      minWidth={SIDE_MENU_MIN_WIDTH}
      flexShrink={0}
      flexDirection="column"
      bgcolor={bgcolor}
      boxSizing="border-box"
    >
      <Box
        position="sticky"
        top={0}
        height={HEADER_HEIGHT}
        pl={3.5}
        display="flex"
        alignItems="center"
        flexShrink={0}
      >
        <Logo variant={isBgBlack ? "full-white" : "full-black"} />
      </Box>
      <Box
        className="sidebar"
        position="sticky"
        top={HEADER_HEIGHT}
        height={`calc(100vh - ${HEADER_HEIGHT}px - ${PADDING_BLOCK}px - ${offsetTop}px)`}
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
      >
        {sidebar}
        <NavigationProfileCompletionNudgeCard />
      </Box>
    </Box>
  );
};

export type TopNavigationProps = BoxProps & {
  bgcolor?: string;
  isTablet: boolean;
  hideMenu?: boolean;
  showMenuToggleAllTheTime?: boolean;
  user: User | null;
  setOpen: (val: boolean) => void;
  disableSubheaderPortal?: boolean;
  logoSize?: number;
  children?: React.ReactNode;
  navLayoutClassName?: string;
};

export const TopNavigationComponent = ({
  bgcolor,
  isTablet,
  hideMenu,
  showMenuToggleAllTheTime,
  user,
  setOpen,
  disableSubheaderPortal,
  logoSize,
  children,
  navLayoutClassName,
  ...props
}: TopNavigationProps) => {
  return (
    <NavLayoutContainer className={navLayoutClassName} bgcolor={bgcolor}>
      <Box
        boxSizing="border-box"
        px={{ xs: 2, md: 6 }}
        width={1}
        height={HEADER_HEIGHT}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        {...props}
      >
        <Box display="flex" flexGrow={1} alignItems="center">
          {((isTablet && !hideMenu) || showMenuToggleAllTheTime) && user && (
            <Box>
              <IconButton
                aria-label="open navigation menu"
                onClick={() => setOpen(true)}
                variant="transparent"
              >
                <MenuIcon />
              </IconButton>
            </Box>
          )}
          {SETTINGS.TOP_SEARCH_BAR_VISIBLE && !user ? (
            <Logo href="https://usebraintrust.com" size={logoSize} />
          ) : (
            <UniversalSearchForm />
          )}
        </Box>
        {children ? children : <Navbar />}
      </Box>
      {!disableSubheaderPortal && <div id="subheader-portal" />}
    </NavLayoutContainer>
  );
};

type NavDrawerProps = LeftColumnProps & {
  setClose: () => void;
  leftColumnBgColor?: string;
  open: boolean;
};

const NavDrawer = ({ setClose, open, leftColumnBgColor }: NavDrawerProps) => {
  const onOpen = () => null;

  return (
    <SwipeableDrawer
      PaperProps={{
        sx: {
          background: leftColumnBgColor,
          maxWidth: 269,
          boxSizing: "border-box",
          width: "100%",
        },
      }}
      open={open}
      onOpen={onOpen}
      onClose={setClose}
      onClick={setClose}
      disableSwipeToOpen
      disableDiscovery
      ModalProps={{ keepMounted: false }}
    >
      <Box display="flex" mt={3.5} ml={3.25}>
        <Logo />
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        height="100vh"
        p={2}
        pr="45px"
      >
        <UserSidebar />
        <NavigationProfileCompletionNudgeCard />
      </Box>
    </SwipeableDrawer>
  );
};

export const NavLayoutContainer = ({
  children,
  bgcolor = "var(--white)",
  ...props
}) => {
  return (
    <Box
      zIndex={COMMON_Z_INDEX + 1}
      position="sticky"
      top={0}
      boxSizing="border-box"
      p={{
        xs: "0 16px 0 5px",
        md: "0 40px",
      }}
      minWidth={320}
      display="flex"
      flexDirection="column"
      bgcolor={bgcolor}
      {...props}
    >
      {children}
    </Box>
  );
};
