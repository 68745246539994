import React from "react";

import { useEmailConfirmationModal } from "@js/apps/dashboard/hooks/use-email-confirmation-modal";

import { GGHIntroductionModalProvider } from "../give-and-get-help/components/ggh-introduction-modal";
import TaxFormRequiredWarning from "../withdrawal/components/tax-form-required-warning";
import WithdrawalMethodWarning from "../withdrawal/components/withdrawal-method-warning";

import { useProfileCompletionSuccess } from "./hooks/use-profile-completion-success";

export const FreelancerGlobalProvider = () => {
  useEmailConfirmationModal();
  useProfileCompletionSuccess();

  return (
    <>
      <WithdrawalMethodWarning />
      <TaxFormRequiredWarning />
      <GGHIntroductionModalProvider />
    </>
  );
};
