import React from "react";

import { Loader } from "@hexocean/braintrust-ui-components";
import { useAppSelector } from "@js/hooks";
import type { FreelancerOffer, OpsInvitation } from "@js/types/jobs";

import { OfferItem } from "./offer-item";

type OfferListProps = {
  offers: FreelancerOffer[] | OpsInvitation[];
  emptyStateContent: JSX.Element;
};

export const OfferList = ({ offers, emptyStateContent }: OfferListProps) => {
  const loading = useAppSelector(
    (state) => state.freelancer.fetchingFreelancerOffers,
  );

  if (loading) {
    return <Loader />;
  }

  if (!offers?.length) {
    return emptyStateContent;
  }

  return (
    <div>
      {offers.map((offer) => (
        <OfferItem key={offer.id} offer={offer} />
      ))}
    </div>
  );
};
