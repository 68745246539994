import React from "react";
import QRCode from "qrcode";

const QRCodeToCanvas = ({ value }) => {
  const canvasRef = React.useRef(null);

  React.useEffect(() => {
    QRCode.toCanvas(canvasRef.current, value);
  }, [value]);

  return <canvas ref={canvasRef} />;
};

export default QRCodeToCanvas;
