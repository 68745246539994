import {
  BriefcaseIcon,
  EarnIcon,
  JobsIcon,
  WelcomeIcon,
} from "@hexocean/braintrust-ui-components/Icons";
import type { DashboardNavItem } from "@js/apps/dashboard/nav-items";

type GetEmployerNavItemsProps = {
  canViewInvoices: boolean;
};

export const getEmployerNavItems = ({
  canViewInvoices,
}: GetEmployerNavItemsProps): DashboardNavItem[] =>
  [
    {
      label: "Home",
      path: "/employer/dashboard/welcome/",
      icon: WelcomeIcon,
    },
    {
      path: "/employer/dashboard/my_jobs/",
      label: "My Jobs",
      icon: JobsIcon,
    },
    canViewInvoices && {
      path: "/employer/invoices/",
      label: "Invoices",
      icon: EarnIcon,
    },
    {
      path: "/talent/",
      label: "Talent",
      icon: BriefcaseIcon,
    },
  ].filter(Boolean) as [];
