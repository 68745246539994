import { Box, Divider } from "@hexocean/braintrust-ui-components/components";
import type { ProfileCardSectionProps } from "@js/apps/give-and-get-help/components/profile-card/profile-card.types";

import { ProfileCardSectionPlaceholder } from "./profile-card-section-placeholder";

export const ProfileCardSection = ({
  isEmpty,
  divider = false,
  placeholder,
  title,
  content,
  gap,
}: ProfileCardSectionProps) => {
  return (
    <>
      <Box display="flex" gap={gap} flexDirection="column">
        {title}
        {isEmpty && placeholder && (
          <ProfileCardSectionPlaceholder text={placeholder} />
        )}
        {!isEmpty && content}
      </Box>
      {divider && (
        <Divider color="light-grey" className="profile-card__divider" />
      )}
    </>
  );
};
