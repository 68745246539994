import { CLOSE_MODAL, OPEN_MODAL } from "./action-types";

const INITIAL_STATE: Record<string, any> = { data: null };

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case OPEN_MODAL:
      return { ...state, [action.payload]: true };
    case CLOSE_MODAL:
      return { ...state, [action.payload]: false };
    default:
      return state;
  }
}
