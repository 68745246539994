import React, { useCallback, useEffect, useMemo } from "react";
import { useSearchParams } from "react-router-dom";

import { Box, Button, Typography } from "@hexocean/braintrust-ui-components";
import { JobMessenger } from "@js/apps/messenger/messengers/jobs";
import type { EmployerBidListBid, Job } from "@js/types/jobs";

import { JobBidResumeViewer } from "../../bid-resume-viewer";

import { BidDetailsApplicationTab } from "./bid-details-application-tab";

const AVAILABLE_TABS = [
  {
    label: "Application",
    param: "application",
    component: BidDetailsApplicationTab,
  },
  {
    label: "Resume",
    param: "resume",
    component: JobBidResumeViewer,
  },
  {
    label: "Messages",
    param: "messages",
    component: JobMessenger,
  },
];

export const BidTabs = ({
  bid,
  job,
}: {
  bid: EmployerBidListBid;
  job: Job;
}) => {
  const [params, setParams] = useSearchParams();
  const tab = params.get("tab");

  const updateTabParam = useCallback(
    (tabParam: string) => {
      const current = params.get("tab");
      if (current === tabParam) {
        return;
      }
      params.delete("tab");
      const prevParams = params.toString();

      const newParams = !!prevParams.length
        ? `?${prevParams}&tab=${tabParam}`
        : `?tab=${tabParam}`;

      setParams(newParams, { replace: true, state: { noScroll: true } });
    },
    [params, setParams],
  );

  useEffect(() => {
    if (!tab) {
      updateTabParam(AVAILABLE_TABS[0].param);
    }
  }, [setParams, tab, updateTabParam]);

  const currentTab = useMemo(
    () => AVAILABLE_TABS.find((i) => i.param === tab),
    [tab],
  );

  return (
    <Box>
      <Box display="flex" columnGap={4} flexWrap="wrap" rowGap={2}>
        {AVAILABLE_TABS.map((option) => {
          if (
            option.param === "messages" &&
            !bid?.freelancer.user.can_be_messaged
          )
            return null;

          const isActive = option.param === tab;

          return (
            <Button
              variant="transparent"
              key={option.param}
              size="small"
              onClick={() => updateTabParam(option.param)}
              style={{
                padding: 0,
              }}
            >
              <Typography
                sx={{
                  color: isActive ? "var(--black)" : "var(--grey-3)",
                  boxShadow: isActive ? "0 2px 0 0 #000000" : undefined,
                }}
                component="h4"
                fontWeight={400}
                variant="title"
                size="small"
              >
                {option.label}
              </Typography>
            </Button>
          );
        })}
      </Box>
      <Box mt={3}>
        {bid && currentTab?.component ? (
          <currentTab.component bid={bid} job={job} />
        ) : (
          currentTab?.label
        )}
      </Box>
    </Box>
  );
};
