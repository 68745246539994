import { useCallback, useEffect, useMemo, useRef } from "react";
import type { ConfigProps } from "redux-form";

import { useUser } from "@js/apps/common/hooks";
import {
  addEmployerTeamMember,
  fetchEmployerProfile,
  fetchEmployerTeamMemberInvitations,
} from "@js/apps/employer/actions";
import { getValuesWithReCaptchaCode } from "@js/forms/utils";
import { useAppDispatch, useAppSelector } from "@js/hooks";
import type { User } from "@js/types/auth";
import type { EmployerTeamMember } from "@js/types/employer";
import type { InvitedTeamMember } from "@js/types/employer";

import { useGetEmployerTeamMembersQuery } from "../../api";
import type { ActionType } from "../../components/team-members/actions";
import {
  getInvitedMembersActions,
  getTeamMembersActions,
} from "../../components/team-members/actions";
import type { InviteMemberFormData } from "../../forms/invite-team-member";

type MemberActionsType<T> = (member: T) => ActionType[];

export type EmployerTeamMembersType = {
  loading: boolean;
  onSubmit: ConfigProps<InviteMemberFormData>["onSubmit"];
  teamMembers: EmployerTeamMember[];
  getMemberActions: MemberActionsType<User>;
  getInvitedMemberActions: MemberActionsType<InvitedTeamMember>;
};

export const useEmployerTeamMembers = (): EmployerTeamMembersType => {
  const recaptchaValues = useRef(null);

  const user = useUser() as User;
  const { data: teamMembers, isLoading } = useGetEmployerTeamMembersQuery();
  const loading = useAppSelector(
    (state) =>
      state.employer.fetchingEmployerProfile ||
      state.employer.fetchingEmployerTeamMembers,
  );

  const teamMembersInvitations = useAppSelector(
    (state) => state.employer.employerTeamMembers,
  );

  const teamMembersWithInvitations = useMemo(() => {
    if (!teamMembers) {
      return [];
    }

    return [...teamMembers, ...teamMembersInvitations];
  }, [teamMembersInvitations, teamMembers]);

  const canCurrentUserViewInvoices = user.can_view_employer_invoices;

  const dispatch = useAppDispatch();

  const getMemberActions = (member: User) =>
    getTeamMembersActions({
      member,
      user,
      fetchData,
      canCurrentUserViewInvoices,
      dispatch,
    });

  const getInvitedMemberActions = (member: InvitedTeamMember) =>
    getInvitedMembersActions({
      member,
      fetchData,
    });

  const fetchData = useCallback(
    () =>
      Promise.all([
        dispatch(fetchEmployerTeamMemberInvitations()),
        dispatch(fetchEmployerProfile()),
      ]),
    [dispatch],
  );

  const onSubmit = (values, _dispatch, props) => {
    return addEmployerTeamMember(values)
      .then(() => props.reset())
      .then(fetchData)
      .catch(async (exception) => {
        if (
          !recaptchaValues.current &&
          exception?.errors?.captcha === ENUMS.CaptchaErrorValue.REQUIRED
        ) {
          recaptchaValues.current = await getValuesWithReCaptchaCode(
            values,
            ENUMS.CaptchaActions.INVITE_EMPLOYER,
          );
          return onSubmit(recaptchaValues.current, _dispatch, props);
        }

        // Re-throw so validation errors can be shown.
        throw exception;
      });
  };

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return {
    loading: loading || isLoading,
    onSubmit,
    teamMembers: teamMembersWithInvitations,
    getMemberActions,
    getInvitedMemberActions,
  };
};
