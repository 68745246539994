import { useMemo } from "react";

import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@hexocean/braintrust-ui-components";
import { ErrorIcon } from "@hexocean/braintrust-ui-components/Icons";
import { getInvoicesTotal } from "@js/apps/invoices/logic";
import type { EmployerInvoiceMinimal } from "@js/types/invoices";
import { formatCurrency } from "@js/utils";

import styles from "./style.module.scss";

type PaymentConfirmationContentProps = {
  error?: Record<string, string[]>;
  selectedInvoices: EmployerInvoiceMinimal[];
};

export const PaymentConfirmationContent = ({
  error,
  selectedInvoices,
}: PaymentConfirmationContentProps) => {
  return (
    <div>
      <Typography
        component="h1"
        variant="title"
        fontWeight={400}
        size="small"
        sx={{ mb: 2 }}
      >
        Pay Invoices
      </Typography>
      <TableContainer
        sx={{
          maxHeight: { md: "calc(90vh - 450px)" },
        }}
      >
        <Table stickyHeader className={styles.table}>
          <TableHead>
            <TableRow>
              <TableCell>Invoice Number</TableCell>
              <TableCell align="right">Amount</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {selectedInvoices.map((invoice) => (
              <ConfirmationInvoiceItem
                key={invoice.id}
                error={error}
                invoice={invoice}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Box sx={{ textAlign: "end", p: "12px 15px" }}>
        <Typography
          component="p"
          size="small"
          fontWeight={500}
          textTransform="uppercase"
        >
          Total
        </Typography>
        {formatCurrency(getInvoicesTotal(selectedInvoices))}
      </Box>
    </div>
  );
};

type ConfirmationInvoiceItemProps = {
  error?: Record<string, string[]>;
  invoice: EmployerInvoiceMinimal;
};

const ConfirmationInvoiceItem = ({
  error,
  invoice,
}: ConfirmationInvoiceItemProps) => {
  const isError = useMemo(() => {
    return (
      !!error &&
      !!error.invoices &&
      error.invoices.map((id) => parseInt(id, 10)).includes(invoice.id)
    );
  }, [error, invoice.id]);

  return (
    <TableRow>
      <TableCell>
        <div>
          {isError ? <ErrorIcon className={styles.errorIcon} /> : null}
          {invoice.payee_name}
        </div>
        <Typography component="p" size="small" color="grey-2">
          {invoice.number}
        </Typography>
      </TableCell>
      <TableCell align="right">{formatCurrency(invoice.gross_total)}</TableCell>
    </TableRow>
  );
};
