import React from "react";
import type { ConnectedProps } from "react-redux";
import { connect } from "react-redux";

import { Button, Typography } from "@hexocean/braintrust-ui-components";
import { ErrorIcon } from "@hexocean/braintrust-ui-components/Icons";
import { RouterLink } from "@js/components/link";
import { Modal } from "@js/components/modal";
import type { RootState } from "@js/store";

class TaxFormRequiredWarning extends React.Component<
  ConnectedProps<typeof connector>
> {
  static Instance = Modal("tax-form-required-warning", {
    className: "general-warning",
    closeButton: false,
  });

  render() {
    return (
      <TaxFormRequiredWarning.Instance>
        <Typography
          component="h1"
          variant="title"
          fontWeight={400}
          size="small"
          className="mb+"
        >
          <ErrorIcon className="general-warning-icon" />
          {this.props.isW9FormRequired ? "W-9" : "W-8"} Form Required.
        </Typography>
        <Typography component="p">
          {this.props.isW9FormRequired
            ? "Before withdrawing funds, all U.S. residents must provide their W-9 tax information."
            : "Before withdrawing funds, all residents outside of U.S. must provide their W-8 tax information."}
        </Typography>
        <div className="buttons right mt++">
          <Button
            variant="transparent"
            color="secondary"
            onClick={TaxFormRequiredWarning.Instance.close}
          >
            Cancel
          </Button>
          <Button
            variant="transparent"
            color="secondary"
            onClick={TaxFormRequiredWarning.Instance.close}
            href="/settings/payments/"
            RouterLink={RouterLink}
          >
            Setup Now
          </Button>
        </div>
      </TaxFormRequiredWarning.Instance>
    );
  }
}

const connector = connect((state: RootState) => ({
  isW8FormRequired: state.auth.user && state.auth.user.is_w8_form_required,
  isW9FormRequired: state.auth.user && state.auth.user.is_w9_form_required,
}));

export default connector(TaxFormRequiredWarning);
