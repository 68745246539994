import React from "react";

import { OfferListEmptyState } from "@js/apps/freelancer/views/dashboard/offers/offer-list/offer-list-empty-state";
import { useFreelancerOffers } from "@js/apps/jobs/hooks/freelancer-offers";

import { OfferList } from "./offer-list";

export const FreelancerDashboardCurrentOffersPage = () => {
  const { offers } = useFreelancerOffers(
    ENUMS.TalentOffersSectionFilter.CURRENT,
  );
  return (
    <OfferList
      offers={offers}
      emptyStateContent={
        <OfferListEmptyState description="Offers made by employers will appear here." />
      }
    />
  );
};

export const FreelancerDashboardPastOffersPage = () => {
  const { offers } = useFreelancerOffers(ENUMS.TalentOffersSectionFilter.PAST);

  return (
    <OfferList
      offers={offers}
      emptyStateContent={
        <OfferListEmptyState description="You have no past offers." />
      }
    />
  );
};
