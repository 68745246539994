import { useMemo, useState } from "react";
import type { FieldValues, UseControllerProps } from "react-hook-form";
import { useController } from "react-hook-form";

import type { ComboBoxProps } from "@hexocean/braintrust-ui-components/";
import { ComboBox } from "@hexocean/braintrust-ui-components/";
import { TextField } from "@hexocean/braintrust-ui-components/components/Input/";
import { useGetReferralLinksQuery } from "@js/apps/dashboard/api";
import type { ReferralLink } from "@js/apps/dashboard/types";
import { useSearchPhrase } from "@js/components/autocomplete-new/hooks/search-phrase";
import { useSynchronizeSingleValueInternalStateWithFormValue } from "@js/components/autocomplete-new/hooks/synchronize-with-form-value";

type RhfReferralLinksSearchComboboxFieldProps<T extends FieldValues> =
  UseControllerProps<T> &
    Partial<Omit<ComboBoxProps<ReferralLink, false>, "component">> & {
      label?: string;
      placeholder?: string;
    };

export const RhfReferralLinksSearchComboboxField = <T extends FieldValues>({
  label,
  placeholder,
  ...props
}: RhfReferralLinksSearchComboboxFieldProps<T>) => {
  const { field, fieldState } = useController(props);
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState<ReferralLink | null>(null);
  const { onInputChange, searchPhrase } = useSearchPhrase();

  const { data: fetchedOptions, isLoading } = useGetReferralLinksQuery(
    { search: searchPhrase, limit: 100 },
    { skip: !open || !!(value && searchPhrase === getLabel(value)) },
  );

  const options = useMemo(() => {
    const processedOptions = fetchedOptions || [];
    if (!value || processedOptions.some((option) => option.id === value.id)) {
      return processedOptions;
    }

    return [value, ...processedOptions];
  }, [fetchedOptions, value]);

  useSynchronizeSingleValueInternalStateWithFormValue({
    initialTaxonomyLoading: isLoading,
    formValue: field.value,
    isValueEqualFormValue: field.value === value?.id,
    findOption: (option) => {
      return option.id === field.value;
    },
    setValue,
    options,
  });

  return (
    <ComboBox
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      initialTaxonomiesLoading={false}
      options={options}
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            label={label || "Referral link"}
            placeholder={placeholder}
            error={fieldState.invalid}
            helperText={fieldState.error?.message}
            data-testid="referral-link-combobox"
          />
        );
      }}
      onInputChange={onInputChange}
      displayAllOptions
      value={value}
      onChange={(_ev, valueArg) => {
        setValue(valueArg);
        field.onChange(valueArg?.id || undefined);
      }}
      isOptionEqualToValue={(option, val) => option.id === val?.id}
      getOptionLabel={(option) => getLabel(option)}
      onBlur={field.onBlur}
      disabled={field.disabled}
      {...props}
    />
  );
};

const getLabel = (value: ReferralLink) =>
  `${value.user.public_name} (${value.user.email}): ${value.link}`;
