import React, { Fragment } from "react";

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Divider,
  Typography,
} from "@hexocean/braintrust-ui-components";
import { ExpandMoreIcon } from "@hexocean/braintrust-ui-components/Icons";
import { clickReferralFaq } from "@js/apps/dashboard/components/faq/actions";
import { Sanitize } from "@js/components/sanitize";
import { useAppDispatch } from "@js/hooks";

import type { FaqEntry } from "../../types";

import styles from "./faq-accordion.module.scss";

export type FaqAccordionProps = {
  items: FaqEntry[];
};

export const FaqAccordion = ({ items }: FaqAccordionProps) => {
  const dispatch = useAppDispatch();
  return (
    <Box>
      {items.map(({ id, header, content }) => {
        return (
          <Fragment key={id}>
            <Accordion
              className={styles.accordion}
              onChange={(_ev, expanded) => {
                if (expanded) {
                  dispatch(clickReferralFaq({ faq_header: header }));
                }
              }}
            >
              <AccordionSummary
                sx={{ px: 0 }}
                className={styles.summary}
                expandIcon={<ExpandMoreIcon />}
              >
                <Typography
                  variant="paragraph"
                  component="span"
                  size="large"
                  className={styles.text}
                >
                  <Sanitize input={header} className={styles.sanitizedText} />
                </Typography>
              </AccordionSummary>
              <AccordionDetails sx={{ p: 0, lineHeight: 0 }}>
                <Typography
                  variant="paragraph"
                  component="span"
                  size="medium"
                  className={styles.text}
                >
                  <Sanitize input={content} className={styles.sanitizedText} />
                </Typography>
              </AccordionDetails>
            </Accordion>
            <Divider color="primary" />
          </Fragment>
        );
      })}
    </Box>
  );
};
