import * as actionTypes from "./action-types";

type RequestLoaderReduxState = {
  loading: boolean;
};

const INITIAL_STATE: RequestLoaderReduxState = {
  loading: false,
};

export default function (
  state = INITIAL_STATE,
  action,
): RequestLoaderReduxState {
  switch (action.type) {
    case actionTypes.START_REQUEST_LOADER:
      return { ...state, loading: true };

    case actionTypes.STOP_REQUEST_LOADER:
      return { ...state, loading: false };

    default:
      return state;
  }
}
