import type { JSXElementConstructor } from "react";
import { useRef } from "react";
import { useEffect, useState } from "react";

import type { PopperProps } from "@hexocean/braintrust-ui-components";
import { AutocompleteBase } from "@hexocean/braintrust-ui-components";
import { useMediaQuery } from "@hexocean/braintrust-ui-components/hooks";
import type { Hashtag } from "@js/apps/give-and-get-help/types";

import { useMenuFocusControl } from "../../hooks/use-menu-focus-control";
import { useReplaceEditorCommands } from "../../hooks/use-replace-editor-commands";
import { getHashtagRawName } from "../../utils/get-hashtag-raw-name";

import { HashtagAutocompleteInput } from "./hashtag-autocomplete-input";
import { HashtagListItem } from "./hashtag-list-item";
import type { HashtagPopperProps } from "./hashtag-popper";
import { HashtagPopper } from "./hashtag-popper";

type HashtagMenuProps = {
  currentHashtagText: string;
  anchorElement: HTMLElement;
  onSelect: (selectedOption: Hashtag) => void;
  hashtags: Hashtag[];
  isHashtagActive: boolean;
  isLoading: boolean;
};

export const HashtagMenu = ({
  currentHashtagText,
  anchorElement,
  onSelect,
  hashtags,
  isHashtagActive,
  isLoading,
}: HashtagMenuProps) => {
  const [isOpen, setIsOpen] = useState(true);
  const isMobile = useMediaQuery("sm");

  const handleClickAway = () => {
    if (isOpen && !currentHashtagText) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    setIsOpen(isHashtagActive && !!currentHashtagText);
  }, [isHashtagActive, currentHashtagText]);

  const handleSelect = (selectedOption: Hashtag) => {
    onSelect?.(selectedOption);
  };

  const filterOptions = (
    options: Hashtag[],
    state?: { inputValue: string },
  ) => {
    const inputValue = state?.inputValue || currentHashtagText;
    return options.filter((option) => {
      return (
        !getHashtagRawName(inputValue).length ||
        option.name.toLowerCase().includes(inputValue.toLowerCase())
      );
    });
  };

  const hashtagMenuRef = useRef<HTMLDivElement>(null);

  const { isFocused, setFocusedItem } = useMenuFocusControl({
    enabled: isOpen,
    list: filterOptions(hashtags),
    onSelect: handleSelect,
    hashtagMenuRef: hashtagMenuRef,
  });

  useReplaceEditorCommands({
    active: isOpen,
    onEscape: () => setIsOpen(false),
  });

  const isAnchorPresentInHTMLDocument = document.contains(anchorElement);

  if (!isAnchorPresentInHTMLDocument) {
    return null;
  }

  const isHashtagAlreadySelected =
    anchorElement.classList.contains("hashtag-node");

  return (
    <AutocompleteBase<Hashtag, false, true, false>
      id="hashtag-menu"
      filterOptions={filterOptions}
      options={hashtags}
      noOptionsText={
        getHashtagRawName(currentHashtagText).length === 0
          ? "Start typing to search..."
          : "No results found"
      }
      getOptionLabel={(option) => (option as Hashtag).name}
      open={!!currentHashtagText && !isHashtagAlreadySelected && isOpen}
      blurOnSelect
      autoSelect
      loadingText="Loading..."
      loading={isLoading}
      componentsProps={{
        paper: {
          ref: hashtagMenuRef,
          sx: isMobile
            ? {
                width: "calc(100vw - 32px)",
              }
            : {},
        },
        popper: {
          onClickAway: handleClickAway,
          anchorElement: anchorElement,
          isMobile,
        } as HashtagPopperProps,
      }}
      PopperComponent={HashtagPopper as JSXElementConstructor<PopperProps>}
      inputValue={currentHashtagText}
      renderInput={HashtagAutocompleteInput}
      onChange={(_, selectedOption) => handleSelect(selectedOption as Hashtag)}
      renderOption={(optionProps, option, optionState) => {
        const index = optionProps["data-option-index"];

        return (
          <HashtagListItem
            key={option.name + index}
            isFocused={isFocused(index)}
            optionProps={optionProps}
            option={option}
            optionState={optionState}
            onMouseEnter={() => setFocusedItem(index)}
          />
        );
      }}
    />
  );
};
