import type { FileError, FileRejection } from "react-dropzone";
import classnames from "classnames";

import { Box, Typography } from "@hexocean/braintrust-ui-components";
import { formatBytes } from "@js/utils";

import { shortFileName } from "../../helpers/file-upload";

import styles from "./file-rejections.module.scss";

export const FileRejections = ({
  fileRejections,
}: {
  fileRejections: FileRejection[];
}) => {
  return (
    <Box
      component="ul"
      className={classnames(styles.list, "dropzone-file-rejections")}
      aria-label="File rejections"
    >
      {fileRejections.map(({ file, errors }) => (
        <li key={file.name}>
          <Typography component="span" error fontWeight={500} size="small">
            {shortFileName(file.name)}:
          </Typography>
          <ul>
            {errors.map((e) => (
              <li key={e.code}>
                <Typography component="span" error size="small">
                  {getErrorMessage(e)}
                </Typography>
              </li>
            ))}
          </ul>
        </li>
      ))}
    </Box>
  );
};

export const getErrorMessage = (error: FileError) => {
  switch (error.code) {
    case "file-invalid-type": {
      const mimeTypes = error.message.split(",");

      const mimeTypeMap = {
        "image/*": "an image (.jpg, .png, .bmp, .gif etc.)",
        "image/png": ".png",
        "image/jpeg": ".jpg",
        "application/pdf": ".pdf",
        "application/msword": ".doc",
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
          ".docx",
      };

      const errorMessage: string[] = mimeTypes
        .map((mimeType) => {
          const type = mimeType.match(/[\w-]+\/\*|[\w-]+\/[\w-.]+/)?.[0];

          if (type && mimeTypeMap[type]) {
            return mimeTypeMap[type];
          }

          return null;
        })
        .filter(Boolean);

      if (errorMessage.length === 0) {
        return error.message;
      }

      if (errorMessage.length > 1) {
        return `File must be one of: ${errorMessage.join(", ")}`;
      }

      return `File must be ${errorMessage[0]}`;
    }
    case "file-too-large": {
      const maxFileSize = error.message.match(/\d+/)?.[0];
      return maxFileSize
        ? `File can\'t be larger than ${formatBytes(+maxFileSize, 2)}.`
        : "File is too large.";
    }
    case "file-too-small": {
      const minFileSize = error.message.match(/\d+/)?.[0];
      if (!minFileSize) {
        return "File is too small.";
      }

      if (minFileSize === "1") {
        return `File can\'t be empty.`;
      }

      return `File can\'t be smaller than ${formatBytes(+minFileSize, 2)}.`;
    }

    default:
      return error.message;
  }
};
