import type { FC } from "react";
import React, { useMemo } from "react";
import { omit } from "underscore";

import type { AvatarSrc } from "@hexocean/braintrust-ui-components";
import {
  Avatar,
  Box,
  Button,
  Loader,
  Typography,
} from "@hexocean/braintrust-ui-components";
import {
  ArrowRightIcon,
  CheckIcon,
  CloseIcon,
  VerifiedIcon,
} from "@hexocean/braintrust-ui-components/Icons";
import { useUser } from "@js/apps/common/hooks";
import { useGetJobApplicationInsightsQuery } from "@js/apps/jobs/api";

import { openInsightPurchaseModal } from "../../application-insight-purchase-modal";
import {
  formatMatchLabel,
  getMatchLabelColors,
  useMatchPlacement,
} from "../utils";

type InsightTooltipProps = {
  jobId: number;
  matchLabel: EnumType<typeof ENUMS.SmartMatchingLabel> | null;
};

export const InsightTooltip: FC<InsightTooltipProps> = ({
  jobId,
  matchLabel,
}) => {
  const user = useUser();
  const avatarSrc = useMemo(
    () => omit(user, "account_type") as AvatarSrc,
    [user],
  );
  const { isLoading, data } = useGetJobApplicationInsightsQuery({ id: jobId });
  const hasTopSkills = !data?.skills.includes("0") || false;
  const [currentSkills, totalSkills] = data?.skills.split("/") || [0, 0];
  const matchLabelStyleProps = getMatchLabelColors(matchLabel);
  const canViewInsights = useMemo(
    () => user?.can_view_insights,
    [user?.can_view_insights],
  );
  const { label: matchPlacementLabel, summaryType: matchLabelSummaryType } =
    useMatchPlacement(matchLabel, data?.total_applicants || 0);

  return (
    <Box>
      <Box display="flex" mb={1.25} gap={1.25}>
        <Box display="flex" alignItems="center">
          <VerifiedIcon
            sx={{ alignSelf: "end", fontSize: "29px", zIndex: 2 }}
          />
          <Avatar
            src={avatarSrc}
            sx={{ height: 29, width: 29, marginLeft: -1 }}
          />
        </Box>
        <Box
          sx={{
            width: "fit-content",
            backgroundColor: matchLabelStyleProps.backgroundColor,
          }}
          borderRadius={9}
          py={0.75}
          px={1.5}
        >
          <Typography
            component="p"
            variant="label"
            size="small"
            sx={{
              color: matchLabelStyleProps.textColor,
            }}
          >
            You're a{matchLabel === ENUMS.SmartMatchingLabel.OK ? "n" : ""}{" "}
            <span
              style={{
                textTransform:
                  matchLabel === ENUMS.SmartMatchingLabel.OK
                    ? "inherit"
                    : "lowercase",
              }}
            >
              {formatMatchLabel(matchLabel)}
            </span>{" "}
            for this job!
          </Typography>
        </Box>
      </Box>
      {isLoading ? (
        <Loader />
      ) : (
        <Box display="flex" flexDirection="column" gap={1.5}>
          <Box display="flex" gap={1}>
            {hasTopSkills ? (
              <CheckIcon
                sx={{ fontSize: "20px", color: "var(--positive-2)" }}
              />
            ) : (
              <CloseIcon
                sx={{ fontSize: "20px", color: "var(--negative-2)" }}
              />
            )}
            <Typography
              sx={{ color: "var(--black)" }}
              component="p"
              variant="paragraph"
              size="small"
            >
              {currentSkills} of {totalSkills} top skills for this job
            </Typography>
          </Box>
          {canViewInsights && (
            <Box display="flex" gap={1}>
              {matchLabelSummaryType === "positive" ? (
                <CheckIcon
                  sx={{ fontSize: "20px", color: "var(--positive-2)" }}
                />
              ) : (
                <CloseIcon
                  sx={{ fontSize: "20px", color: "var(--negative-2)" }}
                />
              )}
              <Typography
                sx={{ color: "var(--black)" }}
                component="p"
                variant="paragraph"
                size="small"
              >
                {matchPlacementLabel}
              </Typography>
            </Box>
          )}
          {canViewInsights &&
            Array.isArray(data?.match_summary) &&
            data?.match_summary?.map(({ sentence, summary_type }) => (
              <Box key={sentence} display="flex" gap={1}>
                {summary_type === "positive" ? (
                  <CheckIcon
                    sx={{ fontSize: "20px", color: "var(--positive-2)" }}
                  />
                ) : (
                  <CloseIcon
                    sx={{ fontSize: "20px", color: "var(--negative-2)" }}
                  />
                )}
                <Typography
                  sx={{ color: "var(--black)" }}
                  component="p"
                  variant="paragraph"
                  size="small"
                >
                  {sentence}
                </Typography>
              </Box>
            ))}
          {!canViewInsights && (
            <Button
              disabled={!user?.freelancer_approved}
              onClick={openInsightPurchaseModal}
              variant="transparent-with-text-hover"
              size="x-small"
              endIcon={<ArrowRightIcon />}
              sx={{ padding: "0 !important" }}
            >
              Sign up for full Application Insights
            </Button>
          )}
        </Box>
      )}
    </Box>
  );
};
