import type { Search, UIUsedParams } from "@js/apps/common/components/filters";
import {
  createFiltersSearchParamsHook,
  FiltersManager,
} from "@js/apps/filters";

export type InvoiceFiltersFetchParams = {
  job?: number;
  paid?: true;
  unpaid?: true;
  ordering?:
    | "employer_name"
    | "number"
    | "issued_at"
    | "due_date"
    | "_gross_total";
} & Pick<UIUsedParams, "page"> &
  Search;

const defaultInvoiceFiltersFetchParamsValues: InvoiceFiltersFetchParams = {
  page: undefined,
  ordering: undefined,
  job: undefined,
  search: undefined,
  paid: undefined,
  unpaid: undefined,
};

export const useInvoiceFilters =
  createFiltersSearchParamsHook<InvoiceFiltersFetchParams>(
    new FiltersManager({
      defaultParamsValues: defaultInvoiceFiltersFetchParamsValues,
      defaultFormValues: defaultInvoiceFiltersFetchParamsValues,
    }),
  );
