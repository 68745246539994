import { API } from "@js/api";
import type { AccountInfoFormData } from "@js/apps/freelancer/forms/account-info";
import type { PopularLocation } from "@js/types/common";
import type {
  Freelancer,
  FreelancerCertificate,
  FreelancerHoverState,
  FreelancerRole,
  FreelancerSkill,
  FreelancerWorkExperience,
  JobInvitations,
  ProfileCompletion,
  TalentExampleProfile,
  WorkSampleItem,
} from "@js/types/freelancer";
import type { Talent } from "@js/types/freelancer";
import type { PaginatedResult } from "@js/types/generic";
import type { FreelancerBid, Job } from "@js/types/jobs";
import type { UploadFile } from "@js/types/uploads";

import type { TalentFiltersFetchParams } from "../common/components/filters";

import type { AvatarFormData } from "./components/profnet-profile-page-head/avatar/hook";
import type { CoverImageFormData } from "./components/profnet-profile-page-head/cover-image";
import type { GetToKnowMeFormData } from "./forms/get-to-know-me-form";
import {
  CREATE_WORK_SAMPLE_SUCCESS,
  DELETE_WORK_SAMPLE,
  UPDATE_WORK_SAMPLE_SUCCESS,
} from "./action-types";

type FreelancerBidsParams = {
  current?: boolean;
  historical?: boolean;
  accepted?: boolean;
  job?: number;
  ordering?: "-created";
};

type FreelancerBidsPaginatedParams = FreelancerBidsParams & {
  page_size?: number;
};

type FreelancerJobsParams = {
  completed?: boolean;
  show_jobs_of_removed_users?: boolean;
};

type SaveFreelancerRolePayload = {
  role: number;
  primary: boolean;
  years_experience: number;
};

type UpdateFreelancerRolePayload = {
  id: number;
  role: number;
  primary: boolean;
  years_experience: number;
  primary_freelancer_role?: number;
};

export type TaxFormType = "w8" | "w9";

type GetTaxFormResponse = {
  form_url: string | null;
  form_uploaded_at: string | null;
};

type GetTaxFormsParams = {
  freelancerId: number;
  formType: TaxFormType;
};

type DeleteTaxFormsParams = {
  freelancerId: number;
  formType: TaxFormType;
};

type UpdateTaxFormsResponse = {
  form_url: string | null;
  form_uploaded_at: string | null;
};

type UpdateTaxFormsParams = {
  freelancerId: number;
  formType: TaxFormType;
  formFileId: number;
};

type AddPortfolioItemParams = Omit<WorkSampleItem, "id">;
type AddPortfolioItemResponse = WorkSampleItem;

export type UpdateFreelancerCustomizationPayload =
  | GetToKnowMeFormData
  | { user: AvatarFormData }
  | { user: CoverImageFormData }
  | AccountInfoFormData;

type GetFreelancerSkillsResult = Array<Omit<FreelancerSkill, "role">>;

type EditFreelancerSkillsParams = {
  id: number;
  new_skills: Array<{ new_skill: number; is_superpower: boolean }>;
};

export const freelancerApi = API.injectEndpoints({
  endpoints: (build) => ({
    boostFreelancerBid: build.mutation<void, { id?: number }>({
      query: ({ id }) => ({
        url: `/freelancer_bids/${id}/boost`,
        method: "POST",
      }),

      invalidatesTags: ["FreelancerBids", { type: "Jobs", id: "LIST" }],
    }),
    getTalentExampleProfiles: build.query<
      PaginatedResult<{
        id: number;
        freelancer: TalentExampleProfile;
      }>,
      { role?: number } | void
    >({
      query: (params) => ({
        url: "/talent_example_profiles/",
        method: "GET",
        params,
      }),
    }),
    getTalent: build.query<Talent, number>({
      query: (id: number) => ({
        url: `/talent/${id}`,
        method: "GET",
      }),
    }),
    getTalents: build.query<
      PaginatedResult<Talent>,
      Partial<TalentFiltersFetchParams> | void
    >({
      query: (filters) => {
        const role = filters?.role;
        const skills = filters?.skills;

        return {
          url: `/talent/`,
          method: "GET",
          params: {
            ...filters,
            role: role?.join(","),
            skills: skills?.join(","),
          },
        };
      },
    }),
    getEliteTalents: build.query<PaginatedResult<Freelancer>, void>({
      query: () => ({
        url: `/talent/explore_elite/`,
        method: "GET",
      }),
    }),
    updatePortfolioItemsPositions: build.mutation<
      void,
      { itemPositions: WorkSampleItem[] }
    >({
      query: ({ itemPositions }) => ({
        url: "/manage_portfolio_items/update_positions/",
        method: "POST",
        data: {
          item_positions: itemPositions,
        },
      }),
    }),
    updateCertificatesPositions: build.mutation<
      void,
      { itemPositions: FreelancerCertificate[] }
    >({
      query: ({ itemPositions }) => ({
        url: "/freelancer_certificates/update_positions/",
        method: "POST",
        data: {
          item_positions: itemPositions,
        },
      }),
    }),
    getFreelancerRoles: build.query<FreelancerRole[], void>({
      query: () => ({
        url: "/freelancer_roles/",
        method: "GET",
      }),
      providesTags: ["FreelancerRole"],
    }),

    saveFreelancerRole: build.mutation<
      Pick<FreelancerRole, "id">,
      SaveFreelancerRolePayload
    >({
      query: (role) => ({
        url: "/freelancer_roles/",
        method: "POST",
        data: role,
      }),
      invalidatesTags: [
        "FreelancerHoverStateData",
        "FreelancerRole",
        "FreelancerProfileCompletion",
      ],
    }),

    updateFreelancerRole: build.mutation<
      Pick<FreelancerRole, "id">,
      UpdateFreelancerRolePayload
    >({
      query: ({ id, ...data }) => ({
        url: `/freelancer_roles/${id}`,
        method: "PUT",
        data,
      }),
      invalidatesTags: ["FreelancerRole", "FreelancerHoverStateData"],
    }),

    removeFreelancerRole: build.mutation<void, FreelancerRole["id"]>({
      query: (id) => ({
        url: `/freelancer_roles/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["FreelancerRole", "FreelancerHoverStateData"],
    }),
    getFreelancerBids: build.query<
      FreelancerBid[],
      FreelancerBidsParams | void
    >({
      query: (params) => ({
        url: "/freelancer_bids/",
        method: "GET",
        params: {
          ...params,
          page_size: 0, // backend returns a non-paginated response when page_size 0 is passed
        },
      }),
      providesTags: ["FreelancerBids"],
    }),
    getFreelancerBidsPaginated: build.query<
      PaginatedResult<FreelancerBid>,
      FreelancerBidsPaginatedParams | void
    >({
      query: (params) => ({
        url: "/freelancer_bids/",
        method: "GET",
        params: {
          ...params,
          page_size: params?.page_size === 0 ? 1 : params?.page_size,
        },
      }),
      providesTags: ["FreelancerBids"],
    }),
    getFreelancerBid: build.query<FreelancerBid, { id?: string }>({
      query: ({ id }) => ({
        url: `/freelancer_bids/${id}/`,
        method: "GET",
      }),
      keepUnusedDataFor: 0,
    }),
    createFreelancerBid: build.mutation<FreelancerBid, FreelancerBid>({
      query: (data) => ({
        url: "/freelancer_bids/",
        method: "POST",
        data,
      }),
      invalidatesTags: [
        { type: "Jobs", id: "LIST" },
        "FreelancerBids",
        "SavedJobs",
        "Interviews",
        "RelevantJobs",
      ],
    }),
    updateFreelancerBid: build.mutation<
      FreelancerBid,
      { id?: number; values: FreelancerBid }
    >({
      query: ({ id, values }) => ({
        url: `/freelancer_bids/${id}/`,
        method: "PUT",
        data: values,
      }),
      invalidatesTags: ["FreelancerBids"],
    }),
    cancelFreelancerBid: build.mutation<void, { id: number }>({
      query: ({ id }) => ({
        url: `/freelancer_bids/${id}/cancel_bid/`,
        method: "POST",
      }),
      invalidatesTags: ["FreelancerBids"],
    }),
    getFreelancerJobs: build.query<Job[], FreelancerJobsParams | void>({
      query: (params) => ({
        url: "/freelancer_jobs/",
        method: "GET",
        params,
      }),
    }),
    deleteResume: build.mutation<void, { id: number }>({
      query: ({ id }) => ({
        url: `/manage_freelancers/${id}/delete_resume`,
        method: "DELETE",
      }),
    }),
    uploadResume: build.mutation<
      Freelancer,
      { id: number; resume: UploadFile["id"] }
    >({
      query: ({ id, resume }) => ({
        url: `/manage_freelancers/${id}/`,
        method: "PATCH",
        data: {
          resume,
        },
      }),
    }),
    updateFreelancerCustomization: build.mutation<
      Freelancer,
      { id: number; data: UpdateFreelancerCustomizationPayload }
    >({
      query: ({ id, data }) => ({
        url: `/manage_talent_customization/${id}/`,
        method: "PUT",
        data,
      }),
      invalidatesTags: ["SpaceMembers", "FreelancerProfileCompletion"],
    }),
    getFreelancerHoverStateData: build.query<
      FreelancerHoverState,
      {
        id: Freelancer["id"];
      }
    >({
      query: ({ id }) => ({
        url: `/freelancers/${id}/hover_state_data`,
        method: "GET",
      }),
      providesTags: ["FreelancerHoverStateData"],
    }),

    getTaxForm: build.query<GetTaxFormResponse, GetTaxFormsParams>({
      query: ({ freelancerId, formType }) => ({
        url: `/manage_freelancers/${freelancerId}/${formType}_form`,
        method: "GET",
      }),
      providesTags: (_result, _error, arg) => [
        { type: "TaxForm", id: arg.formType },
      ],
    }),

    deleteTaxForm: build.mutation<void, DeleteTaxFormsParams>({
      query: ({ freelancerId, formType }) => ({
        url: `/manage_freelancers/${freelancerId}/${formType}_form`,
        method: "DELETE",
      }),

      async onQueryStarted(
        { freelancerId, formType },
        { dispatch, queryFulfilled },
      ) {
        const patch = dispatch(
          freelancerApi.util.updateQueryData(
            "getTaxForm",
            { freelancerId, formType },
            () => {
              return { form_url: null, form_uploaded_at: null };
            },
          ),
        );

        try {
          await queryFulfilled;
        } catch (error) {
          patch.undo();

          dispatch(
            freelancerApi.util.invalidateTags([
              { type: "TaxForm", id: formType },
            ]),
          );
        }
      },
    }),

    updateTaxForm: build.mutation<UpdateTaxFormsResponse, UpdateTaxFormsParams>(
      {
        query: ({ freelancerId, formType, formFileId }) => ({
          url: `/manage_freelancers/${freelancerId}/${formType}_form`,
          method: "PATCH",
          data: { form: formFileId },
        }),

        async onQueryStarted(
          { freelancerId, formType },
          { dispatch, queryFulfilled },
        ) {
          try {
            const { data: updatedFormData } = await queryFulfilled;
            dispatch(
              freelancerApi.util.updateQueryData(
                "getTaxForm",
                { freelancerId, formType },
                () => {
                  return updatedFormData;
                },
              ),
            );
          } catch (error) {
            dispatch(
              freelancerApi.util.invalidateTags([
                { type: "TaxForm", id: formType },
              ]),
            );
          }
        },
      },
    ),
    getFreelancerJobInvitations: build.query<JobInvitations, void>({
      query: () => ({
        url: "/jobs/freelancer_invitations/",
        method: "GET",
      }),
      providesTags: ["FreelancerJobInvitations"],
    }),
    getFreelancerWorkExperience: build.query<FreelancerWorkExperience[], void>({
      query: () => ({
        url: "/freelancer_work_experience/",
        method: "GET",
      }),
      providesTags: ["FreelancerWorkExperience"],
      keepUnusedDataFor: 100000000,
    }),
    getFreelancerSkills: build.query<GetFreelancerSkillsResult, { id: number }>(
      {
        query: ({ id }) => ({
          url: `/manage_freelancers/${id}/skills`,
          method: "GET",
        }),
        providesTags: ["FreelancerSkills"],
        keepUnusedDataFor: 100000000,
        transformResponse: (response: {
          freelancer_skills: GetFreelancerSkillsResult;
        }): GetFreelancerSkillsResult => {
          return response.freelancer_skills ?? [];
        },
      },
    ),
    editFreelancerSkills: build.mutation<void, EditFreelancerSkillsParams>({
      query: ({ id, ...data }) => ({
        url: `/manage_freelancers/${id}/skills`,
        method: "PATCH",
        data,
      }),
      invalidatesTags: [
        "FreelancerSkills",
        "FreelancerHoverStateData",
        "FreelancerProfileCompletion",
      ],
    }),
    addPortfolioItem: build.mutation<
      AddPortfolioItemResponse,
      AddPortfolioItemParams
    >({
      query: (data) => ({
        url: `/manage_portfolio_items/`,
        method: "POST",
        data,
      }),
      //TODO: To be adjusted once freelancer public profile query will be migrated to RTKQ
      async onCacheEntryAdded(
        _args,
        { cacheDataLoaded, cacheEntryRemoved, dispatch },
      ) {
        try {
          const { data } = await cacheDataLoaded;

          dispatch({
            type: CREATE_WORK_SAMPLE_SUCCESS,
            payload: data,
          });
        } catch (_) {
          /* empty */
        }

        await cacheEntryRemoved;
      },
      invalidatesTags: ["FreelancerProfileCompletion"],
    }),
    deletePortfolioItem: build.mutation<void, number>({
      query: (id) => ({
        url: `/manage_portfolio_items/${id}/`,
        method: "DELETE",
      }),
      //TODO: To be adjusted once freelancer public profile query will be migrated to RTKQ
      async onCacheEntryAdded(
        id,
        { cacheDataLoaded, cacheEntryRemoved, dispatch },
      ) {
        try {
          await cacheDataLoaded;

          dispatch({
            type: DELETE_WORK_SAMPLE,
            payload: id,
          });
        } catch (_) {
          /* empty */
        }

        await cacheEntryRemoved;
      },
    }),
    updatePortfolioItem: build.mutation<WorkSampleItem, WorkSampleItem>({
      query: (data) => ({
        url: `/manage_portfolio_items/${data.id}/`,
        method: "PATCH",
        data,
      }),
      //TODO: To be adjusted once freelancer public profile query will be migrated to RTKQ
      async onCacheEntryAdded(
        _args,
        { cacheDataLoaded, cacheEntryRemoved, dispatch },
      ) {
        try {
          const { data } = await cacheDataLoaded;

          dispatch({
            type: UPDATE_WORK_SAMPLE_SUCCESS,
            payload: data,
          });
        } catch (_) {
          /* empty */
        }

        await cacheEntryRemoved;
      },
    }),
    getTalentPopularLocations: build.query<Array<PopularLocation>, void>({
      query: () => ({
        url: "/frequent_talent_location_filters/",
        method: "GET",
      }),
    }),
    getFreelancerProfileCompletion: build.query<
      ProfileCompletion,
      { freelancerId: number }
    >({
      query: ({ freelancerId }) => ({
        url: `/freelancers/${freelancerId}/get_profile_completion_percent/`,
        method: "GET",
      }),
      keepUnusedDataFor: 1000000000,
      providesTags: ["FreelancerProfileCompletion"],
    }),
  }),
});

export const {
  useBoostFreelancerBidMutation,
  useLazyGetTalentQuery,
  useGetTalentsQuery,
  useGetEliteTalentsQuery,
  useGetTalentExampleProfilesQuery,
  useUpdatePortfolioItemsPositionsMutation,
  useUpdateCertificatesPositionsMutation,
  useGetFreelancerRolesQuery,
  useSaveFreelancerRoleMutation,
  useUpdateFreelancerRoleMutation,
  useRemoveFreelancerRoleMutation,
  useGetFreelancerBidQuery,
  useGetFreelancerBidsQuery,
  useCreateFreelancerBidMutation,
  useUpdateFreelancerBidMutation,
  useCancelFreelancerBidMutation,
  useUpdateFreelancerCustomizationMutation,
  useGetFreelancerJobsQuery,
  useDeleteResumeMutation,
  useUploadResumeMutation,
  useGetFreelancerHoverStateDataQuery,
  useGetTaxFormQuery,
  useDeleteTaxFormMutation,
  useUpdateTaxFormMutation,
  useGetFreelancerJobInvitationsQuery,
  useGetFreelancerWorkExperienceQuery,
  useGetFreelancerSkillsQuery,
  useEditFreelancerSkillsMutation,
  useAddPortfolioItemMutation,
  useDeletePortfolioItemMutation,
  useUpdatePortfolioItemMutation,
  useGetTalentPopularLocationsQuery,
  useGetFreelancerBidsPaginatedQuery,
  useGetFreelancerProfileCompletionQuery,
} = freelancerApi;

export const { useQueryState: useFreelancerHoverStateQueryState } =
  freelancerApi.endpoints.getFreelancerHoverStateData;
