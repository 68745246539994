import React from "react";

import { Box, Typography } from "@hexocean/braintrust-ui-components";
import { useMediaQuery } from "@hexocean/braintrust-ui-components/hooks";
import { useUser } from "@js/apps/common/hooks";
import { BidButton } from "@js/apps/jobs/apps/bids/components";
import type { FreelancerBid, Job } from "@js/types/jobs";

import { NotInterestedButton } from "../not-interested-button";

type InvitationMessageBoxTypes = {
  job: Job;
  bid?: FreelancerBid;
  hasFreelancerBid?: boolean;
};

export const InvitationMessageBox = ({
  job,
  bid,
  hasFreelancerBid,
}: InvitationMessageBoxTypes) => {
  const user = useUser();
  const isSmall = useMediaQuery("sm");

  return (
    <Box bgcolor="var(--medium-violet)" p={4} borderRadius={4}>
      <Typography mb={3} component="h2" fontWeight={500} size="large">
        You’ve been invited to apply!
      </Typography>
      <Typography mb={2} component="h3" size="large" multipleEllipsis>
        Hey {user?.first_name} 👋,
      </Typography>
      <Typography component="p" size="large" multipleEllipsis>
        {getInvitationMessage(job)}
      </Typography>
      {!hasFreelancerBid && (
        <Box
          display="flex"
          flexDirection={{ xs: "column", sm: "row" }}
          justifyContent="flex-end"
          alignItems="center"
          gap={1}
          mt={3}
        >
          <NotInterestedButton job={job} fullWidth={isSmall} />
          <BidButton
            style={{
              paddingLeft: 27,
              paddingRight: 27,
            }}
            variant="primary"
            job={job}
            bid={bid}
            fullWidth={isSmall}
          />
        </Box>
      )}
    </Box>
  );
};

const getInvitationMessage = (job: Job): string | null => {
  if (job.invited_by_client) {
    return `${job.inviter_public_name || "A matcher"} from ${job.employer.name}
             thought your skills and experience
             were a great match for this job.
             They’ve invited you to submit an application!`;
  } else if (job.invited_by_matcher) {
    return `A matcher on Braintrust,
            ${job.inviter_public_name} thought your skills and experience
            were a great match for this job.
            They’ve invited you to submit an application!`;
  } else return null;
};
