import React from "react";

import { Box, Button, Typography } from "@hexocean/braintrust-ui-components";
import { useMergeLinkComponent } from "@js/apps/employer/hooks/merge-link-component";
import { Datetime } from "@js/components/date";
import type { ATSIntegration } from "@js/types/employer";
import { DateTimeFormats, type IsoDateTime } from "@js/utils/date/types";

import { openDisconnectATSIntegrationModal } from "../../disconnect-ats-integration-modal";

import styles from "./style.module.scss";

type MyIntegrationItemProps = { integration: ATSIntegration };

export const MyIntegrationItem = ({ integration }: MyIntegrationItemProps) => {
  const handleDisconnectClick = () => {
    openDisconnectATSIntegrationModal({ integration });
  };

  const { handleRelinkClick } = useMergeLinkComponent({ integration });

  return (
    <Box className={styles.myIntegrationItem}>
      <Box className={styles.myIntegrationItemContent}>
        <Box
          sx={{
            width: "56px",
            height: "56px",
            borderRadius: "6px",
            overflow: "hidden",
            border: `1px solid ${integration.color ?? "var(--black)"}`,
          }}
        >
          <img
            src={integration.logo_square}
            width="56"
            height="56"
            alt="ATS integration logo"
          />
        </Box>
        <Box className={styles.myIntegrationItemDescription}>
          <Typography
            component="span"
            variant="label"
            size="medium"
            fontWeight={500}
            ellipsis
          >
            {integration.integration}
          </Typography>

          <Typography component="span" size="small" ellipsis>
            Last synced:{" "}
            {integration.last_sync_start ? (
              <Datetime
                date={integration.last_sync_start as IsoDateTime}
                format={DateTimeFormats["January 1, 1970 at 0:00 am"]}
              />
            ) : (
              "Still awaiting synchronization"
            )}
          </Typography>
        </Box>
      </Box>
      <Box display="flex" gap={1}>
        <Button variant="secondary" shape="squared" onClick={handleRelinkClick}>
          Re-link account
        </Button>
        <Button
          variant="destructive-outlined"
          shape="squared"
          onClick={handleDisconnectClick}
          sx={{ width: "fit-content" }}
        >
          Disconnect
        </Button>
      </Box>
    </Box>
  );
};
