import { Box } from "@hexocean/braintrust-ui-components";
import { useUser } from "@js/apps/common/hooks";
import { useGetEmployerInfoQuery } from "@js/apps/employer/api";
import { EmployerLogo } from "@js/apps/employer/components/employer-logo";

export const ViewEmployerProfileLabel = () => {
  const user = useUser();
  const { data: employerData } = useGetEmployerInfoQuery(
    { employerId: user?.employer as number },
    { skip: user === null || !user?.employer },
  );

  return (
    <Box display="flex" gap="10px" alignItems="center">
      {employerData && (
        <EmployerLogo
          variant="menuitem"
          employer={employerData}
          listingStyle={{
            maxHeight: "25px",
            maxWidth: "25px",
          }}
        />
      )}
      View profile
    </Box>
  );
};
