import cs from "classnames";

import { Checkbox, Stack } from "@hexocean/braintrust-ui-components";
import { Box, Divider } from "@hexocean/braintrust-ui-components";
import { useMediaQuery } from "@hexocean/braintrust-ui-components/hooks";
import { RatingCard } from "@js/apps/common/components/rating-card";
import { useBidsContext } from "@js/apps/jobs/context/refetch-bids-context";
import type { EmployerBidListBid, Job } from "@js/types/jobs";

import { useBidListItem } from "../../hooks/bid-list-item";
import { ReactionsFeedbackField } from "../bid-reactions";
import { ExpandedItem } from "../expanded-item";

import {
  BidListItemAvatar,
  BidOverview,
  CTASection,
  ShowCommentsBtn,
  SkillsAndQASection,
  UserDetails,
} from "./components";

import style from "./style.module.scss";

type BidListItemProps = {
  bid: EmployerBidListBid;
  job: Job;
  selected: boolean;
  onCheckboxChange: () => void;
};

export const BidListItem = ({
  bid,
  job,
  selected,
  onCheckboxChange,
}: BidListItemProps) => {
  const isTablet = useMediaQuery(1280);
  const isMobile = useMediaQuery(420);
  const { isBidExpanded, expandBid } = useBidsContext();
  const {
    notesCount,
    setNotesCount,
    linkToSidePanel,
    isSkillsAndQASectionEmpty,
    handleClickWithinItem,
  } = useBidListItem({ bid, jobId: job.id });

  return (
    <Box
      className={cs(style.itemWrapper, {
        [style.blackBorder]: bid.recommended_by_matcher,
        [style.greenBg]: bid.status === ENUMS.BidStatus.HIRED,
        [style.redBg]: bid.is_hidden,
        [style.selected]: selected,
      })}
      onClick={handleClickWithinItem}
    >
      <Box
        className={style.topSection}
        data-testid={`bid ${!bid.is_hidden ? "eligible" : "ineligible"}`}
      >
        <Box
          sx={{
            display: "flex",
            width: { xs: "100%", sm: "auto" },
            maxWidth: { xs: "100%", lg: "65%" },
            alignItems: "center",
          }}
        >
          <Box display="flex" alignItems="center">
            <Checkbox
              checked={selected}
              onChange={onCheckboxChange}
              inputProps={{
                "aria-label": "Select talent",
              }}
              className="job-bids-list-item__talent-checkbox"
            />
          </Box>
          <BidListItemAvatar bid={bid} />
          <UserDetails bid={bid} job={job} isMobile={isMobile} />
        </Box>

        {isTablet && !isMobile && <Divider color="beige" />}

        <BidOverview bid={bid} job={job} isMobile={isMobile} />
      </Box>
      <Box>
        <Divider color="beige" style={{ marginBottom: "12px" }} />
        {!isSkillsAndQASectionEmpty && (
          <SkillsAndQASection bid={bid} link={linkToSidePanel} />
        )}
        <Stack
          direction="row"
          sx={{
            flexWrap: { xs: "wrap", lg: "nowrap" },
            gap: "16px 8px",
            alignItems: "center",
          }}
        >
          <ReactionsFeedbackField bid={bid} />
          <ShowCommentsBtn
            bidId={bid.id}
            isBidExpanded={isBidExpanded}
            expandBid={expandBid}
            commentsCount={notesCount}
          />
          <RatingCard freelancer={bid.freelancer} className={style.bidRating} />
          <CTASection
            bid={bid}
            job={job}
            link={linkToSidePanel}
            isMobile={isMobile}
          />
        </Stack>
      </Box>
      {isBidExpanded && isBidExpanded(bid.id) && (
        <ExpandedItem id={bid.id} setNotesCount={setNotesCount} />
      )}
    </Box>
  );
};
