import React from "react";
import classNames from "classnames";

import type { Job, MainJobListingJob } from "@js/types/jobs";
import { formatBudget, formatBudgetWithPrefix } from "@js/utils";

type OwnProps = {
  job: Pick<
    Job | MainJobListingJob,
    "payment_type" | "budget_minimum_usd" | "budget_maximum_usd"
  >;
  className?: string;
  round?: boolean;
  removeDecimal?: boolean;
  noSpaces?: boolean;
  formatWithPrefix?: boolean;
};

export const JobBudget = ({
  job,
  className,
  round,
  removeDecimal,
  noSpaces,
  formatWithPrefix = false,
}: OwnProps) => {
  return (
    <span className={classNames(className)}>
      {formatWithPrefix
        ? formatBudgetWithPrefix(job.budget_minimum_usd, job.budget_maximum_usd)
        : round
          ? formatBudget(
              Math.round(Number(job.budget_minimum_usd)),
              Math.round(Number(job.budget_maximum_usd)),
              {
                removeDecimal: true,
              },
            )
          : formatBudget(job.budget_minimum_usd, job.budget_maximum_usd, {
              removeDecimal: removeDecimal,
            })}
      {job.payment_type === ENUMS.JobPaymentType.HOURLY && (
        <span className={classNames({ "job-role-budget-unit": !noSpaces })}>
          /{noSpaces ? "" : " "}hr
        </span>
      )}
      {job.payment_type === ENUMS.JobPaymentType.ANNUAL && (
        <span className={classNames({ "job-role-budget-unit": !noSpaces })}>
          /{noSpaces ? "" : " "}yr
        </span>
      )}
    </span>
  );
};
