import React from "react";

import { AppLayout } from "@js/layouts/app";

type EmployerDashboardLayoutProps = {
  contentClassName?: string;
  pageTitle?: string;
  bgcolor?: string;
};

const EmployerDashboardLayout: React.FC<
  React.PropsWithChildren<EmployerDashboardLayoutProps>
> = ({ children, contentClassName, pageTitle, bgcolor }) => {
  return (
    <AppLayout
      flexColumn
      pageTitle={pageTitle}
      className={contentClassName}
      bgcolor={bgcolor}
    >
      {children}
    </AppLayout>
  );
};

export default EmployerDashboardLayout;
