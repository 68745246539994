import { useMemo } from "react";

import { useGetEmployerJobsMinimalQuery } from "@js/apps/jobs/api";

export const useLetPostJobModule = () => {
  const { data: employerJobsMinimal, isLoading } =
    useGetEmployerJobsMinimalQuery();

  const showModule = useMemo(
    () => !isLoading && employerJobsMinimal?.length === 0,
    [employerJobsMinimal, isLoading],
  );

  return {
    showFirstJobPromptModule: showModule,
    isLoading,
  };
};
