import { useEffect } from "react";

export type SelectionProps = {
  selectedNode: Node;
  cursorPosition: number;
  anchorElement: HTMLElement;
  keyPressed?: KeyPressedTypes;
};
type KeyPressedTypes = "ARROW_LEFT" | "ARROW_RIGHT";

type useSelectionProps = {
  editorElement?: Element | null;
  onSelection: (params: SelectionProps) => void;
  onSelectionOutside: () => void;
};

export const useSelection = ({
  editorElement,
  onSelection,
  onSelectionOutside,
}: useSelectionProps) => {
  useEffect(() => {
    const onKeyPress = (ev: KeyboardEvent) => {
      const selection = window.getSelection();
      const selectedNode = selection?.focusNode;

      if (
        !editorElement ||
        !selection ||
        !selectedNode ||
        !editorElement.contains(selectedNode)
      ) {
        return;
      }

      const keyMap = {
        ArrowLeft: "ARROW_LEFT",
        ArrowRight: "ARROW_RIGHT",
      };

      const keyPressed = keyMap[ev.key];

      if (!keyPressed) {
        return;
      }

      const cursorPosition = selection.focusOffset;
      const anchorElement = selectedNode.parentNode as HTMLElement;

      onSelection?.({
        selectedNode,
        cursorPosition,
        anchorElement,
        keyPressed,
      });
    };

    const onSelectionChange = () => {
      if (!editorElement) return;
      const selection = window.getSelection();

      if (!selection) {
        return;
      }

      const selectedNode = selection.focusNode;
      if (!selectedNode || !editorElement.contains(selectedNode)) {
        onSelectionOutside?.();
        return;
      }

      const cursorPosition = selection.focusOffset;
      const anchorElement = selectedNode.parentNode as HTMLElement;

      onSelection?.({ selectedNode, cursorPosition, anchorElement });
    };

    document.addEventListener("keydown", onKeyPress);
    document.addEventListener("selectionchange", onSelectionChange);

    return () => {
      document.removeEventListener("selectionchange", onSelectionChange);
      document.removeEventListener("keydown", onKeyPress);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editorElement]);
};
