import { type FC, useMemo } from "react";
import PoweredByCoinbase from "@static/on-ramp/poweredByCoinbase.svg";

import {
  Button,
  Grid,
  IconButton,
  Typography,
  useMediaQuery,
} from "@hexocean/braintrust-ui-components";
import {
  ArrowRightTopIcon,
  BraintrustIcon,
  CloseIcon,
  SwapVerticalIcon,
} from "@hexocean/braintrust-ui-components/Icons";
import { OfferVisual } from "@js/apps/common/components/offer-visual";
import { useCoinbasePay } from "@js/apps/on-ramp/hooks/useCoinbase";
import { RouterLink } from "@js/components/link";
import { ModalInstance } from "@js/components/modal";

import { BasicAccordion } from "../accordion";
import { openPendingTransactionModal } from "../pending-transaction-modal";

import styles from "../styles.module.scss";

const accordionOptions = [
  {
    title: "Why do we need to use Coinbase?",
    body: (
      <>
        Coinbase is the largest online exchange that allows people to buy, sell,
        and trade cryptocurrencies, including BTRST, on the Blockchain. We chose
        Coinbase for it’s trusted reputation, secure platform, and easy-to-use
        interface that makes it ideal for beginner to advanced cryptocurrency
        traders.
      </>
    ),
  },
  {
    title: "Is it safe to use Coinbase?",
    body: (
      <>
        Coinbase is the most trusted crypto exchange:
        <br />
        <a
          href="https://www.coinbase.com/security"
          target="_blank"
          rel="noopener noreferrer"
          style={{ textDecoration: "underline" }}
        >
          https://www.coinbase.com/security
        </a>
      </>
    ),
  },
];

type SimpleOnrampModalProps = {
  walletAddress: string;
  transactionUuid: string;
};
export const SimpleOnrampModal: FC<SimpleOnrampModalProps> = ({
  transactionUuid,
  walletAddress,
}) => {
  const { onRampURL } = useCoinbasePay({ transactionUuid, walletAddress });
  const isMobile = useMediaQuery("sm");

  const clickOnCoinbase = () => {
    ModalInstance.close();
    openPendingTransactionModal();
    if (onRampURL) {
      window.open(onRampURL, "_blank", "noreferrer");
    }
  };

  const mb = useMemo(() => {
    return isMobile ? "16px" : "24px";
  }, [isMobile]);

  return (
    <Grid container height="100%">
      <Grid
        item
        container
        mb={mb}
        xs={12}
        alignItems="center"
        justifyContent="space-between"
      >
        <Typography
          component="p"
          variant="label"
          size="medium"
          mb={0}
          className={styles["onramp-modal__title"]}
        >
          Purchase
          <BraintrustIcon
            style={{
              fontSize: "inherit",
              verticalAlign: "text-bottom",
              marginLeft: "6px",
              marginRight: "6px",
            }}
          />
          BTRST
        </Typography>
        <IconButton
          variant="tertiary"
          size="small"
          aria-label="Close coinbase onramp modal"
          onClick={() => ModalInstance.close()}
        >
          <CloseIcon />
        </IconButton>
      </Grid>
      <Grid item xs={12}>
        <OfferVisual
          wrapperClassName={styles["onramp-modal__offer-visual"]}
          exchangeFrom={{
            currency: "USD",
            avatar_thumbnail: `${SETTINGS.STATIC_URL}flags/us-round.svg`,
            has_avatar_set: false,
            gravatar: "",
          }}
          exchangeTo={{
            currency: "BTRST",
            avatar_thumbnail: "",
            has_avatar_set: false,
            gravatar: "",
          }}
          icon={<SwapVerticalIcon />}
          isInverse
          largePill={!isMobile}
        />
        <Typography
          component="p"
          variant={isMobile ? "label" : "paragraph"}
          size="large"
          mb={mb}
          mt={mb}
          fontWeight={400}
        >
          Buy BTRST securely through Coinbase.
        </Typography>

        <Grid item xs={12} mb={mb}>
          <Typography component="p" variant="paragraph" size="medium">
            By confirming payment you accept our{" "}
            <Typography
              component="a"
              href={SETTINGS.PRIVACY_DOC_URL}
              target="_blank"
              color="blue"
              rel="noreferrer"
              onClick={(e) => e.stopPropagation()}
              RouterLink={RouterLink}
            >
              Privacy Policy
            </Typography>
            , and{" "}
            <Typography
              component="a"
              href={SETTINGS.TERMS_DOC_URL}
              target="_blank"
              color="blue"
              rel="noreferrer"
              onClick={(e) => e.stopPropagation()}
              RouterLink={RouterLink}
            >
              Payments Terms & Conditions
            </Typography>
          </Typography>
        </Grid>
        <Button
          onClick={clickOnCoinbase}
          sx={{ marginBottom: mb }}
          fullWidth
          variant="positive"
          shape="squared"
          target="_blank"
          rel="noreferrer"
          title="Initiate Coinbase Pay"
          endIcon={<ArrowRightTopIcon />}
        >
          Get started with Coinbase
        </Button>
        <Grid
          item
          container
          alignItems="center"
          justifyContent="center"
          mb={mb}
        >
          <PoweredByCoinbase />
        </Grid>

        <BasicAccordion options={accordionOptions} />
      </Grid>
    </Grid>
  );
};

export const openSimpleOnrampModal = ({
  walletAddress,
  transactionUuid,
}: Omit<SimpleOnrampModalProps, "onClose">): void => {
  ModalInstance.open({
    closeButton: false,
    padding: false,
    className: `${styles["onramp-modal"]} ${styles["offer-payment-modal-width"]}`,
    children: (
      <SimpleOnrampModal
        transactionUuid={transactionUuid}
        walletAddress={walletAddress}
      />
    ),
  });
};
