import React from "react";

import { EmployerDashboardJobsComponent } from "./my-jobs/jobs";
import DashboardLayout from "./layout";

const EmployerDashboardMyJobsPageComponent = () => {
  return (
    <DashboardLayout pageTitle="My Jobs" bgcolor="var(--soft-blue)">
      <EmployerDashboardJobsComponent />
    </DashboardLayout>
  );
};

export default EmployerDashboardMyJobsPageComponent;
