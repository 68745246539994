export const INTRODUCTION_SLIDES = [
  {
    id: 1111,
    header: "Get advice from the community",
    subheader: `Post questions about your resume, negotiating salaries, or anything to help you move forward in your career.`,
    images: {
      lg: `${SETTINGS.STATIC_URL}give-get-help/introduction-img-1.svg`,
      sm: `${SETTINGS.STATIC_URL}give-get-help/introduction-img-1-sm.svg`,
    },
  },
  {
    id: 2222,
    header: "Get 1:1 help from industry experts",
    subheader: `Talk to those with more experience than you, and ask if they have time to give you more in-depth help.`,
    images: {
      lg: `${SETTINGS.STATIC_URL}give-get-help/introduction-img-2.svg`,
      sm: `${SETTINGS.STATIC_URL}give-get-help/introduction-img-2-sm.svg`,
    },
  },
  {
    id: 3333,
    header: "Get praise for helping others",
    subheader: `Stand out to clients and act as a mentor by sharing your knowledge and skills through 1:1 help with others.`,
    images: {
      lg: `${SETTINGS.STATIC_URL}give-get-help/introduction-img-3.svg`,
      sm: `${SETTINGS.STATIC_URL}give-get-help/introduction-img-3-sm.svg`,
    },
  },
];
