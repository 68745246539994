import type { ReactNode } from "react";
import React from "react";

import { Paper, Typography } from "@hexocean/braintrust-ui-components";

type ATSIntegrationConnectedSectionContainerProps = {
  children: ReactNode;
  title: ReactNode;
};

export const ATSIntegrationConnectedSectionContainer = ({
  children,
  title,
}: ATSIntegrationConnectedSectionContainerProps) => {
  return (
    <Paper
      variant="outlined"
      sx={{
        p: { xs: "12px 16px", md: "20px 32px" },
        width: "100%",
        boxSizing: "border-box",
        borderRadius: "6px",
      }}
    >
      <Typography component="h3" variant="label" size="large" sx={{ mb: 2 }}>
        {title}
      </Typography>
      {children}
    </Paper>
  );
};
