import type { WrappedFieldProps } from "redux-form";

import {
  ComboBox,
  Loader,
  TextField as TextInput,
} from "@hexocean/braintrust-ui-components";

import { useInvoiceStatusField } from "./use-status-field";

export const InvoiceStatusField = ({ input, meta }: WrappedFieldProps) => {
  const { options, value, setValue } = useInvoiceStatusField({ input });

  if (!options.length) {
    return <Loader />;
  }

  return (
    <ComboBox<{ value: string; label: string }, false>
      value={value}
      initialTaxonomiesLoading={false}
      getOptionLabel={(option) => option.label}
      renderInput={(params) => {
        return (
          <TextInput
            {...params}
            label="Invoice Status"
            error={!!meta.error}
            helperText={meta.error}
          />
        );
      }}
      isOptionEqualToValue={(option, valueArg) => {
        return option.value === valueArg?.value;
      }}
      options={options}
      onChange={(_ev, valueArg) => {
        setValue(valueArg);
        input.onChange(valueArg?.value || null);
      }}
    />
  );
};
