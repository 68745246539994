import React from "react";

import { EmployerEditWizard } from "@js/apps/employer/components/employer-edit-wizard";
import { EmployerTeamMembers } from "@js/apps/employer/components/team-members";

export default () => {
  return (
    <EmployerEditWizard
      className="employer-team-members"
      pageTitle="Edit Profile | Team Members"
    >
      <EmployerTeamMembers />
    </EmployerEditWizard>
  );
};
