import React from "react";

import { Typography } from "@hexocean/braintrust-ui-components";

import { PostJobButton } from "../../views/dashboard/my-jobs/jobs";

import style from "./style.module.scss";

export const LetPostJob = ({
  talentCountsToDisplay,
}: {
  talentCountsToDisplay: string;
}) => {
  return (
    <div className={style.letPostJobContainer}>
      <div className={style.letPostJobContainerCtaSection}>
        <Typography
          component="h2"
          variant="title"
          size="medium"
          fontWeight={400}
        >
          Let’s post your first job
        </Typography>
        <Typography component="p" size="large" color="grey-1" mb={3}>
          More than {talentCountsToDisplay} talent are on the network ready for
          your next project.
        </Typography>
        <PostJobButton style={{ padding: "11px 20px" }} />
      </div>

      <img
        className={style.letPostJobContainerImage}
        src={`${SETTINGS.STATIC_URL}dashboard/let-post-job.svg`}
        alt="Picture of the create a job form"
        width="439"
        height="248"
      />
    </div>
  );
};
