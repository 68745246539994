import React from "react";

import { PERSONAL_INFO_FORM_ID } from "@js/apps/common/constants";
import { Form } from "@js/forms/components/form";
import type { InjectedFormProps } from "@js/forms/fields";
import { reduxForm } from "@js/forms/fields";

import type { PersonalInfoFormValues } from "../../hooks/personal-info";

import { PersonalInfoFields } from "./fields";

const PersonalInfoFormComponent = ({
  submit,
  error,
  submitting,
}: InjectedFormProps<PersonalInfoFormValues>) => (
  <Form
    onSubmit={submitting ? null : submit}
    error={error}
    style={{ minWidth: 0 }}
  >
    <PersonalInfoFields />
  </Form>
);

export const PersonalInfoForm = reduxForm<PersonalInfoFormValues>({
  form: PERSONAL_INFO_FORM_ID,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
  unsavedChangesWarning: true,
})(PersonalInfoFormComponent);
