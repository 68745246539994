import React, { useMemo } from "react";

import { Menu } from "@hexocean/braintrust-ui-components";
import type { EmployerInvoiceMinimal } from "@js/types/invoices";

type PaySelectedActionProps = {
  selectedInvoices: EmployerInvoiceMinimal[];
  startPaymentFlow: () => void;
};

export const PaySelectedAction = ({
  selectedInvoices,
  startPaymentFlow,
}: PaySelectedActionProps) => {
  const isUnpayableInvoiceSelected = useMemo(() => {
    return selectedInvoices.some((invoice) => invoice.can_be_paid === false);
  }, [selectedInvoices]);

  const disablePaySelectedActionButton =
    !selectedInvoices.length || isUnpayableInvoiceSelected;

  return (
    <Menu.Item
      disabled={disablePaySelectedActionButton}
      onClick={startPaymentFlow}
      displayTooltipOnlyIfDisabled
      tooltipText={
        "Some of selected invoices can't be paid (maybe they're already paid)?"
      }
      tooltipProps={{
        placement: "right",
      }}
    >
      Pay Selected
    </Menu.Item>
  );
};
