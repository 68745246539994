import { Box } from "@hexocean/braintrust-ui-components";
import { AddWorkHistoryModal } from "@js/apps/freelancer/components/work-history/modals";

import { useProfileCompletionSequenceContext } from "../context/profile-completion-sequence-context";

import { ProfileCompletionSequenceCloseBtn } from "./close-btn";

export const WorkExperience = () => {
  const { shouldRender, setStepBeingEdited } =
    useProfileCompletionSequenceContext();

  if (!shouldRender("Work Experience")) {
    return null;
  }

  return (
    <Box sx={{ padding: 4 }}>
      <ProfileCompletionSequenceCloseBtn />
      <AddWorkHistoryModal
        saveButtonText="Save and continue"
        persistModalInstance
        setStepBeingEdited={setStepBeingEdited}
      />
    </Box>
  );
};
