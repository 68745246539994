import React from "react";

import type { TextFieldUnionProps } from "@hexocean/braintrust-ui-components";
import { TextField } from "@hexocean/braintrust-ui-components";
import {
  mapAddressComponentsToAddress,
  mapFullAddressComponentsToAddress,
} from "@js/components/autocomplete-new/combobox/google-places";
import type { GooglePlacesComboBoxProps } from "@js/components/autocomplete-new/combobox/google-places/google-places-location-combo-box";
import { GooglePlacesDeprecated } from "@js/components/autocomplete-new/combobox/google-places/google-places-location-combo-box";
import type { CertificatesCreatableComboboxProps } from "@js/components/autocomplete-new/creatable/certificates";
import { CertificatesCreatableCombobox } from "@js/components/autocomplete-new/creatable/certificates";
import type { CompaniesCreatableComboboxProps } from "@js/components/autocomplete-new/creatable/companies";
import { CompaniesCreatableCombobox } from "@js/components/autocomplete-new/creatable/companies";
import type { DegreesCreatableComboboxProps } from "@js/components/autocomplete-new/creatable/degrees";
import { DegreesCreatableCombobox } from "@js/components/autocomplete-new/creatable/degrees";
import type { SchoolsCreatableComboboxProps } from "@js/components/autocomplete-new/creatable/schools";
import { SchoolsCreatableCombobox } from "@js/components/autocomplete-new/creatable/schools";
import { GooglePlacesComboBoxMultiple } from "@js/components/autocomplete-new/google-places/multiple";
import type { GoogleComboboxProps } from "@js/components/autocomplete-new/google-places/single";
import { GoogleCombobox } from "@js/components/autocomplete-new/google-places/single";

import type { ReduxFormAutocompleteFactoryInjectedProps } from "./connector";
import { ReduxFormAutocompleteFactory } from "./connector";
import { overwriteInputOnChange, transformValueToPrimitive } from "./helpers";

export { mapAddressComponentsToAddress, mapFullAddressComponentsToAddress };

const ReduxFormConnectedGooglePlacesCombobox = ReduxFormAutocompleteFactory(
  GooglePlacesComboBoxMultiple,
);

const ReduxFormConnectedGooglePlacesDeprecatedCombobox =
  ReduxFormAutocompleteFactory(GooglePlacesDeprecated);

const ReduxFormConnectedSingleGoogle =
  ReduxFormAutocompleteFactory(GoogleCombobox);

const ReduxFormConnectedCertificatesCreatable = ReduxFormAutocompleteFactory(
  CertificatesCreatableCombobox,
);

const ReduxFormConnectedCompaniesCreatable = ReduxFormAutocompleteFactory(
  CompaniesCreatableCombobox,
);

const ReduxFormConnectedDegreesCreatable = ReduxFormAutocompleteFactory(
  DegreesCreatableCombobox,
);

const ReduxFormConnectedSchoolsCreatable = ReduxFormAutocompleteFactory(
  SchoolsCreatableCombobox,
);

type CommonTextFieldUnionProps = {
  placeholder?: TextFieldUnionProps["placeholder"];
  label: TextFieldUnionProps["label"] | undefined;
  required?: TextFieldUnionProps["required"];
  "aria-label"?: TextFieldUnionProps["aria-label"];
};

type TaxonomyFieldProps<Props> = Optional<
  ReduxFormAutocompleteFactoryInjectedProps,
  "renderInput"
> &
  Omit<Props, "renderInput" | "component" | "onChange"> & {
    component?: React.ElementType; // we need to loose the type due to Field and Autocomplete `component` type conflict
  } & CommonTextFieldUnionProps;

export type SchoolsFieldProps =
  TaxonomyFieldProps<SchoolsCreatableComboboxProps>;

export const SchoolsField = ({
  placeholder,
  label,
  required,
  [`aria-label`]: ariaLabel,
  input,
  ...props
}: SchoolsFieldProps) => {
  return (
    <ReduxFormConnectedSchoolsCreatable
      renderInput={(params, state) => {
        return (
          <TextField
            {...params}
            inputProps={{
              "aria-label": ariaLabel,
              ...params.inputProps,
            }}
            label={label}
            required={required}
            error={state.isError}
            helperText={state.isError && state.error}
            placeholder={placeholder}
          />
        );
      }}
      input={overwriteInputOnChange(input)}
      {...props}
    />
  );
};

export type DegreesFieldProps =
  TaxonomyFieldProps<DegreesCreatableComboboxProps>;

export const DegreesField = ({
  placeholder,
  label,
  required,
  [`aria-label`]: ariaLabel,
  input,
  ...props
}: DegreesFieldProps) => {
  return (
    <ReduxFormConnectedDegreesCreatable
      renderInput={(params, state) => {
        return (
          <TextField
            {...params}
            inputProps={{
              "aria-label": ariaLabel,
              ...params.inputProps,
            }}
            label={label}
            required={required}
            error={state.isError}
            helperText={state.isError && state.error}
            placeholder={placeholder}
          />
        );
      }}
      input={overwriteInputOnChange(input)}
      {...props}
    />
  );
};

export type CompaniesFieldProps =
  TaxonomyFieldProps<CompaniesCreatableComboboxProps>;

export const CompaniesField = ({
  placeholder,
  label,
  required,
  [`aria-label`]: ariaLabel,
  input,
  ...props
}: CompaniesFieldProps) => {
  return (
    <ReduxFormConnectedCompaniesCreatable
      renderInput={(params, state) => {
        return (
          <TextField
            {...params}
            inputProps={{
              "aria-label": ariaLabel,
              ...params.inputProps,
            }}
            label={label}
            required={required}
            error={state.isError}
            helperText={state.isError && state.error}
            placeholder={placeholder}
          />
        );
      }}
      input={{
        ...overwriteInputOnChange(input),
        value: transformValueToPrimitive(input.value, "name"),
      }}
      {...props}
    />
  );
};

export type CertificatesFieldProps =
  TaxonomyFieldProps<CertificatesCreatableComboboxProps>;

export const CertificatesField = ({
  placeholder,
  label,
  required,
  [`aria-label`]: ariaLabel,
  input,
  ...props
}: CertificatesFieldProps) => {
  return (
    <ReduxFormConnectedCertificatesCreatable
      renderInput={(params, state) => {
        return (
          <TextField
            {...params}
            inputProps={{
              "aria-label": ariaLabel,
              ...params.inputProps,
            }}
            label={label}
            required={required}
            error={state.isError}
            helperText={state.isError && state.error}
            placeholder={placeholder}
          />
        );
      }}
      input={overwriteInputOnChange(input)}
      {...props}
    />
  );
};

export type SingleFieldProps<DisableClearable extends boolean> =
  TaxonomyFieldProps<GoogleComboboxProps<DisableClearable>>;

export const GooglePlacesSingleField = <DisableClearable extends boolean>({
  placeholder = "Enter your city",
  label,
  required,
  [`aria-label`]: ariaLabel,
  ...props
}: SingleFieldProps<DisableClearable>) => {
  return (
    <ReduxFormConnectedSingleGoogle
      renderInput={(params, state) => {
        return (
          <TextField
            {...params}
            inputProps={{
              "aria-label": ariaLabel,
              ...params.inputProps,
            }}
            label={label}
            required={required}
            error={state.isError}
            helperText={state.isError && state.error}
            placeholder={placeholder}
          />
        );
      }}
      {...props}
    />
  );
};

export type GooglePlacesMultipleFieldDeprecatedProps<
  DisableClearable extends boolean,
> = TaxonomyFieldProps<GooglePlacesComboBoxProps<true, DisableClearable>>;

/** @deprecated use GooglePlacesMultipleField instead */
export const GooglePlacesMultipleFieldDeprecated = <
  DisableClearable extends boolean,
>({
  placeholder = "Enter your city",
  label,
  required,
  [`aria-label`]: ariaLabel,
  ...props
}: GooglePlacesMultipleFieldDeprecatedProps<DisableClearable>) => {
  return (
    <ReduxFormConnectedGooglePlacesDeprecatedCombobox
      renderInput={(params, state) => {
        return (
          <TextField
            {...params}
            inputProps={{
              "aria-label": ariaLabel,
              ...params.inputProps,
            }}
            label={label}
            required={required}
            error={state.isError}
            helperText={state.isError && state.error}
            placeholder={placeholder}
          />
        );
      }}
      {...props}
      multiple
    />
  );
};

export type GooglePlacesMultipleFieldProps<DisableClearable extends boolean> =
  TaxonomyFieldProps<GooglePlacesComboBoxProps<true, DisableClearable>>;

export const GooglePlacesMultipleField = <DisableClearable extends boolean>({
  placeholder = "Enter your city",
  label,
  required,
  [`aria-label`]: ariaLabel,
  ...props
}: GooglePlacesMultipleFieldProps<DisableClearable>) => {
  return (
    <ReduxFormConnectedGooglePlacesCombobox
      renderInput={(params, state) => {
        return (
          <TextField
            {...params}
            inputProps={{
              "aria-label": ariaLabel,
              ...params.inputProps,
            }}
            label={label}
            required={required}
            error={state.isError}
            helperText={state.isError && state.error}
            placeholder={placeholder}
          />
        );
      }}
      {...props}
      multiple
    />
  );
};

export const GoogleStandardSingleField = <DisableClearable extends boolean>({
  placeholder = "Enter your city",
  label,
  required,
  [`aria-label`]: ariaLabel,
  ...props
}: SingleFieldProps<DisableClearable>) => {
  return (
    <ReduxFormConnectedSingleGoogle
      renderInput={(params, state) => {
        return (
          <TextField
            {...params}
            inputProps={{
              "aria-label": ariaLabel,
              ...params.inputProps,
            }}
            variant="standard"
            label={label}
            required={required}
            placeholder={placeholder}
            error={state.isError}
            helperText={state.isError && state.error}
          />
        );
      }}
      {...props}
    />
  );
};
