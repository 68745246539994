import { useCallback } from "react";

import {
  Box,
  Divider,
  Loader,
  Stack,
  Typography,
} from "@hexocean/braintrust-ui-components";
import { useMediaQuery } from "@hexocean/braintrust-ui-components/hooks";
import { useGetEmployerTeamMembersQuery } from "@js/apps/employer/api";
import { useEditedJobSubscribers } from "@js/apps/employer/hooks/use-edited-job-subscribers";
import { JobSubscribersList } from "@js/apps/jobs/components/job-subscribers";
import { SubscribeTeamMembersAutocomplete } from "@js/apps/jobs/components/subscribe-team-members-autocomplete";
import { ScrollableContainer } from "@js/components/scrollable-container";
import type {
  JobSubscriberData,
  JobSubscriberInvitationData,
} from "@js/types/jobs";

import { AddTeamMemberDrawerHeader } from "./add-team-member-drawer-header";

import styles from "./add-team-member-drawer-content.module.scss";

export type AddTeamMemberDrawerContentProps = {
  jobOwnerId: number;
  jobSubscribers: JobSubscriberData[];
  jobSubscribersValue: JobSubscriberData[];
  jobSubscriberInvitationsValue: JobSubscriberInvitationData[];
  handleClose: () => void;
  changeSubscribers: (arg: {
    jobSubscribersData: JobSubscriberData[];
    jobSubscriberInvitationsData: JobSubscriberInvitationData[];
  }) => void;
  errorMessage: string | undefined;
};

export const AddTeamMemberDrawerContent = ({
  jobOwnerId,
  handleClose,
  jobSubscribers,
  jobSubscribersValue,
  jobSubscriberInvitationsValue,
  changeSubscribers,
  errorMessage,
}: AddTeamMemberDrawerContentProps) => {
  const { data: teamMembers, isLoading: isLoadingTeamMembers } =
    useGetEmployerTeamMembersQuery();

  const {
    editedJobSubscribers,
    editedJobSubscriberInvitations,
    toggleJobSubscriber,
    toggleJobSubscriberInvitation,
  } = useEditedJobSubscribers({
    jobSubscribers: jobSubscribersValue,
    jobSubscriberInvitations: jobSubscriberInvitationsValue,
  });
  const isMobile = useMediaQuery("sm");

  const handleSubscribersSave = useCallback(() => {
    changeSubscribers({
      jobSubscribersData: editedJobSubscribers,
      jobSubscriberInvitationsData: editedJobSubscriberInvitations,
    });
    handleClose();
  }, [
    editedJobSubscribers,
    editedJobSubscriberInvitations,
    changeSubscribers,
    handleClose,
  ]);

  return (
    <Stack className={styles.container}>
      <AddTeamMemberDrawerHeader
        onSave={handleSubscribersSave}
        onClose={handleClose}
      />
      {!isMobile && (
        <Box>
          <Divider color="light-grey" flexItem />
        </Box>
      )}
      <Stack
        sx={{
          flex: 1,
          overflow: "hidden",
          px: "0 !important", // override px set by parent
          gap: 3,
        }}
      >
        <Typography
          component="p"
          variant="paragraph"
          size={isMobile ? "small" : "medium"}
          sx={commonSxWithInlinePadding}
        >
          Invite team members to stay in the loop with updates and notifications
          for this job. If they're not on Braintrust yet, you can invite them to
          join your organization by entering their email addresses
        </Typography>

        <Box sx={commonSxWithInlinePadding}>
          <SubscribeTeamMembersAutocomplete
            teamMembers={teamMembers ?? []}
            toggleInvitation={(invitationEmail) =>
              toggleJobSubscriberInvitation({ email: invitationEmail })
            }
            toggleTeamMemberId={(teamMemberId) =>
              toggleJobSubscriber({ team_member_id: teamMemberId })
            }
            jobSubscribersValue={editedJobSubscribers}
            jobSubscriberInvitationsValue={editedJobSubscriberInvitations}
          />
          {errorMessage && (
            <Typography
              component="p"
              variant="paragraph"
              size="medium"
              error
              sx={{ mt: 2 }}
            >
              {errorMessage}
            </Typography>
          )}
        </Box>

        <Stack sx={{ overflow: "hidden" }}>
          <Typography
            component="p"
            variant="label"
            size="small"
            mb="10px"
            sx={commonSxWithInlinePadding}
          >
            Team members
          </Typography>
          {isLoadingTeamMembers && <TeamMembersLoader />}
          {!isLoadingTeamMembers && teamMembers && (
            <ScrollableContainer sx={commonSxWithInlinePadding}>
              <JobSubscribersList
                jobOwnerId={jobOwnerId}
                jobSubscribers={jobSubscribers}
                editedJobSubscribers={editedJobSubscribers}
                jobSubscriberInvitations={editedJobSubscriberInvitations}
                teamMembers={teamMembers}
                toggleJobSubscriber={toggleJobSubscriber}
                toggleJobSubscriberInvitation={toggleJobSubscriberInvitation}
              />
            </ScrollableContainer>
          )}
        </Stack>
      </Stack>
    </Stack>
  );
};

const commonSxWithInlinePadding = { px: { xs: "18px", md: 5 } } as const;

const TeamMembersLoader = () => {
  return (
    <Box sx={{ position: "relative", minHeight: "5rem" }}>
      <Loader centered />
    </Box>
  );
};
