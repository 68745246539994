import { Box } from "@hexocean/braintrust-ui-components";

import style from "./style.module.scss";

type OfferNavigationProps = {
  leftSocket?: React.ReactElement;
  centerSocket?: React.ReactElement;
  rightSocket?: React.ReactElement;
};

export const OfferNavigation = ({
  leftSocket,
  centerSocket,
  rightSocket,
}: OfferNavigationProps) => {
  return (
    <Box className={style.navigationBar}>
      {leftSocket && <SocketContainer>{leftSocket}</SocketContainer>}
      {centerSocket}
      <SocketContainer>{rightSocket}</SocketContainer>
    </Box>
  );
};

const SocketContainer = ({ children }) => {
  return (
    <Box
      sx={{
        display: "inline-flex",
        whiteSpace: "nowrap",
        gap: 1,
      }}
    >
      {children}
    </Box>
  );
};
