import type { FC } from "react";
import PoweredByCoinbase from "@static/on-ramp/poweredByCoinbase.svg";

import type { BoxProps } from "@hexocean/braintrust-ui-components";
import { Box, Button, Typography } from "@hexocean/braintrust-ui-components";
import { useMediaQuery } from "@hexocean/braintrust-ui-components/hooks";
import { ArrowTopRightIcon } from "@hexocean/braintrust-ui-components/Icons";
import { RouterLink } from "@js/components/link";

import { INSIGHT_BTRST_COST } from "../../constants";

type PurchaseSummaryProps = {
  isLoadingCoinbase: boolean;
  isPurchasing: boolean;
  isSufficientBtrst: boolean;
  onPurchase: () => void;
  requiredTotalBtrst: number;
};

const PurchaseSummary: FC<PurchaseSummaryProps> = ({
  isLoadingCoinbase,
  isPurchasing,
  isSufficientBtrst,
  onPurchase,
  requiredTotalBtrst,
}) => {
  const isTablet = useMediaQuery("lg");

  const formattedRequiredBtrst =
    requiredTotalBtrst < 0 ? requiredTotalBtrst * -1 : requiredTotalBtrst;
  const totalBtrst = isSufficientBtrst
    ? INSIGHT_BTRST_COST
    : formattedRequiredBtrst;

  const breakDownItemProps: BoxProps = {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  };

  return (
    <Box
      py={isTablet ? 0 : 4}
      flex={isTablet ? 0 : 1}
      display={isTablet ? "initial" : "flex"}
      justifyContent={isTablet ? "initial" : "center"}
      alignItems={isTablet ? "initial" : "center"}
    >
      <Box
        display="flex"
        gap={isTablet ? 2 : 3}
        maxWidth={isTablet ? "initial" : "361px"}
        flexDirection="column"
      >
        <Box
          borderRadius={2}
          textAlign="center"
          sx={{
            "& img": {
              maxWidth: isTablet ? "361px" : "100%",
            },
          }}
        >
          <img
            src={`${SETTINGS.STATIC_URL}jobs/${
              isSufficientBtrst
                ? "job-insight.svg"
                : "job-insight-purchase-btrst.svg"
            }`}
            width="100%"
            height="auto"
            alt="job-insight-image"
          />
        </Box>
        <Typography component="h3" size="large">
          {isSufficientBtrst
            ? "Purchase Application Insights"
            : "Purchase BTRST to unlock Application Insight"}
        </Typography>
        <Box
          width="100%"
          display="flex"
          flexDirection="column"
          gap={1}
          borderTop="1px solid var(--soft-beige)"
          borderBottom="1px solid var(--soft-beige)"
          paddingY={1}
        >
          <Box {...breakDownItemProps}>
            <Typography component="p" variant="paragraph" size="medium">
              30 days of Application Insights
            </Typography>
            <Typography component="p" variant="paragraph" size="medium">
              {INSIGHT_BTRST_COST} BTRST
            </Typography>
          </Box>
          <Box {...breakDownItemProps}>
            <Typography
              component="p"
              variant="label"
              size="medium"
              fontWeight={500}
            >
              {isSufficientBtrst ? "Total" : "Total you need to buy"}
            </Typography>
            <Typography
              component="p"
              variant="label"
              size="medium"
              fontWeight={500}
            >
              {totalBtrst} BTRST
            </Typography>
          </Box>
        </Box>
        {!isSufficientBtrst && (
          <Box
            sx={{ backgroundColor: "var(--soft-blue)" }}
            borderRadius={2}
            padding={2}
          >
            <Typography component="p" variant="paragraph" size="small">
              We'll notify you when your BTRST is available. This could take up
              to 3 hours
            </Typography>
          </Box>
        )}
        <Box
          sx={{
            "& a": {
              color: "var(--link)",
              textDecoration: "none",
            },
          }}
        >
          <Typography component="p" variant="paragraph" size="small">
            By confirming payment you accept our{" "}
            <Typography
              component="a"
              size="small"
              href={SETTINGS.PRIVACY_DOC_URL}
              target="_blank"
              className="policy-field-link"
              rel="noreferrer"
              RouterLink={RouterLink}
            >
              Privacy Policy
            </Typography>
            , and{" "}
            <Typography
              component="a"
              size="small"
              href={SETTINGS.TERMS_DOC_URL}
              target="_blank"
              className="policy-field-link"
              rel="noreferrer"
              RouterLink={RouterLink}
            >
              Payments Terms & Conditions
            </Typography>
          </Typography>
        </Box>
        <Button
          disabled={isPurchasing || (isLoadingCoinbase && !isSufficientBtrst)}
          variant="positive-2"
          shape="squared"
          sx={{ padding: "15px 0 !important" }}
          onClick={onPurchase}
          {...(!isSufficientBtrst
            ? {
                endIcon: <ArrowTopRightIcon />,
              }
            : {})}
        >
          {isSufficientBtrst
            ? "Purchase Application Insights"
            : "Get started with Coinbase"}
        </Button>
        {!isSufficientBtrst && (
          <Box textAlign="center">
            <PoweredByCoinbase />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default PurchaseSummary;
