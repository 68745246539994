import { useEffect, useMemo } from "react";
import { SubmissionError } from "redux-form";
import _ from "underscore";

import { fetchCurrentUser } from "@js/apps/auth/actions";
import {
  fetchExternalSites,
  fetchFreelancerProfile,
  fetchFreelancerPublicProfile,
  updateFreelancerProfile,
} from "@js/apps/freelancer/actions";
import type { AccountInfoFormData } from "@js/apps/freelancer/forms/account-info";
import { useAppDispatch, useAppSelector } from "@js/hooks";
import type { Freelancer, FreelancerPublic } from "@js/types/freelancer";

import { useUpdateFreelancerCustomizationMutation } from "../../api";

export const useAccountInfo = (profile: Freelancer | FreelancerPublic) => {
  const dispatch = useAppDispatch();

  const externalSites = useAppSelector(
    (state) => state.freelancer.externalSites,
  );

  const [updateFreelancerCustomization] =
    useUpdateFreelancerCustomizationMutation();

  const initialSites = useMemo(() => {
    return externalSites?.map(({ id }) => {
      const externalProfile = profile?.external_profiles.find(
        (el) => el.site.id === id,
      );
      return externalProfile
        ? { new_site: id, ...externalProfile }
        : { new_site: id };
    }) as AccountInfoFormData["external_profiles"];
  }, [externalSites, profile?.external_profiles]);

  useEffect(() => {
    if (!externalSites?.length) {
      dispatch(fetchExternalSites());
    }
  }, [dispatch, externalSites]);

  const initialValues = useMemo<AccountInfoFormData>(
    () => ({
      user: {
        first_name: profile?.user?.first_name,
        last_name: profile?.user?.last_name || "",
      },
      external_profiles: initialSites,
      full_location: profile?.location,
    }),
    [
      initialSites,
      profile?.location,
      profile?.user.first_name,
      profile?.user.last_name,
    ],
  );

  const onSubmit = async (values: AccountInfoFormData) => {
    await dispatch(
      updateFreelancerProfile(
        {
          user: {
            first_name: values.user.first_name,
            last_name: values.user.last_name,
          },
          // undefined won't overwrite full_location
          full_location:
            typeof values.full_location !== "string"
              ? values.full_location
              : undefined,
        },
        profile,
      ),
    ).then(() => {
      dispatch(fetchCurrentUser());
    });

    await updateFreelancerCustomization({
      id: profile.id,
      data: {
        external_profiles: values?.external_profiles?.map((site) =>
          !!site?.public_url ? site : null,
        ),
        user: _.omit(profile.user, "introduction", "introduction_headline"),
      },
    })
      .unwrap()
      .catch((error) => {
        throw new SubmissionError(error.data);
      });
  };

  const onSubmitSuccess = () => {
    dispatch(fetchFreelancerProfile(profile.id));
    dispatch(fetchFreelancerPublicProfile(profile.id));
  };

  return {
    formConfig: {
      onSubmit,
      onSubmitSuccess,
      initialValues,
      enableReinitialize: true,
    },
  };
};
