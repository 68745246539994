import React from "react";

import { Box, useButtonSelect } from "@hexocean/braintrust-ui-components";
import { CustomOptionTileButton } from "@js/components/option-tile-buttons";

const OPTIONS = [
  { label: "Yes", value: true },
  { label: "No", value: false },
];

export const BooleanTileButton = ({
  value,
  onChange,
  options = OPTIONS,
  revertBooleans = false,
}) => {
  const { isSelected, handleChange, itemA11yProps, containerA11yProps } =
    useButtonSelect({
      value,
      onChange,
    });

  const _options = getOptions(revertBooleans, options);

  return (
    <Box display="flex" gap={1} {...containerA11yProps}>
      {_options.map((option, index) => {
        const _isSelected = isSelected(option);

        const commonProps = {
          key: index,
          ...itemA11yProps(_isSelected),
          sx: {
            minWidth: "72px !important",
            width: "fit-content !important",
            height: "72px !important",
          },
          handleChange,
        };

        return (
          // key prop included in commonProps
          // eslint-disable-next-line react/jsx-key
          <CustomOptionTileButton
            {...commonProps}
            variant="white-violet"
            className="button-shadow"
            option={option}
            isSelected={_isSelected}
          />
        );
      })}
    </Box>
  );
};

const getOptions = (revertBooleans, options) => {
  if (!revertBooleans) return options;

  return options.map((option) => ({
    label: option.label,
    value: !option.value,
  }));
};
