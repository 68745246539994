import React, { useMemo } from "react";
import { Field, reduxForm } from "redux-form";

import { Loader, Typography } from "@hexocean/braintrust-ui-components";
import { Form } from "@js/forms/components/form";
import type { InjectedFormProps } from "@js/forms/fields";
import { RadioListField } from "@js/forms/fields";
import type { PaymentMethod } from "@js/types/payments";

import { PaymentMethodInline } from "../../components/payment-method-inline";

export const PAY_FORM_ID = "pay";

type PayFormComponentProps = {
  paymentMethods: PaymentMethod[];
  fetchingPaymentMethods: boolean;
};

export type PayFormValues = {
  paymentMethod: PaymentMethod;
} & Record<string, unknown>;

type PayFormContentProps = PayFormComponentProps &
  InjectedFormProps<PayFormValues, PayFormComponentProps, string>;

const PayFormContent = (props: PayFormContentProps) => {
  const { paymentMethods, submit, error, submitting, fetchingPaymentMethods } =
    props;

  const filteredPaymentMethods = useMemo(() => {
    return paymentMethods.filter(
      ({ can_be_charged: canBeCharged }) => canBeCharged,
    );
  }, [paymentMethods]);

  const paymentMethodOptions = useMemo(() => {
    return filteredPaymentMethods.map((paymentMethod) => ({
      value: paymentMethod,
      label: <PaymentMethodInline paymentMethod={paymentMethod} />,
    }));
  }, [filteredPaymentMethods]);

  const showNoPaymentMethodsText =
    !fetchingPaymentMethods && !filteredPaymentMethods.length;

  const showChoosePaymentMethodsText =
    !fetchingPaymentMethods && !!filteredPaymentMethods.length;

  return (
    <Form
      onSubmit={submitting ? null : submit}
      className="pay-form"
      error={error}
    >
      {fetchingPaymentMethods && <Loader />}
      {showNoPaymentMethodsText && (
        <Typography component="p" className="pay-form-message">
          No payment methods defined. Please add one first.
        </Typography>
      )}
      {showChoosePaymentMethodsText && (
        <Typography component="p" className="pay-form-message">
          Please choose payment method from the list below:
        </Typography>
      )}
      <Field
        name="paymentMethod"
        label="Payment method"
        component={RadioListField}
        options={paymentMethodOptions}
      />
    </Form>
  );
};

export const PayFormComponent = reduxForm<PayFormValues, PayFormComponentProps>(
  {
    form: PAY_FORM_ID,
    enableReinitialize: true,
    validate: (values) => {
      const errors: Record<string, string> = {};

      if (!values.paymentMethod) {
        errors.paymentMethod = "Please choose payment method.";
      }

      return errors;
    },
  },
)(PayFormContent);
