import { useMemo } from "react";

import { AddIcon, MessageIcon } from "@hexocean/braintrust-ui-components/Icons";
import { useUser } from "@js/apps/common/hooks";

import type { TeamMemberInvitationsAutocompleteProps } from "../team-member-invitations-autocomplete";
import {
  TeamMemberInvitationsActionButton,
  TeamMemberInvitationsAutocomplete,
} from "../team-member-invitations-autocomplete";

type ShareWithTeamMemberInvitationsAutocompleteProps = Pick<
  TeamMemberInvitationsAutocompleteProps,
  | "selectedInvitations"
  | "selectedTeamMemberIds"
  | "teamMembers"
  | "toggleInvitation"
  | "toggleTeamMemberId"
>;

export const ShareWithTeamMemberInvitationsAutocomplete = ({
  teamMembers,
  ...rest
}: ShareWithTeamMemberInvitationsAutocompleteProps) => {
  const user = useUser();
  const teamMembersWithoutCurrentUser = useMemo(() => {
    return teamMembers.filter((teamMember) => teamMember.id !== user?.id);
  }, [teamMembers, user]);

  return (
    <TeamMemberInvitationsAutocomplete
      {...rest}
      teamMembers={teamMembersWithoutCurrentUser}
      memberActionButton={<ShareWithTeamMemberInvitationsMemberButton />}
      invitationActionButton={<ShareWithTeamMemberInvitationsInviteButton />}
    />
  );
};

const ShareWithTeamMemberInvitationsInviteButton = () => {
  return <TeamMemberInvitationsActionButton label="Share" Icon={MessageIcon} />;
};

const ShareWithTeamMemberInvitationsMemberButton = () => {
  return <TeamMemberInvitationsActionButton label="Share" Icon={AddIcon} />;
};
