import { Field } from "redux-form";

import { Box } from "@hexocean/braintrust-ui-components";
import { createFiltersFormAndHook } from "@js/apps/filters";
import { EmployerInvoicePayeeSearchComboboxField } from "@js/components/autocomplete-new/combobox/employer-invoices-payee";
import { DateField, DollarNumberField, TextField } from "@js/forms/fields";

import { InvoiceStatusField, ProjectField } from "../../fields";
import type {
  EmployerListingCommonFilters,
  EmployerListingFormValues,
  EmployerListingParamsValues,
} from "../../types";
import {
  mapParamsToValuesEmployerListingFilters,
  mapValuesToParamsEmployerListingFilters,
} from "../../utils";

import styles from "./style.module.scss";

const EMPLOYER_LISTING_FILTERS_FORM_ID = "EMPLOYER_LISTING_FILTERS_FORM";

const CommonDefault: EmployerListingCommonFilters = {
  page: 1,
  date_after: undefined,
  date_before: undefined,
  status: undefined,
  number: undefined,
  amount: undefined,
  ordering: "-created",
  job: undefined,
};

export const [EmployerListingFiltersFormInstance, useEmployerListingParams] =
  createFiltersFormAndHook<
    EmployerListingFormValues,
    EmployerListingParamsValues
  >({
    submitOnChange: true,
    submitOnChangeFields: ["ordering"],
    defaultFormValues: {
      ...CommonDefault,
      payee_name: undefined,
    },
    defaultParamsValues: {
      ...CommonDefault,
      freelancer: undefined,
      fee_invoices_only: undefined,
    },
    mapValuesToParams: mapValuesToParamsEmployerListingFilters,
    mapParamsToValues: mapParamsToValuesEmployerListingFilters,
  });

type EmployerListingFiltersFormProps = {
  children: React.ReactNode;
};

export const EmployerListingFiltersForm = ({
  children,
}: EmployerListingFiltersFormProps) => {
  return (
    <EmployerListingFiltersFormInstance form={EMPLOYER_LISTING_FILTERS_FORM_ID}>
      <Box className={styles.invoiceFiltersFields}>
        <Box className={styles.invoiceFiltersFieldsField}>
          <Field
            name="date_after"
            id="startDate"
            component={DateField}
            label="Start Date"
          />
        </Box>
        <Box className={styles.invoiceFiltersFieldsField}>
          <Field
            name="date_before"
            id="endDate"
            component={DateField}
            label="End Date"
          />
        </Box>
        <Box className={styles.invoiceFiltersFieldsField}>
          <Field name="status" component={InvoiceStatusField} />
        </Box>
        <Box className={styles.invoiceFiltersFieldsField}>
          <Field
            name="payee_name"
            component={EmployerInvoicePayeeSearchComboboxField}
            format={null}
          />
        </Box>
        <Box className={styles.invoiceFiltersFieldsField}>
          <Field
            name="number"
            id="number"
            component={TextField}
            label="Invoice Number"
          />
        </Box>
        <Box className={styles.invoiceFiltersFieldsField}>
          <Field
            name="amount"
            id="amount"
            placeholder="0.00"
            component={DollarNumberField}
            label="Invoice Amount"
          />
        </Box>
        <Box className={styles.invoiceFiltersFieldsField}>
          <Field name="job" component={ProjectField} />
        </Box>
      </Box>
      {children}
    </EmployerListingFiltersFormInstance>
  );
};
