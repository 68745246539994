import _ from "underscore";

import type { IFreelancerJobToIssueInvoice } from "@js/apps/invoices/api";
import type { FreelancerInvoice } from "@js/types/invoices";

import type { InvoiceFormData } from "../../forms/add-invoice/types";

type BaseInvoiceInitialValues = {
  suggestedInvoiceNumber: InvoiceFormData["number"];
  jobIdParam: number;
  jobSelectList: IFreelancerJobToIssueInvoice[];
  issueDate: string;
};

type GetAddInvoiceInitialValues = BaseInvoiceInitialValues & {
  idOfInvoiceToCopy: string;
  invoiceToCopy?: FreelancerInvoice;
};

type GetInitialValuesForNewInvoiceParams = BaseInvoiceInitialValues & {
  new_items?: InvoiceFormData["new_items"];
  attachment_ids?: number[];
  notes_to_receipt?: InvoiceFormData["notes_to_receipt"];
};

export const isPONumberRequired = (values, initialInvoiceData) => {
  return !!initialInvoiceData?.po_number_required_since
    ? !!(values.new_items || []).some((item) => item && !item.po_number)
    : false;
};

export const getAddInvoiceInitialValues = ({
  idOfInvoiceToCopy,
  invoiceToCopy,
  suggestedInvoiceNumber,
  jobIdParam,
  jobSelectList,
  issueDate,
}: GetAddInvoiceInitialValues) => {
  if (idOfInvoiceToCopy && invoiceToCopy?.subject) {
    return getInitialValuesForCopiedInvoice({
      suggestedInvoiceNumber,
      invoiceToCopy,
      issueDate,
      jobSelectList,
    });
  }

  return getInitialValuesForNewInvoice({
    suggestedInvoiceNumber,
    jobIdParam,
    jobSelectList,
    issueDate,
  });
};
export const getInitialValuesForNewInvoice = ({
  suggestedInvoiceNumber,
  jobIdParam,
  jobSelectList,
  issueDate,
  new_items,
  attachment_ids,
  notes_to_receipt,
}: GetInitialValuesForNewInvoiceParams) => {
  const jobId = getJobId({
    jobIdParam,
    jobSelectList,
  });

  return {
    job: jobId,
    employer: jobSelectList.find(({ id }) => jobId === id)?.employer.id,
    issued_at: issueDate,
    number: (jobId && suggestedInvoiceNumber) || "",
    new_items,
    attachment_ids,
    notes_to_receipt,
  };
};

type GetInitialValuesForCopiedInvoiceParams = Pick<
  BaseInvoiceInitialValues,
  "issueDate" | "suggestedInvoiceNumber" | "jobSelectList"
> &
  Required<Pick<GetAddInvoiceInitialValues, "invoiceToCopy">>;

const getInitialValuesForCopiedInvoice = ({
  issueDate,
  invoiceToCopy,
  suggestedInvoiceNumber,
  jobSelectList,
}: GetInitialValuesForCopiedInvoiceParams) => {
  const isJobOnList = jobSelectList.some(
    ({ id }) => invoiceToCopy.subject.id === id,
  );

  return {
    issued_at: issueDate,
    number: suggestedInvoiceNumber || invoiceToCopy.number,
    job: isJobOnList ? invoiceToCopy.subject.id : undefined,
    employer: isJobOnList ? invoiceToCopy.employer.id : undefined,
    new_items: (invoiceToCopy.items || []).map((item) =>
      _.pick(
        item,
        "description",
        "unit_price",
        "tax_rate",
        "tax_name",
        "quantity",
        "po_number",
      ),
    ),
  };
};

type GetJobIdParams = Pick<
  BaseInvoiceInitialValues,
  "jobIdParam" | "jobSelectList"
>;

const getJobId = ({ jobIdParam, jobSelectList }: GetJobIdParams) => {
  // id specified in query param or chosen in form -- invoice for specific job
  if (jobIdParam) {
    return Number(jobIdParam);
  }

  // user can't choose, so we set only option for better UX
  const isOnlyOneJobToSelect = jobSelectList?.length === 1;
  if (isOnlyOneJobToSelect) {
    return jobSelectList[0].id;
  }

  // user needs to choose by himself
  return undefined;
};
