import { createAction } from "@reduxjs/toolkit";

import { Events } from "@js/services/analytics";

import type {
  EmployerReferralsOrderByType,
  ReferType,
  TalentReferralsOrderByType,
  UserReferralType,
} from "./types";

const clientReferralCopyLinkClicked = createAction(
  Events.CLIENT_REFERRAL_COPY_LINK,
);

const clientReferralEmailLinkClicked = createAction(
  Events.CLIENT_REFERRAL_EMAIL_LINK,
);

const clientReferralLinkedInLinkClicked = createAction(
  Events.CLIENT_REFERRAL_LINKEDIN_LINK,
);

const clientReferralTwitterLinkClicked = createAction(
  Events.CLIENT_REFERRAL_TWITTER_LINK,
);

const talentReferralCopyLinkClicked = createAction(
  Events.TALENT_REFERRAL_COPY_LINK,
);

const talentReferralEmailLinkClicked = createAction(
  Events.TALENT_REFERRAL_EMAIL_LINK,
);

const talentReferralLinkedInLinkClicked = createAction(
  Events.TALENT_REFERRAL_LINKEDIN_LINK,
);

const talentReferralTwitterLinkClicked = createAction(
  Events.TALENT_REFERRAL_TWITTER_LINK,
);

const clientReferralSortOrderClicked = createAction<{
  sort_filter: EmployerReferralsOrderByType;
}>(Events.REFERRAL_CLIENT_SORT_ORDER);

const talentReferralSortOrderClicked = createAction<{
  sort_filter: TalentReferralsOrderByType;
}>(Events.REFERRAL_TALENT_SORT_ORDER);

const clientReferralCSVClicked = createAction(Events.CLICK_REFERRAL_CLIENT_CSV);

const talentReferralCSVClicked = createAction(Events.CLICK_REFERRAL_TALENT_CSV);

export type ReferralActionTypes =
  | ReturnType<typeof clientReferralCopyLinkClicked>["type"]
  | ReturnType<typeof clientReferralEmailLinkClicked>["type"]
  | ReturnType<typeof clientReferralLinkedInLinkClicked>["type"]
  | ReturnType<typeof clientReferralTwitterLinkClicked>["type"]
  | ReturnType<typeof clientReferralSortOrderClicked>["type"]
  | ReturnType<typeof clientReferralCSVClicked>["type"]
  | ReturnType<typeof talentReferralCopyLinkClicked>["type"]
  | ReturnType<typeof talentReferralEmailLinkClicked>["type"]
  | ReturnType<typeof talentReferralLinkedInLinkClicked>["type"]
  | ReturnType<typeof talentReferralTwitterLinkClicked>["type"]
  | ReturnType<typeof talentReferralSortOrderClicked>["type"]
  | ReturnType<typeof talentReferralCSVClicked>["type"];

export const clickCopyLink = (type: ReferType) => {
  if (type === "job") return () => {};

  if (type === "client") return clientReferralCopyLinkClicked();

  if (type === "talent") return talentReferralCopyLinkClicked();
};

export const clickEmailLink = (type: ReferType) => {
  if (type === "job") return () => {};

  if (type === "client") return clientReferralEmailLinkClicked();

  if (type === "talent") return talentReferralEmailLinkClicked();
};

export const clickLinkedInLink = (type: ReferType) => {
  if (type === "job") return () => {};

  if (type === "client") return clientReferralLinkedInLinkClicked();

  if (type === "talent") return talentReferralLinkedInLinkClicked();
};

export const clickTwitterLink = (type: ReferType) => {
  if (type === "job") return () => {};

  if (type === "client") return clientReferralTwitterLinkClicked();

  if (type === "talent") return talentReferralTwitterLinkClicked();
};

export const clickSortReferrals = (type: UserReferralType, payload) => {
  if (type === "client") return clientReferralSortOrderClicked(payload);

  if (type === "talent") return talentReferralSortOrderClicked(payload);
};

export const clickReferralsCSV = (type: UserReferralType) => {
  if (type === "client") return clientReferralCSVClicked();

  if (type === "talent") return talentReferralCSVClicked();
};
