import { useState } from "react";
import { change, Field, reduxForm } from "redux-form";
import classNames from "classnames";

import {
  Button,
  Grid,
  Popover,
  Typography,
} from "@hexocean/braintrust-ui-components";
import { NumberField } from "@js/forms/fields";
import { StandardTextField } from "@js/forms/fields";
import { maxLength, minValue } from "@js/forms/validators";

const minValueValidator = minValue(0);
const maxTaxNameLength = maxLength(64);
const maxTaxRateLength = maxLength(10);

const TaxPopover = ({
  buttonText,
  reset,
  submit,
  submitting,
  taxRate,
  valid,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (e) => setAnchorEl(e.currentTarget);

  const handleClose = () => setAnchorEl(null);

  const handleApplyClick = () => {
    if (valid && !submitting) {
      submit();
      handleClose();
    }
  };

  const getButtonLabel = () => {
    return buttonText || taxRate
      ? `+${buttonText} ${taxRate && `${taxRate}%`}`
      : "Add Tax";
  };

  return (
    <>
      <Popover
        anchor={
          <button
            type="button"
            aria-label={taxRate ? `tax rate: ${getButtonLabel()}` : "Add tax"}
            className={classNames("link tax-popover-button btn-reset", {
              "has-tax": taxRate,
            })}
            onClick={handleClick}
          >
            {getButtonLabel()}
          </button>
        }
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        TransitionProps={{
          onEnter: reset,
        }}
      >
        <div className="tax-popover-content">
          <Typography
            component="h1"
            variant="title"
            fontWeight={400}
            size="small"
            mb={1}
          >
            Add Tax
          </Typography>
          <Grid container spacing={3} alignItems="end">
            <Grid item xs={4}>
              <Field
                variant="standard"
                label="Rate"
                validate={[minValueValidator, maxTaxRateLength]}
                id="tax_rate"
                name="tax_rate"
                component={NumberField}
                size="small"
              />
            </Grid>
            <Grid item xs={8}>
              <Field
                variant="standard"
                label="Tax Name"
                size="small"
                id="tax_name"
                name="tax_name"
                validate={[maxTaxNameLength]}
                component={StandardTextField}
              />
            </Grid>
          </Grid>
          <div className="tax-popover-actions">
            <Button variant="primary" onClick={handleApplyClick}>
              Apply
            </Button>
          </div>
        </div>
      </Popover>
    </>
  );
};

export const TaxPopoverField = reduxForm({
  // unique form id is provided directly by props
  enableReinitialize: true,
  onSubmit: (values, dispatch, props) => {
    // we need to refer to `props.formName`; props.change seems to refer to different form
    dispatch(change(props.formName, `${props.item}.tax_name`, values.tax_name));
    dispatch(change(props.formName, `${props.item}.tax_rate`, values.tax_rate));
  },
})(TaxPopover);
