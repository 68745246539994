import { Fragment } from "react";

import {
  Box,
  Divider,
  List,
  ListItem,
  Typography,
} from "@hexocean/braintrust-ui-components";
import { copyReferralTemplate } from "@js/apps/dashboard/components/templates/actions";
import { ButtonCopyToClipboard } from "@js/components/buttons/button-copy-to-clipboard";
import { Sanitize } from "@js/components/sanitize";
import { useAppDispatch } from "@js/hooks";
import { removeHTMLTags } from "@js/utils";

import type { TemplateEntry } from "../../types";

import styles from "./templates-list.module.scss";

export type TemplatesListProps = {
  items: TemplateEntry[];
};

export const TemplatesList = ({ items }: TemplatesListProps) => {
  const dispatch = useAppDispatch();
  const onCopied = (templateContent: string) => {
    dispatch(copyReferralTemplate({ template_content: templateContent }));
  };

  return (
    <List className={styles.list}>
      {items.map(({ id, content }) => {
        return (
          <Fragment key={id}>
            <ListItem className={styles.listItem}>
              <Typography component="span" variant="paragraph" size="medium">
                <Sanitize input={content} className={styles.sanitizedText} />
              </Typography>
              <Box className={styles.buttonWrap}>
                <ButtonCopyToClipboard
                  variant="primary"
                  text={removeHTMLTags(content)}
                  onCopied={() => onCopied(content)}
                >
                  Copy text
                </ButtonCopyToClipboard>
              </Box>
            </ListItem>
            <Divider component="li" color="primary" />
          </Fragment>
        );
      })}
    </List>
  );
};
