import React from "react";
import AvatarBackground from "@static/freelancer/talent-image-card-background.svg";

import {
  Avatar,
  Box,
  Stack,
  Typography,
} from "@hexocean/braintrust-ui-components";
import { LocationPinSmallIcon } from "@hexocean/braintrust-ui-components/Icons";
import { ApprovedBadge } from "@js/apps/freelancer/components/approved-badge";
import { RolesTag } from "@js/apps/jobs/components/job-type-and-role-tags";
import { RouterLink } from "@js/components/link";

import type { SimplePostTalentLinkAttachment } from "../post-attachments";

export const TalentProfileCard = ({
  user,
  location,
  role,
  years_experience,
  url,
  approved,
  rate,
}: SimplePostTalentLinkAttachment) => {
  return (
    <Box
      className="pretty-link-card__container"
      component={RouterLink}
      to={url}
    >
      <div className="pretty-link-card__avatar-container">
        <AvatarBackground className="pretty-link-card__avatar-background" />
        <Avatar className="pretty-link-card__avatar" size="md" src={user} />
      </div>
      <div className="pretty-link-card__introduction">
        <Stack direction="row" sx={{ alignItems: "center", gap: 1 }}>
          <Typography component="h4" fontWeight={500} ellipsis>
            {user.public_name}
          </Typography>
          {approved && <ApprovedBadge iconStyles={{ strokeWidth: "2px" }} />}
        </Stack>
        {user.introduction_headline && (
          <Typography component="p" size="small" ellipsis>
            {user.introduction_headline}
          </Typography>
        )}
      </div>
      <Box display="flex" alignItems="center" px={3}>
        {!!rate && (
          <Typography component="p" size="small" mr={1}>
            ${rate}/hr
          </Typography>
        )}
        {!!location && (
          <div className="pretty-link-card__location">
            <LocationPinSmallIcon className="pretty-link-card__location-icon" />
            <Typography component="span" size="small" title={location} ellipsis>
              {location}
            </Typography>
          </div>
        )}
      </Box>
      {!!role && (
        <Box className="pretty-link-card__role" px={3} maxWidth="fit-content">
          <RolesTag
            className="role-tag"
            role={role}
            experience={years_experience}
          />
        </Box>
      )}
    </Box>
  );
};
