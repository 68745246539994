import React from "react";
import type { AbstractConnector } from "@web3-react/abstract-connector";
import cs from "classnames";

import { Button, Loader, Typography } from "@hexocean/braintrust-ui-components";

import { injected } from "../../connectors";
import { SUPPORTED_WALLETS } from "../../constants";

import Option from "./option";

const ERROR_CODE_DOUBLE_TRY_WALLECT_ACTIVATION = -32002;

const PendingView = ({
  connector,
  error = false,
  errorCode,
  setPendingError,
  tryActivation,
}: {
  connector?: AbstractConnector;
  error?: boolean;
  errorCode?: number;
  setPendingError: (error: boolean) => void;
  tryActivation: (connector: AbstractConnector) => void;
}) => {
  const isMetamask = window?.ethereum?.isMetaMask;

  return (
    <>
      <Typography
        component="div"
        size="large"
        pb={4}
        className={cs("vote-wallet-state", {
          "vote-wallet-state--error": error,
        })}
      >
        {error ? (
          errorCode === ERROR_CODE_DOUBLE_TRY_WALLECT_ACTIVATION ? (
            <>Please finish wallet connection in your MetaMask app.</>
          ) : (
            <>
              Error connecting.
              <Button
                variant="secondary"
                style={{ marginLeft: "auto" }}
                onClick={() => {
                  setPendingError(false);
                  if (connector) tryActivation(connector);
                }}
              >
                Try Again
              </Button>
            </>
          )
        ) : (
          <>
            <Loader className="vote-wallet-state__loader" />
            Initializing...
          </>
        )}
      </Typography>
      {Object.keys(SUPPORTED_WALLETS).map((key) => {
        const option = SUPPORTED_WALLETS[key];
        if (option.connector === connector) {
          if (option.connector === injected) {
            if (isMetamask && option.name !== "MetaMask") {
              return null;
            }
            if (!isMetamask && option.name === "MetaMask") {
              return null;
            }
          }
          return (
            <Option
              id={`connect-${key}`}
              key={key}
              header={option.name}
              subheader={option.description}
              icon={`${SETTINGS.STATIC_URL}vote/${option.iconName}`}
            />
          );
        }
        return null;
      })}
    </>
  );
};
export default PendingView;
