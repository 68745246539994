import React from "react";
import { Field } from "redux-form";

import {
  Button,
  IconButton,
  Tooltip,
  Typography,
} from "@hexocean/braintrust-ui-components";
import { InfoIcon } from "@hexocean/braintrust-ui-components/Icons";
import { createFormInstance } from "@js/forms/components";
import { RatingField } from "@js/forms/fields/rating";

import type { AddInternalReviewPayload, ReviewsProps } from "../../api";
import type { InternalRatingProfile } from "../../components";
import { CommentTextField } from "../fields/comment-field";

const INTERNAL_RATING_FORM_ID = "internalRatingForm";

type FormData = {
  text: string;
  rating: string | null;
};

type InternalRatingFormProps = {
  talent: InternalRatingProfile;
  onSubmit: (
    values: Omit<AddInternalReviewPayload, "freelancer">,
  ) => Promise<{ data: ReviewsProps }>;
  onSubmitSuccess: (_values: any, _dispatch: any, props: any) => void;
};

const FormInstance = createFormInstance<FormData, unknown>(
  INTERNAL_RATING_FORM_ID,
);

export const InternalRatingForm = ({
  talent,
  onSubmit,
  onSubmitSuccess,
}: InternalRatingFormProps) => {
  const initialValues = React.useMemo(() => {
    return {
      text: "",
      rating: null,
    };
  }, []);

  return (
    <FormInstance
      onSubmit={onSubmit}
      onSubmitSuccess={onSubmitSuccess}
      initialValues={initialValues}
    >
      <div className="internal-rating-form">
        <Typography
          component="p"
          variant="label"
          className="internal-rating-form__header"
        >
          Rate {talent.user.first_name} on a scale from 1 to 5.
          <Tooltip
            placement="top"
            title="5 is the best, you can send this person anywhere anytime.
                    3 is  a great candidate but with a particular skill and 1 this candidate is good, but has a few issues."
            color="var(--black)"
          >
            <IconButton
              variant="transparent"
              size="x-small"
              aria-label="rate scale information"
            >
              <InfoIcon />
            </IconButton>
          </Tooltip>
        </Typography>

        <Field
          id="rating"
          name="rating"
          variant="stars"
          iconStyle={{ height: "44px", width: "44px", marginLeft: "-5px" }}
          containerProps={{ marginLeft: "5px" }}
          component={RatingField}
        />

        <Field
          id="text"
          name="text"
          placeholder="Let your team know your thoughts..."
          variant="outlined"
          fullWidth
          className="internal-rating-form__note-field"
          component={CommentTextField}
          avatarProps={{
            className: "internal-rating-form__note-field__avatar",
          }}
          showSubmitButton="always"
          submitButton={
            <Button
              variant="dark-grey"
              className="internal-rating-form__submit-btn"
              size="x-small"
              shape="squared"
              type="submit"
            >
              Post
            </Button>
          }
        />
      </div>
    </FormInstance>
  );
};
