import type { TalentMixedLocation } from "@js/apps/freelancer/types";
import type { CustomLocation, PopularLocation } from "@js/types/common";
import { typeGuard } from "@js/utils";

type IsOnlyLocationResult = {
  isOnlyLocation: boolean;
  location: TalentMixedLocation | null;
};

export const isOnlyLocation = (
  data: Array<TalentMixedLocation | string>,
): IsOnlyLocationResult => {
  if (data.length) {
    const singleLocationData = data[data.length - 1] as TalentMixedLocation;

    const isButtonCustomLocation = typeGuard<unknown, PopularLocation>(
      singleLocationData,
      "custom_location",
    );

    const isOptionCustomLocation = typeGuard<
      TalentMixedLocation,
      CustomLocation
    >(singleLocationData, "custom");

    if (isButtonCustomLocation) {
      return {
        isOnlyLocation: !!singleLocationData.custom_location?.includes("only"),
        location: singleLocationData,
      };
    }

    if (isOptionCustomLocation) {
      return {
        isOnlyLocation: singleLocationData.id.includes("only"),
        location: singleLocationData,
      };
    }
  }

  return {
    isOnlyLocation: false,
    location: null,
  };
};

export const filterSelectedAndCustomOptions = (
  option: TalentMixedLocation,
  inputValue: string,
) => {
  if (inputValue) {
    return !option.custom && !option.formatted_address;
  }

  // filter out custom options from buttons and selected custom options
  const isButtonOption = "custom_location" in option && !("custom" in option);
  const isCustomSelectedOption = option.custom && "formatted_address" in option;
  const isGooglePlaceButtonOption = option.custom_location === null;

  if (isGooglePlaceButtonOption) {
    return true;
  }

  if (isButtonOption || isCustomSelectedOption) {
    return false;
  }

  if ("session_token" in option || "formatted_address" in option) {
    return true;
  }

  return option.custom;
};

export const sortSelectedAndCustomOptions = (
  a: TalentMixedLocation,
  b: TalentMixedLocation,
) => {
  // sort options so selected are displayed at top
  if (a.formatted_address) {
    return -1;
  }

  if (b.formatted_address) {
    return 1;
  }

  return 0;
};

export const mapSelectedOptions = (option: TalentMixedLocation) => {
  // Mapping selected option to option format
  if ("session_token" in option || "formatted_address" in option) {
    return {
      id: option.id || option.place_id,
      name: option.formatted_address,
      disabled: false,
    };
  }

  return option;
};

export const filterDuplicatedOptions = (
  option: TalentMixedLocation,
  index: number,
  array: TalentMixedLocation[],
) => {
  return array.findIndex((item) => item.id === option.id) === index;
};

export const handleOptionDisabled = (
  option: TalentMixedLocation | string,
  data: TalentMixedLocation[],
) => {
  const optionId = typeof option === "string" ? option : option.id;

  if (data.length === 1) {
    const currentSelectedLocation = data[0];
    if (
      currentSelectedLocation?.id?.includes("only") ||
      currentSelectedLocation?.custom_location?.includes("only")
    ) {
      if (
        optionId === currentSelectedLocation?.id ||
        optionId === currentSelectedLocation?.custom_location
      ) {
        return false;
      }

      return true;
    }
  }
  return false;
};

export const getLocationName = (location: TalentMixedLocation): string => {
  return location.name || location.formatted_address || "";
};
