import { createContext, useContext } from "react";

import type { Freelancer } from "@js/types/freelancer";

import type { useProfileCompletion } from "../hooks/use-profile-completion";

export const ProfileCompletionSequenceContext = createContext<
  (ReturnType<typeof useProfileCompletion> & { profile: Freelancer }) | null
>(null);

export const useProfileCompletionSequenceContext = () => {
  const context = useContext(ProfileCompletionSequenceContext);

  if (!context) {
    throw new Error(
      "CompletionSequence.* component must be child of CompletionSequence. Did you forget to provide a value to  the Context? Or, did you try to render a child component outside of CompletionSequence parent?",
    );
  }

  return context;
};
