import React from "react";

import { PageHead } from "@js/components/page-head";

export const PageTitle = ({ children = "" }: { children: string }) => {
  const prefix = "Braintrust | ";
  const pageTitle = prefix + children.replace(prefix, "");

  return (
    <PageHead>
      <title>{pageTitle}</title>
    </PageHead>
  );
};
