import type { LocationToken } from "@js/apps/freelancer/types";
import type { TypedWrappedFieldProps } from "@js/forms/utils";

type ClearSessionTokenFieldFromRemovedLocation = {
  (parameters: {
    currentPlaceIds: string[];
    sessionTokenField: TypedWrappedFieldProps<string[] | null>;
    sessionTokensWithIds: LocationToken[];
  }): void;
};

const clearSessionTokenFieldFromRemovedLocation: ClearSessionTokenFieldFromRemovedLocation =
  ({ currentPlaceIds, sessionTokenField, sessionTokensWithIds }) => {
    let sessionTokensFromParams = sessionTokenField.input.value
      ? [...sessionTokenField.input.value]
      : [];

    const filteredSessionTokensWithIds = sessionTokensWithIds.filter(
      (sessionTokenWithId) => {
        return currentPlaceIds.includes(sessionTokenWithId.id);
      },
    );

    sessionTokensFromParams = filteredSessionTokensWithIds.map(
      (sessionTokenWithId) => sessionTokenWithId.session_token,
    );

    sessionTokenField.input.onChange(sessionTokensFromParams);
  };

export { clearSessionTokenFieldFromRemovedLocation };
