import { skipToken } from "@reduxjs/toolkit/query";

import { useUser } from "@js/apps/common/hooks";
import {
  useGetEmployerReviewsQuery,
  useGetTalentReviewsQuery,
} from "@js/apps/reviews/api";
import { useAppSelector } from "@js/hooks";
import { useCombinedQueryState } from "@js/hooks/combined-query-state";
import { assertUnreachable } from "@js/utils";

type UseReviewListProps = {
  reviewSubject: EnumType<typeof ENUMS.AccountType>;
  reviewSubjectId: number;
};

export const useReviewList = ({
  reviewSubject,
  reviewSubjectId,
}: UseReviewListProps) => {
  const profile = useAppSelector((state) => {
    if (reviewSubject === ENUMS.AccountType.EMPLOYER) {
      return state.employer.employerPublicProfile;
    }
    if (reviewSubject === ENUMS.AccountType.FREELANCER) {
      return state.freelancer.publicProfile;
    }
    assertUnreachable(reviewSubject);
  });

  const { data: talentReviews, ...talentReviewsQueryState } =
    useGetTalentReviewsQuery(
      reviewSubject === ENUMS.AccountType.FREELANCER && reviewSubjectId
        ? { id: reviewSubjectId, count: 0 }
        : skipToken,
    );

  const { data: employerReviews, ...employerReviewsQueryState } =
    useGetEmployerReviewsQuery(
      reviewSubject === ENUMS.AccountType.EMPLOYER && reviewSubjectId
        ? { id: reviewSubjectId, count: 0 }
        : skipToken,
    );

  const user = useUser();

  const { isLoading } = useCombinedQueryState([
    talentReviewsQueryState,
    employerReviewsQueryState,
  ]);

  const isOwnProfile =
    !!profile &&
    (reviewSubject === ENUMS.AccountType.EMPLOYER
      ? profile.id === user?.employer
      : profile.id === user?.freelancer);

  const reviewList = (employerReviews || talentReviews) ?? [];

  return {
    profile,
    loading: isLoading,
    reviewList,
    isOwnProfile,
  };
};
