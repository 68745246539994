import { Button } from "@hexocean/braintrust-ui-components";
import { Reactions } from "@js/apps/give-and-get-help/components/stickers-module";
import { StickersPopover } from "@js/apps/give-and-get-help/components/stickers-module/stickers-popover";
import { useInteractsSection } from "@js/apps/give-and-get-help/hooks";
import { useDisableSpecificPostActions } from "@js/apps/give-and-get-help/hooks/use-disable-specific-post-actions";
import type { PostComment } from "@js/types/give-and-get-help";

import { ReactButton } from "./react-button";

import styles from "./style.module.scss";

type CommentInteractsSectionProps = {
  commentData: PostComment;
  slotPosition: number;
  onReply: () => void;
  restrictTogglingReplies?: boolean;
  disableReply?: boolean;
};

export const CommentInteractsSection = ({
  commentData,
  slotPosition,
  onReply,
  restrictTogglingReplies = false,
}: CommentInteractsSectionProps) => {
  const { handleReactionSelect, handleReplyButtonClick, isDisabled, user } =
    useInteractsSection({
      postId: commentData.main_post_id,
      entityData: { ...commentData, slot_position: slotPosition },
      onReply,
      restrictTogglingReplies,
    });
  const disablePostActions = useDisableSpecificPostActions();

  return (
    <div>
      <div className={styles.interactsSection}>
        <StickersPopover
          disabled={Boolean(disablePostActions || !user?.is_verified)}
          onStickerSelect={handleReactionSelect}
          AnchorComponent={ReactButton}
        />

        <Button
          variant="transparent-blue"
          size="x-small"
          shape="squared"
          disabled={isDisabled}
          onClick={handleReplyButtonClick}
          sx={{ p: "6px !important", minWidth: "0px !important" }}
          disabledType="opacity"
        >
          Reply
        </Button>
      </div>

      {!!commentData.reactions.length && (
        <div className={styles.interactsSectionReactions}>
          <Reactions
            reactions={commentData.reactions}
            entityId={commentData.id}
            onReactionClick={handleReactionSelect}
            currentUser={user}
          />
        </div>
      )}
    </div>
  );
};
