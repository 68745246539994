import { useMemo, useRef, useState } from "react";
import axios from "axios";
import _ from "underscore";

import { Snackbar } from "@js/components/snackbar";
import { serializeArrayParams, serializeArrayParamsBy } from "@js/utils";

const placesServicesTypesMap = {
  cities: ["(cities)"],
  regions_without_sublocality: [
    "locality",
    "postal_code",
    "country",
    "administrative_area_level_1",
    "administrative_area_level_2",
  ],
} as const;

/** @description we limit types to make sure no one pass `[("regions")]`. Extend type if needed. */
export type PlacesServicesTypes = keyof typeof placesServicesTypesMap;

type AutocompletionRequest = Omit<
  google.maps.places.AutocompletionRequest,
  "types"
> & { types?: PlacesServicesTypes };
type AutocompleteResponse = google.maps.places.AutocompleteResponse;
export type AutocompletePrediction = google.maps.places.AutocompletePrediction;

type PlaceDetailsRequest = google.maps.places.PlaceDetailsRequest;
export type PlaceDetailsResponse = google.maps.places.PlaceResult;

type GetPlaceDetailsCallback = (
  placeResult: PlaceDetailsResponse | null,
) => void;

export const useGoogleMaps = () => {
  const [placePredictions, setPlacePredictions] = useState<
    AutocompletePrediction[]
  >([]);
  const [isPlacePredictionsLoading, setIsPlacePredictionsLoading] =
    useState(false);

  const getPlacePredictions = async ({
    input,
    types = "cities",
    ...options
  }: AutocompletionRequest) => {
    if (!input) {
      setIsPlacePredictionsLoading(false);
      setPlacePredictions([]);
      return;
    }

    const _options = {
      language: "en",
      types: placesServicesTypesMap[types],
      ...options,
    };

    setIsPlacePredictionsLoading(true);
    try {
      const response = await axios.get<AutocompleteResponse>(
        "/api/google_maps/place_autocomplete/",
        {
          params: { input, ..._options },
          paramsSerializer: (params) => serializeArrayParamsBy(params, "|"),
        },
      );
      setPlacePredictions(response.data.predictions);
      return response.data.predictions;
    } catch (e: any) {
      Snackbar.error(e);
    } finally {
      setIsPlacePredictionsLoading(false);
    }
  };

  const getPlacePredictionsDebounced = useMemo(() => {
    return _.debounce((v: AutocompletionRequest) => {
      getPlacePredictions(v);
    }, 600);
  }, []);

  const getPlaceDetailsAbortControllerRef = useRef(new AbortController());

  const getPlaceDetails = async (
    params: PlaceDetailsRequest,
    callback?: GetPlaceDetailsCallback,
  ) => {
    const place_id = params.placeId;
    const fields = params.fields || SETTINGS.GOOGLE_PLACES_DETAILS;
    const newParams = {
      ..._.omit(params, "placeId", "fields"),
      place_id,
      fields,
    };

    getPlaceDetailsAbortControllerRef.current = new AbortController();

    try {
      const response = await axios.get<{ result: PlaceDetailsResponse }>(
        "/api/google_maps/place_details/",
        {
          params: newParams,
          paramsSerializer: serializeArrayParams,
          signal: getPlaceDetailsAbortControllerRef.current.signal,
        },
      );
      if (callback) {
        callback(response.data.result);
      } else {
        return response.data.result;
      }
    } catch (e: any) {
      if (e?.code !== "ERR_CANCELED") {
        Snackbar.error(e);
      }
    }
  };

  return {
    placePredictions,
    getPlacePredictions: getPlacePredictionsDebounced,
    getPlaceDetails,
    getPlaceDetailsAbortControllerRef,
    getPlaceDetailsAbortController: getPlaceDetailsAbortControllerRef.current,
    isPlacePredictionsLoading,
  };
};
