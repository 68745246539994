import { Avatar, Badge, Box, Button } from "@hexocean/braintrust-ui-components";
import { AdminBadgeIcon } from "@hexocean/braintrust-ui-components/Icons";
import { ProfileCard } from "@js/apps/give-and-get-help/components/profile-card";
import { ProfileCardPopover } from "@js/apps/give-and-get-help/components/profile-card-popover";
import { RouterLink } from "@js/components/link";
import type { IPost } from "@js/types/give-and-get-help";

import { PostOrCommentDetails } from "..";

type PostDetailsWrapperProps = {
  handleUserNameClicked: () => void;
  shouldDisplayAdminBadge: boolean;
} & Pick<IPost, "freelancer" | "created">;

export const PostDetailsWrapper = ({
  freelancer,
  created,
  handleUserNameClicked,
  shouldDisplayAdminBadge,
}: PostDetailsWrapperProps) => {
  return (
    <Box className="post-details-wrapper">
      <ProfileCardPopover
        anchor={
          <Button
            className="post-wrapper__avatar"
            onClick={handleUserNameClicked}
            variant="transparent"
            RouterLink={RouterLink}
            to={`/talent/${freelancer.id}/`}
            target="_blank"
            sx={{ mr: 2 }}
          >
            <Badge
              badgeContent={
                shouldDisplayAdminBadge ? (
                  <AdminBadgeIcon data-testid="space-admin-badge" />
                ) : undefined
              }
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              overlap="circular"
            >
              <Avatar src={freelancer.user} size="md" color="dark-blue" />
            </Badge>
          </Button>
        }
        renderContent={({ updatePosition }) => (
          <ProfileCard
            talentId={freelancer.id}
            avatarData={freelancer.user}
            firstName={freelancer.user.first_name}
            onDataLoad={updatePosition}
          />
        )}
      />

      <PostOrCommentDetails
        firstName={freelancer.user.first_name}
        lastName={freelancer.user.last_name}
        role={freelancer.role}
        id={freelancer.id}
        avatarData={freelancer.user}
        introductionHeadline={freelancer.user.introduction_headline}
        created={created}
        variant="post"
        onUserNameClick={handleUserNameClicked}
        shouldDisplayAdminBadge={shouldDisplayAdminBadge}
      />
    </Box>
  );
};
