export const round = (value, step = 1.0) => {
  const inv = 1.0 / step;
  return Math.round(value * inv) / inv;
};

export const generateNumberList = (
  start,
  end,
  valueSuffix = "",
  labelSuffix = "",
  valuePrefix = "",
  labelPrefix = "",
) => {
  const arr: { value: string; label: string }[] = [];

  for (let i = start; i <= end; i += 1) {
    arr.push({
      value: valuePrefix + i + valueSuffix,
      label: labelPrefix + i + labelSuffix,
    });
  }

  return arr;
};
export const normalizeNumberInput = (input: string): string => {
  const hasDot = input.includes(".");
  const hasComma = input.includes(",");
  const hasDotAndComma = hasDot && hasComma;

  if (hasDotAndComma) {
    return input.replaceAll(",", "");
  }

  //check if it has more than one comma
  if (hasComma) {
    const commaCount = input.split(",").length - 1;
    if (commaCount > 1) {
      return input.replaceAll(",", "");
    }
  }

  return input.replaceAll(",", ".");
};

export const castToInteger = (
  value: string | number | null | undefined,
): number | undefined =>
  value && !isNaN(+value) && Number.isInteger(+value)
    ? Number(value)
    : undefined;

export const notLessThanZero = (number: number): number =>
  number >= 0 ? number : 0;
