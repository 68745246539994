import { Box, Chip } from "@hexocean/braintrust-ui-components";
import { StarIcon } from "@hexocean/braintrust-ui-components/Icons";
import type {
  ProfileCardTopSkillProps,
  ProfileCardTopSkillsProps,
} from "@js/apps/give-and-get-help/components/profile-card/profile-card.types";

export const ProfileCardTopSkills = ({
  topSkills,
}: ProfileCardTopSkillsProps) => {
  const _topSkills = [...topSkills];
  const sortedTopSkills = _topSkills.sort((a, b) => a.order - b.order);

  return (
    <Box display="flex" flexWrap="wrap" gap={1}>
      {sortedTopSkills.map((topSkill) => (
        <ProfileCardTopSkill key={topSkill.skill.name} topSkill={topSkill} />
      ))}
    </Box>
  );
};

const ProfileCardTopSkill = ({ topSkill }: ProfileCardTopSkillProps) => {
  return (
    <Chip
      className="profile-card__skill-chip"
      key={topSkill.skill.name}
      variant="rounded"
      label={topSkill.skill.name}
      LabelProps={{
        component: "span",
        variant: "label",
      }}
      icon={<StarIcon style={{ fontSize: "16px" }} />}
      color="positive-green"
      size="default"
      typographySize="small"
    />
  );
};
