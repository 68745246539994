import {
  Box,
  Checkbox,
  Stack,
  Typography,
} from "@hexocean/braintrust-ui-components";

import styles from "../style.module.scss";

type JobHeaderProps = {
  toggleSelectAllJobs: () => void;
  allJobsSelected: boolean;
  someJobsSelected: boolean;
  jobsCount: number;
};

export const JobsHeader = ({
  toggleSelectAllJobs,
  allJobsSelected,
  someJobsSelected,
  jobsCount,
}: JobHeaderProps) => {
  return (
    <Stack className={styles.jobHeaderContainer}>
      <Stack className={styles.jobHeader}>
        <Box display="flex" alignItems="center">
          <Checkbox
            onChange={toggleSelectAllJobs}
            checked={allJobsSelected}
            noMargin
            indeterminate={someJobsSelected}
            checkedColor="yellow"
            aria-label="Select all jobs"
            sx={{
              // Make sure first head column is aligned with content column
              marginLeft: `${jobsCount.toString().length * 10}px`,
            }}
          />
          <Typography component="span" size="small" fontWeight={500}>
            Job title
          </Typography>
        </Box>
        <Box
          display="flex"
          alignItems="center"
          sx={{ width: { xs: "66px", sm: "126px" } }}
        >
          <Typography component="span" size="small" fontWeight={500}>
            ID
          </Typography>
        </Box>
      </Stack>
    </Stack>
  );
};
