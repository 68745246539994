import { useState } from "react";
import type { ConfigProps } from "redux-form";
import { Field } from "redux-form";

import { Box, Button, Typography } from "@hexocean/braintrust-ui-components";
import { GraterOrEqualZeroIntegerNumberFormat } from "@hexocean/braintrust-ui-components";
import { CERTIFICATES_FORM_ID } from "@js/apps/freelancer/forms/certificates-form/constants";
import { ADD_ANOTHER_CERTIFICATE } from "@js/apps/freelancer/hooks/edit-certificates/constants";
import { CommonConfirmationModal } from "@js/components/modal";
import { createFormInstance } from "@js/forms/components";
import { CertificatesField, NumberField, TextField } from "@js/forms/fields";
import { noFutureYearValidator, required } from "@js/forms/validators";

export type FormData = {
  new_certificate: string;
  issuer: string;
  year_of_issuing: number;
};

const CertificatesFormInstance = createFormInstance<FormData, unknown>(
  CERTIFICATES_FORM_ID,
);

type CertificatesFormProps = {
  onSubmit: ConfigProps<FormData>["onSubmit"];
  onShowForm?: () => void;
  onSubmitSuccess: ConfigProps<FormData>["onSubmitSuccess"];
  initialValues?: FormData;
  editMode?: boolean;
  emptyState?: boolean;
  setSubmitType?: (type: string | null) => void;
};

export const CertificatesForm = ({
  setSubmitType,
  emptyState = false,
  editMode = false,
  ...props
}: CertificatesFormProps) => {
  const [showForm, setShowForm] = useState(emptyState || editMode);

  return (
    <CertificatesFormInstance {...props}>
      {showForm && (
        <>
          <Typography
            mt={3}
            mb={3}
            component="h3"
            variant="paragraph"
            fontWeight={400}
            size="large"
          >
            Add a certificate
          </Typography>
          <Box mb={4}>
            <Field
              id="new_certificate"
              name="new_certificate"
              component={CertificatesField}
              validate={[required]}
              label="Certificate name*"
            />
          </Box>
          <Box mb={4}>
            <Field
              variant="outlined"
              id="issuer"
              name="issuer"
              component={TextField}
              validate={[required]}
              label="Certificate issued by*"
            />
          </Box>
          <Box maxWidth="128px">
            <Field
              id="year_of_issuing"
              name="year_of_issuing"
              inputProps={{ format: "####" }}
              NumberFormat={GraterOrEqualZeroIntegerNumberFormat}
              component={NumberField}
              validate={[required, noFutureYearValidator]}
              label="Year Issued*"
            />
          </Box>

          {!editMode && (
            <Box mt={3}>
              <Button
                variant="tertiary"
                onClick={() =>
                  setSubmitType && setSubmitType(ADD_ANOTHER_CERTIFICATE)
                }
                type="submit"
              >
                Save and add another
              </Button>
            </Box>
          )}
          <Box mt={4} gap={2} display="flex" justifyContent="flex-end">
            {editMode && (
              <Button
                variant="black-outlined"
                shape="squared"
                onClick={CommonConfirmationModal.close}
                type="submit"
              >
                Discard changes
              </Button>
            )}
            <Button variant="positive" shape="squared" type="submit">
              {editMode ? "Save changes" : "Save"}
            </Button>
          </Box>
        </>
      )}
      {!showForm && (
        <Button variant="tertiary" onClick={() => setShowForm(true)}>
          Add a certificate
        </Button>
      )}
    </CertificatesFormInstance>
  );
};
