import { useState } from "react";
import _ from "underscore";

import { useEqualMemo } from "@js/hooks/equal-memo";

const mapValueToOption = (value, options, config) => {
  if (Array.isArray(value)) {
    return value.map((val) => mapSingleValueToOption(val, options, config));
  }

  return mapSingleValueToOption(value, options, config);
};

const mapSingleValueToOption = (value, options, config) => {
  const { valueKey, mapFormValueToOption } = config;

  if (_.isObject(value)) {
    return value;
  }

  if (mapFormValueToOption) {
    const found =
      options.find((option) => mapFormValueToOption(value, option)) || value;
    return found;
  }

  return options.find((option) => option[valueKey] === value) || value;
};

export const useOptions = ({ options, taxonomyOptions, additionalOptions }) => {
  let opt = options || taxonomyOptions;
  opt = additionalOptions ? [...opt, ...additionalOptions] : opt;

  const equalOptions = useEqualMemo(opt);

  return equalOptions;
};

type useValueCommonArg = {
  value: any;
  initialTaxonomies: any[];
  options: any[];
};

type useValueArg =
  | ({
      type: "single";
      valueKey: string | null;
    } & useValueCommonArg)
  | ({
      type: "multiple";
      mapFormValueToOption: (value, option) => any;
    } & useValueCommonArg);

export const useValue = (args: useValueArg) => {
  // options are dynamic so we need to cache previously selected choices in case of multiple select
  const [previouslySelected, setPreviouslySelected] = useState<any>([]);
  const { value: valueProp, initialTaxonomies, options, ...config } = args;

  const newValue = mapValueToOption(
    valueProp,
    [
      ...initialTaxonomies,
      ...(config.type === "multiple" ? previouslySelected : options),
    ],
    config,
  );
  const value = useEqualMemo(newValue);
  return { value, setPreviouslySelected };
};

export const GOOGLE_ADDRESS_COMPONENTS_TYPE = {
  city: "locality",
  state: "administrative_area_level_1",
  country: "country",
};

type GoogleAddressComponentsType = EnumType<
  typeof GOOGLE_ADDRESS_COMPONENTS_TYPE
>;
export const findAddressComponentType = (
  addressComponents,
  type: GoogleAddressComponentsType,
) => {
  return addressComponents.find((component) => component.types.includes(type));
};

export const mapAddressComponentsToAddress = (addressComponents) => {
  const city = findAddressComponentType(
    addressComponents,
    GOOGLE_ADDRESS_COMPONENTS_TYPE.city,
  )?.long_name;
  const state = findAddressComponentType(
    addressComponents,
    GOOGLE_ADDRESS_COMPONENTS_TYPE.state,
  )?.long_name;
  const country = findAddressComponentType(
    addressComponents,
    GOOGLE_ADDRESS_COMPONENTS_TYPE.country,
  )?.short_name;

  return {
    city,
    state,
    country,
  };
};

export const mapFullAddressComponentsToAddress = (addressComponents) => {
  const city = findAddressComponentType(
    addressComponents,
    GOOGLE_ADDRESS_COMPONENTS_TYPE.city,
  );
  const state = findAddressComponentType(
    addressComponents,
    GOOGLE_ADDRESS_COMPONENTS_TYPE.state,
  );
  const country = findAddressComponentType(
    addressComponents,
    GOOGLE_ADDRESS_COMPONENTS_TYPE.country,
  );

  return {
    city,
    state,
    country,
  };
};
