import { useMemo } from "react";

import { useEmployerInvoiceListingContext } from "@js/apps/invoices/context/employer-invoice-listing";

export const useInvoiceTableComponent = () => {
  const { selectedInvoicesIds, totalInvoicesCount, invoiceList } =
    useEmployerInvoiceListingContext();

  const areAllInvoicesOnCurrentPageSelected = useMemo(() => {
    return invoiceList.every((invoice) =>
      selectedInvoicesIds.includes(invoice.id),
    );
  }, [invoiceList, selectedInvoicesIds]);

  const areAllInvoicesAcrossAllPagesSelected = useMemo(() => {
    return selectedInvoicesIds.length === totalInvoicesCount;
  }, [selectedInvoicesIds, totalInvoicesCount]);

  const isAnySelectedOnCurrentPage = invoiceList.some(({ id }) =>
    selectedInvoicesIds.includes(id),
  );

  const showSelectInvoicesOnAllPages =
    (areAllInvoicesAcrossAllPagesSelected ||
      areAllInvoicesOnCurrentPageSelected) &&
    totalInvoicesCount > SETTINGS.INVOICE_LISTING_INVOICES_PER_PAGE;

  return {
    areAllInvoicesAcrossAllPagesSelected,
    isAnySelectedOnCurrentPage,
    showSelectInvoicesOnAllPages,
  };
};
