/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/no-unused-vars */

import URI from "urijs";

// https://github.com/bradvin/social-share-urls

const SHARE_TWITTER_URL = "https://twitter.com/intent/tweet/";
const SHARE_LINKED_IN_URL =
  "https://www.linkedin.com/sharing/share-offsite/?url=";

export type AllLinks = {
  twitter: string;
  email: string;
  linkedIn: string;
};

export type OptionalParams = {
  email?: string;
  subject: string;
  body?: string;
  twitterText?: string;
};

export const ShareLink = {
  twitter: (url: string, text = ""): string => {
    return new URI(SHARE_TWITTER_URL)
      .search({
        hashtags: "braintrust",
        text,
        url,
      })
      .toString();
  },

  linkedIn: (url: string): string => {
    return new URI(SHARE_LINKED_IN_URL).search({ url }).toString();
  },

  email: (email = "", subject: string, body = ""): string => {
    return (
      `mailto:${email}` +
      `?subject=${encodeURIComponent(subject)}` +
      `&body=${encodeURIComponent(body)}`
    );
  },

  createAllLinks: (
    url: string,
    { email, subject, body, twitterText }: OptionalParams,
  ): AllLinks => {
    return {
      twitter: ShareLink.twitter(url, twitterText),
      email: ShareLink.email(email, subject, body),
      linkedIn: ShareLink.linkedIn(url),
    };
  },
};
