import { markFreelancerInvoicesAsPaid } from "@js/apps/invoices/actions";
import { Snackbar } from "@js/components/snackbar";
import type { FreelancerInvoice } from "@js/types/invoices";

import {
  closeInvoicePreviewModal,
  openInvoicePreviewModal,
} from "../../invoice-preview";

export type UseFreelancerSingleInvoiceRowProps = {
  invoice: FreelancerInvoice;
  onInvoicesPaid?: () => void;
};

export const useFreelancerSingleInvoiceRow = ({
  onInvoicesPaid,
  invoice,
}: UseFreelancerSingleInvoiceRowProps) => {
  const markInvoiceAsPaidCallback = () => {
    onInvoicesPaid?.();
  };

  const markInvoicesAsPaid = (invoicesIds: number[]) => {
    markFreelancerInvoicesAsPaid(invoicesIds)
      .then(() => {
        markInvoiceAsPaidCallback();
        Snackbar.success("Successfully marked as paid.");
      })
      .catch((error) => Snackbar.error(error));
  };

  const openInvoicePreview = () => {
    openInvoicePreviewModal({
      invoice,
      invoiceEndpoint: "freelancer_invoices",
      markInvoiceAsPaidCallback: () => {
        closeInvoicePreviewModal();
        markInvoiceAsPaidCallback();
      },
    });
  };

  const handleKeyPress = (ev: React.KeyboardEvent<HTMLTableRowElement>) => {
    const isTableRow = (ev.target as HTMLTableRowElement)?.tagName === "TR";
    if (!ev.shiftKey && ev.key === "Enter" && isTableRow) {
      ev.preventDefault();
      openInvoicePreview();
    }
  };

  return {
    openInvoicePreview,
    markInvoicesAsPaid,
    handleKeyPress,
  };
};
