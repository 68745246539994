import type { MouseEvent } from "react";
import { useCallback, useMemo } from "react";
import { Navigate, Outlet } from "react-router-dom";

import { Box, Loader } from "@hexocean/braintrust-ui-components";
import {
  freelancerProfileClicked,
  freelancerProfileViewed,
} from "@js/apps/freelancer/actions";
import {
  FreelancerProfilePageTabs,
  ProfnetProfilePageHead,
} from "@js/apps/freelancer/components";
import { OpenInterviewModalProvider } from "@js/apps/interview";
import { UniversalSearchContextProvider } from "@js/apps/universal-search/context/universal-search-context";
import { PageHead } from "@js/components/page-head";
import { useAppDispatch } from "@js/hooks";
import { globalTimeoutsDelays, useTimeout } from "@js/hooks/timeout";
import { useIdParam } from "@js/hooks/use-id-param";
import { AppLayout } from "@js/layouts/app";
import { useLogViewTalentProfilePage } from "@js/services/analytics/hooks";
import { isInteractiveElementClicked } from "@js/utils";

import { IsProfileOwnerContext } from "../../common/profile/is-profile-owner-context";
import { NodeStaffView } from "../components/profile/node-staff-view";
import { ProfnetProfilePageCoverImage } from "../components/profnet-profile-page-head/cover-image";
import { useFreelancerPublicProfile } from "../hooks";

const FreelancerProfilePage = (): JSX.Element => {
  const currentProfileId = useIdParam();
  const dispatch = useAppDispatch();
  const { user, profile, isAccessToNodeStaff } =
    useFreelancerPublicProfile(currentProfileId);

  const canDispatchEvent = profile && user && profile.user.id !== user.id;

  useTimeout(
    () => {
      if (!canDispatchEvent) {
        return;
      }
      dispatch(freelancerProfileViewed());
    },
    globalTimeoutsDelays.log_view_event,
    [dispatch, canDispatchEvent],
  );

  const handlePageClick = useCallback(
    (event: MouseEvent<HTMLDivElement>) => {
      const interactiveElementClicked = isInteractiveElementClicked(event);

      if (canDispatchEvent && interactiveElementClicked) {
        dispatch(freelancerProfileClicked());
      }
    },
    [canDispatchEvent, dispatch],
  );

  const isCurrentProfileLoaded =
    !!currentProfileId && currentProfileId === profile?.id;

  const isProfileOwner =
    !!profile &&
    !!user &&
    isCurrentProfileLoaded &&
    profile.user.id === user.id;

  const isProfileOwnerContextProviderValue = useMemo(
    () => ({ isProfileOwner }),
    [isProfileOwner],
  );

  useLogViewTalentProfilePage(
    isCurrentProfileLoaded ? isProfileOwner : undefined,
  );

  if (!currentProfileId) {
    return <Navigate to="/page-not-found/?reloaded=true" replace />;
  }

  if (!isCurrentProfileLoaded) {
    return <Loader centered />;
  }

  return (
    <>
      <UniversalSearchContextProvider initialTab="PEOPLE">
        <AppLayout
          coreClassName="profile-layout"
          className="pt0"
          bgcolor="var(--soft-violet)"
        >
          <IsProfileOwnerContext.Provider
            value={isProfileOwnerContextProviderValue}
          >
            <PageHead>
              <meta property="og:title" content={profile.user.public_name} />
              <meta
                property="og:image"
                content={profile.user.avatar ?? undefined}
              />
              <meta
                property="og:description"
                content={profile.user.title || undefined}
              />
              <title>{`Talent: ${profile.user.public_name}`}</title>
            </PageHead>
            <ProfnetProfilePageCoverImage profile={profile} />
            <Box className="profile-page-content" onClick={handlePageClick}>
              <ProfnetProfilePageHead profile={profile} />
              <FreelancerProfilePageTabs />
              <Outlet />
              {isAccessToNodeStaff && (
                <NodeStaffView mt={8} profile={profile} />
              )}
            </Box>
          </IsProfileOwnerContext.Provider>
        </AppLayout>
      </UniversalSearchContextProvider>
      {isProfileOwner && <OpenInterviewModalProvider />}
    </>
  );
};

FreelancerProfilePage.HTTP_404_ID = "freelancerNotFound";

export default FreelancerProfilePage;
