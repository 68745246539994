import React, { useState } from "react";

import { Switch } from "@hexocean/braintrust-ui-components";
import { CheckIcon, CloseIcon } from "@hexocean/braintrust-ui-components/Icons";
import { useUpdateNotificationsSettingsMutation } from "@js/apps/settings/api";
import type { NotificationSetting } from "@js/types/notifications";

export const NotificationSwitch = ({
  notification,
  className,
}: {
  notification: NotificationSetting;
  className?: string;
}) => {
  const [switchValue, setSwitchValue] = useState(!!notification.value);
  const [updateNotifications] = useUpdateNotificationsSettingsMutation();

  return (
    <Switch
      className={className}
      checked={switchValue}
      checkedIcon={<CheckIcon />}
      icon={<CloseIcon />}
      id={notification.notification}
      onChange={(data) => {
        setSwitchValue(data.target.checked);
        updateNotifications([{ ...notification, value: data.target.checked }]);
      }}
      inputProps={{ "aria-label": notification.notification }}
    />
  );
};
