import { assertUnreachable, getEnumLabel } from "@js/utils";

import { StatusLabel } from "../status-label";

type WithdrawalStatusLabelProps = {
  status?: EnumType<typeof ENUMS.WithdrawalTransactionStatus>;
};

export const WithdrawalStatusLabel = ({
  status,
}: WithdrawalStatusLabelProps) => {
  if (!status) return null;

  const label = getEnumLabel(ENUMS.WithdrawalTransactionStatusLabels, status);

  switch (status) {
    case ENUMS.WithdrawalTransactionStatus.SUCCESS: {
      return <StatusLabel variant="success">Completed</StatusLabel>;
    }
    case ENUMS.WithdrawalTransactionStatus.FAILED:
    case ENUMS.WithdrawalTransactionStatus.LINK_EXPIRED:
    case ENUMS.WithdrawalTransactionStatus.BOUNCED: {
      return <StatusLabel variant="failed">{label}</StatusLabel>;
    }
    case ENUMS.WithdrawalTransactionStatus.PROCESSING:
    case ENUMS.WithdrawalTransactionStatus.AWAITING: {
      return <StatusLabel variant="pending">Pending</StatusLabel>;
    }
    default: {
      assertUnreachable(status);
      return null;
    }
  }
};
