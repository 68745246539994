import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { useUser } from "@js/apps/common/hooks";
import {
  useGetHelpServicesDraftQuery,
  useGetHelpServicesQuery,
} from "@js/apps/give-and-get-help/api";
import type { FreelancerPublic } from "@js/types/freelancer";

import { useClearAddServicesForm } from "../../forms/add-ggh-services-form";
import { ADD_SERVICE_STEP } from "../../hooks";
import { openAddServices } from "../ggh-services/modals/add-ggh-services";

type UseServiceModuleProps = {
  profile: FreelancerPublic;
};

export const servicesSectionId = "services-section";

export const useServicesModule = ({ profile }: UseServiceModuleProps) => {
  const location = useLocation();
  const navigate = useNavigate();

  const { destroyForm } = useClearAddServicesForm();

  const user = useUser();
  const isNotLoggedIn = user === null;
  const isOwnProfile = !!(user && profile.id === user.freelancer);

  const { data: helpServices } = useGetHelpServicesQuery({
    freelancerId: profile.id,
  });
  const { data: helpServicesDraft } = useGetHelpServicesDraftQuery();

  const isHelpServicesEmpty = !helpServices || helpServices.length === 0;
  const isDraftHelpServicesEmpty =
    !helpServicesDraft || helpServicesDraft.length === 0;

  const showEditButton =
    isOwnProfile && (!isHelpServicesEmpty || !isDraftHelpServicesEmpty);

  const isHelpServicesEmptyAndOwnProfile = isHelpServicesEmpty && isOwnProfile;

  const showServicesListWithPendingState =
    !isHelpServicesEmpty || (!!helpServicesDraft?.length && isOwnProfile);
  const showMessageUserBtn = !isHelpServicesEmpty;

  const handleEditButton = () => {
    openAddServices({
      onClose: destroyForm,
      freelancerId: profile.id,
      initialStep:
        !!helpServices && helpServices.length > 0
          ? ADD_SERVICE_STEP.SERVICES_LIST_MODAL_OPENED
          : undefined,
    });
  };

  useEffect(() => {
    if (location.hash !== `#${servicesSectionId}`) return;

    const timeout = setTimeout(
      () =>
        navigate(location.pathname, {
          replace: true,
          state: { noScroll: true },
        }),
      100,
    );

    return () => clearTimeout(timeout);
  }, [location, navigate]);

  return {
    handleEditButton,
    isOwnProfile,
    isHelpServicesEmptyAndOwnProfile,
    showMessageUserBtn,
    showServicesListWithPendingState,
    showEditButton,
    helpServices,
    helpServicesDraft,
    isNotLoggedIn,
  };
};
