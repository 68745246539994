import React from "react";

import { Box, Chip, Typography } from "@hexocean/braintrust-ui-components";
import { useMediaQuery } from "@hexocean/braintrust-ui-components/hooks";
import { StarIcon } from "@hexocean/braintrust-ui-components/Icons";
import { BoxedEmployerLogo } from "@js/apps/employer/components/employer-logo";
import { JobBudgetWithPaymentTypeTooltip } from "@js/apps/jobs/components/job-budget-with-payment-type-tooltip";
import { JobTypeTagWithTooltip } from "@js/apps/jobs/components/job-type-tag-with-tooltip";
import { DEFAULT_LISTING_BG_COLOR } from "@js/apps/jobs/constants";
import { RouterLink } from "@js/components/link";

import type { SimplePostJobLinkAttachment } from "../post-attachments";

export const JobCard = ({
  url,
  title,
  employer,
  job_type,
  job_skills,
  payment_type,
  budget_minimum_usd,
  budget_maximum_usd,
}: SimplePostJobLinkAttachment) => {
  const isSmall = useMediaQuery("sm");
  const isExtraSmall = useMediaQuery(390);
  const maxSkillsQuantity = isExtraSmall ? 1 : isSmall ? 2 : 3;
  const skills =
    job_skills.length >= maxSkillsQuantity
      ? job_skills.slice(0, maxSkillsQuantity)
      : job_skills;

  const { logo_background_color, logo_thumbnail } = employer;
  return (
    <Box
      className="pretty-link-card__container pretty-link-card__container--with-full-padding"
      component={RouterLink}
      to={url}
    >
      <div className="pretty-link-card__logo-and-job-type">
        <BoxedEmployerLogo
          employer={employer}
          className="pretty-link-card__boxed-employer-logo"
          bgcolor={`var(${logo_background_color || DEFAULT_LISTING_BG_COLOR})`}
          source={logo_thumbnail}
          size="small"
        />
        <JobTypeTagWithTooltip jobType={job_type} />
      </div>
      <div className="pretty-link-card__title-and-budget">
        <div className="pretty-link-card__title">
          <Typography
            component="h5"
            variant="label"
            size="medium"
            multilineEllipsis={1}
            fontWeight={500}
            className="capitalize-first-letter"
          >
            {employer.name}
          </Typography>
          <Typography
            component="h5"
            variant="paragraph"
            size="small"
            multilineEllipsis={1}
            className="capitalize-first-letter"
          >
            {title}
          </Typography>
        </div>
        <JobBudgetWithPaymentTypeTooltip
          job={{
            payment_type,
            budget_minimum_usd: budget_minimum_usd,
            budget_maximum_usd: budget_maximum_usd,
          }}
          variant="paragraph"
          size="large"
          fontWeight={400}
        />
      </div>
      <Box display="flex" alignItems="center">
        <Box
          display="flex"
          flexWrap="wrap"
          gap={1}
          alignItems="stretch"
          overflow="hidden"
        >
          {skills.map((skill) => {
            return (
              <Chip
                key={`skill-${skill.skill.id}`}
                label={skill.skill.name}
                className="skill-chip"
                LabelProps={{
                  component: "span",
                  variant: "label",
                }}
                icon={!isSmall && skill.is_top ? <StarIcon /> : undefined}
                color={skill.is_top ? "positive-green" : "soft-grey"}
                variant="rounded"
                size="small"
                typographySize="small"
              />
            );
          })}
        </Box>
      </Box>
    </Box>
  );
};
