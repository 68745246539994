import {
  actionTypes,
  getFormError,
  getFormSubmitErrors,
  getFormSyncErrors,
} from "redux-form";
import _ from "underscore";

import { scrollToFirstError } from "../forms/utils";

export const reduxFormScrollToFirstErrorMiddleware =
  (store) => (next) => (action) => {
    if (action.type === actionTypes.SET_SUBMIT_FAILED) {
      const formId = action.meta.form;

      const generalFormError = getFormError(formId)(store.getState());
      const syncErrors = getFormSyncErrors(formId)(store.getState());
      const submitErrors = getFormSubmitErrors(formId)(store.getState());

      const errors =
        generalFormError || (_.isEmpty(syncErrors) ? submitErrors : syncErrors);

      scrollToFirstError(formId, errors, "");
    }

    return next(action);
  };
