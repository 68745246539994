import React from "react";

import { Typography } from "@hexocean/braintrust-ui-components";
import { AppLayout } from "@js/layouts/app";

const TooManyRequestsPage = () => {
  return (
    <AppLayout
      pageTitle="Too Many Requests"
      flexColumn
      className="not-found-page"
    >
      <>
        <Typography component="h1" className="not-found-page-title">
          429
        </Typography>
        <Typography
          component="p"
          variant="title"
          fontWeight={400}
          className="not-found-page-message"
        >
          Too Many Requests
        </Typography>
        <Typography
          component="p"
          variant="title"
          fontWeight={400}
          className="not-found-page-info"
        >
          Please wait for a while or contact us about the problem.
        </Typography>
      </>
    </AppLayout>
  );
};

export default TooManyRequestsPage;
