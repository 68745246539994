import React from "react";

import { Button, Typography } from "@hexocean/braintrust-ui-components";
import { CheckCircleIcon } from "@hexocean/braintrust-ui-components/Icons";

import styles from "./style.module.scss";

const Option = ({
  link = null,
  onClick,
  header,
  subheader = null,
  icon,
  active = false,
  id,
  disabled,
}: {
  link?: string | null;
  onClick?: () => void;
  header: React.ReactNode;
  subheader?: React.ReactNode | null;
  icon: string;
  active?: boolean;
  disabled?: boolean;
  id: string;
}) => {
  const content = (
    <Button
      variant="secondary"
      size="medium"
      fullWidth
      className={styles["vote-wallet-option"]}
      id={id}
      onClick={onClick}
      disabled={disabled}
    >
      {active && (
        <CheckCircleIcon
          className={styles["vote-wallet-option__active-icon"]}
        />
      )}
      <span className={styles["vote-wallet-option__header"]}>
        {header}
        {subheader && (
          <Typography component="p" size="small">
            {subheader}
          </Typography>
        )}
      </span>
      <img
        src={icon}
        alt="Icon"
        className={styles["vote-wallet-option__icon"]}
      />
    </Button>
  );

  if (link) {
    return (
      <a href={link} target="_blank" rel="noopener noreferrer">
        {content}
      </a>
    );
  }

  return content;
};

export default Option;
