import React from "react";
import { Field, reduxForm } from "redux-form";

import { Button, Grid } from "@hexocean/braintrust-ui-components";
import { AddIcon } from "@hexocean/braintrust-ui-components/Icons";
import { Form } from "@js/forms/components/form";
import { StandardTextField } from "@js/forms/fields";

const INVITE_BY_EMAIL_FORM_ID = "inviteByEmailFormId";

export type InviteMemberFormData = {
  email: string;
};

export const InviteByEmailForm = reduxForm<InviteMemberFormData>({
  form: INVITE_BY_EMAIL_FORM_ID,
})(({ error, submit, submitting }) => {
  return (
    <Form onSubmit={submitting ? null : submit} error={error}>
      <Grid container spacing={4}>
        <Grid item xs={12} sm={6}>
          <Field
            id="email"
            name="email"
            variant="standard"
            component={StandardTextField}
            label="Email Address"
            size="small"
          />
        </Grid>
        <Grid item sm={3}>
          <Button
            variant="primary"
            disabled={submitting}
            onClick={submit}
            sx={{ whiteSpace: "nowrap" }}
          >
            <AddIcon className="button-add-icon" /> Add To Team
          </Button>
        </Grid>
      </Grid>
    </Form>
  );
});
