import type { FC } from "react";

import type { SvgIconProps } from "@hexocean/braintrust-ui-components";
import { Typography } from "@hexocean/braintrust-ui-components";

import styles from "./team-member-invitations-action-button.module.scss";

export const TeamMemberInvitationsActionButton = ({
  label,
  Icon,
}: {
  label: string;
  Icon: FC<SvgIconProps>;
}) => {
  return (
    <Typography
      component="button"
      variant="paragraph"
      sx={{
        textDecoration: "none",
        border: "none",
        backgroundColor: "transparent",
        color: "currentColor",
        padding: 0,
        cursor: "pointer",
      }}
      size="small"
      color="blue"
      fontWeight={500}
      className={styles.button}
    >
      <span className={styles.iconWrapper}>
        <Icon className={styles.icon} />
      </span>
      {label}
    </Typography>
  );
};
