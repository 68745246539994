import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { Button, Typography } from "@hexocean/braintrust-ui-components";
import { useMediaQuery } from "@hexocean/braintrust-ui-components/hooks";
import { useUser } from "@js/apps/common/hooks";
import { useMint } from "@js/apps/nft/hooks";
import { RouterLink } from "@js/components/link";
import { Logo } from "@js/components/logo";

import { Video } from "../../components/nft-video-img";

import styles from "./style.module.scss";

const BUTTON_ACTION = {
  LOGIN: {
    text: "Sign in",
    url: `/auth/login/?next=${encodeURIComponent("/nft/mint/")}`,
  },
  MINT: {
    text: "Get started",
    url: "/nft/mint/",
  },
  MANAGEMENT: {
    text: "Get started",
    url: "/nft/management/",
  },
} as const;

export const NFTMembershipPage = () => {
  const isMobile = useMediaQuery("md");
  const { status, tokenId } = useMint();
  const navigate = useNavigate();
  const user = useUser();

  useEffect(() => {
    if (status === ENUMS.NFTMintingStatus.MINTED) {
      navigate(`/nft/${tokenId}/`);
    }
  }, [navigate, status, tokenId]);

  const action = !!user ? BUTTON_ACTION.MINT : BUTTON_ACTION.LOGIN;

  return (
    <div className="nft-membership">
      <article className={styles.content}>
        <Logo variant="full-white" />
        <Typography
          component="h1"
          variant="title"
          fontWeight={400}
          size={isMobile ? "small" : "medium"}
          mt={7}
          mb={2}
        >
          Mint your Membership NFT!
        </Typography>
        <Typography component="p" size={isMobile ? "medium" : "large"} mb={7}>
          Your NFT is your new on-chain membership to Braintrust. Deposit $BTRST
          in it to unlock special features. Use your Braintrust profile in other
          web3 apps.
        </Typography>
        <Button
          variant="primary"
          inverse
          to={action.url}
          size="medium"
          RouterLink={RouterLink}
        >
          {action.text}
        </Button>
      </article>
      <div className={styles.previewContainer}>
        <Video
          src={`${SETTINGS.STATIC_URL}nft/nft.mp4`}
          className={styles.image}
        />
      </div>
    </div>
  );
};
