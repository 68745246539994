import { useEffect, useState } from "react";

import {
  Box,
  Button,
  IconButton,
  Typography,
  useMediaQuery,
} from "@hexocean/braintrust-ui-components";
import {
  ArrowForwardIcon,
  CloseIcon,
} from "@hexocean/braintrust-ui-components/Icons";
import { useAccountType } from "@js/apps/common/hooks";
import { openBoostModal } from "@js/apps/jobs/components/job-list-boost-modal/job-list-boost-modal";
import { useAppSelector } from "@js/hooks";
import { LocalStorage } from "@js/services";

export const JobListBoostBanner = () => {
  const user = useAppSelector((state) => state.auth.user);

  const [showJobListBoostBanner, setShowJobListBoostBanner] = useState(false);
  const { isFreelancer } = useAccountType();

  useEffect(() => {
    const determineBoostBannerVisibility = () => {
      if (user?.boost_credit || !isFreelancer) {
        return;
      }

      const dismissTime = LocalStorage.getItem(
        LocalStorage.keys.JOB_LIST_BOOST_BANNER_DISMISS_TIME,
      );

      if (!dismissTime) {
        setShowJobListBoostBanner(true);
        return;
      }

      const twoHoursLater = new Date(
        parseInt(dismissTime, 10) + 2 * 60 * 60 * 1000,
      );

      if (new Date() > twoHoursLater) {
        setShowJobListBoostBanner(true);
      } else {
        setShowJobListBoostBanner(false);
      }
    };

    determineBoostBannerVisibility();
  }, [user?.boost_credit, isFreelancer]);

  const handleDismiss = () => {
    LocalStorage.setItem(
      LocalStorage.keys.JOB_LIST_BOOST_BANNER_DISMISS_TIME,
      String(new Date().getTime()),
    );
    setShowJobListBoostBanner(false);
  };
  const openModal = async () => {
    openBoostModal();
  };

  const isMobile = useMediaQuery("sm");

  if (!showJobListBoostBanner) {
    return null;
  }

  return (
    <Box
      display="flex"
      flexDirection={isMobile ? "column" : "row"}
      borderRadius={4}
      position="relative"
      sx={{
        border: "1px solid var(--soft-grey)",
      }}
      overflow="auto"
    >
      <IconButton
        size={isMobile ? "medium" : "x-small"}
        variant="transparent"
        aria-label="Dismiss boost banner"
        sx={{
          position: "absolute",
          right: isMobile ? 0 : 1,
          top: isMobile ? 0 : 2,
        }}
        onClick={handleDismiss}
      >
        <CloseIcon />
      </IconButton>

      <Box display="flex" flex="3">
        <Box
          minHeight="146px"
          width="100%"
          sx={{
            backgroundColor: "var(--blue)",
            backgroundImage: `url(${SETTINGS.STATIC_URL}jobs/job-list-banner.png)`,
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            backgroundSize: isMobile ? "cover" : "contain",
          }}
        />
      </Box>

      <Box
        bgcolor="white"
        display="flex"
        flexWrap={isMobile ? "wrap" : "nowrap"}
        alignItems="center"
        justifyContent="space-between"
        gap={1.5}
        p={3}
        flex="5"
      >
        <Box display="flex" flexDirection="column">
          <Typography component="p" size="large" mb={1}>
            Stand out with Application Boost
          </Typography>

          <Typography component="p" size="small">
            Elevate your applications to the top and get noticed by employers.
          </Typography>
        </Box>

        <Box>
          <Button
            onClick={openModal}
            variant="positive"
            sx={{
              height: "40px",
              borderRadius: "10px !important",
              textWrap: "nowrap",
            }}
            endIcon={<ArrowForwardIcon />}
          >
            Get Application Boosts
          </Button>
        </Box>
      </Box>
    </Box>
  );
};
