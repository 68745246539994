import { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";

import { useUser } from "@js/apps/common/hooks";
import { openEmailConfirmationModal } from "@js/apps/onboarding/components/sign-up-confirmation-content";

export const useEmailConfirmationModal = () => {
  const user = useUser();
  const wasShownRef = useRef(false);
  const { pathname } = useLocation();

  const isVerifiedUser = !!user?.is_verified;
  useEffect(() => {
    if (isVerifiedUser || wasShownRef.current) {
      return;
    }

    const isTalentDashboard = /^\/talent\/dashboard\/welcome\/?$/.test(
      pathname,
    );
    if (isTalentDashboard) {
      wasShownRef.current = true;
      openEmailConfirmationModal();
    }
  }, [isVerifiedUser, pathname, wasShownRef]);
};
