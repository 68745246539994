import { useMemo } from "react";

import {
  Box,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Tooltip,
  Typography,
} from "@hexocean/braintrust-ui-components";
import {
  BraintrustIcon,
  InfoIcon,
} from "@hexocean/braintrust-ui-components/Icons";
import { ApprovedBadge } from "@js/apps/freelancer/components/approved-badge";
import type { NftTokenMeta } from "@js/apps/nft/types";

import { getNftAttributesAsObject } from "../../utils";

const TRAIT_TYPE = ENUMS.NFTMetadataTraitTypeLabels;

const NameTableCell = ({ value }) => {
  return (
    <TableCell
      className="name-table-cell"
      component="th"
      scope="row"
      align="left"
    >
      <Typography component="span" variant="label">
        {value}
      </Typography>
    </TableCell>
  );
};

const DefaultTableCell = ({ value }) => {
  return (
    <TableCell className="default-table-cell" align="left">
      {value}
    </TableCell>
  );
};

const IconTableCell = ({ value, type }) => {
  if (type === TRAIT_TYPE.approved) {
    return (
      <TableCell className="icon-table-cell" align="left">
        <ApprovedBadge boxProps={{ className: "icon-table-cell__icon" }} />
        {value ? "Approved" : "Not Approved"}
      </TableCell>
    );
  }

  return (
    <TableCell className="icon-table-cell" align="left">
      <BraintrustIcon className="icon-table-cell__icon" sx={{ mr: 1 }} />
      {value}
    </TableCell>
  );
};

type PublicOnChainProfileProps = {
  profile: NftTokenMeta;
  tokenId: number;
};

export const PublicOnChainProfile: React.FC<PublicOnChainProfileProps> = ({
  profile,
  tokenId,
}) => {
  const attributes = useMemo(
    () => getNftAttributesAsObject(profile.attributes),
    [profile.attributes],
  );

  const rows = useMemo(
    () => [
      attributes[ENUMS.NFTMetadataTraitTypeLabels.username],
      { trait_type: "NFT Artwork Link", value: profile.image },
      attributes[ENUMS.NFTMetadataTraitTypeLabels.membership_tier],
      { trait_type: "Membership Number", value: tokenId },
      attributes[ENUMS.NFTMetadataTraitTypeLabels.internal_user_id],
      attributes[ENUMS.NFTMetadataTraitTypeLabels.approved],
      attributes[ENUMS.NFTMetadataTraitTypeLabels.token_balance],
      attributes[ENUMS.NFTMetadataTraitTypeLabels.profile_link],
    ],
    [attributes, profile.image, tokenId],
  );

  return (
    <Box className="public-on-chain-profile">
      <Box className="public-on-chain-profile__head">
        <Typography component="p" size="large">
          Public on-chain profile
        </Typography>
        <Tooltip
          title={
            <Typography component="p" size="small" variant="paragraph">
              This information is viewable to other web3 applications, so you
              can take your professional identity with you anywhere online.
            </Typography>
          }
        >
          <span>
            <IconButton
              aria-label="tokens balance"
              variant="primary"
              size="x-small"
              style={{ pointerEvents: "none" }}
            >
              <InfoIcon />
            </IconButton>
          </span>
        </Tooltip>
      </Box>
      <TableContainer
        className="public-on-chain-profile__table"
        component={Paper}
      >
        <Table aria-label="nft info table">
          <TableBody>
            {rows.map((row) => (
              <TableRow
                className="public-on-chain-profile__table-row"
                key={row.trait_type}
              >
                <NameTableCell value={row.trait_type} />
                {row.trait_type === TRAIT_TYPE.approved ||
                row.trait_type === TRAIT_TYPE.token_balance ? (
                  <IconTableCell value={row.value} type={row.trait_type} />
                ) : (
                  <DefaultTableCell value={row.value} />
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};
